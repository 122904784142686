import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchAllChannelPushLogs,
  fetchChannelPushLogs,
  channelPusherCloseNotification,
} from '../../actions/channelPusher';
import useInterval from '../../hooks/useInterval';
import DescriptionsList from '../../components/global/DescriptionsList';
import { ApplicationState } from '../../reducers';

const ChannelPusher: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const runningChannelPushIds = useSelector((state: ApplicationState) =>
    state.channelPusher.channelPushes.filter((log: any) => !log.isCompleted).map(({ id }) => id)
  );
  const channelPushes = useSelector((state: ApplicationState) => state.channelPusher.channelPushes);
  const showNotification = useSelector(
    (state: ApplicationState) => state.channelPusher.showNotification
  );

  React.useEffect(() => {
    dispatch(fetchAllChannelPushLogs());
  }, [dispatch]);

  React.useEffect(() => {
    const handleGoToDistributionReports = () => {
      navigate(`/distribution/history`);
    };

    const displayNotification = () => {
      notification.open({
        key: 'channel-pusher',
        message: t('channelPusher:channelUpdates'),
        onClose: () => dispatch(channelPusherCloseNotification()),
        description: (
          <div className="flex flex-col">
            <div
              style={{ maxHeight: 400 }}
              className="flex flex-col overflow-y-auto overflow-x-hidden"
            >
              <DescriptionsList colNumber={2}>
                {channelPushes.map(log => (
                  <DescriptionsList.Item
                    key={log.id}
                    label={`${t('channelPusher:channel')}: ${log.channelName}`}
                    fullWidth
                  >
                    <div className="flex flex-col justify-between">
                      <div className="flex-1">
                        {t('channelPusher:plan')}: {log.planName}
                        {log.brandName ? ` (${log.brandName})` : ''}
                      </div>
                      <div className="flex-1 flex">
                        <div className="flex-1" />
                        <div className="flex flex-1">
                          <div className="pr-2"> {t('channelPusher:status')}:</div>
                          <div>
                            {log.isCompleted && log.statusId === 3 && (
                              <div>
                                {t('common:warnings')}
                                <WarningOutlined className="icon__yellow ml-2" />
                              </div>
                            )}
                            {log.isCompleted && log.statusId === 4 && (
                              <div>
                                {t('common:errors')}
                                <WarningOutlined className="text-red-600 ml-2" />
                              </div>
                            )}
                            {log.isCompleted && log.statusId === 5 && (
                              <div>
                                {t('channelPusher:finished')}
                                <CheckCircleOutlined className="text-green-600 ml-2" />
                              </div>
                            )}
                            {!log.isCompleted && (
                              <div>
                                {t('channelPusher:pushing')}
                                <LoadingOutlined className="ml-2" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </DescriptionsList.Item>
                ))}
              </DescriptionsList>
            </div>
          </div>
        ),
        btn: (
          <Button onClick={() => handleGoToDistributionReports()} type="primary">
            {t('channelPusher:goToReports')}
          </Button>
        ),
        duration: 0,
      });
    };
    if (channelPushes.length > 0 && showNotification) {
      displayNotification();
    }
  }, [t, dispatch, channelPushes, runningChannelPushIds, showNotification, navigate]);

  const pollRunningChannelPushes = () => {
    if (runningChannelPushIds.length > 0) {
      dispatch(fetchChannelPushLogs(runningChannelPushIds));
    }
  };

  const [delay, setDelay] = useState(2000);
  const [pendingCount, setPendingCount] = useState(0);

  React.useEffect(() => {
    // reset polling interval when new push was started
    if (runningChannelPushIds.length > pendingCount) {
      setDelay(2000);
    }
    if (runningChannelPushIds.length !== pendingCount) {
      setPendingCount(runningChannelPushIds.length);
    }
  }, [pendingCount, runningChannelPushIds]);

  useInterval(() => {
    if (delay < 600000) setDelay(delay + 1000);
    pollRunningChannelPushes();
  }, delay);

  return null;
};

export default ChannelPusher;
