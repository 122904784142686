import React from 'react';
import { connect } from 'react-redux';
import constants from '../../constants/ErrorBoundaryTranslation.json';
import { notifyBugsnag } from '../../actions/tools/BugsnagActions';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    window.onerror = this.logError;
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    this.logError(error, errorInfo);
  }

  logError = (error, errorInfo) => {
    this.props.dispatch(
      notifyBugsnag(error, {
        metadata: {
          error: JSON.stringify([error, errorInfo]),
        },
      })
    );
  };

  render() {
    if (this.state.hasError && !this.props.noMessage) {
      return <h1>{constants.error_message}</h1>;
    }
    return this.props.children;
  }
}

export default connect()(ErrorBoundary);

export const withErrorBoundary = Component => props =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
