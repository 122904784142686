import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { SubBrand } from '../../../types/brand';
import { AvailableReceiver } from '../../../types/receiver';
import { AvailableChannel } from '../../../types/channel';

export type CurrentBrandState = {
  isInitialized: boolean;
  isFilterInitialized: boolean;
  subBrands: SubBrand[];
  availableChannels: AvailableChannel[];
  availableReceivers: AvailableReceiver[];
};

const initialState = {
  isInitialized: false,
  isFilterInitialized: false,
  subBrands: [],
  availableChannels: [],
  availableReceivers: [],
};

const reducer: Reducer<CurrentBrandState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BRAND_INITIALIZE_STATUS': {
      return { ...state, isInitialized: action.payload.status };
    }
    case 'SET_BRAND_FILTER_INITIALIZE_STATUS': {
      return { ...state, isFilterInitialized: action.payload.status };
    }
    case 'FETCH_SUB_BRANDS_FULFILLED': {
      const brandList = action.payload.data.brands;
      const subBrands = brandList[0]?.sub_brands || [];
      return { ...state, subBrands };
    }
    case '@MANUFACTURER/FETCH_AVAILABLE_CHANNELS_AND_RECEIVERS_FULFILLED': {
      const { data } = action.payload;
      return {
        ...state,
        availableChannels: data.channels,
        availableReceivers: data.receivers,
      };
    }
  }
  return state;
};

export default reducer;
