import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import LoginFooter from '../LoginFooter';
import useGoogleAnalytics from '../../containers/GoogleAnalyticsTracker';

type VerificationSuccessPageProps = {
  receiver?: boolean;
};

const VerificationSuccessPage: React.FC<VerificationSuccessPageProps> = ({ receiver }) => {
  const { t } = useTranslation();
  useGoogleAnalytics();

  return (
    <div className="login__background">
      <div className="login__box">
        <div className="login__box-header">
          <div className="login-logo" />
          <div className="login__box-content">
            <div className="login--header-content">
              <div className="login__title">{t('login:welcome_txt1')}</div>
              <div className="mt-2">{t('login:verifyWelcome')}</div>
            </div>
            <div className="login__box-login">
              <div className="verify__content-icon">
                <CheckCircleOutlined />
              </div>
              <div className="text-base mt-3">
                <p>{t('login:emailVerified')}</p>
                <p>
                  {receiver ? t('login:verifyInfoReceiver') : t('login:verifyInfoManufacturer')}
                </p>
              </div>

              <Link to="/">
                <Button type="primary" size="large" style={{ width: '100%', marginTop: 5 }}>
                  {t('login:clickHere')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default VerificationSuccessPage;
