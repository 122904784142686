import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleFilled } from '@ant-design/icons';

type ThreeLevelDropdownProps = {
  elements?: { id: number; parent_name: string; owner_name: string; brand_name: string }[];
  selectedElement: number;
  fetchingRegistrationResources: boolean;
  onChange: (id?: number) => void;
  onBlur: () => void;
  addCompany: (name: string) => void;
};

const ThreeLevelDropdown: React.FC<ThreeLevelDropdownProps> = props => {
  const { elements, selectedElement, fetchingRegistrationResources, onChange, onBlur, addCompany } =
    props;

  const { t } = useTranslation();

  const [keywords, setKeywords] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleSearch = (keywords: string) => {
    setKeywords(keywords);
  };

  // filters the values by parameter => every filter-keyword must be in the value-name
  const filteredValues = () => {
    const keys = keywords
      .toLowerCase()
      .split(' ')
      .filter(entry => !!entry);
    const filtered = elements?.filter(elem =>
      keys.every(keyword => {
        const brandName = elem.brand_name || '';
        const ownerName = elem.owner_name || '';
        return (
          elem.parent_name.toLowerCase().includes(keyword) ||
          ownerName.toLowerCase().includes(keyword) ||
          brandName.toLowerCase().includes(keyword)
        );
      })
    );
    return filtered || [];
  };

  const values = keywords && elements ? filteredValues() : elements || [];

  return (
    <Select
      className="register__brand-select"
      popupClassName="register__brand-select-dropdown"
      placeholder={t('registerPage:placeholder.company_select')}
      value={selectedElement}
      loading={fetchingRegistrationResources}
      open={open}
      onDropdownVisibleChange={open => setOpen(open)}
      showSearch
      onSearch={handleSearch}
      onChange={val => {
        onChange(val);
        if (val === 0) addCompany(keywords);
        if (keywords) setKeywords('');
      }}
      onBlur={onBlur}
      filterOption={false}
      allowClear
      dropdownRender={menu => (
        <React.Fragment>
          {values.length > 0 && menu}
          {keywords && (
            <div
              className="register__add-company flex text-primary-600"
              onClick={() => {
                onChange(0);
                addCompany(keywords);
                setKeywords('');
                setOpen(false);
              }}
            >
              <div className="register__add-company-icon">
                <PlusCircleFilled />
              </div>
              <div className="flex-1">
                <div className="text-xs pt-1">{t('registerPage:add_company')}</div>
                <span className="text-base pb-1">{keywords}</span>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    >
      {!open && (
        <Select.Option value={selectedElement}>
          <div className="text-sm pt-1">
            {values.find(b => b.id === selectedElement)?.parent_name ||
              (selectedElement === 0 && t('registerPage:label.other_company'))}
          </div>
        </Select.Option>
      )}
      {open && (
        <React.Fragment>
          {values.map((value, i) => (
            <Select.Option
              key={i + value.id.toString()}
              value={value.id}
              title={value.parent_name}
              onClick={() => setOpen(true)}
            >
              <div className="flex">
                <div className="register__brand-select-dot text-gray-700">&#9679;</div>
                <div className="flex-1">
                  <div className="leading-none pt-1">
                    <span className="text-xs">{value.parent_name}</span>
                    <span className="register__brand-select-owner pl-2 text-gray-700 text-xs">
                      {value.owner_name}
                    </span>
                  </div>
                  <span className="text-base pb-1">{value.brand_name}</span>
                </div>
              </div>
            </Select.Option>
          ))}
        </React.Fragment>
      )}
    </Select>
  );
};

export default ThreeLevelDropdown;
