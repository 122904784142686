import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchAccountLanguages } from '../../../actions/parent/language/fetch';
import LanguageSettingsPage from '../../../components/body/parentSettings/translations/LanguageSettingsPage';

const LanguageSettingsContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAccountLanguages());
  }, [dispatch]);

  return <LanguageSettingsPage />;
};

export default LanguageSettingsContainer;
