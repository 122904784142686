import { WarningOutlined } from '@ant-design/icons';
import React from 'react';

import { hasManualDelivery } from '../../../../utils/ChannelUtils.ts';
import AntButtonDropdown from '../../../global/Dropdown/AntButtonDropdown';
import AntTooltip from '../../../global/AntTooltip';
import translationConstants from '../../../../constants/ChannelTranslation.json';

class ChannelUpdate extends React.Component {
  createActionMenu() {
    const {
      channel,
      plan,
      plan: { id: planId, brand_ids: brandIds, file_type_id: fileTypeId },
      pushChannelUpdate,
      downloadChannelUpdate,
      downloadDisabled,
      integrationTypeId,
    } = this.props;
    // Note: It is planned that a plan can belong to multiple brandIds
    // However currently there is no case where the brandIds array can have more than one entry
    const brandId = brandIds.length > 0 ? brandIds[0] : undefined;
    const planHasManualDelivery = hasManualDelivery(this.props.plan, channel);
    const noPush = !plan.push_available;

    const actions = [];

    if (!planHasManualDelivery || integrationTypeId === 3) {
      actions.push({
        id: '1',
        name: translationConstants.pushSingle,
        callback: () => pushChannelUpdate(planId),
        disabled: noPush,
      });
    }

    // no download for ebay channel
    if (integrationTypeId !== 3)
      actions.push({
        id: '2',
        name: translationConstants.downloadSingle,
        callback: () => downloadChannelUpdate(planId, brandId, fileTypeId),
        disabled: downloadDisabled || noPush,
      });

    if (integrationTypeId !== 8)
      actions.push({
        id: '3',
        name: translationConstants.editPlan,
        callback: () => this.props.editPlan(channel.id, planId),
        disabled: false,
      });

    const handleActionClick = ({ key }) => {
      const { selectedProfiles } = this.props;

      const action = actions.find(({ id }) => key === id);
      if (action) {
        action.callback(selectedProfiles);
      }
    };

    return actions.length
      ? actions.map(({ id, name, disabled }) => ({
          label: name,
          disabled,
          key: id,
          onClick: handleActionClick,
        }))
      : undefined;
  }

  render() {
    const { plan } = this.props;
    const noPush = !plan.push_available;

    return (
      <div className="channel-update">
        <AntButtonDropdown
          title={translationConstants.exportSelected}
          menu={{
            items: this.createActionMenu(),
          }}
          trigger={['click']}
          size="small"
        />
        {noPush && (
          <AntTooltip title={translationConstants.invalidConfiguration}>
            <WarningOutlined className="ml-2 text-red-500" />
          </AntTooltip>
        )}
      </div>
    );
  }
}

export default ChannelUpdate;
