import React from 'react';

const WAIT_INTERVAL = 1000;

class TextInput extends React.Component {
  timer;

  state = {
    value: this.props.value || '',
  };

  handleChange = e => {
    clearTimeout(this.timer);
    this.setState({ value: e.target.value });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  triggerChange = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    const { className, readOnly, maxLength, dataCy } = this.props;
    return (
      <input
        data-cy={dataCy}
        className={className}
        readOnly={readOnly}
        value={this.state.value}
        onChange={this.handleChange}
        maxLength={maxLength}
      />
    );
  }
}

TextInput.defaultProps = {
  className: '',
  value: '',
  onChange: () => {},
  readOnly: false,
};

export default TextInput;
