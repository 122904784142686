import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table, Modal, Popover, DatePicker, Popconfirm } from 'antd';
import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { SelectValue } from 'antd/lib/select';
import { ApplicationState } from '../../../reducers';
import { ExtendedResources } from '../../../../types/resources';
import { deletePrice } from '../../../actions/items/pricing/delete';
import { PriceSheet, InitItemPrice } from '../../../../types/price_sheet';
import { DefaultValue } from '../../../../types/brand_settings';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';

const confirm = Modal.confirm;

type PriceItemTableProps = {
  itemPrices: InitItemPrice[];
  itemPriceSheetId: number;
  handleChange: ({
    prevStateRecord,
    changedStateRecord,
    newRecord,
    recordCountId,
  }: {
    prevStateRecord?: InitItemPrice;
    changedStateRecord?: InitItemPrice;
    newRecord?: InitItemPrice;
    recordCountId?: number;
  }) => void;
};

const PriceItemTable: React.FC<PriceItemTableProps> = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { itemPrices, handleChange } = props;
  const [data, setData] = React.useState(itemPrices);

  const { selectedItem, resources, defaultValues, priceSheets, brandId } = useSelector(
    (state: ApplicationState) => {
      return {
        selectedItem: getSelectedItems(state)[0],
        resources: state.resources.data.price,
        defaultValues: state.settings.defaultValues,
        priceSheets: state.parent.priceSheets.priceSheets,
        brandId: state.parent.brands.selectedBrandId,
      };
    }
  );
  const [showField, setShowField] = React.useState<number | undefined>(undefined);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setData(itemPrices);
    const priceSheet = priceSheets.find(
      (priceSheet: PriceSheet) => priceSheet.id === props.itemPriceSheetId
    );

    const newItemPriceRow = {
      uniqueId: -1,
      key: '0',
      name: t('pricing:newItemPrice'),
      priceSheetCurrencyId: priceSheet?.currency_id,
      priceSheetId: props.itemPriceSheetId,
    };
    setData([newItemPriceRow, ...itemPrices]);
  }, [itemPrices, priceSheets, props.itemPriceSheetId, t]);

  const fileInput: any = React.useRef();

  const renderSuggestButton = (name: string, id: number) => (
    <Button
      key={id}
      type="dashed"
      size="small"
      onClick={() => {
        setShowField(id);
      }}
    >
      {name}
    </Button>
  );

  const addNewRow = (record: InitItemPrice) => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'item_price_new',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    const defaultUom = defaultValues.find(
      (def: DefaultValue) => def.resource_table === 'price_uoms'
    );
    const defaultCurrency = defaultValues.find(
      (def: DefaultValue) => def.resource_table === 'price_currencies'
    );

    const newRowData = {
      priceSheetId: record.priceSheetId,
      uniqueId: data.length - 1,
      countId: data.length + 1,
      typeId: null,
      price: null,
      priceUomId: Number(defaultUom.value) || null,
      currencyId: record.priceSheetCurrencyId || Number(defaultCurrency.value) || null,
      priceBreak: null,
      priceBreakUomId: null,
      effectiveDate: null,
      expirationDate: null,
      typeDescription: null,
    };
    handleChange({ newRecord: newRowData });
  };
  const renderButton = (record: InitItemPrice) => (
    <Button
      size="small"
      onClick={() => addNewRow(record)}
      style={{ width: '180px' }}
      data-testid="new-item-price"
    >
      {t('pricing:newItemPrice')}
    </Button>
  );

  const renderSelect = (
    options: ExtendedResources[],
    existingValue: ExtendedResources,
    record: InitItemPrice,
    parameter: string
  ) => {
    return (
      <FormSelect
        className={classNames('price-item-table__select', {
          'border-invisible': !!existingValue,
        })}
        name={`[${record.priceSheetId}][${record.uniqueId}].${parameter}`}
        values={options}
        onChange={(value: SelectValue) => {
          const changedRecord = { ...record, [parameter]: value };
          handleChange({ prevStateRecord: record, changedStateRecord: changedRecord });
        }}
        clearContainer
        size="small"
        allowClear
        showSearch
        optionToolTip
        testId="price-type"
      />
    );
  };

  return (
    <div className="price-item-table__form-fields">
      <Table
        pagination={false}
        size="small"
        dataSource={data}
        className="price-item-table"
        rowKey="uniqueId"
      >
        <Column
          title={t('pricing:type')}
          key={1}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) {
              return {
                children: <div>{renderButton(record)}</div>,
                props: { colSpan: 2 },
              };
            }
            const existingValue = resources.types.find(
              (type: ExtendedResources) => type.id === record.typeId
            );
            return renderSelect(resources.types, existingValue, record, 'typeId');
          }}
          width={180}
        />
        <Column
          title={t('pricing:price')}
          key={2}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            return showField === record.uniqueId ||
              record.price ||
              !record.typeId ||
              record.countId ||
              record.typeId !== record.usedTypeIdInOtherItem ? (
              <div className="price-item-table__input ">
                <FormInput
                  name={`[${record.priceSheetId}][${record.uniqueId}].price`}
                  autoFocus={fileInput && !record.price}
                  size="small"
                  min={0}
                  step=".01"
                  className={classNames({ 'border-visible': !record.price })}
                  handleChange={e => {
                    const reg = /^[0-9]*(.[0-9]*)?$/;
                    if (reg.test(e.target.value)) {
                      const changedRecord = { ...record, price: e.target.value.replace(',', '.') };
                      handleChange({
                        prevStateRecord: record,
                        changedStateRecord: changedRecord,
                      });
                    }
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                  testId="price"
                />
              </div>
            ) : (
              renderSuggestButton(t('pricing:addPrice'), record.uniqueId)
            );
          }}
          width={150}
        />
        <Column
          key={3}
          title={t('pricing:priceUom')}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;

            const existingValueUom = resources.uoms.find(
              (uom: ExtendedResources) => uom.id === record.priceUomId
            );
            return renderSelect(resources.uoms, existingValueUom, record, 'priceUomId');
          }}
          width={150}
          ellipsis
        />
        <Column
          key={4}
          title={t('pricing:currencyCode')}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;

            const existingValueCurrency = resources.currencies.find(
              (currency: ExtendedResources) => currency.id === record.currencyId
            );
            return renderSelect(resources.currencies, existingValueCurrency, record, 'currencyId');
          }}
          width={150}
          ellipsis
        />
        <Column
          title={t('pricing:priceBreak')}
          key={5}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;

            const existingValue = resources.break_quantity_uoms.find(
              (priceBreakUom: ExtendedResources) => priceBreakUom.id === record.priceBreakUomId
            );
            return (
              <div className="flex items-center">
                <div className="w-2/4 pr-2 price-item-table__input">
                  <FormInput
                    name={`[${record.priceSheetId}][${record.uniqueId}].priceBreak`}
                    size="small"
                    type="number"
                    min={0}
                    className={classNames({ 'border-visible': !record.priceBreak })}
                    handleChange={e => {
                      setShowField(record.uniqueId);
                      const changedRecord = { ...record, priceBreak: e.target.value };
                      handleChange({
                        prevStateRecord: record,
                        changedStateRecord: changedRecord,
                      });
                    }}
                    handleOnBlur={() => setShowField(undefined)}
                  />
                </div>
                <div className="w-3/4">
                  {renderSelect(
                    resources.break_quantity_uoms,
                    existingValue,
                    record,
                    'priceBreakUomId'
                  )}
                </div>
              </div>
            );
          }}
          ellipsis
          width={200}
        />
        <Column
          title={t('pricing:effectiveDate')}
          key={6}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;

            return (
              <div
                className={classNames({
                  'price-item-table__date-picker': !!record.effectiveDate,
                })}
              >
                <DatePicker
                  size="small"
                  onChange={(momentDate: any, stringDate: any) => {
                    setShowField(record.uniqueId);
                    const changedRecord = {
                      ...record,
                      effectiveDate: (stringDate.length > 0 && stringDate) || null,
                    };
                    handleChange({
                      prevStateRecord: record,
                      changedStateRecord: changedRecord,
                    });
                  }}
                  value={record.effectiveDate ? moment(record.effectiveDate) : undefined}
                  format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                />
              </div>
            );
          }}
          ellipsis
          width={150}
        />
        <Column
          title={t('pricing:expirationDate')}
          key={7}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;
            return (
              <div
                className={classNames({ 'price-item-table__date-picker': !!record.expirationDate })}
              >
                <DatePicker
                  size="small"
                  onChange={(momentDate: any, stringDate: any) => {
                    setShowField(record.uniqueId);
                    const changedRecord = {
                      ...record,
                      expirationDate: (stringDate.length > 0 && stringDate) || null,
                    };
                    handleChange({
                      prevStateRecord: record,
                      changedStateRecord: changedRecord,
                    });
                  }}
                  value={record.expirationDate ? moment(record.expirationDate) : undefined}
                  format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                />
              </div>
            );
          }}
          ellipsis
          width={150}
        />
        <Column
          title={t('pricing:typeDescription')}
          key={8}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice')) return null;
            if (record.usedTypeIdInOtherItem && !record.price) return null;
            return (
              <div className="flex items-center">
                {record.typeDescription && (
                  <Popover content={record.typeDescription}>
                    <div className="pr-2 w-3/4 truncate"> {record.typeDescription}</div>
                  </Popover>
                )}
                <Popover
                  content={
                    <div className="w-64">
                      <div className="customized">{t('pricing:typeInfoDescription')}</div>
                      <div className="mt-2">
                        <Popconfirm
                          title={t('pricing:confirmSwitchToDefaultValues')}
                          okText={t('common:yes')}
                          cancelText={t('common:no')}
                          onConfirm={() =>
                            navigate(`/brand/settings/default-values?brandId=${brandId}`)
                          }
                        >
                          <Button type="primary" size="small">
                            {t('pricing:defaultValues')}
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="ant-info-tooltip" />
                </Popover>
              </div>
            );
          }}
          width={150}
          ellipsis
        />
        <Column
          key={9}
          render={(record: InitItemPrice) => {
            if (record.name === t('pricing:newItemPrice'))
              return { children: <div />, props: { colSpan: 0 } };

            const handleDeleteItemPrice = () => {
              intercomEvent('viewed-all-product', {
                action: 'item-deleted',
                location: 'deleted_item_price',
                part_number: selectedItem.part_number,
                brand_code: selectedItem.brand_code,
              });

              confirm({
                title: t('pricing:deleteItemPriceInfo'),
                okText: t('common:delete'),
                okButtonProps: { danger: true },
                onOk() {
                  if (record.countId) {
                    handleChange({
                      prevStateRecord: record,
                      recordCountId: record.countId || record.usedTypeIdInOtherItem,
                    });
                  } else dispatch(deletePrice(record.priceSheetId!, record.existingItemPriceid!));
                },
              });
            };
            return (
              <div>
                {record.usedTypeIdInOtherItem !== record.typeId && (
                  <Button
                    size="small"
                    type="ghost"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={handleDeleteItemPrice}
                  >
                    {t('common:delete')}
                  </Button>
                )}
              </div>
            );
          }}
          width={120}
          ellipsis
        />
      </Table>
    </div>
  );
};

export default PriceItemTable;
