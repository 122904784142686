import { combineReducers } from 'redux';
import receivers, { ReceiverListState } from './ReceiverReducer';
import receiverImport, { ReceiverImportState } from './ReceiverImportReducer';

export type ReceiverState = { receivers: ReceiverListState; receiverImport: ReceiverImportState };

const ReceiverReducer = combineReducers<ReceiverState>({
  receivers,
  receiverImport,
});

export default ReceiverReducer;
