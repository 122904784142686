import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { FileExportOption } from '../../../types/import_export';

export type ExportBrandState = {
  fileExportOptions: FileExportOption[];
  fetchingExportOptions: boolean;
};

const initialState = {
  fileExportOptions: [],
  fetchingExportOptions: false,
};

const reducer: Reducer<ExportBrandState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EXPORT_OPTIONS_PENDING': {
      return { ...state, fileExportOptions: [], fetchingExportOptions: true };
    }
    case 'FETCH_EXPORT_OPTIONS_FULFILLED': {
      return { ...state, fileExportOptions: action.payload.data, fetchingExportOptions: false };
    }
  }
  return state;
};

export default reducer;
