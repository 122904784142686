const initialState = {
  isLoading: true,
  digitalAssets: [],
  selectedDigitalAssetId: undefined,
  setExternal: false,
  showParentDigitalAssets: false,
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'FETCH_DIGITAL_ASSET_PARENTS_PENDING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FETCH_DIGITAL_ASSET_PARENTS_FULFILLED': {
      return {
        ...state,
        isLoading: false,
        digitalAssets:
          action.meta.page === 1 ? payload.data : [...state.digitalAssets, ...payload.data],
      };
    }
    case 'SELECT_DIGITAL_ASSET_PARENT': {
      return {
        ...state,
        selectedDigitalAssetId: payload.selectedId,
      };
    }
    case 'SET_DIGITAL_ASSET_PARENT': {
      return {
        ...state,
        digitalAssets: payload.assets,
        isLoading: false,
      };
    }
    case 'SET_DIGITAL_ASSET_PARENT_EXTERNAL': {
      return {
        ...state,
        setExternal: payload.setExternal,
      };
    }
    case 'UPDATE_DIGITAL_ASSET_PARENT_PENDING': {
      return {
        ...state,
        digitalAssets: state.digitalAssets.map(digitalAsset => {
          if (digitalAsset.digital_asset_id === action.meta.digitalAssetId) {
            const newValue = { [action.meta.key]: action.meta.value };
            return { ...digitalAsset, ...newValue };
          }
          return digitalAsset;
        }),
      };
    }
    case 'SHOW_PARENT_DIGITAL_ASSETS': {
      return {
        ...state,
        showParentDigitalAssets: action.meta.display,
      };
    }
  }
  return state;
};

export default reducer;
