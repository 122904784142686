import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchVcdbVersions } from '../../../actions/items/application/fetch';
import VcdbImportExportPage from '../../../components/body/parentSettings/vcdb/VcdbImportExportPage';

const VcdbImportExportContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchVcdbVersions());
  }, [dispatch]);

  return <VcdbImportExportPage />;
};

export default VcdbImportExportContainer;
