import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function resetRedux() {
  return {
    type: 'RESET_REDUX',
  };
}

export function setCurrentVersion(scriptName) {
  return {
    type: 'SET_SCRIPT_NAME',
    payload: { scriptName },
  };
}

export function checkVersionUpdate() {
  const config = {
    headers: { 'Cache-Control': 'No-Cache' },
  };

  return {
    type: 'CHECK_VERSION_UPDATE',
    payload: axios.get('/', config),
  };
}

export function fetchRegistrationResources() {
  return {
    type: 'FETCH_REGISTRATION_RESOURCES',
    payload: axios.get(`${getApiRoot()}/registration_resources`),
  };
}

export function setMenuState(state) {
  return {
    type: 'SET_MENU_STATE',
    payload: { state },
  };
}

export function setInAppArea(area) {
  return {
    type: 'SET_IN_APP_AREA',
    payload: { area },
  };
}
