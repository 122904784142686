import axios from 'axios';

import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export const deleteShopifyStore = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);
  return {
    type: 'DELETE_SHOPIFY_STORE',
    meta: { shopifyId: channelId },
    payload: axios.delete(`${getApiRoot()}/channels${params}`),
  };
};

export const deleteChannel = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);
  return {
    type: 'DELETE_CHANNEL',
    meta: { channelId },
    payload: axios.delete(`${getApiRoot()}/channels${params}`),
  };
};

export const deletePlans = (channelId: number, ids: number[]) => {
  const params = utils.createURL([{ name: 'channel_plan_ids', values: ids }]);

  return {
    type: 'DELETE_PLANS',
    payload: axios.delete(`${getApiRoot()}/channels/plans${params}`),
    meta: { channelId, ids },
  };
};

export const deleteExportFileSettings = (channelId: number, planId: number) => {
  const params = utils.createURL([{ name: 'channel_plan_id', values: planId }]);

  return {
    type: 'DELETE_EXPORT_FILE_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/file_settings${params}`),
    meta: { channelId, planId },
  };
};

export const deleteDefaultExportFileSettings = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);

  return {
    type: 'DELETE_DEFAULT_EXPORT_FILE_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/file_settings${params}`),
    meta: { channelId },
  };
};

export const deleteExportFtpSettings = (channelId: number, planId: number) => {
  const params = utils.createURL([{ name: 'channel_plan_id', values: planId }]);

  return {
    type: 'DELETE_EXPORT_FTP_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/ftp_settings${params}`),
    meta: { channelId, planId },
  };
};

export const deleteDefaultExportFtpSettings = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);

  return {
    type: 'DELETE_DEFAULT_EXPORT_FTP_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/ftp_settings${params}`),
    meta: { channelId },
  };
};

export const deleteSchedule = (channelId: number, planId: number) => {
  const params = utils.createURL([{ name: 'channel_plan_id', values: planId }]);

  return {
    type: 'DELETE_EXPORT_SCHEDULE',
    payload: axios.delete(`${getApiRoot()}/channels/schedule_settings${params}`),
    meta: { channelId, planId },
  };
};

export const deleteDefaultSchedule = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);

  return {
    type: 'DELETE_DEFAULT_EXPORT_SCHEDULE',
    payload: axios.delete(`${getApiRoot()}/channels/schedule_settings${params}`),
    meta: { channelId },
  };
};

export const deleteIntegratedChannelDefaultSettings = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);

  return {
    type: 'DELETE_DEFAULT_INTEGRATED_CHANNEL_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/integrated_default_settings${params}`),
    meta: { channelId },
  };
};

export const deleteIntegratedChannelSettings = (channelId: number, planId: number) => {
  const params = utils.createURL([{ name: 'channel_plan_id', values: planId }]);

  return {
    type: 'DELETE_INTEGRATED_CHANNEL_SETTINGS',
    payload: axios.delete(`${getApiRoot()}/channels/integrated_default_settings${params}`),
    meta: { channelId, planId },
  };
};

export const deleteNamingConventions = (templateIds: number[]) => {
  const params = utils.createURL([{ name: 'file_name_template_ids', values: templateIds }]);

  return {
    type: 'DELETE_NAMING_CONVENTIONS',
    payload: axios.delete(`${getApiRoot()}/file_name_templates${params}`),
    meta: { templateIds },
  };
};

export default {
  deleteExportFileSettings,
  deleteDefaultExportFileSettings,
  deleteExportFtpSettings,
  deleteDefaultExportFtpSettings,
  deletePlans,
  deleteChannel,
  deleteSchedule,
  deleteDefaultSchedule,
  deleteIntegratedChannelDefaultSettings,
  deleteIntegratedChannelSettings,
  deleteNamingConventions,
};
