import { Reducer } from 'redux';
import {
  UserType,
  PermissionGroup,
  UserPermissionRole,
  ListUser,
  UserName,
} from '../../../types/user';
import { getRoles } from '../../utils/UserUtils';
import { hasPermission } from '../../utils/Permissions';

export type UserState = {
  fetchingUser: boolean;
  error?: any;
  user?: UserType;
  updateSettingsPending: boolean;
  userList: ListUser[];
  userNameList: UserName[];
  permissionGroups: PermissionGroup[];
  roles: UserPermissionRole[];
};

const initialState = {
  fetchingUser: false,
  error: null,
  user: undefined,
  updateSettingsPending: false,
  userList: [],
  userNameList: [],
  permissionGroups: [],
  roles: [],
};

const reducer: Reducer<UserState> = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCH_USERS_FULFILLED': {
      const userList = action.meta.userIds
        ? [...state.userList, ...action.payload.data]
        : action.payload.data;
      return { ...state, userList };
    }
    case 'FETCH_USER_NAMES_FULFILLED': {
      return { ...state, userNameList: action.payload.data };
    }
    case 'FETCH_PERMISSIONS_FULFILLED': {
      const permissionGroups = action.payload.data.groups;
      return { ...state, permissionGroups };
    }
    case 'FETCH_ROLES_FULFILLED': {
      const roles = action.payload.data.roles;
      return { ...state, roles };
    }
    case 'LOAD_USER_BY_TOKEN_PENDING': {
      return { ...state, fetchingUser: true };
    }
    case 'LOAD_USER_BY_TOKEN_REJECTED': {
      return { ...state, fetchingUser: false };
    }
    case 'LOGIN_PENDING': {
      return { ...state, error: null };
    }
    case 'LOGIN_REJECTED': {
      return { ...state, error: action.payload.response };
    }
    case 'LOAD_USER_BY_TOKEN_FULFILLED':
    case 'LOGIN_FULFILLED': {
      const user = {
        ...action.payload.data,
        roles: getRoles(action.payload.data),
      };
      return {
        ...state,
        fetchingUser: false,
        error: null,
        user:
          process.env.REACT_APP_STAGE === 'production' &&
          (hasPermission(user, 'running_on_go_api') ||
            hasPermission(user, 'running_on_go_only_api')) &&
          !action.meta.apiRedirect
            ? undefined
            : user,
      };
    }
    case 'LOGOUT_REJECTED': {
      return { ...state, error: action.payload.response };
    }
    case 'LOGOUT_PENDING': {
      return { ...state, error: null, user: undefined };
    }
    case 'CREATE_USER_FULFILLED': {
      const user = {
        ...action.payload.data,
        user_role_ids: action.payload.data.user_role_ids || [],
        brand_permissions: action.payload.data.brand_permissions || [],
      };
      return { ...state, userList: [...state.userList, user] };
    }
    case 'REMOVE_USER': {
      return { ...state, user: undefined };
    }
    case 'UPDATE_ACCOUNT_INFORMATIONS_PENDING': {
      return { ...state, updateSettingsPending: true };
    }
    case 'UPDATE_ACCOUNT_INFORMATIONS_REJECTED': {
      return { ...state, updateSettingsPending: false };
    }
    case 'UPDATE_ACCOUNT_INFORMATIONS_FULFILLED': {
      const user = {
        ...action.payload.data,
        permissions: state.user ? state.user.permissions : null,
        roles: state.user ? state.user.roles : [],
      };
      return { ...state, user, updateSettingsPending: false };
    }
    case 'UPDATE_USER_STATE_FULFILLED': {
      const updatedUser = action.payload.data;
      const userList = state.userList.map(user =>
        // TODO: We can't just use the user object from the backend, as it is missing fields (e.g. brand_permissions).
        user.id === updatedUser.id ? { ...user, active: updatedUser.active } : user
      );
      return { ...state, userList };
    }
    case 'UPDATE_USER_FULFILLED': {
      const updatedUser = action.payload.data;
      const userList = state.userList.map(user =>
        user.id === updatedUser.id ? updatedUser : user
      );
      return { ...state, userList };
    }
    case 'UPDATE_MAPPED_USER_PERMISSIONS_FULFILLED': {
      const userList = action.payload.data;
      return { ...state, userList };
    }
    case 'UPDATE_USER_ROLE_FULFILLED': {
      const roles = action.payload.data.roles;
      return { ...state, roles };
    }
    case 'DELETE_USER_ROLE_FULFILLED': {
      const roles = state.roles.filter(role => role.id !== action.meta.roleId);
      return { ...state, roles };
    }
  }
  return state;
};

export default reducer;
