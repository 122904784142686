import React from 'react';
import SplitPane from 'react-split-pane';
import { Layout, Alert } from 'antd';
import { connect } from 'react-redux';

import ProductsRoutes from '../../routes/brand/ProductsRoutes';
import CatalogueContainer from '../../containers/catalouge/CatalogueContainer';
import BrandProductsSideBar from '../../containers/sidebars/BrandProductsSideBar';
import UpdateManager from '../../containers/catalouge/UpdateManager';
import ConnectedContainerWrapper from '../../containers/BrandRouteWrapper';
import ConnectedReceiverRouteWrapper from '../../containers/ReceiverRouteWrapper';
import catalougeTranslation from '../../constants/CatalogueTranslations.json';

import { ApplicationState } from '../../reducers/index';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { Item } from '../../../types/item';
import { isManufacturer } from '../../utils/UserUtils';
import { Brand } from '../../../types/brand';

const { Content } = Layout;

type ProductsPageProps = {
  inAppArea: string;
  canSeeProductEditArea: boolean;
};

type ProductsPageState = {
  catalogueExtended: boolean;
  catalougeIsResizing: boolean;
  transitionOn: boolean;
};

class ProductsPage extends React.Component<ProductsPageProps, ProductsPageState> {
  state = {
    catalogueExtended: this.props.inAppArea === 'AVAILABLE_BRAND_AREA',
    catalougeIsResizing: false,
    transitionOn: false,
  };

  // TODO: This should be part of the REDUX store so that other components can resize the catalogue without needing this method.
  changeCatalougeHeight = () => {
    this.setState(prevState => ({
      catalogueExtended: !prevState.catalogueExtended,
      transitionOn: true,
    }));
    setTimeout(() => {
      this.setState({ transitionOn: false });
    }, 600);
  };

  render() {
    const { catalogueExtended, transitionOn } = this.state;
    let size: any = 205;
    if (catalogueExtended) size = '100vh';
    return (
      <Layout className="products-page">
        <SplitPane
          split="horizontal"
          minSize={125}
          maxSize={500}
          onDragStarted={() => this.setState({ catalougeIsResizing: true })}
          onDragFinished={() => this.setState({ catalougeIsResizing: false })}
          size={size}
          pane1Style={{
            transition: transitionOn ? 'height 0.3s ease-in' : '',
          }}
          pane2Style={{
            overflow: 'hidden',
          }}
          resizerStyle={{
            display: catalogueExtended ? 'none' : '',
          }}
        >
          <CatalogueContainer
            changeCatalougeHeight={this.changeCatalougeHeight}
            catalogueExtended={this.state.catalogueExtended}
            catalougeIsResizing={this.state.catalougeIsResizing}
          />
          <Layout className="products-edit-outer">
            <BrandProductsSideBar />
            <Content className="brand-products-content">
              {this.props.canSeeProductEditArea ? (
                <ProductsRoutes />
              ) : (
                <Alert
                  style={{ height: 85, width: '100%' }}
                  showIcon
                  type="warning"
                  message={catalougeTranslation.noAccess}
                  description={catalougeTranslation.detailedPreview}
                />
              )}
            </Content>
          </Layout>
        </SplitPane>
        <UpdateManager />
      </Layout>
    );
  }
}

const withProductsPageWrapper = (Component: any) => (props: any) => {
  if (props.inAppArea === 'AVAILABLE_BRAND_AREA') {
    return (
      <ConnectedReceiverRouteWrapper>
        <Component {...props} />
      </ConnectedReceiverRouteWrapper>
    );
  }
  return (
    <ConnectedContainerWrapper>
      <Component {...props} />
    </ConnectedContainerWrapper>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  let canSeeProductEditArea = false;
  const user = state.user.user;
  if (user && isManufacturer(user)) {
    canSeeProductEditArea = true;
  } else {
    const allBrands = state.receiverDataStream.authorizedBrands;
    const selectedItems: Item[] = getSelectedItems(state);
    if (selectedItems.length > 0) {
      canSeeProductEditArea = !!(
        selectedItems.length &&
        allBrands.find((brand: Brand) => brand.id === selectedItems[0].parent_owner_brand_id)
      );
    } else {
      canSeeProductEditArea = true;
    }
  }
  return {
    inAppArea: state.app.global.inAppArea,
    canSeeProductEditArea,
  };
};

export default connect(mapStateToProps)(withProductsPageWrapper(ProductsPage));
