import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteApplicationFilter(id) {
  return {
    type: 'DELETE_APPLICATION_FILTER',
    payload: axios.delete(`${getApiRoot()}/filters/applications/${id}`),
  };
}

export function deleteCategoryFilter(id) {
  return {
    type: 'DELETE_CATEGORY_FILTER',
    payload: axios.delete(`${getApiRoot()}/filters/categories/${id}`),
  };
}

export function deleteAnalysisFilter(id) {
  return {
    type: 'DELETE_ANALYSIS_FILTER',
    payload: axios.delete(`${getApiRoot()}/filters/analyses/${id}`),
  };
}

export function deleteCustomFilter(id) {
  return {
    type: 'DELETE_CUSTOM_FILTER',
    payload: axios.delete(`${getApiRoot()}/filters/customs/${id}`),
  };
}

export function deleteFilterCategoryParameter(id) {
  return {
    type: 'DELETE_CATEGORY_FILTER_PARAMETER',
    payload: axios.delete(`${getApiRoot()}/filters/categories/parameters/${id}`),
  };
}

export function deleteFilterApplicationParameter(id) {
  return {
    type: 'DELETE_APPLICATION_FILTER_PARAMETER',
    payload: axios.delete(`${getApiRoot()}/filters/applications/parameters/${id}`),
  };
}

export function deleteFilterAnalysisParameter(id) {
  return {
    type: 'DELETE_ANALYSIS_FILTER_PARAMETER',
    payload: axios.delete(`${getApiRoot()}/filters/analyses/parameters/${id}`),
  };
}

export function deleteFilterCustomParameter(id) {
  return {
    type: 'DELETE_CUSTOM_FILTER_PARAMETER',
    payload: axios.delete(`${getApiRoot()}/filters/customs/parameters/${id}`),
  };
}

export function deleteFilter(filterId) {
  return {
    type: 'DELETE_FILTER',
    meta: { filterId },
    payload: axios.delete(`${getApiRoot()}/filters/${filterId}`),
  };
}
