import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';

type InputWithPreviewProps = {
  onChange: (e: any) => void;
  onBlur?: () => void;
  submit?: (e: any) => void;
  value: string;
  showInput?: boolean;
  maxLength?: number | undefined;
  type?: string | undefined;
  size?: any;
  min?: number;
  placeholder?: string;
  displayError?: boolean;
  showCount?: boolean;
};

const InputWithPreview: React.FC<InputWithPreviewProps> = ({
  onChange,
  value,
  showInput,
  submit,
  size,
  min,
  ...props
}) => {
  const [isEditAble, setIsEditAble] = React.useState(showInput || !value || false);

  React.useEffect(() => {
    if (!value) {
      setIsEditAble(true);
    }
  }, [value]);

  return isEditAble ? (
    <Input
      placeholder={props.placeholder}
      size={size || 'default'}
      min={min}
      type={props.type}
      autoFocus={!!value}
      value={value}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          const elementTarget = e.target as HTMLFormElement;
          if (submit) {
            submit(elementTarget.value);
          }
          if (elementTarget.value) {
            setIsEditAble(false);
          }
        }
      }}
      onBlur={e => {
        if (submit) {
          submit(e.target.value);
        }
        if (e.target.value) {
          setIsEditAble(false);
        }
        if (props.onBlur) props.onBlur();
      }}
      onChange={onChange}
      maxLength={props.maxLength}
      suffix={
        props.maxLength && (
          <span className="input-with-preview__suffix">{`${value.length}/${props.maxLength}`}</span>
        )
      }
      showCount={props.showCount && value.length > 0}
    />
  ) : (
    <span
      onClick={() => setIsEditAble(true)}
      className={classNames('input-with-preview', {
        'input-with-preview__error': props.displayError,
      })}
    >
      {value}
    </span>
  );
};

export default InputWithPreview;
