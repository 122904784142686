import React, { ReactNode } from 'react';
import { Input, Form, Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Select, { SelectValue } from 'antd/lib/select';
import MultiEditButton from './MultiEditButton';

type PackageDimensionInputsProps = {
  label?: ReactNode;
  inputNames: string[];
  heightValue: string | null;
  widthValue: string | null;
  lengthValue: string | null;
  dimensionUomValue: number | null;
  options: {
    id: string | number;
    name: string | number;
  }[];
  borderInvisible?: boolean;
  disabled?: boolean;
  inputChange: (value: string, name: string) => void;
  selectChange: (value: SelectValue) => void;
  handleDrawerVisibility?: (drawerVisible: boolean) => void;
  multipleValueField?: boolean;
  showMultiEditIcon?: boolean;
  handleOnBlur?: () => void;
  className?: string;
};

const PackageDimensionInputs: React.FC<PackageDimensionInputsProps> = ({
  label,
  inputNames,
  options,
  borderInvisible,
  disabled,
  multipleValueField,
  heightValue,
  widthValue,
  lengthValue,
  dimensionUomValue,
  inputChange,
  selectChange,
  handleDrawerVisibility,
  showMultiEditIcon,
  handleOnBlur,
  className,
}) => {
  const { t } = useTranslation();

  const renderXInput = () => (
    <Input
      className="package__dimension-input-split"
      style={{
        width: 20,
        borderLeft: 0,
        borderRight: 0,
        pointerEvents: 'none',
        paddingLeft: 6,
      }}
      placeholder="x"
      disabled
    />
  );

  const renderMultiValueEditLink = () => (
    <div className="flex items-center flex-1">
      <Button
        type="link"
        onClick={() => handleDrawerVisibility!(true)}
        style={{ padding: 0 }}
        disabled={disabled}
        data-testid="multiple-values"
      >
        {t('packageSeg:multipleValues')}
      </Button>
    </div>
  );

  const renderDimensionFields = () => (
    <div
      className={classNames(
        `${className} package__input-group-wrapper package__input-group-background`,
        {
          'package-border-transparent': borderInvisible,
        }
      )}
    >
      <Input.Group compact size="small">
        <Input
          className={classNames({ 'package__borderless-input': borderInvisible })}
          disabled={disabled}
          style={{ width: 60 }}
          value={heightValue || ''}
          onChange={e => {
            const value = e.target.value;
            inputChange(value, inputNames[0]);
          }}
          size="small"
          onBlur={handleOnBlur}
        />
        {renderXInput()}
        <Input
          disabled={disabled}
          size="small"
          className={classNames('site-input-right', {
            'package__borderless-input': borderInvisible,
          })}
          style={{ width: 60 }}
          value={widthValue || ''}
          onChange={e => {
            const value = e.target.value;
            inputChange(value, inputNames[1]);
          }}
          onBlur={handleOnBlur}
        />
        {renderXInput()}
        <Input
          disabled={disabled}
          size="small"
          className={classNames('site-input-right', {
            'package__borderless-input': borderInvisible,
          })}
          style={{ width: 60 }}
          value={lengthValue || ''}
          onChange={e => {
            const value = e.target.value;
            inputChange(value, inputNames[2]);
          }}
          onBlur={handleOnBlur}
        />
        <Select
          className={classNames('package__dimension-select', {
            'package__borderless-select': borderInvisible,
          })}
          value={dimensionUomValue || undefined}
          size="small"
          allowClear
          showSearch
          onChange={(value: SelectValue) => selectChange(value)}
          disabled={disabled}
          onBlur={handleOnBlur}
        >
          {options.map(uom => (
            <Select.Option key={uom.id} value={uom.id}>
              {uom.name}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>
    </div>
  );

  return (
    <Form.Item label={label}>
      {!multipleValueField ? (
        <div className="flex flex-row">
          {renderDimensionFields()}
          {showMultiEditIcon && (
            <MultiEditButton handleOnClick={() => handleDrawerVisibility!(true)} />
          )}
        </div>
      ) : (
        multipleValueField && renderMultiValueEditLink()
      )}
    </Form.Item>
  );
};

export default PackageDimensionInputs;
