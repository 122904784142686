import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import constants from '../constants/RefreshPageTranslation.json';

export default class RefreshPage extends React.Component {
  isVisible = () => (this.props.newVersion ? 'refresh-page_visible' : '');

  render() {
    return (
      <div className={`refresh-page ${this.isVisible()}`}>
        <span>{constants.page_outdated}</span>

        <span className="refresh-page__link" onClick={() => window.location.reload(true)}>
          <ReloadOutlined className="mx-1" />
          <span>{constants.refresh}</span>
        </span>
      </div>
    );
  }
}
