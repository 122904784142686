import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

class AddNewButton extends React.Component {
  render() {
    const { name, toggle, className } = this.props;
    let cssName = `add-new-button ${this.props.size}`;
    if (className) {
      cssName += ` ${className}`;
    }
    return (
      <div className={cssName} onClick={toggle}>
        <PlusCircleOutlined className="mr-1" />
        <span className="add-new-button__text">{name}</span>
      </div>
    );
  }
}

AddNewButton.propTypes = {
  // two size types main and sub
  size: PropTypes.string,
  // this is the name what will be displayed in the button
  name: PropTypes.string.isRequired,
  toggle: PropTypes.func,
};

AddNewButton.defaultProps = {
  size: 'main',
  toggle: () => {},
};

export default AddNewButton;
