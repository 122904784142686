import React, { Dispatch } from 'react';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { updateFormDirtyState } from '../../../actions/channel/update';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type ChannelFormDirtyHandlerProps = {
  dispatch: Dispatch<AnyAction>;
};

class ChannelFormDirtyHandler extends React.Component<ChannelFormDirtyHandlerProps & FormikProps> {
  componentDidUpdate(prevProps: ChannelFormDirtyHandlerProps & FormikProps) {
    const { dirty } = this.props.formik;
    const { dirty: prevDirty } = prevProps.formik;
    if (prevDirty !== dirty) {
      this.props.dispatch(updateFormDirtyState(dirty));
    }
  }

  render() {
    return null;
  }
}

export default connect()(
  formikConnect<ChannelFormDirtyHandlerProps, FormikValues>(ChannelFormDirtyHandler)
);
