import React from 'react';
import constants from '../../../constants/AnalyseTranslation.json';

class AnalyseLatestChanges extends React.Component {
  render() {
    const { items } = this.props;
    return (
      <div className="analyse-latest-changes analyse__border">
        <p className="title home-container__analyse-title ">{constants.latest_product_changes}</p>
        <div
          className="analyse-latest-product-changes__table"
          onScroll={e => this.props.fetchNextItems(e)}
        >
          {items &&
            items.map(item => (
              <div key={item.id} className="row items__row">
                <span className="item__part-number item__inner">{item.part_number} </span>
                <span className="item__row-name item__inner">{item.short_name}</span>
              </div>
            ))}
          {this.props.fetching && <div className="loader" />}
        </div>
      </div>
    );
  }
}

export default AnalyseLatestChanges;
