import React, { memo } from 'react';
import classNames from 'classnames';
import { Tag, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { EditOutlined, StarTwoTone, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import pdmLogo from '../../../../images/logos/PDM-Brandmark-Blue.svg';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType, ExtendedResources } from '../../../../types/resources';
import { Attribute } from '../../../../types/attributes';
import { SelectedListItem } from '../../../../types/item';
import AttributeNamePopover from './AttributeNamePopover';
import { intercomEvent } from '../../../utils/IntercomUtils';

type AttributeNameCellProps = {
  attributeId: number | string;
  rowData: Attribute;
  analyses: (Analysis & AnalysisType)[];
  selectedItems: SelectedListItem[];
  selected: boolean;
  handleRowSelection?: (attributeId: number | string, e: any) => void;
};

const AttributeNameCell: React.FC<AttributeNameCellProps> = ({
  attributeId,
  rowData,
  analyses,
  selectedItems,
  selected,
  handleRowSelection,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  // todo: move to parent
  const handleEditAttributeName = (attributeName: string) => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'attribute_edit_name',
      part_number: selectedItems.map(i => i.part_number).toString(),
      brand_code: selectedItems[0]?.brand_code,
    });

    const updatedAttributes = values.attributes.map(
      (a: Attribute & { renamedAttributeId?: number }) =>
        a.uniqueId === rowData.uniqueId
          ? {
              ...rowData,
              id: null,
              name: attributeName,
              temp: true,
              renamedAttributeId: a.id || a.renamedAttributeId,
            }
          : a
    );
    setFieldValue('attributes', updatedAttributes);
  };

  const recommendationText = (rank: number | null, pdmRecommendation: number) => {
    return rank === 1
      ? t('attributes:recommendedByAutocare')
      : (pdmRecommendation > 0 && t('attributes:recommendedByPdm')) ||
          (rank === 2 && t('attributes:previouslyUsedInfo'));
  };

  const attributeAnalyses: (Analysis & AnalysisType)[] =
    analyses.filter(e => e.item_part_attribute?.part_attribute_id === rowData.id) || [];

  const alertTypeId = attributeAnalyses.length
    ? Math.max(...attributeAnalyses.map(e => e.alert_type_id))
    : undefined;

  const errorTooltip = (errors: (Analysis & AnalysisType)[]) => {
    const errorDescriptions = [...new Set(errors.map(e => e.description))];
    return errorDescriptions.map(e => <div>{e}</div>);
  };

  return (
    <div
      className={classNames('attributes__name-cell flex items-center', {
        'attributes__selected-cell': selected,
      })}
      onClick={e => !!handleRowSelection && handleRowSelection(attributeId, e)}
    >
      <Tooltip
        title={recommendationText(rowData.rank, rowData.pdm_recommendation)}
        placement="left"
      >
        <div
          className={classNames('attributes__name-cell_border', {
            'green-border': rowData.rank === 1,
            'blue-border': rowData.rank === 2 || rowData.pdm_recommendation === 1,
          })}
        />
      </Tooltip>

      <div className="flex-1 pr-2 pl-2">{rowData.name}</div>
      {rowData.ranked_values.find(rv => rv.item_ids.length > 0) && (
        <AttributeNamePopover
          attributeName={rowData.name}
          handleChange={handleEditAttributeName}
          translations={rowData.translations}
        >
          <EditOutlined className="edit-button" />
        </AttributeNamePopover>
      )}

      {rowData.pdm_recommendation === 1 && (
        <img src={pdmLogo} className="pr-1 h-5" alt="pdm-logo" />
      )}
      {attributeAnalyses.length > 0 && (
        <Tooltip placement="leftTop" title={errorTooltip(attributeAnalyses)}>
          <WarningOutlined
            className={classNames('attributes__analysis-message mr-1', {
              blue: alertTypeId === 1,
              yellow: alertTypeId === 2,
              red: alertTypeId === 3,
            })}
          />
        </Tooltip>
      )}
      {rowData.receivers.length > 0 && (
        <Tooltip
          title={
            <React.Fragment>
              <div>{t('attributes:receiversRequest')}</div>
              {rowData.receivers.map((receiver: ExtendedResources) => (
                <div key={receiver.id}>{receiver.name}</div>
              ))}
            </React.Fragment>
          }
        >
          <StarTwoTone className="attributes-star" />
        </Tooltip>
      )}
      {rowData.used_by_brand !== 0 && (
        <Tooltip title={t('attributes:previouslyUsedInfo')}>
          <Tag className="self-center">{rowData.used_by_brand}</Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default memo(AttributeNameCell);
