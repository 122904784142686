import React from 'react';
import { connect } from 'react-redux';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withErrorBoundary } from '../../components/global/ErrorBoundary';
import actions from '../../actions/items/application';
import * as utils from '../../utils/Utils';
import { StandardResource, Vehicle } from '../../../types/resources';
import { Application, ApplicationNote, ListQualifier } from '../../../types/application';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import ApplicationQualifiers from '../../components/body/application/ApplicationQualifiers';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type QualifierContainerProps = {
  dispatch: AsyncDispatch;
  application: Application;
  brandId: number;
  selectedApplicationId: number;
  resources: Vehicle;
  qualifierMetaUoms: StandardResource[];
  qualifiers: ListQualifier[];
  fetchingQualifiers: boolean;
} & WithTranslation;

class QualifierContainer extends React.Component<QualifierContainerProps & FormikProps> {
  componentDidMount() {
    this.fetchQualifierData();
  }

  fetchQualifierData = () => {
    this.props.dispatch(actions.fetchQualifiers(this.props.application.item_id));
  };

  fetchQualifiers = (keywords?: string, typeId?: number, includesValues?: boolean) => {
    const { application } = this.props;
    this.props.dispatch(
      actions.fetchQualifiers(application.item_id, keywords, typeId, includesValues)
    );
  };

  fetchNextQualifiers = (
    event: any,
    keywords?: string,
    typeId?: number,
    includesValues?: boolean
  ) => {
    const { application, fetchingQualifiers } = this.props;
    const nextPage = utils.nextPage(event, undefined, this.props.qualifiers.length);
    if (nextPage && !fetchingQualifiers) {
      this.props.dispatch(
        actions.fetchQualifiers(application.item_id, keywords, typeId, includesValues, nextPage)
      );
    }
  };

  render() {
    const { t, application, qualifiers, formik } = this.props;
    const { setFieldValue } = formik;
    const applicationQualifiers = application.qualifiers || [];

    const addNote = (noteValue?: string) => {
      const recordNumber = application.notes?.length
        ? Math.max(...application.notes.map((n: ApplicationNote) => n.record_number))
        : 1;
      const notes = [
        ...(application.notes || []),
        { record_number: (recordNumber || 0) + 1, note: noteValue || '' },
      ];
      setFieldValue('application', { ...application, notes });
    };

    const clearTempState = () => {
      const updated = applicationQualifiers.map(q => ({
        ...q,
        temp: false,
      }));
      setFieldValue('application', { ...application, qualifiers: updated });
    };

    return (
      <div className="application__qualifiers">
        <div>{t('application:qualifier.tableTitle')}</div>
        <div className="flex-1 flex flex-col overflow-hidden">
          <ApplicationQualifiers
            qualifiers={this.props.qualifiers}
            qualifierTypes={this.props.resources.qualifier_types}
            qualifierMetaUoms={this.props.qualifierMetaUoms}
            selectedQualifiers={applicationQualifiers}
            notes={this.props.application.notes}
            category={this.props.application.category}
            fetching={this.props.fetchingQualifiers}
            fetchQualifiers={(keywords: string, typeId?: number, includesValues?: boolean) => {
              this.fetchQualifiers(keywords, typeId, includesValues);
              clearTempState();
            }}
            fetchNextQualifiers={this.fetchNextQualifiers}
            updateQualifiers={(qualifierId: number, qValues: any = [], recordNumber?: number) => {
              const existingQualifier = applicationQualifiers.find(
                q => q.qualifier_id === qualifierId && q.record_number === recordNumber
              );
              let qualifier: any;
              if (!existingQualifier) {
                const listQualifier = qualifiers.find(q => q.id === qualifierId);
                qualifier = {
                  ...listQualifier,
                  temp: true,
                  values: qValues,
                  qualifier_id: listQualifier!.id,
                };
              } else {
                qualifier = { ...existingQualifier, values: qValues };
              }
              const updatetQualifiers = existingQualifier
                ? applicationQualifiers.map(q =>
                    q.qualifier_id === qualifier?.qualifier_id && q.record_number === recordNumber
                      ? qualifier
                      : q
                  )
                : [...applicationQualifiers, qualifier];
              setFieldValue('application', { ...application, qualifiers: updatetQualifiers });
            }}
            duplicateQualifier={(qualifierId: number) => {
              let index = 0;
              let recordNumber = 1;
              applicationQualifiers.forEach((qualifier, i: number) => {
                if (qualifier.qualifier_id === qualifierId) {
                  index = i;
                  recordNumber = qualifier.record_number || 1;
                }
              });
              const applicationQualifier = applicationQualifiers.find(
                q => q.qualifier_id === qualifierId
              );

              const updatetQualifiers = [...applicationQualifiers];
              updatetQualifiers.splice(index + 1, 0, {
                ...applicationQualifier!,
                values: [],
                record_number: recordNumber + 1,
              });
              setFieldValue('application', { ...application, qualifiers: updatetQualifiers });
            }}
            removeUsedQualifiers={(id: number, recordNumber?: number) => {
              const qualifiers = applicationQualifiers.filter(
                q => !(q.qualifier_id === id && q.record_number === recordNumber)
              );
              setFieldValue('application', { ...application, qualifiers });
            }}
            addNote={addNote}
            removeNote={(recordNumber: number) => {
              const notes = application.notes!.filter(
                (n: ApplicationNote) => n.record_number !== recordNumber
              );
              setFieldValue('application', { ...application, notes });
            }}
            updateNote={(recordNumber: number, noteValue: string) => {
              const note = application.notes!.find(
                (n: ApplicationNote) => n.record_number === recordNumber
              );
              const notes = note
                ? application.notes!.map((n: ApplicationNote) =>
                    n.record_number === recordNumber ? { ...n, note: noteValue } : n
                  )
                : [...application.notes!, { record_number: recordNumber, note: noteValue }];
              setFieldValue('application', { ...application, notes });
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    brandId: state.parent.brands.selectedBrandId,
    selectedApplicationId: state.items.application.selectedApplicationId,
    resources: state.resources.data.vehicle,
    qualifierMetaUoms: state.resources.data.application.qualifier_meta_uoms,
    qualifiers: state.items.application.qualifiers,
    fetchingQualifiers: state.items.application.fetchingQualifiers,
  };
}

export { QualifierContainer };
export default connect(mapStateToProps)(
  formikConnect<FormikValues>(withErrorBoundary(withTranslation()(QualifierContainer)))
);
