import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AsyncDispatch } from '../../../../types/global';
import { fetchSdcMissingPlanReport } from '../../../actions/receiver_data_stream/externalSources/fetch';
import { localDateTimeTimeZoneString } from '../../../utils/Utils';

type ExternalSourceInfoProps = {
  authorizedBrandCount: number;
  availableBrandCount: number;
  lastConnection: null | string;
  showSupplier?: boolean;
  showBrandReportDownload?: boolean;
};

const ExternalSourceInfo: React.FC<ExternalSourceInfoProps> = ({
  authorizedBrandCount,
  availableBrandCount,
  lastConnection,
  showSupplier,
  showBrandReportDownload,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const handleReportDownload = () =>
    // @ts-ignore
    dispatch(fetchSdcMissingPlanReport());

  return (
    <div>
      <div className="font-medium ant-text-black mt-4">{t('externalSources:connectionInfo')}</div>
      <div className="sources__connection-info-box mt-2 w-1/2">
        <div className="flex sources__connection-info-row">
          <div className="sources__connection-info-label">
            {t('externalSources:lastConnection')}
          </div>
          {localDateTimeTimeZoneString(lastConnection)}
        </div>
        <div className="flex sources__connection-info-row">
          <div className="sources__connection-info-label">
            {showSupplier
              ? t('externalSources:totalSuppliersAvailable')
              : t('externalSources:totalBrandsAvailable')}
          </div>
          {availableBrandCount}
        </div>
        <div className="flex sources__connection-info-row">
          <div className="sources__connection-info-label">
            {showSupplier
              ? t('externalSources:totalSuppliersAccessable')
              : t('externalSources:totalBrandsAccessable')}
          </div>
          {showBrandReportDownload ? (
            <Button
              onClick={handleReportDownload}
              style={{ paddingLeft: 0, paddingRight: 0, alignSelf: 'center' }}
              type="text"
            >
              {authorizedBrandCount}
            </Button>
          ) : (
            authorizedBrandCount
          )}
        </div>
      </div>
    </div>
  );
};

export default ExternalSourceInfo;
