import React from 'react';
import classNames from 'classnames';
import constants from '../../../constants/ApplicationTranslation.json';
import { createYearRanges, stringifyYearRanges } from '../../../utils/ApplicationUtils';
import { RankedApplications } from '../../../../types/application';

type ApplicationDetailsMenuHeadProps = {
  rankedApplications: RankedApplications;
  selectedYears: number[];
  selectSubconfig: (subConfig: string | null) => void;
};

export default class ApplicationDetailsMenuHead extends React.Component<ApplicationDetailsMenuHeadProps> {
  applicationMakeModelYear = () => {
    const { rankedApplications } = this.props;

    const selectedMakes = rankedApplications.makes
      ? rankedApplications.makes.filter(make => make.mapped)
      : [];
    const selectedModels = rankedApplications.models
      ? rankedApplications.models.filter(model => model.mapped)
      : [];
    const selectedSubmodels = rankedApplications.sub_models
      ? rankedApplications.sub_models.filter(subModel => subModel.mapped)
      : [];
    const selectedRegions = rankedApplications.regions
      ? rankedApplications.regions.filter(region => region.mapped)
      : [];

    // bring output in right format: [YearFrom] - [YearTo] [Make] [Model] [SubModel] ([Region])
    const makes = selectedMakes ? selectedMakes.map(make => make.name).join('/') : '';
    const models = selectedModels ? selectedModels.map(model => model.name).join('/') : '';
    const submodels = selectedSubmodels
      ? selectedSubmodels.map(submodel => submodel.name).join('/')
      : '';
    const regions =
      selectedRegions.length > 0 ? `(${selectedRegions.map(region => region.name).join('/')})` : '';
    const years = stringifyYearRanges(createYearRanges(this.props.selectedYears));

    return (
      <div
        className="title application__details_title application__config-row"
        onClick={() => this.props.selectSubconfig('vehicle_bases')}
      >
        <div className={classNames('application__details_title_text', { 'text-gray': !makes })}>
          {makes ? `${years} ${makes} ${models} ${submodels} ${regions}` : constants.noMake}
        </div>
      </div>
    );
  };

  applicationEquipment = () => {
    const { rankedApplications } = this.props;

    const selectedMfrs = rankedApplications.mfrs
      ? rankedApplications.mfrs.filter(mfr => mfr.mapped)
      : [];
    const selectedModels = rankedApplications.equipment_models
      ? rankedApplications.equipment_models.filter(model => model.mapped)
      : [];
    const selectedVehicleTypes = rankedApplications.vehicle_types
      ? rankedApplications.vehicle_types.filter(type => type.mapped)
      : [];
    const selectedRegions = rankedApplications.regions
      ? rankedApplications.regions.filter(region => region.mapped)
      : [];

    // bring output in right format: [YearFrom] - [YearTo] [mfr] [Model] [type] ([Region])
    const mfrs = selectedMfrs ? selectedMfrs.map(mfr => mfr.name).join('/') : '';
    const models = selectedModels ? selectedModels.map(model => model.name).join('/') : '';
    const vehicleTypes = selectedVehicleTypes
      ? selectedVehicleTypes.map(type => type.name).join('/')
      : '';
    const regions =
      selectedRegions.length > 0 ? `(${selectedRegions.map(region => region.name).join('/')})` : '';
    const years = stringifyYearRanges(createYearRanges(this.props.selectedYears));

    return (
      <div
        className="title application__details_title application__config-row"
        onClick={() => this.props.selectSubconfig('vehicle_bases')}
      >
        <div className="application__details_title_text">
          {`${years} ${mfrs} ${models} ${vehicleTypes} ${regions}`}
        </div>
      </div>
    );
  };

  render() {
    const { rankedApplications } = this.props;
    const equipmentApplication = Object.keys(rankedApplications).find(key =>
      ['mfrs', 'equipment_models'].includes(key)
    );

    return (
      <div className="application__details-menu-header mb-3">
        {!equipmentApplication && this.applicationMakeModelYear()}
        {equipmentApplication && this.applicationEquipment()}
      </div>
    );
  }
}
