import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../components/global/ErrorBoundary';

class ContainerWrapper extends React.Component {
  render() {
    const { fetchingResources } = this.props;
    return <ErrorBoundary>{fetchingResources ? null : this.props.children}</ErrorBoundary>;
  }
}

const mapStateToProps = state => ({
  fetchingResources: state.resources.fetching,
});

const ConnectedContainerWrapper = connect(mapStateToProps)(ContainerWrapper);

export const withContainerWrapper = Component => props =>
  (
    <ConnectedContainerWrapper>
      <Component {...props} />
    </ConnectedContainerWrapper>
  );

export default ConnectedContainerWrapper;
