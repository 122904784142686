import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { convertConfigNameToIds } from '../../../utils/ApplicationUtils';
import { cancelUpdateFilterRequest } from './update';

const CancelToken = axios.CancelToken;

let fetchFiltersCancelToken;

export function fetchFilters(brandId) {
  if (fetchFiltersCancelToken) fetchFiltersCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_FILTERS',
    payload: axios.get(`${getNonGwApiRoot()}/filters${params}`, {
      cancelToken: new CancelToken(c => {
        fetchFiltersCancelToken = c;
      }),
    }),
  };
}

export function fetchAllAccountFilters() {
  return {
    type: 'FETCH_ALL_ACCOUNT_FILTERS',
    payload: axios.get(`${getApiRoot()}/accounts/filters`),
  };
}

export function fetchAndSelectFilter(filterId) {
  cancelUpdateFilterRequest();

  return {
    type: 'FETCH_FILTER',
    payload: axios.get(`${getNonGwApiRoot()}/filters/${filterId}`),
  };
}

export function fetchFilterGo(filterId) {
  cancelUpdateFilterRequest();

  return {
    type: 'FETCH_FILTER_GO',
    payload: axios.get(`${getNonGwApiRoot()}/v2/filters/${filterId}`),
  };
}

let fetchItemCategoriesCancelToken;
export function fetchFilterItemCategories(brandId, params, page = 1) {
  if (fetchItemCategoriesCancelToken) fetchItemCategoriesCancelToken();

  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'item_id', values: params.itemId },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
    { name: 'category_id', level: '1', values: params.categoryId1 },
    { name: 'category_id', level: '2', values: params.categoryId2 },
    { name: 'keywords', level: '0', values: utils.stringToArray(params.keywords0 || '') },
    { name: 'keywords', level: '1', values: utils.stringToArray(params.keywords1 || '') },
    { name: 'keywords', level: '2', values: utils.stringToArray(params.keywords2 || '') },
    { name: 'keywords', level: '3', values: utils.stringToArray(params.keywords3 || '') },
  ]);

  return {
    meta: { page, activeLevel: params.activeLevel },
    type: 'FETCH_FILTER_ITEM_CATEGORIES',
    payload: axios.get(`${getNonGwApiRoot()}/categories/v2${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchItemCategoriesCancelToken = c;
      }),
    }),
  };
}

export function fetchRankedApplicationsByFilter(applicationConfigs, versionIds, keywords) {
  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  const keys = Object.keys(applicationConfigs);
  const subConfigs = {};
  keys.forEach(key => {
    const configObj = applicationConfigs[key];
    const configIds = Object.keys(configObj).map(key => configObj[key].resource_id);

    subConfigs[convertConfigNameToIds(key)] = configIds;
  });

  return {
    type: 'FETCH_RANKED_APPLICATIONS_FOR_FILTER',
    payload: axios.post(`${getNonGwApiRoot()}/v2/applications/ranks${urlParams}`, {
      sub_configs: subConfigs,
    }),
  };
}

export function fetchApplicationMakes(brandId, versionIds, keywords = '') {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_FILTER_MAKES',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/makes${urlParams}`),
  };
}

export function fetchApplicationModels(brandId, versionIds, makeID = '', keywords = '') {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'make_id', values: makeID },
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_FILTER_MODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/models${urlParams}`),
  };
}

export function fetchApplicationYears(makeId, modelId) {
  const urlParams = utils.createURL([
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_FILTER_YEARS',
    payload: axios.get(`${getNonGwApiRoot()}/vehicles/bases/years${urlParams}`),
  };
}

export function fetchApplicationSubmodels(brandId, versionIds, makeId, modelId, keywords) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_FILTER_SUBMODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/sub_models${urlParams}`),
  };
}

export function fetchApplicationRegions(brandId, versionIds, makeId, modelId) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_FILTER_REGIONS',
    payload: axios.get(`${getApiRoot()}/vehicles/regions${urlParams}`),
  };
}

export function fetchAnalysesAlertItemCount(brandId) {
  return {
    type: 'FETCH_ANALYSES_ALERT_ITEM_COUNT',
    payload: axios.get(
      `${getNonGwApiRoot()}/parents/owners/brands/${brandId}/analysis_types/items_count`
    ),
  };
}
