import { Drawer } from 'antd';
import React from 'react';
import EnterprisePromotion from './EnterprisePromotion';
import text from '../../../constants/EnterprisePromotionTranslation.json';

type EnterprisePromotionDrawerProps = {
  visible: boolean;
  onClose: () => void;
};

class EnterprisePromotionDrawer extends React.Component<EnterprisePromotionDrawerProps> {
  render() {
    return (
      <Drawer title={text.title} width={400} open={this.props.visible} onClose={this.props.onClose}>
        <EnterprisePromotion />
      </Drawer>
    );
  }
}

export default EnterprisePromotionDrawer;
