import React from 'react';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { connect } from 'react-redux';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type FormUserPreselectProps = {
  users: { id: number; name: string; email: string; primaryUser: boolean }[];
};

class FormUserPreselect extends React.Component<FormUserPreselectProps & FormikProps> {
  componentDidMount() {
    const { status, values, setFieldValue, setStatus } = this.props.formik;
    if (!status && !values.primary_contact_id) {
      const user = this.props.users.find(
        user => user.primaryUser && !user.email.endsWith('@pdm.ai')
      );
      setStatus({ primaryContactInitialized: true });
      setFieldValue('primary_contact_email', user?.email);
      setFieldValue('primary_contact_name', user?.name);
      setFieldValue('primary_contact_id', user?.id);
    }
  }

  render() {
    return null;
  }
}

export default connect()(formikConnect<FormUserPreselectProps, FormikValues>(FormUserPreselect));
