import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../../actions/app/modal';

class ModalContent extends React.Component {
  modalContentRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.modalContentRef && !this.modalContentRef.current.contains(event.target)) {
      if (this.props.clickOutside) {
        this.props.hideModal();
      }
    }
  };

  render() {
    return (
      <div className={`modal-content ${this.props.className}`} ref={this.modalContentRef}>
        {this.props.children}
      </div>
    );
  }
}

ModalContent.defaultProps = {
  clickOutside: false,
};

export default connect(null, { hideModal })(ModalContent);
