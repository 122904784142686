import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function fetchItemDeliveryStatuses(itemId: number) {
  return {
    type: 'FETCH_ITEM_DELIVERY_STATUSES',
    payload: axios.get(`${getApiRoot()}/items/${itemId}/delivery_statuses`),
  };
}

export function fetchChannelDeliveryLogsOverview({
  itemId,
  channelId,
  receiverId,
}: {
  itemId: number;
  channelId: number | null;
  receiverId: number | null;
}) {
  const params = utils.createURL([
    { name: 'item_id', values: itemId },
    { name: 'channel_id', values: channelId },
    { name: 'receiver_id', values: receiverId },
  ]);

  return {
    type: 'FETCH_CHANNEL_DELIVERY_LOGS_OVERVIEW',
    payload: axios.get(`${getApiRoot()}/channels/delivery_logs/overview${params}`),
  };
}
