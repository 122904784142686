import axios from 'axios';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';
import { ApplicationElement } from '../../../types/all_application_validation';

export const createApplicationToPartNumber = (params: {
  partNumber: string;
  brandId: number;
  applicationElements: ApplicationElement[];
}) => {
  const urlParams = utils.createURL([
    { name: 'part_number', values: params.partNumber },
    { name: 'brand_id', values: params.brandId },
  ]);

  return {
    type: 'CREATE_APPLICATIONS_TO_PART_NUMBER',
    payload: axios.post(`${getApiRoot()}/items/applications/full${urlParams}`, {
      applications: params.applicationElements,
    }),
  };
};
