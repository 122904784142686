import React from 'react';
import CategoryDropdown from '../../global/CategoryDropdown';

export default class AnalysesCategoriesDropdown extends React.Component {
  updateReviewCategory = category => {
    const groupKey = this.props.analysis.group_key;
    const referenceId = this.props.analysis.reference_id;
    const typeId = this.props.analysis.type_id;

    this.props.updateReviewCategory(groupKey, referenceId, category.id, typeId);
  };

  render() {
    const { analysis } = this.props;
    const displayedCategory = {
      id: analysis.part_type.category_id,
      level1_name: analysis.part_type.category_name,
      level2_name: analysis.part_type.sub_category_name,
      level3_name: analysis.part_type.name,
    };

    return (
      <CategoryDropdown
        className="analyse__category-dropdown-container"
        fetchingCategories={this.props.fetchingReviewCategories}
        defaultKeyword={analysis.items[0].name}
        setDropdownState={this.props.setDropdownState}
        fetchReviewCategories={this.props.fetchReviewCategories}
        fetchNextReviewCategories={this.props.fetchNextReviewCategories}
        categories={this.props.categories}
        updateCategory={this.updateReviewCategory}
        displayedCategory={displayedCategory}
      />
    );
  }
}
