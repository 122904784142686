import axios from 'axios';
import { Dispatch } from 'redux';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;
let createOfferCancelToken: any;

export function createEbayOffer(itemId: number, updateOffer?: boolean) {
  if (createOfferCancelToken) createOfferCancelToken();

  return {
    type: 'CREATE_EBAY_OFFER',
    meta: { updateOffer, itemId },
    payload: axios.patch(
      `${getApiRoot()}/ebay_native/offer`,
      {
        item_ids: [itemId],
      },
      {
        cancelToken: new CancelToken(c => (createOfferCancelToken = c)),
      }
    ),
  };
}

export function createEbayOffers({
  itemIds,
  filterId,
  withoutItemdIds,
  updateOnlyFitments,
}: {
  itemIds?: number[];
  filterId?: number;
  withoutItemdIds?: number[];
  updateOnlyFitments?: number;
}) {
  return async (dispatch: Dispatch) => {
    const params = utils.createURL([
      { name: 'filter_id', values: filterId },
      { name: 'update_only_fitments', values: updateOnlyFitments },
    ]);

    const result = await axios.patch(`${getApiRoot()}/ebay_native/offer/bulk${params}`, {
      item_ids: itemIds,
      without_item_ids: withoutItemdIds,
    });

    const logParams = utils.createURL([
      { name: 'log_ids', values: result.data.channel_log_id },
      { name: 'page', values: 1 },
      { name: 'limit', values: 1 },
    ]);

    const newLogs = await axios.get(`${getNonGwApiRoot()}/channels/delivery_logs${logParams}`);

    return dispatch({
      type: '@CHANNEL_PUSHER/PUSH_UPDATE',
      payload: newLogs,
    });
  };
}

export function registerEbay(code: string) {
  const token = localStorage.getItem('paramount-token')
    ? JSON.parse(localStorage.getItem('paramount-token')!)
    : '';
  const config = {
    headers: { 'Paramount-Access-Token': token },
  };

  const params = utils.createURL([{ name: 'code', values: code }]);

  return {
    type: 'REGISTER_EBAY',
    payload: axios.get(`${getApiRoot()}/ebay_native/auth/register${params}`, config),
  };
}
