export enum UserRole {
  MANUFACTURER = 'MANUFACTURER',
  RECEIVER = 'RECEIVER',
}

export type UserType = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  email_notification: number;
  admin: number;
  permissions: string[];
  account_id: number;
  parent_id: number | null;
  receiver_id: number | null;
  account_type: string;
  token: string;
  brand_permissions: BrandPermissions;
  roles: string[];
  account_name: string;
  intercom_user_id_key: string;
  intercom_user_hash: string;
  user_role_ids: number[];
};

export type BrandPermissions = {
  parent: Parent;
  receiver: Parent;
};

export type Parent = {
  read: number[] | null;
  edit: number[] | null;
};

export type ListUser = {
  id: number;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  email_notification: number;
  admin: number;
  active: number;
  user_role_ids: number[];
  brand_permissions: BrandPermission[];
  primary_user_id?: number;
};

export type PermissionGroup = {
  id: number;
  name: string;
  permissions: Permission[];
};

export interface Permission {
  id: number;
  code: string;
  name: string;
  description: string;
}

export type UserPermissionRole = {
  id: number;
  name: string;
  permission_ids: number[];
  segments: RoleSegment[];
};

export type RoleSegment = {
  segment_id: number;
  maintenance_type_id: number;
};

export type BrandPermission = {
  brand_id: number;
  maintenance_type_id: number;
};

export type UserName = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  status_code: number;
};
