import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { KitType } from '../../../../types/kit';

export function updateKitItem(values: any) {
  const paramList = Object.keys(values)
    .filter(key => !(key === 'description'))
    .map(key => ({ name: key, values: values[key], allowEmpty: true }));

  const params = utils.createURL(paramList);

  return {
    type: 'UPDATE_KIT_ITEM',
    payload: axios.patch(`${getApiRoot()}/items/kits/${params}`, {
      description: values.description,
    }),
  };
}

export function updateKitOrder(kits: KitType[]) {
  const sortedKits = kits.map((kit, i) => ({ item_kit_id: kit.id, record_number: i + 1 }));
  return {
    type: 'UPDATE_KIT_ORDER',
    payload: axios.patch(`${getApiRoot()}/items/kits/update_order`, sortedKits),
    meta: { kits },
  };
}
