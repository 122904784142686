import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { LanguageSetting } from '../../../types/language';

export type LanguageSettingsState = {
  accountLanguages: LanguageSetting[];
  isFetching: boolean;
};

const initialState = {
  accountLanguages: [],
  isFetching: false,
};

const reducer: Reducer<LanguageSettingsState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_LANGUAGES_PENDING': {
      return { ...state, accountLanguages: [], isFetching: true };
    }
    case 'FETCH_ACCOUNT_LANGUAGES_FULFILLED': {
      return { ...state, accountLanguages: action.payload.data, isFetching: false };
    }
    case 'UPDATE_ACCOUNT_LANGUAGES_FULFILLED': {
      return { ...state, accountLanguages: action.payload.data };
    }
    case 'DELETE_ACCOUNT_LANGUAGE_FULFILLED': {
      return {
        ...state,
        accountLanguages: state.accountLanguages.filter(
          language => language.id !== action.meta.accountLanguageid
        ),
      };
    }
  }
  return state;
};

export default reducer;
