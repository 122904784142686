import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchReceiverWarehouseList } from '../../actions/receiver/fetch';
import { fetchCompanyProfile } from '../../actions/settings/companyProfile';
import { fetchUsers } from '../../actions/user';
import CompanyProfilePage from '../../components/body/settings/companyProfile/CompanyProfilePage';
import { withContainerWrapper } from '../ContainerWrapper';

const CompanyProfileContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCompanyProfile());
    dispatch(fetchUsers());
    dispatch(fetchReceiverWarehouseList());
  }, [dispatch]);

  return <CompanyProfilePage />;
};

export default withContainerWrapper(CompanyProfileContainer);
