import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Spin, Switch } from 'antd';
import { Formik } from 'formik';
import { ApplicationState } from '../../../../reducers';
import { AsyncDispatch } from '../../../../../types/global';
import FormMultiSelect from '../../../global/Forms/FormMultiSelect';
import FormSelect from '../../../global/Forms/FormSelect';
import { VcdbVersion } from '../../../../../types/application';
import { ExtendedResources } from '../../../../../types/resources';
import CustomVcdbImportModal from './CustomVcdbImportModal';
import { exportVCdbExcelFile } from '../../../../actions/parent/vcdb/update';
import { hasPermission } from '../../../../utils/Permissions';

const VcdbImportExportPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { vehicleConfigTypes, fetchingVcdbVersions, vcdbVersions, user } = useSelector(
    (state: ApplicationState) => {
      return {
        vehicleConfigTypes: state.resources.data.global.vehicle_config_types.filter(
          (type: ExtendedResources) => type.id > 4
        ),
        vcdbVersions: state.items.application.vcdbVersions.filter(
          (v: VcdbVersion) => !!v.account_id
        ),
        fetchingVcdbVersions: state.items.application.fetchingVcdbVersions,
        user: state.user.user,
      };
    }
  );

  const [showImportModal, setShowImportModal] = React.useState<boolean>(false);

  const canManageVcdb = hasPermission(user, 'can_manage_custom_vcdb');

  if (fetchingVcdbVersions)
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <div className="p-6">
      <Formik
        initialValues={{
          configTypeIds: [],
          checked: false,
          versionId: null,
        }}
        onSubmit={values => {
          // @ts-ignore
          dispatch(exportVCdbExcelFile(values.configTypeIds, values.versionId));
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          const renderLabel = () => {
            return (
              <div className="inline-flex w-full">
                <div>{t('vcdb:vcdbConfigs')}</div>
                <div className="flex-1 flex justify-end">
                  <div className="mr-2">{t('vcdb:selectAll')}</div>
                  <Switch
                    size="small"
                    checked={values.checked}
                    onChange={() => {
                      if (values.checked) {
                        setFieldValue('checked', false);
                        setFieldValue('configTypeIds', []);
                      } else {
                        setFieldValue('checked', true);
                        setFieldValue(
                          'configTypeIds',
                          vehicleConfigTypes.map(type => {
                            return type.id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
            );
          };
          return (
            <React.Fragment>
              <CustomVcdbImportModal
                showImportModal={showImportModal}
                handleOnCancel={() => setShowImportModal(false)}
                values={values}
              />
              <Form layout="vertical">
                <Form.Item label={renderLabel()} required className="custom-vcdb-page__config">
                  <FormMultiSelect
                    name="configTypeIds"
                    values={vehicleConfigTypes || []}
                    size="small"
                    handleChange={(value: string[]) => {
                      if (value.length === vehicleConfigTypes.length)
                        setFieldValue('checked', true);
                      else setFieldValue('checked', false);

                      setFieldValue('configTypeIds', value);
                    }}
                    allowClear
                  />
                </Form.Item>
                <FormSelect
                  label={t('vcdb:version')}
                  values={vcdbVersions || []}
                  name="versionId"
                  size="small"
                  allowClear
                  showSearch
                  required
                />
              </Form>
              <div className="flex">
                <Button
                  disabled={
                    !canManageVcdb || values.configTypeIds.length === 0 || !values.versionId
                  }
                  className="mr-2"
                  onClick={() => setShowImportModal(true)}
                >
                  {t('common:import')}
                </Button>
                <Button
                  disabled={
                    !canManageVcdb || values.configTypeIds.length === 0 || !values.versionId
                  }
                  onClick={() => handleSubmit()}
                >
                  {t('common:export')}
                </Button>
              </div>
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
};

export default VcdbImportExportPage;
