import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteInterchangeItem(infoId: number) {
  return {
    type: 'DELETE_INTERCHANGE_ITEM',
    meta: { infoId },
    payload: axios.delete(`${getApiRoot()}/items/part_interchange_informations/${infoId}`),
  };
}
