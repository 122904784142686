import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type AutomationErrorLogModalProps = {
  showModal: boolean;
  error: string;
  onClose: () => void;
};

const AutomationErrorLogModal: React.FC<AutomationErrorLogModalProps> = props => {
  const { t } = useTranslation();
  const { error, onClose } = props;

  return (
    <Modal
      title={t('api:consoleLog')}
      open={props.showModal}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <div className="api-automation-error-modal">{error}</div>
    </Modal>
  );
};

export default AutomationErrorLogModal;
