import React from 'react';

type DescriptionsListProps = {
  title?: string;
  colNumber: number;
  layout?: 'horizontal' | 'vertical';
};

class DescriptionsList extends React.Component<DescriptionsListProps> {
  public static defaultProps = {
    layout: 'vertical',
  };

  static Item = (props: any) => (
    <div
      className="flex"
      style={{
        flexDirection: props.layout === 'vertical' ? 'column' : 'row',
        gridColumnStart: props.fullWidth ? 1 : '',
        gridColumnEnd: props.fullWidth ? props.colNumber + 1 : '',
        marginBottom: 5,
      }}
    >
      {props.label && <div className="descriptions-list__child-label">{props.label}</div>}
      <div className="descriptions-list__child-value">{props.children}</div>
    </div>
  );

  render() {
    return (
      <div className="descriptions-list">
        {this.props.title && <div className="descriptions-list__title">{this.props.title}</div>}

        <div
          className="descriptions-list__childs"
          style={{
            gridTemplateColumns: `repeat(${this.props.colNumber}, 1fr)`,
          }}
        >
          {React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                // @ts-ignore
                ...child.props,
                // @ts-ignore
                colNumber: this.props.colNumber,
                // @ts-ignore
                layout: this.props.layout,
              });
            }
          })}
        </div>
      </div>
    );
  }
}

export default DescriptionsList;
