import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ApplicationElement } from '../../../types/all_application_validation';
import { Item } from '../../../types/item';
import { Analysis } from '../../../types/analyses';

export type AllApplicationsState = {
  readonly fetching: boolean;
  readonly fetchingApplicationItem: boolean;
  readonly creatingApplications: boolean;
  readonly fetchListingError: boolean;
  readonly applicationRows: ApplicationElement[];
  readonly columns: Array<{ [key: string]: string[] } | string>;
  readonly listAnalyses: Analysis[];
  readonly applicationItem?: Item;
  readonly listingTypeId: number;
  readonly listingTypeOptionId?: number;
};

const initialState: AllApplicationsState = {
  fetching: false,
  fetchingApplicationItem: false,
  creatingApplications: false,
  fetchListingError: false,
  applicationRows: [],
  columns: [],
  listAnalyses: [],
  applicationItem: undefined,
  listingTypeId: 1,
  listingTypeOptionId: 1,
};

const reducer: Reducer<AllApplicationsState, FluxStandardAction> = (
  state = initialState,
  action
): AllApplicationsState => {
  switch (action.type) {
    case 'FETCH_APPLICATION_LISTINGS_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        applicationRows: page === 1 ? [] : state.applicationRows,
        columns: page === 1 ? [] : state.columns,
        listAnalyses: page === 1 ? [] : state.listAnalyses,
        fetching: true,
        fetchListingError: false,
      };
    }
    case 'FETCH_APPLICATION_LISTINGS_REJECTED': {
      // eslint-disable-next-line no-underscore-dangle
      const canceled = !!action.payload.__CANCEL__;
      const error = !canceled;
      const fetching = canceled;
      return { ...state, fetching, fetchListingError: error };
    }
    case 'FETCH_APPLICATION_LISTINGS_FULFILLED': {
      const listing = action.payload.data;
      return {
        ...state,
        applicationRows: [...state.applicationRows, ...listing.applications],
        columns: listing.columns,
        listAnalyses: listing.analysis,
        fetching: false,
        fetchListingError: false,
      };
    }
    case 'FETCH_APPLICATION_ITEM_PENDING': {
      return {
        ...state,
        fetchingApplicationItem: true,
        applicationItem: undefined,
      };
    }
    case 'FETCH_APPLICATION_ITEM_REJECTED': {
      return { ...state, fetchingApplicationItem: true };
    }
    case 'FETCH_APPLICATION_ITEM_FULFILLED': {
      return {
        ...state,
        fetchingApplicationItem: false,
        applicationItem: action.payload.data,
      };
    }
    case 'MAP_CONFIG_TO_APPLICATION_FULFILLED': {
      const applicationRows = state.applicationRows.map(app => {
        if (app.application_id !== action.meta.applicationId) return app;
        return { ...app, updated: true };
      });
      return { ...state, applicationRows };
    }
    case 'CREATE_APPLICATIONS_TO_PART_NUMBER_PENDING': {
      return { ...state, creatingApplications: true };
    }
    case 'CREATE_APPLICATIONS_TO_PART_NUMBER_REJECTED': {
      return { ...state, creatingApplications: false };
    }
    case 'CREATE_APPLICATIONS_TO_PART_NUMBER_FULFILLED': {
      const itemId = action.payload.data.item_id;
      let hashToApplicationId: any[] = [];
      action.payload.data.applications.forEach((applicationRelation: { [x: string]: string[] }) => {
        const applicationId = Object.keys(applicationRelation)[0];
        const hashApplications = applicationRelation[applicationId].map(hashKey => ({
          hashKey,
          applicationId: Number(applicationId),
          itemId,
        }));
        hashToApplicationId = [...hashToApplicationId, ...hashApplications];
      });

      const applicationRows = state.applicationRows.map(app => {
        const applicationRelation = hashToApplicationId.find(el => el.hashKey === app.hash_key);
        if (!applicationRelation) return app;
        return { ...app, application_id: applicationRelation.applicationId, item_id: itemId };
      });
      return { ...state, applicationRows, creatingApplications: false };
    }
    case 'DELETE_APPLICATION_PENDING': {
      const applicationRows = state.applicationRows.filter(
        app => app.application_id !== action.meta.id
      );
      return { ...state, applicationRows };
    }
    case 'SET_LISTING_TYPE_ID': {
      return { ...state, listingTypeId: action.payload.id };
    }
    case 'SET_LISTING_TYPE_OPTION_ID': {
      return { ...state, listingTypeOptionId: action.payload.id };
    }
    case 'RESET_ALL_APPLICATIONS': {
      return initialState;
    }
    case 'RESET_APPLICATION_LISTINGS': {
      return { ...state, applicationRows: [] };
    }
  }
  return state;
};

export default reducer;
