export const NO_VALUE_OPTIONS = [3, 5]; // any value, no value

export const ALL_FILTER_TYPE_ID = 1;
export const MARKED_FILTER_TYPE_ID = 2;
export const TEMP_FILTER_TYPE_ID = 3;
export const CUSTOM_FILTER_TYPE_ID = 4;
export const APPLICATION_LISTING_FILTER_TYPE_ID = 5;
export const ITEM_APPLICATION_LISTING_FILTER_TYPE_ID = 6;

export const filterOptions = {
  1: 'is',
  2: 'is not',
  3: 'with any value',
  4: 'with any value, excluding',
  5: 'with no value',
};
