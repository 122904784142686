import { connect } from 'react-redux';
import React from 'react';
import { Spin, message, Modal, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import {
  EbaySettings,
  ItemEbaySettings,
  EbayResources,
  Epid,
  EbayCategory,
  EbayPolicies,
  EbayOffer,
  EbayItemSettingsData,
} from '../../../types/ebay';
import { Ebay } from '../../../types/resources';
import { Item } from '../../../types/item';
import { UserType } from '../../../types/user';
import { nextPage, typingDone } from '../../utils/Utils';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { areas } from '../../constants/ParamountReactConstants';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/ebay';
import channelActions from '../../actions/channel';
import { syncItemsLive } from '../../actions/brand/synchronisation';
import { setActiveArea } from '../../actions/app/navigationBar';
import ItemInfo from '../../components/global/ItemInfo';
import EbayItemSettings from '../../components/body/ebay/EbayItemSettings';
import { isReceiver } from '../../utils/UserUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';

const confirm = Modal.confirm;

type EbayContainerProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  selectedItems: Item[];
  selectedItem: Item;
  brandId: number;
  fetchingEbaySettings: boolean;
  fetchingEbayResources: boolean;
  ebayPolicies: EbayPolicies;
  ebaySettings: EbaySettings;
  ebayResources: Ebay;
  ebayData: EbayResources;
  fetchingCategories: boolean;
  fetchingEpids: boolean;
  ebayOffer: EbayOffer;
  epids: Epid[];
  ebayCategories: EbayCategory[];
  itemEbaySettings: ItemEbaySettings;
  ebayItemSettings: EbayItemSettingsData;
  canManageEbay: boolean;
} & WithTranslation;

type EbayContainerState = {
  initialListingFetching: boolean;
};

export class EbayContainer extends React.Component<EbayContainerProps, EbayContainerState> {
  state = { initialListingFetching: false };

  componentDidMount() {
    const { ebayData } = this.props;
    this.props.dispatch(channelActions.fetchEbayPolicies());
    if (!Object.keys(ebayData).length) this.props.dispatch(channelActions.fetchEbayResources());

    this.props.dispatch(channelActions.fetchEbaySettings()).then(result => {
      const { value } = result;
      if (value.data.ebay_registered) {
        if (this.props.selectedItem && this.props.selectedItem.id) {
          this.fetchEbayItemData();
        }
      }
    });

    this.props.dispatch(setActiveArea(areas.ebay));
  }

  componentDidUpdate(prevProps: EbayContainerProps) {
    const prevItem = prevProps.selectedItem || {};
    const selectedItem = this.props.selectedItem || {};

    if (
      selectedItem.id &&
      selectedItem.id !== prevItem.id &&
      this.props.ebaySettings.ebay_registered
    ) {
      this.fetchEbayItemData();

      intercomEvent('viewed-all-product', {
        location: 'ebay-item',
        part_number: selectedItem.part_number,
        brand_code: selectedItem.brand_code,
      });
    }
  }

  fetchEbayItemData = async () => {
    const { selectedItem } = this.props;
    this.setState({ initialListingFetching: true });
    this.props.dispatch(actions.fetchEpIds(selectedItem.id));
    await Promise.all([
      this.props.dispatch(actions.fetchEbayOffer(selectedItem.id)),
      this.props.dispatch(actions.fetchEbayItemSettings(selectedItem.id)),
      this.props.dispatch(actions.fetchIntegratedSettings(selectedItem.id)),
      this.props.dispatch(actions.fetchItemEbayCategories(selectedItem.id)),
    ]);
    this.setState({ initialListingFetching: false });
  };

  fetchEbayCategories = (keywords?: string) => {
    const { selectedItem } = this.props;
    typingDone(() =>
      this.props.dispatch(actions.fetchItemEbayCategories(selectedItem.id, keywords))
    );
  };

  fetchNextCategories = (event: React.UIEvent<HTMLDivElement>, keywords?: string) => {
    const { selectedItem, ebayCategories, fetchingCategories } = this.props;

    const nextPageNr = nextPage(event, 100, ebayCategories.length);
    if (nextPageNr && !fetchingCategories) {
      this.props.dispatch(actions.fetchItemEbayCategories(selectedItem.id, keywords, nextPageNr));
    }
  };

  handleSyncItem = (itemId: number) =>
    this.props.dispatch(syncItemsLive([itemId], this.props.brandId));

  checkSyncAndAsset = (params?: { updateOffer?: boolean }) => {
    const { t, selectedItem: item, user } = this.props;
    const outOfSync = new Date(item.last_synced_at || 0) < new Date(item.updated_at);
    const inactive = item.population_status_id !== 1;
    if (inactive) message.error(t('ebay:syncDisabled'));

    // no sync test for receiver user
    if (isReceiver(user)) return inactive;

    if (outOfSync && !inactive) {
      confirm({
        title: t('ebay:outOfSync'),
        content: t('ebay:syncNow'),
        onOk: () => {
          return this.handleSyncItem(item.id)
            .then(() => {
              this.createEbayOffer({ updateOffer: params?.updateOffer });
              // return Promise to close modal
              return Promise.resolve();
            })
            .catch(() => {
              message.error(t('ebay:syncError'));
              return Promise.resolve();
            });
        },
      });
    } else if (outOfSync) message.error(t('ebay:notInSyncMessage'));

    return outOfSync || inactive;
  };

  updateItemSettings = async (values: {
    title?: string;
    partNumber?: string;
    description?: string;
    price?: string;
    primaryCategoryId?: number;
    secondaryCategoryId?: number;
    fulfillmentProfileId?: number;
    paymentProfileId?: number;
    returnProfileId?: number;
    ebayListtype?: string;
    ebayListtime?: string;
  }) => {
    const { selectedItem } = this.props;

    const updateParams = {
      itemId: selectedItem.id,
      listingType: values.ebayListtype || null,
      listingLength: values.ebayListtime || null,
      paymentId: values.paymentProfileId || null,
      returnId: values.returnProfileId || null,
      fulfillmentId: values.fulfillmentProfileId || null,
      primaryCategoryId: values.primaryCategoryId || null,
      secondaryCategoryId: values.secondaryCategoryId || null,
      title: values.title || null,
      partNumber: values.partNumber || null,
      description: values.description || null,
      price: values.price || null,
    };

    return this.props.dispatch(actions.updateEbayItemSettings(updateParams));
  };

  createEbayOffer = (params?: { updateOffer?: boolean }) => {
    const { selectedItem } = this.props;
    if (this.checkSyncAndAsset()) return;

    this.props.dispatch(actions.createEbayOffer(selectedItem.id, params?.updateOffer));
  };

  deleteEbayOffer = () => {
    const { selectedItem } = this.props;

    this.props.dispatch(actions.deleteEbayOffer(selectedItem.id));
  };

  render() {
    const {
      t,
      selectedItems,
      ebaySettings,
      fetchingEbayResources,
      fetchingEbaySettings,
      canManageEbay,
    } = this.props;

    if (!canManageEbay) {
      return (
        <div className="p-5">
          <Alert
            showIcon
            type="info"
            message={t('common:noAccess')}
            description={t('ebay:noPermission')}
          />
        </div>
      );
    }

    if (selectedItems.length > 1 || selectedItems.length === 0) {
      return <ItemInfo items={selectedItems} />;
    }

    if (fetchingEbaySettings || fetchingEbayResources || this.state.initialListingFetching)
      return <Spin className="flex justify-center" style={{ marginTop: 50 }} size="large" />;

    if (!ebaySettings.ebay_registered)
      return (
        <Alert
          className="ebay__no-connection"
          message={t('ebay:noConnection')}
          description={
            <Button size="small" className="analyses__button-add-session">
              <Link to={{ pathname: '/distribution/channels' }}>{t('ebay:goToChannel')}</Link>
            </Button>
          }
          type="info"
        />
      );

    return (
      <EbayItemSettings
        ebayOffer={this.props.ebayOffer}
        epids={this.props.epids}
        ebayCategories={this.props.ebayCategories}
        categoryResources={this.props.ebayData.ebay_categories}
        itemEbaySettings={this.props.itemEbaySettings}
        ebaySettings={this.props.ebaySettings}
        itemSettings={this.props.ebayItemSettings}
        itemUpdatedAt={this.props.selectedItem.updated_at}
        ebayResources={this.props.ebayResources}
        ebayPolicies={this.props.ebayPolicies}
        fetchingCategories={this.props.fetchingCategories}
        fetchingEpids={this.props.fetchingEpids}
        createEbayOffer={this.createEbayOffer}
        deleteEbayOffer={this.deleteEbayOffer}
        updateItemSettings={this.updateItemSettings}
        searchEbayCategories={this.fetchEbayCategories}
        fetchNextCategories={this.fetchNextCategories}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedItems = getSelectedItems(state);

  return {
    user: state.user.user,
    selectedItems,
    selectedItem: selectedItems[0],
    brandId: state.parent.brands.selectedBrandId,
    fetchingEbaySettings: state.channel.channels.fetchingEbaySettings,
    fetchingEbayResources: state.channel.channels.fetchingEbayResources,
    ebayPolicies: state.channel.channels.ebayPolicies,
    ebaySettings: state.channel.channels.ebaySettings,
    ebayResources: state.resources.data.ebay,
    ebayData: state.channel.channels.ebayResources,
    fetchingCategories: state.items.ebay.fetchingCategories,
    fetchingEpids: state.items.ebay.fetchingEpids,
    ebayOffer: state.items.ebay.ebayOffer,
    epids: state.items.ebay.epids,
    ebayCategories: state.items.ebay.ebayCategories,
    itemEbaySettings: state.items.ebay.itemEbaySettings,
    ebayItemSettings: state.items.ebay.ebayItemSettings,
    canManageEbay: hasPermission(state.user.user, 'can_manage_ebay'),
  };
};

export default connect(mapStateToProps)(withContainerWrapper(withTranslation()(EbayContainer)));
