import { Empty, Spin } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import { Attribute } from '../../../../types/attributes';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { ApplicationState } from '../../../reducers';
import { fetchUnits, fetchValues } from '../../../actions/items/attributes/fetch';
import { AsyncDispatch } from '../../../../types/global';
import AttributeNameCell from './AttributeNameCell';
import AttributeValueCell from './AttributeValueCell';
import AttributeHeaderCell from './AttributeHeaderCell';
import { SelectedListItem } from '../../../../types/item';
import { intercomEvent } from '../../../utils/IntercomUtils';

type AttributesTableProps = {
  attributes: Attribute[];
  selectedCells: { rowIndex: number; columnIndex: number }[];
  selectedRows: (number | string)[];
  openDrawer: boolean;
  handleFetchNextAttributes: () => Promise<any> | undefined;
  handleAddValue: (data: {
    attributeId: number | string;
    uomId: number | null;
    uomlabel: string;
    itemIds: number[];
    valueId: number | null;
    valueName: string;
  }) => void;
  handleCopiedCellData: ({
    updateItemIds,
    copiedItemId,
    copiedCells,
  }: {
    updateItemIds: number[];
    copiedItemId: number;
    copiedCells: { rowIndex: number; columnIndex: number }[];
  }) => void;
  handleSelect: (
    selectedItemsCount: number,
    selectedRows: (number | string)[],
    focusedCells: { rowIndex: number; columnIndex: number }[]
  ) => void;
};

const AttributesTable: React.FC<AttributesTableProps> = ({
  attributes,
  selectedCells,
  selectedRows,
  openDrawer,
  handleAddValue,
  handleCopiedCellData,
  handleSelect,
  handleFetchNextAttributes,
}) => {
  const tableRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { setFieldValue } = useFormikContext<any>();

  const {
    analysisBySegment,
    selectedItems,
    selectedItemIds,
    allSelectedItemIds,
    fetchingAttributes,
    fetchingMoreAttributes,
    selectedItemsList,
  } = useSelector((state: ApplicationState) => {
    return {
      analysisBySegment: extendedAnalysesBySegment(state),
      selectedItems: getSelectedItems(state),
      selectedItemIds: state.catalogue.catalogue.selectedItemIds,
      allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
      fetchingAttributes: state.items.attributes.fetchingAttributes,
      fetchingMoreAttributes: state.items.attributes.fetchingMoreAttributes,
      selectedItemsList: state.catalogue.catalogue.selectedItemsList,
    };
  });

  const [copiedCells, setCopiedCells] = React.useState<{ rowIndex: number; columnIndex: number }[]>(
    []
  );
  const [scrollLeft, setScrollLeft] = React.useState(0);

  const selectedBrandItemIds = useMemo(
    () => (selectedItemIds.length > 1 ? allSelectedItemIds : selectedItemIds),
    [allSelectedItemIds, selectedItemIds]
  );

  const selectedBrandItems = useMemo(
    () => (selectedItems.length > 1 ? selectedItemsList : selectedItems),
    [selectedItems, selectedItemsList]
  );

  React.useEffect(() => {
    if (selectedRows.length === 1) {
      const attributeIds = typeof selectedRows[0] === 'string' ? [] : [selectedRows[0]!];
      dispatch(fetchUnits({ itemIds: selectedBrandItemIds, attributeIds }));
      dispatch(
        // @ts-ignore
        fetchValues({ itemIds: selectedBrandItemIds, attributeIds })
      );
    }
  }, [dispatch, selectedBrandItemIds, selectedRows]);

  React.useEffect(() => {
    setCopiedCells([]);
  }, [selectedBrandItemIds.length]);

  const keyBoardNavigation = React.useCallback(
    (e: any) => {
      if (e.key === 'c' && (e.ctrlKey || e.metaKey)) {
        const selectedColumnCount = [...new Set(selectedCells.map(c => c.columnIndex))].length;
        if (selectedColumnCount === 1) setCopiedCells(selectedCells);
      }
      if (e.key === 'Escape') {
        setCopiedCells([]);
      }
      if (e.key === 'v' && (e.ctrlKey || e.metaKey)) {
        let copy = false;
        const selectedColumnIndices =
          selectedRows.length > 0
            ? selectedBrandItemIds.map((_id, i) => i + 1)
            : [...new Set(selectedCells.map(c => c.columnIndex))];

        if (selectedColumnIndices.length > 0 || selectedRows.length > 0) {
          const selectedRowIndices =
            selectedRows.length > 0
              ? selectedRows.map(id => attributes.findIndex(a => a.id === id))
              : [...new Set(selectedCells.map(c => c.rowIndex))];

          copiedCells.forEach(c => {
            if (selectedRowIndices.includes(c.rowIndex)) copy = true;
          });
          if (copy) {
            const updateItemIds: number[] = [];
            selectedColumnIndices.forEach(i => updateItemIds.push(selectedBrandItemIds[i - 1]));

            const copiedItemId = selectedBrandItemIds[copiedCells[0].columnIndex - 1];
            handleCopiedCellData({ updateItemIds, copiedItemId, copiedCells });
            setCopiedCells([]);
          }
        }
      }
    },
    [
      selectedCells,
      selectedRows,
      selectedBrandItemIds,
      copiedCells,
      attributes,
      handleCopiedCellData,
    ]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', keyBoardNavigation, false);

    return () => {
      window.removeEventListener('keydown', keyBoardNavigation, false);
    };
  }, [keyBoardNavigation]);

  const handleRowSelection = (attributeId: number | string, e: any) => {
    if (e.ctrlKey || e.metaKey) {
      if (selectedRows.includes(attributeId)) {
        handleSelect(
          selectedBrandItemIds.length,
          selectedRows.filter((r: any) => r !== attributeId),
          []
        );
      } else {
        const attributeIds = [...new Set([...selectedRows, attributeId])];
        handleSelect(selectedBrandItemIds.length, attributeIds, []);
      }
    } else if (selectedRows.includes(attributeId)) {
      handleSelect(0, [], []);
    } else {
      handleSelect(selectedBrandItemIds.length, [attributeId], []);
    }
  };

  const handleCellSelection = (rowIndex: number, columnIndex: number, e: any) => {
    const cellSelected = selectedCells.find(
      c => c.rowIndex === rowIndex && c.columnIndex === columnIndex
    );

    if (e.ctrlKey || e.metaKey) {
      if (cellSelected) {
        const updatedSelection = selectedCells.filter(
          mc => !(mc.rowIndex === rowIndex && mc.columnIndex === columnIndex)
        );

        const columnIndices = [...new Set([...updatedSelection.map(c => c.columnIndex)])];
        handleSelect(columnIndices.length, [], updatedSelection);
      } else {
        const columnIndices = [...new Set([...selectedCells.map(c => c.columnIndex), columnIndex])];
        handleSelect(columnIndices.length, [], [...selectedCells, { rowIndex, columnIndex }]);
      }
    } else if (cellSelected && selectedCells.length === 1) {
      handleSelect(0, [], []);
    } else {
      const updatedSelection = [{ rowIndex, columnIndex }];
      handleSelect(1, [], updatedSelection);
    }
  };

  const fetchNext = () => handleFetchNextAttributes();

  const handleValueChange = ({
    valueId,
    prevValueId,
    uniqueId,
    uomlabel,
    uomId,
    valueName,
    attributeId,
    itemId,
  }: {
    valueId: number | null;
    prevValueId?: number;
    uniqueId?: string;
    uomlabel: string;
    uomId: number | null;
    valueName: string;
    attributeId: number | string;
    itemId: number;
  }) => {
    const attribute = attributes.find(
      attribute => attribute.id === attributeId || attribute.uniqueId === attributeId
    );

    const updatedValues = attribute!.ranked_values.map(value => {
      if (
        (valueId && (value.id === valueId || value.id === prevValueId)) ||
        (uniqueId && (value.uniqueId === uniqueId || (value.id && value.id === prevValueId)))
      ) {
        const uoms = value.part_attribute_meta_uoms.find(uom => uom.id === uomId)
          ? value.part_attribute_meta_uoms.map(uom => {
              const itemIds =
                uom.id === uomId
                  ? [...new Set([...uom.item_ids, itemId])]
                  : uom.item_ids.filter(id => id !== itemId);

              return { ...uom, item_ids: itemIds };
            })
          : [
              ...value.part_attribute_meta_uoms.map(uom => ({
                ...uom,
                item_ids: uom.item_ids.filter(id => id !== itemId),
              })),
              ...(uomId ? [{ id: uomId, label: uomlabel, item_ids: [itemId] }] : []),
            ];

        return {
          ...value,
          id: valueId,
          name: valueName,
          item_ids: [...new Set([...value.item_ids, itemId])],
          part_attribute_meta_uoms: uoms,
        };
      }
      return value;
    });
    const newAttribute = { ...attribute!, ranked_values: updatedValues || [], temp: true };

    const updatedAttributes = attributes.map((a: Attribute) =>
      a.id === newAttribute.id || a.uniqueId === newAttribute.uniqueId ? newAttribute : a
    );

    setFieldValue('attributes', updatedAttributes);

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'attribute_edit_value',
      part_number: selectedBrandItems.map((i: SelectedListItem) => i.part_number).toString(),
      brand_code: selectedItems[0]?.brand_code,
    });
  };

  const onScroll = React.useCallback(
    args => {
      if (args.scrollLeft !== scrollLeft) setScrollLeft(args.scrollLeft);
    },
    [scrollLeft]
  );

  const getVisibleColumnIndices = (offset: number, columns: any[], width: number) => {
    // build the net offset for each column
    const netOffsets: number[] = [];
    let offsetSum = 0;
    const leftBound = offset;
    const rightBound = offset + width; // tableWidth;
    const visibleIndices: number[] = [];

    // derive the column net offsets
    columns.forEach(col => {
      netOffsets.push(offsetSum); // the current offsetsum is the column offset
      offsetSum += col.width; // increase the offset sum by the width of the column
    });

    // which column offsets are outside the left and right bounds?
    netOffsets.forEach((columnOffset, colIdx) => {
      const isOutside = columnOffset < leftBound || columnOffset > rightBound;
      if (!isOutside) visibleIndices.push(colIdx);
    });

    return visibleIndices;
  };

  const rowRenderer = React.useCallback(
    ({ cells, columns }) => {
      // this could be rendering the table body row, the fixed columns row, the header row.
      // if we have the full complement of columns in the cell array (which includes placeholders
      // for frozen columns), then we have the header or body
      // plus, only want to null out hidden content when scrolling vertically

      const columnCount = selectedBrandItemIds.length + 1;
      const width = tableRef.current?.offsetWidth || 1500 - 400;

      if (cells.length === columnCount) {
        const visibleIndices = getVisibleColumnIndices(scrollLeft, columns, width);
        const startIndex = visibleIndices[0];
        const visibleCells = visibleIndices.map(x => cells[x]);

        if (startIndex > 0) {
          let width = 0;
          for (let i = 0; i < visibleIndices[0]; i++) {
            width += cells[i].props.style.width;
          }

          const placeholder = <div key="placeholder" style={{ width }} />;
          return [placeholder, visibleCells];
        }
        return visibleCells;
      }

      return cells;
    },
    [selectedBrandItemIds.length, scrollLeft]
  );

  const getItemColumnWidth = (width: number) => {
    const minColWidth = 500;
    const attributeWidth = 350;
    if (selectedBrandItemIds.length === 1) return width - attributeWidth - 20;
    if (selectedBrandItemIds.length > 1) {
      const dynamicColWidth = (width - 400) / selectedBrandItemIds.length;
      if (dynamicColWidth > minColWidth) return dynamicColWidth;
    }
    return minColWidth;
  };

  const renderOverlay = () => {
    if (fetchingMoreAttributes)
      return <Spin className="spinner-center" style={{ position: 'absolute', bottom: '30px' }} />;
    if (attributes.length === 0)
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '100px' }} />;
  };

  const valueDrawerWidth = openDrawer ? 350 : 50;

  if (fetchingAttributes) return <Spin className="spinner-center mt-2" />;

  return (
    <AutoResizer>
      {({ height, width }) => (
        <div ref={tableRef} style={{ height, width }}>
          <BaseTable
            className="attributes-base-table"
            fixed
            data={attributes || []}
            rowRenderer={rowRenderer}
            onScroll={onScroll}
            width={selectedBrandItemIds.length === 1 ? width : width - valueDrawerWidth}
            height={height - 40}
            estimatedRowHeight={40}
            rowKey="uniqueId"
            loadingMore={fetchingMoreAttributes}
            onEndReached={() => fetchNext()}
            overlayRenderer={renderOverlay()}
            overscanRowCount={10}
            headerHeight={40}
            headerClassName="attribute-table__header"
          >
            <Column
              className="attributes-table__attribute-name"
              key="attribute"
              frozen="left"
              width={350}
              title={t('attributes:attributes')}
              selectedRows={selectedRows}
              cellRenderer={({ rowData }) => {
                const row = rowData as Attribute;
                const attributeId = row?.id ? row.id : row?.uniqueId;
                return (
                  <AttributeNameCell
                    attributeId={attributeId!}
                    rowData={row}
                    analyses={analysisBySegment}
                    selectedItems={selectedBrandItems}
                    handleRowSelection={
                      selectedBrandItemIds.length > 1 ? handleRowSelection : undefined
                    }
                    selected={selectedRows.includes(row.id || row.uniqueId!)}
                  />
                );
              }}
            />
            {selectedBrandItems.map((item: SelectedListItem) => (
              <Column
                className="attributes-table__attribute-values flex justify-between items-center"
                attributes={attributes}
                analysisBySegment={analysisBySegment}
                selectedCells={selectedCells}
                copiedCells={copiedCells}
                key={item.part_number}
                dataKey={item.part_number}
                title={item.part_number}
                width={getItemColumnWidth(width)}
                headerRenderer={() => <AttributeHeaderCell partNumber={item.part_number} />}
                cellRenderer={({ rowData, rowIndex, columnIndex }) => {
                  const row = rowData as Attribute;
                  return (
                    <AttributeValueCell
                      itemId={item.id}
                      rowData={row}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                      analyses={analysisBySegment}
                      multiItemSelect={selectedBrandItemIds.length > 1}
                      selected={
                        selectedRows.includes(row.id || row.uniqueId!) ||
                        !!selectedCells.find(
                          c => c.rowIndex === rowIndex && c.columnIndex === columnIndex
                        )
                      }
                      copied={
                        !!copiedCells.find(
                          c => c.rowIndex === rowIndex && c.columnIndex === columnIndex
                        )
                      }
                      handleValueChange={handleValueChange}
                      handleAddValue={handleAddValue}
                      handleCellSelection={handleCellSelection}
                    />
                  );
                }}
              />
            ))}
          </BaseTable>
        </div>
      )}
    </AutoResizer>
  );
};

export default AttributesTable;
