import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { ScheduledImports } from '../../../../types/import_export';

export function updateScheduledImports(scheduledImports: ScheduledImports) {
  return {
    type: 'UPDATE_SCHEDULED_IMPORTS',
    meta: scheduledImports,
    payload: axios.patch(`${getApiRoot()}/imports/scheduled_imports`, scheduledImports),
  };
}
