import React from 'react';
import noImage from '../../../../../images/icons/no-image.svg';
import constants from '../../../../constants/DigitalAssetsConstants.json';

class DigitalAssetTypePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noImagePreview: false,
      validUrl: this.props.asset.shop_preview,
    };
  }

  componentDidMount() {
    const { asset } = this.props;
    this.getValidUrl(asset.shop_preview, asset.asset_url).then(url =>
      this.setState({ validUrl: url })
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.getValidUrl(this.props.asset.shop_preview, this.props.asset.asset_url).then(url =>
        this.setState({ validUrl: url })
      );
      this.setState({ noImagePreview: false, validUrl: this.props.asset.shopPreview });
    }
  }

  handleError = event => {
    const { asset } = this.props;
    if (constants.imgTypeIds.includes(asset.file_type_id) || !asset.file_type_id)
      event.target.src = noImage;
    else this.setState({ noImagePreview: true });
  };

  getFileTypeName = typeId => {
    const fileType = this.props.digitalAssetResources.file_types.find(
      type => type.id === Number(typeId)
    );
    return fileType ? fileType.code : '';
  };

  showNoImagePreview = asset => (
    <div className="image-not-supported">
      <span className="image-not-supported__text">{this.getFileTypeName(asset.file_type_id)}</span>
    </div>
  );

  getValidUrl = (shopPreview, assetUrl) => {
    return new Promise(resolve => {
      if (shopPreview) {
        const img = new Image();
        img.src = shopPreview;
        img.onload = () => resolve(shopPreview);
        img.onerror = () => resolve(assetUrl);
      } else {
        resolve(assetUrl);
      }
    });
  };

  render() {
    const { asset } = this.props;
    const assetSrc = this.props.asset.uri || this.state.validUrl || noImage;

    if (!this.state.noImagePreview) {
      return <img src={assetSrc} alt="preview" onError={this.handleError} />;
    }
    return this.showNoImagePreview(asset);
  }
}

export default DigitalAssetTypePreview;
