import axios from 'axios';
import { AsyncAction } from 'redux-promise-middleware';
import { Action } from 'redux';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function requestBrands(brandIds: number[]): AsyncAction {
  const params = utils.createURL([{ name: 'requesting_brand_ids', values: brandIds }]);
  return {
    type: 'RECEIVER_REQUEST_BRANDS',
    meta: { brandIds },
    payload: axios.post(`${getApiRoot()}/receivers/authorized_brands/request_brand${params}`),
  };
}

export function approveBrandRequests(brandIds: number[]): AsyncAction {
  const params = utils.createURL([{ name: 'requesting_brand_ids', values: brandIds }]);
  return {
    type: 'APPROVE_BRAND_REQUESTS',
    meta: { brandIds },
    payload: axios.patch(
      `${getApiRoot()}/receivers/authorized_brands/approve_brand_request${params}`
    ),
  };
}

export function declineBrandRequests(brandIds: number[]): AsyncAction {
  const params = utils.createURL([{ name: 'requesting_brand_ids', values: brandIds }]);
  return {
    type: 'DECLINE_BRAND_REQUESTS',
    meta: { brandIds },
    payload: axios.patch(
      `${getApiRoot()}/receivers/authorized_brands/decline_brand_request${params}`
    ),
  };
}

export function cancelRequestBrand(brandId: number): AsyncAction {
  const params = utils.createURL([{ name: 'requesting_brand_ids', values: brandId }]);
  return {
    type: 'CANCEL_BRAND_REQUEST',
    meta: { brandId },
    payload: axios.patch(
      `${getApiRoot()}/receivers/authorized_brands/cancel_brand_request${params}`
    ),
  };
}

export function setChannelsReceiverToEmpty(): Action {
  return {
    type: '@RECEIVER/SET_CHANNELS_RECEIVERS_TO_EMPTY',
  };
}

export function acknowledgeDetailsRequest(parentId: number) {
  const params = utils.createURL([{ name: 'acknowledge_parent_id', values: parentId }]);
  return {
    type: 'ACKNOWLEDGE_DETAILS_REQUEST',
    meta: { parentId },
    payload: axios.patch(`${getApiRoot()}/receivers/acknowledge_details_request${params}`),
  };
}
