import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';
import resourcesMap from '../../constants/ApplicationResourcesMapping.json';
import { StandardResource, Vehicle, AnalysisType } from '../../../types/resources';
import { ApplicationElement } from '../../../types/all_application_validation';
import { Analysis } from '../../../types/analyses';
import { LIST_ANALYSIS_IDS } from '../../constants/ApplicationListConstants';
import { getConfigName } from '../../utils/AllApplicationUtils';
import { ApplicationGroup } from '../../../types/application';
import { convertConfigIdsNameToName } from '../../utils/ApplicationUtils';

const resourcesMapping = resourcesMap as { [key: string]: string };

const getApplications = (state: ApplicationState) =>
  state.allApplications.applicationList.applicationRows;
const getItemApplications = (state: ApplicationState) => state.items.application.applicationRows;
const getItemGridApplications = (state: ApplicationState) =>
  state.items.application.applicationGridRows;
const getItemGridApplicationGroupData = (state: ApplicationState) =>
  state.items.application.applicationGridGroupData;
const getApplicationResources = (state: ApplicationState) => state.resources.applicationVehicle;
const getApplicationAnalyses = (state: ApplicationState) =>
  state.allApplications.applicationList.listAnalyses;
const getItemApplicationAnalyses = (state: ApplicationState) =>
  state.items.application.listAnalyses;
const getAnalysisResources = (state: ApplicationState) => state.resources.data.analysis;

// configs are fully included (not just id arrays)
const fullConfigs = [
  'mfr_label',
  'qualifiers',
  'notes',
  'category',
  'digital_assets',
  'qty',
  'asset_name',
];

export const mapApplicationResources = (
  applications: ApplicationElement[],
  resources: Vehicle,
  analyses?: (Analysis & AnalysisType)[]
) => {
  const excludedApplicationKeys = [
    'part_number',
    'item_id',
    'application_id',
    'year_count',
    'year',
    'category_id',
    'valid_vcdb',
  ];

  return applications.map((application, i) => {
    const mappedApplication = { ...application };
    Object.keys(application).forEach(key => {
      if (!fullConfigs.includes(key) && !excludedApplicationKeys.includes(key)) {
        const valueId = application[key];
        const resourcesKey = resourcesMapping.hasOwnProperty(key) ? resourcesMapping[key] : key;
        if (valueId && resources[resourcesKey]) {
          const valueResources = resources[resourcesKey].find(
            (resource: StandardResource) => resource.id === valueId
          );
          mappedApplication[key] = valueResources?.name;
        }
      }
    });
    return {
      ...mappedApplication,
      id: `${application.application_id}${application.year}${i}`,
      analyses: analyses?.filter(analysis => analysis.reference_id === application.application_id),
    };
  });
};

export const mapApplicationResourcesGrid = (
  applicationGroups: ApplicationGroup[],
  resources: Vehicle,
  analyses?: (Analysis & AnalysisType)[]
) => {
  const excludedApplicationKeys = [
    'part_number',
    'item_id',
    'application_id',
    'year_count',
    'year',
    'category_id',
    'valid_vcdb',
    'grid_application_summary',
  ];

  return applicationGroups.map((application, i) => {
    const mappedApplication = { ...application };
    Object.keys(application).forEach(key => {
      if (!fullConfigs.includes(key) && !excludedApplicationKeys.includes(key)) {
        const valueIds = application[key];

        let configName = key;
        if (key.includes('_ids')) {
          configName = convertConfigIdsNameToName(key);
        }

        const resourcesKey = resourcesMapping.hasOwnProperty(configName)
          ? resourcesMapping[configName]
          : configName;
        if (valueIds.length > 0 && !!valueIds[0] && resources[resourcesKey]) {
          const valueResources = resources[resourcesKey]
            .filter((resource: StandardResource) => valueIds.includes(resource.id))
            .map((valueResource: StandardResource) => valueResource.name);
          mappedApplication[configName] = valueResources;
        }
      }
    });
    return {
      ...mappedApplication,
      analyses: analyses?.filter(
        analysis => Number(analysis.reference_id) === application.application_id
      ),
    };
  });
};

export const mapApplicationResourcesGridGroupData = (
  applicationGroupRows: ApplicationGroup[],
  resources: Vehicle,
  analyses?: (Analysis & AnalysisType)[]
) => {
  const excludedApplicationKeys = [
    'part_number',
    'item_id',
    'application_id',
    'year_count',
    'year',
    'category_id',
    'valid_vcdb',
  ];

  return applicationGroupRows.map((application, i) => {
    const mappedApplication = { ...application };
    Object.keys(application).forEach(key => {
      if (!fullConfigs.includes(key) && !excludedApplicationKeys.includes(key)) {
        const valueId = application[key];

        let configName = key;
        if (key.includes('_ids')) {
          configName = convertConfigIdsNameToName(key);
        }

        const resourcesKey = resourcesMapping.hasOwnProperty(configName)
          ? resourcesMapping[configName]
          : configName;
        if (valueId && resources[resourcesKey]) {
          const valueResource = resources[resourcesKey].find(
            (resource: StandardResource) => valueId === resource.id
          )?.name;
          mappedApplication[configName] = valueResource;
        }
      }
    });
    return {
      ...mappedApplication,
      analyses: analyses?.filter(analysis => analysis.reference_id === application.application_id),
    };
  });
};

export const extendedApplicationAnalyses = createSelector(
  [getApplicationAnalyses, getAnalysisResources],
  (analyses, analysisResources) => {
    if (!analyses) return;
    if (!analysisResources) return;

    return analyses
      .filter((analysis: Analysis) => LIST_ANALYSIS_IDS.includes(analysis.type_id))
      .map((analysis: Analysis) => {
        const resource = analysisResources.analysis_types.find(
          (type: AnalysisType) => type.id === analysis.type_id
        );
        if (analysis.type_id === 209) {
          const key = Object.keys(analysis.details_json || {})[0];
          if (key) {
            const name = getConfigName(key);
            return { ...analysis, ...resource, details_json: { ...analysis.details_json, name } };
          }
        }

        return { ...analysis, ...resource };
      });
  }
);

export const extendedItemApplicationAnalyses = createSelector(
  [getItemApplicationAnalyses, getAnalysisResources],
  (analyses, analysisResources) => {
    if (!analyses) return;

    return analyses
      .filter((analysis: Analysis) => LIST_ANALYSIS_IDS.includes(analysis.type_id))
      .map((analysis: Analysis) => {
        const resource = analysisResources.analysis_types.find(
          (type: AnalysisType) => type.id === analysis.type_id
        );
        if (analysis.type_id === 209) {
          const key = Object.keys(analysis.details_json || {})[0];
          if (key) {
            const name = getConfigName(key);
            return { ...analysis, ...resource, details_json: { ...analysis.details_json, name } };
          }
        }
        return { ...analysis, ...resource };
      });
  }
);

export const mappedApplicationList = createSelector(
  [getApplications, getApplicationResources, extendedApplicationAnalyses],
  (applications, resources, analyses) => {
    return mapApplicationResources(applications, resources as Vehicle, analyses);
  }
);

export const mappedItemApplicationList = createSelector(
  [getItemApplications, getApplicationResources, extendedItemApplicationAnalyses],
  (applications, resources, analyses) => {
    return mapApplicationResources(applications, resources as Vehicle, analyses);
  }
);

export const mappedItemApplicationGridList = createSelector(
  [getItemGridApplications, getApplicationResources, extendedItemApplicationAnalyses],
  (applications, resources, analyses) => {
    return mapApplicationResourcesGrid(applications, resources as Vehicle, analyses);
  }
);

export const mappedItemApplicationGridGroupRows = createSelector(
  [getItemGridApplicationGroupData, getApplicationResources],
  (applications, resources) => {
    return mapApplicationResourcesGridGroupData(applications, resources as Vehicle);
  }
);
