import { Progress } from 'antd';
import React from 'react';

type StartGuideProgressBarProps = {
  progress: number;
};

const StartGuideProgressBar: React.FC<StartGuideProgressBarProps> = ({ progress }) => {
  return (
    <React.Fragment>
      <div style={{ width: `${progress}%` }} className="text-right px-1">{`${progress}%`}</div>
      <Progress percent={progress} showInfo={false} size="small" strokeLinecap="square" />
    </React.Fragment>
  );
};

export default StartGuideProgressBar;
