import { FluxStandardAction } from 'redux-promise-middleware';
import { Reducer } from 'redux';
import { UsedExtendedInfo, ExtendedInfo, TypeOptions } from '../../../types/extendedInfo';

export type ExtendedInfoState = {
  fetchingExpi: boolean;
  extendedInfos: ExtendedInfo[];
  typesOptions: TypeOptions[];
  usedExtendedInformationTypes: UsedExtendedInfo[];
  updateExpiPending: boolean;
  typeBrandId?: number;
};

const initialState = {
  fetchingExpi: false,
  updateExpiPending: false,
  extendedInfos: [],
  typesOptions: [],
  usedExtendedInformationTypes: [],
  typeBrandId: undefined,
};

const reducer: Reducer<ExtendedInfoState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EXTENDED_INFORMATIONS_PENDING': {
      return { ...state, fetchingExpi: true, extendedInfos: [] };
    }
    case 'FETCH_EXTENDED_INFORMATIONS_FULFILLED': {
      return { ...state, fetchingExpi: false, extendedInfos: action.payload.data };
    }
    case 'UPDATE_EXTENDED_INFORMATION_PENDING': {
      return { ...state, updateExpiPending: true };
    }
    case 'UPDATE_EXTENDED_INFORMATION_FULFILLED': {
      return { ...state, updateExpiPending: false, extendedInfos: action.payload.data };
    }
    case 'FETCH_EXTENDED_INFORMATION_TYPES_OPTIONS_FULFILLED': {
      return {
        ...state,
        typesOptions: action.payload.data,
        typeBrandId: action.meta.brandId,
      };
    }
    case 'FETCH_EXTENDED_INFORMATION_USED_TYPES_FULFILLED': {
      return {
        ...state,
        usedExtendedInformationTypes: action.payload.data,
        typeBrandId: action.meta.brandId,
      };
    }
  }
  return state;
};

export default reducer;
