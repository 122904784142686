import React from 'react';
import { Tag } from 'antd';
import headerConstants from '../../../constants/HeaderTranslation.json';
import applConstants from '../../../constants/ApplicationTranslation.json';
import { COUNTRY_CODES } from '../../../constants/ExtendedInfoConstants';
import { FilterCustomReference, FilterTypeGo } from '../../../../types/filter';
import HorizontalScroll from '../../global/HorizontalScroll';
import { SubBrand } from '../../../../types/brand';
import { Resources } from '../../../../types/resources';
import { AvailableReceiver } from '../../../../types/receiver';
import { AvailableChannel } from '../../../../types/channel';
import { BrandCustomField } from '../../../../types/custom_fields';

const applicationConstants = applConstants as { [key: string]: any };
const constants = headerConstants as { [key: string]: any };

const FILTER_CATEGORY = 'filter_category';
const FILTER_APPLICATION = 'filter_application';
const FILTER_ANALYSIS = 'filter_analysis';
const FILTER_CUSTOM = 'filter_custom';
const FILTER_SECTIONS = [FILTER_CATEGORY, FILTER_APPLICATION, FILTER_ANALYSIS, FILTER_CUSTOM];

type CatalogueFilterPreviewProps = {
  selectedFilter: FilterTypeGo;
  resources: Resources;
  subBrands: SubBrand[];
  disabled?: boolean;
  brandCustomFields?: BrandCustomField[];
  availableReceivers?: AvailableReceiver[];
  availableChannels?: AvailableChannel[];
  handleDeleteFilterParameter: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => void;
};

class CatalogueFilterPreview extends React.Component<CatalogueFilterPreviewProps> {
  parameterIsBool = (customReferenceId: FilterCustomReference, referenceId: number) => {
    const { resources, brandCustomFields } = this.props;
    if (customReferenceId === FilterCustomReference.UNIVERSAL_PART) return true;
    if (customReferenceId === FilterCustomReference.CUSTOM_FIELDS) {
      const customField = brandCustomFields?.find(field => field.id === referenceId);
      // custom field can be deleted but stays in filter
      return customField ? customField.custom_field_type_id === 4 : false;
    }
    if (customReferenceId === FilterCustomReference.EXTENDED_INFORMATIONS) {
      const groups = resources.extended_information.groups;
      const allExpi = groups.map(group => group.types).reduce((a, b) => a.concat(b), []);
      const expi = allExpi.find(expi => expi.id === referenceId);
      return expi?.format === 'BOOL';
    }
    return false;
  };

  getExpi = (id: number) => {
    const { resources } = this.props;
    const groups = resources.extended_information.groups;
    const allExpi = groups.map(group => group.types).reduce((a, b) => a.concat(b), []);
    return allExpi.find(expi => expi.id === id);
  };

  getExpiName = (id: number) => {
    const expi = this.getExpi(id);
    return expi?.name;
  };

  getExpiValueName = (id: number, code: string) => {
    const { resources } = this.props;
    const expi = this.getExpi(id);
    if (expi?.format === 'ARRAY' || expi?.format === 'BOOL') {
      const value = COUNTRY_CODES.includes(expi.code)
        ? resources.global.countries.find(opt => opt.code === code)
        : expi.options.find(opt => opt.code === code);
      return value?.name !== value?.code ? `${value?.code} - ${value?.name}` : value?.name;
    }
    return code;
  };

  getSubBrandName = (id: number) => {
    const { subBrands } = this.props;
    const subBrand = subBrands.find(brand => brand.id === id);

    return (subBrand && subBrand.name) || id;
  };

  getCustomFilterName = (customReferenceId: number, referenceId: number) => {
    if (
      [
        FilterCustomReference.UNIVERSAL_PART,
        FilterCustomReference.POPULATION_STATUS,
        FilterCustomReference.SUB_BRAND,
      ].includes(customReferenceId)
    )
      return constants.filter_custom[customReferenceId];
    if (
      [FilterCustomReference.SUBMITTED_RECEIVER, FilterCustomReference.ONLINE_RECEIVER].includes(
        customReferenceId
      ) &&
      this.props.availableReceivers
    )
      return this.props.availableReceivers.find(({ id }) => id === referenceId)?.name;
    if (
      [FilterCustomReference.SUBMITTED_CHANNEL, FilterCustomReference.ONLINE_CHANNEL].includes(
        customReferenceId
      )
    )
      return this.props.availableChannels?.find(({ id }) => id === referenceId)?.name;
    if (customReferenceId === FilterCustomReference.CUSTOM_FIELDS)
      return this.getCustomFieldName(referenceId);
    if (customReferenceId === FilterCustomReference.EXTENDED_INFORMATIONS)
      return this.getExpiName(referenceId);
  };

  getCustomFieldName = (id: number) => {
    const customField = this.props.brandCustomFields?.find(customField => customField.id === id);
    // custom field can be deleted but stays in filter
    return customField ? customField.name : '';
  };

  getCustomFilterValue = (
    customReferenceId: number,
    referenceId: number,
    value: { value: string }
  ) => {
    if (this.parameterIsBool(customReferenceId, referenceId)) {
      if (customReferenceId === FilterCustomReference.EXTENDED_INFORMATIONS) {
        // expi boolean is Yes and No instead of true and false
        return this.getExpiValueName(referenceId, value.value);
      }
      return Number(value.value) ? constants.filter_custom.true : constants.filter_custom.false;
    }
    if (customReferenceId === FilterCustomReference.POPULATION_STATUS) {
      const status = this.props.resources.item.population_statuses.find(
        state => state.id === Number(value.value)
      );
      return status?.name;
    }
    if (customReferenceId === FilterCustomReference.EXTENDED_INFORMATIONS) {
      return this.getExpiValueName(referenceId, value.value);
    }
    if (customReferenceId === FilterCustomReference.SUB_BRAND) {
      return this.getSubBrandName(Number(value.value));
    }
    return value.value;
  };

  parameterValues = (
    sectionKey: string,
    values: { [x: string]: any },
    optionId?: number,
    customReferenceId?: number,
    referenceId?: number | null
  ) => {
    if (
      customReferenceId &&
      [FilterCustomReference.SUBMITTED_CHANNEL, FilterCustomReference.SUBMITTED_RECEIVER].includes(
        customReferenceId
      )
    )
      return optionId === 3
        ? constants.filter_custom.submitted
        : constants.filter_custom.not_submitted;
    if (
      customReferenceId &&
      [FilterCustomReference.ONLINE_CHANNEL, FilterCustomReference.ONLINE_RECEIVER].includes(
        customReferenceId
      )
    )
      return optionId === 1 ? constants.filter_custom.online : constants.filter_custom.offline;
    const mappedValues = Object.keys(values).map(valueKey => {
      const value = values[valueKey];
      const name =
        sectionKey === FILTER_CUSTOM
          ? this.getCustomFilterValue(customReferenceId!, referenceId!, value)
          : value.category_name || value.resource_name || value.analysis_name || value.value;
      return name;
    });
    return mappedValues.join(', ');
  };

  getOptionName = (customReferenceId: number, optionName: string) => {
    if (
      [
        FilterCustomReference.SUBMITTED_CHANNEL,
        FilterCustomReference.SUBMITTED_RECEIVER,
        FilterCustomReference.ONLINE_CHANNEL,
        FilterCustomReference.ONLINE_RECEIVER,
      ].includes(customReferenceId)
    )
      return 'is';
    return optionName;
  };

  parameter = (
    parameters: {
      option_id: number;
      option_name: string;
      resource?: string;
      filter_custom_reference_id?: number;
      level?: number;
      reference_id?: number | null;
      values: any[];
    }[],
    sectionKey: string
  ) =>
    parameters.map(parameter => {
      // hide tags (visible in filter tag component)
      if (FilterCustomReference.TAGS === parameter.filter_custom_reference_id) return;
      let name = '';
      if (sectionKey === FILTER_APPLICATION) name = applicationConstants[parameter.resource!];
      if (sectionKey === FILTER_CATEGORY) name = constants[sectionKey][parameter.level!];
      if (sectionKey === FILTER_ANALYSIS) name = constants[sectionKey][parameter.reference_id!];
      if (sectionKey === FILTER_CUSTOM)
        name = this.getCustomFilterName(
          parameter.filter_custom_reference_id!,
          parameter.reference_id!
        );

      const paramterName = name || parameter.resource;

      return (
        <Tag
          key={name + parameter.reference_id + parameter.filter_custom_reference_id}
          closable={!this.props.disabled}
          color="blue"
          className="tag"
          onClose={() =>
            this.props.handleDeleteFilterParameter({
              sectionKey,
              referenceId: parameter.reference_id,
              resource: parameter.resource,
              level: parameter.level,
              filterCustomReferenceId: parameter.filter_custom_reference_id,
            })
          }
        >
          {paramterName}{' '}
          {parameter.filter_custom_reference_id
            ? this.getOptionName(parameter.filter_custom_reference_id, parameter.option_name)
            : parameter.option_name}{' '}
          {this.parameterValues(
            sectionKey,
            parameter.values,
            parameter.option_id,
            parameter.filter_custom_reference_id,
            parameter.reference_id
          )}
        </Tag>
      );
    });

  showFilterTags = () => {
    const { selectedFilter } = this.props;
    if (selectedFilter) {
      return Object.keys(selectedFilter).map(filterSection => {
        if (
          FILTER_SECTIONS.includes(filterSection) &&
          selectedFilter[filterSection as keyof FilterTypeGo]
        ) {
          const sectionFilter = selectedFilter[filterSection as keyof FilterTypeGo];
          const sectionParameters =
            (Array.isArray(sectionFilter) && sectionFilter[0].parameters) || [];
          if (sectionParameters.length > 0) {
            return this.parameter(sectionParameters, filterSection);
          }
        }
      });
    }
  };

  render() {
    return (
      <div className="catalogue-filter-tags">
        <HorizontalScroll>{this.showFilterTags()}</HorizontalScroll>
      </div>
    );
  }
}

export default CatalogueFilterPreview;
