import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { url as helpCenterUrl, articles } from '../../../../constants/HelpCenterConstants.json';
import { getSelectedItems } from '../../../../selectors/catalogue/catalogueSelector';
import { createEbayOffers } from '../../../../actions/items/ebay/create';
import { ApplicationState } from '../../../../reducers';
import { AsyncDispatch } from '../../../../../types/global';

type EbayBulkListModalProps = {
  showModal: boolean;
  updateFitment?: boolean;
  onClose: () => void;
};

const EbayBulkListModal: React.FC<EbayBulkListModalProps> = ({
  showModal,
  updateFitment,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItems,
    selectedItemIds,
    filterId,
    itemsSummary,
    allItemsSelected,
    reverseSelected,
    reversedItemIds,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      selectedItemIds: state.catalogue.catalogue.selectedItemIds,
      filterId: state.catalogue.filter.filterGo?.id,
      itemsSummary: state.catalogue.catalogue.itemsSummary,
      allItemsSelected: state.catalogue.catalogue.allItemsSelected,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    };
  });

  const [selectedItemCount, setSelectedItemCount] = React.useState<number>();

  React.useEffect(() => {
    if (showModal) {
      if (!allItemsSelected && !reverseSelected) {
        setSelectedItemCount(selectedItems.length);
      } else {
        const itemCount = reverseSelected ? itemsSummary - reversedItemIds.length : itemsSummary;
        setSelectedItemCount(itemCount);
      }
    }
  }, [allItemsSelected, itemsSummary, reverseSelected, reversedItemIds, selectedItems, showModal]);

  const handleCancel = () => {
    onClose();
  };

  const handleListOnEbay = () => {
    if (!allItemsSelected && !reverseSelected) {
      dispatch(
        // @ts-ignore
        createEbayOffers({
          itemIds: selectedItemIds,
          ...(updateFitment && { updateOnlyFitments: 1 }),
        })
      );
    } else {
      dispatch(
        // @ts-ignore
        createEbayOffers({
          filterId:
            (allItemsSelected && selectedItems.length >= 100) || reverseSelected
              ? filterId
              : undefined,
          withoutItemdIds: reversedItemIds,
          itemIds:
            (!allItemsSelected || (allItemsSelected && selectedItemIds.length < 100)) &&
            !reverseSelected
              ? selectedItemIds
              : undefined,
          ...(updateFitment && { updateOnlyFitments: 1 }),
        })
      );
    }
    onClose();
  };

  return (
    <Modal
      open={showModal}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="cancel" onClick={() => handleCancel()}>
          {t('common:cancel')}
        </Button>,
        <Button key="publish" type="primary" onClick={() => handleListOnEbay()}>
          {updateFitment ? t('exporter:updateNow') : t('exporter:publishNow')}
        </Button>,
      ]}
      closable={false}
    >
      <div className="text-base font-medium">
        <QuestionCircleOutlined className="text-xl icon__blue mr-3" />
        {updateFitment
          ? t('exporter:updateSelectedItemsFitment')
          : t('exporter:publishSelectedItemsEbay')}
      </div>
      <div className="mt-2 ml-8 italic text-gray-700">
        {`(${selectedItemCount} ${t('exporter:itemsSelected')})`}
      </div>
      {!updateFitment && <div className="mt-2 ml-8">{t('exporter:ebayPublishInfo')}</div>}
      <div className="mt-2 ml-8 italic text-xs">
        {`${t('common:learnMoreAbout')} `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={helpCenterUrl + articles.data_distribution}
        >
          {t('exporter:sharingData')}
        </a>
        {` ${t('common:and')} `}
        <a target="_blank" rel="noopener noreferrer" href={helpCenterUrl + articles.publish_data}>
          {t('exporter:syncData')}
        </a>
      </div>
    </Modal>
  );
};

export default EbayBulkListModal;
