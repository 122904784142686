import React from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EBPFilterField from './EBPFilterField';
import { ExportBuilderAdvancedSegmentField } from '../../../../types/export_builder_advanced';
import EBPFilterFormTagSelect from './EBPFilterFormTagSelect';
import { ApplicationState } from '../../../reducers';
import { isReceiver } from '../../../utils/UserUtils';

const ExportBuilderPlusFilterFields: React.FC = () => {
  const { t } = useTranslation();
  const { receiver } = useSelector((state: ApplicationState) => ({
    receiver: state.user.user && isReceiver(state.user.user),
  }));

  const getAttributesTooltip = () =>
    receiver
      ? t('exportBuilderAdvanced:attributeIdentifierInfoReceiver')
      : t('exportBuilderAdvanced:attributeIdentifierInfo');

  return (
    <FieldArray validateOnChange={false} name="uniqueIdentifiers">
      {arrayHelpers => {
        const { form, replace } = arrayHelpers;
        const { values } = form;
        const { uniqueIdentifiers } = values;

        return (
          <React.Fragment>
            {uniqueIdentifiers.map((field: ExportBuilderAdvancedSegmentField, index: number) => {
              if (!field.filterable)
                return (
                  <EBPFilterFormTagSelect
                    name={`uniqueIdentifiers[${index}]`}
                    label={field.name}
                    className="eba__identifier w-1/2"
                    handleChange={valueArray => {
                      replace(index, {
                        ...values.uniqueIdentifiers[index],
                        values: valueArray,
                      });
                    }}
                    key={field.id}
                    info={field.code === 'attribute' ? getAttributesTooltip() : undefined}
                  />
                );

              return (
                <EBPFilterField
                  handleChange={valAsArray => {
                    replace(index, {
                      ...values.uniqueIdentifiers[index],
                      values: valAsArray,
                    });
                  }}
                  options={field.options ? field.options : []}
                  label={field.name}
                  name={`uniqueIdentifiers[${index}]`}
                  key={field.id}
                />
              );
            })}
          </React.Fragment>
        );
      }}
    </FieldArray>
  );
};

export default ExportBuilderPlusFilterFields;
