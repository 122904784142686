import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, message, Select, TreeSelect, Alert } from 'antd';
import { FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  MarketingCopy,
  MarketingCopyCodeId,
  MarketingCopySubCodeId,
} from '../../../../types/marketing_copy';
import TextEditor from '../../global/TextEditor';
import {
  fetchMarketingCopyItemCount,
  fetchMarketingCopyPartTypes,
} from '../../../actions/parent/marketing_copy/fetch';
import { ApplicationState } from '../../../reducers';
import marketingCopyActions from '../../../actions/parent/marketing_copy';
import MarketingCopyEditGroupDrawer from './MarketingCopyEditGroupDrawer';
import { setFile, setUploadInformation } from '../../../actions/parent/digital_asset_upload';
import { MODAL_TYPES } from '../../../constants/ModalConstants';
import { showModal } from '../../../actions/app/modal';
import Image from '../../global/Image';
import { AsyncDispatch } from '../../../../types/global';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import MGroupTreeSelect from './MGroupTreeSelect';
import { fetchMarketingCopies } from '../../../actions/items/description/fetch';
import { showParentDigitalAssets } from '../../../actions/parent/digital_asset';
import ParentDigitalAssetsDrawer from '../../../containers/digital_assets/ParentDigitalAssetsDrawer';
import { intercomEvent } from '../../../utils/IntercomUtils';

const { TreeNode } = TreeSelect;
const { Option } = Select;

const validationSchema = Yup.object().shape({
  selectedId: Yup.string().required('Required'),
});

type MarketingCopyEditDrawerProps = {
  visible: boolean;
  canManageMarketingCopy: boolean;
  isNew?: boolean;
  marketingCopy?: MarketingCopy;
  closeDrawer: () => void;
  alert?: boolean;
  itemId?: number;
};

const MarketingCopyEditDrawer: React.FC<MarketingCopyEditDrawerProps> = props => {
  const { marketingCopy, isNew } = props;
  const [groupDrawerIsOpen, setGroupDrawerIsOpen] = React.useState(false);
  const [codeId, setCodeId] = React.useState<number | null>(null);

  const fileInput: any = React.useRef();
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const maxLength = 2000;

  const parent = useSelector((state: ApplicationState) => state.parent.marketingCopy.parent);
  const partTypes = useSelector((state: ApplicationState) => state.parent.marketingCopy.partTypes);
  const uploadedDigitalAssets = useSelector(
    (state: ApplicationState) => state.parent.marketingCopy.uploadedDigitalAssets
  );
  const fetchingItemCounts = useSelector(
    (state: ApplicationState) => state.parent.marketingCopy.fetchingItemCount
  );
  const itemCounts = useSelector(
    (state: ApplicationState) => state.parent.marketingCopy.marketingCopiesItemCounts
  );
  const languages = useSelector((state: ApplicationState) => state.resources.data.global.languages);
  const showParentDigitalAssetsDrawer = useSelector(
    (state: ApplicationState) => state.parent.digitalAsset.showParentDigitalAssets
  );

  const itemCount = itemCounts.find(
    count => count.marketing_copy_id === props.marketingCopy?.id
  )?.mapped_items;

  const fetchPartTypes = (keywords?: string) => {
    // If undefined returns all part_types of parent.
    const marketingCopyId = marketingCopy ? marketingCopy.id : undefined;
    dispatch(fetchMarketingCopyPartTypes(marketingCopyId, undefined, keywords));
  };

  React.useEffect(() => {
    if (marketingCopy && marketingCopy.id) {
      dispatch(fetchMarketingCopyPartTypes(marketingCopy.id));
      dispatch(fetchMarketingCopyItemCount(marketingCopy.id));
    }
  }, [dispatch, marketingCopy]);

  const handleCloseDrawer = async () => {
    await dispatch(marketingCopyActions.resetUploadedDigitalAssets());
    if (props.itemId) {
      dispatch(fetchMarketingCopies(props.itemId));
    }
    props.closeDrawer();
  };

  const handleSubmit = async (values: FormikValues, isNew?: boolean) => {
    intercomEvent('viewed-company-marketing-copy', {
      action: 'marketing-copy-saved',
    });

    if (isNew) {
      try {
        await dispatch(
          marketingCopyActions.createMarketingCopy({
            codeId,
            referenceId: values.selectedId,
            subReferenceId:
              values.selectedGroupId ||
              values.selectedSubGroupId ||
              values.selectedPartTypeId ||
              null,
            subCodeId: values.subCodeId || null,
            contents: values.contents,
            assets: [...uploadedDigitalAssets, ...values.assets],
            languageId: values.languageId,
          })
        );
        return handleCloseDrawer();
      } catch (e) {
        message.error(t('marketing:error.alreadyExists'));
      }
    } else {
      try {
        await dispatch(
          marketingCopyActions.updateMarketingCopy({
            id: marketingCopy!.id,
            codeId,
            referenceId:
              marketingCopy?.reference_id !== values.selectedId ? values.selectedId : null,
            subReferenceId:
              values.selectedGroupId ||
              values.selectedSubGroupId ||
              values.selectedPartTypeId ||
              null,
            subCodeId: values.subCodeId || null,
            contents: values.contents,
            assets: [...uploadedDigitalAssets, ...values.assets],
            languageId: values.languageId,
          })
        );
        return handleCloseDrawer();
      } catch (e) {
        message.error(t('marketing:error.alreadyExists'));
      }
    }
  };

  const handleAddByKey = (key: string, field: string) => {
    dispatch(setUploadInformation(key, field));
    dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD));
  };

  return (
    <React.Fragment>
      <DrawerFormik
        visible={props.visible}
        onClose={() => handleCloseDrawer()}
        title={
          <div className="flex flex-1">
            <div className="pr-2">{t('marketing:marketingCopy')}</div>
            <div className="mc-drawer__products-alert">
              {props.alert && (
                <Alert
                  message={
                    <span>
                      {`${t('description:editInfo')} `}
                      {fetchingItemCounts ? <LoadingOutlined /> : itemCount}
                      {` ${t('description:products')}`}
                    </span>
                  }
                  type="info"
                  showIcon
                />
              )}
            </div>
          </div>
        }
        width="800"
        initialValues={{
          selectedId: !isNew ? marketingCopy?.reference_id : undefined,
          selectedPartTypeId:
            !isNew && marketingCopy?.sub_code_id === MarketingCopySubCodeId.PART_TYPE
              ? marketingCopy?.sub_reference_id
              : undefined,
          selectedGroupId:
            !isNew && marketingCopy?.sub_code_id === MarketingCopySubCodeId.GROUP
              ? marketingCopy?.sub_reference_id
              : null,
          selectedSubGroupId:
            !isNew && marketingCopy?.sub_code_id === MarketingCopySubCodeId.SUB_GROUP
              ? marketingCopy?.sub_reference_id
              : null,
          assets: !isNew ? marketingCopy?.assets || [] : [],
          contents: !isNew ? marketingCopy?.contents : [],
          languageId: !isNew ? marketingCopy?.language_id : undefined,
          subCodeId: !isNew ? marketingCopy?.sub_code_id : undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleSubmit(values, isNew).then(() => setSubmitting(false));
        }}
        handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
          props.canManageMarketingCopy && (formik.dirty || uploadedDigitalAssets.length > 0)
        }
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <Form onFinish={() => handleSubmit()} layout="vertical">
            <Form.Item
              label={t('marketing:labels.parentOwnerBrand')}
              required
              hasFeedback
              validateStatus={errors.selectedId ? 'error' : ''}
              help={errors.selectedId ? errors.selectedId : undefined}
            >
              <TreeSelect
                data-testid="select-brand"
                value={values.selectedId}
                placeholder={t('marketing:placeholder.selectParentOwnerBrand')}
                allowClear
                treeDefaultExpandAll
                onChange={(val, title) => {
                  const titleString = title.join('');
                  if (titleString.includes('Parent')) {
                    setCodeId(MarketingCopyCodeId.PARENT);
                  } else if (titleString.includes('Owner')) {
                    setCodeId(MarketingCopyCodeId.OWNER);
                  } else if (titleString.includes('Subbrand')) {
                    setCodeId(MarketingCopyCodeId.SUB_BRAND);
                  } else if (titleString.includes('Brand')) {
                    setCodeId(MarketingCopyCodeId.BRAND);
                  } else if (!val) {
                    setCodeId(null);
                  }
                  setFieldValue('selectedId', val);
                }}
              >
                <TreeNode
                  value={parent.id}
                  title={`Parent: ${parent.name}`}
                  key={parent.id}
                  data-testid="parentNode"
                >
                  {parent.owners?.map((owner: any) => {
                    return (
                      <TreeNode value={owner.id} title={`Owner: ${owner.name}`} key={owner.id}>
                        {owner.brands?.map((brand: any) => {
                          return (
                            <TreeNode
                              value={brand.id}
                              title={`Brand: ${brand.name}`}
                              key={brand.id}
                            >
                              {brand.sub_brands?.map((subbrand: any) => {
                                return (
                                  <TreeNode
                                    value={subbrand.id}
                                    title={`Subbrand: ${subbrand.name}`}
                                    key={subbrand.id}
                                  />
                                );
                              })}
                            </TreeNode>
                          );
                        })}
                      </TreeNode>
                    );
                  })}
                </TreeNode>
              </TreeSelect>
            </Form.Item>

            <div className="flex justify-between">
              <div className="flex-1 mc-drawer__part-type">
                <Form.Item label="Part Type">
                  <Select
                    value={values.selectedPartTypeId}
                    placeholder={t('marketing:placeholder.selectPartType')}
                    filterOption={false}
                    onSearch={val => {
                      fetchPartTypes(val);
                    }}
                    showSearch
                    onChange={(val: any) => {
                      setFieldValue('subCodeId', val ? MarketingCopySubCodeId.PART_TYPE : null);
                      setFieldValue('selectedPartTypeId', val);
                    }}
                    allowClear
                    disabled={!!values.selectedGroupId}
                  >
                    {partTypes.map((p: any) => (
                      <Option key={p.id} value={p.id}>
                        {p.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="flex-col flex-1">
                <Form.Item label={t('marketing:labels.group')}>
                  <MGroupTreeSelect
                    selectedGroupId={values.selectedGroupId}
                    selectedSubGroupId={values.selectedSubGroupId}
                    onChange={({ groupId, subGroupId }) => {
                      setFieldValue(
                        'subCodeId',
                        (subGroupId && MarketingCopySubCodeId.SUB_GROUP) ||
                          (groupId && MarketingCopySubCodeId.GROUP) ||
                          null
                      );
                      setFieldValue('selectedGroupId', groupId || null);
                      setFieldValue('selectedSubGroupId', subGroupId || null);
                    }}
                    disabled={!!values.selectedPartTypeId}
                  />
                  <div className="flex justify-end mt-1">
                    <Button size="small" onClick={() => setGroupDrawerIsOpen(true)}>
                      {t('marketing:actions.manageGroups')}
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </div>
            <Form.Item label={t('marketing:labels.digitalAsset')}>
              {(values.assets && values.assets.length && values.assets[0].asset_url) ||
              uploadedDigitalAssets.length ? (
                <Image
                  asset={
                    values.assets && values.assets.length && values.assets[0].asset_url
                      ? {
                          asset_url: values.assets[0].asset_url,
                          file_type_id: values.assets[0].file_type_id,
                        }
                      : uploadedDigitalAssets[0]
                  }
                  handleDelete={() => {
                    dispatch(marketingCopyActions.resetUploadedDigitalAssets());
                    setFieldValue('assets', []);
                  }}
                />
              ) : (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: t('marketing:actions.addByUrl'),
                        key: 'url',
                        onClick: () => handleAddByKey('url', 'marketing_copy'),
                      },
                      {
                        label: t('marketing:actions.linkRecordInPdm'),
                        key: 'parent',
                        onClick: () => dispatch(showParentDigitalAssets(true)),
                      },
                      {
                        label: (
                          <label
                            style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                            }}
                          >
                            <input
                              className="digital-asset__file-input"
                              id="digital-asset__file-input"
                              type="file"
                              onClick={() => {
                                if (fileInput.value) fileInput.value = '';
                              }}
                              onChange={e => {
                                if (e.target.files) {
                                  const file = e.target.files[0];
                                  dispatch(setFile(file));
                                  handleAddByKey('local', 'marketing_copy');
                                }
                              }}
                              ref={fileInput}
                            />
                            {t('marketing:actions.uploadFromPC')}
                          </label>
                        ),
                        key: 'local',
                      },
                      {
                        label: t('marketing:actions.linkToExternalSource'),
                        key: 'external',
                        onClick: () => handleAddByKey('external', 'marketing_copy'),
                      },
                    ],
                  }}
                >
                  <Button>
                    <PlusOutlined /> {t('marketing:actions.uploadAsset')}
                  </Button>
                </Dropdown>
              )}
            </Form.Item>
            <div>
              <div className="flex justify-between items-center">
                <div className="ant-form-item-label">
                  <label>{t('marketing:labels.marketingText')}</label>
                </div>

                <Select
                  style={{ width: 200 }}
                  placeholder={t('marketing:placeholder.selectLanguage')}
                  value={values.languageId}
                  size="small"
                  showSearch
                  filterOption={(input, option) =>
                    // @ts-ignore
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val: number) => setFieldValue('languageId', val)}
                >
                  {languages.map(l => (
                    <Option value={l.id} key={l.id}>
                      {l.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <TextEditor
                autoSubmit
                contents={values.contents.map((c: any) => {
                  return {
                    content: c.content,
                    type: c.type_id === 1 ? 'list-item' : 'block',
                  };
                })}
                placeholder={t('marketing:placeholder.addMarketingText')}
                submit={(val: any) => {
                  const newContents = val.map((entry: any, index: number) => ({
                    content: entry.content,
                    id: entry.id,
                    type_id: entry.type === 'list-item' ? 1 : 2,
                    record_number: index + 1,
                  }));
                  setFieldValue('contents', newContents);
                }}
                styleControls
                maxLength={maxLength}
              />
            </div>
          </Form>
        )}
      </DrawerFormik>

      <MarketingCopyEditGroupDrawer
        isOpen={groupDrawerIsOpen}
        canManageMarketingCopy={!!props.canManageMarketingCopy}
        closeDrawer={() => setGroupDrawerIsOpen(false)}
      />
      {showParentDigitalAssetsDrawer && <ParentDigitalAssetsDrawer area="marketing_copy" />}
    </React.Fragment>
  );
};

export default MarketingCopyEditDrawer;
