import React from 'react';
import { Input, Tooltip } from 'antd';
import { InputProps } from 'antd/lib/input';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { checkKeywordLength, typingDone } from '../../../utils/Utils';

type SearchInputProps = {
  value: string;
  className?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  testId?: string;
  suffix?: React.ReactNode;
  allowClear?: boolean;
  search?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onPressEnter?: () => void;
} & InputProps;

const SearchInput: React.FC<SearchInputProps> = props => {
  const {
    value,
    className,
    id,
    placeholder,
    disabled,
    size,
    testId,
    suffix,
    allowClear,
    search,
    onChange,
    onPressEnter,
  } = props;

  const { t } = useTranslation();

  const [minLengthReached, setMinLengthReached] = React.useState(checkKeywordLength(value));

  React.useEffect(() => {
    if (!value) {
      setMinLengthReached(true);
    } else {
      const sanitizedKeywords = value
        .split(' ')
        .map(keyword => keyword.trim().replace(/[^0-9A-Za-z.-]/g, ''));

      if (sanitizedKeywords.find(k => k.length >= 3)) {
        setMinLengthReached(true);
      } else {
        typingDone(() => {
          setMinLengthReached(false);
        }, 1000);
      }
    }
  }, [value]);

  return search ? (
    <Input.Search
      id={id}
      className={className}
      data-testid={testId}
      size={size}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      allowClear={allowClear}
      suffix={
        minLengthReached ? (
          suffix || <span />
        ) : (
          <Tooltip title={t('validation:searchLength')}>
            <WarningOutlined className="icon__yellow" />
          </Tooltip>
        )
      }
      status={minLengthReached ? undefined : 'warning'}
      onChange={onChange}
      onPressEnter={onPressEnter}
      onSearch={onPressEnter}
    />
  ) : (
    <Input
      id={id}
      className={className}
      data-testid={testId}
      size={size}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      allowClear={allowClear}
      suffix={
        minLengthReached ? (
          suffix || <span />
        ) : (
          <Tooltip title={t('validation:searchLength')}>
            <WarningOutlined className="icon__yellow" />
          </Tooltip>
        )
      }
      status={minLengthReached ? undefined : 'warning'}
      onChange={onChange}
      onPressEnter={onPressEnter}
    />
  );
};

export default SearchInput;
