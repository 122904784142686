import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { AsyncDispatch } from '../../../../types/global';
import { ExportLog } from '../../../../types/import_export';
import {
  fetchParentExport,
  fetchParentExportLogDetailsJson,
  fetchParentExportLogs,
  fetchParentExportLogDetailsExcel,
} from '../../../actions/parent/exportImport/fetch';
import ImportExportLogs from '../../../components/body/import_export/ImportExportLogs';
import { ApplicationState } from '../../../reducers';
import { parentExportLogMapper } from '../../../selectors/importExportLogsSelector';

const ParentExportLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const fileTypeId = useSelector((state: ApplicationState) => state.parent.parentLogs.fileTypeId);

  const { exportLogs, fetchingParentExportLogs, exportErrorLogDetails, fetchingErrorLog } =
    useSelector((state: ApplicationState) => {
      return {
        exportLogs: parentExportLogMapper(state) as ExportLog[],
        fetchingParentExportLogs: state.parent.parentExportLogs.fetchingParentExportLogs,
        exportErrorLogDetails: state.parent.parentExportLogs.exportErrorLogDetails,
        fetchingErrorLog: state.parent.parentExportLogs.fetchingErrorLog,
      };
    });

  React.useEffect(() => {
    dispatch(fetchParentExportLogs(fileTypeId));
  }, [dispatch, fileTypeId]);

  const saveExportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(
      blob,
      fileTypeId === 13
        ? 'translation_paramount_export_errors.xlsx'
        : 'vehicle-table_paramount_export_errors.xlsx'
    );
  };

  const handleErrorDownload = (logId: number) =>
    dispatch(fetchParentExportLogDetailsExcel(logId)).then(response =>
      saveExportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );

  const handleExportFileDownload = (logId: number) =>
    // @ts-ignore
    dispatch(fetchParentExport(logId));

  const handleFetchExportLogDetailsJSON = (logId: number) =>
    dispatch(fetchParentExportLogDetailsJson(logId));

  return (
    <ImportExportLogs
      logs={exportLogs}
      fetchingLogs={fetchingParentExportLogs}
      fetchingErrors={fetchingErrorLog}
      errorLogDetails={exportErrorLogDetails}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleExportFileDownload}
      handleFetchLogDetailsJSON={handleFetchExportLogDetailsJSON}
      exportLogs
    />
  );
};

export default ParentExportLogsContainer;
