import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { areas } from '../../constants/ParamountReactConstants';
import { filterOptions } from '../../constants/FilterConstants';
import { setActiveArea } from '../../actions/app/navigationBar';
import homeActions from '../../actions/brand/analyses';
import * as utils from '../../utils/Utils';
import AnalyseQualityHistory from '../../components/body/home/AnalyseQualityHistory';
import AnalyseQualitySegment from '../../components/body/home/AnalyseQualitySegment';
import AnalyseRecommendation from '../../components/body/home/AnalyseRecommendation';
import AnalyseLatestChanges from '../../components/body/home/AnalyseLatestChanges';
import AnalyseAverage from '../../components/body/home/AnalyseAverage';
import { getTempProductsFilter } from '../../selectors/catalogue/filterSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import {
  resetFilter,
  updateFilterGo,
  updateTempFilterItems,
} from '../../actions/catalogue/filter/update';
import {
  createAnalysisFilter,
  createFilterAnalysisParameter,
} from '../../actions/catalogue/filter/create';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import DashboardUpgradeModal from '../../components/body/home/DashboardUpgradeModal';
import { intercomEvent } from '../../utils/IntercomUtils';
import { convertToGoFilterStructure } from '../../utils/FilterUtils';
import { fetchFilterGo } from '../../actions/catalogue/filter/fetch';
import { StandardResource } from '../../../types/resources';

const HomeContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const navigate = useNavigate();

  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);

  const {
    tempProductsFilter,
    latestProductChanges,
    fetchingLatestProductChanges,
    brandId,
    brandCode,
    analysesHistory,
    averageSegmentRecom,
    user,
    itemCount,
    alertTypes,
    segments,
    goOnly,
  } = useSelector((state: ApplicationState) => {
    const tempProductsFilter = getTempProductsFilter(state);
    return {
      brandId: state.parent.brands.selectedBrandId,
      brandCode: getSelectedBrandCode(state),
      analysesHistory: state.brand.analyses.history,
      averageSegmentRecom: state.brand.analyses.averageSegmentRecom,
      latestProductChanges: state.brand.analyses.latestProductChanges,
      fetchingLatestProductChanges: state.brand.analyses.fetchingLatestProductChanges,
      user: state.user.user,
      itemCount: state.catalogue.catalogue.brandItemsSummary,
      alertTypes: state.resources.data.analysis.alert_types,
      segments: state.resources.data.global.segments,
      goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
      tempProductsFilter,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-home', {
      location: 'home-dashboard',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    dispatch(setActiveArea(areas.home));
  }, [dispatch]);

  React.useEffect(() => {
    if (brandId) {
      dispatch(homeActions.fetchHistoryAnalysis(brandId));
      dispatch(homeActions.fetchAverageSegmentRecomAnalysis(brandId));
      dispatch(homeActions.fetchLatestProductChangesAnalysis(brandId));
    }
  }, [dispatch, brandId]);

  const fetchNextItems = (event: any) => {
    const nextPage = utils.nextPage(event);
    if (nextPage && !fetchingLatestProductChanges) {
      dispatch(homeActions.fetchLatestProductChangesAnalysis(brandId, nextPage));
    }
  };

  const resetTempFilter = async () => {
    if (goOnly) return dispatch(resetFilter(tempProductsFilter?.id));
    await dispatch(resetFilter(tempProductsFilter?.id));
    return dispatch(updateTempFilterItems(tempProductsFilter?.id));
  };

  const visitAllProducts = () => {
    navigate(`../products/item?brandId=${brandId}&filterId=${tempProductsFilter.id}`);
  };

  const handleNavigation = async (errorTypeId: number, segmentId?: number) => {
    if (hasPermission(user, 'can_filter_by_dashboard')) {
      await resetTempFilter();

      if (goOnly) {
        const tempFilterResp = await dispatch(fetchFilterGo(tempProductsFilter.id));
        const tempFilter = tempFilterResp.value.data;

        dispatch(
          updateFilterGo({
            ...tempFilter,
            filter_analysis: [
              {
                and_condition: true,
                parameters: [
                  ...(segmentId
                    ? [
                        {
                          option_id: 1,
                          option_name: filterOptions[1],
                          reference_id: 1,
                          values: [
                            {
                              analysis_id: segmentId,
                              analysis_name: segments.find(s => s.id === segmentId)?.name,
                            },
                          ],
                        },
                      ]
                    : []),
                  {
                    option_id: 1,
                    option_name: filterOptions[1],
                    reference_id: 2,
                    values: [
                      {
                        analysis_id: errorTypeId,
                        analysis_name: alertTypes.find(
                          (t: StandardResource) => t.id === errorTypeId
                        )?.name,
                      },
                    ],
                  },
                ],
              },
            ],
          })
        );
      } else {
        const analysisFilter = await dispatch(createAnalysisFilter(tempProductsFilter?.id));
        const analysisFilterId = Object.keys(analysisFilter.value.data.filter.filter_analysis)[0];

        if (segmentId)
          await dispatch(createFilterAnalysisParameter(analysisFilterId, 1, 1, segmentId));
        const response = await dispatch(
          createFilterAnalysisParameter(analysisFilterId, 1, 2, errorTypeId)
        );

        dispatch(updateFilterGo(convertToGoFilterStructure(response.value.data.filter)));
      }

      intercomEvent('viewed-home', {
        location: 'home-dashboard',
        recommendation: alertTypes.find((t: StandardResource) => t.id === errorTypeId)?.name || '',
        brand_code: brandCode!,
      });

      visitAllProducts();
    } else {
      setShowUpgradeModal(true);
    }
  };

  const progress =
    analysesHistory.length > 0 ? analysesHistory[analysesHistory.length - 1].progress_score : null;
  const dataForDonut =
    analysesHistory.length > 0 ? analysesHistory[analysesHistory.length - 1] : {};

  return (
    <div className="home-container flex">
      <div className="home-container__left">
        <div className="analyse-data-quality">
          <AnalyseQualityHistory analysesHistory={analysesHistory} />
          <AnalyseAverage progress={progress} />
        </div>
        <AnalyseQualitySegment segments={averageSegmentRecom} handleNavigation={handleNavigation} />
      </div>
      <div className="home-container__right">
        <AnalyseRecommendation
          recommendationDonutData={dataForDonut}
          itemCount={itemCount}
          handleNavigation={handleNavigation}
        />
        <AnalyseLatestChanges
          items={latestProductChanges}
          fetching={fetchingLatestProductChanges}
          fetchNextItems={fetchNextItems}
        />
      </div>

      <DashboardUpgradeModal
        showModal={showUpgradeModal}
        firstName={user?.first_name}
        onCancel={() => setShowUpgradeModal(false)}
      />
    </div>
  );
};

export default HomeContainer;
