import { Checkbox, Col, Row } from 'antd';
import { Field } from 'formik';
import React from 'react';

const CheckboxGroup = Checkbox.Group;

export const getCheckable = elems => elems.filter(({ disabled }) => !disabled).map(({ id }) => id);

export default class FormCheckboxGroup extends React.Component {
  onCheckAllChange = selected => {
    const { elements, name, setFieldValue } = this.props;
    const checkableElements = getCheckable(elements);
    if (checkableElements.length === selected.length) {
      setFieldValue(name, []);
    } else {
      setFieldValue(
        name,
        checkableElements.map(({ id }) => id)
      );
    }
  };

  onChange = e => {
    this.props.setFieldValue(this.props.name, e);
  };

  render() {
    const { name, elements } = this.props;
    const checkableElements = getCheckable(elements);

    return (
      <Field name={name} label>
        {({ field }) => (
          <div>
            <div className="forms-checkbox-group">
              <Checkbox
                indeterminate={
                  field.value.length > 0 && field.value.length < checkableElements.length
                }
                onChange={() => this.onCheckAllChange(field.value)}
                checked={field.value.length === checkableElements.length}
              >
                Select all
              </Checkbox>
            </div>
            <CheckboxGroup onChange={this.onChange} value={field.value}>
              <Row>
                {elements.map(({ id, name: checkboxName, disabled }, i) => (
                  <Col span={12} key={i}>
                    <Checkbox value={id} disabled={disabled}>
                      {checkboxName}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </CheckboxGroup>
          </div>
        )}
      </Field>
    );
  }
}
