import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

/*
  Possible props to pass:
  - className: string that is aditionally added to default className
  - elements: array => [{id: "Int", name: "String"}]
  - onChange: function that is called onChange and returns the new selected Element
  - onSelect: function that is called when a new element is selected
  - onDeselect: function that is called when a selected element is deselected
  - onMouseEnter: function that is called when the mouse enters the component
  - onMouseLeave: function that is called when the mouse leaves the component
  - onFocus: function that is called when the component is put into focus
  - selectedElements: array of ids
  - placeholder: placeholder string
  - disabled: dropdown is disabled
  - showSearch: show search input
  - allowClear: selected value can be cleared
*/

export default class AntMultiSelect extends React.Component {
  render() {
    const {
      className,
      popupClassName,
      elements,
      onChange,
      onSelect,
      onDeselect,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      selectedElements,
      placeholder,
      size,
      disabled,
      showSearch,
      allowClear,
    } = this.props;

    return (
      <Select
        mode="multiple"
        value={selectedElements}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        placeholder={placeholder}
        size={size}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        className={className}
        popupClassName={popupClassName}
        getPopupContainer={trigger => (trigger && trigger.parentNode) || document.body}
      >
        {elements.map(element => (
          <Select.Option key={element.id} value={element.id}>
            {element.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

AntMultiSelect.propTypes = {
  // string that is aditionally added to default className
  className: PropTypes.string,
  // array => [{id: "Int", name: "String"}]
  elements: PropTypes.array,
  // function that is called onChange and returns the new selected Element
  onChange: PropTypes.func,
  // function that is called when a new element is selected
  onSelect: PropTypes.func,
  // function that is called when a selected element is deselected
  onDeselect: PropTypes.func,
  // function that is called when the mouse enters the component
  onMouseEnter: PropTypes.func,
  // function that is called when the mouse leaves the component
  onMouseLeave: PropTypes.func,
  // function that is called when the component is put into focus
  onFocus: PropTypes.func,
  // array of ids
  selectedElements: PropTypes.array,
  // placeholder string
  placeholder: PropTypes.string,
  // dropdown is disabled
  disabled: PropTypes.bool,
  // show search input
  showSearch: PropTypes.bool,
  // selected value can be cleared
  allowClear: PropTypes.bool,
};

AntMultiSelect.defaultProps = {
  elements: [],
  onChange: () => {},
  onSelect: () => {},
  onDeselect: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onFocus: () => {},
  className: '',
  selectedElements: [],
  placeholder: '',
  disabled: false,
  showSearch: false,
  allowClear: false,
};
