import React from 'react';
import { Form, Card, Input, Button } from 'antd';

import constants from '../../../constants/SettingsTranslation.json';
import SingleDefaultValue from './SingleDefaultValue.tsx';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { xl: { span: 24 }, xxl: { span: 4 } },
  wrapperCol: { xl: { span: 24 }, xxl: { span: 20 } },
};

const formItemCardLayout = {
  labelCol: { xl: { span: 24 }, xxl: { span: 8 } },
  wrapperCol: { xl: { span: 24 }, xxl: { span: 14 } },
};

class DefaultValuesPrice extends React.Component {
  state = {
    showTypeDescription: false,
  };

  handeShowTypeDescription = () => {
    this.setState(prevState => ({ showTypeDescription: !prevState.showTypeDescription }));
  };

  createDefaultPriceTypeDescription = (priceSheetId, priceTypeId, description) => {
    this.props.createDefaultPriceTypeDescription(priceSheetId, priceTypeId, description);
  };

  render() {
    const { values, priceSheets } = this.props;

    return (
      <React.Fragment>
        {values.map(value => (
          <SingleDefaultValue
            key={value.id}
            value={value}
            resources={this.props.resources}
            updateDefaultValue={this.props.updateDefaultValue}
          />
        ))}
        <FormItem
          key={1}
          className="setting-price-type-description"
          {...formItemLayout}
          label={constants.default_values.price_sheets_type_desc}
        >
          <Button type="primary" onClick={this.handeShowTypeDescription}>
            {constants.default_values.show_price_type_desc}
          </Button>
          {this.state.showTypeDescription && (
            <div className="setting-price-type-description__sheets">
              {priceSheets.map(priceSheet => {
                const title =
                  priceSheet.name && priceSheet.name !== priceSheet.number
                    ? `${priceSheet.number} (${priceSheet.name})`
                    : priceSheet.number;
                return (
                  priceSheet.parent_price_types.length > 0 && (
                    <Card
                      key={priceSheet.id}
                      type="inner"
                      title={title}
                      style={{ width: '32%', marginRight: '10px', marginBottom: '10px' }}
                    >
                      {priceSheet.parent_price_types.map(priceType => {
                        const label = this.props.resources.price_types.find(
                          type => type.id === priceType.type_id
                        );
                        return (
                          <FormItem
                            key={priceType.type_id}
                            className="setting-price-type-description__row"
                            {...formItemCardLayout}
                            label={label.name}
                          >
                            <Input
                              defaultValue={priceType.type_description}
                              maxLength="80"
                              onBlur={e =>
                                this.createDefaultPriceTypeDescription(
                                  priceSheet.id,
                                  priceType.type_id,
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        );
                      })}
                    </Card>
                  )
                );
              })}
            </div>
          )}
        </FormItem>
      </React.Fragment>
    );
  }
}
export default DefaultValuesPrice;
