import React, { ReactNode } from 'react';
import { Input, Form, Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import MultiEditButton from './MultiEditButton';
import { StandardResource } from '../../../../types/resources';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';

type PackageInputGroupProps = {
  inputName: string;
  selectName: string;
  label?: ReactNode;
  required?: boolean;
  options: StandardResource[];
  borderInvisible?: boolean;
  disabled?: boolean;
  inputChange: (value: string) => void;
  selectChange: (value: SelectValue) => void;
  handleDrawerVisibility?: (drawerVisible: boolean) => void;
  multipleValueField?: boolean;
  inputFocus?: boolean;
  showMultiEditIcon?: boolean;
  uomSelectDisable?: boolean;
  handleOnBlur?: () => void;
  className?: string;
};

const PackageInputGroup: React.FC<PackageInputGroupProps> = ({
  inputName,
  selectName,
  label,
  options,
  borderInvisible,
  required,
  disabled,
  multipleValueField,
  inputChange,
  selectChange,
  handleDrawerVisibility,
  inputFocus,
  showMultiEditIcon,
  uomSelectDisable,
  handleOnBlur,
  className,
}) => {
  const { t } = useTranslation();

  const renderFields = () => (
    <div className={`${className || ''}`}>
      <Input.Group compact size="small">
        <FormInput
          name={inputName}
          disabled={disabled}
          size="small"
          handleChange={e => inputChange(e.target.value)}
          autoFocus={inputFocus}
          className={classNames('package__input-group', {
            'package-border-transparent': borderInvisible,
          })}
          bordered
          testId={inputName}
        />
        <FormSelect
          name={selectName}
          disabled={disabled || uomSelectDisable}
          size="small"
          showSearch
          values={options}
          allowClear
          className={classNames(`package__input-group`, {
            'package-border-transparent': borderInvisible,
          })}
          onChange={(value: SelectValue) => selectChange(value)}
          bordered
          onBlur={handleOnBlur}
          optionToolTip
        />
      </Input.Group>
    </div>
  );

  const renderMultiValueEditLink = () => (
    <div className="flex items-center flex-1">
      <Button
        type="link"
        onClick={() => handleDrawerVisibility!(true)}
        style={{ padding: 0 }}
        disabled={disabled}
        data-testid="multiple-values"
      >
        {t('packageSeg:multipleValues')}
      </Button>
    </div>
  );

  return (
    <Form.Item label={label} required={required}>
      {(!multipleValueField && (
        <div className="flex flex-row items-center">
          {renderFields()}
          {showMultiEditIcon && (
            <MultiEditButton handleOnClick={() => handleDrawerVisibility!(true)} />
          )}
        </div>
      )) ||
        (multipleValueField && renderMultiValueEditLink())}
    </Form.Item>
  );
};

export default PackageInputGroup;
