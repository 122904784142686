import React from 'react';
import { Row, Col, Popconfirm, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Description from './Description';
import MarketingCopy from './MarketingCopy';
import SimpleDivider from '../../global/SimpleDivider';
import MGroupTreeSelect from '../media_and_marketing_copy/MGroupTreeSelect';
import PageFormik from '../../global/page/PageFormik';
import IntercomFormEventHandler from '../../global/page/IntercomFormEventHandler';
import { fetchMarketingCopies } from '../../../actions/items/description/fetch';
import { ApplicationState } from '../../../reducers';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { AsyncDispatch } from '../../../../types/global';
import { updateItem } from '../../../actions/catalogue/catalogue/update';
import { intercomEvent } from '../../../utils/IntercomUtils';

const DescriptionPage: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { itemId, selectedItems, isFetchingFullItem } = useSelector((state: ApplicationState) => {
    return {
      itemId: state.catalogue.catalogue.selectedItemIds[0],
      selectedItems: getSelectedItems(state),
      marketingCopies: state.items.description.marketingCopies,
      isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
    };
  });

  const selectedItem = selectedItems[0];

  const handleUpdateMarketingCopies = (values: FormikValues) => {
    const updatedItem = {
      ...selectedItem,
      part_group_id: values.groupId,
      part_sub_group_id: values.subGroupId,
    };
    return dispatch(updateItem(itemId, updatedItem)).then(() => {
      dispatch(fetchMarketingCopies(itemId));
    });
  };

  if (isFetchingFullItem) {
    return <Spin className="spinner-center" />;
  }

  return (
    <PageFormik
      initialValues={{
        groupId: selectedItem.part_group_id || null,
        subGroupId: selectedItem.part_sub_group_id || null,
      }}
      onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
        intercomEvent('viewed-all-product', {
          action: 'item-saved',
          location: 'description',
          part_number: selectedItem?.part_number,
          brand_code: selectedItem?.brand_code,
        });

        setSubmitPending();
        handleUpdateMarketingCopies(values)
          .then(() => setSubmitSuccess())
          .catch(() => setSubmitError());
      }}
      contentNoSpacing
      showAnalysis
    >
      {({ values, setFieldValue }) => (
        <div className="pr-2 pl-2 flex">
          <div className="flex-1 pr-4">
            <SimpleDivider title={t('glossary:marketingCopy')} />

            <Row gutter={16} className="pb-3">
              <Col span={14}>
                <div className="description__group_treeselect">
                  <MGroupTreeSelect
                    selectedGroupId={values.groupId}
                    selectedSubGroupId={values.subGroupId}
                    onChange={({ groupId, subGroupId }) => {
                      setFieldValue('groupId', groupId || null);
                      setFieldValue('subGroupId', subGroupId || null);
                    }}
                    size="small"
                    requiredGroupSubGroup
                  />
                </div>
              </Col>
              <Col span={10}>
                <Popconfirm
                  title={t('description:confirmSwitchToMarketingCopy')}
                  okText={t('common:yes')}
                  cancelText={t('common:cancel')}
                  onConfirm={() => navigate('/company/marketing-copy')}
                >
                  <Button size="small" block className="truncate">
                    {`${t('description:manageMarketingCopy')}`}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>

            <MarketingCopy
              unLinkMarketingCopy={() => {
                setFieldValue('groupId', null);
                setFieldValue('subGroupId', null);
              }}
            />
          </div>

          <div className="flex-1">
            <Description />
          </div>

          <IntercomFormEventHandler
            segment="description"
            partNr={selectedItem?.part_number}
            brandCode={selectedItem?.brand_code}
          />
        </div>
      )}
    </PageFormik>
  );
};

export default DescriptionPage;
