import React from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Card, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AsyncDispatch } from '../../../../types/global';
import FormTextArea from '../../global/Forms/FormTextArea';
import { runScript } from '../../../actions/distribution_apisettings';

type EditableScriptParameterPopoverProps = {
  defaultData: string;
  scriptId: number;
};

const EditableScriptParameterPopover: React.FC<EditableScriptParameterPopoverProps> = ({
  defaultData,
  children,
  scriptId,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [runScriptPending, setRunScriptPending] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('data-text-area');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const validationSchema = Yup.object().shape({
    data: Yup.string().test('json', t('validation:validJson'), val => {
      try {
        JSON.parse(val || '');
      } catch (e) {
        return false;
      }
      return true;
    }),
  });

  return (
    <Popover
      title={t('api:parametersForExecution')}
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="api-automation-popover"
      placement="left"
      destroyTooltipOnHide
      content={
        <Card bordered={false}>
          <Formik
            initialValues={{
              data: defaultData ? JSON.stringify(JSON.parse(defaultData), null, 2) : '{}',
            }}
            validationSchema={validationSchema}
            onSubmit={async values => {
              setRunScriptPending(true);
              await dispatch(runScript(scriptId, { data: JSON.parse(values.data) }));
              setRunScriptPending(false);
              setPopoverVisible(false);
            }}
          >
            {({ handleSubmit }) => (
              <React.Fragment>
                <Form layout="vertical">
                  <FormTextArea name="data" id="data-text-area" />
                </Form>
                <div className="flex-col items-end mt-2">
                  <Button
                    data-testid="ok"
                    type="primary"
                    onClick={e => {
                      handleSubmit();
                      e.stopPropagation();
                    }}
                    loading={runScriptPending}
                    size="small"
                  >
                    {t('api:runNow')}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default EditableScriptParameterPopover;
