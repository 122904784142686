import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

export function fetchMarketingCopiesOfParent({ keywords }: { keywords?: string }) {
  const urlParams = utils.createURL([{ name: 'keywords', values: keywords }]);
  return {
    type: 'FETCH_PARENT_MARKETING_COPIES',
    payload: axios.get(`${getNonGwApiRoot()}/parents/marketing_copies${urlParams}`),
  };
}

export function fetchMarketingCopyItemCount(mcId: number) {
  const params = utils.createURL([{ name: 'marketing_copy_id', values: mcId }]);

  return {
    type: 'FETCH_MARKETING_COPY_ITEM_COUNT',
    payload: axios.get(`${getApiRoot()}/parents/marketing_copies/item_counts${params}`),
  };
}

export function fetchMarketingCopyBrands() {
  return {
    type: 'FETCH_MARKETING_COPY_BRANDS',
    payload: axios.get(`${getNonGwApiRoot()}/parents/brand_levels`),
  };
}

export function fetchMarketingCopyPartTypes(
  marketingCopyID?: number,
  page = 1,
  keywords?: string | undefined
) {
  const urlParams = utils.createURL([
    { name: 'marketing_copy_id', values: marketingCopyID },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);
  return {
    meta: { page },
    type: 'FETCH_MARKETING_COPY_PART_TYPES',
    payload: axios.get(`${getNonGwApiRoot()}/parents/part_types${urlParams}`),
  };
}

export function fetchMarketingCopyGroups() {
  return {
    type: 'FETCH_MARKETING_COPY_GROUPS',
    payload: axios.get(`${getNonGwApiRoot()}/parents/part_groups`),
  };
}

export function fetchMarketingCopyDigitalAssets(marketingCopyId: number) {
  return {
    type: 'FETCH_MARKETING_COPY_DIGITAL_ASSETS',
    payload: axios.get(
      `${getApiRoot()}/parents/marketing_copies/${marketingCopyId}/digital_assets`
    ),
  };
}
