import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import {
  MarketingCopy,
  MarketingCopyGroup,
  MarketingCopyItemCount,
  ParentBrandLevels,
} from '../../../types/marketing_copy';

export type MarketingCopyState = {
  readonly fetchingMarketingCopies: boolean;
  readonly fetchingPartTypes: boolean;
  readonly marketingCopies: MarketingCopy[];
  readonly selectedMarketingCopyId: number | null;
  readonly parent: ParentBrandLevels;
  readonly partTypes: [];
  readonly groups: MarketingCopyGroup[];
  readonly uploadedDigitalAssets: any[];
  readonly fetchingItemCount: boolean;
  readonly marketingCopiesItemCounts: MarketingCopyItemCount[];
};

const initialState: MarketingCopyState = {
  fetchingMarketingCopies: false,
  fetchingPartTypes: false,
  marketingCopies: [],
  selectedMarketingCopyId: null,
  parent: {} as ParentBrandLevels,
  partTypes: [],
  groups: [],
  uploadedDigitalAssets: [],
  fetchingItemCount: false,
  marketingCopiesItemCounts: [],
};

const reducer: Reducer<MarketingCopyState, FluxStandardAction> = (state = initialState, action) => {
  const { meta } = action;
  switch (action.type) {
    case 'FETCH_MARKETING_COPY_ITEM_COUNT_PENDING': {
      return { ...state, fetchingItemCount: true };
    }
    case 'FETCH_MARKETING_COPY_ITEM_COUNT_FULFILLED': {
      return {
        ...state,
        marketingCopiesItemCounts: action.payload.data,
        fetchingItemCount: false,
      };
    }
    case 'FETCH_PARENT_MARKETING_COPIES_PENDING': {
      return { ...state, fetchingMarketingCopies: true };
    }
    case 'FETCH_PARENT_MARKETING_COPIES_FULFILLED': {
      return {
        ...state,
        marketingCopies: action.payload.data,
        fetchingMarketingCopies: false,
      };
    }
    case 'FETCH_MARKETING_COPY_BRANDS_REJECTED': {
      return { ...state, fetched: false, fetching: false, error: true };
    }
    case 'FETCH_MARKETING_COPY_BRANDS_PENDING': {
      return { ...state, parent: {} };
    }
    case 'FETCH_MARKETING_COPY_BRANDS_FULFILLED': {
      return {
        ...state,
        parent: action.payload.data,
      };
    }
    case 'FETCH_MARKETING_COPY_PART_TYPES_REJECTED': {
      return { ...state, fetched: false, fetchingPartTypes: false, error: true };
    }
    case 'FETCH_MARKETING_COPY_PART_TYPES_PENDING': {
      return { ...state, fetched: false, fetchingPartTypes: true, error: false };
    }
    case 'FETCH_MARKETING_COPY_PART_TYPES_FULFILLED': {
      const response = action.payload.data || [];
      const partTypes = action.meta.page > 1 ? [...state.partTypes, ...response] : response;
      return { ...state, partTypes, fetched: true, fetchingPartTypes: false, error: false };
    }
    case 'FETCH_MARKETING_COPY_GROUPS_REJECTED': {
      return { ...state, fetched: false, fetching: false, error: true };
    }
    case 'FETCH_MARKETING_COPY_GROUPS_PENDING': {
      return { ...state, groups: [], fetched: false, fetching: true, error: false };
    }
    case 'FETCH_MARKETING_COPY_GROUPS_FULFILLED': {
      return {
        ...state,
        groups: action.payload.data,
        fetched: true,
        fetching: false,
        error: false,
      };
    }
    case 'FETCH_MARKETING_COPY_DIGITAL_ASSETS_FULFILLED': {
      return {
        ...state,
        selectedMarketingCopyDigitalAsset: action.payload.data,
      };
    }
    case 'SET_SELECTED_MARKETING_COPY_ID': {
      return {
        ...state,
        selectedMarketingCopyId: action.payload,
      };
    }
    case 'CREATE_MARKETING_COPY_FULFILLED': {
      const newMarketingCopy = action.payload.data;
      return {
        ...state,
        marketingCopies: [newMarketingCopy, ...state.marketingCopies],
      };
    }
    case 'CREATE_MARKETING_COPY_DIGITAL_ASSET_FULFILLED': {
      const digitalAsset = action.payload.data;
      return {
        ...state,
        uploadedDigitalAssets: [
          ...state.uploadedDigitalAssets,
          {
            id: digitalAsset.digital_asset_id,
            asset_url: digitalAsset.asset_url,
            file_type_id: digitalAsset.file_type_id,
            type_id: digitalAsset.type_id || 19, // marketing copy asset type 19 (logo) fallback
            representation_id: 1,
          },
        ],
      };
    }
    case 'LINK_MARKETING_COPY_DIGITAL_ASSET_FULFILLED': {
      const digitalAsset = action.payload.data;
      return {
        ...state,
        uploadedDigitalAssets: [
          ...state.uploadedDigitalAssets,
          {
            id: digitalAsset.digital_asset_id,
            asset_url: digitalAsset.asset_url,
            file_type_id: digitalAsset.file_type_id,
            type_id: digitalAsset.type_id || 19, // marketing copy asset type 19 (logo) fallback
            representation_id: 1,
          },
        ],
      };
    }
    case 'ADD_UPLOADED_DIGITAL_ASSET': {
      return {
        ...state,
        uploadedDigitalAssets: [action.payload.asset, ...state.uploadedDigitalAssets],
      };
    }
    case 'RESET_UPLOADED_DIGITAL_ASSETS': {
      return {
        ...state,
        uploadedDigitalAssets: [],
      };
    }
    case 'UPDATE_MARKETING_COPY_FULFILLED': {
      const newMarketingCopy = action.payload.data;
      return {
        ...state,
        marketingCopies: state.marketingCopies.map(m => {
          if (m.id === newMarketingCopy.id) {
            return {
              ...newMarketingCopy,
            };
          }
          return m;
        }),
      };
    }
    case 'UPDATE_MARKETING_COPY_GROUPS_FULFILLED': {
      return { ...state, groups: action.payload.data };
    }
    case 'DELETE_MARKETING_COPY_PENDING': {
      const { id } = meta;
      return {
        ...state,
        marketingCopies: state.marketingCopies.filter(marketingCopy => marketingCopy.id !== id),
      };
    }
    case 'DELETE_GROUP_FULFILLED': {
      const { id } = meta;
      return {
        ...state,
        groups: state.groups.filter(group => group.id !== id),
      };
    }
    case 'DELETE_SUB_GROUP_FULFILLED': {
      const { groupId, subGroupId } = meta;
      return {
        ...state,
        groups: state.groups.map(group => {
          if (group.id === groupId) {
            return {
              ...group,
              sub_groups: group.sub_groups!.filter(sG => sG.id !== subGroupId),
            };
          }
          return group;
        }),
      };
    }
  }
  return state;
};

export default reducer;
