import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Modal, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DeliveryLogError } from '../../../../types/delivery_logs';
import { ExtendedResources, ErrorTypes } from '../../../../types/resources';
import ErrorModalTitle from '../import_export/ErrorModalTitle';
import { ExportErrorLogDetail, ImportErrorLogDetail } from '../../../../types/import_export';

type ErrorLogModalProps = {
  fetching: boolean;
  showModal: boolean;
  errors: DeliveryLogError[] | ImportErrorLogDetail[] | ExportErrorLogDetail[];
  errorTypes: ExtendedResources[];
  handleErrorModalState: () => void;
  handleErrorsDownload: () => void;
  type?: string;
  displayRowNumber?: boolean;
};

const ErrorLogModal = (props: ErrorLogModalProps) => {
  const { errors, handleErrorModalState, handleErrorsDownload, fetching, errorTypes } = props;
  const { t } = useTranslation();

  const messageDisplay = (message: string) => (
    <Tooltip title={message} placement="topLeft">
      {message}
    </Tooltip>
  );

  const createDeliverLogTableRowData = (error: DeliveryLogError, i: number) => {
    const errorType = errorTypes.find(e => e.id === error.type_id);

    return {
      key: `${error.part_number}${i}`,
      type: (
        <div>
          {errorType && (
            <WarningOutlined
              className={classNames(
                { blue: errorType && errorType.id === ErrorTypes.INFO },
                { yellow: errorType && errorType.id === ErrorTypes.WARNING },
                { red: errorType && errorType.id === ErrorTypes.ERROR }
              )}
            />
          )}
          {errorType && ` ${errorType.name}`}
        </div>
      ),
      partNumber: messageDisplay(error.part_number),
      action: props.type === 'deliveryLog' ? error.action : '',
      shortMessage: messageDisplay(error.short_message),
      longMessage: messageDisplay(error.long_message),
    };
  };

  const createImportTableRowData = (error: ImportErrorLogDetail, i: number) => {
    const errorType = errorTypes.find(e => e.id === error.type_id);

    return {
      key: `${error.row_number}${i}`,
      type: (
        <div>
          {errorType && (
            <WarningOutlined
              className={classNames(
                { blue: errorType && errorType.id === ErrorTypes.INFO },
                { yellow: errorType && errorType.id === ErrorTypes.WARNING },
                { red: errorType && errorType.id === ErrorTypes.ERROR }
              )}
            />
          )}
          {errorType && ` ${errorType.name}`}
        </div>
      ),
      rowNumber: error.row_number,
      segmentName: error.segment_name,
      shortMessage: messageDisplay(error.description),
      longMessage: messageDisplay(error.long_description || ''),
    };
  };

  const createExportTableRowData = (error: ExportErrorLogDetail, i: number) => {
    const errorType = errorTypes.find(e => e.id === error.type_id);

    return {
      key: `${error.part_number}${i}`,
      type: (
        <div>
          {errorType && (
            <WarningOutlined
              className={classNames(
                { blue: errorType && errorType.id === ErrorTypes.INFO },
                { yellow: errorType && errorType.id === ErrorTypes.WARNING },
                { red: errorType && errorType.id === ErrorTypes.ERROR }
              )}
            />
          )}
          {errorType && ` ${errorType.name}`}
        </div>
      ),
      partNumber: messageDisplay(error.part_number),
      segmentName: error.segment_name,
      shortMessage: messageDisplay(error.description),
      longMessage: messageDisplay(error.long_description || ''),
    };
  };

  const dataSource = errors.map(
    (error: DeliveryLogError | ImportErrorLogDetail | ExportErrorLogDetail, i: number) => {
      if (props.type === 'deliveryLog')
        return createDeliverLogTableRowData(error as DeliveryLogError, i);
      if (props.type === 'importLog')
        return createImportTableRowData(error as ImportErrorLogDetail, i);
      return createExportTableRowData(error as ExportErrorLogDetail, i);
    }
  );

  return (
    <Modal
      title={<ErrorModalTitle handleErrorDownload={handleErrorsDownload} />}
      open={props.showModal}
      onCancel={handleErrorModalState}
      footer={null}
      width={1200}
    >
      <Table
        className="delivery__error-table"
        dataSource={dataSource}
        pagination={false}
        size="middle"
        loading={fetching}
        scroll={dataSource.length >= 14 ? { y: 600 } : undefined}
      >
        <Table.Column title={t('common:status')} dataIndex="type" width="100px" />
        {(props.type === 'exportLog' || props.type === 'importLog') && (
          <Table.Column
            title={t('errorLogModal:segmentName')}
            dataIndex="segmentName"
            width="180px"
            ellipsis
          />
        )}

        {props.type === 'exportLog' && (
          <Table.Column
            title={t('errorLogModal:partNumber')}
            dataIndex="partNumber"
            width="250px"
            ellipsis
          />
        )}
        {props.type === 'importLog' && props.displayRowNumber && (
          <Table.Column
            title={t('errorLogModal:rowNumber')}
            dataIndex="rowNumber"
            width="250px"
            ellipsis
          />
        )}
        {props.type === 'deliveryLog' && (
          <Table.Column
            title={t('errorLogModal:action')}
            dataIndex="action"
            width="100px"
            ellipsis
          />
        )}
        <Table.Column
          title={t('errorLogModal:message')}
          dataIndex="shortMessage"
          width="250px"
          ellipsis
        />
        <Table.Column
          title={t('errorLogModal:explanation')}
          dataIndex="longMessage"
          width="250px"
          ellipsis
        />
      </Table>
    </Modal>
  );
};

export default ErrorLogModal;
