import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { hideModal, showModal } from '../../app/modal';
import { MODAL_TYPES } from '../../../constants/ModalConstants';
import { AsyncDispatch } from '../../../../types/global';
import { showParentDigitalAssets } from '../../parent/digital_asset';

export function createApplication(itemId: number, categoryId?: number | null) {
  return {
    type: 'CREATE_APPLICATIONS',
    payload: axios.post(`${getNonGwApiRoot()}/items/applications?item_id=${itemId}`),
    meta: { categoryId, itemId },
  };
}

export function createApplicationDigitalAssets(
  typeId: number,
  file: any,
  fileName: string,
  applicationId: number,
  overwrite: number,
  representationId: number,
  fileTypeId: number,
  downloadUri: string,
  uri: string,
  assetName: string,
  brandId: number,
  languageId: number
) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  let body: { [key: string]: any };
  if (file) {
    body = file;
  } else if (downloadUri) {
    body = { download_uri: downloadUri };
  } else if (uri) {
    body = { uri };
  }

  const params = utils.createURL([
    { name: 'reference_type', values: 'applications' },
    { name: 'type_id', values: typeId },
    { name: 'file_name', values: fileName },
    { name: 'application_id', values: applicationId },
    { name: 'override', values: overwrite },
    { name: 'representation_id', values: representationId },
    { name: 'file_type_id', values: fileTypeId },
    { name: 'asset_name', values: assetName },
    { name: 'brand_id', values: brandId },
    { name: 'language_id', values: languageId },
  ]);

  return (dispatch: AsyncDispatch) => {
    dispatch({
      type: 'CREATE_APPLICATION_DIGITAL_ASSET',
      payload: axios.post(`${getNonGwApiRoot()}/parents/digital_assets${params}`, body, config),
    })
      .then(() => dispatch(hideModal()))
      .catch((err: { response: { data: any } }) => {
        const errData = err && err.response.data;
        if (
          errData.type === 'file_name' ||
          errData.type === 'asset_name' ||
          errData.type === 'file_and_asset_name' ||
          errData.type === 'multi_file_and_asset_name'
        ) {
          dispatch(hideModal());
          dispatch(
            showModal(MODAL_TYPES.DIGITAL_ASSET_EXISTING, {
              error: errData,
            })
          );
        }
      });
  };
}

export function linkApplicationDigitalAsset(
  applicationId: number,
  assetId: number,
  assetTypeId: number,
  representationTypeId: number,
  assetName: string
) {
  const params = utils.createURL([
    { name: 'item_application_id', values: applicationId },
    { name: 'digital_asset_id', values: assetId },
    { name: 'type_id', values: assetTypeId },
    { name: 'asset_name', values: assetName },
    { name: 'representation_id', values: representationTypeId },
  ]);
  return (dispatch: AsyncDispatch) => {
    dispatch({
      type: 'LINK_APPLICATION_DIGITAL_ASSET',
      payload: axios.post(`${getApiRoot()}/items/applications/digital_assets${params}`),
    }).then(() => {
      dispatch(hideModal());
      dispatch(showParentDigitalAssets(false));
    });
  };
}

export const createApplicationLinks = (baseItemIds: number, itemId: number) => {
  const params = utils.createURL([
    { name: 'base_item_ids', values: baseItemIds },
    { name: 'item_id', values: itemId },
    { name: 'segment_id', values: 3 },
  ]);
  return {
    type: 'CREATE_APPLICATION_LINKS',
    payload: axios.post(`${getApiRoot()}/items/bases${params}`),
    meta: { baseItemIds },
  };
};

export const createCustomVcdb = (name: string) => {
  const params = utils.createURL([{ name: 'name', values: name }]);
  return {
    type: 'CREATE_VCDB',
    payload: axios.patch(`${getApiRoot()}/vehicles/versions${params}`),
  };
};
