import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { BRAND_AREA, COMPANY_AREA } from '../../constants/InAppAreaConst';
import ItemInfo from '../../components/global/ItemInfo';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { intercomEvent } from '../../utils/IntercomUtils';
import {
  holleyAccountId,
  pdmSalesReportId,
  holleyAccountReportId,
  reportId,
  workspaceId,
} from './InsightsConfig';
import InsightsPage from '../../components/body/insights/InsightsPage';
import {
  fetchInsightsAppToken,
  fetchInsightsEmbedToken,
  fetchInsightsEmbedTokenUsage,
  fetchInsightsEmbedData,
} from '../../actions/insights';
import { hasPermission } from '../../utils/Permissions';
import { AsyncDispatch } from '../../../types/global';
import { POWER_BI_REPORT_ID } from '../../constants/DefaultValueConstants';
import { DefaultValue } from '../../../types/brand_settings';

const InsightsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedItems,
    accessTokenObj,
    embedTokenObj,
    embedData,
    fetchingEmbedToken,
    fetchingEmbedData,
    user,
    inAppArea,
    defaultValues,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      accessTokenObj: state.tools.insights.accessTokenObj,
      embedTokenObj: state.tools.insights.embedTokenObj,
      embedData: state.tools.insights.embedData,
      fetchingEmbedToken: state.tools.insights.fetchingEmbedToken,
      fetchingEmbedData: state.tools.insights.fetchingEmbedData,
      user: state.user.user,
      inAppArea: state.app.global.inAppArea,
      defaultValues: state.settings.defaultValues,
    };
  });

  const defaultReportId = useMemo(() => {
    return defaultValues.find((dValue: DefaultValue) => dValue.id === POWER_BI_REPORT_ID)?.value;
  }, [defaultValues]);

  const [currentReportId, setCurrentReportId] = React.useState<string>(
    inAppArea === BRAND_AREA
      ? defaultReportId || reportId
      : (user?.account_id === holleyAccountId && holleyAccountReportId) || pdmSalesReportId
  );

  const canManageInsights = hasPermission(user, 'can_access_insights');

  React.useEffect(() => {
    intercomEvent('viewed-insights-item');
  }, []);

  React.useEffect(() => {
    if (canManageInsights) dispatch(fetchInsightsAppToken());
  }, [dispatch, canManageInsights]);

  React.useEffect(() => {
    if (!embedTokenObj) return;
    const expDate = new Date(embedTokenObj.expiration);
    const timeToRefreshToken = expDate.getTime() - Date.now() - 60 * 1000;

    let timer: NodeJS.Timeout | undefined;

    if (timeToRefreshToken <= 0) {
      dispatch(fetchInsightsAppToken());
    } else {
      timer = setTimeout(() => {
        dispatch(fetchInsightsAppToken());
      }, timeToRefreshToken);
    }

    return () => clearTimeout(timer);
  }, [dispatch, embedTokenObj]);

  React.useEffect(() => {
    if (accessTokenObj && embedData)
      dispatch(
        fetchInsightsEmbedToken(
          workspaceId,
          currentReportId,
          accessTokenObj.access_token,
          embedData.datasetId,
          user!.account_id.toString(),
          user!.account_name,
          true
        )
      ).catch(() => {
        dispatch(
          fetchInsightsEmbedToken(
            workspaceId,
            currentReportId,
            accessTokenObj.access_token,
            embedData.datasetId,
            user!.account_id.toString(),
            user!.account_name,
            false
          )
        );
      });
  }, [dispatch, accessTokenObj, embedData, user, currentReportId]);

  React.useEffect(() => {
    if (accessTokenObj && !embedData && !fetchingEmbedData)
      dispatch(fetchInsightsEmbedData(workspaceId, currentReportId, accessTokenObj.access_token));
    if (accessTokenObj && !embedData)
      dispatch(fetchInsightsEmbedTokenUsage(accessTokenObj.access_token));
  }, [dispatch, accessTokenObj, embedData, currentReportId, fetchingEmbedData]);

  const changeReport = (reportId: string) => {
    if (accessTokenObj) {
      dispatch(fetchInsightsEmbedData(workspaceId, reportId, accessTokenObj.access_token));
      setCurrentReportId(reportId);
    }
  };

  if (!canManageInsights) {
    return (
      <div className="p-5">
        <Alert
          showIcon
          type="info"
          message={t('common:noAccess')}
          description={t('insights:noPermission')}
        />
      </div>
    );
  }

  if (inAppArea === BRAND_AREA && (selectedItems.length > 1 || selectedItems.length === 0)) {
    return <ItemInfo items={selectedItems} />;
  }

  return (
    <InsightsPage
      embedToken={embedTokenObj?.token}
      embedUrl={embedData?.embedUrl}
      sku={
        inAppArea === BRAND_AREA
          ? `${selectedItems[0].brand_code}_${selectedItems[0].part_number}`
          : undefined
      }
      currentReportId={currentReportId}
      setReportId={(reportId: string) => changeReport(reportId)}
    />
  );
};

export default InsightsContainer;
