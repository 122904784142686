import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { notification, Progress, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/AntStyles.json';
import { ApplicationState } from '../../reducers';
import { CurrentImportState, ImportState } from '../../reducers/importer/ImporterReducer';
import {
  fetchLatestImportLogs,
  closeImportNotificatoin,
  fetchRunningImportLogs,
  parentCloseImportNotification,
} from '../../actions/importer';
import DescriptionsList from '../../components/global/DescriptionsList';
import useInterval from '../../hooks/useInterval';
import { ImportTypes } from '../../../types/import_export';
import { withContainerWrapper } from '../ContainerWrapper';
import {
  fetchLatestParentImportLogs,
  fetchRunningParentImportLogs,
} from '../../actions/parent/exportImport/fetch';
import { hasPermission } from '../../utils/Permissions';
import { isReceiver } from '../../utils/UserUtils';

const Importer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    brands,
    importsWithNotification,
    runningImports,
    user,
    vcdbImportLogId,
    fileTypeId,
    receiverUser,
  } = useSelector((state: ApplicationState) => {
    const imports = state.importer.imports;
    const parentImports = state.parent.parentImportLogs.imports;
    const importsWithNotification = [
      ...imports.filter(singleImport => singleImport.showNotification),
      ...parentImports.filter((singleImport: any) => singleImport.showNotification),
    ];
    const runningImports = [
      ...imports.filter(singleImport => singleImport.currentState === CurrentImportState.IMPORT),
      ...parentImports.filter(
        (singleImport: any) => singleImport.currentState === CurrentImportState.IMPORT
      ),
    ];
    return {
      brands: state.parent.brands.brands,
      user: state.user.user,
      importsWithNotification,
      runningImports,
      vcdbImportLogId: state.parent.parentImportLogs.vcdbImportLogId,
      fileTypeId: state.parent.parentLogs.fileTypeId,
      receiverUser: state.user.user && isReceiver(state.user.user),
    };
  }, shallowEqual);

  const canManageTranslations = useMemo(
    () => hasPermission(user, 'can_manage_translation'),
    [user]
  );
  const canManageCustomVcdb = useMemo(() => hasPermission(user, 'can_manage_custom_vcdb'), [user]);

  React.useEffect(() => {
    dispatch(fetchLatestImportLogs(user!.id));
    if (canManageTranslations || canManageCustomVcdb)
      dispatch(fetchLatestParentImportLogs(user!.id));
  }, [canManageTranslations, canManageCustomVcdb, dispatch, user]);

  const pollRunningImportLogs = () => {
    if (runningImports.length > 0) {
      const importIds = runningImports.map(runningImport => runningImport.logId).filter(Boolean);
      dispatch(fetchRunningImportLogs(importIds));

      if (canManageTranslations || canManageCustomVcdb || receiverUser)
        dispatch(fetchRunningParentImportLogs(importIds, vcdbImportLogId));
    }
  };

  const [delay, setDelay] = useState(2000);
  const [pendingCount, setPendingCount] = useState(0);

  React.useEffect(() => {
    // reset polling interval when new import was started
    if (runningImports.length > pendingCount) {
      setDelay(2000);
    }
    if (runningImports.length !== pendingCount) {
      setPendingCount(runningImports.length);
    }
  }, [pendingCount, runningImports]);

  useInterval(() => {
    if (delay < 600000) setDelay(delay + 1000);
    pollRunningImportLogs();
  }, delay);

  const handleGoToImportReports = (brandId: number) => {
    const translationId = 13;
    const customVcdb = 14;
    const attributeSettings = 20;
    if (brandId) navigate(`/brand/import-export/import-logs?brandId=${brandId}`);
    else if (receiverUser) navigate('/available-brands/export/import-reports');
    else {
      if (fileTypeId === translationId) navigate(`/settings/translations/import-logs`);
      if (fileTypeId === customVcdb) navigate(`/settings/custom-vcdb/import-logs`);
      if (fileTypeId === attributeSettings) navigate(`/settings/attributes/import-logs`);
    }
  };

  const getUploadNotificationState = (importState: ImportState) => {
    const brand = brands.find((brand: any) => brand.id === importState.brandId);
    return {
      description: (
        <div>
          <DescriptionsList colNumber={2}>
            <DescriptionsList.Item label="Brand">
              <div>{brand ? brand.name : ''}</div>
            </DescriptionsList.Item>
            <DescriptionsList.Item label="Status">
              <div>
                {importState.uploadState.failed
                  ? t('importer:uploadFailed')
                  : t('importer:uploading')}
              </div>
            </DescriptionsList.Item>
            <DescriptionsList.Item label="File" fullWidth>
              <div className="break-all">{importState.fileName}</div>
            </DescriptionsList.Item>
            <DescriptionsList.Item label="Progress" fullWidth>
              <Progress
                percent={importState.uploadState.progress}
                status={importState.uploadState.failed ? 'exception' : 'active'}
              />
            </DescriptionsList.Item>
          </DescriptionsList>
        </div>
      ),
    };
  };

  const getImportNotificationState = (importState: ImportState) => {
    const progressInfo = [ImportTypes.ACES, ImportTypes.PIES, ImportTypes.EXCEL].includes(
      importState.importType
    );
    const brand = brands.find((brand: any) => brand.id === importState.brandId);
    return {
      description: (
        <DescriptionsList colNumber={2}>
          <DescriptionsList.Item label={t('glossary:brand')}>
            <div>{brand ? brand.name : ''}</div>
          </DescriptionsList.Item>
          <DescriptionsList.Item label={t('importer:status')}>
            <div>{importState.importState ? importState.importState.status : '-'}</div>
          </DescriptionsList.Item>
          <DescriptionsList.Item label={t('importer:file')} fullWidth>
            <div className="break-all">{importState.fileName}</div>
          </DescriptionsList.Item>
          <DescriptionsList.Item label={t('importer:progress')} fullWidth>
            <Progress
              percent={
                progressInfo ? importState.importState && importState.importState.progress : 99.9
              }
              status="active"
              showInfo={progressInfo}
            />
          </DescriptionsList.Item>
          {progressInfo && (
            <DescriptionsList.Item label={t('importer:subProgress')} fullWidth>
              <Progress
                percent={importState.importState && importState.importState.subProgress}
                status="active"
              />
            </DescriptionsList.Item>
          )}
        </DescriptionsList>
      ),
    };
  };

  const getDoneNotificatonState = (singleImport: ImportState) => {
    const { importState } = singleImport;
    const brand = brands.find((brand: any) => brand.id === singleImport.brandId);
    return {
      description: (
        <DescriptionsList colNumber={2}>
          <DescriptionsList.Item label="Brand">
            <div>{brand ? brand.name : ''}</div>
          </DescriptionsList.Item>
          <DescriptionsList.Item label="Status">
            <div>{t('importer:importCompleted')}</div>
          </DescriptionsList.Item>
          {importState && importState.statusId === 4 && (
            <DescriptionsList.Item
              label={<div className="pl-5">{t('importer:importFailed')}</div>}
              fullWidth
            >
              <div className="flex">
                <WarningOutlined
                  style={{ color: colors['error-color'], paddingRight: '5px', marginTop: '-18px' }}
                />
                <div className="flex-1">{t('importer:importError')}</div>
              </div>
            </DescriptionsList.Item>
          )}
          {importState && importState.statusId === 3 && (
            <DescriptionsList.Item
              label={<div className="pl-5">{t('importer:importWarning')}</div>}
              fullWidth
            >
              <div className="flex">
                <WarningOutlined
                  style={{
                    color: colors['warning-color'],
                    paddingRight: '5px',
                    marginTop: '-18px',
                  }}
                />
                <div className="flex-1">{t('importer:importWarningInfo')}</div>
              </div>
            </DescriptionsList.Item>
          )}
          {importState && importState.statusId === 5 && (
            <DescriptionsList.Item
              label={<div className="pl-5">{t('importer:importSuccess')}</div>}
              fullWidth
            >
              <div className="flex">
                <CheckCircleOutlined
                  style={{
                    color: colors['success-color'],
                    paddingRight: '5px',
                    marginTop: '-18px',
                  }}
                />
                <div className="break-all">{singleImport.fileName}</div>
              </div>
            </DescriptionsList.Item>
          )}
        </DescriptionsList>
      ),
      btn: (
        <Button type="primary" onClick={() => handleGoToImportReports(singleImport.brandId)}>
          {t('importer:goToReports')}
        </Button>
      ),
    };
  };

  const getImportMessage = (importState: ImportState) => {
    if (importState.importType === ImportTypes.EXCEL) {
      return `${t('glossary:import')} - ${t('glossary:excel')}`;
    }
    if (importState.importType === ImportTypes.ACES) {
      return `${t('glossary:import')} - ${t('glossary:aces')}`;
    }
    if (importState.importType === ImportTypes.PIES) {
      return `${t('glossary:import')} - ${t('glossary:pies')}`;
    }
    if (importState.importType === ImportTypes.ASSET) {
      return `${t('glossary:import')} - ${t('glossary:digitalAssets')}`;
    }
  };

  const showNotifications = () => {
    importsWithNotification.forEach(singleImport => {
      let notificationState = {};

      if (singleImport.currentState === CurrentImportState.UPLOAD)
        notificationState = getUploadNotificationState(singleImport);
      if (singleImport.currentState === CurrentImportState.IMPORT)
        notificationState = getImportNotificationState(singleImport);
      if (singleImport.currentState === CurrentImportState.DONE)
        notificationState = getDoneNotificatonState(singleImport);

      // if we open all notificaton at once, they take the same position and do overlap
      setTimeout(() => {
        notification.open({
          key: `${singleImport.id}`,
          message: getImportMessage(singleImport),
          duration: 0,
          onClose: () => {
            dispatch(closeImportNotificatoin(singleImport.id));
            if (canManageTranslations || canManageCustomVcdb || receiverUser)
              dispatch(parentCloseImportNotification(singleImport.id));
          },
          ...notificationState,
        });
      }, 10);
    });
  };

  React.useEffect(() => {
    showNotifications();
  }, [importsWithNotification]);

  return null;
};

export default withContainerWrapper(Importer);
