import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import DrawerFormik from './drawer/DrawerFormik';
import { fetchItemCategories } from '../../actions/items/item/fetch';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { AsyncDispatch } from '../../../types/global';
import { typingDone } from '../../utils/Utils';
import SelectList from './SelectList';
import { Category, SubCategory, PartType as TPartType } from '../../../types/item';
import { DefaultValue } from '../../../types/brand_settings';

const { Search } = Input;

const ROW_HEIGHT = 50;

type ActiveLevel = 'category' | 'subCategory' | 'partType';

type PartTypeProps = {
  onSubmit: (
    partTypeId: number,
    categoryName: string,
    subCategoryName: string,
    partTypeName: string,
    visiblePartTypeId: number
  ) => void;
  onClose: () => void;
  partTypeDrawerVisible?: boolean;
  categoryName?: string;
  subCategoryName?: string;
  partTypeName?: string;
};

const PartType: React.FC<PartTypeProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedItem,
    categories,
    subCategories,
    partTypesList,
    selectedBrandId,
    defaultValues,
    fetchingCategory,
    fetchingSubCategory,
    fetchingPartType,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      categories: state.items.item.categories,
      subCategories: state.items.item.subCategories,
      partTypesList: state.items.item.partTypes,
      selectedBrandId: state.parent.brands.selectedBrandId,
      defaultValues: state.settings.defaultValues,
      fetchingCategory: state.items.item.fetchingCategory,
      fetchingSubCategory: state.items.item.fetchingSubCategory,
      fetchingPartType: state.items.item.fetchingPartType,
    };
  }, shallowEqual);

  React.useEffect(() => {
    dispatch(fetchItemCategories({ itemId: selectedItem.id, brandId: selectedBrandId }));
  }, [dispatch, selectedBrandId, selectedItem.id]);

  const [visible, setVisible] = React.useState(props.partTypeDrawerVisible);

  const fetchCategories = (values: any) => {
    dispatch(
      fetchItemCategories({
        ...values,
        brandId: selectedBrandId,
        itemId: selectedItem.id,
      })
    );
  };

  const showPartTypeId = defaultValues.find((d: DefaultValue) => d.id === 11)?.value === '1';

  const getDrawerTitle = () => {
    return props.partTypeName
      ? ` [ ${props.categoryName} | ${props.subCategoryName} | ${props.partTypeName} ]`
      : '';
  };

  return (
    <div>
      {visible && (
        <DrawerFormik
          title={
            <div className="flex">
              <div>{t('partType:selectPartType')}</div>
              <div className="part-type-draw-title text-gray-600 text-sm pl-2">
                {getDrawerTitle()}
              </div>
            </div>
          }
          visible={visible}
          onClose={() => {
            dispatch(fetchItemCategories({ itemId: selectedItem.id, brandId: selectedBrandId }));
            setVisible(false);
            props.onClose();
          }}
          initialValues={{
            categoryId: '',
            subCategoryId: '',
            partTypeId: '',
            visiblePartTypeId: '',
            keywordsSmartMapping: '',
            keywordCategory: '',
            keywordSubCategory: '',
            keywordPartType: '',
            category: '',
          }}
          width="80%"
          onSubmit={values => {
            props.onSubmit(
              values.partTypeId,
              values.level1_name,
              values.level2_name,
              values.level3_name,
              values.visiblePartTypeId
            );
          }}
          handleSaveButtonEnabled={formik => {
            const { values, dirty } = formik;
            return values.partTypeId && dirty;
          }}
        >
          {({ values, setFieldValue }) => {
            const handleCategorySelect = (category: Category, activelevel: ActiveLevel) => {
              setFieldValue('subCategoryId', '');
              setFieldValue('partTypeId', '');
              const categoryValues = {
                ...values,
                categoryId: category.id,
                subCategoryId: undefined,
                partTypeId: undefined,
                activeLevel: activelevel,
              };
              if (values.categoryId === category.id) {
                setFieldValue('categoryId', '');
                fetchCategories({ ...categoryValues, categoryId: undefined });
              } else {
                setFieldValue('categoryId', category.id);
                fetchCategories(categoryValues);
              }
            };
            const handleSubCategorySelect = (
              subCategory: SubCategory,
              activelevel: ActiveLevel
            ) => {
              setFieldValue('categoryId', '');
              setFieldValue('partTypeId', '');
              const categoryValues = {
                ...values,
                categoryId: subCategory.category_id,
                subCategoryId: subCategory.id,
                partTypeId: undefined,
                activeLevel: activelevel,
              };
              if (values.subCategoryId === subCategory.id) {
                setFieldValue('subCategoryId', '');
                fetchCategories({ ...categoryValues, subCategoryId: undefined });
              } else {
                setFieldValue('subCategoryId', subCategory.id);
                fetchCategories(categoryValues);
              }
            };
            const choosePartType = (partType: TPartType) => {
              setFieldValue('subCategoryId', '');
              setFieldValue('categoryId', '');
              if (values.partTypeId === partType.id) {
                setFieldValue('partTypeId', '');
                setFieldValue('level1_name', '');
                setFieldValue('level2_name', '');
                setFieldValue('level3_name', '');
                setFieldValue('visiblePartTypeId', '');
                fetchCategories({ ...values, partTypeId: undefined });
              } else {
                setFieldValue('partTypeId', partType.id);
                setFieldValue('level1_name', partType.category_name);
                setFieldValue('level2_name', partType.sub_category_name);
                setFieldValue('level3_name', partType.part_type_name);
                setFieldValue('visiblePartTypeId', partType.part_type_id);
              }
            };
            const searchCategory = (categeorySearch: string, activelevel: ActiveLevel) => {
              typingDone(() => {
                setFieldValue('keywordCategory', categeorySearch);
                fetchCategories({
                  ...values,
                  keywordCategory: categeorySearch,
                  activeLevel: activelevel,
                });
              });
            };
            const searchSubCategory = (subCatgeorySearch: string, activelevel: ActiveLevel) => {
              typingDone(() => {
                setFieldValue('keywordSubCategory', subCatgeorySearch);
                fetchCategories({
                  ...values,
                  keywordSubCategory: subCatgeorySearch,
                  activeLevel: activelevel,
                });
              });
            };
            const searchPartType = (partTypeSearch: string, activelevel: ActiveLevel) => {
              typingDone(() => {
                setFieldValue('keywordPartType', partTypeSearch);
                fetchCategories({
                  ...values,
                  keywordPartType: partTypeSearch,
                  activeLevel: activelevel,
                });
              });
            };
            const handleSmartMapping = (smartSearch: string, activelevel: ActiveLevel) => {
              setFieldValue('partTypeId', '');
              setFieldValue('subCategoryId', '');
              setFieldValue('categoryId', '');
              typingDone(() => {
                setFieldValue('keywordsSmartMapping', smartSearch);
                fetchCategories({
                  ...values,
                  keywordsSmartMapping: smartSearch,
                  activeLevel: activelevel,
                });
              });
            };

            return (
              <div className="flex flex-1 flex-col h-full p-2">
                <Search
                  placeholder={t('partType:smartMapping')}
                  onChange={e => handleSmartMapping(e.target.value, 'category')}
                  className="p-6"
                />
                <div className="flex flex-1 pt-6 h-full">
                  <div className="flex-1 pr-6 h-full">
                    <SelectList
                      title={t('partType:category')}
                      placeholder={t('partType:searchCategory')}
                      rowHeight={ROW_HEIGHT}
                      value={values.categoryId}
                      loading={fetchingCategory}
                      showSearch
                      border
                      onSearchChange={value => searchCategory(value, 'category')}
                      dataSource={categories}
                      onClick={(value, data) => handleCategorySelect(data, 'subCategory')}
                      dataSourceValue="category_name"
                      renderItem={data => {
                        return (
                          <div
                            key={data.id}
                            className="flex flex-1 justify-between p-2"
                            data-testid="category-row-field"
                          >
                            <span className="text-black align self-center">
                              {data.category_name}
                            </span>
                            {data.used_by_brand > 0 && (
                              <Tooltip
                                title={`${data.used_by_brand} ${t(
                                  'partType:assignedProductsTextCategory'
                                )}`}
                              >
                                <Tag
                                  className="self-center"
                                  style={{
                                    margin: 0,
                                    color: '#7aace0',
                                    borderRadius: '8px',
                                    borderColor: '#7aace0',
                                    lineHeight: '14px',
                                    alignContent: 'center',
                                  }}
                                >
                                  {data.used_by_brand}
                                </Tag>
                              </Tooltip>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className=" flex-1 pr-6 h-full">
                    <SelectList
                      title={t('partType:subCategory')}
                      rowHeight={ROW_HEIGHT}
                      placeholder={t('partType:searchSubCategory')}
                      onPagination={(value, page) => {
                        return dispatch(
                          fetchItemCategories({
                            ...values,
                            brandId: selectedBrandId,
                            itemId: selectedItem.id,
                            page,
                          })
                        );
                      }}
                      loading={fetchingSubCategory}
                      showSearch
                      border
                      value={values.subCategoryId}
                      onSearchChange={value => searchSubCategory(value, 'category')}
                      dataSource={subCategories}
                      onClick={(value, data) => handleSubCategorySelect(data, 'partType')}
                      dataSourceValue="sub_category_name"
                      renderItem={data => {
                        return (
                          <div key={data.id} className="flex flex-1 justify-between p-2">
                            <div className="self-center">
                              <div className="text-gray-600 text-sm">{data.category_name}</div>
                              <div className="text-black">{data.sub_category_name}</div>
                            </div>

                            {data.used_by_brand > 0 && (
                              <Tooltip
                                title={`${data.used_by_brand} ${t(
                                  'partType:assignedProductsTextSubCategory'
                                )}`}
                              >
                                <Tag
                                  className="self-center"
                                  style={{
                                    margin: 0,
                                    color: '#7aace0',
                                    borderRadius: '8px',
                                    borderColor: '#7aace0',
                                    lineHeight: '14px',
                                    alignContent: 'center',
                                  }}
                                >
                                  {data.used_by_brand}
                                </Tag>
                              </Tooltip>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="flex-1 h-full">
                    <SelectList
                      title={t('partType:partType')}
                      rowHeight={ROW_HEIGHT}
                      placeholder={t('partType:searchPartType')}
                      onPagination={(value, page) => {
                        return dispatch(
                          fetchItemCategories({
                            ...values,
                            brandId: selectedBrandId,
                            itemId: selectedItem.id,
                            page,
                          })
                        );
                      }}
                      value={values.partTypeId}
                      loading={fetchingPartType}
                      showSearch
                      border
                      onClick={(value, data) => choosePartType(data)}
                      onSearchChange={value => searchPartType(value, 'category')}
                      dataSource={partTypesList}
                      dataSourceValue="part_type_name"
                      renderItem={data => {
                        const partTypeName = `${data.part_type_name}${
                          showPartTypeId && data.part_type_id ? ` (${data.part_type_id})` : ''
                        }`;
                        return (
                          <div
                            key={data.id}
                            className="flex flex-1 justify-between items-center p-2 overflow-hidden"
                            data-testid="part-type-data"
                          >
                            <div className="flex-1 overflow-hidden mr-4">
                              <Tooltip title={`${data.category_name} - ${data.sub_category_name}`}>
                                <div
                                  className="text-gray-600 text-sm truncate"
                                  title={`${data.category_name} - ${data.sub_category_name}`}
                                >
                                  {`${data.category_name} - ${data.sub_category_name}`}
                                </div>
                              </Tooltip>
                              <div className="text-black">
                                <Tooltip title={partTypeName}>
                                  <div className="sm:truncate md:truncate" title={partTypeName}>
                                    {partTypeName}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="self-center">
                              <div className="flex justify-between items-center">
                                {data.description && (
                                  <Tooltip title={data.description} className="mr-2">
                                    <InfoCircleOutlined
                                      style={{
                                        color: '#7aace0',
                                        fontSize: 18,
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {data.used_by_brand > 0 && (
                                  <Tooltip
                                    title={`${data.used_by_brand} ${t(
                                      'partType:assignedProductsTextPartType'
                                    )}`}
                                  >
                                    <Tag
                                      style={{
                                        margin: 0,
                                        color: '#7aace0',
                                        borderRadius: '8px',
                                        borderColor: '#7aace0',
                                        lineHeight: '14px',
                                        alignContent: 'center',
                                      }}
                                    >
                                      {data.used_by_brand}
                                    </Tag>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </DrawerFormik>
      )}
    </div>
  );
};

export default PartType;
