import React from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ExportStarted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="export-started flex flex-col">
      <div className="flex flex-col info-started">
        <ExportOutlined />
        {t('exporter:exportStarted')}
      </div>
      <div className="mt-4">{t('exporter:infoStepExporting')}</div>
    </div>
  );
};

export default ExportStarted;
