import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ItemContainer from '../../containers/item/ItemContainer';
import ApplicationContainer from '../../containers/application/ApplicationContainer';
import AttributesContainer from '../../containers/attributes/AttributesContainer';
import PackageContainer from '../../containers/package/PackageContainer';
import PricingContainer from '../../containers/pricing/PricingContainer';
import ExtendedInfoContainer from '../../containers/extended_info/ExtendedInfoContainer';
import DescriptionContainer from '../../containers/description/DescriptionContainer';
import KitContainer from '../../containers/kit/KitContainer';
import InterchangeContainer from '../../containers/interchange/InterchangeContainer';
import DigitalAssetsContainer from '../../containers/digital_assets/DigitalAssetsContainer';
import CustomFieldsItemContainer from '../../containers/custom_fields/CustomFieldsItemContainer';
import EbayContainer from '../../containers/ebay/EbayContainer';
import ItemChannelReceiverContainer from '../../containers/dashboard/ItemDashboardContainer';
import InsightsContainer from '../../containers/insights/InsightsContainer';
import RetoolContainer from '../../containers/retool/RetoolContainer';

const ProductRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="item" element={<ItemContainer />} />
      <Route path="application" element={<ApplicationContainer />} />
      <Route path="attributes" element={<AttributesContainer />} />
      <Route path="package" element={<PackageContainer />} />
      <Route path="pricing" element={<PricingContainer />} />
      <Route path="extended" element={<ExtendedInfoContainer />} />
      <Route path="description" element={<DescriptionContainer />} />
      <Route path="kit" element={<KitContainer />} />
      <Route path="interchange" element={<InterchangeContainer />} />
      <Route path="digital-assets" element={<DigitalAssetsContainer />} />
      <Route path="custom-fields" element={<CustomFieldsItemContainer />} />
      <Route path="ebay" element={<EbayContainer />} />
      <Route path="channel-receiver" element={<ItemChannelReceiverContainer />} />
      <Route path="insights" element={<InsightsContainer />} />
      <Route path="retool" element={<RetoolContainer />} />
    </Routes>
  );
};

export default ProductRoutes;
