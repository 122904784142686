import { combineReducers } from 'redux';
import channels from './ChannelReducer';
import logs from './DeliveryLogsReducer.ts';

const ChannelReducer = combineReducers({
  channels,
  logs,
});

export default ChannelReducer;
