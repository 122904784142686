import React from 'react';
import { Tag, Input } from 'antd';
import classNames from 'classnames';
import { ExtendedResources } from '../../../../types/resources';
import { Warranty, WARRANTY_OPTIONS } from '../../../constants/ExtendedInfoConstants';
import ExpiWarningIcon from './ExpiWarningIcon';
import { ExpiAnalysis } from '../../../../types/extendedInfo';
import { getValueAnalysis } from './extendedInfoUtils';
import { typingDone } from '../../../utils/Utils';

const { CheckableTag } = Tag;

type ExpiCheckableTagsProps = {
  label: string;
  values: string[];
  options: ExtendedResources[];
  renderOnlyCode?: boolean;
  addTextInputField?: boolean;
  analyses: ExpiAnalysis[];
  name: string;
  itemId: number;
  existingTypeValues: Record<string, { value: string; id: number }[]>;
  handleChange: (option: string[]) => void;
};

const ExpiCheckableTags: React.FC<ExpiCheckableTagsProps> = props => {
  const {
    label,
    handleChange,
    options,
    renderOnlyCode,
    addTextInputField,
    analyses,
    itemId,
    existingTypeValues,
    name,
    values,
  } = props;

  const [textValue, setTextValue] = React.useState(
    WARRANTY_OPTIONS.includes(values[0]) ? '' : values[0]
  );

  const [defaultAnalysis, setDefaultAnalysis] = React.useState(
    analyses.find(analysis =>
      existingTypeValues[name]?.find(
        type => type.value === values[0] && type.id.toString() === analysis.reference_id?.toString()
      )
    )
  );

  React.useEffect(() => {
    setDefaultAnalysis(
      analyses.find(analysis =>
        existingTypeValues[name]?.find(
          type =>
            type.value === values[0] && type.id.toString() === analysis.reference_id?.toString()
        )
      )
    );
    setTextValue(WARRANTY_OPTIONS.includes(values[0]) ? '' : values[0]);
  }, [analyses, existingTypeValues, name, values]);

  const handleChangeOption = (optionCode: string) =>
    handleChange(values.includes(optionCode) ? [] : [optionCode]);

  const alertType = defaultAnalysis?.alert_type_id;

  const renderExpiIcon = (value: string) => {
    const analysis = getValueAnalysis(analyses, itemId, name, [value], existingTypeValues);

    return analysis ? <ExpiWarningIcon inlineAnalysis={analysis} /> : null;
  };

  return (
    <div className="extended-info__checkable-tags flex items-center flex-1">
      <div className="extended-info__label pl-3 self-start flex-1">{label}</div>
      <div
        className={classNames('extended-info__tags-wrapper', 'flex-1', {
          flex: addTextInputField,
        })}
      >
        {options.map(option =>
          addTextInputField && option.code === Warranty.Code ? (
            <span key={option.code}>
              <Input
                className="extended-info__tag-input"
                key={option.code}
                allowClear
                size="small"
                value={textValue}
                placeholder={option.name}
                onChange={e => {
                  const value = e.target.value;
                  setTextValue(value);
                  if (value) typingDone(() => handleChange([value]));
                  else handleChange([]);
                }}
                prefix={
                  !WARRANTY_OPTIONS.includes(values[0]) &&
                  defaultAnalysis?.details === values[0] &&
                  renderExpiIcon(values[0])
                }
                data-testid={option.name}
              />
            </span>
          ) : (
            <span
              className={classNames({
                'extended-info__checkable-tag_blue': !alertType,
                'extended-info__checkable-tag_yellow': alertType === 2,
                'extended-info__checkable-tag_red': alertType === 3,
              })}
              key={option.code}
            >
              <CheckableTag
                key={option.code}
                checked={values.includes(option.code!)}
                onChange={() => handleChangeOption(option.code!)}
                data-testid={option.name}
              >
                {renderExpiIcon(option.code!)}
                <span>{renderOnlyCode ? option.code : `${option.code} - ${option.name}`}</span>
              </CheckableTag>
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default ExpiCheckableTags;
