import React from 'react';
import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type InfoTooltipProps = {
  className?: string;
  popupClassName?: string;
  elements: { id: number; name: string; options: { id: number; name: string }[] }[];
  selectedElements: any;
  placeholder?: string;
  size?: SizeType;
  disabled?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
  onChange: any;
  onSelect?: any;
  onDeselect: any;
};

const AntMultiSelectGroups: React.FC<InfoTooltipProps> = ({
  className,
  popupClassName,
  elements,
  selectedElements,
  placeholder,
  size,
  disabled,
  showSearch,
  allowClear,
  onChange,
  onSelect,
  onDeselect,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedElements}
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      placeholder={placeholder}
      size={size}
      allowClear={allowClear}
      showSearch={showSearch}
      filterOption={(input, option) =>
        option?.options?.find(
          (opt: any) => opt.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
      disabled={disabled}
      className={className}
      popupClassName={popupClassName}
      getPopupContainer={trigger => (trigger && trigger.parentNode) || document.body}
    >
      {elements.map(element => (
        <Select.OptGroup key={element.id} label={element.name}>
          {element.options.map(option => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};

export default AntMultiSelectGroups;
