import React from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Spin, Button, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { ApplicationState } from '../../../reducers';
import PageFormik, { CustomFormikActions } from '../../global/page/PageFormik';
import { Group, Format, GroupType } from '../../../../types/resources';
import { updateExtendedInfo } from '../../../actions/items/extended_info/update';
import { AsyncDispatch } from '../../../../types/global';
import { ExpiType, ExpiGroup, ExpiNewContent } from '../../../../types/extendedInfo';
import ExpiSelectTags from './ExpiSelectTags';
import { fetchUsedExtendedInfoTypes } from '../../../actions/items/extended_info/fetch';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import { addOptionsToExtendedInfoTypes } from '../../../selectors/extended_info/extendedInfoSelector';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { COUNTRY_CODES, Warranty } from '../../../constants/ExtendedInfoConstants';
import ExpiCheckableTags from './ExpiCheckableTags';
import ExpiCountryTagSelect from './ExpiCountryTagSelect';
import ExpiWarningIcon from './ExpiWarningIcon';
import {
  getGroupAnalyses,
  getUsedGroups,
  getTypeContentStrings,
  getTypeContents,
} from './extendedInfoUtils';
import ExpiDrawer from './ExpiDrawer';
import LabelSwitch from '../../global/LabelSwitch';
import IntercomFormEventHandler from '../../global/page/IntercomFormEventHandler';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { hasPermission } from '../../../utils/Permissions';

type ExtendedInfoPageProps = {
  multiSelect: boolean;
  multiItemTypes: ExpiType[];
  differentValueTypeIds: number[];
};

const ExtendedInfoPage: React.FC<ExtendedInfoPageProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const [expiDrawerVisible, setExpiDrawerVisible] = React.useState<boolean>(false);
  const [groupType, setGroupType] = React.useState<GroupType | undefined>(undefined);
  const {
    selectedItems,
    selectedItemId,
    fetchingExpi,
    fetchingSelectedItemsList,
    singleExtendedInfo,
    usedExtendedInfoTypes,
    analysesBySegment,
    extendedInfoResource,
    countries,
    selectedBrandId,
    selectedItemsLength,
    allSelectedItemIds,
    reverseSelected,
    reversedItemIds,
    extendedInfos,
    canMaintainProduct,
  } = useSelector((state: ApplicationState) => ({
    selectedItems: getSelectedItems(state),
    extendedInfoResource: addOptionsToExtendedInfoTypes(state),
    selectedItemsLength: state.catalogue.catalogue.selectedItemIds.length,
    selectedItemId: state.catalogue.catalogue.selectedItemIds[0],
    countries: state.resources.data.global.countries,
    extendedInfos: state.items.extendedInfo.extendedInfos,
    singleExtendedInfo: state.items.extendedInfo.extendedInfos[0],
    usedExtendedInfoTypes: state.items.extendedInfo.usedExtendedInformationTypes,
    fetchingExpi: state.items.extendedInfo.fetchingExpi,
    analysesBySegment: extendedAnalysesBySegment(state),
    selectedBrandId: state.parent.brands.selectedBrandId,
    fetchingSelectedItemsList: state.catalogue.catalogue.fetchingSelectedItemsList,
    reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
    reverseSelected: state.catalogue.catalogue.reverseSelected,
    canMaintainProduct: hasPermission(state.user.user, 'can_maintain_products'),
  }));

  const [showAllFields, setShowAllFields] = React.useState<boolean>(
    usedExtendedInfoTypes?.length === 0
  );

  React.useEffect(() => {
    if (selectedItemId) setShowAllFields(usedExtendedInfoTypes?.length === 0);
  }, [selectedItemId, usedExtendedInfoTypes, usedExtendedInfoTypes?.length]);

  const setInitialValues = () => {
    const existingValues = props.multiSelect
      ? getTypeContentStrings(props.multiItemTypes)
      : getTypeContentStrings(singleExtendedInfo?.types || []);

    const types: string[] = [];
    extendedInfoResource.groups.forEach((group: Group) => {
      group.types.forEach(type => {
        types.push(type.id.toString());
      });
    });
    const typesInitialValues: Record<string, string[]> = {};
    types.forEach(type => {
      if (Object.keys(existingValues).includes(type)) {
        typesInitialValues[type] = existingValues[type];
      } else {
        typesInitialValues[type] = [];
      }
    });
    return typesInitialValues;
  };

  const getSelectedItemIds = () =>
    reverseSelected
      ? allSelectedItemIds.filter(id => !reversedItemIds.includes(id))
      : allSelectedItemIds;

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError } = formikActions;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'extended-info',
      part_number: selectedItems.map(i => i.part_number).toString(),
      brand_code: selectedItems[0]?.brand_code,
    });

    setSubmitPending();

    const types: { id: number; contents: ExpiNewContent[] }[] = [];

    Object.keys(values).forEach(type => {
      const contents: ExpiNewContent[] = [];
      values[type].forEach((value: string) => {
        contents.push({ value, language_id: 1 });
      });
      types.push({ id: Number(type), contents });
    });

    const filterTypes = () => types.filter(type => !props.differentValueTypeIds.includes(type.id));

    const itemIds = getSelectedItemIds();
    const extendedInfos =
      itemIds.length > 1
        ? itemIds.map(id => ({ item_id: id, types: filterTypes() }))
        : [{ item_id: selectedItemId, types }];

    dispatch(updateExtendedInfo(extendedInfos))
      .then(() => {
        const usedExtendedInfoTypeIds = usedExtendedInfoTypes.map(type => type.type_id);
        if (types.find(t => !usedExtendedInfoTypeIds.includes(t.id))) {
          dispatch(fetchUsedExtendedInfoTypes(selectedBrandId));
        }
        setSubmitSuccess();
      })
      .catch(() => setSubmitError());
  };

  const renderMultiEditButton = (type: GroupType) => (
    <div className="multi-edit-button mx-1">
      <Button
        onClick={() => {
          setExpiDrawerVisible(true);
          setGroupType(type);
        }}
        size="small"
      >
        {t('extendedInfo:multiEditing')}
      </Button>
    </div>
  );

  const renderMultiValueEditLink = (type: GroupType) => (
    <div className="flex items-center flex-1">
      <div className="extended-info__label pl-3 self-start flex-1">{type.name}</div>
      <Button
        type="link"
        onClick={() => {
          setExpiDrawerVisible(true);
          setGroupType(type);
        }}
        style={{ padding: 0 }}
      >
        {t('extendedInfo:multipleValues')}
      </Button>
    </div>
  );

  if (fetchingExpi || fetchingSelectedItemsList) return <Spin className="spinner-center" />;
  return (
    <PageFormik
      showNoError={!canMaintainProduct}
      enableReinitialize
      initialValues={setInitialValues()}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      showAnalysis
      contentNoSpacing
    >
      {({ values, setFieldValue }) => {
        const analyses = analysesBySegment;
        const extendedInfo = props.multiSelect
          ? extendedInfos.find(e => e.item_id === selectedItemId)!
          : singleExtendedInfo;
        const existingTypeValues = getTypeContents(extendedInfo);
        const usedGroups: ExpiGroup[] = getUsedGroups(extendedInfoResource, usedExtendedInfoTypes);

        const renderExpiIcon = (type: GroupType) => {
          const typeCode = existingTypeValues[type.id.toString()]?.length > 0 ? '' : type.code;
          const groupedAnalysesTexts = getGroupAnalyses(
            analyses,
            type.id.toString(),
            existingTypeValues!,
            typeCode,
            selectedItemId
          );
          return (
            <div className="right__warn-icon">
              <ExpiWarningIcon groupedAnalyses={groupedAnalysesTexts} isGroupedWarnTexts />
            </div>
          );
        };

        const renderExpiCheckableTags = (type: GroupType) => (
          <ExpiCheckableTags
            label={type.name}
            values={values[type.id.toString()]}
            options={type.options}
            handleChange={(option: string[]) => setFieldValue(type.id.toString(), option)}
            renderOnlyCode={type.options[0]?.code === type.options[0]?.name}
            addTextInputField={type.id === Warranty.TypeId}
            analyses={analyses}
            itemId={selectedItemId}
            existingTypeValues={existingTypeValues}
            name={type.id.toString()}
          />
        );

        const renderExpiSelectTags = (type: GroupType) => (
          <ExpiSelectTags
            label={type.name}
            name={type.id.toString()}
            maxLength={type.max_length}
            minLength={type.min_length}
            values={values[type.id.toString()]}
            placeholder={type.application!}
            itemId={selectedItemId}
            analyses={analyses}
            existingTypeValues={existingTypeValues}
          />
        );

        const renderExpiCountry = (type: GroupType) => (
          <ExpiCountryTagSelect
            label={type.name}
            placeholder={type.application!}
            handleChange={option => setFieldValue(type.id.toString(), option)}
            countries={countries}
            options={type.options}
            typeValues={values[type.id.toString()]}
          />
        );

        const renderExpiForm = (groups: ExpiGroup[]) => (
          <Form className="extended-info__form">
            {groups.map(group => (
              <div key={group.id} data-testid={group.name}>
                <div className="extended-info__row font-medium bg-gray-200 text-black pl-3">
                  {group.name}
                </div>
                {group.types.map(type => (
                  <div key={type.id} className="extended-info__row flex">
                    {allSelectedItemIds.length > 1 &&
                    props.differentValueTypeIds.includes(type.id) ? (
                      renderMultiValueEditLink(type)
                    ) : (
                      <React.Fragment>
                        {type.format === Format.Text && renderExpiSelectTags(type)}
                        {(type.format === Format.Array || type.format === Format.Bool) &&
                          (COUNTRY_CODES.includes(type.code)
                            ? renderExpiCountry(type)
                            : renderExpiCheckableTags(type))}
                      </React.Fragment>
                    )}
                    {selectedItemsLength > 1 && renderMultiEditButton(type)}
                    {renderExpiIcon(type)}
                  </div>
                ))}
              </div>
            ))}
          </Form>
        );

        return (
          <div className="extended-info-page__form">
            <LabelSwitch
              className="pr-2"
              displayToolTip={usedExtendedInfoTypes?.length > 0}
              infoText={t('common:hideFieldsInfo')}
              checked={showAllFields}
              onChange={() => setShowAllFields(!showAllFields)}
            />
            {showAllFields
              ? renderExpiForm(extendedInfoResource.groups)
              : renderExpiForm(usedGroups)}
            <div className="mt-2 pl-2 mb-2">
              <Button
                icon={showAllFields ? <UpOutlined /> : <DownOutlined />}
                size="small"
                onClick={() => setShowAllFields(!showAllFields)}
                data-testid="show-all-fields"
              >
                {showAllFields ? t('extendedInfo:hideUnusedFields') : t('common:showAllFields')}
              </Button>
            </div>

            <ExpiDrawer
              visible={expiDrawerVisible}
              groupType={groupType}
              onClose={() => setExpiDrawerVisible(false)}
            />

            <IntercomFormEventHandler
              segment="extended-info"
              partNr={selectedItems.map(i => i.part_number).toString()}
              brandCode={selectedItems[0]?.brand_code}
            />
          </div>
        );
      }}
    </PageFormik>
  );
};

export default ExtendedInfoPage;
