import React from 'react';
import { Badge, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import constants from '../../../constants/ApplicationTranslation.json';
import AntTooltip from '../../global/AntTooltip';
import { Application, ApplicationCategory } from '../../../../types/application';
import ApplicationCardConfig from './ApplicationCardConfig';
import ApplicationCardQualifier from './ApplicationCardQualifier';
import { ApplicationState } from '../../../reducers';

type ApplicationValuePreviewProps = {
  application: Application;
  type: string;
};

const ApplicationValuePreview: React.FC<ApplicationValuePreviewProps> = ({ application, type }) => {
  const { t } = useTranslation();

  const { positionResources } = useSelector((state: ApplicationState) => ({
    positionResources: state.resources.data.vehicle.positions,
  }));

  let count = 0;
  if (type === 'category') {
    count = ['category', 'qty', 'mfr_label', 'position_ids'].filter(key => application[key]).length;
  }
  if (type === 'qualifiers') {
    const { qualifiers, notes } = application;
    count = (qualifiers || []).length + (notes || []).length;
  }

  const partTypeRow = (partType?: ApplicationCategory) => {
    const level1_name = partType?.level1_name || '';
    const level2_name = partType?.level2_name || '';
    const level3_name = partType?.level3_name || '';

    const categoryName = level3_name
      ? `${level1_name} | ${level2_name} | ${level3_name}`
      : constants.no_part_type;

    return (
      <ApplicationCardConfig
        configs={[{ value: categoryName }]}
        configKey="category"
        selectConfig={() => {}}
        noEdit
        disableSelection
      />
    );
  };

  const applicationBlocks = (application: Application) => {
    return Object.keys(application)
      .filter(key => ['qualifiers', 'notes', 'position_ids', 'qty', 'mfr_label'].includes(key))
      .map(key => {
        const subConfigs = [];

        if (key === 'mfr_label' || key === 'qty') {
          subConfigs.push({ value: application[key] });
        } else if (key === 'position_ids') {
          key = 'positions';
          const position = positionResources.find((p: any) => p.id === application.position_ids[0]);
          subConfigs.push({ values: [position] });
        } else if (Array.isArray(application[key])) {
          subConfigs.push({ values: application[key] });
        } else {
          Object.keys(application[key]).map(type => {
            if (Object.keys(application[key][type]).length > 0) {
              subConfigs.push({ key: type, values: application[key][type] });
            }
          });
        }

        if (subConfigs.length > 0) {
          if (key === 'qualifiers' && application.qualifiers) {
            return (
              <React.Fragment key={key}>
                {application.qualifiers.length > 0 && (
                  <ApplicationCardQualifier
                    qualifiers={application.qualifiers}
                    configKey={key}
                    noEdit
                  />
                )}
              </React.Fragment>
            );
          }
          if (key === 'notes' && application.notes) {
            return (
              <React.Fragment key={key}>
                {application.notes.length > 0 && (
                  <ApplicationCardConfig configs={subConfigs} configKey={key} noEdit />
                )}
              </React.Fragment>
            );
          }

          return <ApplicationCardConfig key={key} configs={subConfigs} configKey={key} noEdit />;
        }
      });
  };

  const tooltip = (
    <div key={application.id} className="application__card">
      <Card>
        {partTypeRow(application.category)}
        <div className="application__card_configs">{applicationBlocks(application)}</div>
      </Card>
    </div>
  );

  return (
    <AntTooltip overlayClassName="application__preview-tooltip" title={tooltip} placement="bottom">
      <div key={type} className="flex">
        <div className="application__config-nam">{t(`application:${type}`)}</div>
        <div className="flex flex-1 items-center px-1">
          <Badge count={count} className="ant-blue-batch badge-small" overflowCount={99} />
        </div>
      </div>
    </AntTooltip>
  );
};

export default ApplicationValuePreview;
