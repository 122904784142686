import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ParentExportLogsContainer from '../../containers/parentSettings/importExport/ParentExportLogsContainer';
import ParentImportLogsContainer from '../../containers/parentSettings/importExport/ParentImportLogsContainer';
import VcdbImportExportContainer from '../../containers/parentSettings/vcdb/VcdbImportExportContainer';
import CustomVcdbSettingsContainer from '../../containers/parentSettings/vcdb/CustomVcdbSettingsContainer';

const CustomVehicleTableRoutes = () => (
  <Routes>
    <Route path="custom-vcdb-table" element={<CustomVcdbSettingsContainer />} />
    <Route path="import-export" element={<VcdbImportExportContainer />} />
    <Route path="import-logs" element={<ParentImportLogsContainer />} />
    <Route path="export-logs" element={<ParentExportLogsContainer />} />
  </Routes>
);

export default CustomVehicleTableRoutes;
