import * as Yup from 'yup';

import validationMessages from '../constants/ValidationMessages.json';

export const planValidationSchema = {
  name: Yup.string().required(validationMessages.required),
  fileTypeId: Yup.number().required(validationMessages.required),
};

export const receiverPlanValidationSchema = {
  name: Yup.string().required(validationMessages.required),
  fileTypeId: Yup.number().required(validationMessages.required),
};

export const deliveryValidationSchema = {
  deliveryTypeId: Yup.number().required(validationMessages.required),
  ftpDirectoryTypeId: Yup.number()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
      deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
    ),
  // ftpModeId: Yup.number().when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
  //   deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
  // ),
  protocolTypeId: Yup.number()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
      deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
    ),
  // ftpEncryptionTypeId: Yup.number().when(['deliveryTypeId'], (deliveryTypeId, schema) =>
  //   deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
  // ),
  customDirectory: Yup.string(),
  server: Yup.string()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) => {
      return deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema;
    }),
  user: Yup.string()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
      deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
    ),
  password: Yup.string()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
      deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
    ),
  port: Yup.number()
    .nullable()
    .when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
      deliveryTypeId === 1 ? schema.required(validationMessages.required) : schema
    ),
  email: Yup.string().when(['deliveryTypeId'], ([deliveryTypeId], schema) =>
    deliveryTypeId === 2 ? emailSchema.required(validationMessages.required) : schema
  ),
};

export const scheduleValidationSchema = {
  deliveryFrequencyId: Yup.number().required(validationMessages.required),
  deliveryDay: Yup.number(),
  deliveryDate: Yup.number(),
  deliveryTime: Yup.string(),
};

export const ebayValidationSchema = (noPriceValidation?: boolean) => ({
  defaultIntegrationSettings: Yup.boolean(),
  withoutPrices: Yup.number().nullable(),
  titleDescriptionTypeId: Yup.number().nullable().required(validationMessages.required),
  // only validate if not withoutPrices
  priceSheetId: noPriceValidation
    ? Yup.number().nullable().notRequired()
    : Yup.number().when(['withoutPrices', 'defaultIntegrationSettings'], {
        is: (withoutPrices: number, defaultIntegrationSettings: any) =>
          !defaultIntegrationSettings && (withoutPrices === 0 || !withoutPrices),
        then: schema => schema.nullable().required(validationMessages.required),
        otherwise: schema => schema.nullable().notRequired(),
      }),
  priceTypeId: Yup.number().nullable(),
});

export const emailSchema = Yup.array()
  .transform(originalValue => {
    // Transform comma-separated string into array
    return originalValue ? originalValue.split(/[\s,]+/) : [];
  })
  .of(Yup.string().email(({ value }) => `${validationMessages.invalidEmail} ${value}! `));
