import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deletePriceSheet(sheetId: number) {
  return {
    type: 'DELETE_PRICE_SHEET',
    meta: { sheetId },
    payload: axios.delete(`${getApiRoot()}/parents/price_sheets/${sheetId}`),
  };
}

export function unassignPriceSheetToReciver(sheetId: number) {
  const params = utils.createURL([{ name: 'price_sheet_ids', values: sheetId }]);
  return {
    type: 'DELETE_ASSIGNED_PRICE_SHEET',
    meta: { sheetId },
    payload: axios.delete(
      `${getApiRoot()}/parents/receivers/authorized_brands/price_sheets${params}`
    ),
  };
}
