import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { showIntercom } from '../../../utils/IntercomUtils';

type DashboardUpgradeModalProps = {
  showModal: boolean;
  firstName?: string;
  onCancel: () => void;
};

const DashboardUpgradeModal: React.FC<DashboardUpgradeModalProps> = props => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.showModal}
      title={<div className="text-center">{t('homeContainer:qualitiyDashboard')}</div>}
      onCancel={() => props.onCancel()}
      footer={[
        <div className="text-center">
          <Button onClick={() => showIntercom()} type="primary">
            {t('common:contactSales')}
          </Button>
        </div>,
      ]}
    >
      <div>{`${t('homeContainer:hiUser', { username: props.firstName })},`}</div>
      <p>{t('homeContainer:qualityInfo')}</p>
      <p>{t('homeContainer:upgradePlanInfo')}</p>

      <ul className="ml-5">
        <li>{t('homeContainer:featureInfo1')}</li>
        <li>{t('homeContainer:featureInfo2')}</li>
        <li>{t('homeContainer:featureInfo3')}</li>
      </ul>
    </Modal>
  );
};

export default DashboardUpgradeModal;
