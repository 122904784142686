import React from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import itemConstants from '../../constants/ItemTranslation.json';
import PartTypeDetails from '../body/item/PartTypeDetails';
import ItemCategory from '../body/item/ItemCategory';
import * as utils from '../../utils/Utils';

export default class FilterCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords0: '',
      keywords1: '',
      keywords2: '',
      keywords3: '',
      selectedCategory: null,
      selectedSubCategory: null,
      selectedPartType: null,
    };
  }

  getCategoryFilter = () => {
    const { filter } = this.props;
    if (filter.filter_category) {
      return Object.values(filter.filter_category)[0];
    }
  };

  selectCategory = category => {
    if (this.state.selectedCategory && this.state.selectedCategory.id === category.id) {
      this.setState({ selectedCategory: null, selectedSubCategory: null, selectedPartType: null });
      this.fetchCategories({ categoryId1: null });
    } else {
      this.setState({
        selectedCategory: category,
        selectedSubCategory: null,
        selectedPartType: null,
      });
      this.fetchCategories({ categoryId1: category.id, level: 1 });
    }
  };

  selectSubCategory = subCategory => {
    const { categories } = this.props;
    if (this.state.selectedSubCategory && this.state.selectedSubCategory.id === subCategory.id) {
      this.setState({ selectedSubCategory: null, selectedPartType: null });
      this.fetchCategories({ categoryId2: null });
    } else {
      const selectedCategory = categories.find(cat => cat.id === subCategory.parent_id);
      this.setState({ selectedCategory, selectedSubCategory: subCategory, selectedPartType: null });
      this.fetchCategories({
        categoryId1: selectedCategory.id,
        categoryId2: subCategory.id,
        level: 2,
      });
    }
  };

  selectPartType = partType => {
    const { categories, subCategories } = this.props;
    if (this.state.selectedPartType && this.state.selectedPartType.id === partType.id) {
      this.setState({ selectedPartType: null });
    } else {
      const subCategory = subCategories.find(cat => cat.id === partType.parent_id);
      const category = categories.find(cat => cat.id === subCategory?.parent_id);
      this.setState({
        selectedCategory: category,
        selectedSubCategory: subCategory,
        selectedPartType: partType,
      });
    }
  };

  fetchCategories = (params = {}) => {
    const page = params.page;
    let categoryId1;
    if (params.categoryId1 || params.categoryId1 === null) categoryId1 = params.categoryId1;
    else categoryId1 = this.state.selectedCategory ? this.state.selectedCategory.id : null;
    let categoryId2;
    if (params.categoryId2 || params.categoryId2 === null) categoryId2 = params.categoryId2;
    else categoryId2 = this.state.selectedSubCategory ? this.state.selectedSubCategory.id : null;

    params = {
      categoryId1,
      categoryId2,
      keywords0: params.hasOwnProperty('keywords0') ? params.keywords0 : this.state.keywords0,
      keywords1: params.hasOwnProperty('keywords1') ? params.keywords1 : this.state.keywords1,
      keywords2: params.hasOwnProperty('keywords2') ? params.keywords2 : this.state.keywords2,
      keywords3: params.hasOwnProperty('keywords3') ? params.keywords3 : this.state.keywords3,
      activeLevel: params.level,
    };
    this.props.fetchCategories(params, page);
  };

  fetchNext = (event, level) => {
    const { fetchingCategory, fetchingSubCategory, fetchingPartType } = this.props;
    const nextPage = utils.nextPage(event);
    if (nextPage && (!fetchingCategory || !fetchingSubCategory || !fetchingPartType)) {
      this.fetchCategories({ page: nextPage, level });
    }
  };

  updateCategoryFilter = category => {
    this.props.updateCategoryFilter(category);
  };

  getLevelOptionId = level => {
    const categoryFilter = this.getCategoryFilter();
    let id = 0;
    if (categoryFilter) {
      Object.values(categoryFilter.parameters).forEach(parameter => {
        if (level === parameter.level) id = parameter.option_id;
      });
    }
    return id;
  };

  filterItemCategories = filter => {
    this.setState({ keywords1: filter });
    this.fetchCategories({ keywords1: filter, level: 0 });
  };

  filterItemSubCategories = filter => {
    this.setState({ keywords2: filter });
    this.fetchCategories({ keywords2: filter, level: 0 });
  };

  filterItemPartTypes = filter => {
    this.setState({ keywords3: filter });
    this.fetchCategories({ keywords3: filter, level: 0 });
  };

  handleSmartMappingSearch = input => {
    utils.typingDone(() => {
      this.setState({ keywords0: input });
      this.fetchCategories({ keywords0: input, level: 0 });
    });
  };

  clearSmartMappingInput = () => {
    this.handleSmartMappingSearch('');
    this.smartMappingSearch.value = '';
    this.smartMappingSearch.focus();
  };

  setCategoryColumnWidth = () => {
    if (
      this.props.selectedPartType &&
      (this.props.selectedPartType.description_id > 1 || this.props.selectedPartType.used_by_brand)
    ) {
      return { width: '25%' };
    }
    return {};
  };

  partTypeDetails = () => {
    if (
      this.props.selectedPartType &&
      (this.props.selectedPartType.description_id > 1 || this.props.selectedPartType.used_by_brand)
    ) {
      return (
        <PartTypeDetails
          description={this.props.selectedPartType.description}
          articles={this.props.categoryItems}
          fetchNext={this.fetchNextItems}
          fetching={this.props.fetching}
        />
      );
    }
  };

  render() {
    return (
      <div className="item__mapping_area" id="mappingArea">
        <div className="item__search_form">
          <div className="item__smart_mapping item_input">
            <SearchOutlined className="item__input_icon pb-1" />
            <input
              className="item__input_text"
              placeholder={itemConstants.smartMapping}
              onChange={event => this.handleSmartMappingSearch(event.target.value)}
              ref={input => (this.smartMappingSearch = input)}
            />
            <CloseOutlined
              className="item__input_button"
              onClick={() => this.clearSmartMappingInput()}
            />
          </div>
        </div>
        <div className="item__category_mapping">
          <ItemCategory
            submit={this.selectCategory}
            updateCategory={this.updateCategoryFilter}
            optionId={this.getLevelOptionId(1)}
            updateFilterOption={this.props.updateCategoryFilterOption}
            categories={this.props.categories}
            filter={this.filterItemCategories}
            selected={this.state.selectedCategory}
            width={this.setCategoryColumnWidth()}
            filterView // show filter dropdown
            fetchNext={this.fetchNext}
            fetching={this.props.fetchingCategory}
            title={itemConstants.category}
            level={1}
          />
          <ItemCategory
            submit={this.selectSubCategory}
            updateCategory={this.updateCategoryFilter}
            optionId={this.getLevelOptionId(2)}
            updateFilterOption={this.props.updateCategoryFilterOption}
            categories={this.props.subCategories || []}
            filter={this.filterItemSubCategories}
            selected={this.state.selectedSubCategory}
            width={this.setCategoryColumnWidth()}
            filterView // show filter dropdown
            fetchNext={this.fetchNext}
            fetching={this.props.fetchingSubCategory}
            title={itemConstants.subcategory}
            level={2}
          />
          <ItemCategory
            submit={this.selectPartType}
            updateCategory={this.updateCategoryFilter}
            optionId={this.getLevelOptionId(3)}
            updateFilterOption={this.props.updateCategoryFilterOption}
            categories={this.props.partTypes || []}
            filter={this.filterItemPartTypes}
            selected={this.state.selectedPartType}
            width={this.setCategoryColumnWidth()}
            categoryRowHeights={this.props.categoryRowHeights}
            setCategoryRowHeights={this.setCategoryRowHeights}
            filterView // show filter dropdown
            fetchNext={this.fetchNext}
            fetching={this.props.fetchingPartType}
            title={itemConstants.partType}
            level={3}
            showPartTypeId={this.props.showPartTypeId}
          />
          {this.partTypeDetails()}
        </div>
      </div>
    );
  }
}
