import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, Form } from 'antd';
import { useFormikContext } from 'formik';
import { SelectValue } from 'antd/lib/select';
import FormSelect from '../../global/Forms/FormSelect';
import { ApplicationState } from '../../../reducers';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';

type GapCoverageReportStep1Props = {
  handleCurrentStep: () => void;
};

const GapCoverageReportStep1: React.FC<GapCoverageReportStep1Props> = props => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();
  const { customFilters } = useSelector((state: ApplicationState) => {
    return {
      customFilters: getAllCustomFilters(state),
    };
  });

  const handlePartSelection = (partSelection: string) => {
    switch (partSelection) {
      case 'all': {
        setFieldValue('partSelection', partSelection);
        setFieldValue('filterId', null);
        props.handleCurrentStep();
        break;
      }
      case 'filter': {
        setFieldValue('partSelection', partSelection);
        break;
      }
    }
  };

  return (
    <div>
      <div className="mt-2 mb-6 text-gray-700">{t('gapCoverageReport:step1Description')}</div>
      <Form layout="horizontal">
        <Radio.Group
          onChange={e => {
            const partSelection = e.target.value;
            handlePartSelection(partSelection);
          }}
          value={values.partSelection}
          className="select-options"
        >
          <Radio key="all" value="all" className="radio-style" data-testid="all">
            {t('exporter:allParts')}
          </Radio>

          <div className="flex flex-row">
            <Radio key="filter" value="filter" className="radio-style">
              {t('exporter:filter')}
            </Radio>
            <FormSelect
              name="filterId"
              values={customFilters}
              size="small"
              allowClear
              placeholder={t('exporter:selectFilter')}
              showSearch
              onChange={(value: SelectValue) => {
                setFieldValue('filterId', value);
                setFieldValue('partSelection', 'filter');
                if (value) props.handleCurrentStep();
              }}
            />
          </div>
        </Radio.Group>
      </Form>
    </div>
  );
};

export default GapCoverageReportStep1;
