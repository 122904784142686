import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import {
  fetchImport,
  fetchImportLogDetailsJSON,
  fetchImportLogsDetailsExcel,
} from '../../actions/brand/import_export/fetch';
import { ApplicationState } from '../../reducers';
import { receiverImportLogMapper } from '../../selectors/importExportLogsSelector';
import ImportExportLogs from '../../components/body/import_export/ImportExportLogs';
import { AsyncDispatch } from '../../../types/global';
import { fetchReceiverImportLogs } from '../../actions/receiver_data_stream/import/fetch';
import { fetchUserNames } from '../../actions/user';

const ReceiverImportLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const { importLogs, fetchingImportLogs, fetchingImportErrors, importErrorLogDetail } =
    useSelector((state: ApplicationState) => ({
      importLogs: receiverImportLogMapper(state),
      fetchingImportLogs: state.receiver.receiverImport.fetchingImportLogs,
      fetchingImportErrors: state.brand.importExport.fetchingImportErrors,
      importErrorLogDetail: state.brand.importExport.importErrorLogDetail,
    }));

  React.useEffect(() => {
    dispatch(fetchReceiverImportLogs());
    dispatch(fetchUserNames());
  }, [dispatch]);

  const saveImportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'custom-fields_paramount_import_errors.xlsx');
  };

  const handleErrorDownload = (logId: number) =>
    dispatch(fetchImportLogsDetailsExcel(logId)).then(response =>
      saveImportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );

  const handleImportFileDownload = (logId: number) =>
    // @ts-ignore
    dispatch(fetchImport(logId));

  const handleFetchImportLogDetailsJSON = (logId: number) =>
    dispatch(fetchImportLogDetailsJSON(logId));

  return (
    <ImportExportLogs
      logs={importLogs}
      fetchingLogs={fetchingImportLogs}
      fetchingErrors={fetchingImportErrors}
      errorLogDetails={importErrorLogDetail}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleImportFileDownload}
      handleFetchLogDetailsJSON={handleFetchImportLogDetailsJSON}
      hideApplicationCount
      hideFileType
      hideItemCount
    />
  );
};

export default ReceiverImportLogsContainer;
