import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { BundleQualifier, ApplicationNote, Qualifier } from '../../../../types/application';
import DefaultQualifierContainer from '../../../containers/application/DefaultQualifierContainer';

type BundleQualifierDrawerProps = {
  drawerVisible: boolean;
  bundleNotes: ApplicationNote[];
  bundleQualifiers: BundleQualifier[];
  childItemId: number;
  onClose: () => void;
  updateBundle: (notes: ApplicationNote[], qualifiers: BundleQualifier[]) => void;
};

const BundleQualifierDrawer: React.FC<BundleQualifierDrawerProps> = ({
  drawerVisible,
  bundleNotes,
  bundleQualifiers,
  childItemId,
  onClose,
  updateBundle,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerFormik
      className="application__bundle-drawer"
      title={t('application:bundle.bundleDrawerTitle')}
      width="60%"
      destroyOnClose
      onClose={onClose}
      visible={drawerVisible}
      initialValues={{
        bundleNotes,
        bundleQualifiers,
      }}
      onSubmit={(values, formikActions) => {
        const { setSubmitting } = formikActions;
        setSubmitting(true);
        const qualifiers = values.bundleQualifiers.map((q: any) => ({ ...q, temp: false }));

        updateBundle(values.bundleNotes, qualifiers);
        setSubmitting(false);
      }}
    >
      {({ setFieldValue, values }) => (
        <React.Fragment>
          <div className="h-full overflow-hidden flex flex-col">
            <Typography.Text className="mb-2">
              {t('application:qualifier.tableTitle')}
            </Typography.Text>
            <div className="flex-1 flex flex-col overflow-auto">
              <DefaultQualifierContainer
                itemId={childItemId}
                defaultQualifiers={values.bundleQualifiers}
                deleteDefaultQualifier={(id: number, recordNumber: number) => {
                  const qualifiers = values.bundleQualifiers.filter(
                    (q: BundleQualifier) =>
                      !(q.qualifier_id === id && q.record_number === recordNumber)
                  );
                  setFieldValue('bundleQualifiers', qualifiers);
                }}
                updateDefaultQualifier={(qualifier: Qualifier & BundleQualifier) => {
                  const { bundleQualifiers } = values;

                  const existingQualifier = bundleQualifiers.find(
                    ({ qualifier_id }: BundleQualifier) => qualifier.qualifier_id === qualifier_id
                  );
                  const qualifiers = existingQualifier
                    ? bundleQualifiers.map((q: BundleQualifier) =>
                        q.qualifier_id === qualifier.qualifier_id &&
                        q.record_number === qualifier.record_number
                          ? qualifier
                          : q
                      )
                    : [...values.bundleQualifiers, qualifier];

                  setFieldValue('bundleQualifiers', qualifiers);
                }}
                duplicateDefaultQualifier={(qualifier: Qualifier) => {
                  const { bundleQualifiers } = values;
                  let index = 0;
                  bundleQualifiers.forEach((q: BundleQualifier, i: number) => {
                    if (q.qualifier_id === qualifier.qualifier_id) index = i;
                  });
                  const updatetQualifiers = [...bundleQualifiers];
                  updatetQualifiers.splice(index + 1, 0, qualifier);

                  setFieldValue('bundleQualifiers', updatetQualifiers);
                }}
                clearTempState={() => {
                  const updated = values.bundleQualifiers.map((q: BundleQualifier) => ({
                    ...q,
                    temp: false,
                  }));
                  setFieldValue('bundleQualifiers', updated);
                }}
                notes={values.bundleNotes}
                addNote={(noteValue?: string) => {
                  const { bundleNotes } = values;
                  const recordNumber = bundleNotes.length
                    ? Math.max(...bundleNotes.map((n: ApplicationNote) => n.record_number))
                    : 1;
                  const notes = [
                    ...bundleNotes,
                    { record_number: (recordNumber || 0) + 1, note: noteValue || '' },
                  ];
                  setFieldValue('bundleNotes', notes);
                }}
                removeNote={(recordNumber: number) => {
                  const { bundleNotes } = values;
                  const notes = bundleNotes.filter(
                    (n: ApplicationNote) => n.record_number !== recordNumber
                  );
                  setFieldValue('bundleNotes', notes);
                }}
                updateNote={(recordNumber: number, noteValue: string) => {
                  const note = values.bundleNotes.find(
                    (n: ApplicationNote) => n.record_number === recordNumber
                  );
                  const notes = note
                    ? values.bundleNotes.map((n: ApplicationNote) =>
                        n.record_number === recordNumber ? { ...n, note: noteValue } : n
                      )
                    : [...values.bundleNotes, { record_number: recordNumber, note: noteValue }];
                  setFieldValue('bundleNotes', notes);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </DrawerFormik>
  );
};

export default BundleQualifierDrawer;
