import React from 'react';
import classNames from 'classnames';
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Button, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as constants from '../../../constants/ImportExportConstants';
import { ImportTypes } from '../../../../types/import_export';

type UploadFieldProps = {
  fileType: any;
  name: string;
  big?: boolean;
  inactive: boolean;
  readFile: any;
  canUploadFile?: boolean;
};

const UploadField: React.FC<UploadFieldProps> = props => {
  const { t } = useTranslation();

  const [hover, setHover] = React.useState('import-export__upload');
  const [canImport, setCanImport] = React.useState<boolean>(props.fileType === ImportTypes.EXCEL);

  const handleDragEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setHover('import-export__upload--hover');
  };

  const handleDragLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setHover('import-export__upload');
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleFileSelect = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    props.readFile(e, file, props.fileType);
  };

  const getAcceptedFileExtension = () => {
    switch (props.fileType) {
      case ImportTypes.EXCEL:
        return constants.EXCEL_FILE_EXTENSION;
      case ImportTypes.ACES:
        return constants.XML_FILE_EXTENSION;
      case ImportTypes.PIES:
        return constants.XML_FILE_EXTENSION;
      case ImportTypes.ASSET:
        return constants.ASSET_FILE_EXTENSION;
      default:
        return '';
    }
  };

  const { fileType } = props;

  const isExcel = fileType === ImportTypes.EXCEL;
  const isAces = fileType === ImportTypes.ACES;
  const isPies = fileType === ImportTypes.PIES;
  const isAsset = fileType === ImportTypes.ASSET;
  const boxCSS = classNames('box', 'box_background', {
    'import-export__box--full': props.big,
    'import-export__box': !props.big,
  });
  const getBackgroundImg = () => {
    if (isExcel) return <FileExcelOutlined className="import-export__box-background" />;
    if (isAces) return <FileOutlined className="import-export__box-background" />;
    if (isPies) return <FileOutlined className="import-export__box-background" />;
    if (isAsset) return <FileImageOutlined className="import-export__box-background" />;
  };
  const excelDisabled = isExcel && !props.canUploadFile;
  const acesLocked = isAces && !canImport;
  const piesLocked = isPies && !canImport;

  if (props.inactive)
    return (
      <React.Fragment>
        <div className={`${boxCSS} bg-gray-300 cursor-not-allowed`}>
          <div className="mb-2">Import is running...</div>
          <Spin size="small" />
        </div>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      {excelDisabled || acesLocked || piesLocked ? (
        <div className={`${boxCSS}`}>
          {getBackgroundImg()}
          {!canImport && (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => setCanImport(true)} ghost danger>
                <UnlockOutlined />
                {t('importer:import.unlock_import', {
                  fileType: ImportTypes[props.fileType],
                })}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div
          className={boxCSS}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onMouseEnter={handleDragEnter}
          onMouseLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleFileSelect}
        >
          <div className={hover}>
            {getBackgroundImg()}
            {t('importer:uploadField.upload')} {props.name}
            <span className="import-export__upload-info">
              {t('importer:uploadField.clickOrDrop')}
            </span>
            <input
              id="excel-flyout__import"
              type="file"
              accept={getAcceptedFileExtension()}
              onChange={e => {
                const fileSizeInGB =
                  e.target.files && e.target.files[0] ? e.target.files[0].size / 1024 ** 3 : 0;
                if (fileSizeInGB <= 4) {
                  props.readFile(e, null, props.fileType);
                } else {
                  message.error(t('importer:import.asset_too_large'), 5);
                }
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadField;
