import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import constantsTranslation from '../../../constants/DigitalAssetsTranslation.json';
import constants from '../../../constants/DigitalAssetsConstants.json';
import DropZone from './DropZone';

class DigitalAssetUpload extends React.Component {
  render() {
    return (
      <div className="digital-asset-uploader">
        <div
          className="digital-asset__upload-button"
          onClick={() => this.props.handleOpenModal(constants.popupType.url)}
        >
          <PlusCircleOutlined />
          <span className="add-section__text">{constantsTranslation.add_file.by_url}</span>
        </div>
        <div
          className="digital-asset__upload-button"
          onClick={() => this.props.handleOpenModal(constants.popupType.parentDigitalAssets)}
        >
          <PlusCircleOutlined />
          <span className="add-section__text">{constantsTranslation.add_file.linkRecordInPdm}</span>
        </div>

        <label className="digital-asset__upload-button" htmlFor="digital-asset__file-input">
          <input
            className="digital-asset__file-input"
            type="file"
            id="digital-asset__file-input"
            onClick={() => (this.fileInput.value = '')}
            onChange={e => this.props.readFile(e.target.files[0])}
            ref={input => (this.fileInput = input)}
          />
          <PlusCircleOutlined />
          <span className="add-section__text">{constantsTranslation.add_file.from_pc}</span>
        </label>

        <div
          className="digital-asset__upload-button"
          onClick={() => this.props.handleOpenModal(constants.popupType.external)}
        >
          <PlusCircleOutlined />
          <span className="add-section__text">{constantsTranslation.add_file.external}</span>
        </div>
        <DropZone handleDraggedFile={this.props.readFile} />
      </div>
    );
  }
}

export default DigitalAssetUpload;
