import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import BrandManagingPage from '../../../components/body/parentSettings/brandManaging/BrandManagingPage';

const BrandManagingContainer: React.FC = () => {
  const { fetchingBrands, brands } = useSelector((state: ApplicationState) => {
    return {
      fetchingBrands: state.parent.brands.fetchingBrands,
      brands: state.parent.brands.brands,
    };
  });

  if (fetchingBrands || brands.length === 0)
    return (
      <div className="mt-10">
        <Spin className="spinner-center" size="large" />
      </div>
    );

  return <BrandManagingPage />;
};

export default BrandManagingContainer;
