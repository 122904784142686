import { createSelector } from 'reselect';
import { ApplicationState } from '../reducers';
import { ListUser } from '../../types/user';
import { ReceiverDetails } from '../../types/receiver';

const getUserList = (state: ApplicationState) => state.user.userList as ListUser[];
const getCompanyInfo = (state: ApplicationState) =>
  state.settings.companyProfile.companyInfo as ReceiverDetails;

export const getPrimaryContacts = createSelector(
  [getUserList, getCompanyInfo],
  (userList, companyInfo) => {
    return userList
      .filter(user => !!user.active || user.id === companyInfo.primary_contact_id)
      .map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        primaryUser: !!user.primary_user_id,
        disabled: !user.active,
      }));
  }
);
