import React from 'react';
import { Button, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';

type BrandResetModalProps = {
  showModal: boolean;
  internalBrandCode: string;
  onCancel: () => void;
  resetBrand: () => Promise<any>;
};

const BrandResetModal: React.FC<BrandResetModalProps> = props => {
  const { t } = useTranslation();

  const [codeInput, setCodeInput] = React.useState<string>('');
  const [pending, setPending] = React.useState(false);

  const handleCancel = () => {
    setCodeInput('');
    props.onCancel();
  };

  const handleReset = async () => {
    setPending(true);
    await props.resetBrand();
    setCodeInput('');
    setPending(false);
  };

  return (
    <Modal
      open={props.showModal}
      onCancel={() => props.onCancel()}
      footer={[
        <Button key="cancel" onClick={() => handleCancel()}>
          {t('common:cancel')}
        </Button>,
        <Input
          key="input"
          value={codeInput}
          onChange={e => setCodeInput(e.target.value)}
          style={{ width: '70px', marginLeft: '15px' }}
        />,
        <Button
          key="save"
          danger
          loading={pending}
          onClick={() => handleReset()}
          disabled={codeInput !== props.internalBrandCode}
        >
          {t('common:reset')}
        </Button>,
      ]}
      closable={false}
    >
      <div className="text-base font-medium">
        <ExclamationCircleFilled className="text-xl yellow mr-3" />
        {t('brandManaging:resetBrand')}
      </div>
      <div className="mt-2 ml-8">{t('brandManaging:resetInfo')}</div>
      <div className="mt-2 ml-8">
        {t('brandManaging:enterBrandCode', { brandCode: props.internalBrandCode })}
      </div>
      <div className="mt-2 ml-8">{t('brandManaging:resetTimeInfo')}</div>
    </Modal>
  );
};

export default BrandResetModal;
