import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Layout } from 'antd';

import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/brand/import_export';
import { hasPermission } from '../../utils/Permissions';
import EnterprisePromotion from '../../components/global/EnterprisePromotion/EnterprisePromotion';
import { ApplicationState } from '../../reducers';
import ImportPage from '../../components/body/import_export/ImportPage';

const { Content } = Layout;

type ImportContainerProps = {
  brandId: number;
  hasImportAccess: boolean;
};

const ImportContainer: React.FC<ImportContainerProps> = props => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.fetchImportBrandStatus(props.brandId));
    dispatch(actions.fetchImportOptions(props.brandId));
  }, [dispatch, props.brandId]);

  return (
    <Content className="import-page__content">
      {!props.hasImportAccess ? <EnterprisePromotion /> : <ImportPage />}
    </Content>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  hasImportAccess: hasPermission(state.user.user, 'has_import_access'),
  brandId: state.parent.brands.selectedBrandId,
});

export default connect(mapStateToProps)(withContainerWrapper(ImportContainer));
