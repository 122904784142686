import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Tooltip, Button } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import analyseConstants from '../../../constants/AnalyseConstants.json';
import analyseTranslation from '../../../constants/AnalyseTranslation.json';

const options = {
  responsive: false,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 100,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxRotation: 0,
          autoSkip: false,
          color: analyseConstants.color_code.grey,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  layout: {
    padding: {
      top: 20,
    },
  },
  legend: {
    display: false,
  },
  events: [],
  tooltips: {
    enabled: false,
  },
};

class AnalyseQualitySegment extends React.Component {
  generateSegmentData = (segments = this.props.segments) => {
    const progressScores = segments.map(segment => segment.progress_score);

    const data = {
      labels: [
        'Items',
        'Attributes',
        'Application',
        'Description',
        'Packages',
        'Pricing',
        'Extended Info',
        'Kit',
        'Interchange',
        'Digital Assets',
        'Marketing Copies',
      ],
      datasets: [
        {
          label: 'Progress',
          data: progressScores,
          backgroundColor: Array(11).fill(analyseConstants.color_code.lightGreenOpacity),
          borderColor: Array(11).fill(analyseConstants.color_code.lightGreenOpacity),
          borderWidth: 2,
        },
      ],
    };
    return data;
  };

  navigationButton = (title, alertTypeId, segmentId) => {
    return (
      <Button
        onClick={() => this.props.handleNavigation(alertTypeId, segmentId)}
        size="small"
        data-testid={title}
      >
        {title}
      </Button>
    );
  };

  errorIcons = segments => {
    const { t } = this.props;
    let top = analyseConstants.iconSizes.topInit;
    let left = analyseConstants.iconSizes.leftInit;

    return segments.map(segment => {
      left += analyseConstants.iconSizes.leftAdd;
      if (!segment.item_error_count) {
        return;
      }
      top = analyseConstants.iconSizes.positionIconBottom;
      top = (top * analyseConstants.iconSizes.scaleUp) / 100;
      return (
        <Tooltip
          key={segment.segment_id}
          trigger="click"
          title={this.navigationButton(t('homeContainer:showErrors'), 3, segment.segment_id)}
          color="white"
        >
          <span className="analyse-icons__error" style={{ left, top }}>
            <WarningOutlined style={{ fontSize: '9px', verticalAlign: 'initial' }} />
            {segment.item_error_count}
          </span>
        </Tooltip>
      );
    });
  };

  warningIcons = segments => {
    const { t } = this.props;
    const itemErrorCount = segments.map(segment => segment.item_error_count);

    let top = analyseConstants.iconSizes.topInit;
    let left = analyseConstants.iconSizes.leftInit;

    return segments.map((segment, i) => {
      left += analyseConstants.iconSizes.leftAdd;
      if (!segment.item_warning_count) {
        return;
      }
      const errorsInSegment = itemErrorCount[i] > 0;
      top = errorsInSegment
        ? analyseConstants.iconSizes.positionIconMiddle
        : analyseConstants.iconSizes.positionIconBottom;
      top = (top * analyseConstants.iconSizes.scaleUp) / 100;
      return (
        <Tooltip
          key={segment.segment_id}
          trigger="click"
          title={this.navigationButton(t('homeContainer:showWarnings'), 2, segment.segment_id)}
          color="white"
        >
          <span className="analyse-icons__warning" style={{ left, top }}>
            <WarningOutlined style={{ fontSize: '9px', verticalAlign: 'initial' }} />
            {segment.item_warning_count}
          </span>
        </Tooltip>
      );
    });
  };

  infoIcons = segments => {
    const { t } = this.props;
    const itemErrorCount = segments.map(segment => segment.item_error_count);
    const itemWarningCount = segments.map(segment => segment.item_warning_count);

    let top = analyseConstants.iconSizes.topInit;
    let left = analyseConstants.iconSizes.leftInit;

    return segments.map((segment, i) => {
      left += analyseConstants.iconSizes.leftAdd;
      if (!segment.item_info_count) {
        return;
      }

      const errorsInSegment = itemErrorCount[i] > 0;
      const warningsInSegment = itemWarningCount[i] > 0;
      if (errorsInSegment && warningsInSegment) {
        top = analyseConstants.iconSizes.positionIconTop;
      } else if (errorsInSegment || warningsInSegment) {
        top = analyseConstants.iconSizes.positionIconMiddle;
      } else {
        top = analyseConstants.iconSizes.positionIconBottom;
      }

      top = (top * analyseConstants.iconSizes.scaleUp) / 100;
      return (
        <Tooltip
          key={segment.segment_id}
          trigger="click"
          title={this.navigationButton(
            t('homeContainer:showRecommendations'),
            1,
            segment.segment_id
          )}
          color="white"
        >
          <span className="analyse-icons__info" style={{ left, top }}>
            <WarningOutlined style={{ fontSize: '9px', verticalAlign: 'initial' }} />
            {segment.item_info_count}
          </span>
        </Tooltip>
      );
    });
  };

  checkIfAnalysesExist = () => {
    if (this.props.segments.length === 0) {
      return (
        <span className="analyses-not-existing">{analyseTranslation.no_analyses_existing}</span>
      );
    }
  };

  render() {
    let data = {};
    const segementsWithoutCustomField = this.props.segments.filter(
      segment => segment.segment_id !== 12
    );
    if (this.props.segments.length > 0) {
      data = this.generateSegmentData();
    }
    return (
      <div className="analyse-quality-segment analyse__border">
        <p className="title home-container__analyse-title">
          {analyseTranslation.quaility_segment} {this.checkIfAnalysesExist()}
        </p>
        <div>
          <Bar data={data} options={options} width={1080} height={252} />
        </div>
        {Object.keys(data).length > 0 && this.errorIcons(segementsWithoutCustomField)}
        {Object.keys(data).length > 0 && this.warningIcons(segementsWithoutCustomField)}
        {Object.keys(data).length > 0 && this.infoIcons(segementsWithoutCustomField)}
      </div>
    );
  }
}

export default withTranslation()(AnalyseQualitySegment);
