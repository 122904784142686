import React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VList } from 'react-virtualized';
import * as utils from '../../../utils/Utils';

type ApplicationPositionProps = {
  className: string;
  value?: number;
  rankedPositions: any[];
  positionResources: any[];
  defaultPosition?: string;
  size?: 'small' | 'middle' | 'large';
  onChange: (id: number | null) => void;
};

type ApplicationPositionState = {
  open: boolean;
  keywords: string;
};

export default class ApplicationPosition extends React.Component<
  ApplicationPositionProps,
  ApplicationPositionState
> {
  cache: CellMeasurerCache;

  constructor(props: ApplicationPositionProps) {
    super(props);
    this.cache = new CellMeasurerCache({ defaultHeight: 30, fixedWidth: true });
    this.state = { open: false, keywords: '' };
  }

  handleSearch = (keywords: string) => {
    this.setState({ keywords });
    this.cache.clearAll();
  };

  selectPosition = (positionId: number | null) => {
    this.props.onChange(positionId);
    this.setState({ open: false });
  };

  filterRows = (keywords: string) => {
    const keywordList = utils.stringToArray(keywords.toLowerCase());
    const data = this.positionData(this.props.positionResources);

    const filteredData = data.filter(element => {
      const name = element.name.toLowerCase();
      let found = true;
      keywordList.forEach(keyword => {
        if (found) {
          found = name.includes(keyword);
        }
      });
      return found;
    });
    return filteredData.sort((a, b) => {
      if (a.name.length === b.name.length) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      if (a.name.length < b.name.length) return -1;
      if (a.name.length > b.name.length) return 1;
      return 0;
    });
  };

  positionData = (positions: any[]) => {
    positions = positions.filter(
      position => !this.props.rankedPositions.find(ranked => ranked.id === position.id)
    );

    const rankedPositions = this.props.rankedPositions.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return [...rankedPositions, ...positions];
  };

  rows = () => {
    const { value } = this.props;
    const data = !this.state.keywords
      ? this.positionData(this.props.positionResources)
      : this.filterRows(this.state.keywords);

    const renderRow = ({ index, parent, key, style }: any) => {
      const position = data[index];
      const selected = value === Number(position.id);

      return (
        <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          <div
            key={key}
            style={style}
            onClick={() => this.selectPosition(position.id)}
            className={classNames(
              'application__position_row flex items-center text-gray-900 p-1 hover:bg-gray-300',
              { 'bg-primary-100': selected, rank1: position.ranked }
            )}
          >
            <div className="application__row_name">{position.name}</div>
          </div>
        </CellMeasurer>
      );
    };

    return (
      <div
        className="dropdown-wrapper"
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <AutoSizer disableHeight>
          {({ width }) => (
            <VList
              deferredMeasurementCache={this.cache}
              height={300}
              rowCount={data.length}
              rowHeight={this.cache.rowHeight}
              rowRenderer={renderRow}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  render() {
    const {
      value,
      rankedPositions,
      positionResources,
      size,
      className,
      defaultPosition,
      onChange,
    } = this.props;
    const { open } = this.state;

    const rankedPosition = value && rankedPositions.find(pos => value === pos.id);

    const selectedPosition = rankedPosition || positionResources.find(pos => value === pos.id);

    return (
      <Select
        open={open}
        className={classNames({ rank1: rankedPosition && rankedPosition.ranked }, className)}
        value={selectedPosition && selectedPosition.name}
        placeholder={defaultPosition || undefined}
        dropdownRender={this.rows}
        onBlur={() => {
          this.setState({ open: false });
        }}
        onDropdownVisibleChange={open => this.setState({ open })}
        showSearch
        onSearch={this.handleSearch}
        onChange={val => {
          if (!val) onChange(null);
        }}
        allowClear
        size={size}
      />
    );
  }
}
