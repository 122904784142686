import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { QuickStartGuide } from '../../../../types/home';
import { ApplicationState } from '../../../reducers';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';
import { intercomEvent } from '../../../utils/IntercomUtils';
import QuickStartSteps from './QuickStartSteps';
import WelcomeMessage from './WelcomeMessage';

type WelcomePageProps = {
  guideSteps: QuickStartGuide;
  handleHideWelcomeMessage: () => void;
  handleSkipStep: (stepId: number) => void;
  displayHideGuide: boolean;
  handleHideScreen: (hide: boolean) => void;
};

const WelcomePage: React.FC<WelcomePageProps> = props => {
  const { t } = useTranslation();

  const { brandCode } = useSelector((state: ApplicationState) => {
    return {
      brandCode: getSelectedBrandCode(state),
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-home', {
      location: 'home-welcome',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  return (
    <div className="welcome-page grid justify-items-center">
      {props.guideSteps.hide_welcome_message !== 1 && (
        <WelcomeMessage handleHideWelcomeMessage={props.handleHideWelcomeMessage} />
      )}
      <div className="bg-white">
        <QuickStartSteps guideSteps={props.guideSteps} handleSkipStep={props.handleSkipStep} />
      </div>
      {props.displayHideGuide && (
        <div className="mt-6 flex flex-col items-center">
          <h5>{t('welcome:hideWelcomeScreen')}</h5>
          <div>
            <Button onClick={() => props.handleHideScreen(false)}>{t('common:no')}</Button>
            <Button type="primary" className="ml-2" onClick={() => props.handleHideScreen(true)}>
              {t('welcome:hide')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomePage;
