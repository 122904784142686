import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export const updateEbayItemSettings = (params: {
  itemId: number;
  listingType?: string | null;
  listingLength?: string | null;
  fulfillmentId?: number | null;
  paymentId?: number | null;
  returnId?: number | null;
  primaryCategoryId?: number | null;
  secondaryCategoryId?: number | null;
  title?: string | null;
  partNumber?: string | null;
  description?: string | null;
  price?: string | null;
}) => {
  return {
    type: 'UPDATE_EBAY_ITEM_SETTINGS',
    payload: axios.patch(`${getApiRoot()}/items/ebay_settings`, {
      item_id: params.itemId,
      listing_type: params.listingType,
      listing_length: params.listingLength,
      fulfillment_id: params.fulfillmentId,
      payment_id: params.paymentId,
      return_id: params.returnId,
      primary_category_id: params.primaryCategoryId,
      secondary_category_id: params.secondaryCategoryId,
      title: params.title,
      part_number: params.partNumber,
      description: params.description,
      price: params.price,
    }),
  };
};
