import axios from 'axios';
import { getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import { showModal, hideModal } from '../app/modal';
import { MODAL_TYPES } from '../../constants/ModalConstants';
import { isValidJSON } from '../../utils/Utils';
import { fetchApplicationResources } from '../items/application/fetch';

export function fulfillResourcesFetch(resources) {
  return dispatch => {
    dispatch({
      type: 'FETCH_INITIAL_RESOURCES_FULFILLED',
      payload: resources,
    });
    dispatch(hideModal());
  };
}

export function fetchInitalResources() {
  return dispatch => {
    dispatch({
      type: 'FETCH_INITIAL_RESOURCES_PENDING',
    });
    dispatch(showModal(MODAL_TYPES.INIT_APP));

    const localStorageData = localStorage.getItem('pdm-resources');
    const parsedResources = isValidJSON(localStorageData) ? JSON.parse(localStorageData) : null;

    if (parsedResources) {
      dispatch(fulfillResourcesFetch(parsedResources));
    }
    dispatch(fetchApplicationResources(true));
    axios.get(`${getNonGwApiRoot()}/resources`).then(res => {
      const resources = res.data;
      localStorage.setItem('pdm-resources', JSON.stringify(resources));
      dispatch(fulfillResourcesFetch(resources));
    });
  };
}
