import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { intercomEvent } from '../../utils/IntercomUtils';
import CustomVehicleTableRoutes from '../../routes/parentSettings/CustomVehicleTableRoutes';
import { setFileTypeId } from '../../actions/parent/exportImport/fetch';

const { Content, Sider } = Layout;

const CustomVehicleTablePage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const customVcdb = 14;
    intercomEvent('viewed-custom-vcdb');
    dispatch(setFileTypeId(customVcdb));
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu mode="inline" defaultSelectedKeys={['1']} selectedKeys={[location.pathname]}>
          <Menu.Item key="/settings/custom-vcdb/custom-vcdb-table">
            <Link to={{ pathname: `custom-vcdb-table` }}>{t('vcdb:vehicleSettings')}</Link>
          </Menu.Item>
          <Menu.Item key="/settings/custom-vcdb/import-export">
            <Link to={{ pathname: `import-export` }}>{t('sideBarLeft:import_export')}</Link>
          </Menu.Item>
          <Menu.Item key="/settings/custom-vcdb/import-logs">
            <Link to={{ pathname: `import-logs` }}>{t('importer:importReport')}</Link>
          </Menu.Item>
          <Menu.Item key="/settings/custom-vcdb/export-logs">
            <Link to={{ pathname: `export-logs` }}>{t('exporter:exportReport')}</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Content className="main-page-content">
        <CustomVehicleTableRoutes />
      </Content>
    </Layout>
  );
};

export default CustomVehicleTablePage;
