import React, { useCallback } from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Modal, Spin, Empty, Tooltip } from 'antd';
import { FilterApplicationParameter, FilterType } from '../../../../types/filter';
import TableSorter from '../../global/tableFilters/TableSorter';
import constants from '../../../constants/ApplicationTranslation.json';

const { confirm } = Modal;

type ShopifyFilterPreviewProps = {
  searchKeyword: string;
  fetching: boolean;
  filtersList: FilterType[];
  handleDeleteFilter: (filterId: number) => void;
  handleEditFilter: (filter: FilterType) => void;
};

const ShopifyFilterPreview: React.FC<ShopifyFilterPreviewProps> = props => {
  const { t } = useTranslation();

  const [sortBy, setSortBy] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState<string>('');

  const columnVariables = useCallback(() => ['makes', 'years', 'models'], []);

  const configParams = useCallback(
    (parameterValues: FilterApplicationParameter[]) =>
      parameterValues.filter(p => !columnVariables().includes(p.resource)),
    [columnVariables]
  );

  const getData = useCallback(() => {
    if (props.filtersList)
      return props.filtersList
        .filter(f => f.filter_application)
        .map(f => {
          const parameterValues = Object.values(
            Object.values(f.filter_application)[0]?.parameters || {}
          );
          const configParameters = parameterValues ? configParams(parameterValues) : [];

          return {
            make:
              Object.values(parameterValues.find(p => p.resource === 'makes')?.values || {})
                .map(make => make.resource_name)
                .join(', ') || '',

            model:
              Object.values(parameterValues.find(p => p.resource === 'models')?.values || {})
                .map(make => make.resource_name)
                .join(', ') || '',
            years:
              Object.values(parameterValues.find(p => p.resource === 'years')?.values || {})
                .map(make => Number(make.resource_name))
                .sort((a: number, b: number) => a - b)
                .map(year => year.toString())
                .join(', ') || '',
            configurations: configParameters
              .map(config => {
                const values = Object.values(config.values)
                  .map(c => c.resource_name)
                  .join(', '); // @ts-ignore
                const translationName = constants[config.resource] || config.resource;
                const configWithValues = translationName.concat(': ', values);
                return configWithValues;
              })
              .join(' | '),
            filterId: f.id,
          };
        });

    return [];
  }, [configParams, props.filtersList]);

  const sortList = useCallback(
    ({
      sortBy,
      sortDirection,
      filterKeywords = props.searchKeyword,
    }: {
      sortBy: string;
      sortDirection: string;
      filterKeywords?: string;
    }) => {
      const lowerKeywords = filterKeywords.toLowerCase();
      return getData()
        .filter(
          d =>
            d.make.toLowerCase().includes(lowerKeywords) ||
            d.model.toLowerCase().includes(lowerKeywords) ||
            d.years.toLowerCase().includes(lowerKeywords) ||
            d.configurations.toLowerCase().includes(lowerKeywords)
        )
        .sort((a, b) => {
          if (sortBy === 'make') {
            if (a.make < b.make)
              return sortDirection === 'asc' ? -1 : (sortDirection === 'desc' && 1) || 0;
            if (a.make > b.make)
              return sortDirection === 'asc' ? 1 : (sortDirection === 'desc' && -1) || 0;
            return 0;
          }
          if (sortBy === 'model') {
            if (a.model < b.model)
              return sortDirection === 'asc' ? -1 : (sortDirection === 'desc' && 1) || 0;
            if (a.model > b.model)
              return sortDirection === 'asc' ? 1 : (sortDirection === 'desc' && -1) || 0;
            return 0;
          }
          if (sortBy === 'years') {
            if (a.years < b.years)
              return sortDirection === 'asc' ? -1 : (sortDirection === 'desc' && 1) || 0;
            if (a.years > b.years)
              return sortDirection === 'asc' ? 1 : (sortDirection === 'desc' && -1) || 0;
            return 0;
          }
          if (sortBy === 'configurations') {
            if (a.configurations < b.configurations)
              return sortDirection === 'asc' ? -1 : (sortDirection === 'desc' && 1) || 0;
            if (a.configurations > b.configurations)
              return sortDirection === 'asc' ? 1 : (sortDirection === 'desc' && -1) || 0;
            return 0;
          }
          return 0;
        });
    },
    [getData, props.searchKeyword]
  );

  const [sortedList, setSortedList] = React.useState(sortList({ sortBy, sortDirection }));

  React.useEffect(() => {
    const sortedList = sortList({ sortBy, sortDirection });
    setSortedList(sortedList);
  }, [props.filtersList, props.searchKeyword, sortBy, sortDirection, sortList]);

  const columns = [
    { dataKey: 'make', id: 1, label: 'Make' },
    { dataKey: 'model', id: 2, label: 'Model' },
    { dataKey: 'years', id: 3, label: 'Year' },
    { dataKey: 'configurations', id: 4, label: 'Configurations' },
  ];

  const handleDeleteFilter = (filterId: number) => {
    confirm({
      title: t('channel:deleteVehicleFilter'),
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        props.handleDeleteFilter(filterId);
      },
    });
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <Table
            headerClassName="shopify-filter-table__header"
            rowClassName={({ index }: { index: number }) =>
              (index !== -1 && 'shopify-filter-table_row') || ''
            }
            className="shopify-filter-table"
            headerHeight={40}
            height={height - 80}
            width={width - 2}
            rowCount={sortedList.length}
            dataSource={sortedList}
            rowHeight={40}
            rowGetter={({ index }) => sortedList[index]}
            noRowsRenderer={() =>
              props.fetching ? (
                <Spin className="spinner-center" style={{ marginTop: '20px' }} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
          >
            {columns.map(col => {
              return (
                <Column
                  width={300}
                  flexGrow={1}
                  dataKey={col.dataKey}
                  label={col.label}
                  key={col.id}
                  headerRenderer={({ dataKey, label }) => (
                    <div className="flex items-center justify-between mr-2">
                      <div>{label}</div>
                      {col.label !== 'Configurations' && (
                        <TableSorter
                          selected={dataKey === sortBy}
                          sortOrder={sortDirection}
                          handleSort={order => {
                            setSortBy(col.dataKey);
                            const orderDirection =
                              sortDirection !== order || col.dataKey !== sortBy ? order : '';
                            setSortDirection(orderDirection);
                          }}
                        />
                      )}
                    </div>
                  )}
                  cellRenderer={({ rowData, dataKey }) =>
                    rowData[dataKey] ? (
                      <Tooltip title={rowData[dataKey]} placement="topLeft">
                        <div>{rowData[dataKey]}</div>
                      </Tooltip>
                    ) : (
                      <div />
                    )
                  }
                  disableSort={col.label === 'Configurations'}
                />
              );
            })}
            <Column
              disableSort
              headerClassName="shopify-filter-table-col__action"
              className="shopify-filter-table_action-buttons"
              width={100}
              dataKey="action"
              cellRenderer={({ rowData }) => (
                <React.Fragment>
                  <Button
                    type="text"
                    icon={<EditFilled />}
                    onClick={() => {
                      props.handleEditFilter(
                        props.filtersList.find(f => f.id === rowData.filterId)!
                      );
                    }}
                  />
                  <Button
                    type="text"
                    icon={<DeleteFilled />}
                    onClick={() => handleDeleteFilter(rowData.filterId)}
                  />
                </React.Fragment>
              )}
            />
          </Table>
        </div>
      )}
    </AutoSizer>
  );
};

export default ShopifyFilterPreview;
