import { InfoCircleOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AntTooltip from './AntTooltip';

type LabelSwitchProps = {
  displayToolTip?: boolean;
  infoText?: string;
  checked: boolean;
  onChange: () => void;
  className?: string;
};

const LabelSwitch: React.FC<LabelSwitchProps> = ({
  displayToolTip,
  infoText,
  checked,
  onChange,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`flex justify-end leading-10 ${className}`}>
      <span className="text-gray-800 pt-px pr-1">{t('common:showAllFields')}</span>
      {displayToolTip && (
        <AntTooltip title={infoText} className="self-center pr-2">
          <InfoCircleOutlined className="ant-info-tooltip" />
        </AntTooltip>
      )}
      <span>
        <Switch checked={checked} onChange={onChange} size="small" />
      </span>
    </div>
  );
};

export default LabelSwitch;
