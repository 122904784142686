import { Alert, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RankedValue } from '../../../../types/application';
import { createYearRanges, stringifyYearRanges } from '../../../utils/ApplicationUtils';

type ChangeConfigInfoProps = {
  ranks: { [key: string]: RankedValue[] };
  activeKey: string;
};

const ChangeConfigInfo: React.FC<ChangeConfigInfoProps> = ({ ranks, activeKey }) => {
  const { t } = useTranslation();

  const getVehicleConfig = () => {
    const selectedMakes = ranks.makes ? ranks.makes.filter(make => make.mapped) : [];
    const selectedModels = ranks.models ? ranks.models.filter(model => model.mapped) : [];
    const selectedSubmodels = ranks.sub_models
      ? ranks.sub_models.filter(subModel => subModel.mapped)
      : [];
    const selectedRegions = ranks.regions ? ranks.regions.filter(region => region.mapped) : [];
    const selectedYears = ranks.years ? ranks.years.filter(year => year.mapped).map(y => y.id) : [];

    // bring output in right format: [YearFrom] - [YearTo] [Make] [Model] [SubModel] ([Region])
    const makes = selectedMakes ? selectedMakes.map(make => make.name).join('/') : '';
    const models = selectedModels ? selectedModels.map(model => model.name).join('/') : '';
    const submodels = selectedSubmodels
      ? selectedSubmodels.map(submodel => submodel.name).join('/')
      : '';
    const regions =
      selectedRegions.length > 0 ? `(${selectedRegions.map(region => region.name).join('/')})` : '';
    const years = stringifyYearRanges(createYearRanges(selectedYears));
    return `${t('common:old')}: ${years} ${makes} ${models} ${submodels} ${regions}`;
  };

  const getEquipmentConfig = () => {
    const selectedMfrs = ranks.mfrs ? ranks.mfrs.filter(mfr => mfr.mapped) : [];
    const selectedModels = ranks.equipment_models
      ? ranks.equipment_models.filter(model => model.mapped)
      : [];
    const selectedVehicleTypes = ranks.vehicle_types
      ? ranks.vehicle_types.filter(type => type.mapped)
      : [];
    const selectedRegions = ranks.regions ? ranks.regions.filter(region => region.mapped) : [];
    const selectedYears = ranks.years ? ranks.years.filter(year => year.mapped).map(y => y.id) : [];

    // bring output in right format: [YearFrom] - [YearTo] [mfr] [Model] [type] ([Region])
    const mfrs = selectedMfrs ? selectedMfrs.map(mfr => mfr.name).join('/') : '';
    const models = selectedModels ? selectedModels.map(model => model.name).join('/') : '';
    const vehicleTypes = selectedVehicleTypes
      ? selectedVehicleTypes.map(type => type.name).join('/')
      : '';
    const regions =
      selectedRegions.length > 0 ? `(${selectedRegions.map(region => region.name).join('/')})` : '';
    const years = stringifyYearRanges(createYearRanges(selectedYears));

    return `${t('common:old')}: ${years} ${mfrs} ${models} ${vehicleTypes} ${regions}`;
  };

  return (
    <div className="application__change-vcdb-info-wrapper flex-1 overflow-hidden">
      <Tooltip title={activeKey === 'vehicle' ? getVehicleConfig() : getEquipmentConfig()}>
        <Alert
          className="application__change-vcdb-info"
          message={activeKey === 'vehicle' ? getVehicleConfig() : getEquipmentConfig()}
          type="info"
          showIcon
        />
      </Tooltip>
    </div>
  );
};

export default ChangeConfigInfo;
