import { SUB_CONFIG_MAPPING } from '../constants/ApplicationListConstants';
import { Analysis } from '../../types/analyses';
import { AnalysisType } from '../../types/resources';

export const getConfigName = (configIdString: string) => {
  const key = configIdString.replace('invalid_', '').replace('valid_', '');
  const configMapping = SUB_CONFIG_MAPPING as { [key: string]: string };

  return configMapping[key];
};

export const getConfigAnalyses = (analyses: (Analysis & AnalysisType)[], configName: string) => {
  let analysis;
  if (configName === 'category')
    analysis = analyses.find(
      a =>
        a.code === 'applications_invalid_category' ||
        a.code === 'applications_invalid_aces_category' ||
        a.code === 'applications_missing_category'
    );
  else if (configName === 'positions')
    analysis = analyses.find(a => a.code === 'applications_invalid_positions');
  else if (configName === 'qualifiers')
    analysis = analyses.find(a => a.code === 'applications_invalid_qualifier');
  else if (configName === 'makes' || configName === 'models' || configName === 'year')
    analysis = analyses.find(
      a =>
        a.code === 'applications_incomplete_vehicle_base' ||
        a.code === 'applications_invalid_vehicle_base'
    );
  else if (analyses.find(a => a.code === 'applications_invalid_vehicle_sub_configs')) {
    const vehicleAnalysis = analyses.find(
      a =>
        a.code === 'applications_invalid_vehicle_sub_configs' && a.details_json?.name === configName
    );
    if (vehicleAnalysis) analysis = vehicleAnalysis;
  }

  if (analysis) return analysis;

  // check for group analyses
  const engineBaseAnalysis =
    configName.includes('engine_base') &&
    analyses.find(a => a.code === 'applications_engine_config_changed_base');
  if (engineBaseAnalysis) return engineBaseAnalysis;

  // const invalidSubconfigAnalysis = analyses.find(
  //   a => a.code === 'applications_invalid_sub_configs'
  // );
  // if (invalidSubconfigAnalysis) return invalidSubconfigAnalysis;
};
