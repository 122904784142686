import { Row, Col, Card, Button } from 'antd';
import React from 'react';
import classNames from 'classnames';

import { NO_VALUE_OPTIONS } from '../../constants/FilterConstants';
import AntMultiSelect from '../global/AntMultiSelect';
import AntMultiSelectGroups from '../global/AntMultiSelectGroups';
import AntSelect from '../global/AntSelect';
import constants from '../../constants/FilterTranslation.json';
import headerConstants from '../../constants/HeaderTranslation.json';
import { FilterCustomParameterGo } from '../../../types/filter';
import { GroupedSubBrand, SubBrand } from '../../../types/brand';
import { CustomReference, PopulationStatus } from '../../../types/resources';

type FilterCustomItemProps = {
  populationParameter: FilterCustomParameterGo;
  universalParameter: FilterCustomParameterGo;
  subBrandParameter: FilterCustomParameterGo;
  subBrands: SubBrand[];
  groupedSubBrands?: GroupedSubBrand[];
  populationStatuses: PopulationStatus[];
  customReferences: CustomReference[];
  createParameter: (params: {
    customRefId: number;
    optionId: number | null;
    refId?: number;
    value?: string;
  }) => void;
  updateParameterOption: (params: {
    filterCustomReferenceId: number;
    referenceId: number | null;
    optionId: number;
  }) => void;
  addValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
  removeValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
  changeValue: (
    referenceId: number | null,
    filterCustomReferenceId: number,
    oldValue: any,
    value: any
  ) => void;
};

type FilterCustomItemState = {
  populationOption: number;
  subBrandOption: number;
  selectedPopulationStatuses: number[];
  selectedSubBrands: number[];
  universalPart?: number;
};

export default class FilterCustomItem extends React.Component<
  FilterCustomItemProps,
  FilterCustomItemState
> {
  constructor(props: FilterCustomItemProps) {
    super(props);
    this.state = {
      populationOption: 1,
      subBrandOption: 1,
      selectedPopulationStatuses: [],
      selectedSubBrands: [],
      universalPart: undefined,
    };
  }

  componentDidMount() {
    const { populationParameter, universalParameter, subBrandParameter } = this.props;
    if (populationParameter) {
      this.setState({
        selectedPopulationStatuses: this.getFilterParameterValues(populationParameter),
        populationOption: populationParameter.option_id,
      });
    }
    if (universalParameter) {
      this.setState({ universalPart: this.getFilterUniversalValue(universalParameter) });
    }
    if (subBrandParameter) {
      this.setState({
        selectedSubBrands: this.getFilterParameterValues(subBrandParameter),
        subBrandOption: subBrandParameter.option_id,
      });
    }
  }

  componentDidUpdate(prevProps: FilterCustomItemProps) {
    const { populationParameter, universalParameter, subBrandParameter } = this.props;
    if (!populationParameter && populationParameter !== prevProps.populationParameter) {
      this.setState({ selectedPopulationStatuses: [], populationOption: 1 });
    }
    if (populationParameter && populationParameter !== prevProps.populationParameter) {
      this.setState({
        selectedPopulationStatuses: this.getFilterParameterValues(populationParameter),
        populationOption: populationParameter.option_id,
      });
    }
    if (!subBrandParameter && subBrandParameter !== prevProps.subBrandParameter) {
      this.setState({ selectedSubBrands: [], subBrandOption: 1 });
    }
    if (subBrandParameter && subBrandParameter !== prevProps.subBrandParameter) {
      this.setState({
        selectedSubBrands: this.getFilterParameterValues(subBrandParameter),
        subBrandOption: subBrandParameter.option_id,
      });
    }
    if (!universalParameter && universalParameter !== prevProps.universalParameter) {
      this.setState({ universalPart: undefined });
    }
  }

  getUniversalValue = () => {
    const { universalParameter } = this.props;
    return universalParameter?.values[0];
  };

  getFilterParameterValues = (filterParameter: FilterCustomParameterGo) =>
    filterParameter.values.map(value => Number(value.value));

  getFilterUniversalValue = (filterParameter: FilterCustomParameterGo) =>
    filterParameter.values.map(value => Number(value.value))[0];

  createParameter = (customRefId: number, optionId: number, value?: number) => {
    this.props.createParameter({ customRefId, optionId, value: value?.toString() });
  };

  changePopulationOption = (selectedOptionId: number) => {
    const { populationParameter, customReferences } = this.props;
    if (populationParameter) {
      this.props.updateParameterOption({
        referenceId: populationParameter.reference_id,
        filterCustomReferenceId: populationParameter.filter_custom_reference_id,
        optionId: selectedOptionId,
      });
    }
    if (!populationParameter && NO_VALUE_OPTIONS.includes(selectedOptionId)) {
      const ref = customReferences.find(ref => ref.resource_table === 'population_statuses');
      this.createParameter(ref!.id, selectedOptionId);
    }

    this.setState({ populationOption: selectedOptionId });
  };

  changeSubBrandOption = (selectedOptionId: number) => {
    const { subBrandParameter, customReferences } = this.props;
    if (subBrandParameter) {
      this.props.updateParameterOption({
        referenceId: subBrandParameter.reference_id,
        filterCustomReferenceId: subBrandParameter.filter_custom_reference_id,
        optionId: selectedOptionId,
      });
    }
    if (!subBrandParameter && NO_VALUE_OPTIONS.includes(selectedOptionId)) {
      const ref = customReferences.find(ref => ref.id === 10);
      this.createParameter(ref!.id, selectedOptionId);
    }

    this.setState({ subBrandOption: selectedOptionId });
  };

  handlePopulationChange = (values: any[]) => {
    const { customReferences, populationParameter } = this.props;
    const { selectedPopulationStatuses } = this.state;
    const ref = customReferences.find(ref => ref.resource_table === 'population_statuses');
    if (values.length < selectedPopulationStatuses.length) return;
    const newId = values.filter(id => this.state.selectedPopulationStatuses.indexOf(id) === -1)[0];

    if (!populationParameter) this.createParameter(ref!.id, this.state.populationOption, newId);
    else
      this.props.addValue(
        populationParameter.reference_id,
        populationParameter.filter_custom_reference_id,
        newId
      );
    this.setState({ selectedPopulationStatuses: [...selectedPopulationStatuses, newId] });
  };

  handleUniversalChange = (value: number) => {
    const { universalParameter } = this.props;
    const { universalPart } = this.state;
    const customReferenceId = 1;

    if (!universalParameter) this.createParameter(customReferenceId, 1, value);
    else if (value === universalPart) {
      this.props.removeValue(
        universalParameter.reference_id,
        universalParameter.filter_custom_reference_id,
        value.toString()
      );
    } else {
      this.props.changeValue(
        universalParameter.reference_id,
        universalParameter.filter_custom_reference_id,
        this.getUniversalValue().value,
        value
      );
    }
    this.setState({ universalPart: value !== universalPart ? value : undefined });
  };

  handleSubbrandChange = (values: any[]) => {
    const { customReferences, subBrandParameter } = this.props;
    const { selectedSubBrands } = this.state;
    const ref = customReferences.find(ref => ref.id === 10);
    if (values.length < selectedSubBrands.length) return;
    const newId = values.filter(id => selectedSubBrands.indexOf(id) === -1)[0];

    if (!subBrandParameter) this.createParameter(ref!.id, this.state.populationOption, newId);
    else
      this.props.addValue(
        subBrandParameter.reference_id,
        subBrandParameter.filter_custom_reference_id,
        newId
      );
    this.setState({ selectedSubBrands: [...selectedSubBrands, newId] });
  };

  removePopulationValue = (value: number) => {
    const { populationParameter } = this.props;
    this.props.removeValue(
      populationParameter.reference_id,
      populationParameter.filter_custom_reference_id,
      value.toString()
    );
    this.setState(prevState => ({
      selectedPopulationStatuses: prevState.selectedPopulationStatuses.filter(id => id !== value),
    }));
  };

  removeSubBrandValue = (value: number) => {
    const { subBrandParameter } = this.props;
    this.props.removeValue(
      subBrandParameter.reference_id,
      subBrandParameter.filter_custom_reference_id,
      value.toString()
    );
    this.setState(prevState => ({
      selectedSubBrands: prevState.selectedSubBrands.filter(id => id !== value),
    }));
  };

  parameterOption = (customReferenceId: number) => {
    const { populationOption, subBrandOption } = this.state;

    // @ts-ignore
    const options = [1, 2, 3, 4, 5].map(id => ({ id, name: headerConstants.filterDropdown[id] }));
    const selectedElement = customReferenceId === 4 ? populationOption : subBrandOption;

    return (
      <Col className="filter-custom-label" span={6}>
        <AntSelect
          className="filter-custom-dropdown"
          popupClassName="filter-custom-dropdown_small"
          elements={options}
          onChange={
            customReferenceId === 4 ? this.changePopulationOption : this.changeSubBrandOption
          }
          value={selectedElement}
        />
      </Col>
    );
  };

  render() {
    const { groupedSubBrands } = this.props;
    const { populationOption, subBrandOption } = this.state;

    return (
      <React.Fragment>
        <div className="title">{constants.filter_item.custom_item}</div>
        <div className="filter__custom-item box box_background">
          <Card className="filter__custom-card" bordered={false}>
            <Row align="middle" gutter={10}>
              <Col className="filter-custom-label" span={6}>
                <Card className="filter-custom-label_card">
                  {constants.filter_item.population_status}
                </Card>
              </Col>
              {this.parameterOption(4)}
              <Col span={12}>
                <AntMultiSelect
                  className="filter-custom-dropdown"
                  disabled={NO_VALUE_OPTIONS.includes(populationOption)} // any value, no value
                  elements={this.props.populationStatuses}
                  onChange={this.handlePopulationChange}
                  onDeselect={this.removePopulationValue}
                  selectedElements={this.state.selectedPopulationStatuses}
                />
              </Col>
            </Row>
          </Card>
          <Card className="filter__custom-card" bordered={false}>
            <Row gutter={10}>
              <Col className="filter-custom-label" span={6}>
                <Card className="filter-custom-label_card">
                  {constants.filter_item.universal_part}
                </Card>
              </Col>
              <Col span={6}>
                <Button.Group>
                  <Button
                    className={classNames({ selected: this.state.universalPart })}
                    onClick={() => this.handleUniversalChange(1)}
                  >
                    {constants.true}
                  </Button>
                  <Button
                    className={classNames({ selected: this.state.universalPart === 0 })}
                    onClick={() => this.handleUniversalChange(0)}
                  >
                    {constants.false}
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </Card>
          <Card className="filter__custom-card" bordered={false}>
            <Row align="middle" gutter={10}>
              <Col className="filter-custom-label" span={6}>
                <Card className="filter-custom-label_card">{constants.filter_item.sub_brand}</Card>
              </Col>
              {this.parameterOption(10)}
              <Col span={12}>
                {groupedSubBrands ? (
                  <AntMultiSelectGroups
                    className="filter-custom-dropdown"
                    disabled={NO_VALUE_OPTIONS.includes(subBrandOption)} // any value, no value
                    elements={groupedSubBrands.map(brand => ({
                      ...brand,
                      options: brand.sub_brands,
                    }))}
                    onChange={this.handleSubbrandChange}
                    onDeselect={this.removeSubBrandValue}
                    selectedElements={this.state.selectedSubBrands}
                  />
                ) : (
                  <AntMultiSelect
                    className="filter-custom-dropdown"
                    disabled={NO_VALUE_OPTIONS.includes(subBrandOption)} // any value, no value
                    elements={this.props.subBrands}
                    onChange={this.handleSubbrandChange}
                    onDeselect={this.removeSubBrandValue}
                    selectedElements={this.state.selectedSubBrands}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
