import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Tooltip, Switch, Button, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import { ApplicationState } from '../../../reducers';

const GapCoverageReportStep4: React.FC = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  // as these are already exists in step 3 we dont have to display
  const hideSubConfigTypeIds = [1, 2, 3, 5, 6];

  const { fetchingPositions, positions, vehicleSubConfigTypes, regions, fetchingUsedSubConfigIds } =
    useSelector((state: ApplicationState) => {
      return {
        positions: state.resources.applicationVehicle.positions,
        vehicleSubConfigTypes: state.resources.data.global.vehicle_sub_config_types.filter(
          config => !hideSubConfigTypeIds.includes(config.id)
        ),
        regions: state.resources.applicationVehicle.regions,
        fetchingUsedSubConfigIds: state.exporter.fetchingUsedSubConfigIds,
        fetchingPositions: state.exporter.fetchingPositions,
      };
    });

  const allRegionIds = regions.map((r: any) => r.id);

  const handleChange = (config: string, checked?: boolean) => {
    switch (config) {
      case 'position': {
        setFieldValue('positionIds', []);
        break;
      }
      case 'subConfigTypeIds': {
        setFieldValue('subConfigTypeIds', []);
        break;
      }
      case 'regions': {
        if (checked) setFieldValue('regions', allRegionIds);
        if (!checked) setFieldValue('regions', [1, 2, 3]);
        break;
      }
    }
  };

  const getChecked = (config: string) => {
    switch (config) {
      case 'regions': {
        if (values.regions.length === regions.length) return true;
        return false;
      }
    }
  };

  const renderSwitch = (configName: string) => {
    return (
      <div className="flex justify-end items-center" key={configName}>
        <div className="mr-2">{t('common:selectAll')}</div>
        <Switch
          size="small"
          checked={getChecked(configName)}
          onChange={checked => handleChange(configName, checked)}
        />
      </div>
    );
  };

  if (fetchingUsedSubConfigIds || fetchingPositions)
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <div className="flex flex-col h-full">
      <div className="mt-2 mb-6 text-gray-700">{t('gapCoverageReport:step4Description')}</div>
      <div className="flex flex-row justify-between pb-2">
        <div className="space-x-2">
          <span> {t('gapCoverageReport:selectPosition')} </span>
          <Tooltip
            title={
              <div className="space-y-2">
                <div> {t('gapCoverageReport:selectPositionInfo1')}</div>
                <div> {t('gapCoverageReport:selectPositionInfo2')}</div>
              </div>
            }
          >
            <InfoCircleOutlined className="ant-info-tooltip" />
          </Tooltip>
        </div>
        <Button
          size="small"
          disabled={values.positionIds.length === 0}
          onClick={() => handleChange('position')}
        >
          {t('common:clearSelection')}
        </Button>
      </div>
      <FormMultiSelect
        name="positionIds"
        values={positions || []}
        size="small"
        className="export-format-options__multi-select"
        allowClear
        addFilterSort
      />
      <div className="flex flex-row justify-between pb-2">
        <div className="space-x-2">
          <span> {t('gapCoverageReport:selectSubConfig')} </span>
          <Tooltip
            title={
              <div className="space-y-2">
                <div> {t('gapCoverageReport:selectSubConfigInfo1')}</div>
                <div> {t('gapCoverageReport:selectSubConfigInfo2')}</div>
              </div>
            }
          >
            <InfoCircleOutlined className="ant-info-tooltip" />
          </Tooltip>
        </div>
        <Button
          size="small"
          disabled={values.subConfigTypeIds.length === 0}
          onClick={() => handleChange('subConfigTypeIds')}
        >
          {t('common:clearSelection')}
        </Button>
      </div>
      <FormMultiSelect
        name="subConfigTypeIds"
        values={vehicleSubConfigTypes || []}
        size="small"
        className="export-format-options__multi-select"
        allowClear
        handleChange={values =>
          setFieldValue('subConfigTypeIds', values.length > 20 ? values.slice(0, 20) : values)
        }
      />
      <div className="flex flex-row justify-between pb-2">
        <div className="space-x-2">
          <span> {t('gapCoverageReport:selectRegions')} </span>
          <Tooltip title={t('gapCoverageReport:selectRegionsInfo')}>
            <InfoCircleOutlined className="ant-info-tooltip" />
          </Tooltip>
        </div>
        {renderSwitch('regions')}
      </div>
      <FormMultiSelect
        name="regions"
        values={regions || []}
        size="small"
        className="export-format-options__multi-select"
        allowClear
      />
    </div>
  );
};

export default GapCoverageReportStep4;
