import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import ModalInfo from './ModalInfo';
import translationConstants from '../../../constants/ModalTranslation.json';

class BackendDownModal extends React.Component {
  refreshPage = () => {
    window.location.reload(true);
  };

  render() {
    return (
      <ModalInfo
        type="warning"
        message={translationConstants.backend_down.message}
        interaction={this.refreshPage}
        interactionText={translationConstants.backend_down.interaction}
        interactionIcon={<ReloadOutlined className="mr-1" />}
      />
    );
  }
}

export default BackendDownModal;
