import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { intercomEvent } from '../../utils/IntercomUtils';
import SettingsLanguageRoutes from '../../routes/parentSettings/SettingsLanguageRoutes';
import { setFileTypeId } from '../../actions/parent/exportImport/fetch';

const { Content, Sider } = Layout;

const TranslationSettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const translationId = 13;
    intercomEvent('viewed-translation-settings');
    dispatch(setFileTypeId(translationId));
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu mode="inline" defaultSelectedKeys={['1']} selectedKeys={[location.pathname]}>
          <Menu.Item key="/settings/translations/language-settings">
            <Link to={{ pathname: `language-settings` }}>{t('language:languageSettings')}</Link>
          </Menu.Item>
          <Menu.Item key="/settings/translations/translation-settings">
            <Link to={{ pathname: `translation-settings` }}>{t('language:translations')}</Link>
          </Menu.Item>
          {/* <Menu.Item key="/settings/translations/import-export">
            <Link to={{ pathname: `import-export` }}>
              {t('sideBarLeft:import_export')}
            </Link>
          </Menu.Item> */}
          <Menu.Item key="/settings/translations/import-logs">
            <Link to={{ pathname: `import-logs` }}>{t('importer:importReport')}</Link>
          </Menu.Item>
          <Menu.Item key="/settings/translations/export-logs">
            <Link to={{ pathname: `export-logs` }}>{t('exporter:exportReport')}</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Content className="main-page-content">
        <SettingsLanguageRoutes />
      </Content>
    </Layout>
  );
};

export default TranslationSettingsPage;
