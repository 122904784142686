import React from 'react';
import classNames from 'classnames';
import { Row, Col, Card, Input, Button, Tooltip } from 'antd';
import AddNewButton from '../global/AddNewButton';
import AntSelect from '../global/AntSelect';
import AntSelectGroups from '../global/AntSelectGroups';
import AntMultiSelect from '../global/AntMultiSelect';
import AntTags from '../global/AntTags';
import constants from '../../constants/FilterTranslation.json';
import { COUNTRY_CODES } from '../../constants/ExtendedInfoConstants';
import { NO_VALUE_OPTIONS } from '../../constants/FilterConstants';
import headerConstants from '../../constants/HeaderTranslation.json';
import { AVAILABLE_BRAND_AREA } from '../../constants/InAppAreaConst';
import { ExtendedResources, Group, GroupType } from '../../../types/resources';
import { TypeOptions } from '../../../types/extendedInfo';
import { FilterCustomParameterGo } from '../../../types/filter';

type FilterExtendedInfoProps = {
  inAppArea: string;
  groups: Group[];
  allExpi: GroupType[];
  countryOptions: TypeOptions[];
  countries: ExtendedResources[];
  parameters: FilterCustomParameterGo[];
  createParameter: (params: {
    customRefId: number;
    optionId: number | null;
    refId?: number;
    value?: string;
  }) => void;
  updateParameterOption: (params: {
    filterCustomReferenceId: number;
    referenceId: number | null;
    optionId: number;
  }) => void;
  addValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
  removeValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
  changeValue: (
    referenceId: number | null,
    filterCustomReferenceId: number,
    oldValue: any,
    value: any
  ) => void;
};

type FilterExtendedInfoState = {
  showAddFilterParameter: boolean;
  selectedExpi?: number;
  selectedOption: number;
  parameterValue?: any;
};

export default class FilterExtendedInfo extends React.Component<
  FilterExtendedInfoProps,
  FilterExtendedInfoState
> {
  labels: { [key: number]: any } = {};

  constructor(props: FilterExtendedInfoProps) {
    super(props);
    this.state = {
      showAddFilterParameter: false,
      selectedExpi: undefined,
      selectedOption: 1,
      parameterValue: undefined,
    };
  }

  createParameter = (value?: string | number) => {
    const { allExpi, countries } = this.props;
    const { selectedExpi, selectedOption } = this.state;
    const customRefId = 3;
    const expi = allExpi.find(expi => expi.id === selectedExpi);
    let valueName = value;
    if (expi?.format === 'ARRAY' && value) {
      const countrySelection = COUNTRY_CODES.includes(expi.code);
      if (countrySelection) valueName = countries.find(opt => opt.id === value)?.code;
      else valueName = expi.options.find(opt => opt.id === value)?.code;
    }

    this.props.createParameter({
      customRefId,
      refId: selectedExpi,
      optionId: selectedOption,
      value: valueName?.toString(),
    });

    // reset state
    this.setState({
      showAddFilterParameter: false,
      selectedExpi: undefined,
      selectedOption: 1,
      parameterValue: undefined,
    });
  };

  handleCreateValueSelection = (selectedValue?: string | number) => {
    this.setState({ parameterValue: selectedValue });
    this.createParameter(selectedValue);
  };

  handleCheckboxChange = (
    referenceId: number | null,
    filterCustomReferenceId: number,
    oldValue: string,
    newValue: string
  ) => {
    if (oldValue === newValue)
      this.props.removeValue(referenceId, filterCustomReferenceId, oldValue);
    else this.props.changeValue(referenceId, filterCustomReferenceId, oldValue, newValue);
  };

  getCountryOptions = (id: number) => {
    // Country Options for brands will be fetched seperatly. Only shows set Countries
    // For Receiver we show all countries
    if (this.props.inAppArea === AVAILABLE_BRAND_AREA) return this.props.countries;
    const countries = this.props.countryOptions.find(option => option.id === id);
    return countries ? countries.options : [];
  };

  getOptions = (options: any[]) =>
    options.map(o => (o.code !== o.name ? { ...o, name: `${o.code} - ${o.name}` } : o));

  parameterValue = (expiId: number) => {
    const { allExpi } = this.props;
    const { parameterValue, selectedOption } = this.state;
    const expi = allExpi.find(el => el.id === expiId);
    const countrySelection = COUNTRY_CODES.includes(expi?.code || '');

    return (
      <Col span={10}>
        {expi?.format === 'TEXT' && (
          <Input
            value={this.state.parameterValue}
            // @ts-ignore
            onPressEnter={e => this.handleCreateValueSelection(e.target.value)}
            onBlur={e => this.handleCreateValueSelection(e.target.value)}
            onChange={e => this.setState({ parameterValue: e.target.value })}
            disabled={NO_VALUE_OPTIONS.includes(selectedOption)} // any value, no value
          />
        )}
        {expi?.format === 'ARRAY' && (
          <AntSelect
            className="filter-custom-dropdown"
            elements={
              countrySelection ? this.getCountryOptions(expi.id) : this.getOptions(expi.options)
            }
            onChange={selectedValue => this.handleCreateValueSelection(selectedValue)}
            value={parameterValue}
            placeholder={constants.dropdown_placeholder}
            showSearch
            disabled={NO_VALUE_OPTIONS.includes(selectedOption)} // any value, no value
          />
        )}
        {expi?.format === 'BOOL' && (
          <Button.Group>
            <Button
              className={classNames({ selected: this.state.parameterValue === 'Y' })}
              onClick={() => this.handleCreateValueSelection('Y')}
            >
              {constants.yes}
            </Button>
            <Button
              className={classNames({ selected: this.state.parameterValue === 'N' })}
              onClick={() => this.handleCreateValueSelection('N')}
            >
              {constants.no}
            </Button>
          </Button.Group>
        )}
      </Col>
    );
  };

  parameterOption = (parameter?: FilterCustomParameterGo) => {
    // @ts-ignore
    const options = [1, 2, 3, 4, 5].map(id => ({ id, name: headerConstants.filterDropdown[id] }));
    const onChange = parameter
      ? (selectedValue: any) =>
          this.props.updateParameterOption({
            referenceId: parameter.reference_id,
            filterCustomReferenceId: parameter.filter_custom_reference_id,
            optionId: selectedValue,
          })
      : (selectedValue: number) =>
          this.setState({ selectedOption: selectedValue }, () => {
            if (NO_VALUE_OPTIONS.includes(selectedValue)) this.createParameter();
          });
    const selectedElement = parameter ? parameter.option_id : this.state.selectedOption;

    return (
      <Col className="filter-custom-label" span={6}>
        <AntSelect
          className="filter-custom-dropdown"
          popupClassName="filter-custom-dropdown_small"
          elements={options}
          onChange={onChange}
          value={selectedElement}
        />
      </Col>
    );
  };

  showCreateParameter = () => {
    const { allExpi, parameters, groups } = this.props;
    const { selectedExpi } = this.state;
    const expi = allExpi.find(el => el.id === selectedExpi);
    // filter used parameters
    const paramReferenceIds = parameters.map(param => param.reference_id);
    const groupedUnusedElements = groups.map(group => ({
      ...group,
      types: group.types.filter(expi => !paramReferenceIds.includes(expi.id)),
    }));

    return (
      <Card className="filter__create-custom-card">
        <Row align="middle" gutter={10}>
          <Col className="filter-custom-label" span={8}>
            <AntSelectGroups
              className="filter-custom-dropdown"
              elements={groupedUnusedElements}
              onChange={(selectedValue: any) => this.setState({ selectedExpi: selectedValue })}
              selectedElement={selectedExpi}
              placeholder="Select extended Info"
              showSearch
            />
          </Col>
          {selectedExpi && expi?.format !== 'BOOL' && this.parameterOption()}
          {selectedExpi && this.parameterValue(selectedExpi)}
        </Row>
      </Card>
    );
  };

  showCreateButton = () => (
    <Row>
      <AddNewButton
        size="sub"
        name={constants.add_new}
        toggle={() =>
          this.setState(prevState => ({
            showAddFilterParameter: !prevState.showAddFilterParameter,
          }))
        }
      />
    </Row>
  );

  parameterLabel = (expi: GroupType) => {
    const longTitle =
      this.labels[expi.id] &&
      this.labels[expi.id].offsetWidth > this.labels[expi.id].offsetParent.offsetWidth - 10;

    return (
      <Card className="filter-custom-label_card">
        <Tooltip title={longTitle ? expi.name : ''}>
          <span ref={el => (this.labels[expi.id] = el)}>{expi.name}</span>
        </Tooltip>
      </Card>
    );
  };

  singleParameter = (parameter: FilterCustomParameterGo) => {
    const { allExpi, countries } = this.props;
    const expi = allExpi.find(expi => expi.id === parameter.reference_id);
    const values = parameter.values.map(value => {
      let option;
      if (expi?.format === 'ARRAY' && COUNTRY_CODES.includes(expi.code))
        option = countries.find(opt => opt.code === value.value);
      else if (expi?.format === 'ARRAY')
        option = expi.options.find(opt => opt.code === value.value);
      return { ...value, optionId: option?.id };
    });
    const selectedOptionIds = values.map(value => value.optionId);

    const handleDropdownChange = (selectedValues: any[]) => {
      if (selectedValues.length < values.length) return;
      const newId = selectedValues.filter(id => selectedOptionIds.indexOf(id) === -1)[0];
      const value = COUNTRY_CODES.includes(expi?.code || '')
        ? countries.find(opt => opt.id === newId)?.code
        : expi?.options.find(opt => opt.id === newId)?.code;
      this.props.addValue(parameter.reference_id, parameter.filter_custom_reference_id, value);
    };

    const handleDropdownRemove = (selectedOptionId: number) => {
      const value = values.find(value => value.optionId === selectedOptionId)?.value;
      this.props.removeValue(parameter.reference_id, parameter.filter_custom_reference_id, value);
    };

    return (
      <Card key={parameter.reference_id} className="filter__custom-card" bordered={false}>
        <Row align="middle" gutter={10}>
          <Col className="filter-custom-label" span={7}>
            {this.parameterLabel(expi!)}
          </Col>
          {expi?.format !== 'BOOL' && this.parameterOption(parameter)}
          <Col span={11}>
            {expi?.format === 'TEXT' && (
              <AntTags
                closable
                elements={values}
                onClose={(value: any) =>
                  this.props.removeValue(
                    parameter.reference_id,
                    parameter.filter_custom_reference_id,
                    value
                  )
                }
                create={(value: any) =>
                  this.props.addValue(
                    parameter.reference_id,
                    parameter.filter_custom_reference_id,
                    value
                  )
                }
                addButtonClassName="filter__custom-add-tag"
                disabled={NO_VALUE_OPTIONS.includes(parameter.option_id)} // any value, no value
              />
            )}
            {expi?.format === 'ARRAY' && (
              <AntMultiSelect
                className="filter-custom-dropdown"
                elements={
                  COUNTRY_CODES.includes(expi.code)
                    ? this.getCountryOptions(expi.id)
                    : this.getOptions(expi.options)
                }
                onChange={handleDropdownChange}
                onDeselect={handleDropdownRemove}
                selectedElements={selectedOptionIds}
                showSearch
                disabled={NO_VALUE_OPTIONS.includes(parameter.option_id)} // any value, no value
              />
            )}
            {expi?.format === 'BOOL' && (
              <Button.Group>
                <Button
                  className={classNames({ selected: values[0].value === 'Y' })}
                  onClick={() =>
                    this.handleCheckboxChange(
                      parameter.reference_id,
                      parameter.filter_custom_reference_id,
                      values[0].value,
                      'Y'
                    )
                  }
                >
                  {constants.yes}
                </Button>
                <Button
                  className={classNames({ selected: values[0].value === 'N' })}
                  onClick={() =>
                    this.handleCheckboxChange(
                      parameter.reference_id,
                      parameter.filter_custom_reference_id,
                      values[0].value,
                      'N'
                    )
                  }
                >
                  {constants.no}
                </Button>
              </Button.Group>
            )}
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { parameters } = this.props;
    const { showAddFilterParameter } = this.state;
    return (
      <React.Fragment>
        <div className="title">{constants.filter_extended.extended_info}</div>
        <div className="filter__custom-fields box box_background">
          {!showAddFilterParameter && this.showCreateButton()}
          {showAddFilterParameter && this.showCreateParameter()}
          {parameters.map(parameter => this.singleParameter(parameter))}
        </div>
      </React.Fragment>
    );
  }
}
