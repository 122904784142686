import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type SaveContinueModalProps = {
  saving?: boolean;
  showModal: boolean;
  onCancel: () => void;
  saveAndSelectNext: () => void;
  selectNext: () => void;
};

const SaveContinueModal: React.FC<SaveContinueModalProps> = props => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.showModal}
      onCancel={() => props.onCancel()}
      footer={[
        <Button key="cancel" onClick={() => props.onCancel()}>
          {t('common:cancel')}
        </Button>,
        <Button key="next" type="primary" onClick={() => props.selectNext()} danger ghost>
          {t('application:discardAndSelectNext')}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={props.saving}
          onClick={() => props.saveAndSelectNext()}
        >
          {t('application:saveAndSelectNext')}
        </Button>,
      ]}
      closable={false}
    >
      <div className="text-base font-medium	">{t('application:unsavedChanges')}</div>
    </Modal>
  );
};

export default SaveContinueModal;
