import { message } from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';
import { Dispatch } from 'redux';
import { ImportTypes } from '../../../../types/import_export';
import { EXCEL_FILE_EXTENSION } from '../../../constants/ImportExportConstants';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { getFileExtension } from '../../../utils/ImportExportUtils';
import { createURL, generateUUID } from '../../../utils/Utils';
import { fetchPresignedUrl } from '../../importer';
import {
  createNewUpload,
  updateUploadProgress,
  uploadFailed,
  uploadFinished,
} from '../../parent/language/update';

export function importExcelFile(file: any, importOptions: { [key: string]: number[] }) {
  return (disptach: any) => {
    const extension = getFileExtension(file.name);
    if (extension !== EXCEL_FILE_EXTENSION) {
      message.error('invalid file');
    } else {
      disptach(createExcelImport(file, importOptions));
    }
  };
}

export function createExcelImport(file: any, importOptions: { [key: string]: number[] }) {
  return async (dispatch: Dispatch) => {
    const uuid = generateUUID();
    dispatch(createNewUpload(uuid, file.name, ImportTypes.EXCEL));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    const paramsArray: { name: string; values: number | string | undefined }[] = [
      { name: 'file_name', values: filename },
    ];

    try {
      const params = createURL(paramsArray);
      const response = await axios.post(`${getApiRoot()}/imports/excel_files${params}`, {
        import_options: importOptions,
      });
      const logId = response.data.import_log_id;
      dispatch(uploadFinished(uuid, logId));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}
