import { combineReducers } from 'redux';
import apiSettings, { APISettingsState } from './APISettingsReducer';

export type DistributionState = {
  apiSettings: APISettingsState;
};

const distributionReducer = combineReducers<DistributionState>({
  apiSettings,
});

export default distributionReducer;
