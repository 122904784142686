import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import translationConstants from '../../constants/ImportExportTranslation.json';
import { ExtendedResources } from '../../../types/resources';

type ExportAcesProps = {
  acesVersions: ExtendedResources[];
  onChange: (obj: any) => void;
  defaultVersion: number;
  defaultBaseVehicle: string;
};

function ExportAces(props: ExportAcesProps) {
  const [acesExportState, setAcesExportState] = React.useState({
    acesVersion: props.defaultVersion || props.acesVersions[0],
    baseVehicle: props.defaultBaseVehicle || '0',
  });

  return (
    <React.Fragment>
      <div className="export-aces__version">
        <div className="export-aces__label">{translationConstants.version}</div>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setAcesExportState(values => {
              const newState = {
                ...values,
                acesVersion: e.target.value,
              };
              props.onChange(newState);
              return newState;
            });
          }}
          value={acesExportState.acesVersion}
        >
          {props.acesVersions.map(version => (
            <Radio key={version.id} value={version.id}>
              {version.code}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div className="export-aces__grouping">
        <div className="export-aces__label">{translationConstants.export.application_grouping}</div>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setAcesExportState(values => {
              const newState = {
                ...values,
                baseVehicle: e.target.value,
              };
              props.onChange(newState);
              return newState;
            });
          }}
          value={acesExportState.baseVehicle}
        >
          <Radio value="0">{translationConstants.export.grouped_by_range}</Radio>
          <Radio value="1">{translationConstants.export.individual_by_base}</Radio>
        </Radio.Group>
      </div>
    </React.Fragment>
  );
}

export default ExportAces;
