import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Select } from 'antd';
import { AvailableChannel } from '../../../../types/channel';
import { AvailableReceiver } from '../../../../types/receiver';
import { ApplicationState } from '../../../reducers';
import { isManufacturer } from '../../../utils/UserUtils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import useCatalogueQueryParams from '../../../hooks/useCatalogueQuery';

const { Option, OptGroup } = Select;

function CatalogueChannelSelect() {
  const { updateChannelsReceivers } = useCatalogueQueryParams();
  const { availableChannels, availableReceivers, selectedChannelId, selectedReceiverId } =
    useSelector((state: ApplicationState) => {
      const userIsManufacturer = state.user && state.user.user && isManufacturer(state.user.user);
      return {
        availableChannels: userIsManufacturer
          ? state.brand.currentBrand.availableChannels
          : state.receiverDataStream.availableChannels,
        availableReceivers: userIsManufacturer
          ? state.brand.currentBrand.availableReceivers
          : state.receiverDataStream.availableReceivers,
        selectedChannelId: state.catalogue.catalogue.catalogueQueryParams.selectedChannelId,
        selectedReceiverId: state.catalogue.catalogue.catalogueQueryParams.selectedReceiverId,
      };
    }, shallowEqual);

  const sendEvents = (selectedChannelId: null | number, selectedReceiverId: null | number) => {
    intercomEvent('changed-catalog-view', {
      location: 'channel/receiver',
      filter_by: selectedChannelId
        ? availableChannels.find(c => c.id === selectedChannelId)?.name || ''
        : availableReceivers.find(r => r.id === selectedReceiverId)?.name || '',
    });
  };

  const handleClick = (val: string) => {
    let filterParams: {
      selectedChannelId: null | number;
      selectedReceiverId: null | number;
    } = {
      selectedChannelId: null,
      selectedReceiverId: null,
    };

    if (val) {
      const [type, id] = val.split(':');
      const intId = parseInt(id, 10);
      filterParams = {
        ...filterParams,
        selectedChannelId: type === 'channel' ? intId : null,
        selectedReceiverId: type === 'receiver' ? intId : null,
      };
    }
    updateChannelsReceivers({
      ...filterParams,
    });

    sendEvents(filterParams.selectedChannelId, filterParams.selectedReceiverId);
  };

  const getChannelValue = (id: number) => `channel:${id}`;

  const getReceiverValue = (id: number) => `receiver:${id}`;

  const getValue = () => {
    if (selectedChannelId) {
      return getChannelValue(selectedChannelId);
    }
    if (selectedReceiverId) {
      return getReceiverValue(selectedReceiverId);
    }
    return undefined;
  };

  return (
    <Select
      size="small"
      placeholder="Channels/Receivers"
      onChange={(val: string) => handleClick(val)}
      value={getValue()}
      allowClear
    >
      <OptGroup label="Channels">
        {availableChannels.map(({ id, name }: AvailableChannel) => (
          <Option key={id} value={getChannelValue(id)}>
            {name}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label="Receivers">
        {availableReceivers.map(({ id, name }: AvailableReceiver) => (
          <Option key={id} value={getReceiverValue(id)}>
            {name}
          </Option>
        ))}
      </OptGroup>
    </Select>
  );
}

export default CatalogueChannelSelect;
