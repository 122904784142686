import React from 'react';
import { useDispatch } from 'react-redux';
import { Drawer } from 'antd';
import PreviewTable from '../export_builder_advanced/PreviewTable';
import ExportBuilderAdvancedDrawer from '../export_builder_advanced/ExportBuilderAdvancedDrawer';
import { ChannelColumn, ChannelTemplate } from '../../../../types/channel';
import {
  fetchChannelAdvisorTemplate,
  fetchChannelNPWTemplate,
  fetchChannelShopifyTemplate,
} from '../../../actions/channel/fetch';
import { ExportBuilderAdvancedSegment } from '../../../../types/export_builder_advanced';
import { ChannelTypesWithBrandConnectivity } from '../../../constants/ChannelConstants';

type EditChannelProductDataDrawerProps = {
  onClose: () => void;
  title: string;
  visible: boolean;
  brandId: number | null;
  template: ChannelTemplate;
  templateColumns: ChannelColumn[];
  segments: ExportBuilderAdvancedSegment[];
  fetchingApplicationResources: boolean;
  fetchingSegments: boolean;
  fetchingTemplate: boolean;
  preview: { [key: string]: string }[];
  channelType?: string;
};

const EditChannelProductDataDrawer: React.FC<EditChannelProductDataDrawerProps> = props => {
  const dispatch = useDispatch();

  const [drawerColumnVisible, setDrawerColumnVisible] = React.useState(false);
  const [selectedColId, setSelectedColId] = React.useState<number | null>();

  const templateColumnsData = props.templateColumns.map(t => ({
    id: t.id,
    label: t.name,
    number: t.number,
    dataKey: t.code,
    locked: t.configuration.locked,
  }));

  return (
    <Drawer
      title={props.title}
      placement="right"
      closable
      onClose={() => {
        props.onClose();
      }}
      open={props.visible}
      width="65%"
      className="channel-advisor-product-data-drawer"
    >
      <React.Fragment>
        <PreviewTable
          templateColumns={templateColumnsData || []}
          updateColumnsOrder={() => ''}
          setDrawerVisible={(visible: boolean, colId: number | null) => {
            setDrawerColumnVisible(visible);
            setSelectedColId(colId);
          }}
          fetching={props.fetchingApplicationResources || props.fetchingSegments}
          fetchingPreview={props.fetchingTemplate}
          preview={props.preview}
          segment="editChannel"
          lockColumnOrder // no sorting columns required in channel
        />
        {drawerColumnVisible && (
          <ExportBuilderAdvancedDrawer
            visible={drawerColumnVisible}
            onClose={() => {
              setDrawerColumnVisible(false);
              if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR)
                dispatch(fetchChannelAdvisorTemplate(props.brandId!));
              if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELNPW)
                dispatch(fetchChannelNPWTemplate(props.brandId!));
              if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELSHOPIFY)
                dispatch(fetchChannelShopifyTemplate(props.brandId!));
            }}
            selectNextColumn={() => {
              const index = props.templateColumns.findIndex(col => col.id === selectedColId);
              if (index !== -1 && index + 1 < props.templateColumns.length)
                setSelectedColId(props.templateColumns[index + 1].id);
            }}
            selectPrevColumn={() => {
              const index = selectedColId
                ? props.templateColumns.findIndex(col => col.id === selectedColId)
                : props.templateColumns.length;
              if (index > 0) setSelectedColId(props.templateColumns[index - 1].id);
            }}
            createNewColumn={() => setSelectedColId(null)}
            segments={props.segments}
            selectedColId={selectedColId!}
            templateColumns={props.templateColumns || []}
            selectedTemplateId={props.template?.id}
            lockedColumn={props.template.locked_columns === 1}
            brandId={props.brandId!}
            segment="editChannel"
          />
        )}
      </React.Fragment>
    </Drawer>
  );
};

export default EditChannelProductDataDrawer;
