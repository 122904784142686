import React from 'react';
import DateInput from '../../../global/DateInput';

class DigitalAssetDates extends React.Component {
  showDate = typeId => {
    if (!this.props.selectedDigitalAsset.dates) return '';
    const date = this.props.selectedDigitalAsset.dates.find(date => date.type_id === typeId);
    return date ? date.value : '';
  };

  updateDates = (newValue, dateTypeId) => {
    const { selectedDigitalAsset } = this.props;
    const digitalAssetId = selectedDigitalAsset.digital_asset_id || selectedDigitalAsset.id;
    const { dates } = selectedDigitalAsset;
    const isNewDate = !dates.find(date => date.type_id === dateTypeId);
    const rawDateObj = dates.map(date => ({ value: date.value, type_id: date.type_id }));

    const updatedDates = isNewDate
      ? [{ value: newValue, type_id: dateTypeId }, ...rawDateObj]
      : rawDateObj.map(date => {
          if (date.type_id === dateTypeId) {
            return { ...date, value: newValue };
          }
          return date;
        });

    this.props.updateDigitalAssetParent(digitalAssetId, 'dates', updatedDates);
  };

  render() {
    const key = this.props.selectedDigitalAsset.id;
    return (
      <div>
        {this.props.digitalAssetResources.date_types.map(dateType => (
          <div className="form-field" key={dateType.id}>
            <span className="form-field-name">{dateType.name}</span>
            <DateInput
              key={key}
              className="form-field-content"
              value={this.showDate(dateType.id) || ''}
              onChange={val => {
                this.updateDates(val, dateType.id);
              }}
              disabled={!this.props.canManageDigitalAssets}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default DigitalAssetDates;
