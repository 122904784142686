import { FluxStandardAction } from 'redux-promise-middleware';
import { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../javascripts/reducers';

export enum DrawerMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type ThunkAsyncAction = { type: string; payload?: Promise<any>; meta?: any };
export type ThunkAsyncDispatch = ThunkDispatch<ApplicationState, undefined, ThunkAsyncAction>;
export type ThunkResult<R> = ThunkAction<R, ApplicationState, undefined, ThunkAsyncAction>;
export type ThunkAsyncResult = ThunkResult<Promise<void>>;
export type ThunkAxiosResult = ThunkResult<Promise<AxiosResponse>>;
// TODO: Remove this after refactoring its usages, no longer needed.
export type AsyncDispatch = (action: FluxStandardAction) => Promise<any>;

export interface Indexable {
  [key: string]: any;
}
