import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemInfo from '../../components/global/ItemInfo';
import ExtendedInfoPage from '../../components/body/extended_info/ExtendedInfoPage';
import {
  fetchUsedExtendedInfoTypes,
  fetchExtendedInfoTypeOptionsOrder,
  fetchExtendedInfos,
} from '../../actions/items/extended_info/fetch';
import { intercomEvent } from '../../utils/IntercomUtils';
import * as navigationActions from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import { ExpiType } from '../../../types/extendedInfo';
import { AsyncDispatch } from '../../../types/global';
import { SelectedListItem } from '../../../types/item';
import { fetchSelectedItemsLists } from '../../actions/catalogue/catalogue/fetch';

const ExtendedInfoContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const [multiItemTypes, setMultiItemTypes] = React.useState<ExpiType[]>([]);
  const [differentValueTypeIds, setDifferentValueTypeIds] = React.useState<number[]>([]);

  const {
    selectedItems,
    selectedBrandId,
    itemBrandId,
    selectedItemIds,
    allSelectedItemIds,
    allItemsSelected,
    filterId,
    reverseSelected,
    reversedItemIds,
    isFetchingFullItem,
    extendedInfos,
    usedExtendedInfoTypes,
    fetchingExpi,
    updateExpiPending,
    typeBrandId,
    fetchingSelectedItemsList,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      selectedBrandId: state.parent.brands.selectedBrandId,
      itemBrandId: getSelectedItems(state)[0]?.parent_owner_brand_id,
      selectedItemIds: state.catalogue.catalogue.selectedItemIds,
      allItemsSelected: state.catalogue.catalogue.allItemsSelected,
      allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
      filterId: state.catalogue.filter.filterGo?.id,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
      isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
      extendedInfos: state.items.extendedInfo.extendedInfos,
      usedExtendedInfoTypes: state.items.extendedInfo.usedExtendedInformationTypes,
      fetchingExpi: state.items.extendedInfo.fetchingExpi,
      updateExpiPending: state.items.extendedInfo.updateExpiPending,
      typeBrandId: state.items.extendedInfo.typeBrandId,
      fetchingSelectedItemsList: state.catalogue.catalogue.fetchingSelectedItemsList,
    };
  });

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.expi));
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'extended-info',
      part_number: selectedItems.map(i => i.part_number).join(', '),
      brand_code: selectedItems[0]?.brand_code,
    });
  }, [selectedItems]);

  React.useEffect(() => {
    const brandId = selectedBrandId || itemBrandId;
    if (brandId && typeBrandId !== brandId) {
      dispatch(fetchUsedExtendedInfoTypes(brandId));
      dispatch(fetchExtendedInfoTypeOptionsOrder(brandId));
    }
  }, [dispatch, itemBrandId, selectedBrandId, typeBrandId]);

  React.useEffect(() => {
    if (reverseSelected) {
      dispatch(fetchSelectedItemsLists({ filterId })).then(response => {
        const allItemIds: number[] = response.action.payload.data.map(
          (item: SelectedListItem) => item.id
        );
        const itemIds = allItemIds.filter(id => !reversedItemIds.includes(id));
        dispatch(fetchSelectedItemsLists({ itemIds }));
      });
    }
  }, [dispatch, filterId, reverseSelected, reversedItemIds]);

  React.useEffect(() => {
    if (allItemsSelected && selectedItemIds.length > 1) {
      dispatch(fetchSelectedItemsLists({ filterId }));
    }
    if (!allItemsSelected && !reverseSelected && selectedItemIds.length > 1)
      dispatch(fetchSelectedItemsLists({ itemIds: selectedItemIds }));
  }, [allItemsSelected, dispatch, filterId, reverseSelected, selectedItemIds]);

  React.useEffect(() => {
    if (allSelectedItemIds.length > 1 && !fetchingSelectedItemsList) {
      dispatch(fetchExtendedInfos(allSelectedItemIds));
    } else if (selectedItemIds.length === 1) {
      dispatch(fetchExtendedInfos(selectedItemIds));
    }
  }, [
    dispatch,
    allSelectedItemIds,
    allSelectedItemIds.length,
    fetchingSelectedItemsList,
    selectedItemIds,
  ]);

  React.useEffect(() => {
    if (selectedItems.length > 1 && !fetchingExpi && !isFetchingFullItem && !updateExpiPending) {
      const usedTypeIds = usedExtendedInfoTypes.map(usedType => usedType.type_id);

      const selectedUsedTypeIds = usedTypeIds.filter(typeId =>
        extendedInfos.find(info => info.types.find(t => t.id === typeId && t.contents.length > 0))
      );

      const differentValueTypeIds = selectedUsedTypeIds.filter(typeId => {
        if (allSelectedItemIds.length !== extendedInfos.length) return true;

        const type = extendedInfos[0]?.types.find(type => type.id === typeId);
        const firstItemValues = type?.contents.map(c => c.value).sort() || [];

        return extendedInfos.find(extendedInfo => {
          const type = extendedInfo.types.find(type => type.id === typeId);
          if (!type) return true;

          const values = type?.contents.map(c => c.value).sort() || [];

          return !(
            firstItemValues.length === values.length &&
            firstItemValues.every((value, i) => value === values[i])
          );
        });
      });

      const types = extendedInfos[0]?.types || [];
      const multiTypes = types.filter(type => !differentValueTypeIds.includes(type.id));

      setMultiItemTypes(multiTypes);
      setDifferentValueTypeIds(differentValueTypeIds);
    }
  }, [
    extendedInfos,
    isFetchingFullItem,
    fetchingExpi,
    updateExpiPending,
    usedExtendedInfoTypes,
    selectedItems.length,
    allSelectedItemIds.length,
  ]);

  if (selectedItems.length < 1) return <ItemInfo items={selectedItems} />;

  return (
    <ExtendedInfoPage
      multiSelect={selectedItems.length > 1}
      multiItemTypes={multiItemTypes}
      differentValueTypeIds={differentValueTypeIds}
    />
  );
};

export default ExtendedInfoContainer;
