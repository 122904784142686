import axios from 'axios';
import { Dispatch } from 'redux';
import { message } from 'antd';
import * as utils from '../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import { getPathName, getExportTypeFromSourceId } from '../../utils/ImportExportUtils';
import { ExportTypes } from '../../../types/import_export';

const CancelToken = axios.CancelToken;

let fetchUsedVehiclesCancelToken: any;
let fetchRecommendedVehiclesCancelToken: any;

export function fetchLatestExportLogs(userId: number) {
  const params = utils.createURL([
    { name: 'limit', values: 50 },
    { name: 'user_id', values: userId },
  ]);

  return {
    type: '@EXPORTER/FETCH_LATEST_EXPORT_LOGS',
    payload: axios.get(`${getNonGwApiRoot()}/exports/logs${params}`),
  };
}

export function fetchRunningExportLogs(logIds: number[]) {
  const params = utils.createURL([
    { name: 'export_log_ids', values: logIds },
    { name: 'limit', values: logIds.length },
    { name: 'page', values: 1 },
  ]);

  return {
    type: '@EXPORTER/FETCH_RUNNING_EXPORT_LOGS',
    payload: axios.get(`${getNonGwApiRoot()}/exports/logs${params}`),
  };
}

export function addToReadyExports(logId: number) {
  return {
    type: '@EXPORTER/EXPORT_DOWNLOAD_READY',
    payload: { logId },
  };
}

type CreateExportParams = {
  brandId: number;
  fileType: ExportTypes;
  itemIds?: number[];
  exportSchemaId?: number;
  populated: number;
  filterId: number;
  acesVersion?: string;
  piesVersion?: string;
  baseVehicle?: string;
  priceSheetIds?: number[];
  priceTypeIds?: number[];
  exportTemplateId?: number;
  exportTemplateAdvancedId?: number;
  individualYears?: boolean;
  selectedSegmentIds?: number[];
  withoutItemIds?: number[];
  pdfTemplateId?: number;
  priceSheetId?: number;
};

type CreateNewExportParams = {
  brandId?: number;
  receiverExportBrandIds?: number[];
  fileType: ExportTypes;
  fileTypeId: number;
  fileTypeName: string;
  itemIds?: number[];
  exportSchemaId?: number;
  populated?: number;
  filterId: number | null;
  exportFilterTypeOptions: any;
};

type GapCoverageReportExportParams = {
  brandId: number;
  filterId: number | null;
  selectedPartTypes: number[];
  makes: number[];
  models: number[];
  years: number[];
  vehicleTypes: number[];
  vehicleTypeGroups: number[];
  positionIds: number[];
  subConfigTypeIds: number[];
  regions: number[];
};

type GapCoverageVehicles = {
  categoryIds?: number[];
  makeIds: number[];
  modelIds: number[];
  yearIds: number[];
  vehicleTypeIds: number[];
  vehicleGroupIds: number[];
};

export const exportChannel = (channelPlanId: number, brandId: number, fileTypeId: number) => {
  return async (dispatch: Dispatch) => {
    const params = utils.createURL([
      { name: 'channel_plan_ids', values: channelPlanId },
      { name: 'manual_delivery_type_id', values: 3 },
    ]);

    const fileType = getExportTypeFromSourceId(fileTypeId);

    let exportRes;
    try {
      exportRes = await axios.post(`${getNonGwApiRoot()}/channels/plans/files${params}`);
      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType,
          brandId,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
};

export function startExport({
  brandId,
  fileType,
  itemIds,
  exportSchemaId,
  populated,
  filterId,
  acesVersion,
  piesVersion,
  baseVehicle,
  priceSheetIds,
  priceTypeIds,
  exportTemplateId,
  exportTemplateAdvancedId,
  individualYears,
  selectedSegmentIds,
  withoutItemIds,
  pdfTemplateId,
  priceSheetId,
}: CreateExportParams) {
  return async (dispatch: Dispatch) => {
    const pathName = getPathName(fileType);
    const paramsArray = [
      { name: 'brand_id', values: brandId },
      { name: 'filter_id', values: filterId },
      { name: 'export_schema_id', values: exportSchemaId },
      { name: 'populated', values: populated },
      { name: 'version_id', values: acesVersion || piesVersion },
      { name: 'use_base_vehicle', values: baseVehicle },
      { name: 'price_sheet_ids', values: priceSheetIds },
      { name: 'price_type_ids', values: priceTypeIds },
      { name: 'brand_export_template_id', values: exportTemplateId },
      { name: 'full_export_template_id', values: exportTemplateAdvancedId },
      { name: 'individual_years', values: utils.booleanToInt(individualYears) },
      { name: 'segment_ids', values: selectedSegmentIds },
      { name: 'template_id', values: pdfTemplateId },
      { name: 'price_sheet_id', values: priceSheetId },
    ];

    const itemIdsBody = itemIds && !filterId ? { item_ids: itemIds } : undefined;
    const withoutItemIdsBody = withoutItemIds ? { without_item_ids: withoutItemIds } : undefined;

    const params = utils.createURL(paramsArray);

    let exportRes;
    try {
      exportRes = await axios.post(`${getApiRoot()}/exports/${pathName}${params}`, {
        ...itemIdsBody,
        ...withoutItemIdsBody,
      });
      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType,
          brandId,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}

export function startNewExport({
  brandId,
  receiverExportBrandIds,
  fileTypeId,
  fileTypeName,
  itemIds,
  exportSchemaId,
  populated,
  filterId,
  exportFilterTypeOptions,
}: CreateNewExportParams) {
  return async (dispatch: Dispatch) => {
    const paramsArray = [
      { name: 'brand_id', values: brandId || receiverExportBrandIds },
      { name: 'file_type_id', values: fileTypeId },
      { name: 'filter_id', values: filterId },
      { name: 'export_schema_id', values: exportSchemaId },
      { name: 'populated', values: populated },
    ];

    const params = utils.createURL(paramsArray);

    let exportRes;
    try {
      exportRes = await axios.post(`${getNonGwApiRoot()}/exports/files${params}`, {
        item_ids: itemIds || undefined,
        export_options: exportFilterTypeOptions,
      });
      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType: fileTypeName,
          brandId,
          fileTypeId,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}

export function startGapCoverageExport({
  brandId,
  filterId,
  selectedPartTypes,
  makes,
  models,
  years,
  vehicleTypes,
  vehicleTypeGroups,
  positionIds,
  subConfigTypeIds,
  regions,
}: GapCoverageReportExportParams) {
  return async (dispatch: Dispatch) => {
    const paramsObj = {
      brand_id: brandId,
      filter_id: filterId,
      category_ids: selectedPartTypes,
      make_ids: makes,
      model_ids: models,
      year_ids: years,
      vehicle_type_ids: vehicleTypes,
      vehicle_type_groups: vehicleTypeGroups,
      position_ids: positionIds,
      visable_sub_config_type_ids: subConfigTypeIds,
      region_ids: regions,
      type_id: 4,
      type_option_id: 4,
    };

    let exportRes;
    try {
      exportRes = await axios.post(`${getApiRoot()}/exports/reports`, paramsObj);
      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType: 'Excel Report',
          brandId,
          fileTypeId: 21,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}

export function closeExportNotificaton(id: number) {
  return {
    type: '@EXPORTER/CLOSE_NOTIFICATION',
    payload: { id },
  };
}

export function openExportNotification(id: number) {
  return {
    type: '@EXPORTER/OPEN_NOTIFICATION',
    payload: { id },
  };
}

export function parentCloseExportNotification(id: string) {
  return {
    type: '@EXPORTER/PARENT_CLOSE_NOTIFICATION',
    payload: { id },
  };
}

export function parentOpenExportNotification(id: string) {
  return {
    type: '@EXPORTER/PARENT_OPEN_NOTIFICATION',
    payload: { id },
  };
}

export function fetchUsedVehicles(selectedPartTypes: number[]) {
  const params = utils.createURL([{ name: 'category_ids', values: selectedPartTypes }]);

  if (fetchUsedVehiclesCancelToken) fetchUsedVehiclesCancelToken();

  return {
    type: 'FETCH_USED_VEHICLES',
    payload: axios.get(`${getNonGwApiRoot()}/vehicles/used_vehicles${params}`, {
      cancelToken: new CancelToken(c => {
        fetchUsedVehiclesCancelToken = c;
      }),
    }),
  };
}

export function fetchRecommendedVehicles({
  makeIds,
  modelIds,
  yearIds,
  vehicleTypeIds,
  vehicleGroupIds,
}: GapCoverageVehicles) {
  const paramObj = {
    make_ids: makeIds,
    model_ids: modelIds,
    year_ids: yearIds,
    type_ids: vehicleTypeIds,
    group_ids: vehicleGroupIds,
  };

  if (fetchRecommendedVehiclesCancelToken) fetchRecommendedVehiclesCancelToken();

  return {
    type: 'FETCH_RECOMMENDED_VEHICLES',
    payload: axios.post(`${getApiRoot()}/vehicles`, paramObj, {
      cancelToken: new CancelToken(c => {
        fetchRecommendedVehiclesCancelToken = c;
      }),
    }),
  };
}

export function resetRecommendedVehicles() {
  return {
    type: 'RESET_RECOMMENDED_VEHICLES',
  };
}

export function fetchUsedPositions({
  categoryIds,
  makeIds,
  modelIds,
  yearIds,
  vehicleTypeIds,
  vehicleGroupIds,
}: GapCoverageVehicles) {
  const paramObj = {
    category_ids: categoryIds,
    make_ids: makeIds,
    model_ids: modelIds,
    year_ids: yearIds,
    vehicle_type_ids: vehicleTypeIds,
    vehicle_group_ids: vehicleGroupIds,
  };

  return {
    type: 'FETCH_USED_POSITIONS',
    payload: axios.post(`${getNonGwApiRoot()}/vehicles/used_positions`, paramObj),
  };
}

export function fetchUsedSubConfigs({
  categoryIds,
  makeIds,
  modelIds,
  yearIds,
  vehicleTypeIds,
  vehicleGroupIds,
}: GapCoverageVehicles) {
  const paramObj = {
    category_ids: categoryIds,
    make_ids: makeIds,
    model_ids: modelIds,
    year_ids: yearIds,
    vehicle_type_ids: vehicleTypeIds,
    vehicle_group_ids: vehicleGroupIds,
  };

  return {
    type: 'FETCH_USED_SUB_CONFIGS',
    payload: axios.post(`${getNonGwApiRoot()}/vehicles/used_sub_configs`, paramObj),
  };
}
