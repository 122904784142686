import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import * as navigationActions from '../../actions/app/navigationBar';
import PackagePage from '../../components/body/package/PackagePage';
import ItemInfo from '../../components/global/ItemInfo';
import { areas } from '../../constants/ParamountReactConstants';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { intercomEvent } from '../../utils/IntercomUtils';
import { fetchSelectedItemsLists } from '../../actions/catalogue/catalogue/fetch';
import { SelectedListItem } from '../../../types/item';
import { AsyncDispatch } from '../../../types/global';
import { fetchItemPackages, fetchBrandUsedPackageFields } from '../../actions/items/package/fetch';
import { AllPackageInputStrings } from '../../constants/PackageConstants';
import { ExtendedResources, StandardResource } from '../../../types/resources';
import { HazardousMaterial, MultiValueFields, Package } from '../../../types/itemPackage';
import { packagesUpdatedWithSummary } from '../../selectors/packageSeg/packageSelector';

const PackageContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedItems,
    selectedItemIds,
    allItemsSelected,
    filterId,
    reverseSelected,
    reversedItemIds,
    selectedBrandId,
    isFetchingFullItem,
    fetchingPackages,
    packages,
    usedFieldsBrandId,
    uoms,
    initialAccountResourcesFetching,
    itemPackages,
    grossWeights,
    dimensionUoms,
    allSelectedItemIds,
  } = useSelector((state: ApplicationState) => ({
    selectedItems: getSelectedItems(state),
    selectedItemIds: state.catalogue.catalogue.selectedItemIds,
    allItemsSelected: state.catalogue.catalogue.allItemsSelected,
    filterId: state.catalogue.filter.filterGo?.id,
    reverseSelected: state.catalogue.catalogue.reverseSelected,
    reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    selectedBrandId: state.parent.brands.selectedBrandId,
    isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
    fetchingPackages: state.items.packageSeg.fetchingPackages,
    packages: state.items.packageSeg.itemPackages,
    usedFieldsBrandId: state.items.packageSeg.usedFieldsBrandId,
    uoms: state.resources.data.package.uoms as ExtendedResources[],
    grossWeights: state.resources.data.package.weight_uoms as StandardResource[],
    dimensionUoms: state.resources.data.package.dimension_uoms as StandardResource[],
    initialAccountResourcesFetching: state.resources.initialAccountResourcesFetching,
    allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
    itemPackages: packagesUpdatedWithSummary(state) as Package[],
  }));

  const getPkgSummary = useCallback(
    (pkg: Package) => {
      const shipping =
        pkg.length || pkg.width || pkg.height
          ? ` | ${t('packageSeg:package.s')} ${pkg.height ? pkg.height : '-'} x ${
              pkg.width ? pkg.width : '-'
            } x ${pkg.length ? pkg.length : '-'} ${pkg.dimensionsUomName}`
          : '';
      const merchandising =
        pkg.merchandising_length || pkg.merchandising_height || pkg.merchandising_width
          ? ` | ${t('packageSeg:package.m')} ${
              pkg.merchandising_height ? pkg.merchandising_height : '-'
            } x ${pkg.merchandising_width ? pkg.merchandising_width : '-'} x ${
              pkg.merchandising_length ? pkg.merchandising_length : '-'
            } ${pkg.dimensionsUomName}`
          : '';
      const dimensionalWeight = pkg.dimensional_weight
        ? ` | ${t('packageSeg:package.sDimensionalWt')} ${pkg.dimensional_weight}`
        : '';
      const pkgWeight = pkg.weight ? ` | ${pkg.weight} ${pkg.weightName}` : '';
      const gtin = pkg.level_gtin
        ? ` | ${t('packageSeg:package.levelGtin')} ${pkg.level_gtin}`
        : '';

      return `${pkg.quantity_of_eaches} ${pkg.uomName}
       ${shipping}${dimensionalWeight}${pkgWeight}${merchandising}${gtin}`;
    },
    [t]
  );

  const getDetailedSummary = useCallback(
    (pkg: Package) => {
      const detailedSummary: string[] = [];

      if (pkg.quantity_of_eaches)
        detailedSummary.push(
          `${t('packageSeg:package.quantityEach')}: ${pkg.quantity_of_eaches} ${pkg.uomName}`
        );
      if (pkg.length || pkg.width || pkg.height)
        detailedSummary.push(
          `${t('packageSeg:package.shippingDim')}: ${pkg.height || '-'} x ${pkg.width || '-'} x ${
            pkg.length || '-'
          } ${pkg.dimensionsUomName}`
        );
      if (pkg.dimensional_weight)
        detailedSummary.push(
          `${t('packageSeg:package.sDimensionalWt')}: ${pkg.dimensional_weight}`
        );
      if (pkg.weight)
        detailedSummary.push(`${t('packageSeg:package.weight')}: ${pkg.weight} ${pkg.weightName}`);
      if (pkg.merchandising_length || pkg.merchandising_height || pkg.merchandising_width)
        detailedSummary.push(
          `${t('packageSeg:package.mDimensions')}: ${pkg.merchandising_height || '-'} x ${
            pkg.merchandising_width || '-'
          } x ${pkg.merchandising_length} ${pkg.dimensionsUomName}`
        );
      if (pkg.level_gtin)
        detailedSummary.push(`${t('packageSeg:package.levelGtin')}: ${pkg.level_gtin}`);
      return detailedSummary;
    },
    [t]
  );

  const getHZSummary = (hz: HazardousMaterial) => `${hz.transportUomName || ''} | ${
    hz.shippingScopeUomName || ''
  } | ${hz.bulkUomName || ''} 
      | ${hz.countryUomName || ''} | ${hz.regulatedOptionName || ''} | ${hz.languageName || ''} `;

  const hzDetailedSummary = useCallback(
    (hz: HazardousMaterial) => {
      const detailedSummary: string[] = [];
      if (hz.transport_method_id)
        detailedSummary.push(
          `${t('packageSeg:hazardous.transportMethod')}: ${hz.transportUomName}`
        );
      if (hz.shipping_scope_id)
        detailedSummary.push(
          `${t('packageSeg:hazardous.shippingScope')}: ${hz.shippingScopeUomName}`
        );
      if (hz.bulk_id) detailedSummary.push(`${t('packageSeg:hazardous.bulk')}: ${hz.bulkUomName}`);
      if (hz.regulating_country_id)
        detailedSummary.push(
          `${t('packageSeg:hazardous.regulatingCountry')}: ${hz.countryUomName}`
        );
      if (hz.regulated_option_id)
        detailedSummary.push(`${t('packageSeg:hazardous.regulated')}: ${hz.regulatedOptionName}`);
      if (hz.language_id)
        detailedSummary.push(`${t('packageSeg:hazardous.language')}:  ${hz.languageName}`);
      return detailedSummary;
    },
    [t]
  );

  const [multipleValueFields, setMultipleValueFields] = React.useState<MultiValueFields[]>();
  const [extendedItemPackages, setExtendedItemPackages] = React.useState<Package[]>(
    itemPackages.map(pkg => ({
      ...pkg,
      originalUomId: pkg.uom_id,
      summary: getPkgSummary(pkg),
      detailedSummary: getDetailedSummary(pkg),
      hazardous_materials: pkg.hazardous_materials.map((hz, index) => ({
        ...hz,
        recordName: `${t('packageSeg:hazardous.hz')} ${index + 1}`,
        summary: getHZSummary(hz),
        detailedSummary: hzDetailedSummary(hz),
      })),
    }))
  );

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.package));
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'package',
      part_number: selectedItems.map(i => i.part_number).join(', '),
      brand_code: selectedItems[0]?.brand_code,
    });
  }, [selectedItems]);

  React.useEffect(() => {
    if (selectedItemIds.length === 1)
      setExtendedItemPackages(
        itemPackages.map(pkg => ({
          ...pkg,
          summary: getPkgSummary(pkg),
          detailedSummary: getDetailedSummary(pkg),
          hazardous_materials: pkg.hazardous_materials.map((hz, index) => ({
            ...hz,
            recordName: `${t('packageSeg:hazardous.hz')} ${index + 1}`,
            summary: getHZSummary(hz),
            detailedSummary: hzDetailedSummary(hz),
          })),
        }))
      );
  }, [
    getDetailedSummary,
    getPkgSummary,
    hzDetailedSummary,
    itemPackages,
    selectedItemIds.length,
    t,
  ]);

  React.useEffect(() => {
    if (selectedBrandId !== usedFieldsBrandId)
      dispatch(fetchBrandUsedPackageFields(selectedBrandId));
  }, [dispatch, selectedBrandId, usedFieldsBrandId]);

  React.useEffect(() => {
    if (reverseSelected) {
      dispatch(fetchSelectedItemsLists({ filterId })).then(response => {
        const allItemIds: number[] = response.action.payload.data.map(
          (item: SelectedListItem) => item.id
        );
        const itemIds = allItemIds.filter(id => !reversedItemIds.includes(id));
        dispatch(fetchSelectedItemsLists({ itemIds })).then(() =>
          dispatch(fetchItemPackages({ itemIds }))
        );
      });
    }
  }, [dispatch, filterId, reverseSelected, reversedItemIds]);

  React.useEffect(() => {
    if (selectedItemIds.length > 0) {
      if (allItemsSelected && selectedItemIds.length > 1) {
        const params = {
          filterId: selectedItemIds.length >= 100 ? filterId : undefined,
          itemIds: selectedItemIds.length < 100 ? selectedItemIds : undefined,
        };
        dispatch(fetchSelectedItemsLists(params));
        dispatch(fetchItemPackages(params));
      }
      if (
        !allItemsSelected ||
        (allItemsSelected && selectedItemIds.length === 1 && !reverseSelected)
      ) {
        if (selectedItemIds.length > 1)
          dispatch(fetchSelectedItemsLists({ itemIds: selectedItemIds }));
        dispatch(fetchItemPackages({ itemIds: selectedItemIds }));
      }
    }
  }, [allItemsSelected, dispatch, filterId, reverseSelected, selectedItemIds]);

  React.useEffect(() => {
    if (
      selectedItems.length > 1 &&
      !fetchingPackages &&
      !isFetchingFullItem &&
      !initialAccountResourcesFetching
    ) {
      const getUsedBrandUomIds = uoms
        .filter(uom =>
          packages.find(itemPkg => itemPkg.packages.find(pkg => pkg.uom_id === uom.id))
        )
        .map(uom => uom.id);

      const getUsedBrandUomPackages = getUsedBrandUomIds.map(id =>
        packages
          .find(itemPkg => itemPkg.packages.find(pkg => pkg.uom_id === id))
          ?.packages.find(pkg => pkg.uom_id === id)
      );

      setExtendedItemPackages(
        getUsedBrandUomPackages.map(pkg => ({
          ...pkg!,
          originalUomId: pkg!.uom_id,
          uomName: uoms.find(uom => uom.id === pkg!.uom_id)?.name || '',
        }))
      );

      const differentValuePackage = getUsedBrandUomIds.map(uomId => {
        const getIDPackage = packages
          .filter(pkg => pkg.packages.find(p => p.uom_id === uomId))
          .map(pkg => pkg.packages.find(p => p.uom_id === uomId));

        if (getIDPackage.length < allSelectedItemIds.length) {
          return {
            uomId,
            multiValues: AllPackageInputStrings,
          };
        }
        const multiValues = AllPackageInputStrings.filter(name => {
          // @ts-ignore
          const values = getIDPackage.map(p => p[name]);
          const firstValue = values[0];
          return !values.every(value => value === firstValue);
        });
        return { uomId, multiValues };
      });

      setMultipleValueFields(differentValuePackage);
    }
  }, [
    isFetchingFullItem,
    selectedItems.length,
    fetchingPackages,
    initialAccountResourcesFetching,
    uoms,
    packages,
    grossWeights,
    dimensionUoms,
    allSelectedItemIds.length,
  ]);

  if (selectedItems.length < 1) return <ItemInfo items={selectedItems} />;

  return (
    <PackagePage
      multipleValueFields={multipleValueFields}
      fetchingPackages={fetchingPackages}
      extendedItemPackages={extendedItemPackages}
      selectedBrandId={selectedBrandId}
    />
  );
};

export default PackageContainer;
