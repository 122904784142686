import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from '../reducers/index.ts';

const isDev = process.env.NODE_ENV === 'development';
const middleware = [thunk, promise, isDev ? logger : null].filter(Boolean);
/* eslint-disable no-underscore-dangle */
const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
/* eslint-enable */
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

export default store;
