import React, { memo } from 'react';

type AttributeHeaderCellProps = {
  partNumber: string;
};

const AttributeHeaderCell: React.FC<AttributeHeaderCellProps> = ({ partNumber }) => {
  return <div className="m-2 w-full font-medium">{partNumber}</div>;
};

export default memo(AttributeHeaderCell);
