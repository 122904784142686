import React, { Dispatch } from 'react';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { intercomEvent } from '../../../utils/IntercomUtils';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type IntercomFormEventHandlerProps = {
  dispatch: Dispatch<AnyAction>;
  segment: string;
  partNr: string;
  brandCode: string;
};

class IntercomFormEventHandler extends React.Component<
  IntercomFormEventHandlerProps & FormikProps
> {
  componentDidUpdate(prevProps: IntercomFormEventHandlerProps & FormikProps) {
    const { segment, partNr, brandCode } = this.props;
    const { dirty } = this.props.formik;
    const { dirty: prevDirty } = prevProps.formik;
    if (!prevDirty && dirty) {
      intercomEvent('viewed-all-product', {
        action: 'item-edited',
        location: segment,
        part_number: partNr,
        brand_code: brandCode,
      });
    }
  }

  render() {
    return null;
  }
}

export default connect()(
  formikConnect<IntercomFormEventHandlerProps, FormikValues>(IntercomFormEventHandler)
);
