import { combineReducers, Reducer } from 'redux';
import app, { AppState } from './app/index';
import brand, { BrandState } from './brand';
import receiverDataStream, {
  ReceiverDataStreamState,
} from './receiver_data_stream/ReceiverDataStreamReducer';
import externalSources, { ExternalSourcesState } from './external_sources/ExternalSourcesReducer';
import catalogue, { BrandCatalogueState } from './catalogue';
import channel from './channel';
import items, { ItemsState } from './items';
import parent, { ParentState } from './parent';
import receiver, { ReceiverState } from './receiver';
import allApplications, { ApplicationListState } from './all_applications';
import resources from './resources';
import settings, { SettingsState } from './settings';
import tools, { ToolState } from './tools';
import user, { UserState } from './user/UserReducer';
import { ResourcesState } from './resources/ResourcesReducer';
import importer, { ImporterState } from './importer/ImporterReducer';
import exporter, { ExporterState } from './exporter/ExporterReducer';
import channelPusher, { ChannelPusherState } from './channelPusher/ChannelPusherReducer';
import distribution, { DistributionState } from './distribution_apiSettings';

export type ApplicationState = {
  app: AppState;
  brand: BrandState;
  receiverDataStream: ReceiverDataStreamState;
  catalogue: BrandCatalogueState;
  channel: any;
  items: ItemsState;
  parent: ParentState;
  receiver: ReceiverState;
  resources: ResourcesState;
  settings: SettingsState;
  tools: ToolState;
  user: UserState;
  allApplications: ApplicationListState;
  importer: ImporterState;
  exporter: ExporterState;
  channelPusher: ChannelPusherState;
  distribution: DistributionState;
  externalSources: ExternalSourcesState;
};

const appReducer = combineReducers<ApplicationState>({
  app,
  brand,
  receiverDataStream,
  externalSources,
  catalogue,
  channel,
  items,
  parent,
  receiver,
  resources,
  settings,
  tools,
  user,
  allApplications,
  importer,
  exporter,
  channelPusher,
  distribution,
});

const rootReducer: Reducer<ApplicationState> = (state: any, action) => {
  if (action.type === 'RESET_REDUX') {
    state = { app: { global: state.app.global }, bugsnag: state.bugsnag, user: state.user };
  }
  return appReducer(state, action);
};

export default rootReducer;
