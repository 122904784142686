import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Spin } from 'antd';
import queryString from 'query-string';
import { SyncOutlined } from '@ant-design/icons';
import { withRouter, WithRouterProps } from '../withRouter';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/application';
import catalougeActions from '../../actions/catalogue/catalogue/index';
import * as navigationActions from '../../actions/app/navigationBar';
import { fetchAccountLanguages } from '../../actions/parent/language/fetch';
import { fetchApplicationResources } from '../../actions/items/application/fetch';
import * as utils from '../../utils/Utils';
import { extendedAnalysesBySegment } from '../../selectors/item_analysis/itemAnalysisSelector';
import {
  applicationAnalysesGroups,
  structuredApplications,
  structuredLinkedApplications,
  structuredOeApplications,
} from '../../selectors/applications/applicationsSelector';
import { bundleItemsApplicationIndexed } from '../../selectors/applications/applicationBundleSelector';
import { triggers as hotjarTriggers } from '../../constants/Hotjar.json';
import ApplicationOverview from '../../components/body/application/ApplicationOverview';
import ApplicationEditDrawer from '../all_applications_validation/ApplicationEditDrawer';
import ApplicationGridEditDrawer from '../all_applications_validation/ApplicationGridEditDrawer';
import ItemInfo from '../../components/global/ItemInfo';
import { areas } from '../../constants/ParamountReactConstants';
import constants from '../../constants/ApplicationTranslation.json';
import { isReceiver, isManufacturer } from '../../utils/UserUtils';
import { hasPermission } from '../../utils/Permissions';
import { ApplicationState } from '../../reducers';
import { Item } from '../../../types/item';
import { UserType } from '../../../types/user';
import { AsyncDispatch } from '../../../types/global';
import { Analysis } from '../../../types/analyses';
import {
  StructuredApplication,
  Application,
  BaseItemApplications,
  BundleItem,
  Qualifier,
  ApplicationNote,
  AppOrder,
  ApplicationType,
} from '../../../types/application';
import { Vehicle } from '../../../types/resources';
import { intercomEvent } from '../../utils/IntercomUtils';

type ApplicationContainerProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  selectedItems: Item[];
  selectedItem: Item;
  isReceiver: boolean;
  isManufacturer: boolean;
  showApplicationListView: boolean;
  showApplicationGridView: boolean;
  analysisBySegment: Analysis[];
  analysisGroups: { [key: string]: string };
  initialAccountResourcesFetching: boolean;
  errorApplicationResources: boolean;
  isFetchingFullItem: boolean;
  fetchingApplications: boolean;
  fetchingBundles: boolean;
  structuredApplications: StructuredApplication[];
  applications: Application[];
  applicationOrder: AppOrder[];
  baseItemApplications: BaseItemApplications[];
  oeItemApplications: BaseItemApplications[];
  applicationBundleItems: BundleItem[];
  parentApplicationItemBases: BundleItem[];
  selectedApplicationId: number | null;
  resources: Vehicle;
  defaultQualifiers: Qualifier[];
  defaultNotes: ApplicationNote[];
} & WithRouterProps;

type ApplicationContainerState = {
  detailsDrawerOpen: boolean;
  createApplicationType?: ApplicationType;
  selectedCategory?: string;
  listApplicationSelected: boolean;
  cloneApplicationSelected: boolean;
  resourcesRetryCount: number;
  keywords: string;
};

class ApplicationContainer extends React.Component<
  ApplicationContainerProps,
  ApplicationContainerState
> {
  constructor(props: ApplicationContainerProps) {
    super(props);
    this.state = {
      detailsDrawerOpen: false,
      createApplicationType: undefined,
      listApplicationSelected: false,
      cloneApplicationSelected: false,
      resourcesRetryCount: 0,
      keywords: '',
    };
  }

  componentDidMount() {
    const { selectedItem, isManufacturer } = this.props;
    const canManageBundle = hasPermission(this.props.user, 'can_manage_bundle');

    this.props.dispatch(navigationActions.setActiveArea(areas.application));
    this.props.dispatch(fetchAccountLanguages());

    if (selectedItem && selectedItem.id)
      this.getApplications().then(() => this.handleQueryStringSelect());
    if (isManufacturer && canManageBundle && selectedItem && selectedItem.id) {
      this.getBundleItems(selectedItem.id);
      intercomEvent('viewed-all-product', {
        location: 'applications',
        part_number: selectedItem.part_number,
        brand_code: selectedItem.brand_code,
      });
    }
  }

  componentDidUpdate(prevProps: ApplicationContainerProps) {
    const {
      selectedItem,
      isManufacturer,
      selectedApplicationId,
      applications,
      errorApplicationResources,
      navigate,
    } = this.props;
    const { resourcesRetryCount } = this.state;
    const { selectedItem: prevItem } = prevProps;
    const selectedItemId = selectedItem ? selectedItem.id : undefined;
    const prevItemId = prevItem ? prevItem.id : undefined;
    const { search } = location;

    if (selectedItemId && prevItemId !== selectedItemId) {
      const canManageBundle = hasPermission(this.props.user, 'can_manage_bundle');

      this.props.dispatch(actions.selectApplication(null));
      this.props
        .dispatch(actions.fetchItemApplications(selectedItemId, '', 1, true))
        .then(() => this.handleQueryStringSelect());
      if (canManageBundle && isManufacturer) this.getBundleItems(selectedItemId);

      this.setState({ keywords: '' });
    }

    if (prevProps.selectedApplicationId !== selectedApplicationId) {
      const parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { applicationId, ...rest } = parsed;
      if (
        (applicationId || selectedApplicationId) &&
        Number(applicationId) !== selectedApplicationId
      ) {
        const stringified = queryString.stringify(
          { ...rest, ...(selectedApplicationId && { applicationId: selectedApplicationId }) },
          { arrayFormat: 'comma' }
        );
        navigate(`?${stringified}`);
      }
    }

    if (selectedApplicationId && !location.search.includes('applicationId')) {
      this.props.dispatch(actions.selectApplication(null));
      this.setState({ detailsDrawerOpen: false, selectedCategory: undefined });
    } else if (!selectedApplicationId && search.includes('applicationId') && applications.length) {
      this.handleQueryStringSelect();
    }

    if (errorApplicationResources && resourcesRetryCount === 0) {
      this.setState({ resourcesRetryCount: resourcesRetryCount + 1 });
      this.props.dispatch(fetchApplicationResources(true));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actions.selectApplication(null));
  }

  getApplications = (keywords?: string) => {
    const { selectedItem } = this.props;
    const searchKeywords = keywords || this.state.keywords;
    return this.props.dispatch(actions.fetchItemApplications(selectedItem.id, searchKeywords));
  };

  getBundleItems = (itemId: number) => {
    this.props.dispatch(actions.fetchBundleItems(itemId));
  };

  getLinkedApplicationsCount = () => {
    const { baseItemApplications } = this.props;
    return baseItemApplications.map(baseItem => baseItem.applications).flat().length;
  };

  getOeApplicationsCount = () => {
    const { oeItemApplications } = this.props;
    return oeItemApplications.map(baseItem => baseItem.applications).flat().length;
  };

  handleQueryStringSelect = () => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma' });
    if (Number(params.applicationId)) this.selectApplication(Number(params.applicationId));
  };

  handleKeywordsChange = (keywords: string) => {
    this.setState({ keywords });
    const minKeywordLengthReached = utils.checkKeywordLength(keywords);
    utils.typingDone(() => {
      if (!keywords || minKeywordLengthReached) this.getApplications(keywords);
    });
  };

  fetchNextApplications = (event: any) => {
    const { keywords } = this.state;
    const nextPage = utils.nextPage(event, undefined, this.props.applications.length);
    const nextLinkedPage = utils.nextPage(event, undefined, this.getLinkedApplicationsCount());
    const nextOePage = utils.nextPage(event, undefined, this.getOeApplicationsCount());
    const page = nextPage || nextLinkedPage || nextOePage;

    if (page && !this.props.fetchingApplications) {
      this.props.dispatch(
        actions.fetchItemApplications(this.props.selectedItem.id, keywords, page)
      );
    }
  };

  findEmptyApplication = () =>
    this.props.applications.find(
      application =>
        Object.keys(application).length === 1 ||
        !Object.keys(application).find(key => application[key] && application[key].length > 0)
    );

  createApplication = (type?: ApplicationType) => {
    const { selectedItem } = this.props;
    const emptyApplication = this.findEmptyApplication();

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'application_new',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    this.setState({ detailsDrawerOpen: true, createApplicationType: type });
    if (emptyApplication) {
      this.selectApplication(emptyApplication.item_application_id);
    } else {
      this.props.dispatch(actions.createApplication(selectedItem.id, selectedItem.category_id));
      utils.triggerHj(hotjarTriggers.application_details);
    }
  };

  deleteApplication = (applicationId: number) =>
    this.props.dispatch(actions.deleteApplication({ applicationId }));

  deleteApplications = ({
    applicationIds,
    itemId,
  }: {
    applicationIds?: number[];
    itemId?: number;
  }) => this.props.dispatch(actions.deleteApplication({ applicationIds, itemId }));

  selectApplication = (id: number, category?: string) => {
    this.props.dispatch(actions.selectApplication(id));
    // category (application part type) and mfr_label, notes are located in qualifer section
    if (category === 'mfr_label') category = 'category';
    if (category === 'notes') category = 'qualifiers';

    this.setState({
      detailsDrawerOpen: true,
      selectedCategory: category,
    });
    utils.triggerHj(hotjarTriggers.application_details);
  };

  selectListApplication = (id: number) => {
    this.setState({ listApplicationSelected: true });
    this.selectApplication(id);
  };

  cloneApplication = (id: number) => {
    this.setState({ cloneApplicationSelected: true });
    this.selectApplication(id);
  };

  selectNextApplication = (id: number) => {
    const index = this.props.applications.findIndex(
      application => application.item_application_id === id
    );
    if (index !== -1 && index !== this.props.applications.length - 1) {
      const nextApplication = this.props.applications[index + 1];
      this.selectApplication(nextApplication.item_application_id);
    }
  };

  selectPreviousApplication = (id: number) => {
    const index = this.props.applications.findIndex(
      application => application.item_application_id === id
    );
    if (index !== -1 && index !== 0) {
      const prevApplication = this.props.applications[index - 1];
      this.selectApplication(prevApplication.item_application_id);
    }
  };

  showListView = (show: boolean) => {
    this.props.dispatch(actions.showApplicationListView(show));
  };

  showGridView = (show: boolean) => {
    this.props.dispatch(actions.showApplicationGridView(show));
  };

  backToOverview = (refetchApplications?: boolean) => {
    this.setState({
      detailsDrawerOpen: false,
      selectedCategory: undefined,
      cloneApplicationSelected: false,
    });

    // fetch applications when drawer was saved
    if (refetchApplications) this.getApplications();
    else {
      // check if an emtpy application is existing
      const empty = this.findEmptyApplication();
      if (empty) this.deleteApplication(empty.item_application_id);
    }

    utils.triggerHj(hotjarTriggers.application_overview);
  };

  updateUniversalPart = (value: number) => {
    const { selectedItem } = this.props;

    const updatedItem = { ...selectedItem, universal_part: value };
    this.props.dispatch(catalougeActions.updateItem(selectedItem.id, updatedItem));

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'application_universal_product',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  };

  updateApplicationBundle = (itemBases: BundleItem[]) =>
    this.props.dispatch(actions.updateApplicationBundle(itemBases));

  unlinkItem = (itemId: number) => {
    const { parentApplicationItemBases } = this.props;
    const itemBase = parentApplicationItemBases.find(b => b.base_item_id === itemId);
    this.props.dispatch(actions.removeApplicationLinks([itemId], [itemBase!.id]));
  };

  removeBundleItems = (baseIds: number[]) => {
    this.props.dispatch(actions.removeBundleItem(baseIds));
  };

  overview = () => {
    const {
      isFetchingFullItem,
      fetchingBundles,
      parentApplicationItemBases,
      showApplicationGridView,
    } = this.props;
    if (!showApplicationGridView && fetchingBundles)
      return (
        <div className="mt-10">
          <Spin className="spinner-center" />
        </div>
      );
    return (
      <ApplicationOverview
        user={this.props.user}
        keywords={this.state.keywords}
        isReceiver={this.props.isReceiver}
        isManufacturer={this.props.isManufacturer}
        applications={this.props.structuredApplications}
        applicationOrder={this.props.applicationOrder}
        baseItemApplications={this.props.baseItemApplications}
        oeItemApplications={this.props.oeItemApplications}
        applicationBundleItems={this.props.applicationBundleItems}
        selectedItem={!isFetchingFullItem ? this.props.selectedItem : {}}
        resources={this.props.resources}
        fetching={this.props.fetchingApplications || isFetchingFullItem || fetchingBundles}
        fetchingFullItem={isFetchingFullItem}
        showApplicationListView={this.props.showApplicationListView}
        showApplicationGridView={this.props.showApplicationGridView}
        fetchNextApplications={this.fetchNextApplications}
        filterApplications={this.getApplications}
        handleKeywordsChange={this.handleKeywordsChange}
        selectApplication={this.selectApplication}
        selectListApplication={this.selectListApplication}
        cloneApplication={this.cloneApplication}
        createApplication={this.createApplication}
        deleteApplication={this.deleteApplication}
        deleteApplications={this.deleteApplications}
        updateUniversalPart={this.updateUniversalPart}
        updateApplicationBundle={this.updateApplicationBundle}
        unlinkItem={this.unlinkItem}
        removeBundleItems={this.removeBundleItems}
        showListView={this.showListView}
        showGridView={this.showGridView}
        analyses={this.props.analysisBySegment}
        analysisGroups={this.props.analysisGroups}
        defaultQualifiers={this.props.defaultQualifiers}
        defaultNotes={this.props.defaultNotes}
        parentApplicationItemBases={parentApplicationItemBases}
      />
    );
  };

  view = () => {
    const { selectedItem, selectedApplicationId, fetchingApplications, showApplicationGridView } =
      this.props;

    if (selectedItem) {
      return (
        <div className="application">
          {this.overview()}

          {showApplicationGridView ? (
            <ApplicationGridEditDrawer
              visible={this.state.detailsDrawerOpen}
              createType={this.state.createApplicationType}
              clone={this.state.cloneApplicationSelected}
              selectedApplicationId={!fetchingApplications ? selectedApplicationId : null}
              selectedItem={selectedItem}
              hideActionButtons={this.state.listApplicationSelected}
              keywords={this.state.keywords}
              backToOverview={this.backToOverview}
            />
          ) : (
            <ApplicationEditDrawer
              visible={this.state.detailsDrawerOpen}
              createType={this.state.createApplicationType}
              selectedApplicationId={!fetchingApplications ? selectedApplicationId : null}
              initialSelectedCategory={this.state.selectedCategory}
              selectedItem={selectedItem}
              hideActionButtons={this.state.listApplicationSelected}
              backToOverview={this.backToOverview}
              createApplication={this.createApplication}
              selectNextApplication={this.selectNextApplication}
              selectPreviousApplication={this.selectPreviousApplication}
            />
          )}
        </div>
      );
    }
  };

  render() {
    const { selectedItems, initialAccountResourcesFetching, errorApplicationResources } =
      this.props;

    if (selectedItems.length > 1 || selectedItems.length === 0) {
      return <ItemInfo items={this.props.selectedItems} />;
    }

    if (initialAccountResourcesFetching)
      return (
        <div className="mt-10">
          <Spin className="spinner-center" />
        </div>
      );

    if (errorApplicationResources)
      return (
        <div className="m-10 flex">
          <Alert
            message={constants.resourcesError}
            description={constants.resourcesErrorInfo}
            type="error"
            action={
              <Button
                className="ml-7"
                icon={<SyncOutlined />}
                onClick={() => this.props.dispatch(fetchApplicationResources(true))}
              >
                {constants.retry}
              </Button>
            }
            showIcon
          />
        </div>
      );

    return this.view();
  }
}

function mapStateToProps(state: ApplicationState) {
  const selectedItems = getSelectedItems(state);
  return {
    user: state.user.user,
    isReceiver: state.user.user && isReceiver(state.user.user),
    isManufacturer: state.user.user && isManufacturer(state.user.user),
    analysisBySegment: extendedAnalysesBySegment(state),
    analysisGroups: applicationAnalysesGroups(state),
    initialAccountResourcesFetching: state.resources.initialAccountResourcesFetching,
    errorApplicationResources: state.resources.errorApplicationResources,
    isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
    fetchingApplications: state.items.application.fetchingApplications,
    fetchingBundles: state.items.application.fetchingBundles,
    structuredApplications: structuredApplications(state),
    selectedItems,
    selectedItem: selectedItems[0],
    applications: state.items.application.applications,
    applicationOrder: state.items.application.applicationOrder,
    baseItemApplications: structuredLinkedApplications(state),
    oeItemApplications: structuredOeApplications(state),
    selectedApplicationId: state.items.application.selectedApplicationId,
    showApplicationListView: state.items.application.showApplicationListView,
    showApplicationGridView: state.items.application.showApplicationGridView,
    applicationBundleItems: bundleItemsApplicationIndexed(state),
    parentApplicationItemBases: state.items.application.parentApplicationItemBases,
    defaultNotes: state.items.application.defaultNotes,
    defaultQualifiers: state.items.application.defaultQualifiers,
    resources: state.resources.applicationVehicle,
  };
}

export { ApplicationContainer };
export default withRouter(connect(mapStateToProps)(withContainerWrapper(ApplicationContainer)));
