// End point URL for Power BI API
export const powerBiApiUrl = 'https://api.powerbi.com/';

// Id of the workspace where the report is hosted
export const workspaceId = 'efd28b90-baa8-43dc-bf98-8b5cf105c876';

// Id of the report to be embedded
export const reportId = '8644ea7b-21f2-4b27-99a8-7db71e4c0980';
export const pdmSalesReportId = '7ed9e143-204a-4db5-8c11-28a3381cf5b6';
export const reportIdUserTest = 'd17062e7-f55f-4cb8-a256-2706e900ed32';
export const holleyAccountReportId = 'e2c63d83-47ac-4884-b745-f09ac8c2b997';
export const holleyAccountId = 3146;
