import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../components/global/ErrorBoundary';
import { withContainerWrapper } from './ContainerWrapper';

class BrandRouteWrapper extends React.Component {
  render() {
    const { isInitialized, isFilterInitialized, isSyncing, t } = this.props;
    if (!isInitialized || !isFilterInitialized) return null;
    return (
      <ErrorBoundary>
        {isSyncing && (
          <div className="flex items-center justify-center h-full">
            <div className="flex-col items-center">
              <Spin />
              <div className="mt-2">{t('publishData:publishInfo')}</div>
            </div>
          </div>
        )}
        <div className={`h-full w-full ${isSyncing ? 'hidden' : ''}`}>{this.props.children}</div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  isInitialized: state.brand.currentBrand.isInitialized,
  isFilterInitialized: state.brand.currentBrand.isFilterInitialized,
  isSyncing: state.brand.synchronisation.isSyncing,
});

const ConnectedContainerBrandWrapper = connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(BrandRouteWrapper))
);

export const withBrandRouteWrapper = Component => props =>
  (
    <ConnectedContainerBrandWrapper>
      <Component {...props} />
    </ConnectedContainerBrandWrapper>
  );

export default ConnectedContainerBrandWrapper;
