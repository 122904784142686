import { Checkbox, Button } from 'antd';
import React from 'react';

import { withTranslation } from 'react-i18next';
import { localDateString, localTimeString } from '../../../utils/Utils';
import AntTable from '../../global/AntTable';
import ChannelUpdate from './column/ChannelUpdate';
import translationConstants from '../../../constants/ChannelTranslation.json';

class BrandNestedTable extends React.Component {
  pushChannelUpdate = planId => {
    this.props.pushChannelUpdate(planId);
  };

  getRowClassname = record => {
    return record.disabled ? 'disabledTableRow' : '';
  };

  getProfileData = () => {
    const {
      record: {
        plans,
        channel: { id: channelId, default_file: defaultFile, default_schedule: defaultSchedule },
      },
      brands,
      deliveryFrequencies = [],
      fileTypes = [],
      selectedPlans,
      handlePlanSelect,
      handleEditPlan,
      handlePlanUnselect,
      downloadChannelUpdate,
      submissionTypes = [],
      allAccessableBrands,
      t,
    } = this.props;

    const getFrequencyById = i => deliveryFrequencies.find(({ id }) => id === i);
    const getSubmissionById = i => submissionTypes.find(({ id }) => id === i);
    const getFileTypeById = i => fileTypes.find(({ id }) => id === i);

    return plans.map(plan => {
      const {
        id,
        name: planName,
        file_type_id: fileTypeId,
        filter_names: filterNames,
        file,
        schedule,
        export_delivered_at: exportDeliveredAt,
        brand_ids: planBrandIds,
      } = plan;
      const fileData = file || defaultFile.find(ft => ft.file_type_id === fileTypeId) || {};
      const submissionTypeId = fileData?.export_options?.find(
        option => option.option_id === 46
      )?.parameters;
      const scheduleData = schedule || defaultSchedule || {};
      const frequency = getFrequencyById(scheduleData.delivery_frequency_id);
      const submissionType = submissionTypeId && getSubmissionById(submissionTypeId);
      const fileType = getFileTypeById(fileTypeId);
      const brand = brands.find(brand => planBrandIds && planBrandIds[0] === brand.id) || {};
      const unauthorizedBrand = !Object.keys(brand).length
        ? allAccessableBrands.find(brand => planBrandIds && planBrandIds[0] === brand.id) || {}
        : {};
      const disabled = !!unauthorizedBrand.name;

      const disablePlanEdit = this.props.record.channel.integration_type_id === 8;

      return {
        key: `${id}_${channelId}`,
        [translationConstants.brand]:
          brand.name || (unauthorizedBrand.name ? `${unauthorizedBrand.name} (unauthorized)` : ''),
        [translationConstants.plan]: <span>{planName}</span>,
        [translationConstants.file]: fileType ? fileType.name : '-',
        [translationConstants.filter]: filterNames ? filterNames.join(', ') : '-',
        [translationConstants.frequency]: `${frequency ? frequency.name : '-'} (${
          submissionType ? submissionType.name : '-'
        })`,
        [translationConstants.lastPush]: plan.pushing
          ? t('channelPusher:pushing')
          : `${localDateString(exportDeliveredAt)} - ${localTimeString(exportDeliveredAt)}`,
        [translationConstants.updatesAvailable]:
          disabled && !disablePlanEdit ? (
            <div className="channel-update">
              <Button size="small" onClick={() => this.props.handlePlanDelete(channelId, id)}>
                {translationConstants.plans.delete}
              </Button>
            </div>
          ) : (
            <div>{plan.items_count || '-'}</div>
          ),
        actions: (
          <ChannelUpdate
            channel={this.props.record.channel}
            plan={plan}
            integrationTypeId={this.props.record.channel.integration_type_id}
            downloadChannelUpdate={downloadChannelUpdate}
            pushChannelUpdate={this.pushChannelUpdate}
            editPlan={handleEditPlan}
            selectedPlans={selectedPlans}
            downloadDisabled={brand.downloadDisabled}
            disablePlanEdit={disablePlanEdit}
          />
        ),
        [translationConstants.pushSelected]: (
          <Checkbox
            value={id}
            checked={selectedPlans.includes(id)}
            onChange={({ target: { value, checked } }) =>
              checked ? handlePlanSelect(value) : handlePlanUnselect(value)
            }
            disabled={disabled}
          />
        ),
        disabled,
      };
    });
  };

  render() {
    const columns = [
      { name: translationConstants.brand },
      { name: translationConstants.plan },
      { name: translationConstants.file },
      { name: translationConstants.filter },
      { name: translationConstants.frequency },
      { name: translationConstants.lastPush },
      { name: translationConstants.updatesAvailable, align: 'right', width: '150px' },
      { name: 'actions', hideHeader: true, width: '140px' },
      {
        name: translationConstants.pushSelected,
        align: 'right',
        hideHeader: true,
        width: '16px',
      },
    ];

    const dataSource = this.getProfileData();
    return (
      <AntTable
        className="channel__plan-table"
        columns={columns}
        rowClassName={this.getRowClassname}
        dataSource={dataSource}
        pagination={false}
        size="small"
      />
    );
  }
}

export default withTranslation()(BrandNestedTable);
