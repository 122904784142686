import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import analyseConstants from '../../../constants/AnalyseConstants.json';
import analyseTranslation from '../../../constants/AnalyseTranslation.json';

const generateDataSet = progress => ({
  labels: ['Today'],
  datasets: [
    {
      type: 'bar',
      label: 'Average Progress',
      backgroundColor: analyseConstants.color_code.lightGreenOpacity,
      hoverBackgroundColor: analyseConstants.color_code.lightGreenOpacity,
      borderColor: analyseConstants.color_code.lightGreenOpacity,
      borderWidth: 1,
      data: [progress],
    },
  ],
});

const options = {
  scales: {
    yAxes: [
      {
        offset: true,
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          min: 0,
          max: 100,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        gridLines: {
          display: false,
        },
        ticks: {
          color: analyseConstants.color_code.grey,
        },
      },
    ],
  },
  mirror: true,
  layout: {
    padding: {
      top: 20,
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
    titleMarginBottom: 8,
    callbacks: {
      label(tooltipItems) {
        return ['Average', `Progress: ${tooltipItems.yLabel}%`];
      },
    },
  },
  responsive: false,
  maintainAscpectRatio: false,
};

class AnalyseAverage extends React.Component {
  render() {
    let data = generateDataSet(0);
    if (this.props.progress) {
      data = generateDataSet(this.props.progress);
    }

    return (
      <div className="analyse-average analyse__border">
        <p className="title home-container__analyse-title">
          {analyseTranslation.progress}
          <Tooltip title={analyseTranslation.tooltip.progress}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </p>
        <div>
          <Bar data={data} options={options} width={155} height={240} />
        </div>
      </div>
    );
  }
}

export default AnalyseAverage;
