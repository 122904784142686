import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, Form } from 'antd';
import { useFormikContext } from 'formik';
import { SelectValue } from 'antd/lib/select';
import FormSelect from '../../global/Forms/FormSelect';
import { ApplicationState } from '../../../reducers';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';

type ExportSelectPartsProps = {
  handleCurrentStep: () => void;
};

const ExportSelectParts: React.FC<ExportSelectPartsProps> = props => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();
  const { customFilters } = useSelector((state: ApplicationState) => {
    return {
      customFilters: getAllCustomFilters(state),
    };
  });

  const handleDatabaseSelection = (exportSchemaId: number) => {
    setFieldValue('exportSchemaId', exportSchemaId);
  };

  const handleSelection = (selection: string) => {
    switch (selection) {
      case 'all': {
        setFieldValue('selection', selection);
        setFieldValue('populated', 0);
        setFieldValue('filterId', null);
        props.handleCurrentStep();
        break;
      }
      case 'filter': {
        setFieldValue('selection', selection);
        setFieldValue('populated', 0);
        break;
      }
      case 'populated': {
        setFieldValue('selection', selection);
        setFieldValue('populated', 1);
        setFieldValue('filterId', null);
        props.handleCurrentStep();
        break;
      }
    }
  };

  return (
    <div className="export-select-parts">
      <Form layout="horizontal">
        <Radio.Group
          onChange={e => {
            const selection = e.target.value;
            handleSelection(selection);
          }}
          value={values.selection}
          className="select-options"
        >
          <Radio key="all" value="all" className="radio-style" data-testid="all">
            {t('exporter:allParts')}
          </Radio>
          <Radio key="populated" value="populated" className="radio-style">
            {t('exporter:allPopulatedParts')}
          </Radio>
          <div className="flex flex-row">
            <Radio key="filter" value="filter" className="radio-style">
              {t('exporter:filter')}
            </Radio>
            <FormSelect
              name="filterId"
              values={customFilters}
              size="small"
              allowClear
              placeholder={t('exporter:selectFilter')}
              showSearch
              onChange={(value: SelectValue) => {
                setFieldValue('filterId', value);
                setFieldValue('selection', 'filter');
                if (value) props.handleCurrentStep();
              }}
            />
          </div>
        </Radio.Group>
        <Radio.Group
          onChange={e => handleDatabaseSelection(e.target.value)}
          value={values.exportSchemaId}
          className="database-selection"
        >
          <Radio key={1} value={1} className="radio-style">
            {t('exporter:stagingDatabase')}
          </Radio>
          <Radio key={2} value={2} className="radio-style">
            {t('exporter:receiverDatabase')}
          </Radio>
        </Radio.Group>
      </Form>
    </div>
  );
};

export default ExportSelectParts;
