import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditChannelTable from './EditChannelTable';
import EditChannelProductDataDrawer from './EditChannelProductDataDrawer';
import {
  ChannelColumn,
  ChannelData,
  ChannelTemplate,
  ShopifyChannel,
} from '../../../../types/channel';
import { ApplicationState } from '../../../reducers';
import { updateChannelShopify } from '../../../actions/channel/update';
import { AsyncDispatch } from '../../../../types/global';
import {
  fetchChannelShopifyTemplate,
  fetchShopifyChannelBrands,
} from '../../../actions/channel/fetch';
import { fetchExportBuilderAdvancedSegments } from '../../../actions/brand/export_builder_advanced/fetch';
import { segmentsMappedWithOptions } from '../../../selectors/export_builder_advanced/exportBuilderAdvancedSelector';
import { getMappedTempStatus } from '../../../selectors/channel/channelSelector';

type ShopifyProductTableProps = {
  channelType: string;
  shopifyId: number;
  handleNavigation: () => void;
};

const ShopifyProductTable: React.FC<ShopifyProductTableProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const [brandName, setBrandName] = React.useState<string | undefined>();
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [searchKey, setSearchkey] = React.useState('');

  const {
    fetchingChannelShopify,
    template,
    templateColumns,
    preview,
    segments,
    fetchingApplicationResources,
    fetchingSegments,
    fetchingTemplate,
    updatingShopify,
    shopifyData,
    shopifyChannel,
  } = useSelector((state: ApplicationState) => {
    const shopifyChannel = state.channel.channels.channelShopifyData.channels?.find(
      (d: ShopifyChannel) => d.id === props.shopifyId
    );
    return {
      shopifyChannel,
      shopifyData: getMappedTempStatus(shopifyChannel.brands),
      fetchingChannelShopify: state.channel.channels.fetchingChannelShopify,
      template: (state.channel.channels.channelShopifyTemplate?.template || {}) as ChannelTemplate,
      templateColumns: (state.channel.channels.channelShopifyTemplate?.columns ||
        []) as ChannelColumn[],
      preview: (state.channel.channels.channelShopifyTemplate.preview || []) as {
        [key: string]: string;
      }[],
      segments: segmentsMappedWithOptions(state) || [],
      fetchingApplicationResources: state.resources.fetchingApplicationResources,
      fetchingSegments: state.brand.exportBuilderAdvanced.fetchingSegments,
      fetchingTemplate: state.channel.channels.fetchingChannelShopifyTemplate,
      updatingShopify: state.channel.channels.updatingShopify,
    };
  });

  const handleShowProductDataDrawer = (brandName: string, brandId: number) => {
    setBrandName(brandName);
    setBrandId(brandId);
    dispatch(fetchChannelShopifyTemplate(brandId));
    dispatch(fetchExportBuilderAdvancedSegments(brandId));
  };

  const handleBrandSearch = (keywords: string | '') => {
    setSearchkey(keywords);
    dispatch(fetchShopifyChannelBrands(keywords, shopifyChannel.id));
  };

  const updateChannelShopifyValues = (updatedRecord: ChannelData) => {
    const updatedValues = [updatedRecord];
    const updatedShopifyRecord = {
      ...shopifyChannel!,
      brands: shopifyChannel!.brands.map((brand: ChannelData) => {
        if (brand.brand_id === updatedRecord.brand_id) return updatedRecord;
        return brand;
      }),
    };
    dispatch(updateChannelShopify(updatedValues, updatedShopifyRecord)); // direct save
  };

  return (
    <React.Fragment>
      <div className="h-full overflow-hidden flex-1 overflow-auto mx-10 my-10">
        <EditChannelTable
          data={shopifyData}
          handleShowProductDataDrawer={handleShowProductDataDrawer}
          updateChannel={updateChannelShopifyValues}
          handleBrandSearch={handleBrandSearch}
          searchKey={searchKey}
          fetchingChannel={fetchingChannelShopify}
          channelType={props.channelType}
          updatePending={updatingShopify}
          shopifyStatus={shopifyChannel.shop_status}
          handleNavigation={props.handleNavigation}
        />
      </div>
      <EditChannelProductDataDrawer
        onClose={() => (brandName ? setBrandName(undefined) : '')}
        title={`${brandName || ''} - ${t('channel:productData')}`}
        visible={!!brandName}
        brandId={brandId}
        template={template}
        templateColumns={templateColumns}
        segments={segments}
        fetchingApplicationResources={fetchingApplicationResources}
        fetchingSegments={fetchingSegments}
        fetchingTemplate={fetchingTemplate}
        preview={preview}
        channelType={props.channelType}
      />
    </React.Fragment>
  );
};

export default ShopifyProductTable;
