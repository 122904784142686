import React from 'react';
import { Popover, Card, Button, Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import TextArea from 'antd/lib/input/TextArea';

type PackageDescriptionPopoverProps = {
  description: string;
  type: string;
  handleChange: (description: string) => void;
  onClose?: () => void;
  maxLength?: number;
  className: string;
};

const PackageDescriptionPopover: React.FC<PackageDescriptionPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => {
        if (!visible && props.onClose) props.onClose();
        setPopoverVisible(visible);
      }}
      overlayClassName="help package_popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        popoverVisible ? (
          <Card className="package-popover__form" onClick={e => e.stopPropagation()}>
            <Formik
              initialValues={{
                description: props.description || '',
              }}
              onSubmit={values => {
                props.handleChange(values.description);
                if (props.onClose) props.onClose();
                setPopoverVisible(false);
              }}
            >
              {({ handleSubmit, setFieldValue, values, dirty }) => (
                <React.Fragment>
                  <Form layout="vertical">
                    <Form.Item label={props.type}>
                      <TextArea
                        autoFocus={!values.description}
                        value={values.description}
                        onChange={e => setFieldValue('description', e.target.value)}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={props.maxLength ? props.maxLength : undefined}
                        showCount={!!props.maxLength}
                      />
                    </Form.Item>
                  </Form>

                  <div className="flex-col items-end mt-2">
                    <div>
                      <Button
                        onClick={e => {
                          if (props.onClose) props.onClose();
                          setPopoverVisible(false);
                          e.stopPropagation();
                        }}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={e => {
                          handleSubmit();
                          e.stopPropagation();
                        }}
                        disabled={!dirty}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Formik>
          </Card>
        ) : null
      }
      trigger="click"
    >
      {props.description ? (
        <Tooltip title={props.description}>
          <div
            className={`package-description ${props.className}`}
            onClick={e => {
              setPopoverVisible(true);
              e.stopPropagation();
            }}
          >
            {props.description}
          </div>
        </Tooltip>
      ) : (
        <div className="package__suggest-button items-center">
          <Button
            type="dashed"
            size="small"
            onClick={e => {
              setPopoverVisible(true);
              e.stopPropagation();
            }}
          >
            {t('common:add')}
          </Button>
        </div>
      )}

      {!props.type && props.children}
    </Popover>
  );
};

export default PackageDescriptionPopover;
