import { Empty, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { ChannelData, ShopifyStatus } from '../../../../types/channel';
import ChannelBrandTitleSearch from './ChannelBrandTitleSearch';
import ChannelTitleWithInfo from './ChannelTitleWithInfo';
import ChannelTableColPublishBrand from './ChannelTableColPublishBrand';
import ChannelTableColProductSync from './ChannelTableColProductSync';
import { ChannelTypesWithBrandConnectivity } from '../../../constants/ChannelConstants';
import ChannelBrandStatus from './ChannelBrandStatus';
import AntSelect from '../../global/AntSelect';

type EditChannelTableProps = {
  data: ChannelData[];
  handleShowProductDataDrawer: (brandName: string, brandId: number) => void;
  updateChannel: (updatedValues: ChannelData) => void;
  handleBrandSearch: (keywords: string) => void;
  searchKey: string;
  fetchingChannel: boolean;
  channelType?: string;
  updatePending: boolean;
  shopifyStatus?: ShopifyStatus;
  handleNavigation?: () => void;
};

const EditChannelTable: React.FC<EditChannelTableProps> = props => {
  const { t } = useTranslation();

  const handleOnSearch = (keywords: string) => {
    props.handleBrandSearch(keywords);
  };

  const getConnect = (connect: number, record: ChannelData) => {
    if (connect === 1) return 1;
    if (!record.statusId) return null;
    return 0;
  };

  const [updatingBrands, setUpdatingBrands] = React.useState<number[]>([]);

  const handleConnect = (connect: number, record: ChannelData) => {
    setUpdatingBrands([...updatingBrands, record.brand_id]);
    if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR) {
      const updatedRecord =
        connect === 1 && !record.send_fitment_data && !record.send_product_data
          ? {
              ...record,
              connect: 1,
              send_fitment_data: 1,
              send_product_data: 1,
            }
          : {
              ...record,
              connect: getConnect(connect, record),
            };
      props.updateChannel(updatedRecord);
    }
    if (
      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELNPW ||
      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELWALMART ||
      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELSHOPIFY
    ) {
      const updatedRecord = {
        ...record,
        connect: getConnect(connect, record),
      };
      props.updateChannel(updatedRecord);
    }
  };

  const handleCheck = (checked: boolean, record: ChannelData, name: string) => {
    const updatedRecord = { ...record, [name]: checked ? 1 : 0 };
    props.updateChannel(updatedRecord);
  };

  const getStatusInfoText = () => {
    if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR)
      return t('channel:channelAdvisorInfoText');
    if (props.channelType === ChannelTypesWithBrandConnectivity.CHANNELNPW)
      return t('channel:channelNPWInfoText');
    return t('channel:channelWalmartInfoText');
  };

  React.useEffect(() => {
    if (!props.updatePending) setUpdatingBrands([]);
  }, [props.updatePending]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <Table
            headerClassName="edit-channel-table__header"
            rowClassName={({ index }: { index: number }) =>
              (index !== -1 && 'edit-channel-table_row') || ''
            }
            className="edit-channel-table"
            headerHeight={40}
            height={height - 80}
            width={width - 2}
            rowCount={props.data?.length || 0}
            dataSource={props.data ? props.data : []}
            rowHeight={40}
            rowGetter={({ index }) => props.data[index]}
            noRowsRenderer={() =>
              props.fetchingChannel ? (
                <Spin className="spinner-center" style={{ marginTop: '20px' }} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
          >
            <Column
              width={300}
              flexGrow={1}
              dataKey="brand_name"
              headerRenderer={() => (
                <div className="mr-4">
                  <ChannelBrandTitleSearch
                    title={t('common:brand')}
                    handleOnSearch={handleOnSearch}
                    searchKey={props.searchKey}
                  />
                </div>
              )}
            />
            <Column
              headerRenderer={() => t('channel:filter')}
              width={250}
              flexGrow={1}
              dataKey="filter_id"
              cellRenderer={({ rowData, rowIndex }) => {
                const filters = props.data[rowIndex].filters || [];
                return (
                  <AntSelect
                    className="edit-channel-table__selector"
                    clearContainer
                    elements={filters}
                    value={rowData.filter_id}
                    onChange={value => {
                      props.updateChannel({
                        ...rowData,
                        filter_id: value === undefined ? null : Number(value),
                      });
                    }}
                    size="small"
                    showSearch
                    allowClear
                  />
                );
              }}
            />
            {props.channelType !== ChannelTypesWithBrandConnectivity.CHANNELWALMART && (
              <Column
                width={220}
                flexGrow={1}
                headerRenderer={() => (
                  <ChannelTitleWithInfo
                    title={
                      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR
                        ? t('channel:sendProductData')
                        : t('channel:syncData')
                    }
                    infoText={
                      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR
                        ? t('channel:sendProductDataInfoText')
                        : undefined
                    }
                  />
                )}
                dataKey="send_product_data"
                cellRenderer={({ rowData }) => (
                  <ChannelTableColProductSync
                    checked={
                      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR
                        ? rowData.send_product_data === 1 || rowData.send_product_data === 2
                        : undefined
                    }
                    handleCheckboxChange={
                      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR
                        ? () => {
                            handleCheck(
                              rowData.send_product_data !== 1,
                              rowData,
                              'send_product_data'
                            );
                          }
                        : undefined
                    }
                    handleOnClick={() =>
                      props.handleShowProductDataDrawer(rowData.brand_name, rowData.brand_id)
                    }
                    displayCheckbox={
                      props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR
                    }
                  />
                )}
              />
            )}
            {props.channelType === ChannelTypesWithBrandConnectivity.CHANNELADVISOR && (
              <Column
                width={200}
                flexGrow={1}
                headerRenderer={() => (
                  <ChannelTitleWithInfo
                    title={t('channel:sendFitmentData')}
                    infoText={t('channel:sendFitmentDataInfoText')}
                  />
                )}
                dataKey="send_fitment_data"
                cellRenderer={({ rowData }) => (
                  <ChannelTableColProductSync
                    checked={rowData.send_fitment_data === 1}
                    handleCheckboxChange={() =>
                      handleCheck(rowData.send_fitment_data !== 1, rowData, 'send_fitment_data')
                    }
                    displayCheckbox
                  />
                )}
              />
            )}
            {props.channelType !== ChannelTypesWithBrandConnectivity.CHANNELSHOPIFY && (
              <Column
                dataKey="accepted_by_channeld_advisor"
                width={150}
                flexGrow={1}
                headerRenderer={() => (
                  <ChannelTitleWithInfo title={t('common:status')} infoText={getStatusInfoText()} />
                )}
                cellRenderer={({ rowData }) => <ChannelBrandStatus statusId={rowData.statusId} />}
              />
            )}
            <Column
              headerClassName="edit-channel-table-col__action"
              width={180}
              dataKey="action"
              headerRenderer={() => <div>{t('common:action')}</div>}
              cellRenderer={({ rowData }) => (
                <div className="text-right">
                  <ChannelTableColPublishBrand
                    statusId={rowData.statusId}
                    handleConnect={connect => handleConnect(connect, rowData)}
                    connectAndReject={
                      !rowData.authorized_by_brand_at &&
                      !!rowData.authorized_by_receiver_at &&
                      !rowData.declined_by_brand_at
                    }
                    showSpinner={updatingBrands.includes(rowData.brand_id)}
                    shopifyStatus={props.shopifyStatus}
                    handleNavigation={props.handleNavigation}
                  />
                </div>
              )}
            />
          </Table>
        </div>
      )}
    </AutoSizer>
  );
};

export default EditChannelTable;
