import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TextEditor from '../../global/TextEditor';
import { DescriptionContent } from '../../../../types/description';
import { DescriptionTypeElement } from '../../../../types/resources';
import { UNORDERD_LIST_TYPE } from '../../../constants/DescriptionConstants';

type ContentEditorProps = {
  contents: DescriptionContent[];
  typeId: number;
  onChange: (contents: DescriptionContent[]) => void;
  listDescriptionTypes: DescriptionTypeElement[];
};

const ContentEditor: React.FC<ContentEditorProps> = props => {
  const { t } = useTranslation();
  const [maxLength, setMaxLength] = React.useState<number>();

  React.useEffect(() => {
    const type = props.listDescriptionTypes.find(type => type.id === props.typeId);
    setMaxLength(type?.max_length);
  }, [props.listDescriptionTypes, props.typeId]);

  return (
    <div
      className={classNames('description__content-editor_position', {
        'description__content-editor__small': maxLength && maxLength < 240,
      })}
    >
      <TextEditor
        autoSubmit
        contents={props.contents.map(c => ({
          content: c.content,
          type: props.typeId === UNORDERD_LIST_TYPE.FABID ? 'list-item' : 'block',
        }))}
        placeholder={t('description:addDescriptionText')}
        submit={(val: any) => {
          const newContents = val.map((entry: any, index: number) => ({
            content: entry.content,
            id: entry.id,
            typeId: entry.type === 'list-item' ? 1 : 2,
            record_number: index + 1,
          }));
          props.onChange(newContents);
        }}
        maxLength={maxLength}
        allowLimitExceeding
      />
    </div>
  );
};

export default ContentEditor;
