import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import * as Yup from 'yup';
import { Button, Spin, Tooltip } from 'antd';
import { ApplicationState } from '../../../reducers';
import { DefaultValue } from '../../../../types/brand_settings';
import PageFormik, { CustomFormikActions } from '../../global/page/PageFormik';
import { AsyncDispatch } from '../../../../types/global';
import PackageTable from './PackageTable';
import { HazardousMaterial, MultiValueFields, Package } from '../../../../types/itemPackage';
import { ExtendedResources } from '../../../../types/resources';
import { generateUUID } from '../../../utils/Utils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { updatePackage } from '../../../actions/items/package/update';
import LabelSwitch from '../../global/LabelSwitch';
import { fetchBrandUsedPackageFields } from '../../../actions/items/package/fetch';
import NoDataInfo from '../../global/NoDataInfo';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { hasPermission } from '../../../utils/Permissions';
import { PackageFields } from '../../../constants/PackageConstants';

type PackagePageProps = {
  fetchingPackages: boolean;
  multipleValueFields?: MultiValueFields[];
  extendedItemPackages: Package[];
  selectedBrandId: number;
};

const PackagePage: React.FC<PackagePageProps> = ({
  fetchingPackages,
  multipleValueFields,
  extendedItemPackages,
  selectedBrandId,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItems,
    selectedItemIds,
    allSelectedItemIds,
    uoms,
    itemPackages,
    defaultPkgUom,
    defaultPkgDimUom,
    defaultPkgWtUom,
    brandUsedFields,
    canMaintainProduct,
  } = useSelector((state: ApplicationState) => ({
    selectedItems: getSelectedItems(state),
    selectedItemIds: state.catalogue.catalogue.selectedItemIds,
    allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
    uoms: state.resources.data.package.uoms as ExtendedResources[],
    itemPackages: state.items.packageSeg.itemPackages,
    defaultPkgUom: state.settings.defaultValues.find(
      (defValue: DefaultValue) => defValue.resource_table === 'package_uoms'
    ),
    defaultPkgDimUom: state.settings.defaultValues.find(
      (defValue: DefaultValue) => defValue.resource_table === 'package_dimension_uoms'
    ),
    defaultPkgWtUom: state.settings.defaultValues.find(
      (defValue: DefaultValue) => defValue.resource_table === 'package_weight_uoms'
    ),
    brandUsedFields: state.items.packageSeg.brandUsedFields,
    canMaintainProduct: hasPermission(state.user.user, 'can_maintain_products'),
  }));

  const [showAllFields, setShowAllFields] = React.useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = React.useState<(string | number)[]>([]);
  const [editedFields, setEditedFields] = React.useState<string[]>([]);
  const [editedUoms, setEditedUoms] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (selectedItemIds.length > 0) {
      setExpandedKeys([]);
    }
  }, [selectedItemIds]);

  React.useEffect(() => {
    if (extendedItemPackages.length === 1) {
      setExpandedKeys([extendedItemPackages[0]!.id]);
    }
  }, [extendedItemPackages, allSelectedItemIds]);

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError, resetForm } = formikActions;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'package',
      part_number: selectedItems.map(i => i.part_number).join(', '),
      brand_code: selectedItems[0]?.brand_code,
    });

    setSubmitPending();
    if (selectedItemIds.length === 1) {
      // single item update
      const updateItemPackage = selectedItemIds.map(itemId => ({
        item_id: itemId,
        packages: values.extendedItemPackages.map((pkg: Package) => {
          // always check for existing uom id , if exists update id, unique_key, value_key with same to avoid creating duplicates
          // the new go api does not use unique_key and value_key
          const existingPkg = itemPackages[0]?.packages.find(iPkg => iPkg.uom_id === pkg.uom_id);
          return {
            ...pkg,
            id: existingPkg?.id || null,
            ...(existingPkg?.unique_key && { unique_key: existingPkg?.unique_key }),
            ...(existingPkg?.value_key && { value_key: existingPkg?.value_key }),
            hazardous_materials: pkg.hazardous_materials.map(hz =>
              hz.temp ? { ...hz, id: null } : hz
            ),
          };
        }),
      }));
      dispatch(updatePackage(updateItemPackage))
        .then(response => {
          if (
            expandedKeys.length > 0 &&
            selectedItemIds.length === 1 &&
            response.action.payload.data.length > 0
          ) {
            const responsePkg = response.action.payload.data[0].packages;
            const tempPkgs = values.extendedItemPackages.filter((u: Package) => u.temp);
            const tempIds = tempPkgs
              .map((p: Package) => p.id)
              .find((pId: any) => expandedKeys.includes(pId));
            let replacedKeys: any[] = [];
            if (tempIds) {
              replacedKeys = responsePkg
                .filter((pk: Package) => tempPkgs.find((tp: Package) => tp.uom_id === pk.uom_id))
                .map((fp: Package) => fp.id);
              setExpandedKeys([...expandedKeys, ...replacedKeys]);
            }
          }
          // check for new field used
          const newFieldUsed = PackageFields.find(field =>
            updateItemPackage[0].packages.find(
              (p: any) => !!p[field] && !brandUsedFields.find(f => f.includes(field))
            )
          );
          if (newFieldUsed) dispatch(fetchBrandUsedPackageFields(selectedBrandId));
          setSubmitSuccess();
        })
        .catch(() => {
          setSubmitError();
        });
    }

    let updatedValues: any[];
    if (allSelectedItemIds.length > 1) {
      // multi items update
      updatedValues = allSelectedItemIds.map(itemId => {
        const itemPkg = itemPackages.find(iPkg => iPkg.item_id === itemId);
        let packages: Package[] = [];
        if (itemPkg) {
          packages = itemPkg.packages
            .filter(fPkg =>
              values.extendedItemPackages
                .filter((pkgValue: Package) => !pkgValue.temp) // check only to upate values except from uom id
                .find((p: Package) => p.uom_id === fPkg.uom_id)
            )
            .map(pkg => {
              const updatedValue = values.extendedItemPackages.find(
                (p: Package) => p.uom_id === pkg.uom_id
              );
              if (editedUoms.includes(pkg.uom_id) && updatedValue) {
                return {
                  ...pkg,
                  quantity_of_eaches: editedFields.find(f => f.includes('quantity_of_eaches'))
                    ? updatedValue.quantity_of_eaches
                    : pkg.quantity_of_eaches,
                  inner_quantity: editedFields.find(f => f.includes('inner_quantity'))
                    ? updatedValue.inner_quantity
                    : pkg.inner_quantity,
                  inner_quantity_uom_id: editedFields.find(f => f.includes('inner_quantity_uom_id'))
                    ? updatedValue.inner_quantity_uom_id
                    : pkg.inner_quantity_uom_id,
                  length: editedFields.find(f => f.includes('length'))
                    ? updatedValue.length
                    : pkg.length,
                  width: editedFields.find(f => f.includes('width'))
                    ? updatedValue.width
                    : pkg.width,
                  height: editedFields.find(f => f.includes('height'))
                    ? updatedValue.height
                    : pkg.height,
                  dimensions_uom_id: editedFields.find(f => f.includes('dimensions_uom_id'))
                    ? updatedValue.dimensions_uom_id
                    : pkg.dimensions_uom_id,
                  weight: editedFields.find(f => f.includes('weight'))
                    ? updatedValue.weight
                    : pkg.weight,
                  weights_uom_id: editedFields.find(f => f.includes('weights_uom_id'))
                    ? updatedValue.weights_uom_id
                    : pkg.weights_uom_id,
                  dimensional_weight: editedFields.find(f => f.includes('dimensional_weight'))
                    ? updatedValue.dimensional_weight
                    : pkg.dimensional_weight,
                  weight_variance: editedFields.find(f => f.includes('weight_variance'))
                    ? updatedValue.weight_variance
                    : pkg.weight_variance,
                  merchandising_height: editedFields.find(f => f.includes('merchandising_height'))
                    ? updatedValue.merchandising_height
                    : pkg.merchandising_height,
                  merchandising_width: editedFields.find(f => f.includes('merchandising_width'))
                    ? updatedValue.merchandising_width
                    : pkg.merchandising_width,
                  merchandising_length: editedFields.find(f => f.includes('merchandising_length'))
                    ? updatedValue.merchandising_length
                    : pkg.merchandising_length,
                  orderable_option_id: editedFields.find(f => f.includes('orderable_option_id'))
                    ? updatedValue.orderable_option_id
                    : pkg.orderable_option_id,
                  stacking_factor: editedFields.find(f => f.includes('stacking_factor'))
                    ? updatedValue.stacking_factor
                    : pkg.stacking_factor,
                  level_gtin: editedFields.find(f => f.includes('level_gtin'))
                    ? updatedValue.level_gtin
                    : pkg.level_gtin,
                  electronic_product_code: editedFields.find(f =>
                    f.includes('electronic_product_code')
                  )
                    ? updatedValue.electronic_product_code
                    : pkg.electronic_product_code,
                  bar_code_characters: editedFields.find(f => f.includes('bar_code_characters'))
                    ? updatedValue.bar_code_characters
                    : pkg.bar_code_characters,
                };
              }
              return pkg;
            });
          return { ...itemPkg, packages };
        }
        if (!itemPkg) return { item_id: itemId, packages: [] };
      });

      // temp will be true on add new package or on change of uom id
      const newTempUoms = values.extendedItemPackages.find((p: Package) => p.temp);

      if (newTempUoms) {
        const tempPackages = values.extendedItemPackages.filter((p: Package) => p.temp);
        updatedValues = updatedValues.map(iPkg => {
          const allItemPkgs = itemPackages.find(item => item.item_id === iPkg.item_id)?.packages;
          const addedTempPkgs: any = [];
          tempPackages.forEach((tPkg: Package) => {
            const existingPkg = values.extendedItemPackages.find(
              (p: Package) => p.originalUomId === tPkg.uom_id
            );
            // check for existingPkg with originalUomId, if exists update id, unique_key, value_key with same to avoid creating duplicates
            // the new go api does not use unique_key and value_key
            const oldPkg = existingPkg
              ? allItemPkgs?.find((p: Package) => p.uom_id === existingPkg.originalUomId)
              : undefined;
            addedTempPkgs.push({
              ...tPkg,
              id: existingPkg ? oldPkg?.id : null,
              ...(oldPkg?.unique_key && { unique_key: oldPkg?.unique_key }),
              ...(oldPkg?.value_key && { value_key: oldPkg?.value_key }),
            });
          });
          return { ...iPkg, packages: [...iPkg.packages, ...addedTempPkgs] };
        });
      }

      dispatch(updatePackage(updatedValues))
        .then(() => {
          if (values.extendedItemPackages.length < 1)
            resetForm({
              values: { extendedItemPackages: [] },
            });

          // check for new field used
          const newFieldUsed = PackageFields.find(field =>
            updatedValues.find(itemPackage =>
              itemPackage.packages.find(
                (p: any) => !!p[field] && !brandUsedFields.find(f => f.includes(field))
              )
            )
          );
          if (newFieldUsed) dispatch(fetchBrandUsedPackageFields(selectedBrandId));
          setExpandedKeys(expandedKeys);
          setSubmitSuccess();
        })
        .catch(() => {
          setSubmitError();
        });
    }
  };

  const getValidationSchema = Yup.object().shape({
    extendedItemPackages: Yup.array().of(
      Yup.object().shape({
        quantity_of_eaches: Yup.number()
          .integer()
          .min(1)
          .nullable()
          .required(t('validation:required'))
          .positive(t('validation:positiveNumber')),
        uom_id: Yup.number().nullable().required(t('validation:required')),
        hazardous_materials: Yup.array().of(
          Yup.object().shape({
            language_id: Yup.number().nullable().required(t('validation:required')),
            transport_method_id: Yup.number().nullable().required(t('validation:required')),
            shipping_scope_id: Yup.number().nullable().required(t('validation:required')),
            bulk_id: Yup.number().nullable().required(t('validation:required')),
            regulating_country_id: Yup.number().nullable().required(t('validation:required')),
            regulated_option_id: Yup.number().nullable().required(t('validation:required')),
          })
        ),
      })
    ),
  });

  if (fetchingPackages) return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <PageFormik
      showNoError={!canMaintainProduct}
      showAnalysis
      enableReinitialize
      initialValues={{ extendedItemPackages }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      contentNoSpacing
      validationSchema={getValidationSchema}
    >
      {({ values, setFieldValue }) => {
        const handleUpdateHzMaterials = (hzMaterials: HazardousMaterial[], pkgIndex: number) => {
          setFieldValue(`extendedItemPackages[${pkgIndex}].hazardous_materials`, hzMaterials);
        };

        const updatePackage = (updatedPkgRecord: Package) => {
          const updatedPkgs = values.extendedItemPackages.map((pkg: Package) =>
            pkg.id === updatedPkgRecord.id ? updatedPkgRecord : pkg
          );
          setFieldValue(`extendedItemPackages`, updatedPkgs);
        };

        const getFilteredPackageUoms = (uomId: number) => {
          const selectedUoms = values.extendedItemPackages
            .map((pkg: Package) => pkg.uom_id)
            .filter((id: number) => id !== uomId);
          return uoms.filter(uom => !selectedUoms.includes(uom.id));
        };

        const handlePkgDelete = (pkgRecordId: number | string) => {
          setFieldValue(
            `extendedItemPackages`,
            values.extendedItemPackages.filter((pkg: Package) => pkg.id !== pkgRecordId)
          );

          intercomEvent('viewed-all-product', {
            action: 'item-deleted',
            location: 'package',
            part_number: selectedItems.map(i => i.part_number).join(', '),
            brand_code: selectedItems[0]?.brand_code,
          });
        };

        const handleAddNewPackage = () => {
          const uuid = generateUUID();
          const existingUomIds = values.extendedItemPackages.map((pkg: Package) => pkg.uom_id);
          const newPackage = {
            id: uuid,
            uom_id:
              values.extendedItemPackages.length < 1
                ? (defaultPkgUom?.value && Number(defaultPkgUom.value)) || 1
                : uoms.filter(uom => !existingUomIds.includes(uom.id))[0].id,
            level_gtin: null,
            quantity_of_eaches: null,
            electronic_product_code: null,
            bar_code_characters: null,
            inner_quantity: null,
            inner_quantity_uom_id: null,
            orderable_option_id: null,
            height: null,
            width: null,
            length: null,
            merchandising_height: null,
            merchandising_width: null,
            merchandising_length: null,
            dimensions_uom_id: defaultPkgDimUom?.value ? Number(defaultPkgDimUom.value) : null,
            weight: null,
            weights_uom_id: defaultPkgWtUom?.value
              ? Number(defaultPkgWtUom.value)
              : (defaultPkgDimUom?.value && Number(defaultPkgDimUom.value)) || null,
            weight_variance: null,
            dimensional_weight: null,
            stacking_factor: null,
            hazardous_materials: [],
            uomName:
              values.extendedItemPackages.length < 1
                ? uoms.find(uom => uom.id === 1)?.name
                : uoms.filter(uom => !existingUomIds.includes(uom.id))[0].name,
            summary: '',
            weightName: defaultPkgWtUom?.name
              ? defaultPkgWtUom.name
              : (defaultPkgDimUom?.name && defaultPkgDimUom.name) || '',
            temp: true,
          };
          setFieldValue(`extendedItemPackages`, [newPackage, ...values.extendedItemPackages]);
          setExpandedKeys([...expandedKeys, newPackage.id]);
          if (
            (values.extendedItemPackages.length === 0 || extendedItemPackages.length === 0) &&
            brandUsedFields.length === 0
          ) {
            setShowAllFields(true);
          }

          intercomEvent('viewed-all-product', {
            action: 'item-edited',
            location: 'package_new',
            part_number: selectedItems.map(i => i.part_number).join(', '),
            brand_code: selectedItems[0]?.brand_code,
          });
        };

        return (
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center px-2">
              <Tooltip
                title={
                  values.extendedItemPackages.length === uoms.length
                    ? t('packageSeg:allPkgUsedInfo')
                    : ''
                }
              >
                <Button
                  type="primary"
                  data-testid="add-package"
                  ghost
                  onClick={() => handleAddNewPackage()}
                  size="small"
                  disabled={values.extendedItemPackages.length === uoms.length}
                >
                  <span className="px-2">{t('packageSeg:addNewPackage')}</span>
                </Button>
              </Tooltip>
              <LabelSwitch
                displayToolTip
                infoText={t('common:hideFieldsInfo')}
                checked={showAllFields}
                onChange={() => {
                  setShowAllFields(!showAllFields);
                  if (!showAllFields)
                    setExpandedKeys(values.extendedItemPackages.map((pkg: Package) => pkg.id));
                }}
              />
            </div>
            <div className="overflow-y-auto">
              {(values.extendedItemPackages.length < 1 && (
                <NoDataInfo
                  handleAddNew={handleAddNewPackage}
                  buttonName={t('packageSeg:addFirstPackage')}
                />
              )) || (
                <PackageTable
                  extendedItemPackages={values.extendedItemPackages as Package[]}
                  handlePkgDelete={handlePkgDelete}
                  allSelectedItemIds={
                    selectedItemIds.length === 1 ? selectedItemIds : allSelectedItemIds
                  }
                  multipleValueFields={multipleValueFields}
                  expandedKeys={expandedKeys}
                  showAllFields={showAllFields}
                  handleRowExpand={id => {
                    if (expandedKeys.includes(id)) {
                      const keys = expandedKeys.filter((rowKey: number | string) => rowKey !== id);
                      setExpandedKeys(keys);
                    } else {
                      const keys = [...expandedKeys, id];
                      setExpandedKeys(keys);
                    }
                  }}
                  handleMultiEdit={(field: string, uomId: number) => {
                    if (allSelectedItemIds.length > 1) {
                      setEditedFields([...new Set([...editedFields, `uomId${uomId}.${field}`])]);
                      setEditedUoms([...new Set([...editedUoms, uomId])]);
                    }
                  }}
                  getFilteredPackageUoms={uomId => getFilteredPackageUoms(uomId)}
                  updatePackage={updatePackage}
                  handleUpdateHzMaterials={handleUpdateHzMaterials}
                />
              )}
            </div>
          </div>
        );
      }}
    </PageFormik>
  );
};

export default PackagePage;
