import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Form, Button, message } from 'antd';
import * as Yup from 'yup';
import TextArea from 'antd/lib/input/TextArea';
import { FormikHelpers, FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncDispatch } from '../../../../../types/global';
import { ReceiverDetails } from '../../../../../types/receiver';
import {
  addWarehouseDistributor,
  fetchCompanyProfile,
  updateCompanyProfile,
} from '../../../../actions/settings/companyProfile';
import {
  COMPANY_INFORMATION,
  ADDRESS_PARTS,
  SOCIAL_INFORMATION,
  WAREHOUSE_DISTRIBUTOR,
} from '../../../../constants/CompanyProfileConstants';
import { ApplicationState } from '../../../../reducers';
import { getPrimaryContacts } from '../../../../selectors/userSelector';
import AntTooltip from '../../../global/AntTooltip';
import DrawerFormik from '../../../global/drawer/DrawerFormik';
import FormInput from '../../../global/Forms/FormInput';
import FormMultiSelect from '../../../global/Forms/FormMultiSelect';
import FormSelect from '../../../global/Forms/FormSelect';
import AutoFillAddress from '../AutoFillAddress';
import WareHouseDistributorTable from '../WareHouseDistributorTable';
import { validUrlRegExp } from '../../../../utils/Utils';
import FormUserPreselect from './FormUserPreselect';

type CompanyProfileDrawerProps = {
  drawerTitle: string;
  onClose: (saved?: boolean) => void;
  companyInfo: ReceiverDetails;
};

const CompanyProfileDrawer: React.FC<CompanyProfileDrawerProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();

  const { t } = useTranslation();

  const [filterKeywords, setFilterKeywords] = React.useState<string>('');
  const [wareHouseDealer, setWarehouseDealer] = React.useState<string>('');

  const {
    industries,
    salesChannels,
    revenueRanges,
    primaryContacts,
    userList,
    wareHouseDistributorList,
  } = useSelector((state: ApplicationState) => {
    const receiverWarehouseList = state.receiver.receivers.receiverWarehouseList;
    const sortedList = () => {
      const sortedWDList = props.companyInfo.warehouse_ids.map(
        sid => receiverWarehouseList.find(wd => wd.id === sid)!
      );

      const nonSelectedList = receiverWarehouseList.filter(
        wd => !props.companyInfo.warehouse_ids.includes(wd.id)
      );
      const listWithRecordNumber = [...sortedWDList, ...nonSelectedList].map((rw, index) => ({
        ...rw,
        record_number: index + 1,
      }));
      return listWithRecordNumber;
    };
    return {
      industries: state.resources.data.receiver.industries,
      salesChannels: state.resources.data.receiver.sales_channels,
      revenueRanges: state.resources.data.receiver.revenue_ranges,
      primaryContacts: getPrimaryContacts(state),
      userList: state.user.userList,
      wareHouseDistributorList: sortedList(),
    };
  });

  const [distributorList, setDistributorList] =
    React.useState<{ id: number; name: string; record_number: number }[]>(wareHouseDistributorList);

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    setSubmitting(true);
    dispatch(updateCompanyProfile(values)).then(() => {
      setSubmitting(false);
      dispatch(fetchCompanyProfile());
      props.onClose(true);
    });
  };

  const validationSchema = Yup.object().shape({
    name:
      props.drawerTitle === COMPANY_INFORMATION
        ? Yup.string().required(t('validation:required'))
        : Yup.string(),
    address_1:
      props.drawerTitle === COMPANY_INFORMATION
        ? Yup.string().required(t('validation:required'))
        : Yup.string(),
    website_url:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
    amazon_seller_name:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
    ebay_seller_name:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
    linkedin_name:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
    facebook_name:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
    instagram_name:
      props.drawerTitle === SOCIAL_INFORMATION
        ? Yup.string().matches(validUrlRegExp, t('validation:enterValidUrl'))
        : Yup.string(),
  });

  return (
    <DrawerFormik
      title={props.drawerTitle}
      visible
      onClose={() => {
        dispatch(fetchCompanyProfile());
        props.onClose();
      }}
      initialValues={{
        name: props.companyInfo.name || '',
        address_1: props.companyInfo.address_1 || '',
        address_2: props.companyInfo.address_2 || '',
        town: props.companyInfo.town || '',
        state: props.companyInfo.state || '',
        country: props.companyInfo.country || '',
        zip: props.companyInfo.zip || '',
        primary_contact_name: props.companyInfo.primary_contact_name || '',
        primary_contact_email: props.companyInfo.primary_contact_email || '',
        primary_contact_id: props.companyInfo.primary_contact_id || null,
        phone_number: props.companyInfo.phone_number || '',
        industry_ids: props.companyInfo.industry_ids || [],
        revenue_range_id: props.companyInfo.revenue_range_id || null,
        description: props.companyInfo.description || '',
        website_url: props.companyInfo.website_url || '',
        amazon_seller_name: props.companyInfo.amazon_seller_name || '',
        ebay_seller_name: props.companyInfo.ebay_seller_name || '',
        linkedin_name: props.companyInfo.linkedin_name || '',
        facebook_name: props.companyInfo.facebook_name || '',
        instagram_name: props.companyInfo.instagram_name || '',
        warehouse_ids: props.companyInfo.warehouse_ids || [],
        sales_channel_ids: props.companyInfo.sales_channel_ids || [],
      }}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => {
        const addNewWarehouseDistributor = () => {
          const wdEmail = wareHouseDealer.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');

          dispatch(
            addWarehouseDistributor({
              first_name: wareHouseDealer || '',
              last_name: wareHouseDealer || '',
              email: `${wdEmail}@wd-pdm.ai`,
              password: 'pdm2017$',
              receiver_name: wareHouseDealer,
              type_id: 6,
            })
          )
            .then(res => {
              const data = res.value.data;
              const newWd = {
                id: data.receiver_id,
                name: wareHouseDealer,
                record_number: 1,
              };
              setWarehouseDealer('');
              setFieldValue('warehouse_ids', [newWd.id, ...values.warehouse_ids]);
              setDistributorList([
                newWd,
                ...distributorList.map(
                  (dL: { id: number; name: string; record_number: number }) => ({
                    ...dL,
                    record_number: dL.record_number + 1,
                  })
                ),
              ]);
            })
            .catch(() => message.error(t('companyProfile:errorMessage')));
        };

        const handleAddressChange = (addressComponents: any, address1: string) => {
          if (addressComponents?.length > 0) {
            const allAddressTypes = [] as string[];
            addressComponents.forEach((ac: any) => allAddressTypes.push(...ac.types));
            const missingAddressTypes = ADDRESS_PARTS.filter(
              type => !allAddressTypes.includes(type)
            );

            setFieldValue('address_1', address1);

            addressComponents.forEach((addComp: any) => {
              switch (addComp.types[0]) {
                case 'locality': {
                  setFieldValue('town', addComp.long_name);
                  break;
                }
                case 'administrative_area_level_1': {
                  setFieldValue('state', addComp.short_name);
                  break;
                }
                case 'country': {
                  setFieldValue('country', addComp.long_name);
                  break;
                }
                case 'postal_code': {
                  setFieldValue('zip', addComp.long_name);
                  break;
                }
                case 'postal_code_suffix': {
                  const postalCode = addressComponents.find((addComp: any) =>
                    addComp.types.includes('postal_code')
                  );
                  const zip = postalCode
                    ? `${postalCode.long_name} ${addComp.long_name}`
                    : addComp.long_name;

                  setFieldValue('zip', zip);
                  break;
                }
              }
            });

            missingAddressTypes.forEach((type: string) => {
              switch (type) {
                case 'locality': {
                  setFieldValue('town', '');
                  break;
                }
                case 'administrative_area_level_1': {
                  setFieldValue('state', '');
                  break;
                }
                case 'country': {
                  setFieldValue('country', '');
                  break;
                }
                case 'postal_code': {
                  setFieldValue('zip', '');
                  break;
                }
              }
            });
            const input = document.getElementById('address2Input');
            if (input) input.focus();
          }
        };

        return (
          <Form
            layout={props.drawerTitle === WAREHOUSE_DISTRIBUTOR ? 'horizontal' : 'vertical'}
            className="h-full"
          >
            {props.drawerTitle === COMPANY_INFORMATION && (
              <React.Fragment>
                <FormUserPreselect users={primaryContacts} />
                <FormInput label={t('companyProfile:companyName')} name="name" required />
                <AutoFillAddress handleAddressChange={handleAddressChange} />
                <FormInput
                  id="address2Input"
                  label={t('companyProfile:address2')}
                  name="address_2"
                  placeholder={t('companyProfile:add2Placeholder')}
                />
                <div className="flex flex-row space-x-2">
                  <FormInput className="flex-1" label={t('companyProfile:city')} name="town" />
                  <FormInput
                    className="flex-1"
                    label={t('companyProfile:stateProvince')}
                    name="state"
                  />
                </div>
                <div className="flex flex-row space-x-2">
                  <FormInput
                    className="flex-1"
                    label={t('companyProfile:country')}
                    name="country"
                  />
                  <FormInput
                    className="flex-1"
                    label={t('companyProfile:zipPostalcode')}
                    name="zip"
                  />
                </div>
                <FormSelect
                  label={t('companyProfile:primaryContactName')}
                  name="primary_contact_id"
                  showSearch
                  values={primaryContacts || []}
                  allowClear
                  onChange={value => {
                    const user = primaryContacts.find(user => user.id === value);
                    setFieldValue('primary_contact_email', user?.email);
                    setFieldValue('primary_contact_name', user?.name);
                    setFieldValue('primary_contact_id', value);
                  }}
                />
                <FormInput
                  label={t('companyProfile:primaryContactEmail')}
                  name="primary_contact_email"
                  disabled
                />
                <FormInput label={t('companyProfile:primaryContactPhone')} name="phone_number" />
                <FormMultiSelect
                  label={t('companyProfile:industry')}
                  name="industry_ids"
                  values={industries}
                  className="export-format-options__multi-select"
                  allowClear
                  addFilterSort
                />
                <FormMultiSelect
                  label={t('companyProfile:salesChannel')}
                  name="sales_channel_ids"
                  values={salesChannels}
                  className="export-format-options__multi-select"
                  allowClear
                  addFilterSort
                />
                <FormSelect
                  label={t('companyProfile:annualRevenue')}
                  name="revenue_range_id"
                  values={revenueRanges}
                  allowClear
                />
                <Form.Item label={t('companyProfile:aboutUs')}>
                  <TextArea
                    value={values.description}
                    onChange={e => setFieldValue('description', e.target.value)}
                    maxLength={255}
                    showCount
                    allowClear
                    rows={5}
                  />
                </Form.Item>
              </React.Fragment>
            )}
            {props.drawerTitle === SOCIAL_INFORMATION && (
              <React.Fragment>
                <FormInput label={t('companyProfile:website')} name="website_url" />
                <FormInput label={t('companyProfile:amazonStore')} name="amazon_seller_name" />
                <FormInput label={t('companyProfile:ebayStore')} name="ebay_seller_name" />
                <FormInput label={t('companyProfile:linkedin')} name="linkedin_name" />
                <FormInput label={t('companyProfile:facebook')} name="facebook_name" />
                <FormInput label={t('companyProfile:instagram')} name="instagram_name" />
              </React.Fragment>
            )}
            {props.drawerTitle === WAREHOUSE_DISTRIBUTOR && (
              <div className="flex flex-col h-full">
                <div className="flex flex-row space-x-2">
                  <Input.Search
                    className="flex-1"
                    value={filterKeywords}
                    onChange={e => setFilterKeywords(e.target.value.toLowerCase())}
                    placeholder={t('common:search')}
                    allowClear
                  />
                  <div className="flex-1 flex flex-row space-x-2">
                    <Form.Item
                      className="flex-1"
                      label={
                        <React.Fragment>
                          {t('companyProfile:addWd')}
                          <AntTooltip title={t('companyProfile:addWdInfo')}>
                            <InfoCircleOutlined className="ant-info-tooltip ml-2" />
                          </AntTooltip>
                        </React.Fragment>
                      }
                    >
                      <Input
                        onChange={e => setWarehouseDealer(e.target.value)}
                        value={wareHouseDealer}
                      />
                    </Form.Item>
                    <Button
                      data-testid="add"
                      type="primary"
                      disabled={!wareHouseDealer}
                      onClick={() => addNewWarehouseDistributor()}
                    >
                      {t('common:add')}
                    </Button>
                  </div>
                </div>
                <div className="flex-1 h-auto">
                  <WareHouseDistributorTable
                    wareHouseDistributorList={distributorList}
                    selectedWareHouseIds={values.warehouse_ids}
                    handleWDSelection={ids => setFieldValue('warehouse_ids', ids)}
                    filterKeywords={filterKeywords}
                  />
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default CompanyProfileDrawer;
