import React from 'react';
import { Tooltip, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';
import AllApplicationsAnalysesIcon from './AllApplicationsAnalysesIcon';

type AllApplicationsTableCellProps = {
  col: string;
  cellData: any;
  configAnalysis?: Analysis & AnalysisType;
};

const AllApplicationsTableCell: React.FC<AllApplicationsTableCellProps> = ({
  col,
  cellData,
  configAnalysis,
}) => {
  const { t } = useTranslation();

  const groupAnalysis = configAnalysis?.code === 'applications_engine_config_changed_base';
  let data: any = '';

  if (col === 'positions') {
    const text = cellData && cellData.toString();
    data = (
      <Tooltip title={text} mouseLeaveDelay={0}>
        <span className="cursor-default">{text}</span>
      </Tooltip>
    );
  } else if (typeof cellData === 'string') data = cellData;
  else if (col === 'category')
    data = (cellData && cellData.part_type) || (
      <span className="italic text-gray-800">{t('applicationValidation:noPartType')}</span>
    );
  else if (col === 'notes' || col === 'qualifiers') {
    const text = cellData && cellData.join('\n');
    data = (
      <Tooltip title={text} overlayClassName="whitespace-pre-line" mouseLeaveDelay={0}>
        <span className="cursor-default whitespace-pre-line">{text}</span>
      </Tooltip>
    );
  } else if (col === 'year_count')
    data = <Tag color="blue">{`${cellData} ${t('applicationValidation:configs')}`}</Tag>;
  else data = cellData && cellData.toString();
  return (
    <div>
      {configAnalysis &&
        !['part_number', 'year_count', 'qty', 'notes'].includes(col) &&
        (!groupAnalysis || (groupAnalysis && cellData)) && (
          <AllApplicationsAnalysesIcon analyses={[configAnalysis]} />
        )}
      {data}
    </div>
  );
};

export default AllApplicationsTableCell;
