import React from 'react';
import { Avatar, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ceo from '../../../../images/johannes-crepon.1024x1024.jpg';
import { ApplicationState } from '../../../reducers';

type WelcomeMessageProps = {
  handleHideWelcomeMessage: () => void;
};

const WelcomeMessage: React.FC<WelcomeMessageProps> = props => {
  const { t } = useTranslation();
  const { userName } = useSelector((state: ApplicationState) => ({
    userName: state.user.user?.first_name || '',
  }));
  return (
    <div className="flex flex-row">
      <Avatar src={ceo} size={48} />
      <Card className="welcome-message__card">
        <h5 className="welcome__steps-header-title">{t('welcome:hi', { userName })}</h5>
        <p>{t('welcome:welcomeMessage')}</p>
        <Button onClick={() => props.handleHideWelcomeMessage()} data-testid="got-it">
          {t('welcome:gotIt')}
        </Button>
      </Card>
    </div>
  );
};

export default WelcomeMessage;
