import React, { Dispatch } from 'react';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { intercomEvent } from '../../../utils/IntercomUtils';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type ItemIntercomFormEventHandlerProps = {
  dispatch: Dispatch<AnyAction>;
  partNr: string;
  brandCode: string;
};

type ItemIntercomFormEventHandlerState = {
  mainSent: boolean;
  orderInformationSent: boolean;
  additionalCategoryInformationSent: boolean;
};

class ItemIntercomFormEventHandler extends React.Component<
  ItemIntercomFormEventHandlerProps & FormikProps,
  ItemIntercomFormEventHandlerState
> {
  state = {
    mainSent: false,
    orderInformationSent: false,
    additionalCategoryInformationSent: false,
  };

  componentDidUpdate(prevProps: ItemIntercomFormEventHandlerProps & FormikProps) {
    const { partNr, brandCode } = this.props;
    const { mainSent, orderInformationSent, additionalCategoryInformationSent } = this.state;
    const { values, dirty, isSubmitting } = this.props.formik;
    const { values: prevValues, dirty: prevDirty, isSubmitting: prevSubmitting } = prevProps.formik;

    if (values !== prevValues) {
      if (
        !mainSent &&
        (values.part_number !== prevValues.part_number ||
          values.base_part_number !== prevValues.base_part_number ||
          values.parent_owner_brand_id !== prevValues.parent_owner_brand_id ||
          values.parent_owner_brand_sub_brand_id !== prevValues.parent_owner_brand_sub_brand_id ||
          values.universal_part !== prevValues.universal_part ||
          values.quantity_per_application !== prevValues.quantity_per_application ||
          values.quantity_per_application_qualifier_id !==
            prevValues.quantity_per_application_qualifier_id ||
          values.quantity_per_application_uom_id !== prevValues.quantity_per_application_uom_id)
      ) {
        intercomEvent('viewed-all-product', {
          action: 'item-edited',
          location: 'item_main_information',
          part_number: partNr,
          brand_code: brandCode,
        });
        this.setState({ mainSent: true });
      }

      if (
        !orderInformationSent &&
        (values.minimum_order_quantity !== prevValues.minimum_order_quantity ||
          values.minimum_order_quantity_uom_id !== prevValues.minimum_order_quantity_uom_id ||
          values.effective_date !== prevValues.effective_date ||
          values.available_date !== prevValues.available_date ||
          values.gtin !== prevValues.gtin ||
          values.gtin_qualifier_id !== prevValues.gtin_qualifier_id ||
          values.quantity_size !== prevValues.quantity_size ||
          values.quantity_size_uom_id !== prevValues.quantity_size_uom_id ||
          values.container_type_id !== prevValues.container_type_id)
      ) {
        intercomEvent('viewed-all-product', {
          action: 'item-edited',
          location: 'item_order_information',
          part_number: partNr,
          brand_code: brandCode,
        });
        this.setState({ orderInformationSent: true });
      }

      if (
        !additionalCategoryInformationSent &&
        (values.part_category_code !== prevValues.part_category_code ||
          values.unspsc !== prevValues.unspsc ||
          values.vmrs_code !== prevValues.vmrs_code)
      ) {
        intercomEvent('viewed-all-product', {
          action: 'item-edited',
          location: 'item_additional_category_information',
          part_number: partNr,
          brand_code: brandCode,
        });
        this.setState({ additionalCategoryInformationSent: true });
      }

      if (!dirty && prevDirty) {
        this.setState({
          mainSent: false,
          orderInformationSent: false,
          additionalCategoryInformationSent: false,
        });
      }
    }

    if (isSubmitting && !prevSubmitting) {
      this.sendSaveEvents();
    }
  }

  sendSaveEvents = () => {
    const { partNr, brandCode } = this.props;
    const { mainSent, orderInformationSent, additionalCategoryInformationSent } = this.state;

    if (mainSent) {
      intercomEvent('viewed-all-product', {
        action: 'item-saved',
        location: 'item_main_information',
        part_number: partNr,
        brand_code: brandCode,
      });
    }

    if (orderInformationSent) {
      intercomEvent('viewed-all-product', {
        action: 'item-saved',
        location: 'item_order_information',
        part_number: partNr,
        brand_code: brandCode,
      });
    }

    if (additionalCategoryInformationSent) {
      intercomEvent('viewed-all-product', {
        action: 'item-saved',
        location: 'item_additional_category_information',
        part_number: partNr,
        brand_code: brandCode,
      });
    }
  };

  render() {
    return null;
  }
}

export default connect()(
  formikConnect<ItemIntercomFormEventHandlerProps, FormikValues>(ItemIntercomFormEventHandler)
);
