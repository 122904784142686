import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

/*
  Possible props to pass:
  - title: text of tooltip
  - hide: hide Tooltip if evaluates to true
  - className: string that is additionally added to default className
  - overlayClassName: string that is additionally added to the overlay className
  - placement: string that gives direction of tooltip
*/

export default class AntTooltip extends React.Component {
  render() {
    const { className, overlayClassName, title, hide, placement, mouseEnterDelay } = this.props;

    return hide ? (
      this.props.children
    ) : (
      <Tooltip
        className={className}
        overlayClassName={overlayClassName}
        title={title}
        placement={placement}
        mouseEnterDelay={mouseEnterDelay || 0.1}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

AntTooltip.propTypes = {
  // string that is aditionally added to default className
  className: PropTypes.string,
  // string for tooltip text
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  // boolean to hide tooltip
  hide: PropTypes.bool,
  // string for tooltip placement
  placement: PropTypes.string,
  // children comp to wrap into Tooltip
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

AntTooltip.defaultProps = {
  className: '',
  hide: false,
  placement: 'top',
};
