import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';

const getBrands = (state: ApplicationState) => state.parent.brands.brands;
const getSelectedBrandId = (state: ApplicationState) => state.parent.brands.selectedBrandId;

export const activeBrands = createSelector([getBrands], brands => {
  return brands.filter(brand => brand.active);
});

export const activeBrandsWithoutOe = createSelector([getBrands], brands => {
  return brands.filter(brand => brand.active && !brand.oe_brand);
});

export const getSelectedBrandCode = createSelector(
  [getSelectedBrandId, getBrands],
  (selectedBrandId, brands) => {
    return brands.find(brand => brand.id === selectedBrandId)?.code;
  }
);
