import React from 'react';
import classnames from 'classnames';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

type SearchBoxListProps = {
  valueKey: string;
  values: any[];
  onClick: (value: any) => void;
  className?: string;
};

const SearchBoxList: React.FC<SearchBoxListProps> = ({
  className,
  valueKey = 'id',
  values,
  onClick,
}) => {
  const [searchInput, setSearchInput] = React.useState('');
  const { t } = useTranslation();

  const valuesFilterBySearch = values.filter(({ name }: any) =>
    name.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <div
      className={classnames(
        'search-box-list flex flex-col border-solid border border-gray-500 rounded overflow-hidden',
        className
      )}
    >
      <Input.Search
        placeholder={t('common:search')}
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        data-testid="search-box"
      />
      <div className="overflow-y-auto">
        {valuesFilterBySearch.map((field: any) => (
          <div
            key={field[valueKey]}
            onClick={() => {
              onClick(field[valueKey]);
            }}
            className="px-2 py-1 hover:bg-blue-100 cursor-pointer"
            data-testid="search-list-element"
          >
            {field.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchBoxList;
