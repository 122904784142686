import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ErrorBoundary from '../components/global/ErrorBoundary';
import { withContainerWrapper } from './ContainerWrapper';

class ReceiverRouteWrapper extends React.Component {
  render() {
    const { isInitialized } = this.props;
    return (
      <ErrorBoundary>
        {!isInitialized ? (
          <Spin className="spinner-center" style={{ marginTop: '100px' }} size="large" />
        ) : (
          this.props.children
        )}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  isInitialized: state.receiverDataStream.isInitialized,
});

const ConnectedReceiverRouteWrapper = connect(mapStateToProps)(
  withContainerWrapper(ReceiverRouteWrapper)
);

export const withReceiverRouteWrapper = Component => props => {
  return (
    <ConnectedReceiverRouteWrapper>
      <Component {...props} />
    </ConnectedReceiverRouteWrapper>
  );
};

export default ConnectedReceiverRouteWrapper;
