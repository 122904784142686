import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { DeliveryLog } from '../../../types/delivery_logs';

export type ChannelPusherState = {
  channelPushes: any[];
  showNotification: boolean;
};

const initialState = {
  channelPushes: [],
  showNotification: false,
};

const formatLog = (log: DeliveryLog) => {
  return {
    id: log.id,
    brandName: log.brand_name,
    channelName: log.channel_name,
    planName: log.plan_name,
    isCompleted: !!log.completed_at,
    hasError: log.error_count > 0,
    channelId: log.channel_id,
    planId: log.plan_id,
    statusId: log.status_id,
  };
};

const reducer: Reducer<ChannelPusherState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case '@CHANNEL_PUSHER/FETCH_ALL_CHANNEL_PUSH_LOGS_FULFILLED': {
      const activeChannelPushes = action.payload.data
        .map((log: any) => formatLog(log))
        .filter(({ isCompleted }: any) => !isCompleted);
      return {
        ...state,
        channelPushes: activeChannelPushes,
        showNotification: activeChannelPushes.length > 0,
      };
    }
    case '@CHANNEL_PUSHER/FETCH_CHANNEL_PUSH_LOGS_FULFILLED': {
      const currentPushIds = state.channelPushes.map(({ id }) => id);
      const channelPushes = action.payload.data.map((log: DeliveryLog) => formatLog(log));
      const newPushes = channelPushes.filter(({ id }: any) => !currentPushIds.includes(id));
      return {
        ...state,
        channelPushes: [
          ...newPushes,
          ...state.channelPushes.map((log: any) => {
            const newLog = channelPushes.find(({ id }: any) => log.id === id);
            if (newLog) return newLog;
            return log;
          }),
        ],
      };
    }
    case '@CHANNEL_PUSHER/PUSH_UPDATE': {
      return {
        ...state,
        channelPushes: [
          ...action.payload.data.map((log: DeliveryLog) => formatLog(log)),
          ...state.channelPushes,
        ],
        showNotification: true,
      };
    }
    case '@CHANNEL_PUSHER/CLOSE_NOTIFICATION': {
      return {
        ...state,
        showNotification: false,
      };
    }
  }
  return state;
};

export default reducer;
