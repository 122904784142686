import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import { Menu, Layout, Spin } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import { withReceiverRouteWrapper } from '../ReceiverRouteWrapper';
import { fetchExternalSources } from '../../actions/receiver_data_stream/externalSources/fetch';
import { ExternalSources } from '../../../types/external_sources';
import ExternalSourcePage from '../../components/body/external_sources/ExternalSourcePage';
import ExternalSourceImportLogs from '../../components/body/external_sources/ExternalSourceImportLogs';
import { WithRouterProps, withRouter } from '../withRouter';

const { Content, Sider } = Layout;

type ExternalSourcesContainerProps = {
  dispatch: any;
  externalSources: ExternalSources;
  fetchingSources: boolean;
};

type Props = ExternalSourcesContainerProps & WithRouterProps & WithTranslation;
class ExternalSourcesContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(fetchExternalSources());
  }

  render() {
    const { t, location, fetchingSources, externalSources } = this.props;

    return (
      <Layout className="layout">
        <Sider theme="light">
          <Menu mode="inline" defaultSelectedKeys={['1']} selectedKeys={[location.pathname]}>
            <Menu.Item key="/available-brands/external-sources/sdc">
              <Link to={{ pathname: `sdc` }}>{t('externalSources:sdc')}</Link>
            </Menu.Item>
            <Menu.Item key="/available-brands/external-sources/dci">
              <Link to={{ pathname: `dci` }}>{t('externalSources:dci')}</Link>
            </Menu.Item>
            <Menu.Item key="/available-brands/external-sources/asap">
              <Link to={{ pathname: `asap` }}>{t('externalSources:asap')}</Link>
            </Menu.Item>
            <Menu.Item key="/available-brands/external-sources/opticat">
              <Link to={{ pathname: `opticat` }}>{t('externalSources:opticat')}</Link>
            </Menu.Item>
            <Menu.Item key="/available-brands/external-sources/import-logs">
              <Link to={{ pathname: `import-logs` }}>{t('externalSources:importReports')}</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className="main-page-content">
          {fetchingSources || !externalSources?.sdc ? (
            <Spin className="spinner-center" />
          ) : (
            <Routes>
              <Route path="sdc" element={<ExternalSourcePage />} />
              <Route path="dci" element={<ExternalSourcePage />} />
              <Route path="asap" element={<ExternalSourcePage />} />
              <Route path="opticat" element={<ExternalSourcePage />} />
              <Route path="import-logs" element={<ExternalSourceImportLogs />} />
            </Routes>
          )}
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    externalSources: state.externalSources.externalSources,
    fetchingSources: state.externalSources.fetchingSources,
  };
};

export default withRouter(
  connect(mapStateToProps)(withReceiverRouteWrapper(withTranslation()(ExternalSourcesContainer)))
);
