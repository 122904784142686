import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import {
  Epid,
  ItemEbaySettings,
  EbayCategory,
  EbayItemSettingsData,
  EbayOffer,
} from '../../../types/ebay';

export type EbayState = {
  readonly fetchingOffer: boolean;
  readonly fetchingItemSettings: boolean;
  readonly fetchingEpids: boolean;
  readonly createOfferPending: boolean;
  readonly updateOfferPending: boolean;
  readonly deleteOfferPending: boolean;
  readonly createOfferItemId?: number;
  readonly fetchOfferItemId?: number;
  readonly createOfferError: string;
  readonly ebayOffer?: EbayOffer;
  readonly ebayItemSettings?: EbayItemSettingsData;
  readonly epids: Epid[];
  readonly ebayCategories: EbayCategory[];
  readonly itemEbaySettings: ItemEbaySettings | null;
};

const initialState = {
  fetchingOffer: false,
  fetchingItemSettings: false,
  fetchingEpids: false,
  fetchingCategories: false,
  createOfferPending: false,
  updateOfferPending: false,
  deleteOfferPending: false,
  createOfferItemId: undefined,
  fetchOfferItemId: undefined,
  createOfferError: '',
  ebayOffer: undefined,
  epids: [],
  ebayCategories: [],
  itemEbaySettings: {} as ItemEbaySettings,
};

const reducer: Reducer<EbayState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EBAY_OFFER_PENDING': {
      return {
        ...state,
        fetchingOffer: true,
        ebayOffer: undefined,
        fetchOfferItemId: action.meta.itemId,
      };
    }
    case 'FETCH_EBAY_OFFER_FAILED': {
      return { ...state, fetchingOffer: false };
    }
    case 'FETCH_EBAY_OFFER_FULFILLED': {
      return {
        ...state,
        fetchingOffer: false,
        ebayOffer: action.payload.data.items ? action.payload.data.items[0] : undefined,
      };
    }
    case 'FETCH_EBAY_ITEM_SETTINGS_PENDING': {
      return {
        ...state,
        fetchingItemSettings: true,
        ebayItemSettings: undefined,
      };
    }
    case 'FETCH_EBAY_ITEM_SETTINGS_FULFILLED': {
      return { ...state, fetchingItemSettings: false, ebayItemSettings: action.payload.data };
    }
    case 'FETCH_EPIDS_PENDING': {
      return { ...state, epids: [], fetchingEpids: true };
    }
    case 'FETCH_EPIDS_FULFILLED': {
      return { ...state, epids: action.payload.data, fetchingEpids: false };
    }
    case 'FETCH_INTEGRATED_SETTINGS_PENDING': {
      return { ...state, itemEbaySettings: {} };
    }
    case 'FETCH_INTEGRATED_SETTINGS_FULFILLED': {
      return { ...state, itemEbaySettings: action.payload.data };
    }
    case 'FETCH_EBAY_CATEGORIES_PENDING': {
      const ebayCategories = action.meta.page > 1 ? state.ebayCategories : [];
      return { ...state, fetchingCategories: true, ebayCategories };
    }
    case 'FETCH_EBAY_CATEGORIES_FULFILLED': {
      const categories = action.payload.data;
      const ebayCategories =
        action.meta.page > 1 ? [...state.ebayCategories, ...categories] : categories;
      return { ...state, fetchingCategories: false, ebayCategories };
    }
    case 'CREATE_INTEGRATED_SETTINGS_FULFILLED': {
      const itemEbaySettings = state.itemEbaySettings || {};
      return { ...state, itemEbaySettings: { ...itemEbaySettings, ...action.meta } };
    }
    case 'UPDATE_INTEGRATED_SETTINGS_FULFILLED': {
      const itemEbaySettings = state.itemEbaySettings;
      return { ...state, itemEbaySettings: { ...itemEbaySettings, ...action.meta } };
    }
    case 'UPDATE_ITEM_EBAY_CATEGORY_FULFILLED': {
      const itemEbaySettings = state.itemEbaySettings;
      const primaryCategory = action.payload.data.primary_category_id;
      const secondaryCategory = action.payload.data.secondary_category_id;
      return {
        ...state,
        itemEbaySettings: {
          ...itemEbaySettings,
          primary_category_id: primaryCategory,
          secondary_category_id: secondaryCategory,
        },
      };
    }
    case 'RELIST_EBAY_ITEM_PENDING': {
      return { ...state, relistPending: true };
    }
    case 'RELIST_EBAY_ITEM_FULFILLED': {
      return { ...state, relistPending: false };
    }
    case 'CREATE_EBAY_OFFER_PENDING': {
      return {
        ...state,
        createOfferPending: true,
        updateOfferPending: !!action.meta.updateOffer,
        ebayOffer: !action.meta.updateOffer ? undefined : state.ebayOffer,
        createOfferError: '',
        createOfferItemId: action.meta.itemId,
      };
    }
    case 'CREATE_EBAY_OFFER_REJECTED': {
      return {
        ...state,
        createOfferPending: false,
        createOfferError: action.payload.response.data?.Error,
        updateOfferPending: false,
      };
    }
    case 'CREATE_EBAY_OFFER_FULFILLED': {
      return {
        ...state,
        createOfferPending: false,
        updateOfferPending: false,
        ebayOffer:
          state.fetchOfferItemId === state.createOfferItemId
            ? action.payload.data.items[0]
            : state.ebayOffer,
      };
    }
    case 'DELETE_EBAY_OFFER_PENDING': {
      return { ...state, deleteOfferPending: true };
    }
    case 'DELETE_EBAY_OFFER_FULFILLED': {
      return { ...state, deleteOfferPending: false, ebayOffer: undefined };
    }
  }
  return state;
};

export default reducer;
