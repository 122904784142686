import { Drawer, Col, Modal, Card } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { memoize } from 'lodash';
import { AsyncDispatch } from '../../../../types/global';
import actions from '../../../actions/channel';
import { fetchFilters } from '../../../actions/catalogue/filter/fetch';
import { fetchPriceSheets } from '../../../actions/parent/price_sheets/fetch';
import { fetchSettingsCustomFields } from '../../../actions/brand/custom_fields';
import { updateFormDirtyState } from '../../../actions/channel/update';
import { hasChannelDBPerm, hasPermission } from '../../../utils/Permissions';
import EditChannel from './EditChannel';
import EditEbayChannel from './EditEbayChannel';
import ChannelDrawerMenu from './ChannelDrawerMenu';
import ChannelPlanTabs from './ChannelPlanTabs';
import { fetchExportBuilderAdvancedTemplates } from '../../../actions/brand/export_builder_advanced/fetch';
import { UserType } from '../../../../types/user';
import { ChannelResources, Segment } from '../../../../types/resources';
import { Brand } from '../../../../types/brand';
import { Channel, ChannelFileExportOption } from '../../../../types/channel';
import { ReceiverCatalogueBrandType } from '../../../../types/receiver_data_stream';
import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { fetchExportOptions } from '../../../actions/brand/export/fetch';
import { FileExportOption } from '../../../../types/import_export';
import EditChannelAdvisor from './EditChannelAdvisor';
import {
  ChannelsWithBrandConnectivity,
  ChannelTypesWithBrandConnectivity,
  CHANNEL_IDS_WITH_BRAND_CONNECTIVITY,
} from '../../../constants/ChannelConstants';
import EditChannelNPW from './EditChannelNPW';
import EditChannelWalmart from './EditChannelWalmart';
import EditChannelShopify from './EditChannelShopify';

type ChannelDrawerProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  selectedChannelId?: number;
  ebayData: any;
  channelResources: ChannelResources;
  exportFileTypes: FileExportOption[];
  segments: Segment[];
  create: boolean;
  createIntegratedId?: number;
  drawerVisible: boolean;
  brands: Brand[];
  allAccessableBrands: ReceiverCatalogueBrandType[];
  channel?: Channel;
  selectedPlan: number;
  formDirty: boolean;
  isReceiverUser: boolean;
  canManageChannelAdvisor: boolean;
  canManageNpw: boolean;
  canManageWalmart: boolean;
  canManageShopify: boolean;
  canManageChannel: boolean;
  canManageEbay: boolean;
  createChannel: (values: { [key: string]: any }) => void;
  updateChannel: (values: { [key: string]: any }) => void;
  close: () => void;
  fetchingExportOptions: boolean;
  shopifyId?: number;
} & WithTranslation;

type ChannelDrawerState = {
  selectedMenuKey: string;
};

class ChannelDrawer extends React.Component<ChannelDrawerProps, ChannelDrawerState> {
  constructor(props: ChannelDrawerProps) {
    super(props);
    this.state = { selectedMenuKey: 'channel' };
  }

  componentDidMount() {
    this.props.dispatch(fetchPriceSheets());
  }

  componentDidUpdate(prevProps: ChannelDrawerProps) {
    const { channel, create, createIntegratedId, ebayData, selectedPlan, canManageEbay } =
      this.props;
    if (
      prevProps.channel?.id !== channel?.id ||
      (create && createIntegratedId && prevProps.createIntegratedId !== createIntegratedId)
    ) {
      if (channel?.integration_type_id === 3 && canManageEbay) {
        this.props.dispatch(actions.fetchEbaySettings()).then(result => {
          const { value } = result;
          if (value.data.ebay_registered) {
            this.props.dispatch(actions.fetchEbayPolicies());
          }
        });
      }
      if ((channel?.integration_type_id === 3 || createIntegratedId === 3) && canManageEbay) {
        if (!Object.keys(ebayData).length) this.props.dispatch(actions.fetchEbayResources());
      }
      if (selectedPlan) {
        const plan = channel!.plans.find(plan => plan.id === selectedPlan)!;
        this.handleMenuClick(plan.brand_ids[0].toString());
      }
    }
  }

  fetchChannelData = () => {
    if (this.props.user.parent_id && this.props.selectedChannelId !== 6) {
      this.props.dispatch(fetchPriceSheets());
      this.props.dispatch(fetchExportOptions('channel'));
    }
  };

  fetchBrandData = (brandId: number) => {
    const { isReceiverUser } = this.props;

    if (this.props.user.parent_id) {
      this.props.dispatch(fetchFilters(brandId));

      this.props.dispatch(fetchExportBuilderAdvancedTemplates(brandId));
      this.props.dispatch(fetchPriceSheets(brandId));
      this.props.dispatch(fetchExportOptions('channel', brandId));
      if (hasPermission(this.props.user, 'can_export_whitelabel_products'))
        this.props.dispatch(fetchSettingsCustomFields(brandId));
    }
    if (isReceiverUser) {
      this.props.dispatch(fetchPriceSheets(brandId));
    }
  };

  fetchEbayConnectionState = () =>
    this.props.dispatch(actions.fetchEbaySettings()).then(result => {
      const settings = result.value.data;
      if (settings.ebay_registered) {
        this.props.dispatch(actions.fetchEbayPolicies());
      }

      return result;
    });

  createDefaultSettings = (values: any, fileExportOptions: ChannelFileExportOption[]) => {
    const { selectedChannelId } = this.props;
    const params = { ...values, channelId: selectedChannelId };
    this.props.dispatch(
      actions.createExportFtpSettings({ ...params.delivery, channelId: selectedChannelId })
    );
    this.props.dispatch(
      actions.updateExportFileSettings({
        ...params.file,
        channelId: selectedChannelId,
        fileExportOptions,
      })
    );
    this.props.dispatch(
      actions.createSchedule({ ...params.schedule, channelId: selectedChannelId })
    );
  };

  createPlan = (
    values: {
      selectedSegments: any[];
      delivery: any;
      fileTypeId?: number;
      name: string;
    },
    defaults: {
      defaultDelivery: boolean;
      defaultFile: boolean;
      defaultSchedule: boolean;
      defaultIntegrationSettings: boolean;
    },
    fileExportOptions: ChannelFileExportOption[]
  ) => {
    const { selectedChannelId, segments } = this.props;
    const { selectedSegments = [] } = values;
    const brandId = Number(this.state.selectedMenuKey);
    const segmentIds = selectedSegments.map(
      segmentCode => segments.find(segment => segment.name === segmentCode)!.id
    );
    const params = {
      ...values,
      channelId: selectedChannelId!,
      brandId,
      segmentIds,
      fileTypeId: values.fileTypeId!,
    };
    return this.props.dispatch(actions.createExportPlan(params)).then(result => {
      const params = {
        ...values,
        planId: result.value.data.id,
        channelId: selectedChannelId,
        fileExportOptions,
      };
      const promises = [];
      if (defaults.hasOwnProperty('defaultDelivery') && !defaults.defaultDelivery)
        promises.push(
          this.props.dispatch(
            actions.createExportFtpSettings({
              ...params.delivery,
              planId: params.planId,
              channelId: selectedChannelId,
            })
          )
        );
      if (defaults.hasOwnProperty('defaultFile') && !defaults.defaultFile)
        promises.push(this.props.dispatch(actions.updateExportFileSettings(params)));
      if (defaults.hasOwnProperty('defaultSchedule') && !defaults.defaultSchedule)
        promises.push(this.props.dispatch(actions.createSchedule(params)));
      if (
        defaults.hasOwnProperty('defaultIntegrationSettings') &&
        !defaults.defaultIntegrationSettings
      ) {
        promises.push(this.props.dispatch(actions.createEbayDefaultSettings(params)));
        promises.push(this.props.dispatch(actions.createEbayDefaultDescriptionTypes(params)));
      }

      return Promise.all(promises).then(() => result);
    });
  };

  createEbayConnection = () => {
    const { selectedChannelId } = this.props;

    if (!selectedChannelId) {
      const ebayChannel = this.props.channelResources.integration_types.find(ch => ch.id === 3)!;
      this.props.createChannel({ name: ebayChannel.name, integrationTypeId: ebayChannel.id });
    }
  };

  updateEbaySettings = (values: any) => this.props.dispatch(actions.updateEbaySettings(values));

  createEbayDefaultSettings = (values: any) =>
    this.props.dispatch(actions.createEbayDefaultSettings(values));

  createEbayDefaultDescriptionTypes = (values: any) =>
    this.props.dispatch(actions.createEbayDefaultDescriptionTypes(values));

  updateEbayDefaultSettings = (values: any) =>
    this.props.dispatch(actions.updateEbayDefaultSettings(values));

  updateEbayDefaultDescriptionTypes = (values: any) =>
    this.props.dispatch(actions.updateEbayDefaultDescriptionTypes(values));

  updateDefaultSettings = (values: any, fileExportOptions: ChannelFileExportOption[]) => {
    const { selectedChannelId: channelId, channel } = this.props;
    const params = { ...values, channelId };
    const promises = [];
    if (values.delivery) {
      const ftpSettings = channel!.default_ftp
        ? this.props.dispatch(actions.updateExportFtpSettings({ ...params.delivery, channelId }))
        : this.props.dispatch(actions.createExportFtpSettings({ ...params.delivery, channelId }));
      promises.push(ftpSettings);
    }

    if (values.file) {
      const fileSettings = this.props.dispatch(
        actions.updateExportFileSettings({ ...params.file, channelId, fileExportOptions })
      );
      promises.push(fileSettings);
    }

    if (values.schedule) {
      const scheduleSettings = channel!.default_schedule
        ? this.props.dispatch(actions.updateSchedule({ ...params.schedule, channelId }))
        : this.props.dispatch(actions.createSchedule({ ...params.schedule, channelId }));
      promises.push(scheduleSettings);
    }
    return Promise.all(promises);
  };

  updatePlan = (
    planId: number,
    values: { selectedSegmentIds: number[]; delivery: any },
    defaults: {
      defaultDelivery: any;
      defaultFile: any;
      defaultSchedule: any;
      defaultIntegrationSettings: any;
    },
    fileExportOptions: ChannelFileExportOption[]
  ) => {
    const { channel, selectedChannelId: channelId } = this.props;
    const { selectedSegmentIds = [] } = values;
    const params = {
      ...values,
      planId,
      channelId,
      segmentIds: selectedSegmentIds,
      fileExportOptions,
    };
    const { plans = [] } = channel!;
    const plan = plans.find(plan => plan.id === Number(planId))!;

    if (defaults.hasOwnProperty('defaultDelivery') && !defaults.defaultDelivery) {
      const deliveryParams = { ...params.delivery, planId, channelId };
      if (plan.ftp) this.props.dispatch(actions.updateExportFtpSettings(deliveryParams));
      else this.props.dispatch(actions.createExportFtpSettings(deliveryParams));
    }
    if (defaults.hasOwnProperty('defaultFile') && !defaults.defaultFile) {
      this.props.dispatch(actions.updateExportFileSettings(params));
    }
    if (defaults.hasOwnProperty('defaultSchedule') && !defaults.defaultSchedule) {
      if (plan.schedule) this.props.dispatch(actions.updateSchedule(params));
      else this.props.dispatch(actions.createSchedule(params));
    }
    if (
      defaults.hasOwnProperty('defaultIntegrationSettings') &&
      !defaults.defaultIntegrationSettings
    ) {
      if (plan.integration_settings) {
        this.props.dispatch(actions.updateEbayDefaultSettings(params));
        this.props.dispatch(actions.updateEbayDefaultDescriptionTypes(params));
      } else {
        this.props.dispatch(actions.createEbayDefaultSettings(params));
        this.props.dispatch(actions.updateEbayDefaultDescriptionTypes(params));
      }
    }

    return this.props.dispatch(actions.updateExportPlan(channelId!, params));
  };

  deletePlans = (planIds: number[]) =>
    this.props.dispatch(actions.deletePlans(this.props.selectedChannelId!, planIds));

  deleteExportFileSettings = (planId: number) => {
    const { selectedChannelId } = this.props;
    this.props.dispatch(actions.deleteExportFileSettings(selectedChannelId!, planId));
  };

  deleteExportFtpSettings = (planId: number) => {
    const { selectedChannelId } = this.props;
    this.props.dispatch(actions.deleteExportFtpSettings(selectedChannelId!, planId));
  };

  deleteSchedule = (planId: number) => {
    const { selectedChannelId } = this.props;
    this.props.dispatch(actions.deleteSchedule(selectedChannelId!, planId));
  };

  deleteIntegratedSettings = (planId: number) => {
    const { selectedChannelId } = this.props;
    this.props.dispatch(actions.deleteIntegratedChannelSettings(selectedChannelId!, planId));
  };

  deleteDefaultFtpSettings = () => {
    const { selectedChannelId, channel } = this.props;
    if (channel!.default_ftp) {
      this.props.dispatch(actions.deleteDefaultExportFtpSettings(selectedChannelId!));
    }
  };

  deleteDefaultFileSettings = () => {
    const { selectedChannelId, channel } = this.props;
    if (channel!.default_file) {
      this.props.dispatch(actions.deleteDefaultExportFileSettings(selectedChannelId!));
    }
  };

  deleteDefaultScheduleSettings = () => {
    const { selectedChannelId, channel } = this.props;
    if (channel!.default_schedule) {
      this.props.dispatch(actions.deleteDefaultSchedule(selectedChannelId!));
    }
  };

  handleEbayUpdate = (values: { schedule: { deliveryFrequencyId: any } }) => {
    const { channel, selectedChannelId } = this.props;
    const promises = [];
    const params = { ...values, channelId: selectedChannelId };

    promises.push(this.updateEbaySettings(params));
    if (channel!.default_integration_settings) {
      promises.push(this.updateEbayDefaultSettings(params));
      promises.push(this.updateEbayDefaultDescriptionTypes(params));
    } else {
      promises.push(this.createEbayDefaultSettings(params));
      promises.push(this.createEbayDefaultDescriptionTypes(params));
    }

    if (values.schedule.deliveryFrequencyId) {
      const scheduleParam = { ...params.schedule, channelId: selectedChannelId };
      if (!channel!.default_schedule) this.props.dispatch(actions.createSchedule(scheduleParam));
      else this.props.dispatch(actions.updateSchedule(scheduleParam));
    }

    return Promise.all(promises).then(res => {
      // fetch settings to keep store up to date
      this.props.dispatch(actions.fetchEbaySettings({ noPendingState: true }));
      return res;
    });
  };

  handleMenuClick = (key: string) => {
    const { t, formDirty } = this.props;
    const handleClick = () => {
      this.setState({ selectedMenuKey: key });
      if (formDirty) this.props.dispatch(updateFormDirtyState(false));
      if (key !== 'channel') this.fetchBrandData(Number(key));
      else if (key === 'channel') this.fetchChannelData();
    };

    if (formDirty) {
      Modal.confirm({
        title: t('common:closeWarningTitle'),
        onOk() {
          handleClick();
        },
      });
    } else {
      handleClick();
    }
  };

  handleClose = (force?: boolean) => {
    const { t, formDirty } = this.props;
    const handleClick = () => {
      this.setState({ selectedMenuKey: 'channel' });
      this.props.dispatch(fetchExportOptions('channel'));

      if (formDirty) this.props.dispatch(updateFormDirtyState(false));
      this.props.close();
    };

    if (formDirty && !force) {
      Modal.confirm({
        title: t('common:closeWarningTitle'),
        onOk() {
          handleClick();
        },
      });
    } else handleClick();
  };

  filterBrands = memoize((brands: Brand[]) =>
    brands.filter(b => (b.active === undefined || b.active === 1) && !b.oe_brand)
  );

  channelEdit = () => {
    const { brands, create, createIntegratedId, channel } = this.props;

    if (channel?.integration_type_id === 3 || (create && !channel?.id && createIntegratedId === 3))
      return (
        <EditEbayChannel
          create={create && !channel?.id}
          channel={this.props.channel}
          isReceiverUser={this.props.isReceiverUser}
          createEbayConnection={this.createEbayConnection}
          fetchEbayConnectionState={this.fetchEbayConnectionState}
          handleEbayUpdate={this.handleEbayUpdate}
          onClose={this.handleClose}
        />
      );

    return (
      <EditChannel
        create={create && !channel?.id}
        createIntegratedId={this.props.createIntegratedId}
        fileTypes={this.props.exportFileTypes}
        channel={this.props.channel}
        brands={this.filterBrands(brands)}
        allAccessableBrands={this.props.allAccessableBrands}
        isReceiverUser={this.props.isReceiverUser}
        createChannel={this.props.createChannel}
        updateChannel={this.props.updateChannel}
        createDefaultSettings={this.createDefaultSettings}
        updateDefaultSettings={this.updateDefaultSettings}
        deleteDefaultFileSettings={this.deleteDefaultFileSettings}
        deleteDefaultFtpSettings={this.deleteDefaultFtpSettings}
        deleteDefaultScheduleSettings={this.deleteDefaultScheduleSettings}
        deletePlans={this.deletePlans}
        onClose={this.handleClose}
      />
    );
  };

  renderChannelWithBrandConnectivity = (channelId: number) => {
    const { t } = this.props;
    if (
      this.props.canManageChannelAdvisor &&
      channelId === ChannelsWithBrandConnectivity.CHANNELADVISOR
    )
      return <EditChannelAdvisor channelType={ChannelTypesWithBrandConnectivity.CHANNELADVISOR} />;
    if (this.props.canManageNpw && channelId === ChannelsWithBrandConnectivity.NPW)
      return <EditChannelNPW channelType={ChannelTypesWithBrandConnectivity.CHANNELNPW} />;
    if (this.props.canManageWalmart && channelId === ChannelsWithBrandConnectivity.WALMART)
      return <EditChannelWalmart channelType={ChannelTypesWithBrandConnectivity.CHANNELWALMART} />;
    return (
      <Card className="channel__contact-card">
        <p>{t('channel:contactChannelSetup')}</p>
      </Card>
    );
  };

  render() {
    const {
      create,
      channel,
      brands,
      createIntegratedId,
      channelResources,
      selectedPlan,
      fetchingExportOptions,
      canManageChannel,
      shopifyId,
      canManageShopify,
      canManageEbay,
      t,
    } = this.props;
    const { selectedMenuKey } = this.state;
    const integratedChannelTitle =
      createIntegratedId &&
      channelResources.integration_types.find(intChan => intChan.id === createIntegratedId)!.name;
    const plans = channel?.plans || [];
    const drawerTitle = () => {
      if (create && createIntegratedId !== 9)
        return createIntegratedId ? integratedChannelTitle : 'Create';
      if (channel) return channel.name;
      if (this.props.selectedChannelId === ChannelsWithBrandConnectivity.CHANNELADVISOR)
        return t('channel:channelAdvisor');
      if (this.props.selectedChannelId === ChannelsWithBrandConnectivity.NPW)
        return t('channel:npw');
      if (this.props.selectedChannelId === ChannelsWithBrandConnectivity.WALMART)
        return t('channel:walmart');
      if (!canManageShopify) return t('channel:shopify');
    };
    const title = drawerTitle();

    const renderChannels = () => {
      if ((createIntegratedId === 9 || shopifyId) && canManageShopify)
        return (
          <EditChannelShopify
            channelType={ChannelTypesWithBrandConnectivity.CHANNELSHOPIFY}
            shopifyId={shopifyId}
            handleClose={this.handleClose}
          />
        );
      if (
        (create && !canManageChannel) ||
        ((createIntegratedId === 9 || shopifyId) && !canManageShopify) ||
        ((channel?.integration_type_id === 3 || createIntegratedId === 3) && !canManageEbay)
      )
        return (
          <Card className="channel__contact-card">
            <p>{t('channel:contactChannelSetup')}</p>
          </Card>
        );
      if (
        this.props.selectedChannelId &&
        CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(this.props.selectedChannelId)
      )
        return this.renderChannelWithBrandConnectivity(this.props.selectedChannelId!);

      return (
        <div className="channel__drawer-wrapper flex">
          <Col span={4} className="channel__drawer-menu-column h-full">
            <ChannelDrawerMenu
              handleMenuClick={this.handleMenuClick}
              brands={this.filterBrands(brands)}
              plans={channel?.plans || []}
              create={create && !channel?.id}
              selectedKey={this.state.selectedMenuKey}
              disablePlanEdit={channel?.integration_type_id === 8}
            />
          </Col>
          <Col span={20} className="channel__drawer-content flex">
            {selectedMenuKey === 'channel' && this.channelEdit()}
            {selectedMenuKey && selectedMenuKey !== 'channel' && !fetchingExportOptions && (
              <ChannelPlanTabs
                selectedMenuKey={selectedMenuKey}
                initialSelectedPlanId={selectedPlan}
                plans={plans.filter(p => p.brand_ids.includes(Number(selectedMenuKey)))}
                integrationTypeId={channel!.integration_type_id}
                defaultFile={channel?.default_file}
                defaultTemplateId={channel?.file_name_template_id}
                defaultFtp={channel?.default_ftp}
                defaultSchedule={channel?.default_schedule}
                defaultIntegrationSettings={channel?.default_integration_settings}
                fileTypes={this.props.exportFileTypes}
                createPlan={this.createPlan}
                deletePlans={this.deletePlans}
                updatePlan={this.updatePlan}
                deleteExportFileSettings={this.deleteExportFileSettings}
                deleteExportFtpSettings={this.deleteExportFtpSettings}
                deleteSchedule={this.deleteSchedule}
                deleteIntegratedSettings={this.deleteIntegratedSettings}
                onClose={this.handleClose}
                isReceiverUser={this.props.isReceiverUser}
                showWhitelabel={hasPermission(this.props.user, 'can_export_whitelabel_products')}
              />
            )}
          </Col>
        </div>
      );
    };

    return (
      <div>
        <Drawer
          className="channel__drawer"
          title={title}
          width="80%"
          placement="right"
          destroyOnClose
          onClose={() => this.handleClose()}
          open={this.props.drawerVisible}
          drawerStyle={{ height: '100%' }}
          closable={!(createIntegratedId === 9 || shopifyId)}
        >
          {renderChannels()}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  selectedChannelId: state.channel.channels.selectedChannelId,
  ebayData: state.channel.channels.ebayResources,
  channelResources: state.resources.data.channel,
  segments: state.resources.data.global.segments,
  formDirty: state.channel.channels.formDirtyState,
  canManageChannel: hasChannelDBPerm(state.user.user),
  canManageEbay: hasPermission(state.user.user, 'can_manage_ebay'),
  exportFileTypes: state.brand.brandExport.fileExportOptions,
  fetchingExportOptions: state.brand.brandExport.fetchingExportOptions,
});

export { ChannelDrawer };
export default connect(mapStateToProps)(withContainerWrapper(withTranslation()(ChannelDrawer)));
