import { Button, Dropdown, Empty, Spin, Tag, Tooltip } from 'antd';
import React from 'react';
import {
  DownOutlined,
  FileSearchOutlined,
  FilterFilled,
  FilterOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
  TableRowProps,
} from 'react-virtualized';
import { t } from 'i18next';
import { CANCELED, REJECTED, APPROVED, PENDING } from '../../../constants/ReceiverConstants';
import { ExtendedResources, StandardResource } from '../../../../types/resources';
import ReceiverRequestPopover from './ReceiverRequestPopover';
import { PriceSheetType } from '../../../../types/price_sheet';
import { Receiver, ReceiverRequest } from '../../../../types/receiver';
import TableFilterPopover from '../../global/tableFilters/TableFilterPopover';
import TableSorter from '../../global/tableFilters/TableSorter';
import ReceiverRejectPopover from './ReceiverRejectPopover';
import RequestProfileDetailsButton from './RequestProfileDetailsButton';

type ReceiverRequestParams = {
  priceSheetIds: number[];
  priceTypeIds: number[];
  withoutPrices: boolean;
  filterIds: number[];
  withoutInterchanges: number;
  interchangeQualityIds: number[];
};

type ReceiverTableProps = {
  isLoading: boolean;
  receivers: Receiver[];
  requestReceiverAccess: (params: {
    receiverId: number;
    brandIds: number[];
    integrationTypeId: number | null;
    selectedPriceSheetIds: number[];
    selectedPriceTypeIds: number[];
    withoutPrices: boolean;
    filterIds: number[];
    withoutInterchanges: number;
    interchangeQualityIds: number[];
    isNewRequest: boolean;
  }) => void;
  approveReceivers: (params: {
    receiverId: number;
    brandIds: number[];
    selectedPriceSheetIds: number[];
    selectedPriceTypeIds: number[];
    withoutPrices: boolean;
  }) => void;
  declineReceivers: (receiverId: number, brandId: number[], reason?: string) => void;
  cancelRequests: (receiverId: number, brandIds: number[]) => void;
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  handleReceiverFilter: (filterIds: number[], statusIds: number[], sortOrder: string) => void;
  handleShowReceiverDetails: (receiverId: number) => void;
  fullReceiverList: Receiver[];
  reqStatuses: ExtendedResources[];
  interchangeQuality: StandardResource[];
} & WithTranslation;

type ReceiverTableState = {
  expandedRowKeys: number[];
  editBrandReceiver: string | null;
  approveBrandReceiver: string | null;
  requestBrandReceiver: string | null;
  requestAllBrandsReceiver: string | null;
  approveAllBrandsReceiver: string | null;
  filterReceiverIds: number[];
  filterdStatusIds: number[];
  sortOrder: string;
};

class ReceiverTable extends React.Component<ReceiverTableProps, ReceiverTableState> {
  private listRef = React.createRef<Table>();

  constructor(props: ReceiverTableProps) {
    super(props);
    this.state = {
      expandedRowKeys:
        props.receivers
          .filter(receiver => {
            const pending = receiver.requests.some(
              req => this.getReceiverStatus(req) === PENDING && !!req.authorized_by_receiver_at
            );
            return receiver.requests.length > 1 && pending;
          })
          .map(receiver => receiver.receiver_id) || [],
      editBrandReceiver: null,
      approveBrandReceiver: null,
      requestBrandReceiver: null,
      requestAllBrandsReceiver: null,
      approveAllBrandsReceiver: null,
      filterReceiverIds: [],
      filterdStatusIds: [],
      sortOrder: '',
    };
  }

  handleRowExpand = (rowKey: number) => {
    this.setState((prevState: any) => ({
      expandedRowKeys: prevState.expandedRowKeys.includes(rowKey)
        ? prevState.expandedRowKeys.filter((key: number | string) => key !== rowKey)
        : [...prevState.expandedRowKeys, rowKey],
    }));
  };

  getReceiverStatus = (request: ReceiverRequest) => {
    return request.status || '';
  };

  createReceiverInfo = (request: ReceiverRequest) => {
    return (
      <span className="channel-table-header__profile-info">
        <span>{request.name}</span>
        {request.integrationTypeId && (
          <Tag className="receiver__integrated-tag">{t('receiver:featured')}</Tag>
        )}
      </span>
    );
  };

  handleApproveReceivers = (
    receiverId: number,
    brandIds: number[],
    values: ReceiverRequestParams
  ) => {
    const { priceSheetIds, priceTypeIds, withoutPrices } = values;
    return this.props.approveReceivers({
      receiverId,
      brandIds,
      selectedPriceSheetIds: priceSheetIds,
      selectedPriceTypeIds: priceTypeIds,
      withoutPrices,
    });
  };

  handleRequestReceivers = (
    receiverId: number,
    integrationTypeId: number | null,
    brandIds: number[],
    values: ReceiverRequestParams,
    isNewRequest: boolean
  ) => {
    return this.props.requestReceiverAccess({
      receiverId,
      integrationTypeId,
      brandIds,
      selectedPriceSheetIds: values.priceSheetIds,
      selectedPriceTypeIds: values.priceTypeIds,
      withoutPrices: values.withoutPrices,
      filterIds: values.filterIds,
      withoutInterchanges: values.withoutInterchanges,
      interchangeQualityIds: values.interchangeQualityIds,
      isNewRequest,
    });
  };

  getRowHeight = (rowData: any) => {
    const { expandedRowKeys } = this.state;
    const rowHeight = 65;
    const brandRowHeight = 57;

    if (expandedRowKeys.includes(rowData.receiver_id))
      return rowData.requests.length * brandRowHeight + rowHeight;

    return rowHeight;
  };

  offerAllBrands = (
    receiverId: number,
    receiver: Receiver,
    requestIds: number[],
    buttonType?: 'primary' | 'text'
  ) => {
    return (
      <ReceiverRequestPopover
        visible={`${receiverId}` === this.state.requestAllBrandsReceiver}
        buttonName={t('receiver:actions.offer_all_brands')}
        title={t('receiver:actions.offer_all_brands')}
        tooltip={t('receiver:actions.offer_tooltip')!}
        priceSheets={this.props.priceSheets}
        priceTypes={this.props.priceTypes}
        interchangeQuality={this.props.interchangeQuality}
        hide={() => this.setState({ requestAllBrandsReceiver: null })}
        open={() => {
          this.setState({ requestAllBrandsReceiver: `${receiverId}` });
        }}
        requestReceiver={async (values: ReceiverRequestParams) => {
          const response = await this.handleRequestReceivers(
            receiverId,
            receiver.integration_type_id,
            requestIds,
            values,
            true
          );
          this.setState({ requestAllBrandsReceiver: null });
          return response;
        }}
        type={buttonType}
      />
    );
  };

  menuItems = (receiverId: number, receiver: Receiver, requestIds: number[]) => {
    return [
      ...(requestIds.length > 0
        ? [
            {
              label: this.offerAllBrands(receiverId, receiver, requestIds, 'text'),
              key: 'offer-all-brands',
            },
          ]
        : []),
      {
        label: (
          <RequestProfileDetailsButton
            ghost
            receiverId={receiverId}
            receiverName={receiver?.name || ''}
            disableOnAcknowledge={receiver.receiver_profile_requested_acknowledged === 1}
            disableOnReq={!!receiver.receiver_profile_requested_at}
            type="text"
          />
        ),
        key: 'request-profile-details',
      },
    ];
  };

  renderMenu = (receiverId: number, receiver: Receiver, requestIds: number[]) => {
    return (
      <Dropdown
        menu={{
          // @ts-ignore
          items: this.menuItems(receiverId, receiver, requestIds),
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button size="small" type="primary" ghost>
          {t('receiver:more')} <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  createTopLevelActionButton = (receiver: Receiver) => {
    const { receiver_id: receiverId } = receiver;
    const requestIds = receiver.requests
      .filter(req => [CANCELED, ''].includes(this.getReceiverStatus(req)))
      .map(req => req.brand_id);
    const cancelIds = receiver.requests
      .filter(req => this.getReceiverStatus(req) === PENDING && !!req.authorized_by_brand_at)
      .map(req => req.brand_id);
    const approveIds = receiver.requests
      .filter(req => this.getReceiverStatus(req) === PENDING && req.authorized_by_receiver_at)
      .map(req => req.brand_id);
    const pendingRequest = receiver.requests.find(req => this.getReceiverStatus(req) === PENDING);

    return (
      <div className="channel-table-header__button-group" onClick={e => e.stopPropagation()}>
        {cancelIds.length > 0 && (
          <Button
            onClick={() => this.props.cancelRequests(receiverId, cancelIds)}
            type="primary"
            ghost
            size="small"
          >
            {t('receiver:actions.cancel_all')}
          </Button>
        )}
        {approveIds.length > 0 && (
          <React.Fragment>
            <ReceiverRequestPopover
              visible={`${receiverId}` === this.state.approveAllBrandsReceiver}
              buttonName={t('receiver:actions.approve_all')}
              title={t('receiver:reject_title')}
              priceSheets={this.props.priceSheets}
              interchangeQuality={this.props.interchangeQuality}
              priceTypes={this.props.priceTypes}
              hide={() => this.setState({ approveAllBrandsReceiver: null })}
              open={() => {
                this.setState({ approveAllBrandsReceiver: `${receiverId}` });
              }}
              requestReceiver={async (values: ReceiverRequestParams) => {
                const response = await this.handleApproveReceivers(receiverId, approveIds, values);
                this.setState({ approveAllBrandsReceiver: null });
                return response;
              }}
            />
            <ReceiverRejectPopover
              receiverName={receiver.name}
              handleReject={(reason?: string) =>
                this.props.declineReceivers(receiverId, approveIds, reason)
              }
            >
              <Button type="primary" size="small" ghost>
                {t('receiver:actions.reject_all')}
              </Button>
            </ReceiverRejectPopover>
          </React.Fragment>
        )}
        {!pendingRequest && !receiver.receiver_profile_requested_at && (
          <RequestProfileDetailsButton
            ghost
            receiverId={receiverId}
            receiverName={receiver?.name || ''}
            disableOnAcknowledge={receiver.receiver_profile_requested_acknowledged === 1}
          />
        )}
        {requestIds.length > 0 && !pendingRequest
          ? this.offerAllBrands(receiverId, receiver, requestIds)
          : this.renderMenu(receiverId, receiver, requestIds)}
      </div>
    );
  };

  displayInfo = (receiver: Receiver) => {
    if (
      receiver.receiver_profile_requested_at &&
      receiver.receiver_profile_requested_acknowledged !== 1
    )
      return (
        <Tooltip title={t('receiver:brandResellerReqInfo')}>
          <InfoCircleOutlined className="icon__blue ml-1" />
        </Tooltip>
      );
  };

  createTopLevelStatus = (receiver: Receiver) => {
    const approved = receiver.requests.find(req => this.getReceiverStatus(req) === APPROVED);
    const pending = receiver.requests.find(req => this.getReceiverStatus(req) === PENDING);
    const rejected = receiver.requests.find(req => this.getReceiverStatus(req) === REJECTED);
    return (
      <React.Fragment>
        {approved && <Tag color="green">{t('receiver:status.approved')}</Tag>}
        {pending && <Tag color="orange">{t('receiver:status.pending')}</Tag>}
        {rejected && <Tag color="red">{t('receiver:status.rejected')}</Tag>}
        {this.displayInfo(receiver)}
      </React.Fragment>
    );
  };

  createActionButton = (
    request: ReceiverRequest,
    receiverProfileReq?: string | null,
    receiverProfileAcknowledged?: number | null,
    receiverName?: string
  ) => {
    const { expandedRowKeys } = this.state;

    const status = this.getReceiverStatus(request);

    const brandId = request.brand_id;
    const receiverId = request.receiverId;
    const integrationTypeId = receiverId ? null : request.integrationTypeId;

    return (
      <div className="channel-table-header__button-group">
        {((status === PENDING && request.authorized_by_brand_at) || status === APPROVED) && (
          <ReceiverRequestPopover
            brandId={brandId}
            visible={`${request.brand_id}${request.receiverId}` === this.state.editBrandReceiver}
            buttonName={t('receiver:actions.edit')}
            title={t('receiver:edit_title')}
            selectedFilterIds={request.filter_ids}
            priceSheets={this.props.priceSheets}
            priceTypes={this.props.priceTypes}
            interchangeQuality={this.props.interchangeQuality}
            defaultSelectedPriceSheetIds={request.price_sheet_ids || []}
            defaultSelectedPriceTypeIds={request.price_type_ids || []}
            defaultWithoutPrices={request.without_prices === 1}
            defaultWithoutInterchanges={request.without_interchanges}
            defaultSelectedInterchangeQltyIds={request.with_interchange_type_records}
            hide={() => this.setState({ editBrandReceiver: null })}
            open={() => {
              this.setState(prevState => ({
                editBrandReceiver: !prevState.editBrandReceiver ? `${brandId}${receiverId}` : null,
              }));
            }}
            requestReceiver={async (values: ReceiverRequestParams) => {
              const isNewRequest =
                request.price_sheet_ids.length === 0 &&
                request.price_type_ids.length === 0 &&
                request.without_prices === 0 &&
                request.without_interchanges === 0 &&
                request.with_interchange_type_records.length === 0;
              const response = await this.handleRequestReceivers(
                receiverId,
                request.integrationTypeId,
                [brandId],
                values,
                isNewRequest
              );
              this.setState({ editBrandReceiver: null });
              return response;
            }}
          />
        )}
        {((status === PENDING && request.authorized_by_receiver_at) || status === APPROVED) && (
          <ReceiverRejectPopover
            receiverName={request.name}
            handleReject={(reason?: string) =>
              this.props.declineReceivers(request.receiverId, [request.brand_id], reason)
            }
          >
            <Button type="primary" size="small" ghost>
              {t('receiver:reject')}
            </Button>
          </ReceiverRejectPopover>
        )}
        {((status === PENDING && request.authorized_by_receiver_at) ||
          (status === REJECTED && !request.declined_by_receiver_at)) && (
          <ReceiverRequestPopover
            brandId={brandId}
            visible={`${brandId}${receiverId}` === this.state.approveBrandReceiver}
            buttonName={t('receiver:approve')}
            title={t('receiver:approve_title')}
            selectedFilterIds={request.filter_ids}
            priceSheets={this.props.priceSheets}
            priceTypes={this.props.priceTypes}
            interchangeQuality={this.props.interchangeQuality}
            hide={() => this.setState({ approveBrandReceiver: null })}
            open={() => {
              this.setState({ approveBrandReceiver: `${brandId}${receiverId}` });
            }}
            requestReceiver={async (values: ReceiverRequestParams) => {
              const response = await this.handleApproveReceivers(receiverId, [brandId], values);
              this.setState({ approveBrandReceiver: null });
              return response;
            }}
          />
        )}
        {status === PENDING && request.authorized_by_brand_at && (
          <Button
            onClick={() => this.props.cancelRequests(receiverId, [brandId])}
            type="primary"
            ghost
            size="small"
          >
            {t('common:cancel')}
          </Button>
        )}
        {!receiverProfileReq && expandedRowKeys.length === 0 && (
          <RequestProfileDetailsButton
            ghost
            receiverId={receiverId}
            receiverName={receiverName || ''}
            disableOnAcknowledge={receiverProfileAcknowledged === 1}
          />
        )}
        {(status === '' || status === CANCELED) && (
          <ReceiverRequestPopover
            brandId={brandId}
            visible={`${brandId}${receiverId}` === this.state.requestBrandReceiver}
            buttonName={t('receiver:offer_product_data')}
            title={t('receiver:offer_product_data')}
            selectedFilterIds={request.filter_ids}
            priceSheets={this.props.priceSheets}
            priceTypes={this.props.priceTypes}
            interchangeQuality={this.props.interchangeQuality}
            hide={() => this.setState({ requestBrandReceiver: null })}
            open={() => {
              this.setState({ requestBrandReceiver: `${brandId}${receiverId}` });
            }}
            requestReceiver={async (values: ReceiverRequestParams) => {
              const response = await this.handleRequestReceivers(
                receiverId,
                integrationTypeId,
                [brandId],
                values,
                true
              );
              this.setState({ requestBrandReceiver: null });
              return response;
            }}
          />
        )}
      </div>
    );
  };

  createStatus = (request: ReceiverRequest) => {
    const status = this.getReceiverStatus(request);
    if (status === REJECTED)
      return (
        <span>
          <Tag color="red">
            {t('receiver:status.rejected')}
            {request.declined_by_brand_reason && (
              <Tooltip title={request.declined_by_brand_reason}>
                <FileSearchOutlined className="receiver__declined-icon" />
              </Tooltip>
            )}
          </Tag>
        </span>
      );
    if (status === APPROVED) return <Tag color="green">{t('receiver:status.approved')}</Tag>;
    if (status === PENDING) return <Tag color="orange">{t('receiver:status.pending')}</Tag>;
  };

  createCompanyHeaderCell = () => {
    const { fullReceiverList } = this.props;
    const { filterReceiverIds, filterdStatusIds, sortOrder } = this.state;
    const receiverListValues = fullReceiverList.map(r => ({ ...r, id: r.receiver_id }));

    return (
      <div className="flex flex-row items-center pl-4">
        {t('receiver:columns.company')}
        <div className="ml-3">
          <TableFilterPopover
            values={receiverListValues}
            handleChange={ids => {
              this.setState({ filterReceiverIds: ids });
              this.props.handleReceiverFilter(ids, filterdStatusIds, sortOrder);
            }}
            initiallySelectedIds={filterReceiverIds}
            showSearch
          >
            {filterReceiverIds.length > 0 ? (
              <FilterFilled className="table-filter-blue" />
            ) : (
              <FilterOutlined />
            )}
          </TableFilterPopover>
        </div>
        <div className="ml-1">
          <TableSorter
            selected
            sortOrder={sortOrder}
            handleSort={order => {
              const orderDirection = sortOrder !== order ? order : '';
              this.setState({ sortOrder: orderDirection });
              this.props.handleReceiverFilter(filterReceiverIds, filterdStatusIds, orderDirection);
            }}
          />
        </div>
      </div>
    );
  };

  createStatusHeaderCell = () => {
    const { reqStatuses } = this.props;
    const { filterdStatusIds, filterReceiverIds, sortOrder } = this.state;
    return (
      <div className="flex flex-row items-center">
        {t('receiver:status.status')}
        <div className="ml-3">
          <TableFilterPopover
            values={reqStatuses}
            handleChange={ids => {
              this.setState({ filterdStatusIds: ids });
              this.props.handleReceiverFilter(filterReceiverIds, ids, sortOrder);
            }}
            initiallySelectedIds={filterdStatusIds}
            height={100}
          >
            {filterdStatusIds.length > 0 ? (
              <FilterFilled className="table-filter-blue" />
            ) : (
              <FilterOutlined />
            )}
          </TableFilterPopover>
        </div>
      </div>
    );
  };

  showReceiverProfileButton = (receiverName: string, receiverId: number) => {
    const { t } = this.props;

    return (
      <Tooltip title={t('receiver:viewProfile')}>
        <Button
          onClick={e => {
            e.stopPropagation();
            this.props.handleShowReceiverDetails(receiverId);
          }}
          type="text"
        >
          <span className="font-medium">{receiverName}</span>
        </Button>
      </Tooltip>
    );
  };

  rowRenderer = (props: TableRowProps) => {
    const { style, className, key, rowData, columns } = props;
    const { expandedRowKeys } = this.state;
    const rowHeight = '65px';

    if (expandedRowKeys.includes(rowData.receiver_id)) {
      return (
        <div
          style={{ ...style, display: 'flex', flexDirection: 'column' }}
          className={className}
          key={key}
        >
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: style.height, minHeight: rowHeight },
          })}
          {rowData.requests.map((request: ReceiverRequest) => (
            <div
              className="ReactVirtualized__Table__row bg-gray-200 leading-9"
              style={{ width: style.width, height: style.height }}
              key={`${key}_${request.brand_id}`}
            >
              <div className="ReactVirtualized__Table__rowColumn" style={columns[0].props.style} />
              <div
                className="ReactVirtualized__Table__rowColumn pl-4 flex"
                style={columns[1].props.style}
              >
                <span className="flex-auto w-72">{this.createReceiverInfo(request)}</span>
                <span className="flex-1">{request.brandName}</span>
              </div>
              <div className="ReactVirtualized__Table__rowColumn" style={columns[2].props.style}>
                {this.createStatus(request)}
              </div>
              <div className="ReactVirtualized__Table__rowColumn" style={columns[3].props.style}>
                {this.createActionButton(request)}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return defaultTableRowRenderer(props);
  };

  cellRenderer = ({ rowData }: any) => {
    const { expandedRowKeys } = this.state;
    if (rowData.requests.length > 1)
      return (
        <div className="cursor-pointer">
          {expandedRowKeys.includes(rowData.receiver_id) ? '-' : '+'}
        </div>
      );
  };

  render() {
    const { receivers, isLoading } = this.props;

    const dataSource = receivers;

    return (
      <div className="receiver-content__wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height: height - 1, width: width - 1 }}>
              <Table
                ref={this.listRef}
                height={height - 1}
                width={width - 2}
                headerHeight={60}
                rowHeight={({ index }) => this.getRowHeight(dataSource[index])}
                className="receiver-table"
                noRowsRenderer={() =>
                  isLoading ? (
                    <div className="mt-10">
                      <Spin className="spinner-center" />
                    </div>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                rowCount={dataSource.length}
                dataSource={dataSource}
                rowGetter={({ index }) => dataSource[index]}
                expandedRowKeys={this.state.expandedRowKeys}
                onRowClick={({ rowData }) => {
                  if (rowData.requests.length > 1) this.handleRowExpand(rowData.receiver_id);
                  this.listRef.current?.recomputeRowHeights();
                  this.listRef.current?.forceUpdateGrid();
                }}
                rowRenderer={this.rowRenderer}
              >
                <Column
                  cellRenderer={this.cellRenderer}
                  dataKey="index"
                  disableSort
                  width={receivers[0]?.requests.length > 1 ? 10 : 0}
                />
                <Column
                  label={this.createCompanyHeaderCell()}
                  dataKey="company"
                  width={120}
                  flexGrow={1}
                  cellRenderer={({ rowData }) => (
                    <span>
                      {this.showReceiverProfileButton(rowData.name, rowData.receiver_id)}
                      {rowData.integration_type_id && (
                        <Tag className="receiver__integrated-tag">{t('receiver:featured')}</Tag>
                      )}
                    </span>
                  )}
                />
                <Column
                  label={this.createStatusHeaderCell()}
                  dataKey="status"
                  width={240}
                  cellRenderer={({ rowData }) =>
                    rowData.requests.length > 1 ? (
                      this.createTopLevelStatus(rowData)
                    ) : (
                      <React.Fragment>
                        {rowData.requests[0] && this.createStatus(rowData.requests[0])}
                        {this.displayInfo(rowData)}
                      </React.Fragment>
                    )
                  }
                />
                <Column
                  label={t('receiver:columns.receiver_actions')}
                  dataKey="actions"
                  width={210}
                  cellRenderer={({ rowData }) => {
                    if (rowData.requests.length > 1)
                      return this.createTopLevelActionButton(rowData);
                    if (rowData.requests.length === 1)
                      return this.createActionButton(
                        rowData.requests[0],
                        rowData.receiver_profile_requested_at,
                        rowData.receiver_profile_requested_acknowledged,
                        rowData.name
                      );
                  }}
                />
              </Table>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default withTranslation()(ReceiverTable);
