import React from 'react';
import { Checkbox } from 'antd';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AntPopover from '../../../global/AntPopover';
import TableSorter from '../../../global/tableFilters/TableSorter';

type TranslationHeaderCellProps = {
  title: string;
  handleEmptyValues: () => void;
  handleSort: (order: string) => void;
  checked?: boolean;
  showDefault?: boolean;
  selected: boolean;
  sortOrder?: string;
};

const TranslationHeaderCell: React.FC<TranslationHeaderCellProps> = ({
  title,
  checked,
  showDefault,
  selected,
  sortOrder,
  handleEmptyValues,
  handleSort,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center translations-header-cell">
      <div>
        {title}
        {showDefault && (
          <span className="pl-1 text-gray-500" data-testid="default-text">{`(${t(
            'common:default'
          )})`}</span>
        )}
      </div>
      <div onClick={e => e.stopPropagation()} className="flex flex-row items-center">
        <AntPopover
          trigger="click"
          disabled={false}
          content={
            <Checkbox onChange={() => handleEmptyValues()} checked={checked}>
              {t('language:translationsSegment.emptyValues')}
            </Checkbox>
          }
          buttonProps={{
            type: 'text',
            size: 'small',
            icon: checked ? <FilterFilled className="fill-blue" /> : <FilterOutlined />,
          }}
        />
        <TableSorter selected={selected} sortOrder={sortOrder} handleSort={handleSort} />
      </div>
    </div>
  );
};

export default TranslationHeaderCell;
