import { UserType, UserRole } from '../../types/user';

export const isManufacturer = (user: UserType) => user.roles.includes(UserRole.MANUFACTURER);
export const isReceiver = (user: UserType) => user.roles.includes(UserRole.RECEIVER);

export const getRoles = (user: UserType) => {
  // Atm roles are exclusive, however later users might have multiple roles at once
  const isManufacturer = !!user.parent_id;
  return [isManufacturer ? UserRole.MANUFACTURER : UserRole.RECEIVER];
};

export const isAdmin = (user: UserType) => user.admin === 1;
