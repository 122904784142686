import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ReceiverDetails } from '../../../types/receiver';

export type CompanyProfileState = {
  companyInfo?: ReceiverDetails;
  fetchingCompanyInfo: boolean;
};

const initialState = {
  companyInfo: undefined,
  fetchingCompanyInfo: false,
};

const reducer: Reducer<CompanyProfileState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_COMPANY_PROFILE_PENDING':
      return {
        ...state,
        fetchingCompanyInfo: true,
      };
    case 'FETCH_COMPANY_PROFILE_FULFILLED':
      return {
        ...state,
        fetchingCompanyInfo: false,
        companyInfo: action.payload.data,
      };
  }
  return state;
};

export default reducer;
