import { combineReducers } from 'redux';
import filter, { FilterState } from './FilterReducer';
import updateManager, { CatalogueManagerState } from './CatalogueManagerReducer';
import createItem, { CreateItemState } from './CreateItemReducer';
import catalogue, { CatalogueState } from './CatalogueReducer';

export type BrandCatalogueState = {
  filter: FilterState;
  updateManager: CatalogueManagerState;
  createItem: CreateItemState;
  catalogue: CatalogueState;
};

const CatalogueReducer = combineReducers<BrandCatalogueState>({
  filter,
  updateManager,
  createItem,
  catalogue,
});

export default CatalogueReducer;
