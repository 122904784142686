import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AsyncDispatch } from '../../../../types/global';
import { fetchEbaySettings } from '../../../actions/channel/fetch';

type EbayConnectProps = {
  createEbayConnection: () => Promise<any>;
};

const EbayConnect: React.FC<EbayConnectProps> = ({ createEbayConnection }) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [registerPending, setRegisterPending] = React.useState(false);

  React.useEffect(() => {
    let myInterval: NodeJS.Timeout | undefined;

    if (registerPending && !myInterval) {
      myInterval = setInterval(() => {
        dispatch(fetchEbaySettings({ noPendingState: true })).then(result => {
          const { value } = result;
          if (value.data.ebay_registered) {
            clearInterval(myInterval!);
            setRegisterPending(false);
          }
        });
      }, 5000);
    }

    return () => {
      if (myInterval) clearInterval(myInterval);
    };
  }, [dispatch, registerPending]);

  const handleRegister = () => {
    const height = 600;
    const width = 850;
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
    window.open(
      `https://auth.ebay.com/oauth2/authorize?state=${process.env.REACT_APP_STAGE}&client_id=Paramoun-PDMAutom-PRD-816e08212-8e125024&response_type=code&redirect_uri=Paramount_Data_-Paramoun-PDMAut-iuxaqg&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly`,
      '_blank',
      `scrollbars=1,menubar=0,resizable=1,width=800,height=800,top=${top},left=${left},noreferrer`
    );

    setRegisterPending(true);
    createEbayConnection();
  };

  return (
    <Button type="primary" onClick={() => handleRegister()} loading={registerPending}>
      {t('ebay:connect')}
    </Button>
  );
};

export default EbayConnect;
