import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import HelpCenter, { hasHelpCenterEntry } from '../../../containers/HelpCenter';
import AntTooltip from '../../global/AntTooltip';
import HorizontalScroll from '../../global/HorizontalScroll';
import { Analysis } from '../../../../types/analyses';
import { AnalysisResources } from '../../../../types/brandResources';

type ItemAnalysisProps = {
  analyses: Analysis[];
  brandAnalysisResources: AnalysisResources;
  noCloseIcon?: boolean;
  hideAnalysis?: (id: number) => void;
};

export default class ItemAnalysis extends React.Component<ItemAnalysisProps> {
  getColor = (alertType: number) => {
    switch (alertType) {
      case 1:
        return 'blue';
      case 2:
        return 'orange';
      case 3:
        return 'red';
      default:
        return '';
    }
  };

  hideAnalyses = (typeId: number) => {
    this.props.hideAnalysis!(typeId);
  };

  showMessages = () =>
    this.props.analyses.map(analysis => {
      const analysisTypeResource = this.props.brandAnalysisResources.types.find(
        resource => resource.id === analysis.type_id
      );
      const color = this.getColor(analysisTypeResource!.alert_type_id);
      return (
        analysisTypeResource &&
        analysisTypeResource.name && (
          <Tag
            key={analysis.type_id}
            color={color}
            closable={
              !this.props.noCloseIcon &&
              analysisTypeResource.xml_format_validation !== 1 &&
              analysisTypeResource.alert_type_id !== 3
            }
            onClose={() => this.hideAnalyses(analysis.type_id)}
          >
            <span>{analysisTypeResource.name}</span>
            {(hasHelpCenterEntry(analysisTypeResource.code) && (
              <HelpCenter id={analysisTypeResource.code} className={color} />
            )) ||
              (analysisTypeResource.description && (
                <span style={{ marginLeft: 5 }}>
                  <AntTooltip title={analysisTypeResource.description}>
                    <InfoCircleOutlined />
                  </AntTooltip>
                </span>
              ))}
          </Tag>
        )
      );
    });

  render() {
    return (
      <div className="item-analysis-container">
        <HorizontalScroll>{this.showMessages()}</HorizontalScroll>
      </div>
    );
  }
}
