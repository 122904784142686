import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Spin } from 'antd';
import { ApplicationState } from '../../../reducers';
import { getGapCoverageVehicleResources } from '../../../selectors/applications/applicationsSelector';
import { fetchGapCoverageApplicationYears } from '../../../actions/brand/import_export/fetch';
import GapCoverageReportVehicleApp from './GapCoverageReportVehicleApp';
import { AsyncDispatch } from '../../../../types/global';
import { fetchRecommendedVehicles, resetRecommendedVehicles } from '../../../actions/exporter';

const GapCoverageReportStep3: React.FC = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  const dispatch: AsyncDispatch = useDispatch();

  const {
    fetchingYears,
    years,
    resources,
    fetchingApplicationResources,
    fetchingRecomendedVehicles,
  } = useSelector((state: ApplicationState) => {
    return {
      fetchingYears: state.brand.importExport.fetchingYears,
      years: state.brand.importExport.years,
      resources: getGapCoverageVehicleResources(state),
      fetchingApplicationResources: state.resources.fetchingApplicationResources,
      fetchingRecomendedVehicles: state.exporter.fetchingRecomendedVehicles,
    };
  });

  const getRecommendedVehicles = (exportApplication: any) => {
    const containsFilter =
      exportApplication.makes.length > 0 ||
      exportApplication.models.length > 0 ||
      exportApplication.years.length > 0 ||
      exportApplication.vehicle_types.length > 0 ||
      exportApplication.vehicle_type_groups.length > 0;

    setFieldValue('exportApplication', exportApplication);

    if (containsFilter)
      dispatch(
        fetchRecommendedVehicles({
          makeIds: exportApplication.makes,
          modelIds: exportApplication.models,
          yearIds: exportApplication.years,
          vehicleTypeIds: exportApplication.vehicle_types,
          vehicleGroupIds: exportApplication.vehicle_type_groups,
        })
      ).then(res => {
        const recommendedModels = res.value.data.vehicles.model_ids;
        const recommendedYears = res.value.data.vehicles.year_ids;
        if (recommendedModels || recommendedYears) {
          const updatedModelSelection = recommendedModels
            ? exportApplication.models.filter((id: number) => recommendedModels.includes(id))
            : exportApplication.models;
          const updatedYearSelection = recommendedYears
            ? exportApplication.years.filter((id: number) => recommendedYears.includes(id))
            : exportApplication.years;

          setFieldValue('exportApplication', {
            ...exportApplication,
            models: updatedModelSelection,
            years: updatedYearSelection,
          });
        }
      });
  };

  React.useEffect(() => {
    const containsFilter =
      values.exportApplication.makes.length > 0 ||
      values.exportApplication.models.length > 0 ||
      values.exportApplication.years.length > 0 ||
      values.exportApplication.vehicle_types.length > 0 ||
      values.exportApplication.vehicle_type_groups.length > 0;
    if (!fetchingRecomendedVehicles && !containsFilter) dispatch(resetRecommendedVehicles());
  }, [dispatch, fetchingRecomendedVehicles, values]);

  const fetchAppYears = (exportApplication: any) => {
    if (
      (exportApplication.makes.length > 0 || exportApplication.models.length > 0) &&
      exportApplication.models.length < 2 &&
      exportApplication.makes.length < 2
    ) {
      dispatch(
        fetchGapCoverageApplicationYears(exportApplication.makes[0], exportApplication.models[0])
      );
    } else if (
      (exportApplication.models.length === 2 || exportApplication.makes.length === 2) &&
      !(exportApplication.models.length > 2 || exportApplication.makes.length > 2)
    )
      dispatch(fetchGapCoverageApplicationYears());
  };

  const selectMake = (makeId: number | number[] | null, addValues: any) => {
    if (makeId === null) {
      const updateFilterApplication = {
        ...values.exportApplication,
        makes: [],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    }
    if (addValues) {
      const updateFilterApplication = {
        ...values.exportApplication,
        makes: values.exportApplication.makes.includes(makeId)
          ? values.exportApplication.makes.filter((id: number) => id !== makeId)
          : [...values.exportApplication.makes, makeId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    } else if (!(makeId instanceof Array)) {
      const updateFilterApplication = {
        ...values.exportApplication,
        makes:
          values.exportApplication.makes.length > 1
            ? [makeId]
            : (values.exportApplication.makes.includes(makeId) &&
                values.exportApplication.makes.filter((id: number) => id !== makeId)) || [makeId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    }
  };

  const selectModel = (modelId: number | number[] | null, addValues: any) => {
    if (modelId === null) {
      const updateFilterApplication = {
        ...values.exportApplication,
        models: [],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      fetchAppYears(updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
    if (addValues) {
      const updateFilterApplication = {
        ...values.exportApplication,
        models: values.exportApplication.models.includes(modelId)
          ? values.exportApplication.models.filter((id: number) => id !== modelId)
          : [...values.exportApplication.models, modelId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    } else if (!(modelId instanceof Array)) {
      const updateFilterApplication = {
        ...values.exportApplication,
        models:
          values.exportApplication.models.length > 1
            ? [modelId]
            : (values.exportApplication.models.includes(modelId) &&
                values.exportApplication.models.filter((id: number) => id !== modelId)) || [
                modelId,
              ],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    }
  };

  const selectYears = (yearIds: number | number[] | null, addValues: any, save = false) => {
    if (yearIds === null) {
      setFieldValue('exportApplication', { ...values.exportApplication, years: [] });
      getRecommendedVehicles({ ...values.exportApplication, years: [] });
    }
    if (yearIds instanceof Array) {
      const updateFilterApplication = {
        ...values.exportApplication,
        years: [...new Set([...yearIds, ...values.exportApplication.years])],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      if (save || addValues) getRecommendedVehicles(updateFilterApplication);
    } else {
      const updateFilterApplication = {
        ...values.exportApplication,
        years: values.exportApplication.years.includes(yearIds)
          ? values.exportApplication.years.filter((y: number) => y !== yearIds)
          : [...values.exportApplication.years, yearIds],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
  };

  const selectType = (typeId: number | number[] | null, addValues: any) => {
    if (typeId === null) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_types: [],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
    if (addValues) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_types: values.exportApplication.vehicle_types.includes(typeId)
          ? values.exportApplication.vehicle_types.filter((id: number) => id !== typeId)
          : [...values.exportApplication.vehicle_types, typeId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    } else if (!(typeId instanceof Array)) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_types:
          values.exportApplication.vehicle_types.length > 1
            ? [typeId]
            : (values.exportApplication.vehicle_types.includes(typeId) &&
                values.exportApplication.vehicle_types.filter((id: number) => id !== typeId)) || [
                typeId,
              ],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
  };

  const selectGroup = (groupId: number | number[] | null, addValues: any) => {
    if (groupId === null) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_type_groups: [],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
    if (addValues) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_type_groups: values.exportApplication.vehicle_type_groups.includes(groupId)
          ? values.exportApplication.vehicle_type_groups.filter((id: number) => id !== groupId)
          : [...values.exportApplication.vehicle_type_groups, groupId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
      fetchAppYears(updateFilterApplication);
    } else if (!(groupId instanceof Array)) {
      const updateFilterApplication = {
        ...values.exportApplication,
        vehicle_type_groups:
          values.exportApplication.vehicle_type_groups.length > 1
            ? [groupId]
            : (values.exportApplication.vehicle_type_groups.includes(groupId) &&
                values.exportApplication.vehicle_type_groups.filter(
                  (id: number) => id !== groupId
                )) || [groupId],
      };
      setFieldValue('exportApplication', updateFilterApplication);
      getRecommendedVehicles(updateFilterApplication);
    }
  };

  const handleClearSelection = (configKey: string) => {
    switch (configKey) {
      case 'models': {
        setFieldValue('exportApplication', { ...values.exportApplication, models: [] });
        getRecommendedVehicles({ ...values.exportApplication, models: [] });
        break;
      }
      case 'years': {
        setFieldValue('exportApplication', { ...values.exportApplication, years: [] });
        getRecommendedVehicles({ ...values.exportApplication, years: [] });
        break;
      }
      case 'makes': {
        setFieldValue('exportApplication', { ...values.exportApplication, makes: [] });
        getRecommendedVehicles({ ...values.exportApplication, makes: [] });
        break;
      }
    }
  };

  if (fetchingApplicationResources)
    return (
      <div className="mt-10">
        <Spin className="spinner-center" />
      </div>
    );

  return (
    <div className="flex flex-col h-full">
      <div className="mt-2 mb-6 text-gray-700">
        <div> {t('gapCoverageReport:step3Description1')}</div>
        <div>{t('gapCoverageReport:step3Description2')}</div>
      </div>
      <GapCoverageReportVehicleApp
        selectedMakes={values.exportApplication.makes || []}
        selectMake={selectMake}
        selectedModels={values.exportApplication.models || []}
        selectModel={selectModel}
        years={years}
        makes={resources.makes}
        models={resources.models}
        rYears={resources.years}
        vehicleGroups={resources.model_type_groups}
        vehicleTypes={resources.model_types}
        selectedYears={values.exportApplication.years || []}
        selectYears={selectYears}
        selectedTypes={values.exportApplication.vehicle_types || []}
        selectType={selectType}
        selectedGroups={values.exportApplication.vehicle_type_groups || []}
        selectGroup={selectGroup}
        fetchingYears={fetchingYears}
        handleClearSelection={handleClearSelection}
      />
    </div>
  );
};

export default GapCoverageReportStep3;
