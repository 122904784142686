export function hasPermission(user, permission) {
  return user.permissions.includes(permission);
}

export function hasChannelDBPerm(user) {
  return hasPermission(user, 'can_manage_channels');
}

export const hasExportPerm = user => {
  const hasExportAccess = hasPermission(user, 'has_export_access');
  const hasExportPermission = [
    'can_export_xml',
    'distributing_to_summit',
    'can_export_all_products',
    'can_export_excel_prices',
  ].some(perm => hasPermission(user, perm));
  return hasExportAccess && hasExportPermission;
};

export function hasAccountPermission(permissionGroups, permission) {
  return !!permissionGroups.find(group =>
    group.permissions.find(({ code }) => code === permission)
  );
}

export function runningOnGoOnlyApi(user) {
  return (
    process.env.REACT_APP_API_ROOT.includes('https://qa-api') ||
    hasPermission(user, 'running_on_go_only_api')
  );
}
