import React from 'react';
import { Button, Modal, Input, Spin } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MarketingCopyPreview from '../../components/body/media_and_marketing_copy/MarketingCopyPreview';
import {
  fetchMarketingCopiesOfParent,
  fetchMarketingCopyBrands,
  fetchMarketingCopyPartTypes,
  fetchMarketingCopyGroups,
} from '../../actions/parent/marketing_copy/fetch';
import { setSelectedMarketingCopyId } from '../../actions/parent/marketing_copy/update';
import { ApplicationState } from '../../reducers';
import MarketingCopyEditDrawer from '../../components/body/media_and_marketing_copy/MarketingCopyEditDrawer';
import { MarketingCopy } from '../../../types/marketing_copy';
import { withContainerWrapper } from '../ContainerWrapper';
import MarketingCopyEditGroupDrawer from '../../components/body/media_and_marketing_copy/MarketingCopyEditGroupDrawer';
import marketingCopyTranslation from '../../constants/MarketingCopyTranslation.json';
import generalTranslation from '../../constants/GeneralTranslation.json';
import marketingCopyActions from '../../actions/parent/marketing_copy';
import { showUpgradePlanNotification } from '../../actions/app/modal';
import { typingDone } from '../../utils/Utils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';

const { confirm } = Modal;

const MarketingCopyContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    fetchingItemCount,
    marketingCopies,
    selectedMarketingCopyId,
    user,
    fetchingMarketingCopies,
  } = useSelector((state: ApplicationState) => {
    return {
      marketingCopies: state.parent.marketingCopy.marketingCopies,
      selectedMarketingCopyId: state.parent.marketingCopy.selectedMarketingCopyId,
      user: state.user.user,
      fetchingMarketingCopies: state.parent.marketingCopy.fetchingMarketingCopies,
      fetchingItemCount: state.parent.marketingCopy.fetchingItemCount,
    };
  }, shallowEqual);

  const [marketingCopyDrawerOpen, setMarketingCopyDrawerOpen] = React.useState(false);
  const [editGroupDrawerOpen, setEditGroupDrawerOpen] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');

  const fetchMarketingCopies = React.useCallback(
    (keywords?: string) => dispatch(fetchMarketingCopiesOfParent({ keywords })),
    [dispatch]
  );

  const handleKeywordSearch = (keywords: string) => {
    setKeywordSearch(keywords);

    typingDone(() => fetchMarketingCopies(keywords));
  };

  React.useEffect(() => {
    intercomEvent('viewed-company-marketing-copy');
  }, []);

  React.useEffect(() => {
    fetchMarketingCopies();
    dispatch(fetchMarketingCopyBrands());
    dispatch(fetchMarketingCopyPartTypes());
    dispatch(fetchMarketingCopyGroups());
  }, [dispatch, fetchMarketingCopies]);

  const canManageMarketingCopy = hasPermission(user, 'can_manage_marketing_copy');

  const selectMarketingCopyId = (id: number | null) => {
    dispatch(setSelectedMarketingCopyId(id));
  };

  const handleGroupDrawerOpening = () => {
    if (!canManageMarketingCopy) dispatch(showUpgradePlanNotification());
    setEditGroupDrawerOpen(true);
  };

  const handleMarketingCopyDrawerOpening = (id?: number) => {
    if (!canManageMarketingCopy) dispatch(showUpgradePlanNotification());
    setMarketingCopyDrawerOpen(true);

    intercomEvent('viewed-company-marketing-copy', {
      action: id ? 'marketing-copy-new' : 'marketing-copy-edit',
    });
  };

  const deleteMarketingCopy = (marketingCopyId: number) => {
    intercomEvent('viewed-company-marketing-copy', {
      action: 'marketing-copy-delete',
    });

    if (canManageMarketingCopy) {
      confirm({
        title: marketingCopyTranslation.placeholder.doYouWantToDelete,
        okText: generalTranslation.delete,
        okButtonProps: { danger: true },
        onOk() {
          dispatch(marketingCopyActions.deleteMarketingCopy(marketingCopyId));
        },
      });
    } else {
      dispatch(showUpgradePlanNotification());
    }
  };

  return (
    <div className="page-layout">
      <div className="page-layout__top-bar">
        <div className="page-layout__top-bar__container">
          <Input.Search
            className="page-layout__top-bar__search"
            value={keywordSearch}
            onChange={e => handleKeywordSearch(e.target.value)}
            placeholder={t('settings:marketingCopy.searchMarketingCopy')}
            allowClear
          />
          <div className="page-layout__top-bar__actions">
            <Button onClick={() => handleGroupDrawerOpening()} className="manage-groups-action">
              {marketingCopyTranslation.actions.manageGroups}
            </Button>
            <Button
              type="primary"
              onClick={() => handleMarketingCopyDrawerOpening()}
              data-testid="add-mc"
            >
              {marketingCopyTranslation.actions.addNewMc}
            </Button>
          </div>
        </div>
      </div>
      <div className="page-layout__content">
        {fetchingMarketingCopies && <Spin className="mt-10 spinner-center" />}
        {!fetchingMarketingCopies && (
          <div className="marketing-copy-container">
            {marketingCopies.map((marketingCopy: MarketingCopy) => (
              <MarketingCopyPreview
                key={marketingCopy.id}
                marketingCopy={marketingCopy}
                handleOpenMarketingCopyDrawer={({ id }: { bool: boolean; id: number }) => {
                  handleMarketingCopyDrawerOpening(id);
                  selectMarketingCopyId(id);
                }}
                handleDeleteMarketingCopy={deleteMarketingCopy}
                size="small"
                showDelete
                fetchingItemCount={fetchingItemCount}
              />
            ))}
          </div>
        )}
        {marketingCopyDrawerOpen && (
          <MarketingCopyEditDrawer
            visible={marketingCopyDrawerOpen}
            isNew={!selectedMarketingCopyId}
            canManageMarketingCopy={canManageMarketingCopy}
            marketingCopy={marketingCopies.find(
              (marketingCopy: MarketingCopy) => marketingCopy.id === selectedMarketingCopyId
            )}
            closeDrawer={() => {
              setMarketingCopyDrawerOpen(false);
              setSelectedMarketingCopyId(null);
            }}
            alert
          />
        )}
        <MarketingCopyEditGroupDrawer
          isOpen={editGroupDrawerOpen}
          canManageMarketingCopy={canManageMarketingCopy}
          closeDrawer={() => setEditGroupDrawerOpen(false)}
        />
      </div>
    </div>
  );
};
export { MarketingCopyContainer };
export default withContainerWrapper(MarketingCopyContainer);
