import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Button, message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';

import FormPasswordInput from '../components/global/Forms/FormPasswordInput';
import { AsyncDispatch } from '../../types/global';
import { resetPassword } from '../actions/user';
import LoginFooter from './LoginFooter';
import { withRouter, WithRouterProps } from '../containers/withRouter';

type ResetPasswordProps = {
  dispatch: AsyncDispatch;
  token: string;
} & WithRouterProps &
  WithTranslation;

type ResetPasswordState = {
  submitted: boolean;
};

export class ResetPasswordPage extends React.Component<ResetPasswordProps, ResetPasswordState> {
  state = {
    submitted: false,
  };

  getValidationSchema = () => {
    const { t } = this.props;

    return Yup.object().shape({
      password: Yup.string()
        .min(6, t('validation:passwordLength'))
        .required(t('validation:required')),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password')], t('validation:passwordsDontMatch'))
        .required(t('validation:required')),
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="login__background">
        <div className="login__box">
          <div className="login__box-header">
            <div className="login-logo" />
          </div>
          <div className="login__box-content">
            <div className="login--header-content">
              <div className="login__title">{t('login:resetPassword')}</div>
              <div>{t('login:createNewPassword')}</div>
            </div>
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: '',
              }}
              validationSchema={this.getValidationSchema()}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                this.props
                  .dispatch(resetPassword(this.props.token, values.password))
                  .then(() => {
                    message.success(t('login:passwordResetSuccess'));
                    setSubmitting(false);
                    this.setState({ submitted: true });
                    // Redirect to login
                    setTimeout(() => this.props.navigate('/'), 3000);
                  })
                  .catch(() => {
                    message.error(t('login:passwordResetFailed'));
                    setSubmitting(false);
                  });
              }}
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form>
                  <FormPasswordInput
                    disabled={this.state.submitted}
                    name="password"
                    placeholder={t('common:password')}
                    required
                    size="large"
                  />
                  <FormPasswordInput
                    disabled={this.state.submitted}
                    name="passwordConfirm"
                    placeholder={t('login:confirmPassword')}
                    size="large"
                    required
                  />

                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    disabled={!(isValid && dirty) || isSubmitting || this.state.submitted}
                    loading={isSubmitting}
                    style={{ width: '100%', marginTop: 15, marginBottom: 10 }}
                    size="large"
                  >
                    {t('common:confirm')}
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-5">
              <Link className="register__login-text" to="/">
                {t('login:backToLogin')}
              </Link>
            </div>
          </div>
        </div>

        <LoginFooter />
      </div>
    );
  }
}

export default withRouter(connect(null)(withTranslation()(ResetPasswordPage) as any));
