import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Brand } from '../../../types/brand';

export type BrandState = {
  readonly fetchingBrands: boolean;
  readonly brands: Brand[];
  readonly selectedBrandId: any;
};

const initialState: BrandState = {
  fetchingBrands: false,
  brands: [],
  selectedBrandId: undefined,
};

const reducer: Reducer<BrandState, FluxStandardAction> = (
  state = initialState,
  action
): BrandState => {
  switch (action.type) {
    case 'FETCH_BRANDS_PENDING': {
      return { ...state, fetchingBrands: true };
    }
    case 'FETCH_BRANDS_FULFILLED': {
      return { ...state, brands: action.payload.data, fetchingBrands: false };
    }
    case 'SELECT_BRAND': {
      return { ...state, selectedBrandId: action.payload.id };
    }
    case 'UPDATE_BRANDS_FULFILLED': {
      return { ...state, brands: action.payload.data };
    }
  }
  return state;
};

export default reducer;
