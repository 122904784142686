import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Form, Radio, Space, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AsyncDispatch } from '../../../../types/global';
import { ScheduledImports } from '../../../../types/import_export';
import { updateScheduledImports } from '../../../actions/brand/import_export/update';
import { ApplicationState } from '../../../reducers';
import { StandardResource, StandardResourceCodeDescription } from '../../../../types/resources';
import AntTooltip from '../../global/AntTooltip';
import PageFormik from '../../global/page/PageFormik';

type AutomateImportFormProps = {
  brandCode?: string;
  brandId: number;
  scheduledImports: ScheduledImports;
};

const AutomateImportForm: React.FC<AutomateImportFormProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const { deliveryFrequencies } = useSelector((state: ApplicationState) => ({
    deliveryFrequencies: state.resources.data.channel.delivery_frequencies,
  }));

  const productImportOptions = [
    { id: 1, name: t('importer:keepUpdate') },
    { id: 2, name: t('importer:replace') },
  ];

  return (
    <PageFormik
      initialValues={{
        deliveryFrequencyId: props.scheduledImports.delivery_frequency_id || null,
        inactive: props.scheduledImports.inactive || 0,
        deleteOnPies: props.scheduledImports.delete_on_pies,
      }}
      onSubmit={(values, actions) => {
        const { setSubmitPending, setSubmitSuccess, setSubmitError } = actions;
        setSubmitPending();
        dispatch(
          updateScheduledImports({
            ...props.scheduledImports,
            inactive: values.inactive,
            delivery_frequency_id: values.deliveryFrequencyId,
            delete_on_pies: values.deleteOnPies,
            brand_id: props.brandId,
          })
        )
          .then(() => {
            setSubmitSuccess();
          })
          .catch(() => setSubmitError());
      }}
      enableReinitialize
      contentNoScroll
    >
      {({ values, setFieldValue }) => (
        <Form layout="vertical" className="automate-import-form">
          <Form.Item
            label={
              <div className="flex space-x-2">
                <div className="font-medium">{t('importer:checkForFileUpdates')}</div>
                <AntTooltip title={t('importer:fileUpdatesInfo')} className="self-center pr-2">
                  <InfoCircleOutlined className="ant-info-tooltip" />
                </AntTooltip>
              </div>
            }
          >
            <Radio.Group
              value={values.deliveryFrequencyId}
              onChange={e => setFieldValue('deliveryFrequencyId', e.target.value)}
            >
              <Space direction="vertical">
                {deliveryFrequencies.map((value: StandardResourceCodeDescription) => (
                  <Radio key={value.id} value={value.id}>
                    {value.name}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <div className="flex space-x-2">
                <div className="font-medium">{t('importer:productImport')}</div>
                <AntTooltip
                  title={
                    <div className="space-y-6">
                      <div>{t('importer:productImportInfo1')}</div>
                      <div>{t('importer:productImportInfo2')}</div>
                    </div>
                  }
                  className="self-center pr-2"
                >
                  <InfoCircleOutlined className="ant-info-tooltip" />
                </AntTooltip>
              </div>
            }
          >
            <Radio.Group
              value={values.deleteOnPies === 1 ? 2 : 1}
              onChange={e => setFieldValue('deleteOnPies', e.target.value === 1 ? 0 : 1)}
            >
              <Space direction="vertical">
                {productImportOptions.map((value: StandardResource) => (
                  <Radio key={value.id} value={value.id}>
                    {value.name}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <div className="flex space-x-6 items-center">
                <div className="font-medium">{t('importer:activate')}</div>
                <Switch
                  checked={values.inactive === 0}
                  onChange={checked => setFieldValue('inactive', checked ? 0 : 1)}
                  size="small"
                />
              </div>
            }
          >
            <Alert
              className="w-3/4"
              message={t('importer:warnInfo', { brandCode: props.brandCode })}
              type="info"
            />
          </Form.Item>
        </Form>
      )}
    </PageFormik>
  );
};

export default AutomateImportForm;
