import { Popover, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { AttributeTranslation } from '../../../../types/attributes';
import { UpdateLanguageSetting } from '../../../../types/language';
import { ExtendedResources } from '../../../../types/resources';
import { ApplicationState } from '../../../reducers';

type AttributeTranslationTableProps = {
  type?: string;
  translations: AttributeTranslation[];
  unitTranslations?: AttributeTranslation[];
};

const AttributeTranslationTable: React.FC<AttributeTranslationTableProps> = props => {
  const { translationLanguages, fetchingTranslations } = useSelector((state: ApplicationState) => {
    const languages: ExtendedResources[] = state.resources.data.global.languages;
    const accountLanguages: UpdateLanguageSetting[] =
      state.parent.languageSettings.accountLanguages;

    const mappedLanguageNameTranslations = accountLanguages.map(a => {
      return { ...a, languageName: languages.find(l => l.id === Number(a.language_id))?.name };
    });
    return {
      translationLanguages: mappedLanguageNameTranslations,
      fetchingTranslations: state.parent.languageTranslation.fetchingTransLations,
    };
  });

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const translationLanguage = translationLanguages[index];
    const translation = props.translations.find(
      at => Number(at.language_id) === translationLanguage.language_id
    );
    const unit = props.unitTranslations?.find(
      ut => Number(ut.language_id) === translationLanguage.language_id
    );

    const showUnitTranslationTypes = ['drawerEditValue', 'editValue'];
    return (
      <div key={key} style={style} className="attributes-table___border flex items-center">
        <div className="flex px-2 items-center" style={{ width: '100%' }}>
          <div className="flex-1">{translationLanguage.languageName}</div>
          <Popover content={<div>{translation?.value}</div>} placement="left">
            <div className="flex-1 truncate whitespace-nowrap">{translation?.value}</div>
          </Popover>
          {props.type && showUnitTranslationTypes.includes(props.type) && (
            <div className="flex-1">{unit?.value}</div>
          )}
        </div>
      </div>
    );
  };

  if (fetchingTranslations) return <Spin size="small" className="spinner-center" />;

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <List
            width={width}
            height={height}
            rowCount={translationLanguages.length}
            rowHeight={30}
            rowRenderer={rowRenderer}
          />
        );
      }}
    </AutoSizer>
  );
};

export default AttributeTranslationTable;
