import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import constants from '../../constants/SegmentCheckboxes.json';

export default class SegmentCheckboxes extends React.Component {
  handleCheckAllSegments = () => {
    const { segments, selectedSegmentIds } = this.props;
    const selected =
      selectedSegmentIds.length === segments.length ? [] : segments.map(segment => segment.id);
    this.props.onChange(selected);
  };

  render() {
    const { className, segments, selectedSegmentIds, disabled, onChange } = this.props;
    const segmentList = segments.map(segment => ({ value: segment.id, label: segment.title }));

    return (
      <div className={className}>
        <Checkbox
          className="segment-checkboxes__select-all"
          indeterminate={
            selectedSegmentIds.length > 0 && selectedSegmentIds.length < segments.length
          }
          onChange={this.handleCheckAllSegments}
          checked={selectedSegmentIds.length === segments.length}
          disabled={disabled}
        >
          {constants.all_segments}
        </Checkbox>
        <Checkbox.Group
          className="segment-checkboxes__group"
          options={segmentList}
          value={selectedSegmentIds}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

SegmentCheckboxes.propTypes = {
  // string that is additionally added to default className
  className: PropTypes.string,
  // array => List of segments
  segments: PropTypes.array,
  // array => List of checked elements (returned by Checkbox.Group onChange)
  selectedSegmentIds: PropTypes.array,
  onChange: PropTypes.func,
};

SegmentCheckboxes.defaultProps = {
  segments: [],
  selectedSegmentIds: [],
  onChange: () => {},
  className: '',
};
