import React, { useMemo, useRef } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import { useTranslation } from 'react-i18next';

type JoditHtmlEditorProps = {
  htmlText?: string;
  readonly?: boolean;
  height?: number;
  dirty?: boolean;
  handleOnChange?: (content: string) => void;
};

const JoditHtmlEditor: React.FC<JoditHtmlEditorProps> = ({
  htmlText,
  readonly,
  height,
  dirty,
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const editor = useRef<Jodit>(null);

  const [editorContent, setEditorContent] = React.useState<string>(htmlText || '');
  const [initialized, setInitialized] = React.useState(!htmlText);
  const [prevDirty, setPrevDirty] = React.useState(dirty);

  React.useEffect(() => {
    if (!dirty && prevDirty && initialized) {
      setInitialized(false);
      setEditorContent(htmlText || '');
    }
    if (dirty !== prevDirty) setPrevDirty(dirty);
  }, [htmlText, dirty, initialized, prevDirty]);

  const config = useMemo(
    () => ({
      readonly: readonly || false, // all options from https://xdsoft.net/jodit/docs/,
      height: height || 290,
      showWordsCounter: false,
      hidePoweredByJodit: true,
      placeholder: t('settings:customFields.textEditor'),
      disablePlugins: 'fullsize,wrap-nodes,preview,print',
      cleanHTML: {
        fillEmptyParagraph: false,
      },
    }),
    [height, readonly, t]
  );

  return (
    <div className="cfs-wysiwyg-editor-wrapper">
      <JoditEditor
        ref={editor}
        className="cfs-jodit-editor"
        value={editorContent}
        config={config}
        onBlur={(value: string) => {}}
        onChange={(newContent: string) => {
          // self closing tags get changed by the editor e.g. <img /> to <img> -this should be not tracked as change
          if (!initialized) setInitialized(true);
          else if (handleOnChange) handleOnChange(newContent);
          setEditorContent(newContent);
        }}
      />
    </div>
  );
};

export default JoditHtmlEditor;
