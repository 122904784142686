import type { UniqueIdentifier } from '@dnd-kit/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { arrayMove } from '@dnd-kit/sortable';
import { CustomFieldGroup } from '../../../types/custom_fields';

import type { FlattenedCustomFieldGroup } from './CustomFieldsTree';

export function getProjection(
  items: FlattenedCustomFieldGroup[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const projectedDepth = activeItem.depth;
  const maxDepth = activeItem.depth;
  const minDepth = activeItem.depth;
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) depth = maxDepth;
  else if (projectedDepth < minDepth) depth = minDepth;

  return { depth: activeItem.depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) return null;
    if (depth === previousItem.depth) return previousItem.parentId;
    if (depth > previousItem.depth) return previousItem.id;

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item: any) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function flatten(
  items: any[],
  parentId: UniqueIdentifier | null = null,
  depth = 0
): FlattenedCustomFieldGroup[] {
  return items.reduce<FlattenedCustomFieldGroup[]>((acc, item, index) => {
    return [
      ...acc,
      {
        ...item,
        parentId,
        depth,
        index,
      },
      ...flatten(item.fields || [], item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree(items: CustomFieldGroup[]): FlattenedCustomFieldGroup[] {
  return flatten(items);
}

export function buildTree(flattenedItems: FlattenedCustomFieldGroup[]): CustomFieldGroup[] {
  const root: any = { id: 'root', name: 'root', fields: [] };
  const nodes: Record<string | number, CustomFieldGroup> = { [root.id]: root };
  const items = flattenedItems.map(item => ({ ...item, fields: [] }));

  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    const { id, fields } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? items.find(({ id }) => id === parentId);

    // @ts-ignore
    nodes[id] = { id, fields };
    parent.fields.push(item as any);
  }

  return root.fields as any;
}

export function setProperty<T extends keyof CustomFieldGroup>(
  items: CustomFieldGroup[],
  id: UniqueIdentifier,
  property: T,
  setter: (value: CustomFieldGroup[T]) => CustomFieldGroup[T]
) {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
    }
  }

  return [...items];
}

export function removeChildrenOf(items: FlattenedCustomFieldGroup[], ids: UniqueIdentifier[]) {
  const excludeParentIds = [...ids];

  return items.filter(item => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.fields?.length) excludeParentIds.push(item.id);

      return false;
    }

    return true;
  });
}
