import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { downloadFileFromUrl } from '../../shared/fetch';

const CancelToken = axios.CancelToken;
let fetchCancelAppYearsToken: any;

export function fetchImportLogs({
  brandId,
  sourceIds,
  page = 1,
  limit,
}: {
  brandId: number;
  sourceIds?: number[];
  page?: number;
  limit?: number;
}) {
  const params = utils.createURL([
    { name: 'brand_ids', values: brandId },
    { name: 'source_ids', values: sourceIds || [] },
    { name: 'page', values: page },
    { name: 'limit', values: limit },
  ]);

  return {
    type: 'FETCH_IMPORT_LOGS',
    payload: axios.get(`${getNonGwApiRoot()}/imports/logs${params}`),
    meta: { page },
  };
}

export function fetchImportLogDetailsJSON(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'json' }]);
  return {
    type: 'FETCH_IMPORT_LOGS_DETAILS_JSON',
    payload: axios.get(`${getApiRoot()}/imports/logs/${logId}/errors${params}`),
  };
}

export function fetchImportLogsDetailsExcel(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'excel' }]);
  return {
    type: 'FETCH_IMPORT_LOGS_DETAILS_EXCEL',
    payload: axios({
      method: 'get',
      url: `${getApiRoot()}/imports/logs/${logId}/errors${params}`,
      responseType: 'arraybuffer',
    }),
  };
}

export function fetchExportLogs({
  brandId,
  fileTypeIds,
  page = 1,
  limit,
}: {
  brandId?: number;
  fileTypeIds?: number[];
  page?: number;
  limit?: number;
}) {
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'file_type_ids', values: fileTypeIds || [] },
    { name: 'page', values: page },
    { name: 'limit', values: limit || 200 },
  ]);

  return {
    type: 'FETCH_EXPORT_LOGS',
    payload: axios.get(`${getNonGwApiRoot()}/exports/logs${params}`),
    meta: { page },
  };
}

export function fetchExportLogsDetailsExcel(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'excel' }]);
  return {
    type: 'FETCH_EXPORT_LOGS_DETAILS_EXCEL',
    payload: axios({
      method: 'get',
      url: `${getApiRoot()}/exports/logs/${logId}/errors${params}`,
      responseType: 'arraybuffer',
    }),
  };
}

export function fetchExportLogDetailsJSON(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'json' }]);
  return {
    type: 'FETCH_EXPORT_LOGS_DETAILS_JSON',
    payload: axios.get(`${getApiRoot()}/exports/logs/${logId}/errors${params}`),
    meta: { logId },
  };
}

export function fetchExport(logId: number) {
  return (dispatch: any, getState: any) => {
    const fileName = getState().brand.importExport.exportLogs.find((log: any) => log.id === logId);

    dispatch({
      type: 'FETCH_EXPORT',
      meta: { fileName: fileName ? fileName.file_name : '', logId },
      payload: axios
        .get(`${getApiRoot()}/exports?export_log_id=${logId}`)
        .then(({ data: { file_link: fileUrl } }) => {
          dispatch(downloadFileFromUrl(fileUrl));
        }),
    });
  };
}

export function fetchImportBrandStatus(brandId: number) {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_IMPORT_BRAND_STATUS',
    payload: axios.get(`${getNonGwApiRoot()}/imports/statuses${params}`),
  };
}

export function fetchImport(logId: number) {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_IMPORT',
      meta: { logId },
      payload: axios
        .get(`${getApiRoot()}/imports?import_log_id=${logId}`)
        .then(({ data: { file_link: fileUrl } }) => {
          dispatch(downloadFileFromUrl(fileUrl));
        }),
    });
  };
}

export function fetchGapCoverageApplicationYears(makeId?: number, modelId?: number) {
  if (fetchCancelAppYearsToken) fetchCancelAppYearsToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: [] },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_GAP_COVERAGE_APPLICATION_YEARS',
    payload: axios.get(`${getNonGwApiRoot()}/vehicles/bases/years${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelAppYearsToken = c;
      }),
    }),
  };
}

export function fetchScheduledImports(brandId: number) {
  const urlParams = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_SCHEDULED_IMPORTS',
    payload: axios.get(`${getApiRoot()}/imports/scheduled_imports${urlParams}`),
  };
}

export function fetchImportOptions(brandId?: number) {
  const urlParams = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_IMPORT_OPTIONS',
    payload: axios.get(
      `${getNonGwApiRoot()}/parents/owners/brands/file_import_options${urlParams}`
    ),
  };
}
