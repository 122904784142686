import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

type InfoTooltipProps = {
  className?: string;
  overlay?: React.ReactNode;
} & TooltipProps;

const InfoTooltip: React.FC<InfoTooltipProps> = ({ className, overlay, ...props }) => {
  return (
    <Tooltip className={className} overlay={overlay} {...props}>
      <InfoCircleOutlined className="ant-info-tooltip" />
    </Tooltip>
  );
};

export default InfoTooltip;
