import React from 'react';
import Dropdown from '../../global/Dropdown';
import constants from '../../../constants/AnalyseConstants.json';

class AnalyseQualityHistoryInterval extends React.Component {
  handleDateRangeChange = element => {
    let days = 14;
    switch (element) {
      case '1':
        days = 14;
        break;
      case '2':
        days = 90;
        break;
      case '3':
        days = 365;
        break;
      case '4':
        days = this.props.allTimeDays;
        break;
      default:
        days = 14;
    }

    this.props.handleDateRangeChange(days);
  };

  render() {
    return (
      <span className="analyse-quality-history__interval-dropdown-container">
        <Dropdown
          className="analyse-quality-history__interval-dropdown"
          elements={constants.intervalOptions}
          onChange={this.handleDateRangeChange}
        />
      </span>
    );
  }
}

export default AnalyseQualityHistoryInterval;
