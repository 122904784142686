import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteDigitalAssetDescription(descriptionId) {
  return {
    type: 'DELETE_DIGITAL_ASSET_DESCRIPTION',
    meta: { descriptionId },
    payload: axios.delete(`${getApiRoot()}/items/digital_assets/descriptions/${descriptionId}`),
  };
}

export function deleteDigitalAsset(itemId, assetId) {
  return {
    type: 'DELETE_DIGITAL_ASSET',
    meta: { assetId },
    payload: axios.delete(`${getApiRoot()}/items/digital_assets/${assetId}`),
  };
}
