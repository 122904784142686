import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CloseOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Button from '../../global/Button';
import FilterDropdown from '../../header/FilterDropdown';
import constants from '../../../constants/ItemTranslation.json';
import { typingDone } from '../../../utils/Utils';

export default class ItemCategory extends React.Component {
  constructor(props) {
    super(props);
    this.rows = {};
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.forceUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    ReactTooltip.hide();
    const heights = {};

    if (this.props.categoryRowHeights !== undefined) {
      this.props.categories.forEach(category => {
        const row = this.rows[category.id];
        if (row) {
          heights[category.id] = { rowHeigth: row.offsetHeight };
        }
      });

      let equal = true;
      if (this.props.categoryRowHeights) {
        if (
          Object.keys(heights).toString() === Object.keys(this.props.categoryRowHeights).toString()
        ) {
          Object.keys(heights).some(id => {
            if (heights[id].rowHeigth !== this.props.categoryRowHeights[id].rowHeigth) {
              equal = false;
              return true;
            }
          });
        } else {
          equal = false;
        }
      } else {
        equal = false;
      }
      if (!equal) {
        this.props.setCategoryRowHeights(heights);
      }
    }
  }

  handleResize = () => {
    this.forceUpdate();
  };

  handleSearch = event => {
    const input = event.target.value;
    typingDone(() => this.props.filter(input));
  };

  handleClick = category => {
    this.props.submit(category);
  };

  handleChooseButtonClick = category => {
    this.props.updateCategory(category);
  };

  clearSearchInput = () => {
    this.filterInput.value = '';
    this.filterInput.focus();
    this.props.filter('');
  };

  fetchNext = e => this.props.fetchNext(e, this.props.level);

  disableInput = e =>
    e && !e.length > 0 && this.filterInput && !this.filterInput.value ? ' disabled' : '';

  borderLeft = () => {
    if (
      this.props.categories &&
      this.props.categories.length > 0 &&
      (this.props.categories[0].sub_category_name || this.props.categories[0].part_type_name)
    ) {
      return ' category__border';
    }
    return '';
  };

  infoIcon = category => {
    if (category.description_id > 1) {
      return (
        <div className="item__info">
          <InfoCircleOutlined />
        </div>
      );
    }
  };

  itemCount = category => {
    if (category.used_by_brand) {
      return (
        <div className="item__count_container">
          <div className="item__icon_wrapper">
            <div className="item__count">{category.used_by_brand}</div>
          </div>
        </div>
      );
    }
  };

  chooseButton = category => {
    if (category.part_type_name || this.props.updateCategory) {
      return (
        <Button
          onClick={() => this.handleChooseButtonClick(category)}
          className="row__choose-button"
          name={constants.hover.choose}
          color="blue"
          hover
        />
      );
    }
  };

  parentCategory = category => {
    if (category.sub_category_name && !category.part_type_name) {
      return <span className="item__row_parent_name">{` / ${category.category_name}`}</span>;
    }
  };

  hoverBreadcrumb = category => {
    let offset = { top: 0 };
    if (this.props.categoryRowHeights && this.props.categoryRowHeights[category.id]) {
      if (this.props.categoryRowHeights[category.id].rowHeigth > 30) {
        offset = { top: (this.props.categoryRowHeights[category.id].rowHeigth / 30 - 1) * 15 };
      }
    }

    let style = {};
    const mappingArea = document.getElementById('mappingArea');
    let width;
    if (mappingArea) {
      width = mappingArea.offsetWidth;
    }
    if (this.props.width && this.props.width.width === '25%') {
      width = (width - 36) / 4; // remove padding to get the right size
    } else {
      width = (width - 36) * 0.33;
    }
    width = `${width.toString()}px`;
    style = { width };
    if (category.part_type_name) {
      return (
        <ReactTooltip
          id={category.id.toString()}
          place="left"
          class="category__breadcrumb_hover_wrapper "
          effect="solid"
          scrollHide
          offset={offset}
        >
          <div className="category__breadcrumb_hover" onClick={event => event.stopPropagation()}>
            <div className="category__breadcrumb_hover_name" style={style}>
              {category.category_name || constants.hover.no_category}
            </div>
            <div className="category__breadcrumb_hover_name" style={style}>
              {category.sub_category_name || constants.hover.no_category}
            </div>
          </div>
        </ReactTooltip>
      );
    }
  };

  categories = () =>
    this.props.categories.map(category => {
      const selected = this.props.selected && category.id === this.props.selected.id;

      return (
        <div
          key={category.id}
          ref={div => (this.rows[category.id] = div)}
          onClick={() => this.handleClick(category)}
          className={classNames('row item_row', { selected })}
          data-tip
          data-for={category.id.toString()}
        >
          {this.hoverBreadcrumb(category)}
          <div className="item__row_name">
            {category.part_type_name || category.sub_category_name || category.category_name}
            {`${
              this.props.showPartTypeId && category.part_type_id
                ? ` (${category.part_type_id})`
                : ''
            }`}
            {this.parentCategory(category)}
          </div>
          <div className="item__row_options">
            {this.itemCount(category)}
            {this.infoIcon(category)}
            {this.chooseButton(category)}
          </div>
        </div>
      );
    });

  render() {
    const { level } = this.props;

    return (
      <div className="float__left item__category_column" style={this.props.width}>
        <div className="item__title-container">
          <div className="item__title">{this.props.title}</div>
          {this.props.filterView && (
            <FilterDropdown
              optionId={this.props.optionId}
              updateFilterOption={values => this.props.updateFilterOption({ ...values, level })}
            />
          )}
        </div>
        <div className="item_input category__input">
          <SearchOutlined className="item__input_icon category__search_icon" />
          <input
            ref={input => (this.filterInput = input)}
            className={`item__input_text${this.disableInput(this.props.categories)}`}
            onChange={input => this.handleSearch(input)}
          />
          <CloseOutlined className="item__input_button" onClick={() => this.clearSearchInput()} />
        </div>
        <div
          onScroll={e => {
            this.fetchNext(e);
            ReactTooltip.hide();
          }}
          className={`pdm-table item_table box box_background${this.borderLeft()}`}
        >
          {this.categories()}
          {this.props.fetching ? <div className="loader" /> : false}
        </div>
      </div>
    );
  }
}

ItemCategory.defaultProps = {
  fetchNext: () => {},
};
