import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ExportLogsContainer from '../containers/import_export/ExportLogsContainer';
import ExportBuilderAdvancedContainer from '../containers/import_export/ExportBuilderAdvancedContainer';
import ExportContainer from '../containers/import_export/ExportContainer';
import ReceiverImportContainer from '../containers/import_export/ReceiverImportContainer';
import ReceiverImportLogsContainer from '../containers/import_export/ReceiverImportLogsContainer';

const ReceiverExportRoutes = () => (
  <Routes>
    <Route path="export-data" element={<ExportContainer />} />
    <Route path="export-logs" element={<ExportLogsContainer />} />
    <Route path="export-builder-advanced" element={<ExportBuilderAdvancedContainer />} />
    <Route path="import" element={<ReceiverImportContainer />} />
    <Route path="import-reports" element={<ReceiverImportLogsContainer />} />
  </Routes>
);

export default ReceiverExportRoutes;
