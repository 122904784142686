import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

import BrandRoutes from './BrandRoutes';
import CompanyRoutes from './CompanyRoutes';
import UserSettingRoutes from './UserSettingRoutes';
import ReceiverDataStreamRoutes from './ReceiverDataStreamRoutes';
import ChannelRoutes from './ChannelRoutes';
import BouncingLoader from '../components/global/BouncingLoader';
import { ApplicationState } from '../reducers';
import { isReceiver } from '../utils/UserUtils';
import App from '../components/App';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import VerifyAccountPage from '../pages/verification/VerifyAccountPage';
import VerificationSuccessPage from '../pages/verification/VerificationSuccessPage';
import VerifyEbaySuccessPage from '../pages/verification/VerifyEbaySuccessPage';

const AppRoutes: React.FC = () => {
  const { brands, user } = useSelector((state: ApplicationState) => {
    return {
      brands: state.parent.brands.brands,
      user: state.user.user,
    };
  });

  const receiver = user && isReceiver(user);

  const routes = (
    <React.Fragment>
      <Route path="/" element={<App />}>
        <Route path="available-brands/*" element={<ReceiverDataStreamRoutes />} />
        <Route
          path="brand/*"
          element={
            <React.Fragment>
              {receiver && <Navigate to={{ pathname: '/available-brands/all-brands' }} />}
              {brands.length > 0 && <BrandRoutes />}
              {brands.length === 0 && !receiver && <BouncingLoader />}
            </React.Fragment>
          }
        />
        <Route path="company/*" element={<CompanyRoutes />} />
        <Route path="distribution/*" element={<ChannelRoutes />} />
        <Route path="settings/*" element={<UserSettingRoutes />} />
        <Route path="reset-password" element={<App />} />
        <Route path="login/driv" element={<App />} />
        <Route path="sso" element={<App />} />
        <Route path="register/*" element={<App />} />
      </Route>
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="verifying" element={<VerifyAccountPage />} />
      <Route path="confirmed" element={<VerificationSuccessPage />} />
      <Route path="receiver_confirmed" element={<VerificationSuccessPage receiver />} />
      <Route path="ebayauth" element={<VerifyEbaySuccessPage />} />
    </React.Fragment>
  );

  const router = createBrowserRouter(createRoutesFromElements(routes));

  return <RouterProvider router={router} />;
};

export default AppRoutes;
