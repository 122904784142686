import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ApplicationQualifier, ApplicationQualifierValue } from '../../../../types/application';
import constants from '../../../constants/ApplicationTranslation.json';
import { splitQualifierText } from '../../../utils/QualifierUtils';

type ApplicationCardQualifierProps = {
  qualifiers: ApplicationQualifier[];
  configKey: string;
  selectConfig?: (config: string, defaultValue?: boolean) => void;
  noEdit?: boolean;
  defaultValue?: boolean;
  disableSelection?: boolean;
};

const ApplicationCardQualifier = (props: ApplicationCardQualifierProps) => {
  const { configKey, qualifiers, noEdit, defaultValue, disableSelection } = props;

  const parameters = (partsList: string[], values?: ApplicationQualifierValue[]) =>
    partsList.map((part, i) => {
      const re = /<p\d type="\D*?"\/>/g; // regex to match parameter like <p1 type="num"/>
      if (re.test(part)) {
        const valueId = part.match(/<p\d+? /)![0].slice(2, -1); // get id from parameter ("p1")
        const parameter = values?.find(value => value.record_number === Number(valueId));
        return (
          <span key={valueId} className="application__qualifier-value">
            {parameter && parameter.value ? `${parameter.value} ${parameter.meta_uom || ''}` : '_'}
          </span>
        );
      }
      return (
        <span key={`t${i}`} className="application__qualifier-text">
          {part}
        </span>
      );
    });

  const qualifierText = (qualifier: ApplicationQualifier) => {
    const parts = splitQualifierText(qualifier.qualifier_text);
    if (parts) return parameters(parts, qualifier.values);

    return qualifier.qualifier_text;
  };

  return (
    <div
      className={classNames({ application__card_config: !disableSelection })}
      onClick={() => props.selectConfig && props.selectConfig(configKey, defaultValue)}
    >
      <div className="application__card_label">
        <React.Fragment>
          {constants.qualifier}
          {defaultValue && (
            <span className="application__card_label-default">
              {` (${constants.default_prefix})`}
            </span>
          )}
        </React.Fragment>
      </div>
      <div className="application__overview-value-wrapper">
        {qualifiers.map(qualifier => (
          <div
            key={`${qualifier.qualifier_id}${qualifier.record_number}`}
            className="application__overview-value"
          >
            {qualifierText(qualifier)}
          </div>
        ))}
        {!noEdit && <EditOutlined className="application__card_config__edit-icon" />}
      </div>
    </div>
  );
};

export default ApplicationCardQualifier;
