import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import analyseConstants from '../../../constants/AnalyseConstants.json';
import analyseTranslation from '../../../constants/AnalyseTranslation.json';

const options = {
  responsive: true,
  legend: {
    display: false,
  },
  cutoutPercentage: 70,
  tooltips: {
    enabled: false,
  },
};

const generateData = (itemAllSum, itemTypeSum, color) => {
  const missingSum = itemAllSum - itemTypeSum;
  return {
    datasets: [
      {
        data: [itemTypeSum, missingSum],
        backgroundColor: [color, analyseConstants.color_code.white],
        hoverBackgroundColor: [color, analyseConstants.color_code.white],
        borderColor: analyseConstants.color_code.lightGrey,
        hoverBorderColor: null,
      },
    ],
  };
};

class AnalyseRecommendation extends React.Component {
  checkIfAnalysesExist = () => {
    if (Object.keys(this.props.recommendationDonutData).length === 0) {
      return (
        <span className="analyses-not-existing">{analyseTranslation.no_analyses_existing}</span>
      );
    }
  };

  navigationButton = (title, alertTypeId) => {
    return (
      <Button onClick={() => this.props.handleNavigation(alertTypeId)} size="small">
        {title}
      </Button>
    );
  };

  render() {
    // generate a empty donut if no analyes existing
    let dataAllRecommendation = generateData(1, 0);
    let dataWarnings = generateData(1, 0);
    let dataErrors = generateData(1, 0);
    let recommendationCount = '';
    let warningCount = '';
    let errorCount = '';
    const { t } = this.props;

    if (Object.keys(this.props.recommendationDonutData).length > 0) {
      const { recommendationDonutData, itemCount } = this.props;
      const {
        item_error_count: itemErrorCount,
        item_info_count: itemInfoCount,
        item_warning_count: itemWarningCount,
      } = recommendationDonutData;
      dataAllRecommendation = generateData(
        itemCount,
        itemInfoCount,
        analyseConstants.color_code.blue
      );
      dataWarnings = generateData(
        itemCount,
        itemWarningCount,
        analyseConstants.color_code.lightYellow
      );
      dataErrors = generateData(itemCount, itemErrorCount, analyseConstants.color_code.lightRed);
      recommendationCount = `${recommendationDonutData.item_info_count} / ${itemCount}`;
      warningCount = `${recommendationDonutData.item_warning_count} / ${itemCount}`;
      errorCount = `${recommendationDonutData.item_error_count} / ${itemCount}`;
    }
    return (
      <div className="analyse-recommendation analyse__border">
        <p className="title home-container__analyse-title">
          {analyseTranslation.recommendations}
          {this.checkIfAnalysesExist()}
        </p>
        <div className="analyse-recommendation__graphs flex">
          <div className="analyse-recommendation__single-graph">
            <p className="analyse-recommendation__single-graph-title">
              {analyseTranslation.recommendations}
            </p>
            {dataAllRecommendation && (
              <div className="analyse-recommendation__single-graph-wrapper">
                <Doughnut width={100} height={100} data={dataAllRecommendation} options={options} />
                <Tooltip
                  trigger="click"
                  title={this.navigationButton(t('homeContainer:showRecommendations'), 1)}
                  color="white"
                >
                  <span className="analyse-recommendation__single-graph-count-info analyse-recommendation__error">
                    {recommendationCount}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="analyse-recommendation__single-graph">
            <p className="analyse-recommendation__single-graph-title">
              {analyseTranslation.warnings}
            </p>
            {dataWarnings && (
              <div className="analyse-recommendation__single-graph-wrapper">
                <Doughnut width={100} height={100} data={dataWarnings} options={options} />
                <Tooltip
                  trigger="click"
                  title={this.navigationButton(t('homeContainer:showWarnings'), 2)}
                  color="white"
                >
                  <span className="analyse-recommendation__single-graph-count-info analyse-recommendation__error">
                    {warningCount}
                  </span>
                </Tooltip>
              </div>
            )}
            {/* will be needed in the future */}
            {/* <p className="analyse-recommendation__single-graph-text-info">
              <span className="icon-warning--info" />
              {this.props.recommendationDonutData && this.props.recommendationDonutData.item_warning_count}
            </p> */}
          </div>
          <div className="analyse-recommendation__single-graph">
            <p className="analyse-recommendation__single-graph-title">
              {analyseTranslation.errors}
            </p>
            {dataErrors && (
              <div className="analyse-recommendation__single-graph-wrapper">
                <Doughnut width={100} height={100} data={dataErrors} options={options} />
                <Tooltip
                  trigger="click"
                  title={this.navigationButton(t('homeContainer:showErrors'), 3)}
                  color="white"
                >
                  <span className="analyse-recommendation__single-graph-count-info analyse-recommendation__error">
                    {errorCount}
                  </span>
                </Tooltip>
              </div>
            )}
            {/* will be needed in the future */}
            {/* <p className="analyse-recommendation__single-graph-text-info">
              <span className="icon-warning--info" />
              {this.props.recommendationDonutData && this.props.recommendationDonutData.item_error_count}
            </p> */}
          </div>
        </div>
        {/* will be needed in the future */}
        {/* <AnalyseRecommendationItems items={this.props.recommendationItems} /> */}
      </div>
    );
  }
}

export default withTranslation()(AnalyseRecommendation);
