import React, { useState } from 'react';
import { Select, Spin, Empty } from 'antd';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { fetchItemsByKeyword } from '../../../actions/items/application/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { Item } from '../../../../types/item';
import { typingDone } from '../../../utils/Utils';
import { createBrandTitle, createShortBrandTitle } from '../../../utils/String';

type ScriptPartTypeSelectionProps = {
  selectedValue?: number;
  partNumber: string;
  brandCode: string;
  brandName: string;
  defaultWhiteLabelCode: string | null;
  onChange: (
    id: number,
    partNumber: string,
    brandCode: string,
    brandName: string,
    defaultWhiteLabelCode: string | null
  ) => void;
};

const ScriptPartTypeSelection: React.FC<ScriptPartTypeSelectionProps> = props => {
  const { selectedValue, onChange, partNumber, brandCode, brandName, defaultWhiteLabelCode } =
    props;

  const dispatch: AsyncDispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<Item[]>([]);

  const loadItemsByKeyword = (keyword: string, selectedItemId: number) => {
    typingDone(() => {
      setFetching(true);
      setItems([]);

      dispatch(fetchItemsByKeyword(null, [selectedItemId], keyword)).then(result => {
        setItems(result.value.data.items);
        setFetching(false);
      });
    });
  };

  const handleChange = (itemId: number) => {
    const item = items.find(item => item.id === itemId);
    onChange(
      itemId,
      item!.part_number,
      item!.brand_code,
      item!.brand_name!,
      item!.default_whitelabel_brand_code!
    );
  };

  const currentItems = open ? items : [{ id: selectedValue, part_number: partNumber } as Item];

  return (
    <Select
      value={selectedValue}
      onChange={value => handleChange(value)}
      notFoundContent={fetching ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      onSearch={value => loadItemsByKeyword(value, selectedValue!)}
      filterOption={false}
      size="small"
      showSearch
      onDropdownVisibleChange={open => setOpen(open)}
      dropdownMatchSelectWidth={400}
      id="select-part-type"
      placeholder={t('api:searchPartNumber')}
    >
      {currentItems.map((item: Item) => (
        <Select.Option
          key={item.id || 'temp'}
          value={item.id}
          title={`${createBrandTitle(
            item.brand_name!,
            item.brand_code,
            item.default_whitelabel_brand_code!
          )}: ${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`}
        >
          {!open &&
            `${createShortBrandTitle(brandName, brandCode, defaultWhiteLabelCode)}: ${partNumber}`}
          {open &&
            `${createShortBrandTitle(
              item.brand_name!,
              item.brand_code,
              item.default_whitelabel_brand_code!
            )}: ${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ScriptPartTypeSelection;
