import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ApplicationCategory, PartTypeCategory } from '../../../types/application';
import { Attribute, PartTypeAttribute } from '../../../types/attributes';

export type AttributeManagementState = {
  readonly fetchingCategories: boolean;
  readonly fetchingAttributes: boolean;
  readonly fetchingSearchAttributes: boolean;
  readonly partTypeCategories: PartTypeCategory[];
  readonly attributes: PartTypeAttribute[];
  readonly searchAttributes: Attribute[];
  readonly tags: string[];
  readonly selectedCategory: ApplicationCategory | undefined;
};

const initialState: AttributeManagementState = {
  fetchingCategories: false,
  fetchingAttributes: false,
  fetchingSearchAttributes: false,
  partTypeCategories: [],
  attributes: [],
  searchAttributes: [],
  tags: [],
  selectedCategory: undefined,
};

const reducer: Reducer<AttributeManagementState, FluxStandardAction> = (
  state = initialState,
  action
): AttributeManagementState => {
  switch (action.type) {
    case 'FETCH_ATTRIBUTE_PART_TYPE_CATEGORIES_PENDING': {
      const partTypeCategories = action.meta.page === 1 ? [] : state.partTypeCategories;
      return { ...state, partTypeCategories, fetchingCategories: true };
    }
    case 'FETCH_ATTRIBUTE_PART_TYPE_CATEGORIES_FULFILLED': {
      const newCategories =
        action.meta.page > 1
          ? [...state.partTypeCategories, ...action.payload.data[3]]
          : action.payload.data[3];
      return { ...state, fetchingCategories: false, partTypeCategories: newCategories };
    }
    case 'FETCH_ATTRIBUTE_TAGS_FULFILLED': {
      return { ...state, tags: action.payload.data.tags };
    }
    case 'FETCH_PART_TYPE_ATTRIBUTES_PENDING': {
      return {
        ...state,
        attributes: [],
        fetchingAttributes: true,
      };
    }
    case 'FETCH_PART_TYPE_ATTRIBUTES_FULFILLED': {
      return {
        ...state,
        attributes: action.payload.data.attributes,
        fetchingAttributes: false,
      };
    }
    case 'FETCH_ATTRIBUTES_BY_KEYWORDS_PENDING': {
      return {
        ...state,
        searchAttributes: [],
        fetchingSearchAttributes: true,
      };
    }
    case 'FETCH_ATTRIBUTES_BY_KEYWORDS_FULFILLED': {
      return {
        ...state,
        searchAttributes: action.payload.data,
        fetchingSearchAttributes: false,
      };
    }
    case 'UPDATE_PART_TYPE_ATTRIBUTES_FULFILLED': {
      return {
        ...state,
        attributes: action.meta.attributes,
      };
    }
    case 'SET_SELECTED_CATEGORY': {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
  }
  return state;
};

export default reducer;
