export const AllPackageInputStrings = [
  'uom_id',
  'level_gtin',
  'quantity_of_eaches',
  'electronic_product_code',
  'bar_code_characters',
  'inner_quantity',
  'inner_quantity_uom_id',
  'orderable_option_id',
  'height',
  'width',
  'length',
  'merchandising_height',
  'merchandising_width',
  'merchandising_length',
  'dimensions_uom_id',
  'weight',
  'weights_uom_id',
  'weight_variance',
  'stacking_factor',
];

export const PackageFields = [
  'quantity_of_eaches',
  'inner_quantity',
  'height',
  'dimensional_weight',
  'weight',
  'weight_variance',
  'merchandising_height',
  'orderable_option_id',
  'stacking_factor',
  'level_gtin',
  'electronic_product_code',
  'bar_code_characters',
];

export const HZFields = [
  'shipping_name',
  'language_id',
  'transport_method_id',
  'shipping_scope_id',
  'bulk_id',
  'regulating_country_id',
  'regulated_option_id',
  'regulations_exemption_code',
  'outer_package_label',
  'packing_group_id',
  'class_id',
  'label_id',
  'qualifier_id',
  'hazardous_material_description',
  'unnaid_code',
  'whmis_code_id',
  'whmis_free_text',
  'description',
  'text_message',
];
