import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';
import {
  CheckCircleOutlined,
  FileSearchOutlined,
  LinkOutlined,
  ReloadOutlined,
  StarOutlined,
  StarTwoTone,
  StarFilled,
  UpOutlined,
  DownOutlined,
  TagsOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Checkbox, Progress, Tag, Popconfirm, Spin, Modal, Button, Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { fillInPlaceholders } from '../../../utils/String.ts';
import { isAllFilter, isFilterEmpty } from '../../../utils/FilterUtils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { hasPermission } from '../../../utils/Permissions';
import AntPopover from '../../global/AntPopover.tsx';
import CatalogueActions from './CatalogueActions.tsx';
import CatalogueAddTag from './CatalogueAddTag';
import CatalogueSyncIcon from './CatalogueSyncIcon.tsx';
import CatalogueCopyProductDrawer from './CatalogueCopyProductDrawer';
import catalogueTranslations from '../../../constants/CatalogueTranslations.json';
import CatalogueExportModal from './CatalogueExportModal.tsx';
import EbayBulkListModal from './modals/EbayBulkListModal';
import EbayEndListModal from './modals/EbayEndListModal';
import DeleteItemsModal from './modals/DeleteItemsModal';
import { ExportTypes } from '../../../../types/import_export.ts';
import { NoProducts } from './NoProducts.tsx';
import CatalogueChannelSelect from './CatalogueChannelSelect.tsx';
import CustomIcon from '../../global/CustomIcon.tsx';
import CatalogueOrderSelect from './CatalogueOrderSelect.tsx';
import ImageThumbnail from '../../global/ImageThumbnail.tsx';
import { MULTI_EDIT_SEGMENTS } from '../../../constants/CatalogueConstants';

class CatalogueList extends React.Component {
  expandedTimeOut = undefined;

  state = {
    expandedItemListActive: false,
    selectedIndex: 0, // save index of selected item
    scrollIndex: undefined, // when list will collapse again set the state where to scroll to
    showCopyProductDrawer: false,
    showExportModal: false,
    showEbayBulkListModal: false,
    showEbayEndListModal: false,
    showDeleteItemsModal: false,
    updateEbayFitmentModal: false,
    exportType: undefined,
    hasFetchedItemsOnce: false,
  };

  componentDidUpdate(prevProps) {
    // preventing to scroll to index after new filter is selected
    if (
      prevProps.selectedFilterGo.id !== this.props.selectedFilterGo.id ||
      (!prevProps.catalogueExtended && this.props.catalogueExtended)
    ) {
      this.setState({ selectedIndex: 0, scrollIndex: undefined });
    }
    if (prevProps.catalogueExtended && !this.props.catalogueExtended) {
      this.setState(prevState => ({ scrollIndex: prevState.selectedIndex }));
    }
    if (!this.state.hasFetchedItemsOnce && prevProps.itemsFetching && !this.props.itemsFetching) {
      this.setState({ hasFetchedItemsOnce: true });
    }
  }

  handleItemListExpand = boolean => {
    clearTimeout(this.expandedTimeOut);
    if (!this.props.catalogueExtended && !this.props.catalougeIsResizing) {
      if (boolean) {
        this.expandedTimeOut = setTimeout(
          () => this.setState({ expandedItemListActive: true, scrollIndex: undefined }),
          1000
        );
      } else {
        this.setState(prevState => ({
          expandedItemListActive: false,
          scrollIndex: prevState.selectedIndex,
        }));
        this.expandedTimeOut = null;
      }
    }
  };

  handleMultiSelectItems = index => {
    const { selectedIndex } = this.state;
    const startIndex = selectedIndex < index ? selectedIndex : index;
    const endIndex = selectedIndex < index ? index : selectedIndex;
    const items = this.props.items
      .filter((item, i) => {
        if (startIndex <= i && endIndex >= i) {
          return item;
        }
        return false;
      })
      .map(({ id }) => id);
    this.props.handleMultiSelectItems(items, this.props.items[selectedIndex].id);
  };

  handleSelectItem = (e, index, item) => {
    if (e.shiftKey) {
      this.handleMultiSelectItems(index);
    } else if (e.metaKey || e.ctrlKey) {
      this.props.handleAddToSelectedItems(item.id);
      this.setState({ selectedIndex: index });
    } else {
      this.props.handleSelectItem(item.id);
      this.setState({ selectedIndex: index });
    }
  };

  handleCheckItem = (e, index, item) => {
    e.stopPropagation();
    if (this.props.selectedItemIds.length === 0) {
      this.props.handleSelectItem(item.id);
      this.setState({ selectedIndex: index });
    } else if (e.shiftKey) {
      this.handleMultiSelectItems(index);
    } else {
      this.props.handleAddToSelectedItems(item.id);
      this.setState({ selectedIndex: index });
    }
  };

  handleDeleteItems = () => {
    const { selectedItems } = this.props;

    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_item_actions',
      part_number: selectedItems.map(i => i.part_number).toString(),
      brand_code: selectedItems[0]?.brand_code,
    });

    this.setState({ showDeleteItemsModal: true });
  };

  handleExportClick = type => {
    if (type === ExportTypes.ASSET || type === ExportTypes.SUMMIT) {
      // no params needed
      this.props.handleStartExport({
        fileType: type,
      });
    } else {
      this.handleExportModalState();
    }
    this.setState({ exportType: type });
  };

  handleExportModalState = () => {
    this.setState(prevProps => ({ showExportModal: !prevProps.showExportModal }));
  };

  renderSelectedItemsInfo = () => {
    const {
      allItemsSelected,
      itemsSummary,
      selectedItemIds,
      reverseSelected,
      reversedItemIds,
      activeArea,
    } = this.props;
    let itemCount = 0;
    if (allItemsSelected || reverseSelected) {
      itemCount = reverseSelected ? itemsSummary - reversedItemIds.length : itemsSummary;
    } else {
      itemCount = selectedItemIds.length;
    }
    return (
      itemCount > 0 && (
        <div>
          <span className={classNames('catalogue-list-bar__selected-count mr-2', 'grey')}>
            {itemCount === 1
              ? catalogueTranslations.selectedProductsSingle
              : fillInPlaceholders(catalogueTranslations.selectedProductsMultiple, {
                  count: itemCount,
                })}
          </span>
          {MULTI_EDIT_SEGMENTS.includes(activeArea) && selectedItemIds.length > 1 && (
            <span className="catalogue-list__multi-edit">
              <Tag>
                <WarningOutlined /> {catalogueTranslations.activeMultiEditing}
              </Tag>
            </span>
          )}
        </div>
      )
    );
  };

  getIsBrandApproved = item =>
    !!this.props.authorizedBrands.find(({ id }) => id === item.parent_owner_brand_id);

  getProductShortName = item => {
    const shortName = item.alternative_short_name ? (
      <span>
        {item.alternative_short_name}
        <span className="catalogue-list-row__short-name-extra">
          {item.short_name ? ` (${item.short_name})` : ''}
        </span>
      </span>
    ) : (
      <span>{item.short_name}</span>
    );
    if (item.alternative_short_name || item.short_name) return shortName;
    return (
      <span className="catalogue-list-row__no-part-type">{catalogueTranslations.noPartType}</span>
    );
  };

  displayExternalLink = link =>
    link && (
      <a href={link} rel="noopener noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
        <CustomIcon icon="external-link" verticalAlign="text-top" />
      </a>
    );

  displayNothingFound = () => {
    const { selectedFilterGo, fetchFilter, filters } = this.props;
    const allFilter = filters.find(isAllFilter);

    if (selectedFilterGo.type_id === 2 && isFilterEmpty(selectedFilterGo))
      return (
        <div className="no-products">
          <h3 className="no-products__title">{catalogueTranslations.noMarkedProducts}</h3>
          <Button
            type="primary"
            size="small"
            className="no-products__button"
            onClick={() => fetchFilter({ filterId: allFilter.id })}
          >
            {catalogueTranslations.showAllProducts}
          </Button>
        </div>
      );

    return <NoProducts />;
  };

  displayChannelReceiverStatus = item => {
    if (this.props.isReceiver && !this.getIsBrandApproved(item)) return null;
    return (
      <React.Fragment>
        {item.hasOwnProperty('delivered') &&
          (item.delivered === 1 ? (
            <span className="catalogue-list-row__submitted-status">
              <CheckCircleOutlined
                className="catalogue-list-row__submitted-status-icon"
                style={{ color: '#52c41a' }}
              />
              {catalogueTranslations.catalogueList.submitted}
            </span>
          ) : (
            <span className="catalogue-list-row__submitted-status">
              <ReloadOutlined className="catalogue-list-row__submitted-status-icon" />
              {catalogueTranslations.catalogueList.notSubmitted}
            </span>
          ))}

        {item.hasOwnProperty('online') &&
          item.online !== null &&
          (item.online === 1 ? (
            <Tag color="green">
              {catalogueTranslations.catalogueList.online} {this.displayExternalLink(item.link)}
            </Tag>
          ) : (
            <Tag color="red">
              {catalogueTranslations.catalogueList.offline} {this.displayExternalLink(item.link)}
            </Tag>
          ))}
      </React.Fragment>
    );
  };

  render() {
    const rowReceiver = ({ index, key, style }) => {
      const { items, selectedItemIds, brandItemTags, allAccessableBrands } = this.props;
      const item = items[index];
      const isSelected = selectedItemIds.includes(item.id);
      const itemBrand = allAccessableBrands.find(brand => brand.id === item.parent_owner_brand_id);
      const brandIsApproved = this.getIsBrandApproved(item);
      return (
        <div
          key={key}
          className={classNames('catalogue-list-row', {
            'catalogue-list-row__is-selected': isSelected,
          })}
          style={style}
        >
          <div className="catalogue-list-row__select">
            <Checkbox
              className="catalogue-list-row__checkbox"
              checked={isSelected}
              onClick={e => {
                this.handleCheckItem(e, index, item);
              }}
            />
          </div>
          <div
            className="catalogue-list-row__inner-select catalogue-list-row__inner-receiver-select"
            onClick={e => this.handleSelectItem(e, index, item)}
          >
            {!item.thumbnail && item.uri ? (
              <LinkOutlined className="catalogue-list-row__icon" />
            ) : (
              <ImageThumbnail thumbnail={item.thumbnail} />
            )}
            <div className="catalogue-list-row__part-number">
              <Tooltip title={item.part_number} mouseEnterDelay={1}>
                {item.part_number}
              </Tooltip>
            </div>
            <div className="catalogue-list-row__short-name">{this.getProductShortName(item)}</div>

            <div className="flex items-center justify-between">
              <div>{itemBrand ? itemBrand.name : ''}</div>
              <div className="ml-2">{brandIsApproved && <Tag color="green">Approved</Tag>}</div>
            </div>

            <div className="flex items-center">{this.displayChannelReceiverStatus(item)}</div>

            <div className="catalogue-list-row__tags">
              {item.tag_ids && (
                <Tooltip
                  overlayClassName="catalogue-list-row__tag-tooltip"
                  placement="topRight"
                  title={item.tag_ids.map(tagId => {
                    const brandTag = brandItemTags.find(itemTag => itemTag.id === tagId);
                    return <Tag key={tagId}>{brandTag && brandTag.name}</Tag>;
                  })}
                  mouseEnterDelay={0.7}
                >
                  <div className="catalogue-list-row__tag-wrapper">
                    {item.tag_ids.map(tagId => {
                      const brandTag = brandItemTags.find(itemTag => itemTag.id === tagId);
                      return (
                        <Tag
                          key={tagId}
                          onClick={e => {
                            if (this.props.selectedFilterTagIds.includes(brandTag.id)) {
                              this.props.handleRemoveTagFilter(brandTag.id);
                            } else {
                              this.props.handleAddTagFilter(brandTag.name);
                            }
                            e.stopPropagation();
                          }}
                        >
                          {brandTag && brandTag.name}
                        </Tag>
                      );
                    })}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      );
    };

    const rowRenderer = ({ index, key, style }) => {
      const { items, selectedItemIds, brandItemTags, isReceiver } = this.props;
      if (isReceiver) {
        return rowReceiver({ index, key, style });
      }
      const item = items[index];

      const isMarked = item.marked === 1;
      const isSelected = selectedItemIds.includes(item.id);
      const outOfSync = new Date(item.last_synced_at) < new Date(item.updated_at);
      const syncActive = item.population_status_id === 1;
      return (
        <div
          key={key}
          className={classNames('catalogue-list-row', {
            'catalogue-list-row__is-selected': isSelected,
          })}
          style={style}
        >
          <div className="catalogue-list-row__select">
            <Checkbox
              className="catalogue-list-row__checkbox"
              checked={isSelected}
              onClick={e => {
                this.handleCheckItem(e, index, item);
              }}
            />
            {!isMarked && (
              <StarOutlined
                className="star"
                onClick={e => {
                  e.stopPropagation();
                  this.props.handleMarkItem(item.id, isMarked);
                }}
              />
            )}
            {isMarked && (
              <StarFilled
                className="star marked"
                onClick={e => {
                  e.stopPropagation();
                  this.props.handleMarkItem(item.id, isMarked);
                }}
              />
            )}
          </div>
          <div
            className="catalogue-list-row__inner-select"
            onClick={e => this.handleSelectItem(e, index, item)}
          >
            {!item.thumbnail && item.uri ? (
              <LinkOutlined className="catalogue-list-row__icon" />
            ) : (
              <ImageThumbnail thumbnail={item.thumbnail} />
            )}
            <div className="catalogue-list-row__part-number">
              <Tooltip title={item.part_number} mouseEnterDelay={1}>
                {item.part_number}
              </Tooltip>
            </div>
            <div className="catalogue-list-row__short-name">{this.getProductShortName(item)}</div>

            <div className="flex items-center">{this.displayChannelReceiverStatus(item)}</div>

            <div className="catalogue-list-row__tags">
              {item.tag_ids && (
                <Tooltip
                  overlayClassName="catalogue-list-row__tag-tooltip"
                  placement="topRight"
                  title={item.tag_ids.map(tagId => {
                    const brandTag = brandItemTags.find(tag => tag.id === tagId);
                    return <Tag key={tagId}>{brandTag && brandTag.name}</Tag>;
                  })}
                  mouseEnterDelay={0.7}
                >
                  <div className="catalogue-list-row__tag-wrapper">
                    {item.tag_ids.map(tagId => {
                      const brandTag = brandItemTags.find(tag => tag.id === tagId);
                      return (
                        <Tag
                          key={tagId}
                          onClick={e => {
                            if (this.props.selectedFilterTagIds.includes(brandTag.id)) {
                              this.props.handleRemoveTagFilter(tagId);
                            } else {
                              this.props.handleAddTagFilter(brandTag.name);
                            }
                            e.stopPropagation();
                          }}
                        >
                          {brandTag && brandTag.name}
                        </Tag>
                      );
                    })}
                  </div>
                </Tooltip>
              )}
            </div>
            <div>
              <CatalogueSyncIcon syncActive={syncActive} outOfSync={outOfSync} />
              <Progress
                className={classNames('catalogue-list-row__progress', {
                  red: item.quality_rank === 3,
                  yellow: item.quality_rank === 2,
                  blue: item.quality_rank === 1,
                })}
                type="circle"
                percent={item.score || 0}
                width={27}
              />
            </div>
          </div>
        </div>
      );
    };
    const {
      t,
      user,
      items,
      itemsFetching,
      selectedItemIds,
      handleSyncItems,
      catalogueExtended,
      allItemsSelected,
      handleCheckAllItems,
      handleMarkAllItems,
      brandItemTags,
      handleAddTag,
      handleRemoveTag,
      itemsSummary,
      brandItemsSummary,
      handleItemListScroll,
      isManufacturer,
      isReceiver,
    } = this.props;
    const {
      expandedItemListActive,
      scrollIndex,
      showCopyProductDrawer,
      showDeleteItemsModal,
      showExportModal,
      showEbayBulkListModal,
      showEbayEndListModal,
    } = this.state;

    const itemsCount = items.length;
    const markedCount = items.filter(item => item.marked === 1).length;
    const allItemsMarked = itemsCount > 0 && markedCount === itemsCount;
    const buttonName = this.props.isManufacturer
      ? 'catalogue:openCatalogue:editSelectedProducts'
      : 'catalogue:openCatalogue:openDetailedProductPreview';
    const hasScriptAccess = hasPermission(user, 'has_script_access');

    return (
      <div
        className={classNames('catalogue-list-container', {
          full: catalogueExtended,
        })}
      >
        <div className="catalogue-list-bar">
          <div className="catalogue-list-bar__select">
            <Checkbox
              className="checkbox"
              indeterminate={
                selectedItemIds.length > 0 &&
                !allItemsSelected &&
                items.some(({ id }) => selectedItemIds.includes(id))
              }
              checked={allItemsSelected}
              onChange={handleCheckAllItems}
              disabled={items.length === 0}
            />
            {isManufacturer && (
              <Popconfirm
                title={allItemsMarked ? t('catalogue:mark.unmarkAll') : t('catalogue:mark.markAll')}
                onConfirm={() => handleMarkAllItems(allItemsMarked)}
                okText={t('common:yes')}
                cancelText={t('common:cancel')}
              >
                {!allItemsMarked && markedCount === 0 && <StarOutlined className="star" />}
                {!allItemsMarked && markedCount > 0 && (
                  <StarTwoTone className="star marked" twoToneColor="#fadb14" />
                )}
                {allItemsMarked && <StarFilled className="star marked" />}
              </Popconfirm>
            )}
          </div>
          <div className="catalogue-list-bar__actions">
            <AntPopover
              trigger="click"
              disabled={!selectedItemIds.length}
              content={
                <CatalogueAddTag
                  fetchItemTags={this.props.fetchItemTags}
                  allItemsChecked={this.props.allItemsSelected}
                  checkedItemIds={this.props.selectedItemIds}
                  checkedItems={this.props.selectedItems}
                  brandItemTags={brandItemTags}
                  handleAddTag={handleAddTag}
                  handleRemoveTag={handleRemoveTag}
                />
              }
              buttonProps={{
                icon: <TagsOutlined />,
                size: 'small',
                title: catalogueTranslations.tag,
              }}
            />
            {(isManufacturer || (isReceiver && hasScriptAccess)) && (
              <CatalogueActions
                user={user}
                checkedItemIds={selectedItemIds}
                openCloneItem={() => this.setState({ showCopyProductDrawer: true })}
                handleSyncItems={handleSyncItems}
                handleExportClick={this.handleExportClick}
                handleDeleteItems={this.handleDeleteItems}
                handleListOnEbay={() => this.setState({ showEbayBulkListModal: true })}
                handleUpdateFitmentOnEbay={() =>
                  this.setState({ showEbayBulkListModal: true, updateEbayFitmentModal: true })
                }
                handleEndListOnEbay={() => this.setState({ showEbayEndListModal: true })}
                handleRunScript={this.props.handleRunScript}
              />
            )}
            {this.renderSelectedItemsInfo()}
          </div>
          <CatalogueOrderSelect />
          <CatalogueChannelSelect />
          <div className="catalogue-list-bar__items-count">
            <span>
              {fillInPlaceholders(catalogueTranslations.selectedFilter, {
                availableItems: itemsSummary,
                totalItems: brandItemsSummary,
              })}
            </span>
          </div>
          <Tooltip
            title={t(this.props.catalogueExtended ? buttonName : 'catalogue:openCatalogue.title')}
            placement="bottom"
          >
            <Button
              className="catalogue-button"
              size="small"
              type="primary"
              onClick={this.props.changeCatalougeHeight}
            >
              <FileSearchOutlined />
              {this.props.catalogueExtended ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Tooltip>
        </div>
        {this.state.hasFetchedItemsOnce && !itemsFetching && !items.length ? (
          this.displayNothingFound()
        ) : (
          <div
            className="catalogue-list"
            onMouseEnter={() => this.handleItemListExpand(true)}
            onMouseLeave={() => this.handleItemListExpand(false)}
          >
            <InfiniteLoader
              isRowLoaded={({ index }) => !!items[index]}
              loadMoreRows={handleItemListScroll}
              rowCount={itemsSummary}
              minimumBatchSize={100}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => {
                    const ROW_HEIGHT = 40;
                    const MAX_HEIGHT = 415;
                    const allItemsHeight = ROW_HEIGHT * items.length;
                    let listHeight;
                    if (expandedItemListActive) {
                      if (allItemsHeight > MAX_HEIGHT) {
                        listHeight = MAX_HEIGHT;
                      } else if (allItemsHeight < height) {
                        listHeight = height;
                      } else {
                        listHeight = allItemsHeight;
                      }
                    } else {
                      listHeight = height;
                    }
                    return (
                      <React.Fragment>
                        <List
                          className="items-virtualized-list"
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowRenderer={rowRenderer}
                          height={listHeight}
                          width={width}
                          rowCount={items.length}
                          rowHeight={ROW_HEIGHT}
                          scrollToIndex={scrollIndex}
                          style={{
                            boxShadow: expandedItemListActive && '0 2px 3px hsla(0, 0%, 0%, 0.2)',
                            transition: this.expandedTimeOut && 'height 0.3s ease-out',
                            zIndex: expandedItemListActive ? 2 : 0,
                          }}
                        />
                        <Spin
                          spinning={itemsFetching}
                          className="spinner"
                          style={{
                            left: width / 2,
                          }}
                          size="large"
                        />
                      </React.Fragment>
                    );
                  }}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        )}
        {showCopyProductDrawer && (
          <CatalogueCopyProductDrawer
            visible={showCopyProductDrawer}
            onClose={() => this.setState({ showCopyProductDrawer: false })}
          />
        )}
        {showDeleteItemsModal && (
          <DeleteItemsModal
            showModal={showDeleteItemsModal}
            handleDeleteItems={this.props.handleDeleteItems}
            onClose={() => this.setState({ showDeleteItemsModal: false })}
          />
        )}
        {showExportModal && (
          <CatalogueExportModal
            showModal={showExportModal}
            exportType={this.state.exportType}
            handleExportModalState={this.handleExportModalState}
            handleStartExport={this.props.handleStartExport}
          />
        )}
        <EbayBulkListModal
          showModal={showEbayBulkListModal}
          updateFitment={this.state.updateEbayFitmentModal}
          onClose={() =>
            this.setState({ showEbayBulkListModal: false, updateEbayFitmentModal: false })
          }
        />
        {showEbayEndListModal && (
          <EbayEndListModal
            showModal={showEbayEndListModal}
            onClose={() => this.setState({ showEbayEndListModal: false })}
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(CatalogueList);
