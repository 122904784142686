import { DownloadOutlined, EyeOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { Empty, Spin, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Table, Column, InfiniteLoader } from 'react-virtualized';
import { ExportLog, FileExportOption, ImportLog } from '../../../../types/import_export';
import { UserName } from '../../../../types/user';
import JobStatus from '../../global/JobStatus';
import JobInfo from '../../global/JobInfo';
import { StandardResourceDescription } from '../../../../types/resources';
import TableFilterPopover from '../../global/tableFilters/TableFilterPopover';

const ROW_HEIGHT = 40;

type LogsTableProps = {
  exportLogs?: boolean;
  logs: ImportLog[] | ExportLog[];
  fetchingLogs: boolean;
  columns: { dataKey: string; label: string; visible: boolean }[];
  userNameList: UserName[];
  handleDisplayError: (logId: number, fileTypeId: number) => void;
  handleImportFileDownload: (logId: number) => void;
  handleOpenNotification?: (logId: number) => void;
  fileTypeValues?: StandardResourceDescription[] | FileExportOption[];
  handleFilterByType?: (ids: number[]) => void;
  pageSize?: number;
  selectedTypeFilterIds?: number[];
  handleTableScroll?: ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<any>;
};

const LogsTable: React.FC<LogsTableProps> = ({
  exportLogs,
  logs,
  fetchingLogs,
  columns,
  userNameList,
  handleDisplayError,
  handleImportFileDownload,
  handleOpenNotification,
  fileTypeValues,
  handleFilterByType,
  pageSize,
  selectedTypeFilterIds,
  handleTableScroll,
}) => {
  const { t } = useTranslation();

  const getRow = ({ index }: any) => {
    return logs[index];
  };

  const createFiletypeNameFilter = (label: string) => {
    return (
      <div className="flex flex-row items-center">
        {label}
        {selectedTypeFilterIds && (
          <div className="ml-1">
            <TableFilterPopover
              values={fileTypeValues || []}
              handleChange={ids => handleFilterByType!(ids)}
              initiallySelectedIds={selectedTypeFilterIds}
              showSearch
            >
              {selectedTypeFilterIds.length > 0 ? (
                <FilterFilled className="table-filter-blue" />
              ) : (
                <FilterOutlined />
              )}
            </TableFilterPopover>
          </div>
        )}
      </div>
    );
  };

  const getColumn = (col: { dataKey: string; label: string; visible: boolean }) => {
    if (col.dataKey === 'fileTypeName')
      return (
        <Column
          key={col.dataKey}
          label={createFiletypeNameFilter(col.label)}
          dataKey="fileTypeName"
          width={120}
        />
      );
    if (col.dataKey === 'file_name')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey={col.dataKey}
          width={150}
          flexGrow={1}
          cellRenderer={({ rowData }) => (
            <Tooltip title={rowData.file_name}>{rowData.file_name}</Tooltip>
          )}
        />
      );
    if (col.dataKey === 'user_id')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey={col.dataKey}
          cellRenderer={({ rowData }) => {
            const user = userNameList.find(u => u.id === rowData.user_id);
            if (user) return `${user.first_name[0]}. ${user.last_name}`;
          }}
          width={120}
        />
      );
    if (col.dataKey === 'startedTime')
      return <Column key={col.dataKey} label={col.label} dataKey="startedTime" width={180} />;
    if (col.dataKey === 'completedTime')
      return <Column key={col.dataKey} label={col.label} dataKey="completedTime" width={180} />;
    if (col.dataKey === 'item_count')
      return <Column key={col.dataKey} label={col.label} dataKey="item_count" width={50} />;
    if (col.dataKey === 'application_count')
      return <Column key={col.dataKey} label={col.label} dataKey="application_count" width={90} />;
    if (col.dataKey === 'displayError')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey="displayError"
          cellRenderer={({ rowData }) => (
            <JobStatus statusId={rowData.status_id} status={rowData.status} />
          )}
          width={140}
          flexShrink={0}
        />
      );
    if (col.dataKey === 'file_size')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey="file_size"
          cellRenderer={({ rowData }) => {
            if (rowData.file_size) return `${rowData.file_size} kB`;
          }}
          width={80}
          flexShrink={0}
        />
      );
    if (col.dataKey === 'downloadFile')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey="downloadFile"
          className="text-right"
          cellRenderer={({ rowData }) => {
            const renderInfo = () => (
              <JobInfo
                displayInfo={
                  rowData.info_count > 0 || rowData.warning_count > 0 || rowData.error_count > 0
                }
                statusId={rowData.status_id}
                handleClick={() => handleDisplayError(rowData.id, rowData.file_type_id)}
              />
            );
            if ((rowData.status_id === 1 || rowData.status_id === 2) && handleOpenNotification)
              // status_id 1 is queued 2 is processing
              return (
                <Tooltip title={t('importer:viewProgress')} placement="topRight">
                  <EyeOutlined onClick={() => handleOpenNotification(rowData.id)} />
                </Tooltip>
              );
            if (rowData.download === 1)
              return (
                <div className="flex items-center justify-end space-x-1">
                  {renderInfo()}
                  <DownloadOutlined onClick={() => handleImportFileDownload(rowData.id)} />
                </div>
              );
            return renderInfo();
          }}
          width={45}
        />
      );
  };

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <InfiniteLoader
            isRowLoaded={({ index }) => !!logs[index]}
            loadMoreRows={handleTableScroll!}
            rowCount={logs.length + pageSize!}
            minimumBatchSize={pageSize}
          >
            {({ onRowsRendered, registerChild }) => (
              <React.Fragment>
                <Table
                  width={width - 2}
                  height={height}
                  headerHeight={ROW_HEIGHT}
                  rowHeight={ROW_HEIGHT}
                  rowCount={logs.length}
                  onRowsRendered={onRowsRendered}
                  rowGetter={getRow}
                  ref={registerChild}
                  noRowsRenderer={() =>
                    fetchingLogs && logs.length === 0 ? (
                      <Spin className="spinner-center" style={{ marginTop: '20px' }} />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        className="translation-import-export"
                        description={
                          exportLogs ? t('exporter:no_exports') : t('importer:no_imports')
                        }
                      />
                    )
                  }
                >
                  {columns.map(col => (col.visible ? getColumn(col) : null))}
                </Table>
                <Spin
                  spinning={fetchingLogs && logs.length > 0}
                  className="spinner"
                  style={{
                    left: width / 2,
                    position: 'absolute',
                    bottom: '20px',
                  }}
                />
              </React.Fragment>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default LogsTable;
