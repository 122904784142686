const reducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_DEFAULT_VALUES_FULFILLED':
      return action.payload.data;
    case 'UPDATE_DEFAULT_VALUE_PENDING': {
      const defaultValues = state.map(value =>
        value.id === action.meta.defaultValueId ? { ...value, value: action.meta.value } : value
      );
      return defaultValues;
    }
  }
  return state;
};

export default reducer;
