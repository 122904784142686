import React, { useMemo } from 'react';
import {
  CloudOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExportOutlined,
  DownOutlined,
  ShopOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Dropdown, Button, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasExportPerm, hasPermission } from '../../../utils/Permissions';

import { UserType } from '../../../../types/user';
import { ExportTypes } from '../../../../types/import_export';
import { ApplicationState } from '../../../reducers';
import { APIScript } from '../../../../types/distribution';
import { isManufacturer } from '../../../utils/UserUtils';

type CatalogueActionsProps = {
  user: UserType;
  checkedItemIds: number[];
  handleSyncItems: () => void;
  handleExportClick: (exportType: ExportTypes) => void;
  handleDeleteItems: () => void;
  handleListOnEbay: () => void;
  handleEndListOnEbay: () => void;
  handleUpdateFitmentOnEbay: () => void;
  handleRunScript: (scriptId: number) => void;
  openCloneItem: () => void;
};

const CatalogueActions: React.FC<CatalogueActionsProps> = props => {
  const { t } = useTranslation();
  const {
    user,
    checkedItemIds,
    handleSyncItems,
    handleExportClick,
    handleDeleteItems,
    handleListOnEbay,
    handleEndListOnEbay,
    handleUpdateFitmentOnEbay,
    handleRunScript,
    openCloneItem,
  } = props;

  const { scripts } = useSelector((state: ApplicationState) => {
    return {
      scripts: state.distribution.apiSettings.scripts,
    };
  });

  const manufacturer = useMemo(() => isManufacturer(user), [user]);

  const userHasExportPerm = useMemo(() => hasExportPerm(user), [user]);

  const xmlExportPermission = useMemo(() => hasPermission(user, 'can_export_xml'), [user]);
  const excelExportTemplateAdvancedPermission = useMemo(
    () => hasPermission(user, 'can_export_full_template'),
    [user]
  );
  const excelExportFullPermission = useMemo(
    () => hasPermission(user, 'can_export_all_products'),
    [user]
  );
  const pdfExportPermission = useMemo(() => hasPermission(user, 'can_export_pdf'), [user]);
  const summitExportPermission = useMemo(
    () => hasPermission(user, 'can_export_summit_loadsheet'),
    [user]
  );
  const canPublishProducts = useMemo(() => hasPermission(user, 'can_publish_products'), [user]);
  const canManageEbay = useMemo(() => hasPermission(user, 'can_manage_ebay'), [user]);
  const hasScriptAccess = useMemo(() => hasPermission(user, 'has_script_access'), [user]);

  const items: MenuProps['items'] = [
    ...(manufacturer
      ? [
          {
            key: 'clone',
            disabled: checkedItemIds.length !== 1,
            onClick: openCloneItem,
            label: (
              <React.Fragment>
                <CopyOutlined className="pr-2" />
                {t('catalogue:catalogueActions.clone')}
              </React.Fragment>
            ),
          },
        ]
      : []),
    ...(manufacturer && canPublishProducts
      ? [
          {
            key: 'sync',
            label: (
              <React.Fragment>
                <CloudOutlined className="pr-2" />
                {t('catalogue:catalogueActions.sync')}
              </React.Fragment>
            ),
            onClick: handleSyncItems,
          },
        ]
      : []),
    ...(manufacturer && userHasExportPerm
      ? [
          {
            label: (
              <span>
                <ExportOutlined style={{ marginRight: 8 }} />
                {t('catalogue:catalogueActions.export')}
              </span>
            ),
            key: 'submenu-export',
            children: [
              ...(excelExportFullPermission
                ? [
                    {
                      label: t('exporter:excel_full'),
                      key: 'excel_full',
                      onClick: () => handleExportClick(ExportTypes.EXCEL),
                    },
                  ]
                : []),
              ...(excelExportTemplateAdvancedPermission
                ? [
                    {
                      label: t('exporter:full_export_template'),
                      key: 'full_export_template',
                      onClick: () => handleExportClick(ExportTypes.EXCEL_TEMPLATE_ADVANCED),
                    },
                  ]
                : []),
              ...(pdfExportPermission
                ? [
                    {
                      label: t('exporter:pdf'),
                      key: 'export-pdf',
                      onClick: () => handleExportClick(ExportTypes.PDF),
                    },
                  ]
                : []),
              ...(xmlExportPermission
                ? [
                    {
                      label: t('exporter:aces'),
                      key: 'export-aces',
                      onClick: () => handleExportClick(ExportTypes.ACES),
                    },
                  ]
                : []),
              ...(xmlExportPermission
                ? [
                    {
                      label: t('exporter:pies'),
                      key: 'export-pies',
                      onClick: () => handleExportClick(ExportTypes.PIES),
                    },
                  ]
                : []),
              {
                label: t('exporter:digitalAssets'),
                key: 'digital-assets',
                onClick: () => handleExportClick(ExportTypes.ASSET),
              },
              ...(summitExportPermission
                ? [
                    {
                      label: t('exporter:summit_loadsheet'),
                      key: 'summit_loadsheet',
                      onClick: () => handleExportClick(ExportTypes.SUMMIT),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(manufacturer && canManageEbay
      ? [
          {
            label: (
              <span>
                <ShopOutlined style={{ marginRight: 8 }} />
                {t('catalogue:catalogueActions.ebay')}
              </span>
            ),
            key: 'submenu-ebay',
            children: [
              {
                label: t('exporter:ebayPublishAndUpdate'),
                key: 'ebay-publish-update',
                onClick: () => handleListOnEbay(),
              },
              {
                label: t('exporter:updateFitment'),
                key: 'update-fitment',
                onClick: () => handleUpdateFitmentOnEbay(),
              },
              {
                label: t('exporter:endListing'),
                key: 'end-listing',
                onClick: () => handleEndListOnEbay(),
              },
            ],
          },
        ]
      : []),
    ...(hasScriptAccess
      ? [
          {
            label: (
              <span>
                <PlayCircleOutlined style={{ marginRight: 8 }} />
                {t('catalogue:catalogueActions.runScript')}
              </span>
            ),
            key: 'catalogue-runscript',
            children: scripts
              .filter((s: APIScript) => s.active && s.new_logic)
              .map(s => ({
                label: s.name,
                key: s.id,
                onClick: () => handleRunScript(s.id),
              })),
          },
        ]
      : []),
    ...(manufacturer
      ? [
          {
            label: (
              <React.Fragment>
                <DeleteOutlined className="pr-2" />
                {t('catalogue:catalogueActions.delete')}
              </React.Fragment>
            ),
            key: 'catalogueActions_delete',
            onClick: handleDeleteItems,
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      menu={{ items }}
      className="actions"
      disabled={!checkedItemIds.length}
      trigger={['click']}
    >
      <Button size="small">
        {t('catalogue:actions')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default CatalogueActions;
