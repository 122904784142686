import React from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Button, Switch, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import AntTooltip from '../../global/AntTooltip';

type ApplicationActionsProps = {
  canManageBaseItems: boolean;
  canManageBundle: boolean;
  disableBundle: boolean;
  universalPart: boolean;
  bundle: boolean;
  handleLinkToApplication: () => void;
  handleDefaultValues: () => void;
  updateUniversalPart: (checked: boolean) => void;
  updateBundle: (checked: boolean) => void;
};

const ApplicationActions: React.FC<ApplicationActionsProps> = ({
  canManageBaseItems,
  canManageBundle,
  disableBundle,
  universalPart,
  bundle,
  handleLinkToApplication,
  handleDefaultValues,
  updateUniversalPart,
  updateBundle,
}) => {
  const { t } = useTranslation();

  const items: MenuProps['items'] = [
    ...(canManageBaseItems && !canManageBundle
      ? [
          {
            key: 'actions-link',
            label: t('application:actions.link'),
            onClick: () => handleLinkToApplication(),
          },
        ]
      : []),
    {
      key: 'actions-default_values',
      label: t('application:actions.default_values'),
      onClick: () => handleDefaultValues(),
    },
    {
      key: 'universal-part',
      label: (
        <div className="flex">
          <span>{t('application:universalPart')}</span>
          <span className="pl-2 flex-1 flex-col items-end self-center">
            <Switch checked={universalPart} onChange={() => {}} size="small" />
          </span>
        </div>
      ),
      onClick: () => updateUniversalPart(!universalPart),
    },
    ...(canManageBundle
      ? [
          {
            key: 'bundle-part',
            label: (
              <div className="flex">
                <span>
                  {t('application:bundlePart')}
                  {disableBundle && (
                    <AntTooltip
                      title={t('application:info.multiInheriting')}
                      className="self-center pr-2"
                    >
                      <InfoCircleOutlined className="ant-info-tooltip pl-1" />
                    </AntTooltip>
                  )}
                </span>
                <span className="pl-2 flex-1 flex-col items-end self-center">
                  <Switch
                    checked={bundle}
                    onChange={() => {}}
                    size="small"
                    disabled={disableBundle}
                  />
                </span>
              </div>
            ),
            onClick: () => !disableBundle && updateBundle(!bundle),
          },
        ]
      : []),
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
      <Button className="application__action-button" size="small">
        {t('application:actions.more_actions')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ApplicationActions;
