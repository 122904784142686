import { createSelector } from 'reselect';
import { HazardousMaterial, Package } from '../../../types/itemPackage';
import {
  ExtendedResources,
  StandardResource,
  StandardResourceCode,
  StandardResourceDescription,
} from '../../../types/resources';
import { ApplicationState } from '../../reducers';
import { generateUUID } from '../../utils/Utils';

const getItemsPackages = (state: ApplicationState) => state.items.packageSeg.itemPackages;
const uoms = (state: ApplicationState) =>
  state.resources.data.package.uoms as StandardResourceDescription[];
const grossWeights = (state: ApplicationState) =>
  state.resources.data.package.weight_uoms as StandardResource[];
const dimensionUoms = (state: ApplicationState) =>
  state.resources.data.package.dimension_uoms as StandardResource[];
const transportUoms = (state: ApplicationState) =>
  state.resources.data.package.hazardous_material_transport_methods as StandardResource[];
const shippingScopeUoms = (state: ApplicationState) =>
  state.resources.data.package.hazardous_material_shipping_scopes as StandardResource[];
const bulkUoms = (state: ApplicationState) =>
  state.resources.data.package.hazardous_material_bulks as StandardResource[];
const countryUoms = (state: ApplicationState) => state.resources.data.global.countries;
const regulatedOptions = (state: ApplicationState) =>
  state.resources.data.package.hazardous_material_regulated_options as StandardResource[];
const languages = (state: ApplicationState) => state.resources.data.global.languages;

export const addSummary = (
  pkg: Package,
  uoms: StandardResourceDescription[],
  grossWeights: StandardResource[],
  dimensionUoms: StandardResource[],
  transportUoms: StandardResource[],
  shippingScopeUoms: StandardResource[],
  bulkUoms: StandardResource[],
  countryUoms: ExtendedResources[],
  regulatedOptions: StandardResource[],
  languages: StandardResourceCode[]
) => {
  const transportUomName = (hz: HazardousMaterial) =>
    transportUoms.find(uom => hz.transport_method_id === uom.id)?.name;
  const shippingScopeUomName = (hz: HazardousMaterial) =>
    shippingScopeUoms.find(uom => hz.shipping_scope_id === uom.id)?.name;
  const bulkUomName = (hz: HazardousMaterial) => bulkUoms.find(uom => hz.bulk_id === uom.id)?.name;
  const countryUomName = (hz: HazardousMaterial) =>
    countryUoms.find(uom => hz.regulating_country_id === uom.id)?.name;
  const regulatedOptionName = (hz: HazardousMaterial) =>
    regulatedOptions.find(uom => hz.regulated_option_id === uom.id)?.name;
  const languageName = (hz: HazardousMaterial) =>
    languages.find(l => l.id === hz.language_id)?.name;

  const uomName = uoms.find(uom => uom.id === pkg.uom_id)?.name || '';
  const weightName = grossWeights.find(wt => wt.id === pkg.weights_uom_id)?.name || '';
  const dimensionsUomName = dimensionUoms.find(uom => uom.id === pkg.dimensions_uom_id)?.name || '';

  return {
    ...pkg,
    id: pkg.id ? pkg.id : generateUUID(),
    uomName,
    dimensionsUomName,
    weightName,
    hazardous_materials: pkg.hazardous_materials.map(hz => ({
      ...hz,
      dimensions_uom_id: pkg.dimensions_uom_id || 7,
      weights_uom_id: pkg.weights_uom_id || 7,
      transportUomName: transportUomName(hz),
      shippingScopeUomName: shippingScopeUomName(hz),
      bulkUomName: bulkUomName(hz),
      countryUomName: countryUomName(hz),
      regulatedOptionName: regulatedOptionName(hz),
      languageName: languageName(hz),
    })),
  };
};

export const packagesUpdatedWithSummary = createSelector(
  [
    getItemsPackages,
    uoms,
    grossWeights,
    dimensionUoms,
    transportUoms,
    shippingScopeUoms,
    bulkUoms,
    countryUoms,
    regulatedOptions,
    languages,
  ],
  (
    itemsPackages,
    uoms,
    grossWeights,
    dimensionUoms,
    transportUoms,
    shippingScopeUoms,
    bulkUoms,
    countryUoms,
    regulatedOptions,
    languages
  ) => {
    const itemPackage = itemsPackages.length > 0 ? itemsPackages[0].packages : [];

    return itemPackage.map(pkg =>
      addSummary(
        pkg,
        uoms,
        grossWeights,
        dimensionUoms,
        transportUoms,
        shippingScopeUoms,
        bulkUoms,
        countryUoms,
        regulatedOptions,
        languages
      )
    );
  }
);
