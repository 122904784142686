import { DownOutlined } from '@ant-design/icons';
import { Button, MenuProps } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import AntDropdown from './AntDropdown';

type AntButtonDropdownProps = {
  title: string;
  disabled?: boolean;
  menu: MenuProps;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  onFocus: React.FocusEventHandler<HTMLElement>;
  trigger: ['click', 'hover', 'contextMenu'];
  size: SizeType;
  icon: any;
};

const AntButtonDropdown: React.FC<AntButtonDropdownProps> = props => {
  const { title, disabled, menu, onMouseEnter, onMouseLeave, onFocus, trigger, size, icon } = props;

  return (
    <AntDropdown {...{ disabled, menu, onMouseEnter, onMouseLeave, onFocus, trigger }}>
      <div style={{ marginLeft: 8 }}>
        <Button
          className={classNames({ 'ant-button-disabled': disabled })}
          htmlType="button"
          size={size}
        >
          {title}
          {icon || <DownOutlined />}
        </Button>
      </div>
    </AntDropdown>
  );
};

export default AntButtonDropdown;
