import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileSearchOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { Button, Spin, Tag, Dropdown, Checkbox, Tooltip } from 'antd';
import { List, ListRowProps, AutoSizer, InfiniteLoader } from 'react-virtualized';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReceiverCatalogueBrandType } from '../../../../types/receiver_data_stream';
import { ExportTypes } from '../../../../types/import_export';
import CatalogueExportModal, { ExportParamsState } from '../catalogue/CatalogueExportModal';
import { FilterTypeGo } from '../../../../types/filter';
import ReceiverBrandActions from './ReceiverBrandActions';
import ReceiverBrandImportIcons from './ReceiverBrandImportIcons';

import { getOrderStringToInt, getPageLimit } from '../../../utils/Utils';
import { AsyncDispatch } from '../../../../types/global';
import { fetchReceiverBrandsByFilterId } from '../../../actions/receiver_data_stream/productsCatalogue/fetch';
import SourceFilterPopover from './SourceFilterPopover';
import { ApplicationState } from '../../../reducers';
import TableFilterPopover from '../../global/tableFilters/TableFilterPopover';
import TableSorter from '../../global/tableFilters/TableSorter';
import { ReceiverColumns } from '../../../constants/ReceiverConstants';
import ReceiverBrandPopover from './ReceiverBrandPopover';
import AntTooltip from '../../global/AntTooltip';

type ReceiverCatalogueListProps = {
  fetching: boolean;
  brands: ReceiverCatalogueBrandType[];
  selectedFilterGo: FilterTypeGo;
  allBrandsCount?: number;
  brandFilterCount?: number;
  brandFilterIds: number[];
  handleRequestBrands: (brandIds: number[]) => void;
  handleApproveBrandRequests: (brandIds: number[]) => void;
  handleDeclineBrandRequests: (brandIds: number[]) => void;
  handleCancelRequestBrand: (brandId: number) => void;
  handleStartExport: (exportState: ExportParamsState, brandId: number) => void;
  updateClienFilterBrandIds: (brandIds: number[]) => void;
  handleFilterByBrand: (
    brandIds: number[],
    statusIds: number[],
    sortOrder: string,
    sourceId?: number
  ) => void;
  canExportFullTemplate: boolean;
};

const ReceiverCatalogueList: React.FC<ReceiverCatalogueListProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { allAccessableBrands, catalogueQueryParams, reqStatuses } = useSelector(
    (state: ApplicationState) => ({
      allAccessableBrands: state.receiverDataStream.allAccessableBrands,
      catalogueQueryParams: state.catalogue.catalogue.catalogueQueryParams,
      reqStatuses: state.resources.data.receiver?.request_statuses || [],
    })
  );

  const [showExportModalState, setExportModal] = React.useState(false);
  const [exportTypeState, setExportType] = React.useState<ExportTypes>();
  const [selectedBrandId, setBrandId] = React.useState<number>();
  const [selectedBrandIds, setBrandIds] = React.useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const [sortOrder, setSortOrder] = React.useState<string>('');
  const [statusIds, setStatusIds] = React.useState<number[]>([]);
  const [sourceFilterId, setSourceFilterId] = React.useState<number>();

  const pageSize = getPageLimit();

  const handleFetchNext = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => {
    const nextPage = Math.ceil(stopIndex / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize);
    const lastPage = currentPage > startIndex / pageSize;

    if (!lastPage)
      return dispatch(
        fetchReceiverBrandsByFilterId(
          props.selectedFilterGo.id,
          props.brandFilterIds,
          sourceFilterId,
          statusIds,
          sortOrder ? ReceiverColumns.COMPANY : undefined,
          getOrderStringToInt(sortOrder),
          nextPage
        )
      );

    return Promise.resolve();
  };

  const handleMultiSelect = (index: number) => {
    const startIndex = selectedIndex! < index ? selectedIndex! : index;
    const endIndex = selectedIndex! < index ? index : selectedIndex!;
    const brandIds = props.brands
      .filter((brand, i) => {
        if (startIndex <= i && endIndex >= i) return true;
        return false;
      })
      .map(({ id }) => id);

    setBrandIds([...new Set([...selectedBrandIds, ...brandIds])]);
  };

  const handleCheckBrand = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number,
    brandId: number
  ) => {
    e.stopPropagation();
    if (selectedBrandIds.length === 0) {
      setBrandIds([brandId]);
      setSelectedIndex(index);
    } else if (selectedBrandIds.includes(brandId)) {
      setBrandIds(selectedBrandIds.filter(id => id !== brandId));
    } else if (e.shiftKey) {
      handleMultiSelect(index);
    } else {
      setBrandIds([...selectedBrandIds, brandId]);
      setSelectedIndex(index);
    }
  };

  const showRequestStatus = (brand: ReceiverCatalogueBrandType) => {
    if (brand.declined_by_brand_at || brand.declined_by_receiver_at) {
      return (
        <span>
          <Tag color="red">
            {t('receiver:status.rejected')}
            {brand.declined_by_brand_reason && (
              <Tooltip title={brand.declined_by_brand_reason}>
                <FileSearchOutlined className="receiver__declined-icon" />
              </Tooltip>
            )}
          </Tag>
        </span>
      );
    }
    if (brand.authorized_by_brand_at && brand.authorized_by_receiver_at) {
      return <Tag color="green">{t('receiver:status.approved')}</Tag>;
    }
    if (brand.authorized_by_brand_at || brand.authorized_by_receiver_at) {
      return (
        <Tag color="orange" data-testid="pending">
          {t('receiver:status.pending')}
        </Tag>
      );
    }
    return null;
  };

  const handleExportClick = (brandId: number, exportType: ExportTypes) => {
    // no modal needed
    if (exportType === ExportTypes.ASSET) {
      props.handleStartExport({ fileType: ExportTypes.ASSET }, brandId);
    } else {
      setExportModal(!showExportModalState);
      setExportType(exportType);
      setBrandId(brandId);
    }
  };

  const menuItems = (brandId: number) => [
    {
      label: (
        <Link className="receiver-catalogue__auto-delivery-link" to="/distribution/channels">
          {t('receiver:manageAutoDelivery')}
        </Link>
      ),
      key: 'manage-auto-delivery',
      onClick: () => {},
    },
    {
      label: t('exporter:excel_full'),
      key: 'excel_full',
      onClick: () => handleExportClick(brandId, ExportTypes.EXCEL),
    },
    {
      label: t('exporter:aces'),
      key: 'aces',
      onClick: () => handleExportClick(brandId, ExportTypes.ACES),
    },
    {
      label: t('exporter:pies'),
      key: 'pies',
      onClick: () => handleExportClick(brandId, ExportTypes.PIES),
    },
    {
      label: t('exporter:digitalAssets'),
      key: 'digital-assets',
      onClick: () => handleExportClick(brandId, ExportTypes.ASSET),
    },
    ...(props.canExportFullTemplate
      ? [
          {
            label: t('exporter:full_export_template'),
            key: 'full-export-template',
            onClick: () => handleExportClick(brandId, ExportTypes.EXCEL_TEMPLATE_ADVANCED),
          },
        ]
      : []),
  ];

  const showExportOptions = (brandId: number) => {
    return (
      <Dropdown
        menu={{
          items: menuItems(brandId),
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button size="small" style={{ marginRight: 5 }}>
          {t('common:export')}
        </Button>
      </Dropdown>
    );
  };

  const showActions = (brand: ReceiverCatalogueBrandType) => {
    if (
      brand.authorized_by_brand_at &&
      !brand.authorized_by_receiver_at &&
      !brand.declined_by_receiver_at
    ) {
      return (
        <React.Fragment>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => props.handleDeclineBrandRequests([brand.id])}
            htmlType="button"
          >
            {t('receiver:reject')}
          </Button>
          <Button
            size="small"
            type="primary"
            ghost
            style={{ marginLeft: 5 }}
            onClick={() => props.handleApproveBrandRequests([brand.id])}
            htmlType="button"
          >
            {t('receiver:approve')}
          </Button>
        </React.Fragment>
      );
    }

    if (brand.declined_by_receiver_at) {
      return (
        <Button
          size="small"
          type="primary"
          ghost
          onClick={() => props.handleApproveBrandRequests([brand.id])}
          htmlType="button"
        >
          {t('receiver:approve')}
        </Button>
      );
    }

    if (brand.authorized_by_brand_at && brand.authorized_by_receiver_at) {
      return (
        <React.Fragment>
          {showExportOptions(brand.id)}
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => props.handleDeclineBrandRequests([brand.id])}
            htmlType="button"
          >
            {t('receiver:reject')}
          </Button>
        </React.Fragment>
      );
    }

    if (!brand.authorized_by_brand_at && brand.authorized_by_receiver_at) {
      return (
        <Button
          size="small"
          type="primary"
          ghost
          onClick={() => props.handleCancelRequestBrand(brand.id)}
          htmlType="button"
          data-testid="cancel"
        >
          {t('common:cancel')}
        </Button>
      );
    }
    if (!brand.authorized_by_brand_at && !brand.authorized_by_receiver_at) {
      return (
        <Button
          size="small"
          type="primary"
          ghost
          onClick={() => props.handleRequestBrands([brand.id])}
          htmlType="button"
          data-testid="request-data"
        >
          {t('receiver:actions.request_data')}
        </Button>
      );
    }
    return null;
  };

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: ListRowProps) => {
    const brand = props.brands[index];
    const isSelected = selectedBrandIds.includes(brand.id);

    return (
      <div key={key} style={style} className="receiver-catalogue__list-row">
        <Checkbox
          className="catalogue-list-row__checkbox"
          checked={isSelected}
          onClick={e => {
            handleCheckBrand(e, index, brand.id);
          }}
        />
        <div>
          <Tooltip
            title={
              brand.code !== brand.pdm_brand_code
                ? `${brand.name} (${brand.code}|${brand.pdm_brand_code})`
                : `${brand.name} (${brand.code})`
            }
            placement="right"
            mouseEnterDelay={0.7}
          >
            {brand.name}
          </Tooltip>
        </div>
        <div>{brand.external_data_provider_name || ''}</div>
        <div className="receiver-catalogue__list-row__products">
          <Link
            to={{
              pathname: '/available-brands/all-products/item',
              search: `filterId=${props.selectedFilterGo.id}`,
            }}
          >
            <AntTooltip
              title={t('receiver:productCountInfo')}
              hide={!brand.item_count}
              mouseEnterDelay={0.7}
            >
              <Button
                className="receiver-catalogue__product-button"
                size="small"
                ghost
                type="primary"
                onClick={() => props.updateClienFilterBrandIds([brand.id])}
              >
                {brand.item_count}
              </Button>
            </AntTooltip>
          </Link>
        </div>
        <ReceiverBrandImportIcons updatedAt={brand.updated_at} />
        <div className="receiver-catalogue__list-row__request-status">
          {showRequestStatus(brand)}
          {brand.receiver_profile_requested_at &&
            brand.receiver_profile_requested_acknowledged !== 1 && (
              <ReceiverBrandPopover code={brand.code} parentId={brand.parent_id} />
            )}
        </div>
        <div className="receiver-catalogue__list-row__actions">{showActions(brand)}</div>
      </div>
    );
  };

  return (
    <div className="receiver-catalogue-list__container">
      <div className="w-full p-2 pl-2 flex">
        <div>
          {/* select all will be implemented later */}
          {/* <Checkbox
            indeterminate={
              selectedBrandIds.length > 0 &&
              !allBrandsSelected &&
              props.brands.some(({ id }) => selectedBrandIds.includes(id))
            }
            checked={allBrandsSelected}
            onChange={handleCheckAllBrands}
            disabled={props.brands.length === 0}
          /> */}
        </div>
        <div className="flex-1">
          <ReceiverBrandActions
            handleApprove={() => props.handleApproveBrandRequests(selectedBrandIds)}
            handleRequest={() => props.handleRequestBrands(selectedBrandIds)}
            handleReject={() => props.handleDeclineBrandRequests(selectedBrandIds)}
            disabled={selectedBrandIds.length === 0}
          />
          <span className="ml-1 grey">
            {`(${selectedBrandIds.length} ${t('common:brands')} selected)`}
          </span>
        </div>
        <div className="receiver-catalogue__brand-count pr-3">
          <span>{`${props.brandFilterCount} ${t('common:of')} ${props.allBrandsCount} ${t(
            'common:brands'
          )}`}</span>
        </div>
      </div>
      <div className="receiver-catalogue-list__header">
        <span />
        <div className=" flex flex-row items-center">
          {t('common:brand')}
          <div className="ml-3 flex flex-row items-center">
            <TableFilterPopover
              values={allAccessableBrands}
              handleChange={ids => {
                props.handleFilterByBrand(ids, statusIds, sortOrder, sourceFilterId);
              }}
              initiallySelectedIds={catalogueQueryParams.filterBrandIds}
              showSearch
            >
              {props.brandFilterIds.length ? (
                <FilterFilled className="table-filter-blue" />
              ) : (
                <FilterOutlined />
              )}
            </TableFilterPopover>

            <div className="ml-1">
              <TableSorter
                selected
                sortOrder={sortOrder}
                handleSort={order => {
                  const orderDirection = sortOrder !== order ? order : '';
                  setSortOrder(orderDirection);
                  props.handleFilterByBrand(
                    catalogueQueryParams.filterBrandIds,
                    statusIds,
                    orderDirection,
                    sourceFilterId
                  );
                }}
              />
            </div>
          </div>
        </div>
        <span>
          {t('receiver:source')}
          <span className="ml-3">
            <SourceFilterPopover
              sourceFilterId={sourceFilterId}
              handleChange={id => {
                setSourceFilterId(id);
                props.handleFilterByBrand(
                  catalogueQueryParams.filterBrandIds,
                  statusIds,
                  sortOrder,
                  id
                );
              }}
            >
              {sourceFilterId ? <FilterFilled className="table-filter-blue" /> : <FilterOutlined />}
            </SourceFilterPopover>
          </span>
        </span>
        <span className="receiver-catalogue__list-row__products">{t('receiver:products')}</span>
        <span>{t('receiver:lastUpdate')}</span>
        <span className="receiver-catalogue__list-row__request-status">
          {t('receiver:status.status')}
          <span className="ml-3">
            <TableFilterPopover
              values={reqStatuses}
              handleChange={ids => {
                setStatusIds(ids);
                props.handleFilterByBrand(
                  catalogueQueryParams.filterBrandIds,
                  ids,
                  sortOrder,
                  sourceFilterId
                );
              }}
              initiallySelectedIds={statusIds}
              height={100}
            >
              {statusIds.length > 0 ? (
                <FilterFilled className="table-filter-blue" />
              ) : (
                <FilterOutlined />
              )}
            </TableFilterPopover>
          </span>
        </span>
        <span className="receiver-catalogue__list-row__actions pr-10">{t('common:actions')}</span>
      </div>

      <div className="catalogue-list-container">
        <InfiniteLoader
          isRowLoaded={({ index }) => !!props.brands[index]}
          loadMoreRows={handleFetchNext}
          rowCount={props.brandFilterCount}
          minimumBatchSize={pageSize}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => {
                if (props.fetching && props.brands.length === 0)
                  return <Spin size="large" className="receiver-catalogue__spinner" />;

                const scrollbarVisible = props.brands.length * 40 > height;

                return (
                  <React.Fragment>
                    <List
                      className={classNames({
                        'receiver-catalogue__list-scrollbar': scrollbarVisible,
                      })}
                      ref={registerChild}
                      onRowsRendered={onRowsRendered}
                      width={width}
                      height={height}
                      rowCount={props.brands.length}
                      rowHeight={40}
                      rowRenderer={rowRenderer}
                    />

                    <Spin
                      spinning={props.fetching && props.brands.length > 0}
                      className="receiver-catalogue__spinner-bottom"
                    />
                  </React.Fragment>
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
      {showExportModalState && (
        <CatalogueExportModal
          showModal={showExportModalState}
          exportType={exportTypeState}
          handleExportModalState={() => setExportModal(!showExportModalState)}
          handleStartExport={(exportState: ExportParamsState) =>
            props.handleStartExport(exportState, selectedBrandId!)
          }
        />
      )}
    </div>
  );
};

export default ReceiverCatalogueList;
