import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { downloadDigitalAssetsList } from '../../../../actions/items/digital_assets/fetch';
import { ThunkAsyncDispatch } from '../../../../../types/global';

export type DigitalAssetsDownloadListButtonProps = {
  className: string;
};

export const DigitalAssetsDownloadListButton: React.FC<DigitalAssetsDownloadListButtonProps> = (
  props: DigitalAssetsDownloadListButtonProps
) => {
  const { t } = useTranslation();

  const dispatch: ThunkAsyncDispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const downloadFile = () => {
    setLoading(true);
    dispatch(downloadDigitalAssetsList()).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Tooltip title={t('digitalAssets:parent.downloadListTooltip')}>
      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={() => downloadFile()}
        className={props.className}
      >
        {t('digitalAssets:parent.downloadList')}
      </Button>
    </Tooltip>
  );
};
