import { connect } from 'react-redux';
import React from 'react';

import { withRouter } from '../withRouter';
import {
  fetchOutOfSyncItems,
  syncItems,
  syncAtNight,
  fetchOutOfSyncItemsCount,
} from '../../actions/brand/synchronisation/index.ts';
import { getTempProductsFilter } from '../../selectors/catalogue/filterSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { nextPage } from '../../utils/Utils';
import OutOfSyncTable from '../../components/body/synchronisation/OutOfSyncTable';
import filterActions from '../../actions/catalogue/filter';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import { convertToGoFilterStructure } from '../../utils/FilterUtils';

class SynchronisationContainer extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchOutOfSyncItems(this.props.brandId));
    this.props.dispatch(fetchOutOfSyncItemsCount(this.props.brandId));
    intercomEvent('viewed-publish-data', { brand_code: this.props.brandCode });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brandId !== this.props.brandId) {
      this.props.dispatch(fetchOutOfSyncItems(this.props.brandId));
    }
  }

  handleSyncItems = itemIds => {
    this.props.dispatch(syncItems({ itemIds, brandId: this.props.brandId }));
  };

  handleBrandSync = () => {
    this.props.dispatch(syncItems({ brandId: this.props.brandId }));
  };

  handleSyncAtNight = itemIds => {
    this.props.dispatch(syncAtNight(itemIds, this.props.brandId));
  };

  handleAddToTempFilter = async itemIds => {
    const { brandId, tempProductsFilter, goOnly } = this.props;
    const response = await this.props.dispatch(filterActions.resetFilter(tempProductsFilter.id));

    if (goOnly) {
      // updateTempFilterItems
      await this.props.dispatch(
        filterActions.updateFilterGo({
          ...response.value.data.filter,
          item_ids: itemIds.join(),
        })
      );
    } else {
      const updateItemsResponse = await this.props.dispatch(
        filterActions.updateTempFilterItems(tempProductsFilter.id, itemIds)
      );
      this.props.dispatch(
        filterActions.updateFilterGo(
          convertToGoFilterStructure({
            ...updateItemsResponse.value.data.filter,
            item_ids: itemIds.join(),
          })
        )
      );
    }
    this.props.navigate(
      `/brand/products/item?brandId=${brandId}&filterId=${tempProductsFilter.id}`
    );
  };

  handleScroll = e => {
    const newPage = nextPage(e, 100, this.props.outOfSyncItems.length);
    if (newPage && !this.props.fetchingOutOfSyncItems) {
      this.props.dispatch(fetchOutOfSyncItems(this.props.brandId, newPage));
    }
  };

  render() {
    const { outOfSyncItems, user } = this.props;

    const canPublishItems = hasPermission(user, 'can_publish_products');

    return (
      <div className="synchronisation-container">
        <div className="synchronisation-content">
          <OutOfSyncTable
            canPublishItems={canPublishItems}
            fetching={this.props.fetchingOutOfSyncItems}
            handleScroll={this.handleScroll}
            outOfSyncItems={outOfSyncItems}
            handleBrandSync={this.handleBrandSync}
            handleSyncItems={this.handleSyncItems}
            handleSyncAtNight={this.handleSyncAtNight}
            handleAddToTempFilter={this.handleAddToTempFilter}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetchingOutOfSyncItems: state.brand.synchronisation.fetchingOutOfSyncItems,
  brandId: state.parent.brands.selectedBrandId,
  outOfSyncItems: state.brand.synchronisation.outOfSyncItems,
  tempProductsFilter: getTempProductsFilter(state),
  user: state.user.user,
  brandCode: getSelectedBrandCode(state),
  goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
});

export default connect(mapStateToProps)(withRouter(SynchronisationContainer));
