import React from 'react';
import { Form, Select, Spin, Empty, Tooltip } from 'antd';
import { Field, FieldProps, FastField } from 'formik';
import { get as _get } from 'lodash';
import { SelectProps, SelectValue } from 'antd/lib/select';

type FormSelectProps = {
  optionToolTip?: boolean;
  name: string;
  className?: string;
  values: {
    id: string | number | undefined;
    name: string | number;
    code?: string;
    path?: string;
    disabled?: boolean;
  }[];
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  useCode?: boolean;
  addPathToOption?: boolean;
  testId?: string;
  noStyle?: boolean;
  clearContainer?: boolean;
  fastField?: boolean;
  searchId?: boolean;
  onChange?: (value: SelectValue) => void;
  handleOnBlur?: () => void;
} & SelectProps<any>;

const FormSelect: React.FC<FormSelectProps> = props => {
  const {
    name,
    className,
    values,
    label,
    useCode,
    addPathToOption,
    required,
    noStyle,
    onChange,
    testId,
    clearContainer,
    fastField,
    optionToolTip,
    searchId,
    handleOnBlur,
    ...selectProps
  } = props;

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched, setFieldValue, setFieldTouched } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        className={className}
        label={label}
        required={required}
        noStyle={noStyle}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <Select
          data-testid={testId}
          value={field.value || (field.value === null ? undefined : field.value)}
          onChange={value =>
            onChange ? onChange(value) : setFieldValue(name, value === undefined ? null : value)
          }
          onBlur={() => {
            setFieldTouched(name);
            if (handleOnBlur) handleOnBlur();
          }}
          filterOption={
            props.onSearch
              ? false
              : (inputValue, option) =>
                  !optionToolTip
                    ? option?.children &&
                      (option?.children[0].toLowerCase().includes(inputValue.toLowerCase()) ||
                        (searchId && option?.value?.toString().includes(inputValue)))
                    : option?.children &&
                      option?.children[0].props.title
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
          }
          getPopupContainer={clearContainer ? undefined : trigger => trigger.parentNode}
          notFoundContent={
            props.loading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
          {...selectProps}
        >
          {values.map(value => (
            <Select.Option
              key={value.id}
              value={useCode ? value.code! : value.id!}
              disabled={value.disabled}
            >
              {!optionToolTip ? (
                value.name
              ) : (
                <Tooltip title={value.name}>
                  <span>{value.name} </span>
                </Tooltip>
              )}
              {addPathToOption && <span style={{ fontSize: '9px' }}>{` (${value.path})`}</span>}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return fastField ? (
    <FastField name={name}>{fieldContent}</FastField>
  ) : (
    <Field name={name}>{fieldContent}</Field>
  );
};

export default FormSelect;
