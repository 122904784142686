import { createSelector } from 'reselect';

const getChannelOverview = state => state.channel.channels.channelOverview;
const getChannelResources = state => state.resources.data.channel;
const getChannelId = state => state.channel.channels.selectedChannelId;
const getChannelItemsCount = state => state.channel.channels.channelItemsCount;
const getChannelPushes = state => state.channelPusher.channelPushes;

const getVersion = (channelResources, acesVersionId, piesVersionId) => {
  if (!acesVersionId && !piesVersionId) return undefined;
  const versionId = acesVersionId || piesVersionId;
  const versionResource = acesVersionId
    ? channelResources.aces_versions
    : channelResources.pies_versions;
  return versionResource.find(version => version.id === versionId);
};

const getPlanPushingStatus = (channelId, planId, channelPushes) => {
  const pushedPlan = channelPushes.find(cp => cp.channelId === channelId && cp.planId === planId);

  if (pushedPlan && !pushedPlan.isCompleted) return true;
  return false;
};

const mapChannelPlanResources = (
  channelResources,
  channel,
  channelItemsCount = [],
  channelPushes = []
) => {
  return {
    ...channel,
    plans: channel.plans
      ? channel.plans.map(plan => {
          const planItemCount = channelItemsCount.find(itemCount => itemCount.plan_id === plan.id);
          const itemCount = planItemCount ? planItemCount.count : 0;
          return {
            ...plan,
            fileType: channelResources.file_types.find(
              fileType => fileType.id === plan.file_type_id
            ),
            frequency: channelResources.delivery_frequencies.find(
              frequency => frequency.id === plan.delivery_frequency_id
            ),
            items_count: itemCount,
            version: getVersion(channelResources, plan.aces_version_id, plan.pies_version_id),
            submissionType: channelResources.submission_types.find(
              submissionType => submissionType.id === plan.submission_type_id
            ),
            pushing: getPlanPushingStatus(channel.id, plan.id, channelPushes),
          };
        })
      : [],
  };
};

export const mapResourcesToChannelOverview = createSelector(
  [getChannelOverview, getChannelResources, getChannelItemsCount, getChannelPushes],
  (channelOverview, channelResources, channelItemsCount, channelPushes) => {
    const integratedChannels = channelResources ? channelResources.integration_types : [];
    const mappedIntegrated = integratedChannels.map(integratedChannel => {
      // find channel matching integrated channel id
      const customIntegrated = channelOverview.find(
        channel => channel.integration_type_id === integratedChannel.id
      );
      return customIntegrated || { ...integratedChannel, integrated: true };
    });
    // filter channels for integrated channels
    const customChannels = channelOverview.filter(channel => !channel.integration_type_id);

    return [...mappedIntegrated, ...customChannels].map(channel =>
      mapChannelPlanResources(channelResources, channel, channelItemsCount, channelPushes)
    );
  }
);

export const mapSelectedChannelId = createSelector(
  [getChannelId, getChannelOverview, getChannelResources],
  (selectedChannelId, channelOverview, channelResources) => {
    if (!selectedChannelId) return undefined;
    const channel = channelOverview.find(({ id }) => id === selectedChannelId);
    return channel ? mapChannelPlanResources(channelResources, channel) : undefined;
  }
);
