import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React from 'react';

type TableSorterProps = {
  handleSort: (order: string) => void;
  selected: boolean;
  sortOrder?: string;
};

const TableSorter: React.FC<TableSorterProps> = props => {
  return (
    <div className="table-sorter" style={{ display: 'inline-grid' }}>
      <CaretUpOutlined
        className={props.selected && props.sortOrder === 'asc' ? 'fill-blue' : 'fill-grey'}
        onClick={() => props.handleSort('asc')}
      />
      <CaretDownOutlined
        onClick={() => props.handleSort && props.handleSort('desc')}
        className={props.selected && props.sortOrder === 'desc' ? 'fill-blue' : 'fill-grey'}
      />
    </div>
  );
};

export default TableSorter;
