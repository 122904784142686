import { CheckCircleOutlined, ClockCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type JobStatusProps = {
  statusId: number;
  status: string;
};

const JobStatus: React.FC<JobStatusProps> = props => {
  const { statusId } = props;
  const { t } = useTranslation();

  const renderButton = (statusId: number) => {
    switch (statusId) {
      case 1: {
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            {t('common:queued')}
          </Tag>
        );
      }
      case 2: {
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            {t('common:processing')}
          </Tag>
        );
      }
      case 3: {
        return (
          <Tag icon={<WarningOutlined />} color="warning">
            {t('common:warning')}
          </Tag>
        );
      }
      case 4: {
        return (
          <Tag icon={<WarningOutlined />} color="error">
            {t('common:error')}
          </Tag>
        );
      }
      case 5: {
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {t('common:success')}
          </Tag>
        );
      }
      default:
        return <div />;
    }
  };

  return renderButton(statusId);
};

export default JobStatus;
