import axios from 'axios';

import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export const createChannel = (params: {
  name: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  integrationTypeId?: number;
}) => {
  const urlParams = utils.createURL([
    { name: 'name', values: params.name },
    { name: 'email', values: params.email },
    { name: 'first_name', values: params.firstName },
    { name: 'last_name', values: params.lastName },
    { name: 'phone', values: params.phone },
    { name: 'integration_type_id', values: params.integrationTypeId },
  ]);

  return {
    type: 'CREATE_CHANNEL',
    payload: axios.post(`${getApiRoot()}/channels${urlParams}`),
  };
};

export const createExportPlan = (params: {
  channelId: number;
  brandId: number;
  fileTypeId: number;
  name: string;
  exportTemplateId?: number;
  filterId?: number;
  inactive?: any;
  baseVehicle?: any;
  addAddtributes?: any;
  withoutPrices?: any;
  priceSheetIds?: any;
  priceTypeIds?: any;
  segmentIds: number[];
  individualYears?: any;
  whitelabelBrandCode?: any;
  whitelabelCustomFieldId?: any;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.channelId },
    { name: 'brand_ids', values: params.brandId },
    { name: 'file_type_id', values: params.fileTypeId },
    { name: 'name', values: params.name },
    { name: 'export_template_id', values: params.exportTemplateId },
    { name: 'filter_ids', values: params.filterId },
    { name: 'inactive', values: params.inactive },
    { name: 'use_base_vehicle', values: params.baseVehicle },
    { name: 'add_addtributes', values: params.addAddtributes },
    { name: 'without_prices', values: params.withoutPrices },
    { name: 'price_sheet_ids', values: params.priceSheetIds },
    { name: 'price_type_ids', values: params.priceTypeIds },
    { name: 'segment_ids', values: params.segmentIds },
    { name: 'individual_years', values: params.individualYears },
    { name: 'whitelabel_brand_code', values: params.whitelabelBrandCode },
    { name: 'whitelabel_custom_field_id', values: params.whitelabelCustomFieldId },
  ]);

  return {
    type: 'CREATE_EXPORT_PLAN',
    meta: { channelId: params.channelId },
    payload: axios.post(`${getApiRoot()}/channels/plans${urlParams}`),
  };
};

export const createExportFtpSettings = (params: {
  planId: number;
  channelId: number;
  server?: string;
  email?: string;
  user?: string;
  password?: string;
  ftpDirectoryTypeId?: string;
  ftpModeId?: number;
  protocolTypeId?: number;
  ftpEncryptionTypeId?: number;
  port?: number;
  customDirectory?: string;
  deliveryTypeId?: number;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_plan_id', values: params.planId },
    { name: 'channel_id', values: params.channelId },
    { name: 'server', values: params.server },
    { name: 'email', values: params.email },
    { name: 'user_name', values: params.user },
    { name: 'password', values: params.password },
    { name: 'ftp_directory_type_id', values: params.ftpDirectoryTypeId },
    { name: 'ftp_mode_id', values: params.ftpModeId },
    { name: 'protocol_type_id', values: params.protocolTypeId },
    { name: 'ftp_encryption_type_id', values: params.ftpEncryptionTypeId },
    { name: 'port', values: params.port },
    { name: 'custom_directory', values: params.customDirectory },
    { name: 'delivery_type_id', values: params.deliveryTypeId },
  ]);

  return {
    type: params.planId ? 'CREATE_EXPORT_FTP_SETTINGS' : 'CREATE_DEFAULT_EXPORT_FTP_SETTINGS',
    meta: { channelId: params.channelId },
    payload: axios.post(`${getApiRoot()}/channels/ftp_settings${urlParams}`),
  };
};

export const createSchedule = (params: {
  planId?: number;
  channelId?: number;
  deliveryFrequencyId?: number;
  deliveryDate?: any;
  deliveryDay?: any;
  deliveryTime?: any;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_plan_id', values: params.planId },
    { name: 'channel_id', values: params.channelId },
    { name: 'delivery_frequency_id', values: params.deliveryFrequencyId },
    { name: 'delivery_date', values: params.deliveryDate },
    { name: 'delivery_day', values: params.deliveryDay },
    { name: 'delivery_time', values: params.deliveryTime },
  ]);

  return {
    type: params.planId ? 'CREATE_EXPORT_SCHEDULE' : 'CREATE_DEFAULT_EXPORT_SCHEDULE',
    meta: { channelId: params.channelId },
    payload: axios.post(`${getApiRoot()}/channels/schedule_settings${urlParams}`),
  };
};

export const createDefaultPlans = (params: { channel_id: number; brands: any[] }) => {
  return {
    type: 'CREATE_CHANNEL_DEFAULT_PLANS',
    meta: { channelId: params.channel_id },
    payload: axios.post(`${getNonGwApiRoot()}/channels/defaults/plans`, params),
  };
};

export const pushChannelUpdate = (channelPlanId: number) => {
  const params = utils.createURL([
    { name: 'channel_plan_ids', values: channelPlanId },
    { name: 'manual_delivery_type_id', values: 1 },
  ]);

  return {
    type: 'PUSH_CHANNEL_UPDATE',
    meta: { channelPlanId },
    payload: axios.post(`${getNonGwApiRoot()}/channels/plans/files${params}`),
  };
};

export const createEbayDefaultSettings = (params: {
  channelId?: number;
  planId?: number;
  titleDescriptionTypeId?: number;
  withoutPrices?: any;
  priceSheetId?: number;
  priceTypeId?: number;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.channelId },
    { name: 'channel_plan_id', values: params.planId },
    { name: 'title_description_type_id', values: params.titleDescriptionTypeId },
    { name: 'manage_prices_manually', values: params.withoutPrices },
    { name: 'price_sheet_id', values: params.priceSheetId },
    { name: 'price_type_id', values: params.priceTypeId },
  ]);

  return {
    type: params.planId
      ? 'CREATE_EBAY_INTEGRATED_SETTINGS'
      : 'CREATE_EBAY_DEFAULT_INTEGRATED_SETTINGS',
    payload: axios.post(`${getApiRoot()}/channels/integrated_default_settings${urlParams}`),
    meta: { channelId: params.channelId },
  };
};

export const createEbayDefaultDescriptionTypes = (params: {
  planId?: number;
  channelId?: number;
  descriptionTypeIds?: number;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.planId ? undefined : params.channelId },
    { name: 'channel_plan_id', values: params.planId },
    { name: 'description_type_ids', values: params.descriptionTypeIds, allowEmpty: true },
  ]);

  return {
    type: params.planId ? 'CREATE_EBAY_DESC_TYPES' : 'CREATE_EBAY_DEFAULT_DESC_TYPES',
    payload: axios.post(
      `${getApiRoot()}/channels/integrated_default_description_types${urlParams}`
    ),
    meta: { channelId: params.channelId, planId: Number(params.planId) },
  };
};

export default {
  createExportFtpSettings,
  createExportPlan,
  createChannel,
  createSchedule,
  pushChannelUpdate,
  createEbayDefaultSettings,
  createEbayDefaultDescriptionTypes,
};
