import React from 'react';
import DigitalAssetEditBoxLeft from './DigitalAssetEditBoxLeft';
import DigitalAssetEditBoxRight from './DigitalAssetEditBoxRight';
import DigitalAssetTypePreview from '../../digital_assets/digital_asset_preview/DigitalAssetTypePreview';

class DigitalAssetEdit extends React.Component {
  render() {
    const {
      digitalAssetResources,
      languages,
      countries,
      selectedDigitalAsset,
      updateDigitalAssetParent,
      canManageDigitalAssets,
    } = this.props;

    return (
      <div className="digital-asset-parent__edit">
        {!selectedDigitalAsset ? (
          <div className="title">No Digital Asset selected</div>
        ) : (
          <div className="digital-asset-parent__edit-content">
            <div className="digital-asset-parent__edit-preview">
              <div className="title">Asset</div>
              <DigitalAssetTypePreview
                asset={selectedDigitalAsset}
                digitalAssetResources={digitalAssetResources}
              />
            </div>
            <DigitalAssetEditBoxLeft
              selectedDigitalAsset={selectedDigitalAsset}
              updateDigitalAssetParent={updateDigitalAssetParent}
              digitalAssetResources={digitalAssetResources}
              languages={languages}
              countries={countries}
              canManageDigitalAssets={canManageDigitalAssets}
            />
            <DigitalAssetEditBoxRight
              selectedDigitalAsset={selectedDigitalAsset}
              updateDigitalAssetParent={updateDigitalAssetParent}
              digitalAssetResources={digitalAssetResources}
              canManageDigitalAssets={canManageDigitalAssets}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DigitalAssetEdit;
