import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import PriceSheetSelect from './PriceSheetSelect';
import { PriceTypes } from '../body/catalogue/CatalogueExportModal';
import { ExtendedResources, Price } from '../../../types/resources';
import { PriceSheetType } from '../../../types/price_sheet';
import translationConstants from '../../constants/ImportExportTranslation.json';

type ExportAcesProps = {
  piesVersions: ExtendedResources[];
  onChange: (obj: any) => void;
  defaultVersion: number;
  showPriceOption: boolean;
  priceSheets: PriceSheetType[];
  priceTypes: Price[];
};

function ExportPies(props: ExportAcesProps) {
  const [exportPiesState, setExportPiesState] = React.useState<{
    priceSheetIds: number[];
    priceTypeIds: number[];
    withoutPrices: boolean;
    piesVersion: number;
  }>({
    piesVersion: props.defaultVersion || props.piesVersions[0].id,
    priceSheetIds: [],
    priceTypeIds: [],
    withoutPrices: false,
  });
  return (
    <React.Fragment>
      <div className="export-aces__version">
        <div className="export-aces__label">{translationConstants.version}</div>
        <Radio.Group
          onChange={(e: RadioChangeEvent) =>
            setExportPiesState(values => {
              const newState = {
                ...values,
                piesVersion: e.target.value,
              };
              props.onChange(newState);
              return newState;
            })
          }
          value={exportPiesState.piesVersion}
        >
          {props.piesVersions.map((p: any) => (
            <Radio key={p.id} value={p.id}>
              {p.code}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {props.showPriceOption && (
        <PriceSheetSelect
          priceSheets={props.priceSheets}
          priceTypes={props.priceTypes}
          updateSelection={(selection: PriceTypes) =>
            setExportPiesState(values => {
              const newState = {
                ...values,
                priceSheetIds: selection.selectedPriceSheetIds,
                priceTypeIds: selection.selectedPriceTypeIds,
                withoutPrices: selection.withoutPrices,
              };
              props.onChange(newState);
              return newState;
            })
          }
          multiSelectSheets
          multiSelectTypes
        />
      )}
    </React.Fragment>
  );
}

export default ExportPies;
