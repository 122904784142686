import React from 'react';
import { AutoSizer, List as VList } from 'react-virtualized';
import { Select } from 'antd';
import { typingDone } from '../../utils/Utils';

type VSelectProps = {
  data: { id: string | number; name: string }[];
  onChange: (id: number | string | null) => void;
  onBlur?: () => void;
  value: number | string | null | undefined;
  placeholder?: string;
  width?: number;
};

const VSelect: React.FC<VSelectProps> = ({ data, onChange, onBlur, value, placeholder, width }) => {
  const [open, setOpen] = React.useState(false);
  const [internalValue, setInternalValue] = React.useState(value);
  const [keyword, setKeyword] = React.useState('');
  const filterData = data.filter(({ name }: any) =>
    name.toLowerCase().includes(keyword.toLowerCase())
  );
  const mappedValue = React.useMemo(() => {
    const value = data.find(({ id }: any) => id === internalValue);
    return value ? value.name : undefined;
  }, [data, internalValue]);

  const onClickMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSearch = (value: string) => {
    typingDone(() => {
      setKeyword(value);
    });
  };

  const renderItem = ({ index, key, style }: any) => {
    const item = filterData[index];
    const selected = item.id === internalValue;
    return (
      <div
        key={key}
        className={`flex items-center cursor-pointer text-gray-900 p-2 hover:bg-primary-100 ${
          selected ? 'bg-primary-300' : ''
        }`}
        style={style}
        onClick={e => {
          e.stopPropagation();
          setOpen(false);
          setInternalValue(item.id);
          onChange(item.id);
        }}
      >
        {item.name}
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div className="dropdown-wrapper" onMouseDown={onClickMenu}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <VList
              className="VirtualSelectGrid"
              height={400}
              rowCount={filterData.length}
              rowHeight={30}
              rowRenderer={renderItem}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  return (
    <span onClick={() => setOpen(true)}>
      <Select
        open={open}
        onBlur={() => {
          setOpen(false);
          if (onBlur) onBlur();
        }}
        value={mappedValue}
        style={{ width }}
        placeholder={placeholder}
        dropdownRender={renderMenu}
        showSearch
        getPopupContainer={trigger => (trigger?.parentNode as HTMLElement) || document.body}
        onSearch={handleSearch}
        allowClear
        onChange={(val: string | number | undefined) => {
          if (!val) setInternalValue(null);
          onChange(null);
        }}
      />
    </span>
  );
};

export default VSelect;
