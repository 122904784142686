export const WAREHOUSE_DISTRIBUTOR = 'Warehouse Distributor';
export const SOCIAL_INFORMATION = 'Social Information';
export const COMPANY_INFORMATION = 'Company Information';
export const ADDRESS_PARTS = [
  'locality',
  'administrative_area_level_1',
  'country',
  'postal_code',
  'postal_code_suffix',
];
