import React from 'react';
import { CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';

type SelectWithPreviewProps = {
  value: string | number | null;
  onChange: (e: any) => void;
  showSelect?: boolean;
  allowClear?: boolean;
  size?: any;
  showSearch?: boolean;
};

const SelectWithPreview: React.FC<SelectWithPreviewProps> = ({
  onChange,
  value,
  showSelect,
  allowClear,
  size,
  showSearch,
  ...props
}) => {
  const [isEditAble, setIsEditAble] = React.useState(showSelect || !value || false);
  React.useEffect(() => {
    if (!value) {
      setIsEditAble(true);
    }
  }, [value]);
  return isEditAble ? (
    <Select
      size={size || 'default'}
      autoFocus={!!value}
      defaultOpen={!!value}
      allowClear={allowClear}
      value={value || undefined}
      showSearch={showSearch}
      filterOption={(input: any, option: any) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(val: string | number | undefined) => {
        if (val) {
          setIsEditAble(false);
        }
        onChange(val ? val.toString() : undefined);
      }}
      onBlur={(e: React.FocusEvent<HTMLElement>) => {
        if (e.target.nodeValue || value) {
          setIsEditAble(false);
        }
      }}
      style={{ width: '100%' }}
    >
      {props.children}
    </Select>
  ) : (
    <span onClick={() => setIsEditAble(true)} className="select-with-preview">
      <span>{value}</span>
      <DownOutlined className="icon-down" />
      <CloseCircleFilled className="icon-close-circle" onClick={() => onChange(undefined)} />
    </span>
  );
};

export default SelectWithPreview;
