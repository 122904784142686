import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

let fetchOfferCanceltoken: any;
let fetchItemSettingsCanceltoken: any;
let fetchCategoriesCanceltoken: any;
let fetchIntegratedSettingsCanceltoken: any;
let fetchEpIdsCanceltoken: any;

export const fetchEbayOffer = (itemId: number) => {
  if (fetchOfferCanceltoken) fetchOfferCanceltoken();

  const params = utils.createURL([{ name: 'item_ids', values: itemId }]);

  return {
    type: 'FETCH_EBAY_OFFER',
    meta: { itemId },
    payload: axios.post(
      `${getApiRoot()}/ebay_native/offer${params}`,
      {},
      {
        cancelToken: new CancelToken(c => {
          fetchOfferCanceltoken = c;
        }),
      }
    ),
  };
};

export const fetchEbayItemSettings = (itemId: number) => {
  if (fetchItemSettingsCanceltoken) fetchItemSettingsCanceltoken();

  const params = utils.createURL([{ name: 'item_ids', values: itemId }]);

  return {
    type: 'FETCH_EBAY_ITEM_SETTINGS',
    payload: axios.get(`${getApiRoot()}/items/ebay_settings${params}`, {
      cancelToken: new CancelToken(c => {
        fetchItemSettingsCanceltoken = c;
      }),
    }),
  };
};

export const fetchIntegratedSettings = (itemId: number) => {
  if (fetchIntegratedSettingsCanceltoken) fetchIntegratedSettingsCanceltoken();

  const params = utils.createURL([{ name: 'item_id', values: itemId }]);

  return {
    type: 'FETCH_INTEGRATED_SETTINGS',
    payload: axios.get(`${getApiRoot()}/items/integrated_channel_settings${params}`, {
      cancelToken: new CancelToken(c => {
        fetchIntegratedSettingsCanceltoken = c;
      }),
    }),
  };
};

export const fetchEpIds = (itemId: number) => {
  if (fetchEpIdsCanceltoken) fetchEpIdsCanceltoken();

  const params = utils.createURL([{ name: 'item_id', values: itemId }]);

  return {
    type: 'FETCH_EPIDS',
    payload: axios.get(`${getApiRoot()}/items/ebay_vehicles${params}`, {
      cancelToken: new CancelToken(c => {
        fetchEpIdsCanceltoken = c;
      }),
    }),
  };
};

export const fetchItemEbayCategories = (itemId: number, keywords?: string, page = 1) => {
  if (fetchCategoriesCanceltoken) {
    fetchCategoriesCanceltoken();
  }

  const params = utils.createURL([
    { name: 'item_id', values: itemId },
    { name: 'keywords', values: keywords },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_EBAY_CATEGORIES',
    payload: axios.get(`${getApiRoot()}/ebay_categories${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCategoriesCanceltoken = c;
      }),
    }),
    meta: { page },
  };
};
