import { createSelector } from 'reselect';

const getPriceSheets = state => state.parent.priceSheets.priceSheets;
const getItemPrices = state => state.items.pricing.itemPrices;

export const mapPricesToPriceSheet = createSelector(
  [getPriceSheets, getItemPrices],
  (priceSheets, itemPrices) => {
    if (!priceSheets || !itemPrices) return [];
    return priceSheets.map(priceSheet => {
      const pricesMappedToPriceSheet = itemPrices.filter(
        price => price.price_sheet_id === priceSheet.id
      );
      return {
        ...priceSheet,
        item_prices: pricesMappedToPriceSheet,
      };
    });
  }
);
