import React from 'react';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

const SCROLL_WIDTH = 300;

const HorizontalScroll: React.FC = ({ children }) => {
  const scrollRef = React.createRef<HTMLDivElement>();
  const [hasWidth, setHasWidth] = React.useState(false);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(false);

  React.useLayoutEffect(() => {
    if (scrollRef.current) {
      window.scrollTo(0, (scrollRef.current.scrollLeft -= SCROLL_WIDTH));
      setShowLeftArrow(false);
      if (
        scrollRef.current.clientWidth !== 0 &&
        scrollRef.current.clientWidth !== scrollRef.current.scrollWidth
      ) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  React.useEffect(() => {
    if (
      scrollRef &&
      scrollRef.current &&
      scrollRef.current.childElementCount > 1 &&
      scrollRef.current.clientWidth < scrollRef.current.scrollWidth
    ) {
      setHasWidth(true);
    } else {
      setHasWidth(false);
    }
  }, [scrollRef]);

  React.useEffect(() => {
    const handleResize = () => {
      if (scrollRef.current) {
        if (scrollRef.current.clientWidth === scrollRef.current.scrollWidth) {
          setShowLeftArrow(false);
          setShowRightArrow(false);
        }
        const positionRight =
          scrollRef.current &&
          scrollRef.current.scrollWidth -
            (scrollRef.current.clientWidth + scrollRef.current.scrollLeft);
        if (!positionRight || positionRight <= 0) {
          setShowRightArrow(false);
        } else {
          setShowRightArrow(true);
        }
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const scrollToRefLeft = (ref: any) => {
    setShowRightArrow(true);
    if (ref.current.scrollLeft <= SCROLL_WIDTH) {
      setShowLeftArrow(false);
    }
    window.scrollTo({
      left: (ref.current.scrollLeft -= SCROLL_WIDTH),
      behavior: 'smooth',
    });
  };
  const scrollToRefRight = (ref: any) => {
    window.scrollTo({
      left: (ref.current.scrollLeft += SCROLL_WIDTH),
      behavior: 'smooth',
    });
    setShowLeftArrow(true);
    const positionRight =
      ref.current.scrollWidth - (ref.current.clientWidth + ref.current.scrollLeft);
    if (positionRight <= 0) {
      setShowRightArrow(false);
    }
  };

  return (
    <div className="flex items-center overflow-hidden">
      {hasWidth && (
        <div className="w-5 mb-2">
          <DoubleLeftOutlined
            style={{ color: showLeftArrow ? '' : 'lightGrey' }}
            className="cursor-pointer mr-2 ml-1 select-none"
            onClick={() => scrollToRefLeft(scrollRef)}
          />
        </div>
      )}
      <div
        ref={scrollRef}
        className="flex items-center overflow-x-auto no-scrollbar"
        onScroll={event => {
          const element = event.target;
          // @ts-ignore
          if (element.scrollLeft === 0) {
            setShowLeftArrow(false);
          } else {
            setShowLeftArrow(true);
          }
          // @ts-ignore
          if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            setShowRightArrow(false);
          } else {
            setShowRightArrow(true);
          }
        }}
      >
        {children}
      </div>
      <div className="w-5 mb-2">
        {hasWidth && (
          <DoubleRightOutlined
            style={{ color: showRightArrow ? '' : 'lightGrey' }}
            className="cursor-pointer ml-2 mr-1 select-none"
            onClick={() => scrollToRefRight(scrollRef)}
          />
        )}
      </div>
    </div>
  );
};

export default HorizontalScroll;
