import { InfoCircleOutlined } from '@ant-design/icons';
import { Spin, Empty, Checkbox } from 'antd';
import React from 'react';
import classNames from 'classnames';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLUMN_WIDTHS as WIDTHS } from '../../../constants/ApplicationListConstants';
import applicationText from '../../../constants/ApplicationListTranslation.json';
import { ApplicationElement } from '../../../../types/all_application_validation';
import AllApplicationsTableCell from './AllApplicationsTableCell';
import AntTooltip from '../../global/AntTooltip';
import PartNumberPopover from './PartNumberPopover';
import { getConfigAnalyses } from '../../../utils/AllApplicationUtils';
import AllApplicationsError from './AllApplicationsError';
import AllApplicationsActionCell from './AllApplicationsActionCell';
import AllApplicationsAnalysesIcon from './AllApplicationsAnalysesIcon';

const applicationMapping = applicationText as { [key: string]: string };
const COLUMN_WIDTHS = WIDTHS as { [key: string]: number };

type AllApplicationsTableProps = {
  fetching: boolean;
  fetchListingError: boolean;
  overlap: boolean;
  gapValidation?: boolean;
  applicationRows: ApplicationElement[];
  mappedApplicationRows: ApplicationElement[];
  columns: Array<{ [key: string]: string[] } | string>;
  handleApplicationSelect: (id: number) => void;
  fetchNextListings: () => void;
} & WithTranslation;

type AllApplicationsTableState = {
  selectedRowIndexes: number[];
};

class AllApplicationsTable extends React.Component<
  AllApplicationsTableProps,
  AllApplicationsTableState
> {
  constructor(props: AllApplicationsTableProps) {
    super(props);
    this.state = { selectedRowIndexes: [] };
  }

  handleRowSelect = (index: number) => {
    const { selectedRowIndexes } = this.state;
    const checked = selectedRowIndexes.includes(index);

    if (!checked) {
      this.setState({ selectedRowIndexes: [...selectedRowIndexes, index] });
    } else {
      this.setState({ selectedRowIndexes: selectedRowIndexes.filter(i => i !== index) });
    }
  };

  getRowData = () => {
    const { mappedApplicationRows: rows } = this.props;
    const { selectedRowIndexes } = this.state;

    if (selectedRowIndexes.length === 0) return rows;
    return rows.map((app, i) => (selectedRowIndexes.includes(i) ? { ...app } : app));
  };

  checkForHeaderGroups = () => {
    const { columns } = this.props;
    return !!columns.find(column => typeof column === 'object');
  };

  getHeaderGroupName = (groupKey?: string) => {
    return groupKey && applicationMapping[groupKey] ? applicationMapping[groupKey] : groupKey;
  };

  getRowClassnames = ({
    columns,
    rowData,
    rowIndex,
  }: {
    columns: any;
    rowData: any;
    rowIndex: number;
  }) => {
    const { applicationRows } = this.props;

    const groupKey = rowData.duplicate_group || rowData.overlap_group;
    const prevGroupKey =
      rowIndex === 0
        ? undefined
        : applicationRows[rowIndex - 1].duplicate_group ||
          applicationRows[rowIndex - 1].overlap_group;
    const nextGroupKey =
      rowIndex === applicationRows.length - 1
        ? undefined
        : applicationRows[rowIndex + 1].duplicate_group ||
          applicationRows[rowIndex + 1].overlap_group;

    const firstRow = rowIndex === 0 || prevGroupKey !== groupKey;
    const lastRow = nextGroupKey !== groupKey;

    const prevRowValid = rowIndex === 0 ? 1 : applicationRows[rowIndex - 1].valid_vcdb;

    return classNames(
      {
        // TODO: check for better condition than columns.length > 2 (check for frozen columns)
        'application_listing__duplicate-left': groupKey && columns.length > 2,
        'application_listing__duplicate-right': groupKey && columns.length <= 2,
        'application_listing__first-duplicate': groupKey && firstRow,
        'application_listing__last-duplicate': groupKey && lastRow,
      },
      {
        'application_listing__border-top-red': rowData.valid_vcdb === 0 && prevRowValid,
        'application_listing__border-left-red': rowData.valid_vcdb === 0 && columns.length > 2,
        'application_listing__border-right-red': rowData.valid_vcdb === 0 && columns.length <= 2,
      },
      { 'bg-green-200': rowData.hash_key && rowData.application_id }
    );
  };

  getOverlapClassname = (rowData: any, columnKey: string, rowIndex: number) => {
    const { applicationRows } = this.props;
    const overlapCell =
      rowData.overlap_sub_configs && rowData.overlap_sub_configs.includes(columnKey);

    if (overlapCell) return 'bg-orange-300';

    const prevRowData = applicationRows[rowIndex - 1];
    const prevRowOverlapCell =
      prevRowData &&
      prevRowData.overlap_sub_configs &&
      prevRowData.overlap_sub_configs.includes(columnKey);

    return prevRowOverlapCell ? 'bg-orange-300' : '';
  };

  getCellClassname = (rowData: any, columnKey: string, rowIndex: number) => {
    const overlapClass = this.getOverlapClassname(rowData, columnKey, rowIndex);
    if (overlapClass) return overlapClass;

    if (rowData.analyses) {
      const configAnalysis = getConfigAnalyses(rowData.analyses, columnKey);

      if (configAnalysis) {
        // check for group analyses
        if (configAnalysis.code === 'applications_engine_config_changed_base')
          if (columnKey.includes('engine_base')) return '';
        if (configAnalysis.code === 'applications_invalid_sub_configs')
          if (
            rowData[columnKey] &&
            !['part_number', 'year_count', 'qty', 'notes'].includes(columnKey)
          )
            return '';

        if (
          configAnalysis.code !== 'applications_engine_config_changed_base' &&
          configAnalysis.code !== 'applications_invalid_sub_configs'
        )
          return 'bg-red-300';
      }
    }

    return '';
  };

  getColumnGroup = (name: string) => {
    const { columns } = this.props;
    const group = columns.find(column => {
      if (typeof column === 'object') {
        const subColKey = Object.keys(column)[0];
        const subCols = column[subColKey];
        return subCols.find(col => col === name);
      }
      return false;
    });

    return group ? Object.keys(group)[0] : undefined;
  };

  overlapCell = (rowIndex: number, columnKey: string) => {
    const { mappedApplicationRows: rows, t } = this.props;
    const nextRowData = rows[rowIndex + 1] && rows[rowIndex + 1][columnKey];
    const title =
      columnKey === 'notes' || columnKey === 'qualifiers' || columnKey === 'mfr_label'
        ? t('applicationValidation:overlapMessageQualifierMfrNotes')
        : t('applicationValidation:overlapMessage');
    return (
      <div className="application_listing__overlap-cell">
        {nextRowData}
        <AntTooltip title={title}>
          <InfoCircleOutlined className="application_listing__warning-icon ml-2" />
        </AntTooltip>
      </div>
    );
  };

  headerRenderer = ({
    cells,
    columns,
    headerIndex,
  }: {
    cells: any;
    columns: any;
    headerIndex: number;
  }) => {
    const { gapValidation, applicationRows } = this.props;
    const { selectedRowIndexes: selected } = this.state;

    if (headerIndex === 1 || !this.checkForHeaderGroups()) {
      return columns.map((column: { [x: string]: any; key: string }, columnIndex: number) => {
        const columnGroup = this.getColumnGroup(column.dataKey);
        const nextColumn = columns[columnIndex + 1];
        const nextColumnGroup = nextColumn ? this.getColumnGroup(nextColumn.dataKey) : undefined;

        return (
          <div
            key={column.key}
            className={classNames('application_listing__header-cell', {
              'application_listing__header-group-cell':
                columnGroup && columnGroup === nextColumnGroup,
            })}
          >
            {gapValidation && column.key === 'actions' ? (
              <div className="application_listing__header-checkbox">
                <Checkbox
                  checked={!!selected.length && selected.length === applicationRows.length}
                  indeterminate={!!selected.length && selected.length < applicationRows.length}
                />
                <PartNumberPopover
                  applicationRows={applicationRows.filter((row, index) => selected.includes(index))}
                  onClose={() => this.setState({ selectedRowIndexes: [] })}
                />
              </div>
            ) : (
              cells[columnIndex]
            )}
          </div>
        );
      });
    }

    const groupCells: any[] = [];
    let width = 0;

    columns.forEach((column: { [x: string]: any; key: string }, columnIndex: number) => {
      // if there are frozen columns, there will be some placeholders for the frozen cells
      // eslint-disable-next-line no-underscore-dangle
      if (column.__placeholder__) groupCells.push(cells[columnIndex]);
      else {
        width += cells[columnIndex].props.style.width;

        const columnGroup = this.getColumnGroup(column.dataKey);
        const nextColumn = columns[columnIndex + 1];
        if (
          columnIndex === columns.length - 1 ||
          // eslint-disable-next-line no-underscore-dangle
          nextColumn.__placeholder__ ||
          !columnGroup ||
          columnGroup !== this.getColumnGroup(nextColumn.dataKey)
        ) {
          groupCells.push(
            <div
              key={`header-group-cell-${column.key}`}
              style={{ ...cells[columnIndex].props.style, width }}
              className={classNames('application_listing__empty-group-cell', {
                'application_listing__group-header-cell': columnGroup,
              })}
            >
              {this.getHeaderGroupName(columnGroup)}
            </div>
          );
          width = 0;
        }
      }
    });
    return groupCells;
  };

  createCol = (col: { [key: string]: string[] } | string) => {
    const { overlap } = this.props;

    if (typeof col === 'string') {
      return (
        <Column
          key={col}
          title={applicationMapping[col] ? applicationMapping[col] : col}
          className={({ rowData, rowIndex }) => this.getCellClassname(rowData, col, rowIndex)}
          dataKey={col}
          width={COLUMN_WIDTHS[col] ? COLUMN_WIDTHS[col] : 150}
          frozen={col === 'part_number' || col === 'year_count' ? 'right' : false}
          resizable
          cellRenderer={({
            cellData,
            rowData,
            rowIndex,
          }: {
            cellData: any;
            rowData: any;
            rowIndex: number;
          }) => {
            const overlapCell = overlap && rowData.overlap_sub_configs?.includes(col);
            if (overlapCell) return this.overlapCell(rowIndex, col);

            const configAnalysis = rowData.analyses && getConfigAnalyses(rowData.analyses, col);
            return (
              <AllApplicationsTableCell
                col={col}
                cellData={cellData}
                configAnalysis={configAnalysis}
              />
            );
          }}
        />
      );
    }

    const subColKey = Object.keys(col)[0];
    const subCols = col[subColKey];

    return subCols.map(subCol => (
      <Column
        key={subCol}
        title={applicationMapping[subCol] || subCol}
        className={({ rowData, rowIndex }) => this.getCellClassname(rowData, subCol, rowIndex)}
        dataKey={subCol}
        width={COLUMN_WIDTHS[subCol] ? COLUMN_WIDTHS[subCol] : 150}
        resizable
        cellRenderer={({
          cellData,
          rowData,
          rowIndex,
        }: {
          cellData: any;
          rowData: any;
          rowIndex: number;
        }) => {
          const overlapCell =
            overlap && rowData.overlap_sub_configs && rowData.overlap_sub_configs.includes(subCol);
          if (overlapCell) return this.overlapCell(rowIndex, subCol);

          const configAnalysis = rowData.analyses && getConfigAnalyses(rowData.analyses, subCol);
          const groupAnalysis = configAnalysis?.code === 'applications_engine_config_changed_base';

          return (
            <div>
              {configAnalysis && (!groupAnalysis || (groupAnalysis && cellData)) && (
                <AllApplicationsAnalysesIcon analyses={[configAnalysis]} />
              )}
              {cellData && cellData.toString()}
            </div>
          );
        }}
      />
    ));
  };

  render() {
    const {
      columns,
      mappedApplicationRows,
      fetching,
      fetchListingError,
      gapValidation,
      handleApplicationSelect,
    } = this.props;

    return (
      <div className="application_listing__wrapper">
        <AutoResizer>
          {({ height, width }: { height: number; width: number }) => (
            <div style={{ height, width }}>
              <div className="application_listing__table">
                <React.Fragment>
                  <BaseTable
                    data={this.getRowData()}
                    rowClassName={props => this.getRowClassnames(props)}
                    width={width - 1}
                    height={height}
                    rowHeight={40}
                    headerHeight={this.checkForHeaderGroups() ? [40, 40] : 40}
                    headerRenderer={this.headerRenderer}
                    emptyRenderer={() => {
                      if (fetchListingError) return <AllApplicationsError />;
                      if (fetching)
                        return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;
                      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                    }}
                    onEndReached={() => this.props.fetchNextListings()}
                    overscanRowCount={5}
                    fixed
                  >
                    {!fetchListingError && mappedApplicationRows.length > 0 && (
                      <Column
                        key="actions"
                        width={60}
                        cellRenderer={({ rowIndex, rowData }) => {
                          const checked = this.state.selectedRowIndexes.includes(rowIndex);
                          const applicationRow = rowData as ApplicationElement;
                          return (
                            <AllApplicationsActionCell
                              handleEditClick={() =>
                                handleApplicationSelect(applicationRow.application_id)
                              }
                              handleRowSelect={() => this.handleRowSelect(rowIndex)}
                              invalid={applicationRow.valid_vcdb === 0}
                              checked={checked}
                              showCheckbox={gapValidation}
                              applicationData={this.props.applicationRows[rowIndex]}
                              analyses={applicationRow.analyses}
                            />
                          );
                        }}
                      />
                    )}
                    {columns.map(col => this.createCol(col))}
                  </BaseTable>
                  {fetching && mappedApplicationRows.length > 0 && (
                    <Spin
                      style={{ position: 'absolute', bottom: '20px' }}
                      className="spinner-center"
                    />
                  )}
                </React.Fragment>
              </div>
            </div>
          )}
        </AutoResizer>
      </div>
    );
  }
}

export default withTranslation()(AllApplicationsTable);
