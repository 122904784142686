import { DigitalAsset } from './digital_asset';

export type MarketingCopy = {
  id: number;
  parent_id: number;
  code_id: MarketingCopyCodeId;
  reference_id: number;
  reference_code_name: string;
  sub_code_id: MarketingCopySubCodeId | null;
  sub_reference_id: number | null;
  reference_sub_code_name: null | string;
  mapped_items: number;
  language_id: number;
  asset_url: string | null;
  asset_thumbnail_url: string | null;
  asset_preview_url: string | null;
  assets: DigitalAsset[];
  contents: Content[];
};

export type MarketingCopyItemCount = {
  mapped_items: number | null;
  marketing_copy_id: number;
  part_type_id: number | null;
};

export type Content = {
  id: number;
  content: string;
  type_id: number;
  record_number: number;
};

export enum MarketingCopyCodeId {
  'PARENT' = 3,
  'OWNER' = 2,
  'BRAND' = 1,
  'SUB_BRAND' = 4,
}

export enum MarketingCopySubCodeId {
  'PART_TYPE' = 5,
  'GROUP' = 6,
  'SUB_GROUP' = 7,
}

export type MarketingCopyGroup = {
  id: number;
  name: string;
  sub_groups?: MarketingCopyGroup[];
  type_code_id: number;
};

export type ParentBrandLevels = {
  id: number;
  code: string;
  name: string;
  owners: ParentOwner[];
};

export type ParentOwner = {
  id: number;
  code: string;
  name: string;
  brands: ParentBrand[];
};

export type ParentBrand = {
  id: number;
  code: string;
  name: string;
  sub_brands: ParentSubBrand[];
};

export type ParentSubBrand = {
  id: number;
  code: string;
  name: string;
};
