import React from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { Spin, Empty, Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import AntTooltip from '../../global/AntTooltip';
import { APIScript } from '../../../../types/distribution';
import EditableScriptParameterPopover from './EditableScriptParameterPopover';
import ScriptDataPreviewPopover from './ScriptDataPreviewPopover';
import { fetchScriptDataPreview } from '../../../actions/distribution_apisettings';
import { ApplicationState } from '../../../reducers';

type ApiAutomationTableProps = {
  scripts: APIScript[];
  fetching: boolean;
  handleAction: (key: string, rowData: APIScript) => void;
};

const ApiAutomationTable: React.FC<ApiAutomationTableProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fetchingDataPreview, dataPreview } = useSelector((state: ApplicationState) => {
    return {
      dataPreview: state.distribution.apiSettings.dataPreview,
      fetchingDataPreview: state.distribution.apiSettings.fetchingDataPreview,
    };
  });

  const [item, setItem] = React.useState<{
    id: number;
    partNumber: string;
    brandCode: string;
    brandName: string;
    defaultWhiteLabelCode: string | null;
  }>();

  const scriptItems = (rowData: APIScript) => {
    return [
      ...(rowData.active === 1
        ? [
            {
              label: t('api:resetCache'),
              key: 'reset-cache',
              onClick: () => props.handleAction('resetCache', rowData),
            },
          ]
        : []),
      ...(rowData.active === 1 && rowData.event_id === null
        ? [
            {
              label: (
                <EditableScriptParameterPopover
                  defaultData={rowData.default_data || ''}
                  scriptId={rowData.id}
                >
                  <div>{t('api:runScript')}</div>
                </EditableScriptParameterPopover>
              ),
              key: 'run-script',
            },
          ]
        : []),
      ...(rowData.active === 1 && rowData.event_id
        ? [
            {
              label: (
                <ScriptDataPreviewPopover
                  fetchingDataPreview={fetchingDataPreview}
                  dataPreview={dataPreview}
                  item={item}
                  eventId={rowData.event_id}
                  handleSelectItem={(id, partNumber, brandCode, brandName, defaultWhiteLabelCode) =>
                    setItem({
                      id,
                      partNumber,
                      brandCode,
                      brandName,
                      defaultWhiteLabelCode,
                    })
                  }
                  scriptId={rowData.id}
                  newLogic={rowData.new_logic}
                  displayRunScript
                >
                  <div>{t('api:runScript')}</div>
                </ScriptDataPreviewPopover>
              ),
              key: 'run-script',
              onClick: () => {
                if (rowData.event_id)
                  dispatch(fetchScriptDataPreview(rowData.event_id, rowData.new_logic));
              },
            },
          ]
        : []),
      {
        label: t('api:editScript'),
        key: 'edit-script',
        onClick: () => props.handleAction('editScript', rowData),
      },
    ];
  };

  const columns = [
    { dataKey: 'name', label: t('api:automationName') },
    { dataKey: 'activeValue', label: t('api:active') },
    { dataKey: 'cacheTTlName', label: t('api:cacheTtl') },
    { dataKey: 'lastExecution', label: t('api:lastExecution') },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <Table
            rowClassName={({ index }: { index: number }) =>
              (index !== -1 && 'api-automation-table__row') || ''
            }
            headerHeight={40}
            height={height}
            width={width - 2}
            rowCount={props.scripts.length}
            dataSource={props.scripts}
            rowHeight={40}
            rowGetter={({ index }) => props.scripts[index]}
            noRowsRenderer={() =>
              props.fetching ? (
                <Spin className="spinner-center" style={{ marginTop: '20px' }} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
          >
            {columns.map(col => {
              return (
                <Column
                  width={300}
                  flexGrow={1}
                  dataKey={col.dataKey}
                  label={col.label}
                  key={col.dataKey}
                  headerRenderer={({ label }) => (
                    <div>
                      <span>{label}</span>
                      {label === t('api:cacheTtl') && (
                        <AntTooltip title={t('api:ttlInfo')}>
                          <InfoCircleOutlined className="ant-info-tooltip ml-2" />
                        </AntTooltip>
                      )}
                    </div>
                  )}
                  cellRenderer={({ rowData, dataKey }) => {
                    if (dataKey === 'lastExecution')
                      return (
                        <React.Fragment>
                          {rowData[dataKey]}
                          {rowData.error_message && rowData.status_id === 4 && (
                            <AntTooltip title={t('api:errorInfo')}>
                              <WarningOutlined className="warning" />
                            </AntTooltip>
                          )}
                        </React.Fragment>
                      );
                    if (dataKey === 'name' && rowData.new_logic === 0)
                      return (
                        <div>
                          {rowData[dataKey]}
                          <AntTooltip title={t('api:oldLogicInfo')} className="pl-1">
                            <InfoCircleOutlined className="ant-info-tooltip" />
                          </AntTooltip>
                        </div>
                      );
                    return <div>{rowData[dataKey]}</div>;
                  }}
                />
              );
            })}
            <Column
              width={90}
              flexShrink={0}
              dataKey="action"
              label={t('common:action')}
              cellRenderer={({ rowData }) => (
                <Dropdown
                  menu={{
                    items: scriptItems(rowData),
                  }}
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <Button size="small" data-testid="option">
                    {t('api:options')} <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            />
          </Table>
        </div>
      )}
    </AutoSizer>
  );
};

export default ApiAutomationTable;
