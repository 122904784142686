import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

export type ParentLogsState = {
  fileTypeId: number | null;
};

const initialState = {
  fileTypeId: null,
};

const reducer: Reducer<ParentLogsState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_LOGS_FILE_TYPE': {
      return { ...state, fileTypeId: action.meta.fileTypeId };
    }
  }
  return state;
};

export default reducer;
