import React from 'react';
import { CalendarOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Collapse,
  Checkbox,
  DatePicker,
  Form,
  Select,
  Button,
  Tag,
  Input,
  Modal,
  Popconfirm,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import generalTranslation from '../../../constants/GeneralTranslation.json';
import PageFormik from '../../global/page/PageFormik';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import PartType from '../../global/PartType';
import ItemIntercomFormEventHandler from './ItemIntercomFormEventHandler';
import { Item } from '../../../../types/item';
import { StandardResource } from '../../../../types/resources';
import { UserName } from '../../../../types/user';
import { AsyncDispatch } from '../../../../types/global';
import { booleanToInt } from '../../../utils/Utils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import InfoTooltip from '../../global/InfoTooltip';
import { ApplicationState } from '../../../reducers';
import { fetchExtendedItem } from '../../../actions/items/item/fetch';
import { updateItem } from '../../../actions/catalogue/catalogue/update';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector.js';
import { DefaultValue } from '../../../../types/brand_settings';
import { hasPermission } from '../../../utils/Permissions';

const confirm = Modal.confirm;
const { Panel } = Collapse;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

type ItemPageProps = {
  listGtin: StandardResource[];
  listBrands: StandardResource[];
  listSubBrands: StandardResource[];
  listQualifier: StandardResource[];
  listUOM: StandardResource[];
  onSubmit: (itemId: number, editedItem: Item) => Promise<void>;
  populationStatusList: StandardResource[];
  deleteItem: (itemId: number) => void;
  userNameList: UserName[];
};

const ItemPage = (props: ItemPageProps) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const { item, defaultValues, canMaintainProduct } = useSelector((state: ApplicationState) => ({
    item: getSelectedItems(state)[0],
    defaultValues: state.settings.defaultValues,
    canMaintainProduct: hasPermission(state.user.user, 'can_maintain_products'),
  }));

  const [partTypeDrawerVisible, setPartTypeDrawerVisible] = React.useState(false);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', { location: 'items', brand_code: item?.brand_code });
  }, [item.brand_code]);

  const openPartTypeDrawer = () => {
    setPartTypeDrawerVisible(true);
    sendIntercomPartTypeEditEvent();
  };

  const sendIntercomPartTypeEditEvent = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'item_part_type',
      part_number: item?.part_number,
      brand_code: item?.brand_code,
    });
  };

  const createdBy = props.userNameList.find(user => user.id === item.user_id);
  const reg = /^[0-9]+$|^$/;
  const decimalRegex = /^[0-9]*(\.[0-9]*)?$/;

  const changedBy =
    item.changed_by_user_id && props.userNameList.find(user => user.id === item.changed_by_user_id);

  const clearPartType = (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const updatedItem = {
      ...item,
      category: { id: null },
    };
    dispatch(updateItem(item.id, updatedItem)).then(() => {
      dispatch(fetchExtendedItem(item.id));
    });
    setPartTypeDrawerVisible(false);

    sendIntercomPartTypeEditEvent();
  };

  const defaultGtinUom = defaultValues.find(
    (def: DefaultValue) => def.resource_table === 'gtin_qualifiers'
  );

  const showPartTypeId = defaultValues.find((def: DefaultValue) => def.id === 11)?.value === '1';

  return (
    <PageFormik
      showNoError={!canMaintainProduct}
      showAnalysis
      enableReinitialize
      initialValues={{
        id: item.id || null,
        part_number: item.part_number || '',
        base_part_number: item.base_part_number || '',
        parent_owner_brand_id: item.parent_owner_brand_id || null,
        parent_owner_brand_sub_brand_id: item.parent_owner_brand_sub_brand_id || null,
        universal_part: item.universal_part || 0,
        quantity_per_application: item.quantity_per_application || '',
        quantity_per_application_qualifier_id: item.quantity_per_application_qualifier_id || null,
        quantity_per_application_uom_id: item.quantity_per_application_uom_id || null,
        minimum_order_quantity:
          ((item.minimum_order_quantity || item.minimum_order_quantity === 0) &&
            item.minimum_order_quantity.toString()) ||
          '',
        minimum_order_quantity_uom_id: item.minimum_order_quantity_uom_id || null,
        effective_date: item.effective_date || '',
        available_date: item.available_date || '',
        gtin: (item.gtin && item.gtin.toString()) || '',
        gtin_qualifier_id:
          item.gtin_qualifier_id || (defaultGtinUom?.value && Number(defaultGtinUom.value)) || null,
        quantity_size:
          ((item.quantity_size || item.quantity_size === 0) && item.quantity_size.toString()) || '',
        quantity_size_uom_id: item.quantity_size_uom_id || null,
        container_type_id: item.container_type_id || null,
        unspsc: item.unspsc || '',
        vmrs_code: item.vmrs_code || '',
        part_category_code: (item.part_category_code && item.part_category_code.toString()) || '',
        population_status_id: item.population_status_id || null,
        partTypeName: (item.category && item.category.level3_name) || null,
        subCategoryName: (item.category && item.category.level2_name) || null,
        categoryName: (item.category && item.category.level1_name) || null,
        partTypeId: (item.category && item.category.level1_name && item.part_type_id) || null,
      }}
      onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
        setSubmitPending();
        props
          .onSubmit(item.id, values as Item)
          .then(() => setSubmitSuccess())
          .catch(() => setSubmitError());
      }}
    >
      {({ values, setFieldValue }) => {
        const handleDeleteItem = () => {
          intercomEvent('viewed-all-product', {
            action: 'item-deleted',
            location: 'deleted_item',
            part_number: item.part_number,
            brand_code: item.brand_code,
          });

          confirm({
            title: `${t('item:confirmText')} ${item.part_number}?`,
            okText: generalTranslation.delete,
            okButtonProps: { danger: true },
            onOk() {
              props.deleteItem(item.id);
            },
          });
        };

        const changePartType = (
          partTypeId: number,
          categoryName: string,
          subCategoryName: string,
          partTypeName: string
        ) => {
          setFieldValue('partTypeName', partTypeName);
          setFieldValue('categoryName', categoryName);
          setFieldValue('subCategoryName', subCategoryName);
          const updatedItem = {
            ...item,
            category: { id: partTypeId },
          };
          dispatch(updateItem(item.id, updatedItem)).then(() => {
            dispatch(fetchExtendedItem(item.id));
          });

          intercomEvent('viewed-all-product', {
            action: 'item-saved',
            location: 'item_part_type',
            part_number: item.part_number,
            brand_code: item.brand_code,
          });
        };

        const getUserDetails = (userDetails?: UserName) => {
          if (userDetails)
            return `${userDetails?.first_name} ${userDetails?.last_name} - ${userDetails?.email}`;
          return '';
        };

        return (
          <div className="item-page flex flex-col h-full">
            <div className="flex justify-between mb-4 flex items-center">
              <div className="flex flex-wrap">
                <div>
                  <Tooltip title={getUserDetails(createdBy)}>
                    <Tag>
                      <span className="mr-2">{t('item:created')}</span>
                      <span className="text-gray-600">
                        {createdBy &&
                          `${createdBy.first_name[0]}. ${
                            createdBy.last_name && `${createdBy.last_name}`
                          }  ${moment(item.created_at).format('YYYY-MM-DD')} | ${moment(
                            item.created_at
                          ).format('HH:mm')}`}
                      </span>
                      <CalendarOutlined className="inline-flex px-2" />
                    </Tag>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title={getUserDetails(changedBy)}>
                    <Tag>
                      <span className="mr-2">{t('item:lastChanged')}</span>
                      <span className="text-gray-600">
                        {changedBy
                          ? `${changedBy.first_name[0]}. ${
                              changedBy.last_name && `${changedBy.last_name}`
                            }  `
                          : ''}
                        {`${moment(item.updated_at).format('YYYY-MM-DD')} | ${moment(
                          item.updated_at
                        ).format('HH:mm')}`}
                      </span>
                      <CalendarOutlined className="inline-flex px-2" />
                    </Tag>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-1 flex-wrap-reverse justify-end">
                <Select
                  value={values.population_status_id}
                  style={{ width: 300 }}
                  onChange={(val: number) => setFieldValue('population_status_id', val)}
                  size="small"
                >
                  {props.populationStatusList.map(val => (
                    <Option key={val.id} value={val.id}>
                      {val.name}
                    </Option>
                  ))}
                </Select>
                <Button
                  danger
                  size="small"
                  icon={<DeleteOutlined />}
                  className="w-30 ml-2"
                  onClick={handleDeleteItem}
                >
                  {t('common:delete')}
                </Button>
              </div>
            </div>
            <div className="overflow-y-auto rounded border border-solid border-gray-500">
              <Form className="item-page__form" {...formItemLayout} labelAlign="left">
                <Collapse defaultActiveKey={['1', '2', '3', '4']} style={{ borderWidth: 0 }}>
                  <Panel
                    showArrow={false}
                    header={<div className=" font-medium">{t('item:partType')}</div>}
                    key="4"
                  >
                    <div className="border border-solid border-gray-500 rounded flex items-center">
                      <div
                        onClick={() => openPartTypeDrawer()}
                        className="flex p-2 cursor-pointer items-center flex-1"
                      >
                        <div className="flex-1">
                          {values.partTypeName ? (
                            `${values.categoryName} | ${values.subCategoryName} | ${
                              values.partTypeName
                            }${
                              showPartTypeId && values.partTypeId ? ` (${values.partTypeId})` : ''
                            }`
                          ) : (
                            <div className="text-gray-600">{t('partType:selectPartType')}</div>
                          )}
                        </div>
                        <div>
                          <Button
                            type="primary"
                            size="small"
                            className="mr-2"
                            onClick={() => openPartTypeDrawer()}
                            disabled={!values.partTypeName}
                          >
                            {t('common:edit')}
                          </Button>
                          <Popconfirm
                            title={t('partType:popDelete')}
                            onConfirm={clearPartType}
                            okText={t('common:delete')}
                            cancelText={t('common:cancel')}
                            okButtonProps={{ danger: true }}
                            onCancel={e => e?.stopPropagation()}
                            disabled={!values.partTypeName}
                          >
                            <Button
                              data-cy="parttype"
                              size="small"
                              className="sm:mt-1 lg:m-0 md:mt-1"
                              onClick={e => {
                                if (values.partTypeName) e.stopPropagation();
                              }}
                              data-testid="part-type"
                            >
                              {values.partTypeName
                                ? t('item:deselectPartType')
                                : t('partType:addPartType')}
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                    {partTypeDrawerVisible && (
                      <PartType
                        onSubmit={changePartType}
                        onClose={() => setPartTypeDrawerVisible(false)}
                        partTypeDrawerVisible
                        categoryName={values.categoryName || undefined}
                        subCategoryName={values.subCategoryName || undefined}
                        partTypeName={values.partTypeName || undefined}
                      />
                    )}
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<div className=" font-medium">{t('item:main')}</div>}
                    key="1"
                  >
                    <FormInput
                      label={t('item:partNumber')}
                      name="part_number"
                      placeholder={t('item:partNumber')}
                      testId="part-number"
                    />
                    <FormInput
                      label={t('item:basePartNumber')}
                      name="base_part_number"
                      placeholder={t('item:basePartNumber')}
                      testId="base-part-number"
                    />
                    <FormSelect
                      label={t('glossary:brand')}
                      name="parent_owner_brand_id"
                      values={props.listBrands}
                      placeholder={t('item:selectBrand')}
                      allowClear
                      testId="brand"
                      clearContainer
                    />
                    <FormSelect
                      label={t('glossary:subBrand')}
                      name="parent_owner_brand_sub_brand_id"
                      values={props.listSubBrands}
                      placeholder={t('item:selectSubBrand')}
                      allowClear
                      clearContainer
                    />
                    <Form.Item label={t('item:universalPart')}>
                      <Checkbox
                        style={{ marginLeft: 12 }}
                        checked={values.universal_part === 1}
                        onChange={e =>
                          setFieldValue('universal_part', booleanToInt(e.target.checked))
                        }
                        data-testid="universal-part"
                      />
                    </Form.Item>
                    <Form.Item label={t('item:qtyPerApp')}>
                      <Input
                        data-testid="quantity-per-application"
                        value={values.quantity_per_application}
                        placeholder={t('item:qtyPerApp')}
                        onChange={e => {
                          const qty = e.target.value;
                          if (reg.test(qty)) setFieldValue('quantity_per_application', qty);
                        }}
                        addonAfter={
                          <div className="flex justify-around">
                            <div
                              className="border border-t-0 border-l-0 
                            border-b-0 border-solid border-gray-600 pr-4 h-7.5"
                            >
                              <Select
                                data-testid="quantity-per-application-qualifier"
                                value={values.quantity_per_application_qualifier_id || undefined}
                                showSearch
                                onChange={(qualifier: number) =>
                                  setFieldValue(
                                    'quantity_per_application_qualifier_id',
                                    qualifier || null
                                  )
                                }
                                filterOption={(input, option: any) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={t('item:selectQualifier')}
                                allowClear
                                style={{ width: 300 }}
                              >
                                {props.listQualifier.map(qualifier => (
                                  <Option key={qualifier.id} value={qualifier.id}>
                                    {qualifier.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <div className="pl-5">
                              <Select
                                data-testid="quantity-per-application-uom"
                                value={values.quantity_per_application_uom_id || undefined}
                                showSearch
                                onChange={(uom: number) =>
                                  setFieldValue('quantity_per_application_uom_id', uom || null)
                                }
                                placeholder={t('item:selectUOM')}
                                allowClear
                                style={{ width: 140, borderLeftColor: 'solid' }}
                                filterOption={(input, option: any) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {props.listUOM.map(uom => (
                                  <Option key={uom.id} value={uom.id}>
                                    {uom.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        }
                      />
                    </Form.Item>
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<div className="font-medium">{t('item:orderInfo')}</div>}
                    key="2"
                  >
                    <Form.Item label={t('item:minOrderQty')}>
                      <Input
                        data-testid="minimum-order-quantity"
                        value={values.minimum_order_quantity}
                        placeholder={t('item:minOrderQty')}
                        onChange={e => {
                          const moQ = e.target.value;
                          if (reg.test(moQ)) setFieldValue('minimum_order_quantity', moQ);
                        }}
                        addonAfter={
                          <Select
                            value={values.minimum_order_quantity_uom_id || undefined}
                            showSearch
                            filterOption={(input, option: any) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(uomid: number) =>
                              setFieldValue('minimum_order_quantity_uom_id', uomid || null)
                            }
                            placeholder={t('item:selectUOM')}
                            allowClear
                            style={{ width: 140 }}
                          >
                            {props.listUOM.map(uomID => (
                              <Option key={uomID.id} value={uomID.id}>
                                {uomID.name}
                              </Option>
                            ))}
                          </Select>
                        }
                      />
                    </Form.Item>
                    <Form.Item label={t('item:effectiveDate')}>
                      <DatePicker
                        data-testid="effective-date"
                        onChange={(momentDate, stringDate) =>
                          setFieldValue('effective_date', stringDate)
                        }
                        value={values.effective_date ? moment(values.effective_date) : undefined}
                        format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                      />
                    </Form.Item>
                    <Form.Item label={t('item:availableDate')}>
                      <DatePicker
                        data-testid="available-date"
                        onChange={(momentDate, stringDate) =>
                          setFieldValue('available_date', stringDate)
                        }
                        value={values.available_date ? moment(values.available_date) : undefined}
                        format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                      />
                    </Form.Item>
                    <Form.Item label={t('item:gtin')}>
                      <Input
                        data-testid="gtin"
                        value={values.gtin || undefined}
                        placeholder={t('item:gtin')}
                        onChange={e => {
                          const gtinValue = e.target.value;
                          if (gtinValue.length < 15) {
                            if (reg.test(gtinValue)) setFieldValue('gtin', gtinValue);
                          }
                        }}
                        addonAfter={
                          <Select
                            data-testid="gtin-qualifier"
                            value={values.gtin_qualifier_id || undefined}
                            onChange={(gtinId: number) =>
                              setFieldValue('gtin_qualifier_id', gtinId || null)
                            }
                            placeholder={t('item:selectQualifier')}
                            allowClear
                            style={{ width: 140 }}
                          >
                            {props.listGtin.map(gtin => (
                              <Option key={gtin.id} value={gtin.id}>
                                {gtin.name}
                              </Option>
                            ))}
                          </Select>
                        }
                      />
                    </Form.Item>
                    <Form.Item label={t('item:quantity')}>
                      <Input
                        data-testid="quantity"
                        value={values.quantity_size}
                        placeholder={t('item:quantity')}
                        onChange={e => {
                          const qty = e.target.value;
                          if (decimalRegex.test(qty)) setFieldValue('quantity_size', qty);
                        }}
                        addonAfter={
                          <Select
                            value={values.quantity_size_uom_id || undefined}
                            onChange={(qtid: number) =>
                              setFieldValue('quantity_size_uom_id', qtid || null)
                            }
                            showSearch
                            filterOption={(input, option: any) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t('item:selectUOM')}
                            allowClear
                            style={{ width: 140 }}
                          >
                            {props.listUOM.map(qty => (
                              <Option key={qty.id} value={qty.id}>
                                {qty.name}
                              </Option>
                            ))}
                          </Select>
                        }
                      />
                    </Form.Item>
                    <FormSelect
                      label={t('item:container')}
                      showSearch
                      name="container_type_id"
                      values={props.listUOM}
                      placeholder={t('item:selectContainer')}
                      allowClear
                      clearContainer
                    />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<div className="font-medium">{t('item:additionalCategoryInfo')}</div>}
                    key="3"
                  >
                    <Form.Item
                      label={
                        <div>
                          {t('item:aaiaProductCode')}
                          <InfoTooltip overlay={t('item:aaiaProductInfo')} className="ml-1" />
                        </div>
                      }
                    >
                      <Input
                        data-testid="aaia-product-code"
                        value={values.part_category_code}
                        placeholder={t('item:aaiaProductCode')}
                        onChange={e => {
                          const productCode = e.target.value;
                          if (productCode.length < 7) {
                            if (reg.test(productCode))
                              setFieldValue('part_category_code', productCode);
                          }
                        }}
                      />
                    </Form.Item>
                    <FormInput
                      label={
                        <div>
                          {t('item:unspcs')}
                          <InfoTooltip overlay={t('item:unspcsInfo')} className="ml-1" />
                        </div>
                      }
                      maxLength={10}
                      name="unspsc"
                      placeholder={t('item:unspcs')}
                      testId="unspcs"
                    />
                    <FormInput
                      label={
                        <div>
                          {t('item:vmrsCode')}
                          <InfoTooltip overlay={t('item:vmrsCodeInfo')} className="ml-1" />
                        </div>
                      }
                      maxLength={9}
                      name="vmrs_code"
                      placeholder={t('item:vmrsCode')}
                      testId="vmrs-code"
                    />
                  </Panel>
                </Collapse>
              </Form>
            </div>

            <ItemIntercomFormEventHandler partNr={item?.part_number} brandCode={item?.brand_code} />
          </div>
        );
      }}
    </PageFormik>
  );
};

export default ItemPage;
