import React, { useMemo, useState } from 'react';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { notification, Progress, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../store/ReduxStore';
import colors from '../../constants/AntStyles.json';
import {
  fetchLatestExportLogs,
  fetchRunningExportLogs,
  closeExportNotificaton,
  addToReadyExports,
} from '../../actions/exporter';
import { fetchExport } from '../../actions/brand/import_export/fetch';
import { ApplicationState } from '../../reducers';
import { CurrentExportState } from '../../reducers/exporter/ExporterReducer';
import useInterval from '../../hooks/useInterval';
import DescriptionsList from '../../components/global/DescriptionsList';
import { ExportTypes } from '../../../types/import_export';
import { isReceiver } from '../../utils/UserUtils';
import { Brand } from '../../../types/brand';

const Exporter: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const thunkDispatch = useDispatch<typeof store.dispatch>();
  const runningExports = useSelector((state: ApplicationState) =>
    state.exporter.exports.filter(
      singleExport => singleExport.currentState === CurrentExportState.EXPORTING
    )
  );
  const downloadReadyExports = useSelector((state: ApplicationState) =>
    state.exporter.downloadReadyExports.filter(exp => !exp.downloadState.started)
  );
  const exportsWithNotification = useSelector((state: ApplicationState) =>
    state.exporter.exports.filter(singleExport => singleExport.showNotification)
  );
  const user = useSelector((state: ApplicationState) => state.user.user);
  const receiverUser = useMemo(() => isReceiver(user!), [user]);
  const brands = useSelector((state: ApplicationState) =>
    receiverUser
      ? (state.receiverDataStream.brands as unknown as Brand[])
      : state.parent.brands.brands
  );
  const fileTypeId = useSelector((state: ApplicationState) => state.parent.parentLogs.fileTypeId);

  React.useEffect(() => {
    dispatch(fetchLatestExportLogs(user!.id));
  }, [dispatch, user]);

  React.useEffect(() => {
    downloadReadyExports.forEach(exp => {
      if (!exp.downloadState.started) thunkDispatch(fetchExport(exp.logId));
    });
  }, [downloadReadyExports]);

  const pollRunningExportLogs = () => {
    if (runningExports.length > 0) {
      const exportLogIds = runningExports.map(runningImport => runningImport.logId);
      thunkDispatch(fetchRunningExportLogs(exportLogIds)).then((result: any) => {
        const completedLog = result.value.data.find((log: any) => log.completed_at);
        if (completedLog) {
          dispatch(addToReadyExports(completedLog.id));
        }
      });
    }
  };

  const [delay, setDelay] = useState(2000);
  const [pendingCount, setPendingCount] = useState(0);

  React.useEffect(() => {
    // reset polling interval when new export was started
    if (runningExports.length > pendingCount) {
      setDelay(2000);
    }
    if (runningExports.length !== pendingCount) {
      setPendingCount(runningExports.length);
    }
  }, [pendingCount, runningExports]);

  useInterval(() => {
    if (delay < 600000) setDelay(delay + 1000);
    pollRunningExportLogs();
  }, delay);

  const handleGoToExportReports = (brandId: number) => {
    const path = receiverUser
      ? '/available-brands/export/export-logs'
      : `/brand/import-export/export-logs?brandId=${brandId}`;
    const translationId = 13;
    const customVcdb = 14;
    const attributeSettings = 20;
    if (brandId) navigate(path);
    else {
      if (fileTypeId === translationId) navigate(`/settings/translations/export-logs`);
      if (fileTypeId === customVcdb) navigate(`/settings/custom-vcdb/export-logs`);
      if (fileTypeId === attributeSettings) navigate(`/settings/attributes/export-logs`);
    }
  };

  const getExportMessage = (exportType: ExportTypes) => {
    switch (exportType) {
      case ExportTypes.ACES: {
        return t('glossary:aces');
      }
      case ExportTypes.PIES: {
        return t('glossary:pies');
      }
      case ExportTypes.ASSET: {
        return t('glossary:digitalAssets');
      }
      case ExportTypes.EXCEL: {
        return t('glossary:excel');
      }
      case ExportTypes.EXCEL_PRICE_SHEETS: {
        return t('glossary:excelPriceSheet');
      }
      case ExportTypes.EXCEL_TEMPLATE_ADVANCED: {
        return t('glossary:exportTemplatePlus');
      }
      case ExportTypes.PDF: {
        return t('glossary:pdf');
      }
    }
    return '';
  };

  React.useEffect(() => {
    const showNotification = () => {
      exportsWithNotification.forEach(singleExport => {
        const { exportState } = singleExport;
        const brand = brands.find((brand: any) => brand.id === singleExport.brandId);
        // Excel(full), Export builder, Export builder plus, Excel price sheet
        const newExportHasProgressInfo =
          !!singleExport.fileTypeId && [10, 6, 4, 12].includes(singleExport.fileTypeId);
        const hasProgressInfo = [ExportTypes.EXCEL, ExportTypes.EXCEL_PRICE_SHEETS].includes(
          singleExport.exportType
        );
        setTimeout(() => {
          notification.open({
            key: `${singleExport.logId}`,
            message: `${t('glossary:export')} - 
            ${getExportMessage(singleExport.exportType) || singleExport.exportType} `,
            duration: 0,
            onClose: () => dispatch(closeExportNotificaton(singleExport.logId)),
            description: (
              <DescriptionsList colNumber={2}>
                {singleExport.currentState !== CurrentExportState.DONE && (
                  <DescriptionsList.Item label={t('glossary:brand')}>
                    {brand ? brand.name : ''}
                  </DescriptionsList.Item>
                )}
                {singleExport.currentState !== CurrentExportState.DONE && (
                  <DescriptionsList.Item label={t('exporter:status')}>
                    {singleExport.exportState.status}
                  </DescriptionsList.Item>
                )}
                {singleExport.currentState === CurrentExportState.EXPORTING && (
                  <DescriptionsList.Item label={t('exporter:progress')} fullWidth>
                    <Progress
                      percent={
                        hasProgressInfo || newExportHasProgressInfo
                          ? singleExport.exportState.progress
                          : 99.9
                      }
                      status="active"
                      showInfo={hasProgressInfo || newExportHasProgressInfo}
                    />
                  </DescriptionsList.Item>
                )}
                {singleExport.currentState === CurrentExportState.EXPORTING &&
                  (hasProgressInfo || newExportHasProgressInfo) && (
                    <DescriptionsList.Item label={t('exporter:subProgress')} fullWidth>
                      <Progress percent={singleExport.exportState.subProgress} status="active" />
                    </DescriptionsList.Item>
                  )}
                {singleExport.currentState === CurrentExportState.DONE &&
                  singleExport.exportState.statusId === 4 && (
                    <DescriptionsList.Item
                      fullWidth
                      label={<div className="pl-5">{t('exporter:exportFailed')}</div>}
                    >
                      <div className="flex">
                        <WarningOutlined
                          style={{
                            color: colors['error-color'],
                            paddingRight: '5px',
                            marginTop: '-18px',
                          }}
                        />
                        <div className="flex-1">
                          {!singleExport.exportState.emptyFile && t('exporter:exportError')}
                          {singleExport.exportState.emptyFile &&
                            (singleExport.exportType === ExportTypes.ASSET
                              ? t('exporter:noAssets')
                              : t('exporter:noProducts'))}
                        </div>
                      </div>
                    </DescriptionsList.Item>
                  )}
                {singleExport.currentState === CurrentExportState.DONE &&
                  exportState.statusId === 3 && (
                    <DescriptionsList.Item
                      fullWidth
                      label={<div className="pl-5">{t('exporter:exportWarning')}</div>}
                    >
                      <div className="flex">
                        <WarningOutlined
                          style={{
                            color: colors['warning-color'],
                            paddingRight: '5px',
                            marginTop: '-18px',
                          }}
                        />
                        <div className="flex-1">{t('exporter:exportWarningInfo')}</div>
                      </div>
                    </DescriptionsList.Item>
                  )}
                {singleExport.currentState === CurrentExportState.DONE &&
                  exportState.statusId === 5 && (
                    <DescriptionsList.Item
                      fullWidth
                      label={<div className="pl-5">{t('exporter:exportSuccess')}</div>}
                    >
                      <div className="flex">
                        <CheckCircleOutlined
                          style={{
                            color: colors['success-color'],
                            paddingRight: '5px',
                            marginTop: '-18px',
                          }}
                        />
                        <div className="flex-1 break-words overflow-hidden">
                          {singleExport.fileName && singleExport.fileName}
                        </div>
                      </div>
                    </DescriptionsList.Item>
                  )}
              </DescriptionsList>
            ),
            btn:
              singleExport.currentState === CurrentExportState.DONE ? (
                <Button
                  type="primary"
                  onClick={() => handleGoToExportReports(singleExport.brandId)}
                >
                  {t('exporter:goToReports')}
                </Button>
              ) : null,
          });
        }, 10);
      });
    };
    showNotification();
  }, [exportsWithNotification]);

  return null;
};

export default Exporter;
