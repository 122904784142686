import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MarketingCopyContainer from '../containers/media_and_marketing_copy/MarketingCopyContainer';
import DigitalAssetsParentContainer from '../containers/media_and_marketing_copy/DigitalAssetsParentContainer';
import InsightsContainer from '../containers/insights/InsightsContainer';
import RetoolContainer from '../containers/retool/RetoolContainer';

const CompanyRoutes = () => (
  <Routes>
    <Route path="marketing-copy" element={<MarketingCopyContainer />} />
    <Route path="digital-assets" element={<DigitalAssetsParentContainer />} />
    <Route path="insights" element={<InsightsContainer />} />
    <Route path="retool" element={<RetoolContainer />} />
  </Routes>
);

export default CompanyRoutes;
