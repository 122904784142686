import { combineReducers } from 'redux';
import analyses from './AnalysesReducer';
import customFields, { CustomFieldsBrandState } from './CustomFieldsReducer';
import importExport, { ImportExportState } from './ImportExportReducer';
import resources from './ResourcesReducer';
import synchronisation from './SynchronisationReducer';
import currentBrand, { CurrentBrandState } from './CurrentBrandReducer';
import exportBuilderAdvanced, { ExportBuilderAdvancedState } from './ExportBuilderAdvancedReducer';
import brandExport, { ExportBrandState } from './ExportReducer';
import welcome, { WelcomeState } from './WelcomeReducer';

export type BrandState = {
  analyses: any;
  customFields: CustomFieldsBrandState;
  importExport: ImportExportState;
  exportBuilderAdvanced: ExportBuilderAdvancedState;
  resources: any;
  synchronisation: any;
  currentBrand: CurrentBrandState;
  brandExport: ExportBrandState;
  welcome: WelcomeState;
};

const BrandReducer = combineReducers<BrandState>({
  analyses,
  customFields,
  importExport,
  exportBuilderAdvanced,
  resources,
  synchronisation,
  currentBrand,
  brandExport,
  welcome,
});

export default BrandReducer;
