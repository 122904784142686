import React from 'react';
import TextInput from '../../../global/TextInput';
import Dropdown from '../../../global/Dropdown';
import { parent as translation } from '../../../../constants/DigitalAssetsTranslation.json';

class DigitalAssetEditBoxRight extends React.Component {
  render() {
    const {
      selectedDigitalAsset,
      updateDigitalAssetParent,
      digitalAssetResources,
      canManageDigitalAssets,
    } = this.props;
    const key = selectedDigitalAsset.id;
    const digitalAssetId = selectedDigitalAsset.digital_asset_id || selectedDigitalAsset.id;

    return (
      <div className="digital-asset-parent__edit-column">
        <div className="digital-asset-parent__edit-box edit-box__top">
          <div className="title">
            {`${translation.asset_information} `}
            <span className="info-text">({translation.not_available_application})</span>
          </div>
          <div className="edit-box__content box box_content scrollable">
            <div className="form-field">
              <div className="form-field-name">{translation.asset_reference}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.asset_reference}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'asset_reference', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.duration}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={digitalAssetResources.duration_uoms}
                selectedElement={selectedDigitalAsset.duration_uom_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'duration_uom_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.frame}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.frame}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'frame', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.total_frames}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.total_frame}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'total_frame', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.plane}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.plane}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'plane', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.total_planes}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.total_planes}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'total_planes', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.hemisphere}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={digitalAssetResources.hemispheres}
                selectedElement={selectedDigitalAsset.hemisphere_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'hemisphere_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.plunge}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.plunge}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'plunge', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
          </div>
        </div>
        <div className="digital-asset-parent__edit-box">
          <div className="title">
            {translation.asset_information}{' '}
            <span className="info-text">({translation.only_available_application})</span>
          </div>
          <div className="edit-box__content box box_content">
            <div className="form-field">
              <div className="form-field-name">{translation.asset_name}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.asset_name}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'asset_name', val)}
                maxLength={40}
                readOnly={!canManageDigitalAssets}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DigitalAssetEditBoxRight;
