import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../containers/withRouter';
import SettingsRoutes from '../../routes/brand/SettingsRoutes';
import { withBrandRouteWrapper } from '../../containers/BrandRouteWrapper';

const { Content, Sider } = Layout;

class SettingsPage extends React.Component {
  render() {
    const values = queryString.parse(this.props.location.search);
    const { location, t } = this.props;
    const { brandId } = values;
    return (
      <Layout className="layout">
        <Sider theme="light">
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            onClick={this.handleMenuClick}
            selectedKeys={[location.pathname]}
          >
            <Menu.Item key="/brand/settings/custom-fields">
              <Link
                to={{
                  pathname: `custom-fields`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.customFields')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/settings/scorecards">
              <Link
                to={{
                  pathname: `scorecards`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.scoreCards')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/settings/default-values">
              <Link
                to={{
                  pathname: `default-values`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.defaultValues')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/settings/auto-care-table-version">
              <Link
                to={{
                  pathname: `auto-care-table-version`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.autoCaretableVersion')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/settings/xml-headers">
              <Link
                to={{
                  pathname: `xml-headers`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.xmlHeader')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/settings/price-sheets">
              <Link
                to={{
                  pathname: `price-sheets`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {t('settings:titles.priceSheets')}
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <SettingsRoutes />
        </Content>
      </Layout>
    );
  }
}

export default withRouter(withBrandRouteWrapper(withTranslation()(SettingsPage)));
