import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { AsyncDispatch } from '../../../../types/global';
import { ImportLog } from '../../../../types/import_export';
import {
  fetchParentImport,
  fetchParentImportLogs,
  fetchParentImportLogsDetailsExcel,
  fetchParentLogDetailsJSON,
} from '../../../actions/parent/exportImport/fetch';
import ImportExportLogs from '../../../components/body/import_export/ImportExportLogs';
import { ApplicationState } from '../../../reducers';
import { parentImportLogMapper } from '../../../selectors/importExportLogsSelector';
import { parentOpenImportNotification } from '../../../actions/importer';
import { intercomEvent } from '../../../utils/IntercomUtils';

const ParentImportLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const fileTypeId = useSelector((state: ApplicationState) => state.parent.parentLogs.fileTypeId);

  const { importLogs, fetchingParentImportLogs, errorLogDetails, fetchingParentImportErrors } =
    useSelector((state: ApplicationState) => ({
      importLogs: parentImportLogMapper(state) as ImportLog[],
      fetchingParentImportLogs: state.parent.parentImportLogs.fetchingParentImportLogs,
      errorLogFile: state.parent.parentImportLogs.errorLogFile,
      errorLogDetails: state.parent.parentImportLogs.errorLogDetails,
      fetchingParentImportErrors: state.parent.parentImportLogs.fetchingParentImportErrors,
    }));

  React.useEffect(() => {
    dispatch(fetchParentImportLogs(fileTypeId));
  }, [dispatch, fileTypeId]);

  React.useEffect(() => {
    intercomEvent('viewed-company-settings', { location: 'reports' });
  }, []);

  const saveImportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(
      blob,
      fileTypeId === 13
        ? 'translation_paramount_import_errors.xlsx'
        : (fileTypeId === 20 && 'excel-category-part-attributes_import-errors.xlsx') ||
            'vehicle-table_paramount_import_errors.xlsx'
    );
  };

  const handleErrorDownload = (logId: number) =>
    dispatch(fetchParentImportLogsDetailsExcel(logId)).then(response =>
      saveImportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );

  // @ts-ignore
  const handleImportFileDownload = (logId: number) => dispatch(fetchParentImport(logId));

  const handleFetchImportLogDetailsJSON = (logId: number) =>
    dispatch(fetchParentLogDetailsJSON(logId));

  const handleOpenNotification = (logId: number) => dispatch(parentOpenImportNotification(logId));

  return (
    <ImportExportLogs
      logs={importLogs}
      fetchingLogs={fetchingParentImportLogs}
      fetchingErrors={fetchingParentImportErrors}
      errorLogDetails={errorLogDetails}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleImportFileDownload}
      handleFetchLogDetailsJSON={handleFetchImportLogDetailsJSON}
      handleOpenNotification={handleOpenNotification}
    />
  );
};

export default ParentImportLogsContainer;
