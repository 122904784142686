import { createSelector } from 'reselect';
import { Receiver } from '../../../types/resources';
import { ApplicationState } from '../../reducers';

const getReceivers = (state: ApplicationState) => state.receiver.receivers.receiverList;
const getReceiverResources = (state: ApplicationState) => state.resources.data.receiver as Receiver;
const getBrands = (state: ApplicationState) => state.parent.brands.brands;

export const mapResourcesToReceivers = createSelector(
  [getReceivers, getReceiverResources, getBrands],
  (receivers, receiverResources, brands) => {
    if (!receiverResources) return [];
    const activeBrands = brands.filter(b => b.active && !b.oe_brand);

    return receivers.map(receiver => {
      const requests = [
        ...receiver.requests.map(request => {
          const brand = brands.find(brand => brand.id === request.brand_id);
          return {
            ...request,
            brandName: brand?.name,
            name: receiver.name,
            receiverId: receiver.receiver_id,
            integrationTypeId: receiver.integration_type_id,
          };
        }),
        ...activeBrands
          .filter(b => !receiver.requests.find(r => r.brand_id === b.id))
          .map(b => ({
            brand_id: b.id,
            brandName: b.name,
            name: receiver.name,
            receiverId: receiver.receiver_id,
            integrationTypeId: receiver.integration_type_id,
          })),
      ];

      return {
        ...receiver,
        requests,
      };
    });
  }
);
