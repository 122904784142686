import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const AllApplicationsError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Alert
      className="application_listing__error"
      message={t('applicationValidation:error')}
      type="error"
      showIcon
    />
  );
};

export default AllApplicationsError;
