import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Descriptions } from 'antd';
import classNames from 'classnames';
import AnalysesCategoriesDropdown from './AnalysesCategoriesDropdown';
import {
  Analysis,
  AnalysisEngineBase,
  AnalysisDetailsJSON,
  AnalysisItem,
  AnalysisEngine,
  AnalysisTransmission,
} from '../../../../types/analyses';
import applicationTranslation from '../../../constants/ApplicationTranslation.json';
import AntSelect from '../../global/AntSelect';
import {
  NEW_ENGINE_ANALYSIS_ID,
  ENGINE_BASE_ANALYSIS_ID,
  SUB_CONFIG_ANALYSIS_ID,
  NEW_YEAR_ANALYSIS_ID,
  PART_TYPE_ANALYSIS_IDS,
  NEW_TRANSMISSION_ANALYSIS_ID,
} from '../../../constants/InboxConstants';

const applicationConfigs: {
  [index: string]: string | { [key: string]: any };
} = applicationTranslation;

type QuickfixActionProps = {
  fetchingReviewCategories: boolean;
  analysis: Analysis;
  selectedSubConfigId?: number;
  categories: any[];
  selectSubConfigId: (id: number) => void;
  updateReviewCategory?: (
    groupKey: string,
    referenceId: number,
    categoryId: number,
    typeId: number
  ) => void;
  setDropdownState?: (state: boolean) => void;
  fetchReviewCategories?: (keywords?: string) => void;
  fetchNextReviewCategories?: (event: any, keywords: string) => void;
};

const QuickfixAction: React.FC<QuickfixActionProps> = ({
  fetchingReviewCategories,
  analysis,
  selectedSubConfigId,
  categories,
  selectSubConfigId,
  updateReviewCategory,
  setDropdownState,
  fetchReviewCategories,
  fetchNextReviewCategories,
}) => {
  const { t } = useTranslation();

  const getNewYearName = () => (
    <span className="analyses__new-year-name">
      {analysis.details} {analysis.item_application?.make_name}{' '}
      {analysis.item_application?.model_name}
    </span>
  );

  const categoryBreadcrumb = () => (
    <AnalysesCategoriesDropdown
      fetchingReviewCategories={fetchingReviewCategories}
      analysis={analysis}
      setDropdownState={setDropdownState}
      fetchReviewCategories={fetchReviewCategories}
      fetchNextReviewCategories={fetchNextReviewCategories}
      categories={categories}
      updateReviewCategory={updateReviewCategory}
    />
  );

  const engineBaseField = (engine: AnalysisEngineBase) => (
    <span className="analyses__engine-base">
      <span className="analyses__engine-base-part">
        {`${engine.engine_base_block_types}${engine.engine_base_cylinders}, ${engine.engine_base_liters}l,`}
      </span>
      <span className="analyses__engine-base-part right">
        {`${engine.engine_base_cubic_centimeters}cc /`}
      </span>
      <span className="analyses__engine-base-part cinch">
        {`${engine.engine_base_cubic_inches}cinch,`}
      </span>
      <span>bore:</span>
      <span className="analyses__engine-base-part bore right">
        {`${engine.engine_base_bore_metrics}cm /`}
      </span>
      <span className="analyses__engine-base-part inch">
        {`${engine.engine_base_bore_inches}inch,`}
      </span>
      <span>stroke:</span>
      <span className="analyses__engine-base-part stroke right">
        {`${engine.engine_base_stroke_metrics}cm /`}
      </span>
      <span className="analyses__engine-base-part inch">
        {`${engine.engine_base_stroke_inches}inch`}
      </span>
    </span>
  );

  const itemField = (item: AnalysisItem) => (
    <span className="analyses__new-engine">{`${item.part_number} | ${item.name}`}</span>
  );

  const newEngineTooltip = (engine: AnalysisEngine, subConfigs: string[]) => {
    const configBlocks = Object.keys(engine).map(engineKey => {
      const enginePart = engine[engineKey];
      const configList = Object.keys(enginePart)
        .filter(key => key !== 'id' && key !== 'engine_id')
        .map(key => ({ key, value: enginePart[key] }));
      return (
        <div key={engineKey} className="quickfix__engine-block">
          <span>{t(`quickfix:engine.${engineKey}`)}</span>
          {configList.map(config => (
            <Descriptions key={config.key} className="application__inner-value-list" size="small">
              <Descriptions.Item
                className={classNames({ 'font-bold': subConfigs.includes(config.key) })}
                label={t(`applicationConfigs:${config.key}`)}
              >
                <div>{config.value}</div>
              </Descriptions.Item>
            </Descriptions>
          ))}
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className="quickfix__engine-tooltip">{configBlocks}</div>
        <div style={{ marginTop: '-10px' }}>{t('quickfix:engineTooltipHint')}</div>
      </React.Fragment>
    );
  };

  const newTransmissionTooltip = (transmission: AnalysisTransmission, subConfigs: string[]) => {
    const configList = Object.keys(transmission)
      .filter(key => key.includes('transmission_') && !key.includes('transmission_id'))
      .map(key => ({ key, value: transmission[key] }));

    const configBlock = (
      <div className="quickfix__transmission-block">
        <span>{t(`quickfix:transmission`)}</span>
        {configList.map(config => (
          <Descriptions key={config.key} className="application__inner-value-list" size="small">
            <Descriptions.Item
              className={classNames({ 'font-bold': subConfigs.includes(config.key) })}
              label={t(`applicationConfigs:${config.key}`)}
            >
              <div>{config.value}</div>
            </Descriptions.Item>
          </Descriptions>
        ))}
      </div>
    );

    return (
      <React.Fragment>
        <div className="quickfix__transmission-tooltip">{configBlock}</div>
        <div style={{ marginTop: '-10px' }}>{t('quickfix:transmissionTooltipHint')}</div>
      </React.Fragment>
    );
  };

  const newEngineField = (engine: AnalysisEngine) => {
    const engineBaseParts: string[] = [];
    Object.keys(engine.base)
      .filter(key => key !== 'id' && key !== 'engine_id')
      .forEach(key => {
        const engineConfigName = t(`applicationConfigs:${key}`);
        engineBaseParts.push(`${engineConfigName}: ${engine.base[key]}`);
      });
    return (
      <span className="analyses__new-engine mr-2 flex-1 truncate">
        {engineBaseParts.join(' | ')}
      </span>
    );
  };

  const newTransmissionField = (transmission: AnalysisTransmission) => {
    const transmissionParts: string[] = [];
    Object.keys(transmission)
      .filter(key => key.includes('transmission_') && !key.includes('transmission_id'))
      .forEach(key => {
        const configName = t(`applicationConfigs:${key}`);
        transmissionParts.push(`${configName}: ${transmission[key]}`);
      });
    return (
      <span className="analyses__new-engine mr-2 flex-1 truncate">
        {transmissionParts.join(' | ')}
      </span>
    );
  };

  const subConfig = (configs: AnalysisDetailsJSON, validType: string) => {
    const configKey = Object.keys(configs).find(key => key.includes(validType)) || '';
    const configList = configs[configKey];
    const configName = applicationConfigs[configKey.replace(`${validType}_`, '')];

    return validType === 'invalid' ? (
      <React.Fragment>
        <span className="analyses__subconfig-label">{configName}</span>
        <span className="analyses__engine-base">{configList[0].name}</span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span className="analyses__subconfig-label">{configName}</span>
        <AntSelect
          className="analyses__subconfig-select"
          elements={configList}
          value={selectedSubConfigId}
          onChange={(selectedValue: string) => selectSubConfigId(Number(selectedValue))}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {analysis.type_id === NEW_YEAR_ANALYSIS_ID && (
        <React.Fragment>
          <span className="analyses__message">{t('quickfix:continueYearTo')}</span>
          {getNewYearName()}
        </React.Fragment>
      )}
      {PART_TYPE_ANALYSIS_IDS.includes(analysis.type_id) && (
        <div className="flex items-baseline">
          <div className="mr-2">{t('quickfix:setPartTypeTo')}</div>
          <div className="flex-1">{categoryBreadcrumb()}</div>
        </div>
      )}
      {analysis.type_id === ENGINE_BASE_ANALYSIS_ID && (
        <React.Fragment>
          <div className="analyses__message-row">
            <span className="analyses__message">{t('quickfix:from')}</span>
            {analysis.details_json!.old_engine_base &&
              engineBaseField(analysis.details_json!.old_engine_base!)}
          </div>
          <div className="analyses__message-row">
            <span className="analyses__message">{t('quickfix:to')}</span>
            {engineBaseField(analysis.details_json!.new_engine_base!)}
          </div>
        </React.Fragment>
      )}
      {analysis.type_id === NEW_ENGINE_ANALYSIS_ID && (
        <React.Fragment>
          <div className="analyses__message-row mb-2">
            <span>{t('quickfix:newEngineLabel.forProduct')}</span>
            <span className="ml-2 mr-2">{itemField(analysis.items[0])}</span>
            <span>{t('quickfix:newEngineLabel.selectedEngine')}</span>
          </div>
          <div className="analyses__message-row flex">
            <Tooltip
              title={newEngineTooltip(
                analysis.details_json!.engine,
                analysis.details_json!.set_sub_configs
              )}
              overlayClassName="quickfix__tooltip-wrapper"
            >
              {newEngineField(analysis.details_json!.engine)}
            </Tooltip>
            <span className="leading-loose">{t('quickfix:newEngineLabel.additionalVehicles')}</span>
          </div>
        </React.Fragment>
      )}
      {analysis.type_id === NEW_TRANSMISSION_ANALYSIS_ID && (
        <React.Fragment>
          <div className="analyses__message-row mb-2">
            <span>{t('quickfix:newEngineLabel.forProduct')}</span>
            <span className="ml-2 mr-2">{itemField(analysis.items[0])}</span>
            <span>{t('quickfix:newTransmissionLabel.selectedTransmission')}</span>
          </div>
          <div className="analyses__message-row flex">
            <Tooltip
              title={newTransmissionTooltip(
                analysis.details_json!.transmission!,
                analysis.details_json!.set_sub_configs
              )}
              overlayClassName="quickfix__tooltip-wrapper"
            >
              {newTransmissionField(analysis.details_json!.transmission!)}
            </Tooltip>
            <span className="leading-loose">
              {t('quickfix:newTransmissionLabel.additionalVehicles')}
            </span>
          </div>
        </React.Fragment>
      )}
      {analysis.type_id === SUB_CONFIG_ANALYSIS_ID && (
        <React.Fragment>
          <div className="analyses__message-row">
            <span className="analyses__message">{t('quickfix:from')}</span>
            {subConfig(analysis.details_json!, 'invalid')}
          </div>
          <div className="analyses__message-row">
            <span className="analyses__message">{t('quickfix:to')}</span>
            {subConfig(analysis.details_json!, 'valid')}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default QuickfixAction;
