import createActions from './create';
import fetchActions from './fetch';
import updateActions from './update';
import deleteActions from './delete';

export default {
  ...createActions,
  ...fetchActions,
  ...updateActions,
  ...deleteActions,
};
