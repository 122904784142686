import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  fetchItemDeliveryStatuses,
  fetchChannelDeliveryLogsOverview,
} from '../../actions/items/dashboard/fetch';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { Item, ItemDeliveryStatus } from '../../../types/item';
import { DeliveryLog } from '../../../types/delivery_logs';
import DistributionOverview from '../../components/body/dashboard/DistributionOverview';
import ItemInfo from '../../components/global/ItemInfo';
import Page from '../../components/global/page/Page';
import { intercomEvent } from '../../utils/IntercomUtils';

type ItemChannelReceiverContainerProps = {
  dispatch: any;
  selectedItems: Item[];
  selectedItem: Item;
  deliveryStatuses: ItemDeliveryStatus[];
  deliveryLogsOverview: DeliveryLog[];
  selectedBrandCode?: string;
};

function ItemChannelReceiverContainer(props: ItemChannelReceiverContainerProps) {
  React.useEffect(() => {
    intercomEvent('viewed-channel-receiver-item');
  }, []);

  React.useEffect(() => {
    if (props.selectedItem.id) {
      props.dispatch(fetchItemDeliveryStatuses(props.selectedItem.id));
    }
  }, [props.selectedItem.id]);

  const fetchDeliveryLogs = (deliverStatus: ItemDeliveryStatus) => {
    props.dispatch(
      fetchChannelDeliveryLogsOverview({
        itemId: props.selectedItem.id,
        receiverId: deliverStatus.receiver_id,
        channelId: deliverStatus.channel_id,
      })
    );
  };

  if (props.selectedItems.length > 1 || props.selectedItems.length === 0) {
    return <ItemInfo items={props.selectedItems} />;
  }

  return (
    <Page showExpandButton>
      <div className="item-dashboard">
        <DistributionOverview
          selectedBrandCode={props.selectedBrandCode}
          selectedItem={props.selectedItem}
          deliveryStatuses={props.deliveryStatuses}
          deliveryLogsOverview={props.deliveryLogsOverview}
          fetchDeliveryLogs={fetchDeliveryLogs}
        />
      </div>
    </Page>
  );
}

function mapStateToProps(state: ApplicationState) {
  const selectedItems = getSelectedItems(state);
  return {
    selectedItem: selectedItems[0] || {},
    selectedItems,
    deliveryStatuses: state.items.dashboard.deliveryStatuses,
    selectedBrandCode: state.parent.brands.brands.find(
      (brand: any) => brand.id === state.parent.brands.selectedBrandId
    )?.code,
    deliveryLogsOverview: state.items.dashboard.deliveryLogsOverview,
  };
}

export default connect(mapStateToProps)(ItemChannelReceiverContainer);
