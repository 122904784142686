import axios from 'axios';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export function fetchRetoolEmbedUrl(appName: string) {
  const params = utils.createURL([{ name: 'app_name', values: appName }]);

  return {
    type: 'FETCH_RETOOL_EMBED_URL',
    payload: axios.get(`${getApiRoot()}/retool/access_token${params}`),
  };
}

export function clearRetoolEmbedUrl() {
  return {
    type: 'CLEAR_RETOOL_EMBED_URL',
  };
}
