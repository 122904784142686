import React from 'react';
import classNames from 'classnames';
import { Button, Input, Tooltip } from 'antd';
import {
  DeleteOutlined,
  PlusCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import ApplicationDetailsMenu from './ApplicationDetailsMenu';
import ApplicationDetailsMenuHead from './ApplicationDetailsMenuHead';
import ApplicationSubconfig from './ApplicationSubconfig';
import ApplicationVehicleEquipment from './ApplicationVehicleEquipment';
import ApplicationPartType from './ApplicationPartType';
import QualifierContainer from '../../../containers/application/QualifierContainer';
import ApplicationVcdbSelect from './ApplicationVcdbSelect';
import ApplicationVcdbEdit from './ApplicationVcdbEdit';
import { typingDone } from '../../../utils/Utils';
import ApplicationDigitalAssetsContainer from '../../../containers/application/ApplicationDigitalAssetsContainer';
import { withErrorBoundary } from '../../global/ErrorBoundary';

class ApplicationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.keywordRef = React.createRef();
    this.state = {
      keywordSearch: false,
      searchDone: false,
      searchError: false,
    };
  }

  componentDidMount() {
    this.fetchInitialRankedData();
  }

  componentDidUpdate(prevProps) {
    const { rankedApplications, application } = this.props;
    if (this.state.searchDone && !this.isFoundByKeywords(this.props.selectedSubconfig)) {
      this.props.selectSubconfig('vehicle_bases');
    }

    if (prevProps.rankedApplications !== rankedApplications) {
      if (this.state.keywordSearch) {
        const searchError =
          JSON.stringify(rankedApplications).indexOf('"found_by_keywords"') === -1;
        this.setState(prevState => ({ searchDone: prevState.keywordSearch, searchError }));
      } else {
        this.setState(prevState => ({ searchDone: prevState.keywordSearch, searchError: false }));
      }
    }

    if (
      prevProps.application &&
      prevProps.application.item_application_id !== application.item_application_id
    ) {
      this.props.selectSubconfig('vehicle_bases');
      // reset states for new application
      this.props.setRecommendations(false);
      this.setState({ keywordSearch: false, searchDone: false, searchError: false });
      this.fetchInitialRankedData(this.keywordRef.value);
    }
  }

  fetchInitialRankedData = keywords => {
    const { rankedApplications } = this.props;
    const models = rankedApplications.models || rankedApplications.equipment_models;
    const selectedModels = models ? models.filter(model => model.mapped) : [];

    if (keywords) {
      this.setState({ keywordSearch: true });
      this.props.getRankedApplications(keywords).then(() => {
        if (selectedModels.length > 0 && this.props.setRecommendations) {
          this.props.setRecommendations(true);
        }
      });
    } else if (selectedModels && selectedModels.length > 0 && this.props.setRecommendations) {
      // get ranked information only if a make and model is selected
      this.props.getRankedApplications().then(() => {
        this.props.setRecommendations(true);
      });
    }
  };

  handleSearch = keywords => {
    const { keywordSearch } = this.state;
    typingDone(() => this.props.getRankedApplications(keywords));
    if (keywordSearch !== !!keywords) this.setState({ keywordSearch: !!keywords });
  };

  fetchRanks = vcdbIds => {
    this.props.getRankedApplications(undefined, vcdbIds);
  };

  isFoundByKeywords = structureEntry => {
    const { rankedApplications } = this.props;
    let configs;
    if (typeof structureEntry === 'string') {
      configs = rankedApplications[structureEntry] || [];
    } else {
      const groupName = Object.keys(structureEntry)[0];
      const configPrefix = groupName === 'bodies' ? 'body' : groupName.slice(0, -1);
      configs = Object.keys(rankedApplications)
        .filter(key => key.startsWith(configPrefix))
        .map(key => rankedApplications[key]);
    }
    return JSON.stringify(configs).indexOf('"found_by_keywords":true') >= 0;
  };

  getConfigKeys = structureName => {
    let keys = [];
    this.props.applicationStructure.forEach(entry => {
      if (typeof entry === 'string' && entry === structureName) {
        keys = [entry];
      } else {
        const groupName = Object.keys(entry)[0];
        if (groupName === structureName) {
          keys = entry[groupName];
        }
      }
    });
    return keys;
  };

  isApplicationFirst = id => {
    const appIndex = this.props.applications.findIndex(
      application => application.item_application_id === id
    );
    return appIndex === 0;
  };

  isApplicationLast = id => {
    const appIndex = this.props.applications.findIndex(
      application => application.item_application_id === id
    );
    return appIndex === this.props.applications.length - 1;
  };

  subconfig = () => {
    const { selectedSubconfig, selectedItem, vcdbView, createVehicleStatus, changeConfigActive } =
      this.props;
    if (selectedSubconfig === 'vehicle_bases') {
      const configKeysVehicle = this.getConfigKeys(selectedSubconfig);

      return (
        <ApplicationVehicleEquipment
          hideEquipment={this.props.hideEquipment}
          vcdbView={vcdbView}
          selectedCustomVcdb={this.props.selectedCustomVcdb}
          createVehicleStatus={createVehicleStatus}
          changeConfigActive={changeConfigActive}
          ranks={this.props.rankedApplications}
          getRecommendations={this.props.getRecommendations}
          configKeysVehicle={configKeysVehicle}
          selectMake={this.props.selectMake}
          filterMakes={this.props.filterMakes}
          makeKeywords={this.props.makeKeywords}
          modelKeywords={this.props.modelKeywords}
          submodelKeywords={this.props.submodelKeywords}
          selectModel={this.props.selectModel}
          filterModels={this.props.filterModels}
          selectYears={this.props.selectYears}
          filterView={this.props.filterView}
          selectSubmodel={this.props.selectSubmodel}
          filterSubmodels={this.props.filterSubmodels}
          selectRegion={this.props.selectRegion}
          selectType={this.props.selectType}
          selectGroup={this.props.selectGroup}
          mapConfigToApplication={this.props.mapConfigToApplication}
          addCustomValue={this.props.addCustomValue}
          deleteCustomValue={this.props.deleteCustomValue}
          deleteCustomConfigById={this.props.deleteCustomConfigById}
          selectedMakes={this.props.selectedMakes}
          selectedModels={this.props.selectedModels}
          selectedSubmodels={this.props.selectedSubmodels}
          selectedRegions={this.props.selectedRegions}
          selectedYears={this.props.selectedYears || this.props.application.years || []}
          resetEqipment={this.props.resetEqipment}
          resetVehicle={this.props.resetVehicle}
          createVehicle={this.props.createVehicle}
          changeConfiguration={this.props.changeConfiguration}
          confirmChangeConfiguration={this.props.confirmChangeConfiguration}
          allSubconfigValues={this.props.allSubconfigValues}
          setRecommendations={this.props.setRecommendations}
          setVehicleEquipment={this.props.setVehicleEquipment}
          vehicleEquipmentType={this.props.vehicleEquipmentType}
        />
      );
    }
    if (this.props.selectedSubconfig === 'qualifiers') {
      return (
        <QualifierContainer
          application={this.props.application}
          selectedItem={this.props.selectedItem}
          selectSubconfig={this.props.selectSubconfig}
        />
      );
    }
    if (this.props.selectedSubconfig === 'category') {
      return (
        <ApplicationPartType
          mfrLabel={this.props.application.mfr_label}
          defaultMfrLabel={this.props.selectedItem.default_mfr_label}
          applicationCategory={this.props.application.category}
          defaultCategory={selectedItem.category.id ? selectedItem.category : undefined}
          qty={this.props.application.qty}
          defaultQty={this.props.selectedItem.default_application_qty}
          handleCategoryUpdate={this.props.handleCategoryUpdate}
          updateItemApplicationAttributes={this.props.updateItemApplicationAttributes}
        />
      );
    }
    if (this.props.selectedSubconfig === 'engines') {
      const groupStructure = this.getConfigKeys('engines')[0];
      const selectedSubconfigs = {};
      Object.keys(groupStructure).forEach(groupKey => {
        selectedSubconfigs[groupKey] = {};
        groupStructure[groupKey].forEach(configKey => {
          selectedSubconfigs[groupKey][configKey] = this.props.rankedApplications[configKey] || [];
        });
      });
      return (
        <ApplicationSubconfig
          selectedSubconfigs={selectedSubconfigs}
          rankedApplications={this.props.rankedApplications}
          allSubconfigValues={this.props.allSubconfigValues}
          mapConfigToApplication={this.props.mapConfigToApplication}
          addCustomValue={this.props.addCustomValue}
          deleteCustomValue={this.props.deleteCustomValue}
          deleteCustomConfigById={this.props.deleteCustomConfigById}
          linkConfig={this.props.linkConfig}
          getRecommendations={this.props.getRecommendations}
          selectedCustomVcdb={this.props.selectedCustomVcdb}
          vcdbView={vcdbView}
          linkConfigs={this.props.linkConfigs}
          changeConfigActive={changeConfigActive}
          changeConfiguration={this.props.changeConfiguration}
          confirmChangeConfiguration={this.props.confirmChangeConfiguration}
          groupView
        />
      );
    }
    if (this.props.selectedSubconfig === 'positions') {
      const { selectedItem = {} } = this.props;
      return (
        <ApplicationSubconfig
          className="application__position-subconfig"
          selectedSubconfigs={{ positions: this.props.rankedApplications.positions || [] }}
          rankedApplications={this.props.rankedApplications}
          allSubconfigValues={this.props.allSubconfigValues}
          mapConfigToApplication={this.props.mapConfigToApplication}
          addCustomValue={this.props.addCustomValue}
          deleteCustomValue={this.props.deleteCustomValue}
          deleteCustomConfigById={this.props.deleteCustomConfigById}
          linkConfig={this.props.linkConfig}
          getRecommendations={this.props.getRecommendations}
          defaultPositionId={selectedItem.default_position_id}
          selectedCustomVcdb={this.props.selectedCustomVcdb}
          vcdbView={vcdbView}
          linkConfigs={this.props.linkConfigs}
          noRank3={this.props.filterView}
        />
      );
    }
    if (this.props.selectedSubconfig === 'digital_assets') {
      return <ApplicationDigitalAssetsContainer />;
    }
    if (this.props.selectedSubconfig) {
      const configKeys = this.getConfigKeys(this.props.selectedSubconfig);
      const selectedSubconfigs = {};
      configKeys.forEach(key => {
        selectedSubconfigs[key] = this.props.rankedApplications[key] || [];
      });
      return (
        <ApplicationSubconfig
          selectedSubconfigs={selectedSubconfigs}
          rankedApplications={this.props.rankedApplications}
          allSubconfigValues={this.props.allSubconfigValues}
          mapConfigToApplication={this.props.mapConfigToApplication}
          addCustomValue={this.props.addCustomValue}
          deleteCustomValue={this.props.deleteCustomValue}
          deleteCustomConfigById={this.props.deleteCustomConfigById}
          linkConfig={this.props.linkConfig}
          getRecommendations={this.props.getRecommendations}
          selectedCustomVcdb={this.props.selectedCustomVcdb}
          vcdbView={vcdbView}
          changeConfigActive={changeConfigActive}
          changeConfiguration={this.props.changeConfiguration}
          confirmChangeConfiguration={this.props.confirmChangeConfiguration}
          linkConfigs={this.props.linkConfigs}
        />
      );
    }
  };

  render() {
    const {
      t,
      application,
      filterView,
      singleApplicationEdit,
      hideSearch,
      vcdbView,
      canManageVcdb,
      vehicleEquipmentType,
    } = this.props;

    const hideCreatePrevNext = filterView || vcdbView || singleApplicationEdit;

    return (
      <div className="application__details-container flex">
        <div className="application__details flex">
          <div className="application__mapping_left flex">
            {!hideSearch && (
              <div className="application__smart-mapping flex">
                {this.props.deleteApplication && (
                  <Tooltip title={t('common:delete')} mouseEnterDelay={0.5}>
                    <Button
                      className="application__drawer-delete-button"
                      onClick={() => this.props.deleteApplication()}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                )}
                {!hideCreatePrevNext && (
                  <div className="application__button-container flex">
                    <Tooltip title={t('application:addButton')} mouseEnterDelay={0.5}>
                      <Button
                        className="application__add-button"
                        onClick={() => this.props.createApplication()}
                        icon={<PlusCircleOutlined />}
                      />
                    </Tooltip>
                    <div className="flex flex-col ml-1">
                      <Tooltip title={t('application:selectPrevApplication')} mouseEnterDelay={0.5}>
                        <div className="application__up-button">
                          <Button
                            onClick={() =>
                              this.props.selectPreviousApplication(application.item_application_id)
                            }
                            icon={<CaretUpOutlined />}
                            disabled={this.isApplicationFirst(application.item_application_id)}
                            size="small"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title={t('application:selectNextApplication')} mouseEnterDelay={0.5}>
                        <div className="application__down-button">
                          <Button
                            onClick={() =>
                              this.props.selectNextApplication(application.item_application_id)
                            }
                            icon={<CaretDownOutlined />}
                            disabled={this.isApplicationLast(application.item_application_id)}
                            size="small"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
                {!hideSearch && (
                  <div className="application__smart-mapping_input flex">
                    <Input.Search
                      ref={this.keywordRef}
                      placeholder={t('application:smartMapping')}
                      onChange={e => this.handleSearch(e.target.value)}
                      allowClear
                    />
                  </div>
                )}
              </div>
            )}
            <div className="application-configs__table-container flex">
              <div className="application-configs__table flex">
                <ApplicationDetailsMenuHead
                  searchDone={this.state.searchDone}
                  rankedApplications={this.props.rankedApplications}
                  getRecommendations={this.props.getRecommendations}
                  selectedItem={this.props.selectedItem}
                  selectedYears={this.props.selectedYears || this.props.application.years || []}
                  selectSubconfig={this.props.selectSubconfig}
                />
                <ApplicationDetailsMenu
                  filterView={this.props.filterView}
                  vcdbView={vcdbView}
                  vcdbEquipment={vcdbView && vehicleEquipmentType === 'equipment'}
                  equipmentApplication={vehicleEquipmentType === 'equipment'}
                  searchDone={this.state.searchDone}
                  searchError={this.state.searchError}
                  applicationStructure={this.props.applicationStructure}
                  rankedApplications={this.props.rankedApplications}
                  invalidVehicleSubconfigs={this.props.invalidVehicleSubconfigs}
                  application={this.props.application}
                  selectedSubconfig={this.props.selectedSubconfig}
                  getRecommendations={this.props.getRecommendations}
                  applicationQualifiers={this.props.applicationQualifiers}
                  defaultPositionId={
                    !filterView && !vcdbView
                      ? this.props.selectedItem.default_position_id
                      : undefined
                  }
                  allSubconfigValues={this.props.allSubconfigValues}
                  selectSubconfig={this.props.selectSubconfig}
                  mapConfigToApplication={this.props.mapConfigToApplication}
                />
              </div>
            </div>
          </div>
          <div className="application__mapping_right flex flex-col">
            {(canManageVcdb || vcdbView) && (
              <div
                className={classNames('pl-5 flex', {
                  'mb-5': canManageVcdb && !vcdbView,
                  'mb-3': vcdbView,
                })}
              >
                {vcdbView ? (
                  <ApplicationVcdbEdit
                    customSelected={this.props.selectedVcdb}
                    selectCustom={selectedId => {
                      this.props.selectVcdb(selectedId);
                    }}
                    disabled={this.props.disableAddDelete}
                  />
                ) : (
                  <ApplicationVcdbSelect
                    onChange={selectedIds => {
                      this.fetchRanks(selectedIds);
                      if (this.props.refetchYears) this.props.refetchYears();
                    }}
                  />
                )}
                <div className="flex-1 text-right">
                  {!vcdbView && !filterView && canManageVcdb && (
                    <Button onClick={() => this.props.showVcdbDrawer()}>
                      {t('application:editVcdb')}
                    </Button>
                  )}
                </div>
              </div>
            )}

            {this.props.selectedSubconfig && this.subconfig()}
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(withTranslation()(ApplicationDetails));
