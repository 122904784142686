import React from 'react';
import { useTranslation } from 'react-i18next';
import { onePagerUrl, termsUrl, privacyUrl } from '../constants/ParamountReactConstants';

const LoginFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="login__footer">
      {t('login:login_footer', { year: new Date().getFullYear() })}
      <a
        className="login__footer-link"
        href={onePagerUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('login:imprint')}
      </a>
      <a className="login__footer-link" href={termsUrl} target="_blank" rel="noopener noreferrer">
        {t('login:terms')}
      </a>
      <a className="login__footer-link" href={privacyUrl} target="_blank" rel="noopener noreferrer">
        {t('login:policy')}
      </a>
    </div>
  );
};

export default LoginFooter;
