import React from 'react';
import { Route, Routes } from 'react-router-dom';
import APIAutomationContainer from '../../containers/apiSetting/APIAutomationContainer';
import APISettingsContainer from '../../containers/apiSetting/APISettingsContainer';
import AutomationLogsContainer from '../../containers/apiSetting/AutomationLogsContainer';

const ApiRoutes = () => (
  <Routes>
    <Route path="api-settings" element={<APISettingsContainer />} />
    <Route path="automation" element={<APIAutomationContainer />} />
    <Route path="automation-logs" element={<AutomationLogsContainer />} />
  </Routes>
);

export default ApiRoutes;
