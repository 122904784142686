import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';
import { Field, FieldProps } from 'formik';
import { get as _get } from 'lodash';

type FormMultiCheckboxProps = {
  name: string;
  values: {
    id: number;
    name: string;
  }[];
  label?: React.ReactNode;
  required?: boolean;
};

const CheckboxGroup = Checkbox.Group;

const FormMultiCheckbox: React.FC<FormMultiCheckboxProps> = props => {
  const { name, values, label, required } = props;

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched, setFieldValue } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        label={label}
        required={required}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <CheckboxGroup
          onChange={checkedValue => setFieldValue(name, checkedValue)}
          value={field.value}
        >
          <Row>
            {values.map(({ id, name: checkboxName }, i) => (
              <Col span={12} key={i}>
                <Checkbox value={id}>{checkboxName}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </Form.Item>
    );
  };

  return <Field name={name}>{fieldContent}</Field>;
};

export default FormMultiCheckbox;
