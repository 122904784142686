import React from 'react';
import { Button, Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { ButtonProps } from 'antd/lib/button';

type AntPopoverProps = PopoverProps & {
  disabled: boolean;
  buttonProps: ButtonProps & { title?: string };
};

export default class AntPopover extends React.Component<AntPopoverProps> {
  render() {
    const {
      buttonProps: { title, ...buttonProps },
      disabled,
      ...props
    } = this.props;

    const button = (
      <Button {...buttonProps} disabled={disabled}>
        {title}
      </Button>
    );

    return !disabled ? <Popover {...props}>{button}</Popover> : button;
  }
}
