import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { fetchDigitalAssetDescriptions } from './fetch';

export function selectDigitalAsset(selectedId) {
  return dispatch => {
    dispatch({
      type: 'SELECT_DIGITAL_ASSET',
      payload: selectedId,
    });
    dispatch(fetchDigitalAssetDescriptions(selectedId));
  };
}

export function updateDigitalAssetItem(itemDigitalAssetId, key, value) {
  const params = utils.createURL([{ name: key, values: value }]);
  return {
    type: 'UPDATE_DIGITAL_ASSET_ITEM',
    meta: {
      itemDigitalAssetId,
      key,
      value,
    },
    payload: axios.patch(`${getApiRoot()}/items/digital_assets/${itemDigitalAssetId}${params}`),
  };
}

export function updateDescriptionContents(newContents, descriptionId, languageId, typeId) {
  const params = utils.createURL([
    { name: 'language_id', values: languageId },
    { name: 'type_id', values: typeId },
  ]);
  return {
    type: 'UPDATE_DIGITAL_ASSET_DESCRIPTION',
    payload: axios.patch(
      `${getApiRoot()}/items/digital_assets/descriptions/${descriptionId}${params}`,
      newContents
    ),
  };
}
