import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import AntTooltip from '../../global/AntTooltip';

type ChannelTitleWithInfoProps = {
  title: string;
  infoText?: string;
};

const ChannelTitleWithInfo: React.FC<ChannelTitleWithInfoProps> = props => {
  return (
    <div className="flex flex-row">
      <div className="pr-1 truncate ...">{props.title}</div>
      {props.infoText && (
        <AntTooltip
          title={props.infoText}
          className="self-center pr-2"
          overlayClassname="whitespace-pre-line"
        >
          <InfoCircleOutlined className="ant-info-tooltip" />
        </AntTooltip>
      )}
    </div>
  );
};

export default ChannelTitleWithInfo;
