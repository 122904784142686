import React from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../global/Forms/FormSelect';
import { ApplicationState } from '../../../reducers';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';

type ReceiverExportSelectPartsProps = {
  handleSelectBrands: (values: string[]) => void;
};

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

const ReceiverExportSelectParts: React.FC<ReceiverExportSelectPartsProps> = props => {
  const { t } = useTranslation();

  const { authorizedBrands, customFilters } = useSelector((state: ApplicationState) => {
    return {
      authorizedBrands: state.receiverDataStream.authorizedBrands,
      customFilters: getAllCustomFilters(state),
    };
  });

  return (
    <Form className="export__receiver-form" {...formItemLayout} labelAlign="left">
      <FormMultiSelect
        name="receiverExportBrandIds"
        values={authorizedBrands}
        size="small"
        allowClear
        placeholder={t('common:brand')}
        showSearch
        label={t('common:brand')}
        handleChange={props.handleSelectBrands}
        info={t('exporter:infoMultiSelectBrands')}
      />

      <FormSelect
        name="filterId"
        values={customFilters}
        size="small"
        allowClear
        placeholder={t('exporter:selectFilter')}
        showSearch
        label={t('exporter:filter')}
      />
    </Form>
  );
};

export default ReceiverExportSelectParts;
