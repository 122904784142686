import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Select } from 'antd';
import { DownOutlined, EditOutlined, MenuOutlined, RightOutlined } from '@ant-design/icons';
import { CustomFieldOption, CustomFieldType } from '../../../../types/custom_fields';
import JoditHtmlEditor from '../JoditHtmlEditor';

const { Option } = Select;

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: any;
  disabled?: boolean;
  onCollapse?: () => void;
  onRemove?: () => void;
  onEdit?: (id: number, type: string) => void;
  wrapperRef?(node: HTMLLIElement): void;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      disabled,
      onCollapse,
      onRemove,
      onEdit,
      style,
      value,
      wrapperRef,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const columnCellRenderer = (record: any) => {
      const cutomFieldTypeId = record.custom_field_type_id;

      return (
        <div key={record.id}>
          {cutomFieldTypeId === CustomFieldType.Input && (
            <Input placeholder="Text" className="cfs-preview-type" disabled />
          )}
          {cutomFieldTypeId === CustomFieldType.Number && (
            <Input placeholder="Number" className="cfs-preview-type" disabled />
          )}
          {cutomFieldTypeId === CustomFieldType.Dropdown && (
            <Select placeholder="Dropdown" className="cfs-preview-type">
              {record.options &&
                record.options.map((option: CustomFieldOption, index: number) => (
                  <Option key={`dropdown-prev${index}`} value={option.name} disabled>
                    {option.name}
                  </Option>
                ))}
            </Select>
          )}
          {cutomFieldTypeId === CustomFieldType.Checkbox && <Checkbox disabled />}
          {cutomFieldTypeId === CustomFieldType.Text_Editor && (
            <JoditHtmlEditor height={140} readonly />
          )}
          {cutomFieldTypeId === CustomFieldType.Link && (
            <Input className="cfs-preview-type" placeholder="https://" />
          )}
          {cutomFieldTypeId === CustomFieldType.Button && (
            <Button disabled>
              {/* {`${t('common:button')}: ${scripts.find(s => s.id === record.script_id)?.name}`} */}
              {t('api:runScript')}
            </Button>
          )}
        </div>
      );
    };

    return (
      <li
        className={classNames('cfs__tree-row-wrapper', {
          clone,
          ghost,
          indicator,
          'cfs__disable-selection': disableSelection,
          'cfs__disable-interaction': disableInteraction || disabled,
          'cfs__group-header': value.depth === 0,
        })}
        ref={wrapperRef}
        style={
          {
            '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        {...props}
      >
        <div className="cfs__tree-item" ref={ref} style={style}>
          <Action
            // ref={ref}
            className="cursor-move"
            data-cypress="draggable-handle"
            {...handleProps}
          >
            <MenuOutlined className={classNames({ disabled })} />
          </Action>
          {onCollapse && (
            <Action onClick={onCollapse} className="ml-2 cursor-pointer">
              {collapsed ? <RightOutlined /> : <DownOutlined />}
            </Action>
          )}
          <span className="cfs__text">{value.name}</span>
          {!!value.custom_field_type_id && (
            <span className="flex-1">{columnCellRenderer(value)}</span>
          )}
          <div className={classNames('flex', { 'flex-1': value.depth === 0 })}>
            <Button
              className={classNames('ml-5', { 'ml-auto': value.depth === 0 })}
              onClick={() => {
                if (onEdit) onEdit(value.id, value.custom_field_type_id ? 'field' : 'group');
              }}
              icon={<EditOutlined />}
              data-testid="customField-edit"
              disabled={value.id === 'other'}
            >
              {t('common:edit')}
            </Button>
          </div>
          {clone && childCount && childCount > 1 ? (
            <span className="cfs__count">{childCount}</span>
          ) : null}
        </div>
      </li>
    );
  }
);

interface ActionProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

const Action = forwardRef<HTMLDivElement, ActionProps>(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(className)}
        style={
          {
            ...style,
            cursor,
            '--fill': active?.fill,
            '--background': active?.background,
          } as CSSProperties
        }
      />
    );
  }
);
