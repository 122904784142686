import { Reducer } from 'redux';

export type CatalogueManagerState = {
  readonly updateCounter: number;
};

const initialState: CatalogueManagerState = {
  updateCounter: 0,
};

const reducer: Reducer<CatalogueManagerState> = (state = initialState, action) => {
  switch (action.type) {
    case 'TRIGGER_ANALYSES_UPDATE_FULFILLED': {
      return { ...state, updateCounter: 0 };
    }
    case 'TRIGGER_UPDATE_COUNTER': {
      return { ...state, updateCounter: state.updateCounter + 1 };
    }
    case 'RESET_UPDATE_COUNTER': {
      return { ...state, updateCounter: 0 };
    }
  }
  return state;
};

export default reducer;
