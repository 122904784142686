import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';

const getDefaultValuesFromState = (state: ApplicationState) => state.settings.defaultValues;

export type DefaultValuesSelectorState = {
  language: string;
  acesVersion: string;
  individualByBase: string;
  piesVersion: string;
  gtinQualifier: string;
  currency: string;
  priceUom: string;
  packageUom: string;
  packageDimensionUom: string;
};

export const getDefaultValues = createSelector(
  [getDefaultValuesFromState],
  defaultValuesFromState => {
    const defaultValues = defaultValuesFromState;

    const language = defaultValues.find((def: any) => def.resource_table === 'languages');
    const gtinQualifier = defaultValues.find(
      (def: any) => def.resource_table === 'gtin_qualifiers'
    );
    const currency = defaultValues.find((def: any) => def.resource_table === 'price_currencies');
    const priceUom = defaultValues.find((def: any) => def.resource_table === 'price_uoms');
    const packageUom = defaultValues.find((def: any) => def.resource_table === 'package_uoms');
    const packageDimensionUom = defaultValues.find(
      (def: any) => def.resource_table === 'package_dimension_uoms'
    );
    const acesVersion = defaultValues.find((def: any) => def.resource_table === 'aces_versions');
    const individualByBase = defaultValues.find((def: any) => def.id === 5);
    const piesVersion = defaultValues.find((def: any) => def.resource_table === 'pies_versions');
    return {
      language: language.value,
      acesVersion: acesVersion.value,
      individualByBase: individualByBase.value,
      piesVersion: piesVersion.value,
      gtinQualifier: gtinQualifier.value,
      currency: currency.value,
      priceUom: priceUom.value,
      packageUom: packageUom.value,
      packageDimensionUom: packageDimensionUom.value,
    };
  }
);
