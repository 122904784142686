const initialState = {
  smallShopPreview: undefined,
  smallShopPreviewError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SMALL_SHOP_PREVIEW_FULFILLED': {
      return { ...state, smallShopPreview: action.payload.data, smallShopPreviewError: null };
    }
    case 'FETCH_SMALL_SHOP_PREVIEW_PENDING': {
      return { ...state };
    }
    case 'FETCH_SMALL_SHOP_PREVIEW_REJECTED': {
      return {
        ...state,
        smallShopPreview: undefined,
        smallShopPreviewError: action.payload.message,
      };
    }
    case 'DELETE_SMALL_SHOP_PREVIEW': {
      return { ...state, smallShopPreview: undefined, smallShopPreviewError: null };
    }
  }

  return state;
};

export default reducer;
