import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { ApplicationState } from '../../reducers';
import { fetchScheduledImports } from '../../actions/brand/import_export/fetch';
import { AsyncDispatch } from '../../../types/global';
import FTPCredentials from '../../components/body/external_sources/FTPCredentials';
import AutomateImportForm from '../../components/body/import_export/AutomateImportForm';
import { withContainerWrapper } from '../ContainerWrapper';
import { intercomEvent } from '../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const AutomateImportContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const { brandId, brandCode, scheduledImports, fetchingScheduledImports } = useSelector(
    (state: ApplicationState) => {
      const brandId = state.parent.brands.selectedBrandId;
      return {
        brandId,
        scheduledImports: state.brand.importExport.scheduledImports,
        fetchingScheduledImports: state.brand.importExport.fetchingScheduledImports,
        brandCode: getSelectedBrandCode(state),
      };
    }
  );

  React.useEffect(() => {
    intercomEvent('viewed-import-export', { location: 'automate-import', brand_code: brandCode! });
  }, [brandCode]);

  React.useEffect(() => {
    dispatch(fetchScheduledImports(brandId));
  }, [brandId, dispatch]);

  if (fetchingScheduledImports)
    return (
      <div className="bg-white w-full">
        <Spin size="small" className="spinner-center" />;
      </div>
    );

  return (
    <div className="bg-white w-full flex flex-col">
      <div className="pl-5">
        <FTPCredentials
          server={scheduledImports?.server || ''}
          user={scheduledImports?.user_name || ''}
          password={scheduledImports?.password || ''}
          port={scheduledImports?.port || null}
          subText={t('importer:autoImportSubtext')}
        />
      </div>
      {!!scheduledImports && (
        <AutomateImportForm
          brandId={brandId}
          scheduledImports={scheduledImports}
          brandCode={brandCode}
        />
      )}
    </div>
  );
};

export default withContainerWrapper(AutomateImportContainer);
