import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SettingsCustomFieldsContainer from '../../containers/settings/SettingsCustomFieldsContainer.tsx';
import SettingsDefaultValuesContainer from '../../containers/settings/SettingsDefaultValuesContainer';
import SettingsScorecardContainer from '../../containers/settings/SettingsScorecardContainer';
import SettingsAutoCareVersions from '../../containers/settings/SettingsAutoCareVersions';
import SettingsXMLHeadersContainer from '../../containers/settings/SettingsXMLHeadersContainer';
import SettingsPriceSheetsContainer from '../../containers/settings/SettingsPriceSheetsContainer';

const SettingsRoutes = () => (
  <Routes>
    <Route path="custom-fields" element={<SettingsCustomFieldsContainer />} />
    <Route path="scorecards" element={<SettingsScorecardContainer />} />
    <Route path="default-values" element={<SettingsDefaultValuesContainer />} />
    <Route path="auto-care-table-version" element={<SettingsAutoCareVersions />} />
    <Route path="xml-headers" element={<SettingsXMLHeadersContainer />} />
    <Route path="price-sheets" element={<SettingsPriceSheetsContainer />} />
  </Routes>
);

export default SettingsRoutes;
