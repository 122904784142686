import React from 'react';
import { TreeSelect } from 'antd';
import { useSelector } from 'react-redux';
import marketingCopyTranslation from '../../../constants/MarketingCopyTranslation.json';
import { ApplicationState } from '../../../reducers';

const { TreeNode } = TreeSelect;

type MGroupTreeSelectProps = {
  selectedGroupId?: number;
  selectedSubGroupId?: number;
  onChange: ({ groupId, subGroupId }: { groupId?: number; subGroupId?: number }) => void;
  disabled?: boolean;
  size?: 'small' | 'large' | undefined;
  requiredGroupSubGroup?: boolean;
};

const MGroupTreeSelect: React.FC<MGroupTreeSelectProps> = props => {
  const groups = useSelector((state: ApplicationState) => state.parent.marketingCopy.groups);

  const value = props.selectedSubGroupId || props.selectedGroupId;
  const postfix =
    (props.selectedSubGroupId && '-subgroup') || (props.selectedGroupId && '-group') || '';

  return (
    <TreeSelect
      size={props.size}
      value={value ? `${value}${postfix}` : undefined}
      disabled={props.disabled}
      placeholder={marketingCopyTranslation.placeholder.selectGroup}
      allowClear
      treeDefaultExpandAll
      showSearch
      filterTreeNode={(input, treeNode) => treeNode?.props.title.includes(input)}
      onChange={(value, title, extra) => {
        if (!value) {
          props.onChange({});
        } else {
          const selected = Number(value.replace('-subgroup', '').replace('-group', ''));
          const titleString = title.join('');

          if (titleString.includes('Group')) {
            props.onChange({ groupId: selected });
          } else {
            const groupId = extra?.triggerNode?.props?.extra || undefined;
            props.onChange({
              groupId: props.requiredGroupSubGroup ? groupId : undefined,
              subGroupId: selected,
            });
          }
        }
      }}
    >
      {groups.map((group: any) => (
        <TreeNode
          value={`${group.id}-group`}
          title={`Group: ${group.name}`}
          key={`group_${group.id}`}
        >
          {group.sub_groups.map((subGroup: any) => (
            <TreeNode
              value={`${subGroup.id}-subgroup`}
              title={`Subgroup: ${subGroup.name}`}
              key={`subGroup_${subGroup.id}`}
              extra={props.requiredGroupSubGroup ? group.id : false}
              isLeaf
            />
          ))}
        </TreeNode>
      ))}
    </TreeSelect>
  );
};

export default MGroupTreeSelect;
