import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { QuickStartGuide } from '../../../types/home';

export type WelcomeState = {
  readonly guideSteps: QuickStartGuide | undefined;
  readonly fetchingGuideSteps: boolean;
};
const initialState = {
  guideSteps: undefined,
  fetchingGuideSteps: false,
};

const reducer: Reducer<WelcomeState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_GUIDE_STEPS_PENDING': {
      return {
        ...state,
        guideSteps: action.meta,
      };
    }
    case 'FETCH_GUIDE_STEPS_PENDING': {
      return {
        ...state,
        fetchingGuideSteps: true,
        guideSteps: undefined,
      };
    }
    case 'FETCH_GUIDE_STEPS_FULFILLED': {
      return {
        ...state,
        guideSteps: action.payload.data,
        fetchingGuideSteps: false,
      };
    }
  }
  return state;
};

export default reducer;
