import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { placeholder } from '../../../constants/DigitalAssetsTranslation.json';

// functions for future improvemnet already defined
class DropZone extends React.Component {
  handleFileSelect = e => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    this.props.handleDraggedFile(file);
  };

  handleDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  render() {
    return (
      <div
        className="digital-asset__drop-zone-box"
        onDragOver={this.handleDragOver}
        onDrop={this.handleFileSelect}
      >
        <PlusCircleOutlined className="mr-1" />
        {placeholder.drop_file}
      </div>
    );
  }
}

export default DropZone;
