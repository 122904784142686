import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { getItemApplicationListFilter } from '../../../selectors/catalogue/filterSelector';
import { mappedItemApplicationList } from '../../../selectors/all_applications/allApplicationsSelector';
import { getPageLimit, typingDone } from '../../../utils/Utils';
import AntSelect from '../../global/AntSelect';
import { StandardResourceTypeId } from '../../../../types/resources';
import AllApplicationsTable from '../all_application_validation/AllApplicationsTable';
import {
  fetchApplication,
  fetchItemApplicationListing,
} from '../../../actions/items/application/fetch';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';

type ApplicationListProps = {
  keywords: string;
  selectApplication: (id: number) => void;
};

const ApplicationList: React.FC<ApplicationListProps> = ({ keywords, selectApplication }) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItem,
    applicationResources,
    listFilter,
    fetchListingError,
    applicationRows,
    mappedApplicationRows,
    columns,
    fetching,
    selectedVcdbs,
    // fullListFilter,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      applicationResources: state.resources.data.application,
      listFilter: getItemApplicationListFilter(state),
      fetchListingError: state.items.application.fetchListingError,
      applicationRows: state.items.application.applicationRows,
      mappedApplicationRows: mappedItemApplicationList(state),
      columns: state.items.application.columns,
      fetching: state.items.application.fetchingApplicationRows,
      selectedVcdbs: state.items.application.selectedVcdbs,
      fullListFilter: state.items.application.listFilter,
    };
  });

  const [listingTypeId, setListingTypeId] = React.useState(1);
  const [listingTypeOptionId, setListingTypeOptionId] = React.useState(1);
  const [startValidation, setStartValidation] = React.useState(true);
  const [cachedKeywords, setCachedKeywords] = React.useState(keywords);
  const [currentItemId, setCurrentItemId] = React.useState();

  const fetchListing = React.useCallback(
    (typeId?: number, typeOptionId?: number) => {
      dispatch(
        fetchItemApplicationListing(
          selectedVcdbs,
          listFilter.id,
          selectedItem.id,
          keywords,
          typeId || listingTypeId,
          typeOptionId || listingTypeOptionId
        )
      );
      setStartValidation(false);
    },
    [
      dispatch,
      keywords,
      listFilter.id,
      listingTypeId,
      listingTypeOptionId,
      selectedItem.id,
      selectedVcdbs,
    ]
  );

  // Filter currently not in use
  React.useEffect(() => {
    // dispatch(fetchApplicationListFilter(listFilter.id)).then(result => {
    //   const filter = result.value.data;
    //   // set filter keyword if necessary
    //   if (filter.keywords !== keywords)
    //     dispatch(updateListFilterKeywords(listFilter.id, keywords)).then(() => fetchListing());
    //   else fetchListing();
    // });
  }, [fetchListing]);

  React.useEffect(() => {
    if (currentItemId !== selectedItem.id) {
      setCurrentItemId(selectedItem.id);
      fetchListing();
    }
  }, [fetchListing, currentItemId, selectedItem.id]);

  React.useEffect(() => {
    if (keywords !== cachedKeywords) {
      setCachedKeywords(keywords);
      typingDone(() => {
        // dispatch(updateListFilterKeywords(listFilter.id, keywords)).then(() => fetchListing());
        fetchListing();
        if (applicationRows.length) {
          const search = document.getElementById('applicationSearch');
          if (search) search.blur();
          if (search) search.focus();
        }
      }, 400);
    }
  }, [dispatch, cachedKeywords, keywords, listFilter.id, applicationRows.length, fetchListing]);

  const pageSize = getPageLimit();
  const showOptionsSelect = !!applicationResources.listing_type_options.find(
    (opt: StandardResourceTypeId) => opt.type_id === listingTypeId
  );

  const fetchNextListings = () => {
    const currentPage = Math.ceil(applicationRows.length / pageSize);
    const lastPage = currentPage > applicationRows.length / pageSize;

    if (!lastPage)
      dispatch(
        fetchItemApplicationListing(
          selectedVcdbs,
          listFilter.id,
          selectedItem.id,
          keywords,
          listingTypeId,
          listingTypeOptionId,
          currentPage + 1
        )
      );
  };

  const handleListingTypeIdSelect = (id: number) => {
    const typeOption = applicationResources.listing_type_options.find(
      (opt: StandardResourceTypeId) => opt.type_id === id
    );
    setListingTypeId(id);
    setListingTypeOptionId(typeOption ? typeOption.id : undefined);
    fetchListing(id, typeOption ? typeOption.id : undefined);
  };

  const handleTypeOptionSelect = (id: number) => {
    setListingTypeOptionId(id);
    fetchListing(listingTypeId, id);
  };

  const handleApplicationSelect = (id: number) => {
    dispatch(fetchApplication(selectedItem.id, id));
    selectApplication(id);
  };

  const applicationEdited = !!applicationRows.find((app: any) => app.updated);

  return (
    <React.Fragment>
      <div className="application__listing application_listing__validation-selects">
        <AntSelect
          className="application_listing__type-select"
          elements={applicationResources.listing_types.filter(
            (type: StandardResourceTypeId) => type.id === 1 || type.id === 3
          )}
          value={listingTypeId}
          onChange={(value: string) => handleListingTypeIdSelect(Number(value))}
          size="small"
        />
        {showOptionsSelect && (
          <AntSelect
            className="application_listing__option-select"
            elements={applicationResources.listing_type_options.filter(
              (opt: StandardResourceTypeId) => opt.type_id === listingTypeId
            )}
            value={listingTypeOptionId}
            onChange={(value: string) => handleTypeOptionSelect(Number(value))}
            size="small"
          />
        )}
        {applicationEdited && (
          <Button
            className="application_listing__validate-button"
            onClick={() => fetchListing()}
            htmlType="button"
            size="small"
            type="primary"
            ghost
          >
            {t('applicationValidation:updateValidation')}
          </Button>
        )}
      </div>

      {!startValidation && (
        <AllApplicationsTable
          columns={columns}
          applicationRows={applicationRows}
          mappedApplicationRows={mappedApplicationRows}
          handleApplicationSelect={handleApplicationSelect}
          fetchNextListings={fetchNextListings}
          fetching={fetching}
          fetchListingError={fetchListingError}
          overlap={listingTypeId === 3}
        />
      )}
      {startValidation && <div className="application__listing-explanation" />}
    </React.Fragment>
  );
};

export default ApplicationList;
