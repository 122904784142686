import React from 'react';
import classNames from 'classnames';

import { CloudOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import catalogueTranslations from '../../../constants/CatalogueTranslations.json';

type CatalogueSyncIconProps = {
  syncActive: boolean;
  outOfSync: boolean;
};
const CatalogueSyncIcon = (props: CatalogueSyncIconProps) => {
  const getIconInfo = () => {
    const { syncActive, outOfSync } = props;
    if (syncActive && outOfSync) {
      return { color: 'yellow', tooltip: catalogueTranslations.catalogueList.syncActiveOutOfSync };
    }
    if (syncActive && !outOfSync) {
      return { color: 'green', tooltip: catalogueTranslations.catalogueList.syncActiveInSync };
    }
    return { color: 'grey', tooltip: catalogueTranslations.catalogueList.syncInActive };
  };

  const { color, tooltip } = getIconInfo();
  return (
    <Tooltip title={tooltip} placement="left">
      <CloudOutlined
        className={classNames('sync-icon', {
          [color]: color,
        })}
      />
    </Tooltip>
  );
};

export default CatalogueSyncIcon;
