export function triggerUpdateCounter(newState) {
  return {
    type: 'TRIGGER_UPDATE_COUNTER',
    payload: newState,
  };
}

export function resetUpdateCounter() {
  return {
    type: 'RESET_UPDATE_COUNTER',
  };
}
