import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Tooltip, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { Package } from '../../../../types/itemPackage';
import { PackageFields } from '../../../constants/PackageConstants';
import { ApplicationState } from '../../../reducers';
import { ExtendedResources, StandardResource } from '../../../../types/resources';
import PackageInputGroup from './PackageInputGroup';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import MultiEditButton from './MultiEditButton';
import { fetchDimensionalWeight } from '../../../actions/items/package/fetch';
import { AsyncDispatch } from '../../../../types/global';
import PackageDimensionInputs from './PackageDimensionInputs';

type PackageFormProps = {
  itemPackage: Package;
  index: number;
  uoms: ExtendedResources[];
  multipleValueField?: string[];
  showAllFields: boolean;
  showMultiEditIcon: boolean;
  handleDrawerVisibility: (pkgName: string, visibility: boolean) => void;
  handleMultiEdit: (field: string) => void;
  updatePackage: (fieldName: string, value: any, dimensionalWeight?: number | null) => void;
};

const PackageForm: React.FC<PackageFormProps> = ({
  itemPackage,
  index,
  uoms,
  multipleValueField,
  showAllFields,
  showMultiEditIcon,
  handleDrawerVisibility,
  handleMultiEdit,
  updatePackage,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { innerQtyUoms, dimensionUoms, weightUoms, oderableOptions, brandUsedFields } = useSelector(
    (state: ApplicationState) => ({
      innerQtyUoms: state.resources.data.global.measurement_uom_codes,
      dimensionUoms: state.resources.data.package.dimension_uoms as StandardResource[],
      weightUoms: state.resources.data.package.weight_uoms as StandardResource[],
      oderableOptions: state.resources.data.package.orderable_options as StandardResource[],
      brandUsedFields: state.items.packageSeg.brandUsedFields
        .filter(title => !title.includes('hazardous_materials'))
        .map(t => t.split('.')[1]),
    })
  );

  const formItemLayout = {
    labelCol: {
      sm: { span: 8 },
    },
    wrapperCol: {
      sm: { span: 16 },
    },
  };

  const [showField, setShowField] = React.useState<string | undefined>(undefined);

  const isDisabled = !itemPackage.uom_id || !itemPackage.quantity_of_eaches;

  const getUsedFields = (fields: string[]) => {
    if (!showAllFields) {
      return fields.filter(field => brandUsedFields.includes(field));
    }
    return fields;
  };

  const renderSuggestButton = (name: string) => (
    <div className="items-center">
      <Button
        key={name}
        type="dashed"
        size="small"
        onClick={() => {
          setShowField(name);
        }}
        disabled={isDisabled}
      >
        {t('common:add')}
      </Button>
    </div>
  );

  const renderMultiValueEditLink = (name: string) => (
    <div className="flex items-center flex-1">
      <Button
        data-testid="multiple-values"
        type="link"
        onClick={() => handleDrawerVisibility(name, true)}
        style={{ padding: 0 }}
      >
        {t('packageSeg:multipleValues')}
      </Button>
    </div>
  );

  const setDimensionalWeight = () => {
    if (
      itemPackage.height &&
      itemPackage.width &&
      itemPackage.length &&
      itemPackage.dimensions_uom_id &&
      itemPackage.weights_uom_id
    ) {
      dispatch(
        fetchDimensionalWeight(
          itemPackage.length,
          itemPackage.width,
          itemPackage.height,
          itemPackage.dimensions_uom_id,
          itemPackage.weights_uom_id
        )
      ).then(response => {
        updatePackage(`dimensional_weight`, response.value.data.result);
      });
    } else updatePackage(`dimensional_weight`, '');
  };

  const renderRow = (field: string) => {
    switch (field) {
      case 'quantity_of_eaches': {
        return (
          <PackageInputGroup
            inputName={`extendedItemPackages[${index}].quantity_of_eaches`}
            selectName={`extendedItemPackages[${index}].uom_id`}
            label={t('packageSeg:package.quantityEach')}
            inputFocus={!itemPackage.quantity_of_eaches}
            borderInvisible={!!itemPackage.quantity_of_eaches && !!itemPackage.uom_id}
            options={uoms}
            inputChange={value => {
              const reg = /^[0-9]+$|^$/;
              if (reg.test(value)) {
                updatePackage('quantity_of_eaches', value);
                if (showMultiEditIcon) handleMultiEdit('quantity_of_eaches');
              }
            }}
            selectChange={value => {
              updatePackage('uom_id', value === undefined ? null : value);
              if (showMultiEditIcon) handleMultiEdit('uom_id');
            }}
            multipleValueField={
              multipleValueField?.includes('quantity_of_eaches') ||
              multipleValueField?.includes('uom_id') ||
              undefined
            }
            uomSelectDisable={showMultiEditIcon}
            handleDrawerVisibility={drawerVisibility =>
              handleDrawerVisibility('quantity_of_eaches', drawerVisibility)
            }
            showMultiEditIcon={
              showMultiEditIcon &&
              !(
                multipleValueField?.includes('quantity_of_eaches') ||
                multipleValueField?.includes('uom_id')
              )
            }
            required
          />
        );
      }
      case 'inner_quantity': {
        return (
          <PackageInputGroup
            inputName={`extendedItemPackages[${index}].inner_quantity`}
            selectName={`extendedItemPackages[${index}].inner_quantity_uom_id`}
            label={t('packageSeg:package.innerQuantity')}
            options={innerQtyUoms}
            borderInvisible={!!itemPackage.inner_quantity && !!itemPackage.inner_quantity_uom_id}
            disabled={isDisabled}
            inputChange={value => {
              const reg = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
              if (reg.test(value) || !value) updatePackage('inner_quantity', value);
              if (showMultiEditIcon) handleMultiEdit('inner_quantity');
            }}
            selectChange={value => {
              updatePackage('inner_quantity_uom_id', value === undefined ? null : value);
              if (showMultiEditIcon) handleMultiEdit('inner_quantity_uom_id');
            }}
            multipleValueField={
              multipleValueField?.includes('inner_quantity') ||
              multipleValueField?.includes('inner_quantity_uom_id') ||
              undefined
            }
            handleDrawerVisibility={drawerVisibility =>
              handleDrawerVisibility('inner_quantity', drawerVisibility)
            }
            showMultiEditIcon={
              showMultiEditIcon &&
              !(
                multipleValueField?.includes('inner_quantity') ||
                multipleValueField?.includes('inner_quantity_uom_id')
              )
            }
          />
        );
      }
      case 'height': {
        return (
          <PackageDimensionInputs
            label={t('packageSeg:package.shippingDimensions')}
            inputNames={[`height`, `width`, `length`]}
            options={dimensionUoms}
            heightValue={itemPackage.height}
            widthValue={itemPackage.width}
            lengthValue={itemPackage.length}
            dimensionUomValue={itemPackage.dimensions_uom_id}
            borderInvisible={
              !!(
                itemPackage.height &&
                itemPackage.width &&
                itemPackage.length &&
                itemPackage.dimensions_uom_id
              )
            }
            disabled={isDisabled}
            inputChange={(value, name) => {
              const reg = /^[0-9]+(.[0-9]*)?$/;
              if (reg.test(value) || !value) {
                if (name.includes('width')) updatePackage('width', value);
                if (name.includes('height')) updatePackage('height', value);
                if (name.includes('length')) updatePackage('length', value);
              }
              if (showMultiEditIcon) {
                if (name.includes('width')) handleMultiEdit('width.dimensional_weight');
                if (name.includes('height')) handleMultiEdit('height.dimensional_weight');
                if (name.includes('length')) handleMultiEdit('length.dimensional_weight');
              }
            }}
            selectChange={value => {
              updatePackage('dimensions_uom_id', value === undefined ? null : value);
              handleMultiEdit('weights_uom_id.dimensions_uom_id.dimensional_weight');
            }}
            handleOnBlur={() => setDimensionalWeight()}
            multipleValueField={
              multipleValueField?.includes('width') ||
              multipleValueField?.includes('height') ||
              multipleValueField?.includes('length') ||
              multipleValueField?.includes('dimensions_uom_id') ||
              undefined
            }
            handleDrawerVisibility={drawerVisibility =>
              handleDrawerVisibility('width', drawerVisibility)
            }
            showMultiEditIcon={
              showMultiEditIcon &&
              !(
                multipleValueField?.includes('width') ||
                multipleValueField?.includes('height') ||
                multipleValueField?.includes('length')
              )
            }
          />
        );
      }
      case 'dimensional_weight': {
        return (
          <Form.Item
            label={
              <Tooltip title={t('packageSeg:package.infoText')} placement="topLeft">
                {t('packageSeg:package.dimensionWeight')}
              </Tooltip>
            }
          >
            <div className="text-gray-600">
              {itemPackage.dimensional_weight &&
              !showMultiEditIcon &&
              itemPackage.weightName &&
              itemPackage.dimensions_uom_id
                ? `${itemPackage.dimensional_weight} ${itemPackage.weightName}`
                : ''}
            </div>
          </Form.Item>
        );
      }
      case 'weight': {
        return (
          <PackageInputGroup
            inputName={`extendedItemPackages[${index}].weight`}
            selectName={`extendedItemPackages[${index}].weights_uom_id`}
            label={t('packageSeg:package.weight')}
            options={weightUoms}
            borderInvisible={!!itemPackage.weight && !!itemPackage.weights_uom_id}
            disabled={isDisabled}
            inputChange={value => {
              const reg = /^[0-9]+(.[0-9]*)?$/;
              if (reg.test(value) || !value) updatePackage('weight', value);
              handleMultiEdit('weight');
            }}
            selectChange={value => {
              updatePackage('weights_uom_id', value === undefined ? null : value);
              handleMultiEdit('weights_uom_id.dimensions_uom_id.dimensional_weight');
            }}
            handleOnBlur={() => setDimensionalWeight()}
            multipleValueField={
              multipleValueField?.includes('weight') ||
              multipleValueField?.includes('weights_uom_id') ||
              undefined
            }
            handleDrawerVisibility={drawerVisibility =>
              handleDrawerVisibility('weight', drawerVisibility)
            }
            showMultiEditIcon={
              showMultiEditIcon &&
              !(
                multipleValueField?.includes('weight') ||
                multipleValueField?.includes('weights_uom_id')
              )
            }
          />
        );
      }
      case 'weight_variance': {
        return (
          <Form.Item
            label={t('packageSeg:package.weightVariance')}
            className="package__suggest-button"
          >
            <div className="flex items-center">
              {showField === `${itemPackage.uom_id}_weightVariance` ||
              (itemPackage.weight_variance &&
                (!multipleValueField || !multipleValueField.includes('weight_variance'))) ? (
                <FormInput
                  disabled={isDisabled}
                  name={`extendedItemPackages[${index}].weight_variance`}
                  autoFocus={!itemPackage.weight_variance}
                  size="small"
                  className={
                    itemPackage.weight_variance
                      ? 'package-border-transparent package__borderless-input'
                      : ''
                  }
                  handleChange={e => {
                    const value = e.target.value;
                    const reg = /^[0-9]+(.[0-9]*)?$/;
                    if (reg.test(value) || !value) updatePackage(`weight_variance`, value);
                    handleMultiEdit('weight_variance');
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                />
              ) : (
                (!multipleValueField?.includes('weight_variance') &&
                  renderSuggestButton(`${itemPackage.uom_id}_weightVariance`)) ||
                (multipleValueField?.includes('weight_variance') &&
                  renderMultiValueEditLink('weight_variance'))
              )}
              {showMultiEditIcon && !multipleValueField?.includes('weight_variance') && (
                <MultiEditButton
                  handleOnClick={() => handleDrawerVisibility('weight_variance', true)}
                />
              )}
            </div>
          </Form.Item>
        );
      }
      case 'merchandising_height': {
        return (
          <PackageDimensionInputs
            label={t('packageSeg:package.merchandisingDimensions')}
            inputNames={[`merchandising_height`, `merchandising_width`, `merchandising_length`]}
            heightValue={itemPackage.merchandising_height}
            widthValue={itemPackage.merchandising_width}
            lengthValue={itemPackage.merchandising_length}
            dimensionUomValue={itemPackage.dimensions_uom_id}
            options={dimensionUoms}
            borderInvisible={
              !!(
                itemPackage.merchandising_height &&
                itemPackage.merchandising_width &&
                itemPackage.merchandising_length &&
                itemPackage.dimensions_uom_id
              )
            }
            disabled={isDisabled}
            inputChange={(value, name) => {
              const reg = /^[0-9]+(.[0-9]*)?$/;
              if (reg.test(value) || !value) {
                if (name.includes('merchandising_width'))
                  updatePackage('merchandising_width', value);
                if (name.includes('merchandising_height'))
                  updatePackage('merchandising_height', value);
                if (name.includes('merchandising_length'))
                  updatePackage('merchandising_length', value);
              }
              if (showMultiEditIcon) {
                if (name.includes('merchandising_width')) handleMultiEdit('merchandising_width');
                if (name.includes('merchandising_height')) handleMultiEdit('merchandising_height');
                if (name.includes('merchandising_length')) handleMultiEdit('merchandising_length');
              }
            }}
            selectChange={value => {
              updatePackage('dimensions_uom_id', value === undefined ? null : value);
              handleMultiEdit('weights_uom_id.dimensions_uom_id');
            }}
            multipleValueField={
              multipleValueField?.includes('merchandising_height') ||
              multipleValueField?.includes('merchandising_width') ||
              multipleValueField?.includes('merchandising_length') ||
              multipleValueField?.includes('dimensions_uom_id') ||
              undefined
            }
            handleDrawerVisibility={drawerVisibility =>
              handleDrawerVisibility('merchandising', drawerVisibility)
            }
            showMultiEditIcon={showMultiEditIcon}
          />
        );
      }
      case 'orderable_option_id': {
        return (
          <Form.Item label={t('packageSeg:package.orderable')}>
            {!multipleValueField?.includes('orderable_option_id') ? (
              <div className="flex flex-row items-center">
                <FormSelect
                  name={`extendedItemPackages[${index}].orderable_option_id`}
                  values={oderableOptions}
                  className={
                    (!!itemPackage.orderable_option_id &&
                      'pkg-form-select package-border-transparent package__borderless-select ') ||
                    'pkg-form-select'
                  }
                  size="small"
                  allowClear
                  disabled={isDisabled}
                  onChange={(value: SelectValue) => {
                    updatePackage(`orderable_option_id`, value);
                    handleMultiEdit('orderable_option_id');
                  }}
                />
                {showMultiEditIcon && (
                  <MultiEditButton
                    handleOnClick={() => handleDrawerVisibility('orderable_option_id', true)}
                  />
                )}
              </div>
            ) : (
              multipleValueField.includes('orderable_option_id') &&
              renderMultiValueEditLink('orderable_option_id')
            )}
          </Form.Item>
        );
      }
      case 'stacking_factor': {
        return (
          <Form.Item
            label={t('packageSeg:package.stackingFactor')}
            className="package__suggest-button"
          >
            <div className="flex items-center">
              {(showField === `${itemPackage.uom_id}_stackingFactor}` ||
                itemPackage.stacking_factor) &&
              (!multipleValueField || !multipleValueField?.includes('stacking_factor')) ? (
                <FormInput
                  maxLength={10}
                  disabled={isDisabled}
                  name={`extendedItemPackages[${index}].stacking_factor`}
                  autoFocus={!itemPackage.stacking_factor}
                  size="small"
                  className={
                    itemPackage.stacking_factor
                      ? 'package-border-transparent package__borderless-input'
                      : ''
                  }
                  handleChange={e => {
                    const value = e.target.value;
                    const reg = /^[0-9]+(.[0-9]*)?$/;
                    if (reg.test(value) || !value) updatePackage(`stacking_factor`, value);
                    handleMultiEdit('stacking_factor');
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                />
              ) : (
                (!multipleValueField?.includes('stacking_factor') &&
                  renderSuggestButton(`${itemPackage.uom_id}_stackingFactor}`)) ||
                (multipleValueField?.includes('stacking_factor') &&
                  renderMultiValueEditLink('stacking_factor'))
              )}
              {showMultiEditIcon && !multipleValueField?.includes('stacking_factor') && (
                <MultiEditButton
                  handleOnClick={() => handleDrawerVisibility('stacking_factor', true)}
                />
              )}
            </div>
          </Form.Item>
        );
      }
      case 'level_gtin': {
        return (
          <Form.Item label={t('packageSeg:package.levelGtin')} className="package__suggest-button">
            <div className="flex items-center">
              {(showField === `${itemPackage.uom_id}_levelGtin}` || itemPackage.level_gtin) &&
              (!multipleValueField || !multipleValueField?.includes('level_gtin')) ? (
                <FormInput
                  disabled={isDisabled}
                  maxLength={14}
                  name={`extendedItemPackages[${index}].level_gtin`}
                  autoFocus={!itemPackage.level_gtin}
                  size="small"
                  className={
                    itemPackage.level_gtin
                      ? 'package-border-transparent package__borderless-input'
                      : ''
                  }
                  handleChange={e => {
                    const value = e.target.value;
                    const reg = /^[0-9]+$|^$/;
                    if (reg.test(value)) updatePackage(`level_gtin`, value);
                    handleMultiEdit('level_gtin');
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                />
              ) : (
                (!multipleValueField?.includes('level_gtin') &&
                  renderSuggestButton(`${itemPackage.uom_id}_levelGtin}`)) ||
                (multipleValueField?.includes('level_gtin') &&
                  renderMultiValueEditLink('level_gtin'))
              )}
              {showMultiEditIcon && !multipleValueField?.includes('level_gtin') && (
                <MultiEditButton handleOnClick={() => handleDrawerVisibility('level_gtin', true)} />
              )}
            </div>
          </Form.Item>
        );
      }
      case 'electronic_product_code': {
        return (
          <Form.Item
            label={t('packageSeg:package.electronicProductCode')}
            className="package__suggest-button"
          >
            <div className="flex items-center">
              {(showField === `${itemPackage.uom_id}_electronicProductCode}` ||
                itemPackage.electronic_product_code) &&
              (!multipleValueField || !multipleValueField?.includes('electronic_product_code')) ? (
                <FormInput
                  disabled={isDisabled}
                  name={`extendedItemPackages[${index}].electronic_product_code`}
                  autoFocus={!itemPackage.electronic_product_code}
                  size="small"
                  className={
                    itemPackage.electronic_product_code
                      ? 'package-border-transparent package__borderless-input'
                      : ''
                  }
                  handleChange={e => {
                    const value = e.target.value;
                    updatePackage(`electronic_product_code`, value);
                    handleMultiEdit('electronic_product_code');
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                />
              ) : (
                (!multipleValueField?.includes('electronic_product_code') &&
                  renderSuggestButton(`${itemPackage.uom_id}_electronicProductCode}`)) ||
                (multipleValueField?.includes('electronic_product_code') &&
                  renderMultiValueEditLink('electronic_product_code'))
              )}
              {showMultiEditIcon && !multipleValueField?.includes('electronic_product_code') && (
                <MultiEditButton
                  handleOnClick={() => handleDrawerVisibility('electronic_product_code', true)}
                />
              )}
            </div>
          </Form.Item>
        );
      }
      case 'bar_code_characters': {
        return (
          <Form.Item
            label={t('packageSeg:package.barcodeChar')}
            className="package__suggest-button"
          >
            <div className="flex items-center">
              {(showField === `${itemPackage.uom_id}_barcodeChar}` ||
                itemPackage.bar_code_characters) &&
              (!multipleValueField || !multipleValueField.includes('bar_code_characters')) ? (
                <FormInput
                  disabled={isDisabled}
                  name={`extendedItemPackages[${index}].bar_code_characters`}
                  autoFocus={!itemPackage.bar_code_characters}
                  size="small"
                  className={
                    itemPackage.bar_code_characters
                      ? 'package-border-transparent package__borderless-input'
                      : ''
                  }
                  handleChange={e => {
                    const value = e.target.value;
                    updatePackage(`bar_code_characters`, value);
                    handleMultiEdit('bar_code_characters');
                  }}
                  handleOnBlur={() => setShowField(undefined)}
                />
              ) : (
                (!multipleValueField?.includes('bar_code_characters') &&
                  renderSuggestButton(`${itemPackage.uom_id}_barcodeChar}`)) ||
                (multipleValueField?.includes('bar_code_characters') &&
                  renderMultiValueEditLink('bar_code_characters'))
              )}
              {showMultiEditIcon && !multipleValueField?.includes('bar_code_characters') && (
                <MultiEditButton
                  handleOnClick={() => handleDrawerVisibility('bar_code_characters', true)}
                />
              )}
            </div>
          </Form.Item>
        );
      }
    }
  };

  return (
    <Form className="package-form" {...formItemLayout} labelAlign="left">
      {getUsedFields(PackageFields).map((field: string) => (
        <React.Fragment key={field}>
          {renderRow(field)}
          <Divider />
        </React.Fragment>
      ))}
    </Form>
  );
};

export default PackageForm;
