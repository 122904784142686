import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Token, Log, APIScript, ScriptEvent, ScriptLog } from '../../../types/distribution';

export type APISettingsState = {
  token: Token;
  requested_item_count: number;
  account_item_count: number;
  logs: Log[];
  fetchingLogsAndToken: boolean;
  fetchingLogs: boolean;
  fetchingScripts: boolean;
  scripts: APIScript[];
  scriptText: string;
  scriptEvents: ScriptEvent[];
  scriptLogs: ScriptLog[];
  fetchingScriptFile: boolean;
  dataPreview: any;
  fetchingDataPreview: boolean;
  fetchingScriptEvents: boolean;
  fetchingScriptLogs: boolean;
};

const initialState = {
  token: {
    id: 0,
    updated_at: '',
    token: '',
  },
  requested_item_count: 0,
  account_item_count: 0,
  logs: [],
  fetchingLogsAndToken: false,
  fetchingLogs: false,
  fetchingScripts: false,
  scripts: [],
  scriptText: '',
  scriptEvents: [],
  scriptLogs: [],
  fetchingScriptFile: false,
  dataPreview: undefined,
  fetchingDataPreview: false,
  fetchingScriptEvents: false,
  fetchingScriptLogs: false,
};

const reducer: Reducer<APISettingsState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'DELETE_SCRIPT_PENDING': {
      const filterdScripts = state.scripts.filter(script => script.id !== action.meta.scriptId);
      return { ...state, scripts: filterdScripts };
    }
    case 'RESET_SCRIPT_FILE': {
      return { ...state, scriptText: '' };
    }
    case 'FETCH_SCRIPT_FILE_PENDING': {
      return { ...state, scriptText: '', fetchingScriptFile: true };
    }
    case 'FETCH_SCRIPT_FILE_REJECTED': {
      return { ...state, scriptText: '', fetchingScriptFile: false };
    }
    case 'FETCH_SCRIPT_FILE_FULFILLED': {
      return { ...state, scriptText: action.payload.data, fetchingScriptFile: false };
    }
    case 'FETCH_SCRIPTS_PENDING': {
      return { ...state, fetchingScripts: true };
    }
    case 'FETCH_SCRIPTS_FULFILLED': {
      return { ...state, scripts: action.payload.data, fetchingScripts: false };
    }
    case 'FETCH_SCRIPT_EVENTS_PENDING': {
      return { ...state, fetchingScriptEvents: true };
    }
    case 'FETCH_SCRIPT_EVENTS_FULFILLED': {
      return { ...state, scriptEvents: action.payload.data, fetchingScriptEvents: false };
    }
    case 'FETCH_SCRIPT_DATA_PREVIEW_PENDING': {
      return { ...state, dataPreview: undefined, fetchingDataPreview: true };
    }
    case 'FETCH_SCRIPT_DATA_PREVIEW_FULFILLED': {
      return {
        ...state,
        dataPreview: action.payload.data,
        fetchingDataPreview: false,
      };
    }
    case 'FETCH_SCRIPT_LOGS_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        scriptLogs: page === 1 ? [] : state.scriptLogs,
        fetchingScriptLogs: true,
      };
    }
    case 'FETCH_SCRIPT_LOGS_FULFILLED': {
      return {
        ...state,
        scriptLogs: [...state.scriptLogs, ...action.payload.data],
        fetchingScriptLogs: false,
      };
    }
    case 'FETCH_API_SETTINGS_PENDING': {
      const firstPage = action.meta.page === 1;
      const logs = firstPage ? [] : state.logs;
      return { ...state, logs, fetchingLogs: true, fetchingLogsAndToken: firstPage };
    }
    case 'FETCH_API_SETTINGS_FULFILLED': {
      const logs = [...state.logs, ...action.payload.data.logs];
      return {
        ...state,
        ...action.payload.data,
        logs,
        token: action.payload.data.token || { id: 0, updated_at: '', token: '' },
        fetchingLogs: false,
        fetchingLogsAndToken: false,
      };
    }
    case 'CREATE_NEW_API_TOKEN_FULFILLED': {
      const newToken = action.payload.data;
      return { ...state, token: newToken };
    }
  }
  return state;
};

export default reducer;
