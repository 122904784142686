import { difference } from './Array';

/**
 * Replace values from replace object in given string.
 * @param str
 * @param replace: Array<{pattern: string, value: string}>
 * @returns {string}
 */
export const replaceMultiple = (
  str: string,
  replace: { pattern: string; value: string }[]
): string => replace.reduce((acc, { pattern, value }) => acc.replace(pattern, value), str);

/**
 * Fill in placeholder values in given string. Placeholders are marked as %placeholder% in string.
 * @param str: String containing placeholders, e.g. "hello %name%"
 * @param values: {["placeholderName"]: valueToInsert}
 * @returns {string}
 */
export const fillInPlaceholders = (str: string, values: { [s: string]: string }): string => {
  const placeholders = str.match(/(?=%)?\w+(?=%)/g) || []; // match %word% -> word
  const mappedValues = Object.entries(values).map(([pattern, value]) => ({ pattern, value }));

  const missingValues = difference(
    placeholders,
    mappedValues.map(({ pattern }) => pattern)
  );
  if (missingValues.length) {
    throw new Error(`missing variable(s) for placeholder: ${missingValues}`);
  } else {
    return replaceMultiple(
      str,
      mappedValues.map(({ pattern, value }) => ({ pattern: `%${pattern}%`, value }))
    );
  }
};

export const createBrandTitle = (
  name: string,
  code: string,
  whiteLabelCode: string | null,
  receiverUser?: boolean
) => {
  if (receiverUser) return `${name} (${whiteLabelCode || code})`;
  return `${name} (${whiteLabelCode ? `${whiteLabelCode}|${code}` : `${code}`})`;
};

export const createShortBrandTitle = (
  name: string,
  code: string,
  whiteLabelCode: string | null,
  receiverUser?: boolean
) => {
  const title = receiverUser
    ? `${name} (${whiteLabelCode || code})`
    : `${name} (${whiteLabelCode ? `${whiteLabelCode}|${code}` : `${code}`})`;
  return title.length > 6 ? `${title.substring(0, 6)}...` : title;
};
