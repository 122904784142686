import React from 'react';
import { Link } from 'react-router-dom';

import {
  CheckCircleOutlined,
  DownloadOutlined,
  HistoryOutlined,
  ReloadOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { Card, Input, Table, Tag, Button, Modal, Alert } from 'antd';
import { ItemDeliveryStatus, Item } from '../../../../types/item';
import CustomIcon from '../../global/CustomIcon';
import { localDateTimeString } from '../../../utils/Utils';
import { DeliveryLog } from '../../../../types/delivery_logs';
import itemDashboardTranslation from '../../../constants/ItemDashboardTranslation.json';

const { Column } = Table;

type DistributionOverviewProps = {
  deliveryStatuses: ItemDeliveryStatus[];
  deliveryLogsOverview: DeliveryLog[];
  fetchDeliveryLogs: (arg0: ItemDeliveryStatus) => void;
  selectedBrandCode?: string;
  selectedItem: Item;
};

function DistributionOverview(props: DistributionOverviewProps) {
  const [showModal, setModalState] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedChannelName, setSelectedChannelName] = React.useState<null | string>(null);
  const [selectedReceiverName, setSelectedReceiverName] = React.useState<null | string>(null);

  const handleLogClick = (deliverStatus: ItemDeliveryStatus) => {
    setModalState(true);
    setSelectedChannelName(deliverStatus.channel_name);
    setSelectedReceiverName(deliverStatus.receiver_name);
    props.fetchDeliveryLogs(deliverStatus);
  };

  const getData = () =>
    props.deliveryStatuses
      .map(deliveryStatus => ({
        ...deliveryStatus,
        channelReceiverName: deliveryStatus.channel_name || deliveryStatus.receiver_name || '',
      }))
      .filter(deliveryStatus =>
        deliveryStatus.channelReceiverName.toLowerCase().includes(searchValue.toLowerCase())
      );

  const displayExternalLink = (link: string | null) =>
    link && (
      <a href={link} rel="noopener noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
        <CustomIcon icon="external-link" verticalAlign="text-top" />
      </a>
    );

  return (
    <Card title={itemDashboardTranslation.distribution_overview} size="small">
      <Input
        placeholder={itemDashboardTranslation.search_channel_receiver}
        prefix={<SearchOutlined />}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        className="distribution-overview__search"
      />
      <Table
        className="distribution-overview__table"
        dataSource={getData()}
        pagination={false}
        size="small"
        locale={{
          emptyText:
            props.deliveryStatuses.length === 0 ? (
              <Alert
                style={{ textAlign: 'start' }}
                type="info"
                message={itemDashboardTranslation.distribution_info_text}
                showIcon
              />
            ) : null,
        }}
      >
        <Column
          key="channelReceiverName"
          title={itemDashboardTranslation.channel_receiver}
          dataIndex="channelReceiverName"
        />
        <Column
          key="submitted"
          title={itemDashboardTranslation.status}
          dataIndex="delivered"
          render={delivered => {
            return (
              <span>
                {delivered === 1 ? (
                  <React.Fragment>
                    <CheckCircleOutlined style={{ color: '#52c41a', marginRight: 3 }} />{' '}
                    {itemDashboardTranslation.submitted}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ReloadOutlined style={{ marginRight: 3 }} />{' '}
                    {itemDashboardTranslation.not_submitted}
                  </React.Fragment>
                )}
              </span>
            );
          }}
        />
        <Column
          key="online"
          dataIndex="online"
          render={(online, deliverStatus: ItemDeliveryStatus) => {
            if (online !== null) {
              if (online === 1) {
                return (
                  <Tag color="green">
                    {itemDashboardTranslation.online} {displayExternalLink(deliverStatus.link)}
                  </Tag>
                );
              }
              return (
                <Tag color="red">
                  {itemDashboardTranslation.offline} {displayExternalLink(deliverStatus.link)}
                </Tag>
              );
            }
          }}
        />
        <Column
          key="log"
          title={itemDashboardTranslation.log}
          dataIndex="delivered"
          render={(delivered, deliverStatus: ItemDeliveryStatus) => {
            return (
              <span>
                {delivered === 1 && (
                  <Button
                    size="small"
                    onClick={() => handleLogClick(deliverStatus)}
                    style={{ marginRight: 8 }}
                  >
                    {itemDashboardTranslation.view_log}
                  </Button>
                )}
              </span>
            );
          }}
        />
      </Table>
      <Modal
        open={showModal}
        title={itemDashboardTranslation.last_delivered_files}
        onCancel={() => setModalState(false)}
        footer={null}
        width={900}
      >
        <div>
          <Table
            dataSource={props.deliveryLogsOverview}
            pagination={false}
            size="small"
            className="distribution-overview__log-table"
          >
            <Column title={itemDashboardTranslation.file_type} dataIndex="file_type_name" />
            <Column title={itemDashboardTranslation.file_name} dataIndex="file_name" />
            <Column
              title={itemDashboardTranslation.last_delivered}
              dataIndex="export_delivered_at"
              render={date => localDateTimeString(date)}
            />
            <Column
              title={itemDashboardTranslation.download_file}
              dataIndex="file_link"
              render={link => {
                return (
                  link && (
                    <Button icon={<DownloadOutlined />} href={link} size="small">
                      {itemDashboardTranslation.download}
                    </Button>
                  )
                );
              }}
            />
            <Column
              dataIndex="error_count"
              render={errorCount =>
                errorCount > 0 && <WarningOutlined style={{ fontSize: 18, color: '#f5222d' }} />
              }
            />
          </Table>
          <Link
            to={{
              pathname: '/distribution/history',
              search: `?brand_code=${props.selectedBrandCode}&part_number=${
                props.selectedItem.part_number
              }${selectedChannelName ? `&channel_name=${selectedChannelName}` : ''}${
                selectedReceiverName ? `&receiver_name=${selectedReceiverName}` : ''
              }`,
            }}
          >
            <Button>
              <HistoryOutlined /> {itemDashboardTranslation.show_history}
            </Button>
          </Link>
        </div>
      </Modal>
    </Card>
  );
}

export default DistributionOverview;
