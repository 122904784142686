import * as React from 'react';
import classNames from 'classnames';
import { List, Checkbox } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { AnalysisItem } from '../../../../types/analyses';

type QuickfixListItemRowProps = {
  item: AnalysisItem;
  isAccepted: boolean;
  isDeclined: boolean;
  isMarked: boolean;
  isSelected: boolean;
  handleMarkItem: (itemId: number, isMarked: boolean) => void;
  selectItemRow: (itemId: number) => void;
};

const QuickfixListItemRow: React.FC<QuickfixListItemRowProps> = ({
  item,
  isAccepted,
  isDeclined,
  isMarked,
  isSelected,
  handleMarkItem,
  selectItemRow,
}) => {
  const cssClasses = classNames(
    { analyses__accepted: isAccepted },
    { analyses__declined: isDeclined }
  );
  return (
    <List.Item className={cssClasses} onClick={() => selectItemRow(item.id)}>
      <Checkbox
        className="analyses__item-list-check"
        checked={isSelected}
        onChange={() => selectItemRow(item.id)}
        disabled={isAccepted || isDeclined}
      />
      {!isMarked && (
        <StarOutlined
          className="analyses__item-list-star star"
          onClick={e => {
            e.stopPropagation();
            handleMarkItem(item.id, isMarked);
          }}
        />
      )}
      {isMarked && (
        <StarFilled
          className="analyses__item-list-star star marked"
          onClick={e => {
            e.stopPropagation();
            handleMarkItem(item.id, isMarked);
          }}
        />
      )}
      <div className="analyses__item-list-part">{item.part_number}</div>
      <div className="analyses__item-list-name">{item.name}</div>
      <div className="analyses__item-list-tags">
        {item.tags.map(tag => (
          <div key={tag.id} className="ant-tag">
            {tag.name}
          </div>
        ))}
      </div>
    </List.Item>
  );
};

export default QuickfixListItemRow;
