import { Application, RankedValue, YearRange } from '../../types/application';

/**
 * E.g. [{year_from: 1988, year_to: 1990}, {year_from: 1991, year_to: 1992}] => [{year_from: 1988, year_to: 1992}].
 *
 * @param yearRanges YearRange[]
 */

export const createYearRanges = (years: number[]) => {
  const ranges: { year_from: number; year_to: number }[] = [];
  let start: number;
  let end: number;
  // find continuous year ranges in years
  years.sort().forEach((year, i) => {
    if (!start) start = year;
    if (!end || year === end + 1) end = year; // update end if year is previous end + 1
    if (end !== year) {
      ranges.push({ year_from: start, year_to: end }); // push start and end to array
      start = year;
      end = year;
    }
    if (years.length === i + 1) ranges.push({ year_from: start, year_to: end });
  });
  return ranges;
};

export const stringifyYearRanges = (yearRanges: YearRange[]) => {
  // Simplify single year ranges as 1999-1999 to 1999.
  return yearRanges
    .map((yearRange: YearRange) =>
      yearRange.year_from === yearRange.year_to
        ? yearRange.year_from.toString()
        : `${yearRange.year_from} - ${yearRange.year_to}`
    )
    .join(', ');
};

export const convertConfigNameToIds = (name: string) => {
  const irregular = ['years', 'production_start', 'production_end'];
  if (irregular.includes(name)) return name;
  if (name === 'production_years') return 'years';
  if (name === 'brake_abs') return 'brake_abs_ids';
  if (name.includes('inches')) return name.replace(/es$/, '_ids');

  return name.replace(/s$/, '_ids');
};

export const convertConfigIdsNameToName = (name: string) => {
  const irregular = ['years'];
  if (irregular.includes(name)) return name;
  if (name === 'brake_abs_ids') return 'brake_abs';
  if (name === 'base_model_type_ids') return 'model_types';
  if (name.includes('inch_ids')) return name.replace(/_ids$/, 'es');

  return name.replace(/_ids$/, 's');
};

export const convertConfigIdNameToName = (name: string) => {
  const irregular = ['years'];
  if (irregular.includes(name)) return name;
  if (name === 'brake_abs_id') return 'brake_abs';
  if (name === 'base_model_type_id') return 'model_types';
  if (name.includes('inch_id')) return name.replace(/_id$/, 'es');

  return name.replace(/_id$/, 's');
};

export const mapConfigToApplication = (
  application: Application,
  subconfigName: string,
  subconfigIds: number[]
) => {
  const values = subconfigIds;

  const idName =
    subconfigName.includes('_ids') || subconfigName.includes('years')
      ? subconfigName
      : convertConfigNameToIds(subconfigName);

  if (values.length === 0) {
    // remove empty array key to be consistend with api behaviour
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [idName]: x, ...rest } = application;
    return rest as Application;
  }
  return { ...application, [idName]: values };
};

export const getConfigGroupName = (configName: string) => {
  if (configName.includes('engine')) return 'engines';
  if (configName.includes('transmission')) return 'transmissions';
  if (configName.includes('mfr_body_code')) return 'mfr_body_codes';
  if (configName.includes('body')) return 'bodies';
  if (configName.includes('spring')) return 'springs';
  if (configName.includes('bed')) return 'beds';
  if (configName.includes('brake')) return 'brakes';
  if (configName.includes('steering')) return 'steerings';
  if (configName.includes('drive')) return 'drives';
  if (configName.includes('wheel_base')) return 'wheel_bases';
  if (
    configName.includes('mfrs') ||
    configName.includes('equipment_models') ||
    configName.includes('vehicle_types')
  )
    return 'equipment_engines';
  if (
    configName.includes('make') ||
    configName.includes('model') ||
    configName.includes('year') ||
    configName.includes('sub_model') ||
    configName.includes('region')
  )
    return 'vehicle';
};

export const getSingularConfigGroupName = (groupName: string) => {
  if (groupName === 'bodies') return 'body';

  return groupName.replace(/s$/, '');
};

export const convertConfigNameToId = (name: string) => {
  if (name === 'brake_abs') return 'brake_abs_id';
  if (name.includes('inches')) return name.replace(/es$/, '_id');

  return name.replace(/s$/, '_id');
};

export const isFullVehicleSelected = (ranks: { [key: string]: RankedValue[] }) => {
  const makeIds = ranks.makes ? ranks.makes.filter(m => m.mapped).map(m => m.id) : [];
  const modelIds = ranks.models ? ranks.models.filter(m => m.mapped).map(m => m.id) : [];
  const years = ranks.years ? ranks.years.filter(y => y.mapped).map(y => y.id) : [];
  const subModelIds = ranks.sub_models ? ranks.sub_models.filter(m => m.mapped).map(m => m.id) : [];
  const regionIds = ranks.regions ? ranks.regions.filter(m => m.mapped).map(m => m.id) : [];
  const mfrs = ranks.mfrs ? ranks.mfrs.filter(mfr => mfr.mapped).map(mfr => mfr.id) : [];
  const eModels = ranks.equipment_models
    ? ranks.equipment_models.filter(m => m.mapped).map(m => m.id)
    : [];
  const vehicleTypes = ranks.vehicle_types
    ? ranks.vehicle_types.filter(t => t.mapped).map(t => t.id)
    : [];

  if (makeIds.length && modelIds.length && subModelIds.length && regionIds.length && years.length)
    return true;
  if (mfrs.length && eModels.length && vehicleTypes.length && regionIds.length) return true;

  return false;
};

export const isValidBaseSelected = (ranks: { [key: string]: RankedValue[] }) => {
  const makeIds = ranks.makes
    ? ranks.makes.filter(m => m.mapped && m.rank === 1).map(m => m.id)
    : [];
  const modelIds = ranks.models
    ? ranks.models.filter(m => m.mapped && m.rank === 1).map(m => m.id)
    : [];
  const years = ranks.years ? ranks.years.filter(y => y.mapped && y.rank === 1).map(y => y.id) : [];
  const subModelIds = ranks.sub_models
    ? ranks.sub_models.filter(m => m.mapped && m.rank === 1).map(m => m.id)
    : [];
  const regionIds = ranks.regions
    ? ranks.regions.filter(m => m.mapped && m.rank === 1).map(m => m.id)
    : [];
  const mfrs = ranks.mfrs
    ? ranks.mfrs.filter(mfr => mfr.mapped && mfr.rank === 1).map(mfr => mfr.id)
    : [];
  const eModels = ranks.equipment_models
    ? ranks.equipment_models.filter(m => m.mapped && m.rank === 1).map(m => m.id)
    : [];
  const vehicleTypes = ranks.vehicle_types
    ? ranks.vehicle_types.filter(t => t.mapped && t.rank === 1).map(t => t.id)
    : [];

  if (makeIds.length && modelIds.length && subModelIds.length && regionIds.length && years.length)
    return true;
  if (mfrs.length && eModels.length && vehicleTypes.length && regionIds.length) return true;

  return false;
};

export const isCustomValidBaseSelected = (ranks: { [key: string]: RankedValue[] }) => {
  const makeIds =
    ranks.makes?.filter(m => m.mapped).length === 1
      ? ranks.makes.filter(m => m.mapped && m.rank === 1 && m.vehicle_config_id).map(m => m.id)
      : [];
  const modelIds =
    ranks.models?.filter(m => m.mapped).length === 1
      ? ranks.models.filter(m => m.mapped && m.rank === 1 && m.vehicle_config_id).map(m => m.id)
      : [];
  const years =
    ranks.years?.filter(m => m.mapped).length === 1
      ? ranks.years.filter(y => y.mapped && y.rank === 1 && y.vehicle_config_id).map(y => y.id)
      : [];
  const subModelIds =
    ranks.sub_models?.filter(m => m.mapped).length === 1
      ? ranks.sub_models.filter(m => m.mapped && m.rank === 1 && m.vehicle_config_id).map(m => m.id)
      : [];
  const regionIds =
    ranks.regions?.filter(m => m.mapped).length === 1
      ? ranks.regions.filter(m => m.mapped && m.rank === 1 && m.vehicle_config_id).map(m => m.id)
      : [];
  const mfrs =
    ranks.mfrs?.filter(m => m.mapped).length === 1
      ? ranks.mfrs
          .filter(mfr => mfr.mapped && mfr.rank === 1 && mfr.vehicle_config_id)
          .map(mfr => mfr.id)
      : [];
  const eModels =
    ranks.equipment_models?.filter(m => m.mapped).length === 1
      ? ranks.equipment_models
          .filter(m => m.mapped && m.rank === 1 && m.vehicle_config_id)
          .map(m => m.id)
      : [];
  const vehicleTypes =
    ranks.vehicle_types?.filter(m => m.mapped).length === 1
      ? ranks.vehicle_types
          .filter(t => t.mapped && t.rank === 1 && t.vehicle_config_id)
          .map(t => t.id)
      : [];

  if (makeIds.length && modelIds.length && subModelIds.length && regionIds.length && years.length)
    return true;
  if (mfrs.length && eModels.length && vehicleTypes.length && regionIds.length) return true;

  return false;
};

export const isFullGroupConfigSelected = (selectedSubconfigs: { [x: string]: any }) => {
  return Object.keys(selectedSubconfigs).every(groupKey => {
    const subconfigs = selectedSubconfigs[groupKey];
    return Object.keys(subconfigs).every(key => {
      const values = subconfigs[key];
      return !!values.find((value: any) => value.mapped === true && value.vehicle_config_id);
    });
  });
};

export const isFullConfigSelected = (subconfigs: { [x: string]: any }) => {
  return Object.keys(subconfigs).every(key => {
    const values = subconfigs[key];
    return !!values.find((value: any) => value.mapped === true && value.vehicle_config_id);
  });
};

export const isSomeGroupConfigSelected = (selectedSubconfigs: { [x: string]: any }) =>
  Object.keys(selectedSubconfigs).some(groupKey => {
    const subconfigs = selectedSubconfigs[groupKey];
    return Object.keys(subconfigs).some(key => {
      const values = subconfigs[key];
      return !!values.find((value: any) => value.mapped === true);
    });
  });

export const isSomeConfigSelected = (subconfigs: { [x: string]: any }) =>
  Object.keys(subconfigs).some(key => {
    const values = subconfigs[key];
    return !!values.find((value: any) => value.mapped === true);
  });

export const getMenuConfigKeys = (name: string) => {
  if (name === 'vehicle_bases')
    return ['make_ids', 'model_ids', 'years', 'sub_model_ids', 'region_ids'];
  if (name === 'engines')
    return [
      'engine_aspiration_ids',
      'engine_base_block_type_ids',
      'engine_base_bore_inch_ids',
      'engine_base_bore_metric_ids',
      'engine_base_cubic_centimeter_ids',
      'engine_base_cubic_inch_ids',
      'engine_base_cylinder_ids',
      'engine_base_liter_ids',
      'engine_base_stroke_inch_ids',
      'engine_base_stroke_metric_ids',
      'engine_cylinder_head_type_ids',
      'engine_designation_ids',
      'engine_fuel_delivery_sub_type_ids',
      'engine_fuel_delivery_system_control_type_ids',
      'engine_fuel_delivery_system_design_ids',
      'engine_fuel_delivery_type_ids',
      'engine_fuel_type_ids',
      'engine_ignition_system_type_ids',
      'engine_mfr_ids',
      'engine_power_output_ids',
      'engine_valve_ids',
      'engine_version_ids',
      'engine_vin_ids',
    ];
  if (name === 'transmissions')
    return [
      'transmission_base_control_type_ids',
      'transmission_base_gear_ids',
      'transmission_base_type_ids',
      'transmission_electronically_controlled_information_ids',
      'transmission_mfr_code_ids',
      'transmission_mfr_ids',
    ];
  if (name === 'bodies') return ['body_door_ids', 'body_type_ids'];
  if (name === 'springs') return ['front_spring_type_ids', 'rear_spring_type_ids'];
  if (name === 'beds') return ['bed_length_ids', 'bed_type_ids'];
  if (name === 'brakes')
    return ['brake_abs_ids', 'brake_system_ids', 'front_brake_type_ids', 'rear_brake_type_ids'];
  if (name === 'steerings') return ['steering_system_ids', 'steering_type_ids'];
  if (name === 'drives') return ['drive_ids'];
  if (name === 'wheel_bases') return ['wheel_base_ids'];
  if (name === 'mfr_body_codes') return ['mfr_body_code_ids'];
};
