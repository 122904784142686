import React from 'react';
import { Badge, Button, Tag } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';
import constants from '../../../constants/ApplicationTranslation.json';
import {
  StandardResource,
  VehicleStructureClass,
  EngineStructure,
} from '../../../../types/resources';
import { Application, RankedApplications, RankedValue } from '../../../../types/application';
import { notUndefined } from '../../../utils/FilterUtils';

const applicationConfigs: { [index: string]: string | { [key: string]: any } } = constants;

type ApplicationDetailsMenuProps = {
  application: Application;
  rankedApplications: RankedApplications;
  applicationStructure: (VehicleStructureClass | string)[];
  invalidVehicleSubconfigs?: { subConfig: string; invalidId: number; validIds: number[] }[];
  defaultPositionId?: number;
  selectedSubconfig: string;
  filterView: boolean;
  vcdbView: boolean;
  vcdbEquipment?: boolean;
  equipmentApplication?: boolean;
  searchDone: boolean;
  getRecommendations: boolean;
  searchError: boolean;
  selectSubconfig: (subConfig: string | null) => void;
  allSubconfigValues: (config: string | null) => StandardResource[];
  mapConfigToApplication: (subconfigName: string, subconfigId: number, parentId?: number) => void;
};

const ApplicationDetailsMenu: React.FC<ApplicationDetailsMenuProps> = props => {
  const { t } = useTranslation();

  const selectSubconfig = (subconfig: string | null) => props.selectSubconfig(subconfig);

  const isFoundByKeywords = (structureEntry: string | { [key: string]: any }) => {
    const { rankedApplications } = props;
    let configs;
    if (typeof structureEntry === 'string') {
      configs = rankedApplications[structureEntry] || [];
    } else {
      const groupName = Object.keys(structureEntry)[0];
      const configPrefix = groupName === 'bodies' ? 'body' : groupName.slice(0, -1);
      configs = Object.keys(rankedApplications)
        .filter(key => key.startsWith(configPrefix))
        .map(key => rankedApplications[key]);
    }
    return JSON.stringify(configs).indexOf('"found_by_keywords":true') >= 0;
  };

  const checkForInvalidConfig = (key: string) => {
    const { rankedApplications, invalidVehicleSubconfigs } = props;
    let invalid = false;

    if (key === 'engines' || key === 'transmissions') {
      const prefix = key === 'engines' ? 'engine_' : 'transmission_';
      const invConfig = invalidVehicleSubconfigs?.find(inv => inv.subConfig.startsWith(prefix));
      const configKeys = Object.keys(rankedApplications).filter(key => key.startsWith(prefix));
      invalid =
        !!invConfig &&
        !!configKeys.find(key =>
          rankedApplications[key]?.find((c: any) => c.id === invConfig.invalidId)
        );
    } else {
      const invConfig = invalidVehicleSubconfigs?.find(inv => inv.subConfig === key);
      invalid =
        invConfig && rankedApplications[key]?.find((c: any) => c.id === invConfig.invalidId);
    }

    return invalid;
  };

  const addDefault = (elements: any[], defaultId: number) => {
    const prefix = constants.default_prefix;
    if (!elements.find(el => el.id === defaultId)) {
      const positions = props.allSubconfigValues('positions');
      const position = positions.find(pos => defaultId === pos.id);
      const defaultPos = { id: defaultId, name: `${prefix} ${position?.name}` };
      return [...elements, defaultPos];
    }
    return elements.map(el => (el.id === defaultId ? { ...el, name: `${prefix} ${el.name}` } : el));
  };

  const digitalAssetsPreview = (elements: any[]) => (
    <div className="application__digital-asset-preview-values">
      {elements.map(element => (
        <span key={element.file_name} className="application__digital-asset-value">
          {element.file_name}
        </span>
      ))}
    </div>
  );

  const subConfigs = (configs: { key: string; mappedValues: RankedValue[] }[]) =>
    configs.map(subConfigObj => {
      const subconfigName = subConfigObj.key;
      const ranks = subConfigObj.mappedValues.map(value => value.rank || 3);
      let rank = props.getRecommendations && ranks.length > 0 ? Math.max(...ranks) : 0;
      if (props.equipmentApplication && !subconfigName.includes('engine_')) rank = 0;

      return (
        <Tag
          key={subconfigName}
          className={classNames('application__config-tag', 'mapped', `rank${rank}`)}
        >
          {`${applicationConfigs[subconfigName]}: ${subConfigObj.mappedValues
            .map(value => value.name)
            .join(' | ')}`}
        </Tag>
      );
    });

  const allMappedValues = (key: string) => {
    if (!['engines', 'transmissions'].includes(key)) return;
    const { rankedApplications } = props;
    const prefix = key === 'engines' ? 'engine_' : 'transmission_';
    const configKeys = Object.keys(rankedApplications).filter(key => key.startsWith(prefix));
    const configs = configKeys
      .map(key => {
        const values: RankedValue[] = rankedApplications[key];
        // check if values are mapped
        const mappedValues = values.filter(value => value.mapped === true);
        if (mappedValues.length > 0) {
          return { key, mappedValues };
        }
      })
      .filter(notUndefined);

    return (
      <div className="application__config-mapped-values">
        <Badge count={configs.length} className="ant-blue-batch badge-small" overflowCount={99} />
        <div className="application__mapped-values-inner">{subConfigs(configs)}</div>
      </div>
    );
  };

  const generateButtons = (elements: RankedValue[], configName: string) => {
    const { defaultPositionId } = props;
    if (configName === 'notes') return;
    if (configName === 'qualifiers') return;
    if (configName === 'digital_assets') return digitalAssetsPreview(elements);
    let showDefault = false;
    if (configName === 'positions' && defaultPositionId && !elements.find(el => el.mapped)) {
      showDefault = true;
      elements = addDefault(elements, defaultPositionId);
    }
    const tags = elements.map(element => {
      if (props.searchDone && !element.found_by_keywords) return;
      let rank = element.rank || 3;
      if (!props.getRecommendations || props.equipmentApplication) rank = 0;
      if (configName === 'positions' && rank === 3) rank = 0;
      const defaultElement = showDefault && element.id === defaultPositionId;
      const styles = classNames(
        'application__config-tag',
        `rank${rank}`,
        { mapped: element.mapped || (!element.mapped && !element.rank) || defaultElement },
        { application__row_default: defaultElement }
      );

      return (
        <Tag
          key={element.id}
          className={styles}
          onClick={e => {
            props.mapConfigToApplication(configName, element.id);
            e.stopPropagation();
          }}
        >
          {element.name}
        </Tag>
      );
    });

    return <div className="application__config-buttons">{tags}</div>;
  };

  const menuEditRow = (key: string) => {
    const { rankedApplications, application } = props;
    if (props.searchDone && !isFoundByKeywords(key)) return;

    let count = 0;
    if (key === 'category') {
      count = ['category', 'qty', 'mfr_label'].filter(key => rankedApplications[key]).length;
    }
    if (key === 'qualifiers') {
      const { qualifiers, notes } = application;
      count = (qualifiers || []).length + (notes || []).length;
    }

    return (
      <div
        key={key}
        className={classNames('row', 'application__row', 'application__config-row', {
          application__row_selected: props.selectedSubconfig === key,
        })}
        onClick={() => selectSubconfig(key)}
      >
        <div className="application__config-name">{t(`application:${key}`)}</div>
        <div className="flex flex-1 items-center px-1">
          {key !== 'vehicle_bases' && (
            <Badge count={count} className="ant-blue-batch badge-small" overflowCount={99} />
          )}
          <Button className="ml-auto" size="small">
            {t('common:edit')}
          </Button>
        </div>
      </div>
    );
  };

  const menuRow = (key: string, level: number, engineGroup?: boolean) => {
    const { rankedApplications } = props;
    if (props.searchDone && level > 0 && !engineGroup && !isFoundByKeywords(key)) return;
    const style = level > 0 ? { paddingLeft: `${15 * level}px` } : {};
    const invalid = checkForInvalidConfig(key);

    return (
      <div
        key={key}
        className={classNames('row', 'application__row', 'application__config-row', {
          application__row_selected: props.selectedSubconfig === key,
        })}
        onClick={() => level === 0 && selectSubconfig(key)}
      >
        <div style={style} className={classNames('application__config-name')}>
          {applicationConfigs[key.toString()]}
          {invalid && <WarningOutlined className="self-center pl-1 icon__red" />}
        </div>
        {key === 'engines' || key === 'transmissions'
          ? allMappedValues(key)
          : rankedApplications[key] && generateButtons(rankedApplications[key], key)}
      </div>
    );
  };

  const engineStructure = (engineStructure: EngineStructure) =>
    Object.keys(engineStructure).map(key => {
      if (key === 'others' || key === 'engine_bases' || key === 'engine_fuel_deliveries') {
        const groupConfigs = engineStructure[key];
        const foundByKeywords = groupConfigs.find((config: any) => isFoundByKeywords(config));
        if (foundByKeywords)
          return (
            <React.Fragment key={key}>
              {menuRow(key, 1, true)}
              {groupConfigs.map((config: any) => menuRow(config, 2))}
            </React.Fragment>
          );
      }
    });

  const configMenu = () =>
    props.applicationStructure.map(key => {
      if (props.searchDone && !isFoundByKeywords(key)) return;
      if (props.filterView && ['qualifiers', 'digital_assets', 'category'].includes(key.toString()))
        return;
      if (
        props.vcdbView &&
        ['qualifiers', 'digital_assets', 'category', 'positions'].includes(key.toString())
      )
        return;
      if (typeof key === 'string') {
        if (key === 'category') return menuEditRow(key);
        if (key === 'qualifiers') return menuEditRow(key);
        if (props.vcdbEquipment) return;

        // mfr_label and notes is displayed with qualifiers
        if (!['mfr_label', 'notes'].includes(key)) {
          return menuRow(key, 0);
        }
      } else if (Object.keys(key)[0] === 'engines') {
        const structure = key.engines![0];
        return (
          <div
            key={key.toString()}
            className="sub-config-container"
            onClick={() => selectSubconfig('engines')}
          >
            {menuRow('engines', 0)}
            {props.searchDone && engineStructure(structure)}
          </div>
        );
      } else {
        const groupName = Object.keys(key)[0];
        if (groupName === 'vehicle_bases') return menuEditRow(groupName);
        if (groupName === 'equipments') return null;
        if (props.vcdbEquipment) return;

        const groupStructure = key[groupName];
        const showConfigRows = groupName !== 'transmissions' || props.searchDone;
        return (
          <div
            key={groupName}
            className="sub-config-container"
            onClick={() => selectSubconfig(groupName)}
          >
            {menuRow(groupName, 0)}
            {showConfigRows && groupStructure.map((configName: string) => menuRow(configName, 1))}
          </div>
        );
      }
    });

  return (
    <div className="application__details-menu">
      {!props.searchError ? (
        configMenu()
      ) : (
        <div className="application__config-search-error">{constants.searchError}</div>
      )}
    </div>
  );
};

export default ApplicationDetailsMenu;
