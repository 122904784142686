import axios from 'axios';
import { Brand } from '../../../../types/brand';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export const updateBrands = (brands: Brand[]) => {
  return {
    type: 'UPDATE_BRANDS',
    payload: axios.patch(`${getApiRoot()}/parents/owners/brands/save`, { brands }),
  };
};

export const resetBrand = (brandId: number) => {
  return {
    type: 'RESET_BRAND',
    payload: axios.delete(`${getApiRoot()}/parents/owners/brands/${brandId}/reset`),
  };
};
