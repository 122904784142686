import React from 'react';
import { Button, Modal } from 'antd';
import { CloudOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import OutOfSyncTableRow from './OutOfSyncTableRow';
import translation from '../../../constants/SynchronisationTranslation.json';

const { confirm } = Modal;

class SyncItemsStatus extends React.Component {
  state = {
    selectedItemIds: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.outOfSyncItems.length !== this.props.outOfSyncItems.length) {
      this.resetSelectedItemsIds();
    }
  }

  resetSelectedItemsIds = () => {
    this.setState({ selectedItemIds: [] });
  };

  handleSelectItem = itemId => {
    const { selectedItemIds } = this.state;
    const isSelected = selectedItemIds.includes(itemId);

    if (isSelected) {
      const filteredSelectedItemIds = selectedItemIds.filter(
        selectedItemId => selectedItemId !== itemId
      );
      this.setState({ selectedItemIds: filteredSelectedItemIds });
    } else {
      this.setState({ selectedItemIds: [...selectedItemIds, itemId] });
    }
  };

  handleSyncItems = () => {
    const { selectedItemIds } = this.state;
    this.props.handleSyncItems(selectedItemIds);
  };

  handleBrandSync = () => {
    this.props.handleBrandSync();
  };

  handleSyncAtNight = () => {
    const { selectedItemIds } = this.state;
    this.props.handleSyncAtNight(selectedItemIds);
  };

  handleAddToTempFilter = () => {
    const { selectedItemIds } = this.state;
    this.props.handleAddToTempFilter(selectedItemIds);
  };

  confirmSyncAllBrand = () => {
    const action = () => this.handleBrandSync();
    confirm({
      title: this.props.t('publishData:publishWarning'),
      onOk() {
        action();
      },
    });
  };

  render() {
    const { outOfSyncItems, fetching, canPublishItems } = this.props;
    const { selectedItemIds } = this.state;

    return (
      <div className="synchronisation-table__container">
        <div className="synchronisation-table">
          <div className="synchronisation-table__header">
            <div className="synchronisation-table__left">
              <div className="synchronisation-table__col header-first " />
              <div className="synchronisation-table__col col--fix">{translation.part_number}</div>
              <div className="synchronisation-table__col col--full">{translation.staging_db}</div>
            </div>
            <div className="synchronisation-table__right">
              <div className="synchronisation-table__col col--full">{translation.receiver_db}</div>
              <div className="synchronisation-table__col col--fix">{translation.last_publish}</div>
              <div className="synchronisation-table__col col--fix">{translation.updated_at}</div>
            </div>
          </div>
          <div className="synchronisation-table__body" onScroll={this.props.handleScroll}>
            {outOfSyncItems.length > 0 &&
              outOfSyncItems.map(item => (
                <OutOfSyncTableRow
                  key={item.id}
                  item={item}
                  handleSelectItem={this.handleSelectItem}
                  selectedItemIds={this.state.selectedItemIds}
                />
              ))}
            {outOfSyncItems.length === 0 && !fetching && (
              <div className="all-products-sync">
                <CloudOutlined />
                <span className="all-products-sync__desc">{translation.all_items_published}</span>
              </div>
            )}
            {fetching && <div className="loader" />}
          </div>
        </div>
        {outOfSyncItems.length > 0 && (
          <div>
            <div className="synchronisation-table__sum">
              <span>
                {translation.selected} {selectedItemIds.length} {translation.items}
              </span>
            </div>
            <div className="synchronisation-table__actions">
              <Button
                name={translation.publish_items}
                color="green"
                onClick={this.handleSyncItems}
                disabled={!canPublishItems || selectedItemIds.length === 0}
              >
                {translation.publish_items}
              </Button>
              <Button color="green" onClick={this.confirmSyncAllBrand} disabled={!canPublishItems}>
                {translation.publish_all_brand_items}
              </Button>
              <Button
                className="actions__sync-night"
                onClick={this.handleSyncAtNight}
                disabled={!canPublishItems || selectedItemIds.length === 0}
              >
                {translation.publish_items_at_night}
              </Button>
              <Button
                className="actions__show-products"
                name={translation.add_to_temp_filter}
                onClick={this.handleAddToTempFilter}
                disabled={!canPublishItems || selectedItemIds.length === 0}
              >
                {translation.add_to_temp_filter}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(SyncItemsStatus);
