import React from 'react';
import CustomIcon from '../CustomIcon.tsx';

export default class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    return (
      <span className="RichEditor-styleButton" onMouseDown={this.onToggle}>
        <CustomIcon
          icon={this.props.icon}
          size={20}
          color={this.props.active && this.props.hasFocus ? '#131313' : ''}
        />
      </span>
    );
  }
}
