import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { DeliveryLog, DeliveryLogError } from '../../../types/delivery_logs';

export type DeliveryLogsState = {
  readonly fetchingLogs: boolean;
  readonly fetchingLogErrors: boolean;
  readonly deliveryLogs: DeliveryLog[];
  readonly deliveryLogErrors: DeliveryLogError[];
};

const initialState = {
  fetchingLogs: false,
  fetchingLogErrors: false,
  deliveryLogs: [],
  deliveryLogErrors: [],
};

const reducer: Reducer<DeliveryLogsState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DELIVERY_LOGS_PENDING': {
      const deliveryLogs = action.meta.page === 1 ? [] : state.deliveryLogs;
      return { ...state, fetchingLogs: true, deliveryLogs };
    }
    case 'FETCH_DELIVERY_LOGS_FULFILLED': {
      const logs = action.payload.data;
      const deliveryLogs = action.meta.page === 1 ? logs : [...state.deliveryLogs, ...logs];
      return { ...state, fetchingLogs: false, deliveryLogs };
    }
    case '@CHANNEL_PUSHER/FETCH_CHANNEL_PUSH_LOGS_FULFILLED': {
      const currentPushIds = state.deliveryLogs.map(({ id }) => id);
      const newPushes = action.payload.data.filter(({ id }: any) => !currentPushIds.includes(id));
      return {
        ...state,
        deliveryLogs: [
          ...newPushes,
          ...state.deliveryLogs.map((log: any) => {
            const newLog = action.payload.data.find(({ id }: any) => log.id === id);
            if (newLog) return newLog;
            return log;
          }),
        ],
      };
    }
    case 'FETCH_DELIVERY_ERROR_LOGS_PENDING': {
      return { ...state, fetchingLogErrors: true, deliveryLogErrors: [] };
    }
    case 'FETCH_DELIVERY_ERROR_LOGS_FULFILLED': {
      return { ...state, fetchingLogErrors: false, deliveryLogErrors: action.payload.data };
    }
  }
  return state;
};

export default reducer;
