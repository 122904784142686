import { createSelector } from 'reselect';
import { Attribute } from '../../../types/attributes';
import { ApplicationState } from '../../reducers';

const getAttributes = (state: ApplicationState) => state.items.attributes.rankedAttributes;

export const getAttributesMappedWithUniqueId = (attributes: Attribute[]) =>
  attributes.map((attribute: Attribute) => ({
    ...attribute,
    uniqueId: attribute.id ? attribute.id : attribute.uniqueId,
    temp: false,
  }));

export const attributesMappedWithUniqueId = createSelector([getAttributes], attributes =>
  getAttributesMappedWithUniqueId(attributes)
);
