import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

export function fetchItemKits(itemId: number, keywords?: string) {
  const params = utils.createURL([{ name: 'keywords', values: keywords }]);
  return {
    type: 'FETCH_ITEM_KITS',
    payload: axios.get(`${getApiRoot()}/items/${itemId}/kits/${params}`),
  };
}
let fetchKitShopPreviewCancelToken: any;

export function fetchKitShopPreview(brandId: number, partNumber: string) {
  if (fetchKitShopPreviewCancelToken) {
    fetchKitShopPreviewCancelToken();
  }
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'part_number', values: partNumber },
  ]);

  return {
    type: 'FETCH_KIT_SHOP_PREVIEW',
    payload: axios.get(`${getNonGwApiRoot()}/items/small_shop_preview/${params}`, {
      cancelToken: new CancelToken(c => {
        fetchKitShopPreviewCancelToken = c;
      }),
    }),
  };
}

export function fetchKitSubBrand(brandId: number, kitId?: number) {
  const params = utils.createURL([{ name: 'external_brand_ids', values: brandId }]);

  return {
    type: 'FETCH_KIT_SUB_BRANDS',
    meta: { kitId },
    payload: axios.get(`${getNonGwApiRoot()}/parents/owners/brands/sub_brands${params}`),
  };
}
