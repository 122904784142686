const initialState = {
  isLoading: true,
  fetchingEbaySettings: false,
  fetchingConnectionState: false,
  fetchingEbayResources: false,
  fetchingDefaultPolicies: false,
  deletePending: [],
  channelOverview: [],
  channelItemsCount: [],
  selectedChannelId: undefined,
  authorizedBrands: [],
  ebayResources: {},
  ebayProfiles: {},
  ebayPolicies: { fulfillment: [], payment: [], return: [] },
  defaultPolicies: {},
  ebaySettings: {},
  fileNameTemplates: [],
  formDirtyState: false,
  channelAdvisorData: [],
  fetchingChannelAdvisor: false,
  channelAdvisorTemplate: [],
  fetchingChannelAdvTemplate: false,
  fetchingChannelNPW: false,
  channelNPWData: [],
  fetchingChannelNPWTemplate: false,
  channelNPWTemplate: [],
  updatingNPW: false,
  updatingChannelAdvisor: false,
  channelWalmartData: [],
  fetchingChannelWalmart: false,
  updatingWalmart: false,
  fetchingChannelShopify: false,
  channelShopifyData: [],
  fetchingChannelShopifyTemplate: false,
  channelShopifyTemplate: [],
  updatingShopify: false,
  fetchingShopifyVehicleFilters: false,
  shopifyVehicleFilters: [],
  rankedApplications: undefined,
  shopifyFilter: {},
  fetchingShopifyRankedApplications: false,
  fetchingShopifyChannelStatus: false,
  shopifyChannelAlreadyExists: undefined,
  shopifyUsernameAlreadyExists: undefined,
};

const reducer = (state = initialState, action) => {
  const getUpdatedChannels = (channelId, channels, updatedChannel) => {
    if (channels?.find(c => c.id === channelId))
      return channels.map(c => (c.id !== channelId ? c : updatedChannel));
    return [...channels, updatedChannel];
  };

  const getUpdatedFilters = updatedFilter => {
    if (state.shopifyVehicleFilters.filters.find(f => f.id === updatedFilter.id)) {
      return state.shopifyVehicleFilters.filters.map(f =>
        f.id === updatedFilter.id ? updatedFilter : f
      );
    }
    return [...state.shopifyVehicleFilters.filters, updatedFilter];
  };

  switch (action.type) {
    case 'CONNECT_SHOPIFY_STORE_DB_REJECTED': {
      return {
        ...state,
        shopifyUsernameAlreadyExists: action.meta.userName,
      };
    }
    case 'CREATE_SHOPIFY_CHANNEL_REJECTED': {
      return {
        ...state,
        shopifyChannelAlreadyExists: action.meta.name,
      };
    }
    case 'CREATE_SHOPIFY_CHANNEL_FULFILLED': {
      return {
        ...state,
        shopifyChannelAlreadyExists: undefined,
      };
    }
    case 'FETCH_CHANNEL_STATUS_PENDING': {
      return {
        ...state,
        fetchingShopifyChannelStatus: true,
      };
    }
    case 'FETCH_CHANNEL_STATUS_FULFILLED': {
      const updatedChannels = state.channelShopifyData.channels.map(c =>
        c.shop_status.username === action.payload.data.username
          ? { ...c, shop_status: action.payload.data }
          : c
      );
      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
        fetchingShopifyChannelStatus: false,
      };
    }
    case 'DELETE_SHOPIFY_STORE_PENDING': {
      const updatedChannels = state.channelShopifyData.channels.filter(
        c => c.id !== action.meta.shopifyId
      );
      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
      };
    }
    case 'CREATE_SHOPIFY_APPLICATION_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const updatedFilters = getUpdatedFilters(newFilter);
      return {
        ...state,
        shopifyFilter: newFilter,
        shopifyVehicleFilters: { filters: updatedFilters },
      };
    }
    case 'SET_SHOPIFY_FILTER': {
      return { ...state, shopifyFilter: action.payload.filter };
    }
    case 'DELETE_SHOPIFY_APPLICATION_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        shopifyFilter: action.payload.data.filter,
      };
    }
    case 'CREATE_SHOPIFY_APPLICATION_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        shopifyFilter: action.payload.data.filter,
      };
    }
    case 'ADD_SHOPIFY_APPLICATION_FILTER_VALUE_FULFILLED': {
      return { ...state, shopifyFilter: action.payload.data.filter };
    }
    case 'REMOVE_SHOPIFY_APPLICATION_FILTER_VALUE_FULFILLED': {
      return {
        ...state,
        shopifyFilter: action.payload.data.filter,
      };
    }
    case 'DELETE_SHOPIFY_FILTER_PENDING': {
      const updatedFilters = state.shopifyVehicleFilters.filters.filter(
        f => f.id !== action.meta.filterId
      );
      return {
        ...state,
        shopifyVehicleFilters: { filters: updatedFilters },
      };
    }
    case 'FETCH_SHOPIFY_VEHICLE_FILTERS_PENDING': {
      return {
        ...state,
        shopifyVehicleFilters: [],
        fetchingShopifyVehicleFilters: true,
      };
    }
    case 'FETCH_SHOPIFY_VEHICLE_FILTERS_FULFILLED': {
      return {
        ...state,
        shopifyVehicleFilters: action.payload.data,
        fetchingShopifyVehicleFilters: false,
      };
    }
    case 'UPDATE_CHANNEL_WALMART_PENDING': {
      const updatedBrands = action.meta.updatedValues;
      return {
        ...state,
        channelWalmartData: {
          ...state.channelWalmartData,
          brands: state.channelWalmartData.brands.map(brand => {
            const updatedBrand = updatedBrands.find(b => b.brand_id === brand.brand_id);
            return updatedBrand || brand;
          }),
        },
        updatingWalmart: true,
      };
    }
    case 'UPDATE_CHANNEL_WALMART_FULFILLED': {
      return {
        ...state,
        channelWalmartData: action.payload.data,
        updatingWalmart: false,
      };
    }
    case 'UPDATE_CHANNEL_SHOPIFY_PENDING': {
      const updatedShopifyChannel = action.meta.channel;
      const updatedChannels = state.channelShopifyData.channels.map(c =>
        c.id === updatedShopifyChannel.id ? updatedShopifyChannel : c
      );

      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
        updatingShopify: true,
      };
    }
    case 'UPDATE_CHANNEL_SHOPIFY_FULFILLED': {
      const updatedShopifyChannel = action.meta.channel;
      const updatedChannels = state.channelShopifyData.channels.map(c =>
        c.id === updatedShopifyChannel.id ? action.payload.data.channels[0] : c
      );

      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
        updatingShopify: false,
      };
    }
    case 'CONNECT_SHOPIFY_STORE_DB_FULFILLED': {
      const updatedChannels = state.channelShopifyData.channels.map(c => {
        return c.id === action.payload.data.channel_id
          ? { ...c, shop_status: { ...c.shop_status, username: action.payload.data.username } }
          : c;
      });
      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
      };
    }
    case 'FETCH_CHANNEL_SHOPIFY_PENDING': {
      return {
        ...state,
        fetchingChannelShopify: true,
      };
    }
    case 'FETCH_CHANNEL_SHOPIFY_FULFILLED': {
      const updatedChannels = getUpdatedChannels(
        action.meta.channelId,
        state.channelShopifyData.channels,
        action.payload.data.channels[0]
      );
      return {
        ...state,
        channelShopifyData: {
          channels: updatedChannels,
        },
        fetchingChannelShopify: false,
      };
    }
    case 'FETCH_ALL_SHOPIFY_CHANNELS_PENDING': {
      return {
        ...state,
        channelShopifyData: [],
        fetchingChannelShopify: true,
      };
    }
    case 'FETCH_ALL_SHOPIFY_CHANNELS_FULFILLED': {
      return {
        ...state,
        channelShopifyData: action.payload.data,
        fetchingChannelShopify: false,
      };
    }
    case 'FETCH_CHANNEL_WALMART_PENDING': {
      return {
        ...state,
        channelWalmartData: [],
        fetchingChannelWalmart: true,
      };
    }
    case 'FETCH_CHANNEL_WALMART_FULFILLED': {
      return {
        ...state,
        channelWalmartData: action.payload.data,
        fetchingChannelWalmart: false,
      };
    }
    case 'UPDATE_CHANNEL_NPW_PENDING': {
      const updatedBrands = action.meta.updatedValues;
      return {
        ...state,
        channelNPWData: {
          ...state.channelNPWData,
          brands: state.channelNPWData.brands.map(brand => {
            const updatedBrand = updatedBrands.find(b => b.brand_id === brand.brand_id);
            return updatedBrand || brand;
          }),
        },
        updatingNPW: true,
      };
    }
    case 'UPDATE_CHANNEL_NPW_FULFILLED': {
      return {
        ...state,
        channelNPWData: action.payload.data,
        updatingNPW: false,
      };
    }
    case 'FETCH_CHANNEL_SHOPIFY_TEMPLATE_PENDING': {
      return {
        ...state,
        fetchingChannelShopifyTemplate: true,
      };
    }
    case 'FETCH_CHANNEL_SHOPIFY_TEMPLATE_FULFILLED': {
      return {
        ...state,
        channelShopifyTemplate: action.payload.data,
        fetchingChannelShopifyTemplate: false,
      };
    }
    case 'FETCH_CHANNEL_NPW_TEMPLATE_PENDING': {
      return {
        ...state,
        fetchingChannelNPWTemplate: true,
      };
    }
    case 'FETCH_CHANNEL_NPW_TEMPLATE_FULFILLED': {
      return {
        ...state,
        channelNPWTemplate: action.payload.data,
        fetchingChannelNPWTemplate: false,
      };
    }
    case 'FETCH_CHANNEL_NPW_PENDING': {
      return {
        ...state,
        channelNPWData: [],
        fetchingChannelNPW: true,
      };
    }
    case 'FETCH_CHANNEL_NPW_FULFILLED': {
      return {
        ...state,
        channelNPWData: action.payload.data,
        fetchingChannelNPW: false,
      };
    }
    case 'UPDATE_CHANNEL_ADVISOR_PENDING': {
      return {
        ...state,
        channelAdvisorData: {
          ...state.channelAdvisorData,
          brands: action.meta.updatedValues,
        },
        updatingChannelAdvisor: true,
      };
    }
    case 'UPDATE_CHANNEL_ADVISOR_FULFILLED': {
      return {
        ...state,
        channelAdvisorData: action.payload.data,
        updatingChannelAdvisor: false,
      };
    }
    case 'FETCH_CHANNEL_ADVISOR_TEMPLATE_PENDING': {
      return {
        ...state,
        fetchingChannelAdvTemplate: true,
      };
    }
    case 'FETCH_CHANNEL_ADVISOR_TEMPLATE_FULFILLED': {
      return {
        ...state,
        channelAdvisorTemplate: action.payload.data,
        fetchingChannelAdvTemplate: false,
      };
    }
    case 'FETCH_CHANNEL_ADVISOR_PENDING': {
      return {
        ...state,
        channelAdvisorData: [],
        fetchingChannelAdvisor: true,
      };
    }
    case 'FETCH_CHANNEL_ADVISOR_FULFILLED': {
      return {
        ...state,
        channelAdvisorData: action.payload.data,
        fetchingChannelAdvisor: false,
      };
    }
    case 'FETCH_CHANNEL_OVERVIEW_FULFILLED': {
      return {
        ...state,
        isLoading: false,
        channelOverview: action.payload.data,
      };
    }
    case 'FETCH_CHANNEL_ITEMS_COUNT_FULFILLED': {
      return { ...state, channelItemsCount: action.payload.data.plans };
    }
    case 'FETCH_AUTHORIZED_BRANDS_FULFILLED': {
      return { ...state, authorizedBrands: action.payload.data };
    }
    case 'FETCH_EBAY_RESOURCES_PENDING': {
      return { ...state, fetchingEbayResources: true };
    }
    case 'FETCH_EBAY_RESOURCES_FULFILLED': {
      return { ...state, fetchingEbayResources: false, ebayResources: action.payload.data };
    }
    case 'FETCH_DEFAULT_POLICIES_PENDING': {
      return { ...state, fetchingDefaultPolicies: true };
    }
    case 'FETCH_DEFAULT_POLICIES_FULFILLED': {
      return { ...state, fetchingDefaultPolicies: false, defaultPolicies: action.payload.data };
    }
    case 'FETCH_EBAY_PROFILES_FULFILLED': {
      return { ...state, ebayProfiles: action.payload.data };
    }
    case 'FETCH_EBAY_POLICIES_FULFILLED': {
      const policies = action.payload.data;
      return {
        ...state,
        ebayPolicies: {
          fulfillment: policies.fulfillment_policies || [],
          return: policies.return_policies || [],
          payment: policies.payment_policies || [],
        },
      };
    }
    case 'FETCH_EBAY_SETTINGS_PENDING': {
      return {
        ...state,
        fetchingEbaySettings: true,
        fetchingConnectionState: !!action.meta.fetchingConnectionState,
      };
    }
    case 'FETCH_EBAY_SETTINGS_REJECTED': {
      return { ...state, fetchingEbaySettings: false, fetchingConnectionState: false };
    }
    case 'FETCH_EBAY_SETTINGS_FULFILLED': {
      return {
        ...state,
        ebaySettings: action.payload.data,
        fetchingEbaySettings: false,
        fetchingConnectionState: false,
      };
    }
    case 'FETCH_NAMING_CONVENTIONS_FULFILLED': {
      return { ...state, fileNameTemplates: action.payload.data };
    }

    case 'CREATE_CHANNEL_FULFILLED': {
      return {
        ...state,
        channelOverview: [...state.channelOverview, action.payload.data],
        selectedChannelId: action.payload.data.id,
      };
    }
    case 'CREATE_EBAY_SETTINGS_FULFILLED': {
      return { ...state, ebaySettings: action.payload.data };
    }
    case 'UPDATE_CHANNEL_FULFILLED': {
      if (!action.meta.shopifyId) {
        const channelOverview = state.channelOverview.map(channel =>
          channel.id === action.meta.channelId
            ? {
                ...action.payload.data,
                plans: channel.plans,
                default_ftp: channel.default_ftp,
                default_file: channel.default_file,
                default_schedule: channel.default_schedule,
              }
            : channel
        );
        return { ...state, channelOverview };
      }
      const shopifyChannels = state.channelShopifyData.channels.map(sc =>
        sc.id === action.meta.shopifyId ? { ...sc, name: action.payload.data.name } : sc
      );
      return { ...state, channelShopifyData: { channels: shopifyChannels } };
    }
    case 'UPDATE_CHANNEL_REJECTED': {
      if (!action.meta.shopifyId) return { ...state };
      return {
        ...state,
        shopifyChannelAlreadyExists: action.meta.name,
      };
    }
    case 'UPDATE_EXPORT_PLAN_FULFILLED': {
      const changedPlan = action.payload.data;
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === changedPlan.id
                  ? {
                      ...changedPlan,
                      brand_id: changedPlan.parent_owner_brand_id,
                      file: plan.file,
                      ftp: plan.ftp,
                      schedule: plan.schedule,
                      integration_settings: plan.integration_settings,
                    }
                  : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'UPDATE_CHANNEL_DIRTY_STATE': {
      return { ...state, formDirtyState: action.payload.dirty };
    }
    case 'UPDATE_NAMING_CONVENTIONS_FULFILLED': {
      return { ...state, fileNameTemplates: action.payload.data };
    }
    case 'CREATE_EXPORT_PLAN_FULFILLED': {
      const plan = { ...action.payload.data, brand_id: action.payload.data.parent_owner_brand_id };
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId ? { ...channel, plans: [...plans, plan] } : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_EXPORT_FILE_SETTINGS_FULFILLED': {
      const fileSettings = action.meta.exportOptions;
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.channelPlanId
                  ? {
                      ...plan,
                      file: {
                        export_options: fileSettings,
                        file_name_template_id: action.meta.fileTemplateId,
                      },
                    }
                  : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_DEFAULT_EXPORT_FILE_SETTINGS_FULFILLED': {
      const fileSettings = action.meta.fileSettings;
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId
          ? {
              ...channel,
              default_file: fileSettings,
              file_name_template_id: action.meta.fileTemplateId,
            }
          : channel
      );
      return { ...state, channelOverview };
    }
    case 'CREATE_EXPORT_FTP_SETTINGS_FULFILLED': {
      const ftpSettings = action.payload.data;
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === ftpSettings.plan_id ? { ...plan, ftp: ftpSettings } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_DEFAULT_EXPORT_FTP_SETTINGS_FULFILLED': {
      const ftpSettings = action.payload.data;
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId ? { ...channel, default_ftp: ftpSettings } : channel
      );
      return { ...state, channelOverview };
    }
    case 'CREATE_EXPORT_SCHEDULE_FULFILLED': {
      const schedule = action.payload.data;
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === schedule.plan_id ? { ...plan, schedule } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_DEFAULT_EXPORT_SCHEDULE_FULFILLED': {
      const schedule = action.payload.data;
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId ? { ...channel, default_schedule: schedule } : channel
      );
      return { ...state, channelOverview };
    }
    case 'CREATE_EBAY_INTEGRATED_SETTINGS_FULFILLED': {
      const plans = state.channelOverview.find(ch => ch.id === action.meta.channelId).plans || [];
      const oldPlan = plans.find(plan => plan.id === action.payload.data.plan_id);
      const descTypeIds =
        oldPlan && oldPlan.integration_settings
          ? oldPlan.integration_settings.description_type_ids
          : [];

      const integratedSettings = { ...action.payload.data, description_type_ids: descTypeIds };
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === integratedSettings.plan_id
                  ? { ...plan, integration_settings: integratedSettings }
                  : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_EBAY_DEFAULT_INTEGRATED_SETTINGS_FULFILLED': {
      const oldChannelData = state.channelOverview.find(ch => ch.id === action.meta.channelId);
      const descTypeIds = oldChannelData.default_integration_settings
        ? oldChannelData.default_integration_settings.description_type_ids
        : [];
      const integratedSettings = { ...action.payload.data, description_type_ids: descTypeIds };
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId
          ? { ...channel, default_integration_settings: integratedSettings }
          : channel
      );
      return { ...state, channelOverview };
    }
    case 'CREATE_EBAY_DESC_TYPES_FULFILLED': {
      const typeIds = action.payload.data.map(type => type.description_type_id);
      const plans = state.channelOverview.find(ch => ch.id === action.meta.channelId).plans || [];
      const plan = plans.find(plan => plan.id === action.meta.planId);
      const integrated = plan && plan.integration_settings ? plan.integration_settings : {};

      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.planId
                  ? {
                      ...plan,
                      integration_settings: { ...integrated, description_type_ids: typeIds },
                    }
                  : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'CREATE_EBAY_DEFAULT_DESC_TYPES_FULFILLED': {
      const channel = state.channelOverview.find(ch => ch.id === action.meta.channelId);
      const integratedSettings = channel.default_integration_settings
        ? channel.default_integration_settings
        : {};

      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId
          ? {
              ...channel,
              default_integration_settings: {
                ...integratedSettings,
                description_type_ids: action.payload.data.map(type => type.description_type_id),
              },
            }
          : channel
      );
      return { ...state, channelOverview };
    }
    case 'PUSH_CHANNEL_UPDATE_FULFILLED': {
      const { channelPlanId } = action.meta;
      return {
        ...state,
        channelItemsCount: state.channelItemsCount.map(ci => {
          if (ci.plan_id === channelPlanId) {
            return {
              ...ci,
              count: 0,
            };
          }
          return ci;
        }),
      };
    }
    case 'DISCONNECT_EBAY_ACCOUNT_FULFILLED': {
      return {
        ...state,
        ebaySettings: { ...state.ebaySettings, ebay_registered: 0 },
      };
    }
    case 'DELETE_PLANS_PENDING': {
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId
          ? {
              ...channel,
              plans: channel.plans.filter(plan => !action.meta.ids.includes(plan.id)),
            }
          : channel
      );
      return { ...state, channelOverview };
    }
    case 'DELETE_DEFAULT_EXPORT_FILE_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId ? { ...channel, default_file: undefined } : channel
      );
      return { ...state, channelOverview };
    }

    case 'DELETE_EXPORT_FILE_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.planId ? { ...plan, file: undefined } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'DELETE_DEFAULT_EXPORT_FTP_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId ? { ...channel, default_ftp: undefined } : channel
      );
      return { ...state, channelOverview };
    }
    case 'DELETE_EXPORT_FTP_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.planId ? { ...plan, ftp: undefined } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'DELETE_DEFAULT_EXPORT_SCHEDULE_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId ? { ...channel, default_schedule: undefined } : channel
      );
      return { ...state, channelOverview };
    }
    case 'DELETE_EXPORT_SCHEDULE_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.planId ? { ...plan, schedule: undefined } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'DELETE_DEFAULT_INTEGRATED_CHANNEL_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel =>
        channel.id === action.meta.channelId
          ? { ...channel, default_integration_settings: undefined }
          : channel
      );
      return { ...state, channelOverview };
    }
    case 'DELETE_INTEGRATED_CHANNEL_SETTINGS_FULFILLED': {
      const channelOverview = state.channelOverview.map(channel => {
        const { plans = [], id } = channel;
        return id === action.meta.channelId
          ? {
              ...channel,
              plans: plans.map(plan =>
                plan.id === action.meta.planId ? { ...plan, integration_settings: undefined } : plan
              ),
            }
          : channel;
      });
      return { ...state, channelOverview };
    }
    case 'SELECT_CHANNEL': {
      return { ...state, selectedChannelId: action.payload.id };
    }
    case 'DELETE_CHANNEL_PENDING': {
      return {
        ...state,
        deletePending: [...state.deletePending, action.meta.channelId],
      };
    }
    case 'DELETE_CHANNEL_FULFILLED': {
      return {
        ...state,
        channelOverview: state.channelOverview.filter(({ id }) => id !== action.meta.channelId),
        deletePending: [...state.deletePending.filter(id => id !== action.meta.channelId)],
      };
    }
    case 'DELETE_NAMING_CONVENTIONS_FULFILLED': {
      const { templateIds } = action.meta;
      return {
        ...state,
        fileNameTemplates: state.fileNameTemplates.filter(t => !templateIds.includes(t.id)),
      };
    }
    case 'FETCH_SHOPIFY_RANKED_APPLICATIONS_BY_FILTER_PENDING': {
      return {
        ...state,
        fetchingShopifyRankedApplications: true,
      };
    }
    case 'FETCH_SHOPIFY_RANKED_APPLICATIONS_BY_FILTER_FULFILLED': {
      return {
        ...state,
        rankedApplications: action.payload.data.sub_configs,
        fetchingShopifyRankedApplications: false,
      };
    }
  }
  return state;
};

export default reducer;
