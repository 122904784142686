import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Spin, Table, Empty, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { AutoSizer } from 'react-virtualized';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { checkKeywordLength, typingDone } from '../../../utils/Utils';
import { ApplicationState } from '../../../reducers';
import KitDrawer from './KitDrawer';
import Page from '../../global/page/Page';
import { fetchItemKits } from '../../../actions/items/kit/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { Item } from '../../../../types/item';
import { KitType } from '../../../../types/kit';
import { DefaultValue } from '../../../../types/brand_settings';
import noImage from '../../../../images/icons/no-image.svg';
import { updateKitOrder } from '../../../actions/items/kit/update';
import DragableBodyRow from '../../global/dragableTable/BodyRow';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { intercomEvent } from '../../../utils/IntercomUtils';
import AntTooltip from '../../global/AntTooltip';
import AnalysesAlertIcon from '../../global/AnalysesAlertIcon';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';
import SearchInput from '../../global/Forms/SearchInput';

const { Search } = Input;

type KitPageProps = {
  itemId: number;
} & Item;

const KitPage: React.FC<KitPageProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const [kitDrawerVisible, setKitDrawerVisible] = React.useState(false);
  const [editKitRecord, setEditKitRecord] = React.useState(undefined);
  const [searchKey, setSearchkey] = React.useState('');

  const {
    brandId,
    allBrands,
    listUoms,
    listQualifiers,
    kits,
    fetchingKits,
    defaultValues,
    selectedItem,
    analysesBySegment,
  } = useSelector((state: ApplicationState) => ({
    selectedItem: getSelectedItems(state)[0],
    brandId: state.parent.brands.selectedBrandId,
    allBrands: state.resources.data.global.parent_owner_brands.map(brand => ({
      ...brand,
      name: `${brand.code} | ${brand.name}`,
    })),
    listUoms: state.resources.data.global.measurement_uom_codes,
    listQualifiers: state.resources.data.kit.qualifiers,
    kits: state.items.kit.kits,
    fetchingKits: state.items.kit.fetchingKits,
    defaultValues: state.settings.defaultValues,
    analysesBySegment: extendedAnalysesBySegment(state),
  }));

  React.useEffect(() => {
    if (props.itemId) setSearchkey('');
  }, [dispatch, props.itemId]);

  const showPartTypeId = defaultValues.find((d: DefaultValue) => d.id === 11)?.value === '1';
  const isOriginalBrand = useMemo(
    () => !!allBrands.find(b => b.id === brandId),
    [allBrands, brandId]
  );

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRow = kits[dragIndex];
    const remaining = kits.filter((i: any) => i !== dragRow);
    const sorted = [...remaining.slice(0, hoverIndex), dragRow, ...remaining.slice(hoverIndex)];

    dispatch(updateKitOrder(sorted));
  };

  const handleKitSearch = (keyword: string | '') => {
    setSearchkey(keyword);
    const minKeywordLengthReached = checkKeywordLength(keyword);

    typingDone(() => {
      if (minKeywordLengthReached) handleFetchKits(keyword);
    });
  };

  const handleFetchKits = (updatedKeyword?: string) => {
    const keyword = updatedKeyword || searchKey;

    dispatch(fetchItemKits(props.itemId, keyword));
  };

  const handleKitAdd = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'kit_new',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
    setKitDrawerVisible(true);
  };

  const handleKitEdit = (record: any) => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'kit',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    setEditKitRecord(record);
    setKitDrawerVisible(true);
  };

  const extendedKits: [] = kits.map((kit: KitType) => {
    const quantityUom = listUoms.find(uom => kit.quantity_in_kit_uom_id === uom.id);
    const qualifier = listQualifiers.find(
      (qualifier: { id: number }) => kit.part_number_qualifier_id === qualifier.id
    );
    const analyses = analysesBySegment.filter((a: Analysis & AnalysisType) => {
      return a.reference_id === kit.id;
    });

    return {
      ...kit,
      key: kit.id.toString(),
      brandCodeName: kit.brand_id && `${kit.brand_code} | ${kit.brand_name}`,
      subBrandName: kit.sub_brand_name && `${kit.sub_brand_code} | ${kit.sub_brand_name}`,
      quantityUom: `${kit.quantity_in_kit} ${quantityUom?.name}`,
      qualifier: qualifier?.name.replace("Vendor's (Seller's)", ''),
      part_type_name: `${kit.part_type_name || ''}${
        showPartTypeId && kit.part_type_id ? ` (${kit.part_type_id})` : ''
      }`,
      analyses,
    };
  });

  const columns: any = [
    {
      width: 40,
      dataIndex: 'record_number',
      key: 'recordNumber',
      render: (text: any, record: any, index: number) => (
        <span className="cursor-move text-gray-600">
          <span className="text-lg">::</span>
          <span className="text-sm">{` ${index + 1}`}</span>
        </span>
      ),
    },
    {
      width: 15,
      dataIndex: 'analyses',
      key: 'analyses',
      render: (analyses: (Analysis & AnalysisType)[]) => <AnalysesAlertIcon analyses={analyses} />,
      align: 'left',
      className: 'kit__analyses-alert-icon',
    },
    {
      title: t('kit:partNumber'),
      dataIndex: 'part_number',
      key: '1',
      ellipsis: true,
    },
    {
      title: t('kit:brand'),
      dataIndex: 'brandCodeName',
      key: '2',
      ellipsis: true,
      render: (text: any, record: any) => (
        <span>
          {!isOriginalBrand && record.brand_id === brandId && (
            <AntTooltip title={t('kit:brandCodeInfo')}>
              <WarningOutlined className="icon__red mr-1" />
            </AntTooltip>
          )}
          {text}
        </span>
      ),
    },
    {
      title: t('kit:subBrand'),
      dataIndex: 'subBrandName',
      key: '3',
      ellipsis: true,
    },
    {
      title: t('kit:partType'),
      dataIndex: 'part_type_name',
      key: '4',
      ellipsis: true,
    },
    {
      title: t('kit:qualifier'),
      dataIndex: 'qualifier',
      key: '5',
      ellipsis: true,
    },
    {
      title: t('kit:quantityUom'),
      dataIndex: 'quantityUom',
      key: '6',
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: '7',
      ellipsis: true,
    },
    {
      title: 'Preview',
      key: 'operation',
      fixed: 'right',
      width: 150,
      render: (text: any, record: any) => (
        <div className="flex justify-around">
          {record.asset_url ? (
            <Tooltip
              placement="top"
              title={<img style={{ height: 150, width: 150 }} src={record.asset_url} alt="" />}
            >
              <div style={{ height: 21, width: 35 }}>
                <img
                  src={record.asset_url}
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  alt=""
                />
              </div>
            </Tooltip>
          ) : (
            <div style={{ height: 21, width: 35 }}>
              <img src={noImage} style={{ maxHeight: '100%', maxWidth: '100%' }} alt="" />
            </div>
          )}
          <Button
            type="primary"
            size="small"
            className="kit__edit__button mr-2"
            onClick={() => handleKitEdit(record)}
          >
            {t('common:edit')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Page showAnalysis contentNoSpacing>
      <div className="overflow-hidden h-full">
        <div className="flex flex-row mb-2">
          <div className="pl-3 pt-3">
            <SearchInput
              className="kit__search"
              placeholder={t('kit:searchKit')}
              onChange={e => handleKitSearch(e.target.value)}
              onPressEnter={() => handleFetchKits()}
              value={searchKey}
              size="small"
              search
            />

            <Button
              type="primary"
              ghost
              onClick={() => handleKitAdd()}
              size="small"
              data-testid="add-kit"
            >
              <span className="px-10">{t('kit:addProductToKit')}</span>
            </Button>
          </div>
        </div>

        {fetchingKits ? (
          <Spin className="spinner-center" style={{ marginTop: '20px' }} />
        ) : (
          (kits.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <div style={{ height, width }} className="kit__page__table">
                  <DndProvider backend={HTML5Backend}>
                    <Table
                      className="drag-sorting-table"
                      size="small"
                      columns={columns}
                      dataSource={extendedKits}
                      components={components}
                      // @ts-ignore
                      onRow={(data: any, index?: number) => ({
                        index,
                        moveRow,
                      })}
                      scroll={{
                        x: width > 1057 ? undefined : 1057,
                        y: height > kits.length * 57 + 83 ? undefined : height - 83,
                      }}
                      pagination={{ hideOnSinglePage: true, pageSize: extendedKits.length }}
                    />
                  </DndProvider>
                </div>
              )}
            </AutoSizer>
          )) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>

      {kitDrawerVisible && (
        <KitDrawer
          onClose={() => {
            setEditKitRecord(undefined);
            if (kitDrawerVisible) setKitDrawerVisible(false);
          }}
          showDelete={!!editKitRecord}
          kit={editKitRecord}
        />
      )}
    </Page>
  );
};

export default KitPage;
