import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ItemPackage } from '../../../types/itemPackage';

export type PackageState = {
  itemPackages: ItemPackage[];
  brandUsedFields: string[];
  usedFieldsBrandId?: number;
  fetchingPackages: boolean;
};
const initialState = {
  itemPackages: [],
  brandUsedFields: [],
  usedFieldsBrandId: undefined,
  fetchingPackages: false,
};

const reducer: Reducer<PackageState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ITEM_PACKAGES_PENDING': {
      return {
        ...state,
        itemPackages: [],
        fetchingPackages: true,
      };
    }
    case 'FETCH_ITEM_PACKAGES_FULFILLED': {
      return {
        ...state,
        itemPackages: action.payload.data,
        fetchingPackages: false,
      };
    }
    case 'FETCH_BRAND_USED_PACKAGES_FULFILLED': {
      return {
        ...state,
        brandUsedFields: action.payload.data,
        usedFieldsBrandId: action.meta.brandId,
      };
    }
    case 'UPDATE_PACKAGE_FULFILLED': {
      return {
        ...state,
        itemPackages: action.payload.data,
      };
    }
  }
  return state;
};

export default reducer;
