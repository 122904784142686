import React from 'react';
import { Button, Form, Switch } from 'antd';
import { FormikValues, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import constants from '../../../constants/ApplicationTranslation.json';
import {
  fetchApplicationLinks,
  fetchApplicationParentItems,
} from '../../../actions/items/application/fetch';
import { createApplicationLinks } from '../../../actions/items/application/create';
import { removeApplicationLinks } from '../../../actions/items/application/delete';
import { fetchItemAnalyses } from '../../../actions/items/analyses/fetch';
import { fetchExtendedItem } from '../../../actions/items/item/fetch';
import ApplicationLinks from './ApplicationLinks';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { BundleItem } from '../../../../types/application';
import { Item } from '../../../../types/item';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';

type ApplicationLinkDrawerProps = {
  dispatch: AsyncDispatch;
  visible: boolean;
  brandId: number;
  selectedItem: Item;
  parentApplicationItemIds: number[];
  parentApplicationItemBases: BundleItem[];
  onClose: () => void;
  fetchApplications: () => void;
};

const ApplicationLinkDrawer: React.FC<ApplicationLinkDrawerProps> = ({
  dispatch,
  visible,
  brandId,
  selectedItem,
  parentApplicationItemIds,
  parentApplicationItemBases,
  onClose,
  fetchApplications,
}) => {
  const { link_drawer: drawerText } = constants;

  const [crossLinkBrandsEnabled, setCrossLinkBrandsEnabled] = React.useState(false);

  React.useEffect(() => {
    if (selectedItem.id) {
      dispatch(fetchApplicationLinks(selectedItem.id)).then(
        ({ value: { data: parentItemBases } }) => {
          if (parentItemBases.length) {
            const parentItemIds = parentItemBases.map((base: any) => base.base_item_id);
            // @ts-ignore
            dispatch(fetchApplicationParentItems({ itemIds: parentItemIds })).then(
              ({ value: { data: items } }) => {
                // if parent items are linked to a other brand we want to set the switch to true
                if (
                  items.items.find(
                    ({ parent_owner_brand_id }: { parent_owner_brand_id: number }) =>
                      parent_owner_brand_id !== brandId
                  )
                ) {
                  setCrossLinkBrandsEnabled(true);
                }
              }
            );
          }
        }
      );
    }
  }, [dispatch, brandId, selectedItem.id]);

  const fetchSelectedItemDetails = () =>
    dispatch(fetchItemAnalyses(selectedItem.id)).then(() => fetchExtendedItem(selectedItem.id));

  const handleSubmit = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    const { parentApplicationItemIds: changedItemdIds } = values;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'application_links',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    const removedIds = parentApplicationItemIds.filter(
      id => !changedItemdIds.find((cId: number) => cId === id)
    );
    const addedIds = changedItemdIds.filter(
      (cId: number) => !parentApplicationItemIds.find(id => cId === id)
    );

    if (addedIds.length) await dispatch(createApplicationLinks(addedIds, selectedItem.id));
    if (removedIds.length) {
      const itemBaseIds = parentApplicationItemBases
        .filter(b => removedIds.includes(b.base_item_id))
        .map(b => b.id);

      await dispatch(removeApplicationLinks(removedIds, itemBaseIds));
    }
    fetchSelectedItemDetails();
    fetchApplications();

    setSubmitting(false);
    onClose();
  };

  return (
    <DrawerFormik
      visible={visible}
      onClose={onClose}
      title={drawerText.title}
      width="800"
      initialValues={{
        parentApplicationItemIds,
      }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ handleSubmit, values, setFieldValue, setFieldTouched }) => (
        <Form onFinish={() => handleSubmit()}>
          <h4>{drawerText.link_application}</h4>
          <div className="flex" style={{ marginBottom: 15 }}>
            <Switch
              checked={crossLinkBrandsEnabled}
              onChange={(checked: boolean) => setCrossLinkBrandsEnabled(checked)}
            />
            <span style={{ marginLeft: 5 }}>{constants.searchAllProducts}</span>
          </div>
          <ApplicationLinks
            crossLinkBrandsEnabled={crossLinkBrandsEnabled}
            parentApplicationItemIds={values.parentApplicationItemIds}
            selectParentItem={(id: number) => {
              setFieldValue('parentApplicationItemIds', [...values.parentApplicationItemIds, id]);
              setFieldTouched('parentApplicationItemIds');
            }}
            deselectParentItem={(id: number) => {
              const itemIds = values.parentApplicationItemIds.filter(
                (itemId: number) => itemId !== id
              );
              setFieldValue('parentApplicationItemIds', itemIds);
              setFieldTouched('parentApplicationItemIds');
            }}
          />

          <h4>{drawerText.link_oem}</h4>
          <p>{drawerText.link_oem_message}</p>
          <Button>
            <Link to={`/brand/products/interchange?brandId=${brandId}`}>
              {drawerText.interchange}
            </Link>
          </Button>
        </Form>
      )}
    </DrawerFormik>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    brandId: state.parent.brands.selectedBrandId,
    selectedItem: getSelectedItems(state)[0],
    parentApplicationItemIds: state.items.application.parentApplicationItemIds || [],
    applicationParentItems: state.items.application.applicationParentItems || [],
    parentApplicationItemBases: state.items.application.parentApplicationItemBases,
  };
};

export default connect(mapStateToProps)(withErrorBoundary(ApplicationLinkDrawer));
