import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { message, Form, Button } from 'antd';

import FormInput from '../../global/Forms/FormInput';

import catalogueTranslation from '../../../constants/CatalogueTranslations.json';
import validationMessages from '../../../constants/ValidationMessages.json';
import { fillInPlaceholders } from '../../../utils/String';

type CatalogueSaveFilterProps = {
  handleSaveNewFilter: (name: string) => Promise<undefined | ProgressEvent>;
};

class CatalogueSaveFilter extends React.Component<CatalogueSaveFilterProps> {
  validationSchema = Yup.object().shape({
    filterName: Yup.string().required(validationMessages.required).min(3),
  });

  handleSaveButton = (
    values: { filterName: string },
    formikActions: FormikHelpers<{ filterName: string }>
  ) => {
    const { filterName } = values;
    const { setErrors, setSubmitting } = formikActions;
    this.props
      .handleSaveNewFilter(filterName)
      .then(() => {
        message.success(
          fillInPlaceholders(catalogueTranslation.filterCreationSuccessfully, {
            filterName,
          })
        );
        setSubmitting(false);
      })
      .catch(() => {
        setErrors({
          // Workaround for backend, waiting for better error handling
          filterName: catalogueTranslation.filterAlreadyExists,
        });
        setSubmitting(false);
      });
  };

  render() {
    return (
      <Formik
        initialValues={{ filterName: '' }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSaveButton}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="save-filter-popover__form" layout="vertical">
            <FormInput
              name="filterName"
              label={`${catalogueTranslation.catalogueBar.filterName}:`}
              placeholder={catalogueTranslation.catalogueBar.filterName}
              required
            />
            <Button
              className="save-filter-popover__form__submit"
              type="primary"
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {catalogueTranslation.catalogueBar.save}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default CatalogueSaveFilter;
