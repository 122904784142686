import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

export type AppNavigationBar = {
  readonly activeArea: string | undefined;
};

const initialState: AppNavigationBar = {
  activeArea: undefined,
};

const reducer: Reducer<AppNavigationBar, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_AREA': {
      return { ...state, activeArea: action.payload };
    }
  }
  return state;
};

export default reducer;
