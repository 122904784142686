const initialState = {
  xmlFile: '',
  fetchingXmlFile: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_XML_FILE_PENDING': {
      return { ...state, fetchingXmlFile: true };
    }
    case 'FETCH_XML_FILE_FULFILLED': {
      return { ...state, xmlFile: action.payload.data, fetchingXmlFile: false };
    }
  }
  return state;
};

export default reducer;
