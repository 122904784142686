import React from 'react';
import { useDispatch } from 'react-redux';
import AttributeManagingPage from '../../../components/body/parentSettings/attributeManaging/AttributeManagingPage';
import { fetchAttributeTagList } from '../../../actions/parent/attribute/fetch';
import { fetchAccountLanguages } from '../../../actions/parent/language/fetch';
import { setSelectedCategory } from '../../../actions/parent/attribute/update';

const AttributeManagingContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAttributeTagList());
    dispatch(fetchAccountLanguages());

    return () => {
      dispatch(setSelectedCategory(undefined));
    };
  }, [dispatch]);

  return <AttributeManagingPage />;
};

export default AttributeManagingContainer;
