import { Field, FieldProps } from 'formik';
import { Form, Radio } from 'antd';
import React, { ReactNode } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import { get as _get } from 'lodash';

type FormRadioProps = {
  name: string;
  label?: string | ReactNode;
  values: {
    id: string | number;
    name: string | number;
    disabled?: boolean;
  }[];
  disabled?: boolean;
  required?: boolean;
  buttonOptions?: boolean;
  handleChange?: (e: RadioChangeEvent) => void;
};

const FormRadio: React.FC<FormRadioProps> = props => {
  const { name, values, label, disabled, required, buttonOptions, handleChange } = props;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { errors, touched } = form;
        const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
        const onChange = handleChange || field.onChange;

        return (
          <Form.Item
            label={label}
            hasFeedback
            validateStatus={showValidationInfo ? 'error' : ''}
            help={(showValidationInfo && _get(errors, name)) || undefined}
            required={required}
          >
            <Radio.Group
              name={name}
              disabled={disabled}
              value={field.value}
              onChange={onChange}
              optionType={buttonOptions ? 'button' : undefined}
              buttonStyle={buttonOptions ? 'solid' : undefined}
            >
              {values.map(value =>
                buttonOptions ? (
                  <Radio.Button
                    key={value.id}
                    value={value.id}
                    disabled={value.disabled || disabled}
                  >
                    {value.name}
                  </Radio.Button>
                ) : (
                  <Radio key={value.id} value={value.id} disabled={value.disabled || disabled}>
                    {value.name}
                  </Radio>
                )
              )}
            </Radio.Group>
          </Form.Item>
        );
      }}
    </Field>
  );
};

export default FormRadio;
