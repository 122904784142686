import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteKitItem(kitId: number) {
  return {
    type: 'DELETE_KIT_ITEM',
    meta: { kitId },
    payload: axios.delete(`${getApiRoot()}/items/kits/${kitId}`),
  };
}
