import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AntPopover from '../../../global/AntPopover';

type TranslationsHeaderCountProps = {
  title: string;
  checked: boolean;
  handleUsedTranslations: () => void;
};

const TranslationsHeaderCount: React.FC<TranslationsHeaderCountProps> = ({
  title,
  checked,
  handleUsedTranslations,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center translations-header-cell">
      <Tooltip title={t('language:usageInfoText')}>
        <div>{title}</div>
      </Tooltip>
      <div onClick={e => e.stopPropagation()} className="flex flex-row items-center">
        <AntPopover
          trigger="click"
          disabled={false}
          content={
            <Checkbox onChange={() => handleUsedTranslations()} checked={checked}>
              {t('common:used')}
            </Checkbox>
          }
          buttonProps={{
            type: 'text',
            size: 'small',
            icon: checked ? <FilterFilled className="fill-blue" /> : <FilterOutlined />,
          }}
        />
      </div>
    </div>
  );
};

export default TranslationsHeaderCount;
