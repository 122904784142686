import { FilterTypeGo } from '../../types/filter';

// use event function as util, because Intercom is not enabled in development
export function intercomEvent(event: string, metaData?: { [key: string]: string }) {
  if ((window as any).Intercom) {
    (window as any).Intercom('trackEvent', event, metaData);
  }
}

export function showIntercom() {
  if ((window as any).Intercom) (window as any).Intercom('show');
}

export function sendFilterEvent(filter: FilterTypeGo, location?: string) {
  const filterSections = [
    'filter_category',
    'filter_application',
    'filter_analysis',
    'filter_custom',
  ];
  const filterBy = Object.keys(filter)
    .map(filterSection => {
      if (filterSections.includes(filterSection)) {
        // @ts-ignore
        if (filter[filterSection] && filter[filterSection][0].parameters.length > 0)
          return filterSection;
      }
    })
    .filter(Boolean);

  intercomEvent('filter', {
    location: location || 'all-products',
    filter_by: filterBy.join(', '),
  });
}

export function startIntercomTour(tourId: number) {
  if ((window as any).Intercom) {
    (window as any).Intercom('startTour', tourId);
  }
}
