import React from 'react';
import { connect } from 'react-redux';
import hljs from 'highlight.js';

import ModalContent from '../../global/Modals/ModalContent';

class XMLPreviewModal extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.xmlFile !== this.props.xmlFile) {
      this.highlightCode();
    }
  }

  highlightCode = () => {
    hljs.initHighlighting.called = false;
    hljs.initHighlighting();
  };

  render() {
    const { xmlFile, fetchingXmlFile } = this.props;
    return (
      <ModalContent clickOutside className="xml-preview-modal">
        {fetchingXmlFile ? (
          <div className="loader__wrapper">
            <div className="loader" />
          </div>
        ) : (
          <pre>
            <code className="xml">{xmlFile}</code>
          </pre>
        )}
      </ModalContent>
    );
  }
}

const mapStateToProps = state => ({
  fetchingXmlFile: state.items.xmlPreview.fetchingXmlFile,
  xmlFile: state.items.xmlPreview.xmlFile,
});

export default connect(mapStateToProps)(XMLPreviewModal);
