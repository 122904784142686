import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../../reducers';
import CustomizeLogModal from '../import_export/CustomizeLogModal';
import AutomationLogsTable from './AutomationLogsTable';
import AutomationErrorLogModal from './AutomationErrorLogModal';
import { getPageLimit } from '../../../utils/Utils';

type AutomationLogsProps = {
  fetchNextScriptLogs: (page: number) => Promise<any>;
};

const AutomationLogs: React.FC<AutomationLogsProps> = props => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showCustomizeModal, setShowCustomizeModal] = React.useState<boolean>(false);
  const [logId, setLogId] = React.useState<number | null>(null);
  const [columns, setColumns] = React.useState([
    { dataKey: 'script_name', label: t('api:automationName'), visible: true },
    {
      dataKey: 'started_at',
      label: t('common:started'),
      visible: true,
    },
    {
      dataKey: 'completed_at',
      label: t('common:completed'),
      visible: true,
    },
    { dataKey: 'status', label: t('common:status'), visible: true },
    { dataKey: 'action', label: t('common:action'), visible: true },
  ]);

  const { scriptLogs, fetchingLogs } = useSelector((state: ApplicationState) => ({
    scriptLogs: state.distribution.apiSettings.scriptLogs,
    fetchingLogs: state.distribution.apiSettings.fetchingScriptLogs,
  }));

  const pageSize = useMemo(getPageLimit, []);

  const handleTableScroll = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => {
    const nextPage = Math.ceil(stopIndex / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize);
    const lastPage = currentPage > startIndex / pageSize;

    if (!lastPage && !fetchingLogs) return props.fetchNextScriptLogs(nextPage);
    return Promise.resolve();
  };

  return (
    <div className="excel-import__table bg-white flex flex-col">
      <div className="p-5 text-right">
        <Button icon={<TableOutlined />} onClick={() => setShowCustomizeModal(true)}>
          {t('common:customize')}
        </Button>
      </div>
      <div className="flex-1 px-5">
        <AutomationLogsTable
          logs={scriptLogs}
          columns={columns}
          fetchingLogs={fetchingLogs}
          handleDisplayError={(logId: number) => {
            setShowModal(true);
            setLogId(logId);
          }}
          handleTableScroll={handleTableScroll}
        />

        <AutomationErrorLogModal
          showModal={showModal}
          error={(showModal && logId && scriptLogs.find(l => l.id === logId)?.error_message) || ''}
          onClose={() => {
            setShowModal(!showModal);
            setLogId(null);
          }}
        />
      </div>

      <CustomizeLogModal
        showModal={showCustomizeModal}
        columns={columns}
        onCancel={() => setShowCustomizeModal(false)}
        handleSave={columns => {
          setColumns(columns);
          setShowCustomizeModal(false);
        }}
      />
    </div>
  );
};

export default AutomationLogs;
