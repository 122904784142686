import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';

declare interface BlockerConfirmType {
  blockerActive: boolean;
  next: string;
  onConfirm(): void;
  onCancel(): void;
}

export const useBlockerConfirm = (when: boolean): BlockerConfirmType => {
  const { t } = useTranslation();

  const next = useRef('');

  const blocker = useBlocker(
    useCallback(
      ({ currentLocation, nextLocation }) => {
        if (when && currentLocation.pathname !== nextLocation.pathname)
          next.current = nextLocation.pathname + nextLocation.search;
        return currentLocation.pathname !== nextLocation.pathname && when;
      },
      [when]
    )
  );

  useBeforeUnload(
    useCallback(
      event => {
        if (when) {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.returnValue = t('common:closeWarningTitle');
        }
      },
      [when]
    ),
    { capture: true }
  );

  const onCancel = () => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    next.current = '';
  };

  const onConfirm = () => {
    next.current = '';
  };

  return {
    blockerActive: blocker.state === 'blocked',
    next: next.current,
    onConfirm,
    onCancel,
  };
};

export default useBlockerConfirm;
