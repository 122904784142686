import { Reducer } from 'redux';
import {
  AccessableBrand,
  ReceiverCatalogueBrandType,
  ReceiverPendingRequests,
} from '../../../types/receiver_data_stream';
import { isBrandRequestPending } from '../../utils/ReceiverUtils';
import { Brand, SubBrand, GroupedSubBrand } from '../../../types/brand';
import { AvailableChannel } from '../../../types/channel';
import { AvailableReceiver } from '../../../types/receiver';

export type ReceiverDataStreamState = {
  readonly isInitialized: boolean;
  readonly fetching: boolean;
  readonly allAccessableBrands: AccessableBrand[];
  readonly brands: ReceiverCatalogueBrandType[];
  readonly brandFilterCount: number;
  readonly authorizedBrands: Brand[];
  readonly groupedSubBrands: GroupedSubBrand[];
  readonly subBrands: SubBrand[];
  readonly availableChannels: AvailableChannel[];
  readonly availableReceivers: AvailableReceiver[];
  readonly brandRequestCount: number;
  readonly pendingDetailsRequests: ReceiverPendingRequests[];
};

const initialState: ReceiverDataStreamState = {
  isInitialized: false,
  fetching: false,
  allAccessableBrands: [],
  brands: [],
  brandFilterCount: 0,
  authorizedBrands: [],
  groupedSubBrands: [],
  subBrands: [],
  availableChannels: [],
  availableReceivers: [],
  brandRequestCount: 0,
  pendingDetailsRequests: [],
};

const reducer: Reducer<ReceiverDataStreamState> = (
  state = initialState,
  action
): ReceiverDataStreamState => {
  switch (action.type) {
    case 'SET_RECEIVER_DATA_STREAM_INITIALIZE_STATUS': {
      return { ...state, isInitialized: action.payload.status };
    }
    case 'FETCH_BRAND_REQUEST_COUNT_FULFILLED': {
      return { ...state, brandRequestCount: action.payload.data.pending_request_count };
    }
    case 'FETCH_ALL_RECEIVER_BRANDS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_ALL_RECEIVER_BRANDS_FULFILLED': {
      const brands = action.payload.data;
      return { ...state, allAccessableBrands: brands, brands, fetching: false };
    }
    case 'FETCH_RECEIVER_BRANDS_BY_FILTER_ID_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        brands: page === 1 ? [] : state.brands,
        brandFilterCount: page === 1 ? 0 : state.brandFilterCount,
        fetching: true,
      };
    }
    case 'FETCH_RECEIVER_BRANDS_BY_FILTER_ID_FULFILLED': {
      const add = action.meta.page > 1;
      return {
        ...state,
        brands: add ? [...state.brands, ...action.payload.data.brands] : action.payload.data.brands,
        brandFilterCount: action.payload.data.brand_count,
        fetching: false,
      };
    }
    case 'FETCH_AUTHORIZED_BRANDS_FULFILLED': {
      const authorizedBrands = action.payload.data;
      return { ...state, authorizedBrands };
    }
    case 'FETCH_RECEIVER_SUB_BRANDS_FULFILLED': {
      const brandList = action.payload.data.brands;
      const subBrands = brandList.map((brand: GroupedSubBrand) => brand.sub_brands).flat();
      return { ...state, groupedSubBrands: brandList, subBrands };
    }
    case '@RECEIVER/FETCH_AVAILABLE_CHANNELS_RECEIVERS_FULFILLED': {
      const { channels, receivers } = action.payload.data;
      return { ...state, availableChannels: channels, availableReceivers: receivers };
    }
    case '@RECEIVER/SET_CHANNELS_RECEIVERS_TO_EMPTY': {
      return { ...state, availableChannels: [], availableReceivers: [] };
    }
    case 'APPROVE_BRAND_REQUESTS_FULFILLED': {
      const approvedBrandIds = action.meta.brandIds.filter((brandId: number) => {
        const brand = state.brands.find(({ id }) => id === brandId);
        return brand && isBrandRequestPending(brand);
      });
      return {
        ...state,
        brandRequestCount:
          approvedBrandIds.length > 0
            ? Math.max(0, state.brandRequestCount - approvedBrandIds.length)
            : state.brandRequestCount,
        brands: state.brands.map(brand => {
          if (action.meta.brandIds.includes(brand.id)) {
            return {
              ...brand,
              authorized_by_receiver_at: new Date().toString(),
              declined_by_receiver_at: null,
            };
          }
          return brand;
        }),
        authorizedBrands: [
          ...approvedBrandIds.map((brandId: number) =>
            state.brands.find(({ id }) => id === brandId)
          ),
          ...state.authorizedBrands,
        ],
      };
    }
    case 'DECLINE_BRAND_REQUESTS_FULFILLED': {
      const declinedBrandIds = action.meta.brandIds.filter((brandId: number) => {
        const brand = state.brands.find(({ id }) => id === brandId);
        return brand && isBrandRequestPending(brand);
      });
      return {
        ...state,
        brandRequestCount:
          declinedBrandIds.length > 0
            ? Math.max(0, state.brandRequestCount - declinedBrandIds.length)
            : state.brandRequestCount,
        brands: state.brands.map(brand => {
          if (action.meta.brandIds.includes(brand.id)) {
            return {
              ...brand,
              authorized_by_receiver_at: null,
              declined_by_receiver_at: new Date().toString(),
            };
          }
          return brand;
        }),
        authorizedBrands: state.authorizedBrands.filter(
          brand => !action.meta.brandIds.includes(brand.id)
        ),
      };
    }
    case 'RECEIVER_REQUEST_BRANDS_FULFILLED': {
      return {
        ...state,
        brands: state.brands.map(brand => {
          if (action.meta.brandIds.includes(brand.id)) {
            return { ...brand, authorized_by_receiver_at: new Date().toString() };
          }
          return brand;
        }),
      };
    }
    case 'CANCEL_BRAND_REQUEST_FULFILLED': {
      return {
        ...state,
        brands: state.brands.map((brand: any) => {
          if (brand.id === action.meta.brandId) {
            return {
              ...brand,
              authorized_by_receiver_at: null,
              request_canceled_at: new Date().toString(),
            };
          }
          return brand;
        }),
      };
    }
    case 'ACKNOWLEDGE_DETAILS_REQUEST_FULFILLED': {
      const updateBrands = state.brands.map(brand =>
        brand.parent_id === action.meta.parentId
          ? {
              ...brand,
              receiver_profile_requested_acknowledged: 1,
            }
          : brand
      );
      const updatePendingDetailsRequests = state.pendingDetailsRequests.filter(
        req => req.parent_id === action.meta.parentId
      );
      return {
        ...state,
        brands: updateBrands,
        pendingDetailsRequests: updatePendingDetailsRequests,
      };
    }
    case 'FETCH_PENDING_DETAILS_REQUESTS_FULFILLED': {
      return {
        ...state,
        pendingDetailsRequests: action.payload.data.pending_requests,
      };
    }
  }
  return state;
};

export default reducer;
