import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Switch, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AntTooltip from '../../components/global/AntTooltip';

import { fetchPriceSheets, fetchBrandPriceSheets } from '../../actions/parent/price_sheets/fetch';
import { showUpgradePlanNotification } from '../../actions/app/modal';
import { ApplicationState } from '../../reducers';
import { PriceSheet } from '../../../types/price_sheet';
import PriceSheetsSettingsPage from '../../components/body/settings/PriceSheetsSettingsPage';
import PriceSheetDrawer from '../../components/body/settings/PriceSheetDrawer';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const SettingsPriceSheetsContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAllPriceSheets, setShowAllPriceSheets] = React.useState<boolean>(false);
  const [newPriceSheet, setNewPriceSheet] = React.useState<boolean>(false);

  const { brandId, priceSheets, brandPriceSheetIds, user, brandCode } = useSelector(
    (state: ApplicationState) => {
      return {
        priceSheets: state.parent.priceSheets.priceSheets,
        brandPriceSheetIds: state.parent.priceSheets.brandPriceSheetIds,
        brandId: state.parent.brands.selectedBrandId,
        user: state.user.user,
        brandCode: getSelectedBrandCode(state),
      };
    }
  );

  const visiblePriceSheets = () => {
    return showAllPriceSheets
      ? priceSheets
      : priceSheets.filter((priceSheet: PriceSheet) => {
          return brandPriceSheetIds.includes(priceSheet.id) && priceSheet.used === 1;
        });
  };

  React.useEffect(() => {
    intercomEvent('viewed-brand-settings', {
      location: 'settings-price-sheets',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    dispatch(fetchPriceSheets());
    dispatch(fetchBrandPriceSheets(brandId));
  }, [brandId, dispatch]);

  const canManagePriceSheets = hasPermission(user, 'can_manage_price_sheets');

  const handleOpenPriceSheetDrawer = () => {
    setNewPriceSheet(true);
    if (!canManagePriceSheets) dispatch(showUpgradePlanNotification());

    intercomEvent('viewed-brand-settings', {
      location: 'settings-price-sheets',
      action: 'add_new',
      brand_code: brandCode!,
    });
  };

  return (
    <div className="page-layout">
      <div className="page-layout__top-bar">
        <div className="page-layout__top-bar__container">
          <div />
          <div className="page-layout__top-bar__actions">
            <span className="text-gray-800 pr-1">{t('pricing:showallPriceSheets')}</span>

            <AntTooltip title={t('pricing:showAllInfoIcon')} className="self-center pr-2">
              <InfoCircleOutlined className="ant-info-tooltip" />
            </AntTooltip>

            <span className="pr-2">
              <Switch
                checked={showAllPriceSheets}
                onChange={() => setShowAllPriceSheets(!showAllPriceSheets)}
                size="small"
              />
            </span>
            <Button size="small" type="primary" onClick={() => handleOpenPriceSheetDrawer()}>
              {t('settings:priceSheets:newPriceSheet')}
            </Button>
          </div>
        </div>
      </div>
      <div className="h-full overflow-hidden bg-white">
        <PriceSheetsSettingsPage priceSheets={visiblePriceSheets()} />
      </div>
      <PriceSheetDrawer
        visible={newPriceSheet}
        canManagePriceSheet={canManagePriceSheets}
        onClose={() => setNewPriceSheet(false)}
      />
    </div>
  );
};

export default SettingsPriceSheetsContainer;
