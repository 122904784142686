import { combineReducers } from 'redux';
import application from './ApplicationReducer';
import attributes, { AttributesState } from './AttributesReducer';
import customFields from './ItemCustomFieldsReducer';
import description, { DescriptionState } from './DescriptionReducer';
import digitalAssets from './DigitalAssetsReducer';
import extendedInfo, { ExtendedInfoState } from './ExtendedInfoReducer';
import interchange from './InterchangeReducer';
import item, { ItemState } from './ItemReducer';
import analyses, { AnalysesState } from './AnalysesReducer';
import kit from './KitReducer';
import packageSeg, { PackageState } from './PackageReducer';
import pricing from './PricingReducer';
import xmlPreview from './XmlPreviewReducer';
import shopPreview from './ShopPreviewReducer';
import ebay from './EbayReducer';
import dashboard from './ItemDashboardReducer';

export type ItemsState = {
  analyses: AnalysesState;
  application: any;
  attributes: AttributesState;
  customFields: any;
  description: DescriptionState;
  digitalAssets: any;
  extendedInfo: ExtendedInfoState;
  interchange: any;
  item: ItemState;
  kit: any;
  packageSeg: PackageState; // package reserved word
  pricing: any;
  xmlPreview: any;
  shopPreview: any;
  ebay: any;
  dashboard: any;
};

const CatalogueReducer = combineReducers<ItemsState>({
  analyses,
  application,
  attributes,
  customFields,
  description,
  digitalAssets,
  extendedInfo,
  interchange,
  item,
  kit,
  packageSeg, // package reserved word
  pricing,
  xmlPreview,
  shopPreview,
  ebay,
  dashboard,
});

export default CatalogueReducer;
