export const splitQualifierText = (text: string) => {
  const parts = [];
  let qualifierText = text;
  const re = /<p\d type="\D*?"\/>/g; // regex to match parameter like <p1 type="num"/>

  if (re.test(qualifierText)) {
    while (qualifierText.length > 0) {
      re.lastIndex = 0; // reset start index
      const result = re.exec(qualifierText);
      if (result) {
        if (result.index > 0) parts.push(qualifierText.slice(0, result.index).trim()); // text
        parts.push(result[0]); // parameter
        qualifierText = qualifierText.slice(re.lastIndex).trim(); // cut string till end of first parameter
      } else {
        parts.push(qualifierText);
        qualifierText = '';
      }
    }
    return parts;
  }
};
