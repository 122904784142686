import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { UpdateItemPrice } from '../../../../types/price_sheet';

export function updateItemPrices(prices: UpdateItemPrice[]) {
  return {
    type: 'UPDATE_ITEM_PRICES',
    payload: axios.patch(`${getApiRoot()}/items/prices`, {
      prices,
    }),
  };
}
