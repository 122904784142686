import React from 'react';
import { CloseOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import className from 'classnames';
import constants from '../../../../constants/DigitalAssetsConstants.json';
import translations from '../../../../constants/DigitalAssetsTranslation.json';
import DigitalAssetTypePreview from './DigitalAssetTypePreview';
import { isUploadUrlValid } from '../../../../utils/Utils';
import AntTooltip from '../../../global/AntTooltip';

class DigitalAssetSinglePreview extends React.Component {
  getTypeName = typeId => {
    const fileType = this.props.digitalAssetResources.types.find(
      type => type.id === Number(typeId)
    );
    return fileType ? fileType.name : '';
  };

  handleBoxClick = assetPreviewable => {
    const { selectedDigitalAssetId, digitalAsset } = this.props;
    if (selectedDigitalAssetId !== digitalAsset.id) {
      this.props.setSelectedDigitalAsset(digitalAsset.id);
    } else if (assetPreviewable) {
      this.props.showPreview(digitalAsset);
    }
  };

  handleDelete = e => {
    e.stopPropagation();
    this.props.deleteAsset(this.props.digitalAsset.id);
  };

  showTag = (names, alertTypeId, refId) => {
    const cssClasses = className(
      'extended-info__analysis-message',
      'digital-asset-box__analysis-tag',
      { blue: alertTypeId === 1 },
      { yellow: alertTypeId === 2 },
      { red: alertTypeId === 3 }
    );
    return (
      <React.Fragment>
        <span data-tip data-for={`DA-${refId}-${alertTypeId}`} />
        <AntTooltip
          id={`DA-${refId}-${alertTypeId}`}
          title={names.map(name => (
            <span key={name}>
              {name}
              <br />
            </span>
          ))}
        >
          <WarningOutlined className={cssClasses} />
        </AntTooltip>
      </React.Fragment>
    );
  };

  showAnalysis = digitalAsset => {
    const analyses = this.props.analyses.filter(analysis => {
      if (analysis.reference_table === 'parent_digital_assets') {
        return analysis.reference_id === digitalAsset.digital_asset_id;
      }
      if (analysis.reference_table === 'item_digital_assets') {
        return analysis.reference_id === digitalAsset.id;
      }
    });

    if (analyses.length) {
      const alertTypeOne = analyses.filter(analysis => analysis.alert_type_id === 1);
      const alertTypeTwo = analyses.filter(analysis => analysis.alert_type_id === 2);
      const alertTypeThree = analyses.filter(analysis => analysis.alert_type_id === 3);

      const alertTypeOneNames = alertTypeOne.map(
        analysis => analysis.dynamic_description || analysis.name
      );
      const alertTypeTwoNames = alertTypeTwo.map(
        analysis => analysis.dynamic_description || analysis.name
      );
      const alertTypeThreeNames = alertTypeThree.map(
        analysis => analysis.dynamic_description || analysis.name
      );

      return (
        <React.Fragment>
          {alertTypeOneNames.length
            ? this.showTag(alertTypeOneNames, 1, alertTypeOne[0].reference_id)
            : null}
          {alertTypeTwoNames.length
            ? this.showTag(alertTypeTwoNames, 2, alertTypeTwo[0].reference_id)
            : null}
          {alertTypeThreeNames.length
            ? this.showTag(alertTypeThreeNames, 3, alertTypeThree[0].reference_id)
            : null}
        </React.Fragment>
      );
    }
  };

  render() {
    const { digitalAsset, selectedDigitalAssetId, digitalAssetResources, analyses } = this.props;
    const { uri, file_type_id: fileTypeId } = digitalAsset;
    const isSelected = digitalAsset.id === selectedDigitalAssetId;
    const isPDF = fileTypeId === constants.pdfTypeId;
    const isVideo = constants.videoTypeIds.includes(fileTypeId);
    const hasSecurePDFUrl = isPDF && uri ? isUploadUrlValid(uri, true) : true;
    const possibleFileTypePreview = [constants.pdfTypeId, ...constants.imgTypeIds];
    const assetPreviewable =
      possibleFileTypePreview.includes(digitalAsset.file_type_id) && hasSecurePDFUrl;
    const digitalAssetBoxCss = className({
      'digital-asset-box': !isSelected && hasSecurePDFUrl,
      'digital-asset-box--selected': isSelected && hasSecurePDFUrl,
      'digital-asset-box--invalid': !hasSecurePDFUrl,
    });
    const digitalAssetBoxTopCss = className('digital-asset-box__top', {
      preview: assetPreviewable,
    });
    return (
      <div
        className={digitalAssetBoxCss}
        onClick={() => this.handleBoxClick(assetPreviewable || isVideo)}
      >
        {isSelected && (
          <CloseOutlined className="digital-asset-box__delete" onClick={this.handleDelete} />
        )}
        <span className={digitalAssetBoxTopCss}>
          <DigitalAssetTypePreview
            asset={digitalAsset}
            digitalAssetResources={digitalAssetResources}
          />
        </span>
        <span className="digital-asset-box__bottom">
          <span>{this.getTypeName(digitalAsset.type_id)}</span>
          <span className="digital-asset-box__bottom__icons">
            <span>{analyses && this.showAnalysis(digitalAsset)}</span>
            {!hasSecurePDFUrl && (
              <span>
                <AntTooltip title={translations.preview.insecurePreview}>
                  <ExclamationCircleOutlined className="info-icon" />
                </AntTooltip>
              </span>
            )}
          </span>
        </span>
      </div>
    );
  }
}

export default DigitalAssetSinglePreview;
