import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { DescriptionContent } from '../../../../types/description';

export function updateDescription(
  id: number,
  typeId: number,
  autoGenerate: number,
  languageId: number
) {
  const params = utils.createURL([
    { name: 'type_id', values: typeId },
    { name: 'auto_generate', values: autoGenerate },
    { name: 'language_id', values: languageId },
  ]);
  return {
    type: 'UPDATE_DESCRIPTION',
    meta: {
      descriptionId: id,
      typeId,
      autoGenerate,
      languageId,
    },
    payload: axios.patch(`${getNonGwApiRoot()}/items/descriptions/${id}${params}`),
  };
}

export function updateDescriptionContent(contents: DescriptionContent[], id: number) {
  return {
    type: 'UPDATE_DESCRIPTION_CONTENTS',
    meta: { contents, descriptionId: id },
    payload: axios.patch(`${getNonGwApiRoot()}/items/descriptions/${id}`, { contents }),
  };
}
