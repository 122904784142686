import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import APIRoutes from '../../routes/distribution/APIRoutes';
import { hasPermission } from '../../utils/Permissions';
import { ApplicationState } from '../../reducers';

const { Content, Sider } = Layout;

const APISettingsMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { user } = useSelector((state: ApplicationState) => ({
    user: state.user.user,
  }));

  const hasScriptAccess = hasPermission(user, 'has_script_access');

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu mode="inline" defaultSelectedKeys={['1']} selectedKeys={[location.pathname]}>
          <Menu.Item key="/distribution/api/api-settings">
            <Link to={{ pathname: `api-settings` }}>{t('api:settingLogs')}</Link>
          </Menu.Item>
          {hasScriptAccess && (
            <React.Fragment>
              <Menu.Item key="/distribution/api/automation">
                <Link to={{ pathname: `automation` }}>{t('api:automation')}</Link>
              </Menu.Item>
              <Menu.Item key="/distribution/api/automation-logs">
                <Link to={{ pathname: `automation-logs` }}>{t('api:automationLogs')}</Link>
              </Menu.Item>
            </React.Fragment>
          )}
        </Menu>
      </Sider>
      <Content className="main-page-content">
        <APIRoutes />
      </Content>
    </Layout>
  );
};

export default APISettingsMenu;
