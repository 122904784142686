import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UpgradePlan: React.FC = () => {
  const { t } = useTranslation();

  const showInfo = () => {
    Modal.info({
      title: t('common:contactForUpgrade'),

      onOk() {},
    });
  };

  return <Button onClick={() => showInfo()}>{t('welcome:upgradePlan')}</Button>;
};

export default UpgradePlan;
