export function setBrandInitializeStatus(status) {
  return {
    type: 'SET_BRAND_INITIALIZE_STATUS',
    payload: { status },
  };
}

export function setBrandFilterInitializeStatus(status) {
  return {
    type: 'SET_BRAND_FILTER_INITIALIZE_STATUS',
    payload: { status },
  };
}

export function selectBrand(id) {
  return {
    type: 'SELECT_BRAND',
    payload: { id },
  };
}
