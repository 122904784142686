import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';
import { BundleItem, AppOrder } from '../../../types/application';

const getApplicationOrder = (state: ApplicationState) => state.items.application.applicationOrder;
const getBundles = (state: ApplicationState) => state.items.application.applicationBundleItems;

export const bundleItemsApplicationIndexed = createSelector(
  [getApplicationOrder, getBundles],
  (applicationOrder, bundles) => {
    return bundles.map((bItem: BundleItem) => {
      const applicationIds = bItem.application_ids.map(
        (id: number) =>
          applicationOrder.findIndex((a: AppOrder) => a.item_application_id === id) + 1
      );
      return { ...bItem, application_ids: applicationIds };
    });
  }
);
