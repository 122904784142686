import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function createKitItem(values: any, recordNumber: number) {
  const paramList = Object.keys(values)
    .filter(key => !(key === 'description'))
    .map(key => ({ name: key, values: values[key] }));

  paramList.push({ name: 'record_number', values: [recordNumber] });

  const params = utils.createURL(paramList);

  return {
    type: 'CREATE_KIT_ITEM',
    payload: axios.post(`${getApiRoot()}/items/kits${params}`, {
      description: values.description,
    }),
  };
}
