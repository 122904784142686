import { Reducer } from 'redux';
import { BugsnagAction } from '../../actions/tools/BugsnagActions';

export type BugsnagState = {
  clientStarted: boolean;
};

const initialState = { clientStarted: false };

const BugsnagReducer: Reducer<BugsnagState> = (state: BugsnagState = initialState, action) => {
  switch (action.type) {
    case BugsnagAction.CREATE_CLIENT:
      return { ...state, clientStarted: true };
    default:
      return state;
  }
};

export default BugsnagReducer;
