import { Empty, Spin, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Table, Column, InfiniteLoader } from 'react-virtualized';
import JobStatus from '../../global/JobStatus';
import JobInfo from '../../global/JobInfo';
import { dateToString } from '../parentSettings/importLogs/ImportExportUtils';
import { ScriptLog } from '../../../../types/distribution';
import { getPageLimit } from '../../../utils/Utils';

const ROW_HEIGHT = 40;

type AutomationLogsTableProps = {
  logs: ScriptLog[];
  fetchingLogs: boolean;
  columns: { dataKey: string; label: string; visible: boolean }[];
  handleDisplayError: (logId: number) => void;
  handleTableScroll: ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<any>;
};

const AutomationLogsTable: React.FC<AutomationLogsTableProps> = ({
  logs,
  fetchingLogs,
  columns,
  handleDisplayError,
  handleTableScroll,
}) => {
  const { t } = useTranslation();

  const pageSize = useMemo(getPageLimit, []);

  const getRow = ({ index }: any) => {
    return logs[index];
  };

  const getColumn = (col: { dataKey: string; label: string; visible: boolean }) => {
    if (col.dataKey === 'fileTypeName')
      return <Column key={col.dataKey} label={col.label} dataKey="fileTypeName" width={120} />;
    if (col.dataKey === 'script_name')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey={col.dataKey}
          width={150}
          flexGrow={1}
          cellRenderer={({ rowData }) => (
            <Tooltip title={rowData.script_name}>{rowData.script_name}</Tooltip>
          )}
        />
      );
    if (col.dataKey === 'started_at')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey={col.dataKey}
          width={180}
          cellRenderer={({ rowData }) => dateToString(rowData.started_at)}
        />
      );
    if (col.dataKey === 'completed_at')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey={col.dataKey}
          width={180}
          cellRenderer={({ rowData }) => dateToString(rowData.completed_at)}
        />
      );
    if (col.dataKey === 'status')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey="status"
          cellRenderer={({ rowData }) => (
            <JobStatus statusId={rowData.status_id} status={rowData.status} />
          )}
          width={140}
          flexShrink={0}
        />
      );
    if (col.dataKey === 'action')
      return (
        <Column
          key={col.dataKey}
          label={col.label}
          dataKey="action"
          className="text-right"
          cellRenderer={({ rowData }) => {
            return (
              <JobInfo
                displayInfo={!!rowData.error_message}
                statusId={rowData.status_id}
                handleClick={() => handleDisplayError(rowData.id)}
              />
            );
          }}
          width={45}
        />
      );
  };

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <InfiniteLoader
            isRowLoaded={({ index }) => !!logs[index]}
            loadMoreRows={handleTableScroll}
            rowCount={logs.length + pageSize}
            minimumBatchSize={pageSize}
          >
            {({ onRowsRendered, registerChild }) => (
              <React.Fragment>
                <Table
                  width={width - 2}
                  height={height - 1}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  headerHeight={ROW_HEIGHT}
                  rowHeight={ROW_HEIGHT}
                  rowCount={logs.length}
                  rowGetter={getRow}
                  noRowsRenderer={() =>
                    fetchingLogs ? (
                      <Spin className="spinner-center" style={{ marginTop: '20px' }} />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        className="translation-import-export"
                        description={t('api:noAutomationLogs')}
                      />
                    )
                  }
                >
                  {columns.map(col => (col.visible ? getColumn(col) : null))}
                </Table>

                <Spin
                  spinning={fetchingLogs && logs.length > 0}
                  style={{
                    left: width / 2,
                    position: 'absolute',
                    bottom: '20px',
                  }}
                />
              </React.Fragment>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default AutomationLogsTable;
