import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, Tag } from 'antd';
import {
  fetchLinkingItems,
  fetchApplicationChildItems,
} from '../../../actions/items/application/fetch';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import constants from '../../../constants/ApplicationTranslation.json';
import { Item } from '../../../../types/item';

type ApplicationLinkedChildProps = {
  selectedItemInCatalogueId: number;
  applicationChildItems: Item[];
  brandId: number;
  dispatch: AsyncDispatch;
};

const ApplicationLinkedChilds: React.FC<ApplicationLinkedChildProps> = props => {
  const loadChildItemsByIds = (itemIds: number[]) => {
    if (itemIds.length) {
      props.dispatch(fetchApplicationChildItems({ brandId: props.brandId, itemIds }));
    }
  };

  React.useEffect(() => {
    if (props.selectedItemInCatalogueId) {
      props
        .dispatch(fetchLinkingItems(props.selectedItemInCatalogueId))
        .then(({ value: { data: childItemIds } }) => {
          if (childItemIds.length) loadChildItemsByIds(childItemIds);
        });
    }
  }, [props.selectedItemInCatalogueId]);

  return (
    <div className="application__links">
      {props.applicationChildItems.length > 0 && (
        <Alert
          message={constants.childLinks}
          description={
            <div>
              {props.applicationChildItems.map(item => (
                <Tag key={item.id}>
                  {`${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`}
                </Tag>
              ))}
            </div>
          }
          type="info"
          showIcon
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    applicationChildItems: state.items.application.applicationChildItems,
    brandId: state.parent.brands.selectedBrandId,
    selectedItemInCatalogueId: state.catalogue.catalogue.selectedItemIds[0],
  };
};

export { ApplicationLinkedChilds };

export default connect(mapStateToProps)(withErrorBoundary(ApplicationLinkedChilds));
