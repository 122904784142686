import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Progress, Alert, Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../reducers';
import {
  articles as helpCenterArticles,
  url as helpCenterUrl,
} from '../../../constants/HelpCenterConstants.json';
import ApiSettingsTable from './ApiSettingsTable';

const ApiSettingsPage: React.FC = () => {
  const { t } = useTranslation();

  const { apiSettings } = useSelector((state: ApplicationState) => {
    return { apiSettings: state.distribution.apiSettings };
  });

  const getProgressBarHeader = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-gray-900">{t('api:skus')}</div>
        <div className="info-text">
          {`${t('api:requestLimit')} - ${t('api:total')}: ${apiSettings.account_item_count * 2} ${t(
            'api:skus'
          )} `}
          <Tooltip title={`${t('api:infoText')} ${apiSettings.account_item_count * 2}`}>
            <InfoCircleOutlined className="ant-info-tooltip" />
          </Tooltip>
        </div>
      </div>
    );
  };

  const getTooltipText = () => {
    return (
      <React.Fragment>
        <div>
          {`${t('api:requestLimit')} - ${t('api:used')}: ${apiSettings?.requested_item_count} ${t(
            'api:skus'
          )} `}
        </div>
        <div>
          {`${t('api:requestLimit')} - ${t('api:remaining')}: ${
            apiSettings.account_item_count * 2 - apiSettings.requested_item_count
          } ${t('api:skus')} `}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="flex content">
        <div className="flex-1">
          {getProgressBarHeader()}
          <Tooltip title={getTooltipText()}>
            <Progress
              percent={Math.ceil(
                (apiSettings.requested_item_count / (apiSettings.account_item_count * 2)) * 100
              )}
              status="active"
            />
          </Tooltip>
        </div>
        <div className="ml-2">
          <Alert
            message={
              <Button
                onClick={e => {
                  e.stopPropagation();
                  const helpPagePath = helpCenterArticles.api_settings_documentation;
                  // @ts-ignore
                  const wnd = window.open(helpCenterUrl + helpPagePath, '_blank', {
                    rel: 'noopener noreferrer',
                  });
                  wnd!.opener = null;
                }}
                htmlType="button"
                size="small"
              >
                {t('api:message')}
              </Button>
            }
            type="info"
          />
        </div>
      </div>
      <div className="h-full bg-white px-3 pt-2">
        <ApiSettingsTable />
      </div>
    </React.Fragment>
  );
};

export default ApiSettingsPage;
