import { Col, Row, Select, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Package } from '../../../../types/itemPackage';
import { ExtendedResources, StandardResource } from '../../../../types/resources';
import { ApplicationState } from '../../../reducers';
import PackageDrawerDimensions from './PackageDrawerDimensions';
import PackageDrawerInput from './PackageDrawerInput';
import PackageDrawerInputSelect from './PackageDrawerInputSelect';
import PackageDrawerReplaceButton from './PackageDrawerReplaceButton';

type PackageDrawerFieldProps = {
  title: string;
  uoms: ExtendedResources[];
  disabled?: boolean;
  displayOk?: boolean;
  uomId?: number;
  uomName?: string;
  values?: Package;
  handleReplace?: (
    inputValue?: string,
    selectValue?: number,
    height?: string,
    length?: string
  ) => void;
  handleOnChange?: (value: any, name?: string) => void;
  handleOnBlur?: () => void;
  testId?: string;
  overWriteInfo?: boolean;
};

const PackageDrawerField: React.FC<PackageDrawerFieldProps> = ({
  title,
  uoms,
  disabled,
  displayOk,
  uomId,
  uomName,
  values,
  testId,
  handleReplace,
  handleOnChange,
  handleOnBlur,
  overWriteInfo,
}) => {
  const { t } = useTranslation();

  const { innerQtyUoms, dimensionUoms, weightUoms, oderableOptions } = useSelector(
    (state: ApplicationState) => ({
      innerQtyUoms: state.resources.data.global.measurement_uom_codes,
      dimensionUoms: state.resources.data.package.dimension_uoms as StandardResource[],
      weightUoms: state.resources.data.package.weight_uoms as StandardResource[],
      oderableOptions: state.resources.data.package.orderable_options as StandardResource[],
    })
  );

  const [inputValue, setInputValue] = React.useState<string>('');
  const [selectValue, setSelectValue] = React.useState<number | undefined>(undefined);
  const [dimWidth, setDimWidth] = React.useState<string>('');
  const [dimHeight, setDimHeight] = React.useState<string>('');
  const [dimLength, setDimLength] = React.useState<string>('');
  const [dimensionId, setDimensionId] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (title.includes(t('packageSeg:package.quantityEach'))) setSelectValue(uomId);
  }, [t, title, uomId]);

  const getDisable = () => {
    if (
      title.includes(`${t('packageSeg:package.shippingDimensions')}`) ||
      title.includes(`${t('packageSeg:package.merchandisingDimensions')}`)
    )
      return dimWidth.length < 1 && dimHeight.length < 1 && dimHeight.length < 1 && !dimensionId;
    if (
      title.includes(t('packageSeg:package.innerQuantity')) ||
      title.includes(t('packageSeg:package.weight_variance')) ||
      title === t('packageSeg:package.weight')
    )
      return inputValue.length < 1 && !selectValue;
    if (title.includes(t('packageSeg:package.orderable'))) return !selectValue;
    return inputValue.length < 1;
  };

  const infoTextTitles: { [key: string]: string } = {
    uomName: uomName || '',
    createPkg: `"${t('packageSeg:createPkg')}"`,
  };

  const infoText = overWriteInfo
    ? t('packageSeg:overwriteAllInfoText', { infoTextTitles })
    : t('packageSeg:noPkgText', { infoTextTitles });

  const renderQty = () => (
    <PackageDrawerInputSelect
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      selectDisable
      inputValue={displayOk ? inputValue : values?.quantity_of_eaches || ''}
      handleInputChange={e => {
        const value = e.target.value;
        const reg = /^[0-9]+$|^$/;
        if (reg.test(value)) {
          if (!displayOk) handleOnChange!(Number(value), `quantity_of_eaches`);
          else setInputValue(value);
        }
      }}
      testId={testId}
      selectValue={selectValue || values?.uom_id}
      handleSelectChange={value => {
        if (!displayOk) handleOnChange!(value, `uom_id`);
        else setSelectValue(value);
      }}
      handleOnBlur={handleOnBlur}
      options={uoms}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
    />
  );

  const renderInnerQty = () => (
    <PackageDrawerInputSelect
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      inputValue={displayOk ? inputValue : values?.inner_quantity || ''}
      handleInputChange={e => {
        const value = e.target.value;
        const reg = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (reg.test(value) || value.length < 1) {
          if (!displayOk) handleOnChange!(value, `inner_quantity`);
          else setInputValue(value);
        }
      }}
      testId={testId}
      selectValue={selectValue || values?.inner_quantity_uom_id || undefined}
      handleSelectChange={value => {
        if (!displayOk) handleOnChange!(value, `inner_quantity_uom_id`);
        else setSelectValue(value);
      }}
      options={innerQtyUoms}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
    />
  );

  const renderDimensions = () => (
    <PackageDrawerDimensions
      disabled={disabled}
      infoText={disabled ? infoText : ''}
      heightInputValue={displayOk ? dimHeight : values?.height || ''}
      lengthInputValue={displayOk ? dimLength : values?.length || ''}
      widthInputValue={displayOk ? dimWidth : values?.width || ''}
      handleInputChange={(value, dim) => {
        const reg = /^[0-9]+(.[0-9]*)?$/;
        if (reg.test(value) || value.length < 1) {
          if (dim === 'height') {
            if (!displayOk) handleOnChange!(value, `height`);
            else setDimHeight(value);
          }
          if (dim === 'width') {
            if (!displayOk) handleOnChange!(value, `width`);
            else setDimWidth(value);
          }
          if (dim === 'length') {
            if (!displayOk) handleOnChange!(value, `length`);
            else setDimLength(value);
          }
        }
      }}
      selectValue={dimensionId || values?.dimensions_uom_id || undefined}
      handleSelectChange={value => {
        if (!displayOk) {
          handleOnChange!(value, `dimensions_uom_id`);
          handleOnChange!(value, `weights_uom_id`);
        } else setDimensionId(value);
      }}
      options={dimensionUoms}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(dimWidth, dimensionId, dimHeight, dimLength)}
      disableReplaceAll={getDisable() || disabled}
    />
  );

  const renderWeight = () => (
    <PackageDrawerInputSelect
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      inputValue={displayOk ? inputValue : values?.weight || ''}
      handleInputChange={e => {
        const value = e.target.value;
        const reg = /^[0-9]+(.[0-9]*)?$/;
        if (reg.test(value) || value.length < 1) {
          if (!displayOk) handleOnChange!(value, `weight`);
          else setInputValue(value);
        }
      }}
      testId={testId}
      selectValue={selectValue || values?.weights_uom_id || undefined}
      handleSelectChange={value => {
        if (!displayOk) {
          handleOnChange!(value, `weights_uom_id`);
          handleOnChange!(value, `dimensions_uom_id`);
        } else setSelectValue(value);
      }}
      options={weightUoms}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
    />
  );

  const renderVariance = () => (
    <PackageDrawerInput
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
      inputValue={displayOk ? inputValue : values?.weight_variance || ''}
      handleChange={(value: any) => {
        const reg = /^[0-9]+(.[0-9]*)?$/;
        if (reg.test(value) || value.length < 1) {
          if (!displayOk) handleOnChange!(value, `weight_variance`);
          else setInputValue(value);
        }
      }}
    />
  );

  const renderMerchandising = () => (
    <PackageDrawerDimensions
      disabled={disabled}
      infoText={disabled ? infoText : ''}
      heightInputValue={displayOk ? dimHeight : values?.merchandising_height || ''}
      lengthInputValue={displayOk ? dimLength : values?.merchandising_length || ''}
      widthInputValue={displayOk ? dimWidth : values?.merchandising_width || ''}
      handleInputChange={(value, dim) => {
        const reg = /^[0-9]+(.[0-9]*)?$/;
        if (reg.test(value) || value.length < 1) {
          if (dim === 'height') {
            if (!displayOk) handleOnChange!(value, `merchandising_height`);
            else setDimHeight(value);
          }
          if (dim === 'width') {
            if (!displayOk) handleOnChange!(value, `merchandising_width`);
            else setDimWidth(value);
          }
          if (dim === 'length') {
            if (!displayOk) handleOnChange!(value, `merchandising_length`);
            else setDimLength(value);
          }
        }
      }}
      selectValue={dimensionId || values?.dimensions_uom_id || undefined}
      handleSelectChange={value => {
        if (!displayOk) {
          handleOnChange!(value, `dimensions_uom_id`);
          handleOnChange!(value, `weights_uom_id`);
        } else setDimensionId(value);
      }}
      options={dimensionUoms}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(dimWidth, dimensionId, dimHeight, dimLength)}
      disableReplaceAll={getDisable() || disabled}
    />
  );

  const renderOrderable = () => (
    <Tooltip title={disabled ? infoText : ''}>
      <Row>
        <Col flex="auto">
          <Select
            size="small"
            disabled={disabled}
            allowClear
            value={selectValue || values?.orderable_option_id || undefined}
            showSearch
            onChange={value => {
              if (!displayOk) {
                handleOnChange!(value, `orderable_option_id`);
              } else setSelectValue(value);
            }}
            style={{ width: '100%' }}
          >
            {oderableOptions.map(opt => (
              <Select.Option key={opt.id} value={opt.id}>
                {opt.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        {displayOk && (
          <Col>
            <PackageDrawerReplaceButton
              handleReplace={() => handleReplace!(inputValue, selectValue)}
              disableReplaceAll={getDisable() || disabled}
            />
          </Col>
        )}
      </Row>
    </Tooltip>
  );

  const renderStacking = () => (
    <PackageDrawerInput
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
      inputValue={displayOk ? inputValue : values?.stacking_factor || ''}
      handleChange={(value: any) => {
        const reg = /^[0-9]+(.[0-9]*)?$/;
        if (reg.test(value) || value.length < 1) {
          if (!displayOk) handleOnChange!(value, `stacking_factor`);
          else setInputValue(value);
        }
      }}
    />
  );

  const renderGtin = () => (
    <PackageDrawerInput
      maxLength={14}
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
      inputValue={displayOk ? inputValue : values?.level_gtin || null}
      handleChange={(value: any) => {
        const reg = /^[0-9]+$|^$/;
        if (reg.test(value)) {
          if (!displayOk) handleOnChange!(value, `level_gtin`);
          else setInputValue(value);
        }
      }}
    />
  );

  const renderProductCode = () => (
    <PackageDrawerInput
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
      inputValue={displayOk ? inputValue : values?.electronic_product_code || undefined}
      handleChange={(value: any) => {
        if (!displayOk) handleOnChange!(value, `electronic_product_code`);
        else setInputValue(value);
      }}
    />
  );

  const renderBarChar = () => (
    <PackageDrawerInput
      infoText={disabled ? infoText : ''}
      disabled={disabled}
      displayOk={displayOk}
      handleReplaceAll={() => handleReplace!(inputValue, selectValue)}
      disableReplaceAll={getDisable() || disabled}
      inputValue={displayOk ? inputValue : values?.bar_code_characters || undefined}
      handleChange={(value: any) => {
        if (!displayOk) handleOnChange!(value, `bar_code_characters`);
        else setInputValue(value);
      }}
    />
  );

  return (
    <React.Fragment>
      {title.includes(t('packageSeg:package.quantityEach')) && renderQty()}
      {title.includes(t('packageSeg:package.innerQuantity')) && renderInnerQty()}
      {title.includes(`${t('packageSeg:package.shippingDimensions')}`) && renderDimensions()}
      {title === t('packageSeg:package.weight') && renderWeight()}
      {title.includes(`${t('packageSeg:package.weightVariance')}`) && renderVariance()}
      {title.includes(`${t('packageSeg:package.merchandisingDimensions')}`) &&
        renderMerchandising()}
      {title.includes(t('packageSeg:package.orderable')) && renderOrderable()}
      {title.includes(t('packageSeg:package.stackingFactor')) && renderStacking()}
      {title.includes(t('packageSeg:package.levelGtin')) && renderGtin()}
      {title.includes(t('packageSeg:package.electronicProductCode')) && renderProductCode()}
      {title.includes(t('packageSeg:package.barcodeChar')) && renderBarChar()}
    </React.Fragment>
  );
};

export default PackageDrawerField;
