import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBundlePositions } from '../../../actions/items/application/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { RankedPositions } from '../../../../types/application';
import ApplicationPosition from './ApplicationPosition';

type BundlePositionSelectProps = {
  positionId?: number;
  className?: string;
  onChange: (id: number | null) => void;
  categoryId?: number;
};

const BundlePositionSelect: React.FC<BundlePositionSelectProps> = props => {
  const { positionId, className, onChange, categoryId } = props;

  const dispatch: AsyncDispatch = useDispatch();

  const { positionResources, childBundlePositions } = useSelector((state: ApplicationState) => {
    return {
      positionResources: state.resources.data.vehicle.positions,
      childBundlePositions: state.items.application.childBundlePositions,
    };
  });

  React.useEffect(() => {
    if (categoryId) dispatch(fetchBundlePositions(categoryId));
  }, [categoryId, dispatch]);

  const [childBundleOptions, setChildBundleOptions] = React.useState<RankedPositions[]>([]);

  React.useEffect(() => {
    if (categoryId && childBundlePositions[categoryId])
      setChildBundleOptions(childBundlePositions[categoryId]);
  }, [categoryId, childBundlePositions]);

  return (
    <ApplicationPosition
      className={`application__bundle-position ${className}`}
      value={positionId}
      rankedPositions={childBundleOptions}
      positionResources={positionResources}
      onChange={(id: number | null) => {
        onChange(id);
      }}
      size="small"
    />
  );
};

export default BundlePositionSelect;
