import * as createActions from './create';
import * as deleteActions from './delete';
import * as fetchActions from './fetch';
import * as updateActions from './update';

export default {
  ...createActions,
  ...fetchActions,
  ...updateActions,
  ...deleteActions,
};
