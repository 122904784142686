import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';
import { FormikValues, FormikHelpers } from 'formik';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Form, Tooltip, Modal, Row, Col, Button, Popconfirm, message } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { updateKitItem } from '../../../actions/items/kit/update';
import {
  fetchItemKits,
  fetchKitSubBrand,
  fetchKitShopPreview,
} from '../../../actions/items/kit/fetch';
import { createKitItem } from '../../../actions/items/kit/create';
import { deleteKitItem } from '../../../actions/items/kit/delete';
import saveTranslation from '../../../constants/SaveTranslation.json';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import FormTextArea from '../../global/Forms/FormTextArea';
import FormRadio from '../../global/Forms/FormRadio';
import VSelect from '../../global/VSelect';
import PartType from '../../global/PartType';
import AntTooltip from '../../global/AntTooltip';
import { KitType } from '../../../../types/kit';
import { ShopPreviewType } from '../../../../types/shop_preview';
import { DefaultValue } from '../../../../types/brand_settings';
import { LONG_DESCRIPTION_TYPE_ID } from '../../../constants/DescriptionConstants';
import { intercomEvent } from '../../../utils/IntercomUtils';

type KitDrawerProps = {
  onClose: () => void;
  kit?: KitType;
  showDelete?: boolean;
};

const KitDrawer: React.FC<KitDrawerProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedItem,
    allBrands,
    listUomIds,
    brandId,
    listQualifiers,
    listDescriptionTypes,
    listLanguages,
    listSubBrands,
    defaultValues,
    kits,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      brandId: state.parent.brands.selectedBrandId,
      allBrands: state.resources.data.global.parent_owner_brands.map(brand => ({
        ...brand,
        name: `${brand.code} | ${brand.name}`,
      })),
      listUomIds: state.resources.data.global.measurement_uom_codes,
      listQualifiers: state.resources.data.kit.qualifiers,
      listDescriptionTypes: state.resources.data.kit.description_types,
      listLanguages: state.resources.data.global.languages,
      listSubBrands: state.items.kit.subBrands,
      kits: state.items.kit.kits,
      defaultValues: state.settings.defaultValues,
    };
  });

  React.useEffect(() => {
    dispatch(fetchKitSubBrand(brandId));
  }, [dispatch, brandId]);

  React.useEffect(() => {
    if (props.kit && props.kit.brand_id)
      dispatch(fetchKitSubBrand(props.kit.brand_id, props.kit.id));
  }, [dispatch, props.kit]);

  const defaultLanguage = defaultValues.find(
    (defValue: DefaultValue) => defValue.resource_table === 'languages'
  );

  const showPartTypeId = defaultValues.find((d: DefaultValue) => d.id === 11)?.value === '1';
  // brands which are not in parent_owner_brands resources should not be preselected
  const isOriginalBrand = useMemo(
    () => !!allBrands.find(b => b.id === brandId),
    [allBrands, brandId]
  );

  const validationSchema = () => {
    return Yup.object().shape({
      quantity_in_kit: Yup.number()
        .nullable()
        .test('uom', t('validation:required'), function test() {
          return !!this!.parent.quantity_in_kit_uom_id;
        })
        .required(t('validation:required')),
      description: Yup.string().required(t('validation:required')),
      description_type_id: Yup.number().nullable().required(t('validation:required')),
    });
  };

  const [partTypeDrawerVisible, setPartTypeDrawerVisible] = React.useState(false);
  const [qtyDefaultValue, setQtyDefaultValue] = React.useState(false);
  const [partTypeDefaultValue, setPartTypeDefaultValue] = React.useState(false);
  const [descDefaultValue, setDescDefaultValue] = React.useState(false);
  const [firstEnteredPartNumber, setFirstEnteredPartNumber] = React.useState('');

  const listSoldSeparately = [
    { id: 0, name: `${t('kit:no')} ` },
    { id: 1, name: `${t('kit:yes')}` },
    { id: 2, name: `${t('kit:none')}` },
  ];

  const handleSubmit = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'kit',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    const { setSubmitting } = formikActions;
    const changedValues: FormikValues = {
      ...values,
      sold_separately: values.sold_separately === 2 ? null : values.sold_separately,
    };
    setSubmitting(true);

    if (props.kit) {
      return dispatch(updateKitItem(changedValues)).then(() => {
        setSubmitting(false);
        dispatch(fetchItemKits(selectedItem.id));
        props.onClose();
      });
    }
    const recordNumber = kits.length + 1;
    return dispatch(createKitItem(changedValues, recordNumber))
      .then(() => {
        dispatch(fetchItemKits(selectedItem.id));
        setSubmitting(false);
        props.onClose();
      })
      .catch(error => {
        setSubmitting(false);
        if (error.response.status === 422) message.error(error.response.data.message);
        props.onClose();
      });
  };

  const handleDelete = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_kit',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    if (props.kit) {
      Modal.confirm({
        title: t('kit:deleteKit'),
        okText: saveTranslation.delete,
        okButtonProps: { danger: true },
        onOk() {
          return dispatch(deleteKitItem(props.kit!.id)).then(() => props.onClose());
        },
      });
    }
  };

  return (
    <DrawerFormik
      title={t('kit:kit')}
      visible
      onClose={() => {
        dispatch(fetchItemKits(selectedItem.id));
        props.onClose();
      }}
      onDelete={props.showDelete ? () => handleDelete() : undefined}
      initialValues={{
        item_kit_id: props.kit?.id || undefined,
        item_id: selectedItem.id,
        quantity_in_kit:
          props.kit?.quantity_in_kit ||
          (props.kit?.quantity_in_kit === 0 && props.kit.quantity_in_kit.toString()) ||
          '',
        quantity_in_kit_uom_id: props.kit?.quantity_in_kit_uom_id || null,
        part_number: props.kit?.part_number || '',
        external_brand_id:
          props.kit?.brand_id || (!props.kit && isOriginalBrand && brandId) || null,
        external_sub_brand_id: props.kit?.sub_brand_id || null,
        category_id: props.kit?.category_id || null,
        part_number_qualifier_id: props.kit?.part_number_qualifier_id || null,
        sold_separately:
          props.kit?.sold_separately || props.kit?.sold_separately === 0
            ? props.kit?.sold_separately
            : 2,
        description: props.kit?.description || '',
        description_type_id: props.kit?.description_type_id || null,
        description_language_id:
          props.kit?.description_language_id ||
          (!props.kit && defaultLanguage && Number(defaultLanguage.value)) ||
          null,
        categoryName: props.kit?.category_name || '',
        subCategoryName: props.kit?.sub_category_name || '',
        partTypeName: props.kit?.part_type_name || '',
        partTypeId: props.kit?.part_type_id || null,
        record_number: props.kit?.record_number || null,
      }}
      validationSchema={validationSchema()}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ values, setFieldValue, errors, touched, handleChange }) => {
        const resetExistingDefaultTags = () => {
          setQtyDefaultValue(false);
          setDescDefaultValue(false);
          setPartTypeDefaultValue(false);
        };

        const handleExistingValuesDisplay = (shopPreview: ShopPreviewType) => {
          if (shopPreview) {
            const { description, description_type_id, description_language_id } = shopPreview;
            const enLanguageId = listLanguages.find(lang => lang.code === 'EN')?.id;

            setQtyDefaultValue(!!(shopPreview.quantity_size || shopPreview.quantity_size_uom_id));
            setPartTypeDefaultValue(!!shopPreview.category_id);
            setDescDefaultValue(!!(description || description_type_id || description_language_id));

            setFieldValue('quantity_in_kit_uom_id', shopPreview.quantity_size_uom_id);
            setFieldValue('description', shopPreview.description);
            setFieldValue(
              'description_type_id',
              shopPreview.description_type_id || LONG_DESCRIPTION_TYPE_ID
            );
            setFieldValue(
              'description_language_id',
              shopPreview.description_language_id || enLanguageId
            );
            setFieldValue('category_id', shopPreview.category_id);
            setFieldValue('categoryName', shopPreview.category);
            setFieldValue('subCategoryName', shopPreview.sub_category);
            setFieldValue('partTypeName', shopPreview.part_type);
          }
        };

        const clearPartType = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (e) e.stopPropagation();
          setPartTypeDefaultValue(false);
          setFieldValue('category_id', null);
          setFieldValue('categoryName', '');
          setFieldValue('subCategoryName', '');
          setFieldValue('partTypeName', '');
          setPartTypeDrawerVisible(false);
        };

        return (
          <Form layout="vertical" className="kit__edit-form">
            <Row gutter={16}>
              <Col span={12}>
                <div data-cy="partnumber">
                  <FormInput
                    label={t('kit:partNumber')}
                    name="part_number"
                    placeholder={t('kit:partNumber')}
                    handleOnBlur={() => {
                      // only on first entered part number and local brand id fetch should occur
                      if (!firstEnteredPartNumber && !props.kit) {
                        setFirstEnteredPartNumber(values.part_number);
                        if (values.external_brand_id === brandId && values.part_number !== '') {
                          dispatch(
                            fetchKitShopPreview(values.external_brand_id, values.part_number)
                          ).then(result => handleExistingValuesDisplay(result.value.data));
                        }
                      } else {
                        resetExistingDefaultTags();
                      }
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <FormInput
                  testId="quantity"
                  disabled={!firstEnteredPartNumber && !props.kit}
                  required
                  label={
                    <span>
                      {t('kit:quantity')}
                      {qtyDefaultValue && (
                        <span className="text-gray-600 whitespace-nowrap">
                          {` (${t('kit:defaultQuantityUom')})`}
                        </span>
                      )}
                    </span>
                  }
                  name="quantity_in_kit"
                  addonAfterName="quantity_in_kit_uom_id"
                  placeholder={t('kit:quantity')}
                  handleChange={e => {
                    const qty = e.target.value;
                    const reg = /^[0-9]+$|^$/;
                    if (reg.test(qty)) setFieldValue('quantity_in_kit', qty);
                    setQtyDefaultValue(false);
                  }}
                  addonAfter={
                    <FormSelect
                      className="kit__input-addon"
                      required
                      disabled={!firstEnteredPartNumber && !props.kit}
                      showSearch
                      name="quantity_in_kit_uom_id"
                      values={listUomIds}
                      placeholder={t('kit:selectUOM')}
                      onChange={(id: SelectValue) => {
                        setFieldValue('quantity_in_kit_uom_id', id);
                        setQtyDefaultValue(false);
                      }}
                      allowClear
                      testId="qty-uom"
                    />
                  }
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      {t('kit:brand')}
                      {!isOriginalBrand && values.external_brand_id === brandId && (
                        <AntTooltip title={t('kit:brandCodeInfo')}>
                          <WarningOutlined className="icon__red ml-2" />
                        </AntTooltip>
                      )}
                    </span>
                  }
                  hasFeedback
                  validateStatus={
                    errors.external_brand_id && touched.external_brand_id ? 'error' : undefined
                  }
                  help={(touched.external_brand_id && errors.external_brand_id) || undefined}
                >
                  <React.Fragment>
                    <div data-cy="brand">
                      <VSelect
                        data={allBrands}
                        value={values.external_brand_id}
                        onChange={id => {
                          setFieldValue('external_brand_id', id);
                          setFieldValue('external_sub_brand_id', null);
                          if (id) dispatch(fetchKitSubBrand(Number(id)));
                          resetExistingDefaultTags();
                        }}
                        placeholder={t('kit:selectBrand')}
                      />
                    </div>
                  </React.Fragment>
                </Form.Item>
              </Col>
              <Col span={12}>
                {values.external_brand_id && listSubBrands && listSubBrands.length > 0 && (
                  <FormSelect
                    disabled={!firstEnteredPartNumber && !props.kit}
                    label={t('kit:subBrand')}
                    name="external_sub_brand_id"
                    values={listSubBrands}
                    placeholder={t('kit:subBrand')}
                    allowClear
                  />
                )}
              </Col>
            </Row>
            <Form.Item
              label={
                <div>
                  {t('kit:partType')}
                  {partTypeDefaultValue && (
                    <span className="text-gray-600 whitespace-nowrap">
                      {` (${t('kit:defaultPartType')})`}
                    </span>
                  )}
                </div>
              }
            >
              <div
                className={classNames(
                  { 'bg-gray-300 cursor-not-allowed': !firstEnteredPartNumber && !props.kit },
                  'border border-solid border-gray-500 rounded flex items-center '
                )}
              >
                <div
                  onClick={() => {
                    if (!firstEnteredPartNumber && !props.kit) return;
                    setPartTypeDrawerVisible(true);
                  }}
                  className="flex p-2 cursor-pointer items-center flex-1"
                >
                  <div className="flex-1">
                    {values.partTypeName ? (
                      `${values.categoryName} | ${values.subCategoryName} | ${values.partTypeName}${
                        showPartTypeId && values.partTypeId ? ` (${values.partTypeId})` : ''
                      }`
                    ) : (
                      <div className="text-gray-600">{t('partType:selectPartType')}</div>
                    )}
                  </div>
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      className="mr-2"
                      onClick={() => setPartTypeDrawerVisible(true)}
                      disabled={!values.category_id}
                    >
                      {t('common:edit')}
                    </Button>
                    <Popconfirm
                      title={t('partType:popDelete')}
                      onConfirm={clearPartType}
                      okText={t('common:delete')}
                      cancelText={t('common:cancel')}
                      okButtonProps={{ danger: true }}
                      onCancel={e => e?.stopPropagation()}
                      disabled={!values.category_id}
                    >
                      <Button
                        disabled={!firstEnteredPartNumber && !props.kit}
                        data-cy="parttype"
                        size="small"
                        className="sm:mt-1 lg:m-0 md:mt-1"
                        onClick={e => {
                          if (values.category_id) e.stopPropagation();
                        }}
                      >
                        {values.category_id
                          ? t('item:deselectPartType')
                          : t('partType:addPartType')}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
              {partTypeDrawerVisible && (
                <PartType
                  onSubmit={(
                    partTypeId: number,
                    categoryName: string,
                    subCategoryName: string,
                    partTypeName: string,
                    visiblePartTypeId: number
                  ) => {
                    setPartTypeDefaultValue(false);
                    setFieldValue('category_id', partTypeId);
                    setFieldValue('categoryName', categoryName);
                    setFieldValue('subCategoryName', subCategoryName);
                    setFieldValue('partTypeName', partTypeName);
                    setFieldValue('partTypeId', visiblePartTypeId);
                    setPartTypeDrawerVisible(false);
                  }}
                  onClose={() => setPartTypeDrawerVisible(false)}
                  partTypeDrawerVisible
                  categoryName={values.categoryName || undefined}
                  subCategoryName={values.subCategoryName || undefined}
                  partTypeName={values.partTypeName || undefined}
                />
              )}
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <div data-cy="qualifier">
                  <FormSelect
                    disabled={!firstEnteredPartNumber && !props.kit}
                    label={t('kit:qualifier')}
                    name="part_number_qualifier_id"
                    placeholder={t('kit:qualifier')}
                    values={listQualifiers}
                    allowClear
                  />
                </div>
              </Col>
              <Col span={12}>
                <FormRadio
                  disabled={!firstEnteredPartNumber && !props.kit}
                  name="sold_separately"
                  label={
                    <span>
                      {t('kit:soldSeperately')}
                      <Tooltip title={t('kit:soldSeperatelyInfo')} className="ml-2">
                        <InfoCircleOutlined className="ant-info-tooltip" />
                      </Tooltip>
                    </span>
                  }
                  values={listSoldSeparately}
                />
              </Col>
            </Row>
            <Row gutter={16} className="mt-10">
              <Col span={12}>
                <Form.Item
                  className="kit__standalone-form-label "
                  label={
                    <span>
                      {t('kit:description')}
                      <Tooltip title={t('kit:descriptionInfo')} className="ml-2">
                        <InfoCircleOutlined className="ant-info-tooltip" />
                      </Tooltip>
                      {descDefaultValue && (
                        <span className="text-gray-600 whitespace-nowrap">
                          {` (${t('kit:defaultDescription')})`}
                        </span>
                      )}
                    </span>
                  }
                  required
                >
                  {null}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12}>
                    <div data-cy="descriptiontype">
                      <FormSelect
                        disabled={!firstEnteredPartNumber && !props.kit}
                        showSearch
                        name="description_type_id"
                        values={listDescriptionTypes}
                        placeholder={t('kit:productDescription')}
                        onChange={(type: SelectValue) => {
                          setDescDefaultValue(false);
                          setFieldValue('description_type_id', type || null);
                        }}
                        allowClear
                        testId="description_type_id"
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <FormSelect
                      disabled={!firstEnteredPartNumber && !props.kit}
                      showSearch
                      name="description_language_id"
                      values={listLanguages}
                      placeholder={t('kit:language')}
                      onChange={(id: SelectValue) => {
                        setDescDefaultValue(false);
                        setFieldValue('description_language_id', id || null);
                      }}
                      allowClear
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div data-cy="description">
              <FormTextArea
                disabled={!firstEnteredPartNumber && !props.kit}
                name="description"
                placeholder={t('kit:description')}
                handleChange={e => {
                  setDescDefaultValue(false);
                  handleChange(e);
                }}
                required
                testId="description"
              />
            </div>
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default KitDrawer;
