import React from 'react';
import { Modal } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import VcdbEdit from './VcdbEdit';

type VcdbDrawerProps = {
  visible: boolean;
  onClose: () => void;
} & WithTranslation;

class VcdbDrawer extends React.Component<VcdbDrawerProps> {
  closeDrawer = (dirty: boolean, force?: boolean) => {
    const { t, onClose } = this.props;

    if (!dirty || force) {
      onClose();
    } else {
      Modal.confirm({
        title: t('common:closeWarningTitle'),
        onOk() {
          onClose();
        },
      });
    }
  };

  render() {
    return <VcdbEdit visible={this.props.visible} onClose={this.closeDrawer} />;
  }
}

export { VcdbDrawer };
export default withContainerWrapper(withTranslation()(VcdbDrawer));
