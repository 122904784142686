import React, { useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RankedValue, Years } from '../../../../types/application';
import { StandardResource } from '../../../../types/resources';
import ApplicationColumn from './ApplicationColumn';

type ApplicationEquipmentProps = {
  ranks: { [key: string]: RankedValue[] };
  getRecommendations: boolean;
  vcdbView?: boolean;
  filterView?: boolean;
  years?: Years[];
  fetchingYears: boolean;
  mapConfigToApplication: (subconfigName: string, rowId: number) => void;
  addCustomValue?: (configName: string, value: string, group?: string) => Promise<any>;
  deleteCustomValue?: (configName: string, id: number) => Promise<any>;
  allSubconfigValues: (config: string) => StandardResource[];
};

const ApplicationEquipment: React.FC<ApplicationEquipmentProps> = props => {
  const { t } = useTranslation();

  const orderRank1ByUsedByBrand = (rows: any[]) => {
    rows.sort(
      (
        a: { mapped: any; rank: number; used_by_brand: any; name: number },
        b: { mapped: any; rank: number; used_by_brand: any; name: number }
      ) => {
        // if both elements are mapped sort them by name, otherwise move the mapped element up
        if (a.mapped === b.mapped) {
          // if both elements have the same rank sort them by name, otherwise move the lower rank up
          if (a.rank === b.rank) {
            if (
              a.used_by_brand === b.used_by_brand ||
              (a.used_by_brand && b.used_by_brand) ||
              (props.getRecommendations && a.rank === 3)
            ) {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }
            if (a.used_by_brand) return -1;
            if (b.used_by_brand) return 1;
            return 0;
          }
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        }
        if (a.mapped) return -1;
        if (b.mapped) return 1;
        return 0;
      }
    );
    return rows;
  };

  const rows = (
    values: { [x: string]: any }[],
    rankedValues: any[],
    selectedValues: number[],
    sort: boolean,
    divide?: boolean
  ) => {
    // invalid selections can be missing when only base-vehicle valid sub-configs are shown
    const allValues = rankedValues.length > values.length ? rankedValues : values;

    const rows = allValues.map(({ ...value }) => {
      const rankedValue = rankedValues.find(rankedVal => rankedVal.id === value.id);
      if (rankedValue) value.rank = rankedValue.rank;
      if (!value.rank) value.rank = 3;
      value.mapped = !!selectedValues.find(id => id === value.id);

      return value;
    });

    if (sort) {
      rows.sort((a, b) => {
        // if both elements are mapped sort them by name, otherwise move the mapped element up
        if (a.mapped === b.mapped) {
          // if both elements have the same rank sort them by name, otherwise move the lower rank up
          if (a.rank === b.rank) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        }
        if (a.mapped) return -1;
        if (b.mapped) return 1;
        return 0;
      });
    }

    if (divide) return orderRank1ByUsedByBrand(rows);

    return rows;
  };

  const { ranks, years, fetchingYears } = props;

  const selectedMfrs = ranks.mfrs ? ranks.mfrs.filter(mfr => mfr.mapped).map(mfr => mfr.id) : [];
  const selectedModels = ranks.equipment_models
    ? ranks.equipment_models.filter(model => model.mapped).map(model => model.id)
    : [];
  const selectedVehicleTypes = ranks.vehicle_types
    ? ranks.vehicle_types.filter(type => type.mapped).map(type => type.id)
    : [];
  const selectedVehicleGroups = ranks.vehicle_type_groups
    ? ranks.vehicle_type_groups.filter(g => g.mapped).map(g => g.id)
    : [];
  const selectedYears = ranks.years
    ? ranks.years.filter(year => year.mapped).map(year => year.id)
    : [];
  const selectedRegions = ranks.regions
    ? ranks.regions.filter(region => region.mapped).map(region => region.id)
    : [];
  const selectedProductionStart = ranks.production_start
    ? ranks.production_start.filter(year => year.mapped).map(year => year.id)
    : [];
  const selectedProductionEnd = ranks.production_end
    ? ranks.production_end.filter(year => year.mapped).map(year => year.id)
    : [];

  const yearResources =
    (years && years.length === 0 && !fetchingYears) || !years
      ? props.allSubconfigValues('years')
      : years;

  const vehicleTypes = props.allSubconfigValues('vehicle_types');
  const vehicleTypeGroups = useMemo(
    () => vehicleTypes.map((t: any) => ({ ...t, name: `${t.name} (${t.vehicle_group})` })),
    [vehicleTypes]
  );

  return (
    <div className="application__make-model-year">
      <div
        className={classNames('float__left h-full', {
          'w-1/2': props.vcdbView,
          'w-3/4 application__equipment-view': !props.vcdbView,
        })}
      >
        <div
          className={classNames('float__left application__mfr-equipment-wrapper', {
            'application__mfr-equipment-vcdb': props.vcdbView,
            'w-2/3': !props.vcdbView,
          })}
        >
          <ApplicationColumn
            rows={rows(
              props.allSubconfigValues('mfrs'),
              ranks.mfrs || [],
              selectedMfrs,
              true,
              true
            )}
            getRecommendations={props.getRecommendations}
            selected={selectedMfrs}
            mapConfigToApplication={props.mapConfigToApplication}
            addCustomValue={props.addCustomValue}
            deleteCustomValue={props.deleteCustomValue}
            subconfigName="mfrs"
            divider
            title={t('application:mfr')}
          />
          <ApplicationColumn
            rows={rows(
              props.allSubconfigValues('equipment_models'),
              ranks.equipment_models || [],
              selectedModels,
              true,
              true
            )}
            getRecommendations={props.getRecommendations}
            selected={selectedModels}
            mapConfigToApplication={props.mapConfigToApplication}
            addCustomValue={props.addCustomValue}
            deleteCustomValue={props.deleteCustomValue}
            subconfigName="equipment_models"
            divider
            title={t('application:equipmentModel')}
          />
        </div>
        <div
          className={classNames('float__left', {
            'application__equipment-type-wrapper': props.vcdbView,
            'application__equipment-type-col w-1/3': !props.vcdbView,
            'application__equipment-type-filter ': props.filterView,
          })}
        >
          <ApplicationColumn
            rows={rows(
              vehicleTypeGroups,
              ranks.vehicle_types || [],
              selectedVehicleTypes,
              true,
              true
            )}
            getRecommendations={props.getRecommendations}
            selected={selectedVehicleTypes}
            mapConfigToApplication={props.mapConfigToApplication}
            addCustomValue={props.addCustomValue}
            deleteCustomValue={props.deleteCustomValue}
            subconfigName={props.filterView ? 'equipment_vehicle_types' : 'vehicle_types'}
            title={t('application:vehicleTypeGroup')}
          />
        </div>
        {props.filterView && (
          <div className="float__left application__equipment-group w-1/3">
            <ApplicationColumn
              rows={rows(
                props.allSubconfigValues('vehicle_type_groups'),
                ranks.vehicle_type_groups || [],
                selectedVehicleGroups,
                true,
                true
              )}
              getRecommendations={props.getRecommendations}
              selected={selectedVehicleGroups}
              mapConfigToApplication={props.mapConfigToApplication}
              addCustomValue={props.addCustomValue}
              deleteCustomValue={props.deleteCustomValue}
              subconfigName={
                props.filterView ? 'equipment_vehicle_type_groups' : 'vehicle_type_groups'
              }
              title={t('application:vehicleGroup')}
            />
          </div>
        )}
      </div>

      <div
        className={classNames('application__submodel-region float__left', {
          'application__production-region-vcdb': props.vcdbView,
        })}
      >
        {props.vcdbView ? (
          <React.Fragment>
            <div className="application__equipment-years float__left">
              <ApplicationColumn
                rows={rows(
                  props.allSubconfigValues('years'),
                  ranks.years || [],
                  selectedProductionStart,
                  false,
                  false
                )}
                getRecommendations={props.getRecommendations}
                selected={selectedYears}
                mapConfigToApplication={props.mapConfigToApplication}
                addCustomValue={
                  props.addCustomValue
                    ? (_name, value) => props.addCustomValue!('years', value)
                    : undefined
                }
                deleteCustomValue={
                  props.deleteCustomValue
                    ? (_n, id) => props.deleteCustomValue!('years', id)
                    : undefined
                }
                subconfigName="production_start"
                cmdKeyMultiselect
                scrollToFirstElement
                title={
                  <span>
                    {t('application:productionStartYear')}
                    <Tooltip title={t('application:productionStartHint')}>
                      <InfoCircleOutlined className="ml-1" />
                    </Tooltip>
                  </span>
                }
              />
            </div>
            <div className="application__equipment-years equipment-years-end float__left">
              <ApplicationColumn
                rows={rows(
                  props.allSubconfigValues('years'),
                  ranks.years || [],
                  selectedProductionEnd,
                  false,
                  false
                )}
                getRecommendations={props.getRecommendations}
                selected={selectedYears}
                mapConfigToApplication={props.mapConfigToApplication}
                addCustomValue={
                  props.addCustomValue
                    ? (_n, value) => props.addCustomValue!('years', value)
                    : undefined
                }
                deleteCustomValue={
                  props.deleteCustomValue
                    ? (_n, id) => props.deleteCustomValue!('years', id)
                    : undefined
                }
                subconfigName="production_end"
                cmdKeyMultiselect
                scrollToFirstElement
                title={
                  <span>
                    {t('application:productionEndYear')}
                    <Tooltip title={t('application:productionEndHint')}>
                      <InfoCircleOutlined className="ml-1" />
                    </Tooltip>
                  </span>
                }
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="application__submodel">
            <ApplicationColumn
              rows={rows(yearResources, ranks.years || [], selectedYears, false, false)}
              getRecommendations={props.getRecommendations}
              selected={selectedYears}
              mapConfigToApplication={props.mapConfigToApplication}
              addCustomValue={props.addCustomValue}
              deleteCustomValue={props.deleteCustomValue}
              fetching={props.fetchingYears}
              subconfigName={props.filterView ? 'equipment_years' : 'years'}
              cmdKeyMultiselect
              scrollToFirstElement
              title={t('application:productionYear')}
            />
          </div>
        )}

        <div className={classNames('application__region', { vcdb_equipment: props.vcdbView })}>
          <ApplicationColumn
            rows={rows(
              props.allSubconfigValues('regions'),
              ranks.regions || [],
              selectedRegions,
              true,
              true
            )}
            getRecommendations={props.getRecommendations}
            selected={selectedRegions}
            mapConfigToApplication={props.mapConfigToApplication}
            addCustomValue={props.addCustomValue}
            deleteCustomValue={props.deleteCustomValue}
            subconfigName="regions"
            cmdKeyMultiselect
            title={t('application:region')}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationEquipment;
