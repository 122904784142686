import { Button, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShopifyChannel } from '../../../../types/channel';

import AntTable from '../../global/AntTable';

type ShopifyNestedTableProps = {
  shopifyChannels: ShopifyChannel[];
  handleEditShopifyChannel: (id: number) => void;
  handleDeleteShopifyChannel: (id: number) => void;
  fetchingChannelShopify: boolean;
};

const ShopifyNestedTable: React.FC<ShopifyNestedTableProps> = props => {
  const { t } = useTranslation();

  const getProfileData = () => {
    if (props.shopifyChannels)
      return props.shopifyChannels.map(sc => {
        return {
          ...sc,
          [t('channel:shopifyStore')]: sc.name,
          key: sc.id,
          [t('common:status')]: (
            <Tag
              color={
                sc.shop_status.status === 'connected'
                  ? 'green'
                  : (sc.shop_status.status === 'connected_to_other' && 'red') || ''
              }
            >
              {sc.shop_status.status === 'connected'
                ? t('channel:connected')
                : (sc.shop_status.status === 'disconnected' && t('channel:disconnected')) ||
                  t('channel:connectedToOther')}
            </Tag>
          ),
          [t('common:action')]: (
            <div className="channel-update space-x-2">
              <Button size="small" onClick={() => props.handleEditShopifyChannel(sc.id)}>
                {t('common:edit')}
              </Button>
              <Button
                size="small"
                danger
                onClick={() => props.handleDeleteShopifyChannel(sc.id)}
                data-testid="remove-shopify-channel"
              >
                {t('common:remove')}
              </Button>
            </div>
          ),
        };
      });
    return [];
  };
  const columns = [
    { name: t('channel:shopifyStore') },
    { name: t('common:status') },
    { name: t('common:action') },
  ];

  return (
    <AntTable
      className="channel__plan-table"
      columns={columns}
      dataSource={getProfileData()}
      pagination={false}
      size="small"
      loading={props.fetchingChannelShopify}
    />
  );
};

export default ShopifyNestedTable;
