import React from 'react';

import DigitalAssetSinglePreview from './DigitalAssetSinglePreview';

class DigitalAssetPreview extends React.Component {
  render() {
    const {
      digitalAssets,
      fetching,
      digitalAssetResources,
      selectedDigitalAssetId,
      setSelectedDigitalAsset,
      showPreview,
      deleteAsset,
      analyses,
    } = this.props;
    if (fetching) {
      return <div className="loader" />;
    }
    return (
      <div className="digital-asset-viewer scrollable">
        <div className="digital-asset-viewer__box-container">
          {digitalAssets &&
            digitalAssets.map(digitalAsset => (
              <DigitalAssetSinglePreview
                key={digitalAsset.id}
                digitalAsset={digitalAsset}
                selectedDigitalAssetId={selectedDigitalAssetId}
                digitalAssetResources={digitalAssetResources}
                setSelectedDigitalAsset={setSelectedDigitalAsset}
                analyses={analyses}
                showPreview={showPreview}
                deleteAsset={deleteAsset}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default DigitalAssetPreview;
