import React from 'react';
import { useDispatch } from 'react-redux';
import { AsyncDispatch } from '../../../types/global';
import { fetchScriptLogs } from '../../actions/distribution_apisettings';
import AutomationLogs from '../../components/body/distribution/AutomationLogs';

const AutomationLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchScriptLogs());
  }, [dispatch]);

  const fetchNextScriptLogs = (page: number) => {
    return dispatch(fetchScriptLogs(page));
  };

  return <AutomationLogs fetchNextScriptLogs={fetchNextScriptLogs} />;
};

export default AutomationLogsContainer;
