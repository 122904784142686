import { connect } from 'react-redux';
import React from 'react';
import { Button, Input } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { ListUser, UserType, PermissionGroup, UserPermissionRole } from '../../../types/user';
import { fetchUsers, updateUserState, fetchMappedUserRoles } from '../../actions/user';
import { showUpgradePlanNotification } from '../../actions/app/modal';
import { withContainerWrapper } from '../ContainerWrapper';
import UserTable from '../../components/body/user/UserTable';
import UserDrawer from '../../components/body/user/UserDrawer';
import UserRoleDrawer from '../../components/body/user/UserRoleDrawer';
import { Segment, StandardResourceCode } from '../../../types/resources';
import { isReceiver } from '../../utils/UserUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';

type UserContainerProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  userList: ListUser[];
  permissionGroups: PermissionGroup[];
  roles: UserPermissionRole[];
  segments: Segment[];
  maintenanceTypes: StandardResourceCode[];
  isReceiver: boolean;
} & WithTranslation;

type UserContainerState = {
  showCreateDrawer: boolean;
  showEditDrawer: boolean;
  showRoleDrawer: boolean;
  selectedUserId: number | null;
  filterKeywords: string;
};

export class UserContainer extends React.Component<UserContainerProps, UserContainerState> {
  constructor(props: UserContainerProps) {
    super(props);
    this.state = {
      showCreateDrawer: false,
      showEditDrawer: false,
      showRoleDrawer: false,
      selectedUserId: null,
      filterKeywords: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchUsers());
    intercomEvent('viewed-company-user-settings');
  }

  updateUserState = (active: number) => {
    const { selectedUserId: id } = this.state;
    return this.props.dispatch(updateUserState(id!, active));
  };

  showCreateDrawer = () => {
    const { user } = this.props;
    if (!hasPermission(user, 'can_manage_users'))
      this.props.dispatch(showUpgradePlanNotification());

    this.setState({ showCreateDrawer: true });

    intercomEvent('viewed-company-user-settings', { action: 'add_new' });
  };

  showRoleDrawer = () => {
    const { user } = this.props;
    if (!hasPermission(user, 'can_manage_users'))
      this.props.dispatch(showUpgradePlanNotification());

    this.setState({ showRoleDrawer: true });
    intercomEvent('viewed-company-user-settings', { action: 'manage_roles' });
  };

  showEditDrawer = (userId: number) => {
    const { user } = this.props;
    if (!hasPermission(user, 'can_manage_users'))
      this.props.dispatch(showUpgradePlanNotification());

    this.setState({ showEditDrawer: true, selectedUserId: userId });
    this.props.dispatch(fetchMappedUserRoles(userId));
    intercomEvent('viewed-company-user-settings', { action: 'edit' });
  };

  closeDrawer = () => {
    this.setState({ showCreateDrawer: false, showEditDrawer: false, selectedUserId: null });
  };

  closeRoleDrawer = () => {
    this.setState({ showRoleDrawer: false });
  };

  render() {
    const { userList, isReceiver, t } = this.props;
    const { showCreateDrawer, showEditDrawer, showRoleDrawer, selectedUserId } = this.state;

    return (
      <div className="user__wrapper">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <Input.Search
              className="user__search-input"
              value={this.state.filterKeywords}
              onChange={e => this.setState({ filterKeywords: e.target.value })}
              placeholder={t('user:search')}
              allowClear
            />
            <div className="page-layout__top-bar__actions">
              {!isReceiver && (
                <Button className="user__role-button" onClick={() => this.showRoleDrawer()}>
                  {t('user:manageRoles')}
                </Button>
              )}
              <Button type="primary" onClick={() => this.showCreateDrawer()}>
                {t('user:addUser')}
              </Button>
            </div>
          </div>
        </div>

        <UserTable
          userList={this.props.userList}
          editUser={this.showEditDrawer}
          filterKeywords={this.state.filterKeywords}
          roles={this.props.roles}
        />

        <UserDrawer
          visible={showCreateDrawer || showEditDrawer}
          onClose={() => this.closeDrawer()}
          currentUserId={this.props.user.id}
          user={userList.find(user => user.id === selectedUserId)}
          updateUserState={this.updateUserState}
          showRoleDrawer={this.showRoleDrawer}
        />

        {showRoleDrawer && (
          <UserRoleDrawer
            visible={showRoleDrawer}
            onClose={() => this.closeRoleDrawer()}
            permissionGroups={this.props.permissionGroups}
            roles={this.props.roles}
            segments={this.props.segments}
            maintenanceTypes={this.props.maintenanceTypes}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  userList: state.user.userList,
  permissionGroups: state.user.permissionGroups,
  roles: state.user.roles,
  segments: state.resources.data.global.segments.filter(s => s.name !== 'app_inheriting'),
  maintenanceTypes: state.resources.data.global.maintenance_types,
  isReceiver: state.user.user && isReceiver(state.user.user),
});

export default withTranslation()(connect(mapStateToProps)(withContainerWrapper(UserContainer)));
