import axios from 'axios';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

export function updateCustomFields(values, fields) {
  // const params = utils.createURL([{ name: 'value', values: value }]);

  return {
    type: 'UPDATE_CUSTOM_FIELDS',
    meta: { customFields: fields },
    payload: axios.patch(`${getNonGwApiRoot()}/items/custom_fields/`, { custom_fields: values }),
  };
}
