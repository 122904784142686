import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import { ApplicationState } from '../../../reducers';
import {
  MarketingCopy as TMarketinCopy,
  MarketingCopySubCodeId,
} from '../../../../types/marketing_copy';
import MarketingCopyPreview from '../media_and_marketing_copy/MarketingCopyPreview';
import MarketingCopyEditDrawer from '../media_and_marketing_copy/MarketingCopyEditDrawer';
import { hasPermission } from '../../../utils/Permissions';

type MarketingCopyProps = {
  unLinkMarketingCopy: () => void;
};

const MarketingCopy: React.FC<MarketingCopyProps> = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const [marketingCopyDrawerOpen, setMarketingCopyDrawerOpen] = React.useState(false);
  const [selectedMarketingCopyId, setSelectedMarketingCopyId] = React.useState<number | null>(null);

  const { itemId, marketingCopies, fetchingMarketingCopy, user } = useSelector(
    (state: ApplicationState) => {
      return {
        itemId: state.catalogue.catalogue.selectedItemIds[0],
        marketingCopies: state.items.description.marketingCopies,
        fetchingMarketingCopy: state.items.description.fetchingMarketingCopy,
        user: state.user.user,
      };
    }
  );

  React.useEffect(() => {
    if (!fetchingMarketingCopy) {
      const params = queryString.parse(location.search, { arrayFormat: 'comma' });
      const marketingCopyId = Number(params.marketingCopyId);

      if (marketingCopyId && !marketingCopyDrawerOpen) {
        const mc = marketingCopies.find(mc => mc.id === marketingCopyId);
        if (mc) {
          setMarketingCopyDrawerOpen(true);
          setSelectedMarketingCopyId(marketingCopyId);
        }
      } else if (!marketingCopyId && marketingCopyDrawerOpen) {
        setMarketingCopyDrawerOpen(false);
        setSelectedMarketingCopyId(null);
      }
    }
  }, [fetchingMarketingCopy, location.search, marketingCopies, marketingCopyDrawerOpen]);

  const canManageMarketingCopy = hasPermission(user, 'can_manage_marketing_copy');

  const handleCloseDrawer = () => {
    setMarketingCopyDrawerOpen(false);
    setSelectedMarketingCopyId(null);

    const params = queryString.parse(location.search, { arrayFormat: 'comma' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { marketingCopyId, ...rest } = params;
    navigate(`?${queryString.stringify(rest, { arrayFormat: 'comma' })}`);
  };

  return (
    <div>
      {fetchingMarketingCopy ? (
        <Spin className="spinner-center" />
      ) : (
        <div className="overflow-visible h-full">
          {marketingCopies.map((marketingCopy: TMarketinCopy) => (
            <div className="pb-6 pt-2" key={marketingCopy.id}>
              <MarketingCopyPreview
                key={marketingCopy.id}
                marketingCopy={marketingCopy}
                handleOpenMarketingCopyDrawer={({ bool, id }: { bool: boolean; id: number }) => {
                  setMarketingCopyDrawerOpen(bool);
                  setSelectedMarketingCopyId(id);
                  navigate(`${location.search}&marketingCopyId=${id}`);
                }}
                showUnlink={
                  marketingCopy.sub_code_id === MarketingCopySubCodeId.GROUP ||
                  marketingCopy.sub_code_id === MarketingCopySubCodeId.SUB_GROUP
                }
                showSubCode={!!marketingCopy.sub_code_id}
                unLinkMarketingCopy={() => props.unLinkMarketingCopy()}
                size="small"
              />
            </div>
          ))}
        </div>
      )}

      <MarketingCopyEditDrawer
        visible={marketingCopyDrawerOpen}
        canManageMarketingCopy={canManageMarketingCopy}
        marketingCopy={marketingCopies.find(
          marketingCopy => marketingCopy.id === selectedMarketingCopyId
        )}
        closeDrawer={() => handleCloseDrawer()}
        itemId={itemId}
        alert
      />
    </div>
  );
};

export default MarketingCopy;
