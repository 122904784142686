import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShopifyStatus } from '../../../../types/channel';

type ChannelTableColPublishBrandProps = {
  statusId: number;
  handleConnect: (connect: number) => void;
  connectAndReject?: boolean;
  showSpinner: boolean;
  shopifyStatus?: ShopifyStatus;
  handleNavigation?: () => void;
};

const ChannelTableColPublishBrand: React.FC<ChannelTableColPublishBrandProps> = props => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [connect, setConnect] = React.useState<number>();

  const navigateToSettings = (status: ShopifyStatus) => {
    if (!status.installed || status.status !== 'connected') return true;
    return false;
  };

  const renderModal = () => {
    return (
      <Modal
        className="shopify_connect-account-modal"
        open={showModal}
        footer={[
          <Button
            onClick={() => {
              setShowModal(false);
              props.handleConnect(connect!);
            }}
          >
            {t('channel:continue')}
          </Button>,
          <Button
            onClick={() => {
              props.handleNavigation!();
              setShowModal(false);
            }}
            type="primary"
          >
            {t('channel:settingsTitle')}
          </Button>,
        ]}
        closable={false}
        width={460}
      >
        <div>
          <div className="mb-6">{t('channel:connectAccount')}</div>
          <div>{t('channel:connectInfo')}</div>
        </div>
      </Modal>
    );
  };

  const renderDisconnect = () => {
    return (
      <Button
        size="small"
        onClick={() => {
          if (props.shopifyStatus && navigateToSettings(props.shopifyStatus)) {
            setShowModal(true);
            setConnect(0);
          } else props.handleConnect(0);
        }}
        className={
          props.shopifyStatus
            ? 'channel-table__shopify-publish-button'
            : 'channel-table__publish-button'
        }
        loading={props.showSpinner}
      >
        {props.shopifyStatus ? t('channel:desync') : t('channel:disconnect')}
      </Button>
    );
  };

  const renderConnect = () => {
    return (
      <Button
        size="small"
        onClick={() => {
          if (props.shopifyStatus && navigateToSettings(props.shopifyStatus)) {
            setShowModal(true);
            setConnect(1);
          } else props.handleConnect(1);
        }}
        type="primary"
        className={
          props.shopifyStatus
            ? 'channel-table__shopify-publish-button'
            : 'channel-table__publish-button'
        }
        loading={props.showSpinner}
      >
        {props.shopifyStatus ? t('channel:sync') : t('channel:connect')}
      </Button>
    );
  };

  const renderCancel = () => {
    return (
      <Button
        size="small"
        onClick={() => {
          if (props.shopifyStatus && navigateToSettings(props.shopifyStatus)) {
            setShowModal(true);
            setConnect(0);
          } else props.handleConnect(0);
        }}
        className={
          props.shopifyStatus
            ? 'channel-table__shopify-publish-button'
            : 'channel-table__publish-button'
        }
        loading={props.showSpinner}
      >
        {props.shopifyStatus ? t('channel:desync') : t('common:cancel')}
      </Button>
    );
  };

  const renderReject = () => {
    return (
      <Button
        size="small"
        onClick={() => {
          if (props.shopifyStatus && navigateToSettings(props.shopifyStatus)) {
            setShowModal(true);
            setConnect(0);
          } else props.handleConnect(0);
        }}
        className={
          props.shopifyStatus
            ? 'channel-table__shopify-publish-button'
            : 'channel-table__publish-button'
        }
        loading={props.showSpinner}
      >
        {t('channel:reject')}
      </Button>
    );
  };

  const renderBothButtons = () => {
    return (
      <div className="flex flex-row justify-between">
        {renderConnect()}
        {renderReject()}
      </div>
    );
  };
  const renderActions = () => {
    if (props.statusId === 2) return renderDisconnect();
    if (props.statusId === 1 && !props.connectAndReject) return renderCancel();
    if (props.statusId === 3) return <div />;
    if (props.connectAndReject) return renderBothButtons();

    return renderConnect();
  };

  return (
    <React.Fragment>
      {props.shopifyStatus && renderModal()}
      {renderActions()}
    </React.Fragment>
  );
};

export default ChannelTableColPublishBrand;
