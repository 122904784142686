import { Reducer } from 'redux';
import { ExternalSources } from '../../../types/external_sources';
import { ExternalSourceImport } from '../../../types/import_export';

export type ExternalSourcesState = {
  readonly fetchingSources: boolean;
  readonly externalSources?: ExternalSources;
  readonly fetchingExternalsourceImportLogs: boolean;
  readonly importLogs: ExternalSourceImport[];
};

const initialState: ExternalSourcesState = {
  fetchingSources: false,
  externalSources: undefined,
  fetchingExternalsourceImportLogs: false,
  importLogs: [],
};

const reducer: Reducer<ExternalSourcesState> = (
  state = initialState,
  action
): ExternalSourcesState => {
  switch (action.type) {
    case 'FETCH_EXTERNAL_SOURCES_IMPORT_LOGS_PENDING': {
      return { ...state, fetchingExternalsourceImportLogs: true, importLogs: [] };
    }
    case 'FETCH_EXTERNAL_SOURCES_IMPORT_LOGS_FULFILLED': {
      return { ...state, fetchingExternalsourceImportLogs: false, importLogs: action.payload.data };
    }
    case 'FETCH_RECEIVER_EXTERNAL_SOURCES_PENDING': {
      return { ...state, fetchingSources: true };
    }
    case 'FETCH_RECEIVER_EXTERNAL_SOURCES_FULFILLED': {
      return {
        ...state,
        externalSources: action.payload.data,
        fetchingSources: false,
      };
    }
    case 'UPDATE_RECEIVER_EXTERNAL_SOURCES_FULFILLED': {
      return {
        ...state,
        externalSources: action.payload.data,
      };
    }
  }
  return state;
};

export default reducer;
