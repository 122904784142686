const initialState = {
  fetchingPartTypeAnalyses: false,
  fetchingNewYearAnalyses: false,
  fetchingEngineBaseAnalyses: false,
  fetchingNewEngineAnalyses: false,
  fetchingNewTransmissionAnalyses: false,
  fetchingSubConfigAnalyses: false,
  fetchingReviewCategories: false,
  analysesCount: [],
  error: null,
  partTypeAnalyses: [],
  newYearAnalyses: [],
  engineBaseAnalyses: [],
  newEngineAnalyses: [],
  newTransmissionAnalyses: [],
  subConfigAnalyses: [],
  reviewCategories: [],
  fetchingLatestProductChanges: false,
  history: [],
  averageSegmentRecom: [],
  scoreOfOtherBrands: [],
  recommendationItems: [],
  latestProductChanges: [],
  confirmedVehicleBases: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ANALYSES_COUNT_FULFILLED': {
      return {
        ...state,
        analysesCount: action.payload.data,
      };
    }
    case 'FETCH_PART_TYPE_ANALYSES_PENDING': {
      const partTypeAnalyses = action.meta.page > 1 ? state.partTypeAnalyses : [];
      return { ...state, partTypeAnalyses, newYearAnalyses: [], fetchingPartTypeAnalyses: true };
    }
    case 'FETCH_PART_TYPE_ANALYSES_REJECTED': {
      return { ...state, fetchingPartTypeAnalyses: false, error: action.payload };
    }
    case 'FETCH_PART_TYPE_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingPartTypeAnalyses: false };
      }
      const partTypeAnalyses =
        action.meta.page > 1
          ? [...state.partTypeAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, partTypeAnalyses, fetchingPartTypeAnalyses: false };
    }
    case 'FETCH_NEW_YEAR_ANALYSES_PENDING': {
      const newYearAnalyses = action.meta.page > 1 ? state.newYearAnalyses : [];
      return { ...state, newYearAnalyses, partTypeAnalyses: [], fetchingNewYearAnalyses: true };
    }
    case 'FETCH_NEW_YEAR_ANALYSES_REJECTED': {
      return { ...state, fetchingNewYearAnalyses: false, error: action.payload };
    }
    case 'FETCH_NEW_YEAR_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingNewYearAnalyses: false };
      }
      const newYearAnalyses =
        action.meta.page > 1
          ? [...state.newYearAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, newYearAnalyses, fetchingNewYearAnalyses: false };
    }
    case 'FETCH_ENGINE_BASE_ANALYSES_PENDING': {
      const engineBaseAnalyses = action.meta.page > 1 ? state.engineBaseAnalyses : [];
      return {
        ...state,
        engineBaseAnalyses,
        fetchingEngineBaseAnalyses: true,
      };
    }
    case 'FETCH_ENGINE_BASE_ANALYSES_REJECTED': {
      return { ...state, fetchingEngineBaseAnalyses: false, error: action.payload };
    }
    case 'FETCH_ENGINE_BASE_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingEngineBaseAnalyses: false };
      }
      const engineBaseAnalyses =
        action.meta.page > 1
          ? [...state.engineBaseAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, engineBaseAnalyses, fetchingEngineBaseAnalyses: false };
    }
    case 'FETCH_NEW_ENGINE_ANALYSES_PENDING': {
      const newEngineAnalyses = action.meta.page > 1 ? state.newEngineAnalyses : [];
      return {
        ...state,
        newEngineAnalyses,
        fetchingNewEngineAnalyses: true,
      };
    }
    case 'FETCH_NEW_ENGINE_ANALYSES_REJECTED': {
      return { ...state, fetchingNewEngineAnalyses: false, error: action.payload };
    }
    case 'FETCH_NEW_ENGINE_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingNewEngineAnalyses: false };
      }
      const newEngineAnalyses =
        action.meta.page > 1
          ? [...state.newEngineAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, newEngineAnalyses, fetchingNewEngineAnalyses: false };
    }
    case 'FETCH_NEW_TRANSMISSION_ANALYSES_PENDING': {
      const newTransmissionAnalyses = action.meta.page > 1 ? state.newTransmissionAnalyses : [];
      return {
        ...state,
        newTransmissionAnalyses,
        fetchingNewTransmissionAnalyses: true,
      };
    }
    case 'FETCH_NEW_TRANSMISSION_ANALYSES_REJECTED': {
      return { ...state, fetchingNewTransmissionAnalyses: false, error: action.payload };
    }
    case 'FETCH_NEW_TRANSMISSION_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingNewTransmissionAnalyses: false };
      }
      const newTransmissionAnalyses =
        action.meta.page > 1
          ? [...state.newTransmissionAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, newTransmissionAnalyses, fetchingNewTransmissionAnalyses: false };
    }
    case 'FETCH_SUB_CONFIG_ANALYSES_PENDING': {
      const subConfigAnalyses = action.meta.page > 1 ? state.subConfigAnalyses : [];
      return { ...state, subConfigAnalyses, fetchingSubConfigAnalyses: true };
    }
    case 'FETCH_SUB_CONFIG_ANALYSES_REJECTED': {
      return { ...state, fetchingSubConfigAnalyses: false, error: action.payload };
    }
    case 'FETCH_SUB_CONFIG_ANALYSES_FULFILLED': {
      if (action.payload.data.analyses === null) {
        return { ...state, fetchingSubConfigAnalyses: false };
      }
      const subConfigAnalyses =
        action.meta.page > 1
          ? [...state.subConfigAnalyses, ...action.payload.data.analyses]
          : action.payload.data.analyses;
      return { ...state, subConfigAnalyses, fetchingSubConfigAnalyses: false };
    }
    case 'FETCH_REVIEW_CATEGORIES_PENDING': {
      const reviewCategories = action.meta.page === 1 ? [] : state.reviewCategories;
      return { ...state, reviewCategories, fetchingReviewCategories: true };
    }
    case 'FETCH_REVIEW_CATEGORIES_FULFILLED': {
      const reviewCategories =
        action.meta.page > 1
          ? [...state.reviewCategories, ...action.payload.data[3]]
          : action.payload.data[3];

      return { ...state, reviewCategories, fetchingReviewCategories: false };
    }
    case 'FETCH_HISTORY_ANALYSIS_PENDING': {
      return { ...state, history: [] };
    }
    case 'FETCH_HISTORY_ANALYSIS_FULFILLED': {
      return { ...state, history: action.payload.data };
    }
    case 'FETCH_AVERAGE_SEGMENT_RECOM_ANALYSIS_PENDING': {
      return { ...state, averageSegmentRecom: [] };
    }
    case 'FETCH_AVERAGE_SEGMENT_RECOM_ANALYSIS_FULFILLED': {
      return { ...state, averageSegmentRecom: action.payload.data };
    }
    case 'FETCH_SCORE_OF_OTHER_BRANDS_ANALYSIS_FULFILLED': {
      return { ...state, scoreOfOtherBrands: action.payload.data };
    }
    case 'FETCH_LATEST_PRODUCT_CHANGES_ANALYSIS_PENDING': {
      return { ...state, fetchingLatestProductChanges: true };
    }
    case 'FETCH_LATEST_PRODUCT_CHANGES_ANALYSIS_FULFILLED': {
      const items =
        action.meta.page > 1
          ? [...state.latestProductChanges, ...action.payload.data.items]
          : action.payload.data.items;
      return { ...state, fetchingLatestProductChanges: false, latestProductChanges: items };
    }
    case 'FETCH_ANALYSES_GROUP_ITEMS_PENDING': {
      const partTypeAnalyses = state.partTypeAnalyses.map(analysis =>
        action.meta.groupKey === analysis.group_key ? { ...analysis, refresh: true } : analysis
      );
      const newYearAnalyses = state.newYearAnalyses.map(analysis =>
        action.meta.groupKey === analysis.group_key ? { ...analysis, refresh: true } : analysis
      );
      return { ...state, partTypeAnalyses, newYearAnalyses };
    }
    case 'FETCH_ANALYSES_GROUP_ITEMS_FULFILLED': {
      const partTypeAnalyses = state.partTypeAnalyses.map(analysis =>
        action.meta.groupKey === analysis.group_key ? action.payload.data.analyses[0] : analysis
      );
      const newYearAnalyses = state.newYearAnalyses.map(analysis =>
        action.meta.groupKey === analysis.group_key ? action.payload.data.analyses[0] : analysis
      );
      return { ...state, partTypeAnalyses, newYearAnalyses };
    }
    case 'UPDATE_REVIEW_CATEGORY_FULFILLED': {
      const category = state.reviewCategories.find(cat => cat.id === action.meta.categoryId);
      const partTypeAnalyses = state.partTypeAnalyses.map(review =>
        review.reference_id === action.meta.referenceId
          ? {
              ...review,
              group_key: action.payload.data.new_group_key || review.group_key,
              part_type: {
                category_id: category.id,
                category_name: category.level1_name,
                sub_category_name: category.level2_name,
                name: category.level3_name,
              },
              reference_id: category.id,
            }
          : { ...review }
      );
      return { ...state, partTypeAnalyses };
    }
    case 'CREATE_MARK_ITEMS_FULFILLED': {
      const partTypeAnalyses = state.partTypeAnalyses.map(analysis => ({
        ...analysis,
        items: analysis.items.map(item =>
          action.meta.itemIds.includes(item.id) ? { ...item, marked: 1 } : item
        ),
      }));
      const newYearAnalyses = state.newYearAnalyses.map(analysis => ({
        ...analysis,
        items: analysis.items.map(item =>
          action.meta.itemIds.includes(item.id) ? { ...item, marked: 1 } : item
        ),
      }));
      return { ...state, partTypeAnalyses, newYearAnalyses };
    }
    case 'DELETE_MARK_ITEMS_FULFILLED': {
      const partTypeAnalyses = state.partTypeAnalyses.map(analysis => ({
        ...analysis,
        items: analysis.items.map(item =>
          action.meta.itemIds.includes(item.id) ? { ...item, marked: 0 } : item
        ),
      }));
      const newYearAnalyses = state.newYearAnalyses.map(analysis => ({
        ...analysis,
        items: analysis.items.map(item =>
          action.meta.itemIds.includes(item.id) ? { ...item, marked: 0 } : item
        ),
      }));
      return { ...state, partTypeAnalyses, newYearAnalyses };
    }
    case 'CREATE_TAG_FOR_ITEMS_FULFILLED': {
      // TODO: add tags for engine base items
      const newTag = { id: action.payload.data.tag_id, name: action.meta.tagName };
      const itemIds = action.meta.itemIds;
      const lists = { partType: state.partTypeAnalyses, year: state.newYearAnalyses };
      Object.entries(lists).forEach(([key, analyses]) => {
        lists[key] = analyses.map(analysis => ({
          ...analysis,
          items: analysis.items.map(item =>
            itemIds && itemIds.includes(item.id) ? { ...item, tags: [...item.tags, newTag] } : item
          ),
        }));
      });
      return { ...state, partTypeAnalyses: lists.partType, newYearAnalyses: lists.year };
    }
    case 'DELETE_TAG_FOR_ITEMS_FULFILLED': {
      const itemIds = action.meta.itemIds;
      const lists = { partType: state.partTypeAnalyses, year: state.newYearAnalyses };
      Object.entries(lists).forEach(([key, analyses]) => {
        lists[key] = analyses.map(analysis => ({
          ...analysis,
          items: analysis.items.map(item =>
            itemIds && itemIds.includes(item.id)
              ? { ...item, tags: item.tags.filter(tag => tag.id !== action.meta.tagId) }
              : item
          ),
        }));
      });
      return { ...state, partTypeAnalyses: lists.partType, newYearAnalyses: lists.year };
    }
    case 'CONFIRM_REVIEWS_FULFILLED': {
      const confirmedVehicles =
        action.meta.vehicleBaseIds && action.payload.data.length
          ? action.payload.data.map(vehicle => ({
              ...vehicle,
              itemAnalysisId: action.meta.itemAnalysisIds[0],
            }))
          : action.payload.data;

      return {
        ...state,
        confirmedVehicleBases: [...state.confirmedVehicleBases, ...confirmedVehicles],
      };
    }
  }
  return state;
};

export default reducer;
