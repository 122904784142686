import { combineReducers } from 'redux';
import global, { AppGlobalState } from './GlobalReducer';
import modal, { AppModalState } from './ModalReducers';
import navigationBar, { AppNavigationBar } from './NavigationBarReducer';

export type AppState = {
  global: AppGlobalState;
  modal: AppModalState;
  navigationBar: AppNavigationBar;
};

const AppReducer = combineReducers<AppState>({
  global,
  modal,
  navigationBar,
});

export default AppReducer;
