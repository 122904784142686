import React from 'react';
import { Form } from 'antd';

import constants from '../../../constants/SettingsTranslation.json';
import SingleDefaultValue from './SingleDefaultValue.tsx';
import DefaultValuesPrice from './DefaultValuesPrice';
import SimpleDivider from '../../global/SimpleDivider';

class DefaultValues extends React.Component {
  updateDefaultValue = (id, value) => {
    this.props.updateDefaultValue(id, value);
  };

  settingsSegment = id => {
    const { segments, defaultValues } = this.props;
    const segment = id
      ? segments.find(segment => segment.id === id)
      : { id: 0, title: constants.default_values.global_settings };

    const isPriceSegment = id === 6;
    const values = defaultValues.filter(value => value.segment_id === id);

    return (
      <div key={segment.id} className="settings-card mb-12">
        <SimpleDivider title={segment.title} />

        <div key={segment.id} className="title settings-title">
          <Form>
            {isPriceSegment ? (
              <DefaultValuesPrice
                values={values}
                updateDefaultPriceTypeDescription={this.props.updateDefaultPriceTypeDescription}
                createDefaultPriceTypeDescription={this.props.createDefaultPriceTypeDescription}
                updateDefaultValue={this.updateDefaultValue}
                priceSheets={this.props.priceSheets}
                resources={this.props.resources}
                canManageDefaultValues={this.props.canManageDefaultValues}
              />
            ) : (
              values.map(value => (
                <SingleDefaultValue
                  key={value.id}
                  canManageDefaultValues={this.props.canManageDefaultValues}
                  value={value}
                  resources={this.props.resources}
                  updateDefaultValue={this.updateDefaultValue}
                />
              ))
            )}
          </Form>
        </div>
      </div>
    );
  };

  render() {
    const segmentIds = [...new Set(this.props.defaultValues.map(value => value.segment_id))];
    return (
      <div className="content settings-default">
        {segmentIds.map(id => this.settingsSegment(id))}
      </div>
    );
  }
}

export default DefaultValues;
