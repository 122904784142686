import React from 'react';
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';
import Dropdown from '../../../global/Dropdown';

class DigitalAssetInformationItem extends React.Component {
  render() {
    const { digitalAssetResources, selectedDigitalAsset, t, area } = this.props;
    const key = selectedDigitalAsset.id;

    return (
      <div className="digital-asset-information__item">
        <div className="form-field vertical">
          <span>{t('digitalAssets:asset_type')}</span>
          <Dropdown
            key={key}
            className="digital-asset-information__dropdown"
            noSelection
            selectedElement={selectedDigitalAsset.type_id}
            elements={digitalAssetResources.types}
            onChange={value => this.props.updateItemInformation('type_id', Number(value))}
          />
        </div>
        <div className="form-field vertical">
          <span>{t('digitalAssets:representation')}</span>
          <Dropdown
            key={key}
            noSelection={!selectedDigitalAsset.representation_id}
            className="digital-asset-information__dropdown"
            selectedElement={selectedDigitalAsset.representation_id}
            elements={digitalAssetResources.representations}
            onChange={value => this.props.updateItemInformation('representation_id', Number(value))}
          />
        </div>
        {area === 'application' && (
          <div className="form-field vertical">
            <span>{t('digitalAssets:itemRef')}</span>
            <Input
              maxLength={200}
              value={selectedDigitalAsset.item_ref}
              placeholder={t('digitalAssets:itemRef')}
              onChange={e => {
                const itemRef = e.target.value;
                this.props.updateItemInformation('item_ref', itemRef);
              }}
              size="small"
            />
          </div>
        )}
        {area === 'application' && (
          <div className="form-field vertical">
            <span>{t('digitalAssets:itemOrder')}</span>
            <Input
              value={selectedDigitalAsset.item_order}
              placeholder={t('digitalAssets:itemOrder')}
              onChange={e => {
                const itemOrder = e.target.value;
                const reg = /^[0-9]+$|^$/;
                if (reg.test(itemOrder)) this.props.updateItemInformation('item_order', itemOrder);
              }}
              size="small"
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(DigitalAssetInformationItem);
