export const setListingTypeId = (id: number) => {
  return {
    type: 'SET_LISTING_TYPE_ID',
    payload: { id },
  };
};

export const setListingTypeOptionId = (id?: number) => {
  return {
    type: 'SET_LISTING_TYPE_OPTION_ID',
    payload: { id },
  };
};

export const resetAllApplications = () => {
  return {
    type: 'RESET_ALL_APPLICATIONS',
  };
};

export const resetApplicationListings = () => {
  return {
    type: 'RESET_APPLICATION_LISTINGS',
  };
};
