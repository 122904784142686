import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { typingDone } from '../../utils/Utils';
import { ApplicationCategory } from '../../../types/application';

type CategoryDropdownProps = {
  className: string;
  defaultKeyword: string;
  displayedCategory?: ApplicationCategory;
  fetchReviewCategories: (keywords: string) => void;
  fetchNextReviewCategories: (event: any, keywords: string) => void;
  categories: ApplicationCategory[];
  updateCategory: (category: any) => void;
  allowClear: boolean;
  hasDefault?: boolean;
  fetchingCategories?: boolean;
  defaultCategory?: {
    id: number | null;
    level1_name: string | null;
    level2_name: string | null;
    level3_name: string | null;
  };
  showPartTypeId?: boolean;
  size?: 'small' | 'middle' | 'large';
  showSelectedPartType?: boolean;
  dropdownMatchSelectWidth?: boolean;
};

const CategoryDropdown: React.FC<CategoryDropdownProps> = props => {
  const { t } = useTranslation();

  const [searchKeywords, setSearchKeywords] = useState('');
  const [isDropdownClosed, setIsDropdownClosed] = useState(false);

  const fetchReviewCategories = (keywords: string) => {
    keywords = keywords || searchKeywords || props.defaultKeyword;
    props.fetchReviewCategories(keywords);
  };

  const fetchNextReviewCategories = (event: any) => {
    const keywords = searchKeywords || props.defaultKeyword;
    props.fetchNextReviewCategories(event, keywords);
  };

  const handleSearch = (keywords: string) => {
    typingDone(() => fetchReviewCategories(keywords));
    setSearchKeywords(keywords);
  };

  const dCat = props.defaultCategory;
  let categories = props.categories;
  if (
    props.displayedCategory &&
    !props.categories.find(cat => cat.id === props.displayedCategory!.id) &&
    (!props.fetchingCategories || !searchKeywords)
  )
    categories = [props.displayedCategory, ...props.categories];

  return (
    <Select
      className={props.className}
      value={props.displayedCategory?.id}
      placeholder={
        props.hasDefault && dCat
          ? `${t('application:defaultPrefix')} ${dCat.level1_name} ${dCat.level1_name ? '|' : ''} ${
              dCat.level2_name
            } ${dCat.level2_name ? '|' : ''} ${dCat.level3_name}`
          : ''
      }
      filterOption={false}
      onSearch={val => handleSearch(val)}
      showSearch
      onChange={(id: number) => {
        const category = props.categories.find(c => c.id === id);
        props.updateCategory(category);
      }}
      onPopupScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) =>
        fetchNextReviewCategories(event)
      }
      notFoundContent={props.fetchingCategories ? <Spin size="small" /> : null}
      allowClear={props.allowClear}
      size={props.size}
      onDropdownVisibleChange={
        props.showSelectedPartType ? open => setIsDropdownClosed(!open) : undefined
      }
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
    >
      {categories.map((p: any) => (
        <Select.Option key={p.id} value={p.id}>
          {isDropdownClosed && props.showSelectedPartType
            ? p.level3_name
            : `${p.level1_name} ${p.level1_name ? '|' : ''}  ${p.level2_name} ${
                p.level2_name ? '|' : ''
              } ${p.level3_name} ${
                props.showPartTypeId && p.part_type_id ? ` (${p.part_type_id})` : ''
              }`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CategoryDropdown;
