import { Empty, Spin } from 'antd';
import React from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { Epid } from '../../../../types/ebay';

type EbayEpidListProps = {
  epids: Epid[];
  fetchingEpids: boolean;
};

export default class EbayItemSettings extends React.Component<EbayEpidListProps> {
  render() {
    const { fetchingEpids, epids } = this.props;

    return (
      <React.Fragment>
        <div className="ebay__epid-list">
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ height, width }}>
                <Table
                  height={height}
                  width={width - 2}
                  headerClassName="ebay__epid-list-header"
                  rowClassName="ebay__epid-list-row"
                  headerHeight={50}
                  rowCount={epids.length}
                  dataSource={epids}
                  rowHeight={45}
                  rowGetter={({ index }) => epids[index]}
                  noRowsRenderer={() =>
                    fetchingEpids ? (
                      <div className="mt-10">
                        <Spin className="spinner-center" />
                      </div>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                >
                  <Column label="ePid" dataKey="epid" width={100} />
                  <Column label="Display Name" dataKey="display_name" width={240} />
                  <Column label="Trim" dataKey="trim" width={240} />
                  <Column label="Engine" dataKey="engine_name" width={240} flexGrow={1} />
                </Table>
              </div>
            )}
          </AutoSizer>
        </div>
      </React.Fragment>
    );
  }
}
