import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type ShowChannelLogsButtonProps = {
  timeStamp?: string;
  handleShowLogs: () => void;
  confirmText?: string;
  hideLastConnection?: boolean;
};

const ShowChannelLogsButton: React.FC<ShowChannelLogsButtonProps> = props => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('flex items-center', {
        'justify-end': props.hideLastConnection,
      })}
    >
      {!props.hideLastConnection && (
        <React.Fragment>
          <div className="flex-initial leading-normal text-gray-900">
            {`${t('channel:lastConnection')}:`}
          </div>
          <div className="flex-auto leading-normal ml-1 text-gray-900">
            {props.timeStamp ? (
              <div className="flex flex-row items-center">
                <div className="pr-1">{props.timeStamp}</div>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </div>
            ) : (
              ''
            )}
          </div>
        </React.Fragment>
      )}
      <Popconfirm
        title={props.confirmText}
        okText={t('common:yes')}
        cancelText={t('common:cancel')}
        onConfirm={props.handleShowLogs}
        placement="leftTop"
      >
        <Button
          onClick={() => props.handleShowLogs}
          size="small"
          className="flex-initial leading-normal"
        >
          {t('channel:connectionHistory')}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default ShowChannelLogsButton;
