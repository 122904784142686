import axios from 'axios';
import * as utils from '../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;

export function fetchApplicationListing(
  filterId: number,
  typeId: number,
  typeOptionId?: number,
  page = 1
) {
  if (fetchCancelToken) fetchCancelToken();

  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'type_id', values: typeId },
    { name: 'type_option_id', values: typeOptionId },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_APPLICATION_LISTINGS',
    payload: axios.get(`${getNonGwApiRoot()}/items/applications/listings${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
    meta: { page },
  };
}

export function fetchApplicationItem(itemId: number) {
  const urlParams = [{ name: 'item_id', values: itemId }];

  return {
    type: 'FETCH_APPLICATION_ITEM',
    payload: axios.get(`${getNonGwApiRoot()}/items${utils.createURL(urlParams)}`),
  };
}
