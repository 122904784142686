import React from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';

import Button from '../../../global/Button';
import {
  placeholder,
  add_file as addFile,
  upload,
} from '../../../../constants/DigitalAssetsTranslation.json';
import constants from '../../../../constants/DigitalAssetsConstants.json';
import actions from '../../../../actions/items/digital_assets';
import {
  resetUploadInformation,
  setFileUploadInformation,
} from '../../../../actions/parent/digital_asset_upload';
import applicationActions from '../../../../actions/items/application';
import marketingCopyActions from '../../../../actions/parent/marketing_copy/index.ts';
import { hideModal } from '../../../../actions/app/modal';
import { sortDigitalAssetsTypes } from '../../../../selectors/digital_assets/digitalAssetsSelector';

import ModalContent from '../../../global/Modals/ModalContent';
import SourceURL from './SourceURL';
import FileNameType from './FileNameType';
import AssetType from './AssetType';
import Representations from './Representations';
import DigitalAssetTypePreview from '../digital_asset_preview/DigitalAssetTypePreview';
import { isUploadUrlValid } from '../../../../utils/Utils';

class DigitalAssetUploadModal extends React.Component {
  state = {
    submitError: {},
  };

  componentDidMount() {
    if (!this.props.showParentDigitalAssetsDrawer) this.props.dispatch(resetUploadInformation());
    this.props.dispatch(actions.fetchDigitalAssetsTypesSorting(this.props.brandId));
    if (this.props.digitalAssetUpload.file) {
      this.setFile();
    }
  }

  setFile = () => {
    const { file, assetArea } = this.props.digitalAssetUpload;
    const name = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => this.setFileUploadInformation('localFilePath', event.target.result);
    const formData = new FormData();
    formData.append('file', file);

    const fileTypeId = this.readFileTypeId(name);
    const fileTypeDetectedFromSource = fileTypeId !== null;
    const formattedFileName = this.formatFileName(name);

    this.setFileUploadInformation('file', formData);
    this.setFileUploadInformation('fileName', formattedFileName);
    this.setFileUploadInformation('fileTypeId', fileTypeId);
    this.setFileUploadInformation('fileTypeDetectedFromSource', fileTypeDetectedFromSource);

    if (assetArea === 'application') {
      const formattedAssetName = this.formatAssetName(name);
      this.setFileUploadInformation('assetName', formattedAssetName);
    }
  };

  readFileTypeId = fileName => {
    const { file_types: digitalAssetFileTypes } = this.props.resources;
    const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
    const findFileType = digitalAssetFileTypes.find(
      type => type.code.toLowerCase() === fileType.toLowerCase()
    );
    return findFileType ? findFileType.id : null;
  };

  readFileNameFromSource = url => url.substr(url.lastIndexOf('/') + 1);

  setFileUploadInformation = (key, value) => {
    this.setState({ [key]: value });
    this.props.dispatch(setFileUploadInformation(key, value));
  };

  formatFileName = fileName => {
    // fileName has max length but needs extension
    if (fileName.length < 80) return fileName;
    const extensionStart = fileName.lastIndexOf('.');
    const pureFileName = fileName.substr(0, extensionStart);
    const extension = fileName.substr(extensionStart);
    const extensionLength = extension.length;
    const fileNameShort = pureFileName.substr(0, 80 - extensionLength);
    return fileNameShort + extension;
  };

  formatAssetName = fileName => {
    const extensionStart = fileName.lastIndexOf('.');
    const pureFileName = fileName.substr(0, extensionStart);
    if (pureFileName.length < 45) return pureFileName;
    return pureFileName.substr(0, 45);
  };

  setSourceUrl = url => {
    const { assetArea } = this.props.digitalAssetUpload;
    const { url: mainUrl } = queryString.parseUrl(url);

    const fileTypeId = this.readFileTypeId(mainUrl);
    const fileTypeDetectedFromSource = fileTypeId !== null;
    const isValidUrl = isUploadUrlValid(encodeURI(mainUrl));
    const fileName = this.readFileNameFromSource(mainUrl);
    const formattedFileName = this.formatFileName(fileName);

    this.setFileUploadInformation('sourceUrl', url);
    this.setFileUploadInformation('fileTypeId', fileTypeId);
    this.setFileUploadInformation('fileTypeDetectedFromSource', fileTypeDetectedFromSource);
    this.setFileUploadInformation('fileName', formattedFileName);
    this.setFileUploadInformation('isValidUrl', isValidUrl);

    if (assetArea === 'application') {
      const formattedAssetName = this.formatAssetName(fileName);
      this.setFileUploadInformation('assetName', formattedAssetName);
    }
  };

  showSubmitError = () => {
    const { submitError } = this.state;
    const missingUrl = submitError.sourceUrl ? placeholder.source : '';
    const missingFileName = submitError.fileName ? placeholder.file_name : '';
    const missingAssetName = submitError.assetName ? placeholder.asset_name : '';
    const missingAssetType = submitError.assetTypeId ? placeholder.asset_type : '';
    const invalidSource = submitError.isValidUrl ? placeholder.invalid_source : '';

    const assetNameExist = this.props.digitalAssetUpload.assetNameExistError
      ? upload.asset_name_existing
      : '';
    const assetAlreadyLinked = submitError.existingFile ? upload.alreadyLinkedItem : '';

    const missing = [
      missingUrl,
      missingFileName,
      missingAssetName,
      missingAssetType,
      invalidSource,
    ];
    const missingFilter = missing.filter(val => val).join(', ');

    const missingMessage = `${placeholder.please_define}: ${missingFilter}`;
    const message =
      this.props.digitalAssetUpload.assetNameExistError || submitError.existingFile
        ? (submitError.existingFile && assetAlreadyLinked) || assetNameExist
        : missingMessage;

    if (
      missingFilter.length !== 0 ||
      this.props.digitalAssetUpload.assetNameExistError ||
      submitError.existingFile
    ) {
      return (
        <span className="digital__assets-submit-error">
          <InfoCircleOutlined className="mr-1" />
          <span>{message}</span>
        </span>
      );
    }
  };

  submit = () => {
    // this submit validate if all necessary values are given
    // the errors will get saved to a errorObj in the state so it can be shown in the modal
    // the key value evaluate to true if it is empty/not given
    const { newFileUploadType, assetArea, assetInformation } = this.props.digitalAssetUpload;
    const { fileName, sourceUrl, assetTypeId, isValidUrl, assetName } = assetInformation;
    const { parentAssetInfo } = this.props;

    let valuesMustBeGivenExternal = {
      fileName,
    };

    const needsSourceUrl = newFileUploadType !== constants.popupType.local;

    if (needsSourceUrl) {
      valuesMustBeGivenExternal = { sourceUrl, isValidUrl, ...valuesMustBeGivenExternal };
    }

    const errorObj = {};

    if (newFileUploadType === constants.popupType.parentDigitalAssets) {
      if ((!assetTypeId || parentAssetInfo.existingFile) && assetArea !== 'marketing_copy') {
        this.setState({
          submitError: { assetTypeId: !assetTypeId, existingFile: parentAssetInfo.existingFile },
        });
      } else this.linkDigitalAsset();
    } else {
      if (assetArea === 'application' || assetArea === 'item') {
        valuesMustBeGivenExternal = { ...valuesMustBeGivenExternal, assetTypeId };
      }

      if (assetArea === 'application') {
        valuesMustBeGivenExternal = { ...valuesMustBeGivenExternal, assetName };
      }

      // check here if the value is empty of the required types
      Object.keys(valuesMustBeGivenExternal).map(key => {
        errorObj[key] = !valuesMustBeGivenExternal[key];
      });
      // check if every key value is false
      const allGiven = Object.keys(errorObj).every(k => !errorObj[k]);
      // only than create a asset
      if (allGiven) {
        this.createDigitalAsset();
      } else {
        this.setState({ submitError: errorObj });
      }
    }
  };

  linkDigitalAsset = () => {
    const { parentAssetInfo } = this.props;
    const { assetArea, assetInformation } = this.props.digitalAssetUpload;
    const { assetTypeId, representationTypeId, assetName } = assetInformation;
    const itemId = this.props.selectedItemId;

    if (assetArea === 'item') {
      this.props.dispatch(
        actions.linkDigitalAsset(
          itemId,
          parentAssetInfo?.digitalAssetId,
          assetTypeId,
          representationTypeId
        )
      );
    }
    if (assetArea === 'application') {
      const applicationId = this.props.selectedApplicationId;
      this.props.dispatch(
        applicationActions.linkApplicationDigitalAsset(
          applicationId,
          parentAssetInfo?.digitalAssetId,
          assetTypeId,
          representationTypeId,
          assetName
        )
      );
    }

    if (assetArea === 'marketing_copy') {
      const selectedMarketingCopyId = this.props.selectedMarketingCopyId;
      this.props.dispatch(
        marketingCopyActions.linkMarketingCopyDigitalAsset(
          selectedMarketingCopyId,
          parentAssetInfo?.digitalAssetId,
          representationTypeId,
          assetName
        )
      );
    }
  };

  createDigitalAsset = (newFileName, overwrite = null) => {
    const { newFileUploadType, assetArea, assetInformation } = this.props.digitalAssetUpload;
    const { fileTypeId, sourceUrl, assetTypeId, representationTypeId, file, assetName } =
      assetInformation;

    // in case want to save with new fileName -- from import error(duplicate)
    let { fileName } = assetInformation;
    if (newFileName) {
      fileName = newFileName;
    }

    const downloadUri = newFileUploadType === constants.popupType.url ? sourceUrl : null;
    const uri = newFileUploadType === constants.popupType.external ? sourceUrl : null;
    const selectedBrandId = this.props.selectedBrandId;

    if (assetArea === 'item') {
      const itemId = this.props.selectedItemId;
      this.props.dispatch(
        actions.createDigitalAsset(
          assetTypeId,
          file,
          fileName,
          itemId,
          overwrite,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          null,
          selectedBrandId
        )
      );
    }

    if (assetArea === 'application') {
      const applicationId = this.props.selectedApplicationId;
      this.props.dispatch(
        applicationActions.createApplicationDigitalAssets(
          assetTypeId,
          file,
          fileName,
          applicationId,
          overwrite,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          assetName,
          selectedBrandId
        )
      );
    }

    if (assetArea === 'marketing_copy') {
      this.props.dispatch(
        marketingCopyActions.createMarketingCopyDigitalAsset(
          file,
          fileName,
          overwrite,
          downloadUri,
          uri
        )
      );
    }
  };

  hideModal = () => {
    this.props.dispatch(hideModal());
  };

  showInnerContainer = () => {
    const { newFileUploadType, assetInformation } = this.props.digitalAssetUpload;
    const { sourceUrl, fileTypeId, localFilePath, assetName, assetTypeId, representationTypeId } =
      assetInformation;
    const addByUrl = newFileUploadType === constants.popupType.url;
    const externalSource = newFileUploadType === constants.popupType.external;

    return (
      <div className="digital-assets__inner-container">
        <div className="digital-assets__preview-image">
          <p className="digital-assets__preview-title">{upload.preview}</p>
          <div className="flex flex-1 overflow-hidden">
            <DigitalAssetTypePreview
              asset={{
                file_type_id: fileTypeId,
                uri: sourceUrl || localFilePath,
              }}
              digitalAssetResources={this.props.resources}
            />
          </div>
        </div>

        <div className="digital-assets__options">
          {(addByUrl || externalSource) && (
            <SourceURL sourceUrl={sourceUrl} setSourceUrl={this.setSourceUrl} />
          )}
          <FileNameType
            resources={this.props.resources}
            setFileUploadInformation={this.setFileUploadInformation}
            {...assetInformation}
          />
          {this.props.digitalAssetUpload.assetArea === 'application' && (
            <div className="form-field">
              <div className="form-field-name">{upload.asset_name}</div>
              <input
                className="form-field-content"
                type="text"
                value={assetName}
                maxLength="45"
                onChange={e => this.setFileUploadInformation('assetName', e.target.value)}
              />
            </div>
          )}
          {this.props.digitalAssetUpload.assetArea !== 'marketing_copy' && (
            <React.Fragment>
              <AssetType
                resources={this.props.resources}
                assetTypeId={assetTypeId}
                setFileUploadInformation={this.setFileUploadInformation}
              />
              <Representations
                representationTypeId={representationTypeId}
                setFileUploadInformation={this.setFileUploadInformation}
              />
            </React.Fragment>
          )}
          <div className="digital-assets__buttons">
            <Button name={placeholder.cancel} color="red" hover onClick={this.hideModal} />
            <Button
              name={placeholder.save_asset}
              color="blue"
              inverted
              hover
              onClick={this.submit}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      digitalAssetUpload: { newFileUploadType },
    } = this.props;
    let title;
    if (newFileUploadType === constants.popupType.url) {
      title = addFile.by_url;
    } else if (newFileUploadType === constants.popupType.local) {
      title = addFile.from_pc;
    } else if (newFileUploadType === constants.popupType.external) {
      title = addFile.external;
    } else if (newFileUploadType === constants.popupType.parentDigitalAssets) {
      title = addFile.linkRecordInPdm;
    }
    return (
      <ModalContent clickOutside>
        <div className="digital-assets__add-container">
          <React.Fragment>
            <div className="digital-assets__add-title-wrapper">
              <span className="title">{title}</span>
              <span>{this.state.submitError && this.showSubmitError()}</span>
            </div>
            {this.showInnerContainer()}
          </React.Fragment>
        </div>
      </ModalContent>
    );
  }
}

function mapStateToProps(state) {
  const sortedResources = sortDigitalAssetsTypes(state);
  return {
    brandId: state.parent.brands.selectedBrandId,
    digitalAssetUpload: state.parent.digitalAssetUpload,
    resources: sortedResources,
    selectedItemId: state.catalogue.catalogue.selectedItemIds[0],
    selectedApplicationId: state.items.application.selectedApplicationId,
    selectedMarketingCopy: state.parent.marketingCopy.selectedMarketingCopy,
    selectedBrandId: state.parent.brands.selectedBrandId,
    defaultValues: state.settings.defaultValues,
    selectedMarketingCopyId: state.parent.marketingCopy.selectedMarketingCopyId,
    showParentDigitalAssetsDrawer: state.parent.digitalAsset.showParentDigitalAssets,
  };
}

export default connect(mapStateToProps)(DigitalAssetUploadModal);
