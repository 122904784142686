import React, { useCallback, useMemo } from 'react';
import { FormikValues, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import resourcesMap from '../../../constants/ApplicationResourcesMapping.json';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { Application, ApplicationType } from '../../../../types/application';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { mappedItemApplicationGridGroupRows } from '../../../selectors/all_applications/allApplicationsSelector';
import { StandardResource } from '../../../../types/resources';
import { getVehicleResources } from '../../../selectors/applications/applicationsSelector';
import ApplicationSplitTable from './ApplicationSplitTable';
import { ApplicationElement } from '../../../../types/all_application_validation';
import { convertConfigNameToIds } from '../../../utils/ApplicationUtils';
import { updateApplication } from '../../../actions/items/application/update';
import { createApplication } from '../../../actions/items/application/create';

const resourcesMapping = resourcesMap as { [key: string]: string };

type ApplicationSplitDrawerProps = {
  visible: boolean;
  selectedRowIds: string[];
  onClose: () => void;
};

const ApplicationSplitDrawer: React.FC<ApplicationSplitDrawerProps> = ({
  visible,
  selectedRowIds,
  onClose,
}) => {
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItem,
    brandId,
    brands,
    mappedApplicationGroupRows,
    columns,
    applications,
    vehicleResources,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      brandId: state.parent.brands.selectedBrandId,
      brands: state.parent.brands.brands,
      mappedApplicationGroupRows: mappedItemApplicationGridGroupRows(state) as any,
      columns: state.items.application.columns,
      applications: state.items.application.applications,
      vehicleResources: getVehicleResources(state),
    };
  });

  const [newApplication, setNewApplication] = React.useState<Application>();
  const [updatedApplication, setUpdatedApplication] = React.useState<Application>();

  const applicationId = mappedApplicationGroupRows[0].application_id;
  const application = useMemo(
    () => applications.find((a: Application) => a.item_application_id === applicationId),
    [applicationId, applications]
  );

  const [groupRows, setGroupRows] = React.useState(
    mappedApplicationGroupRows.map((r: any) => ({
      ...r,
      group: selectedRowIds.includes(r.id) ? 0 : 1,
      year: r.years,
    }))
  );

  const applicationType = Object.keys(groupRows[0] || {}).find(key => {
    return ['mfrs', 'equipment_models'].includes(key);
  })
    ? ApplicationType.EQUIPMENT
    : ApplicationType.VEHICLE;

  const allSubconfigValues = useCallback(
    (configName: string) => {
      // get all values to a specific config name e.g. position
      // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
      if (resourcesMapping.hasOwnProperty(configName)) {
        configName = resourcesMapping[configName];
      }

      return vehicleResources && vehicleResources[configName] ? vehicleResources[configName] : [];
    },
    [vehicleResources]
  );

  const convertFilterModelToApplication = (filterModel: { [key: string]: any }) => {
    const application: ApplicationElement = {} as ApplicationElement;
    Object.keys(filterModel).forEach(key => {
      const values = filterModel[key].values;
      const valueIds = values.map((valueName: string) => {
        return allSubconfigValues(key).find((config: StandardResource) => {
          // years are numbers
          return config.name.toString() === valueName;
        }).id;
      });

      const applicationConfigName = key === 'year' ? 'years' : convertConfigNameToIds(key);

      application[applicationConfigName] = valueIds;
    });

    return application;
  };

  const brand = brands.find(brand => brand.id === brandId);

  const handleSubmit = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;

    const newAppl = await dispatch(createApplication(selectedItem.id, selectedItem.category_id));
    await dispatch(
      updateApplication(
        { ...newApplication, item_application_id: newAppl.value.data.id },
        selectedItem.id
      )
    );
    await dispatch(updateApplication(updatedApplication!, selectedItem.id));

    setSubmitting(false);
    onClose();
  };

  return (
    <DrawerFormik
      visible={visible}
      onClose={onClose}
      title={`${brand?.name}: ${selectedItem.part_number}`}
      width="95%"
      initialValues={{ groupRows }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ handleSubmit, values, setFieldValue, setFieldTouched }) => {
        const updateGroups = (configName: string, filterValues: any) => {
          const filterKeys = Object.keys(filterValues);

          const modelApplication = convertFilterModelToApplication(filterValues);

          const newAppl = { ...application, ...modelApplication };
          const updatedAppl = { ...application };

          if (Object.keys(modelApplication).length === 1)
            Object.keys(modelApplication).forEach(key => {
              const values = application[key].filter(
                (id: number) => !modelApplication[key].includes(id)
              );
              updatedAppl[key] = values;
            });

          let newGroupRows = groupRows.map((r: any) => {
            let group: number | undefined;
            filterKeys.forEach(key => {
              const values = filterValues[key].values;
              group =
                // @ts-ignore
                values.includes(r[key].toString()) && (group === undefined || group === 0) ? 0 : 1;
            });

            return { ...r, group };
          });

          if (Object.keys(modelApplication).length > 1) {
            const newApplicationRows = newGroupRows
              .filter((a: any) => a.group === 0)
              .map((a: any) => ({ ...a, group: 1 }));
            newGroupRows = [...newApplicationRows, ...newGroupRows];
          }

          setNewApplication(newAppl);
          setUpdatedApplication(updatedAppl);
          setFieldValue('groupRows', newGroupRows);
        };

        return (
          <React.Fragment>
            <ApplicationSplitTable
              columns={columns}
              mappedApplicationRows={values.groupRows}
              applicationType={applicationType}
              updateGroups={updateGroups}
            />
          </React.Fragment>
        );
      }}
    </DrawerFormik>
  );
};

export default ApplicationSplitDrawer;
