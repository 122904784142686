import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ChannelContainer from '../containers/channel/ChannelContainer';
import ReceiverContainer from '../containers/receiver/ReceiverContainer';
import DeliveryLogsContainer from '../containers/distribution_logs/DeliveryLogsContainer';
import APISettingsMenu from '../pages/distribution/APISettingsMenu';

const ChannelRoutes = () => (
  <Routes>
    <Route path="history" element={<DeliveryLogsContainer />} />
    <Route path="channels" element={<ChannelContainer />} />
    <Route path="receiver" element={<ReceiverContainer />} />
    <Route path="api/*" element={<APISettingsMenu />} />
  </Routes>
);

export default ChannelRoutes;
