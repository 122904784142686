import { Button, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import ShopifyFilterPreview from './ShopifyFilterPreview';
import ShopifyFilterDrawer from './ShopifyFilterDrawer';
import { FilterType } from '../../../../types/filter';
import {
  createShopifyApplicationFilter,
  createShopifyFilter,
  deleteShopifyFilterApplicationParameter,
  removeShopifyFilterApplicationParameterValues,
  setShopifyFilter,
} from '../../../actions/channel/update';
import { AsyncDispatch } from '../../../../types/global';
import { fetchShopifyChannelFilters } from '../../../actions/channel/fetch';
import { ApplicationState } from '../../../reducers';

type ShopifyVehicleFilterProps = {
  fetchingFilterList: boolean;
  filterList: FilterType[];
  shopifyId: number;
  handleDeleteFilter: (filterId: number) => void;
};

const ShopifyVehicleFilter: React.FC<ShopifyVehicleFilterProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [showFilterSettings, setShowFilterSettings] = React.useState<boolean>(false);

  const { filter } = useSelector((state: ApplicationState) => ({
    filter: state.channel.channels.shopifyFilter,
  }));

  const handleEditFilter = async (filter: FilterType) => {
    await dispatch(setShopifyFilter(filter));
    setShowFilterSettings(true);
  };

  const handleCreateFilter = async () => {
    dispatch(setShopifyFilter({} as FilterType));
    dispatch(createShopifyFilter(props.shopifyId)).then(res => {
      dispatch(createShopifyApplicationFilter(res.value.data.id)).then(() => {
        setShowFilterSettings(true);
      });
    });
  };

  const getFilterParameterById = (filter: any, sectionKey: string, id: string | null) => {
    let parameter: any;
    Object.values(filter[sectionKey]).forEach((block: any) => {
      if (block.parameters.hasOwnProperty(id)) parameter = block.parameters[id!];
    });
    return parameter;
  };

  const removeFilterValues = (parameterKey: string, ids: string[]) => {
    const filterObj = cloneDeep(filter);
    const parameter = getFilterParameterById(filterObj, 'filter_application', parameterKey);
    Object.keys(parameter.values).forEach(valueId => {
      if (ids.includes(valueId)) delete parameter.values[valueId];
    });
    dispatch(setShopifyFilter(filterObj));
  };

  const removeFilterParameterById = (sectionKey: string, id: string) => {
    const filterObj: any = cloneDeep(filter);

    Object.values(filterObj[sectionKey]).forEach((block: any) => {
      if (block.parameters.hasOwnProperty(id)) delete block.parameters[id];
    });
    dispatch(setShopifyFilter({ ...filterObj }));
    dispatch(deleteShopifyFilterApplicationParameter(id));
  };

  const removeFilterParameterValue = (
    sectionKey: string,
    parameterKey: string,
    valueKey: string
  ) => {
    // if last value and option "is", "is not", "with any value, excluding" delete parameter
    const parameter = getFilterParameterById(filter, sectionKey, parameterKey);
    if (
      parameter &&
      [1, 2, 4].includes(parameter.option_id) &&
      Object.keys(parameter.values).length === 1
    ) {
      removeFilterParameterById(sectionKey, parameterKey); // update filter
    } else if (valueKey) {
      removeFilterValues(parameterKey, [valueKey]);
      if (sectionKey === 'filter_application') {
        dispatch(removeShopifyFilterApplicationParameterValues(valueKey));
      }
    }
  };

  const getSectionFilter = (filter: FilterType, sectionKey: string) => {
    // @ts-ignore
    if (filter[sectionKey]) return Object.values(filter[sectionKey])[0];
  };

  const addFilterParameterValue = (
    parameterId: string | null,
    subconfigName: string,
    resourceId: number | number[],
    resourceName?: string
  ) => {
    const filterObj = cloneDeep(filter);
    const sectionFilter = getSectionFilter(filterObj, 'filter_application');
    let parameter = getFilterParameterById(filterObj, 'filter_application', parameterId);

    // add value temporary to the parameter
    const name = resourceName;
    if (!parameterId) {
      parameter = { option_id: 1, resource: subconfigName, values: {} };
      // @ts-ignore
      sectionFilter.parameters.temp = parameter;
    }

    if (subconfigName === 'years' && resourceId instanceof Array) {
      resourceId.forEach((id, index) => {
        parameter.values[`temp${index}`] = { resource_id: id, resource_name: id };
      });
    } else {
      parameter.values.temp = { resource_id: resourceId, resource_name: name };
    }

    dispatch(setShopifyFilter({ ...filterObj }));
  };

  return (
    <div className="flex flex-col h-full m-10">
      <div className="flex flex-row justify-between mb-6">
        <Input
          value={searchKeyword}
          onChange={e => setSearchKeyword(e.target.value)}
          prefix={<SearchOutlined className="shopify_vehicle-filter__search" />}
          placeholder={t('channel:searchVehicle')}
          className="shopify__vehicle-filter-search"
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          className="add-vehicle"
          onClick={() => handleCreateFilter()}
          data-testid="add-vehicle-filter"
        >
          {t('channel:addVehicle')}
        </Button>
      </div>
      <div className="overflow-hidden flex-1 h-auto ">
        <ShopifyFilterPreview
          searchKeyword={searchKeyword}
          fetching={props.fetchingFilterList}
          filtersList={props.filterList}
          handleDeleteFilter={props.handleDeleteFilter}
          handleEditFilter={handleEditFilter}
        />
      </div>
      {showFilterSettings && filter && !props.fetchingFilterList && (
        <ShopifyFilterDrawer
          removeFilterValuesLocal={removeFilterValues}
          removeFilterParameterValue={removeFilterParameterValue}
          editShopifyFilter={filter}
          visible={showFilterSettings}
          handleClose={async (deleteFilterId?: number) => {
            await dispatch(setShopifyFilter({} as FilterType));
            setShowFilterSettings(false);
            if (!deleteFilterId) dispatch(fetchShopifyChannelFilters(props.shopifyId));
            if (deleteFilterId) props.handleDeleteFilter(deleteFilterId);
          }}
          addFilterParameterValue={addFilterParameterValue}
        />
      )}
    </div>
  );
};

export default ShopifyVehicleFilter;
