import axios from 'axios';
import { message } from 'antd';
import debounce from 'lodash/debounce';
import { Dispatch } from 'redux';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { UpdateLanguageSetting, LanguageTranslation } from '../../../../types/language';
import * as utils from '../../../utils/Utils';
import { getFileExtension } from '../../../utils/ImportExportUtils';
import { EXCEL_FILE_EXTENSION } from '../../../constants/ImportExportConstants';
import { AsyncDispatch } from '../../../../types/global';
import { ExportTypes, ImportTypes } from '../../../../types/import_export';
import { fetchPresignedUrl } from '../../importer';

export function updateAccountLanguages(accountLanguages: UpdateLanguageSetting[]) {
  return {
    type: 'UPDATE_ACCOUNT_LANGUAGES',
    payload: axios.patch(`${getApiRoot()}/accounts/languages`, {
      account_languages: accountLanguages,
    }),
  };
}

export function updateTranlations({
  translationTypeId,
  page = 1,
  translations,
  emptyLanguageIds,
}: {
  translationTypeId: number;
  page?: number;
  translations: LanguageTranslation[];
  emptyLanguageIds?: number[];
}) {
  const urlParams = utils.createURL([
    { name: 'translation_type_id', values: translationTypeId },
    { name: 'page', values: page },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'empty_language_ids', value: emptyLanguageIds },
  ]);
  return {
    type: 'UPDATE_TRANSLATIONS',
    meta: { translationSegmentId: translationTypeId, translations },
    payload: axios.patch(
      `${getApiRoot()}/accounts/languages/translations${urlParams}`,
      translations
    ),
  };
}

export function createNewUpload(uuid: string, fileName: string, importType: ImportTypes) {
  return {
    type: '@IMPORTER/PARENT_CREATE_NEW_UPLOAD',
    payload: {
      id: uuid,
      importType,
      fileName,
    },
  };
}

export const updateUploadProgress = (id: string, { loaded, total }: any) => {
  const progress = Number(((loaded / total) * 100).toFixed());
  return {
    type: '@IMPORTER/PARENT_UPDATE_UPLOAD_PROGRESS',
    payload: {
      id,
      progress,
    },
  };
};

export function uploadFinished(id: string, logId: number, vcdbSegment?: boolean) {
  return {
    type: '@IMPORTER/PARENT_UPLOAD_FINISHED',
    payload: {
      id,
      logId,
      vcdbImportLogId: vcdbSegment ? logId : null,
    },
  };
}

export function uploadFailed(id: string) {
  return {
    type: '@IMPORTER/PARENT_UPLOAD_FAILED',
    payload: {
      id,
    },
  };
}

function createTransExcelImport(file: any, segmentId: number, uuid: string) {
  return async (dispatch: AsyncDispatch) => {
    dispatch(createNewUpload(uuid, file.name, ImportTypes.EXCEL));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    try {
      const params = utils.createURL([
        { name: 'translation_type_ids', values: segmentId },
        { name: 'file_name', values: filename },
      ]);
      const response = await axios.post(`${getApiRoot()}/imports/translation_files${params}`);
      dispatch(uploadFinished(uuid, response.data.import_log_id));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}

export function importExcelFile(file: any, segmentId: number) {
  return (disptach: any) => {
    const extension = getFileExtension(file.name);
    if (extension !== EXCEL_FILE_EXTENSION) {
      message.error('invalid file');
    } else {
      disptach(createTransExcelImport(file, segmentId, file.uid));
    }
  };
}

export function exportExcelFile(exportData: any) {
  return async (dispatch: Dispatch) => {
    let exportRes;
    try {
      exportRes = await axios.post(`${getApiRoot()}/exports/translation_files`, {
        ...exportData,
      });

      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType: ExportTypes.EXCEL,
          brandId: 0,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}
