import React from 'react';
import { Dropdown, DropDownProps, MenuProps } from 'antd';

type AntDropdownProps = {
  className?: string;
  menu: MenuProps;
  disabled?: boolean;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  onFocus: React.FocusEventHandler<HTMLElement>;
  trigger: ['click', 'hover', 'contextMenu'];
} & DropDownProps;

const AntDropdown: React.FC<AntDropdownProps> = props => {
  return <Dropdown {...props}>{props.children}</Dropdown>;
};

export default AntDropdown;
