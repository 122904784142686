import React from 'react';
import { AutoSizer, Table, Column, SortDirection, SortDirectionType } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, Popover, Tooltip } from 'antd';
import { typingDone, localDateString } from '../../../utils/Utils';
import { ExtendedResources } from '../../../../types/resources';
import TableSortIcons from '../../global/TableSortIcons';

type ChannelPlanListProps = {
  brandPlans: {
    brand: string;
    brandId: number;
    authorizedByBrandAt?: string;
    fileTypes: number[];
  }[];
  fileTypes: ExtendedResources[];
  isReceiverUser: boolean;
  onChange: (typeId: number, brandId: number) => void;
  onColumnSelect: (typeId: number) => void;
  onRowSelect: (typeId: number) => void;
};

const ChannelPlanList: React.FC<ChannelPlanListProps> = ({
  brandPlans,
  fileTypes,
  isReceiverUser,
  onChange,
  onColumnSelect,
  onRowSelect,
}) => {
  const { t } = useTranslation();

  const [sortBy, setSortBy] = React.useState('brand');
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(SortDirection.ASC);
  const [keywords, setKeywords] = React.useState('');

  const sortList = ({
    sortBy,
    sortDirection,
    filterKeywords = keywords,
  }: {
    sortBy: string;
    sortDirection: SortDirectionType;
    filterKeywords?: string;
  }) => {
    const lowerKeywords = filterKeywords.toLowerCase();
    return brandPlans
      .filter(plan => plan.brand.toLowerCase().includes(lowerKeywords))
      .sort((a, b) => {
        if (sortBy === 'brand') {
          if (a.brand < b.brand) return sortDirection === 'ASC' ? -1 : 1;
          if (a.brand > b.brand) return sortDirection === 'ASC' ? 1 : -1;
          return 0;
        }
        if (sortBy === 'date') {
          if ((a.authorizedByBrandAt || '') < (b.authorizedByBrandAt || ''))
            return sortDirection === 'ASC' ? -1 : 1;
          if ((a.authorizedByBrandAt || '') > (b.authorizedByBrandAt || ''))
            return sortDirection === 'ASC' ? 1 : -1;
          return 0;
        }
        if (sortBy === '1' || sortBy === '2' || sortBy === '3' || sortBy === '10') {
          if (a.fileTypes.includes(Number(sortBy)) === b.fileTypes.includes(Number(sortBy))) {
            if (a.brand < b.brand) return -1;
            if (a.brand > b.brand) return 1;
            return 0;
          }
          if (a.fileTypes.includes(Number(sortBy))) return sortDirection === 'ASC' ? -1 : 1;
          if (b.fileTypes.includes(Number(sortBy))) return sortDirection === 'ASC' ? 1 : -1;
          return 0;
        }
        return 0;
      });
  };

  const [sortedList, setSortedList] = React.useState(sortList({ sortBy, sortDirection }));

  React.useEffect(() => {
    const sortedList = sortList({ sortBy, sortDirection });
    setSortedList(sortedList);
  }, [brandPlans]);

  const handleKeywordChange = (input: string) => {
    setKeywords(input);
    typingDone(() => {
      const sortedList = sortList({ sortBy, sortDirection, filterKeywords: input });
      setSortedList(sortedList);
    });
  };

  const sort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string;
    sortDirection: SortDirectionType;
  }) => {
    const sortedList = sortList({ sortBy, sortDirection });

    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedList(sortedList);
  };

  const headerCheckbox = (typeId: number) => {
    const checkCount = brandPlans.filter(p => p.fileTypes.includes(typeId)).length;

    return (
      <Checkbox
        checked={checkCount === brandPlans.length}
        indeterminate={checkCount > 0 && checkCount !== brandPlans.length}
        onChange={() => onColumnSelect(typeId)}
        onClick={e => e.stopPropagation()}
      />
    );
  };

  const rowCheckbox = (brandId: number, fileTypeIds: number[]) => {
    const checkCount = fileTypeIds.length;

    return (
      <Checkbox
        checked={checkCount === fileTypes.length}
        indeterminate={checkCount > 0 && checkCount !== fileTypes.length}
        onChange={() => onRowSelect(brandId)}
      />
    );
  };

  const checkbox = (checked: boolean, brandId: number, typeId: number) => {
    return <Checkbox checked={checked} onChange={() => onChange(typeId, brandId)} />;
  };

  return (
    <React.Fragment>
      <div
        className="channel__default-plans"
        style={{ height: brandPlans.length > 10 ? 400 : (brandPlans.length + 1) * 40 }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <Table
                height={height}
                width={width - 2}
                headerClassName="channel__default-plans-header"
                rowClassName="channel__default-plans-row"
                headerHeight={40}
                rowCount={sortedList.length}
                dataSource={sortedList}
                rowHeight={40}
                rowGetter={({ index }) => sortedList[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  dataKey="select"
                  headerStyle={{ marginLeft: 0, marginRight: 0 }}
                  width={16}
                  cellRenderer={({ rowData }) => rowCheckbox(rowData.brandId, rowData.fileTypes)}
                  disableSort
                />
                <Column
                  label={t('common:brand')}
                  dataKey="brand"
                  width={200}
                  flexGrow={1}
                  cellRenderer={({ rowData }) => (
                    <Tooltip title={`${rowData.brand} | ${rowData.brandCode}`}>
                      <span className="cursor-default">{rowData.brand}</span>
                    </Tooltip>
                  )}
                  headerRenderer={({ dataKey, sortDirection }) => (
                    <div className="flex items-center">
                      <Input.Search
                        className="flex-1"
                        value={keywords}
                        placeholder={t('channel:brandSearch')}
                        onChange={e => handleKeywordChange(e.target.value)}
                        onClick={e => e.stopPropagation()}
                        size="small"
                      />
                      <TableSortIcons
                        sortDirection={(sortBy === dataKey && sortDirection) || undefined}
                      />
                    </div>
                  )}
                />
                {isReceiverUser && (
                  <Column
                    label={t('channel:approvedOn')}
                    dataKey="date"
                    width={200}
                    cellRenderer={({ rowData }) => localDateString(rowData.authorizedByBrandAt)}
                    headerRenderer={({ dataKey, label }) => (
                      <div className="flex">
                        <span>{label}</span>
                        <TableSortIcons
                          sortDirection={(sortBy === dataKey && sortDirection) || undefined}
                        />
                      </div>
                    )}
                  />
                )}

                {fileTypes.map(type => (
                  <Column
                    key={type.id}
                    label={type.name}
                    dataKey={type.id.toString()}
                    width={120}
                    cellRenderer={({ rowData }) =>
                      checkbox(rowData.fileTypes.includes(type.id), rowData.brandId, type.id)
                    }
                    headerRenderer={({ dataKey, label, sortBy, sortDirection }) => (
                      <div className="flex items-center">
                        {headerCheckbox(Number(dataKey))}
                        <Popover content={label}>
                          <span className="ml-1 whitespace-nowrap truncate">{label}</span>
                        </Popover>
                        <TableSortIcons
                          sortDirection={(sortBy === dataKey && sortDirection) || undefined}
                        />
                      </div>
                    )}
                  />
                ))}
              </Table>
            </div>
          )}
        </AutoSizer>
      </div>
    </React.Fragment>
  );
};

export default ChannelPlanList;
