import { Reducer } from 'redux';
import { ItemDeliveryStatus } from '../../../types/item';
import { DeliveryLog } from '../../../types/delivery_logs';

export type ItemDashboardReducerState = {
  deliveryStatuses: ItemDeliveryStatus[];
  deliveryLogsOverview: DeliveryLog[];
};

const initialState: ItemDashboardReducerState = {
  deliveryStatuses: [],
  deliveryLogsOverview: [],
};

const reducer: Reducer<ItemDashboardReducerState> = (
  state = initialState,
  action
): ItemDashboardReducerState => {
  switch (action.type) {
    case 'FETCH_ITEM_DELIVERY_STATUSES_FULFILLED': {
      return { ...state, deliveryStatuses: action.payload.data };
    }
    case 'FETCH_CHANNEL_DELIVERY_LOGS_OVERVIEW_FULFILLED': {
      return { ...state, deliveryLogsOverview: action.payload.data };
    }
  }
  return state;
};

export default reducer;
