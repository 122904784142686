import React from 'react';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Analysis } from '../../../types/analyses';
import { AnalysisType } from '../../../types/resources';

type AnalysesAlertIconProps = {
  analyses: (Analysis & AnalysisType)[];
  className?: string;
};

const AnalysesAlertIcon: React.FC<AnalysesAlertIconProps> = ({ analyses, className }) => {
  const renderIcon = () => {
    const alertTypeIds = analyses.map(analysis => analysis.alert_type_id);
    const alertTypeId = Math.max(...alertTypeIds);

    return (
      <Tooltip
        title={analyses.map(analysis => (
          <div key={analysis.type_id}>{analysis.name}</div>
        ))}
        placement="top"
      >
        <WarningOutlined
          className={classNames(className, 'analyses__alert-icon-message', {
            blue: alertTypeId === 1,
            yellow: alertTypeId === 2,
            red: alertTypeId === 3,
          })}
        />
      </Tooltip>
    );
  };

  if (analyses?.length) return renderIcon();

  return null;
};

export default AnalysesAlertIcon;
