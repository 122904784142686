import { Button, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EbayOffer } from '../../../../types/ebay';
import { ApplicationState } from '../../../reducers';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { localDateTimeString } from '../../../utils/Utils';

type EbayStatusRowProps = {
  ebayOffer?: EbayOffer;
  itemUpdatedAt: string;
  buttonDisabled: boolean;
  formDirty: boolean;
  deleteEbayOffer: () => void;
  updateEbayOffer: () => void;
  createEbayOffer: () => void;
};

const EbayStatusRow: React.FC<EbayStatusRowProps> = props => {
  const { t } = useTranslation();

  const { ebayOffer, itemUpdatedAt } = props;

  const {
    createOfferPending,
    updateOfferPending,
    deleteOfferPending,
    createOfferError,
    createOfferItemId,
    fetchOfferItemId,
    selectedItem,
  } = useSelector((state: ApplicationState) => {
    return {
      createOfferPending: state.items.ebay.createOfferPending,
      updateOfferPending: state.items.ebay.updateOfferPending,
      deleteOfferPending: state.items.ebay.deleteOfferPending,
      createOfferError: state.items.ebay.createOfferError,
      createOfferItemId: state.items.ebay.createOfferItemId,
      fetchOfferItemId: state.items.ebay.fetchOfferItemId,
      selectedItem: getSelectedItems(state)[0],
    };
  });

  const itemUpdated = new Date(ebayOffer?.start_time || 0) < new Date(itemUpdatedAt);

  return (
    <div className="ebay__status-row">
      {ebayOffer?.item_id && (
        <React.Fragment>
          <span className="ebay__status-listed">
            {`${t('ebay:status.listed')} (${t('ebay:status.expires')} ${localDateTimeString(
              ebayOffer.end_time
            )})`}
          </span>
          <Button
            className="ebay__end-button"
            onClick={() => props.deleteEbayOffer()}
            size="small"
            type="primary"
            ghost
            loading={deleteOfferPending}
            disabled={props.buttonDisabled}
          >
            {t('ebay:endListing')}
          </Button>
          {(props.formDirty || updateOfferPending || itemUpdated) && (
            <Button
              className="ebay__end-button"
              onClick={() => props.updateEbayOffer()}
              size="small"
              type="primary"
              ghost
              loading={updateOfferPending}
              disabled={props.buttonDisabled}
            >
              {t('ebay:updateListing')}
            </Button>
          )}
          <Button
            className="ebay__view-product-button ebay__end-button"
            size="small"
            type="primary"
            ghost
            href={ebayOffer.item_url}
            target="_blank"
          >
            {t('ebay:viewProduct')}
          </Button>
        </React.Fragment>
      )}
      {!ebayOffer?.item_id && (
        <React.Fragment>
          {createOfferError && createOfferItemId === selectedItem.id && (
            <Tag color="red">{`${t('ebay:listingError')}: ${createOfferError}`}</Tag>
          )}
          <Button
            className="ebay__end-button"
            onClick={() => props.createEbayOffer()}
            size="small"
            type="primary"
            ghost
            loading={createOfferPending}
            // check for selected item id because of long running request
            disabled={
              props.buttonDisabled || (createOfferPending && createOfferItemId !== selectedItem.id)
            }
          >
            {t('ebay:list')}
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default EbayStatusRow;
