import React from 'react';
import { Form, Select, Tag } from 'antd';
import { Field, FieldProps } from 'formik';
import { get as _get } from 'lodash';
import { SelectProps } from 'antd/lib/select';

type FormEmailTagsProps = {
  name: string;
  className?: string;
  values: { label: string; value: string; key: string }[];
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
} & SelectProps<any>;

const FormEmailTags: React.FC<FormEmailTagsProps> = props => {
  const { name, className, values, label, required, ...selectProps } = props;

  const fieldContent = ({ field, form }: FieldProps) => {
    const { onBlur } = field;
    const { errors, touched, setFieldValue, setFieldTouched } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        className={className}
        label={label}
        required={required}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <Select
          mode="tags"
          value={values}
          onChange={(values: string[]) => {
            const emailsString = values.join(',');
            setFieldValue(name, emailsString);
            setFieldTouched(name);
          }}
          onBlur={onBlur}
          {...selectProps}
          options={values}
          tagRender={props => {
            const { label, closable, onClose, value } = props;
            const allErrors: string[] = Array.isArray(_get(errors, name))
              ? (_get(errors, name) as string[])
              : [];

            return (
              <Tag
                closable={closable}
                onClose={onClose}
                color={
                  allErrors && allErrors.find((err: string) => err?.includes(value))
                    ? 'error'
                    : 'processing'
                }
              >
                {label}
              </Tag>
            );
          }}
        />
      </Form.Item>
    );
  };

  return <Field name={name}>{fieldContent}</Field>;
};

export default FormEmailTags;
