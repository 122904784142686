import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { fetchItemKits } from '../../actions/items/kit/fetch';
import * as navigationActions from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import KitPage from '../../components/body/kit/KitPage';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemInfo from '../../components/global/ItemInfo';
import { intercomEvent } from '../../utils/IntercomUtils';

const KitContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { itemId, selectedItems } = useSelector((state: ApplicationState) => {
    return {
      itemId: state.catalogue.catalogue.selectedItemIds[0],
      selectedItems: getSelectedItems(state),
    };
  });
  const selectedItem = selectedItems[0];

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.kit));
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'kit',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItems.length === 1) dispatch(fetchItemKits(itemId));
  }, [dispatch, itemId, selectedItems.length]);

  if (selectedItems.length > 1 || selectedItems.length === 0) {
    return <ItemInfo items={selectedItems} itemId={itemId} />;
  }

  return <KitPage {...selectedItem} itemId={itemId} />;
};

export default KitContainer;
