const initialState = {
  fetchingOutOfSyncItems: false,
  outOfSyncItems: [],
  outOfSyncItemsCount: 0,
  isSyncing: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'FETCH_OUT_OF_SYNC_ITEMS_PENDING': {
      const outOfSyncItems = action.meta.page === 1 ? [] : state.outOfSyncItems;
      return {
        ...state,
        fetchingOutOfSyncItems: true,
        outOfSyncItems,
      };
    }
    case 'FETCH_OUT_OF_SYNC_ITEMS_FULFILLED': {
      const outOfSyncItems =
        action.meta.page > 1 ? [...state.outOfSyncItems, ...payload.data] : payload.data;
      return {
        ...state,
        outOfSyncItems,
        fetchingOutOfSyncItems: false,
      };
    }
    case 'FETCH_OUT_OF_SYNC_ITEMS_COUNT_FULFILLED': {
      return {
        ...state,
        outOfSyncItemsCount: payload.data.count,
      };
    }
    case 'FETCH_SYNC_LOGS_FULFILLED': {
      const syncCompleted = payload.data ? payload.data.completed_at : true;
      return {
        ...state,
        isSyncing: !syncCompleted,
      };
    }
    case 'SYNC_ITEMS_PENDING': {
      return {
        ...state,
        isSyncing: true,
      };
    }
    case 'SYNC_ITEMS_LIVE_PENDING': {
      return { ...state, isSyncing: true };
    }
    case 'SYNC_ITEMS_LIVE_FULFILLED': {
      return { ...state, isSyncing: false };
    }
    case 'INCREASE_OUT_OF_SYNC_COUNT': {
      return {
        ...state,
        outOfSyncItemsCount: state.outOfSyncItemsCount + action.payload,
      };
    }
  }
  return state;
};

export default reducer;
