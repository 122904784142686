import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Table, Column, InfiniteLoader } from 'react-virtualized';
import moment from 'moment';
import { Spin, Tooltip, Empty } from 'antd';
import classNames from 'classnames';
import { ApplicationState } from '../../../reducers';
import { getPageLimit } from '../../../utils/Utils';
import { fetchAPIToken } from '../../../actions/distribution_apisettings';
import { AsyncDispatch } from '../../../../types/global';

const ApiSettingsTable: React.FC = () => {
  const [pageSize] = React.useState<number>(getPageLimit());
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { apiSettings, fetchingLogs } = useSelector((state: ApplicationState) => ({
    apiSettings: state.distribution.apiSettings,
    fetchingLogs: state.distribution.apiSettings.fetchingLogs,
  }));

  const handleScroll = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    const nextPage = Math.ceil(stopIndex / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize);
    const lastPage = currentPage > startIndex / pageSize;
    if (!lastPage) return dispatch(fetchAPIToken({ page: nextPage, limit: pageSize }));
    return Promise.resolve();
  };

  const noData = () => {
    if (fetchingLogs && apiSettings.logs.length === 0)
      return (
        <Spin
          spinning={fetchingLogs && apiSettings.logs.length === 0}
          size="large"
          className="api-settings__log-spinner"
        />
      );

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <div className="api-settings__log-table">
            <InfiniteLoader
              isRowLoaded={({ index }) => !!apiSettings.logs[index]}
              loadMoreRows={handleScroll}
              rowCount={apiSettings.logs.length + pageSize}
              minimumBatchSize={pageSize}
            >
              {({ onRowsRendered, registerChild }) => (
                <React.Fragment>
                  <Table
                    height={height - 12}
                    width={width - 2}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    headerClassName="api-settings__log-table-header"
                    rowClassName="api-settings__log-table-row"
                    headerHeight={50}
                    rowCount={apiSettings.logs.length}
                    dataSource={apiSettings.logs!}
                    rowHeight={40}
                    rowGetter={({ index }) => apiSettings.logs[index]}
                    noRowsRenderer={noData}
                  >
                    <Column
                      label={t('api:status')}
                      dataKey="code"
                      width={100}
                      cellRenderer={({ cellData, rowData }) => (
                        <Tooltip title={rowData.status} placement="right">
                          <span
                            className={classNames({
                              green: cellData === '200',
                              red: cellData !== '200',
                              yellow: cellData === 'pending',
                            })}
                          >
                            {cellData}
                          </span>
                        </Tooltip>
                      )}
                    />
                    <Column
                      label={t('api:timeStamp')}
                      dataKey="started_at"
                      width={250}
                      cellRenderer={({ cellData }) => (
                        <div>{moment(cellData).local().format('DD.MM.YYYY, h:mm a [UTC]Z')}</div>
                      )}
                    />
                    <Column
                      label={t('api:requestDetails')}
                      dataKey="params"
                      width={350}
                      flexGrow={1}
                    />
                    <Column label={t('api:apiEndPoint')} dataKey="call" width={300} flexGrow={1} />
                    <Column
                      label={t('api:productCount')}
                      dataKey="item_count"
                      width={100}
                      flexGrow={1}
                    />
                  </Table>

                  <Spin
                    spinning={fetchingLogs && apiSettings.logs.length > 0}
                    className="spinner"
                    style={{
                      left: width / 2,
                      position: 'absolute',
                      bottom: '20px',
                    }}
                  />
                </React.Fragment>
              )}
            </InfiniteLoader>
          </div>
        </div>
      )}
    </AutoSizer>
  );
};

export default ApiSettingsTable;
