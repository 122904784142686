import axios from 'axios';
import { FormikValues } from 'formik';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function createPriceSheet(values: FormikValues) {
  const paramObject = Object.keys(values).map(key => ({ name: key, values: values[key] }));
  const params = utils.createURL(paramObject);

  return {
    type: 'CREATE_PRICE_SHEET',
    payload: axios.post(`${getApiRoot()}/parents/price_sheets${params}`),
  };
}
