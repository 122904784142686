import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deletePrice(sheetId: number, priceId: number) {
  return {
    type: 'DELETE_PRICE',
    meta: { sheetId, priceId },
    payload: axios.delete(`${getApiRoot()}/items/prices/${priceId}`),
  };
}
