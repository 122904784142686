import { Form, Button, Col, Row, Transfer } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { PriceSheetType } from '../../../../types/price_sheet';
import { ExtendedResources, DescriptionTypeElement } from '../../../../types/resources';
import { FilterType } from '../../../../types/filter';
import { Plan, DefaultIntegrationSettings, DefaultSchedule } from '../../../../types/channel';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';
import PriceSheetSelect from '../../global/PriceSheetSelect';
import {
  scheduleValidationSchema,
  ebayValidationSchema,
} from '../../../utils/ChannelValidationSchemas';
import { drawer, plans, ebay } from '../../../constants/ChannelTranslation.json';
import commonTranslation from '../../../constants/SaveTranslation.json';
import FormInput from '../../global/Forms/FormInput';
import FormRadio from '../../global/Forms/FormRadio';
import FormSelect from '../../global/Forms/FormSelect';
import SwitchDivider from '../../global/SwitchDivider';
import FormErrorFocus from '../../global/Forms/FormErrorFocus';
import ChannelFormDirtyHandler from './ChannelFormDirtyHandler';
import days from '../../../constants/DayConstants.json';
import validationMessages from '../../../constants/ValidationMessages.json';

type EbayPlanFormValues = {
  deliveryFrequencyId: number;
  descriptionTypeIds: number[];
  withoutPrices: number | null;
  priceSheetId: number | null;
  priceTypeId: number | null;
  defaultSchedule: boolean;
  defaultIntegrationSettings: boolean;
};

type EditEbayPlanProps = {
  plan: Plan;
  defaultIntegrationSettings: DefaultIntegrationSettings;
  defaultSchedule: DefaultSchedule;
  create: boolean;
  deliveryFrequencies: ExtendedResources[];
  descriptionTypes: DescriptionTypeElement[];
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  filters: FilterType[];
  createPlan: (values: { [key: string]: any }, defaults: { [key: string]: any }) => Promise<any>;
  updatePlan: (values: { [key: string]: any }, defaults: { [key: string]: any }) => Promise<any>;
  selectPlan: (id: string) => void;
  deleteIntegratedSettings: (planId: number) => void;
  deleteSchedule: (planId: number) => void;
  onClose: (force?: boolean) => void;
};

export class EditEbayPlan extends React.Component<EditEbayPlanProps> {
  validationSchema = Yup.object().shape({
    name: Yup.string().required(validationMessages.required),
    ...ebayValidationSchema(),
    ...scheduleValidationSchema,
  });

  handlePlanChange = async (values: EbayPlanFormValues): Promise<any> => {
    const { plan } = this.props;
    if (values.withoutPrices && values.priceSheetId) {
      values.priceSheetId = null;
      values.priceTypeId = null;
    }

    // remove plan settings if use default settings got selected
    if (values.defaultIntegrationSettings && plan.integration_settings)
      this.props.deleteIntegratedSettings(plan.id);
    if (values.defaultSchedule && plan.schedule) this.props.deleteSchedule(plan.id);

    if (this.props.create)
      return this.props.createPlan(values, {
        defaultSchedule: values.defaultSchedule,
        defaultIntegrationSettings: values.defaultIntegrationSettings,
      });

    return this.props.updatePlan(values, {
      defaultSchedule: values.defaultSchedule,
      defaultIntegrationSettings: values.defaultIntegrationSettings,
    });
  };

  getInitialValues = () => {
    const { plan, defaultSchedule, defaultIntegrationSettings } = this.props;
    const { schedule, integration_settings: integrationSettings } = plan;
    const settings = integrationSettings || defaultIntegrationSettings;

    return {
      name: plan.name || '',
      filterId: (plan.filter_ids && plan.filter_ids[0]) || undefined,
      withoutPrices: settings && settings.manage_prices_manually,
      priceSheetId: settings && settings.price_sheet_id,
      priceTypeId: settings && settings.price_type_id,
      deliveryFrequencyId:
        (schedule && schedule.delivery_frequency_id) ||
        (defaultSchedule && defaultSchedule.delivery_frequency_id),
      titleDescriptionTypeId: settings && settings.title_description_type_id,
      descriptionTypeIds: (settings && settings.description_type_ids) || [],
      defaultSchedule: !!(!plan.schedule && defaultSchedule),
      defaultIntegrationSettings: !!(!plan.integration_settings && defaultIntegrationSettings),
    };
  };

  render() {
    const { deliveryFrequencies, descriptionTypes, priceSheets, priceTypes } = this.props;

    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.handlePlanChange(values)
            .then(result => {
              setSubmitting(false);
              resetForm({ values: this.getInitialValues() });
              this.props.selectPlan(result.value.data.id.toString());
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
          dirty,
        }) => (
          <Form className="channel__plan-form h-full flex flex-col" layout="vertical">
            <ChannelFormDirtyHandler />
            <FormErrorFocus />
            <div className="flex-1 overflow-auto pr-5">
              <Row gutter={20}>
                <Col span={12}>
                  <FormInput
                    name="name"
                    label={drawer.plan}
                    placeholder={drawer.plan_name}
                    required
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={7}>
                  <FormSelect
                    name="filterId"
                    values={this.props.filters}
                    label={drawer.filter}
                    placeholder={drawer.select_filter}
                    allowClear
                  />
                </Col>
              </Row>

              <SwitchDivider
                title={plans.schedule}
                switchTag={plans.use_default}
                disabled={!this.props.defaultSchedule}
                checked={values.defaultSchedule}
                handleChange={() => setFieldValue('defaultSchedule', !values.defaultSchedule)}
              />

              <Row gutter={20}>
                <Col span={10}>
                  <FormRadio
                    name="deliveryFrequencyId"
                    values={deliveryFrequencies.map((freq: any) => ({
                      ...freq,
                      disabled: freq.id !== 4,
                    }))}
                    label={drawer.delivery_frequency}
                    disabled={values.defaultSchedule}
                    required
                  />
                </Col>
                {values.deliveryFrequencyId === 2 && (
                  <Col span={4}>
                    <FormSelect
                      name="deliveryDay"
                      values={days}
                      label={drawer.delivery_day}
                      disabled={values.defaultSchedule}
                      required
                    />
                  </Col>
                )}
                {values.deliveryFrequencyId === 3 && (
                  <Col span={4}>
                    <FormSelect
                      name="deliveryDate"
                      values={[...new Array(28)].map((val, i) => ({ id: i + 1, name: i + 1 }))}
                      label={drawer.delivery_day}
                      disabled={values.defaultSchedule}
                      required
                    />
                  </Col>
                )}
                {values.deliveryFrequencyId !== 4 && (
                  <Col span={5}>
                    <FormInput
                      name="deliveryTime"
                      type="time"
                      label={drawer.delivery_time}
                      disabled={values.defaultSchedule}
                    />
                  </Col>
                )}
              </Row>

              <SwitchDivider
                title={plans.default_settings}
                switchTag={plans.use_default}
                disabled={!this.props.defaultIntegrationSettings}
                checked={values.defaultIntegrationSettings}
                handleChange={() =>
                  setFieldValue('defaultIntegrationSettings', !values.defaultIntegrationSettings)
                }
              />

              <Row gutter={20}>
                <Col span={12}>
                  <FormSelect
                    name="titleDescriptionTypeId"
                    values={descriptionTypes}
                    label={ebay.title}
                    placeholder={ebay.selectPlaceholder}
                    disabled={values.defaultIntegrationSettings}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={20} className="channel__form-plan-headline">
                <Col span={24}>
                  <span>{ebay.description}</span>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={24}>
                  <Transfer
                    className="channel__ebay-description-transfer"
                    showSearch
                    dataSource={descriptionTypes.map((type: DescriptionTypeElement) => ({
                      ...type,
                      key: type.id.toString(),
                      title: type.name,
                    }))}
                    filterOption={(inputValue, option) =>
                      option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                    }
                    targetKeys={values.descriptionTypeIds.map(id => id.toString())}
                    onChange={(targetKeys, direction, moveKeys) => {
                      if (direction === 'right')
                        setFieldValue('descriptionTypeIds', [
                          ...values.descriptionTypeIds,
                          ...moveKeys,
                        ]);
                      else if (direction === 'left')
                        setFieldValue(
                          'descriptionTypeIds',
                          values.descriptionTypeIds.filter(
                            (key: number) => !moveKeys.includes(key.toString())
                          )
                        );
                    }}
                    render={item => item.title || ''}
                    listStyle={() => ({ width: 'calc(50% - 25px)', height: 350 })}
                    disabled={values.defaultIntegrationSettings}
                  />
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={10}>
                  <Form.Item
                    hasFeedback
                    validateStatus={errors.priceSheetId && touched.priceSheetId ? 'error' : ''}
                    help={(touched.priceSheetId && errors.priceSheetId) || undefined}
                    className="channel__ebay__price-select"
                    label={ebay.price}
                    required
                  >
                    <PriceSheetSelect
                      priceSheets={priceSheets}
                      priceTypes={priceTypes}
                      updateSelection={({
                        selectedPriceSheetIds,
                        selectedPriceTypeIds,
                        withoutPrices,
                      }: {
                        selectedPriceSheetIds: number[];
                        selectedPriceTypeIds: number[];
                        withoutPrices: number;
                      }) => {
                        if (selectedPriceSheetIds) {
                          setFieldValue('priceSheetId', selectedPriceSheetIds[0]);
                        }
                        if (selectedPriceTypeIds) {
                          setFieldValue('priceTypeId', selectedPriceTypeIds[0]);
                          setFieldTouched('priceTypeId');
                        }
                        if (withoutPrices) setFieldValue('withoutPrices', 1);
                        else setFieldValue('withoutPrices', 0);
                      }}
                      onBlur={() => setFieldTouched('priceSheetId')}
                      defaultSelectedPriceSheetIds={
                        values.priceSheetId ? [values.priceSheetId] : []
                      }
                      defaultSelectedPriceTypeIds={[values.priceTypeId]}
                      defaultWithoutPrices={values.withoutPrices}
                      checkboxLabel={ebay.noPriceManaging}
                      disabled={values.defaultIntegrationSettings}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="drawer-submit__bottom flex-col items-end">
              <div>
                <Button
                  onClick={() => this.props.onClose(true)}
                  className="drawer-submit__bottom-cancel"
                >
                  {commonTranslation.cancel}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                >
                  {this.props.create ? drawer.create : commonTranslation.save}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  deliveryFrequencies: state.resources.data.channel.delivery_frequencies,
  descriptionTypes: state.resources.data.description.types,
  priceSheets: state.parent.priceSheets.priceSheets || [],
  priceTypes: (state.resources.data && state.resources.data.price.types) || [],
  filters: getAllCustomFilters(state),
});

export default connect(mapStateToProps)(withContainerWrapper(EditEbayPlan));
