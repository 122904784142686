import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withContainerWrapper } from '../ContainerWrapper';
import resourcesMap from '../../constants/ApplicationResourcesMapping.json';
import filterActions from '../../actions/catalogue/filter';
import ApplicationDetails from '../../components/body/application/ApplicationDetails';
import { AsyncDispatch } from '../../../types/global';
import {
  FilterApplicationGo,
  FilterApplicationParameterValue,
  FilterType,
  FilterTypeGo,
} from '../../../types/filter';
import { RankedApplications, RankedValue } from '../../../types/application';
import { StandardResource, Vehicle, VehicleStructureClass } from '../../../types/resources';
import { ApplicationState } from '../../reducers';
import { hasPermission } from '../../utils/Permissions';
import { UserType } from '../../../types/user';
import { fetchApplicationYears, fetchEquipmentYears } from '../../actions/items/application/fetch';
import { getVehicleResources } from '../../selectors/applications/applicationsSelector';

const resourcesMapping = resourcesMap as { [key: string]: string };

type ApplicationFilterContainerProps = {
  user: UserType;
  dispatch: AsyncDispatch;
  filter: FilterTypeGo;
  filterOld: FilterType;
  rankedApplications: RankedApplications;
  resources: Vehicle;
  brandId: number;
  applicationStructure: VehicleStructureClass;
  selectedVcdbs: number[];
  initialAccountResourcesFetching: boolean;
  fetchingYears: boolean;
  removeFilterParameterValue: (params: {
    sectionKey: string;
    resource: string;
    value: any;
  }) => void;
  removeFilterParameter: (params: { sectionKey: string; resource: string }) => Promise<any>;
  addFilterParameterValue: (params: {
    sectionKey: string;
    resource: string;
    resourceId: number | number[];
    resourceName?: string;
  }) => void;
  removeFilterParameterValues: (params: {
    sectionKey: string;
    resource: string;
    values: number[];
    changeValue?: boolean;
  }) => void;
};

type ApplicationFilterContainerState = {
  selectedSubconfig: string | null;
  selectedSubconfigGroup: string | null;
  rankedMappedApplications: RankedApplications;
  selectedMakes: StandardResource[];
  selectedModels: StandardResource[];
  selectedTypes: StandardResource[];
  selectedGroups: StandardResource[];
  selectedYears: number[];
  selectedSubmodels: StandardResource[];
  selectedRegions: StandardResource[];
  getRecommendations: boolean;
  makeKeywords: string;
  modelKeywords: string;
  submodelKeywords: string;
};

class ApplicationFilterContainer extends React.Component<
  ApplicationFilterContainerProps,
  ApplicationFilterContainerState
> {
  oldYears: any;

  constructor(props: ApplicationFilterContainerProps) {
    super(props);
    this.state = {
      selectedSubconfig: null,
      selectedSubconfigGroup: null,
      rankedMappedApplications: {} as RankedApplications,
      selectedMakes: [],
      selectedModels: [],
      selectedTypes: [],
      selectedGroups: [],
      selectedYears: [],
      selectedSubmodels: [],
      selectedRegions: [],
      getRecommendations: false,
      makeKeywords: '',
      modelKeywords: '',
      submodelKeywords: '',
    };
  }

  async componentDidMount() {
    const { filterOld } = this.props;

    if (filterOld && filterOld.id && !filterOld.filter_application) {
      this.createApplicationFilter();
    } else {
      this.fetchRankedApplications();
      this.fetchMakeModelYearData();
      this.getSelectedValuesFromFilter();
    }
    this.selectSubconfig('vehicle_bases');
  }

  componentDidUpdate(prevProps: ApplicationFilterContainerProps) {
    const { filter, filterOld, selectedVcdbs, fetchingYears, brandId } = this.props;
    if (prevProps.filter !== filter) {
      this.mergeFilterValuesWithRankedApplications();
      this.getSelectedValuesFromFilter();
      this.fetchRankedApplications();
      const filterValues = this.getFilterValues();
      const prevFilterValues = this.getFilterValues(this.getApplicationFilter(prevProps.filter));
      if (
        !filterValues.hasOwnProperty('makes') &&
        !filterValues.hasOwnProperty('models') &&
        !filterValues.hasOwnProperty('years') &&
        !filterValues.hasOwnProperty('sub_models') &&
        !filterValues.hasOwnProperty('regions') &&
        !filterValues.hasOwnProperty('mfrs') &&
        !filterValues.hasOwnProperty('equipment_models')
      ) {
        this.setState({ getRecommendations: false });
      }
      if (
        ((prevFilterValues.makes && !filterValues.makes) ||
          (prevFilterValues.models && !filterValues.models)) &&
        !fetchingYears
      ) {
        const makeIds = Object.values(filterValues.makes || {}).map(v => v.resource_id);
        const modelIds = Object.values(filterValues.models || {}).map(v => v.resource_id);
        this.props.dispatch(fetchApplicationYears(selectedVcdbs, makeIds[0], modelIds[0]));
      }
      if (
        ((prevFilterValues.mfrs && !filterValues.mfrs) ||
          (prevFilterValues.equipment_models && !filterValues.equipment_models)) &&
        !fetchingYears
      ) {
        const mfrIds = Object.values(filterValues.mfrs || {}).map(v => v.resource_id);
        const modelIds = Object.values(filterValues.equipment_models || {}).map(v => v.resource_id);
        const vehicleTypeIds = Object.values(filterValues.vehicle_types || {}).map(
          v => v.resource_id
        );
        this.props.dispatch(
          fetchEquipmentYears(brandId, selectedVcdbs, mfrIds[0], modelIds[0], vehicleTypeIds[0])
        );
      }
    }
    if (
      filterOld &&
      prevProps.filterOld !== filterOld &&
      !filterOld.filter_application &&
      // @ts-ignore
      !filterOld.delete
    ) {
      this.createApplicationFilter();
    }
  }

  createApplicationFilter = () => {
    this.props.dispatch(filterActions.createApplicationFilter(this.props.filter.id)).then(() => {
      this.fetchMakeModelYearData();
    });
  };

  fetchRankedApplications = (keywords?: string) => {
    const { selectedVcdbs } = this.props;
    const filterValues = this.getFilterValues();
    if (
      (filterValues.hasOwnProperty('makes') && !filterValues.makes.find(v => v.temp)) ||
      (filterValues.hasOwnProperty('models') && !filterValues.models.find(v => v.temp)) ||
      (filterValues.hasOwnProperty('years') && !filterValues.years.find(v => v.temp)) ||
      (filterValues.hasOwnProperty('sub_models') && !filterValues.sub_models.find(v => v.temp)) ||
      (filterValues.hasOwnProperty('regions') && !filterValues.regions.find(v => v.temp)) ||
      (filterValues.hasOwnProperty('mfrs') &&
        !filterValues.mfrs.find(v => v.temp) &&
        filterValues.hasOwnProperty('equipment_models') &&
        !filterValues.equipment_models.find(v => v.temp)) ||
      keywords
    ) {
      this.props
        .dispatch(
          filterActions.fetchRankedApplicationsByFilter(filterValues, selectedVcdbs, keywords)
        )
        .then(() => {
          const filterValues = this.getFilterValues();

          if (
            !this.state.getRecommendations &&
            (filterValues.hasOwnProperty('makes') ||
              filterValues.hasOwnProperty('models') ||
              filterValues.hasOwnProperty('years') ||
              filterValues.hasOwnProperty('sub_models') ||
              filterValues.hasOwnProperty('regions') ||
              (filterValues.hasOwnProperty('mfrs') &&
                filterValues.hasOwnProperty('equipment_models')))
          ) {
            this.setState({ getRecommendations: true });
          }
          this.mergeFilterValuesWithRankedApplications();
        });
    } else {
      this.mergeFilterValuesWithRankedApplications();
    }
  };

  fetchMakeModelYearData = () => {
    const { selectedVcdbs } = this.props;
    const filterValues = this.getFilterValues();

    if (filterValues.hasOwnProperty('makes') && filterValues.hasOwnProperty('models')) {
      const makeIds = Object.values(filterValues.makes).map(value => value.resource_id);
      const modelIds = Object.values(filterValues.models).map(value => value.resource_id);
      if (makeIds.length === 1 || modelIds.length === 1) {
        this.props.dispatch(fetchApplicationYears(selectedVcdbs, makeIds[0], modelIds[0]));
      }
    }
  };

  getSelectedValuesFromFilter = () => {
    const filterValues = this.getFilterValues();
    let makes: { id: number; name: string }[] = [];
    let models: { id: number; name: string }[] = [];
    let types: { id: number; name: string }[] = [];
    let groups: { id: number; name: string }[] = [];
    let years;
    let submodels;
    let regions;
    if (filterValues.hasOwnProperty('makes')) {
      makes = Object.values(filterValues.makes).map(value => ({
        id: value.resource_id,
        name: value.resource_name,
      }));
    }
    if (filterValues.hasOwnProperty('models')) {
      models = Object.values(filterValues.models).map(value => ({
        id: value.resource_id,
        name: value.resource_name,
      }));
    }
    if (filterValues.hasOwnProperty('vehicle_types')) {
      types = Object.values(filterValues.vehicle_types).map(v => ({
        id: v.resource_id,
        name: v.resource_name,
      }));
    }
    if (filterValues.hasOwnProperty('vehicle_type_groups')) {
      groups = Object.values(filterValues.vehicle_type_groups).map(g => ({
        id: g.resource_id,
        name: g.resource_name,
      }));
    }
    if (filterValues.hasOwnProperty('years')) {
      years = Object.values(filterValues.years).map(value => value.resource_id);
    }
    if (filterValues.hasOwnProperty('sub_models')) {
      submodels = Object.values(filterValues.sub_models).map(value => ({
        id: value.resource_id,
        name: value.resource_name,
      }));
    }
    if (filterValues.hasOwnProperty('regions')) {
      regions = Object.values(filterValues.regions).map(value => ({
        id: value.resource_id,
        name: value.resource_name,
      }));
    }
    this.setState({
      selectedMakes: makes || [],
      selectedModels: models || [],
      selectedTypes: types || [],
      selectedGroups: groups || [],
      selectedYears: years || [],
      selectedSubmodels: submodels || [],
      selectedRegions: regions || [],
    });
  };

  mergeFilterValuesWithRankedApplications = () => {
    const rankedApplications = this.state.getRecommendations
      ? this.props.rankedApplications || ({} as RankedApplications)
      : ({} as RankedApplications);
    const filterValues = this.getFilterValues();
    const filterKeys = Object.keys(filterValues).filter(key => !['years'].includes(key));

    const configKeys = [...new Set([...Object.keys(rankedApplications), ...filterKeys])];
    const mergedApplication: RankedApplications = {} as RankedApplications;

    configKeys.forEach(configName => {
      const rankedValues = rankedApplications[configName] || [];
      const valueList = filterValues[configName] || [];
      const mappedValueIds = valueList.map(value => {
        return value.resource_id;
      });
      let values = rankedValues;
      if (mappedValueIds) {
        const keys = [
          ...new Set([...rankedValues.map((value: RankedValue) => value.id), ...mappedValueIds]),
        ];
        if (keys.length > rankedValues.length) {
          values = [...rankedValues];
          // add values which are not included in ranking (new mapped values)
          mappedValueIds.forEach(id => {
            if (!rankedValues.find((value: RankedValue) => value.id === id)) values.push({ id });
          });
        }
      }

      const resources = this.allSubconfigValues(configName);
      const mergedValues: RankedValue[] = [];
      values.forEach((value: RankedValue) => {
        const mapped = mappedValueIds && mappedValueIds.includes(value.id);
        const valueResources = resources.find(
          (resource: StandardResource) => resource.id === value.id
        );
        mergedValues.push({ ...value, ...valueResources, mapped });
      });
      mergedApplication[configName] = mergedValues;
    });

    this.setState({ rankedMappedApplications: mergedApplication });
  };

  selectSubconfig = (subConfig: string | null) => {
    if (subConfig && this.state.selectedSubconfig !== subConfig) {
      this.setState({ selectedSubconfig: subConfig });
      subConfig === 'engines'
        ? this.selectSubconfigGroup('engine_bases')
        : this.selectSubconfigGroup(null);
    }
  };

  selectSubconfigGroup = (subConfigGroup: string | null) => {
    this.setState({ selectedSubconfigGroup: subConfigGroup });
  };

  getApplicationFilter = (filter = this.props.filter) => {
    if (filter) {
      if (filter.filter_application) {
        return filter.filter_application[0];
      }
    }
  };

  getFilterValues = (appFilter?: FilterApplicationGo) => {
    const filterValues: { [key: string]: FilterApplicationParameterValue[] } = {};
    const applicationFilter = appFilter || this.getApplicationFilter();
    if (applicationFilter) {
      applicationFilter.parameters.forEach(parameter => {
        filterValues[parameter.resource] = parameter.values;
      });
    }
    return filterValues;
  };

  getApplicationFilterParameter = (configName: string) => {
    const applicationFilter = this.getApplicationFilter();
    if (applicationFilter) {
      return applicationFilter.parameters.find(parameter => parameter.resource === configName);
    }
  };

  allSubconfigValues = (configName: string) => {
    // get all values to a specific config name e.g. position
    if (resourcesMapping.hasOwnProperty(configName)) {
      configName = resourcesMapping[configName];
    }

    return this.props.resources[configName];
  };

  removeValues = (resourceName: string, resourceIds: number[], changeValue?: boolean) => {
    if (resourceIds) {
      return this.props.removeFilterParameterValues({
        sectionKey: 'filter_application',
        resource: resourceName,
        values: resourceIds,
        changeValue,
      });
    }
    return Promise.resolve();
  };

  selectMake = async (makeId: number, addValues?: boolean) => {
    const { selectedVcdbs } = this.props;
    const { selectedModels } = this.state;

    const make = this.allSubconfigValues('makes').find(
      (make: StandardResource) => make.id === makeId
    );

    const selectedMakes = this.state.selectedMakes;
    let makes = selectedMakes.length === 1 && selectedMakes[0].id === make.id ? [] : [make];
    if (addValues) {
      makes = [...this.state.selectedMakes];
      if (this.state.selectedMakes.filter(element => element.id === make.id).length > 0) {
        makes = makes.filter(element => element.id !== make.id);
      } else {
        makes.push(make);
      }
    } else if (selectedMakes.length > 0) {
      // remove all previous selected values
      const ids = this.state.selectedMakes.map(make => make.id).filter(id => id !== make.id);
      if (ids.length > 0) await this.removeValues('makes', ids, true);
    }

    this.mapConfigToFilter('makes', make.id, make.name);
    this.setState({ selectedMakes: makes });

    if (makes.length === 1 || selectedModels.length === 1) {
      const selectedModel = selectedModels.length ? selectedModels[0] : undefined;
      this.props.dispatch(fetchApplicationYears(selectedVcdbs, make?.id, selectedModel?.id));
    }
  };

  selectModel = async (modelId: number | null, addValues?: boolean) => {
    const { selectedVcdbs } = this.props;
    const { selectedMakes } = this.state;
    const model = this.allSubconfigValues('models').find(
      (model: StandardResource) => model.id === modelId
    );
    const selectedModels = this.state.selectedModels;
    let models = selectedModels.length === 1 && selectedModels[0].id === model.id ? [] : [model];
    if (addValues) {
      models = [...this.state.selectedModels];
      if (this.state.selectedModels.filter(element => element.id === model.id).length > 0) {
        models = models.filter(element => element.id !== model.id);
      } else {
        models.push(model);
      }
    } else if (selectedModels.length > 0) {
      // remove all previous selected values
      const ids = this.state.selectedModels.map(model => model.id).filter(id => id !== model.id);
      if (ids.length > 0) await this.removeValues('models', ids, true);
    }

    this.mapConfigToFilter('models', model.id, model.name);
    this.setState({ selectedModels: models });

    if (selectedMakes.length === 1 || models.length === 1) {
      const selectedMake = selectedMakes.length ? selectedMakes[0] : undefined;
      this.props.dispatch(fetchApplicationYears(selectedVcdbs, selectedMake?.id, model.id));
    }
  };

  selectType = (id: number | null) => {
    const { selectedTypes } = this.state;
    const type = this.allSubconfigValues('vehicle_types').find(
      (type: StandardResource) => type.id === id
    );
    const types = selectedTypes.length === 1 && selectedTypes[0].id === type.id ? [] : [type];
    if (selectedTypes.length > 0) {
      // remove all previous selected values
      const ids = selectedTypes.map(t => t.id).filter(id => id !== type.id);
      if (ids.length > 0) this.removeValues('vehicle_types', ids, true);
    }
    this.mapConfigToFilter('vehicle_types', type.id, type.name);
    this.setState({ selectedTypes: types });
  };

  selectGroup = async (id: number | null) => {
    const { selectedGroups } = this.state;
    const type = this.allSubconfigValues('vehicle_type_groups').find(
      (type: StandardResource) => type.id === id
    );
    const groups = selectedGroups.length === 1 && selectedGroups[0].id === type.id ? [] : [type];
    if (selectedGroups.length > 0) {
      // remove all previous selected values
      const ids = selectedGroups.map(g => g.id).filter(id => id !== type.id);
      if (ids.length > 0) await this.removeValues('vehicle_type_groups', ids, true);
    }
    this.mapConfigToFilter('vehicle_type_groups', type.id, type.name);
    this.setState({ selectedTypes: groups });
  };

  selectYears = async (yearIds: number | number[] | null, addValues?: boolean, save = true) => {
    if (yearIds === null) {
      const parameter = this.getApplicationFilterParameter('years');
      if (parameter) {
        this.props.removeFilterParameter({
          sectionKey: 'filter_application',
          resource: 'years',
        });
      }
      this.setState({ selectedYears: [] });
    } else {
      let years = yearIds instanceof Array ? Array.from(new Set(yearIds)) : [yearIds];
      if (years.length === 1 && this.state.selectedYears.length === 1) {
        years = years[0] === this.state.selectedYears[0] ? [] : years;
      }

      if (addValues) {
        years = [...this.state.selectedYears];
        if (yearIds instanceof Array) years = Array.from(new Set(years.concat(yearIds)));
        else if (years.includes(yearIds)) {
          years = years.filter(element => element !== yearIds);
          await this.removeValues('years', [yearIds]);
        } else years.push(yearIds);
      } else if (this.state.selectedYears.length > 0 || yearIds instanceof Array) {
        // remove all previous selected values
        if (!save && !this.oldYears) this.oldYears = this.state.selectedYears;
        else if (!this.oldYears) await this.removeValues('years', this.state.selectedYears);
        else if (save && this.oldYears) {
          if (this.oldYears.length > 0) await this.removeValues('years', this.oldYears, true);
          this.oldYears = null;
        }
      }

      this.setState({ selectedYears: years });

      // save all years in one call
      if (save !== false) this.mapConfigToFilter('years', years);
    }
  };

  selectSubmodel = (submodelId: number | null) => {
    if (submodelId === null) {
      const parameter = this.getApplicationFilterParameter('sub_models');
      if (parameter) {
        this.props.removeFilterParameter({
          sectionKey: 'filter_application',
          resource: 'sub_models',
        });
      }
      this.setState({ selectedSubmodels: [] });
    } else {
      const submodel = this.allSubconfigValues('sub_models').find(
        (model: StandardResource) => model.id === submodelId
      );

      this.state.selectedSubmodels.length > 0 && submodel.id === this.state.selectedSubmodels[0].id
        ? this.setState({ selectedSubmodels: [] })
        : this.setState({ selectedSubmodels: [submodel] });
      this.mapConfigToFilter('sub_models', submodel.id, submodel.name);
    }
  };

  selectRegion = (regionId: number | null) => {
    if (regionId === null) {
      const parameter = this.getApplicationFilterParameter('regions');
      if (parameter) {
        this.props.removeFilterParameter({ sectionKey: 'filter_application', resource: 'regions' });
      }
      this.setState({ selectedRegions: [] });
    } else {
      const region = this.allSubconfigValues('regions').find(
        (region: StandardResource) => region.id === regionId
      );
      this.state.selectedRegions.length > 0 && region.id === this.state.selectedRegions[0].id
        ? this.setState({ selectedRegions: [] })
        : this.setState({ selectedRegions: [region] });
      this.mapConfigToFilter('regions', region.id, region.name);
    }
  };

  getValueFromParameter = (resource: string, resourceId: number) => {
    const parameter = this.getApplicationFilterParameter(resource);
    return parameter?.values.find(value => value.resource_id === Number(resourceId));
  };

  mapConfigToFilter = (
    subconfigName: string,
    resourceId: number | number[],
    resourceName?: string
  ) => {
    const { selectedVcdbs, brandId } = this.props;

    if (!resourceName && subconfigName !== 'years' && subconfigName !== 'equipment_years')
      resourceName = this.allSubconfigValues(subconfigName).find(
        (res: StandardResource) => res.id === resourceId
      ).name;
    const mappedValues = this.getFilterValues();
    // check if filter has already a parameter "configName"
    if (mappedValues.hasOwnProperty(subconfigName)) {
      const parameter = this.getApplicationFilterParameter(subconfigName);
      // check if value is already mapped to parameter
      const value =
        resourceId instanceof Array ? null : this.getValueFromParameter(subconfigName, resourceId);
      if (value) {
        // remove value
        this.props.removeFilterParameterValue({
          sectionKey: 'filter_application',
          resource: subconfigName,
          value: value.resource_id,
        });
      } else if (
        !parameter?.temp &&
        subconfigName === 'years' &&
        resourceId instanceof Array &&
        resourceId.length === 0
      ) {
        this.props.removeFilterParameter({
          sectionKey: 'filter_application',
          resource: subconfigName,
        });
      } else if (!parameter?.temp) {
        // add value
        this.props.addFilterParameterValue({
          sectionKey: 'filter_application',
          resource: subconfigName,
          resourceId,
          resourceName,
        });
      }
    } else {
      // create new filter parameter including value
      this.props.addFilterParameterValue({
        sectionKey: 'filter_application',
        resource: subconfigName,
        resourceId,
        resourceName,
      });
    }

    if (
      ['mfrs', 'equipment_models', 'vehicle_types'].includes(subconfigName) &&
      typeof resourceId === 'number'
    ) {
      const mfrIds = Object.values(mappedValues.mfrs || {}).map(v => v.resource_id);
      const modelIds = Object.values(mappedValues.equipment_models || {}).map(v => v.resource_id);
      const vehicleTypeIds = Object.values(mappedValues.vehicle_types || {}).map(
        v => v.resource_id
      );

      let mfrId: number | undefined = mfrIds[0];
      let modelId: number | undefined = modelIds[0];
      let vehicleTypeId: number | undefined = vehicleTypeIds[0];

      if (subconfigName === 'mfrs') {
        mfrId = mfrIds.includes(resourceId) ? undefined : resourceId;
      } else if (subconfigName === 'equipment_models') {
        modelId = modelIds.includes(resourceId) ? undefined : resourceId;
      } else if (subconfigName === 'vehicle_types') {
        vehicleTypeId = vehicleTypeIds.includes(resourceId) ? undefined : resourceId;
      }

      this.props.dispatch(
        fetchEquipmentYears(brandId, selectedVcdbs, mfrId, modelId, vehicleTypeId)
      );
    }
  };

  render() {
    const { initialAccountResourcesFetching, user } = this.props;
    const canManageVcdb = hasPermission(user, 'can_manage_custom_vcdb');

    if (initialAccountResourcesFetching)
      return (
        <div className="mt-10">
          <Spin className="spinner-center" />
        </div>
      );

    return (
      <div className="filter__application">
        <ApplicationDetails
          filterView
          canManageVcdb={canManageVcdb}
          applicationStructure={this.props.applicationStructure}
          getRankedApplications={this.fetchRankedApplications}
          getRecommendations={this.state.getRecommendations}
          rankedApplications={this.state.rankedMappedApplications}
          makeKeywords={this.state.makeKeywords}
          modelKeywords={this.state.modelKeywords}
          submodelKeywords={this.state.submodelKeywords}
          allSubconfigValues={this.allSubconfigValues}
          selectedMakes={this.state.selectedMakes.map(({ id }) => id) || []}
          selectedModels={this.state.selectedModels.map(({ id }) => id) || []}
          selectedYears={this.state.selectedYears || []}
          selectedSubmodels={this.state.selectedSubmodels.map(({ id }) => id) || []}
          selectedRegions={this.state.selectedRegions.map(({ id }) => id) || []}
          selectedSubconfig={this.state.selectedSubconfig}
          selectedSubconfigGroup={this.state.selectedSubconfigGroup}
          selectMake={this.selectMake}
          selectModel={this.selectModel}
          selectType={this.selectType}
          selectGroup={this.selectGroup}
          selectSubmodel={this.selectSubmodel}
          selectRegion={this.selectRegion}
          selectYears={this.selectYears}
          selectSubconfig={this.selectSubconfig}
          selectSubconfigGroup={this.selectSubconfigGroup}
          mapConfigToApplication={this.mapConfigToFilter}
        />
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: state.user.user,
    rankedApplications: state.catalogue.filter.rankedApplications,
    fetchingYears: state.items.application.fetchingYears,
    resources: getVehicleResources(state),
    initialAccountResourcesFetching: state.resources.initialAccountResourcesFetching,
    brandId: state.parent.brands.selectedBrandId,
    applicationStructure: state.resources.data.vehicle_structure,
    selectedVcdbs: state.items.application.selectedVcdbs,
  };
}
export default connect(mapStateToProps)(withContainerWrapper(ApplicationFilterContainer));
