import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import { Provider } from 'react-redux';
import { notification } from 'antd';
import store from './javascripts/store/ReduxStore';
import 'react-virtualized/styles.css';
import 'react-base-table/styles.css';
import './stylesheets/_index.scss';
import './i18n';
import { createBugsnagClient } from './javascripts/actions/tools/BugsnagActions';
import AppRoutes from './javascripts/routes/Routes';

store.dispatch(createBugsnagClient());
const BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();

notification.config({
  placement: 'bottomRight',
});

ReactDOM.render(
  <BugsnagErrorBoundary>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </BugsnagErrorBoundary>,
  document.getElementById('root')
);

if ((window as any).Cypress) {
  (window as any).store = store;
}
