const initialState = {
  assetArea: undefined,
  newFileUploadType: undefined,
  file: undefined,
  assetInformation: {
    fileName: '',
    fileTypeId: undefined,
    sourceUrl: '',
    assetTypeId: undefined,
    representationTypeId: 1,
    assetName: '',
  },
  assetNameExistError: false,
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'RESET_UPLOAD_INFORMATION': {
      return {
        ...state,
        assetInformation: initialState.assetInformation,
        file: initialState.file,
      };
    }
    case 'SET_UPLOAD_INFORMATION': {
      return {
        ...state,
        newFileUploadType: payload.uploadType,
        assetArea: payload.assetArea,
      };
    }
    case 'SET_FILE': {
      return {
        ...state,
        file: payload,
      };
    }
    case 'SET_FILE_UPLOAD_INFORMATION': {
      return {
        ...state,
        assetInformation: {
          ...state.assetInformation,
          [payload.key]: payload.value,
        },
      };
    }
    case 'SET_ASSET_NAME_EXIST_ERROR': {
      return {
        ...state,
        assetNameExistError: payload,
      };
    }
  }
  return state;
};

export default reducer;
