import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export const deleteExportBuilderAdvancedTemplate = (templateId?: number) => {
  return {
    type: 'DELETE_EXPORT_BUILDER_ADVANCED_TEMPLATE',
    meta: { templateId },
    payload: axios.delete(`${getApiRoot()}/full_export_templates/${templateId}`),
  };
};

export const deleteExportBuilderAdvancedColumn = (columnId?: number) => {
  return {
    type: 'DELETE_EXPORT_BUILDER_ADVANCED_COLUMN',
    meta: { columnId },
    payload: axios.delete(`${getApiRoot()}/full_export_templates/columns/${columnId}`),
  };
};
