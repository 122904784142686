import React from 'react';
import { Checkbox, Empty, Spin } from 'antd';
import {
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
  TableRowProps,
} from 'react-virtualized';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useSelector } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../../reducers';

const SortableTable = SortableContainer(Table as any);
const SortableTableRowRenderer = SortableElement(
  (props: TableRowProps) => defaultTableRowRenderer(props) as any
);
const DragHandle = SortableHandle(() => <span className="cfs-table-drag">::</span>);

function rowRenderer(props: any) {
  return <SortableTableRowRenderer {...props} />;
}

const DRAG_COLUMN_WIDTH = 50;

type WareHouseDistributorTableProps = {
  selectedWareHouseIds: number[];
  handleWDSelection: (selectedIds: number[]) => void;
  filterKeywords: string;
  wareHouseDistributorList: {
    record_number: number;
    id: number;
    name: string;
  }[];
};

const WareHouseDistributorTable: React.FC<WareHouseDistributorTableProps> = props => {
  const { t } = useTranslation();

  const { fetchingReceiverWarehouseList } = useSelector((state: ApplicationState) => ({
    fetchingReceiverWarehouseList: state.receiver.receivers.fetchingReceiverWarehouseList,
  }));

  const [distributorsData, setDistributorsData] = React.useState(props.wareHouseDistributorList);

  const filterdData = distributorsData.filter(wd =>
    wd.name.toLowerCase().includes(props.filterKeywords.toLowerCase())
  );

  React.useEffect(() => {
    setDistributorsData(props.wareHouseDistributorList);
  }, [props.wareHouseDistributorList]);

  const noData = () => {
    if (fetchingReceiverWarehouseList) return <Spin spinning className="mt-4" size="large" />;

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <SortableTable
          lockAxis="y"
          helperClass="stylizedHelper"
          useWindowAsScrollContainer
          useDragHandle
          // @ts-ignore
          width={width}
          height={height}
          headerHeight={48}
          rowHeight={40}
          rowCount={filterdData.length}
          rowRenderer={rowRenderer}
          rowGetter={({ index }: { index: any }) => {
            return filterdData[index];
          }}
          onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            const increasingIndex = oldIndex > newIndex;
            const smallIndex = increasingIndex ? newIndex : oldIndex;
            const bigIndex = increasingIndex ? oldIndex : newIndex;

            // update record_numbers
            const updatedRecord = filterdData.map(distributor => {
              if (distributor.record_number === oldIndex + 1) {
                return { ...distributor, record_number: newIndex + 1 };
              }
              if (
                smallIndex + 1 <= distributor.record_number &&
                bigIndex + 1 >= distributor.record_number
              ) {
                return {
                  ...distributor,
                  record_number: increasingIndex
                    ? distributor.record_number + 1
                    : distributor.record_number - 1,
                };
              }
              return distributor;
            });
            const sortedDistributorsData = arrayMoveImmutable(updatedRecord, oldIndex, newIndex);
            setDistributorsData(sortedDistributorsData);
            if (props.selectedWareHouseIds.length > 0) {
              props.handleWDSelection(
                sortedDistributorsData
                  .filter(wD => props.selectedWareHouseIds.includes(wD.id))
                  .map(wD => wD.id)
              );
            }
          }}
          noRowsRenderer={noData}
        >
          <Column
            width={DRAG_COLUMN_WIDTH}
            dataKey="id"
            cellRenderer={() => {
              return <DragHandle />;
            }}
          />
          <Column label={t('companyProfile:priority')} width={60} dataKey="record_number" />
          <Column
            flexGrow={1}
            label={t('companyProfile:warehouseDistributor')}
            dataKey="name"
            width={width / 2}
          />
          <Column
            label={t('companyProfile:selected')}
            width={60}
            dataKey="id"
            cellRenderer={({ rowData }) => {
              return (
                <span>
                  {rowData.id && (
                    <Checkbox
                      key={rowData.id}
                      checked={props.selectedWareHouseIds.includes(rowData.id)}
                      onChange={() => {
                        if (props.selectedWareHouseIds.includes(rowData.id)) {
                          const filterdIds = props.selectedWareHouseIds.filter(
                            wdId => wdId !== rowData.id
                          );
                          props.handleWDSelection(
                            distributorsData
                              .filter(dD => filterdIds.includes(dD.id))
                              .map(dD => dD.id)
                          );
                        } else {
                          const allIds = [...props.selectedWareHouseIds, rowData.id];
                          props.handleWDSelection(
                            distributorsData.filter(dD => allIds.includes(dD.id)).map(dD => dD.id)
                          );
                        }
                      }}
                    />
                  )}
                </span>
              );
            }}
          />
        </SortableTable>
      )}
    </AutoSizer>
  );
};

export default WareHouseDistributorTable;
