import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { importAttributeExcelFile } from '../../../../actions/parent/attribute/update';

type AttributeImportModalProps = {
  handleOnCancel: () => void;
  showImportModal: boolean;
};

const AttributeImportModal: React.FC<AttributeImportModalProps> = ({
  handleOnCancel,
  showImportModal,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const readFile = (file: any) => {
    handleOnCancel();
    dispatch(importAttributeExcelFile(file));
  };

  const handleChange = (info: any) => {
    readFile(info.file);
  };

  const renderUploadButton = () => (
    <div data-testid="upload-file">
      <FileExcelOutlined className="background-icon-excel" />
      <div className="flex items-center">
        <PlusOutlined />
        <div className="ml-2">
          {t('common:upload')} {t('common:excel')}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      width="50%"
      title={`${t('common:import')} ${t('attributes:attributes')}`}
      open={showImportModal}
      onCancel={() => {
        handleOnCancel();
      }}
      footer={null}
    >
      <Upload
        accept=".xlsx"
        listType="picture-card"
        showUploadList={false}
        customRequest={handleChange}
        className="translation-attribute-import-modal"
      >
        {renderUploadButton()}
      </Upload>
    </Modal>
  );
};

export default AttributeImportModal;
