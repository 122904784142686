import { Channel, Plan } from '../../types/channel';
import { StandardResourceCodeDescription } from '../../types/resources';

export enum DeliveryType {
  FTP = 1,
  EMAIL = 2,
  MANUAL = 3,
}

export const hasManualDelivery = (plan: Plan, channel: Channel): boolean | null => {
  const ftpSettings = plan.ftp || channel.default_ftp;
  if (!ftpSettings) return null;
  return ftpSettings.delivery_type_id === DeliveryType.MANUAL;
};

export const isDeliveryType = (
  deliveryType: DeliveryType,
  plan: Plan,
  channel: Channel
): boolean | null => {
  const ftpSettings = plan.ftp || channel.default_ftp;
  if (!ftpSettings) return null;
  return ftpSettings.delivery_type_id === deliveryType;
};

export const namingConventionPreview = (
  config: string,
  configOptions: StandardResourceCodeDescription[]
) => {
  let preview = `(${config})`;
  configOptions.forEach(opt => {
    preview = preview.replace(`<${opt.code}>`, `${opt.description || opt.name}`);
  });

  return preview;
};
