import React, { CSSProperties } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';

import { TreeItem, Props as TreeItemProps } from './TreeItem';

interface Props extends TreeItemProps {
  id: UniqueIdentifier;
  disabled?: boolean;
  onEdit?: (id: number, type: string) => void;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }: any) =>
  !(isSorting || wasDragging);

export function SortableTreeItem({ id, depth, disabled, ...props }: Props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableInteraction={isSorting}
      disabled={disabled}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}
