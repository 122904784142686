import React from 'react';
import { Button } from 'antd';
import multiEditIcon from '../../../../images/icons/multi-edit-icon.svg';

type MultiEditButtonProps = {
  handleOnClick: () => void;
};

const MultiEditButton: React.FC<MultiEditButtonProps> = ({ handleOnClick }) => (
  <div className="pl-1" style={{ height: '22px' }}>
    <Button
      icon={
        <img
          src={multiEditIcon}
          alt="start-editing-img"
          style={{
            height: '18px',
            margin: '2px',
          }}
        />
      }
      onClick={handleOnClick}
      size="small"
      style={{ height: '22px' }}
    />
  </div>
);

export default MultiEditButton;
