import React from 'react';

class DateInput extends React.Component {
  state = {
    value: this.props.value || '',
  };

  handleChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <input
        type="date"
        className={this.props.className}
        value={this.state.value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
      />
    );
  }
}

export default DateInput;
