import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Analysis, SegmentScore } from '../../../types/analyses';

export type AnalysesState = {
  readonly fetchingAnalyses: boolean;
  readonly analyses: Analysis[];
  readonly segmentScores: SegmentScore[];
};

const initialState = {
  fetchingAnalyses: false,
  analyses: [],
  segmentScores: [],
};

const reducer: Reducer<AnalysesState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ITEM_ANALYSES_PENDING': {
      const { initial } = action.meta;
      return {
        ...state,
        fetchingAnalyses: initial,
        analyses: initial ? [] : state.analyses,
        segmentScores: initial ? [] : state.segmentScores,
      };
    }
    case 'FETCH_ITEM_ANALYSES_REJECTED': {
      // eslint-disable-next-line no-underscore-dangle
      const fetching = !!action.payload.__CANCEL__;
      return {
        ...state,
        fetchingAnalyses: fetching,
      };
    }
    case 'FETCH_ITEM_ANALYSES_FULFILLED': {
      return {
        ...state,
        analyses: action.payload.data.analyses,
        segmentScores: action.payload.data.segment_scores,
        fetchingAnalyses: false,
      };
    }
    case 'RESET_ITEM_ANALYSES': {
      return { ...initialState };
    }
    case 'HIDE_ANALYSIS_PENDING': {
      const analyses = state.analyses.filter(analysis => analysis.type_id !== action.meta.typeId);
      return { ...state, analyses };
    }
  }
  return state;
};

export default reducer;
