import { combineReducers } from 'redux';
import bugsnag, { BugsnagState } from './BugsnagReducer';
import insights, { InsightsState } from './InsightsReducer';
import retool, { RetoolState } from './RetoolReducer';

export type ToolState = {
  bugsnag: BugsnagState;
  insights: InsightsState;
  retool: RetoolState;
};

const ToolReducer = combineReducers({
  bugsnag,
  insights,
  retool,
});

export default ToolReducer;
