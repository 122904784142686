import { Checkbox, Form, Input } from 'antd';
import React from 'react';

import { SelectValue } from 'antd/lib/select';
import AntSelect, { AntSelectEntries } from '../../global/AntSelect';
import * as utils from '../../../utils/Utils';
import {
  STRING_DEFAULT_VALUE_IDS,
  VCDB_DEFAULT_VALUE_ID,
} from '../../../constants/DefaultValueConstants';

const FormItem = Form.Item;

export type SingleDefaultValueProps = {
  canManageDefaultValues: boolean;
  resources: { [key: string]: AntSelectEntries };
  value: { id: number; name: string; value: string; resource_table: string };
  updateDefaultValue: (id: number, selected: boolean | SelectValue) => string;
};

type SingleDefaultValueState = {
  inputValue?: string;
};

class SingleDefaultValue extends React.Component<SingleDefaultValueProps, SingleDefaultValueState> {
  constructor(props: SingleDefaultValueProps) {
    super(props);
    this.state = {
      inputValue: STRING_DEFAULT_VALUE_IDS.includes(props.value.id)
        ? props.value.value || ''
        : undefined,
    };
  }

  render() {
    const { resources, value, canManageDefaultValues } = this.props;
    const { inputValue } = this.state;
    const formItemLayout = {
      labelCol: { xs: { span: 24 }, xl: { span: 24 }, xxl: { span: 4 } },
      wrapperCol: { xs: { span: 7 }, xxl: { span: 7 } },
    };
    const handleChange = (selectedValue: SelectValue) =>
      this.props.updateDefaultValue(value.id, selectedValue);
    const handleCheckboxChange = (checked: boolean) => {
      this.props.updateDefaultValue(value.id, checked);
    };
    const handleInputChange = (inputValue: string) => {
      this.props.updateDefaultValue(value.id, inputValue);
    };

    const stringValueType = STRING_DEFAULT_VALUE_IDS.includes(value.id);

    const getResourceValues = (value: {
      id: number;
      name: string;
      value: string;
      resource_table: string;
    }) => {
      if (VCDB_DEFAULT_VALUE_ID === value.id) return resources.vcdb_versions;
      return resources[value.resource_table];
    };

    return (
      <FormItem {...formItemLayout} label={value.name}>
        {(value.resource_table || VCDB_DEFAULT_VALUE_ID === value.id) && (
          <AntSelect
            elements={getResourceValues(value)}
            onChange={handleChange}
            value={value.value ? Number(value.value) : value.value}
            disabled={!canManageDefaultValues}
            allowClear
            showSearch
          />
        )}
        {!value.resource_table && !stringValueType && VCDB_DEFAULT_VALUE_ID !== value.id && (
          <Checkbox
            checked={utils.intToBoolean(value.value)}
            onChange={e => handleCheckboxChange(e.target.checked)}
            disabled={!canManageDefaultValues}
          />
        )}
        {stringValueType && (
          <Input
            value={inputValue}
            onChange={e => this.setState({ inputValue: e.target.value })}
            onBlur={() => handleInputChange(inputValue!)}
          />
        )}
      </FormItem>
    );
  }
}

export default SingleDefaultValue;
