import React from 'react';
import { useSelector } from 'react-redux';
import AntMultiSelect from '../../global/AntMultiSelect';
import { ApplicationState } from '../../../reducers';
import useCatalogueQueryParams from '../../../hooks/useCatalogueQuery';
import catalogueTranslation from '../../../constants/CatalogueTranslations.json';

const CatalogueReceiverFilterBrandSelect: React.FC = () => {
  const filterBrandIds = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.catalogueQueryParams.filterBrandIds
  );
  const allAccessableBrands = useSelector(
    (state: ApplicationState) => state.receiverDataStream.allAccessableBrands
  );
  const { updateBrandIds, fetchChannelReceivers } = useCatalogueQueryParams();

  React.useEffect(() => {
    fetchChannelReceivers();
  }, []);

  return (
    <AntMultiSelect
      className="receiver-catalogue__filter-brand__dropdown select-blue-tags"
      placeholder={catalogueTranslation.filterByBrands}
      elements={allAccessableBrands}
      onChange={(brandIds: number[]) => updateBrandIds(brandIds)}
      selectedElements={filterBrandIds}
      showSearch
    />
  );
};
export default CatalogueReceiverFilterBrandSelect;
