import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { AsyncDispatch } from '../../../../types/global';
import { acknowledgeDetailsRequest } from '../../../actions/receiver_data_stream/productsCatalogue/update';

type AttributeValuePopoverProps = {
  code: string;
  parentId: number;
};

const ReceiverBrandPopover: React.FC<AttributeValuePopoverProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const navigate = useNavigate();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  return (
    <Popover
      key={props.code || -1}
      open={popoverVisible}
      onOpenChange={visible => setPopoverVisible(visible)}
      destroyTooltipOnHide
      overlayClassName="receiver__brand-popover"
      content={
        popoverVisible ? (
          <React.Fragment>
            <div className="flex flex-row">
              <ExclamationCircleFilled className="icon__yellow mr-2 mt-1" />
              <div>
                <div className="text-gray-900">{t('receiver:resellerRequestInfo')}</div>
                <Button
                  size="small"
                  type="link"
                  onClick={() => navigate('/settings/company-profile')}
                  className="receiver__brand-popover__company-profile"
                >
                  {t('receiver:companyProfile')}
                </Button>
              </div>
            </div>
            <div className="receiver__brand-popover__action-buttons">
              <Button size="small" onClick={() => setPopoverVisible(false)}>
                {t('receiver:keepInfo')}
              </Button>
              <Button
                type="primary"
                size="small"
                className="ml-2"
                onClick={() => {
                  dispatch(acknowledgeDetailsRequest(props.parentId));
                  setPopoverVisible(false);
                }}
              >
                {t('receiver:gotIt')}
              </Button>
            </div>
          </React.Fragment>
        ) : null
      }
      trigger="click"
    >
      <InfoCircleOutlined className="icon__blue ml-2" onClick={() => setPopoverVisible(true)} />
    </Popover>
  );
};

export default ReceiverBrandPopover;
