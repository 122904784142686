import React from 'react';

import globalConstants from '../../constants/ItemInfoTranslation.json';
import Page from './page/Page.tsx';
import { fillInPlaceholders } from '../../utils/String';

// ItemInfo shows a feedback for the user if there is no product selected or multiple products selected
function ItemInfo(props) {
  const renderInfo = () => {
    if (props.items.length > props.limit) {
      return (
        <h3 className="item_info__multiple-selected title">
          {fillInPlaceholders(globalConstants.body_area.multiple_limit.title, {
            count: props.limit,
          })}
        </h3>
      );
    }

    if (props.items.length > 1) {
      return (
        <h3 className="item_info__multiple-selected title">
          {globalConstants.body_area.multiple_selected.title}
        </h3>
      );
    }
    if (props.items.length === 0) {
      return (
        <div>
          <div className="text-primary-600 font-medium text-base">
            {globalConstants.body_area.no_item_selected.title}
          </div>
          <p className="mt-1">{globalConstants.body_area.no_item_selected.body}</p>
        </div>
      );
    }
  };

  return <Page>{renderInfo()}</Page>;
}

export default ItemInfo;
