import React from 'react';
import { Button, Layout, Select, Modal, Spin } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ExportBuilderAdvancedDrawer from '../../components/body/export_builder_advanced/ExportBuilderAdvancedDrawer';
import { ApplicationState } from '../../reducers';
import {
  fetchExportBuilderAdvancedTemplates,
  fetchExportBuilderAdvancedColumns,
  fetchExportBuilderAdvancedPreview,
  fetchExportBuilderAdvancedSegment,
} from '../../actions/brand/export_builder_advanced/fetch';
import ExportBuilderAddTemplateDrawer, {
  validationSchemaTemplate,
} from '../../components/body/export_builder_advanced/ExportBuilderAddTemplateDrawer';
import PreviewTable from '../../components/body/export_builder_advanced/PreviewTable';
import PageFormik from '../../components/global/page/PageFormik';
import ExportBuilderAdvancedEdit from '../../components/body/export_builder_advanced/ExportBuilderAdvancedEdit';
import {
  updateExportBuilderAdvancedColumnOrder,
  updateExportBuilderAdvancedTemplate,
  resetExportBuilderAdvanced,
  copyEbpTemplate,
} from '../../actions/brand/export_builder_advanced/update';
import { AsyncDispatch } from '../../../types/global';
import { deleteExportBuilderAdvancedTemplate } from '../../actions/brand/export_builder_advanced/delete';
import { hasPermission } from '../../utils/Permissions';
import EnterprisePromotion from '../../components/global/EnterprisePromotion/EnterprisePromotion';
import { withErrorBoundary } from '../../components/global/ErrorBoundary';
import { ExportBuilderAdvancedColumn } from '../../../types/export_builder_advanced';
import { segmentsMappedWithOptions } from '../../selectors/export_builder_advanced/exportBuilderAdvancedSelector';
import { fetchExportOptions } from '../../actions/brand/export/fetch';
import { isReceiver } from '../../utils/UserUtils';
import { fetchAPIToken } from '../../actions/distribution_apisettings';
import { intercomEvent } from '../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

const ExportBuilderAdvancedContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    user,
    brandId,
    templates,
    templateColumns,
    receiver,
    segments,
    fetchingColumns,
    fetchingApplicationResources,
    fetchingSegment,
    fetchingPreview,
    preview,
    hasApiAccess,
    brandCode,
  } = useSelector((state: ApplicationState) => {
    return {
      user: state.user.user,
      brandId: state.parent.brands.selectedBrandId || undefined,
      templates: state.brand.exportBuilderAdvanced.templates,
      templateColumns: state.brand.exportBuilderAdvanced.templateColumns,
      segments: segmentsMappedWithOptions(state),
      fetchingColumns: state.brand.exportBuilderAdvanced.fetchingColumns,
      receiver: state.user.user && isReceiver(state.user.user),
      fetchingApplicationResources: state.resources.fetchingApplicationResources,
      fetchingSegment: state.brand.exportBuilderAdvanced.fetchingSegment,
      fetchingPreview: state.brand.exportBuilderAdvanced.fetchingPreview,
      preview: state.brand.exportBuilderAdvanced.preview,
      hasApiAccess: hasPermission(state.user.user, 'has_api_access'),
      brandCode: getSelectedBrandCode(state),
    };
  }, shallowEqual);

  const [drawerTemplateVisible, setDrawerTemplateVisible] = React.useState(false);
  const [drawerColumnVisible, setDrawerColumnVisible] = React.useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<number | null>(null);
  const [isNewTemplate, setIsNewTemplate] = React.useState(false);
  const [selectedColId, setSelectedColId] = React.useState<number | null>();
  const [addRightToColId, setAddRightToColId] = React.useState<number | null>();

  React.useEffect(() => {
    intercomEvent('viewed-import-export', { location: 'EBP', brand_code: brandCode! });
  }, [brandCode]);

  React.useEffect(() => {
    if (hasApiAccess) dispatch(fetchAPIToken({}));
  }, [dispatch, hasApiAccess]);

  React.useEffect(() => {
    dispatch(fetchExportBuilderAdvancedTemplates(brandId));
    for (let i = 1; i <= 15; i++) {
      dispatch(fetchExportBuilderAdvancedSegment(brandId, i));
    }
  }, [brandId, dispatch]);

  React.useEffect(() => {
    if (selectedTemplateId) {
      dispatch(fetchExportBuilderAdvancedPreview(selectedTemplateId, brandId));
      dispatch(fetchExportBuilderAdvancedColumns(selectedTemplateId, brandId));
    }
  }, [dispatch, selectedTemplateId, brandId]);

  const selectedTemplate = templates.find(({ id }) => id === Number(selectedTemplateId));

  const handleDrawerOpen = () => {
    setDrawerColumnVisible(true);
  };

  const handleSelectNextColumn = () => {
    const index = templateColumns.findIndex(col => col.id === selectedColId);
    if (index !== -1 && index + 1 < templateColumns.length) {
      const nextColId = templateColumns[index + 1].id;
      setSelectedColId(nextColId);
    }
  };

  const handleSelectPrevColumn = () => {
    const index = selectedColId
      ? templateColumns.findIndex(col => col.id === selectedColId)
      : templateColumns.length;
    if (index > 0) {
      const prevColId = templateColumns[index - 1].id;
      setSelectedColId(prevColId);
    }
  };

  const handleDeleteTemplate = () => {
    if (selectedTemplate) {
      confirm({
        title: t('exportBuilderAdvanced:deleteTemplateWarning'),
        okText: t('common:delete'),
        cancelText: t('common:cancel'),
        okButtonProps: { danger: true },
        onOk: () => {
          setDrawerTemplateVisible(false);
          setIsNewTemplate(false);
          return dispatch(deleteExportBuilderAdvancedTemplate(selectedTemplate.id)).then(() => {
            setSelectedTemplateId(null);
            if (receiver) dispatch(fetchExportOptions('export'));
          });
        },
      });
    }
  };

  const handleCopyTemplate = async ({
    newTitle,
    columnIds,
    brandIds,
  }: {
    newTitle: string;
    columnIds: number[];
    brandIds?: number[];
  }) => {
    const copyResponse = await dispatch(
      copyEbpTemplate(selectedTemplate!.id, newTitle || selectedTemplate!.name, columnIds, brandIds)
    );
    dispatch(fetchExportBuilderAdvancedTemplates(brandId));
    return copyResponse;
  };

  const getInitialValues = () => {
    return {
      templateName: selectedTemplate ? selectedTemplate.name : '',
      fileTypeId: selectedTemplate ? selectedTemplate.file_type_id : 2,
      csvSeparator: selectedTemplate ? selectedTemplate.csv_separator : '',
      templateColumns: templateColumns.map(t => ({
        id: t.id,
        label: t.name,
        number: t.number,
        dataKey: t.code,
      })),
    };
  };

  if (!hasPermission(user, 'can_create_full_template')) {
    return <EnterprisePromotion />;
  }

  return (
    <Content className="content h-full" style={{ padding: 0 }}>
      <div className="page-layout">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <div className="flex items-center mr-6">
              <div className="font-medium text-gray-900 mr-2">{t('glossary:template')} :</div>
              <span>
                <Select
                  data-testid="template-selection"
                  value={selectedTemplateId ? selectedTemplateId.toString() : undefined}
                  onChange={(id: string) => setSelectedTemplateId(Number(id))}
                  style={{ width: 400 }}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value?.toString().includes(input)
                  }
                  placeholder={t('exportBuilderAdvanced:searchByTemplateNameId')}
                  allowClear
                >
                  {templates.map(({ id, name }) => (
                    <Option key={id} value={id.toString()} title={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </span>
            </div>
            <div className="page-layout__top-bar__actions">
              <Button
                type="primary"
                onClick={() => {
                  setDrawerTemplateVisible(true);
                  setIsNewTemplate(true);
                }}
                data-testid="add-new-template"
              >
                {t('exportBuilderAdvanced:addTemplate')}
              </Button>
            </div>
          </div>
        </div>
        <div className="page-layout__content">
          {fetchingColumns ? (
            <Spin className="spinner-center" />
          ) : (
            <PageFormik
              enableReinitialize
              contentNoSpacing
              initialValues={getInitialValues()}
              validationSchema={validationSchemaTemplate}
              onSubmit={(values, { setSubmitting, setSubmitError, resetForm, setStatus }) => {
                setSubmitting(true);

                const templateUpdateObj = {
                  id: selectedTemplate ? selectedTemplate.id : undefined,
                  name: values.templateName,
                  csv_separator: values.csvSeparator,
                  file_type_id: values.fileTypeId,
                  parent_owner_brand_id: brandId,
                };

                const orderUpdateList = values.templateColumns.map((c: any, index: number) => ({
                  full_export_template_column_id: c.id,
                  number: index + 1,
                }));

                Promise.all([
                  dispatch(updateExportBuilderAdvancedColumnOrder(orderUpdateList)),
                  dispatch(updateExportBuilderAdvancedTemplate(templateUpdateObj)),
                ])
                  .then(() => {
                    const columns = orderUpdateList.map(
                      (t: { full_export_template_column_id: number; number: number }) => {
                        const column = templateColumns.find(
                          col => col.id === t.full_export_template_column_id
                        );
                        return { ...column, number: t.number };
                      }
                    );

                    resetForm({
                      values: {
                        ...values,
                        templateColumns: columns.map((t: ExportBuilderAdvancedColumn) => ({
                          id: t.id,
                          label: t.name,
                          number: t.number,
                          dataKey: t.code,
                        })),
                      },
                    });

                    setStatus('SUCCESS');
                    setTimeout(() => setStatus('EDIT'), 2500);
                  })
                  .catch(() => setSubmitError());
              }}
            >
              {({ setFieldValue, values }) => {
                if (!selectedTemplateId) return null;
                return (
                  <div className="flex flex-col h-full">
                    <ExportBuilderAdvancedEdit
                      selectedTemplateId={selectedTemplateId}
                      values={values}
                      isReceiver={receiver}
                      hasApiAccess={hasApiAccess}
                      setFieldValue={setFieldValue}
                      handleDeleteTemplate={handleDeleteTemplate}
                      handleCopyTemplate={handleCopyTemplate}
                    />
                    <div className="border-solid border-0 border-b border-gray-400" />
                    <div className="flex-1 p-4">
                      <PreviewTable
                        templateColumns={values.templateColumns}
                        updateColumnsOrder={(cols: any) => {
                          setFieldValue('templateColumns', cols);
                        }}
                        setDrawerVisible={(
                          visible: boolean,
                          colId: number | null,
                          newCol?: boolean
                        ) => {
                          handleDrawerOpen();
                          if (!newCol) {
                            setSelectedColId(colId);
                            setAddRightToColId(null);
                          } else {
                            setSelectedColId(null);
                            setAddRightToColId(colId);
                          }
                        }}
                        fetching={fetchingApplicationResources}
                        fetchingPreview={fetchingPreview}
                        preview={preview}
                        segment="exportBuilderAdvanced"
                      />
                    </div>
                  </div>
                );
              }}
            </PageFormik>
          )}
        </div>

        <ExportBuilderAddTemplateDrawer
          visible={drawerTemplateVisible}
          onClose={() => {
            setDrawerTemplateVisible(false);
            setIsNewTemplate(false);
          }}
          brandId={brandId}
          selectedTemplate={!isNewTemplate ? selectedTemplate : undefined}
          setNewTemplateState={(id: number) => {
            setSelectedTemplateId(id);
            setIsNewTemplate(false);
          }}
          isNewTemplate={isNewTemplate}
          receiver={!!receiver}
        />

        {drawerColumnVisible && (
          <ExportBuilderAdvancedDrawer
            fetchingSegment={fetchingSegment}
            visible={drawerColumnVisible}
            onClose={() => {
              setDrawerColumnVisible(false);
              dispatch(fetchExportBuilderAdvancedPreview(selectedTemplateId!, brandId));
            }}
            updateColumnsOrder={(cols: any) => {
              const orderUpdateList = cols.map((c: any, index: number) => ({
                full_export_template_column_id: c.id,
                number: index + 1,
              }));
              dispatch(updateExportBuilderAdvancedColumnOrder(orderUpdateList));
            }}
            selectNextColumn={() => handleSelectNextColumn()}
            selectPrevColumn={() => handleSelectPrevColumn()}
            createNewColumn={() => setSelectedColId(null)}
            segments={segments}
            selectedColId={selectedColId!}
            addRightToColId={addRightToColId}
            templateColumns={templateColumns}
            selectedTemplateId={selectedTemplateId!}
            brandId={brandId}
          />
        )}
      </div>
    </Content>
  );
};

export default withErrorBoundary(ExportBuilderAdvancedContainer);
