import React from 'react';
import { useSelector } from 'react-redux';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ApplicationState } from '../../reducers';
import noImage from '../../../images/icons/no-image.svg';
import { StandardResource } from '../../../types/resources';
import { DigitalAsset } from '../../../types/digital_asset';

type ImageProps = {
  asset: DigitalAsset | undefined | null;
  handleDelete: () => void;
  height?: number | string;
  width?: number | string;
};

const Image: React.FC<ImageProps> = props => {
  const { asset, handleDelete } = props;

  const [imageNotFound, setImageNotFound] = React.useState(false);
  const [modalPreview, setModalPreview] = React.useState(false);

  const fileTypes = useSelector(
    (state: ApplicationState) => state.resources.data.digital_asset.file_types
  );
  const assetSrc = asset ? asset.uri || asset.asset_url || noImage : noImage;

  const getFileTypeName = (typeId: number) => {
    const fileType = fileTypes.find((type: StandardResource) => type.id === Number(typeId));
    return fileType ? fileType.code : '';
  };

  const setError = () => {
    setImageNotFound(true);
  };

  return (
    <React.Fragment>
      <div
        className="image-container"
        style={{
          height: props.height || 150,
          width: props.width || 150,
        }}
      >
        {imageNotFound ? (
          <div className="image-not-supported">
            <span className="image-not-supported__text">
              {getFileTypeName(asset!.file_type_id)}
            </span>
          </div>
        ) : (
          <img className="image" src={assetSrc} alt="preview" onError={setError} />
        )}
        <div className="action-elements">
          <EyeOutlined className="element" onClick={() => setModalPreview(true)} />
          <DeleteOutlined className="element" onClick={() => handleDelete()} />
        </div>
      </div>

      <Modal open={modalPreview} footer={null} onCancel={() => setModalPreview(false)}>
        <div
          className="flex items-center"
          style={{ width: '100%', height: '100%', paddingTop: 20 }}
        >
          <img
            src={assetSrc}
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            alt="preview"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Image;
