import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import constants from '../../constants/HeaderTranslation.json';

export default class FilterDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropownOpen: false };
  }

  componentWillUnmount() {
    if (this.state.dropownOpen) document.removeEventListener('click', this.handleOutsideClick);
  }

  toggleDropdown = () => {
    // attach/remove event handler
    !this.state.dropownOpen
      ? document.addEventListener('mousedown', this.handleOutsideClick)
      : document.removeEventListener('mousedown', this.handleOutsideClick);

    if (this.props.setDropdownState) this.props.setDropdownState(!this.state.dropownOpen);
    this.setState(prevState => ({ dropownOpen: !prevState.dropownOpen }));
  };

  handleOutsideClick = event => {
    // ignore clicks on the component itself
    if (this.node.contains(event.target)) {
      return;
    }
    this.toggleDropdown();
  };

  changeOption = id => {
    this.props.updateFilterOption({
      sectionKey: this.props.sectionKey,
      optionId: id,
    });
    this.toggleDropdown();
  };

  selected = key => (key === this.props.optionId ? 'selected' : '');

  rows = () =>
    [1, 2, 3, 4, 5].map(id => (
      <div key={id} className={`row ${this.selected(id)}`} onClick={() => this.changeOption(id)}>
        {constants.filterDropdown[id]}
      </div>
    ));

  optionList = () => {
    if (this.state.dropownOpen) {
      return (
        <div
          className="pdm-table header__filter-dropdown_options"
          style={{ transform: `translate(-${this.props.position}px)` }}
        >
          {this.rows()}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="header__filter-dropdown" ref={node => (this.node = node)}>
        <div className="header__filter-dropdown_option">
          {constants.filterDropdown[this.props.optionId]}
        </div>
        <CaretDownOutlined
          className="header__dropdown-icon"
          onClick={() => this.toggleDropdown()}
        />
        {this.optionList()}
      </div>
    );
  }
}

FilterDropdown.defaultProps = {
  optionId: 0,
};
