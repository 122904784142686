import React from 'react';
import { connect } from 'react-redux';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/application';
import { setFile, setUploadInformation } from '../../actions/parent/digital_asset_upload';
import {
  updateDigitalAssetParent,
  showParentDigitalAssets,
} from '../../actions/parent/digital_asset';
import { showModal } from '../../actions/app/modal';
import { MODAL_TYPES } from '../../constants/ModalConstants';
import constants from '../../constants/DigitalAssetsConstants.json';

import DigitalAssetUpload from '../../components/body/digital_assets/DigitalAssetUpload';
import DigitalAssetPreview from '../../components/body/digital_assets/digital_asset_preview/DigitalAssetPreview';
// eslint-disable-next-line
import DigitalAssetInformation from '../../components/body/digital_assets/digital_asset_information/DigitalAssetInformation';
import ParentDigitalAssetsDrawer from '../digital_assets/ParentDigitalAssetsDrawer';

class ApplicationDigitalAssetsContainer extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.fetchApplicationDigitalAssets(this.props.selectedApplicationId));
  }

  setSelectedDigitalAsset = digitalAssetId => {
    this.props.dispatch(actions.selectApplicationDigitalAsset(digitalAssetId));
  };

  showPreview = digitalAsset => {
    this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_PREVIEW, digitalAsset));
  };

  deleteAsset = digitalAssetId => {
    this.props.dispatch(actions.deleteApplicationDigitalAsset(digitalAssetId));
  };

  updateItemInformation = (key, value) => {
    const { selectedApplicationDigitalAssetId } = this.props;
    this.props.dispatch(
      actions.updateApplicationDigitalAsset(selectedApplicationDigitalAssetId, key, value)
    );
  };

  updateDescriptionContents = (descriptionId, contents, languageId, typeId) => {
    const newContents = { contents };
    this.props.dispatch(
      actions.updateDescriptionContents(newContents, descriptionId, languageId, typeId)
    );
  };

  updateAssetInformation = (assetId, key, value) => {
    this.props.dispatch(updateDigitalAssetParent(assetId, key, value));
  };

  handleOpenModal = uploadType => {
    if (uploadType === constants.popupType.parentDigitalAssets) {
      this.props.dispatch(showParentDigitalAssets(true));
    } else {
      this.props.dispatch(setUploadInformation(uploadType, 'application'));
      this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD));
    }
  };

  readFile = file => {
    this.props.dispatch(setFile(file));
    this.props.dispatch(setUploadInformation(constants.popupType.local, 'application'));
    this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD));
  };

  render() {
    const {
      digitalAssetResources,
      digitalAssets,
      languages,
      selectedApplicationDigitalAssetId,
      showParentDigitalAssetsDrawer,
    } = this.props;
    const selectedDigitalAsset =
      digitalAssets &&
      digitalAssets.find(digitalAsset => digitalAsset.id === selectedApplicationDigitalAssetId);
    return (
      <div className="application__qualifiers">
        <div className="digital-assets-container flex">
          <DigitalAssetUpload
            setUploadType={this.setUploadType}
            readFile={this.readFile}
            handleOpenModal={this.handleOpenModal}
          />
          <div className="digital-asset__box-right">
            <DigitalAssetPreview
              digitalAssetResources={digitalAssetResources}
              digitalAssets={digitalAssets}
              selectedDigitalAssetId={selectedApplicationDigitalAssetId}
              setSelectedDigitalAsset={this.setSelectedDigitalAsset}
              showPreview={this.showPreview}
              deleteAsset={this.deleteAsset}
            />
            <DigitalAssetInformation
              area="application"
              selectedDigitalAsset={selectedDigitalAsset}
              digitalAssetResources={digitalAssetResources}
              languages={languages}
              updateItemInformation={this.updateItemInformation}
              updateDescriptionContents={this.updateDescriptionContents}
              updateAssetInformation={this.updateAssetInformation}
            />
            {showParentDigitalAssetsDrawer && (
              <ParentDigitalAssetsDrawer digitalAssets={digitalAssets} area="application" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedApplicationId: state.items.application.selectedApplicationId,
    digitalAssets: state.items.application.applicationDigitalAssets,
    selectedApplicationDigitalAssetId: state.items.application.selectedApplicationDigitalAssetId,
    digitalAssetResources: state.resources.data.digital_asset,
    languages: state.resources.data.global.languages,
    showParentDigitalAssetsDrawer: state.parent.digitalAsset.showParentDigitalAssets,
  };
}

export default connect(mapStateToProps)(withContainerWrapper(ApplicationDigitalAssetsContainer));
