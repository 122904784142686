import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getSelectedBrandId } from '../../../utils/ReduxUtils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;
let fetchValuesCanelToken: any;
let fetchUnitsCancelToken: any;

export function fetchAttributes(params: {
  page: number;
  limit: number;
  value_limit: number;
  parent_owner_brand_id: number;
  item_ids?: number[];
  filter_id?: number;
  filters?: string;
}) {
  if (fetchCancelToken) fetchCancelToken();

  return {
    meta: { page: params.page },
    type: 'FETCH_ATTRIBUTES',
    payload: axios.post(`${getNonGwApiRoot()}/part_attributes/ranks`, params, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
  };
}

export function fetchValues({
  itemIds,
  attributeIds,
  filter,
  page = 1,
}: {
  itemIds: number[];
  attributeIds?: number[];
  filter?: string;
  page?: number;
}) {
  if (fetchValuesCanelToken) fetchValuesCanelToken();

  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    const brandId = getSelectedBrandId(getState());
    const params = utils.createURL([
      { name: 'parent_owner_brand_id', values: brandId },
      { name: 'item_ids', values: itemIds },
      { name: 'part_attribute_ids', values: attributeIds },
      { name: 'filters', values: utils.stringToArray(filter) },
      { name: 'limit', values: utils.getPageLimit() },
      { name: 'page', values: page },
    ]);
    return dispatch({
      meta: { page },
      type: 'FETCH_ATTRIBUTE_VALUES',
      payload: axios.get(`${getNonGwApiRoot()}/part_attributes/values/ranks${params}`, {
        cancelToken: new CancelToken(c => {
          fetchValuesCanelToken = c;
        }),
      }),
    });
  };
}

export function fetchUnits({
  itemIds,
  attributeIds,
  filter,
  page = 1,
}: {
  itemIds: number[];
  attributeIds?: number[];
  filter?: string;
  page?: number;
}) {
  if (fetchUnitsCancelToken) fetchUnitsCancelToken();

  const params = utils.createURL([
    { name: 'item_ids', values: itemIds },
    { name: 'part_attribute_ids', values: attributeIds },
    { name: 'filters', values: utils.stringToArray(filter) },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    meta: { page },
    type: 'FETCH_ATTRIBUTE_UNITS',
    payload: axios.get(`${getNonGwApiRoot()}/part_attributes/meta_uoms/ranks${params}`, {
      cancelToken: new CancelToken(c => {
        fetchUnitsCancelToken = c;
      }),
    }),
  };
}

export function fetchAttributesCount(item_ids: number[]) {
  return {
    type: 'FETCH_ATTRIBUTES_COUNT',
    payload: axios.post(`${getApiRoot()}/part_attributes/counts`, { item_ids }),
  };
}
