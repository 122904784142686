import { Alert, Form, Spin, Switch } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ImportOptions, ImportTypes } from '../../../../types/import_export';
import { ApplicationState } from '../../../reducers';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';
import { checkForRunningImport } from '../../../utils/ImportExportUtils';
import { intercomEvent, startIntercomTour } from '../../../utils/IntercomUtils';
import ImportFormatOptions from './ImportFormatOptions';
import UploadField from './UploadField';
import { hasPermission } from '../../../utils/Permissions';
import { importFile } from '../../../actions/importer';
import {
  ACES,
  ASSETS,
  EXCEL,
  ONLY_SELECTED_SEGMENTS,
  PIES,
} from '../../../constants/ImportExportConstants';
import {
  articles as helpCenterArticles,
  url as helpCenterUrl,
} from '../../../constants/HelpCenterConstants.json';
import { tutorialIds } from '../../../constants/IntercomTours.json';

const ImportPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [enabledAdvOption, setEnabledAdvOption] = React.useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] = React.useState<number>(10);

  const formItemLayout = {
    labelCol: {
      sm: { span: 5 },
    },
    wrapperCol: {
      sm: { span: 19 },
    },
  };

  const {
    excelImportRunning,
    assetsImportRunning,
    acesImportRunning,
    piesImportRunning,
    brandCode,
    brandId,
    user,
    fetchingImportOptions,
    importOptions,
  } = useSelector((state: ApplicationState) => {
    const imports = state.importer.imports;
    const brandId = state.parent.brands.selectedBrandId;
    return {
      excelImportRunning: checkForRunningImport(imports, ImportTypes.EXCEL, brandId),
      assetsImportRunning: checkForRunningImport(imports, ImportTypes.ASSET, brandId),
      acesImportRunning: checkForRunningImport(imports, ImportTypes.ACES, brandId),
      piesImportRunning: checkForRunningImport(imports, ImportTypes.PIES, brandId),
      brandCode: getSelectedBrandCode(state),
      importOptions: state.brand.importExport.importOptions,
      fetchingImportOptions: state.brand.importExport.fetchingImportOptions,
      user: state.user.user,
      brandId,
    };
  }, shallowEqual);

  const canManageAdvancedImportOptions = hasPermission(user, 'can_manage_advanced_import_options');
  const canImportExcel = hasPermission(user, 'can_import_excel');
  const canImportAssets = hasPermission(user, 'can_import_assets');
  const canImportXml = hasPermission(user, 'can_import_xml');

  const getEventType = (fileType: ImportTypes) => {
    switch (fileType) {
      case ImportTypes.EXCEL:
        return 'import-excel';
      case ImportTypes.ACES:
        return 'import-aces';
      case ImportTypes.PIES:
        return 'import-pies';
      case ImportTypes.ASSET:
        return 'import-digital-assets';
    }
  };

  const sendIntercomImportEvent = (
    fileType: ImportTypes,
    importOptions: string[],
    selectedSegments?: any
  ) => {
    const type = getEventType(fileType);

    intercomEvent('viewed-import-export', {
      location: 'import',
      type,
      segments: selectedSegments ? selectedSegments.join(', ') : undefined,
      selectedOptions: importOptions.join(', '),
      brand_code: brandCode!,
    });
  };

  const getImportOptions = () => {
    const initValues: { [key: string]: string | number | number[] | null } = {};
    importOptions.forEach(option =>
      option.import_options?.forEach(opt => {
        if (opt.format_type_id === 1 || opt.format_type_id === 2)
          initValues[opt.id] = opt.default_value && Number(opt.default_value);
        if (opt.format_type_id === 3)
          initValues[opt.id] = opt.default_value ? Number(opt.default_value) : null;
        if (opt.format_type_id === 4) initValues[opt.id] = opt.default_value || [];
        if (opt.format_type_id === 6) initValues[opt.id] = opt.default_value || [];
        if (opt.format_type_id === 5) initValues[opt.id] = opt.default_value;
      })
    );
    return initValues;
  };

  const getAllowedOptions = (importOptions: ImportOptions[]) => {
    return importOptions.find(importOption => importOption.id === selectedFormat)?.import_options;
  };

  const schema = () => {
    const importOptionsSchema: any = {};
    getAllowedOptions(importOptions)
      ?.filter(option => option.mandatory === 1)
      .forEach(f => {
        if ([4, 6].includes(f.format_type_id))
          importOptionsSchema[f.id] = Yup.array().min(1, t('validation:required'));
        if ([1, 2, 3].includes(f.format_type_id))
          importOptionsSchema[f.id] = Yup.number().nullable().required(t('validation:required'));
        else Yup.string().required(t('validation:required'));
      });
    return importOptionsSchema;
  };

  const getValidationSchema = () => {
    return Yup.object().shape({
      importOptions: Yup.object().shape({
        ...schema(),
      }),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        showAdvImportOptions: false,
        selectedFormat: 10,
        importOptions: getImportOptions(),
        file: null,
        fileType: null,
      }}
      validationSchema={getValidationSchema()}
      onSubmit={(values, formikActions) => {
        const { setSubmitting } = formikActions;
        setSubmitting(true);
        const selectedImportOptions: { [key: string]: any } = {};
        const selectedOptions = getAllowedOptions(importOptions);

        selectedOptions?.forEach(option => {
          selectedImportOptions[option.id] = values.importOptions[option.id];
        });

        let selectedSegments: any;
        if (values.selectedFormat === 10) {
          selectedSegments = selectedOptions
            ?.find(option => option.id === ONLY_SELECTED_SEGMENTS)
            ?.parameters // @ts-ignore
            .filter(option => values.importOptions[ONLY_SELECTED_SEGMENTS].includes(option.id))
            .map(option => option.name);
        }

        const impOptions = Object.keys(selectedImportOptions).map(
          key => selectedOptions?.find(option => option.id === Number(key))?.name || ''
        );

        setSubmitting(false);
        dispatch(importFile(values.file!, values.fileType!, brandId, selectedImportOptions));
        sendIntercomImportEvent(values.fileType!, impOptions, selectedSegments);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const readFile = (event: any, newFile: any, fileType: ImportTypes) => {
          setFieldValue('file', event.target.files[0] || newFile);
          setFieldValue('fileType', fileType);
          setEnabledAdvOption(values.showAdvImportOptions);
          setSelectedFormat(values.selectedFormat);
          handleSubmit();
        };

        const advOptions = importOptions
          .find(option => option.id === values.selectedFormat)
          ?.import_options?.find(impOption => impOption.advanced === 1);

        if (fetchingImportOptions) return <Spin className="spinner-center mt-2" />;

        return (
          <React.Fragment>
            <div className="flex justify-between items-center import-page__header">
              <h1>{t('importer:importFiles')}</h1>
              {canManageAdvancedImportOptions && (
                <div className="flex leading-10">
                  <span className="text-gray-800 pt-px pr-1">
                    {t('importer:showAdvImportOptions')}
                  </span>
                  <span>
                    <Switch
                      checked={values.showAdvImportOptions}
                      onChange={checked => {
                        setFieldValue('showAdvImportOptions', checked);
                        setEnabledAdvOption(checked);
                      }}
                      size="small"
                      disabled={!advOptions}
                    />
                  </span>
                </div>
              )}
            </div>
            <Form {...formItemLayout} labelAlign="left" className="import-format-options" labelWrap>
              <ImportFormatOptions
                importOptions={importOptions}
                displayAdvanceOptions={values.showAdvImportOptions}
                handleUpdate={formatId => {
                  setEnabledAdvOption(false);
                  setSelectedFormat(formatId);
                }}
              />
              <Form.Item label={t('importer:uploadFiles')} key="upload-file">
                {values.selectedFormat === EXCEL && canImportExcel && (
                  <React.Fragment>
                    <UploadField
                      inactive={excelImportRunning || acesImportRunning || piesImportRunning}
                      fileType={ImportTypes.EXCEL}
                      name={t('importer:excel')}
                      readFile={readFile}
                      canUploadFile={
                        // @ts-ignore
                        values.importOptions[ONLY_SELECTED_SEGMENTS]?.length > 0
                      }
                    />
                    <Alert
                      className="import-export__alert"
                      showIcon
                      message={
                        <div>
                          {t('importer:import.excel_info.entry')}
                          <ul>
                            <li>
                              <span
                                onClick={() => startIntercomTour(tutorialIds.excel_import)}
                                className="import-export__alert-link"
                              >
                                {t('importer:import.excel_info.watch_tutorial')}
                              </span>
                            </li>
                            <li style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                // eslint-disable-next-line max-len
                                href={helpCenterUrl + helpCenterArticles.bulk_import}
                              >
                                {t('importer:import.excel_info.create_template')}
                              </a>
                            </li>
                            <li style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={helpCenterUrl + helpCenterArticles.learn_overwrite}
                              >
                                {t('importer:import.excel_info.learn_overwrite')}
                              </a>
                            </li>
                          </ul>
                          {t('importer:import.excel_info.outry')}
                        </div>
                      }
                    />
                  </React.Fragment>
                )}
                {values.selectedFormat === ASSETS && canImportAssets && (
                  <React.Fragment>
                    <UploadField
                      big
                      inactive={assetsImportRunning}
                      fileType={ImportTypes.ASSET}
                      name={t('importer:digitalAssets')}
                      readFile={readFile}
                    />
                    <Alert
                      className="import-export__alert"
                      showIcon
                      message={t('importer:import.asset_info')}
                    />
                  </React.Fragment>
                )}
                {values.selectedFormat === ACES && canImportXml && (
                  <React.Fragment>
                    <UploadField
                      fileType={ImportTypes.ACES}
                      inactive={acesImportRunning || piesImportRunning || excelImportRunning}
                      name={t('importer:aces')}
                      readFile={readFile}
                    />
                    <Alert
                      className="import-export__alert"
                      showIcon
                      message={t('importer:import.xml_info')}
                    />
                  </React.Fragment>
                )}
                {values.selectedFormat === PIES && canImportXml && (
                  <React.Fragment>
                    <UploadField
                      fileType={ImportTypes.PIES}
                      inactive={piesImportRunning || acesImportRunning || excelImportRunning}
                      name={t('importer:pies')}
                      readFile={readFile}
                    />
                    <Alert
                      className="import-export__alert"
                      showIcon
                      message={t('importer:import.xml_info')}
                    />
                  </React.Fragment>
                )}
              </Form.Item>
            </Form>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default ImportPage;
