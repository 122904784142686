import React from 'react';
import ReactGa from 'react-ga';
import { useLocation } from 'react-router-dom';

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) ReactGa.initialize('UA-109149903-4');

const useGoogleAnalytics = () => {
  const location = useLocation();

  const [path, setPath] = React.useState('');

  const trackPage = (page: string) => {
    ReactGa.set({ page });
    ReactGa.pageview(page);
  };

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (isProduction && path !== currentPath) {
      trackPage(currentPath);
      setPath(currentPath);
    }
  }, [location, path]);
};

export default useGoogleAnalytics;
