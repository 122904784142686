import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import clockImage from '../../../images/icons/register-clock.svg';
import { AsyncDispatch } from '../../../types/global';
import useGoogleAnalytics from '../../containers/GoogleAnalyticsTracker';
import LoginFooter from '../LoginFooter';
import { registerEbay } from '../../actions/items/ebay/create';

const VerifyEbaySuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  useGoogleAnalytics();

  React.useEffect(() => {
    const values = queryString.parse(location.search);
    const { code } = values;
    if (typeof code === 'string') dispatch(registerEbay(code));
  }, [dispatch]);

  return (
    <div className="login__background">
      <div className="login__box">
        <div className="login__box-header">
          <div className="login-logo" />
          <div className="login__box-content mt-10">
            <div className="login--header-content">
              <img src={clockImage} alt={t('login:success')} />
              <div className="login__title mt-3">{t('login:success')}</div>
            </div>
            <div className="login__box-login">
              <div className="text-base">{t('ebay:successfullyConnected')}</div>
              <div className="text-base">{t('ebay:closeWindow')}</div>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default VerifyEbaySuccessPage;
