import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  SetFilterValuesFuncParams,
  ISetFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AutoResizer } from 'react-base-table';

import { ApplicationElement } from '../../../../types/all_application_validation';
import flatGridConfigs from '../../../constants/ApplicationFlatGridConfigs.json';
import flatGridEquipmentConfigs from '../../../constants/ApplicationFlatGridEquipmentConfigs.json';
import { ApplicationType } from '../../../../types/application';
import { createYearRanges, stringifyYearRanges } from '../../../utils/ApplicationUtils';

const configNames = flatGridConfigs as { [key: string]: string };
const equipmentConfigNames = flatGridEquipmentConfigs as { [key: string]: string };

type ApplicationSplitTableProps = {
  columns: Array<{ [key: string]: string[] } | string>;
  mappedApplicationRows: ApplicationElement[];
  applicationType: ApplicationType;
  updateGroups: (configName: string, filterValues: any) => void;
};

const ApplicationSplitTable: React.FC<ApplicationSplitTableProps> = props => {
  const { columns, mappedApplicationRows, applicationType } = props;

  const gridRef: any = useRef();
  const columnsFlatList = columns.map(c => (typeof c === 'string' ? c : Object.values(c))).flat(2);
  const usedColumns = columnsFlatList.filter(c => mappedApplicationRows[0].hasOwnProperty(c));

  const [columnDefs, setColumnDef] = useState<ColDef[]>(
    ['group', ...usedColumns]
      .filter(
        c =>
          c !== 'category' &&
          c !== 'year_count' &&
          c !== 'part_number' &&
          c !== 'qty' &&
          c !== 'qualifiers' &&
          c !== 'notes'
      )
      .map(c => {
        if (typeof c === 'string') {
          const pinnedCols =
            applicationType === ApplicationType.VEHICLE
              ? ['makes', 'models', 'year']
              : ['mfrs', 'equipment_models', 'vehicle_types', 'year', 'regions'];
          return {
            headerName:
              applicationType === ApplicationType.VEHICLE
                ? configNames[c]
                : equipmentConfigNames[c],
            field: c,
            pinned: pinnedCols.includes(c) ? 'left' : undefined,
            rowGroup: c === 'group',
            hide: c === 'group',
            sort: c === 'group' ? 'asc' : undefined,
            suppressColumnsToolPanel: c === 'group',
            suppressFiltersToolPanel: c === 'group',
            valueGetter,
            valueFormatter,
            filter: 'agSetColumnFilter',
            filterParams: {
              defaultToNothingSelected: true,
              values: (params: SetFilterValuesFuncParams) => {
                const field = params.colDef.field;
                const values = params.context.mappedApplicationRows.map(
                  (r: { [x: string]: any }) => r[field!]
                );

                setTimeout(() => {
                  params.success(values);
                }, 30);
              },
            } as ISetFilterParams,
          };
        }
        // todo: check
        return { field: '' };
      })
  );

  const [rowData, setRowData] = useState<ApplicationElement[]>([]);

  React.useEffect(() => {
    if (gridRef.current) {
      setRowData(mappedApplicationRows);
    }
  }, [mappedApplicationRows]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 120,
    }),
    []
  );

  const listYears = (years: number[]) => {
    const yearRanges = createYearRanges(years);
    return yearRanges.length ? stringifyYearRanges(yearRanges) : '';
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnExpandAll: true,
          },
        },
        'filters',
      ],
      defaultToolPanel: '',
    }),
    []
  );

  function valueGetter(params: ValueGetterParams) {
    const field = params.colDef.field || '';
    const filterModel = params.api.getFilterModel();
    if (filterModel[field]) {
      return filterModel[field].values[0];
    }
    return `${params.data[field] || ''}`;
  }

  function valueFormatter(params: ValueFormatterParams) {
    const field = params.colDef.field || '';
    return `${(params.data && params.data[field]) || ''}`;
  }

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      setRowData(mappedApplicationRows);
    },
    [mappedApplicationRows]
  );

  const groupRowRenderer = useCallback(
    (params: ICellRendererParams) => {
      const dataRows = mappedApplicationRows.filter(r => r.group === Number(params.value));

      const makes = [...new Set(dataRows.map(r => r.makes))].filter(Boolean);
      const models = [...new Set(dataRows.map(r => r.models))].filter(Boolean);
      const years = [...new Set(dataRows.map(r => r.year))];

      const submodels =
        models.length === 1 && [...new Set(dataRows.map(r => r.sub_models))].join('/');
      const regions = models.length === 1 && [...new Set(dataRows.map(r => r.regions))].join('/');

      const mfrs = [...new Set(dataRows.map(r => r.mfrs))].filter(Boolean);
      const equipmentModels = [...new Set(dataRows.map(r => r.equipment_models))].filter(Boolean);
      const vehicleTypes = [...new Set(dataRows.map(r => r.vehicle_types))];

      const yearRange =
        (models.length === 1 || equipmentModels.length === 1) && listYears(years || []);

      const equipmentApplication = equipmentModels.length > 0;

      const name = equipmentApplication
        ? `${yearRange || ''} ${mfrs.join('/')} ${equipmentModels.join('/')} ${
            vehicleTypes || ''
          } ${regions || ''}`
        : makes.length > 0 &&
          `${yearRange || ''} ${makes.join('/')} ${models.join('/')} ${submodels || ''} ${
            regions || ''
          }`;

      return (
        <div>
          {Number(params.value) === 0 ? `${name}` : `${name}`}
          {` (${dataRows.length})`}
        </div>
      );
    },
    [mappedApplicationRows]
  );

  const groupRowRendererParams = useMemo(() => {
    return {
      innerRenderer: groupRowRenderer,
      suppressCount: true,
    };
  }, [groupRowRenderer]);

  return (
    <AutoResizer>
      {resProps => (
        <div style={{ height: resProps.height, width: resProps.width }}>
          <div className="ag-theme-alpine h-full">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              groupRowRendererParams={groupRowRendererParams}
              groupDefaultExpanded={1}
              groupDisplayType="groupRows"
              onGridReady={onGridReady}
              suppressRowClickSelection
              onFilterChanged={e => {
                const changedFilterModel = e.api.getFilterModel();
                const configName = e.columns[0].getColId();

                props.updateGroups(configName, changedFilterModel);
              }}
              onFilterModified={e => {}}
              context={{ mappedApplicationRows }}
              groupMaintainOrder
            />
          </div>
        </div>
      )}
    </AutoResizer>
  );
};

export default ApplicationSplitTable;
