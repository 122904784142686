import React from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import VcdbEdit from '../../../components/body/application/VcdbEdit';
import { ApplicationState } from '../../../reducers';
import { fetchVcdbVersions } from '../../../actions/items/application/fetch';
import { intercomEvent } from '../../../utils/IntercomUtils';

const CustomVcdbSettingsContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchVcdbVersions());
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-company-cvt-settings');
  }, []);

  const { initialAccountResourcesFetching, fetchingVcdbVersions } = useSelector(
    (state: ApplicationState) => {
      return {
        initialAccountResourcesFetching: state.resources.initialAccountResourcesFetching,
        fetchingVcdbVersions: state.items.application.fetchingVcdbVersions,
      };
    }
  );

  if (initialAccountResourcesFetching || fetchingVcdbVersions)
    return (
      <div className="mt-10">
        <Spin className="spinner-center" size="large" />
      </div>
    );

  return <VcdbEdit page />;
};

export default CustomVcdbSettingsContainer;
