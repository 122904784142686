import {
  ExpiAnalysis,
  UsedExtendedInfo,
  ExpiGroup,
  ExtendedInfo,
  ExpiContent,
  ExpiType,
} from '../../../../types/extendedInfo';
import { AnalysisItem } from '../../../../types/analyses';
import { Group, GroupType } from '../../../../types/resources';

export const getValueAnalysis = (
  analyses: ExpiAnalysis[],
  itemId: number,
  typeName: string,
  valueTags: string[],
  existingExpiValuesWithIds: Record<string, any>
) => {
  const analysisInfo = analyses.find(analysis => {
    if (valueTags.length > 0) {
      return (
        existingExpiValuesWithIds[typeName]?.find((type: any) =>
          valueTags.find(
            tag => tag === type.value && type.id.toString() === analysis.reference_id?.toString()
          )
        ) &&
        analysis.reference_table === 'item_extended_informations' &&
        analysis.items.find((item: AnalysisItem) => item.id === itemId)
      );
    }
  });
  return analysisInfo;
};

export const getGroupAnalyses = (
  analyses: ExpiAnalysis[],
  typeName: string,
  existingExpiValuesWithIds: Record<string, any>,
  typeCode: string,
  itemId: number
) => {
  if (existingExpiValuesWithIds[typeName]) {
    if (existingExpiValuesWithIds[typeName].length > 0) {
      return analyses.filter(analysis => {
        return existingExpiValuesWithIds[typeName].find(
          (expi: any) => expi.id.toString() === analysis.reference_id?.toString()
        );
      });
    }
  }

  if (typeCode !== '') {
    return analyses.filter(
      analysis =>
        analysis.details &&
        analysis.details === typeCode &&
        analysis.items.find((item: any) => item.id === itemId)
    );
  }
};

export const getUsedGroups = (
  extendedInfoResource: any,
  usedExtendedInfoTypes: UsedExtendedInfo[]
) => {
  const usedGroups: ExpiGroup[] = [];
  extendedInfoResource.groups.forEach((group: Group) => {
    const usedTypes: GroupType[] = [];
    group.types.forEach(type => {
      usedExtendedInfoTypes.forEach((usedType: UsedExtendedInfo) => {
        if (usedType.type_id === type.id) {
          usedTypes.push(type);
        }
      });
    });
    if (usedTypes.length > 0) {
      usedGroups.push({ id: group.id, name: group.name, types: usedTypes });
    }
  });
  return usedGroups;
};

export const getTypeContentStrings = (extendedInfoTypes: ExpiType[]) => {
  const existingTypeContents: { [key: string]: string[] } = {};

  extendedInfoTypes.forEach(type => {
    const stringContents = type.contents.map(content => content.value);
    existingTypeContents[type.id] = stringContents;
  });

  return existingTypeContents;
};

export const getTypeContents = (extendedInfo: ExtendedInfo) => {
  const existingTypeContentsWithIds: { [key: string]: ExpiContent[] } = {};

  if (extendedInfo) {
    extendedInfo.types.forEach(type => {
      const typeId = type.id.toString();
      existingTypeContentsWithIds[typeId] = type.contents;
    });
  }

  return existingTypeContentsWithIds;
};
