import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikValues, FormikHelpers, FormikProps } from 'formik';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Tooltip,
  Row,
  Col,
  Button,
  message,
  DatePicker,
  Select,
  Empty,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { AsyncDispatch } from '../../../../types/global';
import { PriceSheet, UseType } from '../../../../types/price_sheet';
import { fetchPriceSheetUses } from '../../../actions/parent/price_sheets/fetch';
import SimpleDivider from '../../global/SimpleDivider';
import { assignToReceivers, updatePriceSheet } from '../../../actions/parent/price_sheets/update';
import { unassignPriceSheetToReciver } from '../../../actions/parent/price_sheets/delete';
import { createPriceSheet } from '../../../actions/parent/price_sheets/create';
import { ApplicationState } from '../../../reducers';
import DeleteListModal from './DeleteListModal';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';

type PriceSheetDrawerProps = {
  onClose: () => void;
  priceSheet?: PriceSheet;
  visible: boolean;
  canManagePriceSheet: boolean;
};

const PriceSheetDrawer: React.FC<PriceSheetDrawerProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const [assignedPriceSheet, setAssignedPriceSheet] = React.useState<null | number>(null);

  const { resources, priceSheets, uses, brandCode } = useSelector((state: ApplicationState) => {
    return {
      resources: state.resources.data.price,
      uses: state.parent.priceSheets.uses,
      priceSheets: state.parent.priceSheets.priceSheets,
      brandCode: getSelectedBrandCode(state),
    };
  });

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const noUses: UseType = {
    brands: [],
    channel_plans: [],
    receivers: [],
  };

  React.useEffect(() => {
    if (props.priceSheet) dispatch(fetchPriceSheetUses(props.priceSheet.id));
  }, [dispatch, props.priceSheet]);

  const useHeaders = (props.priceSheet && Object.keys(uses)) || Object.keys(noUses);

  const handleDelete = () => {
    setShowModal(true);

    intercomEvent('viewed-brand-settings', {
      location: 'settings-price-sheets',
      brand_code: brandCode!,
      action: 'delete',
    });
  };

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    setSubmitting(true);

    if (props.priceSheet)
      dispatch(updatePriceSheet(props.priceSheet.id, values))
        .then(() => {
          props.onClose();
          setSubmitting(false);
        })
        .catch(() => {
          message.error(t('settings:priceSheets.errorMessage'));
          setSubmitting(false);
        });
    else
      dispatch(createPriceSheet(values))
        .then(() => {
          setSubmitting(false);
          props.onClose();
        })
        .catch(() => {
          message.error(t('settings:priceSheets.errorMessage'));
          setSubmitting(false);
        });
  };

  const validationSchema = () => {
    return Yup.object().shape({
      number: Yup.string()
        .max(15, t('validation:maximumLength', { length: 15 }))
        .required(t('validation:required')),
      currency_id: Yup.number().nullable().required(t('validation:required')),
    });
  };

  const renderListUses = () => (
    <div className="flex">
      {useHeaders.map((use: string) => (
        <div key={use} className="border border-solid border-gray-400 flex-1 mr-2 h-56">
          <div className="price-sheet-drawer__list-header text-opacity-100 text-black font-medium">
            {t(`settings:priceSheets.${use}`)}
          </div>

          <AutoSizer>
            {({ height, width }) => {
              const rowRenderer = ({ index, key, style }: ListRowProps) => {
                // @ts-ignore
                const data = props.priceSheet ? uses[use][index] : noUses[use][index];
                return (
                  <div key={key} style={style} className="price-sheet-drawer__list-values">
                    {data.name}
                  </div>
                );
              };
              const noData = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
              return (
                <List
                  width={width}
                  height={height - 46}
                  rowHeight={40}
                  // @ts-ignore
                  rowCount={props.priceSheet ? uses[use].length : noUses[use].length}
                  rowRenderer={rowRenderer}
                  noRowsRenderer={noData}
                />
              );
            }}
          </AutoSizer>
        </div>
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <div>
        <DeleteListModal
          showModal={showModal!}
          handleOnCancel={() => setShowModal(false)}
          handleClose={() => props.onClose()}
          renderListHeaders={useHeaders || []}
          renderData={props.priceSheet! ? uses || [] : noUses || []}
          priceSheet={props.priceSheet}
        />
      </div>
      <DrawerFormik
        title={props.priceSheet ? props.priceSheet.number : t('settings:priceSheets.newPriceSheet')}
        visible={props.visible}
        onClose={() => {
          props.onClose();
        }}
        onDelete={props.priceSheet ? () => handleDelete() : undefined}
        initialValues={{
          number: props.priceSheet?.number || '',
          superseded_price_sheet_number: props.priceSheet?.superseded_price_sheet_number || '',
          name: props.priceSheet?.name || '',
          currency_id: props.priceSheet?.currency_id || null,
          effective_date: props.priceSheet?.effective_date || '',
          expiration_date: props.priceSheet?.expiration_date || '',
        }}
        validationSchema={validationSchema()}
        width="60%"
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
          props.canManagePriceSheet && formik.dirty
        }
      >
        {({ values, setFieldValue }) => {
          return (
            <Form layout="vertical" className="price-sheet-drawer">
              <Row gutter={16}>
                <Col span={12}>
                  <FormInput
                    label={t('pricing:number')}
                    name="number"
                    placeholder={t('pricing:number')}
                    required
                    testId="number"
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    label={t('pricing:supersededNumber')}
                    name="superseded_price_sheet_number"
                    placeholder={t('pricing:supersededNumber')}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormInput
                    label={t('pricing:name')}
                    name="name"
                    placeholder={t('pricing:name')}
                  />
                </Col>
                <Col span={12}>
                  <FormSelect
                    label={t('pricing:currency')}
                    name="currency_id"
                    values={resources.currencies}
                    placeholder={t('pricing:currency')}
                    allowClear
                    showSearch
                    required
                    testId="currency-id"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t('pricing:effectiveDate')}>
                        <DatePicker
                          size="small"
                          onChange={(momentDate, stringDate) =>
                            setFieldValue('effective_date', stringDate)
                          }
                          value={values.effective_date ? moment(values.effective_date) : undefined}
                          format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('pricing:expirationDate')}>
                        <DatePicker
                          size="small"
                          onChange={(momentDate, stringDate) =>
                            setFieldValue('expiration_date', stringDate)
                          }
                          value={
                            values.expiration_date ? moment(values.expiration_date) : undefined
                          }
                          format={['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'l']}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item label={t('settings:priceSheets.usesText')}>
                {useHeaders && renderListUses()}
              </Form.Item>
              <SimpleDivider
                title={t('settings:priceSheets.receiverAction')}
                className="price-sheet__form-no-spacing"
              />
              <Row>
                <Col span={12}>
                  <Form.Item
                    label={
                      <div className="flex items-center">
                        <div>{t('settings:priceSheets.assignToOtherSheets')}</div>
                        <Tooltip
                          title={<div className="w-120">{t('settings:priceSheets.infoText')}</div>}
                          className="ml-1"
                        >
                          <InfoCircleOutlined className="ant-info-tooltip" />
                        </Tooltip>
                      </div>
                    }
                  >
                    <Row gutter={16}>
                      <Col span={16}>
                        <Select
                          allowClear
                          disabled={!props.priceSheet}
                          value={assignedPriceSheet!}
                          onChange={value => setAssignedPriceSheet(value)}
                          showSearch
                          filterOption={(inputValue, option) =>
                            option?.children &&
                            option?.children[0].toLowerCase().includes(inputValue.toLowerCase())
                          }
                          placeholder={t('settings:priceSheets.priceSheet')}
                        >
                          {priceSheets.map((priceSheet: PriceSheet) => (
                            <Select.Option key={priceSheet.id} value={priceSheet.id}>
                              {priceSheet.number}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={4}>
                        {(assignedPriceSheet || !props.priceSheet) && (
                          <Button
                            disabled={!props.priceSheet}
                            onClick={() =>
                              dispatch(
                                assignToReceivers(props.priceSheet!.id, assignedPriceSheet!)
                              ).then(() => {
                                dispatch(fetchPriceSheetUses(props.priceSheet!.id));
                                setAssignedPriceSheet(null);
                              })
                            }
                            type="primary"
                          >
                            {t('settings:priceSheets.assignNow')}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t('settings:priceSheets.unassignText')}>
                <Popconfirm
                  title={t('settings:priceSheets.confirmUnassignText')}
                  okText={t('common:yes')}
                  cancelText={t('common:no')}
                  onConfirm={() =>
                    dispatch(unassignPriceSheetToReciver(props.priceSheet!.id)).then(() =>
                      dispatch(fetchPriceSheetUses(props.priceSheet!.id))
                    )
                  }
                >
                  <Button disabled={!props.priceSheet} type="ghost" danger>
                    {t('settings:priceSheets.unassignNow')}
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          );
        }}
      </DrawerFormik>
    </React.Fragment>
  );
};

export default PriceSheetDrawer;
