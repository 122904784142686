import { Reducer } from 'redux';

export type RetoolState = {
  readonly embedUrl?: string;
  readonly fetchingEmbedUrl: boolean;
};

const initialState = {
  embedUrl: undefined,
  fetchingEmbedUrl: false,
};

const reducer: Reducer<RetoolState> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RETOOL_EMBED_URL_PENDING': {
      return {
        ...state,
        embedUrl: undefined,
        fetchingEmbedUrl: true,
      };
    }
    case 'FETCH_RETOOL_EMBED_URL_REJECTED': {
      return {
        ...state,
        fetchingEmbedUrl: false,
      };
    }
    case 'FETCH_RETOOL_EMBED_URL_FULFILLED': {
      const url = action.payload.data.embedUrl;
      return {
        ...state,
        embedUrl: url,
        fetchingEmbedUrl: false,
      };
    }
    case 'CLEAR_RETOOL_EMBED_URL': {
      return {
        ...state,
        embedUrl: undefined,
        fetchingEmbedUrl: false,
      };
    }
  }
  return state;
};

export default reducer;
