import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { intercomEvent } from '../../utils/IntercomUtils';
import ItemInfo from '../../components/global/ItemInfo';
import InterChangePage from '../../components/body/interchange/InterchangePage';

const InterchangeContainer: React.FC = () => {
  const { itemId, selectedItems } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      itemId: state.catalogue.catalogue.selectedItemIds[0],
    };
  });
  const selectedItem = selectedItems[0];

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'interchange',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  }, [selectedItem]);

  if (selectedItems.length > 1 || selectedItems.length === 0) {
    return <ItemInfo items={selectedItems} />;
  }
  return <InterChangePage {...selectedItem} itemId={itemId} />;
};

export default InterchangeContainer;
