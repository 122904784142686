import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Popover, Popconfirm } from 'antd';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { FormikValues, FormikHelpers } from 'formik';
import classNames from 'classnames';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { GroupType } from '../../../../types/resources';
import ExpiDrawerSelect from './ExpiDrawerSelect';
import { ExtendedInfo, ExpiContent } from '../../../../types/extendedInfo';
import { updateExtendedInfo } from '../../../actions/items/extended_info/update';
import { Warranty } from '../../../constants/ExtendedInfoConstants';
import {
  fetchExtendedInfoTypeOptionsOrder,
  fetchUsedExtendedInfoTypes,
} from '../../../actions/items/extended_info/fetch';

type ExpiDrawerProps = {
  groupType?: GroupType;
  visible: boolean;
  onClose: () => void;
};

const ExpiDrawer: React.FC<ExpiDrawerProps> = props => {
  const DEFAULT_ROW_HEIGHT = 40;
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    expiSelectedItemIds,
    extendedInfos,
    selectedItemsList,
    reverseSelected,
    reversedItemIds,
    selectedBrandId,
  } = useSelector((state: ApplicationState) => ({
    expiSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
    extendedInfos: state.items.extendedInfo.extendedInfos,
    selectedItemsList: state.catalogue.catalogue.selectedItemsList,
    reverseSelected: state.catalogue.catalogue.reverseSelected,
    reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    selectedBrandId: state.parent.brands.selectedBrandId,
  }));

  const existingExpiValues = () =>
    extendedInfos.filter((extendedInfo: ExtendedInfo) =>
      extendedInfo.types.find(type => type.id === props.groupType?.id)
    );

  const getSelectedItemIds = () =>
    reverseSelected
      ? expiSelectedItemIds.filter(id => !reversedItemIds.includes(id))
      : expiSelectedItemIds;

  const getSelectedItems = () =>
    selectedItemsList.filter(item => !reversedItemIds.includes(item.id));

  const getInitialValues = () => {
    if (!props.groupType) {
      return [];
    }
    const existingValues = existingExpiValues();
    const initialValues: Record<string, string[]> = {};

    const itemIds = getSelectedItemIds();

    itemIds.forEach(itemId => {
      initialValues[itemId] = [];
    });

    if (existingValues.length > 0) {
      existingValues.forEach(value => {
        const type = value.types.find(type => type.id === props.groupType?.id);
        let contents: string[] = [];
        if (type) contents = type.contents.map(content => content.value);
        initialValues[value.item_id] = contents;
      });
    }

    return initialValues;
  };

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    const itemValues = values.itemValues;

    const multiItemExtendedInfo = Object.keys(itemValues).map(key => {
      const contents: ExpiContent[] = itemValues[key].map((value: string) => ({
        value,
        language_id: 1,
      }));
      return { item_id: Number(key), types: [{ id: props.groupType!.id, contents }] };
    });

    setSubmitting(true);
    dispatch(updateExtendedInfo(multiItemExtendedInfo))
      .then(() => {
        dispatch(fetchExtendedInfoTypeOptionsOrder(selectedBrandId));
        dispatch(fetchUsedExtendedInfoTypes(selectedBrandId));
        setSubmitting(false);
        props.onClose();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <DrawerFormik
      title={props.groupType?.name}
      visible={props.visible}
      onClose={() => props.onClose()}
      initialValues={{ itemValues: getInitialValues() }}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ values, setFieldValue }) => {
        const selectedItems = getSelectedItems();

        const replaceAllValues = (newValues: any) => {
          const valueList = typeof newValues === 'string' ? [newValues] : newValues || [];
          const itemValues = { ...values.itemValues };

          const itemIds: number[] = getSelectedItemIds();
          itemIds.forEach((itemId: number) => (itemValues[itemId.toString()] = valueList));
          setFieldValue('itemValues', itemValues);
        };

        const rowRenderer = ({ key, index, style }: ListRowProps) => {
          const item = selectedItems[index];
          return (
            <div style={style} key={key} className="extended-info__drawer-list-row">
              <div className="extended-info__drawer-list-row_data">{item.part_number}</div>

              <div className="extended-info__drawer-list-row_data">
                {item.short_name ? (
                  <Popover content={item.short_name}>
                    <span>{item.short_name}</span>
                  </Popover>
                ) : (
                  <span className="italic text-gray-600">{t('extendedInfo:noPartType')}</span>
                )}
              </div>
              <div>
                <ExpiDrawerSelect
                  groupType={props.groupType}
                  onChange={(newValues: any) => {
                    const valueList = typeof newValues === 'string' ? [newValues] : newValues || [];
                    setFieldValue('itemValues', {
                      ...values.itemValues,
                      [item.id.toString()]: valueList,
                    });
                  }}
                  values={values.itemValues[item.id.toString()]}
                />
              </div>
            </div>
          );
        };

        return (
          <Form
            layout="vertical"
            className="h-full flex flex-col extended-info__drawer overflow-hidden"
          >
            <div className="extended-info__drawer-list-row font-medium text-black bg-gray-200">
              <div className="extended-info__drawer-list-row_data">
                {t('extendedInfo:partNumber')}
              </div>

              <div className="extended-info__drawer-list-row_data">
                {t('extendedInfo:productDescription')}
              </div>

              <div className="flex">
                <div className="flex-1">{t('extendedInfo:value')}</div>
                <div className="text-right">
                  <Popconfirm
                    placement="left"
                    title={t('extendedInfo:warnClearText')}
                    onConfirm={() => replaceAllValues([])}
                    okText={t('common:yes')}
                    cancelText={t('common:cancel')}
                    onCancel={e => e?.stopPropagation()}
                  >
                    <Button size="small">{t('extendedInfo:clearAll')}</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
            <div className="extended-info__drawer-list-row">
              <div />
              <div className="extended-info__drawer-list-row_text font-medium text-black">
                {t('extendedInfo:overwriteAllValues')}
              </div>
              <div
                className={classNames({
                  'extended-info-drawer__overwrite-wrapper':
                    props.groupType?.id !== Warranty.TypeId,
                })}
              >
                <ExpiDrawerSelect
                  groupType={props.groupType}
                  onChange={(selectValues: any) => replaceAllValues(selectValues)}
                  values={[]}
                  displayOk
                />
              </div>
            </div>

            <div className="flex-1">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    width={width}
                    height={height}
                    rowCount={selectedItems.length}
                    rowRenderer={rowRenderer}
                    rowHeight={DEFAULT_ROW_HEIGHT}
                  />
                )}
              </AutoSizer>
            </div>
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default ExpiDrawer;
