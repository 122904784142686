import { Reducer } from 'redux';
import { AccessToken, EmbedData, EmbedToken } from '../../../types/insights';

export type InsightsState = {
  readonly accessTokenObj?: AccessToken;
  readonly embedTokenObj?: EmbedToken;
  readonly embedData?: EmbedData;
  readonly fetchingEmbedToken: boolean;
  readonly fetchingEmbedData: boolean;
};

const initialState = {
  accessTokenObj: undefined,
  embedTokenObj: undefined,
  embedData: undefined,
  fetchingEmbedToken: false,
  fetchingEmbedData: false,
};

const reducer: Reducer<InsightsState> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INSIGHTS_APP_TOKEN_FULFILLED': {
      return {
        ...state,
        accessTokenObj: action.payload.data,
      };
    }
    case 'FETCH_INSIGHTS_EMBED_TOKEN_PENDING': {
      return {
        ...state,
        embedTokenObj: undefined,
        fetchingEmbedToken: true,
      };
    }
    case 'FETCH_INSIGHTS_EMBED_TOKEN_FULFILLED': {
      const tokenObj = action.payload.data;

      return {
        ...state,
        embedTokenObj: tokenObj,
        fetchingEmbedToken: false,
      };
    }
    case 'FETCH_INSIGHTS_EMBED_DATA_PENDING': {
      return {
        ...state,
        embedData: undefined,
        fetchingEmbedData: true,
      };
    }
    case 'FETCH_INSIGHTS_EMBED_DATA_FULFILLED': {
      return {
        ...state,
        embedData: action.payload.data,
        fetchingEmbedData: false,
      };
    }
  }
  return state;
};

export default reducer;
