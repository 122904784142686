import { Reducer } from 'redux';

import { LoadingState } from '../../utils/ReduxUtils';

export type CreateItemState = {
  readonly mappedSegments: { [key: string]: boolean } | undefined;
  readonly fetchingMappedSegments: string;
};

const initialState: CreateItemState = {
  mappedSegments: undefined,
  fetchingMappedSegments: LoadingState.NOT_LOADED,
};

const reducer: Reducer<CreateItemState> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MAPPED_SEGMENTS_PENDING': {
      return { ...state, mappedSegments: undefined, fetchingMappedSegments: LoadingState.LOADING };
    }
    case 'FETCH_MAPPED_SEGMENTS_FULFILLED': {
      return {
        ...state,
        mappedSegments: action.payload.data,
        fetchingMappedSegments: LoadingState.LOADING_DONE,
      };
    }
  }
  return state;
};

export default reducer;
