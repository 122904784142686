import React from 'react';
import constants from '../../../constants/ItemTranslation.json';

export default class PartTypeDetails extends React.Component {
  articlesContainer = () => {
    if (this.props.articles && this.props.articles.length > 0) {
      return (
        <div className="row item-part-type_row">
          <div className="item__title item-part-type__art-nr">{constants.details.art_nr_title}</div>
          <div className="item__title item-part-type__art-description">
            {constants.details.description_title}
          </div>
        </div>
      );
    }
  };

  articlesTable = () => {
    if (this.props.articles && this.props.articles.length > 0) {
      return (
        <div
          className="item-part-type__table_container box box_background"
          onScroll={e => this.props.fetchNext(e)}
        >
          <table className="pdm-table item-part-type__table">
            <tbody>{this.articles()}</tbody>
          </table>
          {this.props.fetching ? <div className="loader" /> : false}
        </div>
      );
    }
  };

  articles = () => {
    const articles = this.props.articles.map((article, i) => (
      <tr key={i} className="row">
        <td className="item-part-type__art-nr">{article.part_number}</td>
        <td className="item-part-type__art-description">{article.short_name}</td>
      </tr>
    ));

    return articles;
  };

  render() {
    return (
      <div className="float__left item-part-type__details">
        <div className="item__title">{constants.details.description_title}</div>

        <div className="item-part-type__description box box_background">
          {this.props.description}
        </div>
        {this.articlesContainer()}
        {this.articlesTable()}
      </div>
    );
  }
}
