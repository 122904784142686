import { Drawer, Form, Button } from 'antd';
import { Formik } from 'formik';
import { get as _get } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { SubBrand } from '../../../../types/brand';
import { ApplicationState } from '../../../reducers';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';

type ApplicationListProps = {
  subBrands: SubBrand[];
  visible: boolean;
  onClose: () => void;
  handleAddNewProduct: (partNumber: string, selectedSubrandId?: number) => Promise<any>;
};

const CatalogueAddProductDrawer: React.FC<ApplicationListProps> = props => {
  const { t } = useTranslation();

  const { brandCode } = useSelector((state: ApplicationState) => {
    return {
      brandCode: getSelectedBrandCode(state),
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-new-product', { brand_code: brandCode! });
  });

  const handleSaveButton = ({ partNumber, subBrand }: { partNumber: string; subBrand?: number }) =>
    props.handleAddNewProduct(partNumber, subBrand).then(() => props.onClose());

  return (
    <Drawer
      title={t('catalogue:addNewProduct')}
      width={400}
      open={props.visible}
      onClose={props.onClose}
    >
      <Formik
        initialValues={{
          partNumber: '',
          subBrand: undefined,
        }}
        validationSchema={Yup.object().shape({
          partNumber: Yup.string().required(t('validation:required')),
        })}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          intercomEvent('viewed-all-product', {
            action: 'item-saved',
            location: 'item_new_product',
            part_number: values.partNumber,
            brand_code: brandCode!,
          });
          handleSaveButton(values)
            .catch(e => {
              const message = _get(e, 'response.data.message');
              const status = _get(e, 'response.status');
              if (message && status === 422) {
                setFieldError('partNumber', message);
              }
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="copy-drawer-form" layout="vertical">
            <FormInput
              name="partNumber"
              label={t('common:partNumber')}
              placeholder={t('catalogue:addDrawer.placeholderPartNumber')}
              required
            />
            <FormSelect
              name="subBrand"
              label={t('catalogue:addDrawer.subBrand')}
              placeholder={t('catalogue:addDrawer.placeholderSubBrand')}
              values={props.subBrands}
              allowClear
            />
            <Button
              className="copy-drawer-form__submit"
              disabled={isSubmitting}
              type="primary"
              onClick={() => handleSubmit()}
              size="small"
            >
              {t('catalogue:createProduct')}
            </Button>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default CatalogueAddProductDrawer;
