import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from '@dnd-kit/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { buildTree, flattenTree, getProjection, removeChildrenOf, setProperty } from './utilities';
import { SortableTreeItem } from './custom_field_tree/SortableTreeItem';
import { BrandCustomField, CustomFieldGroup } from '../../../types/custom_fields';
import { typingDone } from '../../utils/Utils';

export interface FlattenedCustomFieldGroup extends CustomFieldGroup {
  parentId: UniqueIdentifier | null;
  depth: number;
  index: number;
}

export type SensorContext = MutableRefObject<{
  items: FlattenedCustomFieldGroup[];
  offset: number;
}>;

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface CustomFieldsTreeProps {
  groups: CustomFieldGroup[];
  keywords: string;
  formDirty: boolean;
  collapsible?: boolean;
  onEdit?: (id: number, type: string) => void;
  handleGroupChange: (groups: CustomFieldGroup[]) => void;
}

const CustomFieldsTree: React.FC<CustomFieldsTreeProps> = ({
  groups,
  keywords,
  formDirty,
  collapsible,
  onEdit,
  handleGroupChange,
}) => {
  const indentationWidth = 20;
  const indicator = false;

  const [items, setItems] = useState<CustomFieldGroup[]>(groups);
  const [filteredItems, setFilteredItems] = useState<CustomFieldGroup[]>();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [prevGroups, setPrevGroups] = useState(groups);
  const [prevFormDirty, setPrevFormDirty] = useState(formDirty);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(filteredItems || items);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { fields, collapsed, id }) =>
        collapsed && fields.length ? [...acc, id.toString()] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, filteredItems, items]);

  const projected = activeId && overId ? getProjection(flattenedItems, activeId, overId) : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const sensors = useSensors(useSensor(PointerSensor));

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    typingDone(() => {
      if (keywords) {
        const filteredItems = items.map(g => {
          const filteredFields = g.fields.filter(({ name }) =>
            name.toLowerCase().includes(keywords.toLowerCase())
          );
          return { ...g, fields: filteredFields };
        });
        setFilteredItems(filteredItems);
      } else {
        setFilteredItems(undefined);
      }
    });
  }, [filteredItems, items, keywords]);

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  useEffect(() => {
    if (prevFormDirty !== formDirty) {
      if (prevFormDirty && !formDirty) {
        setItems(groups);
      }
      setPrevFormDirty(formDirty);
    }
  }, [formDirty, groups, prevFormDirty]);

  useEffect(() => {
    if (groups !== prevGroups) {
      setPrevGroups(groups);
      if (groups.length > items.length) {
        setItems([groups[0], ...items]);
      } else {
        const newItems: CustomFieldGroup[] = [];
        items.forEach(item => {
          const groupSource = groups.find(g => g.id === item.id);
          const prevGroupSource = prevGroups.find(g => g.id === item.id);
          if (groupSource) {
            const updatedFields: BrandCustomField[] = [];
            const fieldIds = item.fields.map(f => f.id);
            const sourceFieldIds = groupSource.fields.map(f => f.id);

            let removedFieldId: number | string | undefined;
            if (prevGroupSource && prevGroupSource.fields.length > groupSource.fields.length) {
              const removedFieldIds = fieldIds.filter(id => !sourceFieldIds.includes(id));
              removedFieldId = removedFieldIds[0];
            }

            item.fields.forEach(f => {
              if (f.id !== removedFieldId) {
                const sourceField = groupSource.fields.find(sf => sf.id === f.id);
                const updatedField = sourceField ? { ...f, ...sourceField } : f;
                updatedFields.push(updatedField);
              }
            });

            if (prevGroupSource && prevGroupSource.fields.length < groupSource.fields.length) {
              const newFieldIds = sourceFieldIds.filter(id => !fieldIds.includes(id));
              if (newFieldIds[0])
                updatedFields.push(groupSource.fields.find(f => f.id === newFieldIds[0])!);
            }

            const updatedGroup = { ...item, name: groupSource.name, fields: updatedFields };
            newItems.push(updatedGroup);
          }
        });
        setItems(newItems);
      }
    }
  }, [groups, items, prevGroups]);

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
  };

  const handleCollapse = (id: UniqueIdentifier) => {
    setItems(items => {
      const newItems = setProperty(items, id, 'collapsed', value => {
        return !value;
      });
      return newItems;
    });
  };

  const handleRemove = (id: UniqueIdentifier) => {
    if (items.find(i => i.id === id)) {
      handleGroupChange(items.filter(i => i.id !== id));
    } else {
      const groupId = items.find(i => i.fields.find(f => f.id === id))?.id;
      const updatedGroups = groups.map(g =>
        g.id === groupId ? { ...g, fields: g.fields.filter(f => f.id !== id) } : g
      );
      handleGroupChange(updatedGroups);
      setItems(updatedGroups);
    }
  };

  const handleDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
    setActiveId(activeId);
    setOverId(activeId);
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedCustomFieldGroup[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      handleGroupChange(newItems);
      setItems(newItems);
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(item => (
          <SortableTreeItem
            key={item.id}
            id={item.id}
            value={item}
            depth={item.id === activeId && projected ? projected.depth : item.depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(item.collapsed)}
            disabled={!!item.fields?.length && item.name === 'other'}
            onCollapse={
              collapsible && item.fields?.length ? () => handleCollapse(item.id) : undefined
            }
            onRemove={() => handleRemove(item.id)}
            onEdit={onEdit}
          />
        ))}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={activeItem.fields?.length}
                value={activeItem}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );
};

const adjustTranslate: Modifier = ({ transform }: any) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export default CustomFieldsTree;
