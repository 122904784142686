import { createSelector } from 'reselect';

const getExtendedInfoResources = state => state.resources.data.extended_information;
const getTypesOptions = state => state.items.extendedInfo.typesOptions;

export const addOptionsToExtendedInfoTypes = createSelector(
  [getExtendedInfoResources, getTypesOptions],
  (extendedInfoResources, typesOptions) => {
    if (!extendedInfoResources) return false;
    const typeIds = typesOptions.map(type => type.id);
    return {
      ...extendedInfoResources,
      groups: extendedInfoResources.groups.map(group => ({
        ...group,
        types: group.types.map(type => {
          const typeWithNewOptions = typeIds.includes(type.id);
          const newType = !typeWithNewOptions
            ? type
            : {
                ...type,
                options: typesOptions.find(typeOption => typeOption.id === type.id).options,
              };
          return newType;
        }),
      })),
    };
  }
);
