export const EXCEL_FILE_EXTENSION = '.xlsx';
export const XML_FILE_EXTENSION = '.xml,.zip';
export const ASSET_FILE_EXTENSION = '.zip';

export const EXCEL_PATH = 'excel_files';
export const EXCEL_PRICE_SHEETS_PATH = 'excel_price_sheets';
export const ACES_PATH = 'xml_aces_files';
export const PIES_PATH = 'xml_pies_files';
export const ASSET_PATH = 'digital_assets';
export const EXCEL_TEMPLATE_PATH = 'excel_templates';
export const EXCEL_TEMPLATE_ADVANCED_PATH = 'full_export_templates';
export const PDF_PATH = 'pdf_files';
export const SUMMIT_PATH = 'excel_summits';

export const HIDDEN_RECEIVER_SEGMENTS = [
  'app_inheriting',
  'equipments',
  'parent_marketing_copies',
  'app_defaults',
  'parent_digital_assets',
];

export const REMOVE_DEFAULT_SELECTION_SEGMENTS = [
  'parent_marketing_copies',
  'parent_digital_assets',
  'flat_applications',
];

export const EBP_TYPE_ID = 12;

export const ONLY_SELECTED_SEGMENTS = 5;
export const PIES = 1;
export const ACES = 2;
export const ASSETS = 3;
export const EXCEL = 10;

export const EBP_SEGMENTS = [
  { id: 1, code: 'items', name: 'Items' },
  { id: 3, code: 'applications', name: 'Applications' },
  { id: 2, code: 'part_attributes', name: 'Part Attributes' },
  { id: 5, code: 'packages', name: 'Packages' },
  { id: 6, code: 'prices', name: 'Prices' },
  { id: 7, code: 'extended_informations', name: 'Extended Information' },
  { id: 4, code: 'descriptions', name: 'Descriptions' },
  { id: 8, code: 'kits', name: 'Kits' },
  { id: 9, code: 'interchange_informations', name: 'Interchange Information' },
  { id: 10, code: 'digital_assets', name: 'Digital Assets' },
  { id: 12, code: 'custom_fields', name: 'Custom Fields' },
  { id: 11, code: 'marketing_copies', name: 'Marketing Copies' },
  { id: 13, code: 'ebay', name: 'eBay' },
  { id: 14, code: 'app_inheriting', name: 'App Inheriting' },
  { id: 15, code: 'equipments', name: 'Equipments' },
];

export const FULL_EXPORT_TEMPLATE_CODE = 'full_export_template';
