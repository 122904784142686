import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Years } from '../../../../types/application';
import ApplicationColumn from '../application/ApplicationColumn';

type GapCoverageReportVehicleAppProps = {
  selectedMakes: number[];
  selectedModels: number[];
  years?: Years[];
  selectedYears: number[];
  selectedTypes: number[];
  selectedGroups: number[];
  fetchingYears: boolean;
  selectMake: (makeId: number | number[] | null, addValues: any) => void;
  selectModel: (modelId: number | number[] | null, addValues: any) => void;
  selectType: (id: number | number[] | null, addValues: any) => void;
  selectGroup: (id: number | number[] | null, addValues: any) => void;
  selectYears: (year: number | number[] | null, addValues: any, save?: boolean) => void;
  handleClearSelection?: (configType: string) => void;
  makes: any[];
  models: any[];
  rYears: any[];
  vehicleGroups: any[];
  vehicleTypes: any[];
} & WithTranslation;

class GapCoverageReportVehicleApp extends React.Component<GapCoverageReportVehicleAppProps> {
  rows = (values: { [x: string]: any }[], selectedValues: number[], sort: boolean) => {
    const allValues = values;

    const rows = allValues.map(({ ...value }) => {
      value.mapped = !!selectedValues.find(id => id === value.id);
      return value;
    });

    if (sort) {
      const filterdMappedValues = rows
        .filter(r => r.mapped)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      const sortedValues = [...filterdMappedValues, ...rows.filter(r => !r.mapped)];

      return sortedValues;
    }
    return rows;
  };

  render() {
    const {
      t,
      selectedMakes,
      selectedModels,
      selectedYears,
      selectedTypes,
      selectedGroups,
      years,
      fetchingYears,
      rYears,
      vehicleTypes,
      vehicleGroups,
      makes,
      models,
    } = this.props;

    const yearResources = () => {
      let updateYears;
      if ((years && years.length === 0 && !fetchingYears) || !years) updateYears = rYears || [];
      else updateYears = years;
      return updateYears;
    };

    return (
      <div className="flex flex-row h-full">
        <div className="export__gap-vehicle-wrapper flex flex-col flex-grow space-y-2">
          <ApplicationColumn
            rows={this.rows(vehicleGroups, selectedGroups, true)}
            getRecommendations={false}
            selected={selectedGroups}
            submit={this.props.selectGroup}
            subconfigName="vehicle_type_groups"
            title={t('application:vehicleGroup')}
            multiselect
            cmdKeyMultiselect
          />
          <ApplicationColumn
            rows={this.rows(vehicleTypes, selectedTypes, true)}
            getRecommendations={false}
            selected={selectedTypes}
            submit={this.props.selectType}
            subconfigName="vehicle_types"
            title={t('application:vehicleType')}
            multiselect
            cmdKeyMultiselect
          />
        </div>
        <ApplicationColumn
          rows={this.rows(makes, selectedMakes, true)}
          getRecommendations={false}
          selected={this.props.selectedMakes}
          submit={this.props.selectMake}
          subconfigName="makes"
          title="Make"
          handleClearSelection={() =>
            this.props.handleClearSelection && this.props.handleClearSelection('makes')
          }
          multiselect
          cmdKeyMultiselect
        />
        <ApplicationColumn
          rows={this.rows(models, selectedModels, true)}
          getRecommendations={false}
          selected={this.props.selectedModels}
          submit={this.props.selectModel}
          subconfigName="models"
          title="Model"
          handleClearSelection={() =>
            this.props.handleClearSelection && this.props.handleClearSelection('models')
          }
          multiselect
          cmdKeyMultiselect
        />
        <ApplicationColumn
          rows={this.rows(yearResources(), selectedYears, false)}
          getRecommendations={false}
          selected={this.props.selectedYears}
          submit={this.props.selectYears}
          fetching={this.props.fetchingYears}
          subconfigName="years"
          multiselect
          cmdKeyMultiselect
          scrollToFirstElement
          title="Year"
          handleClearSelection={() =>
            this.props.handleClearSelection && this.props.handleClearSelection('years')
          }
        />
      </div>
    );
  }
}

export default withTranslation()(GapCoverageReportVehicleApp);
