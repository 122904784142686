import React from 'react';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ExpiAnalysis } from '../../../../types/extendedInfo';

type ExpiWarningIconProps = {
  groupedAnalyses?: ExpiAnalysis[];
  inlineAnalysis?: ExpiAnalysis;
  isGroupedWarnTexts?: boolean;
};

const ExpiWarningIcon: React.FC<ExpiWarningIconProps> = ({
  groupedAnalyses,
  inlineAnalysis,
  isGroupedWarnTexts,
}) => {
  const getTypeId = (analyses: ExpiAnalysis[]) => {
    const alertTypeIds = analyses.map(analysis => analysis.alert_type_id);

    return Math.max(...alertTypeIds);
  };

  const removeDuplicatesInGroupedAnalyses = () => {
    const analysesTypeList = groupedAnalyses?.map(analysis => analysis.type_id);

    const filteredAnalyses = [...new Set(analysesTypeList)].map(
      typeId => groupedAnalyses!.find(a => a.type_id === typeId)!
    );

    return filteredAnalyses;
  };

  const renderIcon = (analyses: ExpiAnalysis[]) => {
    const alertTypeId = getTypeId(analyses);

    return (
      <Tooltip
        title={analyses.map(analysis => (
          <div key={analysis.type_id}>
            {inlineAnalysis ? analysis.dynamic_description || analysis.name : analysis.name}
          </div>
        ))}
        placement={isGroupedWarnTexts ? 'left' : 'top'}
      >
        <WarningOutlined
          className={classNames('extended-info__analysis-message', {
            blue: alertTypeId === 1,
            yellow: alertTypeId === 2,
            red: alertTypeId === 3,
          })}
        />
      </Tooltip>
    );
  };

  if (groupedAnalyses && groupedAnalyses.length > 0) {
    const groupedAnalyses = removeDuplicatesInGroupedAnalyses();
    if (groupedAnalyses.length) return renderIcon(groupedAnalyses);
  }

  if (inlineAnalysis) return renderIcon([inlineAnalysis]);

  return (
    <div
      className={classNames({
        'extended-info__warning-icon_empty-placeholder': isGroupedWarnTexts,
      })}
    />
  );
};

export default ExpiWarningIcon;
