import React from 'react';
import { Select } from 'antd';

import catalogueTranslation from '../../../constants/CatalogueTranslations.json';

const { Option } = Select;

class CatalogueFilterTags extends React.Component {
  state = {
    values: [],
  };

  componentDidMount() {
    this.updateValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterId !== this.props.filterId) {
      this.updateValue();
    }
    if (prevProps.filterTags !== this.props.filterTags) {
      this.updateValue();
    }
  }

  updateValue = () => {
    const { filterTags, brandItemTags } = this.props;
    const values = filterTags.map(tagId => {
      const tag = brandItemTags.find(tag => tag.id === tagId);
      return tag ? tag.name : '';
    });

    this.setState({ values });
  };

  handleDeselect = value => {
    this.setState(prevState => ({ values: prevState.values.filter(val => val !== value) }));
    const tag = this.props.brandItemTags.find(tag => tag.name === value);
    this.props.handleRemoveTag(tag.id);
  };

  handleOnSelect = value => {
    this.setState(prevProps => ({ values: [...prevProps.values, value] }));
    this.props.handleAddTag(value);
  };

  render() {
    return (
      <Select
        mode="multiple"
        className="catalogue-bar__tags-select select-blue-tags"
        placeholder={catalogueTranslation.filterByTag}
        onSelect={this.handleOnSelect}
        onDeselect={this.handleDeselect}
        value={this.state.values || []}
        disabled={this.props.disabled}
        defaultValue={[]}
        size="small"
      >
        {this.props.brandItemTags.map(({ name }) => (
          <Option key={name}>{name}</Option>
        ))}
      </Select>
    );
  }
}

export default CatalogueFilterTags;
