import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal, Button, Empty, Input } from 'antd';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { useDispatch } from 'react-redux';
import { PriceSheet } from '../../../../types/price_sheet';
import { deletePriceSheet } from '../../../actions/parent/price_sheets/delete';
import { AsyncDispatch } from '../../../../types/global';

type DeleteListModalProps = {
  handleOnCancel: () => void;
  showModal?: boolean;
  handleClose: () => void;
  renderListHeaders: string[];
  renderData: any;
  priceSheet?: PriceSheet;
};

const DeleteListModal: React.FC<DeleteListModalProps> = props => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = React.useState<boolean>(false);
  const [deletePriceSheetNumber, setDeletePriceSheetNumber] = React.useState<string | undefined>(
    undefined
  );

  const dispatch: AsyncDispatch = useDispatch();

  return (
    <Modal
      width="50%"
      title={props.priceSheet?.number}
      open={props.showModal}
      onCancel={() => {
        props.handleOnCancel();
        setShowInput(false);
      }}
      footer={
        <div className="flex justify-end">
          <div className={classNames({ 'input-wrapper__delete': showInput })}>
            <Button
              size="small"
              key="cancel"
              onClick={() => {
                props.handleOnCancel();
                setShowInput(false);
                setDeletePriceSheetNumber(undefined);
              }}
            >
              {t('common:cancel')}
            </Button>
          </div>
          <div
            className={classNames({
              'input-wrapper__delete': !showInput,
              'input-wrapper__delete-input': showInput,
            })}
          >
            <Input.Group compact>
              {showInput && (
                <Input
                  size="small"
                  style={{ width: '80%', borderColor: 'red', textAlign: 'left' }}
                  placeholder={`${t('settings:priceSheets.enter')} "${
                    props.priceSheet?.number
                  }" ${t('settings:priceSheets.toConfirm')}`}
                  onChange={e => setDeletePriceSheetNumber(e.target.value)}
                />
              )}
              <Button
                size="small"
                key="delete"
                type="primary"
                danger
                onClick={() => {
                  if (!deletePriceSheetNumber) setShowInput(true);
                  if (
                    deletePriceSheetNumber &&
                    deletePriceSheetNumber === props.priceSheet?.number
                  ) {
                    dispatch(deletePriceSheet(props.priceSheet?.id)).then(() =>
                      props.handleClose()
                    );
                  }
                }}
              >
                {t('common:delete')}
              </Button>
            </Input.Group>
          </div>
        </div>
      }
    >
      <React.Fragment>
        <div className="mb-4">{t('pricing:deleteInfo')}</div>
        {props.renderListHeaders && (
          <div className="flex">
            {props.renderListHeaders.map((colName: string) => (
              <div key={colName} className="border border-solid border-gray-400 flex-1 mr-2 h-56">
                <div className="delete-list-modal__list-header font-medium">
                  {t(`settings:priceSheets.${colName}`)}
                </div>
                <AutoSizer>
                  {({ height, width }) => {
                    const rowRenderer = ({ index, key, style }: ListRowProps) => {
                      const data = props.renderData[colName][index];
                      return (
                        <div key={key} style={style} className="delete-list-modal__list-values">
                          {data.name}
                        </div>
                      );
                    };
                    const noData = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                    return (
                      <List
                        width={width}
                        height={height - 46}
                        rowHeight={40}
                        rowCount={props.renderData[colName].length}
                        rowRenderer={rowRenderer}
                        noRowsRenderer={noData}
                      />
                    );
                  }}
                </AutoSizer>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    </Modal>
  );
};

export default DeleteListModal;
