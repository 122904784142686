import { createSelector } from 'reselect';
import { ExportBuilderAdvancedSegment } from '../../../types/export_builder_advanced';
import { Indexable } from '../../../types/global';
import {
  Global,
  Resources,
  StandardResource,
  StandardResourceDescription,
  Vehicle,
} from '../../../types/resources';
import { ApplicationState } from '../../reducers';

const getSegments = (state: ApplicationState) => state.brand.exportBuilderAdvanced.segments;
const getAccountResourcesVehicles = (state: ApplicationState) =>
  state.resources.applicationVehicle as Vehicle;
const getResources = (state: ApplicationState) => state.resources.data as Indexable & Resources;

export const segmentsMappedWithOptions = createSelector(
  [getSegments, getAccountResourcesVehicles, getResources],
  (segments, vehicles, resources) => {
    const globalData: Indexable & Global = resources.global;
    const segmentWithFieldOptions: ExportBuilderAdvancedSegment[] = segments.map(seg => {
      if (seg.fields) {
        return {
          ...seg,
          fields: seg.fields.map(field => {
            const uniqueFieldOptions = field.options ? [...new Set(field.options)] : null;
            if (field.options) {
              return { ...field, options: uniqueFieldOptions };
            }
            if (field.resource_list) {
              if (field.resource_list.includes('account_resources__vehicles')) {
                const fieldName = field.resource_list.replace('account_resources__vehicles__', '');
                const options: string[] | null = vehicles[fieldName]
                  ? vehicles[fieldName].map(
                      (v: StandardResourceDescription | StandardResource) => v.name
                    )
                  : null;
                return { ...field, options: options ? [...new Set(options)] : uniqueFieldOptions };
              }
              if (field.resource_list.includes('resources__global')) {
                const fieldName = field.resource_list.replace('resources__global__', '');
                const options: string[] | null = globalData[fieldName]
                  ? globalData[fieldName].map((v: any) => v.name)
                  : null;
                return { ...field, options: options ? [...new Set(options)] : uniqueFieldOptions };
              }
              if (field.resource_list.includes(`resources__`)) {
                const segmentPath = field.resource_list.replace('resources__', '').split('__');
                const options: string[] | null = resources[segmentPath[0]][segmentPath[1]]
                  ? resources[segmentPath[0]][segmentPath[1]].map((v: any) => v.name)
                  : null;
                return { ...field, options: options ? [...new Set(options)] : uniqueFieldOptions };
              }
              return { ...field, options: uniqueFieldOptions };
            }
            return { ...field, options: uniqueFieldOptions };
          }),
        };
      }
      return seg;
    });
    return segmentWithFieldOptions;
  }
);
