export enum ChannelsWithBrandConnectivity {
  CHANNELADVISOR = 6,
  NPW = 7,
  WALMART = 8,
}

export const CHANNEL_IDS_WITH_BRAND_CONNECTIVITY = [6, 7, 8];

export enum ChannelTypesWithBrandConnectivity {
  CHANNELADVISOR = 'channelAdvisor',
  CHANNELNPW = 'channelNPW',
  CHANNELWALMART = 'channelWalmart',
  CHANNELSHOPIFY = 'channelShopify',
}

export const HIDDEN_RECEIVER_CHANNEL_IDS = [2, 4, 6, 7, 8, 9];
export const HIDDEN_MANUFACTURER_CHANNEL_IDS = [6];
