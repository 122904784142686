import React from 'react';
import { connect } from 'react-redux';

import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import CatalogueShopPreview from '../../components/body/catalogue/CatalogueShopPreview';
import { Item } from '../../../types/item';
import { ApplicationState } from '../../reducers';
import { ShopPreviewType } from '../../../types/shop_preview';

type CatalogueShopPreviewContainerProps = {
  selectedItem?: Item;
  smallShopPreview: ShopPreviewType;
};

class CatalogueShopPreviewContainer extends React.Component<CatalogueShopPreviewContainerProps> {
  render() {
    const { selectedItem, smallShopPreview } = this.props;
    return (
      <CatalogueShopPreview
        asset={smallShopPreview.primary_asset}
        selectedItem={selectedItem}
        shortName={smallShopPreview.short_name}
        partNumber={smallShopPreview.part_number}
        position={smallShopPreview.position}
        attributes={smallShopPreview.attributes}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedItems = getSelectedItems(state);
  return {
    selectedItem: selectedItems[0],
    smallShopPreview: state.items.shopPreview.smallShopPreview || {},
  };
};

export default connect(mapStateToProps)(CatalogueShopPreviewContainer);
