import React from 'react';
import { Checkbox } from 'antd';
import { localDateString } from '../../../utils/Utils';

class OutOfSyncTableRow extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <div
        className="synchronisation-table__row"
        onClick={() => {
          this.props.handleSelectItem(this.props.item.id);
        }}
      >
        <div className="synchronisation-table__left">
          <div className="synchronisation-table__col">
            <Checkbox checked={this.props.selectedItemIds.includes(this.props.item.id)} />
          </div>
          <div className="synchronisation-table__col col--fix">{item.part_number}</div>
          <div className="synchronisation-table__col col--full">{item.short_name}</div>
        </div>
        <div className="synchronisation-table__right">
          <div className="synchronisation-table__col col--full">{item.export_short_name}</div>
          <div className="synchronisation-table__col col--fix">
            {localDateString(item.last_synced_at)}
          </div>
          <div className="synchronisation-table__col col--fix">
            {localDateString(item.updated_at)}
          </div>
        </div>
      </div>
    );
  }
}

export default OutOfSyncTableRow;
