export type FilterType = {
  id: number;
  name: string;
  type_id: number;
  sub_type_id: number | null;
  public: number;
  keywords: string | null;
  brand_id: number;
  filter_analysis: FilterAnalysis;
  filter_category: FilterCategory;
  filter_application: FilterApplication;
  filter_custom: FilterCustom;
  item_ids?: number[];
};

export type FilterAnalysis = {
  [key: string]: FilterAnalysisKey;
};

export type FilterAnalysisKey = {
  and_condition: boolean;
  parameters: { [key: string]: FilterAnalysisParameter };
};

export type FilterAnalysisParameter = {
  option_id: number;
  option_name: string;
  reference_id: number;
  values: { [key: string]: FilterAnalysisParameterValue };
};

export type FilterAnalysisParameterValue = {
  analysis_id: number;
  analysis_name: string;
  temp?: boolean;
};

export type FilterApplication = {
  [key: string]: FilterApplicationKey;
};

export type FilterApplicationKey = {
  and_condition: boolean;
  parameters: { [key: string]: FilterApplicationParameter };
};

export type FilterApplicationParameter = {
  option_id: number;
  option_name: string;
  resource: string;
  values: { [key: string]: FilterApplicationParameterValue };
};

export type FilterApplicationParameterValue = {
  resource_id: number;
  resource_name: string;
  temp?: boolean;
};

export type FilterCategory = {
  [key: string]: FilterCategoryKey;
};

export type FilterCategoryKey = {
  and_condition: boolean;
  parameters: { [key: string]: FilterCategoryParameter };
};

export type FilterCategoryParameter = {
  option_id: number;
  option_name: string;
  level: number;
  values: { [key: string]: FilterCategoryParameterValue };
};

export type FilterCategoryParameterValue = {
  category_id: number;
  category_name: string;
  temp?: boolean;
};

export type FilterCustom = {
  [key: string]: FilterCustomKey;
};

export type FilterCustomKey = {
  and_condition: boolean;
  parameters: { [key: string]: FilterCustomParameter };
};

export type FilterCustomParameter = {
  id: number;
  option_id: number;
  option_name: string;
  filter_custom_reference_id: number;
  reference_id: number | null;
  table_name: string;
  column: string;
  reference_column: null | string;
  values: { [key: string]: FilterCustomParameterValue };
};

export type FilterCustomParameterValue = {
  value: string;
  temp?: boolean;
};

export enum FilterCustomReference {
  'UNIVERSAL_PART' = 1,
  'CUSTOM_FIELDS' = 2,
  'EXTENDED_INFORMATIONS' = 3,
  'POPULATION_STATUS' = 4,
  'TAGS' = 5,
  'SUBMITTED_CHANNEL' = 6,
  'SUBMITTED_RECEIVER' = 7,
  'ONLINE_CHANNEL' = 8,
  'ONLINE_RECEIVER' = 9,
  'SUB_BRAND' = 10,
}

export type AccountFilter = {
  id: number;
  name: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
};

export type FilterTypeGo = {
  id: number;
  name: string;
  type_id: number;
  sub_type_id: number | null;
  public: number;
  keywords: string | null;
  brand_id: number;
  filter_analysis: FilterAnalysisGo[] | null;
  filter_category: FilterCategoryGo[] | null;
  filter_application: FilterApplicationGo[] | null;
  filter_custom: FilterCustomGo[] | null;
};

export type FilterAnalysisGo = {
  and_condition: boolean;
  parameters: FilterAnalysisParameterGo[];
};

export type FilterAnalysisParameterGo = {
  option_id: number;
  option_name: string;
  reference_id: number;
  values: FilterAnalysisParameterValue[];
  temp?: boolean;
};

export type FilterApplicationGo = {
  and_condition: boolean;
  parameters: FilterApplicationParameterGo[];
};

export type FilterApplicationParameterGo = {
  option_id: number;
  option_name: string;
  resource: string;
  values: FilterApplicationParameterValue[];
  temp?: boolean;
};

export type FilterCategoryGo = {
  and_condition: boolean;
  parameters: FilterCategoryParameterGo[];
};

export type FilterCategoryParameterGo = {
  option_id: number;
  option_name: string;
  level: number;
  values: FilterCategoryParameterValue[];
  temp?: boolean;
};

export type FilterCustomGo = {
  and_condition: boolean;
  parameters: FilterCustomParameterGo[];
};

export type FilterCustomParameterGo = {
  option_id: number;
  option_name: string;
  filter_custom_reference_id: number;
  reference_id: number | null;
  table_name: string;
  column: string;
  reference_column: null | string;
  values: FilterCustomParameterValue[];
  temp?: boolean;
};
