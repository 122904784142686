import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CategoryDropdown from '../../global/CategoryDropdown';
import InfoTooltip from '../../global/InfoTooltip';
import { ApplicationCategory } from '../../../../types/application';
import { ItemCategory } from '../../../../types/item';
import { AsyncDispatch } from '../../../../types/global';
import { fetchPartTypeCategories } from '../../../actions/items/application/fetch';
import { nextPage } from '../../../utils/Utils';
import { ApplicationState } from '../../../reducers';

type ApplicationPartTypeProps = {
  mfrLabel?: string;
  defaultMfrLabel?: string | null;
  applicationCategory?: ApplicationCategory;
  defaultCategory?: ItemCategory;
  qty?: number;
  defaultQty?: number;
  handleCategoryUpdate: (category?: ApplicationCategory) => void;
  updateItemApplicationAttributes: (param: { [x: string]: any }) => void;
};

const ApplicationPartType: React.FC<ApplicationPartTypeProps> = ({
  mfrLabel,
  defaultMfrLabel,
  applicationCategory,
  defaultCategory,
  qty,
  defaultQty,
  handleCategoryUpdate,
  updateItemApplicationAttributes,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { brandId, partTypeCategories, fetchingCategories } = useSelector(
    (state: ApplicationState) => ({
      brandId: state.parent.brands.selectedBrandId,
      partTypeCategories: state.items.application.partTypeCategories,
      fetchingCategories: state.items.application.fetchingCategories,
    })
  );

  const fetchCategories = (keyword?: string) => {
    dispatch(fetchPartTypeCategories(brandId, keyword));
  };

  const fetchNextPartTypeCategories = (event: any, keywords?: string) => {
    const page = nextPage(event);
    if (page && fetchingCategories) {
      dispatch(fetchPartTypeCategories(brandId, keywords, page));
    }
  };

  const updateCategory = (category?: ApplicationCategory) => {
    handleCategoryUpdate(
      category
        ? {
            id: category.id,
            level1_name: category.level1_name,
            level2_name: category.level2_name,
            level3_name: category.level3_name,
          }
        : undefined
    );
  };

  return (
    <Form
      layout="vertical"
      className="application__category-form flex flex-col flex-1 overflow-hidden"
    >
      <Form.Item label="Part Type">
        <CategoryDropdown
          className="application__part-type-dropdown"
          defaultKeyword={applicationCategory?.level3_name || defaultCategory?.level3_name || ''}
          displayedCategory={applicationCategory}
          fetchReviewCategories={fetchCategories}
          fetchNextReviewCategories={fetchNextPartTypeCategories}
          fetchingCategories={fetchingCategories}
          categories={partTypeCategories}
          updateCategory={updateCategory}
          allowClear={!!applicationCategory}
          hasDefault={!applicationCategory && !!defaultCategory}
          defaultCategory={defaultCategory}
        />
      </Form.Item>

      <Form.Item
        label={
          <span>
            {t('application:mfrLabel')}
            <InfoTooltip className="ml-1" title={t('application:mfrLabelHint')} />
          </span>
        }
      >
        <Input
          placeholder={defaultMfrLabel || ''}
          value={mfrLabel}
          onChange={e =>
            updateItemApplicationAttributes({ mfr_label: e.target.value || undefined })
          }
        />
      </Form.Item>

      <Form.Item label={t('application:qty')}>
        <Input
          placeholder={defaultQty?.toString() || ''}
          value={qty}
          onChange={e =>
            updateItemApplicationAttributes({
              qty: e.target.value ? Number(e.target.value) : undefined,
            })
          }
        />
      </Form.Item>
    </Form>
  );
};

export default ApplicationPartType;
