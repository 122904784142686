import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { AnalysisResources, BrandGlobal } from '../../../types/brandResources';

export type BrandResourcesState = {
  readonly analysis: AnalysisResources;
  readonly global: BrandGlobal;
};

const initialState = {
  analysis: {
    scorecards: [],
    types: [],
  },
  global: {
    users: [],
  },
};

const reducer: Reducer<BrandResourcesState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_BRAND_RESOURCES_FULFILLED': {
      const { data } = action.payload;
      return {
        analysis: data.analysis,
        global: data.global,
      };
    }
  }
  return state;
};

export default reducer;
