export type Application = {
  [key: string]: any;
  item_application_id: number;
  make_ids?: number[];
  model_ids?: number[];
  sub_model_ids?: number[];
  years?: number[];
  category?: ApplicationCategory;
  item_category_id?: number;
  positions?: number[];
  mfr_label?: string;
  qualifiers?: ApplicationQualifier[];
  digital_assets?: ApplicationDigitalAsset[];
  region_ids?: number[];
  bed_lengths?: number[];
  bed_types?: number[];
  body_doors?: number[];
  body_types?: number[];
  brake_systems?: number[];
  brake_abs?: number[];
  drives?: number[];
  engine_aspirations?: number[];
  engine_base_block_types?: number[];
  engine_base_bore_inches?: number[];
  engine_base_bore_metrics?: number[];
  engine_base_cubic_centimeters?: number[];
  engine_base_cubic_inches?: number[];
  engine_base_cylinders?: number[];
  engine_base_liters?: number[];
  engine_base_stroke_inches?: number[];
  engine_base_stroke_metrics?: number[];
  engine_cylinder_head_types?: number[];
  engine_designations?: number[];
  engine_fuel_delivery_sub_types?: number[];
  engine_fuel_delivery_types?: number[];
  engine_fuel_types?: number[];
  engine_ignition_system_types?: number[];
  engine_mfrs?: number[];
  engine_power_outputs?: number[];
  engine_valves?: number[];
  engine_versions?: number[];
  engine_vins?: number[];
  front_brake_types?: number[];
  front_spring_types?: number[];
  mfr_body_codes?: number[];
  rear_brake_types?: number[];
  rear_spring_types?: number[];
  steering_systems?: number[];
  steering_types?: number[];
  transmission_base_control_types?: number[];
  transmission_base_gears?: number[];
  transmission_base_types?: number[];
  transmission_electronically_controlled_informations?: number[];
  transmission_mfr_codes?: number[];
  transmission_mfrs?: number[];
  wheel_bases?: number[];
  notes?: ApplicationNote[];
};

export type ApplicationCategory = {
  id: number;
  level1_name: string;
  level2_name: string;
  level3_name: string;
};

export type ApplicationDigitalAsset = {
  asset_name: string;
  parent_code: string;
  language_code: string;
  file_name: string;
  hash_ref: string;
  asset_detail_type: string;
  file_type: string;
  representation: string;
  file_size: number;
  dimenstion_uom: string;
  height: number;
  width: number;
  file_path: string;
  file_url: string;
  file_thumbnail_url: string;
  file_preview_url: string;
};

export type ApplicationNote = {
  note: string;
  record_number: number;
  language_id: number;
  translations: Translation[];
};

export type BundleQualifier = {
  item_base_qualifier_id?: number;
  qualifier_id: number;
  qualifier_text: string;
  record_number?: number;
  parts: QualifierPart[];
  type_id: number;
  values: QualifierValue[];
};

export type ApplicationQualifier = {
  item_base_qualifier_id: null;
  qualifier_id: number;
  qualifier_text: string;
  type_id: number;
  record_number: number;
  values: ApplicationQualifierValue[];
  parts: QualifierPart[];
};

export type ApplicationQualifierValue = {
  value_id: number;
  value: string;
  meta_uom_id: number | null;
  meta_uom: null | string;
  record_number: number;
  translations?: Translation[];
};

export type YearRange = {
  year_from: number;
  year_to: number;
};

export type StructuredApplication = {
  [key: string]: any;
  id: number;
  vehicle_bases: VehicleBases;
  equipment: EquipmentBases;
  category?: ApplicationCategory;
  positions?: ConfigValue[];
  mfr_label?: string;
  qualifiers?: ApplicationQualifier[];
  engines?: Engines;
  transmissions?: Transmissions;
  bodies?: Bodies;
  springs?: Springs;
  beds?: Beds;
  brakes?: Brakes;
  steerings?: Steerings;
  drives?: ConfigValue[];
  wheel_bases?: ConfigValue[];
  mfr_body_codes?: ConfigValue[];
  digital_assets?: ApplicationDigitalAsset[];
  notes?: ApplicationNote[];
};

export type ConfigValue = {
  id: number;
  name: string;
};

type Beds = {
  bed_lengths: ConfigValue[];
  bed_types: ConfigValue[];
};

type Bodies = {
  body_doors: ConfigValue[];
  body_types: ConfigValue[];
};

type Brakes = {
  front_brake_types: ConfigValue[];
  rear_brake_types: ConfigValue[];
  brake_systems: ConfigValue[];
  brake_abs: ConfigValue[];
};

type Engines = {
  [key: string]: any;
  others?: Others;
  engine_bases?: EngineBases;
  engine_fuel_deliveries?: EngineFuelDeliveries;
};

type EngineBases = {
  engine_base_block_types: ConfigValue[];
  engine_base_bore_inches?: ConfigValue[];
  engine_base_bore_metrics?: ConfigValue[];
  engine_base_cubic_centimeters?: ConfigValue[];
  engine_base_cubic_inches?: ConfigValue[];
  engine_base_cylinders?: ConfigValue[];
  engine_base_liters?: ConfigValue[];
  engine_base_stroke_inches?: ConfigValue[];
  engine_base_stroke_metrics?: ConfigValue[];
};

type EngineFuelDeliveries = {
  engine_fuel_delivery_types: ConfigValue[];
  engine_fuel_delivery_sub_types: ConfigValue[];
};

type Others = {
  engine_fuel_types: ConfigValue[];
  engine_designations: ConfigValue[];
  engine_vins: ConfigValue[];
  engine_valves: ConfigValue[];
  engine_aspirations: ConfigValue[];
  engine_cylinder_head_types: ConfigValue[];
  engine_ignition_system_types: ConfigValue[];
  engine_mfrs: ConfigValue[];
  engine_versions: ConfigValue[];
  engine_power_outputs: ConfigValue[];
};

type Springs = {
  front_spring_types: ConfigValue[];
  rear_spring_types: ConfigValue[];
};

type Steerings = {
  steering_types: ConfigValue[];
  steering_systems: ConfigValue[];
};

type Transmissions = {
  transmission_base_types: ConfigValue[];
  transmission_base_gears: ConfigValue[];
  transmission_base_control_types: ConfigValue[];
  transmission_mfr_codes: ConfigValue[];
  transmission_mfrs: ConfigValue[];
  transmission_electronically_controlled_informations: ConfigValue[];
};

export type VehicleBases = {
  makes: ConfigValue[];
  models: ConfigValue[];
  sub_models?: ConfigValue[];
  years?: number[];
  regions?: ConfigValue[];
};

export type EquipmentBases = {
  mfrs: ConfigValue[];
  equipment_models: ConfigValue[];
  vehicle_types?: ConfigValue[];
  years?: number[];
  regions?: ConfigValue[];
};

export type ApplicationGroups = {
  [key: string]: number[];
};

export type LinkedApplications = {
  item_id: number;
  part_number: string;
  applications: StructuredApplication[];
  default_position_id: number | null;
  brand_code: string;
  brand_name: string;
  default_whitelabel_brand_code: string | null;
};

export type BundleRankedPostion = {
  [key: string]: RankedPositions[];
};

export type RankedPositions = {
  id: number;
  name: string;
  user_count: number;
  ranked: number;
  status_code: number;
};

export type Qualifier = {
  item_application_qualifier_id?: number;
  qualifier_id: number;
  rank?: number;
  qualifier_text: string;
  type_id: number;
  values: QualifierValue[];
  parts: QualifierPart[];
  record_number?: number;
};

export type ListQualifier = {
  id: number;
  rank: string;
  qualifier_text: string;
  type_id: number;
  parts: QualifierPart[];
  language_id: number;
  used_by_brand: number;
};

export type QualifierPart = {
  type: string;
  text?: string;
  parameter?: QualifierParameter;
};

export type QualifierParameter = {
  type: string;
  record_number: number;
};

export type QualifierValue = {
  id?: number;
  value_id?: number;
  value: string;
  alternative_value_id?: number | null;
  alternative_value?: string | null;
  meta_uom_id: number | null | undefined;
  meta_uom: string | null | undefined;
  alternative_meta_uom_id?: number | null;
  alternative_meta_uom?: string | null;
  record_number: number;
  translations?: Translation[];
};

export type Translation = {
  language_id: string;
  value: string;
};

export type PartTypeCategory = {
  id: number;
  is_used: number;
  parent_id: number;
  description_id: number | null;
  description: null | string;
  mapped: number;
  expiration_date: null;
  used_by_brand: number;
  level1_name: string;
  level2_name: string;
  level3_name: string;
};

export type RankedApplications = {
  [key: string]: any;
  id: number;
  sub_models: RankedValue[];
  regions: RankedValue[];
  years: RankedValue[];
  makes: RankedValue[];
  models: RankedValue[];
  mfrs: RankedValue[];
  equipment_models: RankedValue[];
  vehicle_types: RankedValue[];
  bed_lengths: RankedValue[];
  bed_types: RankedValue[];
  body_doors: RankedValue[];
  body_types: RankedValue[];
  brake_systems: RankedValue[];
  brake_abs: RankedValue[];
  rear_brake_types: RankedValue[];
  front_brake_types: RankedValue[];
  drives: RankedValue[];
  engine_aspirations: RankedValue[];
  engine_base_block_types: RankedValue[];
  engine_base_bore_metrics: RankedValue[];
  engine_base_bore_inches: RankedValue[];
  engine_base_cubic_centimeters: RankedValue[];
  engine_base_cubic_inches: RankedValue[];
  engine_base_cylinders: RankedValue[];
  engine_base_liters: RankedValue[];
  engine_base_stroke_inches: RankedValue[];
  engine_base_stroke_metrics: RankedValue[];
  engine_cylinder_head_types: RankedValue[];
  engine_designations: RankedValue[];
  engine_fuel_delivery_types: RankedValue[];
  engine_fuel_delivery_sub_types: RankedValue[];
  engine_fuel_delivery_system_control_types: RankedValue[];
  engine_fuel_delivery_system_designs: RankedValue[];
  engine_fuel_types: RankedValue[];
  engine_ignition_system_types: RankedValue[];
  engine_mfrs: RankedValue[];
  engine_power_outputs: RankedValue[];
  engine_valves: RankedValue[];
  engine_versions: RankedValue[];
  engine_vins: RankedValue[];
  mfr_body_codes: RankedValue[];
  front_spring_types: RankedValue[];
  rear_spring_types: RankedValue[];
  steering_systems: RankedValue[];
  steering_types: RankedValue[];
  transmission_base_control_types: RankedValue[];
  transmission_base_gears: RankedValue[];
  transmission_base_types: RankedValue[];
  transmission_electronically_controlled_informations: RankedValue[];
  transmission_mfr_codes: RankedValue[];
  transmission_mfrs: RankedValue[];
  wheel_bases: RankedValue[];
};

export type RankedValue = {
  id: number;
  rank: number;
  found_by_keywords: boolean;
  name: string | number;
  mapped: boolean;
  vehicle_config_id?: number;
};

export type BaseItemApplications = {
  item_id: number;
  part_number: string;
  applications: StructuredApplication[];
  default_whitelabel_brand_code: string | null;
  brand_code: string;
  brand_name: string;
};

export type AppOrder = {
  item_application_id: number;
};

export type Makes = {
  id: number;
  name: string;
  description: null;
  used_by_brand: number | null;
};

export type Models = {
  id: number;
  name: string;
  vehicle_type_id: null;
  description: null;
  used_by_brand: number | null;
};

export type Years = {
  id: number;
};

export type Submodels = {
  id: number;
  name: string;
  used_by_brand: number | null;
};

export type Regions = {
  id: number;
  name: string;
  abbreviation: string;
  used_by_brand: number;
};

export type BundleItem = {
  id: number;
  brand_code: number;
  brand_name: string;
  base_item_id: number;
  item_id: number;
  segment_id: number;
  part_number: string;
  part_type: string;
  qty: number;
  position_id: number;
  asset_item_order: number;
  category_id: number;
  asset_item_ref: string;
  notes: ApplicationNote[];
  qualifiers: BundleQualifier[];
  application_ids: number[];
  default_whitelabel_brand_code: string | null;
};

export type VcdbVersion = {
  id: number;
  account_id: number | null;
  name: string;
};

export enum ApplicationType {
  VEHICLE = 'VEHICLE',
  EQUIPMENT = 'EQUIPMENT',
}

export type ApplicationGroup = {
  [key: string]: any;
  application_id: number;
  makes: number[];
  models: number[];
  sub_models: number[];
  mfrs: number[];
  equipment_models: number[];
  vehicle_types: number[];
  regions: number[];
  years: number[];
  applications: any[];
};
