import React from 'react';
import { Popover, Card, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

type TableInputSearchPopoverProps = {
  initialValue: string;
  handleChange: (keyword: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const TableInputSearchPopover: React.FC<TableInputSearchPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('filterSearch');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
    if (visible && props.onOpen) props.onOpen();
    else if (props.onClose) props.onClose();
  };

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="table-filter-popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card>
          <Formik
            initialValues={{
              searchKeyword: props.initialValue,
            }}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleChange(values.searchKeyword);
              setSubmitting(false);
              handleVisibleChange(false);
            }}
          >
            {({ handleSubmit, dirty, isSubmitting, setFieldValue, values }) => {
              return (
                <div>
                  <Form layout="vertical">
                    <div className="table-popover-search">
                      <Input.Search
                        id="filterSearch"
                        value={values.searchKeyword}
                        onChange={e => {
                          setFieldValue('searchKeyword', e.target.value);
                        }}
                      />
                    </div>
                  </Form>

                  <div className="flex-col items-end mt-3 mr-3">
                    <div>
                      <Button
                        onClick={() => {
                          setFieldValue('searchKeyword', '');
                          handleSubmit();
                        }}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:reset')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default TableInputSearchPopover;
