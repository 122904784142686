import { createSelector } from 'reselect';

const getItemAnalyses = state => state.items.analyses.analyses;
const getItemAnalysisResources = state => state.brand.resources.analysis;
const getActiveSegment = state => state.app.navigationBar.activeArea;

export const filterBySegmentAndDuplicateTypeIds = createSelector(
  [getItemAnalyses, getItemAnalysisResources, getActiveSegment],
  (itemAnalysis, itemAnalysisResources, activeSegment) => {
    const usedTypeIds = [];
    return itemAnalysis.filter(analyse => {
      if (usedTypeIds.includes(analyse.type_id)) {
        return false;
      }
      usedTypeIds.push(analyse.type_id);
      const analyseTypesResource = itemAnalysisResources.types
        ? itemAnalysisResources.types.find(resource => resource.id === analyse.type_id)
        : {};
      if (!analyseTypesResource) return false;
      const segmentId = analyseTypesResource.segment_id;
      switch (activeSegment) {
        case 'item':
          if (segmentId === 1) return true;
          break;
        case 'application':
          if (segmentId === 3) return true;
          break;
        case 'attributes':
          if (segmentId === 2) return true;
          break;
        case 'package':
          if (segmentId === 5) return true;
          break;
        case 'pricing':
          if (segmentId === 6) return true;
          break;
        case 'expi':
          if (segmentId === 7) return true;
          break;
        case 'description':
          if (segmentId === 4 || segmentId === 11) return true; // also show marketing copies here
          break;
        case 'kit':
          if (segmentId === 8) return true;
          break;
        case 'interchange':
          if (segmentId === 9) return true;
          break;
        case 'digital_assets':
          if (segmentId === 10) return true;
          break;
        case 'custom_fields':
          if (segmentId === 12) return true;
          break;
        default:
          return false;
      }
    });
  }
);

export const filterBySegment = createSelector(
  [getItemAnalyses, getItemAnalysisResources, getActiveSegment],
  (itemAnalysis, itemAnalysisResources, activeSegment) =>
    itemAnalysis.filter(analyse => {
      if (!itemAnalysisResources) return;
      const analyseTypesResource = itemAnalysisResources.types
        ? itemAnalysisResources.types.find(resource => resource.id === analyse.type_id)
        : {};
      if (!analyseTypesResource) return false;
      const segmentId = analyseTypesResource.segment_id;
      switch (activeSegment) {
        case 'item':
          if (segmentId === 1) return true;
          break;
        case 'application':
          if (segmentId === 3) return true;
          break;
        case 'attributes':
          if (segmentId === 2) return true;
          break;
        case 'package':
          if (segmentId === 5) return true;
          break;
        case 'pricing':
          if (segmentId === 6) return true;
          break;
        case 'expi':
          if (segmentId === 7) return true;
          break;
        case 'description':
          if (segmentId === 4 || segmentId === 11) return true; // also show marketing copies here
          break;
        case 'kit':
          if (segmentId === 8) return true;
          break;
        case 'interchange':
          if (segmentId === 9) return true;
          break;
        case 'digital_assets':
          if (segmentId === 10) return true;
          break;
        case 'custom_fields':
          if (segmentId === 12) return true;
          break;
        default:
          return false;
      }
    })
);

export const extendedAnalysesBySegment = createSelector(
  [state => filterBySegment(state), getItemAnalysisResources],
  (filteredAnalyses, itemAnalysisResources) =>
    filteredAnalyses.map(analysis => {
      const resource = itemAnalysisResources.types.find(
        resource => resource.id === analysis.type_id
      );
      return { ...analysis, ...resource };
    })
);
