export type ShopPreview = {
  id: number;
  part_number: string;
  brand: string;
  short_name: string;
  category: string;
  sub_category: string;
  part_type: string;
  makes: string;
  models: string;
  years: string;
  position: null | string;
  price: null | string;
  currency_code: null | string;
  attributes: ShopPreviewAttribute[];
  primary_asset: ShopPreviewPrimaryAsset | null;
};

export type ShopPreviewAttribute = {
  id: number;
  name: string;
  values: ShopPreviewAttributeValue[];
};

export type ShopPreviewAttributeValue = {
  id: number;
  name: string;
  meta_uom_id: number | null;
  meta_uom_label: null | string;
};

export type ShopPreviewPrimaryAsset = {
  id: number;
  created_at: string;
  updated_at: string;
  status_code: number;
  source_code: null | number;
  source_id: number;
  user_id: number;
  changed_by_user_id: null | number;
  parent_id: number;
  reference_type_id: number;
  parent_owner_brand_id: number;
  asset_name: null | string;
  file_name: null | string;
  file_path: null | string;
  file_size: null | string;
  language_id: number;
  asset_reference: null | string;
  file_type_id: number;
  resolution_id: null | number;
  color_mode_id: null | number;
  background_id: null | number;
  orientation_view_id: null | number;
  height: null | number;
  width: null | number;
  dimension_uom_id: null | number;
  additional_information: null | string;
  details: null | string;
  uri: string;
  duration: null | string;
  duration_uom_id: null | number;
  frame: null | string;
  total_frame: null | string;
  plane: null | string;
  total_planes: null | string;
  hemisphere_id: null | number;
  plunge: null | string;
  file_date_modified: null | string;
  effective_date: null | string;
  expiration_date: null | string;
  country_id: null | number;
  hash_ref: string;
  parent_code: string;
  record_number_1: number;
  record_number_2: number;
  asset_url: null | string;
  thumbnail: null | string;
  shop_preview: null | string;
};

export type CatalogueQueryParams = {
  filterBrandIds: number[];
  selectedChannelId: number | null;
  selectedReceiverId: number | null;
  order: Order | null;
};

export enum OrderCondition {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  LAST_SYNCED_AT = 'last_synced_at',
  PART_NUMBER = 'part_number',
}

export enum OrderOption {
  DESCENDING = 'descending',
  ASCENDING = 'ascending',
}

export type Order = {
  condition: OrderCondition;
  option: OrderOption;
};
