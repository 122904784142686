import React from 'react';

class BouncingLoader extends React.Component {
  render() {
    return (
      <div className="bouncing-loader">
        <div />
        <div />
        <div />
      </div>
    );
  }
}

export default BouncingLoader;
