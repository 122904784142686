import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ParentExportLogsContainer from '../../containers/parentSettings/importExport/ParentExportLogsContainer';
import ParentImportLogsContainer from '../../containers/parentSettings/importExport/ParentImportLogsContainer';
import LanguageSettingsContainer from '../../containers/parentSettings/translations/LanguageSettingsContainer';
// import TranslationImportExportContainer from '../../containers/parentSettings/translations/TranslationImportExportContainer';
import TranslationsContainer from '../../containers/parentSettings/translations/TranslationsContainer';

const SettingsLanguageRoutes = () => (
  <Routes>
    <Route path="language-settings" element={<LanguageSettingsContainer />} />
    <Route path="translation-settings" element={<TranslationsContainer />} />
    {/* <Route
      path="import-export"
      element={<TranslationImportExportContainer />}
    /> */}
    <Route path="import-logs" element={<ParentImportLogsContainer />} />
    <Route path="export-logs" element={<ParentExportLogsContainer />} />
  </Routes>
);

export default SettingsLanguageRoutes;
