import React from 'react';
import { connect } from 'react-redux';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/digital_assets';
import {
  updateDigitalAssetParent,
  showParentDigitalAssets,
} from '../../actions/parent/digital_asset';
import { setFile, setUploadInformation } from '../../actions/parent/digital_asset_upload';
import { showModal } from '../../actions/app/modal';
import { MODAL_TYPES } from '../../constants/ModalConstants';
import { setActiveArea } from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import { filterBySegment } from '../../selectors/item_analysis/itemAnalysisSelector';
import constants from '../../constants/DigitalAssetsConstants.json';
import { itemsChanged } from '../../utils/Utils';
import DigitalAssetUpload from '../../components/body/digital_assets/DigitalAssetUpload';
import DigitalAssetPreview from '../../components/body/digital_assets/digital_asset_preview/DigitalAssetPreview';
// eslint-disable-next-line
import DigitalAssetInformation from '../../components/body/digital_assets/digital_asset_information/DigitalAssetInformation';
import ItemInfo from '../../components/global/ItemInfo';
import Page from '../../components/global/page/Page.tsx';
import { intercomEvent } from '../../utils/IntercomUtils';
import ParentDigitalAssetsDrawer from './ParentDigitalAssetsDrawer';

class DigitalAssetsContainer extends React.Component {
  componentDidMount() {
    this.props.dispatch(setActiveArea(areas.digital_assets));

    const { selectedItem } = this.props;
    if (selectedItem && selectedItem.id) {
      this.props.dispatch(actions.fetchDigitalAssets(selectedItem.id));
    }
    intercomEvent('viewed-all-product', { location: 'digital-assets' });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedItem &&
      itemsChanged(prevProps.selectedItems, this.props.selectedItems)
    ) {
      this.props.dispatch(actions.fetchDigitalAssets(this.props.selectedItem.id));
    }
  }

  setSelectedDigitalAsset = selectedDigitalAssetId => {
    this.props.dispatch(actions.selectDigitalAsset(selectedDigitalAssetId));
  };

  showPreview = digitalAsset => {
    this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_PREVIEW, digitalAsset));
  };

  deleteAsset = digitalAssetId => {
    const { selectedItem } = this.props;
    this.props.dispatch(actions.deleteDigitalAsset(selectedItem.id, digitalAssetId));

    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_digital_asset',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
  };

  updateItemInformation = (key, value) => {
    const { selectedDigitalAssetId, selectedItem } = this.props;
    this.props.dispatch(actions.updateDigitalAssetItem(selectedDigitalAssetId, key, value));

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'digital_asset',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
  };

  createDescription = (typeId, languageId, contents) => {
    const { selectedDigitalAssetId } = this.props;
    this.props.dispatch(
      actions.createDigitalAssetDescription(selectedDigitalAssetId, typeId, languageId, contents)
    );
  };

  updateDescriptionContents = (descriptionId, contents, languageId, typeId) => {
    const newContents = { contents };
    this.props.dispatch(
      actions.updateDescriptionContents(newContents, descriptionId, languageId, typeId)
    );
  };

  deleteDescription = descriptionId => {
    this.props.dispatch(actions.deleteDigitalAssetDescription(descriptionId));
  };

  updateAssetInformation = (assetId, key, value) => {
    const { selectedItem } = this.props;
    this.props.dispatch(updateDigitalAssetParent(assetId, key, value));

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'digital_asset',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
  };

  handleOpenModal = uploadType => {
    if (uploadType === constants.popupType.parentDigitalAssets) {
      this.props.dispatch(showParentDigitalAssets(true));
    } else {
      this.props.dispatch(setUploadInformation(uploadType, 'item'));
      this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD));
    }
    const { selectedItem } = this.props;

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'digital_asset_new',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
  };

  readFile = file => {
    const { selectedItem } = this.props;
    this.props.dispatch(setFile(file));
    this.props.dispatch(setUploadInformation(constants.popupType.local, 'item'));
    this.props.dispatch(showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD));

    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'digital_asset_new',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });
  };

  combineAnalysisAndResources = () =>
    this.props.analysisBySegment.map(analysis => {
      const resource = this.props.brandAnalysisResources.types.find(
        resource => resource.id === analysis.type_id
      );
      return { ...analysis, ...resource };
    });

  render() {
    const {
      fetching,
      digitalAssets,
      selectedDigitalAssetId,
      digitalAssetResources,
      selectedItems,
      languages,
      countries,
      digitalAssetDescriptions,
      defaultValues,
      showParentDigitalAssetsDrawer,
    } = this.props;
    const itemCount = selectedItems.length > 1 || selectedItems.length === 0;
    const defaultLanguage = defaultValues.find(def => def.resource_table === 'languages');

    if (itemCount) {
      return <ItemInfo items={selectedItems} />;
    }
    const selectedDigitalAsset =
      digitalAssets &&
      digitalAssets.find(digitalAsset => digitalAsset.id === selectedDigitalAssetId);

    return (
      <Page showAnalysis>
        <div className="digital-assets-container flex">
          <DigitalAssetUpload readFile={this.readFile} handleOpenModal={this.handleOpenModal} />
          <div className="digital-asset__box-right">
            <DigitalAssetPreview
              digitalAssetResources={digitalAssetResources}
              fetching={fetching}
              digitalAssets={digitalAssets}
              selectedDigitalAssetId={selectedDigitalAssetId}
              setSelectedDigitalAsset={this.setSelectedDigitalAsset}
              showPreview={this.showPreview}
              deleteAsset={this.deleteAsset}
              analyses={this.combineAnalysisAndResources()}
            />
            <DigitalAssetInformation
              area="item"
              selectedDigitalAsset={selectedDigitalAsset}
              digitalAssetDescriptions={digitalAssetDescriptions}
              digitalAssetResources={digitalAssetResources}
              languages={languages}
              countries={countries}
              updateItemInformation={this.updateItemInformation}
              createDescription={this.createDescription}
              updateDescriptionContents={this.updateDescriptionContents}
              deleteDescription={this.deleteDescription}
              updateAssetInformation={this.updateAssetInformation}
              handleViewEditDetails={this.handleViewEditDetails}
              defaultLanguageId={defaultLanguage ? defaultLanguage.value : ''}
            />
            {showParentDigitalAssetsDrawer && (
              <ParentDigitalAssetsDrawer digitalAssets={digitalAssets} area="item" />
            )}
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const selectedItems = getSelectedItems(state);
  return {
    fetching: state.items.digitalAssets.fetching,
    digitalAssets: state.items.digitalAssets.digitalAssets,
    selectedDigitalAssetId: state.items.digitalAssets.selectedDigitalAssetId,
    digitalAssetDescriptions: state.items.digitalAssets.digitalAssetDescriptions,
    digitalAssetResources: state.resources.data.digital_asset,
    languages: state.resources.data.global.languages,
    countries: state.resources.data.global.countries,
    brandAnalysisResources: state.brand.resources.analysis,
    analysisBySegment: filterBySegment(state),
    selectedItems,
    selectedItem: selectedItems[0],
    defaultValues: state.settings.defaultValues,
    showParentDigitalAssetsDrawer: state.parent.digitalAsset.showParentDigitalAssets,
  };
}

export { DigitalAssetsContainer };

export default connect(mapStateToProps)(withContainerWrapper(DigitalAssetsContainer));
