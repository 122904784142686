import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Popover, Popconfirm, message } from 'antd';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { FormikValues, FormikHelpers } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { Package } from '../../../../types/itemPackage';
import PackageDrawerField from './PackageDrawerField';
import { ExtendedResources } from '../../../../types/resources';
import { typingDone } from '../../../utils/Utils';
import { updatePackage } from '../../../actions/items/package/update';

type CreatePackageDrawerProps = {
  uomId: number;
  visible: boolean;
  onClose: (sucessSubmit?: boolean) => void;
  title: string;
  uoms: ExtendedResources[];
};

const CreatePackageDrawer: React.FC<CreatePackageDrawerProps> = props => {
  const DEFAULT_ROW_HEIGHT = 40;
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const listRef = React.useRef<List>(null);

  const { allSelectedItemIds, selectedItemsList, reverseSelected, reversedItemIds, itemPackages } =
    useSelector((state: ApplicationState) => ({
      allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
      selectedItemsList: state.catalogue.catalogue.selectedItemsList,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
      itemPackages: state.items.packageSeg.itemPackages,
    }));

  const getSelectedItemIds = () =>
    reverseSelected
      ? allSelectedItemIds.filter(id => !reversedItemIds.includes(id))
      : allSelectedItemIds;

  const getSelectedItems = () =>
    selectedItemsList.filter(item => !reversedItemIds.includes(item.id));

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    const itemIds = getSelectedItemIds();

    const updatedPackages = itemIds.map(itemId => {
      const itemPkg = itemPackages.find(iPkg => iPkg.item_id === itemId);
      const packages = itemPkg?.packages || [];
      const uomPkg = packages?.find(pkg => pkg.uom_id === props.uomId);

      const changedPackage = values.itemValues.find((pkg: any) => pkg.itemId === itemId);
      if (itemPkg) {
        if (uomPkg) {
          const pkgIndex = itemPkg?.packages.findIndex(pkg => pkg.uom_id === props.uomId);
          packages[pkgIndex] = changedPackage;
          return { ...itemPkg, packages };
        }
        if (!uomPkg) {
          return {
            ...itemPkg,
            packages: [...itemPkg.packages, changedPackage],
          };
        }
      }

      return { item_id: itemId, packages: [changedPackage] };
    });

    setSubmitting(true);
    dispatch(updatePackage(updatedPackages))
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch(e => {
        setSubmitting(false);
        message.error(e.message);
      });
  };

  const validationSchema = Yup.object().shape({
    itemValues: Yup.array().of(
      Yup.object().shape({
        quantity_of_eaches: Yup.number().nullable().required(t('validation:required')),
      })
    ),
  });

  const getInitialValues = () => {
    const itemIds = getSelectedItemIds();

    return itemIds.map(itemId => {
      const item = itemPackages.find(itemPackage => itemPackage.item_id === itemId);
      const pkg = item?.packages.find(pkg => pkg.uom_id === props.uomId);

      return pkg
        ? { ...pkg, itemId }
        : {
            itemId,
            uom_id: props.uomId,
          };
    });
  };

  return (
    <DrawerFormik
      title={props.title}
      visible={props.visible}
      onClose={() => props.onClose()}
      initialValues={{ itemValues: getInitialValues() }}
      width="55%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange={false}
    >
      {({ setFieldValue, setFieldTouched, values, errors, touched }) => {
        typingDone(() => {
          listRef.current?.recomputeRowHeights();
        });

        const selectedItems = getSelectedItems();

        const replaceAllValues = (inputValue: string) => {
          const itemValues = values.itemValues.map((pkg: Package) => ({
            ...pkg,
            quantity_of_eaches: Number(inputValue) || null,
          }));
          setFieldValue('itemValues', itemValues);
        };

        const rowRenderer = ({ key, index, style }: ListRowProps) => {
          const item = selectedItems[index];
          const pkg = values.itemValues[index];
          const valueErrors = errors.itemValues;
          const touchedValues = touched.itemValues;
          const showValidationInfo =
            Array.isArray(valueErrors) &&
            !!valueErrors[index] &&
            Array.isArray(touchedValues) &&
            !!touchedValues[index];

          return (
            <div style={style} key={key} className="package__drawer-list-row">
              <div className="package__drawer-list-row_data">{item.part_number}</div>

              <div className="package__drawer-list-row_data">
                {item.short_name ? (
                  <Popover content={item.short_name}>
                    <span>{item.short_name}</span>
                  </Popover>
                ) : (
                  <span className="italic text-gray-600">{t('packageSeg:noPartType')}</span>
                )}
              </div>

              <Form.Item
                hasFeedback
                validateStatus={showValidationInfo ? 'error' : ''}
                help={(showValidationInfo && t('validation:required')) || undefined}
              >
                <PackageDrawerField
                  title={props.title}
                  uoms={props.uoms}
                  uomId={props.uomId}
                  values={pkg}
                  handleOnChange={value => {
                    setFieldValue(`itemValues[${index}].quantity_of_eaches`, value || null);
                  }}
                  handleOnBlur={() => {
                    setFieldTouched(`itemValues[${index}].quantity_of_eaches`);
                  }}
                />
              </Form.Item>
            </div>
          );
        };

        return (
          <Form layout="vertical" className="h-full flex flex-col package__drawer overflow-hidden">
            <div className="package__drawer-list-row font-medium text-black bg-gray-200">
              <div className="package__drawer-list-row_data">{t('packageSeg:partNumber')}</div>

              <div className="package__drawer-list-row_data">
                {t('packageSeg:productDescription')}
              </div>

              <div className="flex">
                <div className="flex-1">{t('packageSeg:value')}</div>
                <div className="text-right">
                  <Popconfirm
                    placement="left"
                    title={t('packageSeg:warnClearText')}
                    onConfirm={() => replaceAllValues('')}
                    okText={t('common:yes')}
                    cancelText={t('common:cancel')}
                    onCancel={e => e?.stopPropagation()}
                  >
                    <Button size="small">{t('packageSeg:clearAll')}</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
            <div className="package__drawer-list-row">
              <div />
              <div className="package__drawer-list-row_text font-medium text-black">
                {t('packageSeg:overwriteAllValues')}
              </div>

              <div className={classNames('package-drawer__overwrite-wrapper')}>
                <PackageDrawerField
                  title={props.title}
                  uoms={props.uoms}
                  displayOk
                  uomId={props.uomId!}
                  handleReplace={inputValue => replaceAllValues(inputValue!)}
                  testId="overwrite-input"
                />
              </div>
            </div>

            <div className="flex-1">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={listRef}
                    width={width}
                    height={height}
                    rowCount={selectedItems.length}
                    rowRenderer={rowRenderer}
                    rowHeight={({ index }) => {
                      const showValidationInfo =
                        Array.isArray(errors.itemValues) &&
                        !!errors.itemValues[index] &&
                        Array.isArray(touched.itemValues) &&
                        !!touched.itemValues[index];
                      return showValidationInfo ? DEFAULT_ROW_HEIGHT + 20 : DEFAULT_ROW_HEIGHT;
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default CreatePackageDrawer;
