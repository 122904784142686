import axios from 'axios';
import { QuickStartGuide } from '../../../../types/home';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function updateGuideSteps(brandId: number, steps: any, startGuide: QuickStartGuide) {
  return {
    type: 'UPDATE_GUIDE_STEPS',
    meta: startGuide,
    payload: axios.patch(`${getApiRoot()}/parents/owners/brands/${brandId}/home`, steps),
  };
}
