import { CheckCircleFilled, ClockCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ChannelBrandStatusProps = {
  statusId: number;
};

const ChannelBrandStatus: React.FC<ChannelBrandStatusProps> = props => {
  const { t } = useTranslation();
  const renderApprovedTag = (color: string) => (
    <Tag color={color} icon={<CheckCircleFilled color={color} />}>
      {t('channel:connected')}
    </Tag>
  );

  const renderPendingTag = (color: string) => (
    <Tag color={color} icon={<ClockCircleFilled color={color} />}>
      {t('channel:pending')}
    </Tag>
  );

  const renderRejectedTag = (color: string) => (
    <Tag color={color} icon={<ExclamationCircleFilled color={color} />}>
      {t('channel:rejected')}
    </Tag>
  );
  const renderDisconnected = (color: string) => (
    <Tag color={color}>{t('channel:disconnected')}</Tag>
  );

  if (props.statusId === 2) return renderApprovedTag('green');
  if (props.statusId === 1) return renderPendingTag('orange');
  if (props.statusId === 3) return renderRejectedTag('red');
  if (props.statusId === 4) return renderDisconnected('');
  return <div />;
};

export default ChannelBrandStatus;
