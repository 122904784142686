import { Field, FieldProps } from 'formik';
import { Form, Input } from 'antd';
import React, { ReactNode } from 'react';

type FormTextAreaProps = {
  name: string;
  id?: string;
  label?: string | ReactNode;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  showCount?: boolean;
  maxLength?: number;
  testId?: string;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export default class FormTextArea extends React.Component<FormTextAreaProps> {
  render() {
    const {
      name,
      id,
      testId,
      label,
      placeholder,
      required,
      disabled,
      showCount,
      maxLength,
      handleChange,
    } = this.props;

    return (
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const { errors, touched } = form;
          const showValidationInfo = !!(errors[name] && touched[name]);

          return (
            <Form.Item
              label={label}
              hasFeedback
              validateStatus={showValidationInfo ? 'error' : ''}
              help={(showValidationInfo && errors[name]) || undefined}
              required={required}
            >
              <Input.TextArea
                id={id}
                name={name}
                placeholder={placeholder}
                value={field.value}
                onChange={handleChange || field.onChange}
                onBlur={field.onChange}
                disabled={disabled}
                showCount={showCount}
                maxLength={maxLength}
                rows={6}
                data-testid={testId}
              />
            </Form.Item>
          );
        }}
      </Field>
    );
  }
}
