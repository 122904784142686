import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import ApplicationColumn from './ApplicationColumn';
import AntTooltip from '../../global/AntTooltip';
import {
  getConfigGroupName,
  isFullConfigSelected,
  isFullGroupConfigSelected,
  isFullVehicleSelected,
  isSomeConfigSelected,
  isSomeGroupConfigSelected,
} from '../../../utils/ApplicationUtils';
import { RankedApplications } from '../../../../types/application';
import { ApplicationState } from '../../../reducers';

type ApplicationSubconfigProps = {
  className?: string;
  defaultPositionId: number;
  getRecommendations: boolean;
  selectedSubconfigs: { [x: string]: any };
  rankedApplications: RankedApplications;
  groupView: boolean;
  vcdbView: boolean;
  changeConfigActive?: boolean;
  linkConfigs: string[];
  selectedCustomVcdb?: number;
  noRank3?: boolean;

  allSubconfigValues: (column: string) => any[];
  mapConfigToApplication: (subconfigName: string, rowId: number) => void;
  addCustomValue?: (configName: string, value: string, group?: string) => Promise<any>;
  deleteCustomValue?: (configName: string, id: number) => Promise<any>;
  deleteCustomConfigById?: (configName: string, id: number) => Promise<any>;
  linkConfig?: (name: string) => void;
  changeConfiguration?: (status: boolean) => void;
  confirmChangeConfiguration?: (configName: string, id: number) => void;
};

const ApplicationSubconfig: React.FC<ApplicationSubconfigProps> = props => {
  const { t } = useTranslation();

  const { validVcdbVersions } = useSelector((state: ApplicationState) => {
    return {
      validVcdbVersions: state.items.application.validVcdbVersions,
    };
  }, shallowEqual);

  const [prevChangeConfig, setPrevChangeConfig] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);

  const getConfigName = () => {
    const key = props.groupView
      ? Object.keys(Object.values(props.selectedSubconfigs)[0])[0]
      : Object.keys(props.selectedSubconfigs)[0];
    return getConfigGroupName(key);
  };

  const linkConfig = () => {
    const configName = getConfigName();
    if (props.linkConfig && configName) props.linkConfig(configName);
  };

  const deleteConfig = () => {
    const key = props.groupView
      ? Object.keys(Object.values(props.selectedSubconfigs)[0])[0]
      : Object.keys(props.selectedSubconfigs)[0];
    const configName = getConfigGroupName(key);

    const vehicleConfigValues = props.groupView
      ? Object.values(Object.values(props.selectedSubconfigs)[0])[0]
      : Object.values(props.selectedSubconfigs)[0];

    if (props.deleteCustomConfigById && configName)
      props.deleteCustomConfigById(configName, vehicleConfigValues[0].vehicle_config_id);
  };

  const handleChangeConfiguration = () => {
    if (props.changeConfiguration && !props.changeConfigActive) {
      props.changeConfiguration(true);
      setPrevChangeConfig(props.selectedSubconfigs);
    } else if (props.confirmChangeConfiguration) {
      const configName = getConfigName();

      const vehicleConfigValues = props.groupView
        ? Object.values(Object.values(prevChangeConfig!)[0])[0]
        : Object.values(prevChangeConfig!)[0];
      props.confirmChangeConfiguration(configName!, vehicleConfigValues[0].vehicle_config_id!);
    }
  };

  const vcdbButtons = () => {
    const configName = getConfigName();
    const fullVehicleSelected = isFullVehicleSelected(props.rankedApplications);
    const fullConfigSelected = props.groupView
      ? isFullGroupConfigSelected(props.selectedSubconfigs)
      : isFullConfigSelected(props.selectedSubconfigs);
    const someConfigSelected = props.groupView
      ? isSomeGroupConfigSelected(props.selectedSubconfigs)
      : isSomeConfigSelected(props.selectedSubconfigs);

    return (
      <div>
        <Button
          className="mr-1"
          disabled={
            props.changeConfigActive
              ? false
              : !(fullConfigSelected && props.selectedCustomVcdb === validVcdbVersions[0])
          }
          onClick={() => handleChangeConfiguration()}
          size="small"
        >
          {props.changeConfigActive
            ? t('application:confirmChange')
            : t('application:changeConfiguration')}
        </Button>

        {fullConfigSelected && props.selectedCustomVcdb === validVcdbVersions[0] ? (
          <AntTooltip
            title={t('application:deleteConfigIdTip')}
            overlayClassName="whitespace-pre-line"
          >
            <Button onClick={() => deleteConfig()} size="small" danger>
              {t('application:deleteConfigId')}
            </Button>
          </AntTooltip>
        ) : (
          <AntTooltip
            title={
              fullVehicleSelected
                ? t('application:createConfigIdTip')
                : t('application:linkToVehicleTip')
            }
            overlayClassName="whitespace-pre-line"
          >
            <Button
              onClick={() => linkConfig()}
              disabled={
                !fullVehicleSelected ||
                !someConfigSelected ||
                props.linkConfigs.includes(configName!) ||
                props.changeConfigActive ||
                (fullConfigSelected && props.selectedCustomVcdb === validVcdbVersions[0])
              }
              size="small"
            >
              {t('application:linkToVehicle')}
            </Button>
          </AntTooltip>
        )}
      </div>
    );
  };

  const columns = (columns: { [x: string]: any }) => {
    const block = Object.keys(columns).map(column => {
      if (column === 'rank') return;
      let allSubconfigs = props.allSubconfigValues(column);

      let rankedValues = columns[column];

      if (allSubconfigs) {
        const rankedIds = rankedValues.map((value: { id: any }) => value.id);
        allSubconfigs = allSubconfigs.filter(value => !rankedIds.includes(value.id));
      }

      // mark default position as mapped if no position is selected
      if (props.defaultPositionId) {
        const individualPositionSelected = rankedValues.find(
          (value: { mapped: any }) => value.mapped
        );
        if (!individualPositionSelected) {
          if (rankedValues.find((value: { id: any }) => value.id !== props.defaultPositionId)) {
            rankedValues = rankedValues.map((value: { id: any }) =>
              value.id !== props.defaultPositionId
                ? value
                : { ...value, mapped: true, default: true }
            );
          } else {
            allSubconfigs.forEach(value => {
              if (value.id === props.defaultPositionId) {
                rankedValues = [...rankedValues, { ...value, mapped: true, default: true }];
              }
            });
            allSubconfigs = allSubconfigs.filter(value => props.defaultPositionId !== value.id);
          }
        }
      }

      rankedValues.sort(
        (
          a: { mapped: any; rank: number; name: number },
          b: { mapped: any; rank: number; name: number }
        ) => {
          if (a.mapped === b.mapped) {
            if (a.rank === b.rank) {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }
            if (a.rank < b.rank) return -1;
            if (a.rank > b.rank) return 1;
            return 0;
          }
          if (a.mapped) return -1;
          if (b.mapped) return 1;
          return 0;
        }
      );

      return (
        <div key={column} className="application__subconfig_column">
          <ApplicationColumn
            rows={[...rankedValues, ...allSubconfigs]}
            selected={null}
            mapConfigToApplication={props.mapConfigToApplication}
            addCustomValue={props.addCustomValue}
            deleteCustomValue={props.deleteCustomValue}
            subconfigName={column}
            getRecommendations={props.getRecommendations}
            noRank3={props.defaultPositionId !== undefined || props.noRank3}
            title={t(`applicationConfigs:${column}`)}
          />
        </div>
      );
    });

    return block;
  };

  const groupColumns = (configObject: any) => (
    <React.Fragment>
      {!props.groupView && props.vcdbView && (
        <div className="flex flex-col items-end">
          <div>{vcdbButtons()}</div>
        </div>
      )}
      <div className="application__subconfig_columns">{columns(configObject)}</div>
    </React.Fragment>
  );

  const groups = () => {
    return (
      <div className="application__subconfig-card-container">
        <Tabs
          type="card"
          defaultActiveKey="engine_bases"
          tabBarExtraContent={props.vcdbView && vcdbButtons()}
          destroyInactiveTabPane
        >
          {Object.keys(props.selectedSubconfigs).map(groupKey => (
            <Tabs.TabPane tab={t(`application:${groupKey}`)} key={groupKey}>
              <div key={groupKey} className="application__subconfig_group">
                <div className="application__subconfig_group_columns">
                  {groupColumns(props.selectedSubconfigs[groupKey])}
                </div>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    );
  };

  const subconfigs = () => (props.groupView ? groups() : groupColumns(props.selectedSubconfigs));

  return (
    <div className={`application__subconfig float__left ${props.className || ''}`}>
      {subconfigs()}
    </div>
  );
};

export default ApplicationSubconfig;
