import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

type JobInfoProps = {
  displayInfo: boolean;
  handleClick: () => void;
  statusId: number;
};

const JobInfo: React.FC<JobInfoProps> = props => {
  const { displayInfo, statusId } = props;

  const renderButton = (statusId: number) => {
    switch (statusId) {
      case 3: {
        return <InfoCircleOutlined onClick={() => props.handleClick()} className="icon__yellow" />;
      }
      case 4: {
        return <InfoCircleOutlined className="icon__red" onClick={() => props.handleClick()} />;
      }
      case 5: {
        return <InfoCircleOutlined className="icon__yellow" onClick={() => props.handleClick()} />;
      }
      default: {
        return <InfoCircleOutlined onClick={() => props.handleClick()} />;
      }
    }
  };

  if (displayInfo) return renderButton(statusId);
  return <div />;
};

export default JobInfo;
