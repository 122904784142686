import React from 'react';
import { EditOutlined, FilterOutlined, SaveOutlined } from '@ant-design/icons';
import { Input, Button, Tooltip, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { typingDone } from '../../../utils/Utils';
import { getFilterTags, isCustomFilter, isFilterEmpty } from '../../../utils/FilterUtils';
import CatalogueFilterPreview from './CatalogueFilterPreview';
import CatalogueFilterTags from './CatalogueFilterTags';
import CatalogueSaveFilter from './CatalogueSaveFilter';
import catalogueTranslation from '../../../constants/CatalogueTranslations.json';
import { FilterType, FilterTypeGo } from '../../../../types/filter';
import AntPopover from '../../global/AntPopover';
import { BrandItemTag } from '../../../../types/brand_catalogue';
import { Resources } from '../../../../types/resources';
import { SubBrand } from '../../../../types/brand';
import HelpCenter from '../../../containers/HelpCenter';
import { CatalogueResetFilterButton } from './CatalogueResetFilterButton';
import CatalogueFilterSelect from './CatalogueFilterSelect';
import AntTooltip from '../../global/AntTooltip';
import { BrandCustomField } from '../../../../types/custom_fields';
import SearchInput from '../../global/Forms/SearchInput';

const { confirm } = Modal;

type CatalogueBarFilterProps = {
  segment: 'brand_catalogue' | 'receiver_catalogue';
  selectedFilterGo: FilterTypeGo;
  filters: FilterType[];
  disableKeywordInput?: boolean;
  brandCustomFields: BrandCustomField[];
  goOnly: boolean;
  handleFilterByKeywords: (keywords: string) => void;
  handleOpenFilter: () => void;
  handleSaveNewFilter: (filterName: string) => Promise<undefined | ProgressEvent>;
  brandItemTags?: BrandItemTag[];
  handleAddTag?: (value: string) => void;
  handleRemoveTag?: (tagId: number) => void;
  handleDeleteFilterParameter: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => void;
  resources: Resources;
  subBrands: SubBrand[];
  onFilterChange: ({ filterId }: { filterId: number }) => void;
};

const CatalogueBarFilter: React.FC<CatalogueBarFilterProps> = props => {
  const [keywordInput, setKeywordInput] = React.useState(props.selectedFilterGo.keywords || '');

  // Custom filters could be assigned to a channel, we therefore protect them from being changed by e.g. disabling the input fields by default.
  const selectedFilterIsCustomFilter = isCustomFilter(props.selectedFilterGo);

  const [actionsDisabled, disableActions] = React.useState(selectedFilterIsCustomFilter);

  React.useEffect(() => {
    disableActions(selectedFilterIsCustomFilter);
  }, [selectedFilterIsCustomFilter, props.selectedFilterGo.id]);

  React.useEffect(() => {
    // after filter reset empy keyword
    if (!props.selectedFilterGo.keywords) {
      setKeywordInput('');
    }
  }, [props.selectedFilterGo.keywords]);

  React.useEffect(() => {
    setKeywordInput(props.selectedFilterGo.keywords || '');
  }, [props.selectedFilterGo.id]);

  const updateKeywordInput = (keyword: string) => {
    setKeywordInput(keyword);
    typingDone(() => {
      props.handleFilterByKeywords(keyword);
    });
  };

  const { selectedFilterGo } = props;
  const { t } = useTranslation();
  const filterIsEmpty = isFilterEmpty(selectedFilterGo);

  const confirmUpdate = () => {
    confirm({
      title: t('catalogue:enableFilterUpdate.title'),
      onOk() {
        disableActions(false);
      },
    });
  };

  return (
    <div className="catalogue-filter-container">
      <AntTooltip title={t('catalogue:receiver.disabledSearch')} hide={!props.disableKeywordInput}>
        <div className="search">
          <SearchInput
            key={props.selectedFilterGo.id}
            value={keywordInput}
            disabled={actionsDisabled || props.disableKeywordInput}
            onChange={e => updateKeywordInput(e.target.value)}
            onPressEnter={() => props.handleFilterByKeywords(keywordInput)}
            placeholder={catalogueTranslation.catalogueBar.searchProducts}
            allowClear
            autoFocus
            size="small"
            suffix={
              <HelpCenter
                id="search_product"
                className="catalogue-search__helper"
                placement="bottom"
              />
            }
          />
        </div>
      </AntTooltip>
      <div className="catalogue-bar__tags">
        <CatalogueFilterTags
          brandItemTags={props.brandItemTags}
          filterId={props.selectedFilterGo.id}
          filterTags={getFilterTags(selectedFilterGo)}
          handleAddTag={props.handleAddTag}
          handleRemoveTag={props.handleRemoveTag}
          disabled={actionsDisabled}
        />
      </div>
      <CatalogueFilterPreview
        {...props}
        selectedFilter={selectedFilterGo}
        disabled={actionsDisabled}
      />
      <div className="filter-action-button-container flex-1 flex justify-between">
        <div className="flex">
          <Tooltip title={t('catalogue:addFilter.title')}>
            <Button
              icon={<FilterOutlined />}
              className="button-add-filter"
              onClick={props.handleOpenFilter}
              size="small"
              htmlType="button"
              disabled={actionsDisabled}
            />
          </Tooltip>
          {props.selectedFilterGo.type_id === 1 && (
            <Tooltip title={t('catalogue:saveFilter.title')}>
              <AntPopover
                trigger="click"
                disabled={filterIsEmpty}
                content={<CatalogueSaveFilter handleSaveNewFilter={props.handleSaveNewFilter} />}
                buttonProps={{
                  icon: <SaveOutlined />,
                  className: 'button-save-filter ml-2',
                  size: 'small',
                  htmlType: 'button',
                }}
                overlayClassName="save-filter-popover"
              />
            </Tooltip>
          )}
          {actionsDisabled && (
            <Tooltip title={t('catalogue:enableFilterUpdate.action')}>
              <Button
                size="small"
                type="primary"
                onClick={() => confirmUpdate()}
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          <CatalogueResetFilterButton
            className="px-2"
            selectedFilterGo={selectedFilterGo}
            disabled={actionsDisabled}
            goOnly={props.goOnly}
          />
        </div>
        <div className="ml-auto">
          <Input.Group compact>
            <CatalogueFilterSelect
              filters={props.filters}
              selectedFilterGo={selectedFilterGo}
              selectedFilterId={props.selectedFilterGo.id}
              onFilterChange={props.onFilterChange}
            />
          </Input.Group>
        </div>
      </div>
    </div>
  );
};

export default CatalogueBarFilter;
