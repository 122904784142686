import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { LanguageTranslation } from '../../../types/language';

export type LanguageTranlationState = {
  fetchingTransLations: boolean;
  fetchingMoreTranslations: boolean;
  translations: LanguageTranslation[];
  translationsRowIds: number[];
};

const initialState = {
  fetchingTransLations: false,
  fetchingMoreTranslations: false,
  translations: [],
  translationsRowIds: [],
};

const reducer: Reducer<LanguageTranlationState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_SEGMENT_TRANSLATIONS_PENDING': {
      const firstPage = action.meta.page === 1;
      return {
        ...state,
        translations: firstPage ? [] : state.translations,
        fetchingMoreTranslations: action.meta.page > 1,
        fetchingTransLations: true,
        translationsRowIds: firstPage ? [] : state.translationsRowIds,
      };
    }
    case 'FETCH_SEGMENT_TRANSLATIONS_FULFILLED': {
      const firstPage = action.meta.page === 1;
      const translations = firstPage
        ? action.payload.data
        : [...state.translations, ...action.payload.data];
      const translationsRowIds = translations.map((data: LanguageTranslation) => data.id);

      return {
        ...state,
        translations,
        translationsRowIds,
        fetchingTransLations: false,
        fetchingMoreTranslations: false,
      };
    }
    case 'UPDATE_TRANSLATIONS_FULFILLED': {
      const translationsWithCount: any[] = [];

      action.meta.translations.forEach((value: any) => {
        translationsWithCount.push({
          ...value,
          count: state.translations.find((v: any) => v.id === value.id)?.count,
        });
      });

      const updateTranslations = translationsWithCount;
      const translationsRowIds: number[] = updateTranslations.map(data => data.id);

      return {
        ...state,
        translations: updateTranslations,
        translationsRowIds,
      };
    }
    case 'DELETE_TRANSLATIONS_FULFILLED': {
      const translations = state.translations.filter(
        trans => !action.meta.deleteTranslationIds.includes(trans.id)
      );
      return {
        ...state,
        translations,
        translationsRowIds: translations.map((data: LanguageTranslation) => data.id),
      };
    }
  }
  return state;
};

export default reducer;
