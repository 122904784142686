import React from 'react';
import { Select } from 'antd';
import translationConstants from '../../constants/ImportExportTranslation.json';
import { ExtendedResources } from '../../../types/resources';
import { PriceSheetType } from '../../../types/price_sheet';

type ExportPDFProps = {
  pdfTemplates: ExtendedResources[];
  priceSheets: PriceSheetType[];
  onChange: (obj: any) => void;
};

function ExportPDF(props: ExportPDFProps) {
  const [, setPdfExportState] = React.useState<{
    pdfTemplateId: number | null;
    priceSheetId: number | null;
  }>({
    pdfTemplateId: null,
    priceSheetId: null,
  });

  return (
    <div className="export-pdf">
      <Select
        className="export-pdf__select export-pdf__template-select "
        placeholder={translationConstants.select_template}
        onChange={(id: number) =>
          setPdfExportState(values => {
            const newState = { ...values, pdfTemplateId: id };
            props.onChange(newState);
            return newState;
          })
        }
      >
        {props.pdfTemplates.map(template => (
          <Select.Option key={template.id} value={template.id}>
            {template.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        className="export-pdf__select"
        placeholder={translationConstants.select_price}
        onChange={(id: number) =>
          setPdfExportState(values => {
            const newState = { ...values, priceSheetId: id };
            props.onChange(newState);
            return newState;
          })
        }
      >
        {props.priceSheets.map(priceSheet => (
          <Select.Option key={priceSheet.id} value={priceSheet.id}>
            {priceSheet.number}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default ExportPDF;
