import React from 'react';
import noImage from '../../../images/icons/no-image.svg';

type ImageThumbnailProps = {
  thumbnail: string;
  height?: number | string;
  width?: number | string;
};

const ImageThumbnail: React.FC<ImageThumbnailProps> = props => {
  const [imageNotFound, setImageNotFound] = React.useState(false);

  const assetSrc = props.thumbnail || noImage;

  const getFileTypeName = () => {
    const lastIndexDot = props.thumbnail?.lastIndexOf('.');
    const fileExtension = props.thumbnail?.substr(lastIndexDot + 1, props.thumbnail.length);
    return fileExtension;
  };

  const setError = () => {
    setImageNotFound(true);
  };
  return (
    <div
      style={{
        height: props.height || 28,
        width: props.width || 28,
      }}
      className="flex items-center justify-center"
    >
      {imageNotFound ? (
        <div className="image-not-supported">
          <span
            className="image-not-supported__text"
            style={{ transform: 'translateY(30%)', fontSize: 8 }}
          >
            {getFileTypeName()}
          </span>
        </div>
      ) : (
        <img
          style={{ maxHeight: '100%', maxWidth: '100%' }}
          src={assetSrc}
          alt="preview"
          onError={setError}
        />
      )}
    </div>
  );
};

export default ImageThumbnail;
