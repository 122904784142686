import React from 'react';
import {
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
  TableRowProps,
} from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Empty, Spin, Tag, Tooltip } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EditOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { PartTypeAttribute } from '../../../../../types/attributes';
import { StandardResource, StandardResourceDescription } from '../../../../../types/resources';
import TableFilterPopover from '../../../global/tableFilters/TableFilterPopover';
import AttributeNamePopover from '../../attributes/AttributeNamePopover';
import { AtrributeSourceIds } from '../../../../constants/AttributeConstants';

const SortableTable = SortableContainer(Table as any);
const SortableTableRowRenderer = SortableElement(
  (props: TableRowProps) => defaultTableRowRenderer(props) as any
);
const DragHandle = SortableHandle(() => <span className="cfs-table-drag">::</span>);

function rowRenderer(props: any) {
  return <SortableTableRowRenderer {...props} />;
}

type AttributeManagingTableProps = {
  fetchingAttributes: boolean;
  disabled: boolean;
  attributes: PartTypeAttribute[];
  selectedAttributeIds: number[];
  sources: StandardResourceDescription[];
  attributeFilterValues: StandardResource[];
  selectAttribute: (id: number, customAttribute: boolean) => void;
  hideAttribute: (id: number) => void;
  showAttribute: (id: number) => void;
  deleteAttribute: (id: number) => void;
  handleSort: (oldIndex: number, newIndex: number) => void;
  editAtrributeName: (attrName: string, rowIndex: number, id: number) => void;
  filterAttributeIds: number[];
  filterSourceIds: number[];
  handleFilterAttributes: (ids: number[]) => void;
  handleFilterSource: (ids: number[]) => void;
  handleSelectAll: () => void;
};

const AttributeManagingTable: React.FC<AttributeManagingTableProps> = ({
  fetchingAttributes,
  disabled,
  attributes,
  selectedAttributeIds,
  sources,
  selectAttribute,
  hideAttribute,
  showAttribute,
  deleteAttribute,
  handleSort,
  editAtrributeName,
  filterAttributeIds,
  filterSourceIds,
  handleFilterAttributes,
  handleFilterSource,
  attributeFilterValues,
  handleSelectAll,
}) => {
  const { t } = useTranslation();

  const noData = () => {
    if (fetchingAttributes)
      return <Spin spinning className="delivery__log-top-spinner" size="large" />;

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <SortableTable
            lockAxis="y"
            helperClass="stylizedHelper"
            // @ts-ignore
            height={height}
            width={width - 2}
            rowClassName="attribute-managing__table-row"
            headerHeight={40}
            rowCount={attributes.length}
            dataSource={attributes}
            rowHeight={40}
            rowGetter={({ index }: { index: number }) => attributes[index]}
            rowRenderer={rowRenderer}
            onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
              handleSort(oldIndex, newIndex);
            }}
            noRowsRenderer={noData}
            pressDelay={120}
          >
            <Column width={15} dataKey="index" cellRenderer={() => <DragHandle />} />
            <Column
              label={t('attributeManaging:active')}
              dataKey="active"
              width={45}
              cellRenderer={({ rowData }) => (
                <Checkbox
                  checked={selectedAttributeIds.includes(rowData.id || rowData.tempId)}
                  onChange={() => {
                    selectAttribute(rowData.id || rowData.tempId, rowData.source_id === 7);
                  }}
                  data-testid="select-row"
                />
              )}
              headerRenderer={() => (
                <Checkbox
                  checked={
                    selectedAttributeIds.length > 0 &&
                    selectedAttributeIds.length === attributes.length
                  }
                  indeterminate={
                    selectedAttributeIds.length > 0 &&
                    selectedAttributeIds.length < attributes.length
                  }
                  onChange={() => handleSelectAll()}
                />
              )}
            />
            <Column
              label={t('attributes:attribute')}
              dataKey="name"
              width={300}
              flexGrow={1}
              headerRenderer={({ label }) => (
                <div className="flex justify-between">
                  <div>{label}</div>
                  <div className="ml-3">
                    <TableFilterPopover
                      values={attributeFilterValues}
                      handleChange={ids => handleFilterAttributes(ids)}
                      initiallySelectedIds={filterAttributeIds}
                      showSearch
                    >
                      {filterAttributeIds.length > 0 ? (
                        <FilterFilled className="table-filter-blue" />
                      ) : (
                        <FilterOutlined />
                      )}
                    </TableFilterPopover>
                  </div>
                </div>
              )}
              cellRenderer={({ rowData, rowIndex }) => {
                const itemCount = (
                  <Tooltip title={t('attributes:previouslyUsedInfo')}>
                    <Tag className="self-center item-count">{rowData.item_count}</Tag>
                  </Tooltip>
                );
                if (rowData.source_id === 7)
                  return (
                    <div className="flex justify-between">
                      <div>{rowData.name}</div>
                      <div className="flex">
                        <AttributeNamePopover
                          attributeName={rowData.name}
                          handleChange={attributeName =>
                            editAtrributeName(attributeName, rowIndex, rowData.id)
                          }
                          translations={rowData.translations}
                          warnText={
                            rowData.item_count && rowData.item_count > 0
                              ? t('attributes:warnText', { count: rowData.item_count })
                              : undefined
                          }
                          detailedConfirmWarnText={
                            rowData.item_count && rowData.item_count > 0
                              ? t('attributes:detailedWarnText', { count: rowData.item_count })
                              : undefined
                          }
                        >
                          <EditOutlined className="edit-button" />
                        </AttributeNamePopover>
                        {rowData.item_count > 0 && itemCount}
                      </div>
                    </div>
                  );
                return (
                  <div className="flex justify-between">
                    <div>{rowData.name}</div>
                    {rowData.item_count > 0 && itemCount}
                  </div>
                );
              }}
            />
            <Column
              label={t('attributes:source')}
              dataKey="source_id"
              width={200}
              headerRenderer={({ label }) => {
                return (
                  <div className="flex justify-between">
                    <div>{label}</div>
                    <div className="ml-3">
                      <TableFilterPopover
                        values={sources.filter(s => AtrributeSourceIds.includes(s.id))}
                        handleChange={ids => {
                          handleFilterSource(ids);
                        }}
                        initiallySelectedIds={filterSourceIds}
                        showSearch
                      >
                        {filterSourceIds.length > 0 ? (
                          <FilterFilled className="table-filter-blue" />
                        ) : (
                          <FilterOutlined />
                        )}
                      </TableFilterPopover>
                    </div>
                  </div>
                );
              }}
              cellRenderer={({ rowData }) => {
                const source = sources.find(s => s.id === rowData.source_id);
                return <span>{source?.name}</span>;
              }}
            />
            <Column
              label={t('attributes:tags')}
              dataKey="tags"
              width={400}
              flexGrow={1}
              cellRenderer={({ rowData }) => (
                <span>
                  {rowData.tags && rowData.tags.map((tag: string) => <Tag key={tag}>{tag}</Tag>)}
                </span>
              )}
            />
            <Column
              label={t('common:actions')}
              dataKey="actions"
              width={120}
              cellRenderer={({ rowData }) => (
                <React.Fragment>
                  {rowData.source_id === 7 && (
                    <Tooltip title={t('attributes:deleteAttributeInfo')}>
                      <Button
                        onClick={() => deleteAttribute(rowData.id || rowData.tempId)}
                        disabled={disabled}
                        size="small"
                        danger
                      >
                        {t('common:delete')}
                      </Button>
                    </Tooltip>
                  )}

                  {!!rowData.hide && (
                    <Tooltip title={t('attributes:showAttributeInfo')}>
                      <Button
                        className="ml-1"
                        onClick={() => showAttribute(rowData.id || rowData.tempId)}
                        disabled={disabled}
                        size="small"
                      >
                        {t('common:show')}
                      </Button>
                    </Tooltip>
                  )}
                  {!rowData.hide && (
                    <Tooltip title={t('attributes:hideAttributeInfo')}>
                      <Button
                        className="ml-1"
                        onClick={() => hideAttribute(rowData.id || rowData.tempId)}
                        disabled={disabled}
                        size="small"
                      >
                        {t('common:hide')}
                      </Button>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}
            />
          </SortableTable>
        </div>
      )}
    </AutoSizer>
  );
};

export default AttributeManagingTable;
