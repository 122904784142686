import React from 'react';

type ReceiverBrandImportIconsProps = {
  updatedAt: string | null;
};

const ReceiverBrandImportIcons: React.FC<ReceiverBrandImportIconsProps> = ({ updatedAt }) => {
  // todo: cleanup in 6 months if not used
  // const getColor = (alertType: number) => {
  //   if (alertType === 1) return 'green';
  //   if (alertType === 2) return 'yellow';
  //   if (alertType === 3) return 'red';
  //   if (alertType === 4) return 'grey';
  //   return '';
  // };

  const getNewestSuccessDate = () => {
    const date = new Date(updatedAt || 0);
    return date.getTime() > 0 ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : '-';
  };

  // const displayIcon = (status: number) => (
  //   <React.Fragment>
  //     {(status === 1 || status === 2 || status === 4) && (
  //       <CheckCircleOutlined className={`${getColor(status)} text-xl`} />
  //     )}
  //     {status === 3 && <CloseCircleOutlined className={`${getColor(status)} text-xl`} />}
  //     {!status && <MinusOutlined className="grey" />}
  //   </React.Fragment>
  // );

  // const iconWrapper = (
  //   <React.Fragment>
  //     <div className="receiver__import-tooltip-row h-7 mb-2">
  //       <span />
  //       <span />
  //       <span>{t('receiver:lastAttempted')}</span>
  //       <span>{t('receiver:lastSuccessful')}</span>
  //     </div>
  //     {importList.map(status => (
  //       <div key={status.name} className="receiver__import-tooltip-row h-7">
  //         <span>{t(`receiver:${status.name}`)}</span>
  //         <span>{displayIcon(status.status)}</span>
  //         <span>
  //           {status.last_attempt ? (
  //             `${localDateString(status.last_attempt)} ${localTimeString(status.last_attempt)}`
  //           ) : (
  //             <MinusOutlined className="grey" />
  //           )}
  //         </span>
  //         <span>
  //           {status.last_success ? (
  //             `${localDateString(status.last_success)} ${localTimeString(status.last_success)}`
  //           ) : (
  //             <MinusOutlined className="grey" />
  //           )}
  //         </span>
  //       </div>
  //     ))}
  //     {/* <div className="mt-5">
  //       <Link to="/available-brands/export/export-logs">
  //         <Button size="small">{t('receiver:importLog')}</Button>
  //       </Link>
  //     </div> */}
  //   </React.Fragment>
  // );

  const successDate = getNewestSuccessDate();

  return (
    <div>
      {/* no icon and tooltip will be displayed for the first version */}
      {/* <Tooltip title={iconWrapper} overlayStyle={{ maxWidth: '800px' }}> */}
      <span>{successDate}</span>
      {/* </Tooltip> */}
    </div>
  );
};

export default ReceiverBrandImportIcons;
