import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../../constants/AntTagsTranslation.json';

/*
  Possible props to pass:
  - className: string that is additionally added to default className
  - noAddButton: Hide add button for dynamically adding new tags
  - addButtonClassName: string that is additionally added to add Button (only relevant with noAddButton)
  - create: function that is called for creating new tags and returns the new selected Element
  - elements: array => [{id: "Int", value: "String"}]
  - closable: defines if tag can be removed (closed)
  - onClose: remove function
  - disabled: disable add button
*/

export default class AntTags extends React.Component {
  state = {
    inputVisible: false,
    inputValue: '',
  };

  handleClose = removedTag => {
    const { elements } = this.props;
    const element = elements.find(el => el.value === removedTag);
    this.props.onClose(element.id || element.value);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { elements } = this.props;
    const { inputValue } = this.state;
    if (!elements.find(el => el.value === inputValue)) {
      this.props.create(inputValue);
      this.setState({ inputVisible: false, inputValue: '' });
    }
  };

  render() {
    const { closable, className, elements, noAddButton, addButtonClassName, disabled } = this.props;
    const { inputVisible, inputValue } = this.state;

    const tags = elements.map(({ id, value }) => (
      <Tag
        className={className}
        closable={closable}
        onClose={() => this.handleClose(value)}
        key={id || value}
      >
        {value}
      </Tag>
    ));

    return noAddButton ? (
      <div>{tags}</div>
    ) : (
      <div>
        {tags}
        {inputVisible && (
          <Input
            ref={input => (this.input = input)}
            type="text"
            size="small"
            style={{ width: 100 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            className={classNames(`filter__custom-add-tag ${addButtonClassName}`, { disabled })}
            onClick={!disabled && this.showInput}
          >
            <PlusOutlined /> {constants.add}
          </Tag>
        )}
      </div>
    );
  }
}

AntTags.propTypes = {
  // string that is additionally added to default className
  className: PropTypes.string,
  // bool that disables the functionality to dynamically add tags
  noAddButton: PropTypes.bool,
  // string that is additionally added to add Button
  addButtonClassName: PropTypes.string,
  // array => [{id: "Int", name: "String"}]
  elements: PropTypes.array,
  // function that is called for creating new tags and returns the new selected Element
  create: PropTypes.func,
  // remove function
  onClose: PropTypes.func,
};

AntTags.defaultProps = {
  elements: [],
  noAddButton: false,
  addButtonClassName: '',
  create: () => {},
  onClose: () => {},
  className: '',
};
