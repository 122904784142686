import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Line } from 'react-chartjs-2';
import analyseConstants from '../../../constants/AnalyseConstants.json';
import analyseTranslation from '../../../constants/AnalyseTranslation.json';
import AnalyseQualityHistoryInterval from './AnalyseQualityHistoryInterval';

const findDateInArray = (date, data) =>
  data.find(
    singleData => new Date(singleData.created_on).toDateString() === new Date(date).toDateString()
  );

const getDateDaysAgo = days => {
  const d = new Date();
  d.setDate(d.getDate() - days);
  return d;
};

const emptyDays = (dateDataFirst, dateDaysAgo) => new Date(dateDataFirst) > new Date(dateDaysAgo);

const getDaysTilToday = day => Math.round((new Date() - new Date(day)) / (1000 * 60 * 60 * 24));

const getNextDayInOrder = day => {
  const d = new Date(day);
  let tommorow = d.getDate() + 1;
  tommorow = d.setDate(tommorow);
  return tommorow;
};

const manipulateObject = (obj, date) => ({ ...obj, created_on: date });

const emptyObj = {
  item_error_count: 0,
  item_warning_count: 0,
  item_count: 0,
};

// we get data from the backend for all days, items got modified, but for our graph we need data for each day for the given interval
// fakeArray is a array with faked data: missing dates get added and holds the state of the last given date
const generateFakeArray = (data, days) => {
  // get back the first day in our days interval
  const dateDaysAgo = getDateDaysAgo(days);

  // filter out all data which is older than our firstDate in the interval
  let dataArrayAfterDay = data.filter(d => d.created_on > dateDaysAgo.toISOString());

  // in case the time interval is to low
  if (!dataArrayAfterDay.length) {
    dataArrayAfterDay = data;
  }

  // check how many days it is from our first data til today
  const daysTilToday = getDaysTilToday(dataArrayAfterDay[0].created_on);

  // if the interval is greater than our first given data, daysNoData hold the days for how many days there are no data
  // so empty data Objects can be added for each day
  let daysNoData = -1;
  if (emptyDays(data[0].created_on, dateDaysAgo)) {
    daysNoData = days - daysTilToday;
  }

  let nextDayInOrder = dateDaysAgo; // starts with day 0 and counts up
  let lastGivenDay = dataArrayAfterDay[0]; // holds the date of the last real day
  const fakeArray = [];

  for (let i = 0; i < days + 1; i++) {
    const isDateGiven = findDateInArray(nextDayInOrder, dataArrayAfterDay);
    if (i < daysNoData) {
      // as long there is no data the array gets empty data
      fakeArray.push(manipulateObject(emptyObj, new Date(nextDayInOrder).toISOString()));
    } else if (isDateGiven) {
      // if a date in our data is found lastGivenDay is updated
      fakeArray.push(isDateGiven);
      lastGivenDay = isDateGiven;
    } else {
      // the array gets fake data - the data from the previous day
      const fakeObjWithNewDate = manipulateObject(
        lastGivenDay,
        new Date(nextDayInOrder).toISOString()
      );
      fakeArray.push(fakeObjWithNewDate);
    }
    nextDayInOrder = getNextDayInOrder(nextDayInOrder);
  }

  return fakeArray;
};

const generateLineData = array => {
  const labels = array.map(d => new Date(d.created_on).toDateString().slice(4, 10)); // get a nice string for output
  const lastItemCount = array[array.length - 1];
  const itemCount = array.map(d => d.item_count);
  const progress = array.map(d => (d.progress_score * lastItemCount.item_count) / 100);
  const errors = array.map(d => d.item_error_count);
  const warnings = array.map(d => d.item_warning_count);
  const infos = array.map(d => d.item_info_count);

  return {
    labels,
    datasets: [
      {
        label: 'Total count',
        fill: 'false',
        cubicInterpolationMode: 'monotone',
        pointBackgroundColor: 'white',
        backgroundColor: analyseConstants.color_code.blackOpacity,
        borderColor: analyseConstants.color_code.blackOpacity,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1.5,
        pointHoverRadius: 2,
        pointRadius: 2,
        data: itemCount,
        borderWidth: 2,
      },
      {
        label: 'With Errors',
        fill: 'false',
        cubicInterpolationMode: 'monotone',
        backgroundColor: analyseConstants.color_code.redOpacity,
        borderColor: analyseConstants.color_code.redOpacity,
        borderJoinStyle: 'miter',
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointRadius: 0,
        data: errors,
        borderWidth: 2,
      },
      {
        label: 'With Warnings',
        fill: 'false',
        cubicInterpolationMode: 'monotone',
        backgroundColor: analyseConstants.color_code.yellowOpacity,
        borderColor: analyseConstants.color_code.yellowOpacity,
        borderJoinStyle: 'miter',
        pointBorderWidth: 0.7,
        pointHoverRadius: 0,
        pointRadius: 0,
        data: warnings,
        borderWidth: 2,
      },
      {
        label: 'With Recommendations',
        fill: 'false',
        cubicInterpolationMode: 'monotone',
        backgroundColor: analyseConstants.color_code.blueOpacity,
        borderColor: analyseConstants.color_code.blueOpacity,
        borderJoinStyle: 'miter',
        pointBorderWidth: 0.7,
        pointHoverRadius: 0,
        pointRadius: 0,
        data: infos,
        borderWidth: 2,
      },
      {
        label: 'Progress',
        fill: 'origin',
        cubicInterpolationMode: 'monotone',
        backgroundColor: analyseConstants.color_code.lightGreenOpacity,
        borderColor: analyseConstants.color_code.lightGreenOpacity,
        borderJoinStyle: 'miter',
        pointBorderWidth: 0.7,
        pointHoverRadius: 0,
        pointRadius: 0,
        data: progress,
        borderWidth: 0.7,
      },
    ],
  };
};

const generateOptions = array => {
  const progressScore = array.map(d => d.progress_score);
  const maxItemCount = array.map(d => d.item_count).reduce((a, b) => Math.max(a, b));
  const maxItemCountRounded = Math.ceil(maxItemCount / 5) * 5;
  const steps = maxItemCountRounded / 5;
  return {
    responsive: false,
    maintainAspectRatio: false,
    steppedLine: true,
    snapGaps: true,
    tooltips: {
      mode: 'index',
      intersect: false,
      position: 'average',
      callbacks: {
        label(tooltipItems, data) {
          // datasetIndex 4 is progress
          if (tooltipItems.datasetIndex === 4) {
            return `Average Progress: ${progressScore[tooltipItems.index]} %`;
          }
          return `${data.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel}`;
        },
      },
    },
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            color: analyseConstants.color_code.grey,
            mirror: true,
            maxRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          scaleShowLabels: false,
          gridLines: {
            offsetGridLines: false,
          },
          offset: true,
          ticks: {
            beginAtZero: true,
            stepSize: steps,
            min: 0,
            max: maxItemCountRounded,
          },
        },
      ],
    },
  };
};

const initalOptions = {
  responsive: false,
  maintainAspectRatio: false,
  steppedLine: true,
  snapGaps: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
        },
      },
    ],
  },
  layout: {
    padding: {
      top: 20,
    },
  },
};

class AnalyseQualityHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dayRange: 14,
    };
  }

  handleDateRangeChange = days => {
    this.setState({ dayRange: days });
  };

  checkIfAnalysesExist = () => {
    if (this.props.analysesHistory.length === 0) {
      return (
        <span className="analyses-not-existing">{analyseTranslation.no_analyses_existing}</span>
      );
    }
  };

  render() {
    let data = generateLineData([]);
    let allTimeDays;
    let options = initalOptions;
    if (this.props.analysesHistory && this.props.analysesHistory.length > 0) {
      const fakeArray = generateFakeArray(this.props.analysesHistory, this.state.dayRange);
      data = generateLineData(fakeArray);
      allTimeDays = getDaysTilToday(this.props.analysesHistory[0].created_on);
      options = generateOptions(fakeArray);
    }
    return (
      <div className="analyse-quality-history analyse__border">
        <div className="analyse-quality-history__top flex">
          <p className="title home-container__analyse-title">
            {analyseTranslation.quality_history}
            <Tooltip title={analyseTranslation.tooltip.quality_history}>
              <InfoCircleOutlined className="ml-1" />
            </Tooltip>
            {this.checkIfAnalysesExist()}
          </p>
          <AnalyseQualityHistoryInterval
            className="analyse-quality-history__canvas"
            handleDateRangeChange={this.handleDateRangeChange}
            allTimeDays={allTimeDays}
          />
        </div>
        <div>
          <Line data={data} options={options} height={240} width={910} />
        </div>
      </div>
    );
  }
}

export default AnalyseQualityHistory;
