import React from 'react';
import { Popover, Card, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import FormInput from '../../global/Forms/FormInput';
import { ApplicationState } from '../../../reducers';
import FormSelect from '../../global/Forms/FormSelect';
import { StandardResource } from '../../../../types/resources';

type VcdbAddModelPopoverProps = {
  keywords?: string;
  handleSave: (name: string, type: string, group: string) => Promise<any>;
};

const VcdbAddModelPopover: React.FC<VcdbAddModelPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const { vehicleResources } = useSelector((state: ApplicationState) => {
    return {
      vehicleResources: state.resources.applicationVehicle,
    };
  });

  const [typeList, setTypeList] = React.useState(vehicleResources.model_types);
  const [groupList, setGroupList] = React.useState(vehicleResources.model_type_groups);

  React.useEffect(() => {
    if (popoverVisible) {
      setTypeList(vehicleResources.model_types);
      setGroupList(vehicleResources.model_type_groups);
    }
  }, [popoverVisible, vehicleResources.model_type_groups, vehicleResources.model_types]);

  const filterTypeValues = (keywords: string) => {
    let typeList = vehicleResources.model_types.filter((type: StandardResource) =>
      type.name.toLowerCase().includes(keywords.toLowerCase())
    );
    if (typeList.length === 0) typeList = [{ id: Date.now(), name: keywords }];

    setTypeList(typeList);
  };

  const filterGroupValues = (keywords: string) => {
    let groupList = vehicleResources.model_type_groups.filter((group: StandardResource) =>
      group.name.toLowerCase().includes(keywords.toLowerCase())
    );
    if (groupList.length === 0) groupList = [{ id: Date.now(), name: keywords }];

    setGroupList(groupList);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation:required')),
    groupId: Yup.number().required(t('validation:required')),
    typeId: Yup.number().required(t('validation:required')),
  });

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => {
        setPopoverVisible(visible);
      }}
      overlayClassName="help application_listing__popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="qualifier__value-popover">
          <Formik
            initialValues={{
              name: props.keywords || '',
              groupId: undefined,
              groupName: '',
              typeId: undefined,
              typeName: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);

              props
                .handleSave(values.name, values.typeName, values.groupName)
                .then(() => {
                  setSubmitting(false);
                  setPopoverVisible(false);
                })
                .catch(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ handleSubmit, setFieldValue, isSubmitting }) => (
              <div>
                <Form layout="vertical">
                  <FormInput
                    name="name"
                    label={t('application:vcdbAddCustom')}
                    maxLength={100}
                    required
                  />

                  <FormSelect
                    name="typeId"
                    values={typeList}
                    label={t('common:type')}
                    onChange={(id: any) => {
                      const type = typeList.find((type: any) => type.id === id);
                      setFieldValue('typeName', type.name);
                      setFieldValue('typeId', id);
                    }}
                    showSearch
                    filterOption={false}
                    onSearch={keywords => {
                      filterTypeValues(keywords);
                    }}
                    required
                  />

                  <FormSelect
                    name="groupId"
                    values={groupList}
                    label={t('common:group')}
                    onChange={(id: any) => {
                      const group = groupList.find((group: any) => group.id === id);
                      setFieldValue('groupName', group.name);
                      setFieldValue('groupId', id);
                    }}
                    showSearch
                    filterOption={false}
                    onSearch={keywords => {
                      filterGroupValues(keywords);
                    }}
                    required
                  />
                </Form>

                <div className="flex-col items-end">
                  <div>
                    <Button
                      onClick={() => setPopoverVisible(false)}
                      className="drawer-submit__bottom-cancel"
                    >
                      {t('common:cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      {t('common:save')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      <Button size="small">{t('common:add')}</Button>
    </Popover>
  );
};

export default VcdbAddModelPopover;
