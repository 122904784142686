import axios from 'axios';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

const CancelToken = axios.CancelToken;

export function fetchAccountLanguages() {
  return {
    type: 'FETCH_ACCOUNT_LANGUAGES',
    payload: axios.get(`${getNonGwApiRoot()}/accounts/languages`),
  };
}

let fetchSegmentTranslationsCanceltoken: any;

export function fetchTranslations({
  translationTypeId,
  page = 1,
  keywords,
  emptyLanguageIds,
  used,
  orderByLanguageId,
  descending,
}: {
  translationTypeId: number;
  page?: number;
  keywords?: string;
  emptyLanguageIds?: number[];
  used?: number;
  orderByLanguageId?: number;
  descending?: number;
}) {
  if (fetchSegmentTranslationsCanceltoken) fetchSegmentTranslationsCanceltoken();

  const urlParams = utils.createURL([
    { name: 'translation_type_id', values: translationTypeId },
    { name: 'page', values: page },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'keywords', values: utils.stringToArray(keywords || '') },
    { name: 'empty_language_ids', values: emptyLanguageIds },
    { name: 'used', values: used },
    { name: 'order_by_language_id', values: orderByLanguageId },
    { name: 'descending', values: descending },
  ]);

  return {
    type: 'FETCH_SEGMENT_TRANSLATIONS',
    meta: { translationSegmentId: translationTypeId, page },
    payload: axios.get(`${getNonGwApiRoot()}/accounts/languages/translations${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchSegmentTranslationsCanceltoken = c;
      }),
    }),
  };
}
