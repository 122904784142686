import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import TableInputSearchPopover from '../../global/TableInputSearchPopover';

type ChannelBrandTitleSearchProps = {
  title?: string;
  handleOnSearch: (searchKeyWord: string) => void;
  searchKey: string;
};

const ChannelBrandTitleSearch: React.FC<ChannelBrandTitleSearchProps> = props => {
  return (
    <div className="flex items-center justify-between">
      <div className="pr-2">{props.title}</div>
      <TableInputSearchPopover
        initialValue={props.searchKey}
        handleChange={keyword => props.handleOnSearch(keyword)}
      >
        {props.searchKey ? (
          <SearchOutlined className="fill-blue" />
        ) : (
          <SearchOutlined className="fill-grey" />
        )}
      </TableInputSearchPopover>
    </div>
  );
};

export default ChannelBrandTitleSearch;
