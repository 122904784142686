import React, { Dispatch } from 'react';
import { connect as formikConnect, FormikValues, FormikContextType } from 'formik';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { updatePageFormDirtyState } from '../../../actions/catalogue/catalogue/update';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type PageFormDirtyHandlerProps = {
  dispatch: Dispatch<AnyAction>;
};

class PageFormDirtyHandler extends React.Component<PageFormDirtyHandlerProps & FormikProps> {
  componentDidUpdate(prevProps: PageFormDirtyHandlerProps & FormikProps) {
    const { dirty } = this.props.formik;
    const { dirty: prevDirty } = prevProps.formik;
    if (prevDirty !== dirty) {
      this.props.dispatch(updatePageFormDirtyState(dirty));
    }
  }

  render() {
    return null;
  }
}

export default connect()(
  formikConnect<PageFormDirtyHandlerProps, FormikValues>(PageFormDirtyHandler)
);
