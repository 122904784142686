import React from 'react';
import { EditOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AntTooltip from '../../global/AntTooltip';
import PartNumberPopover from './PartNumberPopover';
import { ApplicationElement } from '../../../../types/all_application_validation';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';

type AllApplicationsActionCellProps = {
  showCheckbox?: boolean;
  checked?: boolean;
  invalid?: boolean;
  applicationData: ApplicationElement;
  analyses?: (Analysis & AnalysisType)[];
  handleEditClick: () => void;
  handleRowSelect: () => void;
};

const AllApplicationsActionCell: React.FC<AllApplicationsActionCellProps> = ({
  showCheckbox,
  checked,
  invalid,
  applicationData,
  analyses,
  handleEditClick,
  handleRowSelect,
}) => {
  const { t } = useTranslation();

  const tooltip = (
    <div>
      {invalid && t('applicationValidation:invalidApplicationMessage')}
      {!!analyses?.length && (
        <div className={classNames({ 'mt-2': invalid })}>
          {analyses.map(analysis => (
            <div key={analysis.id}>{analysis.name}</div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div>
      {applicationData.application_id && !applicationData.linked_application && (
        <EditOutlined
          className="application_listing__edit-icon"
          onClick={() => handleEditClick()}
        />
      )}
      {applicationData.application_id && applicationData.linked_application && (
        <AntTooltip title={t('applicationValidation:inheritedApplication')}>
          <InfoCircleOutlined className="application_listing__edit-icon" />
        </AntTooltip>
      )}
      {(invalid || !!analyses?.length) && (
        <AntTooltip title={tooltip}>
          <WarningOutlined className="application_listing__warning-icon ml-2" />
        </AntTooltip>
      )}
      {showCheckbox && !applicationData.application_id && (
        <React.Fragment>
          <Checkbox checked={checked} onChange={() => handleRowSelect()} />
          <PartNumberPopover applicationRows={[applicationData]} />
        </React.Fragment>
      )}
    </div>
  );
};

export default AllApplicationsActionCell;
