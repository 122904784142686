import React from 'react';
import BouncingLoader from '../BouncingLoader';
import ModalContent from './ModalContent';

class InitAppModal extends React.Component {
  render() {
    return (
      <ModalContent>
        <BouncingLoader />
      </ModalContent>
    );
  }
}

export default InitAppModal;
