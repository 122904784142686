import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ItemPrice } from '../../../types/price_sheet';

export type PricingState = {
  itemPrices: ItemPrice[];
  itemPriceIsFetching: boolean;
};

const initialState = {
  itemPrices: [],
  itemPriceIsFetching: false,
};

const reducer: Reducer<PricingState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM_PRICES_FULFILLED': {
      return { ...state, itemPrices: action.payload.data };
    }
    case 'FETCH_ITEM_PRICES_PENDING': {
      return { ...state, itemPrices: [], itemPriceIsFetching: true };
    }
    case 'FETCH_ITEM_PRICES_FULFILLED': {
      return { ...state, itemPrices: action.payload.data, itemPriceIsFetching: false };
    }
    case 'DELETE_PRICE_FULFILLED': {
      return {
        ...state,
        itemPrices: state.itemPrices.filter(price => price.id !== action.meta.priceId),
      };
    }
  }
  return state;
};

export default reducer;
