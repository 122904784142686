import { FastField, Field, FieldProps } from 'formik';
import { Form, Checkbox } from 'antd';
import React, { ReactNode } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type FormCheckboxProps = {
  name: string;
  label?: string | ReactNode;
  checkedValue?: number;
  disabled?: boolean;
  className?: string;
  fastField?: boolean;
  handleChange?: (e: CheckboxChangeEvent) => void;
};

export default class FormCheckbox extends React.Component<FormCheckboxProps> {
  render() {
    const { name, label, checkedValue, disabled, className, fastField, handleChange } = this.props;

    const fieldContent = ({ field }: FieldProps) => {
      return (
        <Form.Item label={label} className={className}>
          <Checkbox
            name={name}
            disabled={disabled}
            checked={checkedValue ? field.value === checkedValue : field.value}
            onChange={handleChange || field.onChange}
          />
        </Form.Item>
      );
    };

    return fastField ? (
      <FastField name={name}>{fieldContent}</FastField>
    ) : (
      <Field name={name}>{fieldContent}</Field>
    );
  }
}
