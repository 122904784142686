import React from 'react';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../../global/TextEditor';
import Dropdown from '../../global/Dropdown';

import { description as translation } from '../../../constants/DigitalAssetsTranslation.json';

class DigitalAssetSingleDescription extends React.Component {
  state = {
    languageId: this.props.description
      ? this.props.description.language_id
      : this.props.defaultLanguageId,
    typeId: this.props.description ? this.props.description.type_id : undefined,
    contents: this.props.description ? this.props.description.contents : [],
  };

  createDescription = () => {
    const { typeId, languageId, contents } = this.state;

    const allGiven = typeId && contents.length;
    if (allGiven) {
      this.props.createDescription(typeId, languageId, contents);
    }
  };

  updateDescriptionContents = contents => {
    const { isTemp } = this.props;
    const newContents = contents.map((entry, index) => ({ ...entry, record_number: index + 1 }));
    this.setState({ contents: newContents }, () => {
      if (!isTemp) this.props.updateDescriptionContents(this.props.description.id, newContents);
      else this.createDescription();
    });
  };

  updateLanguage = value => {
    const { isTemp } = this.props;
    this.setState({ languageId: value }, () => {
      if (!isTemp) {
        this.props.updateDescriptionContents(this.props.description.id, undefined, Number(value));
      } else this.createDescription();
    });
  };

  updateType = value => {
    const { isTemp } = this.props;
    this.setState({ typeId: value }, () => {
      if (!isTemp) {
        this.props.updateDescriptionContents(
          this.props.description.id,
          undefined,
          undefined,
          Number(value)
        );
      } else this.createDescription();
    });
  };

  render() {
    const { description, descriptionTypes, languages, isTemp } = this.props;
    const { typeId, languageId, contents } = this.state;
    const descCSS = classNames('digital-assets__description-editor-container box box_background', {
      temp: isTemp,
    });
    return (
      <div className={descCSS}>
        <div className="form-field">
          <div className="form-field-name">{translation.type}</div>
          <Dropdown
            className="form-field-content"
            noSelection={isTemp}
            elements={descriptionTypes}
            selectedElement={typeId}
            onChange={id => this.updateType(id)}
          />
        </div>
        <div className="form-field">
          <div className="form-field-name">{translation.language}</div>
          <Dropdown
            className="form-field-content"
            noSelection={isTemp}
            elements={languages}
            selectedElement={languageId}
            onChange={id => this.updateLanguage(id)}
          />
        </div>
        <div
          className="digital-assets__description-delete-button"
          onClick={() => this.props.deleteDescription(description && description.id)}
        >
          <CloseOutlined style={{ fontSize: '10px', padding: '2px' }} />
        </div>
        <div className="digital-assets__description-editor">
          <TextEditor
            autoFocus={contents.length === 0}
            contents={contents}
            objectId={description ? description.id : 1}
            submit={this.updateDescriptionContents}
          />
        </div>
      </div>
    );
  }
}

export default DigitalAssetSingleDescription;
