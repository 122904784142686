import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { ApplicationState } from '../../reducers';
import { keys } from '../../constants/ParamountReactConstants';
import { AsyncDispatch } from '../../../types/global';

export enum BugsnagAction {
  CREATE_CLIENT = 'CREATE_CLIENT',
  NOTIFY_BUGSNAG = 'NOTIFY_BUGSNAG',
}

export const createBugsnagClient = () => {
  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    Bugsnag.start({
      apiKey: keys.bugsnag.api_key,
      plugins: [new BugsnagPluginReact(React)],
      collectUserIp: true,
      releaseStage: process.env.REACT_APP_STAGE || 'development', // set by deployer
      enabledReleaseStages: ['dev', 'qa', 'beta', 'production'],
      onError(event: any) {
        const user = getState().user.user;
        event.setUser(user?.id, user?.email, `${user?.first_name} ${user?.last_name}`);
        if (event.errors[0].errorMessage === 'Request failed with status code 401') return false;
      },
    });

    return dispatch({
      type: BugsnagAction.CREATE_CLIENT,
    });
  };
};

export const notifyBugsnag = (error: any, metadata: any) => {
  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    const {
      user: { user },
    } = getState();
    Bugsnag.notify(error, event => {
      event.setUser(user?.id.toString(), user?.email, `${user?.first_name} ${user?.last_name}`);
      event.addMetadata('meta', metadata);
    });

    return dispatch({
      type: BugsnagAction.NOTIFY_BUGSNAG,
      meta: { error, metadata },
    });
  };
};
