export function resetUploadInformation() {
  return {
    type: 'RESET_UPLOAD_INFORMATION',
  };
}

export function setUploadInformation(uploadType, assetArea) {
  return {
    type: 'SET_UPLOAD_INFORMATION',
    payload: { uploadType, assetArea },
  };
}

export function setFile(file) {
  return {
    type: 'SET_FILE',
    payload: file,
  };
}

export function setFileUploadInformation(key, value) {
  return {
    type: 'SET_FILE_UPLOAD_INFORMATION',
    payload: { key, value },
  };
}

export function setAssetNameExistError(boolean) {
  return {
    type: 'SET_ASSET_NAME_EXIST_ERROR',
    payload: boolean,
  };
}
