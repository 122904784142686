import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';
import { BrandCustomField, ItemCustomField } from '../../../types/custom_fields';

const getBrandCustomFields = (state: ApplicationState) => state.brand.customFields.customFields;
const getCustomFields = (state: ApplicationState) => state.items.customFields.customFields;
const getSelectedItemIds = (state: ApplicationState) => state.catalogue.catalogue.selectedItemIds;

const getItemValue = (
  selectedItemId: number,
  customFields: ItemCustomField[],
  brandCustomFieldId: number
) => {
  return (
    customFields.find(
      customField =>
        customField.item_id === selectedItemId &&
        customField.brand_custom_field_id === brandCustomFieldId
    )?.value || ''
  );
};

export const mapItemsToCustomFields = createSelector(
  [getBrandCustomFields, getCustomFields, getSelectedItemIds],
  (
    brandCustomFields: BrandCustomField[],
    customFields: ItemCustomField[],
    selectedItemIds: number[]
  ) => {
    return brandCustomFields.map(brandCustomField => {
      const value = getItemValue(selectedItemIds[0], customFields, Number(brandCustomField.id));
      return {
        id: brandCustomField.id,
        custom_field_type_id: brandCustomField.custom_field_type_id,
        name: brandCustomField.name,
        options: brandCustomField.options,
        script_id: brandCustomField.script_id,
        group_id: brandCustomField.group_id,
        value,
      };
    });
  }
);
