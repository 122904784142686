import axios from 'axios';
import { FormikValues } from 'formik';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function updatePriceSheet(sheetId: number, values: FormikValues) {
  const paramObject = Object.keys(values).map(key => ({
    name: key,
    values: values[key],
    allowEmpty: true,
  }));
  const params = utils.createURL(paramObject);
  return {
    type: 'UPDATE_PRICE_SHEET',
    meta: { sheetId },
    payload: axios.patch(`${getApiRoot()}/parents/price_sheets/${sheetId}${params}`),
  };
}

export function assignToReceivers(existingPriceSheetId: number, assigningPriceSheetId: number) {
  const params = utils.createURL([
    { name: 'existing_price_sheet_ids', values: existingPriceSheetId },
    { name: 'new_price_sheet_ids', values: assigningPriceSheetId },
  ]);
  return {
    type: 'UPDATE_PRICE_SHEET_TO_RECEIVER',
    meta: { sheetId: assigningPriceSheetId },
    payload: axios.patch(
      `${getApiRoot()}/parents/receivers/authorized_brands/price_sheets${params}`
    ),
  };
}
