import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { List, Checkbox, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { AsyncDispatch } from '../../../../types/global';
import { AnalysisVehicleBasis, ConfirmedVehicleBase } from '../../../../types/analyses';
import { fetchApplication } from '../../../actions/items/application/fetch';
import { selectApplication } from '../../../actions/items/application/update';
import { fetchApplicationItem } from '../../../actions/all_applications/fetch';
import AntTooltip from '../../global/AntTooltip';

type QuickfixVehicleRowProps = {
  vehicle: AnalysisVehicleBasis;
  isAccepted: boolean;
  isDeclined: boolean;
  isSelected: boolean;
  confirmedBase?: ConfirmedVehicleBase;
  itemId: number;
  selectVehicleRow: (vehicleId: number) => void;
};

const QuickfixVehicleRow: React.FC<QuickfixVehicleRowProps> = ({
  vehicle,
  isAccepted,
  isDeclined,
  isSelected,
  confirmedBase,
  itemId,
  selectVehicleRow,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const handleApplicationSelect = (id: number) => {
    dispatch(fetchApplication(itemId, id));
    dispatch(fetchApplicationItem(itemId));
    dispatch(selectApplication(id));
  };

  const cssClasses = classNames(
    { analyses__accepted: isAccepted },
    { analyses__declined: isDeclined }
  );

  return (
    <List.Item className={cssClasses} onClick={() => selectVehicleRow(vehicle.id)}>
      <Checkbox
        className="analyses__item-list-check"
        checked={isSelected}
        onChange={() => selectVehicleRow(vehicle.id)}
        disabled={isAccepted || isDeclined}
      />
      <div className="analyses__item-list-name">
        {`${vehicle.year} ${vehicle.make_name} ${vehicle.model_name}`}
      </div>
      <div className="analyses__item-list-tags">
        <AntTooltip title={t('quickfix:editButtonTooltip')} hide={!!confirmedBase}>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleApplicationSelect(confirmedBase!.application_id);
            }}
            size="small"
            disabled={!confirmedBase}
          >
            {t('quickfix:editApplication')}
          </Button>
        </AntTooltip>
      </div>
    </List.Item>
  );
};

export default QuickfixVehicleRow;
