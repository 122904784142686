import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

export type AppModalState = {
  readonly modalType: string | undefined;
  readonly modalProps: { [key: string]: any };
  readonly showUpgradePlanNotification: boolean;
};

const initialState: AppModalState = {
  modalType: undefined,
  modalProps: {},
  showUpgradePlanNotification: false,
};

const reducer: Reducer<AppModalState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case 'HIDE_MODAL':
      return initialState;
    case 'SHOW_UPGRADE_NOTIFICATION':
      return {
        ...state,
        showUpgradePlanNotification: true,
      };
    case 'HIDE_UPGRADE_NOTIFICATION':
      return {
        ...state,
        showUpgradePlanNotification: false,
      };

    default:
      return state;
  }
};

export default reducer;
