import { FastField, Field, FieldProps } from 'formik';
import { Form, Switch } from 'antd';
import React from 'react';
import { SwitchSize } from 'antd/lib/switch';

type FormSwitchProps = {
  name: string;
  checkedChildren?: string;
  unCheckedChildren?: string;
  size?: SwitchSize;
  disabled?: boolean;
  fastField?: boolean;
  handleChange?: (checked: boolean) => void;
};

const FormSwitch: React.FC<FormSwitchProps> = ({
  name,
  checkedChildren,
  unCheckedChildren,
  size,
  disabled,
  fastField,
  handleChange,
}) => {
  const fieldContent = ({ field, form }: FieldProps) => {
    const { setFieldValue } = form;
    return (
      <Form.Item>
        <Switch
          disabled={disabled}
          checked={field.value}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          onChange={checked =>
            handleChange ? handleChange(checked) : setFieldValue(name, checked)
          }
          size={size}
        />
      </Form.Item>
    );
  };

  return fastField ? (
    <FastField name={name}>{fieldContent}</FastField>
  ) : (
    <Field name={name}>{fieldContent}</Field>
  );
};

export default FormSwitch;
