import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncDispatch } from '../../../../types/global';
import { selectVcdbs } from '../../../actions/items/application/update';
import { ApplicationState } from '../../../reducers';
import AntMultiSelect from '../../global/AntMultiSelect';

type ApplicationVcdbSelectProps = {
  onChange: (selectedIds: number[]) => void;
};

const ApplicationVcdbSelect: React.FC<ApplicationVcdbSelectProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { vcdbVersions, selectedVcdbs } = useSelector((state: ApplicationState) => {
    return {
      vcdbVersions: state.items.application.vcdbVersions,
      selectedVcdbs: state.items.application.selectedVcdbs,
    };
  });

  const handleChange = async (selectedVcdbs: number[]) => {
    await dispatch(selectVcdbs(selectedVcdbs));
    onChange(selectedVcdbs);
  };

  return (
    <AntMultiSelect
      className="application__vcdb-select"
      placeholder={t('application:vcdbPlaceholder')}
      elements={vcdbVersions}
      selectedElements={selectedVcdbs}
      onChange={handleChange}
    />
  );
};

export default ApplicationVcdbSelect;
