import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Card } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { articles, url as helpCenterUrl } from '../constants/HelpCenterConstants.json';
import translation from '../constants/HelpCenterTranslations.json';
import { tutorialIds } from '../constants/IntercomTours.json';
import { Indexable } from '../../types/global';
import { startIntercomTour } from '../utils/IntercomUtils';

const helpCenterArticles: Indexable = articles;
const helpCenterAreas: Indexable = translation.areas;
const tutorials: Indexable = tutorialIds;

export const hasHelpCenterEntry = (id: string) =>
  helpCenterArticles[id] || tutorials[id] || helpCenterAreas[id];

type HelpCenterProps = {
  id?: string;
  className?: string;
  placement?: TooltipPlacement;
  content?: JSX.Element;
  popoverWidth?: number;
};

export default class HelpCenter extends React.Component<HelpCenterProps> {
  activateTour = (tourId: number) => {
    startIntercomTour(tourId);
  };

  render() {
    const { id, className = '', placement, content, popoverWidth } = this.props;
    const text = id ? ReactHtmlParser(helpCenterAreas[id]) : undefined;
    const helpPagePath = id ? helpCenterArticles[id] : undefined;
    const tourId = id ? tutorials[id] : undefined;

    const actions = [
      tourId && (
        <Button
          className="help__button"
          onClick={e => {
            e.stopPropagation();
            this.activateTour(tourId);
          }}
          htmlType="button"
        >
          {translation.watch_tutorial}
        </Button>
      ),
      helpPagePath && (
        <Button
          className={`${className} help__button`}
          onClick={e => {
            e.stopPropagation();
            const wnd = window.open(helpCenterUrl + helpPagePath, '_blank', 'noopener,noreferrer');
            if (wnd) wnd.opener = null;
          }}
          htmlType="button"
        >
          {translation.read_help}
        </Button>
      ),
    ].filter(x => !!x);

    return helpPagePath || tourId || text || content ? (
      <Popover
        placement={placement || 'right'}
        overlayStyle={{ width: popoverWidth || '20em' }}
        overlayClassName="help"
        content={<Card actions={actions}>{content || text}</Card>}
        trigger="click"
      >
        <QuestionCircleOutlined
          onClick={e => e.stopPropagation()}
          className={classNames('help__icon', className)}
        />
      </Popover>
    ) : null;
  }
}
