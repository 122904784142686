export enum SharedAction {
  DOWNLOAD_FILE_FROM_URL = 'DOWNLOAD_FILE_FROM_URL',
}

export function downloadFileFromUrl(fileUrl: string) {
  // @ts-ignore
  if (window.Cypress) return;
  const link = document.createElement('a');
  link.href = fileUrl;
  // Without this the pdf would be opened in a new tab, we want to download it.
  if (fileUrl.includes('.pdf') || fileUrl.includes('.csv')) {
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
  }
  link.click();
  return {
    type: SharedAction.DOWNLOAD_FILE_FROM_URL,
  };
  // TODO: This would be the cleaner solution, but is currently not possible due to CORS issues.
  // return {
  //  type: SharedAction.DOWNLOAD_FILE_FROM_URL,
  //  payload: axios.get(fileUrl),
  // }
}
