import { EditOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ChannelTableColProductSyncProps = {
  checked?: boolean;
  handleCheckboxChange?: (checked: boolean) => void;
  handleOnClick?: () => void;
  displayCheckbox?: boolean;
  disabled?: boolean;
};

const ChannelTableColProductSync: React.FC<ChannelTableColProductSyncProps> = props => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row channel-product-sync">
      {props.displayCheckbox && (
        <Checkbox
          checked={props.checked}
          onChange={e => props.handleCheckboxChange!(e.target.value)}
          className="mr-2"
          disabled={props.disabled}
        />
      )}
      {props.handleOnClick && (
        <Button
          onClick={() => (props.handleOnClick ? props.handleOnClick() : '')}
          size="small"
          type="text"
          icon={<EditOutlined />}
          disabled={props.disabled}
        >
          {t('channel:productData')}
        </Button>
      )}
    </div>
  );
};

export default ChannelTableColProductSync;
