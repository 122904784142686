import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Button extends React.Component {
  handleOnClick = () => {
    if (this.props.disabled || this.props.loading) {
      return;
    }
    this.props.onClick();
  };

  render() {
    const { name, color, hover, size, className, disabled, dashed, inverted, loading } = this.props;
    const buttonCss = classNames('button-pdm', color, size, className, {
      hover: hover && !loading, // during loading no hover
      disabled,
      dashed,
      inverted,
      loading,
      'hover-blue': color === 'white',
    });
    return (
      <div className={buttonCss} onClick={this.handleOnClick}>
        <span className="button__name">{name}</span>
        {loading && (
          <div className="button__loader">
            <div />
            <div />
            <div />
          </div>
        )}
      </div>
    );
  }
}

Button.propTypes = {
  // onClick a function is called
  onClick: PropTypes.func.isRequired,
  // this is the name what will be shown in the tag
  name: PropTypes.string.isRequired,
  // the color name as string
  color: PropTypes.string,
  // should there be a hover effect
  hover: PropTypes.bool,
  // size can be small, medium, big
  size: PropTypes.string,
  // button will be disabled
  disabled: PropTypes.bool,
  // button border dotted
  dashed: PropTypes.bool,
  // invert the color
  inverted: PropTypes.bool,
};

Button.defaultProps = {
  color: 'grey',
  hover: false,
  size: '',
  disabled: false,
  dashed: false,
  inverted: false,
};

export default Button;
