import React from 'react';
import Dropdown from '../../../global/Dropdown';
import { placeholder } from '../../../../constants/DigitalAssetsTranslation.json';

class AssetType extends React.Component {
  render() {
    const commonTypes = this.props.resources.types.slice(0, 4);
    return (
      <div className="digital-assets__asset-type">
        <span className="digital-assets__asset-type-title">{placeholder.asset_type}</span>

        <div className="digital-assets__asset-type-selection">
          <div className="digital-assets__asset-type-label-wrapper">
            {commonTypes.map(type => {
              const classN =
                type.id === this.props.assetTypeId
                  ? 'digital-assets__asset-type-label_selected'
                  : 'digital-assets__asset-type-label';
              return (
                <span
                  key={type.id}
                  className={classN}
                  onClick={() => this.props.setFileUploadInformation('assetTypeId', type.id)}
                >
                  {type.name}
                </span>
              );
            })}
          </div>
          <div className="form-field">
            <span className="form-field-name">{placeholder.more_types}</span>
            <Dropdown
              noSelection
              className="form-field-content"
              selectedElement={this.props.assetTypeId}
              elements={this.props.resources.types}
              onChange={selected =>
                this.props.setFileUploadInformation('assetTypeId', Number(selected))
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AssetType;
