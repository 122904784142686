import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchScriptEvents, fetchScripts } from '../../actions/distribution_apisettings';
import ApiAutomationPage from '../../components/body/distribution/ApiAutomationPage';
import { withContainerWrapper } from '../ContainerWrapper';

const APIAutomationContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchScripts());
    dispatch(fetchScriptEvents());
  }, [dispatch]);

  return <ApiAutomationPage />;
};

export default withContainerWrapper(APIAutomationContainer);
