import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Badge, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  MarketingCopy,
  MarketingCopyCodeId,
  MarketingCopySubCodeId,
} from '../../../../types/marketing_copy';
import noImage from '../../../../images/icons/no-image.svg';
import DescriptionsList from '../../global/DescriptionsList';
import marketingCopyTranslation from '../../../constants/MarketingCopyTranslation.json';
import { fillInPlaceholders } from '../../../utils/String';

type MarketingCopyPreviewProps = {
  marketingCopy: MarketingCopy;
  handleOpenMarketingCopyDrawer: ({ bool, id }: { bool: boolean; id: number }) => void;
  handleDeleteMarketingCopy?: (marketingCopyId: number) => void;
  showDelete?: boolean;
  showUnlink?: boolean;
  showSubCode?: boolean;
  showItemCount?: boolean;
  size?: 'small' | 'large' | undefined;
  unLinkMarketingCopy?: () => void;
  fetchingItemCount?: boolean;
};

const MarketingCopyPreview: React.FC<MarketingCopyPreviewProps> = props => {
  const { t } = useTranslation();
  const {
    id,
    code_id,
    reference_code_name,
    mapped_items,
    sub_code_id,
    reference_sub_code_name,
    contents,
    assets,
  } = props.marketingCopy;

  const getLevelName = () => {
    switch (code_id) {
      case MarketingCopyCodeId.PARENT: {
        return 'Parent';
      }
      case MarketingCopyCodeId.OWNER: {
        return 'Owner';
      }
      case MarketingCopyCodeId.BRAND: {
        return 'Brand';
      }
      case MarketingCopyCodeId.SUB_BRAND: {
        return 'Subbrand';
      }
    }
  };

  const handleError = (event: any) => (event.target.src = noImage);

  return (
    <Card
      className="marketing-copy-preview"
      title={
        <div className="flex justify-between">
          <div className="flex">
            <img
              src={
                (assets && assets[0].asset_preview_url) ||
                (assets && assets[0].asset_url) ||
                noImage
              }
              onError={event => handleError(event)}
              className="marketing-copy-image"
              alt=""
            />
            {props.showSubCode ? (
              <div>
                <span>
                  {sub_code_id === MarketingCopySubCodeId.GROUP && t('description:group')}
                  {sub_code_id === MarketingCopySubCodeId.SUB_GROUP && t('description:subGroup')}
                  {sub_code_id === MarketingCopySubCodeId.PART_TYPE && t('description:partType')}
                </span>
                <br />
                <span className="marketing-copy-preview__title">{reference_sub_code_name}</span>
              </div>
            ) : (
              <div>
                <span>{getLevelName()}:</span>
                <br />
                <span className="marketing-copy-preview__title">{reference_code_name}</span>
              </div>
            )}
          </div>
          <div>
            {!props.fetchingItemCount && props.showItemCount && (
              <Tooltip
                title={fillInPlaceholders(marketingCopyTranslation.placeholder.linkedInfo, {
                  mappedItems: mapped_items ? mapped_items.toString() : '0',
                })}
              >
                <Badge className="ant-blue-batch" overflowCount={999999} count={mapped_items} />
              </Tooltip>
            )}
            {props.fetchingItemCount && <Spin size="small" />}
          </div>
        </div>
      }
      size="small"
    >
      <DescriptionsList colNumber={2} layout="vertical">
        <DescriptionsList.Item label="Part Type">
          <span>
            {sub_code_id === MarketingCopySubCodeId.PART_TYPE ? reference_sub_code_name : '-'}
          </span>
        </DescriptionsList.Item>
        <DescriptionsList.Item label="Group">
          <span>
            {sub_code_id === MarketingCopySubCodeId.GROUP ||
            sub_code_id === MarketingCopySubCodeId.SUB_GROUP
              ? reference_sub_code_name
              : '-'}
          </span>
        </DescriptionsList.Item>
        <DescriptionsList.Item label="Marketing Copy" fullWidth>
          <span>
            {contents.length
              ? contents.map(c => (
                  <span key={c.id}>
                    {c.type_id === 1 ? <span>&#8226; {c.content}</span> : c.content} <br />
                  </span>
                ))
              : '-'}
          </span>
        </DescriptionsList.Item>
      </DescriptionsList>
      <div className="flex justify-between" style={{ marginTop: 20 }}>
        <Button
          icon={<EditOutlined />}
          type="primary"
          ghost
          onClick={() => props.handleOpenMarketingCopyDrawer({ bool: true, id })}
          style={{ marginRight: 5, width: '50%' }}
          size={props.size}
        >
          {marketingCopyTranslation.actions.edit}
        </Button>
        {props.showDelete && (
          <Button
            className="flex-1"
            icon={<DeleteOutlined />}
            danger
            ghost
            onClick={() => props.handleDeleteMarketingCopy && props.handleDeleteMarketingCopy(id)}
            style={{ marginLeft: 5 }}
            size={props.size}
          >
            {marketingCopyTranslation.actions.delete}
          </Button>
        )}
        {props.showUnlink && (
          <Button
            className="flex-1"
            danger
            ghost
            onClick={() => props.unLinkMarketingCopy && props.unLinkMarketingCopy()}
            style={{ marginLeft: 5 }}
            size={props.size}
          >
            {t('description:unlink')}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default MarketingCopyPreview;
