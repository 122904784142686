import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { AcesPiesHeaderSettings } from '../../../types/xml_header_settings';

export type XmlHeadersState = {
  acesPiesHeaders: AcesPiesHeaderSettings[];
  fetchingHeaders: boolean;
};

const initialState = {
  acesPiesHeaders: [],
  fetchingHeaders: false,
};

const reducer: Reducer<XmlHeadersState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_BRAND_ACES_PIES_XML_HEADERS_PENDING':
      return {
        ...state,
        fetchingHeaders: true,
      };
    case 'FETCH_BRAND_ACES_PIES_XML_HEADERS_FULFILLED':
      return {
        ...state,
        fetchingHeaders: false,
        acesPiesHeaders: action.payload.data,
      };
    case 'UPDATE_ACES_PIES_XML_HEADERS_FULFILLED':
      return {
        ...state,
        acesPiesHeaders: action.payload.data,
      };
  }
  return state;
};

export default reducer;
