import React from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'lodash';
import { ApplicationState } from '../../reducers';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/application';
import ApplicationQualifiers from '../../components/body/application/ApplicationQualifiers';
import * as utils from '../../utils/Utils';
import { AsyncDispatch } from '../../../types/global';
import { StandardResource } from '../../../types/resources';
import { ApplicationNote, ListQualifier, Qualifier } from '../../../types/application';

type DefaultQualifierContainerProps = {
  dispatch: AsyncDispatch;
  itemId: number;
  defaultQualifiers: Qualifier[];
  notes: ApplicationNote[];
  qualifiers: ListQualifier[];
  resources: any;
  qualifierMetaUoms: StandardResource[];
  fetching: boolean;
  deleteDefaultQualifier: (id: number, recordNumber?: number) => void;
  updateDefaultQualifier: (qualifier: any) => void;
  duplicateDefaultQualifier: (qualifier: any) => void;
  clearTempState: () => void;
  addNote: (noteValue?: string) => void;
  removeNote: (recordNumber: number) => void;
  updateNote: (recordNumber: number, noteValue: string) => void;
};

class DefaultQualifierContainer extends React.Component<DefaultQualifierContainerProps> {
  componentDidMount() {
    this.fetchQualifierData(this.props.itemId);
  }

  fetchQualifierData = (itemId: number) => {
    this.props.dispatch(actions.fetchQualifiers(itemId));
  };

  fetchQualifiers = (keywords: string, typeId?: number, includesValues?: boolean) => {
    const { itemId } = this.props;
    this.props.dispatch(actions.fetchQualifiers(itemId, keywords, typeId, includesValues));
    this.props.clearTempState();
  };

  fetchNextQualifiers = (
    event: React.UIEvent,
    keywords: string,
    typeId?: number,
    includesValues?: boolean
  ) => {
    const { itemId } = this.props;
    const nextPage = utils.nextPage(event, undefined, this.props.qualifiers.length);
    if (nextPage && !this.props.fetching) {
      this.props.dispatch(
        actions.fetchQualifiers(itemId, keywords, typeId, includesValues, nextPage)
      );
    }
  };

  updateQualifiers = (qualifierId: number, values: any = [], recordNumber?: number) => {
    const { defaultQualifiers, qualifiers } = this.props;
    const defaultQualifier = defaultQualifiers.find(
      q => q.qualifier_id === qualifierId && q.record_number === recordNumber
    );
    if (!defaultQualifier) {
      const qualifier = qualifiers.find(qualifier => qualifier.id === qualifierId);
      const updatedQualifier = { ...qualifier, temp: true, values, qualifier_id: qualifier!.id };
      this.props.updateDefaultQualifier(updatedQualifier);
    } else {
      this.props.updateDefaultQualifier({ ...defaultQualifier, values });
    }
  };

  duplicateQualifier = (qualifierId: number) => {
    const { defaultQualifiers } = this.props;

    const recordNumbers = defaultQualifiers
      .filter(qualifier => qualifier.qualifier_id === qualifierId)
      .map(qualifier => qualifier.record_number)
      .filter(isNumber);
    const recordNumber = recordNumbers.length ? Math.max(...recordNumbers) : 1;
    const defaultQualifier = defaultQualifiers.find(q => q.qualifier_id === qualifierId);

    this.props.duplicateDefaultQualifier({
      ...defaultQualifier,
      values: [],
      record_number: recordNumber + 1,
    });
  };

  removeUsedQualifiers = (qualifierId: number, recordNumber?: number) => {
    this.props.deleteDefaultQualifier(qualifierId, recordNumber);
  };

  render() {
    return (
      <div className="application__default-qualifiers">
        <ApplicationQualifiers
          qualifiers={this.props.qualifiers}
          qualifierTypes={this.props.resources.qualifier_types}
          qualifierMetaUoms={this.props.qualifierMetaUoms}
          selectedQualifiers={this.props.defaultQualifiers}
          notes={this.props.notes}
          fetching={this.props.fetching}
          fetchQualifiers={this.fetchQualifiers}
          fetchNextQualifiers={this.fetchNextQualifiers}
          updateQualifiers={this.updateQualifiers}
          removeUsedQualifiers={this.removeUsedQualifiers}
          duplicateQualifier={this.duplicateQualifier}
          addNote={this.props.addNote}
          removeNote={this.props.removeNote}
          updateNote={this.props.updateNote}
        />
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    brandId: state.parent.brands.selectedBrandId,
    resources: state.resources.data.vehicle,
    qualifierMetaUoms: state.resources.data.application.qualifier_meta_uoms,
    qualifiers: state.items.application.qualifiers,
    fetching: state.items.application.fetchingQualifiers,
  };
}

export { DefaultQualifierContainer };
export default connect(mapStateToProps)(withContainerWrapper(DefaultQualifierContainer));
