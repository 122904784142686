import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteDescription(descriptionId: number) {
  return {
    type: 'DELETE_DESCRIPTION',
    meta: { descriptionId },
    payload: axios.delete(`${getApiRoot()}/items/descriptions/${descriptionId}`),
  };
}
