import { uniqBy as _uniqBy } from 'lodash';
import { createSelector } from 'reselect';

const getItems = state => state.catalogue.catalogue.items;
const getSelectedItemIds = state => state.catalogue.catalogue.selectedItemIds;
const getCachedItems = state => state.catalogue.catalogue.cachedItems;

export const getSelectedItems = createSelector(
  [getItems, getSelectedItemIds, getCachedItems],
  (items, selectedItemIds, cachedItems) => {
    const allItems = _uniqBy([...items, ...cachedItems], 'id');
    return allItems.filter(item => selectedItemIds.includes(item.id));
  }
);
