import { Menu } from 'antd';
import React from 'react';
import { UserPermissionRole } from '../../../../types/user';

type RoleDrawerMenuProps = {
  roles: UserPermissionRole[];
  noSelection: boolean;
  handleClick: (id: number) => void;
};

const RoleDrawerMenu: React.FC<RoleDrawerMenuProps> = ({ roles, noSelection, handleClick }) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(
    roles.length > 0 ? [roles[0].id.toString()] : []
  );

  return (
    <Menu
      className="overflow-auto overflow-x-hidden flex-1"
      onClick={({ key }) => handleClick(Number(key))}
      selectedKeys={noSelection ? [] : selectedKeys}
      onSelect={({ key }) => setSelectedKeys([key.toString()])}
      mode="inline"
    >
      {roles.map(role => (
        <Menu.Item key={role.id}>{role.name}</Menu.Item>
      ))}
    </Menu>
  );
};

export default RoleDrawerMenu;
