import React from 'react';
import { useTranslation } from 'react-i18next';
import clockImage from '../../../images/icons/register-clock.svg';
import useGoogleAnalytics from '../../containers/GoogleAnalyticsTracker';
import LoginFooter from '../LoginFooter';

const VerifyAccountPage: React.FC = () => {
  const { t } = useTranslation();
  useGoogleAnalytics();

  const [hours, setHours] = React.useState(23);
  const [minutes, setMinutes] = React.useState(59);
  const [seconds, setSeconds] = React.useState(59);

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) clearInterval(myInterval);
          else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className="login__background">
      <div className="login__box">
        <div className="login__box-header">
          <div className="login-logo" />
          <div className="login__box-content">
            <div className="login--header-content">
              <img src={clockImage} alt={t('login:success')} />
              <div className="login__title mt-3">{t('login:success')}</div>
            </div>
            <div className="login__box-login">
              <div className="text-base">
                {t('login:verifyAccount')}

                <div className="mt-7 text-blue-600">
                  {minutes === 0 && seconds === 0 ? null : (
                    <h1>
                      {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default VerifyAccountPage;
