import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

type ReceiverBrandActionsProps = {
  disabled: boolean;
  handleApprove: () => void;
  handleRequest: () => void;
  handleReject: () => void;
};

const ReceiverBrandActions: React.FC<ReceiverBrandActionsProps> = ({
  disabled,
  handleApprove,
  handleRequest,
  handleReject,
}) => {
  const { t } = useTranslation();

  const items: MenuProps['items'] = [
    {
      key: 'request',
      label: t('receiver:request'),
      onClick: () => handleRequest(),
    },
    {
      key: 'reject',
      label: t('receiver:reject'),
      onClick: () => handleReject(),
    },
    {
      key: 'approve',
      label: t('receiver:approve'),
      onClick: () => handleApprove(),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']} disabled={disabled}>
      <Button size="small">
        {t('common:actions')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ReceiverBrandActions;
