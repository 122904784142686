import React from 'react';
import Retool from 'react-retool';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Page from '../../global/page/Page';

type RetoolPageProps = {
  appName: string;
  accountId: number;
  embedUrl?: string;
  brandCode?: string;
  partNumber?: string;
  changeApp: (name: string) => void;
};

const RetoolPage: React.FC<RetoolPageProps> = ({
  appName,
  embedUrl,
  accountId,
  brandCode,
  partNumber,
  changeApp,
}) => {
  const { t } = useTranslation();

  const [appNameInput, setAppNameInput] = React.useState(appName);

  return (
    <Page
      renderHeader={
        <div className="pl-5 flex">
          <span className="min-w-max mr-2"> {`${t('retool:appName')}:`}</span>
          <Input
            className="retool__app-input"
            value={appNameInput}
            onChange={e => setAppNameInput(e.target.value)}
            placeholder={t('retool:appName')}
            size="small"
          />
          <Button type="primary" ghost onClick={() => changeApp(appNameInput)} size="small">
            {t('retool:changeApp')}
          </Button>
        </div>
      }
      showExpandButton
    >
      <div className="retool">
        {embedUrl && (
          <Retool
            url={embedUrl}
            data={{
              account_id: accountId,
              ...(brandCode && { brand_code: brandCode }),
              ...(partNumber && { part_number: partNumber }),
            }}
          />
        )}
      </div>
    </Page>
  );
};

export default RetoolPage;
