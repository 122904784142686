import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { Note } from '../../../../types/interchange';

export function updateInterchangeItem(infoId: number, argument: any, notes: Note) {
  const paramObject = Object.keys(argument)
    .filter(key => !(key === 'notes' || key === 'internal_notes'))
    .map((key: string) => ({
      name: key,
      values: argument[key],
      allowEmpty: true,
    }));
  const params = utils.createURL(paramObject);

  return {
    type: 'UPDATE_INTERCHANGE_ITEM',
    meta: { infoId },
    payload: axios.patch(
      `${getApiRoot()}/items/part_interchange_informations/${infoId}${params}`,
      notes
    ),
  };
}
