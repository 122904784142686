import React from 'react';
import { Button, Col, Form, Modal, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import FormMultiCheckbox from '../../global/Forms/FormMultiCheckbox';
import AntTooltip from '../../global/AntTooltip';

type CopyEBPTemplateModalProps = {
  showModal: boolean;
  isReceiver?: boolean;
  onCancel: () => void;
  onClose: () => void;
  handleCopy: (name: string, columnIds: number[], brandIds?: number[]) => Promise<any>;
};

const CopyEBPTemplateModal: React.FC<CopyEBPTemplateModalProps> = props => {
  const { t } = useTranslation();

  const { templateColumns, brands, brandId } = useSelector((state: ApplicationState) => ({
    templateColumns: state.brand.exportBuilderAdvanced.templateColumns,
    brands: state.parent.brands.brands,
    brandId: state.parent.brands.selectedBrandId,
  }));

  return (
    <Modal
      open={props.showModal}
      onCancel={() => props.onCancel()}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <Formik
        initialValues={{
          name: '',
          selectedColumnIds: templateColumns.map(c => c.id),
          selectedBrandIds: !props.isReceiver ? [brandId] : [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(t('validation:required')),
          selectedColumnIds: Yup.array().of(Yup.number()).min(1, t('validation:required')),
          selectedBrandIds: !props.isReceiver
            ? Yup.array().of(Yup.number()).min(1, t('validation:required'))
            : Yup.array().of(Yup.number()),
        })}
        onSubmit={async (values, formikActions) => {
          const { setSubmitting } = formikActions;
          setSubmitting(true);
          await props.handleCopy(values.name, values.selectedColumnIds, values.selectedBrandIds);
          setSubmitting(false);
          props.onClose();
        }}
      >
        {({ handleSubmit, setFieldValue, dirty, isSubmitting, values }) => {
          return (
            <div>
              <div className="text-3xl mb-7">{t('exportBuilderAdvanced:copyTemplate')}</div>
              <Form layout="vertical" className="ebp__copy-template-form">
                <FormInput
                  name="name"
                  label={
                    <React.Fragment>
                      {t('exportBuilderAdvanced:newName')}
                      {props.isReceiver && (
                        <AntTooltip
                          title={<div>{t('exportBuilderAdvanced:copyInfoTextReceiver')}</div>}
                          className="self-center pl-2 pr-2"
                        >
                          <InfoCircleOutlined className="ant-info-tooltip" />
                        </AntTooltip>
                      )}
                    </React.Fragment>
                  }
                  required
                />
                {!props.isReceiver && (
                  <FormMultiSelect
                    className="ebp__copy-brand-select"
                    label={
                      <React.Fragment>
                        {t('exportBuilderAdvanced:copyToBrands')}
                        <AntTooltip
                          title={<div>{t('exportBuilderAdvanced:copyInfoText')}</div>}
                          className="self-center pl-2 pr-2"
                        >
                          <InfoCircleOutlined className="ant-info-tooltip" />
                        </AntTooltip>
                      </React.Fragment>
                    }
                    name="selectedBrandIds"
                    values={brands}
                    allowClear
                    required
                  />
                )}
                <Row>
                  <Col span={18}>
                    <FormMultiCheckbox
                      label={t('exportBuilderAdvanced:columns')}
                      name="selectedColumnIds"
                      values={templateColumns}
                      required
                    />
                  </Col>
                  <Col span={6}>
                    <div className="flex justify-end items-center ml-2">
                      <div className="mr-2">{t('common:selectAll')}</div>
                      <Switch
                        size="small"
                        checked={
                          templateColumns.length > 0 &&
                          values.selectedColumnIds.length === templateColumns.length
                        }
                        onChange={checked => {
                          const ids = templateColumns.map(c => c.id);
                          if (checked) setFieldValue(`selectedColumnIds`, ids);
                          if (!checked) setFieldValue(`selectedColumnIds`, []);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>

              <div className="flex-col items-end mt-7">
                <div>
                  <Button onClick={() => props.onCancel()} className="drawer-submit__bottom-cancel">
                    {t('common:cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    disabled={!dirty || isSubmitting}
                    loading={isSubmitting}
                  >
                    {t('exportBuilderAdvanced:copyAndReplace')}
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CopyEBPTemplateModal;
