import React from 'react';
import { connect } from 'react-redux';

import { withContainerWrapper } from '../ContainerWrapper';
import {
  fetchSyncLog,
  fetchOutOfSyncItems,
  fetchOutOfSyncItemsCount,
} from '../../actions/brand/synchronisation/index.ts';

class SynchronisationManager extends React.Component {
  poller;

  componentDidUpdate(prevProps) {
    if (!prevProps.isSyncing && this.props.isSyncing) {
      this.startLogPolling();
    }
    if (prevProps.isSyncing && !this.props.isSyncing) {
      this.endLogPolling();
    }
  }

  componentWillUnmount() {
    clearInterval(this.poller);
  }

  startLogPolling = () => {
    this.pollCount = 1;
    this.skipPollCount = 1;
    this.poller = setInterval(() => {
      // skip poll by variable to allow dynamic intervals
      if (!(this.pollCount % this.skipPollCount)) this.fetchSyncLog();
      // increase poll interval from 5s to 30s every 5m
      if (!(this.pollCount % 60) && this.skipPollCount < 6) this.skipPollCount++;
      this.pollCount++;
    }, 5000);
  };

  fetchSyncLog = () => {
    if (this.props.brandId) {
      this.props.dispatch(fetchSyncLog(this.props.brandId));
    }
  };

  endLogPolling = () => {
    clearInterval(this.poller);
    this.props.dispatch(fetchOutOfSyncItems(this.props.brandId));
    this.props.dispatch(fetchOutOfSyncItemsCount(this.props.brandId));
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    brandId: state.parent.brands.selectedBrandId,
    user: state.user.user,
    isSyncing: state.brand.synchronisation.isSyncing,
  };
};

export default connect(mapStateToProps)(withContainerWrapper(SynchronisationManager));
