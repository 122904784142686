import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import classNames from 'classnames';

const YearRangeFilter: React.FC<CustomFilterProps> = ({
  model,
  onModelChange,
  getValue,
  colDef,
}) => {
  const { t } = useTranslation();

  const [selectedYears, setSelectedYears] = useState<number[]>(
    model?.values.map((y: string) => Number(y)) || []
  );
  const [keywords, setKeywords] = useState<string>('');
  const [lastClicked, setLastClicked] = useState<number | null>(null);
  const [years, setYears] = useState<number[]>(
    colDef.filterParams
      .values()
      .map((y: any) => y.name)
      .sort()
  );

  React.useEffect(() => {
    if (model?.values) {
      setSelectedYears(model?.values.map((y: string) => Number(y)));
    }
  }, [model]);

  const doesFilterPass = useCallback(
    ({ node }) => {
      return getValue(node).contains(model);
    },
    [getValue, model]
  );

  const getModelAsString = useCallback(() => {
    const selectedYears = model.values.filter((y: string) => years.includes(Number(y)));
    return model == null ? '' : `(${selectedYears.length}) ${selectedYears.join(',')}`;
  }, [model, years]);

  const afterGuiAttached = useCallback(
    (params: IAfterGuiAttachedParams) => {
      setYears(
        colDef.filterParams
          .values()
          .map((y: any) => y.name)
          .sort()
      );
    },
    [colDef.filterParams]
  );

  // register filter callbacks with the grid
  useGridFilter({
    doesFilterPass,
    getModelAsString,
    afterGuiAttached,
  });

  const handleYearChange = (year: number, isChecked: boolean, shiftKey: boolean) => {
    let newYearSelection = selectedYears;
    if (shiftKey && lastClicked !== null && isChecked) {
      const range = [year, lastClicked].sort((a, b) => a - b);
      const shiftSelectedYears = years.filter((y: number) => y >= range[0] && y <= range[1]);
      newYearSelection = [...new Set([...selectedYears, ...shiftSelectedYears])];
    } else {
      if (isChecked) {
        newYearSelection = [...selectedYears, year];
      } else {
        newYearSelection = selectedYears.filter(y => y !== year);
      }
      setLastClicked(year);
    }

    setSelectedYears(newYearSelection);

    const updatedModel = { ...model, values: newYearSelection.map(y => y.toString()) };

    onModelChange(newYearSelection.length > 0 ? updatedModel : null);
  };

  const isYearSelected = (year: number) => {
    return selectedYears.includes(year);
  };

  const filteredYears = useMemo(
    () => (keywords ? years.filter((y: number) => y.toString().includes(keywords)) : years),
    [keywords, years]
  );

  return (
    <form className="ag-filter-wrapper">
      <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper">
        <div className="ag-set-filter">
          <div className="ag-mini-filter">
            <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
              <input
                className="ag-input-field-input ag-text-field-input"
                value={keywords}
                onChange={e => setKeywords(e.target.value)}
                placeholder={`${t('common:search')}...`}
              />
            </div>
          </div>
          <div className="ag-set-filter-list">
            <div className="overflow-y-scroll h-full">
              <div>
                {filteredYears.map((year: any) => (
                  <div key={year} className="ag-set-filter-item" style={{ height: '24px' }}>
                    <div
                      className={classNames(
                        'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper',
                        { 'ag-checked': isYearSelected(year) }
                      )}
                    >
                      <input
                        className="ag-input-field-input ag-checkbox-input"
                        type="checkbox"
                        checked={isYearSelected(year)}
                        onChange={e => {
                          // @ts-ignore
                          handleYearChange(year, e.target.checked, e.nativeEvent.shiftKey);
                        }}
                      />
                    </div>
                    <div className="ag-input-field-label ag-label ag-checkbox-label ag-label-ellipsis ml-1">
                      {year}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default YearRangeFilter;
