import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Popover, Card, Button, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ApplicationElement } from '../../../../types/all_application_validation';
import { createApplicationToPartNumber } from '../../../actions/all_applications/create';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';

type PartNumberPopoverProps = {
  applicationRows: ApplicationElement[];
  onClose?: () => void;
};

const PartNumberPopover: React.FC<PartNumberPopoverProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [partNumber, setPartNumber] = React.useState('');
  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const brandId = useSelector((state: ApplicationState) => state.parent.brands.selectedBrandId);
  const creatingApplications = useSelector(
    (state: ApplicationState) => state.allApplications.applicationList.creatingApplications
  );

  const handleClick = () => {
    dispatch(
      createApplicationToPartNumber({
        partNumber,
        brandId,
        applicationElements: props.applicationRows,
      })
    ).then(() => {
      setPopoverVisible(false);
      if (props.onClose) props.onClose();
    });
  };

  return (
    <Tooltip
      title={t('applicationValidation:addPartNumber', { count: props.applicationRows.length })}
    >
      <Popover
        open={popoverVisible}
        onOpenChange={visible => {
          if (visible && !props.applicationRows.length) setPopoverVisible(false);
          else setPopoverVisible(visible);
        }}
        overlayClassName="help application_listing__popover"
        content={
          <Card
            actions={[
              <Button
                type="primary"
                ghost
                loading={creatingApplications}
                disabled={!partNumber.length}
                onClick={() => handleClick()}
              >
                {t('applicationValidation:add')}
              </Button>,
            ]}
          >
            <React.Fragment>
              <div className="mb-2">
                {t('applicationValidation:addPartNumber', { count: props.applicationRows.length })}
              </div>
              <Input
                value={partNumber}
                onChange={e => setPartNumber(e.target.value)}
                placeholder={t('applicationValidation:partNumber')}
              />
            </React.Fragment>
          </Card>
        }
        trigger="click"
      >
        <PlusCircleOutlined
          className={classNames('application_listing__add-icon ml-1', {
            gray: !props.applicationRows.length,
          })}
        />
      </Popover>
    </Tooltip>
  );
};

export default PartNumberPopover;
