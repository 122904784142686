import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';

import catalogueTranslations from '../../../constants/CatalogueTranslations.json';
import { CatalogueResetFilterButton } from './CatalogueResetFilterButton';
import { runningOnGoOnlyApi } from '../../../utils/Permissions';

enum NoProductsState {
  NONE_FOR_FILTER = 'noProductsForFilter',
  NONE_FOR_BRAND = 'noProductsForBrand',
}

export const NoProducts = () => {
  const selectedFilterGo = useSelector(
    (state: ApplicationState) => state.catalogue.filter.filterGo
  );
  const brandItemsSummary = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.brandItemsSummary
  );
  const goOnly = useSelector(
    (state: ApplicationState) => state.user.user && runningOnGoOnlyApi(state.user.user)
  );
  const noProductsState =
    brandItemsSummary === 0 ? NoProductsState.NONE_FOR_BRAND : NoProductsState.NONE_FOR_FILTER;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const watchTutorial = () => {
    // no tutorial available currently
  };

  return (
    <div className="no-products">
      <h3 className="no-products__title">
        {(catalogueTranslations as any)[noProductsState].title}
      </h3>
      <div className="no-products__desc">
        {(catalogueTranslations as any)[noProductsState].desc}
      </div>
      {noProductsState === NoProductsState.NONE_FOR_BRAND ? (
        // <Button
        //   type="primary"
        //   size="small"
        //   className="no-products__button"
        //   onClick={() => watchTutorial()}
        // >
        //   {(catalogueTranslations as any)[noProductsState].button}
        // </Button>
        <div />
      ) : (
        <CatalogueResetFilterButton
          className="no-products__button"
          selectedFilterGo={selectedFilterGo}
          goOnly={goOnly}
          showText
        />
      )}
    </div>
  );
};
