import axios from 'axios';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { downloadArrayBuffer } from '../../../utils/Utils';
import {
  AsyncDispatch,
  ThunkAsyncDispatch,
  ThunkAsyncResult,
  ThunkAxiosResult,
} from '../../../../types/global';

export function fetchDigitalAssetDescriptions(digitalAssetId: number) {
  return {
    type: 'FETCH_DIGITAL_ASSET_DESCRIPTION',
    payload: axios.get(`${getApiRoot()}/items/digital_assets/${digitalAssetId}/descriptions`),
  };
}

export function fetchDigitalAssetsTypesSorting(brandId: number) {
  return (dispatch: AsyncDispatch) => {
    dispatch({
      type: 'FETCH_DIGITAL_ASSETS_TYPES_SORTING',
      payload: axios.get(
        `${getNonGwApiRoot()}/parents/owners/brands/${brandId}/digital_asset_types`
      ),
    });
  };
}

export const downloadDigitalAssetsList = (): ThunkAsyncResult => {
  return (dispatch: ThunkAsyncDispatch) => {
    const downloadList = (): ThunkAxiosResult =>
      ({
        type: 'DOWNLOAD_DIGITAL_ASSETS_LIST',
        payload: axios.get(`${getApiRoot()}/parents/digital_assets/s3_files?type=excel`, {
          responseType: 'arraybuffer',
        }),
      } as any);
    return dispatch(downloadList()).then(({ value }: any) => {
      const fileName = `digital-assets_${Date.now()}.xlsx`;
      return downloadArrayBuffer(value.data, fileName);
    });
  };
};
