import React from 'react';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';
import Button from '../global/Button';
import constants from '../../constants/HeaderTranslation.json';
import { BRAND_AREA } from '../../constants/InAppAreaConst.ts';

export default class FilterAnalyses extends React.Component {
  getWarningColor = typeId => {
    if (typeId === 1) return 'blue';
    if (typeId === 2) return 'yellow';
    if (typeId === 3) return 'red';
  };

  segments = () => {
    const { segments } = this.props;
    const filteredSegments = segments.filter(segment => segment.name !== 'ebay');

    return (
      <div className="box box_background pdm-table filter-analyses__segments">
        {filteredSegments.map(segment => {
          const segmentsRowCss = classNames('row', 'filter-analyses_row', {
            'filter-analyses__row_selected': segment.id === this.props.selectedSegmentId,
          });
          return (
            <div
              key={segment.id}
              className={segmentsRowCss}
              onClick={() => this.props.selectSegment(segment.id)}
            >
              <span className="filter-analyses_row-name">{segment.title}</span>
              <Button
                onClick={() => this.props.addSegmentFilter(segment.id)}
                className="row__choose-button"
                name={constants.filter.choose}
                color="blue"
                hover
              />
            </div>
          );
        })}
      </div>
    );
  };

  alertTypes = () => {
    const { alertTypes, selectedAlertTypeId } = this.props;

    return (
      <div className="box box_background pdm-table filter-analyses__alert-types">
        {alertTypes.map(type => {
          const alertTypesRowCss = classNames('row', 'filter-analyses_row', {
            'filter-analyses__row_selected': type.id === selectedAlertTypeId,
          });
          return (
            <div
              key={type.id}
              className={alertTypesRowCss}
              onClick={() => this.props.selectAlertType(type.id)}
            >
              <WarningOutlined className={`${this.getWarningColor(type.id)}`} />
              <span className="filter-analyses_row-name">{type.name}</span>
              <Button
                onClick={() => this.props.addTypeFilter(type.id)}
                className="row__choose-button"
                name={constants.filter.choose}
                color="blue"
                hover
              />
            </div>
          );
        })}
      </div>
    );
  };

  alertGroups = () => {
    const { segments, analysisTypes } = this.props;
    const typeIds = [...new Set(analysisTypes.map(type => type.segment_id))];
    const filteredSegments = segments.filter(segment => typeIds.includes(segment.id));

    return (
      <div className="box box_background pdm-table filter-analyses__alerts">
        {filteredSegments.map(segment => (
          <React.Fragment key={segment.id}>
            <div className="row filter-analyses_row filter-analyses__segment-row">
              {constants.filter_analysis[segment.name]}
            </div>
            {this.alerts(segment.id)}
          </React.Fragment>
        ))}
      </div>
    );
  };

  scorecards = () => {
    const { scorecards, selectedScorecardId, inAppArea } = this.props;
    return (
      <div className="box box_background pdm-table filter-analyses__scorecards">
        {scorecards.map(type => {
          const scoreCardRowCss = classNames('row', 'filter-analyses_row', {
            'filter-analyses__row_selected': type.id === selectedScorecardId,
            disabled: inAppArea === BRAND_AREA && !type.validating_against_scorecard,
          });
          return (
            <div
              key={type.id}
              className={scoreCardRowCss}
              onClick={() => this.props.selectScorecard(type.id)}
            >
              <span className="filter-analyses_row-name">{type.name}</span>
              <Button
                onClick={() => this.props.addScorecardFilter(type.id)}
                className="row__choose-button"
                name={constants.filter.choose}
                color="blue"
                hover
              />
            </div>
          );
        })}
      </div>
    );
  };

  alerts = segmentId => {
    const { analysisTypes, selectedAlertIds, inAppArea } = this.props;
    const segmentAlerts = analysisTypes.filter(alert => alert.segment_id === segmentId);
    const isBrandArea = inAppArea === BRAND_AREA;

    return segmentAlerts.map(alert => {
      const alertRowCss = classNames('row', 'filter-analyses_row', {
        'filter-analyses__row_selected': selectedAlertIds.includes(alert.id),
        disabled: isBrandArea && !alert.isValidating,
      });
      return (
        <div
          key={alert.id}
          className={alertRowCss}
          onClick={() => this.props.addAlertFilter(alert.id)}
        >
          <WarningOutlined className={`${this.getWarningColor(alert.alert_type_id)}`} />
          {alert.name}
          {this.props.fetchingAnalysesAlertItemCount ? (
            <span className="filter__item-count_loader" />
          ) : (
            isBrandArea && <span className="filter__item-count">{alert.item_count}</span>
          )}
        </div>
      );
    });
  };

  render() {
    return (
      <div className="filter__analyses flex">
        <div className="filter-analyses_left">
          <div className="filter-analyses__segments-container">
            <div className="title filter-analyses__title">{constants.filter.segments}</div>
            {this.segments()}
          </div>
        </div>
        <div className="filter-analyses_middle">
          <div className="filter-analyses__alert-types-container">
            <div className="title filter-analyses__title">{constants.filter.alert_types}</div>
            {this.alertTypes()}
          </div>
          <div className="filter-analyses__scorecards-container">
            <div className="title filter-analyses__title">{constants.filter.scorecards}</div>
            {this.scorecards()}
          </div>
        </div>
        <div className="filter-analyses_right">
          <div className="title filter-analyses__title">{constants.filter.alerts}</div>
          {this.alertGroups()}
        </div>
      </div>
    );
  }
}
