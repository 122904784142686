import React from 'react';
import { connect, FormikValues, FormikContextType } from 'formik';
import { message } from 'antd';

type FormErrorFocusProps = {
  formik: FormikContextType<FormikValues>;
};

class FormErrorFocus extends React.Component<FormErrorFocusProps> {
  // note: this approach is only working for input fields only there we can set a name attribute
  componentDidUpdate(prevProps: FormErrorFocusProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      let key = keys[0];
      if (typeof errors[keys[0]] === 'object') {
        const secondlevelObj = errors[keys[0]] as any;
        const secondlevelKeys = Object.keys(secondlevelObj);
        key = `${keys[0]}.${secondlevelKeys[0]}`;
      }
      const selector = `[name="${key}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        // @ts-ignore
        errorElement.focus({ preventScroll: true });
        errorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
        message.error('An error occured. Please check your form inputs.');
      }
    }
  }

  render() {
    return null;
  }
}

export default connect<FormikValues>(FormErrorFocus);
