import React from 'react';
import { connect } from 'react-redux';
import {
  fetchDefaultValues,
  createDefaultPriceTypeDescription,
  updateDefaultValue,
  updateDefaultPriceTypeDescription,
} from '../../actions/settings/defaultValues';
import { fetchPriceSheets } from '../../actions/parent/price_sheets/fetch';
import { setItemFetchRequired } from '../../actions/catalogue/catalogue/update';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import DefaultValues from '../../components/body/settings/DefaultValues';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { VCDB_DEFAULT_VALUE_ID } from '../../constants/DefaultValueConstants';
import { selectVcdbs } from '../../actions/items/application/update';

class SettingsDefaultValuesContainer extends React.Component {
  componentDidMount() {
    this.fetchDefaultValues();

    intercomEvent('viewed-brand-settings', {
      location: 'settings-default-values',
      brand_code: this.props.brandCode,
    });
  }

  fetchDefaultValues = () => {
    const { brandId } = this.props;

    this.props.dispatch(fetchDefaultValues(brandId));
    this.props.dispatch(fetchPriceSheets(brandId));
  };

  updateDefaultValue = (id, value) => {
    this.props.dispatch(updateDefaultValue(id, value, this.props.brandId)).then(() => {
      if (
        id === VCDB_DEFAULT_VALUE_ID &&
        (!this.props.selectedVcdbs || this.props.selectedVcdbs.length < 1)
      )
        this.props.dispatch(selectVcdbs(value ? [value] : []));
    });
    // on default language change the catalogue items need to be refreshed
    if (id === 1) {
      this.props.dispatch(setItemFetchRequired(true));
    }
  };

  updateDefaultPriceTypeDescription = (id, value, priceTypeId) => {
    this.props.dispatch(updateDefaultPriceTypeDescription(id, value, priceTypeId));
  };

  createDefaultPriceTypeDescription = (priceSheetId, priceTypeId, description) => {
    this.props.dispatch(createDefaultPriceTypeDescription(priceSheetId, priceTypeId, description));
  };

  render() {
    const { resources, vcdbVersions } = this.props;

    const canManageDefaultValues = hasPermission(this.props.user, 'can_manage_default_values');

    const defaultResources = {
      languages: resources.global.languages,
      gtin_qualifiers: resources.global.gtin_qualifiers,
      price_currencies: resources.price.currencies,
      aces_versions: resources.channel.aces_versions,
      pies_versions: resources.channel.pies_versions,
      price_uoms: resources.price.uoms,
      price_types: resources.price.types,
      package_uoms: resources.package.uoms,
      package_dimension_uoms: resources.package.dimension_uoms,
      package_weight_uoms: resources.package.weight_uoms,
      vcdb_versions: vcdbVersions,
    };

    return (
      <DefaultValues
        canManageDefaultValues={canManageDefaultValues}
        priceSheets={this.props.priceSheets}
        defaultValues={this.props.defaultValues}
        segments={this.props.resources.analysis.segments}
        resources={defaultResources}
        updateDefaultValue={this.updateDefaultValue}
        updateDefaultPriceTypeDescription={this.updateDefaultPriceTypeDescription}
        createDefaultPriceTypeDescription={this.createDefaultPriceTypeDescription}
      />
    );
  }
}

const mapStateToProps = state => ({
  defaultValues: state.settings.defaultValues,
  priceSheets: state.parent.priceSheets.priceSheets,
  resources: state.resources.data,
  brandId: state.parent.brands.selectedBrandId,
  brandCode: getSelectedBrandCode(state),
  user: state.user.user,
  vcdbVersions: state.items.application.vcdbVersions,
  selectedVcdbs: state.items.application.selectVcdbs,
});

export default connect(mapStateToProps)(SettingsDefaultValuesContainer);
