import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;

export function fetchInformations(itemId: number, keywords?: string, page = 1) {
  if (fetchCancelToken) fetchCancelToken();

  const params = utils.createURL([
    { name: 'page', values: page },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'keywords', values: utils.stringToArray(keywords || '') },
  ]);
  return {
    type: 'FETCH_INTERCHANGE_INFORMATIONS',
    meta: { page },
    payload: axios.get(
      `${getNonGwApiRoot()}/items/${itemId}/part_interchange_informations${params}`,
      {
        cancelToken: new CancelToken(c => {
          fetchCancelToken = c;
        }),
      }
    ),
  };
}

export function fetchRefrenceItems(brandId: number) {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_REFERENCE_ITEMS',
    payload: axios.get(
      `${getApiRoot()}/items/part_interchange_informations/reference_items${params}`
    ),
  };
}

export function fetchInterchangeSubBrand(brandId: number | string) {
  const params = utils.createURL([{ name: 'external_brand_ids', values: brandId }]);

  return {
    type: 'FETCH_INTERCHANGE_SUB_BRANDS',
    payload: axios.get(`${getNonGwApiRoot()}/parents/owners/brands/sub_brands${params}`),
  };
}

export function clearInterchangeSubBrands() {
  return {
    type: 'CLEAR_INTERCHANGE_SUB_BRANDS',
  };
}
