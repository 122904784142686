import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ShopifyStatus } from '../../../../types/channel';
import { fetchChannelStatus } from '../../../actions/channel/fetch';
import { ApplicationState } from '../../../reducers';
import ShopifyDeleteChannelModal from './ShopifyDeleteChannelModal';
import ShopifyStatusSteps from './ShopifyStatusSteps';

const { Paragraph } = Typography;

type ShopifySettingsProps = {
  channelName: string;
  deleteChannel: () => void;
  updateChannelName: (name: string) => void;
  status: ShopifyStatus;
  handleReconnect: (editUserName?: boolean) => void;
  token: string | null;
  connectToDB: boolean;
};

const ShopifySettings: React.FC<ShopifySettingsProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [channelName, setChannelName] = React.useState<string>(props.channelName);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState<boolean>(false);

  const { fetchingShopifyChannelStatus, rejectedName } = useSelector((state: ApplicationState) => {
    return {
      fetchingShopifyChannelStatus: state.channel.channels.fetchingShopifyChannelStatus,
      rejectedName: state.channel.channels.shopifyChannelAlreadyExists,
    };
  });

  const handleDeleteChannelShopify = () => {
    setShowConfirmDeleteModal(true);
  };

  const displayReconnect = () => {
    if (
      props.status.status === 'connected_to_other' ||
      props.status.status === 'disconnected' ||
      !props.status.installed
    )
      return true;
    return false;
  };

  const displayConnectName = () => {
    if (!props.status.installed) return true;
    return false;
  };

  React.useEffect(() => {
    if (props.status.username) dispatch(fetchChannelStatus(props.status.username));
  }, [dispatch, props.status.username]);

  React.useEffect(() => {
    if (props.status.username && !props.connectToDB) {
      const statusInInterval = setInterval(() => {
        dispatch(fetchChannelStatus(props.status.username));
      }, 5000);

      return () => {
        clearInterval(statusInInterval);
      };
    }
  }, [dispatch, props.connectToDB, props.status.username]);

  return (
    <div className="m-10 shopify-settings">
      <ShopifyDeleteChannelModal
        visible={showConfirmDeleteModal}
        handleDelete={() => {
          if (window && props.status.status === 'connected')
            window.open('https://accounts.shopify.com/store-login');
          props.deleteChannel();
          setShowConfirmDeleteModal(false);
        }}
        handleCancel={() => setShowConfirmDeleteModal(false)}
        connected={props.status.status === 'connected'}
      />

      <h5 className="shopify-settings__label">{t('channel:channelName')}</h5>
      <Input
        value={channelName}
        onChange={e => {
          setChannelName(e.target.value);
          props.updateChannelName(e.target.value);
        }}
        className="shopify-settings__name"
        placeholder={t('channel:channelName')}
        data-testid="channel-name"
      />
      {rejectedName === channelName && (
        <div className="shopify-warn__exists">{t('channel:alreadyExists')}</div>
      )}
      <Divider className="shopify-settings__divider" />
      <h5 className="shopify-settings__label">{t('channel:connectionDetails')}</h5>
      <div className="flex justify-between">
        <div className="w-2/4">
          <h5 className="shopify-settings__secondary-label">{t('channel:pdmAutomotiveStore')}</h5>
          <div className="flex space-x-4 mb-6 flex-row items-top shopify-settings__username">
            <p className="shopify-settings__url">{`https://${props.status.username || ''}`}</p>
            {props.status.username && (
              <Button
                icon={<EditOutlined />}
                size="small"
                onClick={() => props.handleReconnect(true)}
              />
            )}
          </div>
          <ShopifyStatusSteps status={props.status} />
          {displayReconnect() && (
            <div className="space-x-2 mt-6 flex flex-row items-center">
              <Button onClick={() => props.handleReconnect()} data-testid="connect">
                {displayConnectName() ? t('channel:connect') : t('channel:reconnect')}
              </Button>
              {fetchingShopifyChannelStatus && (
                <div className="shopify-setting__updating-status">
                  {t('channel:updatingStatus')}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="shopify-settings__info flex-1">
          <div className="mb-6">{t('channel:titleText')}</div>
          <Paragraph copyable={props.token ? { text: props.token } : undefined}>
            1. {t('channel:step2', { token: props.token })}
          </Paragraph>
          <div>2. {t('channel:step3')}</div>
        </div>
      </div>
      <Divider className="shopify-settings__divider" />
      <Button type="primary" danger onClick={() => handleDeleteChannelShopify()}>
        {t('channel:removeChannel')}
      </Button>
    </div>
  );
};

export default ShopifySettings;
