import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Switch, Form, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { ImportOption, ImportOptions } from '../../../../types/import_export';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import FormRadio from '../../global/Forms/FormRadio';
import FormSelect from '../../global/Forms/FormSelect';
import FormInput from '../../global/Forms/FormInput';
import FormMultiCheckbox from '../../global/Forms/FormMultiCheckbox';
import FormCheckboxGroup from '../../global/Forms/FormCheckboxGroup';

type ImportFormatOptionsProps = {
  importOptions: ImportOptions[];
  displayAdvanceOptions: boolean;
  handleUpdate: (formatId: number) => void;
};

const ImportFormatOptions: React.FC<ImportFormatOptionsProps> = props => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  const selectedOptions =
    props.importOptions.find(option => option.id === values.selectedFormat)?.import_options || [];

  const options = selectedOptions.filter(opt => opt.advanced === 0);
  const advOptions = selectedOptions.filter(opt => opt.advanced > 0);

  const renderSwitch = (option: ImportOption) => {
    return (
      <div className="flex justify-end items-center ml-2" key="selectAll">
        <div className="mr-2">{t('common:selectAll')}</div>
        <Switch
          size="small"
          checked={
            option.parameters.length > 0 &&
            values.importOptions[option.id]?.length === option.parameters.length
          }
          onChange={checked => {
            const ids = option.parameters.map(param => param.id);
            if (checked) setFieldValue(`importOptions[${option.id}]`, ids);
            if (!checked) setFieldValue(`importOptions[${option.id}]`, []);
          }}
        />
      </div>
    );
  };

  const renderName = (option: ImportOption) => (
    <div className="mr-2">
      {option.name}
      {option.description && (
        <Tooltip title={option.description}>
          <InfoCircleOutlined className="ant-info-tooltip ml-2" />
        </Tooltip>
      )}
    </div>
  );

  const renderOptions = (option: ImportOption) => {
    switch (option.format_type_id) {
      case 1: {
        return (
          <FormRadio
            label={renderName(option)}
            name={`importOptions.${option.id}`}
            values={option.parameters}
            required={option.mandatory === 1}
            key={option.id}
          />
        );
      }
      case 2: {
        return (
          <FormCheckboxGroup
            label={renderName(option)}
            name={`importOptions.${option.id}`}
            elements={option.parameters}
            required={option.mandatory === 1}
            key={option.id}
          />
        );
      }
      case 3: {
        return (
          <FormSelect
            label={renderName(option)}
            name={`importOptions.${option.id}`}
            values={option.parameters}
            size="small"
            allowClear={option.mandatory === 0}
            showSearch
            required={option.mandatory === 1}
            key={option.id}
            clearContainer
          />
        );
      }
      case 4: {
        return (
          <Form.Item label={renderName(option)} key={option.name} required={option.mandatory === 1}>
            <Row>
              <Col span={20}>
                <FormMultiSelect
                  className="import-format-options__multi-select"
                  name={`importOptions.${option.id}`}
                  values={option.parameters}
                  key={option.id}
                  allowClear
                  size="small"
                  required={option.mandatory === 1}
                />
              </Col>
              <Col span={4}>{renderSwitch(option)}</Col>
            </Row>
          </Form.Item>
        );
      }
      case 5: {
        return (
          <FormInput
            label={renderName(option)}
            name={`importOptions.${option.id}`}
            size="small"
            required={option.mandatory === 1}
            key={option.id}
          />
        );
      }
      case 6: {
        return (
          <Form.Item label={renderName(option)} key={option.name} required={option.mandatory === 1}>
            <Row>
              <Col span={20}>
                <FormMultiCheckbox
                  name={`importOptions.${option.id}`}
                  values={option.parameters}
                  required={option.mandatory === 1}
                  key={option.id}
                />
              </Col>
              <Col span={4}>{renderSwitch(option)}</Col>
            </Row>
          </Form.Item>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <FormSelect
        label={t('importer:selectFormat')}
        name="selectedFormat"
        values={props.importOptions}
        size="small"
        showSearch
        key="selectedFormat"
        clearContainer
        onChange={value => {
          setFieldValue('file', null);
          setFieldValue('fileType', null);
          setFieldValue('selectedFormat', value === undefined ? null : value);
          setFieldValue('showAdvImportOptions', false);
          props.handleUpdate(value === undefined ? null : value);
        }}
      />
      {options.map(opt => renderOptions(opt))}
      {props.displayAdvanceOptions && advOptions.map(opt => renderOptions(opt))}
    </React.Fragment>
  );
};

export default ImportFormatOptions;
