import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Switch, Form, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { ExportOption } from '../../../../types/import_export';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import FormRadio from '../../global/Forms/FormRadio';
import FormCheckboxGroup from '../../global/Forms/FormCheckboxGroup';
import FormSelect from '../../global/Forms/FormSelect';
import FormInput from '../../global/Forms/FormInput';
import SwitchDivider from '../../global/SwitchDivider';
import FormMultiCheckbox from '../../global/Forms/FormMultiCheckbox';
import { FULL_EXPORT_TEMPLATE_CODE } from '../../../constants/ImportExportConstants';

type ExportFormatOptionsProps = {
  fileTypeId: number;
  fileTypeName: string;
  exportOptions: ExportOption[];
  canManageAdvancedExportOptions: boolean;
  segment: string;
  handleAdvancedOptions: (advancedOptionsEnabled: boolean) => void;
};

const ExportFormatOptions: React.FC<ExportFormatOptionsProps> = props => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();
  const [advancedExportOptions, setAdvancedExportOptions] = React.useState<ExportOption[]>(
    props.exportOptions.filter(option => option.advanced === 1)
  );

  React.useEffect(() => {
    setAdvancedExportOptions(props.exportOptions.filter(option => option.advanced === 1));
  }, [props.exportOptions]);

  const getExpandable = () => {
    const value = advancedExportOptions?.find(option => {
      const x = values?.exportOptions[props.fileTypeName!][option.code];
      if (option.format_type_id === 1 || option.format_type_id === 2) {
        const defaultValue = option?.default_value
          ? Number(option.default_value)
          : option.default_value;
        if (x || x === 0) {
          if (typeof defaultValue !== typeof x) return true;
          if (typeof defaultValue === typeof x && x !== defaultValue) return true;
        }
      }
      if (option.format_type_id === 3) return x && Number(option.default_value) !== Number(x);
      if ([4, 5, 6].includes(option.format_type_id)) return x?.length > 0;
    });
    if (value) return true;
    return false;
  };

  const [expand, setExpand] = React.useState<boolean>(getExpandable());

  const updateValues = () => {
    setExpand(!expand);
    const advancedOptionsEnabled = !expand;
    props.handleAdvancedOptions(advancedOptionsEnabled);
  };

  const renderSwitch = (option: ExportOption) => {
    return (
      <div className="flex justify-end items-center" key={option.id}>
        <div className="mr-2">{t('common:selectAll')}</div>
        <Switch
          size="small"
          checked={
            option.parameters.length > 0 &&
            values.exportOptions[props.fileTypeName][option.code]?.length ===
              option.parameters.length
          }
          onChange={checked => {
            const ids = option.parameters.map(param => param.id);
            if (checked) setFieldValue(`exportOptions[${props.fileTypeName}].${option.code}`, ids);
            if (!checked) setFieldValue(`exportOptions[${props.fileTypeName}].${option.code}`, []);
          }}
        />
      </div>
    );
  };

  const renderName = (option: ExportOption) => (
    <React.Fragment>
      <div className="flex flex-1 flex-row items-center" onClick={e => e.preventDefault()}>
        <div className="mr-1">{option.name}</div>
        <Tooltip overlayClassName="whitespace-pre-line" title={option.description}>
          <InfoCircleOutlined className="ant-info-tooltip" />
        </Tooltip>
      </div>
      {[4, 6].includes(option.format_type_id) &&
        (props.segment === 'channel' || props.segment === 'plan') &&
        renderSwitch(option)}
    </React.Fragment>
  );

  const renderOptions = (option: ExportOption) => {
    switch (option.format_type_id) {
      case 1: {
        return (
          <FormRadio
            label={renderName(option)}
            name={`exportOptions[${props.fileTypeName}].${option.code}`}
            values={option.parameters}
            required={option.mandatory === 1}
            key={`${props.fileTypeId}_${option.id}`}
          />
        );
      }
      case 2: {
        return (
          <FormCheckboxGroup
            label={renderName(option)}
            name={`exportOptions[${props.fileTypeName}].${option.code}`}
            elements={option.parameters}
            required={option.mandatory === 1}
            key={`${props.fileTypeId}_${option.id}`}
          />
        );
      }
      case 3: {
        return (
          <FormSelect
            label={renderName(option)}
            name={`exportOptions[${props.fileTypeName}].${option.code}`}
            values={option.parameters}
            size="small"
            allowClear={option.mandatory === 0}
            showSearch
            required={option.mandatory === 1}
            key={`${props.fileTypeId}_${option.id}`}
            clearContainer
            placeholder={
              option.code === FULL_EXPORT_TEMPLATE_CODE
                ? t('exportBuilderAdvanced:searchByTemplateNameId')
                : undefined
            }
            searchId
          />
        );
      }
      case 4: {
        return (
          <Form.Item label={renderName(option)} key={`${props.fileTypeId}_${option.code}`}>
            <Row>
              <Col span={props.segment === 'export' ? 18 : 24}>
                <FormMultiSelect
                  name={`exportOptions[${props.fileTypeName}].${option.code}`}
                  values={option?.parameters || []}
                  size="small"
                  className="export-format-options__multi-select"
                  allowClear
                  required={option.mandatory === 1}
                />
              </Col>
              {props.segment === 'export' && <Col span={6}>{renderSwitch(option)}</Col>}
            </Row>
          </Form.Item>
        );
      }
      case 5: {
        return (
          <FormInput
            label={renderName(option)}
            name={`exportOptions[${props.fileTypeName}].${option.code}`}
            size="small"
            required={option.mandatory === 1}
            key={`${props.fileTypeId}_${option.id}`}
          />
        );
      }
      case 6: {
        return (
          <Form.Item label={renderName(option)} key={`${props.fileTypeId}_${option.code}`}>
            <Row>
              <Col span={props.segment === 'export' ? 18 : 24}>
                <FormMultiCheckbox
                  name={`exportOptions[${props.fileTypeName}].${option.code}`}
                  values={option.parameters}
                  required={option.mandatory === 1}
                  key={option.id}
                />
              </Col>
              {props.segment === 'export' && <Col span={6}>{renderSwitch(option)}</Col>}
            </Row>
          </Form.Item>
        );
      }
    }
  };

  return (
    <div className="export-format-options">
      {props.exportOptions.map(
        (option: ExportOption) => option.advanced === 0 && renderOptions(option)
      )}
      {props.canManageAdvancedExportOptions && advancedExportOptions.length > 0 && (
        <React.Fragment>
          <SwitchDivider
            title={t('exporter:advancedExportOptions')}
            titlePosition="right"
            checked={
              props.segment === 'export' || props.segment === 'plan'
                ? values.advancedOptionsEnabled
                : expand
            }
            handleChange={() => updateValues()}
          />
          {(props.segment === 'export' || props.segment === 'plan'
            ? values.advancedOptionsEnabled
            : expand) && advancedExportOptions.map((option: ExportOption) => renderOptions(option))}
        </React.Fragment>
      )}
    </div>
  );
};

export default ExportFormatOptions;
