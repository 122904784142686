import React from 'react';
import { Button, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Formik } from 'formik';
import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import FormCheckbox from '../../global/Forms/FormCheckbox';

type CustomizeLogModalProps = {
  showModal: boolean;
  columns: { dataKey: string; label: string; visible: boolean }[];
  onCancel: () => void;
  handleSave: (colums: { dataKey: string; label: string; visible: boolean }[]) => void;
};

const SortableRow = SortableElement(({ children }: { children: any }) => (
  <div className="flex items-center import-export__order-row">{children}</div>
));

const SortableCon = SortableContainer(({ children }: { children: any }) => (
  <div className="import-export-logs__order-table">{children}</div>
));

const DragHandle = SortableHandle(() => <MenuOutlined className="import-export__drag-icon" />);

const CustomizeLogModal: React.FC<CustomizeLogModalProps> = props => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.showModal}
      onCancel={() => props.onCancel()}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <Formik
        initialValues={{
          columns: props.columns,
        }}
        onSubmit={values => {
          props.handleSave(values.columns);
        }}
      >
        {({ handleSubmit, setFieldValue, dirty, isSubmitting, values }) => {
          const updateOptions = (columns: any) => {
            setFieldValue('columns', columns);
          };

          const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            updateOptions(arrayMoveImmutable(values.columns, oldIndex, newIndex));
          };

          return (
            <div>
              <div className="text-3xl mb-7">{t('importer:customizeColumns')}</div>
              <Form layout="vertical" className="import-export-logs__colum-order-form">
                <SortableCon
                  onSortEnd={onSortEnd}
                  lockAxis="y"
                  useDragHandle
                  helperClass="import-export__row-dragging"
                >
                  {values.columns.map((col: any, index: number) => (
                    <SortableRow key={col.dataKey} index={index}>
                      <FormCheckbox className="mr-2" name={`columns[${index}].visible`} />
                      <span className="flex-1">{col.label}</span>
                      <DragHandle />
                    </SortableRow>
                  ))}
                </SortableCon>
              </Form>

              <div className="flex-col items-end mt-7">
                <div>
                  <Button onClick={() => props.onCancel()} className="drawer-submit__bottom-cancel">
                    {t('common:cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    disabled={!dirty || isSubmitting}
                  >
                    {t('common:save')}
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CustomizeLogModal;
