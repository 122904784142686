import { Button, Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PackageDrawerReplaceButtonProps = {
  handleReplace: () => void;
  disableReplaceAll?: boolean;
};

const PackageDrawerReplaceButton: React.FC<PackageDrawerReplaceButtonProps> = ({
  handleReplace,
  disableReplaceAll,
}) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={t('packageSeg:warnReplaceText')}
      placement="left"
      onConfirm={() => handleReplace()}
      okText={t('common:yes')}
      cancelText={t('common:cancel')}
      onCancel={e => e?.stopPropagation()}
      disabled={disableReplaceAll}
    >
      <Button type="primary" size="small" disabled={disableReplaceAll} data-testid="ok">
        {t('common:ok')}
      </Button>
    </Popconfirm>
  );
};

export default PackageDrawerReplaceButton;
