import React from 'react';
import { DeleteOutlined, EyeInvisibleOutlined, TagsOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { FormikProps, FormikValues } from 'formik';
import { AsyncDispatch } from '../../../../../types/global';
import {
  fetchAttributePartTypes,
  fetchPartTypeAttributes,
} from '../../../../actions/parent/attribute/fetch';
import { ApplicationState } from '../../../../reducers';
import { nextPage, typingDone } from '../../../../utils/Utils';
import CategoryDropdown from '../../../global/CategoryDropdown';
import PageFormik from '../../../global/page/PageFormik';
import AttributeManagingTable from './AttributeManagingTable';
import AntPopover from '../../../global/AntPopover';
import { PartTypeAttribute } from '../../../../../types/attributes';
import {
  exportAttributeExcelFile,
  setSelectedCategory,
  updatePartTypeAttributes,
} from '../../../../actions/parent/attribute/update';
import { showUpgradePlanNotification } from '../../../../actions/app/modal';
import AddAttributePopover from './AddAttributePopover';
import AttributeImportExportMenu from './AttributeImportExportMenu';
import AttributeImportModal from './AttributeImportModal';
import { hasPermission } from '../../../../utils/Permissions';
import { intercomEvent } from '../../../../utils/IntercomUtils';

const AttributeManagingPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [categoryKeyword, setCategoryKeyword] = React.useState<string | undefined>(undefined);
  const [selectedAttributeIds, setSelectedAttributeIds] = React.useState<number[]>([]);
  const [customSelectedAttributeIds, setCustomSelectedAttributeIds] = React.useState<number[]>([]);

  const [showImportModal, setShowImportModal] = React.useState<boolean>(false);
  const [filterAttributeIds, setFilterAttributeIds] = React.useState<number[]>([]);
  const [filterSourceIds, setFilterSourceIds] = React.useState<number[]>([]);

  const {
    fetchingCategories,
    partTypeCategories,
    attributes,
    tags,
    sources,
    fetchingAttributes,
    selectedCategory,
    user,
  } = useSelector((state: ApplicationState) => {
    return {
      fetchingCategories: state.parent.attributeManagement.fetchingCategories,
      partTypeCategories: state.parent.attributeManagement.partTypeCategories,
      attributes: state.parent.attributeManagement.attributes,
      tags: state.parent.attributeManagement.tags,
      sources: state.resources.data.global.sources,
      fetchingAttributes: state.parent.attributeManagement.fetchingAttributes,
      selectedCategory: state.parent.attributeManagement.selectedCategory,
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-company-attribute-settings');
  }, []);

  const canManageAttributes = hasPermission(user, 'can_manage_category_part_attribute_relations');

  const fetchCategories = (keyword?: string) => {
    setCategoryKeyword(keyword);
    typingDone(() => {
      dispatch(fetchAttributePartTypes(keyword));
    });
  };

  const fetchAttributes = (categoryId: number) => {
    setFilterAttributeIds([]);
    setFilterSourceIds([]);
    dispatch(fetchPartTypeAttributes(categoryId));
  };

  const fetchNextPartTypeCategories = (event: any, keywords?: string) => {
    const page = nextPage(event);

    if (page && !fetchingCategories) {
      dispatch(fetchAttributePartTypes(keywords, page));
    }
  };

  const selectAttribute = (id: number, customAttribute: boolean) => {
    if (customSelectedAttributeIds.includes(id))
      setCustomSelectedAttributeIds(customSelectedAttributeIds.filter(sId => sId !== id));
    else if (customAttribute) setCustomSelectedAttributeIds([...customSelectedAttributeIds, id]);
    if (selectedAttributeIds.includes(id))
      setSelectedAttributeIds(selectedAttributeIds.filter(sId => sId !== id));
    else setSelectedAttributeIds([...selectedAttributeIds, id]);
  };

  return (
    <PageFormik
      initialValues={{ attributes }}
      onSubmit={(values, { setSubmitError, setSubmitting, setStatus, resetForm }) => {
        setSubmitting(true);

        const attributes = values.attributes.map((a: PartTypeAttribute, i: number) => ({
          ...a,
          record_number: i,
        }));
        dispatch(updatePartTypeAttributes(selectedCategory!.id, attributes))
          .then(() => {
            resetForm({ values });
            setSelectedAttributeIds([]);
            setStatus('SUCCESS');
            setTimeout(() => setStatus('EDIT'), 2500);
          })
          .catch(() => setSubmitError());
      }}
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canManageAttributes && formik.dirty
      }
      contentNoScroll
      contentNoSpacing
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const handleSelectAll = () => {
          if (selectedAttributeIds.length === values.attributes.length) {
            setSelectedAttributeIds([]);
            setCustomSelectedAttributeIds([]);
          } else {
            setSelectedAttributeIds(
              values.attributes.map((a: PartTypeAttribute) => (a.id ? a.id : a.tempId!))
            );
            setCustomSelectedAttributeIds(
              values.attributes
                .filter((a: PartTypeAttribute) => a.source_id === 7)
                .map((a: PartTypeAttribute) => (a.id ? a.id : a.tempId!))
            );
          }
        };

        const selectTag = (tag: string) => {
          const attributes = values.attributes.map((a: PartTypeAttribute) =>
            selectedAttributeIds.includes(a.id ? a.id : a.tempId!)
              ? { ...a, tags: [...(a.tags || []), tag] }
              : a
          );
          setFieldValue('attributes', attributes);
        };

        const unSelectTag = (tag: string) => {
          const attributes = values.attributes.map((a: PartTypeAttribute) => {
            if (selectedAttributeIds.includes(a.id ? a.id : a.tempId!)) {
              const newTags = (a.tags || []).filter(t => t !== tag);
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { tags, ...rest } = a;
              return newTags.length > 0 ? { ...a, tags: newTags } : rest;
            }
            return a;
          });
          setFieldValue('attributes', attributes);
        };

        const hideAttributes = (ids: number[]) => {
          const attributes = values.attributes.map((a: PartTypeAttribute) =>
            ids.includes(a.id ? a.id : a.tempId!) ? { ...a, hide: 1 } : a
          );
          setFieldValue('attributes', attributes);

          intercomEvent('viewed-company-attribute-settings', {
            location: 'attribute-settings',
            action: 'hide',
          });
        };

        const showAttributes = (ids: number[]) => {
          const attributes = values.attributes.map((a: PartTypeAttribute) => {
            if (ids.includes(a.id ? a.id : a.tempId!)) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { hide, ...rest } = a;
              return rest;
            }
            return a;
          });
          setFieldValue('attributes', attributes);
        };

        const addAttribute = (attribute: any) => {
          if (canManageAttributes) {
            const attributes = [attribute, ...values.attributes];
            setFieldValue('attributes', attributes);
          } else {
            dispatch(showUpgradePlanNotification());
          }

          intercomEvent('viewed-company-attribute-settings', {
            location: 'attribute-settings',
            action: 'add',
          });
        };

        const deleteAttributes = (ids: number[]) => {
          const attributes = values.attributes.filter(
            (a: PartTypeAttribute) => !ids.includes(a.id ? a.id : a.tempId!)
          );
          setFieldValue('attributes', attributes);

          intercomEvent('viewed-company-attribute-settings', {
            location: 'attribute-settings',
            action: 'delete',
          });
        };

        const handleSort = (oldIndex: number, newIndex: number) => {
          const sortedAttributes = arrayMoveImmutable(values.attributes, oldIndex, newIndex);

          setFieldValue('attributes', sortedAttributes);
        };

        const editAtrributeName = (attrName: string, rowIndex: number, id: number) => {
          if (id) {
            const attributes = values.attributes.map((a: PartTypeAttribute) =>
              a.id === id ? { ...a, name: attrName, renamed: 1 } : a
            );
            setFieldValue('attributes', attributes);
          } else setFieldValue(`attributes[${rowIndex}].name`, attrName);
        };

        const selectedAttributes = values.attributes.filter((a: PartTypeAttribute) =>
          selectedAttributeIds.includes(a.id ? a.id : a.tempId!)
        );

        const selectedTags: string[] = [
          ...new Set<string>(selectedAttributes.map((a: PartTypeAttribute) => a.tags || []).flat()),
        ];

        const getFilterdAttributes = (attributes: PartTypeAttribute[]) => {
          let filterdAttributes: PartTypeAttribute[] = attributes;
          if (filterAttributeIds.length > 0)
            filterdAttributes = filterdAttributes.filter(a =>
              filterAttributeIds.includes(a.id ? a.id : a.tempId!)
            );
          if (filterSourceIds.length > 0)
            filterdAttributes = filterdAttributes.filter(a =>
              filterSourceIds.includes(a.source_id)
            );
          return filterdAttributes;
        };
        return (
          <div className="page-layout">
            <div className="page-layout__top-bar">
              <div className="page-layout__top-bar__container">
                <CategoryDropdown
                  className="application__part-type-dropdown"
                  displayedCategory={selectedCategory}
                  fetchReviewCategories={fetchCategories}
                  fetchNextReviewCategories={fetchNextPartTypeCategories}
                  fetchingCategories={fetchingCategories}
                  categories={categoryKeyword || selectedCategory ? partTypeCategories : []}
                  updateCategory={category => {
                    dispatch(setSelectedCategory(category));
                    fetchAttributes(category.id);
                  }}
                  allowClear={false}
                  defaultKeyword=""
                />
                <AddAttributePopover
                  attributeIds={values.attributes.map((a: PartTypeAttribute) => a.id || a.tempId)}
                  selectedCategoryId={selectedCategory?.id}
                  disabled={!selectedCategory}
                  addAttribute={addAttribute}
                >
                  <Button
                    className="ml-2"
                    type="primary"
                    disabled={!selectedCategory}
                    data-testid="add-attribute"
                  >
                    {t('attributes:addAttribute')}
                  </Button>
                </AddAttributePopover>
                <AntPopover
                  trigger="click"
                  disabled={!canManageAttributes || selectedAttributeIds.length === 0}
                  content={
                    <Select
                      mode="tags"
                      className="w-60"
                      placeholder={t('attributes:selectTags')}
                      onSelect={selectTag}
                      onDeselect={unSelectTag}
                      value={selectedTags}
                      defaultValue={[]}
                      allowClear
                      getPopupContainer={trigger => trigger.parentNode}
                      data-testid="add-tags"
                    >
                      {tags.map(name => (
                        <Select.Option key={name} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                  buttonProps={{
                    className: 'ml-2',
                    icon: <TagsOutlined data-testid="tags" />,
                    title: t('attributes:tags'),
                  }}
                />
                <Button
                  icon={<EyeInvisibleOutlined />}
                  className="ml-2"
                  disabled={!canManageAttributes || selectedAttributeIds.length === 0}
                  onClick={() => {
                    if (
                      selectedAttributes.filter((a: PartTypeAttribute) => !a.hide).length ===
                      selectedAttributeIds.length
                    )
                      hideAttributes(selectedAttributeIds);
                    else showAttributes(selectedAttributeIds);
                  }}
                  data-testid="show/hide"
                >
                  {t('attributes:showHide')}
                </Button>
                <Button
                  icon={<DeleteOutlined />}
                  className="ml-2"
                  disabled={
                    !canManageAttributes ||
                    customSelectedAttributeIds.length === 0 ||
                    selectedAttributeIds.length > customSelectedAttributeIds.length
                  }
                  onClick={() => deleteAttributes(customSelectedAttributeIds)}
                >
                  {t('common:delete')}
                </Button>
                <AttributeImportExportMenu
                  handleAction={key =>
                    key === 'import'
                      ? setShowImportModal(true) // @ts-ignore
                      : dispatch(exportAttributeExcelFile())
                  }
                  disabled={!canManageAttributes}
                />
              </div>
            </div>
            <div className="h-full bg-white flex">
              {selectedCategory ? (
                <AttributeManagingTable
                  attributeFilterValues={values.attributes.map((a: PartTypeAttribute) => ({
                    id: a.id ? a.id : a.tempId!,
                    name: a.name,
                  }))}
                  fetchingAttributes={fetchingAttributes}
                  attributes={getFilterdAttributes(values.attributes)}
                  selectedAttributeIds={selectedAttributeIds}
                  sources={sources}
                  selectAttribute={selectAttribute}
                  hideAttribute={(id: number) => hideAttributes([id])}
                  showAttribute={(id: number) => showAttributes([id])}
                  deleteAttribute={(id: number) => deleteAttributes([id])}
                  handleSort={handleSort}
                  editAtrributeName={editAtrributeName}
                  filterAttributeIds={filterAttributeIds}
                  filterSourceIds={filterSourceIds}
                  handleFilterAttributes={ids => setFilterAttributeIds(ids)}
                  handleFilterSource={ids => setFilterSourceIds(ids)}
                  handleSelectAll={handleSelectAll}
                  disabled={!canManageAttributes}
                />
              ) : (
                <div className="ml-6 mt-4 space-y-2">
                  <div className="attribute-managing-page__title">
                    {t('attributes:selectPartType')}
                  </div>
                  <div className="attribute-managing-page__sub-title">
                    {t('attributes:searchText')}
                  </div>
                </div>
              )}
            </div>

            <AttributeImportModal
              showImportModal={showImportModal}
              handleOnCancel={() => setShowImportModal(false)}
            />
          </div>
        );
      }}
    </PageFormik>
  );
};

export default AttributeManagingPage;
