import axios from 'axios';
import { APIScriptUpdate } from '../../../types/distribution';
import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export function deleteScript(scriptId: number) {
  const urlParams = utils.createURL([{ name: 'script_id', values: scriptId }]);
  return {
    type: 'DELETE_SCRIPT',
    meta: scriptId,
    payload: axios.delete(`${getApiRoot()}/scripts${urlParams}`),
  };
}

export function resetCache(scriptId: number) {
  const urlParams = utils.createURL([{ name: 'script_id', values: scriptId }]);
  return {
    type: 'RESET_CACHE',
    payload: axios.delete(`${getApiRoot()}/scripts/cache/reset${urlParams}`),
  };
}

export function runScript(scriptId: number, data?: any) {
  const urlParams = utils.createURL([{ name: 'script_id', values: scriptId }]);
  return {
    type: 'RUN_SCRIPT',
    payload: axios.post(`${getApiRoot()}/scripts/run${urlParams}`, data),
  };
}

export function runScripts({
  scriptId,
  itemIds,
  filterId,
  withoutItemIds,
}: {
  scriptId: number;
  itemIds?: number[];
  filterId?: number;
  withoutItemIds?: number[];
}) {
  const urlParams = utils.createURL([
    { name: 'script_id', values: scriptId },
    { name: 'item_ids', values: itemIds },
    { name: 'filter_id', values: filterId },
    { name: 'without_item_ids', values: withoutItemIds },
  ]);
  return {
    type: 'RUN_SCRIPTS',
    payload: axios.post(`${getApiRoot()}/scripts/events/run${urlParams}`),
  };
}

export function updateScriptFile(script: any) {
  return {
    type: 'UPDATE_SCRIPT_FILE',
    payload: axios.patch(`${getApiRoot()}/scripts/file`, {
      script,
    }),
  };
}

export function updateScript(script: APIScriptUpdate) {
  return {
    type: 'UPDATE_SCRIPT',
    payload: axios.patch(`${getApiRoot()}/scripts`, {
      script,
    }),
  };
}

export function fetchScripts() {
  return {
    type: 'FETCH_SCRIPTS',
    payload: axios.get(`${getNonGwApiRoot()}/scripts`),
  };
}

export function fetchScriptEvents() {
  return {
    type: 'FETCH_SCRIPT_EVENTS',
    payload: axios.get(`${getApiRoot()}/scripts/events`),
  };
}

export function fetchScriptDataPreview(eventId: number, newLogic: number, itemId?: number) {
  const urlParams = utils.createURL([
    { name: 'event_id', values: eventId },
    { name: 'item_id', values: itemId },
    { name: 'new_logic', values: newLogic },
  ]);

  return {
    type: 'FETCH_SCRIPT_DATA_PREVIEW',
    meta: { newLogic },
    payload: axios.get(`${getApiRoot()}/scripts/events/data${urlParams}`),
  };
}

export function resetScriptFile() {
  return {
    type: 'RESET_SCRIPT_FILE',
  };
}

export function fetchScriptFile(id: number) {
  const urlParams = utils.createURL([{ name: 'script_id', values: id }]);

  return {
    type: 'FETCH_SCRIPT_FILE',
    payload: axios.get(`${getApiRoot()}/scripts/file${urlParams}`),
  };
}

export function fetchScriptLogs(page = 1) {
  const urlParams = utils.createURL([
    { name: 'page', values: page },
    { name: 'limit', values: utils.getPageLimit() },
  ]);

  return {
    type: 'FETCH_SCRIPT_LOGS',
    meta: { page },
    payload: axios.get(`${getApiRoot()}/scripts/logs${urlParams}`),
  };
}

export function fetchAPIToken({ page = 1, limit = 1 }: { page?: number; limit?: number }) {
  const urlParams = utils.createURL([
    { name: 'limit', values: limit },
    { name: 'page', values: page },
  ]);
  return {
    meta: { page },
    type: 'FETCH_API_SETTINGS',
    payload: axios.get(`${getNonGwApiRoot()}/accounts/api_token${urlParams}`),
  };
}

export function createAPIToken() {
  return {
    type: 'CREATE_NEW_API_TOKEN',
    payload: axios.patch(`${getApiRoot()}/accounts/api_token`),
  };
}
