import {
  devApiRoot,
  devNonGwApiRoot,
  qaApiRoot,
  qaNonGwApiRoot,
  prodApiRoot,
  prodNonGwApiRoot,
} from './ApiRoots.json';

export function getApiRoot() {
  return globalThis.apiRoot || process.env.REACT_APP_API_ROOT;
}
export function getNonGwApiRoot() {
  const root = getApiRoot();
  if (root === prodApiRoot) return prodNonGwApiRoot;
  if (root === devApiRoot) return devNonGwApiRoot;
  if (root === qaApiRoot) return qaNonGwApiRoot;

  return root;
}

export const goSystemApiRoot = 'https://beta-api.pdm-automotive.com';
export const goOnlySystemApiRoot = 'https://test-api.pdm-automotive.com';

export const onePagerUrl = 'https://pdmautomotive.com';
export const termsUrl = 'https://pdmautomotive.com/terms-conditions';
export const privacyUrl = 'https://pdmautomotive.com/privacy';
export const pricingUrl = 'https://pdmautomotive.com/pricing';

export const ebayPolicyUrl = 'https://www.bizpolicy.ebay.com/businesspolicy/policyoptin';

export const infoEmail = 'info@pdmautomotive.com';

export const keys = {
  bugsnag: {
    api_key: 'b8627595e60659941f6ebee676cc27d8',
  },
  intercom: {
    app_id:
      process.env.REACT_APP_STAGE === 'production' || process.env.REACT_APP_STAGE === 'beta'
        ? 'k4eeu5ur'
        : 'wur502bj', // if not production track for test account
  },
  googleMaps: {
    api_key: 'AIzaSyA76CpPMOvM_HYmkOYuNKw6zj6Jw56mT-M',
  },
  dromo: {
    api_key: '5b0c8238-864d-4614-9b15-aaed767ec3ac',
  },
  agTable: {
    license_key:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-059755}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{PARAMOUNT_DATA_MANAGEMENT_INC.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{PDM_Automotive}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{PDM_Automotive}_need_to_be_licensed___{PDM_Automotive}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_May_2025}____[v3]_[01]_MTc0NjgzMTYwMDAwMA==22f8a61d6239669b0e28023e07672245',
  },
};

export const exludedRequestPaths = [
  { method: 'post', path: '/logout' },
  { method: 'post', path: '/login' },
  { method: 'post', path: '/items?part_number=' },
  { method: 'patch', path: '/users' },
  { method: 'post', path: '/items/analyses' },
  { method: 'patch', path: '/items/analyses/individual_analyses' },
  { method: 'patch', path: '/items/analyses/confirm' },
  { method: 'patch', path: '/items/analyses/update' },
  { method: 'post', path: 'v2/applications/ranks' },
  { method: 'post', path: '/items/marks' },
  { method: 'delete', path: '/items/marks' },
  { method: 'post', path: '/items/tags' },
  { method: 'delete', path: '/items/tags' },
  { method: 'post', path: '/items/clone' },
  { method: 'post', path: '/items/sync' },
  { method: 'post', path: '/items/list' },
  { method: 'post', path: '/items/packages' }, // fetch packages
  { method: 'post', path: '/items/extended_informations' }, // fetch extended info
  { method: 'delete', path: '/items?' }, // no analyses call on delete
  { method: 'post', path: '/filters' },
  { method: 'patch', path: '/filters' },
  { method: 'delete', path: '/filters' },
  { method: 'post', path: '/items/search' },
  { method: 'post', path: '/parents', exception: ['/parents/digital_assets'] },
  { method: 'patch', path: '/parents', exception: ['/parents/digital_assets'] },
  { method: 'delete', path: '/parents' },
  { method: 'post', path: '/channels' },
  { method: 'patch', path: '/channels' },
  { method: 'delete', path: '/channels' },
  { method: 'post', path: '/receivers' },
  { method: 'patch', path: '/receivers' },
  { method: 'delete', path: '/receivers' },
  { method: 'post', path: '/items/integrated_channel_settings' },
  { method: 'patch', path: '/integrated_channel_settings' },
  { method: 'patch', path: '/vehicles/versions' },
  { method: 'delete', path: '/vehicles/versions' },
  { method: 'post', path: '/part_attributes/ranks' },
  { method: 'post', path: '/valid_configs' },
  { method: 'post', path: '/items/applications/grids/preview' },
];

export const navigationBarData = [
  { link: 'item', value: 'Item', key: 'item', id: 1 },
  { link: 'application', value: 'Application', key: 'application', id: 3 },
  { link: 'attributes', value: 'Attributes', key: 'attributes', id: 2 },
  { link: 'package', value: 'Package', key: 'package', id: 5 },
  { link: 'pricing', value: 'Pricing', key: 'pricing', id: 6 },
  { link: 'extended', value: 'Extended Info', key: 'expi', id: 7 },
  { link: 'description', value: 'Description', key: 'description', id: 4 },
  { link: 'kit', value: 'Kit', key: 'kit', id: 8 },
  { link: 'interchange', value: 'Interchange', key: 'interchange', id: 9 },
  { link: 'digital-assets', value: 'Digital Assets', key: 'digital_assets', id: 10 },
  { link: 'custom-fields', value: 'Custom Fields', key: 'custom_fields', id: 12 },
  { link: 'ebay', value: 'eBay', key: 'ebay', id: 13 },
  { link: 'channel-receiver', value: 'Channel/Receiver', key: 'channel-receiver' },
  { link: 'insights', value: 'Insights', key: 'insights' },
  { link: 'retool', value: 'Retool', key: 'retool' },
];

export const filterNavigationBarElements = ['Part Type', 'Application', 'Analysis', 'More'];

export const areas = {
  home: 'home',
  item: 'item',
  application: 'application',
  attributes: 'attributes',
  package: 'package',
  pricing: 'pricing',
  expi: 'expi',
  description: 'description',
  kit: 'kit',
  interchange: 'interchange',
  digital_assets: 'digital_assets',
  custom_fields: 'custom_fields',
  ebay: 'ebay',
};
