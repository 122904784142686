import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function deleteMarketingCopy(marketingCopyId: number) {
  return {
    type: 'DELETE_MARKETING_COPY',
    meta: {
      id: marketingCopyId,
    },
    payload: axios.delete(`${getApiRoot()}/parents/marketing_copies/${marketingCopyId}`),
  };
}

export function deleteGroup(groupId: number) {
  return {
    type: 'DELETE_GROUP',
    meta: {
      id: groupId,
    },
    payload: axios.delete(`${getApiRoot()}/parents/part_groups/${groupId}`),
  };
}

export function deleteSubGroup(groupId: number, subGroupId: number) {
  return {
    type: 'DELETE_SUB_GROUP',
    meta: {
      groupId,
      subGroupId,
    },
    payload: axios.delete(`${getApiRoot()}/parents/part_groups/sub_groups/${subGroupId}`),
  };
}

export function deleteMarketingCopyDigitalAsset(marketingCopyId: number) {
  return {
    type: 'DELETE_MARKETING_COPY_DIGITAL_ASSET',
    payload: axios.delete(
      `${getApiRoot()}/parents/marketing_copies/digital_assets/${marketingCopyId}`
    ),
  };
}
