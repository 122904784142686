import { Menu, Input, Badge, Tooltip } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Brand } from '../../../../types/brand';
import { Plan } from '../../../../types/channel';

type ChannelDrawerMenuProps = {
  brands: Brand[];
  plans: Plan[];
  create: boolean;
  selectedKey: string;
  handleMenuClick: (key: string) => void;
  disablePlanEdit?: boolean;
} & WithTranslation;

type ChanneDrawerMenuState = {
  keywords: string;
};
const MenuItemGroup = Menu.ItemGroup;

export class ChanneDrawerMenu extends React.Component<
  ChannelDrawerMenuProps,
  ChanneDrawerMenuState
> {
  constructor(props: ChannelDrawerMenuProps) {
    super(props);
    this.state = { keywords: '' };
  }

  handleClick = (key: string) => {
    this.props.handleMenuClick(key);
  };

  sortBrandsByPlanCount = () => {
    const { plans, brands } = this.props;

    const brandsCounts = brands.map(brand => {
      const brandPlans = plans.filter(plan => plan.brand_ids.includes(brand.id));
      return { ...brand, planCount: brandPlans.length };
    });
    const brandWithPlans = brandsCounts.filter(brand => brand.planCount > 0);
    const brandWithoutPlans = brandsCounts.filter(brand => brand.planCount === 0);

    return [
      ...brandWithPlans.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
      ...brandWithoutPlans.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
    ];
  };

  render() {
    const { t, create, selectedKey } = this.props;
    const { keywords } = this.state;
    const sortedBrands = this.sortBrandsByPlanCount();
    const filteredBrands = keywords
      ? sortedBrands.filter(brand => brand.name.toLowerCase().includes(keywords.toLowerCase()))
      : sortedBrands;

    return (
      <React.Fragment>
        <Menu
          onClick={({ key }) => this.handleClick(key.toString())}
          selectedKeys={[selectedKey]}
          mode="inline"
        >
          <Menu.Item key="channel">{t('channel:settings')}</Menu.Item>
          <MenuItemGroup title={t('channel:plan_other')} />
        </Menu>

        <div className="channel__drawer-menu-search">
          <Input.Search
            value={keywords}
            onChange={event => this.setState({ keywords: event.target.value })}
            placeholder={t('channel:filterBrands')}
            allowClear
            disabled={this.props.disablePlanEdit}
          />
        </div>

        <Menu
          className="flex-1 overflow-auto overflow-x-hidden"
          onClick={({ key }) => this.handleClick(key.toString())}
          selectedKeys={[selectedKey]}
          mode="inline"
        >
          {filteredBrands.map(brand => (
            <Menu.Item key={brand.id} disabled={create || this.props.disablePlanEdit}>
              <div className="flex">
                <span className="channel__drawer-menu-item flex-1">
                  <Tooltip title={`${brand.name} | ${brand.code}`}>
                    <span>{brand.name}</span>
                  </Tooltip>
                </span>
                {!!brand.planCount && (
                  <span className="inline-block">
                    <Badge
                      className="ant-blue-batch"
                      overflowCount={99999}
                      count={brand.planCount}
                    />
                  </span>
                )}
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ChanneDrawerMenu);
