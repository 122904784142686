import { combineReducers } from 'redux';
import applicationList, { AllApplicationsState } from './AllApplicationsReducer';

export type ApplicationListState = { applicationList: AllApplicationsState };

const AllApplicationsReducer = combineReducers<ApplicationListState>({
  applicationList,
});

export default AllApplicationsReducer;
