export const LoadingState = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADING_DONE: 'LOADING_DONE',
  LOADING_ERROR: 'LOADING_ERROR',
};

export function getSelectedBrandId(state) {
  return state.parent.brands.selectedBrandId;
}
