import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import startEditingPage from '../../../images/icons/start-editing-img.jpg';

type NoDataInfoProps = {
  handleAddNew: () => void;
  buttonName: string;
};

const NoDataInfo: React.FC<NoDataInfoProps> = ({ handleAddNew, buttonName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center package-start-edit">
      <img src={startEditingPage} alt="start-editing-img" />
      <span className="mt-2">
        <span>{t('packageSeg:startEdit')}</span>
        <Button
          type="primary"
          data-testid="add-first-package"
          ghost
          onClick={() => handleAddNew()}
          size="small"
        >
          <span>{buttonName}</span>
        </Button>
      </span>
    </div>
  );
};

export default NoDataInfo;
