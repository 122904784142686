import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Checkbox, Input, Spin } from 'antd';
import { List, AutoSizer } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { typingDone, nextPage } from '../../utils/Utils';

const DEFAULT_ROW_HEIGHT = 40;
type ListSearchProps = {
  showCount?: boolean;
  count?: number;
  title?: string;
  placeholder?: string;
  showSearch?: boolean;
  border?: boolean;
  className?: string;
  dataSource: any[];
  value?: string;
  dataSourceKey?: string;
  dataSourceValue?: string;
  rowHeight?: number;
  loading?: boolean;
  renderItem?: (data: any) => ReactElement;
  onSearchChange?: (value: string) => void;
  onClick?: (key: string | number, data: any) => void;
  onPagination?: (value: string | undefined, page: number) => Promise<void>;
};
const SelectList: React.FC<ListSearchProps> = ({
  showCount,
  count,
  className,
  title,
  placeholder,
  showSearch,
  border,
  onSearchChange,
  onPagination,
  onClick,
  dataSource,
  value,
  dataSourceKey = 'id',
  dataSourceValue = 'name',
  renderItem,
  rowHeight,
  loading,
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = React.useState('');
  const [scrollIndex, setScrollIndex] = React.useState(
    dataSource.findIndex(d => d[dataSourceKey] === value)
  );
  const [internalValueState, setInternalValueState] = React.useState(value);
  const [paginationFired, setPaginationFired] = React.useState(false);

  React.useEffect(() => {
    if (value !== internalValueState) {
      setInternalValueState(value);
      const indexNumber = dataSource.findIndex(d => d[dataSourceKey] === value);
      setScrollIndex(indexNumber);
    }
  }, [value, internalValueState, dataSourceKey, dataSource]);

  React.useEffect(() => {
    const updateIndex = dataSource.findIndex(d => d[dataSourceKey] === value);
    if (updateIndex !== scrollIndex) {
      setScrollIndex(updateIndex);
    }
  }, [dataSource, dataSourceKey, value, scrollIndex]);

  const handleSearchChange = (searchValue: string) => {
    setSearchInput(searchValue);
    typingDone(() => onSearchChange && onSearchChange(searchValue));
  };

  const handlePagination = (e: any) => {
    if (onPagination) {
      const page = nextPage(e, null, dataSource.length);
      if (page && !paginationFired) {
        setPaginationFired(true);
        onPagination(internalValueState, page).finally(() => setPaginationFired(false));
      }
    }
  };

  const rowRenderer = ({ key, index, style }: any) => {
    const data = dataSource[index];
    const dataValue = data[dataSourceKey];
    const isSelected = dataValue === internalValueState;
    return (
      <div
        style={style}
        key={key}
        onClick={() => {
          setInternalValueState(dataValue);
          if (onClick) onClick(dataValue, data);
        }}
        className={classnames('cursor-pointer flex items-center', {
          'bg-blue-200': isSelected,
          'hover:bg-blue-200 ': !isSelected,
          'border-solid border-gray-500 border-b border-l-0 border-r-0 border-t-0':
            border && dataSource.length - 1 !== index,
        })}
      >
        {renderItem ? (
          renderItem(dataSource[index])
        ) : (
          <div className={classnames('px-2 py-1 cursor-pointer')}>{data[dataSourceValue]}</div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col select-list h-full">
      {title && <div className="pb-1 font-medium">{title}</div>}
      <div className={classnames('flex flex-col overflow-hidden h-full', className)}>
        {showSearch && (
          <Input.Search
            data-testid={dataSourceValue}
            placeholder={placeholder}
            value={searchInput}
            onChange={e => handleSearchChange(e.target.value)}
            style={{ borderBottomLeftRadius: 0 }}
          />
        )}
        {showCount && (
          <span className="border border-solid border-gray-500 h-10 flex items-center pl-2">
            <Checkbox
              indeterminate={count !== 0 && count! < dataSource.length}
              checked={count !== 0 && count! === dataSource.length}
            >
              <span className="truncate ...">
                {t('gapCoverageReport:partTypeSelected', { count })}
              </span>
            </Checkbox>
          </span>
        )}
        <div
          className={classnames(
            'flex-1 border-solid border border-gray-500 rounded overflow-hidden',
            {
              'border-t-0 rounded-tl-none rounded-tr-none': showSearch,
            }
          )}
        >
          {loading && (
            <div className="flex pt-8 justify-center h-full">
              <Spin />
            </div>
          )}
          <AutoSizer>
            {({ width, height }) => (
              <List
                onScroll={handlePagination}
                width={width}
                height={height}
                rowCount={dataSource.length}
                rowRenderer={rowRenderer}
                rowHeight={rowHeight || DEFAULT_ROW_HEIGHT}
                scrollToIndex={scrollIndex}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default SelectList;
