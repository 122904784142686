import { createSelector } from 'reselect';
import resourcesMapping from '../../constants/ApplicationResourcesMapping.json';

const getApplicationResources = state => state.resources.applicationVehicle;
const getSubConfigAnalyses = state => state.brand.analyses.subConfigAnalyses;

export const subConfigAnalyses = createSelector(
  [getSubConfigAnalyses, getApplicationResources],
  (subConfigAnalyses, resources) => {
    if (!resources) return [];
    const getValues = (name, ids) => {
      const configName = resourcesMapping.hasOwnProperty(name) ? resourcesMapping[name] : name;
      return resources[configName].filter(value => ids.includes(value.id));
    };

    return subConfigAnalyses.map(analysis => {
      const analysisConfigs = {};
      Object.keys(analysis.details_json).forEach(key => {
        // remove first and last part ('valid_', '_id' )
        const configName = key.split('_').slice(1, -1).join('_');

        // pluralisation for resources matching
        let name = '';
        if (configName === 'brake_abs') name = 'brake_abs';
        else if (configName === 'engine_base_bore_inch') name = 'engine_base_bore_inches';
        else if (configName === 'engine_base_cubic_inch') name = 'engine_base_cubic_inches';
        else if (configName === 'engine_base_stroke_inch') name = 'engine_base_stroke_inches';
        else name = `${configName}s`;

        const prefix = key.split('_').slice(0, 1)[0];
        analysisConfigs[`${prefix}_${name}`] = getValues(name, analysis.details_json[key]);
      });

      return { ...analysis, details_json: analysisConfigs };
    });
  }
);
