import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

type warehouseDistributorType = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  receiver_name: string;
  type_id: number;
};

export function addWarehouseDistributor(params: warehouseDistributorType) {
  return {
    type: 'ADD_WAREHOUSE_DISTRIBUTOR',
    payload: axios.post(`${getApiRoot()}/receivers/warehouse_distributers`, params),
  };
}

export function fetchCompanyProfile() {
  return {
    type: 'FETCH_COMPANY_PROFILE',
    payload: axios.get(`${getApiRoot()}/accounts/receivers`),
  };
}

export function updateCompanyProfile(companyDetails: any) {
  return {
    type: 'UPDATE_COMPANY_PROFILE',
    payload: axios.patch(`${getApiRoot()}/accounts/receivers`, companyDetails),
  };
}
