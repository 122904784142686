import React from 'react';
import { Popover, Card, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../global/Forms/FormInput';

type VcdbAddConfigPopoverProps = {
  keywords?: string;
  handleSave: (name: string) => Promise<any>;
};

const VcdbAddConfigPopover: React.FC<VcdbAddConfigPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation:required')),
  });

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => {
        setPopoverVisible(visible);
      }}
      overlayClassName="help application_listing__popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="qualifier__value-popover">
          <Formik
            initialValues={{
              name: props.keywords || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props
                .handleSave(values.name)
                .then(() => {
                  setSubmitting(false);
                  setPopoverVisible(false);
                })
                .catch(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <div>
                <Form layout="vertical">
                  <FormInput
                    name="name"
                    label={t('application:vcdbAddCustom')}
                    maxLength={100}
                    required
                  />
                </Form>

                <div className="flex-col items-end">
                  <div>
                    <Button
                      onClick={() => setPopoverVisible(false)}
                      className="drawer-submit__bottom-cancel"
                    >
                      {t('common:cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      {t('common:save')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      <Button size="small">{t('common:add')}</Button>
    </Popover>
  );
};

export default VcdbAddConfigPopover;
