import { Reducer } from 'redux';

export type AppGlobalState = {
  readonly currentScriptName: string | undefined;
  readonly newVersion: boolean;
  readonly registrationResources: { [key: string]: any }[] | undefined;
  readonly mainMenuCollapsed: boolean;
  readonly inAppArea: string | undefined;
  readonly fetchingRegistrationResources: boolean;
};

const initialState: AppGlobalState = {
  currentScriptName: undefined,
  newVersion: false,
  registrationResources: undefined,
  mainMenuCollapsed: false,
  inAppArea: undefined,
  fetchingRegistrationResources: false,
};

const reducer: Reducer<AppGlobalState> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SCRIPT_NAME': {
      return { ...state, currentScriptName: action.payload.scriptName };
    }
    case 'SET_MENU_STATE': {
      return { ...state, mainMenuCollapsed: action.payload.state };
    }
    case 'SET_IN_APP_AREA': {
      return { ...state, inAppArea: action.payload.area };
    }
    case 'CHECK_VERSION_UPDATE_FULFILLED': {
      const indexHtml = action.payload.data;
      const newVersion = !indexHtml.includes(state.currentScriptName);
      return { ...state, newVersion };
    }
    case 'FETCH_REGISTRATION_RESOURCES_PENDING': {
      return {
        ...state,
        fetchingRegistrationResources: true,
      };
    }
    case 'FETCH_REGISTRATION_RESOURCES_FULFILLED': {
      return {
        ...state,
        registrationResources: action.payload.data,
        fetchingRegistrationResources: false,
      };
    }
  }
  return state;
};

export default reducer;
