import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Badge } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { InfoCircleOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, WithRouterProps } from '../../containers/withRouter';
import constants from '../../constants/ValidationTranslation.json';
import InboxContainer from '../../containers/inbox/InboxContainer';
import { withBrandRouteWrapper } from '../../containers/BrandRouteWrapper';
import brandAnalysesActions from '../../actions/brand/analyses';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import { AnalysisCount } from '../../../types/analyses';
import { UserType } from '../../../types/user';
import {
  PART_TYPE_ANALYSIS_IDS,
  NEW_YEAR_ANALYSIS_ID,
  ENGINE_BASE_ANALYSIS_ID,
  NEW_ENGINE_ANALYSIS_ID,
  NEW_TRANSMISSION_ANALYSIS_ID,
} from '../../constants/InboxConstants.js';
import AntTooltip from '../../components/global/AntTooltip';
import { hasPermission } from '../../utils/Permissions';

const { Content, Sider } = Layout;

type BrandValidationRoutesProps = {
  dispatch: AsyncDispatch;
  brandId: number;
  analysesCount: AnalysisCount[];
  user: UserType;
} & WithRouterProps &
  WithTranslation;

class BrandValidationRoutes extends React.Component<BrandValidationRoutesProps> {
  componentDidMount() {
    this.props.dispatch(brandAnalysesActions.fetchAnalysesCount(this.props.brandId));
  }

  render() {
    const values = queryString.parse(this.props.location.search);
    const { t, location, analysesCount, user } = this.props;
    const { brandId } = values;

    const partTypeCount = analysesCount
      .filter(obj => PART_TYPE_ANALYSIS_IDS.includes(obj.type_id))
      .reduce((prev, key) => prev + key.item_count, 0);
    const newYear = analysesCount.find(obj => obj.type_id === NEW_YEAR_ANALYSIS_ID);
    const engineBase = analysesCount.find(obj => obj.type_id === ENGINE_BASE_ANALYSIS_ID);
    const newEngine = analysesCount.find(obj => obj.type_id === NEW_ENGINE_ANALYSIS_ID);
    const newTransmission = analysesCount.find(obj => obj.type_id === NEW_TRANSMISSION_ANALYSIS_ID);
    // const subConfig = analysesCount.find(
    //   obj => obj.type_id === SUB_CONFIG_ANALYSIS_ID
    // );

    const runningOnGoApi = hasPermission(user, 'running_on_go_api');

    return (
      <Layout className="layout">
        <Sider theme="light">
          <Menu
            mode="inline"
            // onClick={this.handleMenuClick}
            selectedKeys={[location.pathname]}
          >
            <Menu.Item key="/brand/validation/part-type">
              <Link
                to={{
                  pathname: `part-type`,
                  search: `?brandId=${brandId}`,
                }}
              >
                <div className="sidebar-menu__with-badge">
                  <span> {constants.inbox.partType}</span>
                  <Badge count={partTypeCount} className="badge-small" overflowCount={10000} />
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/validation/new-year">
              <Link
                to={{
                  pathname: `new-year`,
                  search: `?brandId=${brandId}`,
                }}
              >
                <div className="sidebar-menu__with-badge">
                  <span> {constants.inbox.newYear}</span>
                  {newYear && (
                    <Badge
                      count={newYear.item_count}
                      className="badge-small"
                      overflowCount={10000}
                    />
                  )}
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="/brand/validation/engine-base">
              <Link
                to={{
                  pathname: `engine-base`,
                  search: `?brandId=${brandId}`,
                }}
              >
                <div className="sidebar-menu__with-badge">
                  <span>{constants.inbox.engineBase}</span>
                  {engineBase && (
                    <Badge
                      count={engineBase.item_count}
                      className="badge-small"
                      overflowCount={10000}
                    />
                  )}
                </div>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="/brand/validation/sub-config">
                <Link
                  to={{
                    pathname: `sub-config`,
                    search: `?brandId=${brandId}`,
                  }}
                >
                  <div className="sidebar-menu__with-badge">
                    <span> {constants.inbox.subConfig}</span>
                    {subConfig && (
                      <Badge
                        count={subConfig.item_count}
                        className="badge-small"
                        overflowCount={10000}
                      />
                    )}
                  </div>
                </Link>
              </Menu.Item> */}
            <Menu.Item key="/brand/validation/new-engine" disabled={!runningOnGoApi}>
              <Link
                to={{
                  pathname: `new-engine`,
                  search: `?brandId=${brandId}`,
                }}
              >
                <div className="sidebar-menu__with-badge">
                  <span>{constants.inbox.newEngine}</span>
                  {newEngine && (
                    <Badge
                      count={newEngine.item_count}
                      className="badge-small"
                      overflowCount={10000}
                    />
                  )}
                </div>
              </Link>
              {!runningOnGoApi && (
                <AntTooltip
                  title={t('common:tempNotAvailable')}
                  className="absolute top-3 left-1.5"
                >
                  <InfoCircleOutlined className="ant-info-tooltip" />
                </AntTooltip>
              )}
            </Menu.Item>
            <Menu.Item key="/brand/validation/new-transmission" disabled={!runningOnGoApi}>
              <Link
                className="flex-1"
                to={{
                  pathname: `new-transmission`,
                  search: `?brandId=${brandId}`,
                }}
              >
                <div className="sidebar-menu__with-badge">
                  <span>{constants.inbox.newTransmission}</span>
                  {newTransmission && (
                    <Badge
                      count={newTransmission.item_count}
                      className="badge-small"
                      overflowCount={10000}
                    />
                  )}
                </div>
              </Link>
              {!runningOnGoApi && (
                <AntTooltip
                  title={t('common:tempNotAvailable')}
                  className="absolute top-3 left-1.5"
                >
                  <InfoCircleOutlined className="ant-info-tooltip" />
                </AntTooltip>
              )}
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <InboxContainer
            // @ts-ignore
            routePathname={this.props.location.pathname}
          />
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  brandId: state.parent.brands.selectedBrandId,
  analysesCount: state.brand.analyses.analysesCount,
});

export default withRouter(
  connect(mapStateToProps)(withBrandRouteWrapper(withTranslation()(BrandValidationRoutes)))
);
