import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FastField, FieldProps } from 'formik';
import { get as _get } from 'lodash';

type EBPFilterFieldProps = {
  handleChange: (valAsArray: string[]) => void;
  name: string;
  options: string[];
  label: string;
};

const EBPFilterField: React.FC<EBPFilterFieldProps> = ({ name, options, handleChange, label }) => {
  const { t } = useTranslation();

  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => {
        const { errors, touched } = form;
        const showValidationInfo = !!(_get(errors, name) && _get(touched, name));

        return (
          <Form.Item
            label={label}
            className="eba__identifier w-1/2"
            key={field.value.id}
            hasFeedback
            validateStatus={showValidationInfo ? 'error' : ''}
            help={showValidationInfo ? t('validation:required') : undefined}
          >
            <Select
              mode="multiple"
              placeholder={label}
              className="w-64"
              value={field.value.values}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                return (
                  option?.props.children
                    // @ts-ignore
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={val => {
                const clearedVal = val === undefined ? [] : val;
                const valAsArray = Array.isArray(clearedVal) ? val : [val];
                handleChange(valAsArray);
              }}
              allowClear
            >
              {options.map(option => (
                <Select.Option
                  key={`${option}`}
                  style={{
                    fontWeight: option === '<no value set>' ? 700 : 'inherit',
                  }}
                  title={option}
                  value={option}
                >
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    </FastField>
  );
};

export default EBPFilterField;
