import React from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import ItemAnalysisContainer from '../../../containers/item_analysis/ItemAnalysisContainer';

type PageProps = {
  renderHeader?: JSX.Element;
  renderBottom?: JSX.Element | null;
  showAnalysis?: boolean;
  contentNoSpacing?: boolean;
  contentNoScroll?: boolean;
  showExpandButton?: boolean;
};

const Page: React.FC<PageProps> = props => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const renderPage = () => (
    <React.Fragment>
      {(props.renderHeader || props.showAnalysis || props.showExpandButton) && (
        <div className="page__header flex justify-between items-center">
          {props.showAnalysis && <ItemAnalysisContainer />}
          {props.renderHeader && props.renderHeader}
          <Button
            icon={!showModal ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
            onClick={() => setShowModal(!showModal)}
            type="text"
          />
        </div>
      )}
      <div
        className={classNames('page__content flex-1', {
          'no-spacing': props.contentNoSpacing,
          'no-scroll': props.contentNoScroll,
        })}
      >
        {props.children}
      </div>
      <AnimatePresence>
        {props.renderBottom && (
          <motion.div
            key="save-frame"
            initial={{
              opacity: 0,
              height: 0,
              y: 50,
            }}
            animate={{
              opacity: 1,
              height: 50,
              y: 0,
            }}
            exit={{
              opacity: 0,
              height: 0,
              y: 50,
            }}
            className="flex page__bottom"
          >
            {props.renderBottom}
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );

  if (showModal)
    return (
      <Modal
        open={showModal}
        footer={null}
        closable={false}
        width="100%"
        keyboard
        onCancel={() => setShowModal(false)}
        className="page__modal"
        wrapClassName="page__modal-wrapper"
      >
        {renderPage()}
      </Modal>
    );

  return <div className="flex-col page">{renderPage()}</div>;
};

export default Page;
