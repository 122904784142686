import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Years } from '../../../types/application';
import {
  ExportErrorLogDetail,
  ExportLog,
  ImportBrandStatus,
  ImportErrorLogDetail,
  ImportLog,
  ImportOptions,
  ScheduledImports,
} from '../../../types/import_export';

export type ImportExportState = {
  importLogs: ImportLog[];
  exportLogs: ExportLog[];
  fetchingImportLogs: boolean;
  fetchingExportLogs: boolean;
  importBrandStatus: ImportBrandStatus;
  importErrorLogDetail: ImportErrorLogDetail[];
  exportErrorLogDetail: ExportErrorLogDetail[];
  fetchingImportErrors: boolean;
  fetchingExportErrors: boolean;
  fetchingYears: boolean;
  years: Years[];
  scheduledImports?: ScheduledImports;
  fetchingScheduledImports: boolean;
  fetchingImportOptions: boolean;
  importOptions: ImportOptions[];
};

const initialState = {
  importLogs: [],
  exportLogs: [],
  fetchingImportLogs: false,
  fetchingExportLogs: false,
  importBrandStatus: {},
  importErrorLogDetail: [],
  exportErrorLogDetail: [],
  fetchingImportErrors: false,
  fetchingExportErrors: false,
  fetchingYears: false,
  scheduledImports: undefined,
  years: [],
  fetchingScheduledImports: false,
  fetchingImportOptions: false,
  importOptions: [],
};

const reducer: Reducer<ImportExportState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_IMPORT_LOGS_PENDING': {
      const importLogs = action.meta.page === 1 ? [] : state.importLogs;
      return { ...state, importLogs, fetchingImportLogs: true };
    }
    case 'FETCH_IMPORT_LOGS_FULFILLED': {
      const logs = action.payload.data;
      const importLogs = [...state.importLogs, ...logs];
      return {
        ...state,
        importLogs,
        fetchingImportLogs: false,
      };
    }
    case '@IMPORTER/FETCH_RUNNING_IMPORT_LOGS_FULFILLED': {
      return {
        ...state,
        importLogs: state.importLogs.map(log => {
          const newLog = action.payload.data.find(({ id }: any) => id === log.id);
          if (newLog) return newLog;
          return log;
        }),
      };
    }
    case '@EXPORTER/FETCH_RUNNING_EXPORT_LOGS_FULFILLED': {
      return {
        ...state,
        exportLogs: state.exportLogs.map(log => {
          const newLog = action.payload.data.find(({ id }: any) => id === log.id);
          if (newLog) return newLog;
          return log;
        }),
      };
    }
    case 'FETCH_EXPORT_LOGS_PENDING': {
      const exportLogs = action.meta.page === 1 ? [] : state.exportLogs;
      return { ...state, exportLogs, fetchingExportLogs: true };
    }
    case 'FETCH_EXPORT_LOGS_FULFILLED': {
      const logs = action.payload.data;
      const exportLogs = [...state.exportLogs, ...logs];
      return {
        ...state,
        exportLogs,
        fetchingExportLogs: false,
      };
    }
    case 'FETCH_EXPORT_LOGS_DETAILS_JSON_PENDING': {
      return {
        ...state,
        exportErrorLogDetail: [],
        fetchingExportErrors: true,
      };
    }
    case 'FETCH_EXPORT_LOGS_DETAILS_JSON_FULFILLED': {
      return {
        ...state,
        exportErrorLogDetail: action.payload.data,
        fetchingExportErrors: false,
      };
    }
    case 'FETCH_IMPORT_LOGS_DETAILS_JSON_PENDING': {
      return { ...state, importErrorLogDetail: [], fetchingImportErrors: true };
    }
    case 'FETCH_IMPORT_LOGS_DETAILS_JSON_FULFILLED': {
      return { ...state, importErrorLogDetail: action.payload.data, fetchingImportErrors: false };
    }
    case 'FETCH_IMPORT_BRAND_STATUS_FULFILLED': {
      return { ...state, importBrandStatus: action.payload.data };
    }
    case 'FETCH_GAP_COVERAGE_APPLICATION_YEARS_PENDING': {
      return { ...state, fetchingYears: true, years: [] };
    }
    case 'FETCH_GAP_COVERAGE_APPLICATION_YEARS_REJECTED': {
      return { ...state, fetchingYears: false };
    }
    case 'FETCH_GAP_COVERAGE_APPLICATION_YEARS_FULFILLED': {
      return { ...state, fetchingYears: false, years: action.payload.data };
    }
    case 'FETCH_SCHEDULED_IMPORTS_PENDING': {
      return { ...state, scheduledImports: undefined, fetchingScheduledImports: true };
    }
    case 'FETCH_SCHEDULED_IMPORTS_FULFILLED': {
      return { ...state, scheduledImports: action.payload.data, fetchingScheduledImports: false };
    }
    case 'UPDATE_SCHEDULED_IMPORTS_PENDING': {
      return { ...state, scheduledImports: action.meta };
    }
    case 'FETCH_IMPORT_OPTIONS_PENDING': {
      return { ...state, fetchingImportOptions: true, importOptions: [] };
    }
    case 'FETCH_IMPORT_OPTIONS_FULFILLED': {
      return { ...state, fetchingImportOptions: false, importOptions: action.payload.data };
    }
  }
  return state;
};

export default reducer;
