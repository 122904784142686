import React from 'react';
import classNames from 'classnames';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

type SimpleDividerProps = {
  sortDirection?: string;
};

const TableSortIcons: React.FC<SimpleDividerProps> = ({ sortDirection }) => {
  const upNode = (
    <CaretUpOutlined
      style={{ fontSize: '11px', display: 'inline-block' }}
      className={classNames(`ant-table-column-sorter-up`, {
        'text-blue-600': sortDirection === 'ASC',
      })}
    />
  );

  const downNode = (
    <CaretDownOutlined
      style={{ fontSize: '11px', display: 'inline-block', marginTop: '-.3em' }}
      className={classNames(`ant-table-column-sorter-down`, {
        'text-blue-600': sortDirection === 'DESC',
      })}
    />
  );

  return (
    <span className="inline-flex flex-col text-gray-600 ml-1">
      {upNode}
      {downNode}
    </span>
  );
};

export default TableSortIcons;
