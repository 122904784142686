import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function deleteAccountsLanguage(accountLanguageid: number) {
  const urlParams = utils.createURL([{ name: 'account_language_ids', values: accountLanguageid }]);
  return {
    type: 'DELETE_ACCOUNT_LANGUAGE',
    payload: axios.delete(`${getApiRoot()}/accounts/languages${urlParams}`),
    meta: { accountLanguageid },
  };
}

export function deleteTranslations({
  translationTypeId,
  ids,
}: {
  translationTypeId: number;
  ids: number[];
}) {
  const urlParams = utils.createURL([
    { name: 'translation_type_id', values: translationTypeId },
    { name: 'ids', values: ids },
  ]);
  return {
    type: 'DELETE_TRANSLATIONS',
    meta: { deleteTranslationIds: ids },
    payload: axios.delete(`${getApiRoot()}/accounts/languages/translations${urlParams}`),
  };
}
