import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RankedValue, Years } from '../../../../types/application';
import { StandardResource } from '../../../../types/resources';
import ApplicationColumn from './ApplicationColumn';

type ApplicationVehicleBaseProps = {
  ranks: { [key: string]: RankedValue[] };
  configKeysVehicle: string[];
  getRecommendations: boolean;
  selectedMakes: number[];
  selectedModels: number[];
  years?: Years[];
  selectedYears: number[];
  selectedSubmodels: number[];
  selectedRegions: number[];
  selectedTypes: number[];
  selectedGroups: number[];
  filterView: boolean;
  fetchingYears: boolean;
  selectMake: (makeId: number, addValues: boolean) => void;
  selectModel: (modelId: number, addValues?: boolean) => void;
  selectType: (id: number) => void;
  selectGroup: (id: number) => void;
  selectYears: (year: number | number[], addValues: boolean, save?: boolean) => void;
  selectSubmodel: (ids: any, addValues: boolean, save?: boolean) => void;
  selectRegion: (ids: any, addValues: boolean, save?: boolean) => void;
  addCustomValue?: (configName: string, value: string, group?: string) => Promise<any>;
  deleteCustomValue?: (configName: string, id: number) => Promise<any>;
  allSubconfigValues: (config: string) => StandardResource[];
} & WithTranslation;

type ApplicationVehicleBaseState = {
  sortSubmodel: boolean;
  sortRegion: boolean;
  vehicleTypes: any;
};

class ApplicationVehicleBase extends React.Component<
  ApplicationVehicleBaseProps,
  ApplicationVehicleBaseState
> {
  submodels: any;

  regions: any;

  constructor(props: ApplicationVehicleBaseProps) {
    super(props);
    this.state = {
      sortSubmodel: true,
      sortRegion: true,
      vehicleTypes: props.allSubconfigValues('vehicle_types'),
    };
  }

  componentDidUpdate() {
    if (this.state.vehicleTypes !== this.props.allSubconfigValues('vehicle_types')) {
      this.setState({ vehicleTypes: this.props.allSubconfigValues('vehicle_types') });
    }
  }

  selectSubmodel = (submodels: any, addValues: boolean, save?: boolean) => {
    this.props.selectSubmodel(submodels, addValues, save);
    if (save === false) this.setState({ sortSubmodel: false });
    else if (!this.state.sortSubmodel) this.setState({ sortSubmodel: true });
  };

  getYearsIncludingSelection = () => {
    const { years, selectedYears } = this.props;
    if (selectedYears.length) {
      const missingSelectedYears = selectedYears
        .filter(y => !years?.find(year => year.id === y))
        .map(y => ({ id: y, name: y }));
      const allYears = [...years!, ...missingSelectedYears].sort((a, b) => (a.id > b.id ? 1 : -1));
      return allYears;
    }

    return years!;
  };

  submodelRows = (rankedValues: any) => {
    const { selectedSubmodels, allSubconfigValues } = this.props;

    if (this.state.sortSubmodel) {
      // temporary store submodels to keep the order while multiselecting
      this.submodels = this.rows(
        allSubconfigValues('sub_models'),
        rankedValues,
        selectedSubmodels,
        true
      );
    } else {
      this.submodels = this.submodels.map((value: { mapped: boolean; id: number }) => {
        value.mapped = !!selectedSubmodels.find(id => id === value.id);
        return value;
      });
    }
    return this.submodels;
  };

  selectRegion = (regions: any, addValues: boolean, save?: boolean) => {
    this.props.selectRegion(regions, addValues, save);
    if (save === false) this.setState({ sortRegion: false });
    else if (!this.state.sortRegion) this.setState({ sortRegion: true });
  };

  regionRows = (rankedValues: any) => {
    const { selectedRegions, allSubconfigValues } = this.props;

    if (this.state.sortRegion) {
      // temporary store regions to keep the order while multiselecting
      this.regions = this.rows(allSubconfigValues('regions'), rankedValues, selectedRegions, true);
    } else {
      this.regions = this.regions.map((value: { mapped: boolean; id: number }) => {
        value.mapped = !!selectedRegions.find(id => id === value.id);
        return value;
      });
    }
    return this.regions;
  };

  rows = (
    values: { [x: string]: any }[],
    rankedValues: any[],
    selectedValues: number[],
    sort: boolean,
    divide?: boolean
  ) => {
    // invalid selected years can be missing in filtered model year list
    const allValues = rankedValues.length > values.length ? rankedValues : values;

    const rows = allValues.map(({ ...value }) => {
      const rankedValue = rankedValues.find(rankedVal => rankedVal.id === value.id);
      if (rankedValue) value.rank = rankedValue.rank;
      if (!value.rank) value.rank = 3;
      value.mapped = !!selectedValues.find(id => id === value.id);

      return value;
    });

    if (sort) {
      rows.sort((a, b) => {
        // if both elements are mapped sort them by name, otherwise move the mapped element up
        if (a.mapped === b.mapped) {
          // if both elements have the same rank sort them by name, otherwise move the lower rank up
          if (a.rank === b.rank) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        }
        if (a.mapped) return -1;
        if (b.mapped) return 1;
        return 0;
      });
    }

    if (divide) return this.orderRank1ByUsedByBrand(rows);

    return rows;
  };

  orderRank1ByUsedByBrand = (rows: any[]) => {
    rows.sort(
      (
        a: { mapped: any; rank: number; used_by_brand: any; name: number },
        b: { mapped: any; rank: number; used_by_brand: any; name: number }
      ) => {
        // if both elements are mapped sort them by name, otherwise move the mapped element up
        if (a.mapped === b.mapped) {
          // if both elements have the same rank sort them by name, otherwise move the lower rank up
          if (a.rank === b.rank) {
            if (
              a.used_by_brand === b.used_by_brand ||
              (a.used_by_brand && b.used_by_brand) ||
              (this.props.getRecommendations && a.rank === 3)
            ) {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }
            if (a.used_by_brand) return -1;
            if (b.used_by_brand) return 1;
            return 0;
          }
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        }
        if (a.mapped) return -1;
        if (b.mapped) return 1;
        return 0;
      }
    );
    return rows;
  };

  render() {
    const {
      t,
      configKeysVehicle,
      allSubconfigValues,
      selectedMakes,
      selectedModels,
      selectedYears,
      selectedTypes,
      selectedGroups,
      years,
      fetchingYears,
      filterView,
    } = this.props;
    const selectedSubconfigsVehicle: { [key: string]: RankedValue[] } = {};
    configKeysVehicle.forEach(key => {
      selectedSubconfigsVehicle[key] = this.props.ranks[key] || [];
    });

    const ranks =
      Object.keys(selectedSubconfigsVehicle).length > 1
        ? selectedSubconfigsVehicle
        : { makes: [], models: [], regions: [], sub_models: [], years: [] };

    const yearResources =
      (years && years.length === 0 && !fetchingYears) || !years
        ? allSubconfigValues('years')
        : this.getYearsIncludingSelection();

    return (
      <div className="application__make-model-year">
        <div className="float__left h-full w-1/2">
          <div
            className={classNames('float__left application__make-model-wrapper', {
              'application__make-model-vcdb': filterView,
            })}
          >
            <ApplicationColumn
              rows={this.rows(allSubconfigValues('makes'), ranks.makes, selectedMakes, true, true)}
              getRecommendations={this.props.getRecommendations}
              selected={this.props.selectedMakes}
              submit={(id, addValues) =>
                this.props.selectMake(typeof id === 'number' ? id : id[0], addValues)
              }
              cmdKeyMultiselect={this.props.filterView} // allow multiselect for filter
              addCustomValue={this.props.addCustomValue}
              deleteCustomValue={this.props.deleteCustomValue}
              subconfigName="makes"
              divider
              title="Make"
            />
            <ApplicationColumn
              rows={this.rows(
                allSubconfigValues('models'),
                ranks.models,
                selectedModels,
                true,
                true
              )}
              getRecommendations={this.props.getRecommendations}
              selected={this.props.selectedModels}
              submit={(id, addValues) =>
                this.props.selectModel(typeof id === 'number' ? id : id[0], addValues)
              }
              cmdKeyMultiselect={this.props.filterView} // allow multiselect for filter
              addCustomValue={this.props.addCustomValue}
              deleteCustomValue={this.props.deleteCustomValue}
              subconfigName="models"
              divider
              title="Model"
            />
          </div>
          {filterView && (
            <div className="float__left application__type-wrapper">
              <ApplicationColumn
                rows={this.rows(
                  allSubconfigValues('vehicle_types'),
                  ranks.vehicle_types || [],
                  selectedTypes,
                  true,
                  true
                )}
                getRecommendations={this.props.getRecommendations}
                selected={selectedTypes}
                submit={id => this.props.selectType(typeof id === 'number' ? id : id[0])}
                cmdKeyMultiselect={this.props.filterView} // allow multiselect for filter
                subconfigName="vehicle_types"
                divider
                title={t('application:vehicleType')}
              />
              <ApplicationColumn
                rows={this.rows(
                  allSubconfigValues('vehicle_type_groups'),
                  ranks.vehicle_type_groups || [],
                  selectedGroups,
                  true,
                  true
                )}
                getRecommendations={this.props.getRecommendations}
                selected={selectedGroups}
                submit={id => this.props.selectGroup(typeof id === 'number' ? id : id[0])}
                cmdKeyMultiselect={this.props.filterView} // allow multiselect for filter
                subconfigName="vehicle_type_groups"
                divider
                title={t('application:vehicleGroup')}
              />
            </div>
          )}
        </div>
        <ApplicationColumn
          rows={this.rows(yearResources, ranks.years || [], selectedYears, false, false)}
          getRecommendations={this.props.getRecommendations}
          selected={this.props.selectedYears}
          submit={this.props.selectYears}
          fetching={this.props.fetchingYears}
          addCustomValue={this.props.addCustomValue}
          deleteCustomValue={this.props.deleteCustomValue}
          subconfigName="years"
          multiselect
          cmdKeyMultiselect
          scrollToFirstElement
          title="Year"
        />
        <div className="application__submodel-region float__left">
          <div className="application__submodel">
            <ApplicationColumn
              rows={this.submodelRows(ranks.sub_models)}
              getRecommendations={this.props.getRecommendations}
              selected={this.props.selectedSubmodels}
              submit={this.selectSubmodel}
              multiselect={!this.props.filterView}
              addCustomValue={this.props.addCustomValue}
              deleteCustomValue={this.props.deleteCustomValue}
              subconfigName="sub_models"
              cmdKeyMultiselect
              title="Submodel"
            />
          </div>
          <div className="application__region">
            <ApplicationColumn
              rows={this.regionRows(ranks.regions)}
              getRecommendations={this.props.getRecommendations}
              selected={this.props.selectedRegions}
              submit={this.selectRegion}
              multiselect={!this.props.filterView}
              addCustomValue={this.props.addCustomValue}
              deleteCustomValue={this.props.deleteCustomValue}
              subconfigName="regions"
              cmdKeyMultiselect
              title="Region"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ApplicationVehicleBase);
