import { Switch } from 'antd';
import React from 'react';
import classNames from 'classnames';
import SimpleDivider from './SimpleDivider';

type SwitchDividerProps = {
  className?: string;
  title: string;
  titlePosition?: 'left' | 'right' | 'center';
  switchTag?: string;
  checked: boolean;
  disabled?: boolean;
  handleChange: (checked: boolean) => void;
};

const SwitchDivider: React.FC<SwitchDividerProps> = ({
  className,
  title,
  switchTag,
  checked,
  disabled,
  titlePosition,
  handleChange,
}) => {
  return (
    <div className={classNames(className, 'antdivider')}>
      <div className="antdivider__line">
        <SimpleDivider title={title} titlePosition={titlePosition} />
      </div>
      <div className="antdivider__toggle">
        <span className="channel__switch-tag">{switchTag}</span>
        <Switch
          size="small"
          disabled={disabled}
          checked={checked}
          onChange={(checked: boolean) => handleChange(checked)}
        />
      </div>
    </div>
  );
};

export default SwitchDivider;
