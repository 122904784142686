import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';

type ValidationExplanationProps = {
  hideDuplicate?: boolean;
  hideGapValidation?: boolean;
};

const ValidationExplanation: React.FC<ValidationExplanationProps> = ({
  hideDuplicate,
  hideGapValidation,
}) => {
  const { t } = useTranslation();

  const selectedBrandId = useSelector(
    (state: ApplicationState) => state.parent.brands.selectedBrandId
  );

  return (
    <div className="m-10 mt-2 max-w-3xl">
      <Typography.Title className="application_listing__explanation-title" level={4}>
        {t('applicationValidation:vcdbHeader')}
      </Typography.Title>
      <Typography.Text>
        {t('applicationValidation:vcdbExplanation')}
        <Link
          to={{
            pathname: '/brand/settings/auto-care-table-version',
            search: `?brandId=${selectedBrandId}`,
          }}
        >
          {` ${t('applicationValidation:vcdbExplanationLink')}`}
        </Link>
        {`. ${t('applicationValidation:vcdbExplanation2')}`}
      </Typography.Text>

      {!hideDuplicate && (
        <React.Fragment>
          <Typography.Title className="application_listing__explanation-title" level={4}>
            {t('applicationValidation:duplicateHeader')}
          </Typography.Title>
          <Typography.Text>{t('applicationValidation:duplicateExplanation')}</Typography.Text>
        </React.Fragment>
      )}

      <Typography.Title className="application_listing__explanation-title" level={4}>
        {t('applicationValidation:overlapHeader')}
      </Typography.Title>
      <Typography.Text>{t('applicationValidation:overlapExplanation')}</Typography.Text>

      {!hideGapValidation && (
        <React.Fragment>
          <Typography.Title className="application_listing__explanation-title" level={4}>
            {t('applicationValidation:coverageHeader')}
          </Typography.Title>
          <Typography.Text>{t('applicationValidation:coverageExplanation')}</Typography.Text>
        </React.Fragment>
      )}
    </div>
  );
};

export default ValidationExplanation;
