import axios from 'axios';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import { powerBiApiUrl, reportIdUserTest } from '../../containers/insights/InsightsConfig';

export function fetchInsightsAppToken() {
  return {
    type: 'FETCH_INSIGHTS_APP_TOKEN',
    payload: axios.get(`${getApiRoot()}/microsoft/access_token`),
  };
}

export function fetchInsightsEmbedToken(
  workspaceId: string,
  reportId: string,
  token: string,
  dataset: string,
  accountId: string,
  accountName: string,
  rls: boolean
) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return {
    type: 'FETCH_INSIGHTS_EMBED_TOKEN',
    payload: axios.post(
      `${powerBiApiUrl}v1.0/myorg/groups/${workspaceId}/reports/${reportId}/GenerateToken`,
      {
        accessLevel: 'View',
        ...(rls && {
          identities: [
            {
              username: accountId,
              roles: ['RLS_ACCOUNT_ID'],
              datasets: [dataset],
            },
          ],
        }),
      },
      config
    ),
  };
}

export function fetchInsightsEmbedData(workspaceId: string, reportId: string, token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return {
    type: 'FETCH_INSIGHTS_EMBED_DATA',
    payload: axios.get(
      `${powerBiApiUrl}v1.0/myorg/groups/${workspaceId}/reports/${reportId}`,
      config
    ),
  };
}

export function fetchInsightsEmbedTokenUsage(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return {
    type: 'FETCH_INSIGHTS_EMBED_TOKEN_USAGE',
    payload: axios.get(
      `https://api.powerbi.com/v1.0/myorg/availableFeatures(featureName='embedTrial')`,
      config
    ),
  };
}
