import React, { memo } from 'react';
import classNames from 'classnames';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';
import { Attribute } from '../../../../types/attributes';
import AttributeValues from './AttributeValues';
import AttributeValuePopover from './AttributeValuePopover';

type AttributeValueCellProps = {
  itemId: number;
  rowData: Attribute;
  analyses: (Analysis & AnalysisType)[];
  multiItemSelect: boolean;
  selected: boolean;
  copied: boolean;
  rowIndex: number;
  columnIndex: number;
  handleValueChange: (props: {
    valueId: number | null;
    prevValueId?: number;
    uniqueId?: string;
    uomlabel: string;
    uomId: number | null;
    valueName: string;
    attributeId: number | string;
    itemId: number;
  }) => void;
  handleAddValue: (data: {
    attributeId: number | string;
    uomId: number | null;
    uomlabel: string;
    itemIds: [number];
    valueId: number | null;
    valueName: string;
  }) => void;
  handleCellSelection: (rowIndex: number, columnIndex: number, e: React.MouseEvent) => void;
};

const AttributeValueCell: React.FC<AttributeValueCellProps> = ({
  itemId,
  rowData,
  analyses,
  multiItemSelect,
  selected,
  copied,
  rowIndex,
  columnIndex,
  handleValueChange,
  handleAddValue,
  handleCellSelection,
}) => {
  return (
    <React.Fragment>
      <div
        className={classNames('pl-2 flex items-center attribute__values-cell', {
          'attributes__selected-cell': selected,
          'attributes__copied-cell': copied,
        })}
        onClick={e => {
          if (multiItemSelect) handleCellSelection(rowIndex, columnIndex, e);
          e.stopPropagation();
        }}
      >
        <AttributeValues
          itemId={itemId}
          rowData={rowData}
          handleValueChange={props =>
            handleValueChange({ ...props, attributeId: rowData.id || rowData.uniqueId! })
          }
          attrErrors={analyses}
        />
        {multiItemSelect && (
          <AttributeValuePopover
            type="addValue"
            addButtonVisisble={rowData.ranked_values.length === 0}
            handleChange={props =>
              handleAddValue({
                ...props,
                attributeId: rowData.id || rowData.uniqueId!,
                itemIds: [itemId],
              })
            }
          />
        )}
      </div>

      {!multiItemSelect && (
        <AttributeValuePopover
          type="addValueButton"
          handleChange={props =>
            handleAddValue({
              ...props,
              attributeId: rowData.id || rowData.uniqueId!,
              itemIds: [itemId],
            })
          }
        />
      )}
    </React.Fragment>
  );
};

export default memo(AttributeValueCell);
