import React from 'react';
import { Modal, Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReceiverDetails } from '../../../../types/receiver';
import ReceiverProfile from './ReceiverProfile';

type ReceiverDetailsModalProps = {
  showModal: boolean;
  fetchingReceiverDetails: boolean;
  receiverDetails?: ReceiverDetails;
  status: {
    approved?: boolean;
    pending?: boolean;
    rejected?: boolean;
    authorizedByReceiver?: boolean;
  };
  onCancel: () => void;
};

const ReceiverDetailsModal: React.FC<ReceiverDetailsModalProps> = ({
  showModal,
  receiverDetails,
  fetchingReceiverDetails,
  status,
  onCancel,
}) => {
  const { t } = useTranslation();

  if (fetchingReceiverDetails)
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <Modal
      className="receiver__detail-modal"
      title={
        <div className="flex justify-between">
          {receiverDetails?.name}
          <div className="mr-4">
            <span className="text-gray-700"> {`${t('receiver:status.status')}: `} </span>
            {status.approved && <Tag color="green">{t('receiver:status.approved')}</Tag>}
            {status.pending && <Tag color="orange">{t('receiver:status.pending')}</Tag>}
            {status.rejected && <Tag color="red">{t('receiver:status.rejected')}</Tag>}
          </div>
        </div>
      }
      open={showModal}
      onCancel={() => onCancel()}
      footer={null}
      width="90%"
    >
      <div className="flex">
        {fetchingReceiverDetails ? (
          <Spin className="spinner-center" style={{ marginTop: '20px' }} />
        ) : (
          <ReceiverProfile
            receiverDetails={receiverDetails}
            approved={status.approved || status.authorizedByReceiver}
          />
        )}
      </div>
    </Modal>
  );
};

export default ReceiverDetailsModal;
