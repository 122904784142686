import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchXmlHeaders } from '../../actions/settings/xmlHeaders';
import XMLHeaderSettingsPage from '../../components/body/settings/XMLHeaderSettingsPage';

const SettingsXMLHeadersContainer: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchXmlHeaders());
  }, [dispatch]);

  return (
    <div className="bg-white h-full ">
      <XMLHeaderSettingsPage />
    </div>
  );
};

export default SettingsXMLHeadersContainer;
