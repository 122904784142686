export type Resources = {
  global: Global;
  package: Package;
  digital_asset: DigitalAsset;
  item: ItemResources;
  kit: Kit;
  interchange_information: InterchangeInformation;
  description: Description;
  price: Price;
  filter: Filter;
  channel: ChannelResources;
  receiver: Receiver;
  custom_field: CustomField;
  analysis: Analysis;
  extended_information: ExtendedInformation;
  export_template: ExportTemplate;
  vehicle: Vehicle;
  vehicle_structure: (VehicleStructureClass | string)[];
  ebay: Ebay;
  export: Export;
  application: ApplicationResources;
  script: ScriptOptions;
};

export type ScriptOptions = {
  cache_ttl_types: StandardResourceCode[];
  execution_frequencies: StandardResourceCode[];
  triggers: StandardResourceCode[];
};

export type Analysis = {
  segments: Segment[];
  alert_types: StandardResource[];
  references: StandardResource[];
  scorecards: ExtendedResources[];
  analysis_types: AnalysisType[];
};

export type StandardResource = {
  id: number;
  name: string;
};

export type StandardResourceCode = {
  id: number;
  name: string;
  code: string;
};

export type StandardResourceDescription = {
  id: number;
  name: string;
  description: string;
};

export type StandardResourceCodeDescription = {
  id: number;
  name: string;
  code: string;
  description: null | string;
};

export type StandardResourceCodeAuth = {
  id: number;
  name: string;
  code: string;
  authorization_type: null | string;
};

export type StandardResourceConfig = {
  id: number;
  name: string;
  config: string;
};

export type StandardResourceTypeId = {
  id: number;
  name: string;
  code: string;
  type_id: number;
};

export type AnalysisType = {
  id: number;
  segment_id: number;
  alert_type_id: number;
  code: string;
  reference_table: null | string;
  name: string;
  description: null | string;
  xml_format_validation: number;
};

export type ExtendedResources = {
  id: number;
  name: string;
  code?: string;
  type_ids?: string;
  description?: null | string;
  order?: number | null;
  created_at?: string;
};

export type Segment = {
  id: number;
  name: string;
  description?: null | string;
  title?: string;
  code?: string;
  type_id?: number;
  order?: number;
};

export type ChannelResources = {
  delivery_frequencies: ExtendedResources[];
  file_types: ExtendedResources[];
  pies_versions: ExtendedResources[];
  aces_versions: ExtendedResources[];
  submission_types: ExtendedResources[];
  ftp_directory_types: ExtendedResources[];
  ftp_encryption_types: ExtendedResources[];
  ftp_modes: ExtendedResources[];
  protocol_types: ExtendedResources[];
  delivery_types: ExtendedResources[];
  integration_types: ExtendedResources[];
};

export type CustomField = {
  types: ExtendedResources[];
};

export type Description = {
  types: DescriptionTypeElement[];
};

export type DescriptionTypeElement = {
  id: number;
  record_number: number;
  name: string;
  description?: string;
  max_length: number;
};

export type DigitalAsset = {
  backgrounds: StandardResource[];
  color_modes: StandardResource[];
  date_types: StandardResource[];
  description_types: DescriptionTypeElement[];
  dimension_uoms: StandardResource[];
  duration_uoms: StandardResource[];
  file_types: ExtendedResources[];
  hemispheres: StandardResource[];
  orientation_views: StandardResource[];
  reference_types: StandardResource[];
  representations: StandardResource[];
  resolutions: StandardResource[];
  types: Segment[];
};

export type ExportTemplate = {
  options: Option[];
  file_types: ExtendedResources[];
};

export type Export = {
  pdf_templates: ExtendedResources[];
};

export type Option = {
  id: number;
  code: string;
  name: string;
  segment_id: number;
  parameters?: Segment[];
};

export type ExtendedInformation = {
  groups: Group[];
};

export type Group = {
  id: number;
  code: string;
  name: string;
  description: string;
  record_number: number;
  types: GroupType[];
};

export type GroupType = {
  id: number;
  code: string;
  name: string;
  description: string;
  application: null | string;
  min_length: number;
  max_length: number;
  record_number: number;
  format: Format;
  options: ExtendedResources[];
};

export enum Format {
  Array = 'ARRAY',
  Bool = 'BOOL',
  Text = 'TEXT',
}

export type Filter = {
  custom_references: CustomReference[];
  analysis_references: StandardResource[];
  types: Segment[];
  type_sub_types: Segment[];
};

export type CustomReference = {
  id: number;
  name: string;
  title: string;
  resource_table: null | string;
  format_type_id: number | null;
};

export type Global = {
  parent_owner_brands: ExtendedResources[];
  languages: StandardResourceCode[];
  countries: ExtendedResources[];
  measurement_uom_codes: StandardResource[];
  gtin_qualifiers: ExtendedResources[];
  export_schemas: StandardResource[];
  format_types: ExtendedResources[];
  view_product_options: StandardResource[];
  segments: Segment[];
  log_error_types: ExtendedResources[];
  maintenance_types: StandardResourceCode[];
  file_name_config_options: StandardResourceCodeDescription[];
  file_name_templates: StandardResourceConfig[];
  category_versions: string;
  part_attribute_padb_versions: string;
  qdb_versions: string;
  vehicle_versions: string;
  translation_types: ExtendedResources[];
  vehicle_config_types: ExtendedResources[];
  sources: StandardResourceDescription[];
  vehicle_sub_config_types: ResourcesSubConfigType[];
  import_sources: StandardResourceDescription[];
};

export type ResourcesSubConfigType = {
  id: number;
  name: string;
  config_type_id: number;
};

export type InterchangeInformation = {
  qualitity_grade_levels: StandardResource[];
  types: Segment[];
};

export type ItemResources = {
  quantity_per_application_qualifiers: StandardResource[];
  population_statuses: PopulationStatus[];
};

export type PopulationStatus = {
  id: number;
  name: string;
  limited_permission_name: null | string;
};

export type Kit = {
  qualifiers: StandardResource[];
  description_types: DescriptionTypeElement[];
};

export type Package = {
  dimension_uoms: StandardResource[];
  orderable_options: StandardResource[];
  uoms: ExtendedResources[];
  weight_uoms: StandardResource[];
  hazardous_material_regulated_options: StandardResource[];
  hazardous_material_bulks: StandardResource[];
  hazardous_material_classes: StandardResource[];
  hazardous_material_labels: StandardResource[];
  hazardous_material_packing_groups: StandardResource[];
  hazardous_material_qualifiers: StandardResource[];
  hazardous_material_shipping_scopes: StandardResource[];
  hazardous_material_transport_methods: StandardResource[];
  hazardous_material_whmis_codes: StandardResource[];
};

export type Price = {
  uoms: StandardResource[];
  types: ExtendedResources[];
  currencies: ExtendedResources[];
  break_quantity_uoms: StandardResource[];
};

export type Receiver = {
  integration_types: ExtendedResources[];
  external_data_provider_datasets: StandardResourceCode[];
  external_data_providers: StandardResourceCodeAuth[];
  import_statuses: StandardResourceCodeDescription[];
  request_statuses: StandardResourceCodeDescription[];
  industries: StandardResourceCodeDescription[];
  revenue_ranges: StandardResourceCodeDescription[];
  sales_channels: StandardResourceCodeDescription[];
};

export type ApplicationResources = {
  listing_type_options: StandardResourceTypeId[];
  listing_types: StandardResourceCode[];
  qualifier_meta_uoms: StandardResource[];
};

export type Vehicle = {
  [key: string]: any;
  positions: StandardResource[];
  drives: StandardResource[];
  qualifier_types: StandardResource[];
  qualifier_value_types: StandardResourceDescription[];
  years: Year[];
  engine_power_outputs: StandardResource[];
};

export type Year = {
  id: number;
  name: number;
};

export type VehicleStructureClass = {
  [key: string]: any;
  vehicle_bases?: string[];
  engines?: EngineStructure[];
  transmissions?: string[];
  bodies?: string[];
  springs?: string[];
  beds?: string[];
  brakes?: string[];
  steerings?: string[];
};

export type EngineStructure = {
  others: string[];
  engine_bases: string[];
  engine_fuel_deliveries: string[];
};

export type Ebay = {
  listtype: StandardResourceCode[];
  listtime: StandardResourceCode[];
  returns_refund: ExtendedResources[];
  returns_shipping_cost_paid_by: ExtendedResources[];
  returns_days: ExtendedResources[];
  shipping_dispatch: ExtendedResources[];
};

export enum ErrorTypes {
  INFO = 1,
  WARNING,
  ERROR,
}
