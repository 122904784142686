export const COUNTRY_CODES = ['CTO', 'CTP', 'CTQ', 'CTR', 'CTS', 'RCC'];

export enum Warranty {
  Code = 'Text Warranty message',
  TypeId = 19,
}

export const WARRANTY_OPTIONS = ['Lifetime', 'See Rep'];

export const WARRANTY_OPTION_CODES = ['L', 'S'];
