import { connect } from 'react-redux';
import React from 'react';

import { Account } from '../../../types/account';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { updateAccountInformations, updatePassword } from '../../actions/user';
import { withContainerWrapper } from '../ContainerWrapper';
import SettingsAccount from '../../components/body/settings/SettingsAccount';
import { UserType } from '../../../types/user';
import { intercomEvent } from '../../utils/IntercomUtils';
import { isManufacturer } from '../../utils/UserUtils';

type AccountContainerProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  account: Account;
};

export class AccountContainer extends React.Component<AccountContainerProps> {
  componentDidMount() {
    intercomEvent('viewed-account');
  }

  updateAccountInformations = async ({
    emailNotification,
    firstName,
    lastName,
    email,
  }: Account) => {
    const { user } = this.props;
    const changedEmail = user.email !== email ? email : undefined;
    return this.props.dispatch(
      updateAccountInformations(user.id, emailNotification, firstName, lastName, changedEmail)
    );
  };

  updatePassword = (currentPw: string, newPw: string) =>
    this.props.dispatch(updatePassword(currentPw, newPw));

  manufacturer = isManufacturer(this.props.user!);

  render() {
    const { account, user } = this.props;
    return (
      <SettingsAccount
        account={{
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          emailNotification: account.emailNotification,
        }}
        accountInfo={{
          accountName: user!.account_name,
          accountId: user!.account_id,
          userId: this.manufacturer ? user!.parent_id : user!.receiver_id,
          isManufacturer: this.manufacturer,
        }}
        updateAccountInformation={this.updateAccountInformations}
        updatePassword={this.updatePassword}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  account: state.user.user && {
    id: state.user.user.id,
    email: state.user.user.email,
    firstName: state.user.user.first_name,
    lastName: state.user.user.last_name,
    emailNotification: state.user.user.email_notification,
  },
});

export default connect(mapStateToProps)(withContainerWrapper(AccountContainer));
