import React from 'react';
import { Button, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createReceiverDetailsRequest } from '../../../actions/receiver/update';
import { AsyncDispatch } from '../../../../types/global';
import { intercomEvent } from '../../../utils/IntercomUtils';

type RequestProfileDetailsButtonProps = {
  receiverId: number;
  ghost?: boolean;
  receiverName: string;
  disableOnAcknowledge?: boolean;
  disableOnReq?: boolean;
  type?: 'primary' | 'text';
};

const RequestProfileDetailsButton: React.FC<RequestProfileDetailsButtonProps> = ({
  receiverId,
  ghost,
  receiverName,
  disableOnReq,
  disableOnAcknowledge,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const handleRequestProfileDetails = () => {
    dispatch(createReceiverDetailsRequest(receiverId)).then(() => {
      message.info(t('receiver:sentRequest'));
      intercomEvent('viewed-receiver', {
        action: 'reseller-profile-info-requested',
        receiver: receiverName,
      });
    });
  };

  return (
    <Tooltip
      title={
        disableOnAcknowledge
          ? t('receiver:reqAlreadyAcknowledged')
          : disableOnReq && t('receiver:reqAlreadySent')
      }
    >
      <Button
        type={type || 'primary'}
        className="float-right"
        size="small"
        onClick={() => handleRequestProfileDetails()}
        disabled={disableOnAcknowledge || disableOnReq}
        ghost={ghost}
      >
        {t('receiver:reqProfileDetails')}
      </Button>
    </Tooltip>
  );
};

export default RequestProfileDetailsButton;
