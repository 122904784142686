import { FluxStandardAction } from 'redux-promise-middleware';
import { Reducer } from 'redux';
import { KitType } from '../../../types/kit';
import { SubBrand } from '../../../types/brand';
import { ShopPreviewType } from '../../../types/shop_preview';

export type KitState = {
  kits: KitType[];
  shopPreview?: ShopPreviewType;
  subBrands: SubBrand[];
  fetchingKits: boolean;
};

const initialState = {
  kits: [],
  shopPreview: undefined,
  subBrands: [],
  fetchingKits: false,
};

const reducer: Reducer<KitState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ITEM_KITS_PENDING': {
      return {
        ...state,
        kits: [],
        shopPreview: undefined,
        subBrands: [],
        fetchingKits: true,
      };
    }
    case 'FETCH_ITEM_KITS_FULFILLED': {
      return {
        ...state,
        kits: action.payload.data,
        fetchingKits: false,
      };
    }
    case 'FETCH_KIT_SHOP_PREVIEW_FULFILLED': {
      const shopPreview = action.payload.data;
      return { ...state, shopPreview };
    }
    case 'FETCH_KIT_SHOP_PREVIEW_REJECTED': {
      const shopPreview = undefined;
      return { ...state, shopPreview };
    }
    case 'FETCH_KIT_SUB_BRANDS_FULFILLED': {
      const brandList = action.payload.data.brands;
      const subBrands = brandList[0]?.sub_brands || [];
      return { ...state, subBrands };
    }
    case 'CREATE_KIT_ITEM_FULFILLED': {
      const newKit = action.payload.data;
      const kits = [newKit, ...state.kits];
      return { ...state, kits };
    }
    case 'DELETE_KIT_ITEM_PENDING': {
      const kits = state.kits.filter(kit => kit.id !== action.meta.kitId);
      return { ...state, kits };
    }
    case 'UPDATE_KIT_ORDER_PENDING': {
      const kits = action.meta.kits;
      return { ...state, kits };
    }
  }
  return state;
};

export default reducer;
