import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ExportError, ExportLog } from '../../../types/import_export';

export type ParentExportLogsState = {
  exportLogs: ExportLog[];
  exportErrorLogDetails: ExportError[];
  fetchingParentExportLogs: boolean;
  fetchingErrorLog: boolean;
};

const initialState = {
  exportLogs: [],
  exportErrorLogDetails: [],
  fetchingParentExportLogs: false,
  fetchingErrorLog: false,
};

const reducer: Reducer<ParentExportLogsState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_PARENT_EXPORT_LOGS_PENDING': {
      return { ...state, exportLogs: [], fetchingParentExportLogs: true };
    }
    case 'FETCH_PARENT_EXPORT_LOGS_FULFILLED': {
      return {
        ...state,
        exportLogs: action.payload.data,
        fetchingParentExportLogs: false,
      };
    }
    case 'FETCH_PARENT_EXPORT_LOGS_DETAILS_JSON_PENDING': {
      return {
        ...state,
        exportErrorLogDetails: [],
        fetchingErrorLog: true,
      };
    }
    case 'FETCH_PARENT_EXPORT_LOGS_DETAILS_JSON_FULFILLED': {
      return {
        ...state,
        exportErrorLogDetails: action.payload.data,
        fetchingErrorLog: false,
      };
    }
  }
  return state;
};

export default reducer;
