import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SynchronisationContainer from '../../containers/synchronisation/SynchronisationContainer';

const SyncRoutes = () => (
  <Routes>
    <Route path="/" element={<SynchronisationContainer />} />
  </Routes>
);

export default SyncRoutes;
