import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, Select } from 'antd';
import { fetchItemsByKeyword } from '../../../actions/items/application/fetch';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { createBrandTitle, createShortBrandTitle, fillInPlaceholders } from '../../../utils/String';
import { typingDone } from '../../../utils/Utils';
import constants from '../../../constants/ApplicationTranslation.json';
import { Item } from '../../../../types/item';

type ApplicationLinksProps = {
  crossLinkBrandsEnabled: boolean;
  applicationParentItems: Item[];
  parentApplicationItemIds: number[];
  brandId: number;
  dispatch: AsyncDispatch;
  selectParentItem: (id: number) => void;
  deselectParentItem: (id: number) => void;
};

const ApplicationLinks: React.FC<ApplicationLinksProps> = props => {
  const loadItemsByKeyword = (keyword?: string) =>
    props.dispatch(
      fetchItemsByKeyword(
        props.crossLinkBrandsEnabled ? null : props.brandId,
        props.parentApplicationItemIds,
        keyword
      )
    );

  const handleSelect = (itemId: number) => {
    props.selectParentItem(itemId);
  };

  const handleDeselect = (itemId: number) => {
    props.deselectParentItem(itemId);
  };

  const handleSearch = (search: string) => typingDone(() => loadItemsByKeyword(search));

  return (
    <div className="application__links">
      <Alert
        message={fillInPlaceholders(constants.parentLinks.title, {
          itemCount: props.parentApplicationItemIds.length.toString(),
        })}
        description={
          <Select
            value={props.applicationParentItems
              .filter(item => props.parentApplicationItemIds.includes(item.id))
              .map(item => item.id)}
            mode="multiple"
            placeholder={constants.parentLinks.placeholder}
            filterOption={false}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onSearch={handleSearch}
            style={{ width: '100%' }}
          >
            {props.applicationParentItems &&
              props.applicationParentItems.map(item => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  title={`${
                    props.crossLinkBrandsEnabled
                      ? `${createBrandTitle(
                          item.brand_name!,
                          item.brand_code,
                          item.default_whitelabel_brand_code!
                        )}: `
                      : ''
                  }${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`}
                >
                  {`${
                    props.crossLinkBrandsEnabled
                      ? `${createShortBrandTitle(
                          item.brand_name!,
                          item.brand_code,
                          item.default_whitelabel_brand_code!
                        )}: `
                      : ''
                  }${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`}
                </Select.Option>
              ))}
          </Select>
        }
        type="info"
        showIcon
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    applicationParentItems: state.items.application.applicationParentItems,
    brandId: state.parent.brands.selectedBrandId,
    selectedItemInCatalogueId: state.catalogue.catalogue.selectedItemIds[0],
  };
};

export { ApplicationLinks };

export default connect(mapStateToProps)(withErrorBoundary(ApplicationLinks));
