import React from 'react';
import { connect } from 'react-redux';
import { withContainerWrapper } from '../ContainerWrapper';
import * as actions from '../../actions/parent/digital_asset';
import * as utils from '../../utils/Utils';
import DigitalAssetList from '../../components/body/media_and_marketing_copy/digital_asset/DigitalAssetList';
import DigitalAssetEdit from '../../components/body/media_and_marketing_copy/digital_asset/DigitalAssetEdit';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';
import { digitalAssetsWithBrandCodes } from '../../selectors/digital_assets/digitalAssetsSelector';

class DigitalAssetsParentContainer extends React.Component {
  state = {
    search: '',
  };

  componentDidMount() {
    this.props.dispatch(actions.fetchDigitalAssetsParent());
    intercomEvent('viewed-company-digital-assets');
  }

  componentWillUnmount() {
    this.props.dispatch(actions.selectDigitalAssetParent());
  }

  selectDigitalAsset = digitalAssetId => {
    this.props.dispatch(actions.selectDigitalAssetParent(digitalAssetId));
  };

  updateDigitalAssetParent = (digitalAssetId, key, value) => {
    this.props.dispatch(actions.updateDigitalAssetParent(digitalAssetId, key, value));
  };

  handleScroll = e => {
    const nextPage = utils.nextPage(e, 60, this.props.digitalAssets.length);
    if (nextPage && !this.props.isLoading) {
      this.props.dispatch(actions.fetchDigitalAssetsParent(this.state.search, nextPage));
    }
  };

  handleSearch = e => {
    const search = e.target.value;
    this.setState({ search });
    utils.typingDone(() => this.props.dispatch(actions.fetchDigitalAssetsParent(search)));
  };

  render() {
    const {
      digitalAssets,
      digitalAssetResources,
      languages,
      countries,
      isLoading,
      selectedDigitalAssetId,
      handleSelectAsset,
      user,
    } = this.props;
    const selectedDigitalAsset = digitalAssets.find(
      digitalAsset => digitalAsset.id === selectedDigitalAssetId
    );
    const canManageDigitalAssets =
      hasPermission(user, 'can_manage_digital_assets') && !handleSelectAsset;

    return (
      <div className="digital-asset-parent">
        <div className="digital-asset-parent__edit-container">
          <DigitalAssetList
            digitalAssets={digitalAssets}
            selectedDigitalAsset={selectedDigitalAsset}
            selectDigitalAsset={this.selectDigitalAsset}
            handleScroll={this.handleScroll}
            handleSearch={this.handleSearch}
            isLoading={isLoading}
            handleSelectAsset={handleSelectAsset}
          />
          <DigitalAssetEdit
            selectedDigitalAsset={selectedDigitalAsset}
            updateDigitalAssetParent={this.updateDigitalAssetParent}
            digitalAssetResources={digitalAssetResources}
            languages={languages}
            countries={countries}
            canManageDigitalAssets={canManageDigitalAssets}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.parent.digitalAsset.isLoading,
  digitalAssets: digitalAssetsWithBrandCodes(state),
  selectedDigitalAssetId: state.parent.digitalAsset.selectedDigitalAssetId,
  setExternal: state.parent.digitalAsset.setExternal,
  digitalAssetResources: state.resources.data.digital_asset,
  languages: state.resources.data.global.languages,
  countries: state.resources.data.global.countries,
  user: state.user.user,
});

export default connect(mapStateToProps)(withContainerWrapper(DigitalAssetsParentContainer));
