import axios from 'axios';

import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function copyFilter(name, filterId) {
  const urlParams = utils.createURL([
    { name: 'name', values: name },
    { name: 'filter_id', values: filterId },
  ]);

  return {
    type: 'COPY_FILTER',
    payload: axios.post(`${getApiRoot()}/filters/copy${urlParams}`),
  };
}

export function createCategoryFilter(filterId, andCondition = 1) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'and_condition', values: andCondition },
  ]);

  return {
    type: 'CREATE_CATEGORY_FILTER',
    payload: axios.post(`${getApiRoot()}/filters/categories${urlParams}`),
  };
}

export function createFilterCategoryParameter(filterCategoryId, optionId, level, categoryIds) {
  const urlParams = utils.createURL([
    { name: 'filter_category_id', values: filterCategoryId },
    { name: 'option_id', values: optionId },
    { name: 'level', values: level },
    { name: 'category_ids', values: categoryIds },
  ]);

  return {
    type: 'CREATE_CATEGORY_FILTER_PARAMETER',
    payload: axios.post(`${getApiRoot()}/filters/categories/parameters${urlParams}`),
  };
}

export function createApplicationFilter(filterId, andCondition = 1) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'and_condition', values: andCondition },
  ]);

  return {
    type: 'CREATE_APPLICATION_FILTER',
    payload: axios.post(`${getNonGwApiRoot()}/filters/applications${urlParams}`),
  };
}

export function createFilterApplicationParameter(appFilterId, optionId, resource, resourceIds) {
  const urlParams = utils.createURL([
    { name: 'filter_application_id', values: appFilterId },
    { name: 'option_id', values: optionId },
    { name: 'resource', values: resource },
    { name: 'resource_ids', values: resourceIds },
  ]);

  return {
    type: 'CREATE_APPLICATION_FILTER_PARAMETER',
    payload: axios.post(`${getNonGwApiRoot()}/filters/applications/parameters${urlParams}`),
  };
}

export function createAnalysisFilter(filterId, andCondition = 1) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'and_condition', values: andCondition },
  ]);

  return {
    type: 'CREATE_ANALYSIS_FILTER',
    payload: axios.post(`${getApiRoot()}/filters/analyses${urlParams}`),
  };
}

export function createFilterAnalysisParameter(filterId, optionId, referenceId, analysisIds) {
  const urlParams = utils.createURL([
    { name: 'filter_analysis_id', values: filterId },
    { name: 'option_id', values: optionId },
    { name: 'reference_id', values: referenceId },
    { name: 'analysis_ids', values: analysisIds },
  ]);

  return {
    type: 'CREATE_ANALYSIS_FILTER_PARAMETER',
    payload: axios.post(`${getApiRoot()}/filters/analyses/parameters${urlParams}`),
  };
}

export function createCustomFilter(filterId, andCondition = 1) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'and_condition', values: andCondition },
  ]);

  return {
    type: 'CREATE_CUSTOM_FILTER',
    payload: axios.post(`${getApiRoot()}/filters/customs${urlParams}`),
  };
}

export function createFilterCustomParameter({ filterId, customRefId, optionId, value, refId }) {
  const urlParams = utils.createURL([
    { name: 'filter_custom_id', values: filterId },
    { name: 'option_id', values: optionId },
    { name: 'reference_id', values: refId },
    { name: 'filter_custom_reference_id', values: customRefId },
    { name: 'value', values: value },
  ]);

  return {
    type: 'CREATE_CUSTOM_FILTER_PARAMETER',
    payload: axios.post(`${getApiRoot()}/filters/customs/parameters${urlParams}`),
  };
}
