import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ExportTypes } from '../../../types/import_export';
import { getExportTypeFromSourceId } from '../../utils/ImportExportUtils';

export enum CurrentExportState {
  'EXPORTING' = 'EXPORTING',
  'DONE' = 'DONE',
}

export type ExportState = {
  fileTypeId?: number;
  fileName?: string;
  logId: number;
  exportType: ExportTypes;
  currentState: CurrentExportState;
  showNotification: boolean;
  brandId: number;
  exportState: {
    progress: number;
    subProgress: number;
    status: string;
    errors: boolean;
    warnings: boolean;
    emptyFile: boolean;
    completed: boolean;
    statusId: number | null;
  };
};

export type ExporterState = {
  exports: ExportState[];
  downloadReadyExports: {
    logId: number;
    downloadState: {
      started: boolean;
      finished: boolean;
    };
  }[];
  vehicleSubConfigs: {
    makes: number[];
    models: number[];
    years: number[];
    vehicle_types: number[];
    vehicle_groups: number[];
  };
  recomendedVehicles: {
    make_ids: number[];
    model_ids: number[];
    year_ids: number[];
    vehicle_type_ids: number[];
    vehicle_group_ids: number[];
  };
  positions: { id: number; value: string }[];
  usedSubConfigIds: number[];
  fetchingUsedSubConfigIds: boolean;
  fetchingPositions: boolean;
  fetchingRecomendedVehicles: boolean;
};

const initialState = {
  exports: [],
  downloadReadyExports: [],
  vehicleSubConfigs: {
    makes: [],
    models: [],
    years: [],
    vehicle_types: [],
    vehicle_groups: [],
  },
  recomendedVehicles: {
    make_ids: [],
    model_ids: [],
    year_ids: [],
    vehicle_type_ids: [],
    vehicle_group_ids: [],
  },
  positions: [],
  usedSubConfigIds: [],
  fetchingUsedSubConfigIds: false,
  fetchingPositions: false,
  fetchingRecomendedVehicles: false,
};

const formatImportLog = (log: any) => {
  const exportCompleted = !!log.completed_at;
  const importState: ExportState = {
    logId: log.id,
    exportType: getExportTypeFromSourceId(log.source_id),
    brandId: log.brand_id,
    showNotification: !exportCompleted,
    currentState: exportCompleted ? CurrentExportState.DONE : CurrentExportState.EXPORTING,
    exportState: {
      progress: log.progress,
      subProgress: log.sub_progress,
      status: log.status,
      errors: log.error_count > 0,
      warnings: log.warning_count > 0,
      emptyFile: log.empty_file === 1,
      completed: exportCompleted,
      statusId: log.status_id,
    },
  };
  return importState;
};

const reducer: Reducer<ExporterState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case '@EXPORTER/FETCH_LATEST_EXPORT_LOGS_FULFILLED': {
      const exports = action.payload.data;
      const formatedExports = exports.map((singleImport: any) => {
        return formatImportLog(singleImport);
      });
      return {
        ...state,
        exports: formatedExports,
      };
    }
    case '@EXPORTER/START_EXPORT_FULFILLED': {
      const { fileType, brandId, fileTypeId } = action.meta;
      const newExport: ExportState = {
        logId: action.payload.data.export_log_id,
        exportType: fileType,
        brandId,
        fileTypeId,
        showNotification: true,
        currentState: CurrentExportState.EXPORTING,
        exportState: {
          progress: 0,
          subProgress: 0,
          status: '',
          errors: false,
          warnings: false,
          emptyFile: false,
          completed: false,
          statusId: null,
        },
      };
      return {
        ...state,
        exports: [newExport, ...state.exports],
      };
    }

    case '@EXPORTER/FETCH_RUNNING_EXPORT_LOGS_FULFILLED': {
      const exportLogs = action.payload.data;
      const exportLogIds = exportLogs.map((log: any) => log.id);
      return {
        ...state,
        exports: state.exports.map(importValue => {
          if (exportLogIds.includes(importValue.logId)) {
            const log = exportLogs.find((log: any) => log.id === importValue.logId);
            const exportCompleted = !!log.completed_at;

            return {
              ...importValue,
              currentState: exportCompleted
                ? CurrentExportState.DONE
                : CurrentExportState.EXPORTING,
              exportState: {
                progress: log.progress,
                subProgress: log.sub_progress,
                completed: exportCompleted,
                status: log.status,
                errors: log.error_count > 0,
                warnings: log.warning_count > 0,
                emptyFile: log.empty_file === 1,
                statusId: log.status_id,
              },
              fileName: log.file_name,
            };
          }
          return importValue;
        }),
      };
    }

    case '@EXPORTER/EXPORT_DOWNLOAD_READY': {
      const currentReadyExportIds = state.downloadReadyExports.map(exp => exp.logId);
      return {
        ...state,
        downloadReadyExports: currentReadyExportIds.includes(action.payload.logId)
          ? state.downloadReadyExports
          : [
              {
                logId: action.payload.logId,
                downloadState: { started: false, finished: false },
              },
              ...state.downloadReadyExports,
            ],
      };
    }
    case '@EXPORTER/CLOSE_NOTIFICATION': {
      const { id } = action.payload;
      return {
        ...state,
        exports: state.exports.map(singleExport => {
          if (singleExport.logId === id) {
            return {
              ...singleExport,
              showNotification: false,
            };
          }
          return singleExport;
        }),
      };
    }
    case '@EXPORTER/OPEN_NOTIFICATION': {
      const { id } = action.payload;
      return {
        ...state,
        exports: state.exports.map(singleExport =>
          singleExport.logId === id
            ? {
                ...singleExport,
                showNotification: true,
              }
            : singleExport
        ),
      };
    }
    case 'FETCH_EXPORT_PENDING': {
      return {
        ...state,
        downloadReadyExports: state.downloadReadyExports.map(exp => {
          return exp.logId === action.meta.logId
            ? { ...exp, downloadState: { ...exp.downloadState, started: true } }
            : exp;
        }),
      };
    }
    case 'FETCH_EXPORT_FULFILLED': {
      return {
        ...state,
        downloadReadyExports: state.downloadReadyExports.map(exp => {
          return exp.logId === action.meta.logId
            ? { ...exp, downloadState: { ...exp.downloadState, finished: true } }
            : exp;
        }),
      };
    }
    case 'FETCH_RECOMMENDED_VEHICLES_PENDING': {
      return {
        ...state,
        fetchingRecomendedVehicles: true,
      };
    }
    case 'FETCH_RECOMMENDED_VEHICLES_FULFILLED': {
      return {
        ...state,
        recomendedVehicles: action.payload.data.vehicles,
        fetchingRecomendedVehicles: false,
      };
    }
    case 'RESET_RECOMMENDED_VEHICLES': {
      return {
        ...state,
        recomendedVehicles: {
          make_ids: null,
          model_ids: null,
          year_ids: null,
          vehicle_type_ids: null,
          vehicle_group_ids: null,
        },
      };
    }
    case 'FETCH_USED_VEHICLES_FULFILLED': {
      return {
        ...state,
        vehicleSubConfigs: action.payload.data.sub_configs,
        recomendedVehicles: action.payload.data.vehicles,
      };
    }
    case 'FETCH_USED_POSITIONS_PENDING': {
      return {
        ...state,
        fetchingPositions: true,
      };
    }
    case 'FETCH_USED_POSITIONS_FULFILLED': {
      return {
        ...state,
        positions: action.payload.data.positions,
        fetchingPositions: false,
      };
    }
    case 'FETCH_USED_SUB_CONFIGS_PENDING': {
      return {
        ...state,
        fetchingUsedSubConfigIds: true,
      };
    }
    case 'FETCH_USED_SUB_CONFIGS_FULFILLED': {
      return {
        ...state,
        usedSubConfigIds: action.payload.data.sub_config_type_ids,
        fetchingUsedSubConfigIds: false,
      };
    }
  }
  return state;
};

export default reducer;
