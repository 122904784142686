import { createSelector } from 'reselect';
import { UpdateLanguageSetting } from '../../../types/language';
import { ApplicationState } from '../../reducers';

const languages = (state: ApplicationState) => state.resources.data.global.languages;
const accountLanguages = (state: ApplicationState) =>
  state.parent.languageSettings.accountLanguages;

export const mappedLanguageNameTranslations = createSelector(
  [languages, accountLanguages],
  (languages, accountLanguages) => {
    return accountLanguages.map((a: UpdateLanguageSetting) => {
      return { ...a, languageName: languages.find(l => l.id === Number(a.language_id))?.name };
    });
  }
);
