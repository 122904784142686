import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import AntTooltip from '../../global/AntTooltip';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';

type AllApplicationsAnalysesIconProps = {
  analyses?: (Analysis & AnalysisType)[];
};

const AllApplicationsAnalysesIcon: React.FC<AllApplicationsAnalysesIconProps> = ({ analyses }) => {
  const tooltip = (
    <div>
      {analyses?.map(analysis => (
        <div key={analysis.id}>
          <div>{analysis.name}</div>
          <div className="mt-2">{analysis.description}</div>
        </div>
      ))}
    </div>
  );

  return (
    <React.Fragment>
      {!!analyses?.length && (
        <AntTooltip title={tooltip}>
          <WarningOutlined className="application_listing__warning-icon mr-2" />
        </AntTooltip>
      )}
    </React.Fragment>
  );
};

export default AllApplicationsAnalysesIcon;
