import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { downloadFileFromUrl } from '../../shared/fetch';

export function fetchExternalSources() {
  return {
    type: 'FETCH_RECEIVER_EXTERNAL_SOURCES',
    payload: axios.get(`${getApiRoot()}/external_sources`),
  };
}

export function fetchExternalSourceImportLogs(limit = 100) {
  const params = utils.createURL([{ name: 'limit', values: limit }]);
  return {
    type: 'FETCH_EXTERNAL_SOURCES_IMPORT_LOGS',
    payload: axios.get(`${getApiRoot()}/external_sources/import_logs${params}`),
  };
}

export function fetchSdcMissingPlanReport() {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_MISSING_SDC_PLANS_REPORT',
      payload: axios
        .get(`${getApiRoot()}/external_sources/missing_sdc_plans`)
        .then(({ data: { download_url: fileUrl } }) => {
          dispatch(downloadFileFromUrl(fileUrl));
        }),
    });
  };
}
