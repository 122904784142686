import React from 'react';
import { Editor, EditorState, RichUtils, ContentState, convertFromHTML } from 'draft-js';
import classnames from 'classnames';
import StyleButton from './TextEditor/StyleButton';
import { typingDone } from '../../utils/Utils';

export default class TextEditor extends React.Component {
  /*
  Possible props to pass:
  - contents: array => [{content: "lorem ipsum", type: "list-item"}, {content: "lorem ipsum", type: "block"}]
  - objectId: integer that represents the id of current element
  - submit: function that is calles onBlur and returns the new contents
  - autoFocus: if true the editor gets focused with cursor
  - styleControls: if true, the control buttons are visible
  */

  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty(), textLength: 0 };
    this.blockTyes = [
      { label: 'UL', style: 'unordered-list-item', icon: 'sort-bullets' },
      { label: 'Initial', style: 'unstyled', icon: 'sort-text' },
    ];
  }

  componentDidMount() {
    if (this.props.contents && this.props.contents.length > 0) {
      this.setEditorState(this.props.contents);
    }
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.objectId !== nextProps.objectId) {
      const { contents } = nextProps;
      if (contents && contents.length > 0) this.setEditorState(contents);
      else this.setState({ editorState: EditorState.createEmpty() });
    }
  }

  onChange = editorState => {
    this.getCurrentBlockLength(editorState);
    this.setState({ editorState });
    if (this.props.autoSubmit) {
      typingDone(() => this.submitText());
    }
  };

  getCurrentBlockLength = editorState => {
    const selection = editorState.getSelection();

    const blockLength = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getLength();

    this.setState({ textLength: blockLength });
  };

  focus = () => {
    this.editor.focus();
  };

  getStyleControls = () => {
    const selection = this.state.editorState.getSelection();
    const blockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const hasFocus = selection.getHasFocus();

    return (
      <div className="RichEditor-controls flex items-center">
        {this.blockTyes.map(type => (
          <StyleButton
            icon={type.icon}
            key={type.label}
            hasFocus={hasFocus}
            active={type.style === blockType}
            label={type.label}
            onToggle={this.toggleBlockType}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  toggleBlockType = async blockType => {
    await this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    this.submitText(true);
  };

  blockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'block';
    }
    return '';
  };

  getEditorHtml = contents => {
    // create HTML from provided contents
    const htmlArray = contents.map(entry => {
      if (entry.type === 'block') {
        // if normal block-type
        return `<p>${entry.content.toString()}</p>`;
      }
      if (entry.type === 'list-item') {
        // if bullet-points
        return `<li>${entry.content.toString()}</li>`;
      } // everything else
      return `<p>${entry.content.toString()}</p>`;
    });
    return htmlArray.join(''); // return html joined to a string
  };

  setEditorState = contents => {
    // filter empty values
    contents = contents.filter(content => content.content);
    if (contents.length === 0) return;

    const htmlCode = this.getEditorHtml(contents);
    const blocksFromHTML = convertFromHTML(htmlCode);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const editorState = EditorState.createWithContent(state);

    this.onChange(editorState);
  };

  submitText = toggle => {
    const currentState = this.state.editorState.toJS();
    const content = currentState.currentContent.blockMap;
    const newContents = [];
    Object.keys(content).forEach((key, index) => {
      const row = content[key];
      if (row.text !== '') {
        newContents.push({
          content: row.text.trim(),
          id: index,
          type: row.type.includes('unordered-list-item') ? 'list-item' : 'block',
        });
      }
    });
    if (
      !toggle &&
      this.props.contents.length === newContents.length &&
      newContents.every((entry, index) => entry.content === this.props.contents[index].content)
    ) {
      // if length and contents are same, early return
      return;
    }
    this.props.submit(newContents, this.props.objectId);
  };

  handleBeforeInput = (chars, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    if (currentContentBlock.getText().length >= this.props.maxLength) return 'handled';
  };

  handlePastedText = (text, html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    if (currentContentBlock.getText().length + text.length > this.props.maxLength) return 'handled';
  };

  render() {
    const { maxLength, allowLimitExceeding, styleControls } = this.props;

    return (
      <div className="text-editor-container flex">
        <div
          className="flex-1"
          style={{ padding: 10, cursor: 'text' }}
          onClick={() => this.focus()}
        >
          <Editor
            placeholder={this.props.placeholder || ''}
            editorState={this.state.editorState}
            blockStyleFn={this.blockStyleFn}
            onChange={this.onChange}
            ref={input => (this.editor = input)}
            tabIndex="-1"
            onBlur={() => this.submitText()}
            handleBeforeInput={!allowLimitExceeding ? this.handleBeforeInput : undefined}
            handlePastedText={!allowLimitExceeding ? this.handlePastedText : undefined}
          />
        </div>
        {(styleControls || maxLength) && (
          <div
            className={classnames({
              'text-editor__options flex justify-between items-center': styleControls && maxLength,
            })}
          >
            {styleControls && (
              <div className="text-editor__buttons" tabIndex="-1">
                {this.getStyleControls()}
              </div>
            )}
            {maxLength && (
              <div
                className={classnames(
                  { 'text-red-600': this.state.textLength > maxLength },
                  'text-editor__count border border-transparent rounded flex mt-5 pr-2 justify-end'
                )}
              >
                {`${this.state.textLength} / ${this.props.maxLength}`}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
