import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import classNames from 'classnames';
import constants from '../../../constants/ApplicationTranslation.json';
import {
  ConfigValue,
  ApplicationDigitalAsset,
  ApplicationNote,
} from '../../../../types/application';

const text: { [index: string]: string | { [key: string]: any } } = constants;

type ApplicationCardConfigProps = {
  configs: {
    key?: string;
    value?: string;
    values?: (ConfigValue | ApplicationDigitalAsset | ApplicationNote)[];
  }[];
  configKey: string;
  defaultValue?: boolean;
  selectConfig?: (config: string, defaultValue?: boolean) => void;
  noEdit?: boolean;
  disableSelection?: boolean;
};

const ApplicationCardConfig: React.FC<ApplicationCardConfigProps> = props => {
  const { configKey, configs, defaultValue, noEdit, disableSelection, selectConfig } = props;

  const notes = (notes: { note: string; record_number: number }[]) => (
    <div className="application__card-subconfigs">
      {notes.map((note, i) => (
        <div key={`${note.record_number}${i}`}>{note.note}</div>
      ))}
    </div>
  );

  const values = (values: { name?: string; file_name?: string }[]) => {
    const valuesString = values.map(value => value.name || value.file_name).join(' | ');

    return (
      <span className={classNames({ 'break-all': configKey === 'digital_assets' })}>
        {valuesString}
      </span>
    );
  };

  const subConfigs = (configs: any[], category?: string) => {
    if (category === 'notes') return notes(configs[0].values);

    return (
      <div className="application__card-subconfigs">
        {configs.map((subConfigObj, i) => {
          const subconfigKey = subConfigObj.key;

          if (subconfigKey) {
            return (
              <Descriptions
                key={subconfigKey}
                className="application__inner-value-list"
                size="small"
              >
                <Descriptions.Item key={i} label={text[subconfigKey]}>
                  <div>{values(subConfigObj.values)}</div>
                </Descriptions.Item>
              </Descriptions>
            );
          }

          return (
            <div key={i} className="application__subconfig-wrapper">
              {subConfigObj.value !== undefined ? (
                <div className="application__overview-value">{subConfigObj.value}</div>
              ) : (
                values(subConfigObj.values)
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      key={configKey}
      className={classNames({ application__card_config: !disableSelection })}
      onClick={() => !disableSelection && selectConfig && selectConfig(configKey, defaultValue)}
    >
      <span className="application__card_label">
        <React.Fragment>
          <span>{text[configKey]}</span>
          {defaultValue && (
            <span className="application__card_label-default">
              {` (${constants.default_prefix})`}
            </span>
          )}
        </React.Fragment>
      </span>
      {subConfigs(configs, configKey)}
      {!noEdit && <EditOutlined className="application__card_config__edit-icon" />}
    </div>
  );
};

export default ApplicationCardConfig;
