import React from 'react';
import { Button } from 'antd';
import formTranslation from '../../../constants/FormTranslations.json';

export type FormButtonGroupProps = {
  onSave: (e?: React.FormEvent<HTMLFormElement>) => void;
  onCancel: () => void;
  loading: boolean;
  disabled: boolean;
};

export default class FormButtonGroup extends React.Component<FormButtonGroupProps> {
  render() {
    const { disabled, onSave, onCancel, loading } = this.props;
    return (
      <div className="button-group">
        <Button
          disabled={disabled}
          loading={loading}
          onClick={() => {
            onCancel();
          }}
        >
          {formTranslation.cancel}
        </Button>
        <Button
          type="primary"
          className="button-group__submit"
          disabled={disabled}
          loading={loading}
          onClick={() => {
            onSave();
          }}
        >
          {formTranslation.save}
        </Button>
      </div>
    );
  }
}
