import React from 'react';
import { Form, Modal } from 'antd';
import { FormikValues, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormInput from '../../global/Forms/FormInput';
import { CustomFieldGroup } from '../../../../types/custom_fields';

type SettingsCustomFieldsGroupDrawerProps = {
  visible: boolean;
  canEditSettingsCustomFields: boolean;
  groupNames: string[];
  selectedGroup?: CustomFieldGroup;
  closeDrawer: () => void;
  addGroup: (name: string) => void;
  changeGroupName: (id: number, name: string) => void;
  deleteGroup: (id: number | string) => void;
};

const SettingsCustomFieldsGroupDrawer: React.FC<SettingsCustomFieldsGroupDrawerProps> = ({
  visible,
  canEditSettingsCustomFields,
  groupNames,
  selectedGroup,
  closeDrawer,
  addGroup,
  changeGroupName,
  deleteGroup,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation:required'))
      .test(
        'check-unique-name',
        t('settings:customFields.validation.customFieldGroupIsUsed'),
        (val: any) => {
          return groupNames.every(name => name !== val);
        }
      ),
  });

  return (
    <DrawerFormik
      visible={visible}
      width={400}
      title={
        selectedGroup
          ? t('settings:customFields:editGroup')
          : t('settings:customFields:addNewGroup')
      }
      validationSchema={validationSchema}
      destroyOnClose
      initialValues={{ name: selectedGroup?.name || '' }}
      deleteButtonEnabled={canEditSettingsCustomFields}
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canEditSettingsCustomFields && formik.dirty
      }
      onSubmit={(values: FormikValues) => {
        if (selectedGroup)
          changeGroupName(
            typeof selectedGroup.id === 'string'
              ? Number(selectedGroup.id.substring(1))
              : selectedGroup.id,
            values.name
          );
        else addGroup(values.name);
        closeDrawer();
      }}
      onDelete={
        selectedGroup
          ? () => {
              Modal.confirm({
                title: t('settings:customFields.confirmGroupDelete'),
                content: t('settings:customFields.deleteGroupInfo'),
                okText: t('common:delete'),
                okButtonProps: { danger: true },
                onOk() {
                  deleteGroup(
                    typeof selectedGroup.id === 'string'
                      ? Number(selectedGroup.id.substring(1))
                      : selectedGroup.id
                  );
                  closeDrawer();
                },
              });
            }
          : undefined
      }
      onClose={closeDrawer}
    >
      {({ handleSubmit }) => (
        <Form layout="vertical" onFinish={() => handleSubmit()}>
          <FormInput name="name" label={t('common:name')} required />
        </Form>
      )}
    </DrawerFormik>
  );
};

export default SettingsCustomFieldsGroupDrawer;
