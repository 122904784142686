import {
  EXCEL_PRICE_SHEETS_PATH,
  EXCEL_PATH,
  ACES_PATH,
  PIES_PATH,
  ASSET_PATH,
  EXCEL_TEMPLATE_ADVANCED_PATH,
  PDF_PATH,
  SUMMIT_PATH,
} from '../constants/ImportExportConstants';
import { ExportTypes, ImportTypes } from '../../types/import_export';
import { ImportState, CurrentImportState } from '../reducers/importer/ImporterReducer';

export const getPathName = (fileType: ExportTypes) => {
  switch (fileType) {
    case ExportTypes.EXCEL:
      return EXCEL_PATH;
    case ExportTypes.EXCEL_PRICE_SHEETS:
      return EXCEL_PRICE_SHEETS_PATH;
    case ExportTypes.EXCEL_TEMPLATE_ADVANCED:
      return EXCEL_TEMPLATE_ADVANCED_PATH;
    case ExportTypes.ACES:
      return ACES_PATH;
    case ExportTypes.PIES:
      return PIES_PATH;
    case ExportTypes.ASSET:
      return ASSET_PATH;
    case ExportTypes.PDF:
      return PDF_PATH;
    case ExportTypes.SUMMIT:
      return SUMMIT_PATH;
  }
};

export const getFileExtension = (fileName: string) => {
  const indexOfExtensionPoint = fileName.lastIndexOf('.');
  return fileName.substring(indexOfExtensionPoint).toLowerCase();
};

export const checkForRunningImport = (
  imports: ImportState[],
  importType: ImportTypes,
  brandId: number
) =>
  imports.filter(singleImport => {
    const isRunning =
      singleImport.currentState === CurrentImportState.UPLOAD ||
      singleImport.currentState === CurrentImportState.IMPORT;
    return isRunning && singleImport.brandId === brandId && singleImport.importType === importType;
  }).length > 0;

export const getExportTypeFromSourceId = (sourceId: number) => {
  if (sourceId === 1) {
    return ExportTypes.PIES;
  }
  if (sourceId === 2) {
    return ExportTypes.ACES;
  }
  if (sourceId === 3) {
    return ExportTypes.ASSET;
  }
  if (sourceId === 6) {
    return ExportTypes.EXCEL_PRICE_SHEETS;
  }
  if (sourceId === 7) {
    return ExportTypes.SUMMIT;
  }
  if (sourceId === 10) {
    return ExportTypes.EXCEL;
  }
  if (sourceId === 11) {
    return ExportTypes.PDF;
  }
  if (sourceId === 12) {
    return ExportTypes.EXCEL_TEMPLATE_ADVANCED;
  }
  return ExportTypes.EXCEL;
};
