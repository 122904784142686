import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VcdbVersion } from '../../../../types/application';
import { AsyncDispatch } from '../../../../types/global';
import { createCustomVcdb } from '../../../actions/items/application/create';
import { deleteVcdb } from '../../../actions/items/application/delete';
import { ApplicationState } from '../../../reducers';
import { intercomEvent } from '../../../utils/IntercomUtils';
import FormSelect from '../../global/Forms/FormSelect';
import VcdbPopover from './VcdbPopover';

type ApplicationVcdbEditProps = {
  customSelected?: number;
  disabled?: boolean;
  selectCustom: (selectedId: number | undefined) => void;
};

const ApplicationVcdbEdit: React.FC<ApplicationVcdbEditProps> = ({
  selectCustom,
  customSelected,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { vcdbVersions } = useSelector((state: ApplicationState) => {
    return {
      vcdbVersions: state.items.application.vcdbVersions,
    };
  });

  const customVcdbs = vcdbVersions.filter((vcdb: VcdbVersion) => vcdb.account_id !== null);

  const createVcdb = (name: string) => {
    intercomEvent('viewed-company-cvt-settings', { location: 'settings', action: 'add' });

    return dispatch(createCustomVcdb(name)).then(result => {
      const newVcdb = result.value.data[0];
      selectCustom(newVcdb.id);
    });
  };

  const handleDeleteVcdb = () => {
    intercomEvent('viewed-company-cvt-settings', { location: 'settings', action: 'delete' });

    Modal.confirm({
      title: t('application:deleteVcdb'),
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        dispatch(deleteVcdb(customSelected!));
        if (customVcdbs.length > 1) {
          const deletedIndex = vcdbVersions.findIndex(
            (vcdb: VcdbVersion) => vcdb.id === customSelected
          );
          const nextSelected = deletedIndex > 0 ? customVcdbs[0] : customVcdbs[1];
          selectCustom(nextSelected.id);
        } else selectCustom(undefined);
      },
    });
  };

  return (
    <React.Fragment>
      <FormSelect
        className="application__vcdb-select"
        name="selectedVcdb"
        values={customVcdbs}
        onChange={(value: SelectValue) => selectCustom && selectCustom(Number(value))}
      />

      <VcdbPopover handleSave={createVcdb} disabled={disabled} />
      <Button
        className="ml-1"
        icon={<DeleteOutlined />}
        onClick={() => handleDeleteVcdb()}
        disabled={disabled}
        ghost
        danger
      />
    </React.Fragment>
  );
};

export default ApplicationVcdbEdit;
