import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import ReceiverCatalogueContainer from '../containers/receiver_data_stream/ReceiverCatalogueContainer';
import ProductsPage from '../pages/brand/ProductsPage';
import { initializeReceiverDataStream } from '../actions/receiver_data_stream/state/update';
import { ApplicationState } from '../reducers';
import { UserType } from '../../types/user';
import ReceiverExportMenu from '../pages/brand/ReceiverExportMenu';
import ExternalSourcesContainer from '../containers/receiver_data_stream/ExternalSourcesContainer';
import { runningOnGoOnlyApi } from '../utils/Permissions';

type ReceiverDataStreamRoutesProps = {
  user: UserType;
  dispatch: any;
};

type ReceiverDataStreamRoutesState = {
  goOnly: boolean;
};

class ReceiverDataStreamRoutes extends React.Component<
  ReceiverDataStreamRoutesProps,
  ReceiverDataStreamRoutesState
> {
  state = {
    goOnly: runningOnGoOnlyApi(this.props.user),
  };

  componentDidMount() {
    const { user } = this.props;
    const { goOnly } = this.state;
    if (user.receiver_id)
      this.props.dispatch(initializeReceiverDataStream(user.receiver_id, goOnly));
  }

  render() {
    return (
      <Routes>
        <Route path="all-brands" element={<ReceiverCatalogueContainer />} />
        <Route path="all-products/*" element={<ProductsPage />} />
        <Route path="external-sources/*" element={<ExternalSourcesContainer />} />
        <Route path="export/*" element={<ReceiverExportMenu />} />
      </Routes>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
});

// @ts-ignore
export default connect(mapStateToProps)(ReceiverDataStreamRoutes);
