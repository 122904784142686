import { Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReceiverWarehouseList } from '../../../../actions/receiver/fetch';
import {
  COMPANY_INFORMATION,
  SOCIAL_INFORMATION,
  WAREHOUSE_DISTRIBUTOR,
} from '../../../../constants/CompanyProfileConstants';
import { ApplicationState } from '../../../../reducers';
import { intercomEvent } from '../../../../utils/IntercomUtils';
import { isReceiver } from '../../../../utils/UserUtils';
import HeaderPage from '../../../global/page/HeaderPage';
import ReceiverProfile from '../../receiver/ReceiverProfile';
import CompanyProfileDrawer from './CompanyProfileDrawer';

const CompanyProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showDrawWithTitle, setShowDrawWithTitle] = React.useState<string | undefined>();

  const { companyInfo, fetchingCompanyInfo, user } = useSelector((state: ApplicationState) => {
    return {
      companyInfo: state.settings.companyProfile.companyInfo,
      fetchingCompanyInfo: state.settings.companyProfile.fetchingCompanyInfo,
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-company-profile');
  }, []);

  const sendIntercomEvents = (section: string, saved?: boolean) => {
    if (section === COMPANY_INFORMATION)
      intercomEvent('viewed-company-profile', {
        location: 'company-info',
        ...(saved ? { action: 'save-profile' } : {}),
      });
    if (section === SOCIAL_INFORMATION)
      intercomEvent('viewed-company-profile', {
        location: 'social-profile',
        ...(saved ? { action: 'save-profile' } : {}),
      });
    if (section === WAREHOUSE_DISTRIBUTOR)
      intercomEvent('viewed-company-profile', {
        location: 'WD-info',
        ...(saved ? { action: 'save-profile' } : {}),
      });
  };

  const handleEdit = (section: string) => {
    setShowDrawWithTitle(section);
    sendIntercomEvents(section);
  };

  if (fetchingCompanyInfo) return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <HeaderPage title={companyInfo?.name || ''} description={t('companyProfile:compDescription')}>
      <div className="flex receiver__company-profile">
        <ReceiverProfile
          receiverDetails={companyInfo}
          receiverUser={isReceiver(user!)}
          handleEdit={section => handleEdit(section)}
        />
      </div>
      {!!showDrawWithTitle && (
        <CompanyProfileDrawer
          drawerTitle={showDrawWithTitle}
          onClose={(saved?: boolean) => {
            if (saved) sendIntercomEvents(showDrawWithTitle, saved);
            setShowDrawWithTitle(undefined);
            dispatch(fetchReceiverWarehouseList());
          }}
          companyInfo={companyInfo!}
        />
      )}
    </HeaderPage>
  );
};

export default CompanyProfilePage;
