import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AttributeManagingContainer from '../../containers/parentSettings/attributes/AttributeManagingContainer';
import ParentExportLogsContainer from '../../containers/parentSettings/importExport/ParentExportLogsContainer';
import ParentImportLogsContainer from '../../containers/parentSettings/importExport/ParentImportLogsContainer';

const AttributeSettingsRoutes = () => (
  <Routes>
    <Route path="/" element={<AttributeManagingContainer />} />
    <Route path="import-logs" element={<ParentImportLogsContainer />} />
    <Route path="export-logs" element={<ParentExportLogsContainer />} />
  </Routes>
);

export default AttributeSettingsRoutes;
