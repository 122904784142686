import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { PlayCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { url as helpCenterUrl } from '../../../constants/HelpCenterConstants.json';
import { QuickStartStep } from '../../../../types/home';
import knowledgeBase from '../../../../images/icons/knowledgeBase.svg';
import { hasPermission } from '../../../utils/Permissions';
import { startIntercomTour } from '../../../utils/IntercomUtils';
import { ApplicationState } from '../../../reducers';
import UpgradePlan from './UpgradePlan';

type StartGuideContentProps = {
  disableNavigationButton: boolean;
  stepDetails: QuickStartStep;
};

const StartGuideContent: React.FC<StartGuideContentProps> = ({
  stepDetails,
  disableNavigationButton,
}) => {
  const navigate = useNavigate();

  const [permissionMissing, setPermissionMissing] = React.useState(false);
  const [allPermissionsMissing, setAllPermissionsMissing] = React.useState(false);

  const { user } = useSelector((state: ApplicationState) => ({
    user: state.user.user,
  }));

  React.useEffect(() => {
    const articlesWithoutPermission = stepDetails.articels?.filter(
      article => article.mandatory_permission && !hasPermission(user, article.mandatory_permission)
    );
    const tutorialsWithoutPermission = stepDetails.tutorials?.filter(
      tutorial =>
        tutorial.mandatory_permission && !hasPermission(user, tutorial.mandatory_permission)
    );
    if (
      (articlesWithoutPermission && articlesWithoutPermission?.length > 0) ||
      (tutorialsWithoutPermission && tutorialsWithoutPermission?.length > 0)
    )
      setPermissionMissing(true);
    if (
      (articlesWithoutPermission &&
        articlesWithoutPermission.length === stepDetails.articels?.length) ||
      (tutorialsWithoutPermission &&
        tutorialsWithoutPermission.length === stepDetails.tutorials?.length)
    )
      setAllPermissionsMissing(true);
  }, [stepDetails.articels, stepDetails.tutorials, user]);

  const openHelpArticle = (path: string) => {
    const wnd = window.open(helpCenterUrl + path, '_blank', 'noopener,noreferrer');
    if (wnd) wnd.opener = null;
  };

  // will be used in next release --temp hold for sprint s8
  // const renderPaidTag = () => {
  //   return (
  //     <Tag className="welcome__start-guide__paid-tag" color={colors['error-color']}>
  //       {t('welcome:paid')}
  //     </Tag>
  //   );
  // };

  return (
    <div className="mt-6 grid grid-cols-1">
      <h5 className="mb-4">{stepDetails.description}</h5>
      {stepDetails.articels?.map(article => (
        <div className="flex flex-row justify-start" key={article.title}>
          <Button
            type="link"
            onClick={e => {
              e.stopPropagation();
              openHelpArticle(article.url);
            }}
            className="knowledge-base-link"
          >
            <div className="flex flex-row items-center">
              <img src={knowledgeBase} alt="knowledgeBase" className="icon__knowledge-base mr-2" />
              {article.title}
            </div>
          </Button>
          {/* {article.mandatory_permission &&
            !hasPermission(user, article.mandatory_permission) &&
            renderPaidTag()} */}
        </div>
      ))}
      {stepDetails.tutorials?.map(tutorial => (
        <div className="flex flex-row justify-start" key={tutorial.title}>
          <Button
            icon={<PlayCircleFilled />}
            type="link"
            onClick={e => {
              e.stopPropagation();
              startIntercomTour(Number(tutorial.intercom_tour_id));
            }}
            className="start-tour-button"
          >
            {tutorial.title}
          </Button>
          {/* {tutorial.mandatory_permission &&
            !hasPermission(user, tutorial.mandatory_permission) &&
          renderPaidTag()} */}
        </div>
      ))}
      <div className="flex flex-row" key={stepDetails.button_title}>
        <Button
          type="primary"
          onClick={() => navigate(stepDetails.button_url)}
          className="start-guide-content_navigate-button"
          disabled={allPermissionsMissing || disableNavigationButton}
        >
          {stepDetails.button_title}
        </Button>
        {permissionMissing && (
          <div className="start-guide-content_upgrade-plan" key={stepDetails.description}>
            <UpgradePlan />
          </div>
        )}
      </div>
    </div>
  );
};

export default StartGuideContent;
