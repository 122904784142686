import React from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Card, Typography, Spin, Empty, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { AsyncDispatch } from '../../../../types/global';
import ScriptPartTypeSelection from './ScriptPartTypeSelection';
import { fetchScriptDataPreview, runScript } from '../../../actions/distribution_apisettings';

const { Paragraph } = Typography;

type ScriptDataPreviewPopoverProps = {
  item?: {
    id: number;
    partNumber: string;
    brandCode: string;
    brandName: string;
    defaultWhiteLabelCode: string | null;
  };
  eventId: number;
  handleSelectItem: (
    id: number,
    partNumber: string,
    brandCode: string,
    brandName: string,
    defaultWhiteLabelCode: string | null
  ) => void;
  dataPreview: any;
  fetchingDataPreview: boolean;
  displayRunScript?: boolean;
  scriptId?: number;
  newLogic: number;
};

const ScriptDataPreviewPopover: React.FC<ScriptDataPreviewPopoverProps> = ({
  item,
  eventId,
  handleSelectItem,
  dataPreview,
  fetchingDataPreview,
  displayRunScript,
  scriptId,
  newLogic,
  children,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [runScriptPending, setRunScriptPending] = React.useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <Popover
      title={t('api:previewTitle')}
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="api-automation-popover"
      placement="left"
      destroyTooltipOnHide
      content={
        <Card bordered={false}>
          <ScriptPartTypeSelection
            selectedValue={item?.id}
            partNumber={item?.partNumber || ''}
            brandCode={item?.brandCode || ''}
            brandName={item?.brandName || ''}
            defaultWhiteLabelCode={item?.defaultWhiteLabelCode || ''}
            onChange={(
              id: number,
              partNumber: string,
              brandCode: string,
              brandName: string,
              defaultWhiteLabelCode: string | null
            ) => {
              dispatch(fetchScriptDataPreview(eventId, newLogic, id));
              handleSelectItem(id, partNumber, brandCode, brandName, defaultWhiteLabelCode);
            }}
          />
          <div className="data-preview">
            {fetchingDataPreview && (
              <Spin className="spinner-center" style={{ marginTop: '16px' }} />
            )}
            {!fetchingDataPreview && !dataPreview && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {dataPreview?.data && (
              <div className="m-4">
                <div>{`{`}</div>
                {Object.keys(dataPreview.data).map(key => (
                  <div key={key}>{`"${key}": ${
                    typeof dataPreview.data[key] === 'object'
                      ? JSON.stringify(dataPreview.data[key], null, 2)
                      : dataPreview.data[key]
                  },`}</div>
                ))}
                <div className="flex flex-row justify-between">
                  {`}`}
                  <Paragraph
                    copyable={{ text: JSON.stringify(dataPreview, null, 2) }}
                    className="api-automation-drawer__copy"
                  />
                </div>
              </div>
            )}
          </div>
          {displayRunScript && (
            <div className="flex-col items-end mt-2">
              <Button
                type="primary"
                onClick={async () => {
                  setRunScriptPending(true);
                  await dispatch(runScript(scriptId!, dataPreview));
                  setRunScriptPending(false);
                  setPopoverVisible(false);
                }}
                loading={runScriptPending}
                size="small"
              >
                {t('api:runNow')}
              </Button>
            </div>
          )}
        </Card>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default ScriptDataPreviewPopover;
