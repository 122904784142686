import React from 'react';
import { Popover, Card, Button, Form, Input, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';

const cache: CellMeasurerCache = new CellMeasurerCache({ defaultHeight: 30, fixedWidth: true });

type TableFilterPopoverProps = {
  values: {
    id: number;
    name: string;
  }[];
  initiallySelectedIds: number[];
  handleChange: (selectedIds: number[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  showSearch?: boolean;
  height?: number;
};

const TableFilterPopover: React.FC<TableFilterPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [keywords, setKeywords] = React.useState('');

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('brandSearch');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setKeywords('');
    setPopoverVisible(visible);
    if (visible && props.onOpen) props.onOpen();
    else if (props.onClose) props.onClose();
  };

  const handleResize = () => {
    cache.clearAll();
  };

  const data = props.values.filter(value =>
    value.name.toLowerCase().includes(keywords.toLowerCase())
  );

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="table-filter-popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card>
          <Formik
            initialValues={{
              selectedIds: props.initiallySelectedIds,
            }}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleChange(values.selectedIds);
              setSubmitting(false);
              handleVisibleChange(false);
            }}
          >
            {({ handleSubmit, dirty, isSubmitting, setFieldValue, values }) => {
              const renderRow = ({ index, parent, key, style }: any) => {
                const row = data[index];
                const selected = values.selectedIds.includes(row.id);

                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    <div
                      key={key}
                      style={style}
                      className="row table-filter-popover__search-table_row flex"
                      onClick={() =>
                        setFieldValue(
                          'selectedIds',
                          selected
                            ? values.selectedIds.filter(id => id !== row.id)
                            : [...values.selectedIds, row.id].sort()
                        )
                      }
                    >
                      <div>
                        <Checkbox checked={selected} />
                      </div>
                      <div className="flex-1 pl-3">{row.name}</div>
                    </div>
                  </CellMeasurer>
                );
              };

              return (
                <div>
                  <Form layout="vertical">
                    <div className="table-popover-search">
                      {props.showSearch && (
                        <Input.Search
                          id="brandSearch"
                          value={keywords}
                          onChange={e => {
                            setKeywords(e.target.value);
                            handleResize();
                          }}
                        />
                      )}
                    </div>

                    <div className="table-filter-popover__search-table">
                      <List
                        deferredMeasurementCache={cache}
                        height={props.height || 300}
                        rowCount={data.length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={renderRow}
                        width={300}
                      />
                    </div>
                  </Form>

                  <div className="flex-col items-end mt-3 mr-3">
                    <div>
                      <Button
                        onClick={() => {
                          setFieldValue('selectedIds', []);
                          handleSubmit();
                        }}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:reset')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default TableFilterPopover;
