import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type AttributeImportExportMenuProps = {
  disabled: boolean;
  handleAction: (key: string) => void;
};

const AttributeImportExportMenu: React.FC<AttributeImportExportMenuProps> = props => {
  const { t } = useTranslation();

  const handleAction = (key: string) => props.handleAction(key);

  const items: MenuProps['items'] = [
    {
      label: t('common:import'),
      key: 'import',
      onClick: () => handleAction('import'),
    },
    {
      label: t('common:export'),
      key: 'export',
      onClick: () => handleAction('export'),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomLeft"
      className="ml-2"
      trigger={['click']}
      disabled={props.disabled}
    >
      <Button>
        {t('common:import/export')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AttributeImportExportMenu;
