import { Col, Input, Row, Tooltip } from 'antd';
import React from 'react';
import PackageDrawerReplaceButton from './PackageDrawerReplaceButton';

type PackageDrawerInputProps = {
  infoText: string;
  inputValue: any;
  disabled?: boolean;
  displayOk?: boolean;
  handleChange: (value: any) => void;
  handleReplaceAll: () => void;
  disableReplaceAll?: boolean;
  maxLength?: number;
};

const PackageDrawerInput: React.FC<PackageDrawerInputProps> = ({
  infoText,
  disabled,
  displayOk,
  inputValue,
  handleReplaceAll,
  handleChange,
  disableReplaceAll,
  maxLength,
}) => (
  <React.Fragment>
    <Row>
      <Col flex="auto">
        <Tooltip title={disabled ? infoText : ''}>
          <Input
            disabled={disabled}
            size="small"
            value={inputValue}
            onChange={e => {
              const value = e.target.value;
              handleChange(value);
            }}
            style={{ width: '100%' }}
            maxLength={maxLength}
          />
        </Tooltip>
      </Col>
      {displayOk && (
        <Col>
          <PackageDrawerReplaceButton
            handleReplace={handleReplaceAll}
            disableReplaceAll={disableReplaceAll}
          />
        </Col>
      )}
    </Row>
  </React.Fragment>
);

export default PackageDrawerInput;
