import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { notification } from 'antd';
import { MODAL_TYPES } from '../../../constants/ModalConstants';
import * as modalActions from '../../../actions/app/modal';

import BackendDownModal from './BackendDownModal';
import UserOfflineModal from './UserOfflineModal';
import InitAppModal from './InitAppModal';
import DigitalAssetUploadModal from '../../body/digital_assets/digital_asset_modal/DigitalAssetUploadModal';
import DigitalAssetPreviewModal from '../../body/digital_assets/digital_asset_modal/DigitalAssetPreviewModal';
import DigitalAssetExistingModal from '../../body/digital_assets/digital_asset_modal/DigitalAssetExistingModal';
import XMLPreviewModal from '../../body/sidebars/XMLPreviewModal';
import { ThunkAsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';

const MODAL_COMPONENTS = {
  [MODAL_TYPES.INIT_APP]: InitAppModal,
  [MODAL_TYPES.BACKEND_DOWN]: BackendDownModal,
  [MODAL_TYPES.USER_OFFLINE]: UserOfflineModal,
  [MODAL_TYPES.DIGITAL_ASSET_UPLOAD]: DigitalAssetUploadModal,
  [MODAL_TYPES.DIGITAL_ASSET_PREVIEW]: DigitalAssetPreviewModal,
  [MODAL_TYPES.DIGITAL_ASSET_EXISTING]: DigitalAssetExistingModal,
  [MODAL_TYPES.XML_PREVIEW_MODAL]: XMLPreviewModal,
};

type ModalRootProps = {
  dispatch: ThunkAsyncDispatch;
  modalType: string | undefined;
  modalProps: { [key: string]: any };
  showUpgradePlanNotification: boolean;
} & WithTranslation;

class ModalRoot extends React.Component<ModalRootProps> {
  componentDidUpdate(prevProps: ModalRootProps) {
    const { t, showUpgradePlanNotification } = this.props;
    if (showUpgradePlanNotification && !prevProps.showUpgradePlanNotification) {
      notification.error({
        message: t('mainApp:subscriptionError'),
        description: t('mainApp:subscriptionUpgrade'),
        placement: 'topLeft',
        style: { marginLeft: 'calc(100vw / 4)', width: 'calc(100vw / 2)' },
        onClose: () => this.props.dispatch(modalActions.hideUpgradePlanNotification()),
        duration: 10,
      });
    }
  }

  hideModal = () => {
    this.props.dispatch(modalActions.hideModal());
  };

  render() {
    const { modalType, modalProps } = this.props;

    if (!modalType) {
      return null;
    }

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return (
      <div className="modal-background">
        <SpecificModal hideModal={this.hideModal} {...modalProps} />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  modalType: state.app.modal.modalType,
  modalProps: state.app.modal.modalProps,
  showUpgradePlanNotification: state.app.modal.showUpgradePlanNotification,
});

export { ModalRoot };

export default connect(mapStateToProps)(withTranslation()(ModalRoot));
