import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Button } from 'antd';
import { useSelector } from 'react-redux';
import { HZFields } from '../../../constants/PackageConstants';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import PackageDescriptionPopover from './PackageDescriptionPopover';
import { HazardousMaterial } from '../../../../types/itemPackage';

type HazardousMaterialFormProps = {
  index: number;
  pkgIndex: number;
  showAllFields: boolean;
  hazardousMaterial: HazardousMaterial;
  updateHzMaterial: (fieldName: string, value: any) => void;
};

const HazardousMaterialForm: React.FC<HazardousMaterialFormProps> = ({
  index,
  pkgIndex,
  showAllFields,
  hazardousMaterial,
  updateHzMaterial,
}) => {
  const { t } = useTranslation();

  const {
    transportUoms,
    shippingScopeUoms,
    bulkUoms,
    countryUoms,
    regulatedOptions,
    pkgGroups,
    classUoms,
    labelUoms,
    qualifierUoms,
    whimUoms,
    languages,
    brandUsedFields,
  } = useSelector((state: ApplicationState) => ({
    transportUoms: state.resources.data.package.hazardous_material_transport_methods,
    shippingScopeUoms: state.resources.data.package.hazardous_material_shipping_scopes,
    bulkUoms: state.resources.data.package.hazardous_material_bulks,
    countryUoms: state.resources.data.global.countries,
    regulatedOptions: state.resources.data.package.hazardous_material_regulated_options,
    pkgGroups: state.resources.data.package.hazardous_material_packing_groups,
    classUoms: state.resources.data.package.hazardous_material_classes,
    labelUoms: state.resources.data.package.hazardous_material_labels,
    qualifierUoms: state.resources.data.package.hazardous_material_qualifiers,
    whimUoms: state.resources.data.package.hazardous_material_whmis_codes,
    languages: state.resources.data.global.languages,
    brandUsedFields: state.items.packageSeg.brandUsedFields
      .filter(title => title.includes('hazardous_materials'))
      .map(t => t.split('.')[1]),
  }));

  const formItemLayout = {
    labelCol: {
      sm: { span: 8 },
    },
    wrapperCol: {
      sm: { span: 16 },
    },
  };

  const [showField, setShowField] = React.useState<string | undefined>(undefined);

  const getUsedFields = (fields: string[]) => {
    if (!showAllFields) {
      return fields.filter(field => brandUsedFields.includes(field));
    }
    return fields;
  };

  const renderSuggestButton = (name: string) => (
    <div className="items-center">
      <Button
        key={name}
        type="dashed"
        size="small"
        onClick={() => {
          setShowField(name);
        }}
      >
        {t('common:add')}
      </Button>
    </div>
  );

  const renderRow = (field: string) => {
    switch (field) {
      case 'shipping_name': {
        return (
          <Form.Item
            label={t('packageSeg:hazardous.shippingName')}
            className="package__suggest-button"
          >
            {showField === `${index}_shippingName` || hazardousMaterial.shipping_name ? (
              <FormInput
                name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].shipping_name`}
                autoFocus={!hazardousMaterial.shipping_name}
                size="small"
                className={
                  hazardousMaterial.shipping_name
                    ? 'package-border-transparent package__borderless-input'
                    : ''
                }
                handleChange={e => {
                  const value = e.target.value;
                  updateHzMaterial('shipping_name', value);
                }}
                handleOnBlur={() => setShowField(undefined)}
                maxLength={260}
              />
            ) : (
              renderSuggestButton(`${index}_shippingName`)
            )}
          </Form.Item>
        );
      }
      case 'transport_method_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.transportMethod')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].transport_method_id`}
            className={
              hazardousMaterial.transport_method_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={transportUoms}
            required
            showSearch
          />
        );
      }
      case 'shipping_scope_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.shippingScope')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].shipping_scope_id`}
            className={
              hazardousMaterial.shipping_scope_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={shippingScopeUoms}
            required
            showSearch
          />
        );
      }
      case 'bulk_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.bulk')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].bulk_id`}
            className={
              hazardousMaterial.bulk_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={bulkUoms}
            required
            showSearch
          />
        );
      }
      case 'regulating_country_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.regulatingCountry')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].regulating_country_id`}
            className={
              hazardousMaterial.regulating_country_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={countryUoms}
            required
            showSearch
          />
        );
      }
      case 'regulations_exemption_code': {
        return (
          <Form.Item
            label={t('packageSeg:hazardous.regulatingExemptionCode')}
            className="package__suggest-button"
          >
            {showField === `${index}_regulatingExemptionCode` ||
            hazardousMaterial.regulations_exemption_code ? (
              <FormInput
                name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].regulations_exemption_code`}
                autoFocus={!hazardousMaterial.regulations_exemption_code}
                size="small"
                className={
                  hazardousMaterial.regulations_exemption_code
                    ? 'package-border-transparent package__borderless-input'
                    : ''
                }
                handleChange={e => {
                  const value = e.target.value;
                  updateHzMaterial('regulations_exemption_code', value);
                }}
                handleOnBlur={() => setShowField(undefined)}
                maxLength={4}
              />
            ) : (
              renderSuggestButton(`${index}_regulatingExemptionCode`)
            )}
          </Form.Item>
        );
      }
      case 'regulated_option_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.regulated')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].regulated_option_id`}
            className={
              hazardousMaterial.regulated_option_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={regulatedOptions}
            required
            showSearch
          />
        );
      }
      case 'outer_package_label': {
        return (
          <Form.Item
            label={t('packageSeg:hazardous.outerPackageLabel')}
            className="package__suggest-button"
          >
            {showField === `${index}_outerPackageLabel` || hazardousMaterial.outer_package_label ? (
              <FormInput
                name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].outer_package_label`}
                autoFocus={!hazardousMaterial.outer_package_label}
                size="small"
                maxLength={20}
                className={
                  hazardousMaterial.outer_package_label
                    ? 'pkg-form-select package-border-transparent package__borderless-input'
                    : 'pkg-form-select'
                }
                handleChange={e => {
                  const value = e.target.value;
                  updateHzMaterial('outer_package_label', value);
                }}
                handleOnBlur={() => setShowField(undefined)}
              />
            ) : (
              renderSuggestButton(`${index}_outerPackageLabel`)
            )}
          </Form.Item>
        );
      }
      case 'packing_group_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.packingGroupCode')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].packing_group_id`}
            className={
              hazardousMaterial.packing_group_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={pkgGroups}
            showSearch
          />
        );
      }
      case 'class_id': {
        return (
          <FormSelect
            showSearch
            label={t('packageSeg:hazardous.hzMaterialClass')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].class_id`}
            className={
              hazardousMaterial.class_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={classUoms}
          />
        );
      }
      case 'label_id': {
        return (
          <FormSelect
            showSearch
            label={t('packageSeg:hazardous.hzMaterialLabel')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].label_id`}
            className={
              hazardousMaterial.label_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={labelUoms}
          />
        );
      }
      case 'qualifier_id': {
        return (
          <FormSelect
            showSearch
            label={t('packageSeg:hazardous.hzMaterialQualifier')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].qualifier_id`}
            className={
              hazardousMaterial.qualifier_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={qualifierUoms}
          />
        );
      }
      case 'hazardous_material_description': {
        return (
          <Form.Item label={t('packageSeg:hazardous.hzMaterialDescription')}>
            <PackageDescriptionPopover
              description={hazardousMaterial.hazardous_material_description || ''}
              type={t('packageSeg:hazardous.hzMaterialDescription')}
              handleChange={description =>
                updateHzMaterial('hazardous_material_description', description)
              }
              maxLength={80}
              className={
                hazardousMaterial.hazardous_material_description ? 'package-border-description' : ''
              }
            />
          </Form.Item>
        );
      }
      case 'unnaid_code': {
        return (
          <Form.Item label={t('packageSeg:hazardous.unnaid')}>
            <PackageDescriptionPopover
              description={hazardousMaterial.unnaid_code || ''}
              type={t('packageSeg:hazardous.unnaid')}
              handleChange={description => updateHzMaterial('unnaid_code', description)}
              className={hazardousMaterial.unnaid_code ? 'package-border-description' : ''}
              maxLength={6}
            />
          </Form.Item>
        );
      }
      case 'whmis_code_id': {
        return (
          <FormSelect
            showSearch
            label={t('packageSeg:hazardous.whmisCode')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].whmis_code_id`}
            className={
              hazardousMaterial.whmis_code_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={whimUoms}
          />
        );
      }
      case 'whmis_free_text': {
        return (
          <Form.Item label={t('packageSeg:hazardous.whmisFreeText')}>
            <PackageDescriptionPopover
              description={hazardousMaterial.whmis_free_text || ''}
              type={t('packageSeg:hazardous.whmisFreeText')}
              handleChange={description => updateHzMaterial('whmis_free_text', description)}
              maxLength={80}
              className={hazardousMaterial.whmis_free_text ? 'package-border-description' : ''}
            />
          </Form.Item>
        );
      }
      case 'description': {
        return (
          <Form.Item label={t('packageSeg:hazardous.description')}>
            <PackageDescriptionPopover
              description={hazardousMaterial.description || ''}
              type={t('packageSeg:hazardous.description')}
              handleChange={description => updateHzMaterial('description', description)}
              maxLength={200}
              className={hazardousMaterial.description ? 'package-border-description' : ''}
            />
          </Form.Item>
        );
      }
      case 'language_id': {
        return (
          <FormSelect
            label={t('packageSeg:hazardous.language')}
            name={`extendedItemPackages[${pkgIndex}].hazardous_materials[${index}].language_id`}
            className={
              hazardousMaterial.language_id
                ? 'pkg-form-select package-border-transparent package__borderless-select '
                : 'pkg-form-select'
            }
            size="small"
            allowClear
            values={languages}
            required
            showSearch
          />
        );
      }
      case 'text_message': {
        return (
          <Form.Item label={t('packageSeg:hazardous.textMessage')}>
            <PackageDescriptionPopover
              description={hazardousMaterial.text_message || ''}
              type={t('packageSeg:hazardous.textMessage')}
              handleChange={description => updateHzMaterial('text_message', description)}
              maxLength={2000}
              className={hazardousMaterial.text_message ? 'package-border-description' : ''}
            />
          </Form.Item>
        );
      }
    }
  };

  return (
    <Form className="package-form package__hz-form" {...formItemLayout} labelAlign="left">
      {getUsedFields(HZFields).map((field, index) => (
        <React.Fragment key={field}>
          {renderRow(field)}
          {getUsedFields(HZFields).length !== index + 1 && <Divider />}
        </React.Fragment>
      ))}
    </Form>
  );
};

export default HazardousMaterialForm;
