import React from 'react';
import { Divider } from 'antd';

type SimpleDividerProps = {
  className?: string;
  title: string;
  titlePosition?: 'left' | 'right' | 'center';
};

const SimpleDivider: React.FC<SimpleDividerProps> = ({ className, title, titlePosition }) => {
  return (
    <div className="flex-1 text-left">
      <Divider className={`ant__divider ${className || ''}`} orientation={titlePosition || 'left'}>
        {title}
      </Divider>
    </div>
  );
};

export default SimpleDivider;
