import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';
import { AsyncDispatch } from '../../../types/global';
import { DefaultValue } from '../../../types/brand_settings';
import RetoolPage from '../../components/body/retool/RetoolPage';
import ItemInfo from '../../components/global/ItemInfo';
import { clearRetoolEmbedUrl, fetchRetoolEmbedUrl } from '../../actions/retool';
import { BRAND_AREA } from '../../constants/InAppAreaConst';

const RetoolContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { selectedItems, brandCode, embedUrl, fetchingEmbedUrl, user, inAppArea, defaultValues } =
    useSelector((state: ApplicationState) => {
      return {
        selectedItems: getSelectedItems(state),
        brandCode: getSelectedBrandCode(state),
        embedUrl: state.tools.retool.embedUrl,
        fetchingEmbedUrl: state.tools.retool.fetchingEmbedUrl,
        user: state.user.user,
        brandId: state.parent.brands.selectedBrandId,
        brands: state.parent.brands.brands,
        inAppArea: state.app.global.inAppArea,
        defaultValues: state.settings.defaultValues,
      };
    });

  const brandView = inAppArea === BRAND_AREA && location.pathname === '/brand/retool';
  const itemView = inAppArea === BRAND_AREA && location.pathname !== '/brand/retool';
  const defaultBrandApp = defaultValues.find((d: DefaultValue) => d.id === 14)?.value;
  const defaultItemApp = defaultValues.find((d: DefaultValue) => d.id === 15)?.value;

  const [appName, setAppName] = React.useState(
    (brandView && defaultBrandApp) || (itemView && defaultItemApp) || 'pdm_testing'
  );
  const [selectedItemCount, setSelectedItemCount] = React.useState(selectedItems.length);

  const canManageRetool = useMemo(() => hasPermission(user, 'can_access_retool'), [user]);

  React.useEffect(() => {
    intercomEvent('viewed-retool');
  }, []);

  React.useEffect(() => {
    if (canManageRetool) dispatch(fetchRetoolEmbedUrl(appName));
  }, [dispatch, appName, canManageRetool]);

  React.useEffect(() => {
    if (
      inAppArea === BRAND_AREA &&
      location.pathname !== '/brand/retool' &&
      selectedItemCount !== selectedItems.length
    ) {
      setSelectedItemCount(selectedItems.length);
      if (selectedItems.length === 1) dispatch(fetchRetoolEmbedUrl(appName));
      else if (embedUrl) dispatch(clearRetoolEmbedUrl());
    }
  }, [
    dispatch,
    appName,
    canManageRetool,
    selectedItemCount,
    selectedItems.length,
    inAppArea,
    location.pathname,
    embedUrl,
  ]);

  const changeApp = (appName: string) => {
    setAppName(appName);
  };

  if (!canManageRetool) {
    return (
      <div className="p-5">
        <Alert
          showIcon
          type="info"
          message={t('common:noAccess')}
          description={t('retool:noPermission')}
        />
      </div>
    );
  }

  if (fetchingEmbedUrl) {
    return <Spin className="spinner-center" style={{ marginTop: '100px' }} />;
  }

  if (
    inAppArea === BRAND_AREA &&
    location.pathname !== '/brand/retool' &&
    (selectedItems.length > 1 || selectedItems.length === 0)
  ) {
    return <ItemInfo items={selectedItems} />;
  }

  return (
    <RetoolPage
      embedUrl={embedUrl}
      appName={appName}
      accountId={user!.account_id}
      brandCode={inAppArea === BRAND_AREA ? brandCode : undefined}
      partNumber={
        inAppArea === BRAND_AREA &&
        location.pathname !== '/brand/retool' &&
        selectedItems[0] &&
        selectedItems[0].part_number
      }
      changeApp={(name: string) => changeApp(name)}
    />
  );
};

export default RetoolContainer;
