import { createSelector } from 'reselect';
import { ApplicationState } from '../reducers';
import {
  ExportLog,
  ExternalSourceImport,
  FileExportOption,
  ImportLog,
} from '../../types/import_export';
import { dateToString } from '../components/body/parentSettings/importLogs/ImportExportUtils';
import { StandardResourceCodeAuth } from '../../types/resources';

const importLogs = (state: ApplicationState) => state.brand.importExport.importLogs;
const fileExportOptions = (state: ApplicationState) => state.brand.brandExport.fileExportOptions;
const exportLogs = (state: ApplicationState) => state.brand.importExport.exportLogs;
const parentImportLogs = (state: ApplicationState) => state.parent.parentImportLogs.importLogs;
const parentExportLogs = (state: ApplicationState) => state.parent.parentExportLogs.exportLogs;
const externalSourceImportLogs = (state: ApplicationState) => state.externalSources.importLogs;
const externalDataProviders = (state: ApplicationState) =>
  state.resources.data.receiver.external_data_providers;
const receiverImportLogs = (state: ApplicationState) => state.receiver.receiverImport.importLogs;

const getUpdatedReceiverImportLogs = (receiverImportLogs: ImportLog[]) => {
  return receiverImportLogs.map(log => {
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, startedTime, completedTime };
  });
};
const getUpdatedImportLogs = (importLogs: ImportLog[], fileExportOptions: FileExportOption[]) => {
  return importLogs.map(log => {
    const fileTypeName = log.file_type_id
      ? fileExportOptions.find(f => f.id === log.file_type_id)?.name || ''
      : '';
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, fileTypeName, startedTime, completedTime };
  });
};

const getUpdatedExportLogs = (exportLogs: ExportLog[], fileExportOptions: FileExportOption[]) => {
  return exportLogs.map(log => {
    const fileTypeName = log.file_type_id
      ? fileExportOptions.find(f => f.id === log.file_type_id)?.name ||
        (log.file_type_id === 21 && 'Gap/Coverage Excel Report ') ||
        ''
      : '';
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, fileTypeName, startedTime, completedTime };
  });
};

const getUpdatedParentImportLogs = (importLogs: ImportLog[]) => {
  return importLogs.map(log => {
    const fileTypeName =
      log.file_type_id === 13
        ? 'Translations'
        : (log.file_type_id === 20 && 'Category Part Attributes') || 'Custom VCdb';
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, fileTypeName, startedTime, completedTime };
  });
};

const getUpdatedParentExportLogs = (exportLogs: ExportLog[]) => {
  return exportLogs.map(log => {
    const fileTypeName =
      log.file_type_id === 13
        ? 'Translations'
        : (log.file_type_id === 20 && 'Category Part Attributes') || 'Custom VCdb';
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, fileTypeName, startedTime, completedTime };
  });
};

const getUpdatedExternalSourcesImportLogs = (
  importLogs: ExternalSourceImport[],
  externalDataProviders: StandardResourceCodeAuth[]
) => {
  return importLogs.map(log => {
    const fileTypeName = log.external_data_provider_id
      ? externalDataProviders.find(f => f.id === log.external_data_provider_id)?.name || ''
      : '';
    const startedTime = dateToString(log.started_at);
    const completedTime = dateToString(log.started_at ? log.completed_at : null);
    return { ...log, fileTypeName, startedTime, completedTime };
  });
};

export const receiverImportLogMapper = createSelector([receiverImportLogs], receiverImportLogs =>
  getUpdatedReceiverImportLogs(receiverImportLogs)
);

export const importLogMapper = createSelector(
  [importLogs, fileExportOptions],
  (importLogs, fileExportOptions) => getUpdatedImportLogs(importLogs, fileExportOptions)
);

export const exportLogMapper = createSelector(
  [exportLogs, fileExportOptions],
  (exportLogs, fileExportOptions) => getUpdatedExportLogs(exportLogs, fileExportOptions)
);

export const parentImportLogMapper = createSelector([parentImportLogs], parentImportLogs =>
  getUpdatedParentImportLogs(parentImportLogs)
);

export const parentExportLogMapper = createSelector([parentExportLogs], parentExportLogs =>
  getUpdatedParentExportLogs(parentExportLogs)
);

export const externalSorcesImportLogMapper = createSelector(
  [externalSourceImportLogs, externalDataProviders],
  (parentTranslationExportLogs, externalDataProviders) =>
    getUpdatedExternalSourcesImportLogs(parentTranslationExportLogs, externalDataProviders)
);
