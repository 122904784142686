import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import {
  ExportBuilderAdvancedTemplate,
  ExportBuilderAdvancedSegment,
  ExportBuilderAdvancedColumn,
} from '../../../types/export_builder_advanced';

export type ExportBuilderAdvancedState = {
  readonly templates: ExportBuilderAdvancedTemplate[];
  readonly templateColumns: ExportBuilderAdvancedColumn[];
  readonly segments: ExportBuilderAdvancedSegment[];
  readonly preview: any[];
  readonly fetchingSegments: boolean;
  readonly fetchingSegment: number[];
  readonly fetchingColumns: boolean;
  readonly fetchingPreview: boolean;
};

const initalState: ExportBuilderAdvancedState = {
  templates: [],
  templateColumns: [],
  segments: [],
  preview: [],
  fetchingSegments: false,
  fetchingSegment: [],
  fetchingColumns: false,
  fetchingPreview: true,
};

const reducer: Reducer<ExportBuilderAdvancedState, FluxStandardAction> = (
  state = initalState,
  action
): ExportBuilderAdvancedState => {
  switch (action.type) {
    case 'FETCH_EXPORT_BUILDER_ADVANCED_TEMPLATES_PENDING': {
      return {
        ...state,
        templates: [],
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_TEMPLATES_FULFILLED': {
      return {
        ...state,
        templates: action.payload.data,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_COLUMS_PENDING': {
      return {
        ...state,
        fetchingColumns: true,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_COLUMS_FULFILLED': {
      return {
        ...state,
        templateColumns: action.payload.data,
        fetchingColumns: false,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENTS_PENDING': {
      return {
        ...state,
        segments: [],
        fetchingSegments: true,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENTS_FULFILLED': {
      return {
        ...state,
        segments: action.payload.data,
        fetchingSegments: false,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENT_PENDING': {
      return {
        ...state,
        fetchingSegment: [...state.fetchingSegment, action.meta.segmentId],
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENT_REJECTED': {
      return {
        ...state,
        fetchingSegment: state.fetchingSegment.filter(id => id !== action.meta.segmentId),
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENT_FULFILLED': {
      return {
        ...state,
        segments: [...state.segments, ...action.payload.data].sort((a, b) =>
          a.id > b.id ? 1 : -1
        ),
        fetchingSegment: state.fetchingSegment.filter(id => id !== action.meta.segmentId),
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_PREVIEW_PENDING': {
      return {
        ...state,
        fetchingPreview: true,
      };
    }
    case 'FETCH_EXPORT_BUILDER_ADVANCED_PREVIEW_FULFILLED': {
      return {
        ...state,
        preview: action.payload.data,
        fetchingPreview: false,
      };
    }
    case 'UPDATE_EXPORT_BUILDER_ADVANCED_TEMPLATE_FULFILLED': {
      const template = action.payload.data;
      return {
        ...state,
        templates: action.meta.isNewTemplate
          ? [...state.templates, template]
          : state.templates.map(t => {
              if (t.id === template.id) {
                return template;
              }
              return t;
            }),
      };
    }
    case 'UPDATE_EXPORT_BUILDER_ADVANCED_COLUMN_FULFILLED': {
      const column = action.payload.data;
      return {
        ...state,
        templateColumns: action.meta.isNewColumn
          ? [...state.templateColumns, column]
          : state.templateColumns.map(tc => {
              if (tc.id === column.id) {
                return column;
              }
              return tc;
            }),
      };
    }
    case 'UPDATE_EXPORT_BUILDER_ADVANCED_COLUMN_ORDER_FULFILLED': {
      const columns = action.meta.orderList.map(
        (t: { full_export_template_column_id: number; number: number }) => {
          const column = state.templateColumns.find(
            col => col.id === t.full_export_template_column_id
          );
          return { ...column, number: t.number };
        }
      );
      return { ...state, templateColumns: columns };
    }
    case 'DELETE_EXPORT_BUILDER_ADVANCED_TEMPLATE_FULFILLED': {
      return {
        ...state,
        templates: state.templates.filter(t => t.id !== action.meta.templateId),
      };
    }
    case 'DELETE_EXPORT_BUILDER_ADVANCED_COLUMN_FULFILLED': {
      return {
        ...state,
        templateColumns: state.templateColumns.filter(t => t.id !== action.meta.columnId),
      };
    }
    case 'RESET_EXPORT_BUILDER_ADVANCED': {
      return {
        templates: [],
        templateColumns: [],
        fetchingColumns: false,
        fetchingPreview: true,
        segments: [],
        preview: [],
        fetchingSegments: false,
        fetchingSegment: [],
      };
    }
  }
  return state;
};

export default reducer;
