import { Field, FieldProps } from 'formik';
import { Form, Input } from 'antd';
import React from 'react';
import { get as _get } from 'lodash';
import { InputProps } from 'antd/lib/input';

type FormPasswordInputProps = {
  name: string;
  label?: React.ReactNode;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  testId?: string;
  handleOnBlur?: () => void;
  handleChange?: (e: React.ChangeEvent<any>) => void;
} & InputProps;

const FormPasswordInput: React.FC<FormPasswordInputProps> = props => {
  const {
    name,
    label,
    className,
    placeholder,
    required,
    disabled,
    testId,
    size,
    handleOnBlur,
    handleChange,
  } = props;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { onChange, onBlur } = field;
        const { errors, touched } = form;
        // use lodash because name can include dots eg. foo.bar
        const showValidationInfo = !!(_get(errors, name) && _get(touched, name));

        return (
          <Form.Item
            label={label}
            className={className}
            hasFeedback
            validateStatus={showValidationInfo ? 'error' : ''}
            help={(showValidationInfo && _get(errors, name)) || undefined}
            required={required}
          >
            <Input.Password
              data-testid={testId}
              name={name}
              placeholder={placeholder}
              value={field.value}
              onChange={handleChange || onChange}
              onBlur={handleOnBlur || onBlur}
              disabled={disabled}
              size={size}
            />
          </Form.Item>
        );
      }}
    </Field>
  );
};

export default FormPasswordInput;
