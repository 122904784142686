import React from 'react';
import ModalInfo from './ModalInfo';
import translationConstants from '../../../constants/ModalTranslation.json';

class UserOfflineModal extends React.Component {
  componentDidMount() {
    this.checkConnectionInterval = setInterval(this.checkStatusOfConnection, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.checkConnectionInterval);
  }

  checkStatusOfConnection = () => {
    const onLine = window.navigator.onLine;
    if (onLine) {
      this.props.hideModal();
      clearInterval(this.checkConnectionInterval);
    }
  };

  render() {
    return <ModalInfo type="warning" message={translationConstants.user_offline.message} />;
  }
}

export default UserOfflineModal;
