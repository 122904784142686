import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { ApplicationState } from '../../reducers';
import DescriptionsList from '../../components/global/DescriptionsList';

const SettingsAutoCareVersions: React.FC = () => {
  const { t } = useTranslation();
  const { globalResources } = useSelector((state: ApplicationState) => {
    return { globalResources: state.resources.data.global };
  }, shallowEqual);

  return (
    <div className="bg-white h-full p-4">
      <div className="font-medium text-base mb-4 text-gray-900">
        {t('settings:autoCareVersion.autoCareTableVersion')}
      </div>
      <DescriptionsList colNumber={1}>
        <div className="flex">
          <div className="font-medium mr-2">{t('settings:autoCareVersion.pcdbVersion')}:</div>
          <div className="text-gray-700">{globalResources.category_versions}</div>
        </div>
        <div className="flex">
          <div className="font-medium mr-2">{t('settings:autoCareVersion.padbVersion')}:</div>
          <div className="text-gray-700">{globalResources.part_attribute_padb_versions}</div>
        </div>
        <div className="flex">
          <div className="font-medium mr-2">{t('settings:autoCareVersion.vcdbVersion')}:</div>
          <div className="text-gray-700">{globalResources.vehicle_versions}</div>
        </div>
        <div className="flex">
          <div className="font-medium mr-2">{t('settings:autoCareVersion.qdbVersion')}:</div>
          <div className="text-gray-700">{globalResources.qdb_versions}</div>
        </div>
      </DescriptionsList>
    </div>
  );
};

export default SettingsAutoCareVersions;
