import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Switch } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import PageFormik from '../../global/page/PageFormik';
import { Segment } from '../../../../types/resources';
import { SegmentResources } from '../../../../types/scoreCards';
import {
  createAnalysisTypeForBrand,
  deleteAnalysisTypeForBrand,
} from '../../../actions/settings/scorecards';
import FormSwitch from '../../global/Forms/FormSwitch';

type SettingsScorecardProps = {
  validatedAnalysisTypeIds: number[];
  canManageScorecard: boolean;
  updateSelectedSegmentId: (id: number) => void;
  updateSelectedAlertTypeId: (id: number) => void;
  updateSelectedScorecardId: (id: number) => void;
  analysisResources: SegmentResources;
  filterAnalysesResources: {
    selectedSegmentId?: number;
    selectedAlertTypeId?: number;
    selectedScorecardId?: number;
  };
  filteredAnalysisResources: SegmentResources;
  brandId: number;
};

const SettingsScorecard: React.FC<SettingsScorecardProps> = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelectSegment = (id: number) => {
    props.updateSelectedSegmentId(id);
  };

  const handleSelectAlertType = (id: number) => {
    props.updateSelectedAlertTypeId(id);
  };

  const handleSelectScorecard = (id: number) => {
    props.updateSelectedScorecardId(id);
  };

  const filteredResourcesIds = useMemo(
    () => props.filteredAnalysisResources.types.map(t => t.id),
    [props.filteredAnalysisResources.types]
  );

  // this will select the segments
  const segments = () => (
    <div className="settings-scorecard__table">
      {props.analysisResources.segments.map(segment => {
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected: segment.id === props.filterAnalysesResources.selectedSegmentId,
        });
        return (
          <span key={segment.id} className={rowCss} onClick={() => handleSelectSegment(segment.id)}>
            {segment.title}
          </span>
        );
      })}
    </div>
  );

  const alertTypes = () => (
    <div className="settings-scorecard__table">
      {props.analysisResources.alert_types.map(alertType => {
        const selected = alertType.id === props.filterAnalysesResources.selectedAlertTypeId;
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected,
        });
        const iconCss = classNames('mr-2', {
          blue: alertType.id === 1,
          yellow: alertType.id === 2,
          red: alertType.id === 3,
          selected: alertType.id === 1 && selected,
        });
        return (
          <div
            key={alertType.id}
            className={rowCss}
            onClick={() => handleSelectAlertType(alertType.id)}
          >
            <WarningOutlined className={iconCss} />
            {alertType.name}
          </div>
        );
      })}
    </div>
  );

  const scorecards = () => (
    <div className="settings-scorecard__table">
      {props.analysisResources.scorecards.map(scorecard => {
        const selected = scorecard.id === props.filterAnalysesResources.selectedScorecardId;
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected,
        });
        const validatingInfoCss = classNames('settings-scorecard__validating-info', { selected });
        const iconCSS = classNames('ml-1', { selected });
        return (
          <div
            key={scorecard.id}
            className={rowCss}
            onClick={() => handleSelectScorecard(scorecard.id)}
          >
            {scorecard.name}
            {scorecard.validating_against_scorecard && (
              <span className={validatingInfoCss}>
                {t('scorecards:validating')}
                <CheckCircleOutlined className={iconCSS} />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );

  const getInitialValues = () => {
    const typesInitialValues: Record<string, boolean> = {};
    props.analysisResources.types.forEach(
      type => (typesInitialValues[type.id] = props.validatedAnalysisTypeIds.includes(type.id))
    );
    return typesInitialValues;
  };

  const handleSubmit = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;

    // Get newly selected values
    const createIds = Object.keys(values).filter(key => values[key] === true);
    const createAnalysisIds = props.analysisResources.types
      .filter(type => createIds.includes(type.id.toString()))
      .filter(type => !props.validatedAnalysisTypeIds.includes(type.id))
      .map(type => type.id);

    // Get deleted values
    const deleteIds = Object.keys(values).filter(key => values[key] === false);
    const deleteAnalysisIds = props.analysisResources.types
      .filter(type => deleteIds.includes(type.id.toString()))
      .filter(type => props.validatedAnalysisTypeIds.includes(type.id))
      .map(type => type.id);

    setSubmitting(true);

    if (createAnalysisIds.length > 0)
      await dispatch(createAnalysisTypeForBrand(createAnalysisIds, props.brandId));
    if (deleteAnalysisIds.length > 0)
      await dispatch(deleteAnalysisTypeForBrand(deleteAnalysisIds, props.brandId));

    setSubmitting(false);
  };

  return (
    <PageFormik
      enableReinitialize
      initialValues={getInitialValues()}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      contentNoSpacing
    >
      {({ values, setFieldValue, setValues }) => {
        const analysisTypes = (segment: Segment) => {
          const { canManageScorecard } = props;
          const segmentAlerts = props.filteredAnalysisResources.types.filter(
            alert => alert.segment_id === segment.id
          );

          return segmentAlerts.map(analysis => {
            const iconCss = classNames('mr-2', {
              blue: analysis.alert_type_id === 1,
              yellow: analysis.alert_type_id === 2,
              red: analysis.alert_type_id === 3,
            });

            return (
              <span key={analysis.id} className="row settings-scorecard__row">
                <WarningOutlined className={iconCss} />
                {analysis.name}
                <span className="settings-scorecard__switch">
                  <FormSwitch
                    key={analysis.id}
                    disabled={!canManageScorecard}
                    name={`${analysis.id.toString()}`}
                    handleChange={checked => {
                      setFieldValue(analysis.id.toString(), checked);
                    }}
                  />
                </span>
              </span>
            );
          });
        };

        const alertGroups = () => {
          const { filteredAnalysisResources } = props;
          const typeIds = [
            ...new Set(filteredAnalysisResources.types.map(type => type.segment_id)),
          ];
          const filteredSegments = filteredAnalysisResources.segments.filter(segment =>
            typeIds.includes(segment.id)
          );

          return (
            <div className=" settings-scorecard__table">
              {filteredSegments.map(segment => (
                <React.Fragment key={segment.id}>
                  <div className="row settings-scorecard__row settings_scorecard__segment-row">
                    {segment.title}
                  </div>
                  {analysisTypes(segment)}
                </React.Fragment>
              ))}
            </div>
          );
        };

        return (
          <div className="content settings-scorecard">
            <div className="settings-scorecard__info">
              <div className="settings-scorecard__title-container">
                <div className="setting-scorecard__title-left">
                  <span className="title">{t('scorecards:scorecards')}</span>
                </div>
              </div>
              <div>{t('scorecards:info')}</div>
            </div>
            <div className="settings-scorecard__selection">
              <div className="settings-scorecard_left">
                <div className="title">{t('scorecards:segments')}</div>
                {segments()}
              </div>
              <div className="settings-scorecard_middle">
                <div className="settings-scorecard__alert-types-container">
                  <div className="title">{t('scorecards:alert_types')}</div>
                  {alertTypes()}
                </div>
                <div className="settings-scorecard__scorecards-container">
                  <div className="title">{t('scorecards:scorecards')}</div>
                  {scorecards()}
                </div>
              </div>
              <div className="settings-scorecard_right">
                <div className="settings-scorecard__title-container flex">
                  <div className="title settings-scorecard__title">{t('scorecards:alerts')}</div>
                  <div className="settings-scorecard__select-all-alerts">
                    <span className="select-all-alerts__title">{t('scorecards:validate_all')}</span>
                    <span className="settings-scorecard__switch">
                      <Switch
                        checked={filteredResourcesIds.every(id => values[id] === true)}
                        onClick={checked => {
                          setValues(
                            Object.fromEntries(
                              Object.entries(values).map(([key, value]) =>
                                filteredResourcesIds.includes(Number(key))
                                  ? [key, checked]
                                  : [key, value]
                              )
                            )
                          );
                        }}
                        disabled={!props.canManageScorecard}
                      />
                    </span>
                  </div>
                </div>
                {alertGroups()}
              </div>
            </div>
          </div>
        );
      }}
    </PageFormik>
  );
};

export default SettingsScorecard;
