import axios from 'axios';
import { AsyncAction } from 'redux-promise-middleware';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { Content, MarketingCopyGroup } from '../../../../types/marketing_copy';
import { DigitalAsset } from '../../../../types/resources';

export function updateMarketingCopy({
  id,
  codeId,
  referenceId,
  subCodeId,
  subReferenceId,
  contents,
  assets,
  languageId,
}: {
  id: number;
  codeId?: number | null;
  referenceId: number | undefined | null;
  subCodeId?: number | null;
  subReferenceId?: number | null;
  contents: Content[];
  assets: DigitalAsset[];
  languageId?: number;
}) {
  const urlParams = utils.createURL([
    { name: 'code_id', values: codeId },
    { name: 'reference_id', values: referenceId },
    { name: 'sub_code_id', values: subCodeId, allowEmpty: true },
    { name: 'sub_reference_id', values: subReferenceId, allowEmpty: true },
    { name: 'language_id', values: languageId },
  ]);

  return {
    type: 'UPDATE_MARKETING_COPY',
    meta: {
      id,
      codeId,
      referenceId,
      subCodeId,
      subReferenceId,
      contents,
      languageId,
    },
    payload: axios.patch(`${getApiRoot()}/parents/marketing_copies/${id}${urlParams}`, {
      assets,
      contents,
    }),
  };
}

export function updateGroups(groups: MarketingCopyGroup[]): AsyncAction {
  return {
    type: 'UPDATE_MARKETING_COPY_GROUPS',
    payload: axios.post(`${getApiRoot()}/parents/part_groups`, {
      part_groups: groups,
    }),
  };
}

export function addUploadedDigitalAsset(asset: DigitalAsset) {
  return {
    type: 'ADD_UPLOADED_DIGITAL_ASSET',
    payload: { asset },
  };
}

export function resetUploadedDigitalAssets() {
  return {
    type: 'RESET_UPLOADED_DIGITAL_ASSETS',
  };
}

export function setSelectedMarketingCopyId(id: number | null) {
  return {
    type: 'SET_SELECTED_MARKETING_COPY_ID',
    payload: id,
  };
}
