import React from 'react';
import { Layout } from 'antd';
import SyncRoutes from '../../routes/brand/SyncRoutes';
import { withBrandRouteWrapper } from '../../containers/BrandRouteWrapper';

const { Content } = Layout;

class SyncPage extends React.Component {
  render() {
    return (
      <Layout className="layout">
        <Content className="content">
          <SyncRoutes />
        </Content>
      </Layout>
    );
  }
}

export default withBrandRouteWrapper(SyncPage);
