import { ReceiverCatalogueBrandType } from '../../types/receiver_data_stream';

export const isBrandRequestPending = (brand: ReceiverCatalogueBrandType) => {
  const {
    authorized_by_brand_at,
    authorized_by_receiver_at,
    declined_by_brand_at,
    declined_by_receiver_at,
  } = brand;
  return (
    !!authorized_by_brand_at &&
    !authorized_by_receiver_at &&
    !declined_by_brand_at &&
    !declined_by_receiver_at
  );
};
