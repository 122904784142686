import React from 'react';
import { Popover, Card, Button, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormTextArea from '../../global/Forms/FormTextArea';
import AntTooltip from '../../global/AntTooltip';

type ReceiverRejectPopoverProps = {
  receiverName?: string;
  handleReject: (value?: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const ReceiverRejectPopover: React.FC<ReceiverRejectPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('rejectValueInput');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
    if (visible && props.onOpen) props.onOpen();
    else if (props.onClose) props.onClose();
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.string().min(10),
  });

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="help receiver__reject-popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="w-full">
          <Formik
            initialValues={{
              reason: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleReject(values.reason);
              setSubmitting(false);
              handleVisibleChange(false);
            }}
          >
            {({ handleSubmit, dirty, isSubmitting, values }) => {
              if (!popoverVisible) return null;

              return (
                <div>
                  <Form layout="vertical">
                    <div className="font-medium mb-3">
                      {t('receiver:declineReason', { receiver: props.receiverName })}
                      <AntTooltip title={t('receiver:declineReasonInfo')}>
                        <InfoCircleOutlined className="ant-info-tooltip ml-1" />
                      </AntTooltip>
                    </div>

                    <FormTextArea
                      id="rejectValueInput"
                      name="reason"
                      placeholder={t('receiver:declineReasonPlaceholder')}
                      maxLength={255}
                      showCount
                    />
                  </Form>

                  <div className="flex-col items-end">
                    <div>
                      <Button
                        onClick={() => props.handleReject(values.reason)}
                        className="mr-1"
                        type="text"
                      >
                        {t('receiver:noThanks')}
                      </Button>
                      <Button onClick={() => handleVisibleChange(false)} className="mr-2">
                        {t('common:cancel')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default ReceiverRejectPopover;
