import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemInfo from '../../components/global/ItemInfo';
import DescriptionPage from '../../components/body/description/DescriptionPage';
import * as navigationActions from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import {
  fetchUsedDescriptions,
  fetchDescriptions,
  fetchMarketingCopies,
} from '../../actions/items/description/fetch';
import {
  fetchMarketingCopyGroups,
  fetchMarketingCopyBrands,
} from '../../actions/parent/marketing_copy/fetch';
import { intercomEvent } from '../../utils/IntercomUtils';

const DescriptionContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { selectedItems, itemId, selectedBrandId, itemBrandId, usedDescriptionBrandId } =
    useSelector((state: ApplicationState) => {
      return {
        itemId: state.catalogue.catalogue.selectedItemIds[0],
        selectedItems: getSelectedItems(state),
        selectedBrandId: state.parent.brands.selectedBrandId,
        itemBrandId: getSelectedItems(state)[0]?.parent_owner_brand_id,
        usedDescriptionBrandId: state.items.description.usedDescriptionBrandId,
      };
    });

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.description));
    dispatch(fetchMarketingCopyGroups());
    dispatch(fetchMarketingCopyBrands());
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'description',
      part_number: selectedItems.map(i => i.part_number).join(', '),
      brand_code: selectedItems[0]?.brand_code,
    });
  }, [selectedItems]);

  React.useEffect(() => {
    const brandId = selectedBrandId || itemBrandId;
    if (brandId && usedDescriptionBrandId !== brandId) {
      dispatch(fetchUsedDescriptions(brandId));
    }
  }, [dispatch, itemBrandId, selectedBrandId, usedDescriptionBrandId]);

  React.useEffect(() => {
    if (itemId) {
      dispatch(fetchDescriptions(itemId));
      dispatch(fetchMarketingCopies(itemId));
    }
  }, [dispatch, itemId]);

  if (selectedItems.length > 1 || selectedItems.length === 0) {
    return <ItemInfo items={selectedItems} />;
  }

  return <DescriptionPage />;
};

export default DescriptionContainer;
