import React from 'react';
import Dropdown from '../../../global/Dropdown';
import { placeholder } from '../../../../constants/DigitalAssetsTranslation.json';

class FileNameType extends React.Component {
  render() {
    const { resources, fileName, fileTypeId, fileTypeDetectedFromSource } = this.props;
    const digitalAssetFileTypes = resources.file_types;
    const dropdownElements = digitalAssetFileTypes.map(el => ({ id: el.id, name: el.code }));

    return (
      <div className="digital-assets__file-name">
        <span className="digital-assets__file-title">{placeholder.file_name}</span>
        <input
          className="digital-assets__file-name-input"
          placeholder={placeholder.file_name}
          type="text"
          value={fileName}
          maxLength="80"
          onChange={e => this.props.setFileUploadInformation('fileName', e.target.value)}
        />
        <Dropdown
          noSelection
          className="digital-assets__file-type-select"
          disabled={fileTypeDetectedFromSource}
          selectedElement={fileTypeId}
          elements={dropdownElements}
          onChange={fileType => this.props.setFileUploadInformation('fileTypeId', fileType)}
        />
      </div>
    );
  }
}

export default FileNameType;
