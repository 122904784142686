import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import critical from '../../../../images/icons/critical.svg';

type ShopifyDeleteChannelModalProps = {
  visible: boolean;
  handleDelete: () => void;
  handleCancel: () => void;
  connected: boolean;
};

const ShopifyDeleteChannelModal: React.FC<ShopifyDeleteChannelModalProps> = props => {
  const { t } = useTranslation();
  return (
    <Modal
      open={props.visible}
      footer={[
        <Button
          onClick={() => props.handleDelete()}
          type="primary"
          danger
          data-testid="delete-continue"
        >
          {t('channel:continue')}
        </Button>,
        <Button onClick={() => props.handleCancel()}>{t('common:cancel')}</Button>,
      ]}
      closable={false}
    >
      <div>
        <div className="shopify_delete-title flex items-center">
          <img src={critical} alt="knowledgeBase" className="icon__critical mr-2" />
          {t('channel:confirmDeleteTitle')}
        </div>
        <div>
          {props.connected ? t('channel:deleteDescription') : t('channel:shortDeleteDescription')}
        </div>
        {props.connected && <div className="pt-6">{t('channel:deleteShopifyIntegration')}</div>}
      </div>
    </Modal>
  );
};

export default ShopifyDeleteChannelModal;
