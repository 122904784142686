import React from 'react';
import { Form, Radio, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikValues, FormikHelpers } from 'formik';
import FormInput from '../../global/Forms/FormInput';
import CopyEBPTemplateModal from './CopyEBPTemplateModal';

type ExportBuilderAdvancedEditProps = {
  selectedTemplateId: number;
  values: FormikValues;
  hasApiAccess: boolean;
  isReceiver?: boolean;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  handleDeleteTemplate: () => void;
  handleCopyTemplate: (props: {
    newTitle: string;
    columnIds: number[];
    brandIds?: number[];
  }) => Promise<any>;
};

const ExportBuilderAdvancedEdit: React.FC<ExportBuilderAdvancedEditProps> = ({
  selectedTemplateId,
  values,
  hasApiAccess,
  isReceiver,
  setFieldValue,
  handleDeleteTemplate,
  handleCopyTemplate,
}) => {
  const { t } = useTranslation();

  const [showCopyModal, setShowCopyModal] = React.useState(false);

  return (
    <div className="flex items-center p-4">
      <Form layout="inline" labelAlign="left" className="flex">
        <div className="flex mr-6">
          <FormInput
            testId="template-name"
            label={t('common:name')}
            name="templateName"
            addonAfter={
              selectedTemplateId && hasApiAccess
                ? `${t('common:id')}: ${selectedTemplateId}`
                : undefined
            }
          />
        </div>

        <div className="flex mr-6">
          <Form.Item label="Export type">
            <div className="mr-4">
              <Radio.Group
                onChange={e => setFieldValue('fileTypeId', e.target.value)}
                value={values.fileTypeId}
              >
                <Radio.Button value={2}>{t('glossary:excel')}</Radio.Button>
                <Radio.Button value={1}>{t('glossary:csv')}</Radio.Button>
              </Radio.Group>
            </div>
          </Form.Item>
        </div>
        {values.fileTypeId === 1 && (
          <div className="flex items-center mr-6">
            <FormInput
              testId="csv-identifier"
              label={t('exportBuilderAdvanced:csvDelimiter')}
              name="csvSeparator"
            />
          </div>
        )}
      </Form>

      <div className="flex flex-1">
        <div className="inline-flex">
          <Button onClick={() => setShowCopyModal(true)}>
            {t('exportBuilderAdvanced:copyTemplate')}
          </Button>
        </div>
      </div>

      <div className="ml-auto">
        <Button onClick={() => handleDeleteTemplate()} danger ghost data-testid="delete-template">
          {t('exportBuilderAdvanced:deleteTemplate')}
        </Button>
      </div>

      <CopyEBPTemplateModal
        showModal={showCopyModal}
        isReceiver={isReceiver}
        onCancel={() => setShowCopyModal(false)}
        onClose={() => setShowCopyModal(false)}
        handleCopy={(name: string, columnIds: number[], brandIds?: number[]) =>
          handleCopyTemplate({ newTitle: name, brandIds, columnIds })
        }
      />
    </div>
  );
};

export default ExportBuilderAdvancedEdit;
