import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { AsyncDispatch } from '../../../../types/global';
import {
  fetchImport,
  fetchImportLogDetailsJSON,
  fetchImportLogsDetailsExcel,
} from '../../../actions/brand/import_export/fetch';
import { fetchExternalSourceImportLogs } from '../../../actions/receiver_data_stream/externalSources/fetch';
import { ApplicationState } from '../../../reducers';
import ImportExportLogs from '../import_export/ImportExportLogs';
import { externalSorcesImportLogMapper } from '../../../selectors/importExportLogsSelector';
import { intercomEvent } from '../../../utils/IntercomUtils';

const ExternalSourceImportLogs: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const { importLogs, fetchingImportLogs, fetchingImportErrors, importErrorLogDetail } =
    useSelector((state: ApplicationState) => ({
      importLogs: externalSorcesImportLogMapper(state),
      fetchingImportLogs: state.externalSources.fetchingExternalsourceImportLogs,
      fetchingImportErrors: state.brand.importExport.fetchingImportErrors,
      importErrorLogDetail: state.brand.importExport.importErrorLogDetail,
    }));

  React.useEffect(() => {
    dispatch(fetchExternalSourceImportLogs());
    intercomEvent('viewed-external-sources', { location: 'import-reports' });
  }, [dispatch]);

  const saveImportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'external-source_paramount_import_errors.xlsx');
  };

  const handleErrorDownload = (logId: number) =>
    dispatch(fetchImportLogsDetailsExcel(logId)).then(response =>
      saveImportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );

  const handleImportFileDownload = (logId: number) =>
    // @ts-ignore
    dispatch(fetchImport(logId));

  const handleFetchImportLogDetailsJSON = (logId: number) =>
    dispatch(fetchImportLogDetailsJSON(logId));

  return (
    <ImportExportLogs
      logs={importLogs}
      fetchingLogs={fetchingImportLogs}
      fetchingErrors={fetchingImportErrors}
      errorLogDetails={importErrorLogDetail}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleImportFileDownload}
      handleFetchLogDetailsJSON={handleFetchImportLogDetailsJSON}
      type="externalSourceImport"
    />
  );
};

export default ExternalSourceImportLogs;
