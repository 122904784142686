import axios from 'axios';
import { ChannelData, ChannelFileExportOption, ShopifyChannel } from '../../../types/channel';
import { FilterType } from '../../../types/filter';

import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

const CancelToken = axios.CancelToken;

let updateNpwCancelToken: any;
let updateChannelAdvisorCancelToken: any;
let updateWalmartCancelToken: any;
let updateShopifyCancelToken: any;

export function createShopifyApplicationFilter(filterId: number, andCondition = 1) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'and_condition', values: andCondition },
  ]);

  return {
    type: 'CREATE_SHOPIFY_APPLICATION_FILTER',
    payload: axios.post(`${getNonGwApiRoot()}/filters/applications${urlParams}`),
  };
}

export function createShopifyFilter(channelId: number) {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: channelId },
    { name: 'type_id', values: 7 },
  ]);

  return {
    type: 'CREATE_SHOPIFY_FILTER',
    payload: axios.post(`${getApiRoot()}/filters${urlParams}`),
  };
}

export function setShopifyFilter(updatedFilter: FilterType) {
  return {
    type: 'SET_SHOPIFY_FILTER',
    payload: { filter: updatedFilter },
  };
}

export function deleteShopifyFilterApplicationParameter(id: string) {
  return {
    type: 'DELETE_SHOPIFY_APPLICATION_FILTER_PARAMETER',
    payload: axios.delete(`${getApiRoot()}/filters/applications/parameters/${id}`),
  };
}

export function createShopifyFilterApplicationParameter(
  appFilterId: string,
  optionId: number,
  resource: any,
  resourceIds: any
) {
  const urlParams = utils.createURL([
    { name: 'filter_application_id', values: appFilterId },
    { name: 'option_id', values: optionId },
    { name: 'resource', values: resource },
    { name: 'resource_ids', values: resourceIds },
  ]);

  return {
    type: 'CREATE_SHOPIFY_APPLICATION_FILTER_PARAMETER',
    payload: axios.post(`${getNonGwApiRoot()}/filters/applications/parameters${urlParams}`),
  };
}

export function addShopifyFilterApplicationParameterValue(parameterId: any, resourceId: any) {
  const urlParams = utils.createURL([
    { name: 'filter_application_parameter_id', values: parameterId },
    { name: 'resource_ids', values: resourceId },
  ]);

  return {
    type: 'ADD_SHOPIFY_APPLICATION_FILTER_VALUE',
    payload: axios.post(`${getApiRoot()}/filters/applications/parameters/values${urlParams}`),
  };
}

export function removeShopifyFilterApplicationParameterValues(
  valueIds: number | string[] | string
) {
  return {
    type: 'REMOVE_SHOPIFY_APPLICATION_FILTER_VALUE',
    payload: axios.delete(`${getApiRoot()}/filters/applications/parameters/values/${valueIds}`),
  };
}

export function deleteShopifyFilter(filterId: number) {
  return {
    type: 'DELETE_SHOPIFY_FILTER',
    meta: { filterId },
    payload: axios.delete(`${getApiRoot()}/filters/${filterId}`),
  };
}

export const createShopifyStore = (name: string) => {
  const params = utils.createURL([{ name: 'name', values: name }]);
  return {
    type: 'CREATE_SHOPIFY_CHANNEL',
    meta: { name },
    payload: axios.post(`${getApiRoot()}/channels/shopify${params}`),
  };
};

export const connectShopifyStoreToDB = (userName: string, channelId: number) => {
  const params = utils.createURL([
    { name: 'username', values: userName },
    { name: 'channel_id', values: channelId },
  ]);
  return {
    type: 'CONNECT_SHOPIFY_STORE_DB',
    meta: { userName },
    payload: axios.post(`${getApiRoot()}/channels/shopify_shops${params}`),
  };
};

export const updateChannelShopify = (updatedValues: ChannelData[], channel: ShopifyChannel) => {
  const params = utils.createURL([{ name: 'channel_id', values: channel.id }]);
  if (updateShopifyCancelToken) updateShopifyCancelToken();

  return {
    type: 'UPDATE_CHANNEL_SHOPIFY',
    meta: { channel },
    payload: axios.patch(`${getApiRoot()}/channels/shopify${params}`, updatedValues, {
      cancelToken: new CancelToken(c => {
        updateShopifyCancelToken = c;
      }),
    }),
  };
};

export const updateChannelWalmart = (updatedValues: ChannelData[]) => {
  if (updateWalmartCancelToken) updateWalmartCancelToken();

  return {
    type: 'UPDATE_CHANNEL_WALMART',
    meta: { updatedValues },
    payload: axios.patch(`${getApiRoot()}/channels/walmart`, updatedValues, {
      cancelToken: new CancelToken(c => {
        updateWalmartCancelToken = c;
      }),
    }),
  };
};

export const updateChannelNPW = (updatedValues: ChannelData[]) => {
  if (updateNpwCancelToken) updateNpwCancelToken();

  return {
    type: 'UPDATE_CHANNEL_NPW',
    meta: { updatedValues },
    payload: axios.patch(`${getApiRoot()}/channels/npws`, updatedValues, {
      cancelToken: new CancelToken(c => {
        updateNpwCancelToken = c;
      }),
    }),
  };
};

export const updateChannelAdvisor = (updatedValues: ChannelData[]) => {
  if (updateChannelAdvisorCancelToken) updateChannelAdvisorCancelToken();

  return {
    type: 'UPDATE_CHANNEL_ADVISOR',
    meta: { updatedValues },
    payload: axios.patch(`${getApiRoot()}/channels/channel_advisors`, updatedValues, {
      cancelToken: new CancelToken(c => {
        updateChannelAdvisorCancelToken = c;
      }),
    }),
  };
};

export const selectChannel = (id: number) => ({
  type: 'SELECT_CHANNEL',
  payload: { id },
});

export const updateChannel = (params: {
  shopifyId?: number;
  channelId?: number;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.channelId || params.shopifyId },
    { name: 'name', values: params.name },
    { name: 'email', values: params.email, allowEmpty: true },
    { name: 'first_name', values: params.firstName, allowEmpty: true },
    { name: 'last_name', values: params.lastName, allowEmpty: true },
    { name: 'phone', values: params.phone, allowEmpty: true },
  ]);

  return {
    type: 'UPDATE_CHANNEL',
    meta: { channelId: params.channelId, shopifyId: params.shopifyId, name: params.name },
    payload: axios.patch(`${getApiRoot()}/channels${urlParams}`),
  };
};

export const updateExportPlan = (channelId: number, params: any) => {
  let urlParams = utils.createURL([
    { name: 'file_type_id', values: params.fileTypeId },
    { name: 'name', values: params.name },
    { name: 'export_template_id', values: params.exportTemplateId },
    { name: 'full_export_template_id', values: params.fullExportTemplateId },
    { name: 'filter_ids', values: params.filterId },
    { name: 'inactive', values: params.inactive },
    { name: 'without_prices', values: params.withoutPrices },
    { name: 'whitelabel_brand_code', values: params.whitelabelBrandCode, allowEmpty: true },
    {
      name: 'whitelabel_custom_field_id',
      values: params.whitelabelCustomFieldId,
      allowEmpty: true,
    },
  ]);

  if (!params.filterId) urlParams += '&filter_ids=';
  if (params.priceSheetIds && params.priceSheetIds.length === 0) urlParams += '&price_sheet_ids=';
  if (params.priceTypeIds && params.priceTypeIds.length === 0) urlParams += '&price_type_ids=';

  return {
    type: 'UPDATE_EXPORT_PLAN',
    meta: { channelId },
    payload: axios.patch(`${getApiRoot()}/channels/plans/${params.planId}${urlParams}`),
  };
};

export const updateExportFileSettings = (params: {
  planId?: number;
  channelId?: number;
  fileNameTemplateId?: number;
  fileExportOptions?: ChannelFileExportOption[];
}) => {
  const values = {
    channel_plan_id: params.planId,
    channel_id: params.channelId,
    file_name_template_id: params.fileNameTemplateId || null,
    file_settings: params.fileExportOptions,
  };

  return {
    type: params.planId ? 'CREATE_EXPORT_FILE_SETTINGS' : 'CREATE_DEFAULT_EXPORT_FILE_SETTINGS',
    meta: {
      channelId: params.channelId,
      channelPlanId: params.planId,
      exportOptions: params.fileExportOptions && params.fileExportOptions[0].export_options,
      fileTemplateId: params.fileNameTemplateId || null,
      fileSettings: params.fileExportOptions,
    },
    payload: axios.patch(`${getApiRoot()}/channels/file_settings`, values),
  };
};

export const updateExportFtpSettings = (params: {
  planId: number;
  channelId: number;
  server: string;
  user: string;
  password: string;
  ftpDirectoryTypeId: number;
  ftpModeId: number;
  protocolTypeId: number;
  ftpEncryptionTypeId: number;
  port: any;
  customDirectory: string;
  deliveryTypeId: number;
  email: string;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_plan_id', values: params.planId },
    { name: 'channel_id', values: params.channelId },
    { name: 'server', values: params.server },
    { name: 'user_name', values: params.user },
    { name: 'password', values: params.password },
    { name: 'ftp_directory_type_id', values: params.ftpDirectoryTypeId },
    { name: 'ftp_mode_id', values: params.ftpModeId },
    { name: 'protocol_type_id', values: params.protocolTypeId },
    { name: 'ftp_encryption_type_id', values: params.ftpEncryptionTypeId },
    { name: 'port', values: params.port },
    { name: 'custom_directory', values: params.customDirectory },
    { name: 'delivery_type_id', values: params.deliveryTypeId },
    { name: 'email', values: params.email, allowEmpty: true },
  ]);

  return {
    type: params.planId ? 'CREATE_EXPORT_FTP_SETTINGS' : 'CREATE_DEFAULT_EXPORT_FTP_SETTINGS',
    meta: { channelId: params.channelId },
    payload: axios.patch(`${getApiRoot()}/channels/ftp_settings${urlParams}`),
  };
};

export const updateSchedule = (params: {
  planId?: number;
  channelId?: number;
  deliveryFrequencyId?: number;
  deliveryDate?: any;
  deliveryDay?: any;
  deliveryTime?: any;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_plan_id', values: params.planId },
    { name: 'channel_id', values: params.channelId },
    { name: 'delivery_frequency_id', values: params.deliveryFrequencyId },
    { name: 'delivery_date', values: params.deliveryDate },
    { name: 'delivery_day', values: params.deliveryDay },
    { name: 'delivery_time', values: params.deliveryTime },
  ]);

  return {
    type: params.planId ? 'CREATE_EXPORT_SCHEDULE' : 'CREATE_DEFAULT_EXPORT_SCHEDULE',
    meta: { channelId: params.channelId },
    payload: axios.patch(`${getApiRoot()}/channels/schedule_settings${urlParams}`),
  };
};

export const updateEbaySettings = (params: any) => {
  return {
    type: 'UPDATE_EBAY_SETTINGS',
    payload: axios.patch(`${getApiRoot()}/channels/ebay_native/settings`, {
      address_1: params.address1,
      address_2: params.address2,
      city: params.city,
      country: params.country,
      county: params.county,
      postalCode: params.businessZip,
      stateOrProvince: params.state,
      listing_type: params.ebayListtype,
      listing_length: params.ebayListtime,
      fulfillment_id: params.shippingProfileId,
      payment_id: params.paymentProfileId,
      return_id: params.returnProfileId,
    }),
  };
};

export const updateEbayDefaultSettings = (params: {
  channelId?: number;
  planId?: number;
  titleDescriptionTypeId?: number;
  withoutPrices?: any;
  priceSheetId?: number;
  priceTypeId?: number;
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.channelId },
    { name: 'channel_plan_id', values: params.planId },
    { name: 'title_description_type_id', values: params.titleDescriptionTypeId },
    { name: 'manage_prices_manually', values: params.withoutPrices },
    { name: 'price_sheet_id', values: params.priceSheetId, allowEmpty: true },
    { name: 'price_type_id', values: params.priceTypeId, allowEmpty: true },
  ]);

  return {
    type: params.planId
      ? 'CREATE_EBAY_INTEGRATED_SETTINGS'
      : 'CREATE_EBAY_DEFAULT_INTEGRATED_SETTINGS',
    payload: axios.patch(`${getApiRoot()}/channels/integrated_default_settings${urlParams}`),
    meta: { channelId: params.channelId },
  };
};

export const updateEbayDefaultDescriptionTypes = (params: {
  planId?: number;
  channelId?: number;
  descriptionTypeIds?: number[];
}) => {
  const urlParams = utils.createURL([
    { name: 'channel_id', values: params.planId ? undefined : params.channelId },
    { name: 'channel_plan_id', values: params.planId },
    { name: 'description_type_ids', values: params.descriptionTypeIds, allowEmpty: true },
  ]);

  return {
    type: params.planId ? 'CREATE_EBAY_DESC_TYPES' : 'CREATE_EBAY_DEFAULT_DESC_TYPES',
    payload: axios.patch(
      `${getApiRoot()}/channels/integrated_default_description_types${urlParams}`
    ),
    meta: { channelId: params.channelId, planId: Number(params.planId) },
  };
};

export const updateFormDirtyState = (dirty: boolean) => {
  return {
    type: 'UPDATE_CHANNEL_DIRTY_STATE',
    payload: { dirty },
  };
};

export const updateNamingConventions = (templates: any[]) => {
  return {
    type: 'UPDATE_NAMING_CONVENTIONS',
    payload: axios.patch(`${getApiRoot()}/file_name_templates`, {
      file_name_templates: templates,
    }),
  };
};

export default {
  selectChannel,
  updateExportFileSettings,
  updateExportFtpSettings,
  updateExportPlan,
  updateChannel,
  updateSchedule,
  updateEbaySettings,
  updateEbayDefaultSettings,
  updateEbayDefaultDescriptionTypes,
  updateNamingConventions,
};
