import React from 'react';
import { Button, Modal, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedItems } from '../../../../selectors/catalogue/catalogueSelector';
import { ApplicationState } from '../../../../reducers';
import { AsyncDispatch } from '../../../../../types/global';
import { fetchFilterItemCount } from '../../../../actions/catalogue/catalogue/fetch';

type DeleteItemsModalProps = {
  showModal: boolean;
  handleDeleteItems: () => Promise<any>;
  onClose: () => void;
};

const DeleteItemsModal: React.FC<DeleteItemsModalProps> = ({
  showModal,
  handleDeleteItems,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItems,
    filterId,
    itemsSummary,
    allItemsSelected,
    reverseSelected,
    reversedItemIds,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      filterId: state.catalogue.filter.filterGo?.id,
      itemsSummary: state.catalogue.catalogue.itemsSummary,
      allItemsSelected: state.catalogue.catalogue.allItemsSelected,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    };
  });

  const [selectedItemCount, setSelectedItemCount] = React.useState<number>();
  const [deletePending, setDeletePending] = React.useState(false);
  const [fetchingItemCount, setFetchingItemCount] = React.useState(false);

  React.useEffect(() => {
    if (showModal) {
      if (!allItemsSelected && !reverseSelected) {
        setSelectedItemCount(selectedItems.length);
      } else {
        setFetchingItemCount(true);
        dispatch(fetchFilterItemCount({ filterId: filterId! })).then(response => {
          const itemsResponse = response.value.data;
          const filterItemCount = itemsResponse.item_count;

          const itemCount = reverseSelected
            ? filterItemCount - reversedItemIds.length
            : filterItemCount;

          setSelectedItemCount(itemCount);
          setFetchingItemCount(false);
        });
      }
    }
  }, [
    allItemsSelected,
    dispatch,
    filterId,
    itemsSummary,
    reverseSelected,
    reversedItemIds,
    selectedItems,
    showModal,
  ]);

  const handleDelete = async () => {
    setDeletePending(true);
    await handleDeleteItems();
    setDeletePending(false);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      open={showModal}
      onCancel={() => handleCancel()}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={() => handleCancel()}>
          {t('common:cancel')}
        </Button>,
        <Button
          key="delete"
          type="primary"
          loading={deletePending}
          disabled={deletePending || fetchingItemCount}
          danger
          onClick={() => handleDelete()}
        >
          {t('common:delete')}
        </Button>,
      ]}
      closable={false}
    >
      {fetchingItemCount ? (
        <Skeleton paragraph={{ rows: 2 }} />
      ) : (
        <React.Fragment>
          <div className="text-base font-medium">
            <ExclamationCircleOutlined className="text-xl icon__yellow mr-3" />
            {selectedItemCount === 1
              ? t('catalogue:delete.deleteProduct', { item: selectedItems[0]?.part_number })
              : t('catalogue:delete.deleteProducts', { itemCount: selectedItemCount })}
          </div>
          <div className="mt-2 ml-8">{`${t('catalogue:delete.deleteProductsDesc')}`}</div>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default DeleteItemsModal;
