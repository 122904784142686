import { createSelector } from 'reselect';
import { ChannelData } from '../../../types/channel';
import { ApplicationState } from '../../reducers';

const getChannelAdvisorData = (state: ApplicationState) =>
  state.channel.channels.channelAdvisorData?.brands || [];
const getChannelNPWData = (state: ApplicationState) =>
  state.channel.channels.channelNPWData?.brands || [];
const getChannelWalmart = (state: ApplicationState) =>
  state.channel.channels.channelWalmartData?.brands || [];

const getStatus = (record: ChannelData) => {
  if (record.declined_by_receiver_at) return 3; // rejected
  if (record.authorized_by_brand_at && record.authorized_by_receiver_at) return 2; // approved
  if (
    record.authorized_by_brand_at &&
    !record.authorized_by_receiver_at &&
    !record.declined_by_receiver_at
  )
    return 1; // pending
  if (record.declined_by_brand_at && record.authorized_by_receiver_at) return 4; // disconnected
  if (
    !record.authorized_by_brand_at &&
    record.authorized_by_receiver_at &&
    !record.declined_by_brand_at
  )
    return 1; // pending
  return null;
};

export const getMappedTempStatus = (channelData: ChannelData[]) =>
  channelData.map(b => {
    const statusId = getStatus(b);
    return {
      ...b,
      statusId,
    };
  });

export const mapTempStatusToChannelAdvData = createSelector(
  [getChannelAdvisorData],
  (channelAdvisorData: ChannelData[]) => {
    return getMappedTempStatus(channelAdvisorData);
  }
);

export const mapTempStatusToNPWData = createSelector(
  [getChannelNPWData],
  (channelNPWData: ChannelData[]) => {
    return getMappedTempStatus(channelNPWData);
  }
);

export const mapTempStatusToWalmartData = createSelector(
  [getChannelWalmart],
  (channelWalmart: ChannelData[]) => {
    return getMappedTempStatus(channelWalmart);
  }
);
