import React from 'react';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import { Spin, Empty, Checkbox, Tag, Input } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { LanguageSetting, LanguageTranslation } from '../../../../../types/language';
import TranslationActions from './TranslationActions';
import TranslationHeaderCell from './TranlationHeaderCell';
import TranslationDeleteCell from './TranslationDeleteCell';
import { exportExcelFile } from '../../../../actions/parent/language/update';
import { deleteTranslations } from '../../../../actions/parent/language/delete';
import { ApplicationState } from '../../../../reducers';
import { getPageLimit } from '../../../../utils/Utils';
import { fetchTranslations } from '../../../../actions/parent/language/fetch';
import { AsyncDispatch } from '../../../../../types/global';
import TranslationsHeaderCount from './TranlationsHeaderCount';
import { intercomEvent } from '../../../../utils/IntercomUtils';

type TranslationTableProps = {
  segmentTranslationValues: LanguageTranslation[];
  values: FormikValues;
  segmentId: number;
  searchKey: string;
  dirty: boolean;
  disableActions: boolean;
  selectedTransRowIds: number[];
  selectedLanguageIds: number[];
  handleEditedTransIds: (editedIds: number[]) => void;
  handleDeleteOnDirtyForm: (rowId: number) => void;
  handleResetForm: (fetchedTranslationValue: LanguageTranslation[]) => void;
  handleDirtyResetForm: (
    editedTransIds: number[],
    fetchedTranslationValue: LanguageTranslation[]
  ) => void;
};

const TranslationTable: React.FC<TranslationTableProps> = (
  {
    segmentId,
    searchKey,
    values,
    dirty,
    disableActions,
    handleDeleteOnDirtyForm,
    handleResetForm,
    handleDirtyResetForm,
    handleEditedTransIds,
    segmentTranslationValues,
  },
  props
) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const [selectedTransRowIds, setSelectedTransRowIds] = React.useState<number[]>(
    props.selectedTransRowIds || []
  );
  const [selectedLanguageIds, setSelectedLanguageIds] = React.useState<number[]>(
    props.selectedLanguageIds || []
  );
  const [filterUsedTranslations, setFilterUsedTranslations] = React.useState<number>(0);

  const [scrollLeft, setScrollLeft] = React.useState(0);

  const onScroll = React.useCallback(
    args => {
      if (args.scrollLeft !== scrollLeft) setScrollLeft(args.scrollLeft);
    },
    [scrollLeft]
  );

  const {
    fetchingTransLations,
    translationRowIds,
    languages,
    accountLanguages,
    fetchingMoreTranslations,
  } = useSelector((state: ApplicationState) => {
    return {
      languages: state.resources.data.global.languages,
      accountLanguages: state.parent.languageSettings.accountLanguages,
      fetchingTransLations: state.parent.languageTranslation.fetchingTransLations,
      fetchingMoreTranslations: state.parent.languageTranslation.fetchingMoreTranslations,
      translationRowIds: state.parent.languageTranslation.translationsRowIds,
      translationTypes: state.resources.data.global.translation_types,
    };
  });

  const [sortOrder, setSortOrder] = React.useState<string>('');
  const [sortLanguageId, setSortLanguageId] = React.useState<number | null>(null);
  const [editedIds, setEditedIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    const getOrder = () => {
      if (sortLanguageId && sortOrder === 'desc') return 1;
      if (sortOrder === 'asc') return 0;
      return undefined;
    };
    dispatch(
      fetchTranslations({
        translationTypeId: segmentId,
        used: filterUsedTranslations,
        orderByLanguageId: sortLanguageId || undefined,
        descending: getOrder(),
        emptyLanguageIds: selectedLanguageIds,
        keywords: searchKey,
      })
    ).then(response => handleDirtyResetForm(editedIds, response.action.payload.data));
  }, [selectedLanguageIds, filterUsedTranslations, sortOrder, sortLanguageId, dispatch, segmentId]);

  React.useEffect(() => {
    setSelectedTransRowIds([]);
    setSortLanguageId(null);
    setSelectedLanguageIds([]);
    setFilterUsedTranslations(0);
  }, [segmentId]);

  React.useEffect(() => {
    setSelectedTransRowIds([]);
  }, [searchKey]);

  const sendDeleteEvent = () => {
    intercomEvent('viewed-company-translation-settings', {
      location: 'translation',
      action: 'delete',
    });
  };

  const fetchNextTranslations = () => {
    const currentPage = Math.ceil(segmentTranslationValues.length / getPageLimit());
    const lastPage = currentPage > segmentTranslationValues.length / getPageLimit();

    if (!lastPage) {
      const getOrder = () => {
        if (sortLanguageId && sortOrder === 'desc') return 1;
        if (sortOrder === 'asc') return 0;
        return undefined;
      };
      dispatch(
        fetchTranslations({
          translationTypeId: segmentId,
          used: filterUsedTranslations,
          orderByLanguageId: sortLanguageId || undefined,
          descending: getOrder(),
          keywords: searchKey,
          emptyLanguageIds: selectedLanguageIds,
          page: currentPage + 1,
        })
      ).then(response => handleResetForm(response.action.payload.data));
    }
  };

  const handleExportTranslations = () => {
    const exportData = {
      translation_type_record_ids: {
        [segmentId]:
          translationRowIds.length === selectedTransRowIds.length && !searchKey
            ? []
            : selectedTransRowIds,
      },
    };
    // @ts-ignore
    dispatch(exportExcelFile(exportData));
  };

  const handleDeleteTranslations = () => {
    sendDeleteEvent();

    dispatch(deleteTranslations({ translationTypeId: segmentId, ids: selectedTransRowIds })).then(
      () => setSelectedTransRowIds([])
    );
  };

  const handleDeleteTranslation = (id: number) => {
    sendDeleteEvent();

    dispatch(deleteTranslations({ translationTypeId: segmentId, ids: [id] })).then(() => {
      if (dirty) handleDeleteOnDirtyForm(id);
    });
  };

  const handleEmptyValues = (id: number) => {
    const checked = selectedLanguageIds.includes(id);
    if (!checked) {
      setSelectedLanguageIds([...selectedLanguageIds, id]);
    } else {
      setSelectedLanguageIds(selectedLanguageIds.filter(rowId => rowId !== id));
    }
  };

  const handleRowSelect = (id: number) => {
    if (id === -2) {
      selectedTransRowIds.length === translationRowIds.length
        ? setSelectedTransRowIds([])
        : setSelectedTransRowIds(translationRowIds);
    } else {
      const checked = selectedTransRowIds.includes(id);
      if (!checked) {
        setSelectedTransRowIds([...selectedTransRowIds, id]);
      } else {
        setSelectedTransRowIds(selectedTransRowIds.filter(rowId => rowId !== id));
      }
    }
  };

  const handleUsedTranslations = () => {
    filterUsedTranslations === 0 ? setFilterUsedTranslations(1) : setFilterUsedTranslations(0);
  };

  const onColumnSort = ({ key, order }: { key: number; order: string }) => {
    setSortOrder(order !== sortOrder ? order : '');
    setSortLanguageId(key);
  };

  const getVisibleColumnIndices = (offset: any, columns: any[]) => {
    // build the net offset for each column
    const netOffsets: number[] = [];
    let offsetSum = 0;
    const leftBound = offset;
    const rightBound = offset + 1200; // tableWidth;
    const visibleIndices: number[] = [];

    // derive the column net offsets
    columns.forEach(col => {
      netOffsets.push(offsetSum); // the current offsetsum is the column offset
      offsetSum += col.width; // increase the offset sum by the width of the column
    });

    // which column offsets are outside the left and right bounds?
    netOffsets.forEach((columnOffset, colIdx) => {
      const isOutside = columnOffset < leftBound || columnOffset > rightBound;
      if (!isOutside) visibleIndices.push(colIdx);
    });

    return visibleIndices;
  };

  const rowRenderer = React.useCallback(
    ({ cells, columns, rowData }) => {
      // this could be rendering the table body row, the fixed columns row, the header row.
      // if we have the full complement of columns in the cell array (which includes placeholders
      // for frozen columns), then we have the header or body
      // plus, only want to null out hidden content when scrolling vertically

      const columnCount = accountLanguages.length + 3;

      if (cells.length === columnCount) {
        if (rowData.id === -2) {
          const cellUpdate = cells.map((cell: any, index: number) => {
            if (index === 0) return cell;
            if (index === 1) {
              const style = { ...cell.props.style, width: '350px' };
              const cellupdateWidth = React.cloneElement(cell, { style });
              return cellupdateWidth;
            }
            return null;
          });
          return cellUpdate;
        }

        const visibleIndices = getVisibleColumnIndices(scrollLeft, columns);
        const startIndex = visibleIndices[0];
        const visibleCells = visibleIndices.map(x => cells[x]);

        if (startIndex > 0) {
          let width = 0;
          for (let i = 0; i < visibleIndices[0]; i++) {
            width += cells[i].props.style.width;
          }

          const placeholder = <div key="placeholder" style={{ width }} />;
          return [placeholder, visibleCells];
        }
        return visibleCells;
      }

      return cells;
    },
    [accountLanguages.length, scrollLeft]
  );

  const renderOverlay = () => {
    if (fetchingMoreTranslations)
      return <Spin className="spinner-center" style={{ position: 'absolute', bottom: '30px' }} />;
    if (fetchingTransLations)
      return <Spin className="spinner-center" style={{ marginTop: '100px' }} />;
    if (!fetchingTransLations && segmentTranslationValues.length === 0 && !fetchingMoreTranslations)
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '100px' }} />;
  };

  const renderSelectedText = () => {
    if (
      selectedTransRowIds.length > 0 &&
      (selectedTransRowIds.length < translationRowIds.length || searchKey)
    )
      return `(${selectedTransRowIds.length} ${t(
        'language:translationsSegment.translationsSelected'
      )})`;
    if (
      selectedTransRowIds.length > 0 &&
      selectedTransRowIds.length === translationRowIds.length &&
      !searchKey
    )
      return `(All ${t('language:translationsSegment.translationsSelected')})`;
    return `(0 ${t('language:translationsSegment.translationsSelected')})`;
  };

  return (
    <AutoResizer>
      {({ height, width }: any) => (
        <div style={{ height, width }} className="translations-page">
          <BaseTable
            fixed
            data={segmentTranslationValues || []}
            rowRenderer={rowRenderer}
            onScroll={onScroll}
            width={width - 1}
            height={height}
            rowHeight={40}
            rowKey="id"
            loadingMore={fetchingMoreTranslations}
            emptyRenderer={() => {
              if (fetchingTransLations) return null;
              return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
            }}
            onEndReached={() => fetchNextTranslations()}
            overlayRenderer={renderOverlay()}
            overscanRowCount={5}
            // for action row
            frozenData={[{ id: -2 }]}
          >
            <Column
              key="check"
              width={60}
              cellRenderer={({ rowData }) => {
                const row = rowData as LanguageTranslation;
                return (
                  <Checkbox
                    data-testid="row-selection"
                    checked={
                      row.id !== -2
                        ? selectedTransRowIds.includes(row.id!)
                        : translationRowIds.length > 0 &&
                          selectedTransRowIds.length === translationRowIds.length &&
                          !searchKey
                    }
                    onChange={() => handleRowSelect(row.id)}
                    indeterminate={
                      row.id === -2 &&
                      selectedTransRowIds.length > 0 &&
                      selectedTransRowIds.length < translationRowIds.length
                    }
                    disabled={row.id === -2 && searchKey.length > 0}
                  />
                );
              }}
              selectedTransRowIds={selectedTransRowIds}
              translationRowIds={translationRowIds}
            />

            {accountLanguages.map((header: LanguageSetting) => (
              <Column
                key={header.language_id}
                dataKey={header.language_id.toString()}
                title={languages.find(langDetails => langDetails.id === header.language_id)?.name}
                width={180}
                selectedLanguageIds={selectedLanguageIds}
                values={values}
                headerRenderer={({ column }: any) => (
                  <TranslationHeaderCell
                    title={column.title}
                    checked={selectedLanguageIds.includes(header.language_id)}
                    showDefault={header.default === 1}
                    handleEmptyValues={() => handleEmptyValues(header.language_id)}
                    handleSort={(order: string) => onColumnSort({ key: header.language_id, order })}
                    selected={sortLanguageId === Number(column.dataKey)}
                    sortOrder={sortOrder}
                  />
                )}
                cellRenderer={({ rowData }) => {
                  const row = rowData as LanguageTranslation;
                  if (row.id === -2)
                    return (
                      <React.Fragment>
                        <TranslationActions
                          selectedTranslations={selectedTransRowIds}
                          disabled={disableActions}
                          handleDelete={handleDeleteTranslations}
                          handleExportTranslations={handleExportTranslations}
                        />
                        <span className="pl-1 text-gray-500">{renderSelectedText()}</span>
                      </React.Fragment>
                    );
                  return (
                    <Input
                      size="small"
                      value={values[`${row.id}_${header.language_id}`]?.value}
                      onChange={e => {
                        setFieldValue(`[${row.id}_${header.language_id}]`, {
                          ...values[`${row.id}_${header.language_id}`],
                          value: e.target.value,
                        });
                        setEditedIds([...editedIds, row.id]);
                        handleEditedTransIds([...editedIds, row.id]);
                      }}
                      className={classNames({
                        'border-visible': !values[`${row.id}_${header.language_id}`]?.value,
                      })}
                      data-testid="translation"
                    />
                  );
                }}
              />
            ))}
            <Column
              key="count"
              frozen="right"
              width={100}
              filterUsedTranslations={filterUsedTranslations}
              headerRenderer={() => (
                <TranslationsHeaderCount
                  title={t('common:count')}
                  checked={filterUsedTranslations === 1}
                  handleUsedTranslations={() => handleUsedTranslations()}
                />
              )}
              cellRenderer={({ rowData }) => {
                const row = rowData as LanguageTranslation;
                return row.id !== -2 && <Tag className="self-center">{row.count}</Tag>;
              }}
            />
            <Column
              key="action"
              frozen="right"
              width={100}
              segmentId={segmentId}
              cellRenderer={({ rowData }) => {
                const row = rowData as LanguageTranslation;
                return (
                  row.id !== -2 && (
                    <TranslationDeleteCell
                      disabled={disableActions}
                      handleDelete={() => handleDeleteTranslation(row.id)}
                    />
                  )
                );
              }}
            />
          </BaseTable>
        </div>
      )}
    </AutoResizer>
  );
};

export default TranslationTable;
