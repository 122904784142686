import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { BrandItemTag } from '../../../types/brand_catalogue';
import { Item, SelectedListItem } from '../../../types/item';
import { CatalogueQueryParams } from '../../../types/catalogue';

export type CatalogueState = {
  readonly brandItemsSummary: number;
  readonly brandItemTags: BrandItemTag[];
  readonly itemsFetching: boolean;
  readonly items: Item[];
  readonly itemsSummary: number;
  readonly allItemsSelected: boolean;
  readonly selectedItemIds: number[];
  readonly cachedItems: Item[];
  readonly reverseSelected: boolean;
  readonly reversedItemIds: number[];
  readonly isFetchingFullItem: boolean;
  readonly catalogueQueryParams: CatalogueQueryParams;
  readonly pageFormDirty: boolean;
  readonly fetchingSelectedItemsList: boolean;
  readonly allSelectedItemIds: number[];
  readonly selectedItemsList: SelectedListItem[];
  readonly selectedItemTags: BrandItemTag[];
  readonly itemFetchRequired: boolean;
};

export const initialState: CatalogueState = {
  selectedItemsList: [],
  allSelectedItemIds: [],
  fetchingSelectedItemsList: false,
  brandItemsSummary: 0,
  brandItemTags: [],
  itemsFetching: false,
  items: [],
  itemsSummary: 0,
  allItemsSelected: false,
  selectedItemIds: [],
  cachedItems: [],
  reverseSelected: false,
  reversedItemIds: [],
  isFetchingFullItem: false,
  catalogueQueryParams: {
    order: null,
    selectedChannelId: null,
    selectedReceiverId: null,
    filterBrandIds: [],
  },
  pageFormDirty: false,
  selectedItemTags: [],
  itemFetchRequired: false,
};

const reducer: Reducer<CatalogueState, FluxStandardAction> = (state = initialState, action) => {
  const { payload, type, meta } = action;
  switch (type) {
    case 'FETCH_ITEM_TAGS_FULFILLED': {
      return { ...state, brandItemTags: payload.data };
    }
    case 'FETCH_SELECTED_ITEM_TAGS_FULFILLED': {
      return {
        ...state,
        selectedItemTags: payload.data,
      };
    }
    case 'FETCH_ITEMS_SUMMARY_FULFILLED': {
      return { ...state, brandItemsSummary: payload.data.total_items };
    }
    case 'FETCH_ITEMS_BY_FILTER_PENDING': {
      const firstPage = meta.page === 1;
      return {
        ...state,
        itemsFetching: true,
        items: firstPage ? [] : state.items,
        allItemsSelected: firstPage ? false : state.allItemsSelected,
        reverseSelected: firstPage ? false : state.reverseSelected,
        reversedItemIds: firstPage ? [] : state.reversedItemIds,
      };
    }
    case 'FETCH_ITEMS_BY_FILTER_FULFILLED': {
      const fetchedItems: Item[] = payload.data.items;
      const cachedItemIds = state.cachedItems.map(({ id }) => id);
      const selectedAndFetched =
        meta.page === 1 && !!state.selectedItemIds.find(id => cachedItemIds.includes(id))
          ? fetchedItems.map(item =>
              state.selectedItemIds.includes(item.id) && cachedItemIds.includes(item.id)
                ? { ...state.cachedItems.find(c => c.id === item.id), ...item }
                : item
            )
          : fetchedItems;
      const items = [...state.items, ...selectedAndFetched];
      return {
        ...state,
        itemsFetching: false,
        items,
        itemsSummary: payload.data.item_count,
        selectedItemIds:
          state.allItemsSelected || state.reverseSelected
            ? [...state.selectedItemIds, ...fetchedItems.map(({ id }) => id)]
            : state.selectedItemIds,
        allItemsSelected:
          state.allItemsSelected ||
          (items.length !== 0 && items.every(({ id }) => state.selectedItemIds.includes(id))),
      };
    }
    case 'FETCH_EXTENDED_ITEM_PENDING': {
      return {
        ...state,
        isFetchingFullItem: true,
        selectedItemsList: [],
        allSelectedItemIds: [],
      };
    }
    case 'FETCH_EXTENDED_ITEM_REJECTED': {
      return {
        ...state,
        isFetchingFullItem: false,
      };
    }
    case 'FETCH_EXTENDED_ITEM_FULFILLED': {
      const fullItem = payload.data;
      const extendedItem = { ...state.items.find(item => item.id === fullItem.id), ...fullItem };
      const cachedItems = !state.cachedItems.find(item => item.id === fullItem.id)
        ? [extendedItem, ...state.cachedItems]
        : state.cachedItems.map(item => (item.id === fullItem.id ? extendedItem : item));
      return {
        ...state,
        isFetchingFullItem: false,
        items: state.items.map(item => {
          return item.id === fullItem.id ? extendedItem : item;
        }),
        cachedItems,
      };
    }
    case 'UPDATE_ITEM_FULFILLED': {
      const itemData = action.payload.data;
      const updatedItem = { ...state.items.find(item => item.id === itemData.id), ...itemData };
      return {
        ...state,
        items: state.items.map(item => (item.id === itemData.id ? updatedItem : item)),
        cachedItems: state.cachedItems.map(item => (item.id === itemData.id ? updatedItem : item)),
      };
    }
    case 'CREATE_ITEM_FULFILLED': {
      return {
        ...state,
        cachedItems: [payload.data, ...state.cachedItems],
        itemsSummary: state.itemsSummary + 1,
        brandItemsSummary: state.brandItemsSummary + 1,
      };
    }
    case 'CLONE_ITEM_FULFILLED': {
      return {
        ...state,
        cachedItems: [payload.data, ...state.cachedItems],
        itemsSummary: state.itemsSummary + 1,
        brandItemsSummary: state.brandItemsSummary + 1,
      };
    }
    case 'FETCH_ITEM_ANALYSES_FULFILLED': {
      const itemUpdates: Item[] = action.payload.data.item_updates;
      const itemUpdateIds = itemUpdates.map(item => item.id);
      const updatedItems = state.items.map(item => {
        const itemIsUpdated = itemUpdateIds.includes(item.id);
        const updatedItem = itemUpdates.find(itemUpdate => itemUpdate.id === item.id);
        return itemIsUpdated ? { ...item, ...updatedItem } : item;
      });
      return { ...state, items: updatedItems };
    }
    case 'UPDATE_SESSION_ITEMS_FULFILLED': {
      const newItems: Item[] = action.payload.data.items;
      const itemUpdateIds = newItems.map(item => item.id);
      const updatedItems = state.items.map(item => {
        const itemIsUpdated = itemUpdateIds.includes(item.id);
        const updatedItem = newItems.find(itemUpdate => itemUpdate.id === item.id);
        return itemIsUpdated ? { ...item, ...updatedItem } : item;
      });
      return {
        ...state,
        items: updatedItems,
      };
    }
    case 'SELECT_ITEM': {
      return {
        ...state,
        selectedItemIds: [payload.itemId],
        allItemsSelected: state.items.length === 1,
        reverseSelected: false,
        reversedItemIds: [],
      };
    }
    case 'MULTI_SELECT_ITEMS': {
      const addItems = state.selectedItemIds.includes(payload.selectedItemId) || meta.add;

      const selectedItemIds = addItems
        ? [...new Set([...state.selectedItemIds, ...payload.itemIds])]
        : state.selectedItemIds.filter(id => !payload.itemIds.includes(id));

      const reverseSelected = state.allItemsSelected || state.reverseSelected;
      const reversedItemIds = addItems
        ? state.reversedItemIds.filter(id => !payload.itemIds.includes(id))
        : [...new Set([...state.reversedItemIds, ...payload.itemIds])];
      return {
        ...state,
        selectedItemIds,
        allItemsSelected: state.itemsSummary === selectedItemIds.length,
        reverseSelected,
        reversedItemIds,
      };
    }
    case 'ADD_TO_SELECTED_ITEMS': {
      const { itemId } = payload;
      const isAdding = state.selectedItemIds.includes(itemId);
      const selectedItemIds = isAdding
        ? state.selectedItemIds.filter(id => id !== itemId)
        : [...state.selectedItemIds, payload.itemId];
      const reverseSelected = state.allItemsSelected || state.reverseSelected;
      const reversedItemIds = isAdding
        ? [...state.reversedItemIds, payload.itemId]
        : state.reversedItemIds.filter(id => id !== itemId);
      return {
        ...state,
        selectedItemIds,
        allItemsSelected:
          state.itemsSummary === selectedItemIds.length ||
          (reverseSelected && reversedItemIds.length === 0),
        allSelectedItemIds: isAdding
          ? state.allSelectedItemIds.filter(id => id !== itemId)
          : [...state.allSelectedItemIds, itemId],
        reverseSelected,
        reversedItemIds,
      };
    }
    case 'SELECT_ALL_ITEMS': {
      const selectedItemIdsEmpty = state.selectedItemIds.length === 0;
      const checkAll = selectedItemIdsEmpty || !state.allItemsSelected;
      return {
        ...state,
        selectedItemIds: checkAll ? state.items.map(({ id }) => id) : [],
        allItemsSelected: checkAll,
        reverseSelected: false,
        reversedItemIds: [],
      };
    }
    case 'SET_ITEM_FETCH_REQUIRED': {
      return { ...state, itemFetchRequired: action.payload.required };
    }
    case 'CREATE_MARK_ITEM_FULFILLED': {
      return {
        ...state,
        items: state.items.map(item => {
          if (meta.itemId === item.id) {
            return { ...item, marked: 1 };
          }
          return item;
        }),
      };
    }
    case 'CREATE_MARK_ITEMS_FULFILLED': {
      return {
        ...state,
        items: state.items.map(item =>
          meta.itemIds.includes(item.id) ? { ...item, marked: 1 } : item
        ),
      };
    }
    case 'CREATE_MARK_ALL_ITEMS_FULFILLED': {
      return {
        ...state,
        items: state.items.map(item => ({ ...item, marked: 1 })),
      };
    }
    case 'CREATE_TAG_FOR_ITEMS_FULFILLED': {
      const addToAll = meta.itemIds.length === 0 && meta.withoutItemIds.length === 0;
      const newTagId = payload.data.tag_id;
      return {
        ...state,
        brandItemTags: state.brandItemTags.find(tag => tag.id === newTagId)
          ? state.brandItemTags
          : [{ id: newTagId, name: meta.tagName }, ...state.brandItemTags],
        items: state.items.map(item => {
          if (meta.itemIds.length && meta.itemIds.includes(item.id)) {
            return {
              ...item,
              tag_ids: item.tag_ids.includes(newTagId) ? item.tag_ids : [...item.tag_ids, newTagId],
            };
          }
          if (addToAll || (meta.withoutItemIds.length && !meta.withoutItemIds.includes(item.id))) {
            return {
              ...item,
              tag_ids: item.tag_ids.includes(newTagId) ? item.tag_ids : [...item.tag_ids, newTagId],
            };
          }
          return item;
        }),
      };
    }
    case 'DELETE_TAG_FOR_ITEMS_FULFILLED': {
      const deleteAll = !meta.itemIds && !meta.withoutItemIds;
      const deleteTagId = meta.tagId;
      return {
        ...state,
        items: state.items.map(item => {
          if (meta.itemIds && meta.itemIds.includes(item.id)) {
            return {
              ...item,
              tag_ids: item.tag_ids.filter(id => id !== deleteTagId),
            };
          }
          if (deleteAll || (meta.withoutItemIds && !meta.withoutItemIds.includes(item.id))) {
            return {
              ...item,
              tag_ids: item.tag_ids.filter(id => id !== deleteTagId),
            };
          }

          return item;
        }),
      };
    }
    case 'SYNC_ITEMS_FULFILLED': {
      const syncAll = !meta.itemIds && !meta.withoutItemIds;
      return {
        ...state,
        items: state.items.map(item => {
          if (meta.itemIds && meta.itemIds.includes(item.id)) {
            return {
              ...item,
              last_synced_at: new Date().toISOString(), // faking new date
            };
          }
          if (syncAll) {
            return {
              ...item,
              last_synced_at: new Date().toISOString(), // faking new date
            };
          }
          return item;
        }),
      };
    }
    case 'SYNC_ITEMS_LIVE_FULFILLED': {
      const date = new Date().toISOString();
      return {
        ...state,
        items: state.items.map(item =>
          meta.itemIds && meta.itemIds.includes(item.id) ? { ...item, last_synced_at: date } : item
        ),
        cachedItems: state.cachedItems.map(item =>
          meta.itemIds && meta.itemIds.includes(item.id) ? { ...item, last_synced_at: date } : item
        ),
      };
    }
    case 'DELETE_MARK_ITEM_FULFILLED': {
      return {
        ...state,
        items: state.items.map(item => {
          if (meta.itemId === item.id) {
            return { ...item, marked: 0 };
          }
          return item;
        }),
      };
    }
    case 'DELETE_MARK_ITEMS_FULFILLED': {
      return {
        ...state,
        items: state.items.map(item => ({ ...item, marked: 0 })),
      };
    }
    case 'DELETE_ITEMS_FULFILLED': {
      const allItemsDeleted = !meta.itemIds && !meta.withoutItemIds;
      const items = allItemsDeleted
        ? []
        : state.items.filter(item => {
            if (meta.itemIds) return !meta.itemIds.includes(item.id);
            if (meta.withoutItemIds) return meta.withoutItemIds.includes(item.id);
          });

      let newItemsSummaryCount;
      if (allItemsDeleted) newItemsSummaryCount = 0;
      else if (meta.itemIds) newItemsSummaryCount = state.itemsSummary - meta.itemIds.length;
      else if (meta.withoutItemIds)
        newItemsSummaryCount =
          state.itemsSummary - (state.itemsSummary - meta.withoutItemIds.length);
      else newItemsSummaryCount = state.itemsSummary;

      return {
        ...state,
        items,
        itemsSummary: newItemsSummaryCount,
        brandItemsSummary: allItemsDeleted
          ? state.brandItemsSummary - state.itemsSummary
          : state.brandItemsSummary - 1,
        selectedItemIds: [],
        allItemsSelected: false,
      };
    }
    case 'SET_FILTER':
    case 'FETCH_FILTER_GO_FULFILLED':
    case 'FETCH_FILTER_FULFILLED': {
      return { ...state, selectedItemIds: [], allItemsSelected: false };
    }
    case 'RESET_CATALOGUE': {
      return initialState;
    }
    case 'UPDATE_CATALOGUE_QUERY_PARAMS_BRAND_IDS': {
      return {
        ...state,
        catalogueQueryParams: {
          ...state.catalogueQueryParams,
          filterBrandIds: action.payload,
        },
      };
    }
    case 'UPDATE_CATALOGUE_QUERY_PARAMS_CHANNEL_RECEIVER': {
      const { selectedChannelId, selectedReceiverId } = action.payload;
      return {
        ...state,
        catalogueQueryParams: {
          ...state.catalogueQueryParams,
          selectedChannelId,
          selectedReceiverId,
        },
      };
    }
    case 'RESET_CLIENT_FILTER_PARAMS': {
      return { ...state, catalogueQueryParams: initialState.catalogueQueryParams };
    }
    case 'UPDATE_CATALOGUE_QUERY_PARAMS_ORDER': {
      return {
        ...state,
        catalogueQueryParams: {
          ...state.catalogueQueryParams,
          order: action.payload,
        },
      };
    }
    case 'RESET_CATALOGUE_QUERY_PARAMS_ORDER': {
      return {
        ...state,
        catalogueQueryParams: {
          ...state.catalogueQueryParams,
          order: null,
        },
      };
    }
    case 'UPDATE_PAGE_FORMIK_DIRTY_STATE': {
      return { ...state, pageFormDirty: action.payload.dirty };
    }
    case 'FETCH_SELECTED_ITEMS_LIST_PENDING': {
      return {
        ...state,
        fetchingSelectedItemsList: true,
        selectedItemsList: [],
        allSelectedItemIds: [],
      };
    }
    case 'FETCH_SELECTED_ITEMS_LIST_FULFILLED': {
      const selectedItemsList = action.payload.data;
      // list of all selected item ids
      const allSelectedItemIds = selectedItemsList.map((item: SelectedListItem) => item.id);
      return {
        ...state,
        fetchingSelectedItemsList: false,
        allSelectedItemIds,
        selectedItemsList,
      };
    }
  }
  return state;
};

export default reducer;
