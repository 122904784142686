import { Button, Modal, Switch } from 'antd';
import { useFormikContext, FieldArray } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import FormInput from '../../global/Forms/FormInput';
import { ApplicationState } from '../../../reducers';
import ApplicationBundleConfigCell from './ApplicationBundleConfigCell';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import BundleQualifierDrawer from './BundleQualifierDrawer';
import BundlePartNumberSelect from './BundlePartNumberSelect';
import { AppOrder, BundleItem } from '../../../../types/application';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import { AsyncDispatch } from '../../../../types/global';
import { DefaultValue } from '../../../../types/brand_settings';
import { removeBundleItem } from '../../../actions/items/application/delete';
import BundlePositionSelect from './BundlePositionSelect';
import CategoryDropdown from '../../global/CategoryDropdown';
import { fetchPartTypeCategories } from '../../../actions/items/application/fetch';
import { nextPage } from '../../../utils/Utils';

const ApplicationBundleTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { values, setFieldValue, handleChange } = useFormikContext<any>();

  const {
    selectedItem,
    applicationOrder,
    brandId,
    partTypeCategories,
    fetchingCategories,
    defaultValues,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      applicationOrder: state.items.application.applicationOrder,
      brandId: state.parent.brands.selectedBrandId,
      partTypeCategories: state.items.application.partTypeCategories,
      fetchingCategories: state.items.application.fetchingCategories,
      defaultValues: state.settings.defaultValues,
    };
  });

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [crossLinkBrandsEnabled, setCrossLinkBrandsEnabled] = React.useState(
    values.linkedItems.find((item: BundleItem) => item.brand_code !== selectedItem.brand_code)
  );

  const showPartTypeId = defaultValues.find((d: DefaultValue) => d.id === 11)?.value === '1';

  const openBundleDrawer = (index: number) => {
    setSelectedIndex(index);
  };

  const fetchCategories = (keyword?: string) => {
    dispatch(fetchPartTypeCategories(brandId, keyword));
  };

  const fetchNextPartTypeCategories = (event: any, keywords?: string) => {
    const page = nextPage(event);
    if (page && fetchingCategories) {
      dispatch(fetchPartTypeCategories(brandId, keywords, page));
    }
  };

  const toggleCrossLinkBrandsEnabled = (checked: boolean) => {
    const { linkedItems } = values;
    if (checked) setCrossLinkBrandsEnabled(checked);
    else if (!linkedItems.find((item: BundleItem) => item.brand_code !== selectedItem.brand_code))
      setCrossLinkBrandsEnabled(checked);
  };

  const rowRenderer = ({ key, index, style }: ListRowProps, unlink: (id: number) => void) => {
    const item = values.linkedItems[index];

    return (
      <div className="application__bundle-row" key={key} style={style}>
        <FormInput
          name={`linkedItems[${index}].asset_item_order`}
          className={classNames('application__bundle-order', {
            'border-invisible': !!item.asset_item_order,
          })}
          handleChange={(e: React.ChangeEvent<any>) => {
            if (!e.target.value) setFieldValue(`linkedItems[${index}].asset_item_order`, null);
            else handleChange(e);
          }}
          type="number"
          size="small"
          fastField
        />
        <FormInput
          name={`linkedItems[${index}].asset_item_ref`}
          className={classNames({ 'border-invisible': !!item.asset_item_ref })}
          size="small"
          fastField
        />
        <FormMultiSelect
          className="application__bundle-id-select"
          placeholder={t('application:bundle.idPlaceholder')}
          name={`linkedItems[${index}].application_ids`}
          values={applicationOrder.map((a: AppOrder, i: number) => ({ id: i + 1, name: i + 1 }))}
          size="small"
          allowClear
        />
        <div className="overflow-hidden">
          <BundlePartNumberSelect
            name={`linkedItems[${index}].item_id`}
            className={classNames({ 'border-invisible': !!item.item_id })}
            partNumber={item.part_number}
            brandCode={item.brand_code}
            brandName={item.brand_name}
            crossLinkBrandsEnabled={crossLinkBrandsEnabled}
            defaultWhiteLabelCode={item.default_whitelabel_brand_code}
            onChange={(
              id: number,
              partNumber: string,
              partType: string | null,
              categoryId: null | number,
              brandCode: string,
              brandName: string,
              defaultWhiteLabelCode: string | null
            ) => {
              setFieldValue(`linkedItems[${index}].item_id`, id);
              setFieldValue(`linkedItems[${index}].part_number`, partNumber);
              setFieldValue(`linkedItems[${index}].part_type`, partType);
              setFieldValue(`linkedItems[${index}].category_id`, categoryId);
              setFieldValue(`linkedItems[${index}].brand_code`, brandCode);
              setFieldValue(
                `linkedItems[${index}].default_whitelabel_brand_code`,
                defaultWhiteLabelCode
              );
              setFieldValue(`linkedItems[${index}].brand_name`, brandName);
            }}
          />
        </div>
        <div className="extended-info__drawer-list-row_data">
          <CategoryDropdown
            showSelectedPartType
            className="application__part-type-dropdown"
            defaultKeyword={item.part_type || item.item_part_type || ''}
            displayedCategory={
              item.part_type && item.part_type !== null && item.part_type !== item.item_part_type
                ? {
                    id: item.category_id,
                    level1_name: '',
                    level2_name: '',
                    level3_name: item.part_type,
                  }
                : undefined
            }
            fetchReviewCategories={fetchCategories}
            fetchNextReviewCategories={fetchNextPartTypeCategories}
            fetchingCategories={fetchingCategories}
            categories={partTypeCategories}
            updateCategory={category => {
              setFieldValue(`linkedItems[${index}].part_type`, category?.level3_name);
              setFieldValue(`linkedItems[${index}].category_id`, category?.id);
            }}
            allowClear={!!item.part_type}
            hasDefault={
              ((!item.part_type || item.part_type === null) && item.item_part_type) ||
              (item.part_type && item.part_type === item.item_part_type)
            }
            defaultCategory={{
              id: item.item_category_id,
              level1_name: '',
              level2_name: '',
              level3_name: item.item_part_type,
            }}
            showPartTypeId={showPartTypeId}
            size="small"
            dropdownMatchSelectWidth={false}
          />
          <span>{item.part_type}</span>
        </div>
        <ApplicationBundleConfigCell
          className={classNames({ 'border-visible': !item.qualifiers.length })}
          configKey="qualifiers"
          qualifiers={item.qualifiers}
          openDrawer={() => openBundleDrawer(index)}
        />
        <ApplicationBundleConfigCell
          className={classNames({ 'border-visible': !item.notes.length })}
          configKey="notes"
          notes={item.notes}
          openDrawer={() => openBundleDrawer(index)}
        />
        <div className="overflow-hidden flex">
          <BundlePositionSelect
            className={classNames('application__bundle-select overflow-hidden', {
              'border-invisible': !!item.position_id,
            })}
            positionId={values.linkedItems[index].position_id}
            categoryId={item.category_id}
            onChange={id => setFieldValue(`linkedItems[${index}].position_id`, id)}
          />
        </div>

        <FormInput
          name={`linkedItems[${index}].qty`}
          className={classNames({ 'border-invisible': !!item.qty })}
          handleChange={e => {
            const value = e.target.value;
            setFieldValue(`linkedItems[${index}].qty`, value || null);
          }}
          type="number"
          size="small"
        />
        <Button size="small" onClick={() => unlink(index)} danger ghost>
          {t('application:bundle.unlink')}
        </Button>
      </div>
    );
  };

  return (
    <FieldArray
      name="linkedItems"
      render={({ push, remove, form }) => {
        const unlink = (index: number) => {
          const item = values.linkedItems[index];

          if (item.id) {
            Modal.confirm({
              title: t('application:unlinkItemConfirm'),
              onOk() {
                dispatch(removeBundleItem([item.id]));
              },
            });
          } else {
            remove(index);
          }
        };

        return (
          <div
            className="channel__naming-convention-list flex flex-col"
            style={{ height: values.linkedItems.length * 40 + 76, maxHeight: '100%' }}
          >
            <div className="application__bundle-row bg-gray-200">
              <div>{t('application:bundle.order')}</div>
              <div>{t('application:bundle.ref')}</div>
              <div>{t('application:bundle.id')}</div>
              <div className="extended-info__drawer-list-row_data">
                {t('application:bundle.partNumber')}
              </div>
              <div>{t('application:partType')}</div>
              <div>{t('application:bundle.qualifier')}</div>
              <div>{t('application:bundle.note')}</div>
              <div>{t('application:position')}</div>
              <div>{t('application:qty')}</div>
            </div>

            <div className="application__bundle-add-row">
              <Button
                onClick={() =>
                  push({
                    base_item_id: selectedItem.id,
                    item_id: undefined,
                    part_number: '',
                    part_type: '',
                    qty: 1,
                    category_id: undefined,
                    position_id: undefined,
                    asset_item_order: undefined,
                    asset_item_ref: '',
                    notes: [],
                    qualifiers: [],
                    segment_id: 3,
                    application_ids: [],
                  })
                }
                size="small"
                type="primary"
                ghost
              >
                {t('application:bundle.addNewProduct')}
              </Button>

              <div className="application__bundle-brand-switch inline-block">
                <Switch
                  checked={crossLinkBrandsEnabled}
                  onChange={(checked: boolean) => toggleCrossLinkBrandsEnabled(checked)}
                  size="small"
                />
                <span style={{ marginLeft: 5 }}>{t('application:searchAllBrandProducts')}</span>
              </div>
            </div>

            <div className="flex-1">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    width={width}
                    height={height}
                    rowCount={values.linkedItems.length}
                    rowRenderer={props => rowRenderer(props, unlink)}
                    rowHeight={40}
                  />
                )}
              </AutoSizer>
            </div>

            <BundleQualifierDrawer
              drawerVisible={selectedIndex !== undefined}
              bundleNotes={
                selectedIndex !== undefined ? values.linkedItems[selectedIndex].notes : []
              }
              bundleQualifiers={
                selectedIndex !== undefined ? values.linkedItems[selectedIndex].qualifiers : []
              }
              childItemId={
                selectedIndex !== undefined ? values.linkedItems[selectedIndex].item_id : []
              }
              onClose={() => setSelectedIndex(undefined)}
              updateBundle={(notes, qualifiers) => {
                form.setFieldValue(`linkedItems[${selectedIndex}].qualifiers`, qualifiers);
                form.setFieldValue(`linkedItems[${selectedIndex}].notes`, notes);
                setSelectedIndex(undefined);
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default ApplicationBundleTable;
