import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ItemCustomField } from '../../../types/custom_fields';

export type CustomFieldsState = {
  isFetching: boolean;
  customFields: ItemCustomField[];
};

const initalState: CustomFieldsState = {
  isFetching: true,
  customFields: [],
};

const reducer: Reducer<CustomFieldsState, FluxStandardAction> = (state = initalState, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOM_FIELDS_PENDING': {
      return {
        ...state,
        isFetching: true,
      };
    }
    case 'FETCH_CUSTOM_FIELDS_FULFILLED': {
      return {
        ...state,
        isFetching: false,
        customFields: action.payload.data,
      };
    }
    case 'UPDATE_CUSTOM_FIELDS_FULFILLED': {
      const updatedFields = action.meta.customFields;
      return {
        ...state,
        customFields: updatedFields.map((f: ItemCustomField) => {
          const currentItemField = state.customFields.find(c => c.brand_custom_field_id === f.id);
          return {
            ...(currentItemField || f),
            value: f.value,
          };
        }),
      };
    }
  }
  return state;
};

export default reducer;
