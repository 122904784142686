import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import DigitalAssetsConstants from '../../../../constants/DigitalAssetsConstants.json';
import ModalContent from '../../../global/Modals/ModalContent';
import noImage from '../../../../../images/icons/no-image.svg';

class FilePreviewPopup extends React.Component {
  imageNotFound = event => {
    event.target.src = noImage;
  };

  render() {
    const { file_type_id } = this.props;

    const isPDF = this.props.file_type_id === DigitalAssetsConstants.pdfTypeId;
    const isVideo = DigitalAssetsConstants.videoTypeIds.includes(file_type_id);
    const isImage = DigitalAssetsConstants.imgTypeIds.includes(file_type_id);
    const assetSrc = this.props.uri || this.props.asset_url || noImage;
    return (
      <ModalContent clickOutside className="file-preview-popup__content">
        <CloseOutlined className="file-preview-popup__close" onClick={this.props.hideModal} />
        {isPDF && (
          <object className="file-preview-popup__pdf" data={assetSrc} type="application/pdf">
            <embed src={assetSrc} className="file-preview-popup__pdf" />
          </object>
        )}
        {isVideo && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video className="file-preview-popup__video" controls="controls" preload="metadata">
            <source src={assetSrc} />
          </video>
        )}
        {isImage && (
          <img
            className="file-preview-popup__img"
            src={assetSrc}
            alt={this.props.file_name}
            onError={e => this.imageNotFound(e)}
          />
        )}
      </ModalContent>
    );
  }
}

export default FilePreviewPopup;
