import { Col, Row } from 'antd';
import React from 'react';

import { drawer } from '../../../constants/ChannelTranslation.json';
import FormInput from '../../global/Forms/FormInput';
import FormRadio from '../../global/Forms/FormRadio';
import FormSelect from '../../global/Forms/FormSelect';
import days from '../../../constants/DayConstants.json';

const EbayScheduleFormPart = ({
  values,
  deliveryFrequencies,
  disabled,
}: {
  values: { schedule: { deliveryFrequencyId: number } };
  deliveryFrequencies: any[];
  disabled: boolean;
}) => (
  <React.Fragment>
    <Row gutter={20}>
      <Col span={10}>
        <FormRadio
          name="schedule.deliveryFrequencyId"
          values={deliveryFrequencies.filter(frequency => frequency.id === 4)} // show only manual for first release
          label={drawer.delivery_frequency}
          required
          disabled={disabled}
        />
      </Col>
      {values.schedule.deliveryFrequencyId === 2 && (
        <Col span={4}>
          <FormSelect
            name="schedule.deliveryDay"
            values={days}
            label={drawer.delivery_day}
            required
            disabled={disabled}
          />
        </Col>
      )}
      {values.schedule.deliveryFrequencyId === 3 && (
        <Col span={4}>
          <FormSelect
            name="schedule.deliveryDate"
            values={[...new Array(28)].map((val, i) => ({
              id: i + 1,
              name: i + 1,
            }))}
            label={drawer.delivery_date}
            required
            disabled={disabled}
          />
        </Col>
      )}
      {values.schedule.deliveryFrequencyId !== 4 && (
        <Col span={5}>
          <FormInput
            name="schedule.deliveryTime"
            type="time"
            label={drawer.delivery_time}
            disabled={disabled}
          />
        </Col>
      )}
    </Row>
  </React.Fragment>
);

export default EbayScheduleFormPart;
