import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Spin, Card, Row, Col, Modal, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import DescriptionDrawer from './DescriptionDrawer';
import { ApplicationState } from '../../../reducers';
import { fetchDescriptions } from '../../../actions/items/description/fetch';
import { UsedDescriptionsByBrand, DescriptionContent } from '../../../../types/description';
import { deleteDescription } from '../../../actions/items/description/delete';
import { AsyncDispatch } from '../../../../types/global';
import saveTranslation from '../../../constants/SaveTranslation.json';
import SimpleDivider from '../../global/SimpleDivider';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType, DescriptionTypeElement } from '../../../../types/resources';
import {
  AUTO_GENERATED_DESCRIPTION_TYPE_IDS,
  UNORDERD_LIST_TYPE,
} from '../../../constants/DescriptionConstants';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import AnalysesAlertIcon from '../../global/AnalysesAlertIcon';

const Description: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [descriptionDrawerVisible, setDescriptionDrawerVisible] = React.useState(false);
  const [descriptionDrawerCreateVisible, setDescriptionDrawerCreateVisible] = React.useState(false);
  const [descriptionRecord, setDescriptionRecord] = React.useState(undefined);
  const [suggestedRecordType, setSuggestedRecordType] = React.useState(undefined);

  const {
    selectedItem,
    itemId,
    fetchingDescription,
    descriptions,
    listDescriptionTypes,
    usedDescriptionsByBrand,
    listLanguages,
    analysesBySegment,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      itemId: state.catalogue.catalogue.selectedItemIds[0],
      fetchingDescription: state.items.description.fetchingDescription,
      listDescriptionTypes: state.resources.data.description.types,
      descriptions: state.items.description.descriptions,
      usedDescriptionsByBrand: state.items.description.usedDescriptionsByBrand,
      listLanguages: state.resources.data.global.languages,
      analysesBySegment: extendedAnalysesBySegment(state),
    };
  });

  const descriptionsdata = descriptions.map(desc => {
    const descType = listDescriptionTypes.find(
      (type: DescriptionTypeElement) => type.id === desc.type_id
    );
    const language = listLanguages.find(type => type.id === desc.language_id);
    const analyses = analysesBySegment.filter((a: Analysis & AnalysisType) => {
      return a.reference_id === desc.id || desc.contents.find(c => c.id === a.reference_id);
    });
    const changedDescriptions: any = {
      ...desc,
      label: descType?.name,
      maxLength: descType?.max_length,
      languageCode: language?.code,
      analyses,
    };
    return changedDescriptions;
  });

  React.useEffect(() => {
    if (!fetchingDescription) {
      const params = queryString.parse(location.search, { arrayFormat: 'comma' });
      const descriptionId = Number(params.descriptionId);

      if (descriptionId && !descriptionDrawerVisible) {
        const desc = descriptionsdata.find(d => d.id === descriptionId);
        if (desc) {
          setDescriptionRecord(desc);
          setDescriptionDrawerVisible(true);
        }
      } else if (!descriptionId && descriptionDrawerVisible) {
        setDescriptionRecord(undefined);
        setDescriptionDrawerVisible(false);
      }
    }
  }, [descriptionDrawerVisible, descriptionsdata, fetchingDescription, location.search]);

  const existingDescIds: number[] = descriptions.map(desc => desc.type_id);

  const usedDescriptions: UsedDescriptionsByBrand[] = usedDescriptionsByBrand?.filter(
    descType => !existingDescIds.includes(descType.type_id)
  );

  const suggestedDescriptions = usedDescriptions?.map(desc => {
    const descType = listDescriptionTypes.find(
      (type: DescriptionTypeElement) => type.id === desc.type_id
    );
    const changedDescriptions: any = {
      ...desc,
      label: descType?.name,
      maxLength: descType?.max_length,
    };
    return changedDescriptions;
  });

  const handleCloseDrawer = () => {
    dispatch(fetchDescriptions(itemId));
    setDescriptionRecord(undefined);
    setDescriptionDrawerVisible(false);
    setDescriptionDrawerCreateVisible(false);
    setSuggestedRecordType(undefined);

    const params = queryString.parse(location.search, { arrayFormat: 'comma' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { descriptionId, ...rest } = params;
    navigate(`?${queryString.stringify(rest, { arrayFormat: 'comma' })}`);
  };

  const handleAdd = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'description_new',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    setDescriptionDrawerCreateVisible(true);
  };

  const handleDelete = (id: number) => {
    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_description',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    Modal.confirm({
      title: t('description:deleteDescription'),
      okText: saveTranslation.delete,
      okButtonProps: { danger: true },
      onOk() {
        return dispatch(deleteDescription(id)).then(() => {
          dispatch(fetchDescriptions(itemId));
        });
      },
    });
  };

  const lengthDisplay = (length: number, maxLength: number) => (
    <div
      className={classNames('text-gray-600 text-xs text-right', {
        'text-red-600': length > maxLength,
      })}
    >
      {`${length}/${maxLength}`}
    </div>
  );

  return (
    <div className="description__display__cards">
      <SimpleDivider title={t('glossary:segments.description')} />
      <Button size="small" type="primary" ghost block onClick={() => handleAdd()}>
        {t('description:newDescription')}
      </Button>
      {fetchingDescription ? (
        <div className="pt-2">
          <Spin className="spinner-center p-2" />
        </div>
      ) : (
        <div>
          <div className="pt-2">
            {descriptionsdata.map(descriptionData => (
              <div key={descriptionData.id} className="mb-6">
                <Row gutter={16} className="pb-2 pt-2">
                  <Col span={12}>
                    <div className="text-black">
                      {`${descriptionData.label} (${descriptionData.languageCode})`}
                      <AnalysesAlertIcon analyses={descriptionData.analyses} className="ml-2" />
                    </div>
                  </Col>
                  {AUTO_GENERATED_DESCRIPTION_TYPE_IDS.includes(descriptionData.type_id) && (
                    <Col span={12} className="text-right">
                      <div className="pr-1 text-gray-600">
                        {descriptionData.auto_generate === 0
                          ? t('description:manualText')
                          : t('description:autoText')}
                      </div>
                    </Col>
                  )}
                </Row>
                <Card size="small">
                  <div>
                    {descriptionData.type_id === UNORDERD_LIST_TYPE.FABID ? (
                      <ul className="pl-4 pb-2">
                        {descriptionData.contents?.map((content: DescriptionContent) => (
                          <div key={content.id} className="flex justify-between">
                            <li className="description__card-content">{content.content}</li>
                            <div className="self-end">
                              {lengthDisplay(content.content.length, descriptionData.maxLength)}
                            </div>
                          </div>
                        ))}
                      </ul>
                    ) : (
                      (descriptionData.auto_generate === 0 &&
                        descriptionData.contents.map((content: DescriptionContent) => (
                          <div key={content.id}>
                            <Row justify="space-between" align="bottom" className="pb-2 pr-2">
                              <Col span={22}>
                                <div className="description__card-content">{content.content}</div>
                              </Col>
                              <Col span={2}>
                                {lengthDisplay(content.content.length, descriptionData.maxLength)}
                              </Col>
                            </Row>
                            {content.record_number < descriptionData.contents.length && (
                              <div className="description__divider">
                                <Divider />
                              </div>
                            )}
                          </div>
                        ))) || <div className="pb-2">{descriptionData.generated_content}</div>
                    )}
                    <div className="flex">
                      <div className="flex-1 pr-2">
                        <Button
                          icon={<EditOutlined />}
                          type="primary"
                          size="small"
                          block
                          ghost
                          onClick={() => {
                            setDescriptionRecord(descriptionData);
                            setDescriptionDrawerVisible(true);
                            navigate(`${location.search}&descriptionId=${descriptionData.id}`);
                          }}
                        >
                          {t('common:edit')}
                        </Button>
                      </div>
                      <div className="flex-1">
                        <Button
                          icon={<DeleteOutlined />}
                          ghost
                          size="small"
                          danger
                          block
                          onClick={() => handleDelete(descriptionData.id)}
                          data-testid="delete-description"
                        >
                          {t('common:delete')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

          {suggestedDescriptions?.map(description => (
            <div key={description.type_id} className="mb-6">
              <div className="text-black mb-2">{description.label}</div>
              <Button
                type="dashed"
                size="small"
                onClick={() => {
                  setSuggestedRecordType(description.type_id);
                  setDescriptionDrawerCreateVisible(true);
                }}
              >
                {t('description:addDescription')}
              </Button>
            </div>
          ))}
        </div>
      )}

      <DescriptionDrawer
        visible={descriptionDrawerVisible || descriptionDrawerCreateVisible}
        onClose={() => handleCloseDrawer()}
        title={t('glossary:segments.description')}
        showDelete={!!descriptionRecord}
        description={descriptionRecord}
        suggestedRecordType={suggestedRecordType}
      />
    </div>
  );
};

export default Description;
