import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Popconfirm } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../reducers';
import { createAPIToken, fetchAPIToken } from '../../actions/distribution_apisettings';
import ApiSettingsPage from '../../components/body/distribution/ApiSettingsPage';
import { getPageLimit } from '../../utils/Utils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';
import { Token } from '../../../types/distribution';

const { Paragraph } = Typography;

const APISettingsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { apiSettings, user, fetchingLogsAndToken } = useSelector((state: ApplicationState) => {
    return {
      apiSettings: state.distribution.apiSettings,
      user: state.user.user,
      fetchingLogsAndToken: state.distribution.apiSettings.fetchingLogsAndToken,
    };
  });

  const renderToken = () => {
    const token: Token | null = apiSettings?.token;
    if (fetchingLogsAndToken) return <LoadingOutlined />;
    if (token.token) return token.token;
    return t('api:token');
  };

  React.useEffect(() => {
    intercomEvent('viewed-api', { location: 'settings' });
  }, []);

  React.useEffect(() => {
    dispatch(fetchAPIToken({ limit: getPageLimit() }));
  }, [dispatch]);

  const canCreateApiToken = hasPermission(user, 'can_create_api_token');

  const createToken = () => {
    dispatch(createAPIToken());
    intercomEvent('viewed-api', {
      location: 'settings',
      action: 'token-new',
    });
  };

  return (
    <div className="page-layout api-settings-container">
      <div className="page-layout__top-bar">
        <div className="page-layout__top-bar__container">
          <div className="api-settings__alert">
            {apiSettings.token.token && canCreateApiToken ? (
              <Popconfirm
                title={<div>{t('api:alertText')}</div>}
                onConfirm={() => createToken()}
                okText={t('common:yes')}
                cancelText={t('common:no')}
              >
                <Button type="primary" disabled={fetchingLogsAndToken}>
                  {t('api:createNewApiToken')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={() => createToken()}
                disabled={!canCreateApiToken || fetchingLogsAndToken}
                type="primary"
              >
                {t('api:createNewApiToken')}
              </Button>
            )}
          </div>
          <div className="ml-2 w-full bg-white">
            <Paragraph
              copyable={!!apiSettings.token.token && !fetchingLogsAndToken}
              disabled={!apiSettings.token.token}
              className="api-settings__token flex items-center w-full px-2 justify-between"
            >
              {renderToken()}
            </Paragraph>
          </div>
        </div>
      </div>
      <ApiSettingsPage />
    </div>
  );
};

export default APISettingsContainer;
