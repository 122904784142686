import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import constants from '../../constants/MainAppTranslation.json';
import { nextPage, getPageLimit } from '../../utils/Utils';

/*
  Possible props to pass:
  - className: string that is aditionally added to default className
  - elements: array => [{id: "Int", name: "String"}]
  - noSelection: bool if field "no selection" is displayed
  - noSelectionLabel: display text if nothing is selcted (default: --no selection--)
  - onBlur: function that is called onBlur
  - onChange: function that is called onChange and returns the new selected Element
  - selectedElement: hash => {id: "Int", name: "String"}
  - disabled: dropdown is disabled
*/

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedElement: undefined,
      open: false,
      page: 1,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedElement) {
      this.setState({ selectedElement: this.props.selectedElement });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedElement !== nextProps.selectedElement) {
      this.setState({ selectedElement: nextProps.selectedElement || '' });
    }
    this.isNearBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    // focus the preview on closing the dropdown
    if (prevState.open && !this.state.open) {
      this.preview.focus();
    }
  }

  isNearBottom = () => {
    if (!this.container) return '';
    const top = this.container.getBoundingClientRect().top;
    const bottom = window.innerHeight - top;

    return bottom < 300 ? 'dropdown__select-container_reverse' : '';
  };

  // closed the dropdown on clicks outside of the container
  handleOutsideClick = event => {
    const outsideClick = !this.container.contains(event.target);
    if (outsideClick && this.state.open) {
      this.toggleDropdown(false);
    }
  };

  // Will be replaced by 'selectValue'
  handleChange = newValue => {
    this.setState({ selectedElement: newValue });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };

  // This will replace 'handleChange' when all dropdowns are updated
  selectValue = valueId => {
    this.setState({ selectedElement: valueId });
    this.props.onChange(valueId);
    this.toggleDropdown(false);
  };

  // opens, closed the dropdown => resets the filter and page
  toggleDropdown = state => {
    if (!this.props.new) return; // not needed when all dropdowns are updated
    this.setState(prevState => {
      const newState = state === undefined ? !prevState.open : state;
      return { open: newState, filter: undefined, page: 1 };
    });
  };

  handleScroll = e => {
    const newPage = nextPage(e, undefined, this.valueLimit());
    if (newPage) this.setState({ page: newPage });
  };

  isOpen = () => (this.state.open ? 'dropdown__select-container_open' : '');

  isSelected = value => (value.id === this.state.selectedElement ? 'dropdown__row_selected' : '');

  // returns the count that can be displayed according to pagination
  valueLimit = () => getPageLimit() * this.state.page;

  // returns the slice of the values that fits to the pagination
  paginatedValues = (values = this.props.elements) => values.slice(0, this.valueLimit());

  // filters the values by parameter => every filter-keyword must be in the value-name
  filteredValues = () => {
    const keywords = this.state.filter
      .toLowerCase()
      .split(' ')
      .filter(entry => !!entry);
    const filtered = this.props.elements.filter(elem =>
      keywords.every(keyword => elem.name.toLowerCase().includes(keyword))
    );
    return this.paginatedValues(filtered);
  };

  showDropdown = () => {
    if (this.props.new) return this.showDropdownNew();
    const elements = this.props.elements;
    return (
      <select
        className="dropdown__select"
        value={this.state.selectedElement}
        onChange={e => this.handleChange(e.target.value)}
        onBlur={() => this.props.onBlur()}
        disabled={this.props.disabled}
        data-cy={this.props.dataCy}
      >
        {this.props.noSelection && (
          <option key={-1} value="">
            {this.props.noSelectionLabel || constants.mapping_area.no_selection}
          </option>
        )}
        {elements.map(element => (
          <option key={element.id} value={element.id}>
            {element.name || element.description}
          </option>
        ))}
      </select>
    );
  };

  showDropdownNew = () => {
    const value = this.props.elements.find(elem => elem.id === this.state.selectedElement) || {};
    const values = this.state.filter
      ? this.filteredValues()
      : this.paginatedValues(this.props.elements);

    if (this.state.open) {
      return (
        <div className="dropdown__table-container flex">
          <input
            autoFocus
            className="dropdown__search-input"
            onChange={e => this.setState({ filter: e.target.value })}
            value={this.state.filter || ''}
          />
          <div className="pdm-table dropdown__table" onScroll={e => this.handleScroll(e)}>
            {this.props.noSelection && !this.state.filter && (
              <div
                className="dropdown__row dropdown__row-no-selection"
                onClick={e => {
                  this.selectValue('');
                  e.stopPropagation();
                }}
              >
                <span className="dropdown__row-text">{constants.mapping_area.no_selection}</span>
              </div>
            )}
            {values.map(value => (
              <div
                className={`dropdown__row ${this.isSelected(value)}`}
                key={value.id}
                onClick={e => {
                  this.selectValue(value.id);
                  e.stopPropagation();
                }}
              >
                <span className="dropdown__row-text">{value.name}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div
        className="dropdown__preview"
        ref={input => (this.preview = input)}
        tabIndex="-1"
        onBlur={() => this.props.onBlur()}
      >
        <span className="dropdown__row-text">
          {value.name || constants.mapping_area.no_selection}
        </span>
      </div>
    );
  };

  render() {
    return (
      <span
        className={`dropdown-container ${this.props.className || ''}`}
        ref={element => (this.container = element)}
      >
        <span
          className={`dropdown__select-container ${this.isOpen()} ${this.isNearBottom()}`}
          onClick={() => this.toggleDropdown(true)}
        >
          {this.showDropdown()}
          <DownOutlined className="dropdown__arrow-icon" />
        </span>
      </span>
    );
  }
}

Dropdown.defaultProps = {
  elements: [],
  onChange: () => {},
  onBlur: () => {},
};
