import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { createURL } from '../../../utils/Utils';

export const updateExportBuilderAdvancedTemplate = (obj: any) => {
  return {
    type: 'UPDATE_EXPORT_BUILDER_ADVANCED_TEMPLATE',
    meta: { isNewTemplate: !obj.id },
    payload: axios.patch(`${getApiRoot()}/full_export_templates`, {
      full_export_template: obj,
    }),
  };
};

export const updateExportBuilderAdvancedColumnOrder = (obj: any) => {
  return {
    type: 'UPDATE_EXPORT_BUILDER_ADVANCED_COLUMN_ORDER',
    payload: axios.patch(`${getApiRoot()}/full_export_templates/columns/update_order`, [...obj]),
    meta: { orderList: obj },
  };
};

export const updateExportBuilderAdvancedColumn = (obj: any) => {
  return {
    type: 'UPDATE_EXPORT_BUILDER_ADVANCED_COLUMN',
    meta: { isNewColumn: !obj.id },
    payload: axios.patch(`${getApiRoot()}/full_export_templates/columns`, {
      full_export_template_column: obj,
    }),
  };
};

export const resetExportBuilderAdvanced = () => {
  return {
    type: 'RESET_EXPORT_BUILDER_ADVANCED',
  };
};

export function copyEbpTemplate(
  templateId: number,
  name: string,
  columnIds: number[],
  brandIds?: number[]
) {
  const params = createURL([
    { name: 'brand_ids', values: brandIds },
    { name: 'column_ids', values: columnIds },
    { name: 'new_name', values: name },
  ]);

  return {
    type: 'COPY_EXPORT_BUILDER_ADVANCED_TEMPLATE',
    payload: axios.post(`${getApiRoot()}/full_export_templates/${templateId}/copy${params}`),
  };
}
