import React from 'react';

import { infoEmail } from '../../../constants/ParamountReactConstants';
import Button from '../../global/Button';
import noPermissionImg from '../../../../images/PDM_Enterprise_Receiver.jpg';
import translationContants from '../../../constants/ChannelTranslation.json';

class ChannelNoPermission extends React.Component {
  sendMail = () => {
    window.location.href = `mailto:${infoEmail}`;
  };

  render() {
    return (
      <div className="channel-no-permission">
        <div className="channel-no-permission__picture">
          <img
            src={noPermissionImg}
            className="channel-no-permission__picture box box_background"
            alt="no permission for channel push"
          />
        </div>
        <div className="enterprise-info-box">
          <div className="title">{translationContants.enterpriseFeature}:</div>
          <ul className="enterprise-info-list">
            {translationContants.enterpriseFeatureList.map(feature => (
              <li key={feature}>{feature}</li>
            ))}
          </ul>
          <Button
            name="Contact us to set up new channels!"
            color="blue"
            hover
            onClick={this.sendMail}
          />
        </div>
      </div>
    );
  }
}

export default ChannelNoPermission;
