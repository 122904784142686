import axios from 'axios';
import { debounce } from 'lodash';
import { message } from 'antd';
import { Dispatch } from 'redux';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { PartTypeAttribute } from '../../../../types/attributes';
import * as utils from '../../../utils/Utils';
import { AsyncDispatch } from '../../../../types/global';
import {
  createNewUpload,
  updateUploadProgress,
  uploadFailed,
  uploadFinished,
} from '../language/update';
import { EXCEL_FILE_EXTENSION } from '../../../constants/ImportExportConstants';
import { ExportTypes, ImportTypes } from '../../../../types/import_export';
import { fetchPresignedUrl } from '../../importer';
import { getFileExtension } from '../../../utils/ImportExportUtils';
import { ApplicationCategory } from '../../../../types/application';

export const setSelectedCategory = (category?: ApplicationCategory) => {
  return {
    type: 'SET_SELECTED_CATEGORY',
    payload: category,
  };
};

export const updatePartTypeAttributes = (categoryId: number, attributes: PartTypeAttribute[]) => {
  return {
    type: 'UPDATE_PART_TYPE_ATTRIBUTES',
    payload: axios.patch(`${getApiRoot()}/accounts/category_part_attributes`, {
      category_id: categoryId,
      attributes,
    }),
    meta: { attributes },
  };
};

function createAttributeExcelImport(file: any, uuid: string) {
  return async (dispatch: AsyncDispatch) => {
    dispatch(createNewUpload(uuid, file.name, ImportTypes.EXCEL));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    try {
      const params = utils.createURL([{ name: 'file_name', values: filename }]);
      const response: any = await axios.post(
        `${getApiRoot()}/imports/category_part_attributes${params}`
      );
      dispatch(uploadFinished(uuid, response.data.import_log_id, true));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}

export function importAttributeExcelFile(file: any) {
  return (disptach: any) => {
    const extension = getFileExtension(file.name);
    if (extension !== EXCEL_FILE_EXTENSION) {
      message.error('invalid file');
    } else {
      disptach(createAttributeExcelImport(file, file.uid));
    }
  };
}

export function exportAttributeExcelFile() {
  return async (dispatch: Dispatch) => {
    let exportRes;
    try {
      exportRes = await axios.post(`${getApiRoot()}/exports/category_part_attributes`);

      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType: ExportTypes.EXCEL,
          brandId: 0,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}
