import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { ApplicationState } from '../../reducers';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import EnterprisePromotion from '../../components/global/EnterprisePromotion/EnterprisePromotion';
import { AsyncDispatch } from '../../../types/global';
import GapCoverageReportSteps from '../../components/body/import_export/GapCoverageReportSteps';
import { fetchItemCategories } from '../../actions/items/item/fetch';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const { Content } = Layout;

const GapCoverageReportContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const { canManageGapCoverageReport, selectedBrandId, brandCode } = useSelector(
    (state: ApplicationState) => {
      return {
        canManageGapCoverageReport: hasPermission(
          state.user.user,
          'can_manage_gap_coverage_report'
        ),
        selectedBrandId: state.parent.brands.selectedBrandId,
        brandCode: getSelectedBrandCode(state),
      };
    }
  );

  React.useEffect(() => {
    intercomEvent('viewed-import-export', {
      location: 'gap-coverage-report',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    if (canManageGapCoverageReport)
      dispatch(
        fetchItemCategories({
          brandId: selectedBrandId,
        })
      );
  }, [canManageGapCoverageReport, dispatch, selectedBrandId]);

  return (
    <Content className="bg-white p-6 h-full">
      {!canManageGapCoverageReport ? <EnterprisePromotion /> : <GapCoverageReportSteps />}
    </Content>
  );
};

export default GapCoverageReportContainer;
