import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ErrorModalTitleProps = {
  handleErrorDownload: () => void;
};

const ErrorModalTitle: React.FC<ErrorModalTitleProps> = ({ handleErrorDownload }) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="pr-2">{t('common:errors')}</div>
      <Button icon={<DownloadOutlined />} size="small" onClick={() => handleErrorDownload()}>
        {t('common:downloadErrors')}
      </Button>
    </div>
  );
};

export default ErrorModalTitle;
