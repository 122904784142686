const initialState = {
  fetching: false,
  digitalAssets: [],
  selectedDigitalAssetId: undefined,
  digitalAssetDescriptions: [],
  assetTypesSorting: [],
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'FETCH_DIGITAL_ASSETS_PENDING': {
      return { ...state, fetching: true, digitalAssets: [], digitalAssetDescriptions: [] };
    }
    case 'FETCH_DIGITAL_ASSETS_FULFILLED': {
      const digitalAssets = payload.data;
      return {
        ...state,
        fetching: false,
        digitalAssets,
      };
    }
    case 'FETCH_DIGITAL_ASSETS_TYPES_SORTING_FULFILLED': {
      return { ...state, assetTypesSorting: action.payload.data };
    }
    case 'FETCH_DIGITAL_ASSET_DESCRIPTION_FULFILLED': {
      return { ...state, digitalAssetDescriptions: action.payload.data };
    }
    case 'SELECT_DIGITAL_ASSET': {
      return { ...state, selectedDigitalAssetId: action.payload };
    }
    case 'CREATE_DIGITAL_ASSET_DESCRIPTION_FULFILLED': {
      return {
        ...state,
        digitalAssetDescriptions: [payload.data, ...state.digitalAssetDescriptions],
      };
    }
    case 'CREATE_DIGITAL_ASSET_FULFILLED': {
      const newDigitalAsset = payload.data;
      const assetsIds = state.digitalAssets.map(asset => asset.id);
      const isOverwrite = assetsIds.includes(newDigitalAsset.id);
      return {
        ...state,
        digitalAssets: isOverwrite
          ? state.digitalAssets.map(asset => {
              if (asset.id === newDigitalAsset.id) {
                return {
                  ...newDigitalAsset,
                  asset_url: `${newDigitalAsset.asset_url}?t=${new Date().getTime()}`,
                  thumbnail: `${newDigitalAsset.asset_url}?t=${new Date().getTime()}`,
                  shop_preview: `${newDigitalAsset.asset_url}?t=${new Date().getTime()}`,
                };
              }
              return asset;
            })
          : [newDigitalAsset, ...state.digitalAssets],
        selectedDigitalAssetId: newDigitalAsset.id,
        digitalAssetDescriptions: [],
      };
    }
    case 'LINK_DIGITAL_ASSET_FULFILLED': {
      return {
        ...state,
        digitalAssets: [payload.data, ...state.digitalAssets],
        selectedDigitalAssetId: payload.data.id,
      };
    }
    case 'UPDATE_DIGITAL_ASSET_PARENT_PENDING': {
      return {
        ...state,
        digitalAssets: state.digitalAssets.map(digitalAsset => {
          if (digitalAsset.digital_asset_id === action.meta.digitalAssetId) {
            const newValue = { [action.meta.key]: action.meta.value };
            return { ...digitalAsset, ...newValue };
          }
          return digitalAsset;
        }),
      };
    }
    case 'UPDATE_DIGITAL_ASSET_ITEM_PENDING': {
      return {
        ...state,
        digitalAssets: state.digitalAssets.map(digitalAsset => {
          if (digitalAsset.id === action.meta.itemDigitalAssetId) {
            const newValue = { [action.meta.key]: action.meta.value };
            return { ...digitalAsset, ...newValue };
          }
          return digitalAsset;
        }),
      };
    }
    case 'DELETE_DIGITAL_ASSET_FULFILLED': {
      return {
        ...state,
        digitalAssets: state.digitalAssets.filter(
          digitalAsset => digitalAsset.id !== action.meta.assetId
        ),
        selectedDigitalAssetId: state.digitalAssets.length ? state.digitalAssets[0].id : undefined,
      };
    }
    case 'DELETE_DIGITAL_ASSET_DESCRIPTION_FULFILLED': {
      return {
        ...state,
        digitalAssetDescriptions: state.digitalAssetDescriptions.filter(
          description => description.id !== action.meta.descriptionId
        ),
      };
    }
  }
  return state;
};

export default reducer;
