export function showModal(modalType, modalProps) {
  return {
    type: 'SHOW_MODAL',
    payload: {
      modalType,
      modalProps,
    },
  };
}

export function hideModal() {
  return {
    type: 'HIDE_MODAL',
  };
}

export function showUpgradePlanNotification() {
  return {
    type: 'SHOW_UPGRADE_NOTIFICATION',
  };
}

export function hideUpgradePlanNotification() {
  return {
    type: 'HIDE_UPGRADE_NOTIFICATION',
  };
}
