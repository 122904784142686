import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ImportLog } from '../../../types/import_export';

export type ReceiverImportState = {
  importLogs: ImportLog[];
  fetchingImportLogs: boolean;
};

const initialState = {
  importLogs: [],
  fetchingImportLogs: false,
};

const reducer: Reducer<ReceiverImportState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_RECEIVER_IMPORT_LOGS_PENDING': {
      return { ...state, importLogs: [], fetchingImportLogs: true };
    }
    case 'FETCH_RECEIVER_IMPORT_LOGS_FULFILLED': {
      return {
        ...state,
        importLogs: action.payload.data,
        fetchingImportLogs: false,
      };
    }
  }
  return state;
};

export default reducer;
