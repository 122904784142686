import React from 'react';
import noImage from '../../../../images/icons/no-image.svg';
import { ShopPreviewAttribute, ShopPreviewPrimaryAsset } from '../../../../types/shop_preview';
import { Item } from '../../../../types/item';

type CatalogueShopPreviewProps = {
  asset: ShopPreviewPrimaryAsset | null;
  selectedItem?: Item;
  shortName: string | null;
  partNumber: string;
  position: string;
  attributes: ShopPreviewAttribute[];
};

class CatalogueShopPreview extends React.Component<CatalogueShopPreviewProps> {
  imageNotFound = (event: any) => {
    event.target.src = noImage;
  };

  showImage = () => {
    const { asset } = this.props;
    const path = asset?.uri || asset?.shop_preview || noImage;
    return (
      <div className="catalogue-shop-preview__image">
        <img alt={this.props.partNumber} src={path} onError={e => this.imageNotFound(e)} />
      </div>
    );
  };

  render() {
    const { selectedItem, shortName, position, partNumber, attributes } = this.props;
    const displayedShortName = shortName ? `| ${shortName}` : '';

    return (
      <div className="catalogue-shop-preview">
        {selectedItem && partNumber && (
          <React.Fragment>
            {this.showImage()}
            <div className="catalogue-shop-preview__inner">
              <h4 className="catalogue-shop-preview__title">{`${partNumber} ${displayedShortName}`}</h4>
              <div className="catalogue-shop-preview__content">
                <div className="preview">
                  <span className="preview-label">Position: </span>
                  <span className="preview-data">{position || 'no position'}</span>
                </div>
                {attributes.length > 0 &&
                  attributes.map(attribute => {
                    const values = attribute.values.map(val => val.name);
                    return (
                      <div className="preview" key={attribute.id}>
                        <span className="preview-label">{attribute.name}: </span>
                        <span className="preview-data">{values}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CatalogueShopPreview;
