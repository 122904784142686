import React from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

type EbayFormDefaultLabelProps = {
  checked: boolean;
  disabled?: boolean;
  name: string;
  title: string;
  handleChange: (checked: boolean, name: string) => void;
};

const EbayFormDefaultLabel: React.FC<EbayFormDefaultLabelProps> = props => {
  const { t } = useTranslation();

  const defaultSwitch = (name: string) => {
    const { disabled, checked } = props;

    return (
      <div className="ebay__default-switch-container">
        <span className="ebay__default-switch-tag">{t('ebay:form.default')}</span>
        <Switch
          disabled={disabled}
          checked={checked}
          size="small"
          onChange={(checked: boolean) => props.handleChange(checked, name)}
        />
      </div>
    );
  };

  return (
    <div className="ebay__form-label">
      {props.title}
      {defaultSwitch(props.name)}
    </div>
  );
};

export default EbayFormDefaultLabel;
