import { ExtendedResources } from './resources';

export type ImportOptions = {
  id: number;
  name: string;
  import_options: ImportOption[];
};

export type ImportOption = {
  file_type_id: number;
  id: number;
  code: string;
  name: string;
  description: null | string;
  format_type_id: number;
  default_value: number[] | null | string;
  reference_table: null | string;
  mandatory: number;
  advanced: number;
  parameters: ImportParameters[];
};

export type ImportParameters = {
  id: number;
  name: string;
  code: string;
};

export type ScheduledImports = {
  id: number;
  server: string;
  port: number;
  user_name: string;
  password: string;
  protocol_type_id: number;
  custom_directory: string;
  delete_on_pies: number;
  inactive: number;
  delivery_frequency_id: number;
  brand_id?: number;
};

export type ExternalSourceImport = {
  id: number;
  started_at: null | string;
  created_at: string;
  updated_at: string;
  completed_at: string;
  environment: null | string;
  account_id: number | null;
  user_id: number | null;
  file_name: string;
  s3_file_name: string;
  file_size: number | null;
  item_count: number | null;
  application_count: number | null;
  source_id: number;
  file_type_id: number | null;
  external_data_provider_id: number;
  dataset: null;
  status: string;
  status_id: number;
  progress: number;
  sub_progress: number;
  brand_id: number;
  receiver_id: number;
  hash_key: null | string;
  unique_file_key: null | string;
  download: number;
  info_count: number;
  warning_count: number;
  error_count: number;
};

export type FileExportOption = {
  id: number;
  name: string;
  export_options: ExportOption[];
};

export type ExportOption = {
  file_type_id: number;
  id: number;
  code: string;
  name: string;
  description: string;
  format_type_id: number;
  default_value: number[] | null | string;
  reference_table: null | string;
  mandatory: number;
  advanced: number;
  parameters: ExtendedResources[];
};

export enum ImportTypes {
  'EXCEL',
  'PIES',
  'ACES',
  'ASSET',
}

export enum ExportTypes {
  'EXCEL',
  'EXCEL_PRICE_SHEETS',
  'EXCEL_TEMPLATE_ADVANCED',
  'ACES',
  'PIES',
  'ASSET',
  'PDF',
  'SUMMIT',
}

export type ImportLog = {
  id: number;
  started_at: null | string;
  updated_at: string;
  completed_at: string;
  environment: null | string;
  account_id: null | number;
  user_id: number | null;
  file_name: string;
  file_size: number | null;
  source_id: number | null;
  file_type_id: number | null;
  external_data_provider_id: null | number;
  dataset: null;
  status: null | string;
  status_id: null | number;
  progress: number;
  sub_progress: number;
  brand_id: number;
  receiver_id: null | number;
  hash_key: null | string;
  unique_file_key: null | string;
  error_count: number;
  download: number | null;
  fileTypeName?: string;
  startedTime?: string;
  completedTime?: string;
  info_count: number;
  warning_count: number;
};

export type ImportError = {
  segment_name: string;
  row_number: string;
  description: string;
};

export type ExportError = {
  segment_name: string;
  part_number: string;
  description: string;
};

export type ExportLog = {
  id: number;
  started_at: null | string;
  updated_at: string;
  completed_at: string;
  account_id: number | null;
  user_id: number;
  file_name: null | string;
  source_id: number;
  status: null | string;
  progress: number;
  sub_progress: number;
  brand_id: number | null;
  hash_key: null | string;
  error_count: number;
  empty_file: number;
  download: number;
  file_type_id: number | null;
  status_id: number;
  info_count: number | null;
  warning_count: number | null;
};

export type ImportBrandStatus = {
  pies_import_completed_at?: string | null;
  aces_import_completed_at?: string | null;
  digital_asset_import_completed_at?: string | null;
  excel_import_completed_at?: string | null;
};

export type ImportErrorLogDetail = {
  segment_name: string;
  row_number: string;
  description: string;
  type_id: null | number;
  long_description: string | null;
};

export type ExportErrorLogDetail = {
  segment_name: string;
  part_number: string;
  description: string;
  type_id: null | number;
  long_description: string | null;
};
