import axios from 'axios';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';
import { ReceiverInvite } from '../../../types/receiver';

export const approveReceivers = ({
  receiverId,
  brandIds,
  selectedPriceSheetIds,
  selectedPriceTypeIds,
  withoutPrices,
}: {
  receiverId: number;
  brandIds: number[];
  selectedPriceSheetIds: number[];
  selectedPriceTypeIds: number[];
  withoutPrices: boolean;
}) => {
  const dbWithoutPrices = withoutPrices ? 1 : 0;
  const urlParams = utils.createURL([
    { name: 'requesting_receiver_id', values: receiverId },
    { name: 'brand_ids', values: brandIds },
    { name: 'price_sheet_ids', values: selectedPriceSheetIds },
    { name: 'price_type_ids', values: selectedPriceTypeIds },
    { name: 'without_prices', values: dbWithoutPrices },
  ]);

  return {
    type: 'APPROVE_RECEIVERS',
    meta: {
      receiverId,
      brandIds,
      selectedPriceSheetIds,
      selectedPriceTypeIds,
      withoutPrices: dbWithoutPrices,
    },
    payload: axios.patch(
      `${getApiRoot()}/parents/receivers/authorized_brands/approve_receiver_request${urlParams}`
    ),
  };
};

export const declineReceivers = (params: {
  receiverId: number;
  brandIds: number[];
  reason?: string;
}) => {
  const urlParams = utils.createURL([
    { name: 'requesting_receiver_id', values: params.receiverId },
    { name: 'brand_ids', values: params.brandIds },
    { name: 'declined_by_brand_reason', values: params.reason },
  ]);

  return {
    type: 'DECLINE_RECEIVERS',
    meta: { receiverId: params.receiverId, brandIds: params.brandIds, reason: params.reason },
    payload: axios.patch(
      `${getApiRoot()}/parents/receivers/authorized_brands/decline_receiver_request${urlParams}`
    ),
  };
};

export const cancelRequests = (params: { receiverId: number; brandIds: number[] }) => {
  const urlParams = utils.createURL([
    { name: 'requesting_receiver_id', values: params.receiverId },
    { name: 'brand_ids', values: params.brandIds },
  ]);

  return {
    type: 'CANCEL_REQUESTS',
    meta: { receiverId: params.receiverId, brandIds: params.brandIds },
    payload: axios.patch(
      `${getApiRoot()}/parents/receivers/authorized_brands/cancel_receiver_request${urlParams}`
    ),
  };
};

export const receiverBulkInvite = (values: ReceiverInvite[]) => {
  return {
    type: 'RECEIVER_BULK_INVITE',
    payload: axios.post(`${getApiRoot()}/receivers/create`, {
      receivers: values,
    }),
  };
};

export const receiverBulkInviteByFile = (file: any) => {
  const config = {
    headers: { 'Content-Type': file.type },
  };
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return {
    type: 'RECEIVER_BULK_INVITE',
    payload: axios.post(`${getApiRoot()}/receivers/create`, bodyFormData, config),
  };
};

export const createReceiverDetailsRequest = (receiverId: number) => {
  const urlParams = utils.createURL([{ name: 'receiver_id', values: receiverId }]);
  return {
    type: 'CREATE_RECEIVER_DETAILS_REQUEST',
    meta: { receiverId },
    payload: axios.post(`${getApiRoot()}/parents/receivers/request_receiver_details${urlParams}`),
  };
};
