import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ImportContainer from '../../containers/import_export/ImportContainer';
import ImportLogsContainer from '../../containers/import_export/ImportLogsContainer';
import ExportContainer from '../../containers/import_export/ExportContainer';
import ExportLogsContainer from '../../containers/import_export/ExportLogsContainer';
import ExportBuilderAdvancedContainer from '../../containers/import_export/ExportBuilderAdvancedContainer';
import GapCoverageReportContainer from '../../containers/import_export/GapCoverageReportContainer';
import AutomateImportContainer from '../../containers/import_export/AutomateImportContainer';

const ImportExportRoutes = () => (
  <Routes>
    <Route path="import" element={<ImportContainer />} />
    <Route path="export" element={<ExportContainer />} />
    <Route path="import-logs" element={<ImportLogsContainer />} />
    <Route path="automate-import" element={<AutomateImportContainer />} />
    <Route path="gap-coverage-report" element={<GapCoverageReportContainer />} />
    <Route path="export-logs" element={<ExportLogsContainer />} />
    <Route path="export-builder-advanced" element={<ExportBuilderAdvancedContainer />} />
  </Routes>
);

export default ImportExportRoutes;
