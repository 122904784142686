import axios from 'axios';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { CustomFieldOption, BrandCustomField } from '../../../../types/custom_fields';

const CancelToken = axios.CancelToken;

let fetchSettingsCustFieldsCancelToken: any;

export function fetchSettingsCustomFields(brandId?: number) {
  if (fetchSettingsCustFieldsCancelToken) fetchSettingsCustFieldsCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_SETTINGS_CUSTOM_FIELDS',
    payload: axios.get(`${getNonGwApiRoot()}/parents/owners/brands/custom_fields${params}`, {
      cancelToken: new CancelToken(c => {
        fetchSettingsCustFieldsCancelToken = c;
      }),
    }),
  };
}

let fetchCustomFieldGroupCancelToken: any;

export function fetchCustomFieldGroups(brandId?: number) {
  if (fetchCustomFieldGroupCancelToken) fetchCustomFieldGroupCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_CUSTOM_FIELD_GROUPS',
    payload: axios.get(`${getNonGwApiRoot()}/accounts/custom_fields/groups${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCustomFieldGroupCancelToken = c;
      }),
    }),
  };
}

export function updateCustomField({
  customFieldId,
  brandId,
  name,
  customFieldTypeId,
  recordNumber,
  options,
  ignoreOnSync,
  scriptId,
  groupId,
}: {
  customFieldId: number | string | null;
  name: string;
  brandId?: number;
  customFieldTypeId: number;
  ignoreOnSync: number;
  recordNumber?: number;
  options?: CustomFieldOption[];
  scriptId?: number;
  groupId?: number | null;
}) {
  return {
    type: 'UPDATE_BRAND_CUSTOM_FIELD',
    meta: {
      customFieldId,
      name,
      customFieldTypeId,
      recordNumber,
      options,
      ignoreOnSync,
      scriptId,
      groupId,
    },
    payload: axios.patch(`${getApiRoot()}/parents/owners/brands/custom_fields`, {
      id: customFieldId,
      brand_id: brandId,
      name,
      custom_field_type_id: customFieldTypeId,
      ignore_on_sync: ignoreOnSync,
      record_number: recordNumber,
      options,
      script_id: scriptId,
      group_id: groupId,
    }),
  };
}

export function updateCustomFieldOrder(
  newOrder: {
    brand_custom_field_id: number;
    record_number: number;
    group_id: number | null;
  }[],
  sortedCustomFields: BrandCustomField[]
) {
  return {
    type: 'UPDATE_BRAND_CUSTOM_FIELD_ORDER',
    payload: axios.patch(
      `${getApiRoot()}/parents/owners/brands/custom_fields/update_order`,
      newOrder
    ),
    meta: { sortedCustomFields },
  };
}

export function updateCustomFieldGroups(groups: any) {
  return {
    type: 'UPDATE_CUSTOM_FIELD_GROUPS',
    payload: axios.patch(`${getApiRoot()}/accounts/custom_fields/groups`, {
      custom_field_groups: groups,
    }),
    meta: { groups },
  };
}

export function deleteCustomField(customFieldId: number) {
  return {
    type: 'DELETE_BRAND_CUSTOM_FIELD',
    meta: {
      customFieldId,
    },
    payload: axios.delete(`${getApiRoot()}/parents/owners/brands/custom_fields/${customFieldId}`),
  };
}

export function deleteCustomFieldGroup(groupId: number) {
  return {
    type: 'DELETE_CUSTOM_FIELD_GROUP',
    meta: { groupId },
    payload: axios.delete(`${getApiRoot()}/accounts/custom_fields/groups/${groupId}`),
  };
}
