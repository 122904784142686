import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { Translation } from '../../../../types/application';
import { ApplicationState } from '../../../reducers';
import { mappedLanguageNameTranslations } from '../../../selectors/Translations/languageSelector';

type ModalTranslationTableProps = {
  valueTranslations: Translation[];
};

const ModalTranslationTable: React.FC<ModalTranslationTableProps> = props => {
  const { translationLanguages, fetchingTranslations } = useSelector((state: ApplicationState) => {
    return {
      translationLanguages: mappedLanguageNameTranslations(state),
      fetchingTranslations: state.parent.languageTranslation.fetchingTransLations,
    };
  });

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const translation = translationLanguages[index];

    const value = props.valueTranslations.find(
      vt => Number(vt.language_id) === translation.language_id
    );

    return (
      <div
        key={key}
        style={style}
        className="popover-model-translation-table___border flex items-center"
      >
        <div className="flex px-2 items-center" style={{ width: '100%' }}>
          <div className="flex-1">{translation.languageName}</div>
          <div className="flex-1">{value?.value}</div>
        </div>
      </div>
    );
  };

  if (fetchingTranslations) return <Spin size="small" className="spinner-center" />;

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowCount={translationLanguages.length}
          rowHeight={30}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

export default ModalTranslationTable;
