import axios from 'axios';
import { message } from 'antd';
import { debounce } from 'lodash';
import { Dispatch } from 'redux';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { EXCEL_FILE_EXTENSION } from '../../../constants/ImportExportConstants';
import { getFileExtension } from '../../../utils/ImportExportUtils';
import { AsyncDispatch } from '../../../../types/global';
import { ExportTypes, ImportTypes } from '../../../../types/import_export';
import * as utils from '../../../utils/Utils';
import {
  createNewUpload,
  updateUploadProgress,
  uploadFailed,
  uploadFinished,
} from '../language/update';
import { fetchPresignedUrl } from '../../importer';

function createVcdbExcelImport(file: any, configIds: number[], versionId: number, uuid: string) {
  return async (dispatch: AsyncDispatch) => {
    dispatch(createNewUpload(uuid, file.name, ImportTypes.EXCEL));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    try {
      const params = utils.createURL([
        { name: 'version_id', values: versionId },
        { name: 'config_type_ids', values: configIds },
        { name: 'file_name', values: filename },
      ]);
      const response = await axios.post(`${getApiRoot()}/imports/custom_vehicle_files${params}`);
      dispatch(uploadFinished(uuid, response.data.import_log_id, true));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}

export function importVcdbExcelFile(file: any, configIds: number[], versionId: number) {
  return (disptach: any) => {
    const extension = getFileExtension(file.name);
    if (extension !== EXCEL_FILE_EXTENSION) {
      message.error('invalid file');
    } else {
      disptach(createVcdbExcelImport(file, configIds, versionId, file.uid));
    }
  };
}

export function exportVCdbExcelFile(configIds: number[], versionId: number) {
  const params = utils.createURL([
    { name: 'version_ids', values: versionId },
    { name: 'config_type_ids', values: configIds },
  ]);
  return async (dispatch: Dispatch) => {
    let exportRes;
    try {
      exportRes = await axios.post(`${getApiRoot()}/exports/custom_vehicle_files${params}`);

      dispatch({
        type: '@EXPORTER/START_EXPORT_FULFILLED',
        meta: {
          fileType: ExportTypes.EXCEL,
          brandId: 0,
        },
        payload: exportRes,
      });
    } catch (e: any) {
      message.error(e.response.data.message);
    }
  };
}
