import axios from 'axios';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

let fetchBrandResourcesCancelToken;

export function fetchBrandResources(brandId) {
  if (fetchBrandResourcesCancelToken) fetchBrandResourcesCancelToken();

  return {
    type: 'FETCH_BRAND_RESOURCES',
    payload: axios.get(`${getNonGwApiRoot()}/parents/owners/brands/${brandId}/resources`, {
      cancelToken: new CancelToken(c => {
        fetchBrandResourcesCancelToken = c;
      }),
    }),
  };
}
