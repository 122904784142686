import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { DescriptionContent } from '../../../../types/description';

export function createDescription(descriptionValues: {
  selectedItemId: number;
  typeId: number;
  languageId: number;
  autoGenerate: number;
  contents: DescriptionContent[];
}) {
  const urlParams = utils.createURL([
    { name: 'item_id', values: descriptionValues.selectedItemId },
    { name: 'type_id', values: descriptionValues.typeId },
    { name: 'language_id', values: descriptionValues.languageId },
    { name: 'auto_generate', values: descriptionValues.autoGenerate },
  ]);
  const contents = descriptionValues.contents;

  return {
    type: 'CREATE_DESCRIPTION',
    payload: axios.post(`${getNonGwApiRoot()}/items/descriptions${urlParams}`, { contents }),
  };
}
