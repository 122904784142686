import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { withRouter } from '../../containers/withRouter';
import ImportExportRoutes from '../../routes/brand/ImportExportRoutes';
import { withBrandRouteWrapper } from '../../containers/BrandRouteWrapper';
import { hasPermission, hasAccountPermission } from '../../utils/Permissions';
import text from '../../constants/ImportExportMenuTranslation.json';

const { Sider } = Layout;

class BrandImportExportRoutes extends React.Component {
  componentDidMount() {
    const { user, permissionGroups: permissions } = this.props;
    const values = queryString.parse(this.props.location.search);
    const importAccess = hasPermission(user, 'has_import_access');
    const importAccount = hasAccountPermission(permissions, 'has_import_access');

    if (!importAccess && importAccount)
      this.props.navigate(`/brand/import-export/import-logs?brandId=${values.brandId}`);
  }

  render() {
    const values = queryString.parse(this.props.location.search);
    const { location, user, permissionGroups: permissions } = this.props;
    const { brandId } = values;

    // also checking for account access to show enterprise feature screen
    const exportBuilderAdvancedAccess = hasPermission(user, 'can_create_full_template');
    const exportBuilderAdvancedAccount = hasAccountPermission(
      permissions,
      'can_create_full_template'
    );
    const exportAccess = hasPermission(user, 'has_export_access');
    const exportAccount = hasAccountPermission(permissions, 'has_export_access');
    const importAccess = hasPermission(user, 'has_import_access');
    const importAccount = hasAccountPermission(permissions, 'has_import_access');
    const canManageAutoImport = hasPermission(user, 'can_manage_auto_import');

    return (
      <Layout className="layout">
        <Sider theme="light">
          <Menu mode="inline" onClick={this.handleMenuClick} selectedKeys={[location.pathname]}>
            {(importAccess || !importAccount) && (
              <Menu.Item key="/brand/import-export/import">
                <Link
                  to={{
                    pathname: `import`,
                    search: `?brandId=${brandId}`,
                  }}
                >
                  {text.import}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/brand/import-export/import-logs">
              <Link
                to={{
                  pathname: `import-logs`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {text.importReports}
              </Link>
            </Menu.Item>
            {canManageAutoImport && (
              <Menu.Item key="/brand/import-export/automate-import">
                <Link
                  to={{
                    pathname: `automate-import`,
                    search: `?brandId=${brandId}`,
                  }}
                >
                  {text.automateImport}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/brand/import-export/gap-coverage-report">
              <Link
                to={{
                  pathname: `gap-coverage-report`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {text.gapCoverageReport}
              </Link>
            </Menu.Item>
            {(exportAccess || !exportAccount) && (
              <Menu.Item key="/brand/import-export/export">
                <Link
                  to={{
                    pathname: `export`,
                    search: `?brandId=${brandId}`,
                  }}
                >
                  {text.export}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/brand/import-export/export-logs">
              <Link
                to={{
                  pathname: `export-logs`,
                  search: `?brandId=${brandId}`,
                }}
              >
                {text.exportReports}
              </Link>
            </Menu.Item>
            {(exportBuilderAdvancedAccess || !exportBuilderAdvancedAccount) && (
              <Menu.Item key="/brand/import-export/export-builder-advanced">
                <Link
                  to={{
                    pathname: `export-builder-advanced`,
                    search: `?brandId=${brandId}`,
                  }}
                >
                  {text.exportBuilderPlus}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <ImportExportRoutes />
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  permissionGroups: state.user.permissionGroups,
});

export default connect(mapStateToProps)(withRouter(withBrandRouteWrapper(BrandImportExportRoutes)));
