import { combineReducers } from 'redux';
import scorecards, { ScoreCardsState } from './ScoreCardsReducer';
import defaultValues from './DefaultValuesReducer';
import xmlHeaders from './XmlHeadersReducer';
import companyProfile, { CompanyProfileState } from './CompanyProfileReducer';

export type SettingsState = {
  scorecards: ScoreCardsState;
  defaultValues: any;
  companyProfile: CompanyProfileState;
  xmlHeaders: any;
};
const SettingsReducer = combineReducers<SettingsState>({
  scorecards,
  defaultValues,
  xmlHeaders,
  companyProfile,
});

export default SettingsReducer;
