import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { getItemApplicationListFilter } from '../../../selectors/catalogue/filterSelector';
import { mappedItemApplicationGridList } from '../../../selectors/all_applications/allApplicationsSelector';
import { getPageLimit, typingDone } from '../../../utils/Utils';
import {
  fetchApplication,
  fetchApplicationGroupData,
  fetchItemApplicationGrids,
} from '../../../actions/items/application/fetch';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import AGTableGridList from '../all_application_validation/AGTableGridList';
import ApplicationSplitDrawer from './ApplicationSplitDrawer';

type ApplicationGridListProps = {
  keywords: string;
  selectApplication: (id: number) => void;
  cloneApplication: (id: number) => void;
  deleteApplication: (id: number) => Promise<any>;
  deleteApplications: (p: {
    applicationIds?: number[];
    itemId?: number;
    noConfirm?: boolean;
  }) => Promise<any>;
};

const ApplicationGridList: React.FC<ApplicationGridListProps> = ({
  keywords,
  selectApplication,
  cloneApplication,
  deleteApplication,
  deleteApplications,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItem,
    listFilter,
    applicationRows,
    mappedApplicationRows,
    applicationGridGroupData,
    columns,
    fetching,
    selectedVcdbs,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      listFilter: getItemApplicationListFilter(state),
      applicationRows: state.items.application.applicationGridRows,
      mappedApplicationRows: mappedItemApplicationGridList(state),
      applicationGridGroupData: state.items.application.applicationGridGroupData,
      columns: state.items.application.columns,
      fetching: state.items.application.fetchingApplicationGridRows,
      selectedVcdbs: state.items.application.selectedVcdbs,
    };
  });

  const [cachedKeywords, setCachedKeywords] = React.useState(keywords);
  const [currentItemId, setCurrentItemId] = React.useState();
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
  const [showApplicationSplitDrawer, setShowApplicationSplitDrawer] = React.useState(false);

  const fetchListing = React.useCallback(() => {
    dispatch(fetchItemApplicationGrids(selectedVcdbs, listFilter.id, selectedItem.id, keywords));
  }, [dispatch, keywords, listFilter.id, selectedItem.id, selectedVcdbs]);

  // Filter currently not in use
  React.useEffect(() => {
    // dispatch(fetchApplicationListFilter(listFilter.id)).then(result => {
    //   const filter = result.value.data;
    //   // set filter keyword if necessary
    //   if (filter.keywords !== keywords)
    //     dispatch(updateListFilterKeywords(listFilter.id, keywords)).then(() => fetchListing());
    //   else fetchListing();
    // });
  }, [fetchListing]);

  React.useEffect(() => {
    if (currentItemId !== selectedItem.id) {
      setCurrentItemId(selectedItem.id);
      fetchListing();
      setSelectedRowIds([]);
    }
  }, [fetchListing, currentItemId, selectedItem.id]);

  React.useEffect(() => {
    if (keywords !== cachedKeywords) {
      setCachedKeywords(keywords);
      typingDone(() => {
        fetchListing();
        if (applicationRows.length) {
          const search = document.getElementById('applicationSearch');
          if (search) search.blur();
          if (search) search.focus();
        }
      }, 400);
    }
  }, [dispatch, cachedKeywords, keywords, listFilter.id, applicationRows.length, fetchListing]);

  const pageSize = getPageLimit();

  const fetchNextListings = (page: number) => {
    const currentPage = Math.ceil(applicationRows.length / pageSize);
    const lastPage = currentPage > applicationRows.length / pageSize;

    // if (!lastPage)
    return dispatch(
      fetchItemApplicationGrids(selectedVcdbs, listFilter.id, selectedItem.id, keywords, page)
    );
  };

  const fetchGroupData = (applicationId: number, page?: number) => {
    return dispatch(fetchApplicationGroupData(applicationId, selectedItem.id, page || 1));
  };

  const fetchSplitGroupData = (applicationId: number) => {
    dispatch(fetchApplicationGroupData(applicationId, selectedItem.id, 1)).then(response => {
      const totalCount = response.value.data.total_count;
      if (totalCount > 100)
        dispatch(fetchApplicationGroupData(applicationId, selectedItem.id, 1, totalCount));
    });
  };

  const handleDelete = (props: { applicationIds?: number[]; itemId?: number }) => {
    Modal.confirm({
      title: props.applicationIds
        ? t('application:deleteSelectedMessage')
        : t('application:deleteAllMessage'),
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      async onOk() {
        const deleteResult = await deleteApplications({ ...props, noConfirm: true });
        fetchListing();
        setSelectedRowIds([]);
        return deleteResult;
      },
    });
  };

  const handleApplicationSelect = (id: number) => {
    dispatch(fetchApplication(selectedItem.id, id));
    selectApplication(id);
  };

  const handleRowSelect = (ids: string[]) => {
    setSelectedRowIds(ids);
  };

  if (applicationRows.length === 0 && fetching)
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;
  if (applicationRows.length === 0 && !fetching)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <React.Fragment>
      {columns.length > 0 && (
        <div className="application__listing application_listing__validation-selects">
          <Button
            onClick={() => {
              const applicationId = Number(selectedRowIds[0]);
              fetchSplitGroupData(applicationId);
              dispatch(fetchApplication(selectedItem.id, applicationId));
              setShowApplicationSplitDrawer(true);
            }}
            disabled={selectedRowIds.length !== 1}
            htmlType="button"
            size="small"
            type="primary"
            ghost
          >
            {t('application:split.splitApplication')}
          </Button>
          <Button
            className="ml-2"
            onClick={() => {
              const applicationId = Number(selectedRowIds[0]);
              dispatch(fetchApplication(selectedItem.id, applicationId));
              cloneApplication(applicationId);
            }}
            disabled={selectedRowIds.length !== 1}
            htmlType="button"
            size="small"
            type="primary"
            ghost
          >
            {t('application:clone.cloneApplication')}
          </Button>

          <Button
            className="ml-10"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              if (selectedRowIds.length > 0)
                handleDelete({ applicationIds: selectedRowIds.map(id => Number(id)) });
              else handleDelete({ itemId: selectedItem.id });
            }}
            ghost
            danger
          >
            {selectedRowIds.length > 0
              ? t('application:deleteSelectedApplications')
              : t('application:deleteAllApplications')}
          </Button>
        </div>
      )}

      {columns.length > 0 && (
        <AGTableGridList
          columns={columns}
          applicationRows={applicationRows}
          mappedApplicationRows={mappedApplicationRows}
          fetchNextListings={fetchNextListings}
          fetchApplicationGroupData={fetchGroupData}
          handleApplicationSelect={handleApplicationSelect}
          handleRowSelect={handleRowSelect}
          deleteApplication={deleteApplication}
        />
      )}

      {showApplicationSplitDrawer && applicationGridGroupData.length > 0 && (
        <ApplicationSplitDrawer
          visible={showApplicationSplitDrawer}
          selectedRowIds={selectedRowIds}
          onClose={() => {
            setShowApplicationSplitDrawer(false);
            fetchListing();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ApplicationGridList;
