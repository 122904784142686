export const COLUMN_WIDTHS = {
  qty: 60,
  year_count: 120,
  part_number: 200,
  category: 260,
  year: 70,
  regions: 100,
  positions: 100,
  engine_base_block_types: 70,
  engine_base_bore_inches: 70,
  engine_base_bore_metrics: 70,
  engine_base_cubic_centimeters: 70,
  engine_base_cubic_inches: 70,
  engine_base_cylinders: 60,
  engine_base_liters: 70,
  engine_base_stroke_inches: 80,
  engine_base_stroke_metrics: 80,

  engine_fuel_delivery_types: 70,
  engine_fuel_delivery_sub_types: 90,

  engine_fuel_types: 70,
  engine_designations: 110,
  engine_vins: 60,
  engine_valves: 90,
  engine_aspirations: 100,
  engine_cylinder_head_types: 100,
  engine_ignition_system_types: 100,
  engine_mfrs: 90,
  engine_versions: 90,
  engine_power_outputs: 100,

  transmission_base_types: 120,
  transmission_base_gears: 90,
  transmission_base_control_types: 100,
  transmission_mfr_codes: 100,
  transmission_mfrs: 100,

  body_doors: 90,
  body_types: 100,
  front_spring_types: 110,
  rear_spring_types: 100,
  bed_lengths: 100,
  bed_types: 100,
  front_brake_types: 100,
  rear_brake_types: 100,
  brake_systems: 100,
  steering_types: 100,
  steering_systems: 100,
  drives: 100,
  wheel_bases: 100,
  mfr_body_codes: 100,
  mfr_label: 200,
  qualifiers: 300,
  notes: 300,
  asset_name: 150,
};

export const LIST_ANALYSIS_IDS = [98, 99, 101, 119, 209, 210, 244, 245, 280, 307];

export const SUB_CONFIG_MAPPING = {
  engine_fuel_type_id: 'engine_fuel_types',
  engine_designation_id: 'engine_designations',
  engine_vin_id: 'engine_vins',
  engine_valve_id: 'engine_valves',
  engine_aspiration_id: 'engine_aspirations',
  engine_cylinder_head_type_id: 'engine_cylinder_head_types',
  engine_ignition_system_type_id: 'engine_ignition_system_types',
  engine_mfr_id: 'engine_mfrs',
  engine_version_id: 'engine_versions',
  engine_power_output_id: 'engine_power_outputs',
  engine_base_block_type_id: 'engine_base_block_types',
  engine_base_bore_inch_id: 'engine_base_bore_inches',
  engine_base_bore_metric_id: 'engine_base_bore_metrics',
  engine_base_cubic_inch_id: 'engine_base_cubic_inches',
  engine_base_cubic_centimeter_id: 'engine_base_cubic_centimeters',
  engine_base_cylinder_id: 'engine_base_cylinders',
  engine_base_liter_id: 'engine_base_liters',
  engine_base_stroke_inch_id: 'engine_base_stroke_inches',
  engine_base_stroke_metric_id: 'engine_base_stroke_metrics',
  engine_fuel_delivery_type_id: 'engine_fuel_delivery_types',
  engine_fuel_delivery_sub_type_id: 'engine_fuel_delivery_sub_types',
  engine_fuel_delivery_system_control_type_id: 'engine_fuel_delivery_system_control_types',
  engine_fuel_delivery_system_design_id: 'engine_fuel_delivery_system_designs',
  transmission_mfr_code_id: 'transmission_mfr_codes',
  transmission_mfr_id: 'transmission_mfrs',
  transmission_electronically_controlled_information_id:
    'transmission_electronically_controlled_informations',
  transmission_base_type_id: 'transmission_base_types',
  transmission_base_gear_id: 'transmission_base_gears',
  transmission_base_control_type_id: 'transmission_base_control_types',
  body_door_id: 'body_doors',
  body_type_id: 'body_types',
  front_spring_type_id: 'front_spring_types',
  rear_spring_type_id: 'rear_spring_types',
  bed_length_id: 'bed_lengths',
  bed_type_id: 'bed_types',
  front_brake_type_id: 'front_brake_types',
  rear_brake_type_id: 'rear_brake_types',
  brake_system_id: 'brake_systems',
  brake_abs_id: 'brake_abs',
  steering_type_id: 'steering_types',
  steering_system_id: 'steering_systems',
  drive_id: 'drives',
  mfr_body_code_id: 'mfr_body_codes',
  wheel_base_id: 'wheel_bases',
  position_id: 'positions',
  sub_model_id: 'sub_models',
  region_id: 'regions',
};
