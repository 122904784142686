import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import * as constants from '../../../constants/ParamountReactConstants';
import FormInput from '../../global/Forms/FormInput';

type AutoFillAddressProps = {
  handleAddressChange: (addressComponents: any, address1: string) => void;
};
const AutoFillAddress: React.FC<AutoFillAddressProps> = props => {
  const { t } = useTranslation();

  const [libraries] = React.useState<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(['places']);
  const [language] = React.useState('eng');
  const [version] = React.useState('weekly');

  const [autocomplete, setAutocomplete] = React.useState<any>(null);

  const onLoad = React.useCallback((autocomplete: any) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const address1 = autocomplete!.getPlace().formatted_address?.split(',')[0];
      props.handleAddressChange(autocomplete!.getPlace().address_components, address1);
    }
  };

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={constants.keys.googleMaps.api_key}
      libraries={libraries}
      language={language}
      version={version}
    >
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        fields={['address_components', 'formatted_address']}
        types={['address']}
      >
        <FormInput label={t('companyProfile:address')} name="address_1" required />
      </Autocomplete>
    </LoadScript>
  );
};

export default React.memo(AutoFillAddress);
