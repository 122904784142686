import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormikValues } from 'formik';
import { AutoSizer } from 'react-virtualized';
import { Input, Table, Tooltip } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ApplicationState } from '../../../reducers';
import { ExtendedResources } from '../../../../types/resources';
import { Header, Version } from '../../../../types/xml_header_settings';
import HelpCenter from '../../../containers/HelpCenter';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';

type PiesXMLHeadersProps = {
  canManageXmlHeader: boolean;
  values: FormikValues;
  onChange: (valueId: string, value: string) => void;
};

const { Column } = Table;

const PiesXMLHeaders: React.FC<PiesXMLHeadersProps> = props => {
  const { t } = useTranslation();
  const { values } = props;
  const { piesHeaders, piesVersions, brandCode } = useSelector((state: ApplicationState) => {
    return {
      piesHeaders: state.settings.xmlHeaders.acesPiesHeaders.pies_header,
      piesVersions: state.resources.data.channel.pies_versions,
      brandCode: getSelectedBrandCode(state),
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-brand-settings', {
      location: 'settings-default-aces',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  return (
    <React.Fragment>
      {piesHeaders && (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <div style={{ height, width }}>
                <Table
                  size="small"
                  dataSource={piesHeaders}
                  rowKey="id"
                  scroll={{
                    x: width > 1057 ? undefined : 1057,
                    y: height > piesHeaders.length * 41 + 39 ? undefined : height - 39,
                  }}
                  pagination={false}
                >
                  <Column
                    title={
                      <React.Fragment>
                        {t('settings:acesPiesXMLHeader.fieldName')}
                        <HelpCenter
                          content={
                            <React.Fragment>
                              <div>{t('settings:acesPiesXMLHeader.fieldsContent')}</div>
                              <div>{t('settings:acesPiesXMLHeader.iconContent')}</div>
                            </React.Fragment>
                          }
                        />
                      </React.Fragment>
                    }
                    render={(record: Header) => {
                      return (
                        <React.Fragment>
                          <span className="pr-1">{record.name}</span>
                          {record.description && (
                            <Tooltip title={record.description}>
                              <InfoCircleOutlined className="ant-info-tooltip" />
                            </Tooltip>
                          )}
                        </React.Fragment>
                      );
                    }}
                    width={230}
                  />
                  <Column
                    title={t('settings:acesPiesXMLHeader.input')}
                    render={(record: Header) => {
                      if (record.editable === 0) return record.value || record.default_value;
                      return (
                        <Input
                          value={values.piesHeader[record.id.toString()]}
                          placeholder={
                            (record.default_value && `default: ${record.default_value}`) ||
                            record.name
                          }
                          size="small"
                          onChange={e => props.onChange(record.id.toString(), e.target.value)}
                          maxLength={record.max_length!}
                          disabled={!props.canManageXmlHeader}
                        />
                      );
                    }}
                    width={400}
                  />
                  <Column title={t('settings:acesPiesXMLHeader.version')} width={80} />
                  {piesVersions.map((version: ExtendedResources) => (
                    <Column
                      key={version.id}
                      title={version.code}
                      render={(record: Header) => {
                        const versionIds: number[] = [];
                        record.versions.forEach(version => versionIds.push(version.id));
                        if (versionIds.includes(version.id)) {
                          const vesionDetails: Version[] = record.versions.filter(
                            versionRecord => versionRecord.id === version.id
                          );
                          return (
                            <Tooltip
                              title={`${
                                (vesionDetails[0].mandatory === 1 &&
                                  t('settings:acesPiesXMLHeader.mandatoryFor')) ||
                                (vesionDetails[0].mandatory === 0 &&
                                  t('settings:acesPiesXMLHeader.optionalFor'))
                              } ${t('settings:acesPiesXMLHeader.pies')} ${version.code}`}
                            >
                              <CheckCircleOutlined
                                className={classNames('version-icon', {
                                  green: vesionDetails[0].mandatory === 1,
                                  yellow: vesionDetails[0].mandatory === 0,
                                })}
                              />
                            </Tooltip>
                          );
                        }
                        return (
                          <Tooltip
                            title={`${t('settings:acesPiesXMLHeader.notAvailableText')} ${t(
                              'settings:acesPiesXMLHeader.pies'
                            )} 
                            ${version.code}`}
                          >
                            <CloseCircleOutlined className={classNames('version-icon')} />
                          </Tooltip>
                        );
                      }}
                    />
                  ))}
                </Table>
              </div>
            );
          }}
        </AutoSizer>
      )}
    </React.Fragment>
  );
};

export default PiesXMLHeaders;
