import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Attribute, AttributeCount, AttributeRankedValue, Unit } from '../../../types/attributes';

export type AttributesState = {
  fetchingAttributes: boolean;
  fetchingMoreAttributes: boolean;
  fetchingValues: boolean;
  fetchingMoreValues: boolean;
  fetchingUnits: boolean;
  fetchingMoreUnits: boolean;
  rankedAttributes: Attribute[];
  rankedValues: AttributeRankedValue[];
  rankedUnits: Unit[];
};
const initialState = {
  fetchingAttributes: false,
  fetchingMoreAttributes: false,
  fetchingValues: false,
  fetchingMoreValues: false,
  fetchingUnits: false,
  fetchingMoreUnits: false,
  rankedAttributes: [],
  rankedValues: [],
  rankedUnits: [],
};

const reducer: Reducer<AttributesState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ATTRIBUTES_PENDING': {
      return {
        ...state,
        rankedAttributes: action.meta.page > 1 ? state.rankedAttributes : [],
        fetchingAttributes: action.meta.page === 1,
        fetchingMoreAttributes: action.meta.page > 1,
      };
    }
    case 'FETCH_ATTRIBUTES_FULFILLED': {
      const attributes =
        action.meta.page > 1
          ? [...state.rankedAttributes, ...action.payload.data]
          : action.payload.data;

      return {
        ...state,
        rankedAttributes: attributes,
        fetchingAttributes: false,
        fetchingMoreAttributes: false,
      };
    }
    case 'FETCH_ATTRIBUTE_VALUES_PENDING': {
      return {
        ...state,
        fetchingValues: action.meta.page === 1,
        fetchingMoreValues: action.meta.page > 1,
        rankedValues: action.meta.page === 1 ? [] : state.rankedValues,
      };
    }
    case 'FETCH_ATTRIBUTE_VALUES_FULFILLED': {
      const values =
        action.meta.page > 1
          ? [...state.rankedValues, ...action.payload.data]
          : action.payload.data;
      return { ...state, rankedValues: values, fetchingValues: false, fetchingMoreValues: false };
    }
    case 'FETCH_ATTRIBUTE_UNITS_PENDING': {
      return {
        ...state,
        fetchingUnits: action.meta.page === 1,
        fetchingMoreUnits: action.meta.page > 1,
      };
    }
    case 'FETCH_ATTRIBUTE_UNITS_FULFILLED': {
      const values =
        action.meta.page > 1 ? [...state.rankedUnits, ...action.payload.data] : action.payload.data;
      return { ...state, rankedUnits: values, fetchingUnits: false, fetchingMoreUnits: false };
    }
    case 'UPDATE_ATTRIBUTES_PENDING': {
      return { ...state, rankedAttributes: action.meta.attributes };
    }
    case 'FETCH_ATTRIBUTES_COUNT_FULFILLED': {
      const attributesCount: AttributeCount[] = action.payload.data;
      const updateRankedAttributes = state.rankedAttributes.map(attribute => {
        const attrCount = attributesCount.find(attr => attr.part_attribute_id === attribute.id);
        return { ...attribute, used_by_brand: attrCount ? attrCount.used_by_brand : 0 };
      });
      return { ...state, rankedAttributes: updateRankedAttributes };
    }
  }
  return state;
};

export default reducer;
