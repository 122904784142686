import { FluxStandardAction } from 'redux-promise-middleware';
import { Reducer } from 'redux';
import { SubBrand } from '../../../types/brand';
import { Informations } from '../../../types/interchange';

export type InterchangeState = {
  fetchingInformations: boolean;
  informations: Informations[];
  totalCount?: number;
  subBrands: SubBrand[];
  referenceItems: {
    items: string[];
    referenceItems: { [key: string]: any };
  };
};

const initialState = {
  fetchingInformations: false,
  informations: [],
  subBrands: [],
  referenceItems: {
    items: [],
    referenceItems: {},
  },
};

const reducer: Reducer<InterchangeState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_REFERENCE_ITEMS_FULFILLED': {
      const referenceItems = action.payload.data;
      return {
        ...state,
        referenceItems: {
          items: Object.keys(referenceItems).map(key => key),
          referenceItems,
        },
      };
    }
    case 'FETCH_INTERCHANGE_INFORMATIONS_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        fetchingInformations: true,
        totalCount: page === 1 ? undefined : state.totalCount,
        informations: page === 1 ? [] : state.informations,
      };
    }
    case 'FETCH_INTERCHANGE_INFORMATIONS_FULFILLED': {
      return {
        ...state,
        fetchingInformations: false,
        totalCount: action.payload.data.total_count,
        informations: [...state.informations, ...action.payload.data.part_interchanges],
      };
    }
    case 'UPDATE_INTERCHANGE_ITEM_FULFILLED': {
      const updateInfo = action.payload.data;

      return {
        ...state,
        informations: state.informations.map(info =>
          info.id === action.meta.infoId ? { ...info, ...updateInfo } : info
        ),
      };
    }
    case 'FETCH_INTERCHANGE_SUB_BRANDS_PENDING': {
      return { ...state, subBrands: [] };
    }
    case 'FETCH_INTERCHANGE_SUB_BRANDS_FULFILLED': {
      const brandList = action.payload.data.brands;
      const subBrands = brandList[0]?.sub_brands || [];
      return { ...state, subBrands };
    }
    case 'CLEAR_INTERCHANGE_SUB_BRANDS': {
      return { ...state, subBrands: [] };
    }
    case 'CREATE_INTERCHANGE_ITEM_FULFILLED': {
      const newInfo = action.payload.data;
      return {
        ...state,
        informations: [newInfo, ...state.informations],
      };
    }
    case 'DELETE_INTERCHANGE_ITEM_PENDING': {
      const informations = state.informations.filter(info => info.id !== action.meta.infoId);
      return { ...state, informations };
    }
  }
  return state;
};

export default reducer;
