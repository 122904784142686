import React, { ReactChild, ReactElement } from 'react';
import { Formik, FormikValues, FormikProps, FormikHelpers } from 'formik';
import { Modal, Tag, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import saveTranslation from '../../../constants/SaveTranslation.json';
import Page from './Page';
import FormErrorFocus from '../Forms/FormErrorFocus';
import PageFormDirtyHandler from './PageFormDirtyHandler';
import { updatePageFormDirtyState } from '../../../actions/catalogue/catalogue/update';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { useBlockerConfirm } from '../../../hooks/useBlockerConfirm';

const { confirm } = Modal;

type PageFormikProps = {
  initialValues: FormikValues;
  validationSchema?: any;
  onSubmit: (values: FormikValues, formikActions: CustomFormikActions) => void;
  onCancel?: () => void;
  children: (formikBag: FormikProps<FormikValues>) => ReactChild | null;
  renderHeader?: (formikBag: FormikProps<FormikValues>) => ReactElement;
  handleSaveButtonEnabled?: (formik: FormikProps<FormikValues>) => boolean;
  showAnalysis?: boolean;
  fetchingData?: boolean;
  contentNoSpacing?: boolean;
  contentNoScroll?: boolean;
  enableReinitialize?: boolean;
  showExpandButton?: boolean;
  showNoError?: boolean;
  validateOnChange?: boolean;
};

export type CustomFormikActions = FormikHelpers<FormikValues> & {
  setSubmitPending: () => void;
  setSubmitSuccess: () => void;
  setSubmitError: () => void;
};

enum FormSubmissionStatus {
  'EDIT' = 'EDIT',
  'SUCCESS' = 'SUCCESS',
  'ERROR' = 'ERROR',
}

const PageFormik: React.FC<PageFormikProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const navigate = useNavigate();

  const [nextRouteLocation, setNextRouteLocation] = React.useState<any>(null);
  const [navigateModalVisible, setNavigateModalVisible] = React.useState(false);

  const pageFormDirty = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.pageFormDirty
  );

  const { blockerActive, next, onConfirm, onCancel } = useBlockerConfirm(
    pageFormDirty && !nextRouteLocation
  );

  React.useEffect(() => {
    if (nextRouteLocation) navigate(nextRouteLocation);
  }, [navigate, nextRouteLocation]);

  React.useEffect(() => {
    return () => {
      dispatch(updatePageFormDirtyState(false));
      if (blockerActive) onCancel();
    };
  }, []);

  React.useEffect(() => {
    const showModal = (next: string, onConfirm: () => void, onCancel: () => void) => {
      if (next === nextRouteLocation) {
        setNextRouteLocation('');
        onConfirm();
        return;
      }
      setNavigateModalVisible(true);
      confirm({
        title: saveTranslation.closeWarningTitle,
        onOk() {
          setNextRouteLocation(next);
          setNavigateModalVisible(false);
          onConfirm();
        },
        onCancel() {
          setNextRouteLocation('');
          setNavigateModalVisible(false);
          onCancel();
        },
      });
    };
    if (next && !navigateModalVisible) showModal(next, onConfirm, onCancel);
  }, [navigateModalVisible, next, nextRouteLocation, onCancel, onConfirm]);

  return props.fetchingData ? (
    <div className="flex justify-center items-center flex-1">
      <Spin size="large" />
    </div>
  ) : (
    <Formik
      enableReinitialize={props.enableReinitialize}
      initialStatus={FormSubmissionStatus.EDIT}
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={(values, formikActions) => {
        const actions = {
          ...formikActions,
          setSubmitPending: () => {
            formikActions.setSubmitting(true);
          },
          setSubmitSuccess: () => {
            formikActions.resetForm({ values, status: FormSubmissionStatus.SUCCESS });
            setTimeout(() => formikActions.setStatus(FormSubmissionStatus.EDIT), 2500);
          },
          setSubmitError: () => {
            if (props.showNoError) {
              formikActions.setSubmitting(false);
              formikActions.setStatus(FormSubmissionStatus.EDIT);
            } else {
              formikActions.setSubmitting(false);
              formikActions.setStatus(FormSubmissionStatus.ERROR);
            }
          },
        };
        props.onSubmit(values, actions);
      }}
      validateOnChange={props.validateOnChange}
    >
      {formikBag => {
        const saveButtonEnabled = props.handleSaveButtonEnabled
          ? props.handleSaveButtonEnabled(formikBag)
          : formikBag.dirty;
        const show =
          (formikBag.dirty && FormSubmissionStatus.EDIT === formikBag.status) ||
          FormSubmissionStatus.SUCCESS === formikBag.status ||
          FormSubmissionStatus.ERROR === formikBag.status;
        return (
          <Page
            showExpandButton={props.showExpandButton}
            showAnalysis={props.showAnalysis}
            contentNoSpacing={props.contentNoSpacing}
            contentNoScroll={props.contentNoScroll}
            renderHeader={props.renderHeader && props.renderHeader(formikBag)}
            renderBottom={
              show ? (
                <React.Fragment>
                  <div
                    className={classNames('status-bar flex items-center', {
                      success: formikBag.status === FormSubmissionStatus.SUCCESS,
                      error: formikBag.status === FormSubmissionStatus.ERROR,
                    })}
                  >
                    {formikBag.status === FormSubmissionStatus.ERROR && (
                      <Tag color="red">{saveTranslation.errorSaving}</Tag>
                    )}
                  </div>
                  <div className="page__bottom-save flex items-center">
                    <div className="flex items-center flex-1">
                      {!formikBag.isValid && formikBag.submitCount > 0 && (
                        <Tag color="red">{saveTranslation.fieldsMissing}</Tag>
                      )}
                      <div className="flex-1 flex justify-end">
                        <Button
                          className="page__bottom-save-cancel"
                          onClick={() => {
                            if (props.onCancel) {
                              props.onCancel();
                            }
                            formikBag.resetForm(formikBag.initialValues);
                            formikBag.setStatus(FormSubmissionStatus.EDIT);
                          }}
                        >
                          {saveTranslation.cancel}
                        </Button>

                        <Button
                          data-testid="form-save"
                          type="primary"
                          onClick={() => formikBag.handleSubmit()}
                          disabled={!saveButtonEnabled}
                          loading={formikBag.isSubmitting}
                        >
                          {saveTranslation.save}
                        </Button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null
            }
          >
            <PageFormDirtyHandler />

            {props.children(formikBag)}

            <FormErrorFocus />
          </Page>
        );
      }}
    </Formik>
  );
};

export default PageFormik;
