import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Form, Modal, Button, Typography, Input, message, Alert } from 'antd';
import * as Yup from 'yup';
import { FormikValues, FormikHelpers } from 'formik';
import { FileSearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import AntTooltip from '../../global/AntTooltip';
import SimpleDivider from '../../global/SimpleDivider';
import { ApplicationState } from '../../../reducers';
import { APIScript, APIScriptUpdate } from '../../../../types/distribution';
import {
  deleteScript,
  fetchScriptDataPreview,
  fetchScripts,
  resetCache,
  runScript,
  updateScriptFile,
} from '../../../actions/distribution_apisettings';
import { AsyncDispatch } from '../../../../types/global';
import { isReceiver } from '../../../utils/UserUtils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import ScriptDataPreviewPopover from './ScriptDataPreviewPopover';

const { Paragraph } = Typography;

type ApiAutomationDrawerProps = {
  onClose: () => void;
  scriptData?: APIScript;
  scriptText?: string;
  canManageScript: boolean;
};

const ApiAutomationDrawer: React.FC<ApiAutomationDrawerProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [changeInEditor, setChangeInEditor] = React.useState<boolean>(false);

  const {
    fetchingDataPreview,
    user,
    cacheTTlTypes,
    executionFrequency,
    dataPreview,
    scriptEvents,
  } = useSelector((state: ApplicationState) => {
    return {
      cacheTTlTypes: state.resources.data.script.cache_ttl_types,
      executionFrequency: state.resources.data.script.execution_frequencies,
      user: state.user.user,
      dataPreview: state.distribution.apiSettings.dataPreview,
      scriptEvents: state.distribution.apiSettings.scriptEvents,
      fetchingDataPreview: state.distribution.apiSettings.fetchingDataPreview,
    };
  });

  const [item, setItem] = React.useState<{
    id: number;
    partNumber: string;
    brandCode: string;
    brandName: string;
    defaultWhiteLabelCode: string | null;
  }>();

  React.useEffect(() => {
    if (props.scriptData?.event_id)
      dispatch(fetchScriptDataPreview(props.scriptData.event_id, props.scriptData.new_logic));
  }, [dispatch, props.scriptData]);

  const isReceiverUser = isReceiver(user!);

  const internalOptions = isReceiverUser
    ? [{ id: 1, name: t('api:scheduler'), code: 'execution_frequency_id' }]
    : [
        { id: 1, name: t('api:scheduler'), code: 'execution_frequency_id' },
        { id: 2, name: t('api:event'), code: 'trigger_id' },
      ];

  const getValidationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required(t('api:automationNameMandatory')),
    });

  const formatValues = (values: FormikValues) => ({
    ...(!!props.scriptData && { id: props.scriptData!.id }),
    active: values.active,
    name: values.name,
    cache_ttl_type_id: values.cacheTtlTypeId,
    description: values.description,
    execution_frequency_id: values.internalId === 1 ? values.executionFrequencyId : null,
    language_id: values.languageId,
    script: values.script,
    event_id: values.eventId,
    event_option_id: values.eventOptionId,
    event_sub_option_ids: values.eventSubOptionIds.length
      ? values.eventSubOptionIds.join(',')
      : null,
  });

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    setSubmitting(true);
    const formatedValues: APIScriptUpdate = formatValues(values);
    dispatch(updateScriptFile(formatedValues)).then(() => {
      setSubmitting(false);
      dispatch(fetchScripts());
      props.onClose();
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: t('api:deleteAutomation', {
        automationName: props.scriptData!.name,
      }),
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        dispatch(deleteScript(props.scriptData!.id)).then(() => {
          dispatch(fetchScripts());
          props.onClose();
        });
        intercomEvent('viewed-api', {
          location: 'automation',
          action: 'automation-delete',
        });
      },
    });
  };

  return (
    <DrawerFormik
      title={props.scriptData ? props.scriptData.name : t('api:newAutomation')}
      visible
      onClose={() => {
        dispatch(fetchScripts());
        props.onClose();
      }}
      onDelete={props.scriptData ? () => handleDelete() : undefined}
      initialValues={{
        name: props.scriptData?.name || '',
        active: props.scriptData?.active || 0,
        cacheTtlTypeId: props.scriptData?.cache_ttl_type_id || 4,
        description: props.scriptData?.description || '',
        internalId: props.scriptData?.event_id
          ? 2
          : (props.scriptData?.execution_frequency_id && 1) || null,
        executionFrequencyId: props.scriptData?.execution_frequency_id || 4,
        eventId: props.scriptData?.event_id,
        eventOptionId: props.scriptData?.event_option_id,
        eventSubOptionIds: props.scriptData?.event_sub_option_ids
          ? props.scriptData.event_sub_option_ids.split(',').map(id => Number(id))
          : [],
        externalUrl: props.scriptData?.external_url || '',
        languageId: props.scriptData?.language_id || 1, // python always 1
        script: props.scriptText || '',
      }}
      validationSchema={getValidationSchema()}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      handleSaveButtonEnabled={!props.canManageScript ? () => false : undefined}
    >
      {({ values, setFieldValue, initialValues, resetForm, setValues }) => {
        const handleSaveScript = (values: FormikValues) => {
          const script: APIScriptUpdate = formatValues(initialValues);
          dispatch(updateScriptFile({ ...script, script: values.script })).then(() => {
            const prevValues = { ...values };
            resetForm({
              values: {
                ...initialValues,
                script: values.script,
              },
            });
            setValues({ ...prevValues });
            setChangeInEditor(false);
          });
        };

        const selectedEvent = scriptEvents.find(e => e.id === values.eventId);
        const selectedOption = selectedEvent?.options.find(e => e.id === values.eventOptionId);

        return (
          <Form layout="vertical" className="api-automation-drawer">
            {props.scriptData?.new_logic === 0 && (
              <div className="mb-6">
                <Alert message={t('api:oldLogicInfo')} type="info" showIcon />
              </div>
            )}
            <div className="flex items-center mb-6">
              <div className="mr-2 text-gray-900">
                {t('api:activateAutomation')}
                <AntTooltip
                  title={
                    <div className="space-y-4">
                      <div>{t('api:activateAutomationInfo1')}</div>
                      <div>{t('api:activateAutomationInfo2')}</div>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="ant-info-tooltip ml-2" />
                </AntTooltip>
              </div>
              <Switch
                size="small"
                checkedChildren={t('common:yes')}
                unCheckedChildren={t('common:no')}
                checked={values.active === 1}
                onChange={checked => setFieldValue('active', checked ? 1 : 0)}
              />
            </div>
            <FormInput
              className="w-3/6"
              label={t('api:automationName')}
              name="name"
              placeholder={t('api:automationName')}
              fastField
              required
              testId="automationName"
            />
            <div className="flex flex-row items-center">
              <FormSelect
                className="w-3/6"
                label={t('api:cacheTtl')}
                name="cacheTtlTypeId"
                values={cacheTTlTypes}
                placeholder={t('api:selectTTL')}
              />
              {props.scriptData && (
                <Button
                  className="ml-2 mt-1"
                  disabled={!values.cacheTtlTypeId || values.active === 0}
                  type="primary"
                  size="small"
                  onClick={() => {
                    dispatch(resetCache(props.scriptData!.id));
                    message.success(
                      t('api:cacheTtlSuccess', {
                        automationName: props.scriptData!.name,
                      })
                    );
                  }}
                >
                  {t('api:resetCache')}
                </Button>
              )}
            </div>
            <Form.Item
              label={
                <React.Fragment>
                  {t('api:description')}
                  <AntTooltip title={t('api:addBriefDescription')}>
                    <InfoCircleOutlined className="ant-info-tooltip ml-2" />
                  </AntTooltip>
                </React.Fragment>
              }
            >
              <Input.TextArea
                placeholder={t('api:description')}
                allowClear
                value={values.description || undefined}
                onChange={e => setFieldValue('description', e.target.value)}
                showCount
                rows={4}
                maxLength={255}
              />
            </Form.Item>
            <SimpleDivider title={t('api:triggers')} className="mt-10" />
            <Form.Item label={t('api:external')} className="external">
              <div className="flex flex-row">
                <div className="w-3/6">
                  <Input value={values.externalUrl} disabled />
                  <div className="text-info">{t('api:externalInfo')}</div>
                </div>
                <Paragraph
                  copyable={values.externalUrl ? { text: values.externalUrl } : undefined}
                  className="pl-2 pt-2 api-automation-drawer__copy"
                />
              </div>
            </Form.Item>
            <div className="flex space-x-2">
              <div className="w-3/6 internal mb-6">
                <FormSelect
                  label={t('api:internal')}
                  name="internalId"
                  values={internalOptions}
                  placeholder={t('api:internal')}
                  allowClear
                />
                <div className="text-info">{t('api:internalInfo')}</div>
              </div>

              {values.internalId === 1 && (
                <FormSelect
                  className="flex-1"
                  label={t('api:selectFrequency')}
                  name="executionFrequencyId"
                  values={executionFrequency}
                  placeholder={t('api:selectFrequency')}
                  allowClear
                />
              )}
              {values.internalId === 2 && (
                <div className="flex-1 api-automation__select-event">
                  <FormSelect
                    className="flex-1"
                    label={t('api:selectEvent')}
                    name="eventId"
                    values={scriptEvents}
                    placeholder={t('api:selectEvent')}
                    onChange={value => {
                      setFieldValue('eventId', value || null);
                      setFieldValue('eventOptionId', null);
                      setFieldValue('eventSubOptionIds', []);
                      dispatch(fetchScriptDataPreview(value, props.scriptData?.new_logic || 1));
                    }}
                    allowClear
                  />
                  {values.eventId && selectedEvent?.options && (
                    <FormSelect
                      className="flex-1"
                      label={selectedEvent?.option_title}
                      name="eventOptionId"
                      values={selectedEvent?.options || []}
                      placeholder={selectedEvent?.option_title}
                      onChange={value => {
                        setFieldValue('eventOptionId', value || null);
                        setFieldValue('eventSubOptionIds', []);
                      }}
                      allowClear
                    />
                  )}
                  {values.eventOptionId && selectedOption?.options && (
                    <FormMultiSelect
                      className="flex-1"
                      label={selectedOption?.option_title}
                      placeholder={selectedOption?.option_title}
                      name="eventSubOptionIds"
                      values={selectedOption?.options || []}
                      allowClear
                    />
                  )}
                  <div className="text-info">{t('api:eventInfo')}</div>
                </div>
              )}

              {values.eventId && (
                <ScriptDataPreviewPopover
                  fetchingDataPreview={fetchingDataPreview}
                  dataPreview={dataPreview}
                  item={item}
                  eventId={values.eventId}
                  newLogic={props.scriptData?.new_logic || 1}
                  handleSelectItem={(id, partNumber, brandCode, brandName, defaultWhiteLabelCode) =>
                    setItem({
                      id,
                      partNumber,
                      brandCode,
                      brandName,
                      defaultWhiteLabelCode,
                    })
                  }
                >
                  <FileSearchOutlined
                    className={classNames('api-automation-drawer__preview', {
                      preview__blue: changeInEditor,
                    })}
                  />
                </ScriptDataPreviewPopover>
              )}
            </div>

            {props.canManageScript && (
              <React.Fragment>
                <SimpleDivider title={t('api:script')} className="mt-10" />
                <Editor
                  height="260px"
                  theme="vs-dark"
                  defaultLanguage="Python"
                  onChange={value => {
                    if (initialValues.script.localeCompare(value) !== 0) setChangeInEditor(true);
                    else setChangeInEditor(false);
                    setFieldValue('script', value);
                  }}
                  defaultValue={values.script}
                  options={{ readOnly: false }}
                />
                <div className="flex flex-row justify-between space-y-2">
                  <div className="text-info">{t('api:scriptInfo')}</div>
                  {props.scriptData && values.active === 1 && props.canManageScript && (
                    <div className="align-right space-x-2">
                      <AntTooltip title={t('api:runScriptInfo')} hide={!changeInEditor}>
                        <Button
                          size="small"
                          disabled={changeInEditor}
                          onClick={() => {
                            dispatch(runScript(props.scriptData!.id, dataPreview));
                            message.info(t('api:runningScript'));
                          }}
                        >
                          {t('api:runScript')}
                        </Button>
                      </AntTooltip>
                      <Button
                        size="small"
                        disabled={!changeInEditor}
                        onClick={() => {
                          handleSaveScript(values);
                        }}
                      >
                        {t('api:saveScript')}
                      </Button>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default ApiAutomationDrawer;
