import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import HeaderPage from '../../global/page/HeaderPage';
import ShopifyProductTable from './ShopifyProductTable';
import ShopifyVehicleFilter from './ShopifyVehicleFilter';
import ShopifySettings from './ShopifySettings';
import { ApplicationState } from '../../../reducers';
import { ShopifyChannel } from '../../../../types/channel';
import { fetchShopifyChannelFilters } from '../../../actions/channel/fetch';
import { deleteShopifyFilter, updateChannel } from '../../../actions/channel/update';
import { deleteShopifyStore } from '../../../actions/channel/delete';
import ShopifyUrlModal from './ShopifyUrlModal';
import { createAPIToken } from '../../../actions/distribution_apisettings';
import { AsyncDispatch } from '../../../../types/global';

type EditChannelShopifyProps = {
  channelType: string;
  shopifyId?: number;
  handleClose: () => void;
};

const EditChannelShopify: React.FC<EditChannelShopifyProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [selectedTab, setSelectedTab] = React.useState<number>(1);
  const [connectToDB, setConnectToDB] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string | null>(null);

  const {
    apiToken,
    shopifyChannel,
    filters,
    fetchingVehicleFilters,
    fetchingApplicationResources,
    shopifyUsernameAlreadyExists,
  } = useSelector((state: ApplicationState) => {
    const shopifyChannel = state.channel.channels.channelShopifyData.channels?.find(
      (d: ShopifyChannel) => d.id === props.shopifyId
    );
    return {
      shopifyChannel,
      fetchingVehicleFilters: state.channel.channels.fetchingShopifyVehicleFilters,
      filters: state.channel.channels.shopifyVehicleFilters.filters,
      fetchingApplicationResources: state.resources.fetchingApplicationResources,
      apiToken: state.distribution.apiSettings?.token?.token,
      shopifyUsernameAlreadyExists: state.channel.channels.shopifyUsernameAlreadyExists,
    };
  });

  React.useEffect(() => {
    if (shopifyChannel) dispatch(fetchShopifyChannelFilters(shopifyChannel.id));
  }, [dispatch, shopifyChannel]);

  const handleDeleteFilter = (filterId: number) => {
    dispatch(deleteShopifyFilter(filterId));
  };

  const getDescription = (tab: number) => {
    switch (tab) {
      case 1: {
        return t('channel:shopifyProductDescription');
      }
      case 2: {
        return t('channel:shopifyVehicleFilterDesc');
      }
      case 3: {
        return undefined;
      }
    }
  };

  const shopifyTabOptions = [
    {
      id: 1,
      name: t('channel:products'),
      content: (
        <ShopifyProductTable
          channelType={props.channelType}
          shopifyId={props.shopifyId!}
          handleNavigation={() => setSelectedTab(3)}
        />
      ),
    },
    {
      id: 2,
      name: t('channel:vehicleFilter'),
      content: (
        <ShopifyVehicleFilter
          fetchingFilterList={fetchingVehicleFilters || fetchingApplicationResources}
          filterList={filters || []}
          handleDeleteFilter={handleDeleteFilter}
          shopifyId={props.shopifyId!}
        />
      ),
    },
    {
      id: 3,
      name: t('channel:settingsTitle'),
      content: (
        <ShopifySettings
          connectToDB={connectToDB}
          token={apiToken || token}
          status={shopifyChannel.shop_status}
          channelName={shopifyChannel.name}
          deleteChannel={() => {
            const shopifyName = shopifyChannel.name;
            dispatch(deleteShopifyStore(props.shopifyId!));
            props.handleClose();
            message.success(t('channel:alertDelete', { shopifyName }), 5);
          }}
          updateChannelName={name =>
            dispatch(
              updateChannel({
                shopifyId: props.shopifyId,
                name,
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
              })
            )
          }
          handleReconnect={editUserName => {
            if (editUserName) setConnectToDB(true);
            else if (
              (shopifyChannel.shop_status.status === 'connected_to_other' ||
                !shopifyChannel.shop_status.installed) &&
              !!shopifyChannel.shop_status.username
            ) {
              if (!apiToken && !token)
                dispatch(createAPIToken()).then(res => {
                  setToken(res.value.data.token);
                  if (window) window.open('https://accounts.shopify.com/store-login');
                });
              else if (window) window.open('https://accounts.shopify.com/store-login');
            } else {
              setConnectToDB(true);
            }
          }}
        />
      ),
    },
  ];

  return (
    <div className="edit-channel-shopify">
      {connectToDB && (
        <ShopifyUrlModal
          connectToDatabase={connectToDB}
          handleClose={() => setConnectToDB(false)}
          shopifyChannel={{
            id: shopifyChannel.id,
            name: shopifyChannel.name,
            shopStatus: shopifyChannel.shop_status,
          }}
          token={apiToken || token}
          rejectedUsername={shopifyUsernameAlreadyExists}
        />
      )}
      {props.shopifyId && (
        <HeaderPage
          title={shopifyChannel.name}
          description={getDescription(selectedTab)}
          tabs={shopifyTabOptions}
          selectedTabKey={selectedTab}
          handleSelectTab={(id: number) => setSelectedTab(id)}
          actionButtons={
            <Button
              icon={<CloseOutlined />}
              className="ant-drawer-close-button"
              type="text"
              onClick={() => props.handleClose()}
            />
          }
        />
      )}
    </div>
  );
};

export default EditChannelShopify;
