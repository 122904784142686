import * as createActions from './create';
import * as fetchActions from './fetch';
import * as updateActions from './update';
import * as deleteActions from './delete';
import * as otherActions from './other';

export default {
  ...createActions,
  ...fetchActions,
  ...updateActions,
  ...deleteActions,
  ...otherActions,
};
