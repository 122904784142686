import { Input, Modal, Tooltip, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ShopifyStatus } from '../../../../types/channel';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { fetchShopifyChannelBrands } from '../../../actions/channel/fetch';
import { connectShopifyStoreToDB, createShopifyStore } from '../../../actions/channel/update';
import { createAPIToken } from '../../../actions/distribution_apisettings';
import { hasPermission } from '../../../utils/Permissions';

const { Paragraph } = Typography;

type ShopifyUrlModalProps = {
  shopifyChannel?: { id: number; name: string; shopStatus: ShopifyStatus };
  token: string | null;
  createChannel?: boolean;
  connectToDatabase?: boolean;
  handleClose: () => void;
  fetchingChannelShopify?: boolean;
  handleCreate?: (shopifyId: number) => void;
  rejectedName?: string;
  rejectedUsername?: string;
};

const ShopifyUrlModal: React.FC<ShopifyUrlModalProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { user } = useSelector((state: ApplicationState) => ({
    user: state.user.user,
  }));

  const [name, setName] = React.useState<string>('');
  const [userName, setUserName] = React.useState<string>('');
  const [token, setToken] = React.useState<string | null>(null);
  const [shopifyId, setShopifyId] = React.useState<number | null>(null);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [userNameRejected, setUserNameRejected] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.createChannel) {
      setName('');
      setToken(props.token);
      setShopifyId(null);
      setUserName('');
      setConnected(false);
    }
  }, [props.createChannel, props.token]);

  React.useEffect(() => {
    if (props.connectToDatabase) {
      const channel = props.shopifyChannel!;
      setName(channel.name);
      setToken(props.token);
      setUserName(channel.shopStatus.username || '');
      setConnected(false);
      setShopifyId(channel.id);
    }
  }, [props.connectToDatabase, props.shopifyChannel, props.token]);

  const handleCreateShop = () => {
    if (name)
      dispatch(createShopifyStore(name)).then(res => {
        const shopifyId = res.value.data.id;
        setShopifyId(shopifyId);
        dispatch(fetchShopifyChannelBrands('', shopifyId));
      });
  };

  const handleConnect = () => {
    dispatch(connectShopifyStoreToDB(userName, shopifyId!))
      .then(() => {
        setConnected(true);
        if (token && props.connectToDatabase) {
          if (window) window.open('https://accounts.shopify.com/store-login');
          props.handleClose();
        }
        setUserNameRejected(false);
      })
      .catch(() => {
        setConnected(false);
        setUserNameRejected(true);
      });
  };

  const createToken = () => {
    if (!token)
      dispatch(createAPIToken()).then(res => {
        setToken(res.value.data.token);
        if (window) window.open('https://accounts.shopify.com/store-login');
      });
    else if (window) window.open('https://accounts.shopify.com/store-login');
  };

  const yourStoreLink = (
    <a className="pl-1" href={t('channel:yourStoreUrl')}>
      {t('channel:yourStoreUrl')}
    </a>
  );
  const shopifyLink = <a href={t('channel:shopifyUrl')}>{t('channel:shopifyUrl')}</a>;
  const infoText = (
    <p className="info-text">
      1. {t('channel:enterShopifyUsername')} {shopifyLink} {t('channel:useCustomDomain')}
      {yourStoreLink}
    </p>
  );

  return (
    <Modal
      title={<div>{!shopifyId ? t('channel:enterName') : t('channel:allowPdmText')}</div>}
      open={props.createChannel || props.connectToDatabase}
      onOk={() => {
        if (!shopifyId) handleCreateShop();
        if (shopifyId && !connected) handleConnect();
        if (connected && !token && hasPermission(user, 'can_create_api_token')) createToken();
        if (token && connected && props.handleCreate) {
          if (window) window.open('https://accounts.shopify.com/store-login');
          props.handleCreate(shopifyId!);
        }
      }}
      onCancel={() => {
        if (!shopifyId) props.handleClose();
        if (((shopifyId && !connected) || connected) && props.handleCreate)
          props.handleCreate(shopifyId!);
        else props.handleClose();
      }}
      okText={
        !shopifyId || !connected
          ? t('channel:continue')
          : (!token && t('channel:connect')) || t('channel:continue')
      }
      cancelText={
        !shopifyId ? (
          t('common:cancel')
        ) : (
          <Tooltip placement="bottom" title={t('channel:alloWLater')}>
            {t('common:skip')}
          </Tooltip>
        )
      }
      okButtonProps={{ disabled: name?.length === 0, loading: props.fetchingChannelShopify }}
      className="shopify-modal"
      width={560}
      closable={false}
    >
      <React.Fragment>
        {!shopifyId && (
          <div className="px-6">
            <div className="mb-6">{t('channel:enterdNameInfo')}</div>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder={t('channel:name')}
              data-testid="shopify-name"
            />
            {props.rejectedName === name && (
              <div className="shopify-warn__exists">{t('channel:alreadyExists')}</div>
            )}
          </div>
        )}
        {shopifyId && (
          <React.Fragment>
            {infoText}
            <div className="flex flex-col items-baseline px-6">
              <Input
                addonBefore="https://"
                value={userName}
                onChange={e => setUserName(e.target.value)}
                placeholder={t('channel:userName')}
                className="shopify-user-name"
                disabled={connected}
                data-testid="shopify-username"
              />
              {(props.rejectedUsername === userName || userNameRejected) && (
                <div className="shopify-warn__exists pl-2">{t('channel:alreadyExists')}</div>
              )}
            </div>

            {connected && (
              <div className="mt-2 mx-6">
                <Paragraph copyable={token ? { text: token } : undefined}>
                  2.{t('channel:step2', { token: token || 'XXXXXXXXXXXX' })}
                </Paragraph>
                3. {t('channel:step3')}
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </Modal>
  );
};

export default ShopifyUrlModal;
