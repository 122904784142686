import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { WarningFilled } from '@ant-design/icons';

type SkipWelcomeGuideModalProps = {
  showModal: boolean;
  onCancel: () => void;
  handleSkip: () => void;
};

const SkipWelcomeGuideModal: React.FC<SkipWelcomeGuideModalProps> = props => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      open={props.showModal}
      onCancel={() => props.onCancel()}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            props.handleSkip();
          }}
          className="welcome__skip-guide_skip-button"
        >
          {t('welcome:hideGuide')}
        </Button>,
        <Button key="cancel" onClick={() => props.onCancel()}>
          {t('common:cancel')}
        </Button>,
      ]}
    >
      <div className="flex flex-row items-center">
        <WarningFilled className="welcome__skip-guide_warn-icon" />
        <h5 className="welcome__skip-guide_title">{t('welcome:doYouWantSkip')}</h5>
      </div>
      <p className="welcome__skip-guide_description mt-2">{t('welcome:skipText')}</p>
    </Modal>
  );
};

export default SkipWelcomeGuideModal;
