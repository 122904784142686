import React from 'react';
import useGoogleAnalytics from './GoogleAnalyticsTracker';

export const withTracker = (Component: React.ComponentType) => {
  return (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    useGoogleAnalytics();

    return <Component {...props} />;
  };
};
