import axios from 'axios';
import { getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import { ItemPackage } from '../../../../types/itemPackage';

export function updatePackage(params: ItemPackage[]) {
  return {
    type: 'UPDATE_PACKAGE',
    payload: axios.patch(`${getNonGwApiRoot()}/v2/items/packages`, params),
    meta: { itemPackages: params },
  };
}
