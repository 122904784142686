import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { ImportTypes } from '../../../types/import_export';

export enum CurrentImportState {
  'UPLOAD' = 'UPLOAD',
  'IMPORT' = 'IMPORT',
  'DONE' = 'DONE',
}

export type ImportState = {
  id: string;
  logId: number | null;
  fileName: string;
  importType: ImportTypes;
  currentState: CurrentImportState;
  showNotification: boolean;
  brandId: number;
  uploadState: { progress: number; failed: boolean };
  importState?: {
    progress: number;
    subProgress: number;
    status: string;
    errors: boolean;
    warnings: boolean;
    completed: boolean;
    statusId: number | null;
  };
};

export type ImporterState = {
  imports: ImportState[];
};

const initialState = {
  imports: [],
};

const getImportTypeFromSourceId = (sourceId: number) => {
  if (sourceId === 1) {
    return ImportTypes.ACES;
  }
  if (sourceId === 2) {
    return ImportTypes.PIES;
  }
  if (sourceId === 5) {
    return ImportTypes.ASSET;
  }
  return ImportTypes.EXCEL;
};

const formatImportLog = (log: any) => {
  const importCompleted = !!log.completed_at;
  const importState: ImportState = {
    id: `${log.id}`,
    logId: log.id,
    fileName: log.file_name,
    importType: getImportTypeFromSourceId(log.source_id),
    brandId: log.brand_id,
    showNotification: !importCompleted,
    currentState: importCompleted ? CurrentImportState.DONE : CurrentImportState.IMPORT,
    uploadState: { progress: 0, failed: false },
    importState: {
      progress: log.progress,
      subProgress: log.sub_progress,
      status: log.status,
      errors: log.error_count,
      warnings: log.warning_count,
      completed: importCompleted,
      statusId: log.status_id,
    },
  };
  return importState;
};

const reducer: Reducer<ImporterState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case '@IMPORTER/FETCH_LATEST_IMPORT_LOGS_FULFILLED': {
      const imports = action.payload.data;
      const formatedImports = imports.map((singleImport: any) => {
        return formatImportLog(singleImport);
      });
      return {
        ...state,
        imports: formatedImports,
      };
    }
    case '@IMPORTER/CREATE_NEW_UPLOAD': {
      const { id, importType, brandId, fileName } = action.payload;
      const importState: ImportState = {
        id,
        importType,
        logId: null,
        fileName,
        brandId,
        showNotification: true,
        currentState: CurrentImportState.UPLOAD,
        uploadState: { progress: 0, failed: false },
      };
      return {
        ...state,
        imports: [...state.imports, importState],
      };
    }
    case '@IMPORTER/UPDATE_UPLOAD_PROGRESS': {
      const { progress, id } = action.payload;
      return {
        ...state,
        imports: state.imports.map((importValue: ImportState) => {
          if (importValue.id === id) {
            return {
              ...importValue,
              uploadState: { progress: progress > 99 ? 99 : progress },
            };
          }
          return importValue;
        }),
      };
    }
    case '@IMPORTER/UPLOAD_FAILED': {
      const importId = action.payload.id;
      return {
        ...state,
        imports: state.imports.map((importValue: ImportState) => {
          if (importValue.id === importId) {
            return {
              ...importValue,
              currentState: CurrentImportState.UPLOAD,
              uploadState: { failed: true },
            };
          }
          return importValue;
        }),
      };
    }
    case '@IMPORTER/UPLOAD_FINISHED': {
      const importId = action.payload.id;
      const logId = action.payload.logId;
      return {
        ...state,
        imports: state.imports.map((importValue: ImportState) => {
          if (importValue.id === importId) {
            return {
              ...importValue,
              currentState: CurrentImportState.IMPORT,
              logId,
              uploadState: { progress: 100 },
            };
          }
          return importValue;
        }),
      };
    }
    case '@IMPORTER/FETCH_RUNNING_IMPORT_LOGS_FULFILLED': {
      const importLogs = action.payload.data;
      const importLogIds = importLogs.map((log: any) => log.id);
      return {
        ...state,
        imports: state.imports.map(importValue => {
          if (importLogIds.includes(importValue.logId)) {
            const log = importLogs.find((log: any) => log.id === importValue.logId);
            const importCompleted = !!log.completed_at;
            return {
              ...importValue,
              currentState: importCompleted ? CurrentImportState.DONE : CurrentImportState.IMPORT,
              importState: {
                progress: log.progress,
                subProgress: log.sub_progress,
                completed: importCompleted,
                status: log.status,
                errors: log.error_count > 0,
                warnings: log.warning_count > 0,
                statusId: log.status_id,
              },
            };
          }
          return importValue;
        }),
      };
    }
    case '@IMPORTER/CLOSE_NOTIFICATION': {
      const { id } = action.payload;
      return {
        ...state,
        imports: state.imports.map(importValue => {
          if (importValue.id === id) {
            return {
              ...importValue,
              showNotification: false,
            };
          }
          return importValue;
        }),
      };
    }
    case '@IMPORTER/OPEN_NOTIFICATION': {
      const { id } = action.payload;
      return {
        ...state,
        imports: state.imports.map(importValue =>
          importValue.logId === id
            ? {
                ...importValue,
                showNotification: true,
              }
            : importValue
        ),
      };
    }
  }
  return state;
};

export default reducer;
