import { createSelector } from 'reselect';

const getDigitalAssetResources = state => state.resources.data.digital_asset;
const getAssetTypesSorting = state => state.items.digitalAssets.assetTypesSorting;
const getDigitalAssets = state => state.parent.digitalAsset.digitalAssets;
const getBrands = state => state.parent.brands.brands;

export const sortDigitalAssetsTypes = createSelector(
  [getDigitalAssetResources, getAssetTypesSorting],
  (digitalAssetResources, assetTypesSorting) => {
    if (!digitalAssetResources) return false;
    const assetTypesSortingIds = assetTypesSorting.map(assetType => assetType.id);
    return {
      ...digitalAssetResources,
      types: digitalAssetResources.types.sort(
        (a, b) => assetTypesSortingIds.indexOf(a.id) - assetTypesSortingIds.indexOf(b.id)
      ),
    };
  }
);

export const digitalAssetsWithBrandCodes = createSelector(
  [getDigitalAssets, getBrands],
  (digitalAssets, brands) => {
    return digitalAssets.map(asset => {
      const brandDetails = brands.find(brand => asset.parent_owner_brand_id === brand.id);
      return {
        ...asset,
        brandCode: (brandDetails && brandDetails.code) || '',
        defaultWhiteLabelBrandCode: brandDetails && brandDetails.default_whitelabel_brand_code,
      };
    });
  }
);
