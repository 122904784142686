import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { DescriptionType, UsedDescriptionsByBrand } from '../../../types/description';
import { MarketingCopy } from '../../../types/marketing_copy';

export type DescriptionState = {
  fetchingMarketingCopy: boolean;
  fetchingDescription: boolean;
  descriptions: DescriptionType[];
  error: string | null;
  marketingCopies: MarketingCopy[];
  usedDescriptionsByBrand: UsedDescriptionsByBrand[];
  usedDescriptionBrandId?: undefined;
};

const initialState = {
  fetchingMarketingCopy: false,
  fetchingDescription: false,
  descriptions: [],
  error: null,
  marketingCopies: [],
  usedDescriptionsByBrand: [],
  usedDescriptionBrandId: undefined,
};

const reducer: Reducer<DescriptionState, FluxStandardAction> = (state = initialState, action) => {
  const { meta } = action;
  switch (action.type) {
    case 'FETCH_MARKETING_COPIES_PENDING': {
      return { ...state, marketingCopies: [], fetchingMarketingCopy: true };
    }
    case 'FETCH_MARKETING_COPIES_REJECTED': {
      return { ...state, fetchingMarketingCopy: false, error: action.payload };
    }
    case 'FETCH_MARKETING_COPIES_FULFILLED': {
      return {
        ...state,
        error: null,
        fetchingMarketingCopy: false,
        marketingCopies: action.payload.data,
      };
    }
    case 'FETCH_USED_DESCRIPTIONS_FULFILLED': {
      return {
        ...state,
        usedDescriptionsByBrand: action.payload.data,
        usedDescriptionBrandId: action.meta.brandId,
      };
    }
    case 'FETCH_DESCRIPTIONS_PENDING': {
      return { ...state, descriptions: [], fetchingDescription: true };
    }
    case 'FETCH_DESCRIPTIONS_REJECTED': {
      return { ...state, fetchingDescription: false, error: action.payload };
    }
    case 'FETCH_DESCRIPTIONS_FULFILLED': {
      return {
        ...state,
        fetchingDescription: false,
        error: null,
        descriptions: action.payload.data,
      };
    }
    case 'SET_MARKETING_COPIES': {
      return {
        ...state,
        error: null,
        marketingCopies: action.payload,
      };
    }
    case 'DELETE_DESCRIPTION_PENDING': {
      const descriptions = state.descriptions.filter(desc => desc.id !== action.meta.descriptionId);
      return { ...state, descriptions };
    }
    case 'CREATE_DESCRIPTION_FULFILLED': {
      const descriptions = [action.payload.data, ...state.descriptions];
      return { ...state, descriptions };
    }
    case 'UPDATE_DESCRIPTION_PENDING': {
      return {
        ...state,
        descriptions: state.descriptions.map(description =>
          description.id === meta.descriptionId
            ? {
                ...description,
                language_id: meta.languageId || description.language_id,
                type_id: meta.typeId || description.type_id,
                auto_generate: meta.autoGenerate || description.auto_generate,
              }
            : description
        ),
      };
    }
    case 'UPDATE_DESCRIPTION_FULFILLED': {
      return {
        ...state,
        descriptions: state.descriptions.map(description =>
          description.id === meta.descriptionId ? action.payload.data : description
        ),
      };
    }
    case 'UPDATE_DESCRIPTION_CONTENTS_PENDING': {
      const descriptions = state.descriptions.map(description =>
        description.id === action.meta.descriptionId
          ? { ...description, contents: action.meta.contents }
          : description
      );
      return { ...state, descriptions };
    }
    case 'UPDATE_DESCRIPTION_CONTENTS_FULFILLED': {
      const descriptions = state.descriptions.map(description =>
        description.id === action.payload.data.id ? action.payload.data : description
      );
      return { ...state, descriptions };
    }
  }
  return state;
};

export default reducer;
