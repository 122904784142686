import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WarningOutlined } from '@ant-design/icons';
import ModalContent from './ModalContent';

class ModalInfo extends React.Component {
  render() {
    const { type, message, interaction, interactionText, interactionIcon } = this.props;
    const modalCss = classNames('modal-info', {
      error: type === 'error',
      warning: type === 'warning',
      info: type === 'info',
    });
    const modalIconCss = classNames('modal-info__icon', {
      error: type === 'error',
      warning: type === 'warning',
      info: type === 'info',
    });
    return (
      <ModalContent className={modalCss}>
        <div className={modalIconCss}>
          <WarningOutlined />
        </div>
        <div className="modal-info__message">{message}</div>
        {interaction && (
          <div className="modal-info__interaction" onClick={this.props.interaction}>
            {interactionIcon && interactionIcon}
            {interactionText && (
              <span className="modal-info__interaction-text">{interactionText}</span>
            )}
          </div>
        )}
      </ModalContent>
    );
  }
}

ModalInfo.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  interaction: PropTypes.func,
  interactionText: PropTypes.string,
  interactionIcon: PropTypes.string,
};

ModalInfo.defaultProps = {
  interaction: () => {},
  interactionText: '',
  interactionIcon: '',
};

export default ModalInfo;
