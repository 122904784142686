import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { getSelectedBrandId } from '../../../utils/ReduxUtils';
import { UpdateXMLHeaderSettings } from '../../../../types/xml_header_settings';

export function fetchXmlHeaders() {
  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    const brandId = getSelectedBrandId(getState());
    dispatch({
      type: 'FETCH_BRAND_ACES_PIES_XML_HEADERS',
      payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/xml_headers`),
    });
  };
}

export function updateXMLHeaders(updateHeaderValues: UpdateXMLHeaderSettings, brandId: number) {
  return {
    type: 'UPDATE_ACES_PIES_XML_HEADERS',
    payload: axios.patch(
      `${getApiRoot()}/parents/owners/brands/${brandId}/xml_headers`,
      updateHeaderValues
    ),
  };
}
