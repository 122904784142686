import { CombinedItemPriceSheet } from '../../../../types/price_sheet';

export const usedIds = (priceSheet: CombinedItemPriceSheet) =>
  priceSheet.item_prices.map(itemPrice => itemPrice.type_id);

export const unUsedPriceTypes = (priceSheet: CombinedItemPriceSheet, usedTypeIds: number[]) =>
  priceSheet.parent_price_types?.filter(
    parentPriceType => !usedTypeIds.includes(parentPriceType.type_id)
  );

export const addMinTwoDecimals = (price: string) => {
  const decimal = price.split('.')[1];
  const decimalsLength = decimal && decimal.length > 2 ? decimal.length : 2;
  return Number(price).toFixed(decimalsLength);
};
