import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import SearchBoxList from '../../global/SearchBoxList';
import { ExportBuilderAdvancedSegmentField } from '../../../../types/export_builder_advanced';

type ColumnContentConfiguratorProps = {
  fields: ExportBuilderAdvancedSegmentField[];
};

const ColumnContentConfigurator: React.FC<ColumnContentConfiguratorProps> = ({ fields }) => {
  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const { t } = useTranslation();

  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<any>();

  const previewExample = values.columnContent.replace(
    /([?{2]+)?\w+([?}2]+)/g,
    (match: string | any[]) => {
      const filterMatch = match.slice(2, match.length - 2);
      const option = fields.find(({ code }) => code === filterMatch);
      return option ? option.sample_value : `${match}`;
    }
  );

  return (
    <div>
      <Form.Item
        label={t('exportBuilderAdvanced:columnContent')}
        validateStatus={errors.columnContent && touched.columnContent ? 'error' : ''}
        help={
          (errors.columnContent && touched.columnContent && t('validation:required')) || undefined
        }
        required={!!values.segmentId}
      >
        <Input
          data-testid="column-content"
          value={values.columnContent}
          onChange={e => setFieldValue('columnContent', e.target.value)}
          onBlur={() => setFieldTouched('columnContent')}
          addonAfter={
            !!values.segmentId && (
              <Popover
                open={popoverVisible}
                onOpenChange={visible => setPopoverVisible(visible)}
                content={
                  <SearchBoxList
                    values={fields}
                    valueKey="code"
                    onClick={code => {
                      setFieldValue('columnContent', `${values.columnContent}{{${code}}}`);
                      setPopoverVisible(false);
                    }}
                  />
                }
                trigger="click"
                placement="left"
                className="overflow-auto"
              >
                <span className="cursor-pointer" data-testid="column-content-add">
                  <span className="mr-1">{t('common:add')}</span>
                  <PlusCircleOutlined />
                </span>
              </Popover>
            )
          }
        />
      </Form.Item>
      <div>
        {t('exportBuilderAdvanced:configExample')}: <span className="italic">{previewExample}</span>
      </div>
    </div>
  );
};

export default ColumnContentConfigurator;
