import axios from 'axios';
import { message } from 'antd';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { hideModal, showModal } from '../../app/modal';
import { MODAL_TYPES } from '../../../constants/ModalConstants';
import { showParentDigitalAssets } from '../../parent/digital_asset';

export function createDigitalAssetDescription(assetId, typeId, languageId, contents) {
  const params = utils.createURL([
    { name: 'item_digital_asset_id', values: assetId },
    { name: 'type_id', values: typeId },
    { name: 'language_id', values: languageId },
  ]);
  return {
    type: 'CREATE_DIGITAL_ASSET_DESCRIPTION',
    payload: axios.post(`${getApiRoot()}/items/digital_assets/descriptions${params}`, {
      contents,
    }),
  };
}

export function createDigitalAsset(
  typeId,
  file,
  fileName,
  itemId,
  overwrite,
  representationId,
  fileTypeId,
  downloadUri,
  uri,
  assetName,
  brandId,
  languageId
) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  let body;
  if (file) {
    body = file;
  } else if (downloadUri) {
    body = { download_uri: encodeURI(downloadUri) };
  } else if (uri) {
    body = { uri: encodeURI(uri) };
  }

  const params = utils.createURL([
    { name: 'reference_type', values: 'brands' },
    { name: 'type_id', values: typeId },
    { name: 'file_name', values: fileName },
    { name: 'item_id', values: itemId },
    { name: 'override', values: overwrite },
    { name: 'representation_id', values: representationId },
    { name: 'file_type_id', values: fileTypeId },
    { name: 'brand_id', values: brandId },
    { name: 'language_id', values: languageId },
  ]);

  return dispatch => {
    dispatch({
      type: 'CREATE_DIGITAL_ASSET',
      payload: axios.post(`${getNonGwApiRoot()}/parents/digital_assets${params}`, body, config),
    })
      .then(response => {
        dispatch(hideModal());
        if (overwrite) {
          const newDigitalAsset = response.value.data;
          fetch(newDigitalAsset.asset_url, { cache: 'reload', mode: 'no-cors' });
          fetch(newDigitalAsset.thumbnail, { cache: 'reload', mode: 'no-cors' });
          fetch(newDigitalAsset.shop_preview, { cache: 'reload', mode: 'no-cors' });
        }
      })
      .catch(err => {
        const errData = err.response.data;
        if (errData.type === 'file_name') {
          dispatch(
            showModal(MODAL_TYPES.DIGITAL_ASSET_EXISTING, {
              error: errData,
            })
          );
        }
        if (errData.type === 'insecure_link') {
          message.error(errData.message);
        }
      });
  };
}

export function linkDigitalAsset(itemId, assetId, assetTypeId, representationTypeId) {
  const params = utils.createURL([
    { name: 'item_id', values: itemId },
    { name: 'digital_asset_id', values: assetId },
    { name: 'type_id', values: assetTypeId },
    { name: 'representation_id', values: representationTypeId },
  ]);
  return dispatch => {
    dispatch({
      type: 'LINK_DIGITAL_ASSET',
      payload: axios.post(`${getNonGwApiRoot()}/items/digital_assets${params}`),
    }).then(() => {
      dispatch(hideModal());
      dispatch(showParentDigitalAssets(false));
    });
  };
}
