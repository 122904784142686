import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { BundleQualifier, QualifierValue } from '../../../../types/application';
import { splitQualifierText } from '../../../utils/QualifierUtils';

type ApplicationBundleConfigCellProps = {
  className?: string;
  configKey: string;
  qualifiers?: BundleQualifier[];
  notes?: { note: string; record_number: number }[];
  openDrawer: () => void;
};

const ApplicationBundleConfigCell: React.FC<ApplicationBundleConfigCellProps> = props => {
  const { className, configKey, qualifiers, notes, openDrawer } = props;

  const noteConfig = (notes: { note: string; record_number: number }[]) => {
    const notesDisplay = notes.map(note => <div key={note.record_number}>{note.note}</div>);

    return (
      <Tooltip title={notesDisplay}>
        <div className="application__bundle-value-wrapper flex-1">{notesDisplay}</div>
      </Tooltip>
    );
  };

  const parameters = (partsList: string[], values: QualifierValue[]) =>
    partsList.map(part => {
      const re = /<p\d type="\D*?"\/>/g; // regex to match parameter like <p1 type="num"/>
      if (re.test(part)) {
        const valueId = part.match(/<p\d+? /)![0].slice(2, -1); // get id from parameter ("p1")
        const parameter = values.find(value => value.record_number === Number(valueId));
        return (
          <span key={valueId} className="application__bundle-qualifier-value">
            {parameter && parameter.value ? `${parameter.value} ${parameter.meta_uom || ''}` : '_'}
          </span>
        );
      }
      return part;
    });

  const qualifierText = (qualifier: BundleQualifier) => {
    const parts = splitQualifierText(qualifier.qualifier_text);
    if (parts) return parameters(parts, qualifier.values);

    return qualifier.qualifier_text;
  };

  const subConfigs = (category: string) => {
    if (category === 'notes' && notes?.length) return noteConfig(notes);
    if (category === 'qualifiers' && qualifiers?.length) {
      const qualifierDisplay = qualifiers.map(qualifier => (
        <div key={qualifier.item_base_qualifier_id} className="application__bundle-value">
          {qualifierText(qualifier)}
        </div>
      ));

      return (
        <Tooltip title={qualifierDisplay}>
          <div className="application__bundle-value-wrapper flex-1">{qualifierDisplay}</div>
        </Tooltip>
      );
    }
  };

  return (
    <div className={`application__bundle-edit-cell flex ${className || ''}`} onClick={openDrawer}>
      {subConfigs(configKey)}
      <EditOutlined className="application__bundle-edit-icon" />
    </div>
  );
};

export default ApplicationBundleConfigCell;
